import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { ModalType } from './../../../../enums/ModalType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { ServicenowTicketInformationComponent } from './../../../modal-templates/servicenow/servicenow-ticket-information/servicenow-ticket-information.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';
import { ServicenowIncidentsFormGeneratorModalComponent } from '../../../modal-templates/servicenow-incidents-form-generator-modal/servicenow-incidents-form-generator-modal.component';

@Component({
    selector: 'app-servicenow-incidents-listing',
    templateUrl: './servicenow-incidents-listing.component.html',
    styleUrls: ['./servicenow-incidents-listing.component.sass'],
})
export class ServicenowIncidentsListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    masterLoginIndex = null;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.tableInput = {
            unfitColumns: true,
            afterResponse: null,
            widgetIconData: {
                type: IconType.IMAGE,
                class: '/assets/integrations/servicenow-mini-logo.png',
            },
            selection: 'single',
            buttons: [
                {
                    buttonName: 'Create Incident',
                    function: this.createIncident.bind(this),
                    loader: false,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                },
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'listIncidents',
            },
            columns: [
                {
                    columnName: 'Ticket Id',
                    columnKey: 'ticketId',
                    minWidth: 180,
                },
                {
                    columnName: 'Subject',
                    columnKey: 'subject',
                    minWidth: 250,
                },
                {
                    columnName: 'State',
                    columnKey: 'state',
                    minWidth: 120,
                },
                {
                    columnName: 'Creation Time',
                    columnKey: 'creationTime',
                    minWidth: 180,
                },
                {
                    columnName: 'Priority',
                    columnKey: 'priority',
                    minWidth: 150,
                    cellRenderer: (rowData: RowEvent) => {
                        const ticketData = rowData['data'];
                        const priority = ticketData['priority'];
                        if (Number(priority)) {
                            return String(priority);
                        } else {
                            return String(
                                (priority as string).split('-')[1]
                            ).trim();
                        }
                    },
                },
                {
                    columnName: 'Created By',
                    columnKey: 'createdBy',
                    minWidth: 220,
                },
                {
                    columnName: 'Info',
                    columnKey: 'info',
                    pinned: 'right',
                    minWidth: 90,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        if (!rowData['buttonGenInputs']) {
                            rowData['buttonGenInputs'] = [];
                        }
                        const buttons: IButtonGeneratorInput = {
                            buttonName: '',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-info',
                            },
                            buttonType: ButtonType.ICON,
                            buttonColorType: ButtonColorType.PRIMARY,
                            showLoader: false,
                            customClass: 'icon-height',
                            function: () => {
                                const ticketData = rowData['data'];
                                const modalData: IModalData = {
                                    modalName: 'Ticket Information',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-info',
                                    },
                                    sourceId: this.widgetRef.uniqueIdentity,
                                    modalType: ModalType.SIDE,
                                    modalWidthVw: 50,
                                    modalAutoHeight: true,
                                    modalSteps: [
                                        {
                                            stepData: {
                                                componentToLoad:
                                                    ServicenowTicketInformationComponent,
                                                payload: {
                                                    data: ticketData,
                                                },
                                            },
                                            stepName: 'Ticket Information',
                                        },
                                    ],
                                };
                                this.widgetRef.modalService.openModal(
                                    modalData
                                );
                            },
                        };
                        rowData['buttonGenInputs'].push(buttons);
                        return rowData;
                    },
                },
            ],
        };
    }

    ngOnInit(): void {}

    createIncident() {
        const modalData: IModalData = {
            modalName: 'Create Incident',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt',
            },
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 60,
            // modalHeightVh: 80,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            ServicenowIncidentsFormGeneratorModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            },
                        },
                    },
                    stepName: 'Create Incident',
                },
            ],
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
