import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { GlobalDataService } from './../../services/global-data/global-data.service';

@Component({
    selector: 'app-semi-pie',
    templateUrl: './semi-pie.component.html',
    styleUrls: ['./semi-pie.component.sass']
})
export class SemiPieComponent implements OnInit {
    graphId = Helper.generateUniqueKey(8);
    chart: any;
    am4core: any;
    am4charts: any;
    am4themesAnimated: any;
    @Input('graphData') graphData: IGraphData;

    constructor(public globalDataService: GlobalDataService) {}

    ngOnInit(): void {
        Helper.loadAm4chartsLibraries().then((resolve) => {
            this.am4charts = resolve['am4charts'];
            this.am4core = resolve['am4core'];
            this.am4themesAnimated = resolve['am4themesAnimated'];
            this.generateGraph();
        });

        this.globalDataService.windowResizeEvent.subscribe(() => {
            this.resizeChart();
        });
    }

    resizeChart() {
        if (this.chart && this.chart.svgContainer) {
            this.chart.svgContainer.measure();
        }
    }

    generateGraph() {
        this.am4core.useTheme(this.am4themesAnimated);
        this.chart = this.am4core.create(this.graphId, this.am4charts.PieChart);
        this.chart.hiddenState.properties.opacity = 0;

        this.chart.data = this.graphData.chartData;
        this.chart.radius = this.am4core.percent(70);
        this.chart.innerRadius = this.am4core.percent(40);
        this.chart.startAngle = 180;
        this.chart.endAngle = 360;

        const series = this.chart.series.push(new this.am4charts.PieSeries());
        series.dataFields.category = 'category';
        series.dataFields.value = 'value';

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.inert = true;
        series.slices.template.tooltipText = '{category}';

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;
        series.labels.template.disabled = true;
        series.tooltip.getFillFromObject = false;
        series.tooltip.getStrokeFromObject = true;
        series.tooltip.background.fill = this.am4core.color(
            Helper.convertToHexIfHsl(Helper.getCssVarValue('commonBgColor'))
        );
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = this.am4core.color(
            Helper.convertToHexIfHsl(
                Helper.getCssVarValue('graphTooltipLabelColor')
            )
        );
        series.tooltip.label.fontSize = Helper.getCssVarValue('fs200');

        this.chart.legend = new this.am4charts.Legend();
        const marker = this.chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(100, 100, 100, 100);
        marker.radius = 2;
        // Disabling the slice percentage of the graph
        this.chart.legend.valueLabels.template.disabled = true;
    }
}
