export class TemplateData {
    static widgetDataTemplate = {
        widgetName: 'test',
        portletType: 'INTEGRATION',
        portletVersion: 'V1',
        widgetType: 'FULL',
        apis: {
            createdApiIds: [],
            reportWidgetApis: [],
            widgetApis: [
                {
                    apiRouteSuffix: '/view-and-api-management/test',
                    requestMethod: 'GET',
                    authorizationType: 'BEARER_TOKEN',
                    host: 'https://api.centilytics.com',
                    description: 'test',
                    apiType: 'FETCH',
                    version: 'V1',
                    packageId: 'miscellaneous',
                }
            ]
        },
        extraInfo: {
            icon: 'Aws',
            cloud: 'AWS',
            serviceName: 'Cloud Visibility',
            subProductName: 'Home'
        },
        filters: {
            sideFilters: [],
            configurationAutomation: [],
            generalFilters: [],
            stepWorkLoadFilter: []
        }
    };
}
