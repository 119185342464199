import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import * as $ from 'jquery';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-key-value-pair',
    templateUrl: './key-value-pair.component.html',
    styleUrls: ['./key-value-pair.component.sass']
})
export class KeyValuePairComponent implements OnInit {
    @Input('data') data;
    @Input('limit') limit;
    selectable = true;
    removable = true;
    valueList = [];
    addOnBlur = true;
    objectKeys = Object.keys;
    objectValues = Object.values;
    tempData = {};
    keyValueData = {};
    tempKey = '';
    showEmptyKeyValueField = true;
    errorMessage = '';
    @Output('change') change = new EventEmitter();
    addNewButtonInput: IButtonGeneratorInput;
    selectedListButtonInputs: IButtonGeneratorInput[];
    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash'
    };
    plusIcon: IIcon = {
        type: IconType.SVG,
        class: 'plus_filled'
    };

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor() {}

    ngOnInit() {
        if (Object.keys(this.data).length > 0) {
            this.showEmptyKeyValueField = false;
            $.extend(true, this.keyValueData, this.data);
        }
        this.addNewButtonInput = {
            buttonName: '',
            buttonType: ButtonType.ICON,
            buttonColorType: ButtonColorType.PRIMARY,
            buttonIcon: this.plusIcon,
            customClass: 'plus-icon',
            preventHoverEffect: true,
            function: () => {}
        };
        this.selectedListButtonInputs = [
            {
                buttonName: '',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.ICON,
                buttonIcon: this.trashIcon,
                customClass: 'trash-icon',
                preventHoverEffect: true,
                function: () => {}
            },
            {
                buttonName: '',
                buttonType: ButtonType.ICON,
                buttonColorType: ButtonColorType.PRIMARY,
                buttonIcon: this.plusIcon,
                customClass: 'plus-icon',
                preventHoverEffect: true,
                function: () => {}
            }
        ];
    }

    addValueInExisting(event: MatChipInputEvent, item): void {
        const input = event.input;
        const value = event.value;
        if (item in this.keyValueData && !this.keyValueData[item]) {
            this.keyValueData[item] = [];
        }
        if ((value || '').trim() && !this.keyValueData[item].includes(value)) {
            this.keyValueData[item].push(value);
            const tempDataObj = {};
            $.extend(true, tempDataObj, this.keyValueData, tempDataObj);
            this.updateObject(tempDataObj);
        }
        if (input) {
            input.value = '';
        }
    }

    addNewPair(k: HTMLInputElement) {
        if (this.tempData[this.tempKey]) {
            delete this.tempData[this.tempKey];
        }
        if (!k.value.trim()) {
            this.errorMessage = 'Key can not be empty.';
            return;
        }

        if (k.value) {
            if (this.tempData['']) {
                delete this.tempData[''];
            }
            if (!(k.value in this.keyValueData)) {
                this.errorMessage = '';
                this.tempData[k.value] = this.valueList;
                this.tempKey = k.value;
                const tempDataObj = {};
                $.extend(true, tempDataObj, this.tempData, this.keyValueData);
                this.updateObject(tempDataObj);
            } else {
                this.errorMessage = 'Key already exists.';
                return;
            }
        }
    }

    removeValueIfExisting(value, item): void {
        const index = this.keyValueData[item]
            ? this.keyValueData[item].indexOf(value)
            : -1;
        if (index >= 0) {
            this.keyValueData[item].splice(index, 1);
        }

        const tempDataIndex = this.tempData[item]
            ? this.tempData[item].indexOf(value)
            : -1;
        if (tempDataIndex >= 0) {
            this.tempData[item].splice(tempDataIndex, 1);
        }

        const tempDataObj = {};
        $.extend(true, tempDataObj, this.keyValueData, this.tempData);
        this.updateObject(tempDataObj);
    }

    deletePair(item) {
        delete this.keyValueData[item];
        delete this.tempData[item];
        const tempDataObj = {};
        $.extend(true, tempDataObj, this.keyValueData, this.tempData);
        this.updateObject(tempDataObj);
        if (Object.keys(this.keyValueData).length === 0) {
            this.showEmptyKeyValueField = true;
        }
    }

    addValue(key, event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim() && !this.valueList.includes(value)) {
            this.errorMessage = '';
            this.valueList.push(value);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        // this is only for handling validation(if only valuelist is entered by user. then how to show error for key)
        if (key.value) {
            this.tempData[key.value] = this.valueList;
        } else {
            this.tempData[''] = this.valueList;
        }

        const tempDataObj = {};
        $.extend(true, tempDataObj, this.tempData, this.keyValueData);
        this.updateObject(tempDataObj);
    }

    removeValue(value): void {
        const index = this.valueList.indexOf(value);
        if (index >= 0) {
            this.valueList.splice(index, 1);
        }

        const tempDataObj = {};
        $.extend(true, tempDataObj, this.tempData, this.keyValueData);
        this.updateObject(tempDataObj);
    }

    createNewKeyValueField(key) {
        // if (this.valueList.length === 0) {
        //   this.errorMessage = 'Value can not be empty.';
        //   return;
        // } else
        if (key.value.trim() === '') {
            this.errorMessage = 'Key can not be empty';
            return;
        }
        if (this.tempData['']) {
            delete this.tempData[''];
        }
        $.extend(true, this.keyValueData, this.tempData);
        key.value = '';
        this.valueList = [];
        this.tempKey = '';
    }

    updateObject(data) {
        this.change.emit([data]);
    }
}
