<div #rightsizing>
    <div
        *ngIf="
            !(widgetRef.loadingData | async) && accounts && accounts.length > 0
        "
        class="tw-relative tw-bottom-2 tw-flex tw-justify-center tw-mt-2 tw-mb-2 tw-text-fs-600 tw-rounded-md savings"
    >
        <div class="tw-p-3">
            <span> Potential Savings: &nbsp;&nbsp;</span>
            <span class="tw-font-bold"> {{ totalPotentialSavings }} </span>
        </div>
    </div>
    <div
        [ngClass]="
            widgetRef.visibleSections.value.has(ViewType.TABLE)
                ? 'buttons-container'
                : null
        "
        class="controls tw-pb-2"
        *ngIf="
            !(widgetRef.loadingData | async) && accounts && accounts.length > 0
        "
    >
        <app-multi-button-generator
            *ngIf="buttons"
            [buttonGenInputs]="buttons"
            [options]="options"
        ></app-multi-button-generator>

        <div
            class="table-search"
            *ngIf="
                cardsData.length &&
                widgetRef.visibleSections.value.has(ViewType.CARDS)
            "
        >
            <app-table-search
                [data]="cardsData"
                (changed)="filteredCardsData = $event"
            ></app-table-search>
        </div>
    </div>

    <div
        class="widget-data-container"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS) &&
            cardsData &&
            cardsData.length
        "
    >
        <cdk-virtual-scroll-viewport
            itemSize="20"
            minBufferPx="500"
            maxBufferPx="2000"
            class="cards-container"
        >
            <div
                *cdkVirtualFor="let card of filteredCardsData; let i = index"
                class="card"
            >
                <div
                    class="card-border"
                    [class.greenGradient]="card['Action'] === actions.Downgrade"
                    [class.yellowGradient]="card['Action'] === actions.Upgrade"
                    (click)="moreInfoModal(card)"
                >
                    <div (click)="$event.stopPropagation()">
                        <mat-checkbox
                            (change)="
                                card.selected = $event.checked;
                                cardSelected(card, $event.checked)
                            "
                            [checked]="card['selected']"
                        >
                        </mat-checkbox>
                    </div>
                    <div class="title">
                        <div class="card-circle">
                            <span
                                class="status downgrade"
                                [matTooltip]="'Downgrade'"
                                *ngIf="card['Action'] === 'Downgrade'"
                            >
                                <i class="fas fa-arrow-down"></i>
                            </span>
                            <span
                                class="status upgrade"
                                [matTooltip]="'Upgrade'"
                                *ngIf="card['Action'] === 'Upgrade'"
                            >
                                <i class="fas fa-arrow-up"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="content" (click)="moreInfoModal(card)">
                    <ng-container *ngFor="let data of displayEntry">
                        <ng-container
                            *ngIf="card[data.id] && card[data.id] !== '-'"
                        >
                            <div
                                class="displayEntry"
                                [class.red-saving]="
                                    data.id === costType.ADDITIONAL_COST
                                "
                                [class.green-saving]="
                                    data.id === costType.COST_SAVINGS
                                "
                            >
                                <p>
                                    {{ data.label }}:
                                    <span>{{ card[data.id] }}</span>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div
                    class="card-border tw-text-fs-600 tw-h-10 tw-flex tw-justify-center tw-items-center"
                    [class.greenGradient]="card['Action'] === actions.Downgrade"
                    [class.yellowGradient]="card['Action'] === actions.Upgrade"
                    (click)="getFormData(card); card.loader = true"
                >
                    Rightsize &nbsp;
                    <mat-spinner
                        *ngIf="card.loader"
                        class="light-theme loader"
                        [diameter]="21"
                    ></mat-spinner>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>

    <div
        class="widget-data-container table-container"
        [id]="widgetRef.widgetTableId"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            accounts &&
            accounts.length
        "
    >
        <app-only-table-generator
            *ngIf="tableGenInput"
            [tableInput]="tableGenInput"
            [tableData]="tableData"
            [widgetRef]="widgetRef"
            (gridRef)="gridRef = $event"
        ></app-only-table-generator>
    </div>

    <ng-template #confirmationMessage>
        <div class="tw-pb-1 tw-text-fs-400 tw-pr-1 tw-ml-5">
            The following resources will be rightsized to
            {{ resourceType }} with a minimal downtime.
        </div>
        <div class="tw-ml-5 tw-text-fs-400 tw-font-semibold resource-list">
            <ng-container *ngFor="let name of selectedResourceName">
                <li class="tw-py-1">{{ name }}</li>
            </ng-container>
        </div>
    </ng-template>
</div>
