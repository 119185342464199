export enum SupportedFormat {
    PDF = 'PDF',
    EXCEL = 'Excel',
    CSV = 'CSV',
    JSON = 'JSON',
    REPORT = 'REPORT',
    ALERT = 'ALERT',
    BUDGET_PIE = 'BUDGET_PIE',
    BUDGET_REGIONAL = 'BUDGET_REGIONAL',
    BUDGET_TIMESERIES = 'BUDGET_TIMESERIES'
}

export const SupportFormatFromString = (format): SupportedFormat => {
    return format as SupportedFormat;
};
