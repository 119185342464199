export enum VariableFieldType {
    STRING = 'STRING',
    LIST_STRING = 'LIST_STRING',
    LIST_OBJECT = 'LIST_OBJECT',
    LIST_NUMBER = 'LIST_NUMBER',
    LIST = 'LIST',
    MAP = 'MAP',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    OBJECT = 'OBJECT',
    SHORT_TEXT = 'SHORT_TEXT'
}
