<form
    *ngIf="filterData"
    class="form"
    [formGroup]="addConfigurationForm"
    (keydown.enter)="$event.preventDefault()"
>
    <div class="form-data">
        <mat-form-field #outlineFieldRef appearance="outline">
            <mat-label>Configuration Name</mat-label>
            <input
                [readOnly]="isUpdate"
                [ngClass]="'defaultTextColor'"
                matInput
                placeholder="Enter Name"
                [formControl]="ruleSetName"
                (focus)="outlineFieldRef.updateOutlineGap()"
            />
            <mat-icon matPrefix>keyboard</mat-icon>
            <mat-error *ngIf="ruleSetName.invalid"
                >This field is required</mat-error
            >
        </mat-form-field>

        <app-field-generator
            [enableTemp]="false"
            [filterStoreKey]="STORE_KEY"
            [filterData]="filterData"
            [lightMode]="true"
        ></app-field-generator>

        <app-key-value-pair
            [data]="keyValueSet"
            [limit]="0"
            (change)="changeInputData($event)"
        ></app-key-value-pair>
    </div>
    <div class="form-controls">
        <app-button-generator
            [buttonGenInput]="buttonGenInput"
        ></app-button-generator>
    </div>
</form>
