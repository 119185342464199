import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class EqualityValidator extends CustomValidator {
    key = CustomValidatorKey.EQUALITY_VALIDATOR;

    constructor(private controlNames: string[]) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (this.formGenRef && this.formGenRef.formGroup) {
            const controlvalues = [];
            this.controlNames.forEach((controlName) => {
                if (this.formGenRef.formGroup.get(controlName)) {
                    const controlValue =
                        this.formGenRef.formGroup.get(controlName).value;
                    controlvalues.push(controlValue);
                }
            });
            if (controlvalues.includes(value)) {
                return null;
            }
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
