<div class="container">
    <div class="form-container">
        <mat-tab-group
            #tabGroup
            mat-stretch-tabs
            (selectedTabChange)="changeTab($event.tab.textLabel)"
            mat-align-tabs="center"
        >
            <mat-tab [label]="Tab.EXISTING">
                <div class="tw-w-full tw-mx-auto tw-my-auto tw-pt-5">
                    <app-modal-info-template
                        [infoData]="infoData"
                    ></app-modal-info-template>
                </div>
                <div class="tw-w-4/5 tw-mx-auto tw-my-auto">
                    <app-form-generator
                        *ngIf="accountIdFormGenInput"
                        [formGeneratorInput]="accountIdFormGenInput"
                        (formGroupRef)="accountIdFormGroup = $event"
                        (valuesChange)="change($event)"
                        (updateControl)="updateControl = $event"
                    ></app-form-generator>
                </div>

                <div
                    class="policy-box tw-py-2 tw-px-5"
                    *ngIf="selectedValue1 && selectedValue1.length"
                >
                    <div
                        class="header box tw-flex tw-text-fs-200 tw-font-semibold"
                    >
                        <div
                            class="account-id tw-flex-1 tw-flex tw-items-center"
                        >
                            <span
                                class="tw-font-heading tw-text-fs-200 tw-font-medium"
                                >Account Ids</span
                            >
                        </div>
                        <div class="policy tw-flex-1 tw-text-center">
                            <span
                                class="tw-font-heading tw-text-fs-200 tw-font-medium"
                                >Policy Validation</span
                            >
                        </div>
                    </div>
                    <div
                        class="body box tw-flex tw-text-fs-200 tw-font-semibold"
                        *ngFor="let obj of accountIds1.entries()"
                    >
                        <div
                            class="account-id common-label tw-flex tw-items-center tw-flex-1 tw-font-family tw-text-fs-400 tw-font-semibold"
                        >
                            <span>{{ obj[0] }}</span>
                        </div>
                        <div
                            class="policy common-label tw-flex-1 tw-text-center"
                        >
                            <div *ngIf="!obj[1]" class="spinner">
                                <i class="fas fa-spinner fa-spin"></i>
                            </div>
                            <div
                                *ngIf="obj[1]"
                                [ngClass]="
                                    obj[1] === 'Access Grant'
                                        ? 'access green'
                                        : 'access red'
                                "
                            >
                                <span>{{ obj[1] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="Tab.ADD_NEW">
                <div class="tw-w-full tw-mx-auto tw-my-auto tw-pt-5">
                    <app-modal-info-template
                        [infoData]="infoData"
                    ></app-modal-info-template>
                </div>
                <div class="tw-w-4/5 tw-mx-auto tw-my-auto">
                    <app-form-generator
                        *ngIf="newAccountIdFormGenInput"
                        [formGeneratorInput]="newAccountIdFormGenInput"
                        (formGroupRef)="newAccountIdFormGroup = $event"
                        (valuesChange)="change($event)"
                        (updateControl)="updateControl = $event"
                    ></app-form-generator>
                </div>

                <div
                    class="policy-box tw-py-2 tw-px-5"
                    *ngIf="selectedValue && selectedValue.length"
                >
                    <div class="box tw-flex">
                        <div
                            class="accounts-id tw-font-heading tw-text-fs-200 tw-font-medium"
                        >
                            Accounts Ids
                        </div>
                        <div
                            class="tw-flex tw-flex-nowrap access-secret-key-head"
                        >
                            <div
                                class="tw-font-heading tw-text-fs-200 tw-font-medium access"
                            >
                                Access Key
                            </div>
                            <div
                                class="tw-font-heading tw-text-fs-200 tw-font-medium secret"
                            >
                                Secret Key
                            </div>
                        </div>
                        <div
                            class="tw-font-heading tw-text-fs-200 tw-font-medium tw-invisible action"
                        >
                            Actions
                        </div>
                    </div>
                    <div
                        class="body box tw-flex tw-font-family tw-text-fs-400 tw-font-semibold"
                        *ngFor="let obj of accountIds.keys(); let i = index"
                    >
                        <div
                            class="tw-font-family tw-text-fs-400 tw-font-semibold obj-key"
                            [ngClass]="
                                accountIds.get(obj) ? accountIds.get(obj) : ''
                            "
                        >
                            {{ obj }}
                        </div>
                        <div class="keys tw-flex tw-flex-nowrap">
                            <app-form-generator
                                [formGeneratorInput]="keysFormGenInput"
                                (formGroupRef)="keysFormGroup.set(obj, $event)"
                            ></app-form-generator>
                        </div>
                        <div
                            class="delete-icon"
                            appIcon
                            [data]="deleteIcon"
                            (click)="onDelete(obj)"
                        ></div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="buttons">
        <div class="tw-mr-auto">
            <app-button-generator [buttonGenInput]="buttonInput[0]">
            </app-button-generator>
        </div>
        <app-button-generator
            *ngIf="selectedTab === Tab.ADD_NEW"
            [buttonGenInput]="buttonInput[1]"
            [disabled]="!verifyButton"
        >
        </app-button-generator>

        <app-button-generator
            [disabled]="!saveButton"
            [buttonGenInput]="buttonInput[2]"
        >
        </app-button-generator>
    </div>
</div>
