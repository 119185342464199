import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { ContentField } from './../../../enums/AppearanceType';
import { FilterType } from './../../../enums/FilterType';
import { FormState } from './../../../enums/FormState';
import { IFormField } from './../../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-content-field',
    templateUrl: './content-field.component.html',
    styleUrls: ['./content-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ContentFieldComponent,
            multi: true
        }
    ]
})
export class ContentFieldComponent
    implements OnInit, AfterViewInit, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    FilterType = FilterType;
    FormState = FormState;
    onChange: any = () => {};
    onTouch: any = () => {};
    FieldAppearance = ContentField;
    control: FormControl;
    @ViewChild('htmlContent') htmlContent: ElementRef;

    constructor() {}

    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (
            !existingAppearance.includes(this.field.appearance as ContentField)
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
    }

    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }

    writeValue(value: string): void {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    ngAfterViewInit(): void {
        if (!(this.field.listData && this.field.listData.length)) {  
            let fieldValue = this.field.value;
            if (!this.field.contentDisplayValue && fieldValue) {
                fieldValue = Helper.trimHTML(
                    Helper.stringToHTML(fieldValue)
                ).innerHTML?.toString();
        
                // Moving Closing Brackets out of the Anchor tag.
                let htmlValue = Helper.stringToHTML(fieldValue);

                // Removing closing brackets from link.
                for (let i = 0; i < htmlValue.getElementsByTagName('a').length; i++) {
                    const anchorTagAttribute = htmlValue.getElementsByTagName('a')[i]?.href;
                    let UpdatedAnchorTag = anchorTagAttribute?.replace('\)', '');
                    UpdatedAnchorTag = UpdatedAnchorTag.replace('\(http', 'http');
                    htmlValue.getElementsByTagName('a')[i].setAttribute('href', UpdatedAnchorTag);
                }

                // Updating the original value with the new value.
                fieldValue = Helper.trimHTML(htmlValue).innerHTML?.toString();
                fieldValue = fieldValue.replace(/\)<\/a>/g, '</a>)');
            }
            (this.htmlContent.nativeElement as HTMLElement).innerHTML =
                this.field.contentDisplayValue ?? fieldValue;
        }
    }
}
