<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #numberFieldRef
            [floatLabel]="'auto'"
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <mat-label>
                {{ field.label }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </mat-label>
            <input
                matInput
                type="number"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? numberFieldRef.updateOutlineGap() : ''
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type one end -->

<!-- Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #numberFieldRef
            [floatLabel]="'never'"
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <input
                matInput
                type="number"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? numberFieldRef.updateOutlineGap() : ''
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type two end -->

<!-- Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row 11 {{ field.extraClass }}">
        <span class="form-label">
            {{ field.label }}
            <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
        </span>
        <mat-form-field
            #numberFieldRef
            class="form-input"
            [floatLabel]="'never'"
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <input
                matInput
                type="number"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? numberFieldRef.updateOutlineGap() : ''
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            />
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!-- Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #numberFieldRef
            [floatLabel]="'never'"
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <input
                matInput
                type="number"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? numberFieldRef.updateOutlineGap() : ''
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type four end -->
<!-- Field type five start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_5">
    <div class="form-row filter-field {{ field.extraClass }}">
        <input
            matInput
            type="number"
            [ngClass]="field.extraClass ? field.extraClass : ''"
            [placeholder]="field.placeholder"
            [name]="field.name"
            [value]="value"
            [required]="field.required !== undefined ? field.required : true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            (change)="
                field.onChange
                    ? field.onChange($event.target.value, formGroup)
                    : null;
                valueChanged($event.target.value)
            "
            (focusout)="
                field.onFocusout
                    ? field.onFocusout(
                          $event.target.value,
                          formGroup,
                          field.name
                      )
                    : null
            "
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        />
    </div>
</ng-container>
<!--Field type five end -->

<!-- Field type six start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_6">
    <div class="form-row input-field filter-field {{ field.extraClass }}">
        <input
            matInput
            type="number"
            [min]="0"
            [max]="100"
            [ngClass]="field.extraClass ? field.extraClass : ''"
            [placeholder]="field.placeholder"
            [name]="field.name"
            [value]="percentage"
            [required]="field.required !== undefined ? field.required : true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            (change)="
                field.onChange ? field.onChange($event, formGroup) : null;
                valueChanged($event.target.value)
            "
            (focusout)="
                field.onFocusout
                    ? field.onFocusout($event, formGroup, field.name)
                    : null
            "
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        />
        <div>
            <div class="arrow up">▲</div>
            <div class="arrow down">▼</div>
        </div>
    </div>
</ng-container>
<!--Field type six end -->

<!-- Field type seven start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_7">
    <div class="form-row type-7 {{ field.extraClass }}">
        <span class="form-label type-7">
            {{ field.label }}
            <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
        </span>
        <mat-form-field
            #numberFieldRef
            [floatLabel]="'never'"
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [hideRequiredMarker]="true"
        >
            <input
                matInput
                type="number"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="
                    field.prefixIcon ? numberFieldRef.updateOutlineGap() : ''
                "
                (focusout)="
                    field.onFocusout
                        ? field.onFocusout($event, formGroup, field.name)
                        : null
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
            />
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type seven end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
