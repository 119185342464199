import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ApiUrls } from './../../../../../core/classes/ApiUrls';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { HttpService } from './../../../../services/http/http-main/http.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-servicenow-testing-modal',
    templateUrl: './servicenow-testing-modal.component.html',
    styleUrls: ['./servicenow-testing-modal.component.sass']
})
export class ServicenowTestingModalComponent implements OnInit {
    priorityList: IDropdownData[] = [
        {
            id: '1 - Critical',
            label: 'Critical'
        },
        {
            id: '2 - High',
            label: 'High'
        },
        {
            id: '3 - Moderate',
            label: 'Moderate'
        },
        {
            id: '4 - Low',
            label: 'Low'
        },
        {
            id: '5 - Planning',
            label: 'Planning'
        }
    ];

    formgenInput: IFormGeneratorInput = null;
    stepData = {};
    hiddenFields;
    buttonInput: IButtonGeneratorInput;
    serviceTestFormGroup: FormGroup;

    constructor(
        private modalInputData: ModalInjectedData,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        if (this.modalInputData && this.modalInputData.data) {
            this.stepData = this.modalInputData.data;
        }
        this.buttonInput = {
            buttonName: 'Create Incident',
            showLoader: true,
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (buttonRef: IButtonData) => {
                this.onCreate(buttonRef, this.serviceTestFormGroup);
            }
        };

        this.formgenInput = {
            state: FormState.CREATE,
            formName: 'ServiceNow Create Incident',
            submitButton: null,
            fields: [
                {
                    name: 'description',
                    fieldType: FilterType.TEXT,
                    label: 'Detailed Description',
                    placeholder: 'Detailed Description',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Detailed description is required'
                        }
                    ]
                },
                {
                    name: 'priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Priority',
                    placeholder: 'Incident Priority',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident Priority is required'
                        }
                    ],
                    listData: this.priorityList
                }
            ],
            extraClass: 'integration-styling'
        };
        if (
            this.stepData['isPartner'] ||
            !this.stepData['configuredColumnData']
        ) {
            this.formgenInput.fields.unshift({
                name: 'shortDescription',
                fieldType: FilterType.TEXT,
                label: 'Short Description',
                placeholder: 'Short Description',
                required: true,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData'][
                              'shortDescription'
                          ]
                      ]
                    : '',
                disabled: this.stepData['isPartner'],
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Short description is required'
                    }
                ]
            });
        }
    }
    onCreate(buttonRef, formGroup) {
        if (formGroup.valid) {
            const inputs = { ...formGroup.getRawValue() };
            if (
                !this.stepData['isPartner'] &&
                this.stepData['configuredColumnData']
            ) {
                inputs['shortDescription'] = this.stepData[
                    'configuredColumnData'
                ]
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData'][
                              'shortDescription'
                          ]
                      ]
                    : '';
            }
            buttonRef.loader = true;
            const apiArgs: IHitApi = {
                url: ApiUrls.SERVICENOW_CREATE_INCIDENT,
                input: inputs,
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response) => {
                    this.notificationService.showSnackBar(
                        'Incident created successfully'
                    );
                    buttonRef.loader = false;
                },
                errorFunction: (error) => {
                    this.notificationService.showSnackBar(
                        error.error.message,
                        true
                    );
                    buttonRef.loader = false;
                }
            };

            new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
        }
    }
}
