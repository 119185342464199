import { Component, OnInit } from '@angular/core';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';

@Component({
    selector: 'app-severity-editor',
    templateUrl: './severity-editor.component.html',
    styleUrls: ['./severity-editor.component.sass']
})
export class SeverityEditorComponent implements OnInit {
    filterInfo: IFilterInfo;
    selectedValues: string[];
    defaultValues: string[];

    constructor() {}

    ngOnInit(): void {}
    agInit(params): void {
        this.filterInfo = {
            placeholder: 'Select Severity',
            listData: params['listData'],
            label: 'Severity',
            lightMode: true
        };
        this.defaultValues = params.value;
        this.selectedValues = params.value;
    }
    getValue() {
        if (this.selectedValues.length) {
            return this.selectedValues;
        } else {
            return this.defaultValues;
        }
    }
    selectionChange(data) {
        this.selectedValues = data;
    }
}
