<app-only-table-generator
    [tableInput]="tableGenInput"
    [tableData]="tableData"
></app-only-table-generator>
<ng-template #deleteConfiguration>
    <div class="tw-flex tw-py-2">
        <div class="tw-pr-2"><i class="fa fa-circle"></i></div>
        <div class="tw-font-bold">{{ confiName }}</div>
    </div>

    <div class="modal-info">
        <div class="info-icon">
            <i class="custom-fa-icon fa fa-info-circle"></i>
        </div>
        <div class="info-message">
            <p>
                Once deleted, the salesforce desk widget will also be deleted
                from all ACLs & Views.
            </p>
        </div>
    </div>
</ng-template>
