export enum IconType {
    FONTAWSOME = 'FONTAWESOME',
    MATICON = 'MATICON',
    IMAGE = 'IMAGE',
    LINELOADER = 'LINELOADER',
    SPINNERLOADER = 'SPINNERLOADER',
    SVG = 'SVG',
    SVG_ASSETS = 'SVG_ASSETS',
    NUMBER = 'NUMBER'
}

export function SupportedIconTypesForView(isAssessee?): IconType[] {
    if (isAssessee) {
        return [IconType.NUMBER];
    } else {
        return [
            IconType.FONTAWSOME,
            IconType.MATICON,
            IconType.IMAGE,
            IconType.SVG
        ];
    }
}

export function UINameOfIconType(iconType: IconType): string {
    switch (iconType) {
        case IconType.FONTAWSOME:
            return 'Font Awesome';
        case IconType.MATICON:
            return 'Material Icon';
        case IconType.IMAGE:
            return 'Image';
        case IconType.SVG:
            return 'SVG';
        case IconType.NUMBER:
            return 'NUMBER';
        default:
            return null;
    }
}

export function ListingInfoForIcon(iconType: IconType): string {
    switch (iconType) {
        case IconType.FONTAWSOME:
            return `
      <ol>
        <li>Visit the <a href="https://fontawesome.com/icons?d=gallery&m=free">icon gallery</a></li>
        <li>Click on the icon you want to apply.</li>
        <li>You will see something like <i class="...some text"></i></li>
        <li>Copy the text between double quotes of class.</li>
      </ol>
    `;
        case IconType.MATICON:
            return `
      <ol>
        <li>Visit the <a href="https://material.io/resources/icons/?style=baseline">icon gallery</a></li>
        <li>Click on the icon you want to apply.</li>
        <li>Copy the icon name.</li>
      </ol>
    `;
        default:
            return null;
    }
}
