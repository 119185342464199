<div class="tw-h-full tw-py-2 tw-px-4 report-third-step">
    <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        (selectedTabChange)="changeTabs($event.tab.textLabel)"
        mat-align-tabs="center"
    >
        <mat-tab [label]="Tab.DOWNLOAD_AND_MESSAGING">
            <div class="tw-flex tab-content download-and-messaging">
                <app-common-integrations-index
                    [initialSelection]="initialSelection"
                    [integrations]="[
                        IntegrationName.EMAIL,
                        IntegrationName.O365,
                        IntegrationName.GMAIL,
                        IntegrationName.SLACK
                    ]"
                    (selected)="integrationsChanged($event)"
                    (integrationInfoFunc)="getIntegrationData = $event"
                ></app-common-integrations-index>
                <div
                    class="
                        tw-flex-1 tw-py-3 tw-px-5 tw-overflow-auto
                        integration-data
                    "
                >
                    <!-- Download Report -->
                    <div class="download-report">
                        <app-form-generator
                            *ngIf="downloadReportFormGenInput"
                            [formGeneratorInput]="downloadReportFormGenInput"
                            (formGroupRef)="downloadReportFormGroup = $event"
                        ></app-form-generator>
                    </div>
                    <!-- Email -->
                    <div
                        class="email"
                        *ngIf="
                            selectedIntegrations.has(IntegrationName.EMAIL) ||
                            selectedIntegrations.has(IntegrationName.GMAIL) ||
                            selectedIntegrations.has(IntegrationName.O365)
                        "
                    >
                        <app-form-generator
                            *ngIf="sendEmailFormGenInput"
                            [formGeneratorInput]="sendEmailFormGenInput"
                            (formGroupRef)="sendEmailFormGroup = $event"
                        ></app-form-generator>
                    </div>

                    <!-- Slack -->
                    <div
                        class="slack"
                        *ngIf="
                            selectedIntegrations &&
                            selectedIntegrations.has(IntegrationName.SLACK)
                        "
                    >
                        <app-form-generator
                            *ngIf="slackFormGenInput"
                            [formGeneratorInput]="slackFormGenInput"
                            (formGroupRef)="slackFormGroup = $event"
                        ></app-form-generator>
                    </div>

                    <!-- O365 Handled From Email-->
                    <!-- Gmail Handled From Email-->
                </div>
            </div>
        </mat-tab>
        <mat-tab [disabled]="!enableSaveAndSchedule()">
            <ng-template mat-tab-label>
                <label
                    [class.disabled]="!enableSaveAndSchedule()"
                    class="labelHeading clickable"
                    [matTooltip]="
                        !enableSaveAndSchedule() ? SAVE_DISABLE_MESSAGE : null
                    "
                    >{{ Tab.SAVE_AND_SCHEDULE }}</label
                >
            </ng-template>
            <div class="tab-content save-and-schedule-content">
                <app-form-generator
                    *ngIf="reportFrequencyFormGenInput"
                    [formGeneratorInput]="reportFrequencyFormGenInput"
                    (formGroupRef)="reportFrequencyFormGroup = $event"
                ></app-form-generator>
                <ng-container
                    *ngIf="
                        reportFrequencyFormGroup &&
                        reportFrequencyFormGroup.get('reportFrequency')
                            .value === 'weekly'
                    "
                >
                    <app-form-generator
                        [formGeneratorInput]="receiveReportFormGenInput"
                        (formGroupRef)="receiveReportFormGroup = $event"
                    ></app-form-generator>
                </ng-container>
                <ng-container
                    *ngIf="
                        reportFrequencyFormGroup &&
                        reportFrequencyFormGroup.get('reportFrequency')
                            .value === 'custom'
                    "
                >
                    <app-form-generator
                        [formGeneratorInput]="billingDayFormGenInput"
                        (formGroupRef)="billingDayFormGroup = $event"
                    ></app-form-generator>
                </ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="tw-flex tw-justify-between">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>

        <div class="tw-flex">
            <app-button-generator
                *ngIf="
                    sendButton &&
                    ((selectedIntegrations.has(
                        IntegrationName.DOWNLOAD_REPORT
                    ) &&
                        selectedIntegrations.size > 1) ||
                        (!selectedIntegrations.has(
                            IntegrationName.DOWNLOAD_REPORT
                        ) &&
                            selectedIntegrations.size >= 1)) &&
                    selectedTab === Tab.DOWNLOAD_AND_MESSAGING
                "
                [buttonGenInput]="sendButton"
                [class.disabled]="sendLoader"
            ></app-button-generator>

            <app-button-generator
                *ngIf="
                    saveScheduleButton &&
                    selectedTab === Tab.DOWNLOAD_AND_MESSAGING
                "
                [class.disabled]="!enableSaveAndSchedule()"
                [buttonGenInput]="saveScheduleButton"
            ></app-button-generator>

            <app-button-generator
                *ngIf="
                    lastSaveScheduleButton &&
                    selectedTab === Tab.SAVE_AND_SCHEDULE
                "
                [class.disabled]="
                    (selectedTab === Tab.SAVE_AND_SCHEDULE && saveLoader) ||
                    !enableSaveAndSchedule()
                "
                [buttonGenInput]="lastSaveScheduleButton"
            ></app-button-generator>
        </div>
    </div>
</div>
