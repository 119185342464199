<form class="form">
    <div class="form-data">
        <app-form-generator
            [formGeneratorInput]="ruleNameFormGenInput"
            (formGroupRef)="ruleNameFormGroup = $event"
        ></app-form-generator>

        <ng-container *ngIf="widgetFilters && widgetFilters.length">
            <section *ngFor="let widgetFilter of widgetFilters">
                <div class="section-heading">
                    <div class="title">{{ widgetFilter.heading }}</div>
                </div>
                <div class="section-content">
                    <app-filters-handler
                        [hideLogicalOperatorRadioButtons]="
                            hideLogicalOperatorRadioButtons
                        "
                        [filterIds]="widgetFilter.filters"
                        [filterStoreKey]="FilterStoreKey.AUTOMATION_FILTERS"
                        [validateFilters]="validateFilters"
                        [invalidFilters]="invalidFilters"
                    ></app-filters-handler>
                </div>
            </section>
        </ng-container>
    </div>

    <div class="form-controls">
        <app-button-generator
            *ngIf="nextButton"
            [buttonGenInput]="nextButton"
        ></app-button-generator>
    </div>
</form>
