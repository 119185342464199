import { Component, Input, OnInit } from '@angular/core';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';

@Component({
    selector: 'app-assessment-info-bar',
    templateUrl: './assessment-info-bar.component.html',
    styleUrls: ['./assessment-info-bar.component.sass']
})
export class AssessmentInfoBarComponent implements OnInit {

    @Input() infoMessage: String;
    @Input() extraClass: String;
    @Input() displayIcon: boolean = true;
    
    infoIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'info_icon_blue',
        extraClass: 'icon-custom-style'
    }
    constructor() { }

    ngOnInit(): void {
    }

}
