<div [class.getFullAccessState]="graphData.liteState" class="tw-flex tw-h-full">
    <div class="tw-flex-f3 tw-relative chartdivMap">
        <div [id]="graphId" class="tw-h-full chartdivMapMain"></div>
        <div
            class="tw-absolute tw-bottom-2 tw-left-1/2 tw-flex tw-justify-center tw-items-center tw-flex-wrap tw-rounded-md tw-flex-row map-zoom-control"
        >
            <div
                class="home-button map-zoom-control-buttons"
                appIcon
                [data]="homeIcon"
                (click)="onZoomActionPerformed(GraphActions.Home)"
            ></div>
            <div class="map-zoom-control-button-divider"></div>
            <div
                class="map-zoom-control-buttons"
                appIcon
                [data]="zoomInIcon"
                (click)="onZoomActionPerformed(GraphActions.ZoomIn)"
            ></div>
            <div class="map-zoom-control-button-divider"></div>
            <div
                class="map-zoom-control-buttons"
                appIcon
                [data]="zoomOutIcon"
                (click)="onZoomActionPerformed(GraphActions.ZoomOut)"
            ></div>
        </div>
    </div>
    <ng-container *ngIf="graphData.visibleSections">
        <div
            [id]="legendId"
            class="tw-flex-1 legendDivMap tw-max-h-96 tw-overflow-y-auto"
            [style.display]="
                (graphData.visibleSections | async).has(ViewType.TABLE)
                    ? 'none'
                    : 'block'
            "
        ></div>
    </ng-container>
</div>
