import { Injectable } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CacheMain } from '../../classes/CacheMain';
import { Helper } from '../../classes/Helper';
import { HitApi } from '../../classes/HitApi';
import { Logger } from '../../classes/Logger';
import { Messages } from '../../classes/Messages';
import { Widget } from '../../classes/Widget';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { RequestType } from '../../enums/RequestType';
import { ILoginResponse } from '../../interfaces/api/portlets/ILoginResponse';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { ITestActionData } from '../../interfaces/test-action-data/ITestActionData';
import { IWidgetModifier } from '../../interfaces/widget/IWidgetModifier';
import { IWidgetUrlInfo } from '../../interfaces/widget/IWidgetUrlInfo';
import { CartCacheService } from '../cache/cart-cache/cart-cache.service';
import { WidgetHttpService } from '../http/widget-http/widget-http.service';
import { NotificationsService } from '../notifications/notifications.service';
import { UserDataCacheService } from '../user-data-cache/user-data-cache.service';
import { CredentialsCacheService } from './../cache/credentials-cache/credentials-cache.service';
import { ViewHttpService } from './../http/view-http/view-http.service';
import { SessionService } from './../session/session.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalDataService extends CacheMain {
    isProd: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loadWidgets: Subject<IWidgetUrlInfo> = new Subject();
    loadWidgetsForAssessee: Subject<IHitApi> = new Subject();
    routingData: BehaviorSubject<any> = new BehaviorSubject(null);
    isFrameworkPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    frameworkData: BehaviorSubject<any> = new BehaviorSubject(null);
    assessmentDataToFrameWork: BehaviorSubject<any> = new BehaviorSubject(null);
    returnToConsole: boolean = false;
    loadWidgetsFromApiArgs: BehaviorSubject<IHitApi> = new BehaviorSubject(
        null
    );
    allWidgetsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loadWidgetsFromWidgetId: BehaviorSubject<any> = new BehaviorSubject(null);
    handleLeafNode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    windowResizeEvent: Subject<void> = new Subject();
    widgetsModifier: Subject<Map<string | Symbol, IWidgetModifier>> =
        new Subject();

    fullPageLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);

    sideMenuStepOne;

    enteredUrlBeforeLogin: string[] =
        null; /*This array is used for storing the url(if entered) before login*/
    joinEnteredUrlBeforeLogin: string =
        null; /*This string is used to join the url stored in above array */
    invalidUrl: Subject<boolean> = new Subject();
    // This variable is used to store domainId and email for sso login
    ssoRedirectionCreds: { domainId: string; email: string };

    handleSelectedPath: BehaviorSubject<any> = new BehaviorSubject(null);

    toggleCommonModal: BehaviorSubject<Object> = new BehaviorSubject(false);
    testingActionsObs: Subject<ITestActionData> = new Subject();

    selectedView = null;
    sideNavToggle: BehaviorSubject<boolean> = new BehaviorSubject(false);
    updateViews: Subject<boolean> = new Subject();
    isHandset$: BehaviorSubject<boolean> = new BehaviorSubject(
        window.innerWidth <= 900
    );

    updateCartCount: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    signUp: BehaviorSubject<string> = new BehaviorSubject<string>('');
    toggleCart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    toggleCatalog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    updateSelectedWidgets: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    viaMicrosoftTeams: BehaviorSubject<boolean> = new BehaviorSubject(false); //this checks if app is opened inside Teams

    freshLogin = false;
    ssoAcceptLicenseCallBackFn: any;
    currentLeafNodeData = null;
    private readonly ROLES = 'ROLES';
    whiteLabelingPreviewData: any;
    assesseeCreds: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    beginsAssessmentLogin: boolean = false;
    lastActiveAssessmentTab = null;
    readonly previewModeQueryParam: string = '?previewMode=true';
    widgetIdsOnCurrentPage: string[] = [];
    refreshWidgetsForCache: Widget[] = [];
    createAssessmentOverview: boolean = false;
    assessmentStatus: BehaviorSubject<string> = new BehaviorSubject<string>('');
    breadcrumbData: BehaviorSubject<string[]> = new BehaviorSubject(null);
    domainIdRegion: string;

    constructor(private userDataCacheService: UserDataCacheService) {
        super(`REDIRECTION_CACHE`, true);
        this.intilializeWindowResizeEvent();
        this.initlaizeIsHandsetEvent();
    }

    set roles(value: Array<any>) {
        this.store(this.ROLES, value);
    }

    get roles() {
        return this.fetch(this.ROLES);
    }

    pageSwitch: Subject<{
        leafNodeData: Map<number, any>;
        switched: boolean;
    }> = new Subject();

    changeView(
        emailId: string,
        viewId: string,
        credentialsCacheService: CredentialsCacheService,
        viewHttpService: ViewHttpService,
        sessionService: SessionService,
        cartCacheService: CartCacheService
    ) {
        if (this.selectedView === viewId) {
            return;
        }
        const prevViewId = this.selectedView;
        this.selectedView = viewId;
        this.fullPageLoader.next(true);

        const token = credentialsCacheService.getViewToken(emailId, viewId);
        if (token) {
            viewHttpService.bearerToken = token;
            this.changeViewHandle(
                viewId,
                prevViewId,
                viewHttpService,
                sessionService
            );
        } else {
            const apiArgs: IHitApi = {
                url: `${ApiUrls.VIEW_TOKEN_API_PATH}${viewId}`,
                intactUrl: `${ApiUrls.VIEW_TOKEN_API_PATH}{viewId}`,
                requestType: RequestType.GET,
                input: {},
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response: ILoginResponse) => {
                    viewHttpService.bearerToken = response.tokens.bearerToken;
                    credentialsCacheService.saveViewToken(
                        emailId,
                        viewId,
                        response.tokens.bearerToken
                    );
                    setTimeout(() => {
                        this.changeViewHandle(
                            viewId,
                            prevViewId,
                            viewHttpService,
                            sessionService
                        );
                    }, 0);
                },
                errorFunction: (error) => {
                    Logger.warn(error);
                    this.selectedView = prevViewId;
                    window.location.reload();
                }
            };
            new HitApi(
                apiArgs,
                viewHttpService,
                viewHttpService.ngZone
            ).hitApi();
        }

        cartCacheService.deleteCollection();
    }

    changeViewHandle(
        viewId: string,
        prevViewId: string,
        viewHttpService: ViewHttpService,
        sessionService: SessionService,
        url?
    ) {
        viewHttpService.getSidebarDataForView(
            viewId,
            (response) => {
                sessionService.setSideMenuFirstLevelDataData(
                    viewId,
                    response,
                    true,
                    true
                );
            },
            (error) => {
                this.selectedView = prevViewId;
                window.location.reload();
            },
            url
        );
    }

    // login through sso with role
    roleBasedLogin(
        role,
        token,
        sessionService: SessionService,
        credentialsCacheService: CredentialsCacheService,
        notificationsService: NotificationsService,
        widgetHttpService: WidgetHttpService,
        viewHttpService: ViewHttpService,
        callBackFn?
    ) {
        new HitApi(
            {
                url: ApiUrls.SSO_ROLE_BASED_LOGIN,
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    defaultHeaders: {
                        samlauthorization: token
                    },
                    skipBypassRegion: true
                },
                input: role,
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                function: (response: ILoginResponse) => {
                    if (response && response.licenseAccepted) {
                        this.userLogin(
                            response,
                            sessionService,
                            credentialsCacheService,
                            notificationsService,
                            viewHttpService
                        );
                    } else if (response && !response.licenseAccepted) {
                        this.userDataCacheService.domainId = response.domain;
                        this.ssoAcceptLicenseCallBackFn(response, callBackFn);
                        this.fullPageLoader.next(false);
                    }
                },
                errorFunction: (error) => {
                    sessionService.logout();
                    this.fullPageLoader.next(false);
                    Helper.showErrorMessage(
                        notificationsService,
                        error,
                        Messages.LOGIN_API_INVALID_RESPONSE
                    );
                }
            },
            widgetHttpService,
            widgetHttpService.ngZone
        ).hitApi();
    }

    // Login through sso without role
    userLogin(
        response,
        sessionService: SessionService,
        credentialsCacheService: CredentialsCacheService,
        notificationsService: NotificationsService,
        viewHttpService: ViewHttpService
    ) {
        credentialsCacheService.masterLoginDiveIn(response);
        sessionService.login(response, viewHttpService, true, () => {
            Helper.showErrorMessage(
                notificationsService,
                null,
                Messages.LOGIN_API_INVALID_RESPONSE
            );
            credentialsCacheService.masterLoginDiveOut();
        });
    }

    intilializeWindowResizeEvent() {
        fromEvent(window, 'resize')
            .pipe(debounceTime(250))
            .subscribe((e) => {
                this.windowResizeEvent.next();
            });
    }

    initlaizeIsHandsetEvent() {
        fromEvent(window, 'resize')
            .pipe(
                map((e: any) =>
                    e.currentTarget.innerWidth <= 900 ? true : false
                )
            )
            .pipe(distinctUntilChanged())
            .subscribe((isHandset) => this.isHandset$.next(isHandset));
    }

    /**
     * Constructs the tooltip message based on the provided subsection information.
     * If the subsection is disabled and its icon is '3.1', the message indicates that
     * the section is disabled as the user has submitted Section 3.2. Otherwise, it
     * indicates that the section is disabled as the user has submitted Section 3.1.
     * @param subSection The subsection information used to construct the tooltip message.
     * @returns The constructed tooltip message.
     */
    getTooltipMessage(subSection: any): string {
        if (subSection['disabled'] && subSection['icon'].startsWith('3.1')) {
            return `This section is disabled as you have already submitted Section 3.2`;
        } else {
            return `This section is disabled as you have already submitted Section 3.1`;
        }
    }
}
