import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { Helper } from '../../classes/Helper';
import { DateFilterDropdownKeys } from '../../enums/DateFilterDropdownKeys';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { FilterType } from '../../enums/FilterType';
import { IFilterData } from '../../interfaces/filter/IFilterData';
import { IFiltersInput } from '../../interfaces/filter/IFilterInput';
import { IFilterSelection } from '../../interfaces/filter/IFilterSelection';
import { IMiscFilter } from '../../interfaces/filter/IMiscFilter';
import { IWidgetData } from '../../interfaces/widget/IWidgetData';
import { ConfigCacheService } from '../cache/config-cache/config-cache.service';
import { FilterCacheService } from '../cache/filters-cache/filter-cache.service';
import { UserDataCacheService } from '../user-data-cache/user-data-cache.service';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    // Filters On Page
    filtersPageData: BehaviorSubject<Map<string, Map<string, IFilterData>>> =
        new BehaviorSubject(null);
    filterMenuOpenState = new BehaviorSubject<boolean>(false);
    setFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject(false);
    widgetLinkingData: BehaviorSubject<Map<FilterStoreKey, object>> =
        new BehaviorSubject(null);

    // Restricted Filters
    restrictedFilters: BehaviorSubject<Map<string, Map<string, any>>> =
        new BehaviorSubject(null);

    dataStoreKey;

    // compare view filter
    selectorInternalKeys = {
        resourceTags: ['selectedResourceTagsKeys', 'selectedResourceTagsValue'],
        resourceTagsAzureCsp: [
            'selectedCspResourceTagsKeys',
            'selectedCspResourceTagsValue'
        ],
        resourceTagsAzureEa: [
            'selectedEaResourceTagsKeys',
            'selectedEaResourceTagsValue'
        ],
        resourceTagsAzurePlan: [
            'selectedPlanResourceTagsKeys',
            'selectedPlanResourceTagsValue'
        ]
    };

    // Filters Data
    // Data of filters stored on the basis of filterStoreKey
    filtersData: Map<string, Map<string, IFilterSelection>> = new Map();
    filtersDataTemp: Map<string, Map<string, IFilterSelection>> = new Map();
    filtersDefaultValue: Map<string, IFilterSelection> = new Map();
    filterChanged: string[] = [];
    refreshWidget = new Subject<Set<Symbol | string>>();
    resetFilters = new Subject<Set<Symbol>>();
    updateFilter = new Subject<Map<string, Map<string, any>>>();
    miscFilterList: Map<string, IMiscFilter> = new Map();
    selectedDateDropdownId: string;

    valueChanged: boolean = false;
    filtersFormData: Map<FilterStoreKey, Map<string, IFiltersInput>> =
        new Map();
    checkboxValue: Map<string, Map<string, boolean>> = new Map();
    currentPageFilterInfo: Map<string, IFilterInfo> = new Map();
    drawFilter: BehaviorSubject<FilterStoreKey> = new BehaviorSubject(null);
    currentFilterView;
    comapareViewValueChanged: boolean = false;
    isCompareViewEnabled: boolean = false;
    isFilerReset?: boolean = false;
    firstViewInitailized: boolean = false;
    constructor(
        private filterCacheService: FilterCacheService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService
    ) {}

    generateFiltersData(
        filterStoreKey: FilterStoreKey,
        widgetData: Map<string, IWidgetData>,
        sortedWidgetIds?: string[]
    ): void {
        const filtersData: Map<string, IFilterData> = new Map();

        const evalData = (widget: IWidgetData) => {
            if (
                widget &&
                widget.widgetInfo.filters &&
                widget.widgetInfo.filters.length
            ) {
                widget.widgetInfo.filters.forEach((filterId) => {
                    if (!filtersData.has(filterId)) {
                        const filterData: IFilterData = {
                            filterId,
                            associatedWidgets: []
                        };
                        filtersData.set(filterId, filterData);
                    }
                    filtersData.get(filterId).associatedWidgets.push({
                        widgetTitle: widget.widgetInfo.text,
                        widgetUniqueIdentifier: widget.widgetUniqueIdentifier
                    });
                });
            }
        };

        if (sortedWidgetIds) {
            for (const widgetId of sortedWidgetIds) {
                evalData(widgetData.get(widgetId));
            }
        } else {
            for (const widget of widgetData.values()) {
                evalData(widget);
            }
        }

        const filtersPageData = new Map(this.filtersPageData.getValue());
        filtersPageData.set(filterStoreKey, filtersData);
        this.filtersPageData.next(filtersPageData);
        this.drawFilter.next(filterStoreKey);
    }

    generateFiltersDataWithFilterIds(
        filterIds: string[]
    ): Map<string, IFilterData> {
        const filtersData: Map<string, IFilterData> = new Map();
        filterIds.forEach((filterId) => {
            const filterData: IFilterData = {
                filterId,
                associatedWidgets: []
            };
            filtersData.set(filterId, filterData);
        });
        return filtersData;
    }

    deleteCompleteStoreKey(storeKey: string) {
        const filtersPageData = new Map(this.filtersPageData.getValue());
        if (filtersPageData.has(storeKey)) {
            filtersPageData.delete(storeKey);
            this.filtersPageData.next(filtersPageData);
        }
    }

    convertTempToMain(filterKey, isCached) {
        this.filtersData.set(
            filterKey,
            new Map(this.filtersDataTemp.get(filterKey))
        );
        if (isCached) {
            this.filterCacheService.storeFilterData(
                this.userDataCache.emailId,
                this.configCache.viewId,
                filterKey,
                this.filtersData.get(filterKey)
            );
        }
    }

    assignWebPageFilters() {
        this.filtersData.set(FilterStoreKey.WEBSITE_FILTERS, new Map());
        this.filtersDataTemp.set(FilterStoreKey.WEBSITE_FILTERS, new Map());
        const pageFilters = this.filterCacheService.getFilterData(
            this.userDataCache.emailId,
            this.configCache.viewId,
            FilterStoreKey.WEBSITE_FILTERS
        );
        if (pageFilters && pageFilters instanceof Array) {
            pageFilters.forEach((element) => {
                this.filtersData
                    .get(FilterStoreKey.WEBSITE_FILTERS)
                    .set(element[0], element[1]);
                this.filtersDataTemp
                    .get(FilterStoreKey.WEBSITE_FILTERS)
                    .set(element[0], element[1]);
            });
        }
    }

    prepareInput(filterIds, filterStoreKey) {
        const input: Record<string, any> = {};
        const applicableCheckboxValues: Record<string, string[]> = {};
        if (filterIds) {
            filterIds.forEach((id) => {
                if (
                    this.filtersData.has(filterStoreKey) &&
                    this.filtersData.get(filterStoreKey).has(id)
                ) {
                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .type !== FilterType.CONTENT &&
                        !this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .parentSelector
                    ) {
                        input[
                            this.filtersData
                                .get(filterStoreKey)
                                .get(id).filterInfo.selector
                        ] = this.filtersData.get(filterStoreKey).get(id).value;
                    }

                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .type === FilterType.DATE_TIME_RANGE
                    ) {
                        const value = Helper.cloneDeep(
                            this.filtersData.get(filterStoreKey).get(id).value
                        );
                        value.startDate = `${new Date(
                            value.startDate
                        ).getTime()}`;
                        value.endDate = `${new Date(value.endDate).getTime()}`;
                        value.startTime = `${value.startTime}`;
                        value.endTime = `${value.endTime}`;
                        input[
                            this.filtersData
                                .get(filterStoreKey)
                                .get(id).filterInfo.selector
                        ] = value;
                    }

                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .type === FilterType.DATE_RANGE
                    ) {
                        const value = Helper.cloneDeep(
                            this.filtersData.get(filterStoreKey).get(id).value
                        );

                        if (value && value.id) {
                            const updateValue = (
                                sDateValue,
                                eDateValue,
                                ignoreInit = false
                            ) => {
                                if (!ignoreInit) {
                                    sDateValue.setDate(1);
                                    eDateValue.setDate(1);
                                    eDateValue.setHours(-1);
                                }
                                sDateValue.setHours(0, 0, 0);
                                eDateValue.setHours(23, 59, 59);
                                const sDateFormatted = Helper.formatDate(
                                    sDateValue,
                                    'YYYY-MM-DD HH:mm:ss'
                                );
                                const eDateFormatted = Helper.formatDate(
                                    eDateValue,
                                    'YYYY-MM-DD HH:mm:ss'
                                );
                                value.startDateTime = sDateFormatted;
                                value.endDateTime = eDateFormatted;
                            };
                            const sDateValue = new Date();
                            const eDateValue = new Date();
                            switch (value.id) {
                                case DateFilterDropdownKeys.LAST_WEEK:
                                case DateFilterDropdownKeys.LAST_15_DAYS:
                                    const finalDateObj = Helper.getDateTime(
                                        DateFilterDropdownKeys.LAST_15_DAYS ===
                                            value.id
                                            ? 15
                                            : 7,
                                        false
                                    );
                                    updateValue(
                                        finalDateObj.startDateTimeValue,
                                        finalDateObj.endDateTimeValue,
                                        true
                                    );
                                    break;
                                case DateFilterDropdownKeys.LAST_MONTH:
                                    sDateValue.setMonth(
                                        eDateValue.getMonth() - 1
                                    );
                                    updateValue(sDateValue, eDateValue);
                                    break;
                                case DateFilterDropdownKeys.LAST_THREE_MONTH:
                                    sDateValue.setMonth(
                                        eDateValue.getMonth() - 3
                                    );
                                    updateValue(sDateValue, eDateValue);
                                    break;
                                case DateFilterDropdownKeys.LAST_SIX_MONTH:
                                    sDateValue.setMonth(
                                        eDateValue.getMonth() - 6
                                    );
                                    updateValue(sDateValue, eDateValue);
                                    break;
                                case DateFilterDropdownKeys.LAST_QUARTER:
                                    const currentMonth = new Date().getMonth();
                                    let lastQuarterMonth = currentMonth - 3;
                                    if (lastQuarterMonth < 0) {
                                        lastQuarterMonth = 9;
                                    }
                                    switch (lastQuarterMonth) {
                                        case 0:
                                        case 1:
                                        case 2:
                                            sDateValue.setMonth(0);
                                            eDateValue.setMonth(3);
                                            updateValue(sDateValue, eDateValue);
                                            break;
                                        case 3:
                                        case 4:
                                        case 5:
                                            sDateValue.setMonth(3);
                                            eDateValue.setMonth(6);
                                            updateValue(sDateValue, eDateValue);
                                            break;
                                        case 6:
                                        case 7:
                                        case 8:
                                            sDateValue.setMonth(6);
                                            eDateValue.setMonth(9);
                                            updateValue(sDateValue, eDateValue);
                                            break;
                                        case 9:
                                        case 10:
                                        case 11:
                                            sDateValue.setMonth(9);
                                            eDateValue.setMonth(12);
                                            if (currentMonth <= 2) {
                                                sDateValue.setFullYear(
                                                    new Date().getFullYear() - 1
                                                );
                                                eDateValue.setFullYear(
                                                    new Date().getFullYear() - 1
                                                );
                                            }
                                            updateValue(sDateValue, eDateValue);
                                            break;
                                        default:
                                            break;
                                    }
                                default:
                                    break;
                            }
                            input[
                                this.filtersData
                                    .get(filterStoreKey)
                                    .get(id).filterInfo.selector
                            ] = value;
                        }
                    }

                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .checkboxSelector
                    ) {
                        if (
                            !applicableCheckboxValues[
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.checkboxSelector
                            ]
                        ) {
                            applicableCheckboxValues[
                                this.filtersData
                                    .get(filterStoreKey)
                                    .get(id).filterInfo.checkboxSelector
                            ] = [];
                        }
                        applicableCheckboxValues[
                            this.filtersData.get(filterStoreKey).get(id)
                                .filterInfo.checkboxSelector
                        ].push(
                            this.filtersData.get(filterStoreKey).get(id)
                                .filterInfo.checkboxValue
                        );
                    }

                    // Setting miscFilterList when page is refreshed/view Switched
                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .showCheckbox
                    ) {
                        if (
                            this.miscFilterList.has(
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.checkboxSelector
                            )
                        ) {
                            this.miscFilterList
                                .get(
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .filterInfo.checkboxSelector
                                )
                                .associatedFilterIds.push(id);
                        } else {
                            const data: IMiscFilter = {
                                selector: this.filtersData
                                    .get(filterStoreKey)
                                    .get(id).filterInfo.checkboxSelector,
                                associatedFilterIds: [id],
                                selectorType: FilterType.CHECKBOX
                            };
                            this.miscFilterList.set(
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.checkboxSelector,
                                data
                            );
                        }
                    }

                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .logicalOperatorEnable
                    ) {
                        if (
                            this.miscFilterList.has(
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.logicalOperatorRequestKey
                            )
                        ) {
                            this.miscFilterList
                                .get(
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .filterInfo.logicalOperatorRequestKey
                                )
                                .associatedFilterIds.push(id);
                        } else {
                            const data: IMiscFilter = {
                                selector: this.filtersData
                                    .get(filterStoreKey)
                                    .get(id).filterInfo
                                    .logicalOperatorRequestKey,
                                associatedFilterIds: [id],
                                selectorType: FilterType.RADIO
                            };
                            this.miscFilterList.set(
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.logicalOperatorRequestKey,
                                data
                            );
                        }
                    }

                    if (
                        this.filtersData.get(filterStoreKey).get(id).filterInfo
                            .type === FilterType.TEXT_WITH_DROPDOWN
                    ) {
                        const value = {
                            [this.filtersData.get(filterStoreKey).get(id)
                                .filterInfo.selector]: {
                                frequency:
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .value &&
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .value.frequency
                                        ? this.filtersData
                                              .get(filterStoreKey)
                                              .get(id).value.frequency
                                        : this.filtersData
                                              .get(filterStoreKey)
                                              .get(id).filterInfo.value
                                              .textValue,
                                dateRange:
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .value &&
                                    this.filtersData.get(filterStoreKey).get(id)
                                        .value.dateRange
                                        ? this.filtersData
                                              .get(filterStoreKey)
                                              .get(id).value.dateRange
                                        : this.filtersData
                                              .get(filterStoreKey)
                                              .get(id).filterInfo.value
                                              .dropdownValue
                            }
                        };

                        const previousValue =
                            input[
                                this.filtersData.get(filterStoreKey).get(id)
                                    .filterInfo.parentSelector
                            ];

                        input[
                            this.filtersData
                                .get(filterStoreKey)
                                .get(id).filterInfo.parentSelector
                        ] = Object.assign(previousValue ?? {}, value);
                    }
                }
            });
        }
        for (const [key, value] of this.miscFilterList.entries()) {
            value.associatedFilterIds.forEach((id) => {
                if (filterIds && filterIds.includes(id)) {
                    if (
                        this.filtersData.has(filterStoreKey) &&
                        this.filtersData.get(filterStoreKey) &&
                        this.filtersData.get(filterStoreKey).has(key) &&
                        this.filtersData.get(filterStoreKey).get(key)
                    ) {
                        if (
                            applicableCheckboxValues[key] &&
                            Array.isArray(
                                this.filtersData.get(filterStoreKey).get(key)
                                    .value
                            )
                        ) {
                            input[key] = [];
                            this.filtersData
                                .get(filterStoreKey)
                                .get(key)
                                .value.forEach((element) => {
                                    if (
                                        applicableCheckboxValues[key].includes(
                                            element
                                        )
                                    ) {
                                        input[key].push(element);
                                    }
                                });
                        } else {
                            input[key] = this.filtersData
                                .get(filterStoreKey)
                                .get(key).value;
                        }
                    }
                }
            });
        }
        if ('othersCostAllocation' in input) {
            input['groupingForAccountId'] =
                input['othersCostAllocation'].includes('AccountIds');
            input['groupingForRegion'] =
                input['othersCostAllocation'].includes('Regions');
            input['groupingForService'] =
                input['othersCostAllocation'].includes('Services');
        }

        if ('groupingForResourceGroupPlan' in input) {
            input['groupingForResourceGroupPlan'] =
                input['groupingForResourceGroupPlan'].includes(
                    'ResourceGroups'
                );
        }
        if ('groupingForResourceGroupEa' in input) {
            input['groupingForResourceGroupEa'] =
                input['groupingForResourceGroupEa'].includes('ResourceGroups');
        }
        if ('groupingForDepartmentId' in input) {
            input['groupingForDepartmentId'] =
                input['groupingForDepartmentId'].includes('DepartmentIds');
        }
        if ('groupingForResourceGroupCsp' in input) {
            input['groupingForResourceGroupCsp'] =
                input['groupingForResourceGroupCsp'].includes('ResourceGroups');
        }

        if ('groupingForResourceGroupMca' in input) {
            input['groupingForResourceGroupMca'] =
                input['groupingForResourceGroupMca'].includes('ResourceGroups');
        }

        if ('othersCostAllocationAzureCsp' in input) {
            input['groupingForSubscriptionIdAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Regions');
            input['groupingForServiceAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Services');
        }

        if ('othersCostAllocationAzurePlan' in input) {
            input['groupingForSubscriptionIdAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Regions');
            input['groupingForServiceAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Services');
        }
        if ('othersCostAllocationAzureEa' in input) {
            input['groupingForSubscriptionIdAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Regions');
            input['groupingForServiceAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Services');
            input['groupingForCustomerNameAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('CustomerNames');
            input['groupingForEnrollmentNumberAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'EnrollmentNumbers'
                );
        }

        if ('groupingForWidgetCatalog' in input) {
            input['groupingForWidgetTags'] =
                input['groupingForWidgetCatalog'].includes('widgetTags');
            input['groupingForWidgetType'] =
                input['groupingForWidgetCatalog'].includes('widgetType');
        }
        if ('othersCostAllocationAzureMca' in input) {
            input['groupingForSubscriptionIdAzureMca'] =
                input['othersCostAllocationAzureMca'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureMca'] =
                input['othersCostAllocationAzureMca'].includes('Regions');
            input['groupingForServiceAzureMca'] =
                input['othersCostAllocationAzureMca'].includes('Services');
            input['groupingForBillingProfileIdAzureMca'] =
                input['othersCostAllocationAzureMca'].includes(
                    'BillingProfileIds'
                );
        }

        if ('groupingForStorageAccountNamePlan' in input) {
            input['groupingForStorageAccountNamePlan'] =
                input['groupingForStorageAccountNamePlan'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameEa' in input) {
            input['groupingForStorageAccountNameEa'] =
                input['groupingForStorageAccountNameEa'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameMca' in input) {
            input['groupingForStorageAccountNameMca'] =
                input['groupingForStorageAccountNameMca'].includes(
                    'StorageAccountNames'
                );
        }

        if ('tagsGroupBy' in input) {
            input['tagsGroupBy'] =
                input['tagsGroupBy'] && input['tagsGroupBy'].length
                    ? true
                    : false;
        }
        return input;
    }

    prepareInputWithFilterIds(filterIds, filterStoreKey) {
        const input: Record<string, any> = {};
        if (filterIds) {
            filterIds.forEach((id) => {
                if (
                    this.filtersData.has(filterStoreKey) &&
                    this.filtersData.get(filterStoreKey).has(id)
                ) {
                    if (this.filtersData.get(filterStoreKey).get(id).value) {
                        input[id] = this.filtersData
                            .get(filterStoreKey)
                            .get(id).value;
                    }
                }
            });
        }

        for (const [key, value] of this.miscFilterList.entries()) {
            value.associatedFilterIds.forEach((id) => {
                if (filterIds && filterIds.includes(id)) {
                    if (
                        this.filtersData.has(filterStoreKey) &&
                        this.filtersData.get(filterStoreKey) &&
                        this.filtersData.get(filterStoreKey).has(key) &&
                        this.filtersData.get(filterStoreKey).get(key)
                    ) {
                        input[id] = this.filtersData
                            .get(filterStoreKey)
                            .get(key).value;
                    }
                }
            });
        }
        if ('othersCostAllocation' in input) {
            input['groupingForAccountId'] =
                input['othersCostAllocation'].includes('AccountIds');
            input['groupingForRegion'] =
                input['othersCostAllocation'].includes('Regions');
            input['groupingForService'] =
                input['othersCostAllocation'].includes('Services');
        }

        if ('groupingForResourceGroupPlan' in input) {
            input['groupingForResourceGroupPlan'] =
                input['groupingForResourceGroupPlan'].includes(
                    'ResourceGroups'
                );
        }
        if ('groupingForResourceGroupEa' in input) {
            input['groupingForResourceGroupEa'] =
                input['groupingForResourceGroupEa'].includes('ResourceGroups');
        }
        if ('groupingForResourceGroupCsp' in input) {
            input['groupingForResourceGroupCsp'] =
                input['groupingForResourceGroupCsp'].includes('ResourceGroups');
        }
        if ('othersCostAllocationAzureCsp' in input) {
            input['groupingForSubscriptionIdAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Regions');
            input['groupingForServiceAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Services');
        }

        if ('othersCostAllocationAzurePlan' in input) {
            input['groupingForSubscriptionIdAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Regions');
            input['groupingForServiceAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Services');
        }
        if ('othersCostAllocationAzureEa' in input) {
            input['groupingForSubscriptionIdAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Regions');
            input['groupingForServiceAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Services');
            input['groupingForCustomerNameAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('CustomerNames');
            input['groupingForEnrollmentNumberAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'EnrollmentNumbers'
                );
        }

        if ('groupingForStorageAccountNamePlan' in input) {
            input['groupingForStorageAccountNamePlan'] =
                input['groupingForStorageAccountNamePlan'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameEa' in input) {
            input['groupingForStorageAccountNameEa'] =
                input['groupingForStorageAccountNameEa'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameMca' in input) {
            input['groupingForStorageAccountNameMca'] =
                input['groupingForStorageAccountNameMca'].includes(
                    'StorageAccountNames'
                );
        }

        return input;
    }
    prepareFiltersInputs(filterIds: string[], filterStoreKey: FilterStoreKey) {
        const input: Record<string, any> = {};
        const applicableCheckboxValues: Record<string, string[]> = {};
        if (filterIds && filterIds.length) {
            filterIds.forEach((filterId: string) => {
                const filterInput = this.filterCacheService.getFiltersInfo(
                    this.userDataCache.emailId,
                    this.configCache.viewId,
                    filterStoreKey
                );
                if (
                    this.filtersFormData.has(filterStoreKey) &&
                    this.filtersFormData.get(filterStoreKey).get(filterId) &&
                    this.filtersFormData.get(filterStoreKey).get(filterId)
                        .selector === 'frequencyofBreach'
                ) {
                    return;
                }
                const selector = this.filtersFormData
                    .get(filterStoreKey)
                    ?.get(filterId)?.selector;
                const value = this.filtersFormData
                    .get(filterStoreKey)
                    ?.get(filterId)?.value;
                input[selector] = value;
                if (!input[selector] && filterInput && filterInput[filterId]) {
                    const label = filterInput[filterId].filterInfo?.selector;
                    const value = filterInput[filterId]?.value;
                    input[label] = value;
                }
                if (
                    filterInput &&
                    filterInput[filterId] &&
                    filterInput[filterId].filterInfo &&
                    filterInput[filterId].filterInfo.type ===
                        FilterType.DATE_RANGE
                ) {
                    const value = Helper.cloneDeep(
                        filterInput[filterId]?.value
                    );

                    if (value && value.id) {
                        const updateValue = (
                            sDateValue,
                            eDateValue,
                            ignoreInit = false
                        ) => {
                            if (!ignoreInit) {
                                sDateValue.setDate(1);
                                eDateValue.setDate(1);
                                eDateValue.setHours(-1);
                            }
                            sDateValue.setHours(0, 0, 0);
                            eDateValue.setHours(23, 59, 59);
                            const sDateFormatted = Helper.formatDate(
                                sDateValue,
                                'YYYY-MM-DD HH:mm:ss'
                            );
                            const eDateFormatted = Helper.formatDate(
                                eDateValue,
                                'YYYY-MM-DD HH:mm:ss'
                            );
                            value.startDateTime = sDateFormatted;
                            value.endDateTime = eDateFormatted;
                        };
                        const sDateValue = new Date();
                        const eDateValue = new Date();
                        switch (value.id) {
                            case DateFilterDropdownKeys.LAST_WEEK:
                            case DateFilterDropdownKeys.LAST_15_DAYS:
                                const finalDateObj = Helper.getDateTime(
                                    DateFilterDropdownKeys.LAST_15_DAYS ===
                                        value.id
                                        ? 15
                                        : 7,
                                    false
                                );
                                updateValue(
                                    finalDateObj.startDateTimeValue,
                                    finalDateObj.endDateTimeValue,
                                    true
                                );
                                break;
                            case DateFilterDropdownKeys.LAST_MONTH:
                                sDateValue.setMonth(eDateValue.getMonth() - 1);
                                updateValue(sDateValue, eDateValue);
                                break;
                            case DateFilterDropdownKeys.LAST_THREE_MONTH:
                                sDateValue.setDate(1);
                                sDateValue.setMonth(eDateValue.getMonth() - 3);
                                updateValue(sDateValue, eDateValue);
                                break;
                            case DateFilterDropdownKeys.LAST_SIX_MONTH:
                                sDateValue.setDate(1);
                                sDateValue.setMonth(eDateValue.getMonth() - 6);
                                updateValue(sDateValue, eDateValue);
                                break;
                            case DateFilterDropdownKeys.LAST_QUARTER:
                                const currentMonth = new Date().getMonth();
                                let lastQuarterMonth = currentMonth - 3;
                                if (lastQuarterMonth < 0) {
                                    lastQuarterMonth = 9;
                                }
                                switch (lastQuarterMonth) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        sDateValue.setMonth(0);
                                        eDateValue.setMonth(3);
                                        updateValue(sDateValue, eDateValue);
                                        break;
                                    case 3:
                                    case 4:
                                    case 5:
                                        sDateValue.setMonth(3);
                                        eDateValue.setMonth(6);
                                        updateValue(sDateValue, eDateValue);
                                        break;
                                    case 6:
                                    case 7:
                                    case 8:
                                        sDateValue.setMonth(6);
                                        eDateValue.setMonth(9);
                                        updateValue(sDateValue, eDateValue);
                                        break;
                                    case 9:
                                    case 10:
                                    case 11:
                                        sDateValue.setMonth(9);
                                        eDateValue.setMonth(12);
                                        if (currentMonth <= 2) {
                                            sDateValue.setFullYear(
                                                new Date().getFullYear() - 1
                                            );
                                            eDateValue.setFullYear(
                                                new Date().getFullYear() - 1
                                            );
                                        }
                                        updateValue(sDateValue, eDateValue);
                                        break;
                                    default:
                                        break;
                                }
                            default:
                                break;
                        }
                        input[filterInput[filterId].filterInfo?.selector] =
                            value;
                    }
                }
                if (
                    (filterInput &&
                        filterInput[filterId] &&
                        filterInput[filterId]['isChecked'] &&
                        filterInput[filterId]?.filterInfo?.checkboxSelector) ||
                    (filterInput &&
                        filterInput[filterId] &&
                        filterInput[filterId].filterInfo &&
                        filterInput[filterId].filterInfo.checkboxSelector &&
                        filterInput[
                            filterInput[filterId].filterInfo.checkboxSelector
                        ] &&
                        filterInput[
                            filterInput[filterId].filterInfo.checkboxSelector
                        ].value &&
                        filterInput[
                            filterInput[filterId].filterInfo.checkboxSelector
                        ].value.includes(
                            filterInput[filterId].filterInfo.checkboxValue
                        ))
                ) {
                    if (
                        !applicableCheckboxValues[
                            filterInput[filterId].filterInfo.checkboxSelector
                        ]
                    ) {
                        applicableCheckboxValues[
                            filterInput[filterId].filterInfo.checkboxSelector
                        ] = [];
                    }
                    applicableCheckboxValues[
                        filterInput[filterId].filterInfo.checkboxSelector
                    ].push(filterInput[filterId].filterInfo.checkboxValue);
                }
                if (
                    filterInput &&
                    filterInput[filterId]?.filterInfo?.logicalOperatorEnable &&
                    filterInput[filterId]?.filterInfo?.['logicalOperatorValue']
                ) {
                    input[
                        filterInput[
                            filterId
                        ].filterInfo.logicalOperatorRequestKey
                    ] =
                        filterInput[filterId].filterInfo[
                            'logicalOperatorValue'
                        ];
                }

                if (
                    filterInput &&
                    filterInput[filterId] &&
                    filterInput[filterId].filterInfo &&
                    filterInput[filterId].filterInfo.parentSelector
                ) {
                    const parentSelector =
                        filterInput[filterId].filterInfo.parentSelector;
                    const existindChildSelector =
                        filterInput[filterId].filterInfo.selector;
                    const childSelectorValue = filterInput[filterId].value;
                    if (!input[parentSelector]) {
                        input[parentSelector] = {};
                        input[parentSelector][existindChildSelector] =
                            childSelectorValue;
                    } else {
                        const newInput = {};
                        newInput[existindChildSelector] = childSelectorValue;
                        input[parentSelector] = {
                            ...input[parentSelector],
                            ...newInput
                        };
                    }
                    delete input[existindChildSelector];
                }
            });
        }
        Object.keys(applicableCheckboxValues).forEach((ele) => {
            input[ele] = applicableCheckboxValues[ele];
        });

        if ('othersCostAllocation' in input) {
            input['groupingForAccountId'] =
                input['othersCostAllocation'].includes('AccountIds');
            input['groupingForRegion'] =
                input['othersCostAllocation'].includes('Regions');
            input['groupingForService'] =
                input['othersCostAllocation'].includes('Services');
        }

        if ('groupingForResourceGroupPlan' in input) {
            input['groupingForResourceGroupPlan'] =
                input['groupingForResourceGroupPlan'].includes(
                    'ResourceGroups'
                );
        }
        if ('groupingForResourceGroupEa' in input) {
            input['groupingForResourceGroupEa'] =
                input['groupingForResourceGroupEa'].includes('ResourceGroups');
        }
        if ('groupingForDepartmentId' in input) {
            input['groupingForDepartmentId'] =
                input['groupingForDepartmentId'].includes('DepartmentIds');
        }
        if ('groupingForResourceGroupCsp' in input) {
            input['groupingForResourceGroupCsp'] =
                input['groupingForResourceGroupCsp'].includes('ResourceGroups');
        }

        if ('groupingForResourceGroupMca' in input) {
            input['groupingForResourceGroupMca'] =
                input['groupingForResourceGroupMca'].includes('ResourceGroups');
        }

        if ('groupingForResourceGroupSponsorship' in input) {
            input['groupingForResourceGroupSponsorship'] =
                input['groupingForResourceGroupSponsorship'].includes(
                    'ResourceGroups'
                );
        }
        if ('groupingForResourceGroupPayAsYouGo' in input) {
            input['groupingForResourceGroupPayAsYouGo'] =
                input['groupingForResourceGroupPayAsYouGo'].includes(
                    'ResourceGroups'
                );
        }

        if ('othersCostAllocationAzureCsp' in input) {
            input['groupingForSubscriptionIdAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Regions');
            input['groupingForServiceAzureCsp'] =
                input['othersCostAllocationAzureCsp'].includes('Services');
        }

        if ('othersCostAllocationAzurePlan' in input) {
            input['groupingForSubscriptionIdAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Regions');
            input['groupingForServiceAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Services');
            input['groupingForCustomerIdAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('CustomerIds');
            input['groupingForProviderAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('Provider');
            input['groupingForPartnerIdAzurePlan'] =
                input['othersCostAllocationAzurePlan'].includes('PartnerIds');
        }
        if ('othersCostAllocationAzureEa' in input) {
            input['groupingForSubscriptionIdAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Regions');
            input['groupingForServiceAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('Services');
            input['groupingForCustomerNameAzureEa'] =
                input['othersCostAllocationAzureEa'].includes('CustomerNames');
            input['groupingForEnrollmentNumberAzureEa'] =
                input['othersCostAllocationAzureEa'].includes(
                    'EnrollmentNumbers'
                );
        }

        if ('groupingForWidgetCatalog' in input) {
            input['groupingForWidgetTags'] =
                input['groupingForWidgetCatalog'].includes('widgetTags');
            input['groupingForWidgetType'] =
                input['groupingForWidgetCatalog'].includes('widgetType');
        }
        if ('othersCostAllocationAzureMca' in input) {
            input['groupingForSubscriptionIdAzureMca'] =
                input['othersCostAllocationAzureMca'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureMca'] =
                input['othersCostAllocationAzureMca'].includes('Regions');
            input['groupingForServiceAzureMca'] =
                input['othersCostAllocationAzureMca'].includes('Services');
            input['groupingForBillingProfileIdAzureMca'] =
                input['othersCostAllocationAzureMca'].includes(
                    'BillingProfileIds'
                );
        }
        if ('othersCostAllocationAzureSponsorship' in input) {
            input['groupingForSubscriptionIdAzureSponsorship'] =
                input['othersCostAllocationAzureSponsorship'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzureSponsorship'] =
                input['othersCostAllocationAzureSponsorship'].includes(
                    'Regions'
                );
            input['groupingForServiceAzureSponsorship'] =
                input['othersCostAllocationAzureSponsorship'].includes(
                    'Services'
                );
        }
        if ('othersCostAllocationAzurePayAsYouGo' in input) {
            input['groupingForSubscriptionIdAzurePayAsYouGo'] =
                input['othersCostAllocationAzurePayAsYouGo'].includes(
                    'SubscriptionIds'
                );
            input['groupingForRegionAzurePayAsYouGo'] =
                input['othersCostAllocationAzurePayAsYouGo'].includes(
                    'Regions'
                );
            input['groupingForServiceAzurePayAsYouGo'] =
                input['othersCostAllocationAzurePayAsYouGo'].includes(
                    'Services'
                );
        }

        if ('groupingForStorageAccountNamePlan' in input) {
            input['groupingForStorageAccountNamePlan'] =
                input['groupingForStorageAccountNamePlan'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameEa' in input) {
            input['groupingForStorageAccountNameEa'] =
                input['groupingForStorageAccountNameEa'].includes(
                    'StorageAccountNames'
                );
        }
        if ('groupingForStorageAccountNameMca' in input) {
            input['groupingForStorageAccountNameMca'] =
                input['groupingForStorageAccountNameMca'].includes(
                    'StorageAccountNames'
                );
        }

        if ('tagsGroupBy' in input) {
            input['tagsGroupBy'] =
                input['tagsGroupBy'] && input['tagsGroupBy'].length
                    ? true
                    : false;
        }
        return input;
    }
}
