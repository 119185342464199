<div class="field tw-flex tw-p-2 tw-overflow-auto tw-w-full tw-flex-col">
    <label
        *ngFor="let varr of stepData['data']"
        class="tw-flex tw-w-full tw-items-center tw-p-1"
    >
        <span class="tw-flex-f3 tw-font-semibold tw-mr-2">{{
            varr["label"]
        }}</span>

        <span class="tw-flex-f7">
            <ng-container *ngIf="varr['type'] === 'text'">
                <input
                    class="tw-w-full tw-outline-none"
                    [placeholder]="'Select ' + varr['label']"
                    type="text"
                    (keyup)="variableResp[varr['id']] = $event.target.value"
                />
            </ng-container>

            <ng-container *ngIf="varr['type'] === 'textarea'">
                <textarea
                    class="tw-w-full tw-outline-none"
                    [placeholder]="'Select ' + varr['label']"
                    (keyup)="variableResp[varr['id']] = $event.target.value"
                ></textarea>
            </ng-container>

            <ng-container *ngIf="varr['type'] === 'singe_select_dropdown'">
                <app-single-select-dropdown
                    [filterInfo]="{
                        listData: varr['values'],
                        placeholder: 'Select ' + varr['label'],
                        lightMode: true
                    }"
                    (change)="variableResp[varr['id']] = $event"
                ></app-single-select-dropdown>
            </ng-container>
        </span>
    </label>
</div>

<div
    class="controls tw-absolute tw-bottom-2 tw-right-0 tw-left-0 tw-flex tw-justify-end tw-items-center tw-h-10"
>
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
