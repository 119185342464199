import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ExpandableTableMoreInfoModalComponent } from '../../../modal-templates/expandable-table/expandable-table-more-info-modal/expandable-table-more-info-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-expandable-table-v2',
    templateUrl: './expandable-table-v2.component.html',
    styleUrls: ['./expandable-table-v2.component.sass']
})
export class ExpandableTableV2Component implements OnInit {
    widgetRef: Widget;
    columnNamesToShow = [];
    tableGenInput: ITableGeneratorInput = null;
    lightState: boolean;
    loader: boolean = false;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;

        if (this.widgetRef.lightState) {
            this.lightState = true;
        }
    }

    ngOnInit(): void {
        this.tableGenInput = {
            rowsToShow: 4,
            columns: [
                {
                    columnKey: 'moreInfo',
                    columnName: 'Action',
                    pinned: 'right',
                    filter: false,
                    minWidth: 120,
                    headerClass: 'header-center',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        if (!rowData['buttonGenInputs']) {
                            rowData['buttonGenInputs'] = [];
                        }
                        const fixButton: IButtonGeneratorInput = {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonRef: IButtonGeneratorInput) => {
                                if (
                                    this.widgetRef.widgetData &&
                                    this.widgetRef.widgetData.widgetInfo &&
                                    this.widgetRef.widgetData.widgetInfo[
                                        'additionalApisForWidget'
                                    ] &&
                                    this.widgetRef.widgetData.widgetInfo[
                                        'additionalApisForWidget'
                                    ]['moreInfoInventory']
                                ) {
                                    const apiInfo =
                                        this.widgetRef.widgetData.widgetInfo[
                                            'additionalApisForWidget'
                                        ]['moreInfoInventory'];

                                    this.moreInfoClicked(
                                        rowData,
                                        apiInfo,
                                        buttonRef
                                    );
                                } else {
                                    this.openMoreInfoModal(rowData.data);
                                }
                            }
                        };
                        rowData['buttonGenInputs'].push(fixButton);
                        return rowData;
                    }
                }
            ],
            columnExtraction: {
                type: 'NESTED',
                nestedKey: 'dataMap|keysToShow'
            },
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            handleAttentionRequired: true,
            showViewIcon: true
        };
    }

    moreInfoClicked(
        rowData,
        apiInfo: IApiInfo,
        buttonRef: IButtonGeneratorInput
    ) {
        if (this.loader) {
            return;
        }
        buttonRef.loader = true;
        this.loader = true;
        const args: IHitApi = Helper.generateHitApiConfig(apiInfo);
        args.input = rowData.data;
        args.function = (response) => {
            this.loader = false;
            buttonRef.loader = false;
            const moreInfoData = Object.keys(response);
            if (moreInfoData && moreInfoData.length) {
                this.openMoreInfoModal(response);
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'No Data Available.',
                    true
                );
            }
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        args.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while fetching the data.'
            );
            buttonRef.loader = false;
            this.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };

        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    openMoreInfoModal(rowData) {
        Object.keys(rowData).forEach((data) => {
            this.columnNamesToShow.push(data.toLowerCase().trim());
        });

        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-info'
        };

        const modalData: IModalData = {
            modalName: 'More Information',
            modalIcon: moreInfoIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noHeader: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ExpandableTableMoreInfoModalComponent,
                        payload: {
                            data: {
                                modalData: rowData['modalData']
                                    ? rowData['modalData']
                                    : rowData,
                                containsModalData: rowData['modalData']
                                    ? true
                                    : false,
                                visibleKeys: this.lightState
                                    ? this.columnNamesToShow
                                    : []
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ],
            noStepPadding: true
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
