import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IGraphData } from '../../../interfaces/graph/IGraphData';
import { GraphType } from './../../../enums/GraphType';
import { ModalService } from './../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-auto-recommendation-modal',
    templateUrl: './auto-recommendation-modal.component.html',
    styleUrls: ['./auto-recommendation-modal.component.sass']
})
export class AutoRecommendationModalComponent implements OnInit, AfterViewInit {
    data;
    objectKeys = Object.keys;
    widgetRef: Widget;
    stackBarGraph: IGraphData;
    generalInformation;
    tableData = {};
    tags = [];
    legendData;

    weekdays = [];
    rules = [];
    sassValue = getComputedStyle(document.body);

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.data = modalData.data;
        this.widgetRef = modalData.data.widget;
        this.initializeLegends();
    }

    initializeLegends() {
        this.legendData = [
            {
                name: 'Off',
                fill: Helper.convertToHexIfHsl(
                    this.sassValue.getPropertyValue('--xyChartLegendOff')
                )
            },
            {
                name: 'Optimal',
                fill: Helper.convertToHexIfHsl(
                    this.sassValue.getPropertyValue('--xyChartLegendsOptimal')
                )
            },
            {
                name: 'Underuse',
                fill: Helper.convertToHexIfHsl(
                    this.sassValue.getPropertyValue('--xyChartLegendsUnderuse')
                )
            }
        ];
    }

    ngOnInit(): void {
        this.weekdays = Object.keys(this.data.card.graphData);
        this.generalInformation = this.data.card.generalInfo;
        this.tableData = this.data.card.tableData.tableData;
        this.rules = this.data.card.tableData.maxRules;

        Object.entries(this.data.card.generalInfo).forEach(([key, value]) => {
            if (this.isArray(value)) {
                this.tags = [...(value as Array<any>)];
            }
        });
    }

    ngAfterViewInit() {
        this.prepareGraphData();
    }

    getFullAccess() {
        Helper.getFullAccess(
            this.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    prepareGraphData() {
        const requiredData = [];
        let obj1 = {};

        Object.keys(this.data.card.graphData).forEach((day) => {
            // suppose tuesday

            Object.keys(this.data.card.graphData[day]).forEach(
                (date, index) => {
                    // suppose 2
                    obj1 = {
                        name: day,
                        startTime: date,
                        endTime: Object.keys(this.data.card.graphData[day])[
                            index + 1
                        ]
                            ? Object.keys(this.data.card.graphData[day])[
                                  index + 1
                              ]
                            : (
                                  +Object.keys(this.data.card.graphData[day])[
                                      index
                                  ] + 1
                              ).toString(),
                        color:
                            this.data.card.graphData[day][date] === 'Optimal'
                                ? this.legendData[1].fill
                                : this.data.card.graphData[day][date] === 'Off'
                                ? this.legendData[0].fill
                                : this.data.card.graphData[day][date] ===
                                  'Underuse'
                                ? this.legendData[2].fill
                                : Helper.convertToHexIfHsl(
                                      this.sassValue.getPropertyValue(
                                          '--commonBgColor'
                                      )
                                  )
                    };

                    if (
                        !Object.entries(this.data.card.tableData.tableData[day])
                            .length
                    ) {
                        const obj = JSON.parse(JSON.stringify(obj1));
                        obj.ruleName = 'No Rule Applicable';
                        obj.duration = '';
                        requiredData.push(obj);
                    } else {
                        Object.entries(
                            this.data.card.tableData.tableData[day]
                        ).forEach(([key, value]) => {
                            let startTime = '';
                            if (
                                value['Instance Start Time (in hours)'] ===
                                '-EndOfDay_Warning-'
                            ) {
                                startTime = '24';
                            } else {
                                startTime =
                                    value['Instance Start Time (in hours)'];
                            }

                            if (
                                +date <=
                                    +value['Instance Stop Time (in hours)'] ||
                                +date >= +startTime
                            ) {
                                const obj = JSON.parse(JSON.stringify(obj1));
                                obj['ruleName'] = 'No Rule Applicable';
                                obj['duration'] = '';
                                requiredData.push(obj);
                            }
                        });
                        Object.entries(
                            this.data.card.tableData.tableData[day]
                        ).forEach(([key, value]) => {
                            let startTime = '';
                            if (
                                value['Instance Start Time (in hours)'] ===
                                '-EndOfDay_Warning-'
                            ) {
                                startTime = '24';
                            } else {
                                startTime =
                                    value['Instance Start Time (in hours)'];
                            }
                            if (
                                +date >=
                                    +value['Instance Stop Time (in hours)'] &&
                                +date < +startTime
                            ) {
                                const obj = JSON.parse(JSON.stringify(obj1));
                                obj['ruleName'] = key;
                                if (
                                    value['Instance Start Time (in hours)'] ===
                                    '-EndOfDay_Warning-'
                                ) {
                                    obj['duration'] =
                                        'Duration Time: From ' +
                                        value['Instance Stop Time (in hours)'] +
                                        ' to -';
                                } else {
                                    obj['duration'] =
                                        'Duration Time: From ' +
                                        value['Instance Stop Time (in hours)'] +
                                        ' to ' +
                                        value['Instance Start Time (in hours)'];
                                }
                                requiredData.push(obj);
                            }
                        });
                    }
                }
            );
        });

        const data: IGraphData = {
            graphType: GraphType.STACK_BAR,
            labelText: {
                categoryY: 'name',
                xAxesTitle: '[bold]Time (in hours)',
                yAxesTitle: '[bold]Days'
            },
            categoryAxis: 'y',
            chartData: requiredData,
            seriesList: ['endTime'],
            toggleLegend: false,
            disableLegend: true,
            height: 350,
            disableScrollBarX: true,
            disableScrollBarY: true,
            customizeSeries: (value, columnSeries, modules) => {
                columnSeries.dataFields.openValueX = 'startTime';
                const columnTemplate = columnSeries.columns.template;
                columnTemplate.strokeOpacity = 0;
                columnTemplate.propertyFields.fill = 'color';
                columnTemplate.height = modules.am4core.percent(100);
            },
            onHoverTemplate: (event) => {
                event.target.tooltip.background.stroke = event.target.fill;
                event.target.tooltip.background.strokeOpacity =
                    event.target.opacity;
            },
            tooltiptext: () => `{ruleName}{duration}`
        };
        this.stackBarGraph = data;
    }

    isArray(item) {
        if (item instanceof Array) {
            return true;
        } else {
            return false;
        }
    }

    // to make the keyvalue pipe unsorted
    returnZero() {
        return 0;
    }
}
