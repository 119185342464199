import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { WidgetInjectedData } from '../../../../classes/WidgetInjectedData';
import { IconType } from '../../../../enums/IconType';
import { ITableGeneratorInput } from '../../../../interfaces/table-generator/ITableGeneratorInput';
import { QueryBuilderTemplateComponent } from '../../../modal-templates/query-builder-modal/query-builder-template/query-builder-template.component';
import { WidgetDetailsComponent } from '../../../modal-templates/query-builder-modal/widget-details/widget-details.component';
import { ApiUrls } from './../../../../../core/classes/ApiUrls';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { ActionState } from './../../../../enums/ActionState';
import { ActionVisibility } from './../../../../enums/ActionVisibility';
import { AuthorizationType } from './../../../../enums/AuthorizationType';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { RequestType } from './../../../../enums/RequestType';
import { IApiInfo } from './../../../../interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IConfirmationModal } from './../../../../interfaces/modal/IConfirmationModal';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { CustomWidgetPreviewComponent } from './../../../custom-widget-preview/custom-widget-preview.component';
import { ConfirmationModalComponent } from './../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { WidgetConfigurationComponent } from './../../../modal-templates/query-builder-modal/widget-configuration/widget-configuration.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';
import { PieTemplateComponent } from './../../../pie-template/pie-template.component';

@Component({
    selector: 'app-custom-cost-monitoring-widget-listing',
    templateUrl: './custom-cost-monitoring-widget-listing.component.html',
    styleUrls: ['./custom-cost-monitoring-widget-listing.component.sass']
})
export class CustomCostMonitoringWidgetListingComponent implements OnInit {
    readonly ISSUED_KEY = 'Issued';
    readonly WIDGET_ID_KEY = 'id';
    widgetRef: Widget;
    agGrid: GridOptions;
    tableInput: ITableGeneratorInput = null;
    apiResponse;
    selectedWidgets = [];
    customerUsersData;
    @ViewChild('bulkDeleteList') bulkDeleteTemplateRef: TemplateRef<any>;
    @ViewChild('actionsRestrictionView')
    actionsRestrictionViewTemplateRef: TemplateRef<any>;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngOnInit(): void {
        this.initTable();
        this.bulkActions();
    }

    initTable() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            isRowSelectable: (rowNode) => {
                return !rowNode.data[this.ISSUED_KEY];
            },
            buttons: [
                {
                    buttonName: 'Create Query',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    showLoader: false,
                    function: () => {
                        this.openCreateWidgetModal(null);
                    }
                }
            ],
            tableHeight: 300,
            selection: 'multiple',
            noDataMessage: Messages.NO_CUSTOM_QUERY,
            showNotificationBadgeOnSelection:
                this.widgetRef.showNotificationBadge,
            columns: [],
            afterResponse: (response) => {
                this.apiResponse = response;
                const columns: IColumnData[] = [];
                this.apiResponse.dataMap.keysToShow.forEach((columnObj) => {
                    if (columnObj.id === this.ISSUED_KEY) {
                        columns.push({
                            columnKey: columnObj.id,
                            columnName: columnObj.label,
                            cellRenderer: (item) => {
                                if (item['value']) {
                                    return 'YES';
                                } else {
                                    return 'NO';
                                }
                            }
                        });
                    } else {
                        const column: IColumnData = {
                            columnKey: columnObj.id,
                            columnName: columnObj.label
                        };

                        if (columnObj.id === 'Status') {
                            column.cellStyle = this.cellStyling.bind(this);
                        }
                        columns.push(column);
                    }
                });

                columns.push({
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    filter: false,
                    hideFilterMenu: true,
                    buttonGen: true,
                    minWidth: 200,
                    headerClass: 'grid-cell-data-centred',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        rowData['buttonGenInputs'] = [];
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (rowData.data[this.ISSUED_KEY]) {
                                        this.getPassedCustomersUsersAndOpenModal(
                                            () => {
                                                this.openConfirmationModal(
                                                    {
                                                        buttonName:
                                                            'View Configuration',
                                                        buttonColorType:
                                                            ButtonColorType.PRIMARY
                                                    },
                                                    (modalId) => {
                                                        this.getRequestWidgetData(
                                                            rowData.data[
                                                                this
                                                                    .WIDGET_ID_KEY
                                                            ],
                                                            null,
                                                            rowData.data[
                                                                this.ISSUED_KEY
                                                            ],
                                                            modalId
                                                        );
                                                    },
                                                    'Widget Name cannot be Edited because it is attached to following customers and users.',
                                                    this
                                                        .actionsRestrictionViewTemplateRef,
                                                    55,
                                                    55,
                                                    true
                                                );
                                            },
                                            [rowData.data],
                                            buttonRef
                                        );
                                    } else {
                                        buttonRef.loader = true;
                                        this.getRequestWidgetData(
                                            rowData.data[this.WIDGET_ID_KEY],
                                            buttonRef,
                                            rowData.data[this.ISSUED_KEY]
                                        );
                                    }
                                }
                            },
                            {
                                buttonName: 'Preview',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.showPreview(
                                        rowData.data[this.WIDGET_ID_KEY],
                                        buttonRef
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.openDeleteModal(
                                        [rowData.data],
                                        false,
                                        buttonRef
                                    );
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                });

                this.tableInput.columns = columns;
            }
        };
    }

    cellStyling(data) {
        if (
            data['data']['Status'] &&
            (data['data']['Status'] as string).toLowerCase() === 'active'
        ) {
            return {
                color:
                    Helper.convertToHexIfHsl(
                        Helper.getCssVarValue('positiveColor')
                    ) + ' !important'
            };
        } else {
            return {
                color:
                    Helper.convertToHexIfHsl(
                        Helper.getCssVarValue('neutralColor')
                    ) + ' !important'
            };
        }
    }

    openCreateWidgetModal(
        widgetData?,
        isEdit = false,
        isViewMode = false,
        widgetId = null
    ) {
        this.widgetRef.modalService.openModal({
            modalName: null,
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 95,
            modalHeightVh: 95,
            hideSteps: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: WidgetDetailsComponent,
                        payload: {
                            data: {
                                widgetData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isViewMode,
                                widgetNames:
                                    this.apiResponse &&
                                    this.apiResponse['dataList']
                                        ? this.apiResponse['dataList'].map(
                                              (element) =>
                                                  element['Widget Name']
                                          )
                                        : [],
                                widgetId: widgetId
                            }
                        }
                    },
                    stepName: null,
                    headingText: 'Widget Template'
                },
                {
                    resetModal: true,
                    stepData: {
                        componentToLoad: QueryBuilderTemplateComponent,
                        payload: {
                            data: {
                                widgetData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isViewMode,
                                widgetId
                            }
                        }
                    },
                    stepName: null,
                    headingText: 'Configure Custom Query'
                },
                {
                    resetModal: true,
                    stepData: {
                        componentToLoad: WidgetConfigurationComponent,
                        payload: {
                            data: {
                                widgetData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isViewMode,
                                widgetId
                            }
                        }
                    },
                    stepName: null,
                    headingText: 'Configurations'
                }
            ]
        });
    }

    getRequestWidgetData(
        widgetId,
        buttonRef: IButtonGeneratorInput,
        isViewMode = false,
        modalId = null
    ) {
        const apiInfo: IApiInfo = {
            apiRouteSuffix: `${ApiUrls.CUSTOM_COST_MONITORING_WIDGET}/${widgetId}`,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {};
        apiArgs.function = (response) => {
            if (modalId) {
                this.widgetRef.modalService.closeModal(null, modalId);
            } else {
                buttonRef.loader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            }
            this.openCreateWidgetModal(response, true, isViewMode, widgetId);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
            if (modalId) {
                this.widgetRef.modalService.closeModal(null, modalId);
            } else {
                buttonRef.loader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
            }
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openDeleteModal(data, bulk = false, buttonRef?) {
        this.selectedWidgets = data;

        if (this.selectedWidgets.length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one widget'
            );
            return;
        }

        const callback = (modalId) => {
            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.delete
            );

            const widgetIds = data.map((each) => each.id);

            apiArgs.input = {
                widgetIds: widgetIds
            };

            (apiArgs.function = (response) => {
                this.widgetRef.modalService.closeModal(null, modalId);

                this.widgetRef.notificationsService.showSnackBar(
                    'Widget(s) Deleted Successfully'
                );

                this.widgetRef.showNotificationBadge.next(false);

                this.widgetRef.refreshWidget();
            }),
                (apiArgs.errorFunction = (error) => {
                    this.widgetRef.modalService.closeModal(null, modalId);
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error while deleting widget'
                    );
                }),
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
        };

        if (bulk || !this.selectedWidgets[0][this.ISSUED_KEY]) {
            this.openConfirmationModal(
                { buttonName: 'Delete', buttonColorType: ButtonColorType.WARN },
                callback,
                bulk
                    ? 'Are you sure you want to delete the following widget(s) ?'
                    : 'Are you sure you want to delete this widget ?',
                this.bulkDeleteTemplateRef,
                this.selectedWidgets.length > 1 ? 50 : 40,
                this.selectedWidgets.length > 1 ? 50 : 40
            );
        } else {
            this.getPassedCustomersUsersAndOpenModal(
                () => {
                    this.openConfirmationModal(
                        null,
                        callback,
                        'Widget Name cannot be Deleted because it is attached to following customers and users.',
                        this.actionsRestrictionViewTemplateRef,
                        50,
                        50
                    );
                },
                this.selectedWidgets,
                buttonRef
            );
        }
    }

    getPassedCustomersUsersAndOpenModal(callback, selectedWidgets, buttonRef) {
        buttonRef.loader = true;
        this.customerUsersData = null;
        const apiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.WIDGET_PASSED,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.POST
        };

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {
            widgetIds: selectedWidgets.map((each) => each.id)
        };
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.customerUsersData = response;
            if (
                (this.customerUsersData.customer &&
                    this.customerUsersData.customer.length) ||
                (this.customerUsersData.customer &&
                    this.customerUsersData.user.length)
            ) {
                callback();
            }
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    showPreview(widgetId, buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = true;
        const apiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.PREVIEW,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.POST
        };

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {
            widgetId: widgetId
        };
        apiArgs.function = (response) => {
            const previewData = response;
            if (response.dataMap.description) {
                // Fetching Description
                const hitApi: IHitApi = {
                    url: `${ApiUrls.DESCRIPTION_INFO_API_PATH}${response.dataMap.description}`,
                    intactUrl: `${ApiUrls.DESCRIPTION_INFO_API_PATH}{description}`,
                    requestType: RequestType.GET,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    function: (response) => {
                        buttonRef.loader = false;
                        this.widgetRef.changeDetectorRef.detectChanges();
                        previewData['widgetDescription'] = response;
                        this.openPreviewModal(previewData);
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error getting data.'
                        );
                        buttonRef.loader = false;
                        this.widgetRef.changeDetectorRef.detectChanges();
                    },
                    input: {}
                };

                new HitApi(
                    hitApi,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            } else {
                buttonRef.loader = false;
                this.widgetRef.changeDetectorRef.detectChanges();
                this.openPreviewModal(previewData);
            }
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    bulkActions() {
        this.widgetRef.operationalActions.next(
            new Map().set('Bulk Action', [
                {
                    icon: {
                        type: IconType.SVG,
                        class: 'trash',
                        text: 'Bulk Delete',
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openDeleteModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data),
                            true
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                }
            ])
        );
    }

    openConfirmationModal(
        button: IConfirmationModalButtonData,
        callback,
        confirmationMsg = null,
        templateRef = null,
        height?,
        width?,
        edit = false
    ) {
        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                callback(modalId);
            },
            modalName: edit ? 'Edit Widget' : 'Delete Widget',
            modalIcon: null,
            contextIcon: null,
            confirmationMessage: confirmationMsg,
            buttonColorType: button ? button.buttonColorType : null,
            buttonText: button ? button.buttonName : null,
            hideButtons: button ? false : true,
            loaderOnExec: true,
            bodyTemplate: templateRef,
            fontSize: 1,
            hideCancelButton: true
        };
        const modalData: IModalData = {
            modalName: edit ? 'Edit Widget' : 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: height ? height : 40,
            modalWidthVw: width ? width : 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openPreviewModal(response) {
        const tableInputData: ITableGeneratorInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns: []
        };

        response.dataMap.tableKeys.forEach((row) => {
            tableInputData.columns.push({
                columnName: row,
                columnKey: row
            });
        });

        //Prepare Pie Data
        const prepareTempData = {};
        const yAxis = response.dataMap.pie.y[0];
        const xAxis = response.dataMap.pie.x[0];
        const pieData = [];

        if (!response.dataMap.hideGraph) {
            response.dataMap.table.forEach((element) => {
                if (element[yAxis] in prepareTempData) {
                    const roundOff = 2;
                    prepareTempData[element[yAxis]] = Helper.roundOff(
                        prepareTempData[element[yAxis]] + element[xAxis],
                        roundOff
                    );
                } else {
                    prepareTempData[element[yAxis]] = element[xAxis];
                }
            });

            Object.keys(prepareTempData).forEach((key) => {
                if (!(prepareTempData[key] < 0)) {
                    const tempObject = {};
                    tempObject[yAxis] = key;
                    tempObject[xAxis] = prepareTempData[key];
                    pieData.push(tempObject);
                }
            });
        }

        // Prepare Preview Data
        const previewData = {
            text: response.dataMap.widgetName,
            description: response.widgetDescription
                ? {
                      oldDescription: response.widgetDescription
                  }
                : null,
            componentToLoad: PieTemplateComponent,
            componentInputData: {
                pieData: !response.dataMap.hideGraph ? pieData : null,
                tableData: response.dataMap.table,
                tableInputData: tableInputData,
                xKey: response.dataMap.pie.x[0],
                yKey: response.dataMap.pie.y[0],
                currencySymbol: response.dataMap.currencySymbol,
                totalCost: response.dataMap.totalCost,
                totalUsage: response.dataMap.totalUsage
            }
        };

        this.widgetRef.modalService.openModal({
            modalName: 'Preview',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: Symbol(),
            modalWidthVw: 95,
            modalHeightVh: 95,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CustomWidgetPreviewComponent,
                        payload: {
                            data: {
                                previewData: previewData
                            }
                        }
                    },
                    stepName: 'Preview'
                }
            ]
        });
    }
}

interface IConfirmationModalButtonData {
    buttonName: string;
    buttonColorType: ButtonColorType;
}
