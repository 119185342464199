import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IPieData } from '../../interfaces/graph/IGraphData';
import { DynamicComponentsInjectedData } from './../../classes/DynamicComponentsInjectedData';
import { Helper } from './../../classes/Helper';
import { GraphType } from './../../enums/GraphType';

@Component({
    selector: 'app-pie-template',
    templateUrl: './pie-template.component.html',
    styleUrls: ['./pie-template.component.sass']
})
export class PieTemplateComponent implements OnInit {
    xKey: string;
    yKey: string;
    pieData;
    tableData;
    totalCost;
    totalUsage;
    currencySymbol;
    tableInputData;
    graphId: string;
    graphData: IGraphData;

    constructor(
        dynamicComponentsInjectedData: DynamicComponentsInjectedData,
        @Inject(DOCUMENT) private document: Document
    ) {
        if (
            dynamicComponentsInjectedData &&
            dynamicComponentsInjectedData['inputData']
        ) {
            this.pieData = dynamicComponentsInjectedData['inputData']['pieData']
                ? dynamicComponentsInjectedData['inputData']['pieData']
                : null;
            this.currencySymbol = dynamicComponentsInjectedData['inputData'][
                'currencySymbol'
            ]
                ? dynamicComponentsInjectedData['inputData']['currencySymbol']
                : null;
            this.totalCost = dynamicComponentsInjectedData['inputData'][
                'totalCost'
            ]
                ? dynamicComponentsInjectedData['inputData']['totalCost']
                : null;
            this.totalUsage = dynamicComponentsInjectedData['inputData'][
                'totalUsage'
            ]
                ? dynamicComponentsInjectedData['inputData']['totalUsage']
                : null;
            this.xKey = dynamicComponentsInjectedData['inputData']['xKey']
                ? dynamicComponentsInjectedData['inputData']['xKey']
                : null;

            this.yKey = dynamicComponentsInjectedData['inputData']['yKey']
                ? dynamicComponentsInjectedData['inputData']['yKey']
                : null;

            if (
                dynamicComponentsInjectedData['inputData']['tableData'] &&
                dynamicComponentsInjectedData['inputData']['tableInputData']
            ) {
                this.tableData =
                    dynamicComponentsInjectedData['inputData']['tableData'];
                this.tableInputData =
                    dynamicComponentsInjectedData['inputData'][
                        'tableInputData'
                    ];
            }
        }
        this.graphId = Helper.generateUniqueKey(10);
    }

    ngOnInit(): void {
        this.prepareChartData();
    }

    ngOnChanges(change) {
        if (change.pieData) {
            this.pieData = change.pieData.currentValue;
            this.prepareChartData();
        }
    }

    prepareChartData() {
        const data: IPieData[] = this.pieData?.map((data) => {
            return {
                category: data[this.yKey],
                value: data[this.xKey]
            };
        });

        this.graphData = {
            graphType: GraphType.PIE,
            chartData: data
        };
    }
}
