import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IButtonGeneratorInput } from './../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-email-testing-modal',
    templateUrl: './email-testing-modal.component.html',
    styleUrls: ['./email-testing-modal.component.sass']
})
export class EmailTestingModalComponent implements OnInit {
    executionFunc = null;
    formGenInput: IFormGeneratorInput = null;
    buttonsInput: IButtonGeneratorInput;
    formGroup: FormGroup;
    resetSubcription: Subscription;
    constructor(
        private modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.executionFunc = this.modalInputData.data;
        this.setFormData();
        this.buttonsInput = {
            buttonName: 'Send',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.executionFunc(
                    buttonRef,
                    this.formGroup,
                    this.modalInputData.modalId
                );
            }
        };
        this.resetSubcription = this.modalService.resetModal.subscribe(() => {
            this.formGroup.reset();
        });
    }

    setFormData() {
        this.formGenInput = {
            formName: 'Email Testing',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'To',
                    name: 'emailTo',
                    fieldType: FilterType.MATCHIPLIST,
                    placeholder: 'email@address.com',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Senders are required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    label: 'Subject',
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Subject',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Subject is required'
                        }
                    ]
                },
                {
                    label: 'Email Body',
                    name: 'emailBody',
                    fieldType: FilterType.TEXTAREA,
                    placeholder: 'Email Body',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    extraClass: 'tw-items-center',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email body is required'
                        }
                    ]
                }
            ],
            extraClass: 'integration-styling'
        };
    }
    ngOnDestroy() {
        this.modalService.resetModal.unsubscribe();
    }
}
