import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { ColDef, RowEvent } from 'ag-grid-community';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Widget } from 'src/app/shared/classes/Widget';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { AuthorizationType } from './../../../../enums/AuthorizationType';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { Clause } from './../../../../enums/Clause';
import { IconType } from './../../../../enums/IconType';
import { RequestType } from './../../../../enums/RequestType';
import { IApiInfo } from './../../../../interfaces/api/IApiInfo';
import { ICustomCostMonitoringWidgetCreationInput } from './../../../../interfaces/api/input-data/ICustomCostMonitoringWidgetCreationInput';
import { IApiResponse } from './../../../../interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from './../../../../interfaces/filter/IFilterInfo';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { CustomCostMonitoringWidgetService } from './../../../../services/custom-cost-monitoring-widget/custom-cost-monitoring-widget.service';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
@Component({
    selector: 'app-query-builder-template',
    templateUrl: './query-builder-template.component.html',
    styleUrls: ['./query-builder-template.component.sass']
})
export class QueryBuilderTemplateComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    /* Only added for testing purpose. So, that QA team can test actual query (which will be based on columm_id) on bigQuery platform
    This code should be removed at the time of production push. */
    readonly enableTestQuery: boolean = true;

    dragIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-grip-vertical'
    };
    searchIcon: IIcon = {
        type: IconType.SVG,
        class: 'search'
    };
    pdfIcon: IIcon = {
        type: IconType.SVG,
        class: 'pdf_icon',
        extraClass: 'svg-pdf-icon-fill'
    };
    rightTickIcon: IIcon = {
        type: IconType.SVG,
        class: 'right_tick'
    };
    errorIcon: IIcon = {
        type: IconType.SVG,
        class: 'exclamation_filled_circle'
    };
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times'
    };
    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_without_border'
    };
    downloadIcon: IIcon = {
        type: IconType.SVG,
        class: 'download',
        extraClass: 'svg-transparent-fill'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    lineLoaders: IIcon = {
        type: IconType.LINELOADER,
        class: 'loading-bar'
    };
    infoIcon: IIcon = {
        type: IconType.SVG,
        class: 'exclamation_filled_circle',
        extraClass: 'svg-info-v2-fill'
    };

    widgetId: string;
    widgetRef: Widget;
    isEdit: boolean;
    isViewMode: boolean;
    editorOptions = {
        theme: 'vs',
        language: 'sql',
        scrollbar: {
            useShadows: false,
            vertical: true,
            horizontal: 'auto',
            verticalScrollbarSize: 5,
            horizontalScrollbarSize: 5
        },
        fontSize: 16,
        lineHeight: 28,
        renderLineHighlight: 'none',
        readOnly: true,
        stopRenderingLineAfter: -1,
        scrollBeyondLastLine: false,
        minimap: {
            enabled: false
        }
    };
    query: string = '';
    queryClauseList = [
        {
            id: Clause.SELECT,
            label: 'Select Clause'
        },
        {
            id: Clause.FROM,
            label: 'From Clause'
        },
        {
            id: Clause.WHERE,
            label: 'Where Clause'
        },
        {
            id: Clause.GROUP_BY,
            label: 'Group by Clause'
        },
        {
            id: Clause.ORDER_BY,
            label: 'Order by Clause'
        }
    ];
    tempQueryClauseList = [];
    ColumnType = ColumnType;
    filteredColumns: IColumnListData[] = [];
    selectedColumns: string[] = [];
    columnList: IColumnListData[] = [];
    orderByList: any[] = [];

    tableGenInput: ITableGeneratorInput = null;
    defaultColDef: ColDef = {
        width: 100,
        filter: false,
        editable: false,
        sortable: false,
        resizable: false
    };
    queryTable = `'COST MONITORING DATASET'`;
    selectedClause: Clause[] = [];
    Clause = Clause;
    currentTab: Clause;
    allColumnsIdLabelMap: Map<string, IColumnListData> = new Map();
    addQueryButtonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.saveQuery();
            }
        },
        {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.inCompleteClause = null;

                // By clicking on cancel button user will be able to reset the discard the changes to last saved state
                if (this.isQueryClauseChanged()) {
                    this.getConfirmationModalForCancel(this.currentTab);
                }
            }
        }
    ];
    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.back();
            }
        },
        {
            buttonName: 'Save as Draft',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.saveAsDraft(buttonRef);
            }
        },
        {
            buttonName: 'Run',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.checkQuery();
            }
        },
        {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.nextStep(buttonRef);
            }
        }
    ];
    operatorsList = [
        {
            id: '=',
            label: 'Equal'
        },
        {
            id: '!=',
            label: 'Not Equal'
        },
        {
            id: '>',
            label: 'Greater Than'
        },
        {
            id: '<',
            label: 'Less Than'
        },
        {
            id: '>=',
            label: 'Greater Than or Equal To'
        },
        {
            id: '<=',
            label: 'Less Than or Equal To'
        },
        {
            id: '!<',
            label: 'Not Less Than'
        },
        {
            id: '!>',
            label: 'Not Greater Than'
        }
    ];
    andOrButtonGenInputs: IButtonGeneratorInput[][] = [
        [
            {
                buttonName: WhereClauseRelation.AND,
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.STROKED,
                function: null
            },
            {
                buttonName: WhereClauseRelation.OR,
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.STROKED,
                function: null
            }
        ]
    ];
    deleteButtonInput: IButtonGeneratorInput = {
        buttonIcon: this.trashIcon,
        buttonName: '',
        buttonColorType: ButtonColorType.WARN,
        buttonType: ButtonType.ICON,
        preventHoverEffect: true,
        function: () => {},
        customClass: 'trash_without_border'
    };
    columnDataListFilterInfo: IFilterInfo[] = [];
    checkQueryTableGenInput: ITableGeneratorInput = null;
    checkQueryApiInfo = {
        requestType: RequestType.POST,
        customInput: null,
        host: null,
        apiRouteSuffix: ApiUrls.CHECK_QUERY,
        authorization: AuthorizationType.BEARER_TOKEN
    };
    runQuery = false;
    columnNameMap: Map<string, string> = new Map();
    selectedColumnsTextType = [];
    selectedColumnsNumberType = [];
    isLoading = false;
    tableQueryData: IApiResponse;
    queryValidationError: string;
    downloadLoader = false;
    loader = false;
    inputData: ICustomCostMonitoringWidgetCreationInput;
    actualQuery: string = ''; // Query received from get Api

    // temporary variables for where, groupby and orderby clause
    tempWhereClauseList: IWhereClauseData[] = [];
    tempOrderByMap: Map<string, string> = new Map();
    selectedGroupByColumns: string[] = [];

    // Variables for where, groupby and orderby clause after saving data
    whereClauseList: IWhereClauseData[] = [];
    orderByMap: Map<string, string> = new Map();
    groupByColumns: string[] = [];
    resetModal: Subscription;

    @ViewChildren('queryEditor') queryEditor: QueryList<EditorComponent>;
    customlLangConfigured: boolean = false;
    inCompleteClause: Clause;

    constructor(
        private customCostMonitoringWidgetService: CustomCostMonitoringWidgetService,
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private modalService: ModalService
    ) {
        if (multiStepFormService.stepData.get(modalInputData.modalId).has(2)) {
            this.inputData = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(2).inputData;
            this.selectedColumnsTextType = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(2).textTypeColumns;
            this.selectedColumnsNumberType = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(2).numberTypeColumns;
        }

        if (this.inputData) {
            this.inputData.widgetName = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(1).widgetName;
            this.inputData.widgetDescription = multiStepFormService.stepData
                .get(modalInputData.modalId)
                .get(1).widgetDescription;
        } else {
            this.inputData = Helper.cloneDeep(
                multiStepFormService.stepData.get(modalInputData.modalId).get(1)
            );
        }
        this.actualQuery = Helper.cloneDeep(this.inputData.queryString);
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isViewMode = modalInputData.data['isViewMode'];
        this.widgetId = modalInputData.data['widgetId'];
        this.buttonGenInputs[1].disable = this.isViewMode;
    }

    ngOnInit(): void {
        this.getColumnsList();
        this.resetModal = this.modalService.resetModal.subscribe((reset) => {
            this.modalService.openConfirmationModal({
                modalName: 'Reset',
                modalIcon: null,
                contextIcon: null,
                confirmationMessage:
                    'Are you sure you want to reset the changes ?',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonText: 'Confirm',
                function: (modalId: Symbol) => {
                    this.showOrHideQueryTable(false);
                    this.queryValidationError = '';
                    this.tableQueryData = null;
                    if (!this.isEdit) {
                        this.inputData.query = '';
                    }
                    this.filteredColumns = this.columnList;
                    this.initStep();
                    this.modalService.closeModal(null, modalId);
                }
            });
        });
    }

    ngAfterViewInit(): void {
        this.queryEditor.changes.pipe(delay(750)).subscribe(() => {
            if (!this.customlLangConfigured && (window as any).monaco) {
                const langId = 'queryBuilderLang';
                const themeNm = 'qbTheme';
                monaco.languages.register({ id: langId });
                monaco.languages.setMonarchTokensProvider(langId, {
                    tokenizer: {
                        root: [
                            [/SELECT/, 'qb-select'],
                            [/FROM/, 'qb-from'],
                            [/WHERE/, 'qb-where'],
                            [/GROUP BY/, 'qb-group-by'],
                            [/ORDER BY/, 'qb-order-by']
                        ]
                    }
                });
                monaco.editor.defineTheme(themeNm, {
                    base: 'vs',
                    inherit: false,
                    rules: [
                        {
                            token: 'qb-select',
                            fontStyle: 'bold'
                        },
                        {
                            token: 'qb-from',
                            fontStyle: 'bold'
                        },
                        {
                            token: 'qb-where',
                            fontStyle: 'bold'
                        },
                        {
                            token: 'qb-group-by',
                            fontStyle: 'bold'
                        },
                        {
                            token: 'qb-order-by',
                            fontStyle: 'bold'
                        }
                    ],
                    colors: {}
                });
                this.editorOptions.language = langId;
                this.editorOptions.theme = themeNm;
                this.editorOptions = { ...this.editorOptions };
                this.customlLangConfigured = true;
            }
        });
    }

    initStep() {
        if (this.inputData.query) {
            this.query = this.inputData.queryString;
            this.selectedColumns = this.inputData.query[Clause.SELECT]
                ? Helper.cloneDeep(this.inputData.query[Clause.SELECT])
                : [];
            this.whereClauseList = this.inputData.query[Clause.WHERE]
                ? Helper.cloneDeep(this.inputData.query[Clause.WHERE])
                : [];
            this.groupByColumns = this.inputData.query[Clause.GROUP_BY]
                ? Helper.cloneDeep(this.inputData.query[Clause.GROUP_BY])
                : [];
            this.selectedGroupByColumns = this.inputData.query[Clause.GROUP_BY]
                ? Helper.cloneDeep(this.inputData.query[Clause.GROUP_BY])
                : [];
            this.orderByMap = this.inputData.query[Clause.ORDER_BY]
                ? Helper.cloneDeep(
                      new Map(
                          Object.entries(this.inputData.query[Clause.ORDER_BY])
                      )
                  )
                : new Map();
            this.tempOrderByMap = Helper.cloneDeep(this.orderByMap);
            this.queryClauseList.forEach((clause) => {
                if (this.inputData.queryString.includes(clause.id)) {
                    this.selectedClause.push(clause.id);
                    if (clause.id === Clause.SELECT) {
                        this.currentTab = clause.id;
                    }
                }
            });
            this.columnNameMap = this.inputData.columnsToRename
                ? new Map(Object.entries(this.inputData.columnsToRename))
                : new Map();
        } else {
            this.query = '';
            this.currentTab = null;
            this.selectedClause = [];
            this.selectedColumns = [];
            this.whereClauseList = [];
            this.groupByColumns = [];
            this.selectedGroupByColumns = [];
            this.orderByList = [];
            this.orderByMap = new Map();
            this.tempOrderByMap = new Map();
            this.columnNameMap.clear();
        }

        /* Do not change the order of query function calls */
        // Initialize Select clause data
        this.setSelectClause();

        // Initialize where clause form
        this.setWhereClause();

        // Initialize order_by table
        this.setOrderByClause();
    }

    checkChanges() {
        if (this.query !== this.actualQuery) {
            return true;
        }

        if (
            this.whereClauseList.length &&
            !Helper.compareObjects(
                this.tempWhereClauseList,
                this.whereClauseList
            )
        ) {
            return true;
        }

        if (
            !Helper.compareObjects(
                this.selectedGroupByColumns,
                this.groupByColumns
            )
        ) {
            return true;
        }

        return this.checkOrderByClause();
    }

    // Comparing difference
    checkOrderByClause() {
        if (this.tempOrderByMap.size !== this.orderByMap.size) {
            return true;
        }
        for (const [key, val] of this.orderByMap) {
            if (
                !this.tempOrderByMap.has(key) ||
                this.tempOrderByMap.get(key) !== val
            ) {
                return true;
            }
        }
    }

    setOrderByClause() {
        const orderByList = [];
        this.selectedColumns.forEach((column) => {
            orderByList.push({
                id: column,
                label: this.allColumnsIdLabelMap.get(column).label,
                [Order.ASC]:
                    this.orderByMap.get(column) === Order.ASC ? true : false,
                [Order.DESC]:
                    this.orderByMap.get(column) === Order.DESC ? true : false
            });
        });
        this.orderByList = orderByList;
        this.tableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnKey: 'label',
                    columnName: 'Field Name',
                    flex: 1
                },
                {
                    columnKey: Order.ASC,
                    columnName: 'Ascending',
                    cellClass: 'cell-center',
                    headerClass: 'header-center',
                    flex: 1,
                    cellRenderer: (rowData: RowEvent) => {
                        return this.getRadioRendered(rowData, Order.ASC);
                    }
                },
                {
                    columnKey: Order.DESC,
                    columnName: 'Descending',
                    cellClass: 'cell-center',
                    headerClass: 'header-center',
                    flex: 1,
                    cellRenderer: (rowData: RowEvent) => {
                        return this.getRadioRendered(rowData, Order.DESC);
                    }
                }
            ]
        };
    }

    setSelectClause() {
        this.selectedColumnsTextType = [];
        this.selectedColumnsNumberType = [];
        this.selectedColumns.forEach((column) => {
            this.columnNameMap.set(
                column,
                this.allColumnsIdLabelMap.get(column).label
            );
            if (
                this.allColumnsIdLabelMap.get(column).type === ColumnType.TEXT
            ) {
                this.selectedColumnsTextType.push(
                    Helper.cloneDeep(this.allColumnsIdLabelMap.get(column))
                );
            }
            if (
                this.allColumnsIdLabelMap.get(column).type === ColumnType.NUMBER
            ) {
                this.selectedColumnsNumberType.push(
                    Helper.cloneDeep(this.allColumnsIdLabelMap.get(column))
                );
            }
        });
    }

    setWhereClause() {
        if (this.whereClauseList.length) {
            this.tempWhereClauseList = Helper.cloneDeep(this.whereClauseList);
            this.tempWhereClauseList.forEach((row: IWhereClauseData, index) => {
                if (
                    this.allColumnsIdLabelMap.get(row.columnName).type ===
                    ColumnType.TEXT
                ) {
                    this.setColumnDataListFilterInfo(index, row.columnName);
                }

                if (!this.andOrButtonGenInputs[index]) {
                    this.andOrButtonGenInputs.push([
                        this.getSecondaryButton(
                            WhereClauseRelation.AND,
                            ButtonType.STROKED
                        ),
                        this.getSecondaryButton(
                            WhereClauseRelation.OR,
                            ButtonType.STROKED
                        )
                    ]);
                    return;
                }
                if (row.nextRelation) {
                    this.toggleAndOrRelation(index, row.nextRelation);
                } else {
                    this.toggleAndOrRelation(
                        index,
                        this.andOrButtonGenInputs[index][0]
                            .buttonName as WhereClauseRelation,
                        ButtonType.STROKED
                    );
                    this.toggleAndOrRelation(
                        index,
                        this.andOrButtonGenInputs[index][1]
                            .buttonName as WhereClauseRelation,
                        ButtonType.STROKED
                    );
                }
            });
        } else {
            this.tempWhereClauseList = [
                {
                    columnName: '',
                    operator: '',
                    value: '',
                    nextRelation: null
                }
            ];
        }
    }

    initCheckQueryTableData() {
        /* Only added for testing purpose. So, that QA team can test actual query (which will be based on columm_id) on bigQuery platform
        This code should be removed at the time of production push. */
        if (this.enableTestQuery) {
            let query: string = Helper.cloneDeep(this.query);
            this.selectedColumns.forEach((column) => {
                if (
                    this.allColumnsIdLabelMap.get(column).type ===
                    ColumnType.NUMBER
                ) {
                    query = query.replace(
                        `SUM(${this.allColumnsIdLabelMap.get(column).label})`,
                        `SUM(${column})`
                    );
                } else {
                    const regex = new RegExp(
                        '\n' + this.allColumnsIdLabelMap.get(column).label,
                        'g'
                    );
                    query = query.replace(regex, `\n${column}`);
                }
            });
            query += 'LIMIT 10';
            console.info('Query String: \n', query);
        }

        const input = Helper.cloneDeep(this.updateInputData());
        input.graph = {
            y: this.selectedColumnsTextType.map((row) => row.id),
            x: this.selectedColumnsNumberType.map((row) => row.id)
        };

        this.checkQueryTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataMap|table'
            },
            columns: [],
            afterResponse: (response: IApiResponse) => {
                this.tableQueryData = response;
                this.checkQueryTableGenInput.columns = [];
                if (response.dataMap && response.dataMap['tableKeys']) {
                    response.dataMap['tableKeys'].forEach((tableKey) => {
                        this.checkQueryTableGenInput.columns.push({
                            columnKey: tableKey,
                            columnName: tableKey
                        });
                    });
                }
            },
            afterErrorResponse: (error) => {
                this.queryValidationError = error;
            },
            apiInput: input,
            hideError: true
        };
    }

    search(value) {
        if (value) {
            this.filteredColumns = this.columnList.filter((item) =>
                item.label.toLowerCase().includes(value.trim().toLowerCase())
            );
        } else {
            this.filteredColumns = this.columnList;
        }
    }

    updateCurrentTab(id, index) {
        this.tableQueryData = null;
        this.queryValidationError = '';
        this.showOrHideQueryTable(false);
        if (this.isQueryClauseChanged()) {
            this.inCompleteClause = this.currentTab;
            return;
        } else {
            this.inCompleteClause = null;
        }
        if (
            (id !== Clause.SELECT &&
                !this.selectedClause.includes(Clause.SELECT)) ||
            (id !== Clause.FROM &&
                id !== Clause.SELECT &&
                !this.selectedClause.includes(Clause.FROM))
        ) {
            return;
        }
        const previousTab = this.currentTab;
        this.currentTab = id;
        if (
            previousTab !== this.currentTab &&
            (previousTab === Clause.SELECT ||
                this.currentTab === Clause.GROUP_BY)
        ) {
            setTimeout(() => {
                this.editorOptions = { ...this.editorOptions };
            }, 0);
        }
        if (id === Clause.SELECT || id === Clause.FROM) {
            if (previousTab !== this.currentTab) {
                this.filteredColumns = this.columnList;
            }
            this.addClause(id);
            if (id === Clause.FROM) {
                // Adding selected columns as group_by columns
                this.addClause(Clause.GROUP_BY);

                // Adding selected number type columns as order_by columns
                this.addSelectedTypeNumColumnsInOrderBy();
            }
        } else {
            this.updateEveryClause(true);
        }
    }

    // Adding selected number type columns as order_by columns
    addSelectedTypeNumColumnsInOrderBy() {
        if (this.selectedColumnsNumberType.length) {
            this.selectedColumnsNumberType.forEach((row: IColumnListData) => {
                if (!this.orderByMap.has(row.id)) {
                    this.orderByMap.set(row.id, Order.DESC);
                    this.tempOrderByMap.set(row.id, Order.DESC);
                }
            });
            this.addClause(Clause.ORDER_BY);
            this.setOrderByClause();
        }
    }

    updateEditorQuery(queryClause: Clause) {
        switch (queryClause) {
            case Clause.SELECT:
                this.query =
                    Clause.SELECT +
                    '\n' +
                    this.getColumnString(this.selectedColumns);
                break;
            case Clause.FROM:
                this.query += Clause.FROM + '\n' + this.queryTable + '\n';
                break;
            case Clause.WHERE:
                this.query += this.whereClauseList.length
                    ? Clause.WHERE + '\n' + this.getWhereClauseString()
                    : '';
                break;
            case Clause.GROUP_BY:
                this.updateGroupByColumnsList();
                this.query += this.selectedGroupByColumns.length
                    ? 'GROUP BY' +
                      '\n' +
                      this.getColumnString(this.groupByColumns)
                    : '';
                break;
            case Clause.ORDER_BY:
                this.query += this.orderByMap.size
                    ? 'ORDER BY' + '\n' + this.getOrderByString()
                    : '';
                break;
        }
    }

    // Retrieve string of all columns label on the basis of column Id
    getColumnString(columns: string[]) {
        let selectString = '';
        columns.forEach((column, index) => {
            selectString +=
                (this.allColumnsIdLabelMap.get(column).type ===
                ColumnType.NUMBER
                    ? `SUM(${this.allColumnsIdLabelMap.get(column).label}) AS ${
                          this.allColumnsIdLabelMap.get(column).label
                      }`
                    : this.allColumnsIdLabelMap.get(column).label) +
                (index === columns.length - 1 ? '' : ',') +
                '\n';
        });
        return selectString;
    }

    // Create a string from whereClauseList array
    getWhereClauseString() {
        let whereClauseString = '';
        this.whereClauseList.forEach((data) => {
            whereClauseString +=
                this.allColumnsIdLabelMap.get(data.columnName).label +
                ' ' +
                data.operator +
                ' ' +
                (this.allColumnsIdLabelMap.get(data.columnName).type ===
                ColumnType.TEXT
                    ? `'${data.value}'`
                    : data.value) +
                '\n' +
                (data.nextRelation ? data.nextRelation + ' ' : '');
        });
        return whereClauseString;
    }

    updateGroupByColumnsList() {
        const columnList = [
            ...this.selectedColumnsTextType.map((row) => row.id)
        ];
        this.groupByColumns.forEach((column) => {
            if (!columnList.includes(column)) {
                columnList.push(column);
            }
        });
        this.groupByColumns = [...columnList];
        this.selectedGroupByColumns = [...columnList];
    }

    updateQueryColumns(columnId: string) {
        if (!this.selectedColumns.includes(columnId)) {
            this.selectedColumns.push(columnId);
            this.columnNameMap.set(
                columnId,
                this.allColumnsIdLabelMap.get(columnId).label
            );
            if (
                this.allColumnsIdLabelMap.get(columnId).type === ColumnType.TEXT
            ) {
                this.selectedColumnsTextType.push(
                    Helper.cloneDeep(this.allColumnsIdLabelMap.get(columnId))
                );
            } else {
                this.selectedColumnsNumberType.push(
                    Helper.cloneDeep(this.allColumnsIdLabelMap.get(columnId))
                );
            }
        } else {
            const delIndex = this.selectedColumns.findIndex(
                (column) => column === columnId
            );
            this.selectedColumns.splice(delIndex, 1);
            this.columnNameMap.delete(columnId);
            if (
                this.allColumnsIdLabelMap.get(columnId).type === ColumnType.TEXT
            ) {
                const index = this.selectedColumnsTextType.findIndex(
                    (column) => column.id === columnId
                );
                if (index !== -1) {
                    this.selectedColumnsTextType.splice(index, 1);
                }
            } else {
                const index = this.selectedColumnsNumberType.findIndex(
                    (column) => column.id === columnId
                );
                if (index !== -1) {
                    this.selectedColumnsNumberType.splice(index, 1);
                }
            }
        }
        this.addClause(Clause.SELECT);
        if (this.selectedClause.includes(Clause.FROM)) {
            this.addClause(Clause.GROUP_BY);
            this.addSelectedTypeNumColumnsInOrderBy();
        }

        this.orderByList = this.selectedColumnsTextType.concat(
            this.selectedColumnsNumberType
        );
    }

    getOrderByString() {
        let orderByString = '';
        let index = 0;
        for (const [key, value] of this.orderByMap.entries()) {
            orderByString +=
                this.allColumnsIdLabelMap.get(key).label +
                ' ' +
                value +
                (index === this.orderByMap.size - 1 ? '' : ',') +
                '\n';

            index++;
        }
        return orderByString;
    }

    addClause(clause: Clause) {
        if (clause === Clause.SELECT && !this.selectedColumns.length) {
            this.updateEditorQuery(Clause.SELECT);
        } else {
            if (!this.selectedClause.includes(clause)) {
                this.selectedClause.push(clause);
            }
            this.updateEveryClause();
        }
    }

    // Using queryClauseList to maintain the order of clauses like groupby must be added after where
    updateEveryClause(addTabCondition = false) {
        this.queryClauseList.forEach((clause) => {
            if (
                this.selectedClause.includes(clause.id) ||
                (addTabCondition && clause.id === this.currentTab)
            ) {
                this.updateEditorQuery(clause.id);
            }
        });
    }

    onGroupByColumnsChanged(list) {
        this.selectedGroupByColumns = list.selectedOptions.selected.map(
            (item) => item.value
        );
    }

    getRadioRendered(rowData: RowEvent, order: Order) {
        const data = rowData.data;
        const checked =
            this.orderByMap.size &&
            this.orderByMap.has(data.id) &&
            this.orderByMap.get(data.id) === order;
        return this.getRadioButton(
            data['id'],
            order,
            (inputName, inputValue) => {
                // Temporarily updating orderby selection
                this.tempOrderByMap.set(inputName, inputValue);
            },
            checked
        );
    }

    getRadioButton(inputName, inputId, callback, checked = false) {
        const radioButtonContainer = document.createElement('div');
        const radioButtonSibling = document.createElement('div');
        const radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.name = inputName;
        radioButton.value = inputId;
        radioButton.checked = checked;
        radioButton.addEventListener('click', (event) => {
            callback(inputName, inputId);
        });
        radioButtonContainer.classList.add('radio-button-container');
        radioButtonSibling.classList.add('radio-button');
        radioButtonContainer.appendChild(radioButton);
        radioButtonContainer.appendChild(radioButtonSibling);
        radioButtonContainer.addEventListener('click', (event) => {
            radioButton.click();
        });
        return radioButtonContainer;
    }

    whereClauseColumnNameChanged(
        row: IWhereClauseData,
        rowIndex,
        selectedColumn
    ) {
        if (
            this.allColumnsIdLabelMap.get(selectedColumn).type ===
            ColumnType.TEXT
        ) {
            this.setColumnDataListFilterInfo(rowIndex, selectedColumn);
        }
        row.columnName = selectedColumn;
        row.value = '';
    }

    afterResponse(data, index) {
        this.columnDataListFilterInfo[index].listData = data;
    }

    setColumnDataListFilterInfo(rowIndex, column) {
        this.columnDataListFilterInfo[rowIndex] = {
            placeholder: 'Column Value',
            apiInfo: {
                requestType: RequestType.GET,
                customInput: null,
                host: null,
                apiRouteSuffix: ApiUrls.COLUMN_DATA_LISTING.replace(
                    '{id}',
                    column
                ),
                intactUrl: ApiUrls.COLUMN_DATA_LISTING,
                authorization: AuthorizationType.BEARER_TOKEN
            }
        };
    }

    deleteWhereClauseEntry(index) {
        if (index > 0 && index === this.tempWhereClauseList.length - 1) {
            this.tempWhereClauseList[index - 1].nextRelation = null;

            // resetting both buttons to non selected state
            this.toggleAndOrRelation(
                index - 1,
                this.andOrButtonGenInputs[index - 1][0]
                    .buttonName as WhereClauseRelation,
                ButtonType.STROKED
            );
            this.toggleAndOrRelation(
                index - 1,
                this.andOrButtonGenInputs[index - 1][1]
                    .buttonName as WhereClauseRelation,
                ButtonType.STROKED
            );
        }
        this.tempWhereClauseList.splice(index, 1);
        this.andOrButtonGenInputs.splice(index, 1);
        this.columnDataListFilterInfo.splice(index, 1);

        if (!this.tempWhereClauseList.length) {
            this.addNewWhereClauseRow();
        }
    }

    updateWhereClauseNextRelation(index, button: IButtonGeneratorInput) {
        this.tempWhereClauseList[index].nextRelation =
            button.buttonName as WhereClauseRelation;
        this.toggleAndOrRelation(
            index,
            button.buttonName as WhereClauseRelation
        );
        if (this.tempWhereClauseList.length === index + 1) {
            this.addNewWhereClauseRow();
        }
    }

    toggleAndOrRelation(
        index,
        buttonName: WhereClauseRelation,
        buttonType = ButtonType.FLAT
    ) {
        if (buttonName === WhereClauseRelation.AND) {
            this.andOrButtonGenInputs[index][0] = this.getSecondaryButton(
                buttonName,
                buttonType
            );
            this.andOrButtonGenInputs[index][1] = this.getSecondaryButton(
                WhereClauseRelation.OR,
                ButtonType.STROKED
            );
        } else {
            this.andOrButtonGenInputs[index][1] = this.getSecondaryButton(
                buttonName,
                buttonType
            );
            this.andOrButtonGenInputs[index][0] = this.getSecondaryButton(
                WhereClauseRelation.AND,
                ButtonType.STROKED
            );
        }
    }

    addNewWhereClauseRow() {
        this.tempWhereClauseList.push({
            columnName: '',
            operator: '',
            value: '',
            nextRelation: null
        });
        this.andOrButtonGenInputs.push([
            this.getSecondaryButton(
                WhereClauseRelation.AND,
                ButtonType.STROKED
            ),
            this.getSecondaryButton(WhereClauseRelation.OR, ButtonType.STROKED)
        ]);
    }

    getSecondaryButton(buttonName: string, buttonType: ButtonType) {
        return {
            buttonName: buttonName,
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: buttonType,
            function: null
        };
    }

    saveQuery() {
        this.inCompleteClause = null;
        if (this.currentTab === Clause.GROUP_BY) {
            this.groupByColumns = this.selectedGroupByColumns;
        } else if (this.currentTab === Clause.ORDER_BY) {
            this.orderByMap = new Map(this.tempOrderByMap);
        } else if (this.currentTab === Clause.WHERE) {
            if (
                this.tempWhereClauseList.length === 1 &&
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .columnName === '' &&
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .operator === '' &&
                (this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .value === '' ||
                    this.tempWhereClauseList[
                        this.tempWhereClauseList.length - 1
                    ].value === undefined)
            ) {
                this.whereClauseList = [];
                this.addClause(this.currentTab);
                return;
            }
            if (
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .columnName === '' ||
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .operator === '' ||
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .value === '' ||
                this.tempWhereClauseList[this.tempWhereClauseList.length - 1]
                    .value === undefined
            ) {
                this.notificationService.showSnackBar('Fill all the fields');
                return;
            }
            this.whereClauseList = Helper.cloneDeep(this.tempWhereClauseList);
        }

        this.addClause(this.currentTab);
    }

    isQueryClauseChanged() {
        if (this.currentTab === Clause.WHERE) {
            if (
                (!this.whereClauseList.length &&
                    (this.tempWhereClauseList[0].columnName ||
                        this.tempWhereClauseList[0].operator ||
                        this.tempWhereClauseList[0].value)) ||
                (this.whereClauseList.length &&
                    !Helper.compareObjects(
                        this.tempWhereClauseList,
                        this.whereClauseList
                    ))
            ) {
                return true;
            }
        }

        if (this.currentTab === Clause.GROUP_BY) {
            if (
                !Helper.compareObjects(
                    this.selectedGroupByColumns,
                    this.groupByColumns
                )
            ) {
                return true;
            }
        }

        if (this.currentTab === Clause.ORDER_BY) {
            if (this.checkOrderByClause()) {
                return true;
            }
        }
        return false;
    }

    getConfirmationModalForCancel(clause: Clause) {
        this.modalService.openConfirmationModal({
            modalName: 'Discard Changes',
            modalIcon: null,
            contextIcon: null,
            confirmationMessage:
                'Are you sure you want to discard changes for this clause ?',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonText: 'Confirm',
            function: (modalId: Symbol) => {
                this.discardChanges(clause);
                this.widgetRef.modalService.closeModal(null, modalId);
            }
        });
    }

    // Resetting query to last saved state
    discardChanges(clause: Clause) {
        if (clause === Clause.WHERE) {
            this.setWhereClause();
        }
        if (clause === Clause.GROUP_BY) {
            this.selectedGroupByColumns = Helper.cloneDeep(this.groupByColumns);
        }
        if (clause === Clause.ORDER_BY) {
            this.tempOrderByMap = Helper.cloneDeep(this.orderByMap);
            this.setOrderByClause();
        }
        this.updateEveryClause();
    }

    checkQuery() {
        if (this.loader || !this.validateStep()) {
            return;
        }
        this.inCompleteClause = null;
        this.queryValidationError = '';
        this.showOrHideQueryTable(true);
        this.initCheckQueryTableData();
    }

    getColumnsList() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        const apiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.COLUMN_LISTING,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.isLoading = false;
            this.columnList = this.filteredColumns = response;

            // Creating a Map from columnlist for just easily accessing column label based on its id
            this.columnList.forEach((column) => {
                this.allColumnsIdLabelMap.set(column.id, column);
            });

            // init step
            this.initStep();
        };
        apiArgs.errorFunction = (error) => {
            this.isLoading = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    showOrHideQueryTable(isVisible) {
        if (isVisible !== this.runQuery) {
            setTimeout(() => {
                this.editorOptions = { ...this.editorOptions };
            }, 100);
        }
        this.runQuery = isVisible;
    }

    downloadReport() {
        this.downloadLoader = true;
        const input = Helper.cloneDeep(this.updateInputData());
        input.graph = {
            y: this.selectedColumnsTextType.map((row) => row.id),
            x: this.selectedColumnsNumberType.map((row) => row.id)
        };
        this.customCostMonitoringWidgetService.downloadReport(
            this.widgetRef,
            ApiUrls.CUSTOM_MONITORING_BUILD_QUERY_REPORT,
            input,
            ContentType.EXCEL,
            this.inputData.widgetName,
            () => {
                this.downloadLoader = false;
            }
        );
    }

    updateInputData(draft = false, nextStep = false) {
        const queryMap = new Map<Clause, any>();
        queryMap.set(Clause.SELECT, this.selectedColumns);
        queryMap.set(Clause.WHERE, this.whereClauseList);
        queryMap.set(Clause.GROUP_BY, this.groupByColumns);
        queryMap.set(
            Clause.ORDER_BY,
            this.orderByMap && this.orderByMap.size
                ? Helper.mapToObj(this.orderByMap)
                : {}
        );
        queryMap.set(
            Clause.FROM,
            this.selectedClause.includes(Clause.FROM) ? true : false
        );

        queryMap.set(
            Clause.ORDER_BY,
            this.orderByMap && this.orderByMap.size
                ? Helper.mapToObj(this.orderByMap)
                : {}
        );

        const inputData = Helper.cloneDeep(this.inputData);
        inputData.query = Helper.mapToObj(queryMap);
        inputData.queryString = this.query;
        inputData.draft = nextStep ? this.inputData.draft : draft;
        inputData.columnsToRename = Helper.mapToObj(this.columnNameMap);
        return inputData;
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (this.loader || buttonRef.loader || this.isViewMode) {
            return;
        }

        this.customCostMonitoringWidgetService.saveAsDraft(
            buttonRef,
            this.widgetRef,
            this.updateInputData(this.isEdit ? this.inputData.draft : true),
            () => {
                buttonRef.loader = false;
                this.loader = false;
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
                this.notificationService.showSnackBar(
                    'Data successfully saved'
                );
            },
            () => {
                buttonRef.loader = false;
                this.loader = false;
            },
            this.widgetId,
            () => {
                this.loader = true;
            }
        );
    }

    validateStep(draft = false) {
        if (!draft) {
            if (this.selectedColumns.length < 2) {
                this.queryValidationError = `At least two columns should be added in the 'Select Clause'`;
                return false;
            }
            if (!this.selectedColumnsTextType.length) {
                this.queryValidationError = `At least one 'TEXT' type column should be added in the 'Select Clause'`;
                return false;
            }
            if (!this.selectedColumnsNumberType.length) {
                this.queryValidationError = `Either 'Cost' or 'Usage' column should be added in the 'Select Clause'`;
                return false;
            }
            if (!this.selectedColumns.length) {
                this.queryValidationError = `'Select Clause' should be added`;
                return false;
            }
            if (!this.selectedClause.includes(Clause.FROM)) {
                this.queryValidationError = `'From Clause' should be added`;
                return false;
            }
        }

        return true;
    }

    nextStep(buttonRef: IButtonGeneratorInput) {
        this.showOrHideQueryTable(false);
        if (this.loader || buttonRef.loader) {
            return;
        }

        if (this.isQueryClauseChanged()) {
            this.inCompleteClause = this.currentTab;
            return;
        }

        if (!this.validateStep()) {
            return;
        }

        const input = Helper.cloneDeep(this.updateInputData());
        input.graph = {
            y: this.selectedColumnsTextType.map((row) => row.id),
            x: this.selectedColumnsNumberType.map((row) => row.id)
        };
        buttonRef.loader = true;
        this.loader = true;
        const apiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.CHECK_QUERY,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.POST
        };
        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);
        apiArgs.input = input;
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Query verified successfully'
            );
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(2, {
                    inputData: this.updateInputData(),
                    textTypeColumns: this.selectedColumnsTextType,
                    numberTypeColumns: this.selectedColumnsNumberType,
                    columnList: this.columnList.map((row) => row.id),
                    queryData: response
                });
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.loader = false;
            if (error && error.error && error.error.message) {
                this.queryValidationError = error.error.message;
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    'Error while getting data',
                    true
                );
            }
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    tabDropped(event) {
        this.updateCurrentTab(
            this.queryClauseList[event.previousIndex].id,
            event.previousIndex
        );
    }
    ngOnDestroy() {
        this.resetModal.unsubscribe();
    }
}
interface IColumnListData {
    id: string;
    label: string;
    type: ColumnType;
    cost: boolean;
    usage: boolean;
}

interface IWhereClauseData {
    columnName: string;
    operator: string;
    value: string;
    nextRelation: WhereClauseRelation;
}

enum ColumnType {
    NUMBER = 'Number',
    TEXT = 'Text'
}

enum Order {
    ASC = 'asc',
    DESC = 'desc'
}

enum WhereClauseRelation {
    AND = 'AND',
    OR = 'OR'
}
