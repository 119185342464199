<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <mat-label>
                {{ field.label }}
                <span
                    *ngIf="field.required === undefined || field.required"
                    class="mat-form-field-required-marker no-margin"
                >
                    &nbsp;*
                </span>
            </mat-label>
            <mat-chip-list
                #chipList
                [formControl]="control"
                [errorStateMatcher]="matcher"
            >
                <!--Iterate by array of data and display a chip for each value.-->
                <mat-chip
                    *ngFor="
                        let chipName of formGroup.controls[field.name].value;
                        let i = index
                    "
                    removable
                    (removed)="onRemoveChip(field, i)"
                >
                    {{ chipName }}
                    <!--Icon that is used as a button when 'removable' set to true.-->
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addChip(field, $event)"
                    [type]="field.type ? field.type : 'text'"
                />
            </mat-chip-list>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <mat-chip-list
                #chipList
                [formControl]="control"
                [errorStateMatcher]="matcher"
            >
                <!--Iterate by array of data and display a chip for each value.-->
                <mat-chip
                    *ngFor="
                        let chipName of formGroup.controls[field.name].value;
                        let i = index
                    "
                    removable
                    (removed)="onRemoveChip(field, i)"
                >
                    {{ chipName }}
                    <!--Icon that is used as a button when 'removable' set to true.-->
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addChip(field, $event)"
                    [type]="field.type ? field.type : 'text'"
                />
            </mat-chip-list>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row {{ field.extraClass }}">
        <span class="form-label">
            {{ field.label }}
            <span *ngIf="field.required" class="color-warn"> &nbsp;* </span>

            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
        </span>
        <mat-form-field
            [appearance]="'legacy'"
            class="form-input"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <mat-chip-list
                #chipList
                [formControl]="control"
                [errorStateMatcher]="matcher"
            >
                <!--Iterate by array of data and display a chip for each value.-->
                <mat-chip
                    *ngFor="
                        let chipName of formGroup.controls[field.name].value;
                        let i = index
                    "
                    removable
                    (removed)="onRemoveChip(field, i)"
                >
                    <span class="mat-value">{{ chipName }}</span>
                    <!--Icon that is used as a button when 'removable' set to true.-->
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addChip(field, $event)"
                    [type]="field.type ? field.type : 'text'"
                />
            </mat-chip-list>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <mat-chip-list
                #chipList
                [formControl]="control"
                [errorStateMatcher]="matcher"
            >
                <!--Iterate by array of data and display a chip for each value.-->
                <mat-chip
                    *ngFor="
                        let chipName of formGroup.controls[field.name].value;
                        let i = index
                    "
                    removable
                    (removed)="onRemoveChip(field, i)"
                >
                    {{ chipName }}
                    <!--Icon that is used as a button when 'removable' set to true.-->
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addChip(field, $event)"
                    [type]="field.type ? field.type : 'text'"
                />
            </mat-chip-list>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
