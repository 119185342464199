import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconSideNavigation } from '../../classes/IconSideNavigation';
import { LegacySideNavigation } from '../../classes/LegacySideNavigation';
import { IconType } from '../../enums/IconType';
import { SideMenuType } from '../../enums/SideMenuType';
import { TraversalPurpose } from '../../enums/TraversalPurpose';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { ConfigCacheService } from '../../services/cache/config-cache/config-cache.service';
import { SideMenuCacheService } from '../../services/cache/side-menu-cache/side-menu-cache.service';
import { HttpService } from '../../services/http/http-main/http.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from '../../services/white-labeling/white-labeling.service';
import { DetailedSideNavigation } from './../../classes/DetailedSideNavigation';
import { GlobalDataService } from './../../services/global-data/global-data.service';
import { AuditEvidenceSaveConfirmationService } from '../../services/assessment/audit-evidence-save-confirmation.service';

@Component({
    selector: 'app-n-level-traversal-menu-view',
    templateUrl: './n-level-traversal-menu-view.component.html',
    styleUrls: ['./n-level-traversal-menu-view.component.sass']
})
export class NLevelTraversalMenuViewComponent
    implements OnInit, OnChanges, AfterViewInit
{
    @Input() firstLevelData;
    @Input() traversalPurpose: TraversalPurpose;
    @Input() selectedRoute: string[];
    @Input() sidenav: MatSidenav;
    @Output() leafNodesData = new EventEmitter<Map<number, any>>();
    @ViewChild('menuContent') menuContent: ElementRef<any>;

    sideMenuType: SideMenuType = SideMenuType.DETAILED;
    activeAttributes: Map<number, string[]> = new Map();
    currentLevel: BehaviorSubject<number> = new BehaviorSubject(1);
    subMenuLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
    lastLevelLoader: Map<string, boolean> = new Map(null);
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    selectedAttribues = [];
    sideNavigationData: Map<number, subMenuData> = new Map();

    selectedPath;
    lastLevelData: Map<string, any> = new Map();
    selectedPathIds: string[] = [];
    detailedMenuSelectedPath: BehaviorSubject<any> = new BehaviorSubject(null);
    detailedMenuSelectedPathIds: BehaviorSubject<string[]> =
        new BehaviorSubject([]);
    showLastLevel: Map<string, boolean> = new Map(null);
    IconType = IconType;
    companyLogo: IIcon;
    SideMenuType = SideMenuType;
    LegacySideNavigation = LegacySideNavigation;
    IconSideNavigation = IconSideNavigation;
    DetailedSideNavigation = DetailedSideNavigation;
    TraversalPurpose = TraversalPurpose;
    backArrow: IIcon = {
        type: IconType.SVG,
        class: 'back_arrow_circle',
        extraClass: 'svg-white-fill'
    };
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross'
    };

    toggleCloseIcon: IIcon = {
        type: IconType.SVG,
        class: 'collapse_menu',
        extraClass: 'svg-white-fill'
    };
    toggleOpenIcon: IIcon = {
        type: IconType.SVG,
        class: 'menu_icon',
        extraClass: 'svg-white-fill'
    };
    documentStyles;
    GlobalConfiguration = GlobalConfiguration;
    constructor(
        public httpService: HttpService,
        public ngZone: NgZone,
        public sideMenuCache: SideMenuCacheService,
        public configCacheService: ConfigCacheService,
        public globalDataService: GlobalDataService,
        public whiteLabelingService: WhiteLabelingService,
        public changedDetectorRef: ChangeDetectorRef,
        private userDataCache: UserDataCacheService,
        private auditEvidenceSaveConfirmationService: AuditEvidenceSaveConfirmationService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.documentStyles = getComputedStyle(this.document.documentElement);
    }

    ngAfterViewInit(): void {
        const secondaryTextColorValues = this.documentStyles.getPropertyValue(
            '--secondaryTextColor'
        );

        if (this.menuContent) {
            this.menuContent.nativeElement.style = `--lastLevelScrollColor: ${Helper.addOpacityToColor(
                secondaryTextColorValues,
                50
            )}`;
        }
    }

    ngOnInit(): void {
        if (this.userDataCache.isAssesseeView) {
            GlobalConfiguration.MAX_LAYERS_LIMIT = 5;
        } else {
            GlobalConfiguration.MAX_LAYERS_LIMIT = 4;
        }
        if (this.selectedRoute) {
            this.selectedPathIds = this.selectedRoute;
            this.detailedMenuSelectedPathIds.next(this.selectedRoute);
        }
        this.globalDataService.handleSelectedPath.subscribe((data) => {
            if (data) {
                setTimeout(() => {
                    this.activeAttributes.clear();
                    this.selectedPathIds = data;
                    this.selectedAttribues = data;
                    let path = '';
                    this.selectedAttribues.forEach((pathId) => {
                        path += `>>${pathId}`;
                    });
                    this.detailedMenuSelectedPath.next(path);
                    this.detailedMenuSelectedPathIds.next(data);
                }, 0);
            }
        });
        //Setting side menu type
        this.setSideMenuType();
        // Setting initial level
        this.initMenu();
        this.companyLogo = {
            type: IconType.IMAGE,
            // TODO: Change this later
            class:
                this.whiteLabelingService.whiteLableData.lightLogo ||
                this.whiteLabelingService.whiteLableData.darkLogo
        };
    }

    ngOnChanges(change) {
        if (change.selectedRoute && change.selectedRoute.currentValue) {
            this.selectedRoute = change.selectedRoute.currentValue;
            this.selectedPathIds = this.selectedRoute;
            this.detailedMenuSelectedPathIds.next(this.selectedRoute);
        }
    }

    setSideMenuType() {
        const generalConfig = this.configCacheService.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCacheService.viewId
        );
        if (generalConfig && generalConfig.sideMenuType) {
            this.sideMenuType = generalConfig.sideMenuType;
        }
    }

    initMenu() {
        if (this.sideMenuType === SideMenuType.LEGACY) {
            this.sideNavigationData.set(1, {
                level: 1,
                previousLevelData: this.firstLevelData,
                currentLevelData: null,
                currentLevelName: null
            });
        } else if (this.sideMenuType === SideMenuType.ICON) {
            this.sideNavigationData.set(1, {
                level: 1,
                currentLevelData: this.firstLevelData,
                previousLevelData: null,
                currentLevelName: null
            });
        } else if (this.sideMenuType === SideMenuType.DETAILED) {
            this.sideNavigationData.clear();
            this.currentLevel.next(1);
            this.sideNavigationData.set(1, {
                level: 1,
                previousLevelData: this.firstLevelData,
                currentLevelData: null,
                currentLevelName: null
            });
        }
    }

    public shouldPauseNavigation(navArgsToCache: any) {
        if (
            this.auditEvidenceSaveConfirmationService.getIsUnsavedDataPresent()
        ) {
            this.auditEvidenceSaveConfirmationService.setIsNavigationTriggeredWithoutSave(
                navArgsToCache
            );
            return true;
        } else {
            return false;
        }
    }
}

export interface subMenuData {
    level: number;
    currentLevelName: string;
    previousLevelData: any[];
    currentLevelData: any[];
    currentLevelIconData?;
}
