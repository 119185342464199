<!--Field type six start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_6">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #colorFieldRef
            [appearance]="'none'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <input
                matInput
                type="color"
                [name]="field.name"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (focus)="
                    field.prefixIcon ? colorFieldRef.updateOutlineGap() : ''
                "
            />
            <div class="color-label">
                <span class="heading">{{ field.label }}</span>
                <span> {{ field.suffixText }}</span>
            </div>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.suffixIcon.iconData.class }}</mat-icon
            >
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type six end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
