<ng-container
    *ngIf="!(widgetRef.loadingData | async) && cardsData && cardsData.length"
>
    <div
        class="potential-count"
        *ngIf="accounts && accounts.length && dataMap['Potentials Savings']"
    >
        <div class="general tw-w-auto">
            <span class="header-web-view tw-font-semibold"
                >Potential Savings :
            </span>
            &nbsp;
            <span class="value tw-font-bold">
                {{ dataMap["Potentials Savings"] }}
            </span>
        </div>
    </div>
</ng-container>
<div
    class="tw-flex tw-items-center tw-py-4 tw-px-0 controls tw-relative tw-top-2 tw-pl-1"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        accounts &&
        accounts.length &&
        cardsData &&
        cardsData.length
    "
>
    <div class="overview">
        <div class="consumption-heading"></div>
    </div>
    <div>
        <app-button-generator
            *ngIf="recommendationCount"
            [buttonGenInput]="recommendationCount"
        ></app-button-generator>
    </div>
    <div
        class="tw-ml-auto table-search"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS) &&
            !widgetRef.lightState
        "
    >
        <app-table-search
            [data]="cardsData"
            (changed)="filteredCardsData = $event"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) && cardsData && cardsData.length > 0
    "
>
    <div
        class="tw-overflow-auto cards-container"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS)
        "
    >
        <div
            *ngFor="let card of filteredCardsData; let i = index"
            class="tw-overflow-x-auto tw-m-5 tw-rounded-md tw-flex tw-mb-2 tw-items-center tw-justify-around tw-cursor-pointer card"
            (click)="showRiModal(card)"
            [class.getFullAccessState]="
                widgetRef.lightState && i >= widgetRef.liteViewLimit
            "
        >
            <div
                class="tw-flex-1 tw-py-2 tw-px-3 item"
                *ngFor="
                    let key of dataMap['displayEntry']
                        ? dataMap['displayEntry']
                        : objectKeys(card['displayEntry']);
                    index as i
                "
                [class.saving]="key.includes('Savings')"
            >
                <span class="tw-text-fs-400 tw-font-semibold name"
                    >{{ key }}:
                    <span class="tw-text-fs-200 tw-font-light value tw-block">{{
                        key === instanceTypes.VM_TYPE
                            ? card["displayEntry"][key] +
                              " (" +
                              card["generalInformation"][
                                  "Instance Count to be Reserved"
                              ] +
                              ")"
                            : key === instanceTypes.VM_FAMILY
                            ? card["displayEntry"][key] +
                              " (" +
                              card["generalInformation"][
                                  "Instance Flexibility Count To Reserve"
                              ] +
                              ")"
                            : card["displayEntry"][key]
                    }}</span>
                </span>
            </div>
        </div>
    </div>

    <div
        class="tw--mt-10 table-container"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            (widgetRef.visibleSections | async).has(ViewType.TABLE) &&
            tableGenInput &&
            cardsData &&
            cardsData.length
        "
    >
        <app-only-table-generator
            *ngIf="tableGenInput"
            [tableInput]="tableGenInput"
            [tableData]="tableData"
        ></app-only-table-generator>
    </div>
</div>
