<div class="form-container tw-flex tw-flex-col">
    <div class="search tw-flex tw-items-end tw-w-full tw-mb-2 tw-pl-3">
        <div
            class="icon tw-h-8 tw-w-8 tw-p-1"
            appIcon
            [data]="searchIcon"
        ></div>
        <input
            class="tw-outline-none tw-border-0 tw-w-full tw-text-fs-400 tw-p-2 tw-rounded-lg"
            (keyup)="search($event.target.value)"
            placeholder="Search"
        />
    </div>
    <mat-selection-list
        #fields
        [(ngModel)]="selectedFields"
        class="overflow-y-auto"
    >
        <mat-list-option
            checkboxPosition="before"
            *ngFor="let field of filteredFields"
            [value]="field"
        >
            {{ field.label }}
        </mat-list-option>
    </mat-selection-list>
</div>

<div class="button tw-right-1 tw-absolute tw-bottom-0">
    <app-button-generator [buttonGenInput]="buttonInput">
    </app-button-generator>
</div>
