<ng-container>
    <ng-container
        *ngIf="
            DescriptionApperance.TYPE_2 === descriptionApperanceType;
            else type1
        "
    >
        <div *ngIf="!spinnerLoader; else loader">
            <ng-container *ngIf="descriptionData">
                <div
                    class="description"
                    *ngFor="let desData of descriptionData.baseDescription"
                >
                    <p
                        [matTooltip]="desData.value | extractValueFromTag"
                        [innerHtml]="desData.value"
                    ></p>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #type1>
        <ng-container *ngIf="objectKeys(data).length">
            <div
                class="description-container"
                *ngIf="!spinnerLoader; else loader"
            >
                <div class="description" *ngIf="collapseState">
                    <div class="baseDescription">
                        <div class="description-head">
                            <span class="description-title"> Description </span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="descriptionData && !spinnerLoader">
                    <div
                        class="description"
                        *ngIf="!collapseState && descriptionData"
                    >
                        <div class="baseDescription">
                            <ng-container
                                *ngFor="
                                    let desData of descriptionData.baseDescription
                                "
                            >
                                <div class="description-head">
                                    <span class="description-title">{{
                                        desData.heading
                                            ? desData.heading
                                            : "Description"
                                    }}</span>

                                    <p
                                        *ngIf="desData.type === 'PARAGRAPH'"
                                        [innerHTML]="
                                            desData.value
                                                | descriptionContentGenerator
                                                    : sections
                                                    : selectedSection
                                                    : descriptionData
                                        "
                                    ></p>
                                    <ul *ngIf="desData.type === 'LIST'">
                                        <li
                                            *ngFor="
                                                let item of desData.listValues
                                            "
                                            [innerHTML]="
                                                item
                                                    | descriptionContentGenerator
                                                        : sections
                                                        : selectedSection
                                                        : descriptionData
                                            "
                                        ></li>
                                    </ul>
                                    <p *ngIf="desData.type === 'KEY_VALUE'">
                                        <span
                                            *ngFor="
                                                let comp of desData.map
                                                    | keyvalue;
                                                let isLast = last
                                            "
                                        >
                                            <span class="comp-name">
                                                <strong style="font-weight: 600"
                                                    >{{ comp.key
                                                    }}{{
                                                        comp.value.length
                                                            ? ": "
                                                            : ""
                                                    }}</strong
                                                >
                                            </span>
                                            <span class="com-val"
                                                >{{ comp.value
                                                }}{{ isLast ? "" : ";" }}</span
                                            >
                                        </span>
                                    </p>
                                    <div
                                        class="html-data"
                                        *ngIf="desData.type === 'HTML'"
                                        [innerHTML]="
                                            desData.value
                                                | safeRender: SanitizeTypes.HTML
                                        "
                                    ></div>
                                </div>
                            </ng-container>
                        </div>
                        <mat-radio-group
                            *ngIf="
                                descriptionData.sections &&
                                descriptionData.sections.length
                            "
                            aria-label="Select a compliance"
                            [(ngModel)]="selectedSection"
                            (change)="changeCompliance($event)"
                        >
                            <mat-radio-button
                                *ngFor="
                                    let comp of descriptionData.sections;
                                    let i = index
                                "
                                [value]="comp.sectionName"
                            >
                                <p class="common-label">
                                    {{ comp.sectionName }}
                                </p>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </ng-container>

                <span class="close-description">
                    <i
                        #descriptionSwitch
                        class="switch fas {{
                            collapseState ? 'fa-toggle-off' : 'fa-toggle-on'
                        }}"
                        (click)="
                            $event.stopPropagation();
                            collapseState = !collapseState
                        "
                    ></i>
                </span>
            </div>
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #loader>
    <div class="overlay-content">
        <div appIcon [iconName]="iconName.SPINNER" class="overlay-loader"></div>
    </div>
</ng-template>
