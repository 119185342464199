import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class UsernameValidator extends CustomValidator {
    key = CustomValidatorKey.USERNAME_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const USERNAME_REGEXP = '^[A-Za-z0-9_-]*$';
        return Validators.pattern(USERNAME_REGEXP)(control);
    }
}
