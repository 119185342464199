import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-servicenow-requests-listing',
    templateUrl: './servicenow-requests-listing.component.html',
    styleUrls: ['./servicenow-requests-listing.component.sass']
})
export class ServicenowRequestsListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    masterLoginIndex = null;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.tableInput = {
            unfitColumns: true,
            afterResponse: null,
            widgetIconData: {
                type: IconType.IMAGE,
                class: '/assets/integrations/servicenow-mini-logo.png'
            },
            selection: 'single',
            buttons: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            columns: [
                {
                    columnName: 'Number',
                    columnKey: 'number'
                },
                {
                    columnName: 'Opened By',
                    columnKey: 'openedBy'
                },
                {
                    columnName: 'Status',
                    columnKey: 'approval'
                },
                {
                    columnName: 'Short Description',
                    columnKey: 'shortDescription'
                },
                {
                    columnName: 'Opened At',
                    columnKey: 'openedAt'
                },
                {
                    columnName: 'Closed At',
                    columnKey: 'closedAt'
                }
            ]
        };
    }

    ngOnInit(): void {}
}
