import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';
import { ILoginResponse } from './../../../interfaces/api/portlets/ILoginResponse';

@Injectable({
    providedIn: 'root'
})
export class CredentialsCacheService extends CacheMain {
    static readonly MASTER_LOGIN_KEY = 'MASTER_LOGIN';
    static readonly VIEW_TOKEN_KEY = 'VIEW_TOKEN';

    constructor() {
        const CREDENTIALS_CACHE_PREFIX = 'CREDENTIALS';
        super(CREDENTIALS_CACHE_PREFIX, true);
    }

    masterLoginDiveIn(loginResponse: ILoginResponse) {
        const savedCredsArray = this.fetch(
            CredentialsCacheService.MASTER_LOGIN_KEY
        );
        let credsArray: ILoginResponse[] = [];
        if (savedCredsArray) {
            credsArray = [...savedCredsArray];
        }
        credsArray.push(loginResponse);
        this.store(CredentialsCacheService.MASTER_LOGIN_KEY, credsArray);
    }

    masterLoginDiveOut(): ILoginResponse {
        const savedCredsArray: ILoginResponse[] = this.fetch(
            CredentialsCacheService.MASTER_LOGIN_KEY
        );
        savedCredsArray.pop();
        this.store(CredentialsCacheService.MASTER_LOGIN_KEY, savedCredsArray);
        if (savedCredsArray.length) {
            return savedCredsArray[savedCredsArray.length - 1];
        }
        return null;
    }

    saveViewToken(email: string, viewId: string, bearerToken: string) {
        const existingViewTokens = this.fetch(
            CredentialsCacheService.VIEW_TOKEN_KEY
        );
        let viewTokens = {};
        if (existingViewTokens) {
            viewTokens = { ...existingViewTokens };
        }
        viewTokens[`${viewId}_${email}`] = bearerToken;
        this.store(CredentialsCacheService.VIEW_TOKEN_KEY, viewTokens);
    }

    getViewToken(email: string, viewId: string): string {
        const existingViewTokens = this.fetch(
            CredentialsCacheService.VIEW_TOKEN_KEY
        );
        if (existingViewTokens && `${viewId}_${email}` in existingViewTokens) {
            return existingViewTokens[`${viewId}_${email}`];
        }
        return null;
    }

    get masterLoginInfo(): ILoginResponse[] {
        return this.fetch(CredentialsCacheService.MASTER_LOGIN_KEY);
    }
}
