import { Component, OnInit } from '@angular/core';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-widget-documentation-markdown-guide-modal',
    templateUrl: './widget-documentation-markdown-guide-modal.component.html',
    styleUrls: ['./widget-documentation-markdown-guide-modal.component.sass'],
})
export class WidgetDocumentationMarkdownGuideModalComponent implements OnInit {
    returnIcon: IIcon = {
        type: IconType.MATICON,
        class: 'keyboard_return',
    };

    constructor() {}

    ngOnInit(): void {}
}
