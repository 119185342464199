<div class="overview">
    <div class="tw-flex tw-relative tw-items-center consumption-heading">
        <app-multi-button-generator
            *ngIf="severityButtons"
            [buttonGenInputs]="severityButtons"
        ></app-multi-button-generator>
    </div>
    <div
        class="controls"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            widgetRef.visibleSections.value.has(ViewType.CARDS) &&
            accounts &&
            accounts.length > 0
        "
    >
        <div
            class="table-search"
            *ngIf="cardsData.length && !widgetRef.lightState"
        >
            <app-table-search
                [data]="cardsData"
                (changed)="filteredCardsData = $event"
            ></app-table-search>
        </div>
    </div>
</div>
<div
    class="tw-justify-start widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        widgetRef.visibleSections.value.has(ViewType.CARDS) &&
        cardsData &&
        cardsData.length
    "
>
    <div
        class="tw-flex tw-flex-wrap tw-justify-start tw-overflow-auto cards-container"
    >
        <div
            *ngFor="let card of filteredCardsData; let i = index"
            class="tw-text-fs-200 tw-my-8 tw-flex tw-w-64 tw-flex-col tw-cursor-pointer tw-relative card"
            (click)="showRightsizingModal(card)"
            [class.getFullAccessState]="
                widgetRef.lightState && i >= widgetRef.liteViewLimit
            "
        >
            <div
                class="tw-z-10 tw-flex-1 tw-w-full tw-text-fs-800 tw-rounded head"
                [class.greenGradient]="
                    card['Display Entry']['Action'] === 'Downgrade'
                "
                [class.yellowGradient]="
                    card['Display Entry']['Action'] === 'Upgrade'
                "
            >
                <div
                    *ngIf="widgetRef.widgetData.automation"
                    (click)="
                        $event.stopPropagation();
                        widgetRef.lightState &&
                        selectedResources.length >= 1 &&
                        !card.selected
                            ? onCheckBoxChanged()
                            : null
                    "
                    matTooltip="Please click the checkbox to select the instance to downsize or upsize"
                >
                    <mat-checkbox
                        class="tw-absolute tw-top-0 tw-left-2"
                        (change)="
                            card.selected = $event.checked;
                            cardSelected(card, $event.checked)
                        "
                        [checked]="card['selected']"
                        [disabled]="
                            widgetRef.lightState &&
                            selectedResources.length >= 1 &&
                            !card.selected
                        "
                    >
                    </mat-checkbox>
                </div>
                <div
                    class="tw-flex tw-text-fs-800 tw-my-0 tw-mx-auto tw-justify-center title"
                >
                    <div
                        class="tw-flex tw-w-20 tw-h-20 tw-top-2 tw-relative tw-items-center tw-justify-center tw-rounded-full card-circle"
                    >
                        <span
                            class="tw-absolute tw-text-fs-800 tw-font-semibold status downgrade"
                            [matTooltip]="'Downgrade'"
                            *ngIf="
                                card['Display Entry']['Action'] === 'Downgrade'
                            "
                        >
                            <i class="fas fa-arrow-down tw-text-fs-1000"></i>
                        </span>
                        <span
                            class="tw-absolute tw-text-fs-800 tw-font-semibold status upgrade"
                            [matTooltip]="'Upgrade'"
                            *ngIf="
                                card['Display Entry']['Action'] === 'Upgrade'
                            "
                        >
                            <i class="fas fa-arrow-up tw-text-fs-1000"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div
                class="tw-z-0 tw-flex-f8 tw-px-4 tw-pt-10 tw-pb-5 tw-text-fs-200 tw-relative content"
            >
                <ng-container
                    *ngFor="let k of card['Display Entry'] | keyvalue"
                >
                    <ng-container *ngIf="k.key !== 'Action'">
                        <div
                            class="tw-flex tw-flex-col displayEntry"
                            [class.red-saving]="
                                k.key.includes('Savings') &&
                                card['Display Entry']['Action'] === 'Upgrade'
                            "
                            [class.blue-saving]="
                                k.key.includes('Savings') &&
                                card['Display Entry']['Action'] === 'Downgrade'
                            "
                        >
                            <p class="tw-font-semibold">
                                {{ k.key }}:
                                <span
                                    class="tw-font-normal tw-break-words tw-whitespace-nowrap"
                                >
                                    {{ k.value }}
                                </span>
                            </p>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div
                class="tw-w-full tw-flex tw-justify-center tw-items-center"
                *ngIf="card['selected']"
                (click)="$event.stopPropagation()"
            >
                <app-single-select-category-dropdown
                    class="tw-flex-1 tw-mt-1 tw-mr-1 tw-mb-0 tw-ml-2"
                    *ngIf="
                        widgetRef.widgetData.automation &&
                        widgetRef.widgetData.automation['dropdown']['type'] ===
                            'group' &&
                        widgetRef.widgetData.automation['dropdown'][
                            'selection'
                        ] === 'single'
                    "
                    [data]="
                        rightsizeOptions[card['Display Entry']['Resource Id']]
                    "
                    [groupFields]="{
                        groupBy: 'category',
                        text: 'label',
                        value: 'id'
                    }"
                    [groupWaterMark]="'Select Value'"
                    [height]="'300px'"
                    [value]="
                        selectedRightsizing[
                            card['Display Entry']['Resource Id']
                        ]
                    "
                    (change)="
                        dropdownSelectedChange(
                            card['Current']['Resource Id'],
                            $event
                        )
                    "
                >
                </app-single-select-category-dropdown>
                <app-multi-group-dropdown
                    class="tw-flex-1 tw-mt-1 tw-mr-1 tw-mb-0 tw-ml-2"
                    *ngIf="
                        widgetRef.widgetData.automation &&
                        widgetRef.widgetData.automation['dropdown']['type'] ===
                            'checkGroup' &&
                        widgetRef.widgetData.automation['dropdown'][
                            'selection'
                        ] === 'multiple'
                    "
                    [checkFields]="{
                        groupBy: 'category',
                        text: 'label',
                        value: 'id'
                    }"
                    [showSelectAll]="true"
                    [width]="'200px'"
                    [checkWaterMark]="'Select Value'"
                    [limit]="2"
                    [data]="rightsizeOptions[card['Current']['Resource Id']]"
                    [value]="
                        selectedRightsizing[card['Current']['Resource Id']]
                    "
                    (change)="
                        checkDropdownSelectedChange(
                            card['Current']['Resource Id'],
                            $event
                        )
                    "
                    [enableGroupCheckBox]="false"
                    [mode]="'CheckBox'"
                >
                </app-multi-group-dropdown>
                <div
                    class="tw-p-1 tw-mr-2 tw-rounded-full others"
                    (click)="selectedOtherResourceTypes(card)"
                    [matTooltip]="'Rightsize'"
                >
                    <i class="fas fa-globe tw-text-fs-600"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container
    *ngIf="widgetRef.visibleSections.value.has(ViewType.TABLE) && tableData"
>
    <app-only-table-generator
        [tableData]="filteredTableData"
        [tableInput]="tableGen"
        (gridRef)="agGrid = $event"
        (selectionChange)="selectedNode($event)"
    >
    </app-only-table-generator>
</ng-container>
