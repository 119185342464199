import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFilterSelection } from 'src/app/shared/interfaces/filter/IFilterSelection';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { FiltersService } from './../../../../services/filters/filters.service';
@Component({
    selector: 'app-automation-modal-filters',
    templateUrl: './automation-modal-filters.component.html',
    styleUrls: ['./automation-modal-filters.component.sass']
})
export class AutomationModalFiltersComponent implements OnInit {
    FilterStoreKey = FilterStoreKey;
    hideLogicalOperatorRadioButtons: boolean;
    widgetRef: Widget = null;
    ruleData = null;
    edit = false;
    ruleNameFormGenInput: IFormGeneratorInput = null;
    ruleNameFormGroup: FormGroup = null;
    widgetFilters: {
        heading: string;
        filters: string[];
    }[] = null;
    validateFilters = new Subject();
    invalidFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: this.goToNextStep.bind(this)
    };

    constructor(
        private modalInputData: ModalInjectedData,
        private filtersService: FiltersService,
        private multiStepFormService: MultiStepFormService
    ) {
        this.hideLogicalOperatorRadioButtons =
            modalInputData.data.widgetRef.hideLogicalOperatorRadioButtons;
    }

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data['widgetRef'];
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['edit']
        ) {
            this.edit = true;
            this.ruleData = this.modalInputData.data['stepData'];
            const widgetFilters = this.ruleData['widgetFilter'];
            let filters = {};
            widgetFilters.forEach((data) => {
                filters = { ...filters, ...data.filters };
            });
            const filtersMap = new Map(Object.entries(filters));
            this.filtersService.filtersData.set(
                FilterStoreKey.AUTOMATION_FILTERS,
                filtersMap as Map<string, IFilterSelection>
            );
        }

        let automationName = this.edit ? this.ruleData['automationName'] : null;
        this.widgetFilters =
            this.widgetRef.widgetData.widgetInfo.schedulingAutomation[
                'widgetFilters'
            ];
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
            automationName = stepData['automationName'];
        }
        this.ruleNameFormGenInput = {
            formName: 'Rule Name',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Rule Name',
                    placeholder: 'Rule Name',
                    name: 'automationName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: automationName ? automationName : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Rule name is required'
                        }
                    ]
                }
            ]
        };
    }

    updateData() {
        const filters = this.filtersService.prepareInput(
            Array.from(
                this.filtersService.filtersData
                    .get(FilterStoreKey.AUTOMATION_FILTERS)
                    .keys()
            ),
            FilterStoreKey.AUTOMATION_FILTERS
        );
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, {
                widgetFilters: new Map(Object.entries(filters)),
                ...this.ruleNameFormGroup.value
            });
    }

    goToNextStep() {
        if (this.ruleNameFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.ruleNameFormGroup);
            return;
        }
        this.filtersService.convertTempToMain(
            FilterStoreKey.AUTOMATION_FILTERS,
            false
        );
        this.validateFilters.next();
        if (this.invalidFilters.value) {
            return;
        }
        this.updateData();
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
