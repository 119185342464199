<ng-select
    style="z-index: 10"
    class="{{ filterInfo.lightMode ? 'light' : 'custom' }}"
    [items]="filteredList"
    bindLabel="label"
    [bindValue]="filterInfo?.getFullObj ? null : 'id'"
    [virtualScroll]="true"
    [multiple]="true"
    [closeOnSelect]="false"
    [loading]="showLoading"
    [placeholder]="
        filterInfo.placeholder && !selectedValues.length
            ? filterInfo.placeholder
            : 'Select ' + filterInfo.label
    "
    (change)="selectionChange($event)"
    (search)="onSearch($event)"
    [clearable]="showLoading ? false : !dummy"
    (scroll)="onScroll($event)"
    [loadingText]="loadingMessage ? loadingMessage : 'Loading...'"
    dropdownPosition="auto"
    (scrollToEnd)="onScrollToEnd()"
    [ngModel]="selectedItems"
    (clear)="emitChange('clear')"
    (close)="emitChange('close'); isOpen = false"
    (open)="isOpen = true; initValue()"
    [disabled]="disabled"
    [class.disabled]="disabled"
    [appendTo]="appendTo ? appendTo : 'body'"
>
    <ng-container>
        <ng-template
            ng-multi-label-tmp
            let-items="selectedValues"
            let-clear="clear"
        >
            <div
                class="ng-value"
                *ngIf="selectedValues.length > 0"
                [style.display]="isOpen ? 'none' : 'block'"
                [class.disabled]="dummy"
            >
                <span class="ng-value-label"
                    >{{
                        showLoading
                            ? loadingMessage
                                ? loadingMessage
                                : "Loading..."
                            : selectedValues.length + " selected"
                    }}
                </span>
            </div>
        </ng-template>
    </ng-container>
    <ng-container
        *ngIf="
            showSelectAll && !showLoading && filteredList && filteredList.length
        "
    >
        <ng-template ng-header-tmp>
            <div
                *ngIf="selectedValues && selectedValues.length === 0"
                (click)="onSelectAll('selectAll')"
                [class.disabled]="dummy"
            >
                <i class="far fa-square clickable"></i>
                <span class="common-label"> Select All</span>
            </div>
            <div
                class="selected-item"
                *ngIf="
                    selectedValues.length < tempFilteredList.length &&
                    selectedValues.length !== 0
                "
                (click)="onSelectAll('selectAll')"
                [class.disabled]="dummy"
            >
                <i class="fas fa-minus-square clickable"></i>
                <span class="common-label"> Select All</span>
            </div>
            <div
                class="selected-item"
                *ngIf="
                    selectedValues.length === tempFilteredList.length &&
                    filteredList.length > 0
                "
                (click)="onSelectAll('unSelectAll')"
                [class.disabled]="dummy"
            >
                <i class="fas fa-check-square clickable"></i
                ><span class="common-label"> Select All</span>
            </div>
        </ng-template>
    </ng-container>

    <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
    >
        <div
            appDisableElement
            [data]="
                (filterInfo.selectionLimit &&
                    filterInfo.selectionLimit === selectedItems.length) ||
                dummy
            "
            *ngIf="!item$.selected"
            title="{{ showTooltip(item) }}"
        >
            <i class="checkbox-dropdown far fa-square"></i>
            <span class="common-label">{{ item.label }}</span>
        </div>
        <div
            appDisableElement
            [data]="dummy"
            class="selected-item"
            *ngIf="item$.selected"
            title="{{ showTooltip(item) }}"
        >
            <i class="checkbox-dropdown fas fa-check-square"></i
            ><span class="common-label">{{ item.label }}</span>
        </div>
    </ng-template>
</ng-select>
<div class="dropdown-background-layer" *ngIf="isOpen"></div>
