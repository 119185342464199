<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row content-type {{ field.extraClass }}">
        <ng-container
            *ngIf="
                field.listData && field.listData.length;
                else defaultContentFieldUI
            "
        >
            <ul class="content-field-ul">
                <li class="tw-py-1" *ngFor="let item of field.listData">
                    <div class="tw-flex">
                        <span
                            class="tw-flex-1 tw-text-fs-400"
                            *ngIf="field.label"
                        >
                            {{ field.label }}
                        </span>
                        <span class="tw-flex-1 tw-text-fs-400" [title]="item">
                            {{ item }}
                        </span>
                    </div>
                </li>
            </ul>
        </ng-container>

        <ng-template #defaultContentFieldUI>
            <div class="form-label" *ngIf="field.label">
                {{ field.label }}
            </div>
            <div
                class="form-input"
                [class.tw-w-full]="!field.label"
                [title]="
                    field.showTooltip === undefined || field.showTooltip
                        ? field.contentDisplayValue
                            ? field.contentDisplayValue
                            : field.value
                        : ''
                "
                [class.tw-w-full]="!field.label"
            >
                <span #htmlContent class="html-content"></span>
            </div>
        </ng-template>
    </div>
</ng-container>
<!--Field type three end -->
