import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { CellClassParams, GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-ri-recommendation-modal',
    templateUrl: './ri-recommendation-modal.component.html',
    styleUrls: ['./ri-recommendation-modal.component.sass']
})
export class RiRecommendationModalComponent implements OnInit, AfterViewInit {
    selectedItem;
    gridRef: GridOptions;
    dataMap: any;
    consumptionBreakupArray = [];
    consumptionInformation = [];
    keys;
    tabs;
    tabData: Map<string, object[]> = new Map<string, object[]>();
    selectedTab;
    inputValue = {};
    totalObj = {};
    unitsLeft = 0;
    inputError = {};
    totalCountOfSelected = 0;
    objectKeys = Object.keys;
    tableGenInput: ITableGeneratorInput;
    tableData: object[];
    recommendationTableGenInput: ITableGeneratorInput;
    recommendationTableData: object[];
    widgetRef: Widget;

    defaultColDef: any = {
        sortable: false,
        filter: false,
        resizable: false
    };

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    @ViewChild('recommendationsContainer') recommendationTable: ElementRef<any>;

    constructor(
        public modalData: ModalInjectedData,
        private notificationsService: NotificationsService,
        public modalService: ModalService
    ) {
        this.selectedItem = modalData.data.item;
        this.dataMap = modalData.data.dataMap;
        this.widgetRef = modalData.data.widgetRef;
    }

    ngOnInit(): void {
        this.keys = Object.keys(this.selectedItem);
        this.tabs =
            this.selectedItem && this.selectedItem['amortizedCost']
                ? Object.keys(this.selectedItem['amortizedCost'])
                : [];

        if (Object.keys(this.selectedItem).includes('savingsTable')) {
            const tableData = [];
            this.selectedTab = 'savingsTable';

            const savingsData = this.selectedItem['savingsTable'];
            Object.keys(savingsData).forEach((selectedKey) => {
                const data = savingsData[selectedKey];
                data['cost'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(this.selectedTab, tableData);
        } else {
            this.selectedTab = this.tabs[0];
        }

        this.initgeneralInformationTable();
        this.initRecommendationTable();
    }

    ngAfterViewInit(): void {
        this.setColors();
    }

    setColors() {
        const styleList = [
            `--positiveColorBackground:${Helper.addOpacityToColor(
                Helper.getCssVarValue('positiveColor'),
                15
            )}`,
            `--neutralColorBackground:${Helper.addOpacityToColor(
                Helper.getCssVarValue('neutralColor'),
                15
            )}`
        ];
        this.recommendationTable.nativeElement.style = styleList.join('; ');
    }

    initgeneralInformationTable() {
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: 'value-column'
            }
        ];
        const data = this.selectedItem['generalInformation'];
        const tableData: object[] = [];
        Object.keys(this.selectedItem['generalInformation']).forEach(
            (colKey) => {
                tableData.push({ key: colKey, value: data[colKey] });
            }
        );
        this.tableData = tableData;
        this.tableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0
        };
    }

    changeTab(tab) {
        this.selectedTab = tab;
        this.gridRef.api.setRowData(this.tabData.get(tab));
    }

    displayCondition(key1, key2) {
        for (const element in this.selectedItem['savingsTable'][
            'Savings (%)'
        ]) {
            if (element.includes(key1) && element.includes(key2)) {
                return true;
            }
        }
    }

    updateTotal(item, event, action?, inputElement?) {
        this.totalCountOfSelected = 0;
        const previousInputValue = this.inputValue[item]
            ? this.inputValue[item]
            : '0';
        const previousNormalizedValue = this.totalObj[item]
            ? this.totalObj[item]
            : '0';
        const previousUnitsLeft = this.unitsLeft;
        if (event < 0) {
            this.notificationsService.showSnackBar(
                'Negative values are not accepted',
                true
            );
            this.inputValue[item] = 0;
            return;
        }
        if (action === 'plus') {
            this.totalObj[item] = item.split('|')[1] * (event + 1);
            let total1 = 0;
            Object.values(this.totalObj).forEach((value) => {
                total1 = +total1 + +value;
            });
            this.unitsLeft =
                this.selectedItem['typeDistribution']['normalizationCount'] -
                total1;

            if (
                +total1 >
                this.selectedItem['typeDistribution']['normalizationCount']
            ) {
                this.notificationsService.showSnackBar(
                    'Select an instance matching amount of units left',
                    true
                );
                this.totalObj[item] = previousNormalizedValue
                    ? previousNormalizedValue
                    : 0;
                this.inputValue[item] = previousInputValue
                    ? previousInputValue
                    : 0;
                this.inputError[item] = true;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.selectedItem['typeDistribution'][
                        'normalizationCount'
                    ] - totalUpdate;
            } else {
                this.inputValue[item] = event + 1;
                this.totalObj[item] =
                    item.split('|')[1] * this.inputValue[item];
                this.inputError[item] = false;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.selectedItem['typeDistribution'][
                        'normalizationCount'
                    ] - totalUpdate;
            }

            Object.values(this.totalObj).forEach((value) => {
                this.totalCountOfSelected = +this.totalCountOfSelected + +value;
            });
        } else {
            this.inputValue[item] = +event;
            this.totalObj[item] = item.split('|')[1] * event;
            let total = 0;
            Object.values(this.totalObj).forEach((value) => {
                total = +total + +value;
            });
            this.unitsLeft =
                this.selectedItem['typeDistribution']['normalizationCount'] -
                total;

            if (
                +total >
                this.selectedItem['typeDistribution']['normalizationCount']
            ) {
                this.notificationsService.showSnackBar(
                    'Select an instance matching amount of units left',
                    true
                );
                this.totalObj[item] = 0;
                this.inputValue[item] = 0;
                inputElement.value = '';
                this.unitsLeft = this.unitsLeft + previousNormalizedValue;
                this.inputError[item] = true;
                let totalUpdate = 0;
                Object.values(this.totalObj).forEach((value) => {
                    totalUpdate = +totalUpdate + +value;
                });
                this.unitsLeft =
                    this.selectedItem['typeDistribution'][
                        'normalizationCount'
                    ] - totalUpdate;
            } else {
                this.inputError[item] = false;
            }
            Object.values(this.totalObj).forEach((value) => {
                this.totalCountOfSelected = +this.totalCountOfSelected + +value;
            });
        }
    }

    calColspan(key1, key2) {
        let count = 0;
        Object.keys(this.selectedItem['savingsTable']['Savings (%)']).forEach(
            (element) => {
                if (element.includes(key1) && element.includes(key2)) {
                    count++;
                }
            }
        );

        return count;
    }

    isObject(val) {
        return typeof val === 'object';
    }

    checkNormalization(data) {
        if ('typeDistribution' in data) {
            if (data['typeDistribution']['instanceType'].length > 0) {
                return true;
            }
        }
        return false;
    }

    initRecommendationTable() {
        // Preparing the columns 👇
        const columns: IColumnData[] = [
            { columnKey: null, columnName: '' },
            { columnKey: null, columnName: 'Standard 1 year' },
            { columnKey: null, columnName: 'Convertible 1 year' },
            { columnKey: null, columnName: 'Standard 3 year' },
            { columnKey: null, columnName: 'Convertible 3 year' }
        ];

        columns.forEach((data: IColumnData) => {
            const colName = data.columnName;
            if (colName.includes('Standard')) {
                const year = parseInt(
                    colName.split('Standard')[1].replace(/[^0-9]/g, '')
                );
                data.children = [
                    {
                        columnKey: `Standard No-Upfront ${year}yr`,
                        columnName: 'No-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Standard Partial-Upfront ${year}yr`,
                        columnName: 'Partial-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Standard All-Upfront ${year}yr`,
                        columnName: 'All-Upfront',
                        minWidth: 200,
                        cellClass: (cellClassParams: CellClassParams) => {
                            if (
                                cellClassParams.data['cost'].includes('Savings')
                            ) {
                                return 'positive';
                            }
                        }
                    }
                ];
            } else if (colName.includes('Convertible')) {
                const year = parseInt(
                    colName.split('Convertible')[1].replace(/[^0-9]/g, '')
                );
                data.children = [
                    {
                        columnKey: `Convertible No-Upfront ${year}yr`,
                        columnName: 'No-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Convertible Partial-Upfront ${year}yr`,
                        columnName: 'Partial-Upfront',
                        minWidth: 200
                    },
                    {
                        columnKey: `Convertible All-Upfront ${year}yr`,
                        columnName: 'All-Upfront',
                        minWidth: 200,
                        cellClass: (cellClassParams: CellClassParams) => {
                            if (
                                cellClassParams.data['cost'].includes('Savings')
                            ) {
                                return 'positive';
                            }
                        }
                    }
                ];
            } else {
                data.children = [
                    {
                        columnKey: `cost`,
                        columnName: 'Cost',
                        pinned: 'left',
                        minWidth: 330,
                        cellClass: 'pinned-key'
                    }
                ];
            }
        });

        this.recommendationTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns
        };

        const amortizedCost = this.selectedItem['amortizedCost'];
        this.tabs.forEach((tab) => {
            const tableData = [];
            const selectedTabData = amortizedCost[tab];
            Object.keys(selectedTabData).forEach((selectedKey) => {
                const data = selectedTabData[selectedKey];
                data['cost'] = selectedKey;
                tableData.push(data);
            });
            this.tabData.set(tab, tableData);
        });
        this.recommendationTableData = this.tabData.get(this.selectedTab);
    }
}
