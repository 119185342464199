import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';

@Component({
    selector: 'app-servicenow-ticket-information',
    templateUrl: './servicenow-ticket-information.component.html',
    styleUrls: ['./servicenow-ticket-information.component.sass']
})
export class ServicenowTicketInformationComponent implements OnInit {
    constructor(public modalData: ModalInjectedData) {}

    public readonly impactUrgencyValueDetails = {
        1: "1 - High",
        2: "2 - Medium",
        3: "3 - Low"
    }

    ngOnInit(): void {}
}
