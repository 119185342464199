import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { StepOneData } from '../salesforce-integrations-step-one/salesforce-integration-step-one.component';

enum FormFieldData {
    LABEL = 'label'
}
@Component({
    selector: 'app-salesforce-integration-step-four',
    templateUrl: './salesforce-integration-step-four.component.html',
    styleUrls: ['./salesforce-integration-step-four.component.sass']
})
export class SalesforceIntegrationStepFourComponent implements OnInit {
    readonly formFieldHeading: string = 'Select Customer Domain';
    buttonInputs: IButtonGeneratorInput[];
    totalCustomer: CustomerData[] = [];
    customer: CustomerData[] = [];
    formFieldsLabel: string[];
    filteredFormFieldLabel: any;
    formgenInput: IFormGeneratorInput = null;
    formGroupMap: Map<string, FormGroup> = new Map<string, FormGroup>();
    stepData: StepData;
    configuredCustomers: CustomerData[] = [];
    deleteIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_v2',
        extraClass: 'svg-delete-icon-fill'
    };
    isChecked: Map<string, boolean> = new Map();
    widgetRef: Widget;
    stepOneData: StepOneData;
    resetModal: Subscription;
    step4Data: CustomerData[];
    isEdit: boolean = false;
    customerDropdownData: any[] = [];

    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data.widgetRef;
        this.isEdit = modalInjectedData.data.isEdit;
        if (this.isEdit) {
            this.step4Data =
                modalInjectedData.data['response']?.['step4']?.[
                    'customerMapping'
                ];
        }
    }

    ngOnInit(): void {
        this.stepOneData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(1);
        this.stepData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(4);
        this.totalCustomer = this.stepData['customerData'];
        this.customer = Helper.cloneDeep(this.totalCustomer);
        if (
            this.stepData['isChecked'] &&
            this.stepData['configuredCustomers'] &&
            this.stepData['formGroupMap']
        ) {
            this.isChecked = this.stepData['isChecked'];
            this.configuredCustomers = this.stepData['configuredCustomers'];
            this.formGroupMap = this.stepData['formGroupMap'];
        }
        if (this.isEdit) {
            this.getConfiguredCustomers();
        }
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                function: () => {
                    this.setUpCurrentPageData();
                    this.multiStepFormService.previousStep(
                        this.modalInjectedData.modalId
                    );
                }
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.createIntegration(buttonRef);
                }
            }
        ];
        this.formgenInput = {
            state: FormState.CREATE,
            formName: 'Account Mapping',
            submitButton: null,
            fields: [
                {
                    name: 'account',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Selects Accounts',
                    required: false,
                    label: 'Accounts',
                    hideFloatLabel: true,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo['listAccounts'],
                    apiInput: { step1: this.stepOneData },
                    afterResponse: (response) => {
                        this.customerDropdownData = response;
                    }
                }
            ]
        };
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.isChecked.clear();
            this.configuredCustomers = [];
            if (this.isEdit) {
                this.getConfiguredCustomers();
            }
        });
    }

    getConfiguredCustomers() {
        this.configuredCustomers = this.totalCustomer.filter((customer) => {
            const index = this.step4Data.findIndex(
                (configuredUser) => configuredUser.id === customer['id']
            );
            if (index !== -1) {
                this.isChecked.set(customer['id'], true);
                return customer;
            }
        });
        this.widgetRef.ngZone.run(() => {
            setTimeout(() => {
                if (this.isEdit) {
                    this.step4Data.map((each) => {
                        if (this.formGroupMap.has(each.id)) {
                            this.formGroupMap.get(each.id).patchValue({
                                account: each.accountId ? each.accountId : ''
                            });
                        }
                    });
                }
            }, 2000);
        });
    }
    setUpCurrentPageData() {
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(4, {
                customerData: this.customer,
                isChecked: this.isChecked,
                configuredCustomers: this.configuredCustomers,
                formGroupMap: this.formGroupMap
            });
    }
    toggleListing(customerData, isChecked) {
        if (isChecked) {
            this.configuredCustomers.push(customerData);
            this.isChecked.set(customerData.id, true);
        } else {
            const index = this.configuredCustomers.findIndex(
                (configuredUser) => {
                    return configuredUser['id'] === customerData.id;
                }
            );
            if (index !== -1) {
                this.configuredCustomers.splice(index, 1);
                this.isChecked.set(customerData.id, false);
                this.formGroupMap.delete(customerData.id);
            }
        }
    }
    createIntegration(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const input = this.prepareInput();
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.isEdit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        if (this.isEdit) {
            hitApi.intactUrl = hitApi.url;
            hitApi.url = hitApi.url.replace(
                '{id}',
                this.modalInjectedData.data['response']['id']
            );
        }
        hitApi.input = input;
        hitApi.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                this.isEdit
                    ? 'Integration updated successfully'
                    : 'Integrated successfully'
            );
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
            this.widgetRef.refreshWidget();
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const stepOneData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(1);
        const stepTwoData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(2)['form'];
        const stepThreeData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(3)['configuredUsers'];
        const stepFourData = this.getStepFourData();
        return {
            step1: stepOneData,
            step2: this.getStepTwoData(),
            step3: { userMapping: stepThreeData },
            step4: { customerMapping: stepFourData }
        };
    }
    getStepTwoData() {
        const stepTwoData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(2)['form'];
        stepTwoData['fields'] = stepTwoData['fields'].map((field) => {
            return Helper.renameKey(field, 'placeholder', 'placeHolder');
        });
        return stepTwoData;
    }
    getStepFourData() {
        const stepData = [];
        this.configuredCustomers.map((data) => {
            data['accountId'] = this.formGroupMap
                .get(data['id'])
                .get('account').value;
            stepData.push(data);
        });
        return stepData;
    }
    ngOnDestroy() {
        this.resetModal.unsubscribe();
    }
}
export interface StepData {
    customerData: CustomerData[];
    configuredCustomers: CustomerData[];
    isChecked: Map<string, boolean>;
    formGroupMap: Map<string, FormGroup>;
}
export interface CustomerData {
    customerName: string;
    id: string;
    label: string;
    accountId: string;
}
