import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ModalAction } from './../../../../enums/ModalAction';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-advanced-automation-modal-filters',
    templateUrl: './advanced-automation-modal-filters.component.html',
    styleUrls: ['./advanced-automation-modal-filters.component.sass']
})
export class AdvancedAutomationModalFiltersComponent implements OnInit {
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'Automatically tag all the resources connected with the selected key-value pair (resource tags). Configure tagging around the resources by selecting the key-value pair across account ids, and regions.'
                    ]
                }
            ]
        }
    ];
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    widgetRef: Widget;
    filterData: Map<string, IFilterData> = new Map();
    filtersFormGenInput: IFormGeneratorInput = null;
    filtersFormGroup: FormGroup = null;
    filterIds: [] = [];
    fieldValueMap: Map<string, any> = new Map();
    stepData = null;
    edit: boolean = false;
    dynamicKeys = {
        regions: 'regions',
        resourceTags: 'resourceTags',
        accountIds: 'accountIds'
    };
    buttonGenInput: IButtonGeneratorInput;
    buttonRef: IButtonGeneratorInput;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private filtersCacheService: FilterCacheService,
        private userCacheService: UserDataCacheService,
        private configCacheService: ConfigCacheService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.dynamicKeys = Helper.setDynamicCloudFilterKeys(this.widgetRef);
        this.filterIds =
            this.widgetRef.widgetData.widgetInfo.schedulingAutomation[
                'widgetFilters'
            ][0]['filters'];
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(1)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(1);
        } else if (modalInjectedData.data['cardData']) {
            this.stepData = modalInjectedData.data['cardData'];
        }
        this.edit = true
            ? this.modalInjectedData.data['purpose'] === ModalAction.UPDATE
            : false;
    }

    ngOnInit(): void {
        if (this.stepData) {
            this.fieldValueMap.set(
                this.dynamicKeys.accountIds,
                this.stepData['widgetFilter'][0]['filters']['accountIds']
            );
            this.fieldValueMap.set(
                this.dynamicKeys.regions,
                this.stepData['widgetFilter'][0]['filters']['regions']
            );
            this.fieldValueMap.set(
                this.dynamicKeys.resourceTags,
                this.stepData['widgetFilter'][0]['filters']['resourceTags']
            );
        } else {
            this.fieldValueMap.set(this.dynamicKeys.accountIds, []);
            this.fieldValueMap.set(this.dynamicKeys.regions, []);
            this.fieldValueMap.set(this.dynamicKeys.resourceTags, []);
        }
        this.generateFormInput();
        this.generateButton();
    }
    generateButton() {
        this.buttonGenInput = {
            buttonName: 'Next',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (buttonRef) => {
                this.buttonRef = buttonRef;
                this.nextStep();
            },
            showLoader: true
        };
    }
    generateFormInput() {
        Helper.generateFormInput(
            this.filterIds,
            this.widgetRef.httpService,
            this.filtersCacheService,
            { showKey: 'label', responseValueKey: 'dataList' },
            true,
            this.fieldValueMap,
            null,
            null,
            null,
            this.userCacheService,
            this.configCacheService
        ).subscribe((res) => {
            if (res) {
                res.fields.unshift({
                    label: 'Rule Name',
                    placeholder: 'Enter Rule Name',
                    name: 'automationName',
                    fieldType: FilterType.TEXT,
                    required: !this.edit,
                    disabled: this.edit,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Rule name is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['automationName']
                        : null
                });
                this.filtersFormGenInput = res;
            }
        });
    }
    nextStep() {
        if (this.buttonRef.loader) {
            return;
        }
        if (this.filtersFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.filtersFormGroup);
            this.filtersFormGroup.updateValueAndValidity();
            return;
        }
        this.buttonRef.loader = true;
        const filters = {
            accountIds:
                this.filtersFormGroup.value[this.dynamicKeys.accountIds],
            regions: this.filtersFormGroup.value[this.dynamicKeys.regions],
            resourceTags:
                this.filtersFormGroup.value[this.dynamicKeys.resourceTags]
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(1, {
                firstStepData: {
                    widgetFilters:
                        this.widgetRef.widgetData.widgetInfo
                            .schedulingAutomation['widgetFilters']
                },
                automationName:
                    this.filtersFormGroup.getRawValue()['automationName'],
                widgetFilter: [{ filters: filters }]
            });

        const apiInput = {
            widgetFilter: [],
            automationFilter: {
                resourceTags: filters['resourceTags']
            }
        };
        apiInput.widgetFilter = [
            {
                heading: 'General Filters',
                filters: {
                    accountIds: filters.accountIds,
                    regions: filters.regions
                }
            }
        ];
        const apiArgs: IHitApi = {
            url: `${this.widgetRef.widgetData.widgetInfo.schedulingAutomation.keysToReplicate.apiRouteSuffix}`,
            input: apiInput,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.buttonRef.loader = false;
                this.multiStepFormService.stepData
                    .get(this.modalInjectedData.modalId)
                    .set(3, response);
                this.multiStepFormService.nextStep(
                    this.modalInjectedData.modalId
                );
            },
            errorFunction: (error) => {
                this.buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error fetching data.'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
