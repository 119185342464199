<div class="sso-step-two tw-h-full tw-flex tw-flex-col tw-py-2 tw-px-4">
    <app-modal-info-template [infoData]="infoData"></app-modal-info-template>
    <div class="step-content tw-flex tw-flex-col tw-overflow-auto">
        <ng-container
            *ngFor="
                let formGenInput of formGenInputs;
                let i = index;
                let first = first;
                let last = last
            "
        >
            <div [ngClass]="i !== 0 ? 'role-form add-border' : 'role-form'">
                <div class="item">
                    <app-form-generator
                        [formGeneratorInput]="formGenInput"
                        [intitalFormGroup]="
                            formGroups.has(formGenInput.formId)
                                ? formGroups.get(formGenInput.formId)
                                : null
                        "
                        (formGroupRef)="
                            formGroups.set(formGenInput.formId, $event)
                        "
                    ></app-form-generator>
                </div>
                <div class="w-90 button tw-flex tw-justify-end tw-mb-4">
                    <div *ngIf="first">
                        <app-button-generator
                            [buttonGenInput]="addFieldButtonInput"
                            (buttonRef)="lastFormUpdate(i, formGenInput.formId)"
                        >
                        </app-button-generator>
                    </div>
                    <div *ngIf="!first">
                        <app-button-generator
                            [buttonGenInput]="deleteButtonInput"
                            (buttonRef)="
                                deleteFormGroup(i, formGenInput.formId)
                            "
                        ></app-button-generator>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="controls tw-h-8">
        <app-multi-button-generator [buttonGenInputs]="buttonInput">
        </app-multi-button-generator>
    </div>
</div>
