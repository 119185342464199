<div
	class="info-show"
	[ngClass]="extraClass ? extraClass : ''"
	*ngIf="!infoMessage.includes('span')"
>
	<span appIcon [data]="infoIcon" *ngIf="displayIcon"></span>
	<span class="info-message">{{ infoMessage }}</span>
</div>
<div
	class="info-show"
	[ngClass]="extraClass ? extraClass : ''"
	*ngIf="infoMessage.includes('span')"
>
	<span appIcon [data]="infoIcon"></span>
	<span class="info-message" [innerHTML]="infoMessage"> </span>
</div>
