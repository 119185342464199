import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FormGeneratorComponent } from '../components/form-generator/form-generator.component';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class CustomValidator {
    key: CustomValidatorKey;
    formGenRef: FormGeneratorComponent | { formGroup: FormGroup };
    validatorFunction(control: AbstractControl): ValidationErrors | null {
        return null;
    }
    customErrorMessage: string;
}
