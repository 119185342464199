<div class="wrapper">
    <div class="tabs-container tw-h-full">
        <ul class="tab-list">
            <li
                class="tab"
                [class.active]="selectedTab === Tab.ADD_CONTACT"
                (click)="changeTab(Tab.ADD_CONTACT)"
            >
                <p>Add Contact</p>

                <span
                    *ngIf="contacts && contacts.length"
                    class="delete-btn"
                    [attr.count]="contacts.length"
                    (click)="clearList('contact')"
                ></span>
            </li>
            <li
                class="tab"
                [class.active]="selectedTab === Tab.ADD_GROUP"
                (click)="changeTab(Tab.ADD_GROUP)"
            >
                <p>Add Group</p>
                <span
                    *ngIf="groups && groups.length"
                    class="delete-btn"
                    [attr.count]="groups.length"
                    (click)="clearList('group')"
                ></span>
            </li>
            <li
                class="tab"
                [class.active]="selectedTab === Tab.OPEN_DIRECTORY"
                (click)="changeTab(Tab.OPEN_DIRECTORY)"
            >
                <p>Directory</p>
            </li>
        </ul>
    </div>
    <div class="tw-h-full nagios-container">
        <div
            class="tw-h-full mat-tab-body-cont"
            *ngIf="selectedTab === Tab.ADD_CONTACT"
        >
            <form class="tw-p-2 tw-overflow-auto form">
                <div class="form-data">
                    <app-form-generator
                        *ngIf="addContactFormGenInputs"
                        [formGeneratorInput]="addContactFormGenInputs"
                        (formGroupRef)="addContactFormGroup = $event"
                    ></app-form-generator>
                    <div class="integrationName">
                        <app-form-generator
                            *ngIf="integrationFormGenInputs"
                            [formGeneratorInput]="integrationFormGenInputs"
                            (formGroupRef)="integrationFormGroup = $event"
                            (valuesChange)="depField($event, 'group')"
                        ></app-form-generator>
                    </div>
                    <div class="groupField">
                        <app-form-generator
                            *ngIf="contactsFormGenInputs"
                            [formGeneratorInput]="contactsFormGenInputs"
                            (formGroupRef)="contactsFormGroup = $event"
                            (updateControl)="updateControl = $event"
                        ></app-form-generator>
                    </div>
                    <ng-container
                        *ngIf="
                            integrationFormGroup &&
                            integrationFormGroup.get('integrationName') &&
                            integrationFormGroup.get('integrationName').value &&
                            integrationFormGroup.get('integrationName').value
                                .length
                        "
                    >
                        <ng-container *ngTemplateOutlet="groupField">
                        </ng-container>
                    </ng-container>
                    <ng-template #groupField> </ng-template>
                </div>
            </form>
            <div class="tw-flex tw-mt-2 controls">
                <div class="tw-flex extra-btns">
                    <div class="tw-flex-1 tw-flex tw-justify-start add">
                        <app-button-generator
                            *ngIf="backButton"
                            [buttonGenInput]="backButton"
                        ></app-button-generator>
                    </div>
                </div>

                <div
                    class="tw-flex-f3 tw-flex tw-justify-end tw-absolute tw-right-0 form-controls"
                >
                    <app-multi-button-generator
                        *ngIf="contactButtons"
                        [buttonGenInputs]="contactButtons"
                    ></app-multi-button-generator>
                </div>
            </div>
        </div>

        <div
            class="tw-h-full mat-tab-body-cont"
            *ngIf="selectedTab === Tab.ADD_GROUP"
        >
            <form class="tw-p-2 tw-overflow-auto form">
                <div class="form-data group">
                    <app-form-generator
                        *ngIf="addGroupFormGenInputs"
                        [formGeneratorInput]="addGroupFormGenInputs"
                        (formGroupRef)="addGroupFormGroup = $event"
                    ></app-form-generator>
                    <div class="integrationName">
                        <app-form-generator
                            *ngIf="integrationFormGenInputs"
                            [formGeneratorInput]="integrationFormGenInputs"
                            (formGroupRef)="integrationFormGroup = $event"
                            (valuesChange)="depField($event, 'contact')"
                        ></app-form-generator>
                    </div>
                    <div class="contactField">
                        <app-form-generator
                            *ngIf="contactGroupFormGenInputs"
                            [formGeneratorInput]="contactGroupFormGenInputs"
                            (formGroupRef)="contactGroupFormGroup = $event"
                            (updateControl)="updateControl = $event"
                        ></app-form-generator>
                    </div>

                    <ng-container
                        *ngIf="
                            integrationFormGroup &&
                            integrationFormGroup.get('integrationName') &&
                            integrationFormGroup.get('integrationName').value &&
                            integrationFormGroup.get('integrationName').value
                                .length
                        "
                    >
                        <ng-container *ngTemplateOutlet="contactField">
                        </ng-container>
                    </ng-container>
                    <ng-template #contactField> </ng-template>
                </div>
            </form>
            <div class="tw-flex tw-mt-2 controls">
                <div class="tw-flex extra-btns">
                    <div class="tw-flex-1 tw-flex tw-justify-start add">
                        <app-button-generator
                            *ngIf="backButton"
                            [buttonGenInput]="backButton"
                        ></app-button-generator>
                    </div>
                </div>
                <div
                    class="tw-flex-f3 tw-flex tw-justify-end tw-absolute tw-right-0 form-controls"
                >
                    <app-multi-button-generator
                        *ngIf="groupButtons"
                        [buttonGenInputs]="groupButtons"
                    ></app-multi-button-generator>
                </div>
            </div>
        </div>

        <div
            class="tw-h-full mat-tab-body-cont"
            *ngIf="selectedTab === Tab.OPEN_DIRECTORY"
        >
            <div class="tw-overflow-auto tw-mt-3 table">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex dropdown">
                        <div class="tw-p-3">
                            <span>View By:</span>
                        </div>
                        <div class="single-select-container">
                            <app-single-select-dropdown
                                [filterInfo]="statusChange"
                                (change)="statusChanged($event)"
                                [selectedValue]="
                                    this.viewBy === 'group'
                                        ? 'group'
                                        : 'contact'
                                "
                            >
                            </app-single-select-dropdown>
                        </div>
                    </div>
                    <div
                        *ngIf="filterByData && !hideFilter"
                        class="tw-flex filterBy dropdown"
                    >
                        <div class="tw-m-3">
                            <span>Filter By:</span>
                        </div>
                        <div
                            class="tw-w-44 table-search single-select-container"
                            [style.marginLeft]="'auto'"
                        >
                            <app-multi-select-dropdown
                                *ngIf="filterByData"
                                [filterInfo]="filterByData"
                                (change)="onQuickFilterChanged($event)"
                            >
                            </app-multi-select-dropdown>
                        </div>
                    </div>
                </div>
                <div *ngIf="!hideTable">
                    <app-only-table-generator
                        *ngIf="phoneBookTableGenInputs && !groupTable"
                        [widgetRef]="widgetRef"
                        [tableInput]="phoneBookTableGenInputs"
                        [apiInfo]="apiInfo"
                        (gridRef)="gridRef = $event"
                        (selectionChange)="getSelectedRow()"
                    ></app-only-table-generator>
                    <app-only-table-generator
                        *ngIf="groupTableGenInputs && groupTable"
                        [widgetRef]="widgetRef"
                        [tableInput]="groupTableGenInputs"
                        [apiInfo]="apiInfo"
                        (gridRef)="gridRef = $event"
                        (selectionChange)="getSelectedRow()"
                    ></app-only-table-generator>
                </div>
            </div>
            <div
                class="tw-flex tw-justify-between tw-absolute tw-right-0 directory-controls tw-w-full"
            >
                <app-button-generator
                    *ngIf="backButton"
                    [buttonGenInput]="backButton"
                >
                </app-button-generator>

                <app-multi-button-generator
                    *ngIf="directoryButton"
                    [buttonGenInputs]="directoryButton"
                    [options]="directoryButtonOptopns"
                ></app-multi-button-generator>
            </div>
            <div class="overlay" *ngIf="hideTable">
                <div class="overlay-content">
                    <div
                        appIcon
                        [data]="spinnerLoader"
                        class="overlay-loader"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmation>
    <div class="confirmation-modal-template-wrapper">
        <p>
            Closing this <span> will delete </span> the
            <span>existing changes made.</span>
        </p>
        <p>Click Save to update the changes.</p>
    </div>
</ng-template>

<ng-template #resetConfirmation>
    <div class="confirmation-modal-template-wrapper">
        <p>
            Resetting this <span> will clear</span> the
            <span> data entered in the current form. </span>
        </p>
        <p>Click Proceed to Reset.</p>
    </div>
</ng-template>
