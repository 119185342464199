<div
    class="controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <app-button-generator
        *ngIf="showConvention && conventionButton"
        [buttonGenInput]="conventionButton"
    ></app-button-generator>
    <app-multi-button-generator
        *ngIf="severityButtons && widgetRef.widgetData.description"
        [buttonGenInputs]="severityButtons"
    ></app-multi-button-generator>

    <div class="table-search">
        <app-table-search
            [searchFunction]="onQuickFilterChanged.bind(this)"
        ></app-table-search>
    </div>
</div>
<div
    class="automation-buttons"
    *ngIf="rowData && rowData.length"
    style="margin: 10px 0px 0 auto"
>
    <ng-container *ngIf="widgetRef.widgetData.automation">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </ng-container>
</div>
<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
        *ngIf="
            !(widgetRef.loadingData | async) &&
            rowData &&
            rowData.length &&
            widgetRef.visibleSections.value.has(ViewType.TABLE)
        "
    >
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham"
            [rowData]="filteredSearchData"
            [columnDefs]="colDefs"
            [animateRows]="true"
            [rowHeight]="37"
            [rowSelection]="'multiple'"
            [suppressFieldDotNotation]="true"
            [rowClassRules]="widgetRef.tableRowClassRules"
            [defaultColDef]="defaultColDef"
            [stopEditingWhenGridLosesFocus]="true"
            (cellValueChanged)="editRows($event)"
            [rowMultiSelectWithClick]="true"
            [icons]="agGridIcons"
            (selectionChanged)="onSelectionChanged()"
        >
        </ag-grid-angular>
    </div>
</div>
