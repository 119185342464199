import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { IMultiButtonOption } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-plan-onboarding-modal',
    templateUrl: './plan-onboarding-modal.component.html',
    styleUrls: ['./plan-onboarding-modal.component.sass']
})
export class PlanOnboardingModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    Tab = Tab;
    partnerFormInput: IFormGeneratorInput = null;
    partnerFormGroup: FormGroup;
    partnerResetForm: Function;
    subscriptionFormInput: IFormGeneratorInput = null;
    subscriptionFormGroup: FormGroup;
    subscriptionResetForm: Function;
    singleFormInput: IFormGeneratorInput = null;
    singleFormGroup: FormGroup;
    singleResetForm: Function;
    manualDetailsFormInput: IFormGeneratorInput = null;
    manualFormGroup: FormGroup;
    manualResetForm: Function;
    buttonsInput: IButtonGeneratorInput[];
    buttonOption: IMultiButtonOption = {
        layout: { justifyContent: 'space-between' }
    };
    selectedTabIndex: number = 0;
    activeTabIndex: number = 0;
    Object = Object;
    detailsTabIndex: number = 0;
    edit: boolean;
    stepData: Object;
    changeTab: boolean;
    detailsEnteredManually: boolean;
    widgetRef: Widget;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    oldSubscriptionId: string;
    singleFormUpdateControlInput: IUpdateAction;
    singleFormUpdateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    invalidCredentials: boolean;
    subscriptionId: string;
    resetSubscription: Subscription;

    constructor(private modalInput: ModalInjectedData) {
        this.edit = modalInput.data['edit'];
        this.stepData = modalInput.data['formData'];
        this.detailsTabIndex = this.detailsEnteredManually ? 1 : 0;
        this.widgetRef = modalInput.data['widgetRef'];
    }

    ngOnInit(): void {
        if (this.edit) {
            if (this.stepData['type'] !== 'INDEPENDENT') {
                this.detailsEnteredManually =
                    this.stepData['detailsEnteredManually'];
                this.selectedTabIndex = 1;
                this.activeTabIndex = 1;
                this.changeTab = true;
                this.subscriptionId =
                    this.stepData[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                        PlanAttributeKeys.SUBSCRIPTION_ID
                    ];
            }
        }

        this.prepareFormInput();
        this.generateButtons();

        this.resetSubscription =
            this.widgetRef.modalService.resetModal.subscribe(() => {
                let formData = {};
                if (!this.activeTabIndex) {
                    formData = {
                        accountName: this.stepData
                            ? this.stepData[PlanAttributeKeys.ACCOUNT_NAME]
                            : null,
                        spoc: this.stepData
                            ? this.stepData[PlanAttributeKeys.SPOC]
                            : null,
                        appId: this.stepData
                            ? this.stepData[
                                  PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                              ][PlanAttributeKeys.APP_ID]
                            : null,
                        key: null,
                        accountNumber: this.stepData
                            ? this.stepData[PlanAttributeKeys.ACCOUNT_NUMBER]
                            : null,
                        domainName: this.stepData
                            ? this.stepData[
                                  PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                              ][PlanAttributeKeys.DOMAIN_NAME]
                            : null
                    };
                    this.partnerResetForm(formData);
                    return;
                } else {
                    formData = {
                        accountName: this.changeTab
                            ? this.stepData[PlanAttributeKeys.ACCOUNT_NAME]
                            : null,

                        appId: this.changeTab
                            ? this.stepData[
                                  PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                              ][PlanAttributeKeys.APP_ID]
                            : null,
                        clientSecretKey: null,
                        domainName: this.changeTab
                            ? this.stepData[
                                  PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                              ][PlanAttributeKeys.DOMAIN_NAME]
                            : null,
                        subscriptionID: this.changeTab
                            ? this.stepData[
                                  PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                              ][PlanAttributeKeys.SUBSCRIPTION_ID]
                            : null
                    };
                    if (!this.stepData) {
                        formData[PlanAttributeKeys.SUBSCRIPTION_ID] = null;
                    }
                    this.subscriptionResetForm(formData);
                    if (this.detailsTabIndex && this.selectedTabIndex) {
                        formData = {};
                        formData = {
                            exportName: this.stepData
                                ? this.stepData[
                                      PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                  ][PlanAttributeKeys.EXPORT_NAME]
                                : null,
                            storageAccountName: this.stepData
                                ? this.stepData[
                                      PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                  ][PlanAttributeKeys.STORAGE_ACCOUNT_NAME]
                                : null,
                            containerName: this.stepData
                                ? this.stepData[
                                      PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                  ][PlanAttributeKeys.CONTAINER_NAME]
                                : null,
                            storageDirectory: this.stepData
                                ? this.stepData[
                                      PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                  ][PlanAttributeKeys.STORAGE_DIRECTORY]
                                : null
                        };
                        this.manualResetForm(formData);
                    }
                    this.hitSubscriptionIdApiOnReset();
                    if (this.singleFormGroup) {
                        if (!this.detailsEnteredManually && this.edit) {
                            formData = {
                                exportId:
                                    this.stepData[
                                        PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                    ][PlanAttributeKeys.EXPORT_ID]
                            };
                        } else {
                            formData = {
                                exportId: null
                            };
                        }

                        this.singleResetForm(formData);
                    }
                }
            });
    }
    hitSubscriptionIdApiOnReset() {
        if (!this.stepData) {
            const formFieldObject = this.subscriptionFormInput.fields.find(
                (data) => {
                    return data.name === PlanAttributeKeys.SUBSCRIPTION_ID;
                }
            );
            formFieldObject.apiInput = this.prepareSubscriptionIdPayload(
                null,
                this.stepData ? true : false,
                false
            );
            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: [PlanAttributeKeys.SUBSCRIPTION_ID]
            };

            if (this.singleResetForm) {
                this.updateControl.next(this.updateControlInput);
                this.widgetRef.changeDetectorRef.detectChanges();
            }
        } else {
            this.subscriptionFormInput.fields[5].listData = [
                {
                    id: this.subscriptionId,

                    label: this.stepData[
                        PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                    ]['subscriptionLabel']
                }
            ];
        }
    }
    generateButtons() {
        this.buttonsInput = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                showLoader: true,
                function: null,
                buttonType: ButtonType.LINK,
                customClass: 'steps-button',
                link: this.selectedTabIndex
                    ? 'assets/onboarding-pdf/azure-plan-subscription-onboarding.pdf'
                    : 'assets/onboarding-pdf/azure-plan-poc-onboarding.pdf'
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                },
                buttonType: ButtonType.FLAT,
                customClass: 'save-button'
            }
        ];
    }
    save(buttonRef: IButtonGeneratorInput) {
        this.activeTabIndex === 1
            ? Helper.markAllFieldAsTouched(this.subscriptionFormGroup)
            : Helper.markAllFieldAsTouched(this.partnerFormGroup);
        if (this.invalidCredentials) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                'Invalid credentails'
            );
            return;
        }
        if (this.subscriptionFormGroup.invalid && this.activeTabIndex) {
            return;
        }
        if (this.partnerFormGroup.invalid && !this.activeTabIndex) {
            return;
        }

        const args: IHitApi = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.modalInput.data.create
        );

        args.input = this.createPayload();
        if (this.edit) {
            args.intactUrl = args.url;
            args.url = args.url.replace('{id}', this.stepData['accountNumber']);
        }
        buttonRef.loader = true;
        args.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'Account Edited Successfully'
                    : 'Account Onboarded Successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInput.modalId
            );
            this.widgetRef.refreshWidget();
        };
        args.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.edit
                    ? 'Error While Updating Account'
                    : 'Error While Onboarding Account'
            );
        };
        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareFormInput() {
        this.partnerFormInput = {
            submitButton: null,
            state: FormState.CREATE,
            formName: 'On Board Azure Plan Account',
            fields: [
                {
                    name: PlanAttributeKeys.ACCOUNT_NAME,
                    label: 'Account Name',
                    placeholder: 'Enter Account Name',
                    appearance: 'legacy',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    value: this.stepData
                        ? this.stepData[PlanAttributeKeys.ACCOUNT_NAME]
                        : null
                },

                {
                    name: PlanAttributeKeys.APP_ID,
                    label: 'Application  ID',
                    required: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    placeholder: 'Enter Application ID',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application ID is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.APP_ID]
                        : null
                },
                {
                    name: PlanAttributeKeys.CLIENT_SECRET_KEY,
                    label: 'Secret Key',
                    required: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    placeholder: 'Enter Key',
                    showLabel: true,
                    value: null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client Secret key is required'
                        }
                    ]
                },
                {
                    label: 'Partner ID',
                    name: PlanAttributeKeys.ACCOUNT_NUMBER,
                    placeholder: 'Enter Partner ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Partner ID is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData[PlanAttributeKeys.ACCOUNT_NUMBER]
                        : null
                },
                {
                    label: 'Domain ID',
                    name: PlanAttributeKeys.DOMAIN_NAME,
                    placeholder: 'Enter Domain ID',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain ID is required'
                        }
                    ],
                    fieldType: FilterType.TEXT,
                    value: this.stepData
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.DOMAIN_NAME]
                        : null
                }
            ]
        };

        this.subscriptionFormInput = {
            state: FormState.CREATE,
            formName: '',
            submitButton: null,
            fields: [
                {
                    name: PlanAttributeKeys.ACCOUNT_NAME,
                    label: 'Account Name',
                    showLabel: true,
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Account Name',
                    required: false,
                    appearance: 'legacy',
                    value: this.changeTab
                        ? this.stepData[PlanAttributeKeys.ACCOUNT_NAME]
                        : null
                },
                {
                    name: PlanAttributeKeys.SPOC,
                    label: 'Account Spoc',
                    placeholder: 'Enter Account Spoc',
                    appearance: 'legacy',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    value: this.changeTab
                        ? this.stepData[PlanAttributeKeys.SPOC]
                        : null
                },
                {
                    name: PlanAttributeKeys.APP_ID,
                    label: 'Application (Client) ID',
                    required: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Enter Application ID',
                    value: this.changeTab
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.APP_ID]
                        : null,
                    onChange: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application ID is required'
                        }
                    ]
                },
                {
                    name: PlanAttributeKeys.CLIENT_SECRET_KEY,
                    label: 'Client Secret Key',
                    required: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    placeholder: 'Enter Key',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client Secret Key is required'
                        }
                    ],
                    value: null,
                    onChange: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    }
                },
                {
                    label: 'Domain ID',
                    name: PlanAttributeKeys.DOMAIN_NAME,
                    placeholder: 'Enter Domain Id',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain ID is required'
                        }
                    ],
                    fieldType: FilterType.TEXT,
                    value: this.changeTab
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.DOMAIN_NAME]
                        : null,
                    onChange: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    }
                },
                {
                    label: 'Subscription ID',
                    name: PlanAttributeKeys.SUBSCRIPTION_ID,
                    placeholder: 'Enter Subscription ID',
                    appearance: 'legacy',
                    showLabel: true,
                    required: true,
                    disabled: this.stepData ? true : false,
                    listDataExists: true,
                    listData: this.stepData
                        ? [
                              {
                                  id: this.subscriptionId,

                                  label: this.stepData[
                                      PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                  ]['subscriptionLabel']
                              }
                          ]
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Subscription ID is required'
                        }
                    ],
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    autoSelectDropDownData: true,

                    value: this.changeTab ? this.subscriptionId : null,
                    apiInfo: this.widgetRef.widgetData.widgetInfo[
                        'additionalApisForWidget'
                    ]['getSubscription']
                        ? this.widgetRef.widgetData.widgetInfo[
                              'additionalApisForWidget'
                          ]['getSubscription']
                        : null,
                    apiInput: this.edit
                        ? this.prepareSubscriptionIdPayload(
                              this.subscriptionFormGroup,
                              true
                          )
                        : null,

                    afterResponse: () => {
                        this.invalidCredentials = false;

                        if (
                            this.subscriptionFormGroup.value['appId'] &&
                            this.subscriptionFormGroup.value[
                                'clientSecretKey'
                            ] &&
                            this.subscriptionFormGroup.value['domainName'] &&
                            !this.subscriptionFormInput.fields[5].listData
                        ) {
                            this.invalidCredentials = true;

                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                'Invalid Credentials'
                            );
                        } else {
                            this.getExportNameData();
                        }
                    }
                }
            ]
        };

        this.singleFormInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Export Name',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    showLabel: true,
                    placeholder: 'Select Export Name',
                    name: PlanAttributeKeys.EXPORT_ID,

                    appearance: 'legacy',
                    apiInput: this.edit
                        ? this.prepareSubscriptionIdPayload(
                              this.singleFormGroup,
                              true,
                              true
                          )
                        : null,
                    apiInfo: this.widgetRef.widgetData.widgetInfo[
                        'additionalApisForWidget'
                    ]['getExport']
                        ? this.widgetRef.widgetData.widgetInfo[
                              'additionalApisForWidget'
                          ]['getExport']
                        : null,
                    value: this.stepData
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.EXPORT_ID]
                        : '',
                    listDataExists: true,
                    listData:
                        this.stepData &&
                        this.stepData[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                            'exportId'
                        ]
                            ? [
                                  {
                                      id:
                                          this.stepData &&
                                          this.stepData[
                                              PlanAttributeKeys
                                                  .PLAN_ACCOUNT_ATTRIBUTE
                                          ][PlanAttributeKeys.EXPORT_ID],
                                      label:
                                          this.stepData &&
                                          this.stepData[
                                              PlanAttributeKeys
                                                  .PLAN_ACCOUNT_ATTRIBUTE
                                          ][PlanAttributeKeys.EXPORT_NAME]
                                  }
                              ]
                            : []
                }
            ]
        };
        this.manualDetailsFormInput = {
            state: FormState.CREATE,
            submitButton: null,
            formName: '',
            fields: [
                {
                    label: 'Export Name',
                    showLabel: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',

                    placeholder: 'Enter Export Name',
                    name: PlanAttributeKeys.EXPORT_NAME,
                    value: this.detailsEnteredManually
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.EXPORT_NAME]
                        : null
                },
                {
                    label: 'Storage Account Name',
                    showLabel: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',

                    placeholder: 'Enter Storage Account Name',
                    name: PlanAttributeKeys.STORAGE_ACCOUNT_NAME,
                    value: this.detailsEnteredManually
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.STORAGE_ACCOUNT_NAME]
                        : null
                },
                {
                    label: 'Container Name',
                    showLabel: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',

                    placeholder: 'Enter Container Name',
                    name: PlanAttributeKeys.CONTAINER_NAME,
                    value: this.detailsEnteredManually
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.CONTAINER_NAME]
                        : null
                },
                {
                    label: 'Storage Directory',
                    showLabel: true,
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',

                    placeholder: 'Enter Storage Directory Name',
                    name: PlanAttributeKeys.STORAGE_DIRECTORY,
                    value: this.detailsEnteredManually
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.STORAGE_DIRECTORY]
                        : null
                }
            ]
        };
    }
    checkFormFields(formGroup) {
        const formFieldObject = this.subscriptionFormInput.fields.find(
            (data) => {
                return data.name === PlanAttributeKeys.SUBSCRIPTION_ID;
            }
        );
        if (!this.stepData) {
            formFieldObject.value = null;
            formFieldObject.listData = [];

            this.subscriptionFormGroup
                .get(PlanAttributeKeys.SUBSCRIPTION_ID)
                .setValue(null);
        }
        if (
            formGroup.value[PlanAttributeKeys.APP_ID] &&
            formGroup.value[PlanAttributeKeys.CLIENT_SECRET_KEY] &&
            formGroup.value[PlanAttributeKeys.DOMAIN_NAME]
        ) {
            formFieldObject.apiInput = {
                azurePlanAccountsAttributes: {
                    domainName: formGroup.value[PlanAttributeKeys.DOMAIN_NAME],
                    appId: formGroup.value[PlanAttributeKeys.APP_ID],
                    clientSecretKey:
                        formGroup.value[PlanAttributeKeys.CLIENT_SECRET_KEY]
                }
            };

            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: [PlanAttributeKeys.SUBSCRIPTION_ID]
            };

            if (this.subscriptionFormGroup) {
                this.updateControl.next(this.updateControlInput);
                this.widgetRef.changeDetectorRef.detectChanges();
            }
        }
    }
    hitExportNameApi($event) {
        if (
            $event[PlanAttributeKeys.SUBSCRIPTION_ID] !== this.oldSubscriptionId
        ) {
            this.oldSubscriptionId = $event[PlanAttributeKeys.SUBSCRIPTION_ID];
            this.getExportNameData();
        }
    }
    getExportNameData() {
        if (this.singleFormGroup) {
            this.singleFormUpdateControlInput = {
                action: UpdateAction.REFRESH,
                controls: [PlanAttributeKeys.EXPORT_ID]
            };
            if (!this.stepData) {
                this.singleFormGroup
                    .get(PlanAttributeKeys.EXPORT_ID)
                    .setValue(null);
                this.singleFormInput.fields[0].value = null;
            }
            const payloadData = this.prepareSubscriptionIdPayload(
                this.subscriptionFormGroup
            );
            payloadData[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                PlanAttributeKeys.SUBSCRIPTION_ID
            ] = this.stepData
                ? this.subscriptionId
                : this.subscriptionFormGroup.value[
                      PlanAttributeKeys.SUBSCRIPTION_ID
                  ];

            this.singleFormInput.fields[0].apiInput = payloadData;

            if (this.singleFormGroup) {
                this.singleFormUpdateControl.next(
                    this.singleFormUpdateControlInput
                );
                this.widgetRef.changeDetectorRef.detectChanges();
            }
        }
    }
    prepareSubscriptionIdPayload(
        formGroup?: FormGroup,
        editState?: boolean,
        exportNameDataRequired?: boolean
    ) {
        const data = {
            azurePlanAccountsAttributes: {
                domainName:
                    formGroup && formGroup.value[PlanAttributeKeys.DOMAIN_NAME]
                        ? formGroup.value[PlanAttributeKeys.DOMAIN_NAME]
                        : editState
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.DOMAIN_NAME]
                        : '',
                appId:
                    formGroup && formGroup.value[PlanAttributeKeys.APP_ID]
                        ? formGroup.value[PlanAttributeKeys.APP_ID]
                        : editState
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.APP_ID]
                        : '',
                clientSecretKey:
                    formGroup &&
                    formGroup.value[PlanAttributeKeys.CLIENT_SECRET_KEY]
                        ? formGroup.value[PlanAttributeKeys.CLIENT_SECRET_KEY]
                        : editState
                        ? this.stepData[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.CLIENT_SECRET_KEY]
                        : ''
            }
        };
        if (exportNameDataRequired) {
            data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                PlanAttributeKeys.SUBSCRIPTION_ID
            ] = this.subscriptionId;
        }

        return data;
    }
    tabChanged($event) {
        if ($event.index === this.activeTabIndex) {
            return;
        }
        const confirmModal: IConfirmationModal = {
            modalName: 'Message',
            confirmationMessage:
                'Switching the tabs will discard all the entered information',
            modalAutoHeight: true,
            hideCancelButton: true,
            function: (modalId: Symbol) => {
                this.selectedTabIndex = $event.index;
                this.activeTabIndex = $event.index;
                this.resetForm();
                this.widgetRef.modalService.closeModal(null, modalId);
                this.generateButtons();
            },
            disableClose: true,
            fontSize: 1,
            closeCallBack: (modalId: Symbol) => {
                this.selectedTabIndex = this.activeTabIndex;
            },
            buttonText: 'Continue',
            buttonColorType: ButtonColorType.PRIMARY
        };
        this.widgetRef.modalService.openConfirmationModal(confirmModal);
    }
    resetForm() {
        if (this.singleFormGroup) {
            this.singleResetForm({});
        }
        if (this.partnerFormGroup) {
            this.partnerResetForm({});
        }
        if (this.manualFormGroup) {
            this.manualResetForm({});
        }
        if (this.subscriptionFormGroup) {
            this.subscriptionResetForm({});
        }
    }
    createPayload() {
        const data = { azurePlanAccountsAttributes: {} };

        if (!this.activeTabIndex) {
            for (const [key, value] of this.Object.entries(
                this.partnerFormGroup.value
            )) {
                if (
                    !(
                        key === PlanAttributeKeys.ACCOUNT_NUMBER ||
                        key === PlanAttributeKeys.ACCOUNT_NAME
                    )
                ) {
                    data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][key] = value;
                } else {
                    data[key] = value;
                }
            }
            data['type'] = this.edit ? 'INDEPENDENT' : 'PARTNER';
            return data;
        }
        for (const [key, value] of this.Object.entries(
            this.subscriptionFormGroup.value
        )) {
            if (
                !(
                    key === PlanAttributeKeys.ACCOUNT_NAME ||
                    key === PlanAttributeKeys.SPOC
                )
            ) {
                data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][key] = value;
            } else {
                data[key] = value;
            }
            if (this.stepData) {
                data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                    PlanAttributeKeys.SUBSCRIPTION_ID
                ] = this.subscriptionId;
            }
        }

        data['type'] = this.edit
            ? this.modalInput.data?.['formData']?.['type']
            : 'SUBSCRIPTION';
        if (!this.detailsTabIndex) {
            data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                PlanAttributeKeys.EXPORT_ID
            ] = this.singleFormGroup.value[PlanAttributeKeys.EXPORT_ID];
        } else {
            for (const [key, value] of this.Object.entries(
                this.manualFormGroup.value
            )) {
                data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][key] = value;
            }
        }

        return data;
    }
    billingSectionTab($event) {
        this.detailsTabIndex = $event.index;
        this.singleResetForm({});
        this.manualResetForm({});
    }

    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
enum Tab {
    PARTNER_ACCOUNT = 'Partner Account',
    SUBSCRIPTION_ACCOUNT = 'Subscription Account',
    ONE_CLICK = 'Enter Details On One-Click',
    MANUALLY = 'Enter Details Manually'
}
enum PlanAttributeKeys {
    PLAN_ACCOUNT_ATTRIBUTE = 'azurePlanAccountsAttributes',
    APP_ID = 'appId',
    EXPORT_ID = 'exportId',
    EXPORT_NAME = 'exportName',
    STORAGE_ACCOUNT_NAME = 'storageAccountName',
    CONTAINER_NAME = 'containerName',
    STORAGE_DIRECTORY = 'storageDirectory',
    SUBSCRIPTION_ID = 'subscriptionID',
    CLIENT_SECRET_KEY = 'clientSecretKey',
    ACCOUNT_NUMBER = 'accountNumber',
    ACCOUNT_NAME = 'accountName',
    SPOC = 'spoc',
    DOMAIN_NAME = 'domainName',
    KEY = 'key',
    ACCOUNT_SPOC = 'accountSpoc'
}
