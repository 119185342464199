<!-- field type one start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_1">
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName: formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName: formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <legend
            class="dropdown-legend"
            *ngIf="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).value &&
                      formGroup.get(field.groupByKey).get(field.name).value
                          .length
                    : formGroup.get(field.name).value &&
                      formGroup.get(field.name).value.length
            "
        >
            {{ field.placeholder ? field.placeholder : field.label }}
            <span
                *ngIf="field.required === undefined || field.required"
                class="mat-form-field-required-marker"
            >
                &nbsp;*
            </span>
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
                >{{ field.prefixIcon.iconData.class }}</mat-icon
            >
        </legend>
        <ng-select
            [class.field-required]="
                field.required !== undefined ? field.required : true
            "
            style="z-index: 10"
            [appearance]="field.appearance ? field.appearance : 'outline'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [groupBy]="field.groupBy ? field.groupBy : null"
            [selectableGroupAsModel]="false"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [virtualScroll]="true"
            (change)="onValueChange($event)"
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                <div *ngIf="!item$.selected" class="ng-select-option-custom">
                    <span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
            </ng-template>
            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
            >
                <div *ngIf="!item$.selected" class="ng-select-option-custom">
                    <span class="common-label {{ item.extraClass }}">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <span class="common-label {{ item.extraClass }}">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
            </ng-template>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!-- field type one end -->

<!-- field type two start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_2">
    <div
        [class]="
            'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
        "
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
        [class.disabled]="
            field.groupByKey
                ? formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                : formGeneratorInput.state === FormState.IDLE ||
                  (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
        "
        [matTooltip]="
            field.groupByKey
                ? (field.populateFromControl &&
                      !formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl)) ||
                  (formGroup
                      .get(field.groupByKey)
                      .get(field.populateFromControl) &&
                      (!formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl).value ||
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value.length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName: formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
                : (field.populateFromControl &&
                      !formGroup.get(field.populateFromControl)) ||
                  (formGroup.get(field.populateFromControl) &&
                      (!formGroup.get(field.populateFromControl).value ||
                          !formGroup.get(field.populateFromControl).value
                              .length))
                ? 'Select ' +
                  (
                      field.populateFromControl
                      | fieldDataFromControlName: formGeneratorInput.fields
                  ).label +
                  ' values first'
                : null
        "
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'before'"
    >
        <ng-select
            [class.field-required]="
                field.required !== undefined ? field.required : true
            "
            style="z-index: 10"
            [appearance]="field.appearance ? field.appearance : 'outline'"
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [groupBy]="field.groupBy ? field.groupBy : null"
            [selectableGroupAsModel]="false"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            dropdownPosition="auto"
            [clearable]="true"
            [formControl]="control"
            [errorStateMatcher]="matcher"
            [virtualScroll]="true"
            (change)="onValueChange($event)"
            [compareWith]="compareWith"
            (open)="showOverlay = true"
            (close)="showOverlay = false"
        >
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                <div *ngIf="!item$.selected" class="ng-select-option-custom">
                    <span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
            </ng-template>
            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
            >
                <div *ngIf="!item$.selected" class="ng-select-option-custom">
                    <span class="common-label {{ item.extraClass }}">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <span class="common-label {{ item.extraClass }}">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
            </ng-template>
        </ng-select>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!-- field type two end -->

<!-- field type three start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_3">
    <div class="form-row">
        <div *ngIf="field.showLabel" class="form-label">
            {{ field.label }}
            <span *ngIf="field.required" class="mat-form-field-required-marker">
                &nbsp;*
            </span>
        </div>
        <div
            class="ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline form-input"
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [class.disabled]="
                field.groupByKey
                    ? formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                    : formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
            "
            [matTooltip]="
                field.groupByKey
                    ? (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                    : (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName: formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
            "
            [matTooltipShowDelay]="1000"
            [matTooltipPosition]="'before'"
        >
            <ng-select
                [class.field-required]="
                    field.required !== undefined ? field.required : true
                "
                style="z-index: 10"
                [appearance]="field.appearance ? field.appearance : 'outline'"
                [bindValue]="
                    field.getKey || field.getKey === null ? field.getKey : 'id'
                "
                [bindLabel]="field.showKey ? field.showKey : 'label'"
                [items]="
                    !loading.get(field.name) && field.listData
                        ? field.listData
                        : null
                "
                [groupBy]="field.groupBy ? field.groupBy : null"
                [selectableGroupAsModel]="false"
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [loading]="loading.get(field.name)"
                [appendTo]="'body'"
                [loadingText]="
                    field.loadingMessage ? field.loadingMessage : 'Loading...'
                "
                dropdownPosition="auto"
                [clearable]="true"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [virtualScroll]="true"
                (change)="onValueChange($event)"
                [compareWith]="compareWith"
                (open)="showOverlay = true"
                (close)="showOverlay = false"
            >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <span class="bold-8">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <span class="bold-8">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                >
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <span class="bold-8 {{ item.extraClass }}">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <span class="bold-8 {{ item.extraClass }}">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                </ng-template>
            </ng-select>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!-- field type three end -->

<!-- field type four start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_4">
    <div class="form-row">
        <div
            class="ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline"
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [class.disabled]="
                field.groupByKey
                    ? formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                    : formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
            "
            [matTooltip]="
                field.groupByKey
                    ? (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                    : (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName: formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
            "
            [matTooltipShowDelay]="1000"
            [matTooltipPosition]="'before'"
        >
            <ng-select
                [class.field-required]="
                    field.required !== undefined ? field.required : true
                "
                style="z-index: 10"
                [appearance]="field.appearance ? field.appearance : 'outline'"
                [bindValue]="
                    field.getKey || field.getKey === null ? field.getKey : 'id'
                "
                [bindLabel]="field.showKey ? field.showKey : 'label'"
                [items]="
                    !loading.get(field.name) && field.listData
                        ? field.listData
                        : null
                "
                [groupBy]="field.groupBy ? field.groupBy : null"
                [selectableGroupAsModel]="false"
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [loading]="loading.get(field.name)"
                [appendTo]="'body'"
                [loadingText]="
                    field.loadingMessage ? field.loadingMessage : 'Loading...'
                "
                dropdownPosition="auto"
                [clearable]="true"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [virtualScroll]="true"
                (change)="onValueChange($event)"
                [compareWith]="compareWith"
                (open)="showOverlay = true"
                (close)="showOverlay = false"
            >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <span class="bold-8">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <span class="bold-8">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                >
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <span class="bold-8 {{ item.extraClass }}">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <span class="bold-8 {{ item.extraClass }}">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                </ng-template>
            </ng-select>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!-- field type four end -->

<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
