import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class IntegrationCacheService extends CacheMain {
    static readonly ZENDESK_SCRIPT_KEY = 'ZENDESK_SCRIPT';
    static readonly JIRADESK_SCRIPT_KEY = 'JIRADESK_SCRIPT';
    static readonly SHOW_JIRADESK_SCRIPT_KEY = 'SHOW_JIRADESK_SCRIPT';
    static readonly ZOHO_SCRIPT_KEY = 'ZOHO_SCRIPT';
    constructor() {
        const INTEGRATION_CACHE_PREFIX = 'INTEGRATION_CACHE';
        super(INTEGRATION_CACHE_PREFIX, true);
    }

    get zendeskScript() {
        return this.fetch(IntegrationCacheService.ZENDESK_SCRIPT_KEY);
    }

    set zendeskScript(script: string) {
        this.store(IntegrationCacheService.ZENDESK_SCRIPT_KEY, script);
    }

    get jiradeskScript() {
        return this.fetch(IntegrationCacheService.JIRADESK_SCRIPT_KEY);
    }

    set jiradeskScript(script: string) {
        this.store(IntegrationCacheService.JIRADESK_SCRIPT_KEY, script);
    }

    get showJiradeskScript() {
        return this.fetch(IntegrationCacheService.SHOW_JIRADESK_SCRIPT_KEY);
    }

    set showJiradeskScript(show: boolean) {
        this.store(IntegrationCacheService.SHOW_JIRADESK_SCRIPT_KEY, show);
    }

    get zohoScript() {
        return this.fetch(IntegrationCacheService.ZOHO_SCRIPT_KEY);
    }

    set zohoScript(script: string) {
        this.store(IntegrationCacheService.ZOHO_SCRIPT_KEY, script);
    }
}
