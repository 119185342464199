import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { CreateTagConfigurationFormStepOne } from '../../../modal-templates/create-tag-configuration-Form/create-tag-ConfigurationForm-step-one/create-tag-ConfigurationForm-step-one';
import { CreateTagConfigurationFormStepTwo } from '../../../modal-templates/create-tag-configuration-Form/create-tag-configurationForm-step-two/create-tag-ConfigurationForm-step-two';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { IconType } from './../../../../enums/IconType';
import { ModalAction } from './../../../../enums/ModalAction';
import { ModalType } from './../../../../enums/ModalType';
import { ICardsGeneratorInput } from './../../../../interfaces/card-generator/ICardsGeneratorInput';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { IButtonData } from './../../../../interfaces/table-generator/IButtonData';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-tag-configuration-listing-v2',
    templateUrl: './tag-configuration-lisitng-v2.component.html',
    styleUrls: ['./tag-configuration-listing-v2.component.sass']
})
export class TagConfigurationListingV2Component {
    widgetRef: Widget;
    check = false;
    cardInput: ICardsGeneratorInput;
    loader: boolean = false;
    existingConventionList = [];
    lightState: boolean;
    cardbuttonsToEnable = new Map();
    indexOfButton: number;

    constructor(
        widgetData: WidgetInjectedData,
        private notificationService: NotificationsService,
        private modalService: ModalService,
        private cd: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
        if (this.widgetRef.lightState) {
            this.lightState = true;
        }
    }
    ngOnInit() {
        this.cardInput = {
            cardTitle: {
                infoTitle: 'Convention Name',
                infoKey: 'configurationName'
            },
            infoData: [
                {
                    infoTitle: 'Created By',
                    infoKey: 'email'
                }
            ],
            buttons: [
                {
                    buttonName: 'New Convention',
                    function: (buttonData: IButtonData) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        this.createForm();
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.CIRCLE,
                    hoverText: 'New Convention',
                    disable: this.lightState
                }
            ],

            bulkActions: [
                {
                    buttonName: 'Delete',
                    disable: this.lightState,

                    function: (
                        buttonData: IButtonData,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        this.modalService.openConfirmationModal({
                            modalName: 'Delete Conventions',

                            modalIcon: {
                                type: IconType.FONTAWSOME,

                                class: 'fas fa-trash'
                            },

                            contextIcon: {
                                type: IconType.FONTAWSOME,

                                class: 'fas fa-exclaimation-triangle'
                            },

                            confirmationMessage:
                                'Are you sure you want to delete selected conventions ?',

                            buttonColorType: ButtonColorType.WARN,

                            buttonText: 'Delete',

                            loaderOnExec: true,

                            function: (modalId: Symbol) => {
                                bulkLoader.next(true);

                                const batchDeleteInput = selectedCards.map(
                                    (selCard) => ({
                                        ruleSetName:
                                            selCard['configurationName'],

                                        email: selCard['email']
                                    })
                                );

                                this.deleteApi(
                                    modalId,

                                    null,

                                    batchDeleteInput,

                                    bulkLoader
                                );
                            }
                        });
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash',
                        extraClass: 'color-warn'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Delete Selected Conventions',
                    showLoader: false
                },
                {
                    buttonName: 'Enable',
                    function: (
                        buttonData: IButtonData,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        bulkLoader.next(true);
                        const batchEnableInput = selectedCards.map((card) => ({
                            ruleSetName: card['configurationName'],
                            emailId: card['email'],
                            configurationStatus: true
                        }));
                        this.toggleScheduler(
                            null,
                            batchEnableInput,
                            bulkLoader,
                            false
                        );
                    },
                    disable: this.lightState,

                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-success'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Enable Selected Convention',
                    showLoader: false
                },

                {
                    buttonName: 'Disable',
                    function: (
                        buttonData: IButtonData,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        bulkLoader.next(true);
                        const batchEnableInput = selectedCards.map((card) => ({
                            ruleSetName: card['configurationName'],

                            emailId: card['email'],

                            configurationStatus: false
                        }));

                        this.toggleScheduler(
                            null,

                            batchEnableInput,

                            bulkLoader,

                            true
                        );
                    },
                    disable: this.lightState,

                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-warn'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Enable Selected Conventions',
                    showLoader: false
                }
            ],
            selection: 'multiple',
            cardButtons: [
                {
                    buttonName: 'Edit Convention',
                    function: (buttonData: IButtonData, cardData) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        this.updateCard(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-edit'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Edit Convention',
                    showLoader: false,
                    disable: this.lightState
                },
                {
                    buttonName: 'Delete Convention',
                    function: (buttonData: IButtonData, cardData) => {
                        if (buttonData.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        this.deleteCard(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Convention',
                    showLoader: false,
                    disable: this.lightState
                }
            ],
            titleActions: [
                {
                    buttonName: 'Title',
                    showLoader: true,
                    function: (buttonRef: IButtonData, cardData) => {
                        if (buttonRef.disable) {
                            this.openGetFullAccess();
                            return;
                        }
                        this.toggleScheduler(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off'
                    },
                    ngStyleRenderer: (cardData) => {
                        return {
                            color: cardData['configurationStatus']
                                ? 'green'
                                : 'red'
                        };
                    },
                    hoverTextRenderer: (cardData) => {
                        return cardData['configurationStatus']
                            ? 'Disable Convention'
                            : 'Enable Convention';
                    },
                    disable: this.lightState
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            disableSelectAll: this.lightState,
            afterResponse: (response) => {
                this.setExistingConventionsList(response);

                if (this.lightState) {
                    this.indexOfButton = this.searchCardButtonName(
                        'buttons',
                        'New Convention'
                    );
                    if (this.indexOfButton !== -1) {
                        this.cardInput.buttons[this.indexOfButton].disable =
                            true;
                    }
                    if (!response[0]) {
                        this.enableCardButtons(this.cardInput);
                    } else {
                        this.cardbuttonsToEnable.set('cardButtons', []);
                        this.cardbuttonsToEnable.set('titleActions', []);

                        this.enableCardButtons(
                            this.cardInput,
                            this.cardbuttonsToEnable
                        );
                    }
                }
            }
        };
    }
    searchCardButtonName(buttonType, buttonName: string) {
        const indexOfButton = this.cardInput[buttonType].findIndex(
            (cardDetails) => {
                return cardDetails['buttonName'] === buttonName;
            }
        );

        return indexOfButton;
    }
    enableCardButtons(cardInput, buttonNames?: Map<string, []> | any) {
        if (!buttonNames) {
            this.indexOfButton = this.searchCardButtonName(
                'buttons',
                'New Convention'
            );

            if (this.indexOfButton !== -1) {
                this.cardInput.buttons[this.indexOfButton].disable = false;
            }
        } else {
            for (const [key, value] of buttonNames.entries())
                cardInput[key].forEach((data) => {
                    if (value.length) {
                        if (value.includes(data.buttonName)) {
                            data.disable = false;
                        }
                    } else {
                        data.disable = false;
                    }
                });
        }

        this.cd.detectChanges();
    }
    openGetFullAccess() {
        Helper.getFullAccess(this.modalService, PortletType.TAGCONFIGURATION);
    }
    setExistingConventionsList(cardListing) {
        this.existingConventionList = cardListing.map(
            (card) => card['configurationName']
        );
    }
    toggleScheduler(
        data?,
        input?,
        bulkLoader?: BehaviorSubject<boolean>,
        enable?: boolean
    ): void {
        const widgetRef: Widget = this.widgetRef;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['enable']
        );
        if (enable === undefined) {
            enable = data['configurationStatus'];
        }
        apiArgs.function = (response) => {
            if (bulkLoader) {
                bulkLoader.next(false);
            }
            this.widgetRef.notificationsService.showSnackBar(
                `Convention${enable ? ' disabled ' : ' enabled '} successfully`
            );            
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            if (bulkLoader) {
                bulkLoader.next(false);
            }

            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                `Error ${enable ? 'enabling' : 'disabling'} convention`
            );
            this.widgetRef.refreshWidget();
        };
        if (input) {
            apiArgs.input = input;
        } else {
            apiArgs.input = [
                {
                    email: data['email'],
                    ruleSetName: data['configurationName'],
                    configurationStatus: !data['configurationStatus']
                }
            ];
        }

        new HitApi(apiArgs, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }
    createForm() {
        const modalInfo = {
            label: 'Set Convention',
            widgetRef: this.widgetRef,
            purpose: ModalAction.CREATE
        };
        this.setModalDataAndOpen(modalInfo, null);
    }
    setModalDataAndOpen(modalInfo, stepData) {
        const modalArgs: IModalData = {
            modalName: modalInfo.label,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,

            modalPurpose: modalInfo.purpose,
            modalHeightVh: 94,
            modalWidthVw: 65,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateTagConfigurationFormStepOne,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData,
                                edit: stepData ? true : false,
                                filterIds:
                                    this.widgetRef.widgetData.widgetInfo
                                        .schedulingAutomation[
                                        'widgetFilters'
                                    ][0]['filters'],
                                existingConventionsList:
                                    this.existingConventionList
                            }
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad: CreateTagConfigurationFormStepTwo,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData,
                                edit: stepData ? true : false
                            }
                        }
                    },
                    stepName: 'Configuration'
                }
            ]
        };
        this.modalService.openModal(modalArgs);
    }
    updateCard(item) {
        const modalInfo = {
            label: 'Edit Convention',
            purpose: ModalAction.UPDATE
        };
        this.setModalDataAndOpen(modalInfo, item);
    }
    deleteCard(item) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Convention',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage:
                'Are you sure you want to delete this convention ?',
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                this.deleteApi.call(this, modalId, item);
            }
        });
    }
    deleteApi(
        modalId: Symbol,
        item?,
        input?,
        bulkLoader?: BehaviorSubject<boolean>
    ) {
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );

        if (input) {
            deleteArgs.input = input;
        } else if (item) {
            deleteArgs.input = [
                {
                    ruleSetName: item['configurationName'],
                    email: item.email
                }
            ];
        }
        deleteArgs.function = (response) => {
            this.notificationService.showSnackBar(
                'Convention deleted successfully'
            );
            if (bulkLoader) {
                bulkLoader.next(false);
            }
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while deleting convention'
            );
            if (bulkLoader) {
                bulkLoader.next(false);
            }
            this.modalService.closeModal(null, modalId);
        };

        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
