import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../services/global-data/global-data.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbData: string[] = [];

  constructor(
    public globalDataService: GlobalDataService,
  ) { }

  ngOnInit() {
    this.globalDataService.breadcrumbData.subscribe((data) => {
      this.breadcrumbData = data;    
  });
  } 
}
