import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IframeOpenerModalComponent } from '../../../modal-templates/iframe-opener-modal/iframe-opener-modal.component';

@Component({
    selector: 'app-freshservice-listing',
    templateUrl: './freshservice-listing.component.html',
    styleUrls: ['./freshservice-listing.component.sass']
})
export class FreshserviceListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    widgetIcon: IIcon = {
        class: 'assets/integrations/freshservice-mini-logo.png',
        type: IconType.IMAGE
    };
    agGrid: GridOptions = null;
    appliedFilter = null;

    statusMap = {
        2: {
            name: 'Open',
            color: 'rgb(196, 11, 19)',
            count: 0
        },
        3: {
            name: 'Pending',
            color: 'rgb(249, 253, 80)',
            count: 0
        },
        4: {
            name: 'Resolved',
            color: 'rgb(45, 201, 55)',
            count: 0
        },
        5: {
            name: 'Closed',
            color: 'rgb(0, 117, 246)',
            count: 0
        }
    };

    readonly priorityMap = {
        1: 'Low',
        2: 'Medium',
        3: 'High',
        4: 'Urgent'
    };

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Case',
                    function: (buttonRef: IButtonData) => {
                        this.openTestingModal(this.widgetRef.apiResponse);
                    },
                    showLoader: true,
                    buttonType: 'PRIMARY'
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'userTickets'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'status',
                    columnName: '',
                    pinned: 'left',
                    cellRenderer: (rowData: RowEvent) => {
                        const status = +rowData.data['status'];
                        const circleDiv = `
                            <div
                                style = "
                                    height: 14px;
                                    width: 14px;
                                    background: ${
                                        this.statusMap[status]
                                            ? this.statusMap[status].color
                                            : 'black'
                                    };
                                    border-radius: 100%;
                                "
                            >
                            </div>
                        `;
                        return circleDiv;
                    }
                },
                {
                    columnKey: 'requester',
                    columnName: 'Requester',
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data['requester']['email'];
                    }
                },
                {
                    columnKey: 'subject',
                    columnName: 'Subject'
                },
                {
                    columnKey: 'created_at',
                    columnName: 'Creation Time'
                },
                {
                    columnKey: 'created_at',
                    columnName: 'Creation Time'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority',
                    cellRenderer: (rowData: RowEvent) => {
                        return this.priorityMap[rowData.data['priority']];
                    }
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    cellRenderer: (rowData: RowEvent) => {
                        return this.statusMap[rowData.data['status']].name;
                    }
                }
            ],
            afterResponse: (response) => {
                this.overviewRender(response);
            }
        };
    }

    overviewRender(response) {
        response['userTickets'].forEach((row) => {
            const status = row['status'];
            this.statusMap[status].count++;
        });
    }

    filterStatus(statusKey) {
        if (+this.appliedFilter !== +statusKey) {
            this.appliedFilter = statusKey;
            const filteredRows = this.widgetRef.apiResponse[
                'userTickets'
            ].filter((rowData) => +rowData['status'] === +this.appliedFilter);
            this.agGrid.api.setRowData(filteredRows);
            this.agGrid.api.refreshCells();
        } else {
            this.appliedFilter = null;
            this.agGrid.api.setRowData(
                this.widgetRef.apiResponse['userTickets']
            );
            this.agGrid.api.refreshCells();
        }
    }

    openTestingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'Create Ticket',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            modalSteps: [
                {
                    stepName: 'Create Ticket',
                    stepData: {
                        componentToLoad: IframeOpenerModalComponent,
                        payload: {
                            data: response.widgetCode
                                ? response.widgetCode
                                : null
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
