import { Component, Input, OnInit } from '@angular/core';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from '../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-multi-button-generator',
    templateUrl: './multi-button-generator.component.html',
    styleUrls: ['./multi-button-generator.component.sass']
})
export class MultiButtonGeneratorComponent implements OnInit {
    @Input() buttonGenInputs: IButtonGeneratorInput[];
    @Input() options: IMultiButtonOption;

    justifyContent: string = null;
    alignItems: string = null;
    extraClass: string;

    constructor() {}
    ngOnInit(): void {
        this.initLayout();
    }
    agInit(params): void {
        this.buttonGenInputs = params['valueFormatted']['buttonGenInputs'];
        this.options = params['valueFormatted']['options'];
        this.initLayout();
    }

    initLayout() {
        if (this.options) {
            if (this.options.layout) {
                if (this.options.layout.justifyContent) {
                    this.justifyContent = this.options.layout.justifyContent;
                }
                if (this.options.layout.alignItems) {
                    this.alignItems = this.options.layout.alignItems;
                }
            }
            if (this.options.extraClass) {
                this.extraClass = this.options.extraClass;
            }
        }
    }
}
