<div class="cart-container">
    <div class="action-container">
        <strong>REPORT</strong>
    </div>

    <div class="insights-container">
        <div class="insight-title">
            <strong>Widgets</strong>
            <div class="empty">
                <app-button-generator
                    [buttonGenInput]="emptyCartButtonGenInput"
                ></app-button-generator>
            </div>
        </div>
        <div class="insight-content">
            <div class="info">
                <app-modal-info-template
                    [infoData]="infoData"
                ></app-modal-info-template>
            </div>

            <div class="insights">
                <ul>
                    <li>
                        <mat-checkbox
                            [checked]="checkIfSelectAll()"
                            (change)="selectAllClicked($event.checked)"
                            >Select All</mat-checkbox
                        >
                    </li>
                </ul>
                <ng-container *ngIf="widgetsList.length">
                    <ng-container *ngFor="let widgetKey of widgetsList">
                        <ng-container>
                            <ul>
                                <mat-checkbox
                                    [checked]="checkIfChecked(widgetKey)"
                                    (change)="
                                        onCheckBoxChanged($event, widgetKey)
                                    "
                                    [disabled]="checkIfDisabled(widgetKey)"
                                    [class.disabled]="
                                        checkIfDisabled(widgetKey)
                                    "
                                    [matTooltip]="
                                        checkIfDisabled(widgetKey)
                                            ? 'Widget selection limit reached'
                                            : ''
                                    "
                                    [value]="widgetKey"
                                    >{{ cartWidgets[widgetKey] }}</mat-checkbox
                                >

                                <mat-icon
                                    class="error delete"
                                    (click)="removeWidget(widgetKey)"
                                    >delete</mat-icon
                                >
                            </ul>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="actions">
            <app-button-generator
                *ngIf="this.showGetExistingButton"
                [buttonGenInput]="getExistingButtonGenInput"
            ></app-button-generator>

            <app-single-select-dropdown
                *ngIf="!this.showGetExistingButton"
                [filterInfo]="reportListFilterInfo | async"
                (change)="reportSelectionChanged($event)"
            >
            </app-single-select-dropdown>
            <app-button-generator
                *ngIf="this.showAddButton"
                [buttonGenInput]="addToExistingButtonGenInput"
            ></app-button-generator>
            <app-button-generator
                [buttonGenInput]="createNewButtonGenInput"
            ></app-button-generator>
        </div>
    </div>
</div>
