<div class="wrapper" *ngIf="moreInfoData">
    <div class="modal-heading">
        <div class="tab-container">
            <ng-container
                *ngFor="let headingName of getKeys(moreInfoData); index as i"
            >
                <div
                    class="each-tab each-tab-parent clickable"
                    (click)="changeTabStyle(headingName)"
                    [ngClass]="
                        tabDescription === headingName ? 'active' : 'inactive'
                    "
                >
                    <span class="selectedTab">
                        {{ headingName | camelCaseToNormalName }}
                    </span>
                </div>
                <span *ngIf="i < totalTabs" class="separate"></span>
            </ng-container>
        </div>
        <div class="shadow">
            <div
                class="close"
                (click)="
                    modalService.closeModal(null, modalInjectedData.modalId)
                "
                appIcon
                [data]="crossIcon"
            ></div>
        </div>
    </div>
    <div class="warning-message" *ngIf="warningMessageTemplate">
        <ng-container
            [ngTemplateOutlet]="warningMessageTemplate"
        ></ng-container>
    </div>
    <div class="sections">
        <ng-container *ngFor="let heading of getKeys(moreInfoData); index as i">
            <div class="section">
                <div
                    class="section-heading"
                    id="{{ heading }}"
                    #tableHead
                    *ngIf="!noAccordion"
                >
                    <span>{{ heading | camelCaseToNormalName }}</span>
                    <i
                        class="clpse clickable"
                        (click)="toShowFieldOrNot(heading)"
                        [ngClass]="
                            showData[i][heading]
                                ? 'fas fa-angle-up'
                                : 'fas fa-angle-down'
                        "
                    ></i>
                </div>
                <div class="section-description">
                    <table *ngIf="showData[i][heading]">
                        <div
                            class="xd-container xd-container-1"
                            [class.no-flow]="noAccordion"
                        >
                            <thead>
                                <tr>
                                    <ng-container
                                        *ngIf="
                                            isArray(moreInfoData[heading]) && moreInfoData[heading].length;
                                            else headingBlock
                                        "
                                    >
                                        <th
                                            *ngFor="
                                                let keyName of getKeys(
                                                    moreInfoData[heading][0]
                                                )
                                            "
                                        >
                                            {{
                                                keyName | camelCaseToNormalName
                                            }}
                                        </th>
                                    </ng-container>
                                    <ng-template #headingBlock>
                                        <th
                                            *ngFor="
                                                let keyName of getKeys(
                                                    moreInfoData[heading]
                                                )
                                            "
                                        >
                                            <span
                                                *ngIf="
                                                    ignoreCamelCaseToNormalPipe;
                                                    else noPipe
                                                "
                                            >
                                                {{
                                                    keyName
                                                        | camelCaseToNormalName
                                                }}
                                            </span>
                                            <ng-template #noPipe>
                                                {{ keyName }}
                                            </ng-template>
                                        </th>
                                    </ng-template>
                                </tr>
                            </thead>
                        </div>
                        <div
                            class="xd-container xd-container-2"
                            [class.flow]="noAccordion"
                        >
                            <tbody>
                                <ng-container
                                    *ngIf="
                                        isArray(moreInfoData[heading]);
                                        else rowsBlock
                                    "
                                >
                                    <tr
                                        *ngFor="
                                            let value of moreInfoData[heading];
                                            index as i
                                        "
                                    >
                                        <td
                                            *ngFor="
                                                let obj of getKeys(
                                                    moreInfoData[heading][i]
                                                )
                                            "
                                        >
                                            {{
                                                moreInfoData[heading][i][obj] &&
                                                moreInfoData[heading][i][
                                                    obj
                                                ].trim()
                                                    ? moreInfoData[heading][i][
                                                          obj
                                                      ]
                                                    : "-"
                                            }}
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #rowsBlock>
                                    <tr>
                                        <td
                                            *ngFor="
                                                let keyValue of getKeys(
                                                    moreInfoData[heading]
                                                )
                                            "
                                        >
                                            {{
                                                moreInfoData[heading][
                                                    keyValue
                                                ] &&
                                                validateTrim(
                                                    moreInfoData[heading][
                                                        keyValue
                                                    ]
                                                )
                                                    ? moreInfoData[heading][
                                                          keyValue
                                                      ]
                                                    : "-"
                                            }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </div>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>
</div>
