import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { ButtonType } from './../../../../enums/ButtonType';
import { CreateWidgetDocumentationStepOneModalComponent } from './../../../modal-templates/widget-documentation-modal/create-widget-documentation-step-one-modal/create-widget-documentation-step-one-modal.component';
import { CreateWidgetDocumentationStepTwoModalComponent } from './../../../modal-templates/widget-documentation-modal/create-widget-documentation-step-two-modal/create-widget-documentation-step-two-modal.component';
import { WidgetDocumentationInfoModalComponent } from './../../../modal-templates/widget-documentation-modal/widget-documentation-info-modal/widget-documentation-info-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-widget-documentation-listing',
    templateUrl: './widget-documentation-listing.component.html',
    styleUrls: ['./widget-documentation-listing.component.sass']
})
export class WidgetDocumentationListingComponent implements OnInit {
    tableInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    apiResponse: any;
    @ViewChild('bulkDeleteList') bulkDeleteTemplateRef: TemplateRef<any>;
    selectedWidgets: any;
    agGrid: GridOptions;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.applyOperationalActions();

        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
    }

    applyOperationalActions() {
        this.widgetRef.operationalActions.next(
            new Map().set('Bulk Action', [
                {
                    icon: {
                        type: IconType.SVG,
                        class: 'trash',
                        text: BulkActions.DELETE,
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openConfirmationModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data),
                            BulkActions.DELETE
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                },
                {
                    icon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-file-alt',
                        text: BulkActions.PUBLISH,
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openConfirmationModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data),
                            BulkActions.PUBLISH
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                },
                {
                    icon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-eye-slash',
                        text: BulkActions.DRAFT,
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openConfirmationModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data),
                            BulkActions.DRAFT
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                }
            ])
        );
    }

    setUpBasics() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'cmsWidgetInfoList'
            },
            tableHeight: 300,
            selection: 'multiple',
            selectionLimit: GlobalConfiguration.WIDGET_DOCUMENTATION_LIMIT,
            afterResponse: (res: any) => {
                this.apiResponse = res;
            },
            buttons: [
                {
                    buttonName: 'Add Widget Documentation',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: () => {
                        this.openAddWidgetDocumentation();
                    }
                }
            ],
            rowHeight: 40,
            headerHeight: 45,
            columns: [
                {
                    columnKey: 'name',
                    columnName: 'Widget Name',
                    flex: 1
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    flex: 1,

                    cellRenderer: (rowData: RowEvent) => {
                        return Helper.generateColumnButtons(
                            [
                                {
                                    buttonName:
                                        rowData.data['documentStatus'] ===
                                        'Active'
                                            ? 'Published'
                                            : rowData.data['documentStatus'],
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: ''
                                    },
                                    buttonColor:
                                        rowData.data['documentStatus'] ===
                                        'Draft'
                                            ? 'warning'
                                            : 'success',
                                    showLoader: true,
                                    function: (buttonRef: IButtonData) => {}
                                }
                            ],
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            null,
                            'text',
                            { justifyContent: 'flex-start' }
                        );
                    }
                },
                {
                    columnKey: 'updatedOn',
                    columnName: 'Last Updated',
                    flex: 1
                },
                {
                    columnKey: '',
                    columnName: 'Action',
                    filter: false,
                    pinned: 'right',
                    maxWidth: 200,
                    minWidth: 200,
                    headerClass: 'grid-cell-data-centred',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const buttons: IButtonData[] = [];
                        const editButton = {
                            buttonName: 'Edit',
                            buttonColorType: ButtonColorType.INFO,
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonType: ButtonType.TEXT,
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                buttonRef.loader = true;
                                this.widgetRef.hitWidgetMoreInfoApi(
                                    rowData.data['id'],
                                    true,
                                    (res) => {
                                        buttonRef.loader = false;
                                        this.openAddWidgetDocumentation(
                                            true,
                                            rowData.data['id'],
                                            res
                                        );
                                    }
                                );
                            }
                        };

                        const moreInfoButton = {
                            buttonName: 'More Info',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                const modalData: IModalData = {
                                    modalName: rowData.data.name,
                                    modalIcon: null,
                                    modalType: ModalType.MIDDLE,
                                    sourceId: this.widgetRef.uniqueIdentity,
                                    modalHeightVh: 76,
                                    modalWidthVw: 53,
                                    hideSteps: true,
                                    modalSteps: [
                                        {
                                            stepData: {
                                                componentToLoad:
                                                    WidgetDocumentationInfoModalComponent,
                                                payload: {
                                                    data: {
                                                        widgetRef:
                                                            this.widgetRef,
                                                        widgetId:
                                                            rowData.data.id
                                                    }
                                                }
                                            },
                                            stepName: ''
                                        }
                                    ]
                                };

                                this.widgetRef.modalService.openModal(
                                    modalData
                                );
                            }
                        };

                        const deleteButton = {
                            buttonName: 'Delete',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonColorType: ButtonColorType.WARN,
                            buttonType: ButtonType.TEXT,
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                this.openConfirmationModal(
                                    [rowData.data],
                                    BulkActions.DELETE
                                );
                            }
                        };
                        buttons.push(moreInfoButton);

                        buttons.push(editButton);

                        buttons.push(deleteButton);
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            noDataMessage: Messages.NO_WIDGET_DOCUMENTATION,
            showNotificationBadgeOnSelection:
                this.widgetRef.showNotificationBadge
        };
    }

    openConfirmationModal(data, type: BulkActions) {
        this.selectedWidgets = data;

        if (this.selectedWidgets.length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one widget'
            );
            return;
        }

        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    type === BulkActions.DELETE
                        ? this.widgetRef.widgetData.widgetInfo.delete
                        : type === BulkActions.PUBLISH
                        ? this.widgetRef.widgetData.widgetInfo[
                              'additionalApisForWidget'
                          ]['publish']
                        : this.widgetRef.widgetData.widgetInfo[
                              'additionalApisForWidget'
                          ]['hide']
                );

                const deleteIds = data.map((each) => each.id);

                apiArgs.input = {
                    widgetIds: deleteIds
                };

                apiArgs.function = (response) => {
                    this.widgetRef.modalService.closeModal(null, modalId);

                    this.widgetRef.notificationsService.showSnackBar(
                        `${
                            type === BulkActions.DELETE
                                ? 'Deleted'
                                : type === BulkActions.PUBLISH
                                ? 'Published'
                                : 'Draft'
                        } successfully`
                    );

                    this.widgetRef.refreshWidget();
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.modalService.closeModal(null, modalId);
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error Deleting.'
                    );
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            buttonColorType: ButtonColorType.WARN,
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage:
                type === BulkActions.DRAFT
                    ? `Are you sure you want to set the following widget(s) documentation as draft?`
                    : `Are you sure you want to ${
                          type === BulkActions.DELETE
                              ? 'delete'
                              : type === BulkActions.PUBLISH
                              ? 'publish'
                              : 'draft'
                      } the documentation of the following widget(s) ?`,
            buttonText: 'Confirm',
            cancelButtonType: 'ACCENT',
            cancelButtonAttribute: 'stroked',
            loaderOnExec: true,
            bodyTemplate: this.bulkDeleteTemplateRef,
            fontSize: 1.12
        };

        const modalData: IModalData = {
            modalName: `${
                type === BulkActions.DELETE
                    ? 'Delete'
                    : type === BulkActions.PUBLISH
                    ? 'Publish'
                    : 'Draft'
            } Widget Documentation`,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 40,
            modalWidthVw: 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openAddWidgetDocumentation(
        isEdit = false,
        widgetId = null,
        templateData = null
    ) {
        const modalData: IModalData = {
            modalName: 'Add Widget Documentation',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalAutoWidth: true,
            modalAutoHeight: true,
            hideSteps: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            CreateWidgetDocumentationStepOneModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isEdit,
                                widgetId
                            }
                        }
                    },
                    stepHeightVh: 70,
                    stepName: 'Create',
                    headingText: 'Add Widget Documentation'
                },
                {
                    stepData: {
                        componentToLoad:
                            CreateWidgetDocumentationStepTwoModalComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                widgetId,
                                hideBackButton: isEdit ? true : false
                            }
                        }
                    },
                    stepName: 'Add/Update Widget Documentation',
                    headingText: 'Add/Update Widget Documentation',
                    resetModal: true,
                    stepHeightVh: 80
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}

enum BulkActions {
    PUBLISH = 'Bulk Publish',
    DRAFT = 'Bulk Draft',
    DELETE = 'Bulk Delete'
}
