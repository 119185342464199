import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { SalesforceIntegrationStepOneComponent } from '../../../modal-templates/integration/salesforce-modal/salesforce-integrations-step-one/salesforce-integration-step-one.component';
import { SalesforceIntegrationStepFourComponent } from './../../../modal-templates/integration/salesforce-modal/salesforce-integration-step-four/salesforce-integration-step-four.component';
import { SalesforceIntegrationStepTwoComponent } from './../../../modal-templates/integration/salesforce-modal/salesforce-integration-step-two/salesforce-integration-step-two.component';
import { SalesforceIntegrationsStepThreeComponent } from './../../../modal-templates/integration/salesforce-modal/salesforce-integrations-step-three/salesforce-integrations-step-three.component';
import { SalesforceIntegrationsTableComponent } from './../../../modal-templates/integration/salesforce-modal/salesforce-integrations-table/salesforce-integrations-table.component';

@Component({
    selector: 'app-salesforce-integration',
    templateUrl: './salesforce-integration.component.html',
    styleUrls: ['./salesforce-integration.component.sass']
})
export class SalesforceIntegrationComponent implements OnInit, AfterViewInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isIntegrated = false;
    isConfigured: Boolean = false;
    response: IIntegrationApiResponse;
    tableData;
    constructor(private widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/salesforce.png',
            buttons: []
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        //Add Plus Button
        this.integrationInput.buttons.push({
            buttonName: 'Integrate',
            buttonIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-plus'
            },
            buttonType: ButtonType.CIRCLE,
            buttonColorType: ButtonColorType.SUCCESS,
            function: () => {
                this.openIntegrationsModal(response);
            }
        });
        //Add listing Button
        this.integrationInput.buttons.push({
            buttonName: 'View Existing',
            buttonIcon: {
                type: IconType.SVG,
                class: 'list_bar',
                extraClass: ''
            },
            buttonType: ButtonType.CIRCLE,
            buttonColorType: ButtonColorType.SUCCESS,
            function: () => {
                this.openIntegrationsListingModal(response);
            }
        });
    }
    openIntegrationsModal(response, isEdit?: boolean) {
        const modalArgs: IModalData = {
            modalName: 'Salesforce Integration',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepOneComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                configurationNameList:
                                    this.tableData && this.tableData.length
                                        ? this.tableData.map(
                                              (each) =>
                                                  each['configurationName']
                                          )
                                        : null
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 60
                },
                {
                    stepName: 'Configure Form',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 60
                },
                {
                    stepName: 'User Access',
                    resetModal: true,
                    stepData: {
                        componentToLoad:
                            SalesforceIntegrationsStepThreeComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 50
                },
                {
                    stepName: 'Customer Access',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SalesforceIntegrationStepFourComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef,
                                isEdit
                            }
                        }
                    },
                    stepHeightVh: 85,
                    stepWidthVw: 55
                }
            ],
            modalHeightVh: 80,
            modalWidthVw: 70,
            modalIcon: undefined
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    openIntegrationsListingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'Salesforce Integrations',
            modalIcon: undefined,
            modalType: ModalType.MIDDLE,
            extraStepContainerClass: 'remove-form-space',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalSteps: [
                {
                    stepName: 'Integrations List',
                    stepData: {
                        componentToLoad: SalesforceIntegrationsTableComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 60,
            modalWidthVw: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        if (response && response['length']) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
        this.tableData = response;
    }
}
