export class DefaultValues {
    static readonly EMAIL_DATA = {
        NEW_USER_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                EMAIL: '{{email}}',
                PASSWORD: '{{password}}',
                DOMAIN_NAME: '{{domainName}}',
                COMPANY_NAME: '{{companyName}}'
            },
            SUBJECT: 'Welcome to {{companyName}}',
            CONTENT: `
                <p style="margin-bottom: 8px; margin-top: 0px">Hi {{user}},</p>
                <br />
                <p style="margin-bottom: 8px">Welcome to {{companyName}}!</p>
                <br />
                <p style="margin-bottom: 8px">Your account has been created successfully.</p>
                <br />
                <p style="margin-bottom: 8px">Please find your credentials below:</p>
                <br />
                <p style="margin-bottom: 8px">
                    <br />
                    <b> Email:</b>
                    {{email}}
                    <br />
                    <b> Password:</b>
                    {{password}}
                    <br />
                    <b> Link: </b>
                    <a href="{{domainName}}"> {{domainName}} </a>
                    <br />
                    <b> Domain ID: </b>
                    {{domainId}}
                    <br /><br />
                </p>
                <p>
                    <br />
                    <font style="line-height: 1.6">
                        Thank you for choosing {{companyName}}!
                    </font>
                    <br />
                    <br />
                    Regards,<br />Team {{companyName}}<b>&nbsp;</b>
                </p>
            `
        },
        NEW_CUSTOMER_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                EMAIL: '{{email}}',
                PASSWORD: '{{password}}',
                DOMAIN_NAME: '{{domainName}}',
                COMPANY_NAME: '{{companyName}}'
            },
            SUBJECT: 'Welcome to {{companyName}}',
            CONTENT: `
                <p style="margin-bottom: 8px; margin-top: 0px">Hi {{user}},</p>
                <br />
                <p style="margin-bottom: 8px">Welcome to {{companyName}}!</p>
                <br />
                <p style="margin-bottom: 8px">Your account has been created successfully.</p>
                <br />
                <p style="margin-bottom: 8px">Please find your credentials below:</p>
                <br />
                <p style="margin-bottom: 8px">
                    <br />
                    <b> Email:</b>
                    {{email}}
                    <br />
                    <b> Password:</b>
                    {{password}}
                    <br />
                    <b> Link: </b>
                    <a href="{{domainName}}"> {{domainName}} </a>
                    <br />
                    <b> Domain ID: </b>
                    {{domainId}}
                    <br /><br />
                </p>
                <p>
                    <br />
                    <font style="line-height: 1.6">
                        Thank you for choosing {{companyName}}!
                    </font>
                    <br />
                    <br />
                    Regards,<br />Team {{companyName}}<b>&nbsp;</b>
                </p>
            `
        },
        NEW_REPORT_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                COMPANY_NAME: '{{companyName}}',
                REPORT_NAME: '{{reportName}}'
            },
            SUBJECT: '{{reportName}} | {{companyName}}',
            CONTENT: `
                <p>
                    Hi,
                    <br /><br />
                    Thank you for using {{companyName}}! <br /><br />
                    Your report has been generated successfully, Please find the attachment.
                    <br /><br />
                    Thanks,
                    <br /><br />
                    {{companyName}}
                    Team
                    <b>&nbsp; </b>
                </p>
                `
        },
        NEW_ALERT_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                COMPANY_NAME: '{{companyName}}',
                ALERT_NAME: '{{alertName}}',
                TABLE: '{{table}}'
            },
            SUBJECT: 'Alert! {{alertName}}',
            CONTENT: `
                <p>
                    Hi User, <br /><br />
                    Attention is required for the following:<br />
                    {{table}}
                    <br /><br />
                    Regards,
                    <br />{{companyName}}<br />
                </p>
                `
        },
        NEW_BUDGET_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                COMPANY_NAME: '{{companyName}}',
                BUDGET_NAME: '{{budgetName}}',
                MONITORING_TYPE: '{{monitoringType}}',
                TOTAL_BUDGET: '{{totalBudget}}',
                THRESHOLD_VALUE: '{{thresholdValue}}'
            },
            SUBJECT:
                'Attention Please | Your budget has breached the {{monitoringType}} limit',
            CONTENT: `
                <p>Hi User,</p>
                <p>
                    Your set budget {{budgetName}} has breached the {{monitoringType}} limit
                    of {{totalBudget}} against.
                </p>
                <p>
                    The observed average percentage increase from your threshold budget is
                    {{thresholdValue}}.
                </p>
                `
        },
        NEW_AUTOMATION_EMAIL: {
            MACROS: {
                WIDGET_NAME: '{{widgetName}}',
                AUTOMATION_NAME: '{{automationName}}',
                NEXT_SCHEDULE_RUN: '{{nextScheduleRun}}',
                COMPANY_NAME: '{{companyName}}'
            },
            SUBJECT: 'Automation Alert!',
            CONTENT: `
                <p>Hi,</p>
                <p>Greeting of the day.</p>
                <p>
                    This is to notify you regarding your automation rules that you have
                    scheduled.
                </p>
                <br />
                <table
                    width="100%"
                    border="1px solid black"
                    border-collapse="collapse"
                    cellpadding="10"
                    style="
                        font-size: 14px;
                        font-family: Helvetica, sans-serif;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                    "
                >
                    <tr style="border: 1px solid var(--backgroundColor)">
                        <th>Automation Rule</th>
                        <th>Service</th>
                        <th>Date</th>
                        <th>Time</th>
                    </tr>
                    <tr style="border: 1px solid var(--backgroundColor)">
                        <td>{{automationName}}</td>
                        <td>{{widgetName}}</td>
                        <td>{{nextScheduleRun}}</td>
                    </tr>
                </table>
                `
        },
        RESET_PASSWORD_EMAIL: {
            MACROS: {
                USER_NAME: '{{user}}',
                COMPANY_NAME: '{{companyName}}',
                DATA: '{{date}}',
                TIME: '{{time}}'
            },
            SUBJECT: '{{companyName}} Password Reset Sucessfully',
            CONTENT: `
                Dear User, <br /><br />{{companyName}} received a request to reset the
                password for your account on {{date}} at {{time}}. <br /><br />
                If you'd like to reset your password, please click the button below:
                <br /><br />
                <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                        font-size: 14px;
                        font-family: Helvetica, sans-serif;
                        text-align: center;
                        width: 100%;
                        height: 20px;
                    "
                >
                    <tr>
                        <td>&nbsp;</td>
                        <td width="250">&nbsp;</td>
                        <td
                            align="center"
                            valign="middle"
                            height="36"
                            style="background: var(--accentColor)"
                        >
                            <a
                                href="{{token}}"
                                style="
                                    font-size: 16px;
                                    color: var(--secondaryTextColor);
                                    font-weight: bold;
                                    border: none;
                                    cursor: pointer;
                                    outline: none;
                                    font-family: Helvetica, sans-serif;
                                    text-decoration: none;
                                "
                                >RESET PASSWORD
                            </a>
                        </td>
                        <td width="250">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </table>
                <a
                    href="#"
                    style="
                        font-size: 16px;
                        color: var(--secondaryTextColor);
                        border: none;
                        cursor: pointer;
                        outline: none;
                    "
                >
                </a>
                <br /><br />
                Please note that this is a one-time use link. If you do not want to reset your
                password, please ignore this message and your password will not be changed.
                <br /><br />
                If you have any questions or concerns, please contact us at
                {{companyName}} Support. <br /><br />
                Team {{companyName}}
            `
        },
        FORGET_PASSWORD_EMAIL: {
            MACROS: { COMPANY_NAME: '{{companyName}}' },
            SUBJECT: `{{companyName}} Password Reset Successfully`,
            CONTENT: `
                <p>Dear User !</p>
                <p>As requested, Your password has been changed successfully</p>
                `
        }
    };
    static readonly WIDGET_DESCRIPTION = `
        <h2>Description</h2>
        <p>Description of widget being created</p>
    `;
}
