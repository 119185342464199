<div class="search" [class.applyOutlineStyling]="applyOutlineStyling">
    <label
        *ngIf="!applyOutlineStyling"
        appIcon
        [data]="searchIcon"
        [for]="inputId"
    >
    </label>
    <input
        [id]="inputId"
        (keyup)="search($event.target.value)"
        [placeholder]="searchPlaceholder ? searchPlaceholder : 'Search'"
    />
</div>
