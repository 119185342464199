import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { Node } from './Node';

export class Tree<T> {
    root: Node<T>;

    constructor() {
        // this.root = Helper.generateUniqueKey(12);
        this.root = null;
    }

    addNode(data: T, parentNodeId: string): Node<T> {
        const parent: Node<T> = parentNodeId
            ? this.findNode(parentNodeId)
            : null;
        const node = new Node<T>(data, [], parentNodeId);
        if (parent) {
            parent.children.push(node);
        } else if (!this.root) {
            this.root = node;
        } else {
            throw 'Root already exists';
        }
        return node;
    }

    updateNodeData(data: T, nodeId: string) {
        const node: Node<T> = nodeId ? this.findNode(nodeId) : null;
        if (node) {
            node.data = data;
        } else {
            throw 'Node does not exist';
        }
    }

    findNode(nodeId: string): Node<T> {
        let _node: Node<T> = null;
        this.traverseTree(
            (treeNode: Node<T>, parentNode: Node<T>, nodeFound: Function) => {
                if (treeNode.nodeId === nodeId) {
                    _node = treeNode;
                    nodeFound();
                }
            }
        );
        return _node;
    }

    deleteNode(nodeId: string) {
        let _nodeDeleted: Node<T> = null;
        this.traverseTree(
            (treeNode: Node<T>, parentNode: Node<T>, nodeFound: Function) => {
                if (treeNode.nodeId === nodeId) {
                    _nodeDeleted = treeNode;
                    parentNode.children = parentNode.children.filter(
                        (node) => node.nodeId !== _nodeDeleted.nodeId
                    );
                    nodeFound();
                }
            }
        );
        return _nodeDeleted;
    }

    /**
     *
     * @param callBack Callback will have three params node: Node<T>, parentNode: Node<T> and cancelTraversalFunction
     */
    traverseTree(callBack: Function) {
        const queue: {
            parentNode: Node<T>;
            node: Node<T>;
        }[] = [
            {
                parentNode: null,
                node: this.root
            }
        ];
        let traverse = true;
        if (callBack) {
            while (queue.length && traverse) {
                const node = queue.shift();
                callBack(node.node, node.parentNode, () => {
                    traverse = false;
                });
                if (!traverse) {
                    continue;
                }
                for (const childNode of node.node.children) {
                    queue.push({
                        node: childNode,
                        parentNode: node.node
                    });
                }
            }
        }
    }

    getNodeById(nodeId: string) {
        const node: Node<T> = nodeId ? this.findNode(nodeId) : null;
        return node;
    }

    getLevelOfNode(node: Node<IAttributeInfo>) {
        const path: Node<IAttributeInfo>[] = [node];
        while (node.nodeId !== this.root.nodeId) {
            node = this.findNode(node.parentNodeId);
            path.push(node);
        }
        return path.length;
    }
}
