import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IScoringAssessmentDiscreetOptionsModal } from 'src/app/shared/interfaces/modal/IScoringAssessmentDiscreetOptionsModal';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-scoring-assessment-discreet-options-modal',
    templateUrl: './scoring-assessment-discreet-options-modal.component.html',
    styleUrls: ['./scoring-assessment-discreet-options-modal.component.sass'],
})
export class ScoringAssessmentDiscreetOptionsModalComponent implements OnInit {
    public assignAssessmentFormGroup: FormGroup;
    private functionToExecute = null;
    private valuesAllowedAsScore: Array<string | number> = [];
    public modalParams: IScoringAssessmentDiscreetOptionsModal = null;
    public descriptionText: string;
    private loaderOnExec: boolean;
    public buttonInputs: IButtonGeneratorInput[];
    public extraClass: string;
    public customButtonGeneratorInput: {
        buttonGenInput: IButtonGeneratorInput[];
        options?: IMultiButtonOption;
    };
    public assignAssessmentFormGenInputs: IFormGeneratorInput = null;

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.functionToExecute = this.modalInputData['data']['function'];
        this.modalParams = this.modalInputData['data']['params'];
        this.descriptionText = this.modalParams['descriptionText'];
        this.loaderOnExec = this.modalParams['loaderOnExec'];
        this.extraClass = this.modalParams['extraClass'];
        this.customButtonGeneratorInput =
            this.modalParams['customButtonGeneratorInput'];
        if (this.modalParams?.['valuesAllowedAsScore']?.length) {
            this.valuesAllowedAsScore =
                this.modalParams['valuesAllowedAsScore'];
        }
        this.buttonInputs = [
            {
                buttonName: this.modalParams['buttonText'],
                buttonType: ButtonType.FLAT,
                buttonColorType:
                    this.modalParams['buttonColorType'] || ButtonColorType.WARN,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this?.assignAssessmentFormGroup?.valid) {
                        if (this.loaderOnExec) {
                            buttonRef.loader = true;
                        }
                        this.functionToExecute(
                            this.modalInputData.modalId,
                            this.assignAssessmentFormGroup,
                            buttonRef
                        );
                    }
                },
                showLoader: this.loaderOnExec,
            },
        ];
        this.assignAssessmentFormGenInputs = {
            formName: 'Score change modal',
            state: FormState.CREATE,
            extraClass: 'scoring-modal-discreet-options-form',
            submitButton: null,
            fields: [
                {
                    label: '',
                    placeholder: '',
                    name: 'score',
                    fieldType: FilterType.RADIO,
                    required: true,
                    appearance: 'legacy',
                    showLabel: false,
                    listData: this.valuesAllowedAsScore.map((score) => ({
                        id: score,
                        label: `${score}`,
                    })),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Score is required.',
                        },
                    ],
                },
            ],
        };
    }
}
