import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { Clouds } from 'src/app/shared/enums/Clouds';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-azure-create-disk-provisioning',
    templateUrl: './azure-create-disk-provisioning.component.html',
    styleUrls: ['./azure-create-disk-provisioning.component.sass']
})
export class AzureCreateDiskProvisioningComponent implements OnInit {
    widgetRef: Widget;
    provisioningFormGenInputs: IFormGeneratorInput = null;
    provisioningFormGroup: FormGroup;
    vaultFormGenInputs: IFormGeneratorInput = null;
    vaultFormGroup: FormGroup;
    secretKeyFormGenInputs: IFormGeneratorInput = null;
    secretKeyFormGroup: FormGroup;
    keyValuePairFormGenInputs: IFormGeneratorInput[] = null;
    keyValuePairFormGroup: Map<Symbol, FormGroup> = new Map();
    keyValueSet = {};
    lastKeyValuePair: Symbol;
    widgetFilters = [];
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    dynamicKeys = {
        region: '',
        resourceGroup: '',
        accountIds: ''
    };

    resetButton: IButtonGeneratorInput = {
        buttonName: 'Reset',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: this.reset.bind(this)
    };
    submitButton: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef) => {
            this.createInstance(buttonRef);
        }
    };

    constructor(
        widgetData: WidgetInjectedData,
        public filtersService: FiltersService,
        private filterCacheService: FilterCacheService,
        public changeDetector: ChangeDetectorRef,
        private configCache: ConfigCacheService,
        private userCache: UserDataCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_CSP
            )
        ) {
            (this.dynamicKeys.region = 'regionsAzureCsp'),
                (this.dynamicKeys.resourceGroup = 'resourceGroupCsp'),
                (this.dynamicKeys.accountIds = 'accountIdsAzureCsp');
        }
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_EA
            )
        ) {
            (this.dynamicKeys.region = 'regionsAzureEa'),
                (this.dynamicKeys.resourceGroup = 'resourceGroupEA'),
                (this.dynamicKeys.accountIds = 'accountIdsAzureEa');
        }
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_PLAN
            )
        ) {
            (this.dynamicKeys.region = 'regionsAzurePlan'),
                (this.dynamicKeys.resourceGroup = 'resourceGroupPlan'),
                (this.dynamicKeys.accountIds = 'accountIdsAzurePlan');
        }

        if (
            this.widgetRef.widgetData.widgetInfo['schedulingAutomation'][
                'widgetFilters'
            ][0]['filters'].length
        ) {
            this.widgetFilters =
                this.widgetRef.widgetData.widgetInfo['schedulingAutomation'][
                    'widgetFilters'
                ][0]['filters'];

            Helper.generateFormInput(
                this.widgetFilters,
                this.widgetRef.httpService,
                this.filterCacheService,
                { showKey: 'label', responseValueKey: 'dataList' },
                null,
                null,
                null,
                null,
                null,
                this.userCache,
                this.configCache
            ).subscribe((res) => {
                if (res) {
                    const fieldIndex1 = res.fields.findIndex(
                        (element) =>
                            element.name === this.dynamicKeys.resourceGroup
                    );
                    res.fields[fieldIndex1].hiddenDependency = [
                        {
                            controlName: this.dynamicKeys.accountIds,
                            validations: [
                                { validator: CustomValidators.required }
                            ]
                        }
                    ];
                    res.fields.push(
                        {
                            label: 'OS Type',
                            placeholder: 'Select OS Type',
                            name: 'diskOsType',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            required: false,
                            showKey: 'label',
                            listData: this.osTypeList,
                            validations: null
                        },
                        {
                            label: 'Disk SKU',
                            placeholder: 'Select Disk SKU',
                            name: 'diskSku',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            required: false,
                            showKey: 'label',
                            listData: this.diskSkuList,
                            validations: null
                        },
                        {
                            label: 'Size(GIB)',
                            placeholder: 'Select Size',
                            name: 'diskSizeGB',
                            fieldType: FilterType.NUMBER,
                            required: true,
                            suffixIcon: {
                                iconData: {
                                    type: IconType.MATICON,
                                    class: 'help_outline'
                                },
                                hoverText: 'Min: 1 GiB , Max: 16384 GiB'
                            },
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Size is required'
                                }
                            ]
                        },
                        {
                            label: 'Encryption',
                            placeholder: 'Select Encryption',
                            name: 'encryption',
                            fieldType: FilterType.CHECKBOX,
                            required: false,
                            hiddenDependency: [
                                {
                                    controlName: this.dynamicKeys.accountIds,
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        }
                                    ]
                                },
                                {
                                    controlName: this.dynamicKeys.region,
                                    validations: [
                                        {
                                            validator: CustomValidators.required
                                        }
                                    ]
                                }
                            ]
                        }
                    );
                    res.fields.unshift({
                        label: 'Disk Name',
                        placeholder: 'Enter Disk Name',
                        name: 'diskName',
                        fieldType: FilterType.TEXT,
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Disk Name is required'
                            },
                            {
                                validator: CustomValidators.username,
                                errorMessage:
                                    'Only letters, numbers, underscores, periods, or hyphens are allowed.'
                            }
                        ]
                    });
                    this.provisioningFormGenInputs = res;

                    this.setUpBasics();
                    this.keyValuePairFormGenInputs = [
                        this.getKeyValueFormGenInput()
                    ];
                }
            });
        }
    }

    ngOnInit(): void {}
    setUpBasics() {
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.hideRefreshIcon.next(true);
    }
    addVaultField($event) {
        if ($event['encryption']) {
            this.vaultFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Vault',
                        placeholder: 'Select Vault',
                        name: 'vault',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        showKey: 'label',
                        responseValueKey: 'dataList',
                        apiInput: {
                            [this.dynamicKeys.region]: [
                                this.provisioningFormGroup.get(
                                    this.dynamicKeys.region
                                ).value
                            ],
                            [this.dynamicKeys.accountIds]: [
                                this.provisioningFormGroup.get(
                                    this.dynamicKeys.accountIds
                                ).value
                            ]
                        },
                        apiInfo:
                            this.widgetRef.widgetData.widgetInfo[
                                'provisioning'
                            ]['azureDiskVaults'],
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Vault is required'
                            }
                        ]
                    }
                ]
            };
            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['vault']
            };
            if (this.vaultFormGroup) {
                this.updateControl.next(this.updateControlInput);
            }
        }
    }
    addSecretKeyField($event) {
        if ($event['vault']) {
            this.secretKeyFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Secret Key',
                        placeholder: 'Select Secret Key',
                        name: 'secretKey',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        showKey: 'label',
                        responseValueKey: 'dataList',
                        apiInput: {
                            [this.dynamicKeys.accountIds]: [
                                this.provisioningFormGroup.get(
                                    this.dynamicKeys.accountIds
                                ).value
                            ],
                            configurationProvision: {
                                azureResourceGroup: [
                                    this.provisioningFormGroup.get(
                                        this.dynamicKeys.resourceGroup
                                    ).value
                                        ? this.provisioningFormGroup.get(
                                              this.dynamicKeys.resourceGroup
                                          ).value
                                        : ''
                                ],
                                diskSourceVault: this.vaultFormGroup.get(
                                    'vault'
                                ).value
                                    ? this.vaultFormGroup.get('vault').value
                                    : ''
                            }
                        },
                        apiInfo:
                            this.widgetRef.widgetData.widgetInfo[
                                'provisioning'
                            ]['azureDiskSecretUrl'],
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Secret Key is required'
                            }
                        ]
                    }
                ]
            };
        }

        this.updateControlInput = {
            action: UpdateAction.REFRESH,
            controls: ['secretKey']
        };
        if (this.vaultFormGroup && this.secretKeyFormGroup) {
            this.updateControl.next(this.updateControlInput);
        }
    }
    createInstance(buttonRef) {
        if (buttonRef.loader) {
            return;
        }

        Helper.markAllFieldAsTouched(this.provisioningFormGroup);
        if (this.provisioningFormGroup.invalid) {
            this.provisioningFormGroup.updateValueAndValidity();
            return;
        }
        if (this.provisioningFormGroup.get('encryption').value) {
            Helper.markAllFieldAsTouched(this.vaultFormGroup);
            if (this.vaultFormGroup.invalid) {
                this.vaultFormGroup.updateValueAndValidity();
                return;
            }
            if (this.secretKeyFormGroup.invalid) {
                this.secretKeyFormGroup.updateValueAndValidity();
                return;
            }
        }

        buttonRef.loader = true;
        const inputs = this.prepareInput();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = inputs;
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Request to create disk has been initiated successfully.'
            );
            this.reset();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Creating Instance'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    prepareInput() {
        const tags = [];
        let configurationProvision = {};

        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;
        this.keyValuePairFormGroup.forEach((value, index) => {
            if (this.lastKeyValuePair !== index) {
                tags.push(value.value);
            }
        });
        configurationProvision = {
            diskSourceVault: this.vaultFormGroup?.get('vault').value
                ? this.vaultFormGroup.get('vault').value
                : '',
            diskSecretUrl: this.secretKeyFormGroup?.get('secretKey').value
                ? this.secretKeyFormGroup.get('secretKey').value
                : '',
            diskEncryption: this.provisioningFormGroup.get('encryption').value
                ? this.provisioningFormGroup.get('encryption').value
                : false,
            diskSizeGB: this.provisioningFormGroup.get('diskSizeGB').value,
            diskOsType: this.provisioningFormGroup.get('diskOsType').value,
            diskSku: this.provisioningFormGroup.get('diskSku').value,
            azureResourceGroup: this.provisioningFormGroup.get(
                this.dynamicKeys.resourceGroup
            ).value,
            diskName: this.provisioningFormGroup.get('diskName').value,
            diskTags: tags
        };

        const input = {
            configurationProvision: configurationProvision,
            [this.dynamicKeys.region]: [
                this.provisioningFormGroup.get(this.dynamicKeys.region).value
            ],

            [this.dynamicKeys.accountIds]: [
                this.provisioningFormGroup.get(this.dynamicKeys.accountIds)
                    .value
            ]
        };
        return input;
    }
    reset() {
        this.provisioningFormGroup.reset();
        if (this.vaultFormGroup) {
            this.vaultFormGroup.reset();
        }
        if (this.keyValuePairFormGenInputs.length !== 1) {
            this.keyValuePairFormGenInputs.splice(
                0,
                this.keyValuePairFormGenInputs.length - 1
            );
        }
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;

        this.keyValuePairFormGroup.forEach((fg, id) => {
            if (id !== this.lastKeyValuePair) {
                this.keyValuePairFormGroup.delete(id);
            } else {
                fg.reset();
            }
        });
    }
    osTypeList = [
        {
            id: 'Linux',
            label: 'Linux'
        },

        {
            id: 'Windows',
            label: 'Windows'
        }
    ];
    diskSkuList = [
        {
            id: 'Standard_LRS',
            label: 'Standard_LRS'
        },

        {
            id: 'Premium_LRS',
            label: 'Premium_LRS'
        },
        {
            id: 'StandardSSD_LRS',
            label: 'StandardSSD_LRS'
        }
    ];

    getKeyValueFormGenInput(): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Key',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'Key',
                    fieldType: FilterType.TEXT,
                    label: 'Key',
                    placeholder: 'Key',
                    required: false
                },
                {
                    name: 'Value',
                    fieldType: FilterType.TEXT,
                    label: 'Value',
                    placeholder: 'Value',
                    required: false
                }
            ]
        };
    }
    idleKeyValueForms() {
        this.keyValuePairFormGenInputs = this.keyValuePairFormGenInputs.map(
            (fg, index) => {
                if (index !== this.keyValuePairFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    lastKeyValueFormUpdate(index: number, formId: Symbol) {
        const tag = this.keyValuePairFormGroup.get(formId).value;
        if (!tag.Key || !tag.Value) {
            this.widgetRef.notificationsService.showSnackBar(
                'Key Or Value Can Not Be Left Blank',
                true
            );
            return;
        }
        if (this.keyValuePairFormGroup.has(formId)) {
            const fg = this.keyValuePairFormGroup.get(formId);
            if (fg.valid) {
                if (
                    this.keyValuePairFormGenInputs &&
                    this.keyValuePairFormGenInputs.length
                ) {
                    if (this.getKey().includes(fg.get('Key').value)) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Configuration for this key is already present',
                            true
                        );
                    } else {
                        this.keyValuePairFormGenInputs.push(
                            this.getKeyValueFormGenInput()
                        );
                        this.idleKeyValueForms();
                    }
                }
            }
        }
    }
    getKey(): string[] {
        const key = [];
        this.keyValuePairFormGenInputs.forEach((fg, index) => {
            if (index !== this.keyValuePairFormGenInputs.length - 1) {
                if (this.keyValuePairFormGroup.has(fg.formId)) {
                    key.push(
                        this.keyValuePairFormGroup.get(fg.formId).get('Key')
                            .value
                    );
                }
            }
        });
        return key;
    }
    deleteKeyValuePairFormGroup(index: number, formId: Symbol) {
        this.keyValuePairFormGroup.delete(formId);
        this.keyValuePairFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.keyValuePairFormGenInputs
        );
    }
}
