<div class="bottom-ellipse" appIcon [data]="bottomEllipse"></div>
<div class="top-ellipse" appIcon [data]="topEllipse"></div>
<div class="login-wrapper" [ngClass]="signupFrom">
    <div class="logo-container">
        <div class="logo">
            <a
                appIcon
                [data]="logo"
                [href]="
                    whiteLabelService.whiteLableData.organizationalUrl
                        ? whiteLabelService.whiteLableData.organizationalUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.organizationalUrl
                            : '//' +
                              whiteLabelService.whiteLableData.organizationalUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            >
            </a>
        </div>
    </div>
    <div class="login-container">
        <div
            appIcon
            [data]="
                signupFrom === 'anaplan'
                    ? loginImage
                    : signupFrom === 'google'
                    ? loginImageGoogle
                    : signupFrom === 'juniper'
                    ? loginImageJuniper
                    : loginImageHpe
            "
            class="work"
        ></div>
        <div class="form">
            <ng-container>
                <div class="form-container">
                    <div class="headingAndBackButton">
                        <span class="form-heading">Sign Up</span>
                    </div>
                    <div class="login-content">
                        <div class="fieldsWithErrorAndForgotPassword">
                            <div class="fields">
                                <ng-container *ngIf="!billingFormGenInput">
                                    <div
                                        class="terms customer-information-form"
                                    >
                                        <ng-container
                                            [ngTemplateOutlet]="formPart1"
                                        >
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <mat-horizontal-stepper
                                    *ngIf="billingFormGenInput"
                                    linear
                                    labelPosition="bottom"
                                    class="signup-hpe-stepper"
                                    [ngClass]="signupFrom"
                                    #stepper
                                >
                                    <mat-step
                                        [stepControl]="signupFrom !== 'juniper' 
                                        ? signupFormGenInput 
                                        : juniperSignupFormGenInput"
                                    >
                                        <ng-template matStepperIcon="edit">
                                            <div
                                                class="done_icon"
                                                appIcon
                                                [data]="doneIcon"
                                            ></div>
                                        </ng-template>
                                        <div
                                            class="terms customer-information-form"
                                            [ngClass]="signupFrom"
                                        >
                                            <ng-template matStepLabel
                                                >Customer
                                                Information</ng-template
                                            >
                                            <ng-container
                                                [ngTemplateOutlet]="signupFrom === 'juniper' ? juniperSignupForm : formPart1"
                                            >
                                            </ng-container>
                                        </div>
                                    </mat-step>
                                    <mat-step
                                        [stepControl]="billingFormGenInput"
                                    >
                                        <div
                                            class="billing-information-form"
                                            [ngClass]="signupFrom"
                                            *ngIf="billingFormGenInput"
                                        >
                                            <ng-template matStepLabel
                                                >Billing
                                                Information</ng-template
                                            >
                                            <ng-container
                                                [ngTemplateOutlet]="formPart2"
                                            >
                                            </ng-container>
                                        </div>
                                    </mat-step>
                                </mat-horizontal-stepper>
                                <div class="submit-button-wrapper"
                                    [ngClass]="submitButtonGenInput.buttonName === 'Register' 
                                            ? signupFrom 
                                            : [signupFrom, 'customer-info-step']">
                                    <app-button-generator
                                        [buttonGenInput]="submitButtonGenInput"
                                        [ngClass]="submitButtonGenInput.buttonName === 'Register'
                                                ? signupFrom 
                                                : 'customer-info-step'"
                                        class="submit-button"
                                    ></app-button-generator>
                                    <app-button-generator
                                        *ngIf="matStepper?.selectedIndex === 1"
                                        [buttonGenInput]="backButtonGenInput"
                                        [ngClass]="signupFrom"
                                        class="back-button"
                                    ></app-button-generator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #formPart1>
    <app-form-generator
        *ngIf="signupFormGenInput"
        (formSubmitted)="authenticateAndSignup()"
        [formGeneratorInput]="signupFormGenInput"
        (formGroupRef)="signupFormGroup = $event"
    ></app-form-generator>
</ng-template>

<ng-template #formPart2>
    <app-form-generator
        *ngIf="billingFormGenInput"
        (formSubmitted)="authenticateAndSignup()"
        [formGeneratorInput]="billingFormGenInput"
        (formGroupRef)="billingFormGroup = $event"
    ></app-form-generator>
</ng-template>

<ng-template #juniperSignupForm>
    <app-form-generator
        *ngIf="juniperSignupFormGenInput"
        (formSubmitted)="authenticateAndSignup()"
        [formGeneratorInput]="juniperSignupFormGenInput"
        (formGroupRef)="juniperSignupFormGroup = $event"
        class="juniper-form"
    ></app-form-generator>
</ng-template>
