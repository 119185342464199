<div class="main-container">
    <div class="first-form-container">
        <span class="details">Customer Information</span>
        <hr />
        <app-form-generator
            *ngIf="customerInfoFirstFormGenInput"
            [formGeneratorInput]="customerInfoFirstFormGenInput"
            (formGroupRef)="customerInfoFirstFormRef = $event"
            (resetFormFunction)="resetFirstForm = $event"
            (valuesChange)="checkValue($event)"
        >
        </app-form-generator>
        <div class="customer-tag">
            <div class="container">
                <span>Customer Tag</span>
                <app-tag-generator
                    [extraClass]="'tagMenuResize'"
                    [selectedTags]="
                        selectedTagsData && selectedTagsData.length
                            ? selectedTagsData
                            : []
                    "
                    [availableTags]="
                        availableTagsData && availableTagsData.length
                            ? availableTagsData
                            : []
                    "
                    (tagsDataEmitter)="updateTags($event)"
                    [showSaveButton]="true"
                ></app-tag-generator>
            </div>
        </div>
    </div>
    <div class="second-form-container" *ngIf="!edit">
        <span class="details">Root User Information</span>
        <hr />
        <app-form-generator
            *ngIf="customerInfoSecondFormGenInput"
            [formGeneratorInput]="customerInfoSecondFormGenInput"
            (formGroupRef)="customerInfoSecondFormRef = $event"
            (resetFormFunction)="resetSecondForm = $event"
        >
        </app-form-generator>
    </div>
    <div class="third-form-container">
        <span class="details">Attach Policies</span>
        <hr />
        <app-form-generator
            *ngIf="customerInfoThirdFormgenInput"
            [formGeneratorInput]="customerInfoThirdFormgenInput"
            (formGroupRef)="customerInfoThirdFormRef = $event"
            (updateControl)="updateControl = $event"
            (resetFormFunction)="resetThirdForm = $event"
            (valuesChange)="addViewField($event)"
        ></app-form-generator>
        <ng-container *ngIf="(aclId && !edit) || resetViewForm">
            <ng-container *ngTemplateOutlet="first"></ng-container>
            <ng-template #first>
                <app-form-generator
                    [formGeneratorInput]="viewFormGenInput"
                    (formGroupRef)="viewFormGroup = $event"
                    (updateControl)="updateControlView = $event"
                    (resetFormFunction)="resetViewForm = $event"
                >
                </app-form-generator>
            </ng-template>
        </ng-container>
    </div>

    <div class="button">
        <app-button-generator
            class="tw-ml-auto tw-mb-4 tw-mr-5"
            [buttonGenInput]="nextButtonGenInput"
        ></app-button-generator>
    </div>
</div>
