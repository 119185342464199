<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="gridRef = $event"
    (selectionChanged)="onSelectionChanged()"
></app-table-generator>
<ng-template #rowDataSelected>
    <li *ngFor="let data of selectedRowData" class="data">
        {{ data }}
    </li>
    <span class="error-message">{{ mergeWarningMessage }}</span>
</ng-template>
<ng-template #templateData>
    <div class="main-container">
        <div class="merge-container">
            <div appIcon [iconName]="iconName.WARNING"></div>
            <span
                >Master Account {{ masterAccountName }} for this subscription
                already exists, Use Merge Action to Onboard this account under
                Master Account.</span
            >
        </div>
    </div>
</ng-template>
