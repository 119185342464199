import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-create-mg-report-step-three',
    templateUrl: './create-mg-report-step-three.component.html',
    styleUrls: ['./create-mg-report-step-three.component.sass']
})
export class CreateMgReportStepThreeComponent implements OnInit {
    @ViewChild('tabGroup') tabGroup;
    SAVE_DISABLE_MESSAGE = `Fill in recipient details under Download & Messaging to Save & Schedule reports`;
    Tab = Tab;
    IntegrationName = IntegrationName;
    initialSelection: IntegrationName[] = [];
    selectedTab = Tab.DOWNLOAD_AND_MESSAGING;
    selectedIntegrations: Map<
        IntegrationName,
        {
            iconData: IIcon;
            name: string;
            integrationData;
            integrated: boolean;
        }
    > = new Map();
    availableSupportedFormats: Set<SupportedFormat> = new Set();

    downloadReportFormGenInput: IFormGeneratorInput = null;
    downloadReportFormGroup: FormGroup = null;
    sendEmailFormGenInput: IFormGeneratorInput = null;
    sendEmailFormGroup: FormGroup = null;
    slackFormGenInput: IFormGeneratorInput = null;
    slackFormGroup: FormGroup = null;
    // gmailFormGenInput: IFormGeneratorInput = null;
    // gmailFormGroup: FormGroup = null;
    // o365FormGenInput: IFormGeneratorInput = null;
    // o365FormGroup: FormGroup = null;

    reportFrequencyFormGenInput: IFormGeneratorInput = null;
    reportFrequencyFormGroup: FormGroup = null;

    receiveReportFormGenInput: IFormGeneratorInput = null;
    receiveReportFormGroup: FormGroup = null;

    billingDayFormGenInput: IFormGeneratorInput = null;
    billingDayFormGroup: FormGroup = null;

    sendLoader: boolean = false;
    downloadLoader = false;
    saveLoader = false;

    getIntegrationData = null;

    edit = false;
    reportData = null;
    sendingMechanism = null;
    reportFilters = null;
    widgetRef: Widget = null;
    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            this.multiStepFormService.previousStep(this.modalInputData.modalId);
        }
    };
    sendButton: IButtonGeneratorInput = {
        buttonName: 'Send',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        buttonIcon: {
            type: IconType.FONTAWSOME,
            class: 'fas fa-envelope'
        },
        function: (buttonRef) => {
            this.sendReport(buttonRef);
        }
    };
    saveScheduleButton: IButtonGeneratorInput = {
        buttonName: 'Save & Schedule',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        buttonIcon: {
            type: IconType.FONTAWSOME,
            class: 'fas fa-chevron-right'
        },
        function: () => {
            this.enableSaveAndSchedule()
                ? this.switchToSave(this.tabGroup)
                : null;
        }
    };
    lastSaveScheduleButton: IButtonGeneratorInput = {
        buttonName: 'Save & Schedule',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        buttonIcon: {
            type: IconType.FONTAWSOME,
            class: 'fas fa-clock'
        },
        function: (buttonRef) => {
            this.saveReport(buttonRef);
        }
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private whiteLabelingService: WhiteLabelingService,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalInputData.data.widgetRef;
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData']
        ) {
            this.reportData = this.modalInputData.data['stepData'];
            this.reportFilters = this.reportData['reportFilters'];
            this.sendingMechanism = this.reportFilters['sendingMechanism'];
            this.edit = true;
            if (this.sendingMechanism['sendViaEmail']) {
                this.initialSelection.push(IntegrationName.EMAIL);
            }
            if (this.sendingMechanism['o365IntegrationId']) {
                this.initialSelection.push(IntegrationName.O365);
            }
            if (this.sendingMechanism['gmailIntegrationId']) {
                this.initialSelection.push(IntegrationName.GMAIL);
            }
            if (
                (this.sendingMechanism['slackUsersIds'] &&
                    this.sendingMechanism['slackUsersIds'].length) ||
                (this.sendingMechanism['slackChannelIds'] &&
                    this.sendingMechanism['slackChannelIds'].length)
            ) {
                this.initialSelection.push(IntegrationName.SLACK);
            }
        } else {
            this.initialSelection = [IntegrationName.DOWNLOAD_REPORT];
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2) &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2)['supportedFormats']
        ) {
            this.availableSupportedFormats = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2)['supportedFormats'];
        }
        // Report formats
        const formats = [];
        if (this.availableSupportedFormats.has(SupportedFormat.EXCEL)) {
            formats.push({
                label: 'Excel',
                id: 'MANAGED_REPORT_EXCEL'
            });
        }

        // if (this.availableSupportedFormats.has(SupportedFormat.CSV)) {
        //     formats.push({
        //         label: 'CSV',
        //         id: 'csv',
        //     });
        // }

        // if (this.availableSupportedFormats.has(SupportedFormat.PDF)) {
        //     formats.push({
        //         label: 'PDF',
        //         id: 'pdf',
        //     });
        // }

        const reportContent =
            this.whiteLabelingService.whiteLableData.reportContent.replace(
                /{{companyName}}/g,
                this.whiteLabelingService.whiteLableData.companyName
            );

        // Download Report Form Gen Input
        this.downloadReportFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'reportFormat',
                    label: 'Report Format',
                    placeholder: 'Report Format',
                    fieldType: FilterType.RADIO,
                    value: formats && formats.length ? formats[0].id : null,
                    listData: formats,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Report format is required'
                        }
                    ]
                }
            ]
        };

        // Send Email Form Gen Input
        this.sendEmailFormGenInput = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'emailTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['emailTo'] &&
                        this.sendingMechanism['emailRequest']['emailTo'].length
                            ? this.sendingMechanism['emailRequest']['emailTo']
                            : [],
                    label: 'To',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Atleast one recipients is required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    label: 'Subject',
                    placeholder: 'Subject',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['subject'] &&
                        this.sendingMechanism['emailRequest']['subject'].length
                            ? this.sendingMechanism['emailRequest']['subject']
                            : this.multiStepFormService.stepData
                                  .get(this.modalInputData.modalId)
                                  .get(1)['reportName'] +
                              ' | ' +
                              this.whiteLabelingService.whiteLableData
                                  .companyName,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Subject is required'
                        }
                    ]
                },
                {
                    name: 'copyTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'CC',
                    placeholder: 'example@domain.com',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['copyTo'] &&
                        this.sendingMechanism['emailRequest']['copyTo'].length
                            ? this.sendingMechanism['emailRequest']['copyTo']
                            : [],
                    required: false,
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'bcc',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'BCC',
                    placeholder: 'example@domain.com',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['bcc'] &&
                        this.sendingMechanism['emailRequest']['bcc'].length
                            ? this.sendingMechanism['emailRequest']['bcc']
                            : [],
                    required: false,
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'emailBody',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    label: 'Email Content',
                    placeholder: 'Email Content',
                    required: true,
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['emailBody'] &&
                        this.sendingMechanism['emailRequest']['emailBody']
                            .length
                            ? this.sendingMechanism['emailRequest']['emailBody']
                            : reportContent,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email content is required'
                        }
                    ]
                }
            ]
        };

        // Slack Form Gen Input
        this.slackFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Users',
                    name: 'users',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Users',
                    required: true,
                    value:
                        this.edit &&
                        this.reportData['slackUsersIds'] &&
                        this.reportData['slackUsersIds'].length
                            ? this.reportData['slackUsersIds']
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Select atleast one user'
                        }
                    ],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_USERS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'usersInfo'
                },
                {
                    label: 'Channels',
                    name: 'channels',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Channels',
                    validations: null,
                    value:
                        this.edit &&
                        this.reportData['slackChannelIds'] &&
                        this.reportData['slackChannelIds'].length
                            ? this.reportData['slackChannelIds']
                            : [],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_CHANNELS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'channalsInfo'
                }
            ]
        };

        // Report Frequency Form Gen Input
        this.reportFrequencyFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.RADIO,
                    name: 'reportFrequency',
                    label: 'Report Frequency',
                    placeholder: 'Report Frequency',
                    required: true,
                    value:
                        this.edit &&
                        this.reportFilters &&
                        this.reportFilters['reportTimespan']
                            ? this.reportFilters['reportTimespan'].toLowerCase()
                            : 'monthly',
                    listData: [
                        {
                            id: 'monthly',
                            label: 'Monthly'
                        },
                        {
                            id: 'daily',
                            label: 'Daily'
                        },
                        {
                            id: 'weekly',
                            label: 'Weekly'
                        },
                        {
                            id: 'custom',
                            label: 'Custom'
                        }
                    ],
                    prefixIcon: {
                        hoverText: 'Click here for more info',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.modalInputData.data[
                                'widgetRef'
                            ].modalService.openInfoModal({
                                infoHeading:
                                    'Select the period for which report is to be scheduled',
                                content: [
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `<b>Monthly:</b> Schedule to automatically receive reports based on your last month’s data.<br>Example: If you want a report to be scheduled for a month, i.e. from 1st to 31st of the month then use this.`,
                                            `<b>Daily:</b> Schedule to automatically receive reports based on your last week’s data.<br>Example: If you want a report to be scheduled on daily bases, i.e. you want a 24 hours report then use this.`,
                                            `<b>Weekly:</b> Schedule to automatically receive reports based on your last day’s data.<br>Example: If you want a report to be scheduled for a week, i.e. from Monday to Tuesday, then use this.`,
                                            `<b>Monthly:</b> Schedule to automatically receive reports as per your billing cycle.<br>Example: If you want a report to be scheduled as per your billing cycle, i.e. if your billing cycle is from 7th of a month to 6th of another month, then use this to get a report for that specified billing cycle.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ]
        };

        // Receive report form gen input
        this.receiveReportFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'weekDay',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Receive Report On',
                    placeholder: 'Receive Report On',
                    required: true,
                    value:
                        this.edit &&
                        this.reportFilters &&
                        this.reportFilters['dayOfWeek']
                            ? this.reportFilters['dayOfWeek'].toLowerCase()
                            : 'MON',

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Week day is required'
                        }
                    ],
                    listData: [
                        {
                            id: 'MON',
                            label: 'Monday'
                        },
                        {
                            id: 'TUE',
                            label: 'Tuesday'
                        },
                        {
                            id: 'WED',
                            label: 'Wednesday'
                        },
                        {
                            id: 'THU',
                            label: 'Thursday'
                        },
                        {
                            id: 'FRI',
                            label: 'Friday'
                        },
                        {
                            id: 'SAT',
                            label: 'Saturday'
                        },
                        {
                            id: 'SUN',
                            label: 'Sunday'
                        }
                    ]
                }
            ]
        };

        const billingDays = Array(31)
            .fill(1)
            .map((val, index) => {
                return {
                    id: `${index + 1}`,
                    label: `${index + 1}`
                };
            });

        // Billing day form gen input
        this.billingDayFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'billingDay',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Billing Day',
                    placeholder: 'Billing Day',
                    required: true,
                    value:
                        this.edit &&
                        this.reportFilters &&
                        this.reportFilters['billingDay']
                            ? `${this.reportFilters['billingDay']}`.toLowerCase()
                            : '1',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Week day is required'
                        }
                    ],
                    listData: billingDays
                }
            ]
        };
    }

    changeTabs(tab) {
        this.selectedTab = tab ? tab : Tab.SAVE_AND_SCHEDULE;
    }

    integrationsChanged(integrations) {
        this.selectedIntegrations = integrations;
    }

    enableSaveAndSchedule(): boolean {
        if (
            (this.sendEmailFormGroup && this.sendEmailFormGroup.valid) ||
            (this.slackFormGroup && this.slackFormGroup.valid)
        ) {
            return true;
        }

        return false;
    }

    switchToSave(tabGroup: MatTabGroup) {
        this.selectedTab = Tab.SAVE_AND_SCHEDULE;
        tabGroup.selectedIndex = 1;
    }

    sendReport(buttonRef) {
        if (this.sendLoader && buttonRef.loader) {
            return;
        }
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            Helper.markAllFieldAsTouched(this.sendEmailFormGroup);
            if (this.sendEmailFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        let users = [];
        let channels = [];

        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            users = this.slackFormGroup.value['users'];
            channels = this.slackFormGroup.value['channels'];

            if (
                !users ||
                (users && !users.length) ||
                !channels ||
                (channels && !channels.length)
            ) {
                this.notificationsService.showSnackBar(
                    `Please select atleast one channel and/or user`,
                    true
                );
                return;
            }
        }
        this.sendLoader = true;
        buttonRef.loader = true;
        const sendingMechanism = {
            sendViaEmail: this.selectedIntegrations.has(IntegrationName.EMAIL)
        };
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            sendingMechanism['emailRequest'] = this.sendEmailFormGroup.value;
        }
        if (this.selectedIntegrations.has(IntegrationName.O365)) {
            sendingMechanism['o365IntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.O365
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.GMAIL)) {
            sendingMechanism['gmailIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.GMAIL
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            sendingMechanism['slackUsersIds'] = users;
            sendingMechanism['slackChannelIds'] = channels;
        }
        const sendReqInput = this.getBasicInputs();
        sendReqInput['reportFilters'] = {
            sendingMechanism
        };

        const apiArgs: IHitApi = {
            url: ApiUrls.SEND_REPORT,
            input: sendReqInput,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.sendLoader = false;
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    'Report will be sent in about 10-15 minutes'
                );
            },
            errorFunction: (error) => {
                this.sendLoader = false;
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error sending report'
                );
            },
            endFunction: () => {
                this.sendLoader = false;
                buttonRef.loader = false;
            }
        };

        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    saveReport(buttonRef) {
        if (this.saveLoader && buttonRef.loader) {
            return;
        }
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            Helper.markAllFieldAsTouched(this.sendEmailFormGroup);
            if (this.sendEmailFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        let users = [];
        let channels = [];

        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            users = this.slackFormGroup.value['users'];
            channels = this.slackFormGroup.value['channels'];

            if (
                !users ||
                (users && !users.length) ||
                !channels ||
                (channels && !channels.length)
            ) {
                this.notificationsService.showSnackBar(
                    `Please select atleast one channel and/or user`,
                    true
                );
                return;
            }
        }

        const freq = this.reportFrequencyFormGroup.get('reportFrequency').value;
        const reportTimespan = freq.toUpperCase();
        let dayOfWeek = null;
        let billingDay = null;
        if (freq === 'weekly') {
            Helper.markAllFieldAsTouched(this.receiveReportFormGroup);
            if (this.receiveReportFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
            dayOfWeek = this.receiveReportFormGroup.get('weekDay').value;
        } else if (freq === 'custom') {
            Helper.markAllFieldAsTouched(this.billingDayFormGroup);
            if (this.billingDayFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
            billingDay = this.billingDayFormGroup.get('billingDay').value;
        }

        this.saveLoader = true;
        buttonRef.loader = true;
        const sendingMechanism = {
            sendViaEmail: this.selectedIntegrations.has(IntegrationName.EMAIL)
        };
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            sendingMechanism['emailRequest'] = this.sendEmailFormGroup.value;
        }
        if (this.selectedIntegrations.has(IntegrationName.O365)) {
            sendingMechanism['o365IntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.O365
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.GMAIL)) {
            sendingMechanism['gmailIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.GMAIL
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            sendingMechanism['slackUsersIds'] = users;
            sendingMechanism['slackChannelIds'] = channels;
        }
        const saveReqInput = this.getBasicInputs();
        saveReqInput['reportFilters'] = {
            sendingMechanism,
            reportTimespan,
            dayOfWeek,
            billingDay
        };

        const createArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        const updateArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        if (this.edit) {
            updateArgs.intactUrl = updateArgs.url;
            updateArgs.url = updateArgs.url.replace(
                '{report-id}',
                this.reportData['id']
            );
        }

        createArgs.input = saveReqInput;
        updateArgs.input = saveReqInput;

        createArgs.function = (response) => {
            this.saveLoader = false;
            buttonRef.loader = false;
            this.notificationsService.showSnackBar(
                'Report created successfully'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        updateArgs.function = (response) => {
            this.saveLoader = false;
            buttonRef.loader = false;
            this.notificationsService.showSnackBar(
                'Report updated successfully'
            );
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        createArgs.errorFunction = (error) => {
            this.saveLoader = false;
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error creating report'
            );
        };
        updateArgs.errorFunction = (error) => {
            this.saveLoader = false;
            buttonRef.loader = false;
            this.notificationsService.showSnackBar(
                error && error.error && error.error.message
                    ? error.error.message
                    : 'Error updating report',
                true
            );
        };

        new HitApi(
            !this.edit ? createArgs : updateArgs,
            this.httpService,
            this.ngZone
        ).hitApi();
    }

    getBasicInputs() {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const step2Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        const reportInput = {
            reportName: step1Data['reportName'],
            widgetIds: step1Data['widgetsSelected'],
            reportType: this.downloadReportFormGroup.get('reportFormat').value,
            widgetFilters: Helper.mapToObj(step2Data['widgetFilters'])
        };

        return reportInput;
    }
}

enum Tab {
    DOWNLOAD_AND_MESSAGING = 'Messaging',
    SAVE_AND_SCHEDULE = 'Save & Schedule'
}
