import { Component, OnInit } from '@angular/core';
import { RowEvent, ValueGetterParams } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { AssessmentCreateUserStepOneComponent } from '../../../modal-templates/assessee-view/assessment-user/assessment-create-user-step-one/assessment-create-user-step-one.component';
import { AssessmentCreateUserStepTwoComponent } from '../../../modal-templates/assessee-view/assessment-user/assessment-create-user-step-two/assessment-create-user-step-two.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { AssociatedAssessmentsComponent } from '../../../modal-templates/views/associated-assessments/associated-assessments.component';

@Component({
    selector: 'app-assessment-user-access',
    templateUrl: './assessment-user-access.component.html',
    styleUrls: ['./assessment-user-access.component.sass']
})
export class AssessmentUserAccessComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    constructor(
        private widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.widgetData.widgetRef;
        this.tableInput = {
            buttons: [
                {
                    buttonName: 'Create',
                    function: (buttonRef) => {
                        this.addUser();
                    },
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.RAISED
                }
            ],
            widgetIconData: {
                class: 'fas fa-eye',
                type: IconType.FONTAWSOME
            },
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Name',
                    columnKey: 'name',
                    columnValueGetter: (params: ValueGetterParams) => {
                        const name = `${params.data['firstName'] ?? ''} ${
                            params.data['lastName'] ?? ''
                        }`;
                        return name;
                    }
                },
                {
                    columnName: 'Email',
                    columnKey: 'email'
                },
                {
                    columnName: 'Contact Number',
                    columnKey: 'phoneNumber'
                },
                {
                    columnName: 'Root',
                    columnKey: 'root'
                },
                {
                    columnName: 'Associated Assessments',
                    columnKey: 'assosiatedAssessments',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const itemData = rowData.data;
                            const button: IButtonGeneratorInput = {
                                buttonName: 'View',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                hoverText: itemData['assosiatedAssessments'] ? '' : 'No Associated Assessments',
                                disable: itemData['assosiatedAssessments'] ? false : true,
                                function: () => {
                                    const modalData: IModalData = {
                                        modalName: 'Associated Assessments',
                                        modalHeightVh: 90,
                                        modalWidthVw: 55,
                                        modalIcon: null,
                                        modalType: ModalType.MIDDLE,
                                        sourceId:
                                            this.widgetRef.widgetData
                                                .widgetUniqueIdentifier,
                                        modalSteps: [
                                            {
                                                stepName: 'Associated Assessments',
                                                stepData: {
                                                    componentToLoad:
                                                        AssociatedAssessmentsComponent,
                                                    payload: {
                                                        data: {
                                                            itemData
                                                        }
                                                    }
                                                }
                                            }
                                        ]
                                    };
                                    this.modalService.openModal(modalData);
                                }
                            };
                            rowData['buttonGenInputs'] = [button];
                            return rowData;
                        
                    }
                },
                {
                    columnName: 'Actions',
                    columnKey: 'actions',
                    pinned: 'right',
                    minWidth: 150,
                    filter: false,
                    sortable: false,
                    headerClass: 'grid-cell-data-centred',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const root = rowData.data['root'];
                        const email = rowData.data['email'];
                        const isRoot =
                            root ||
                            (email &&
                                email === this.userDataCacheService.emailId);
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable: isRoot,
                                function: () => {
                                    // Edit user
                                    const userData = rowData['data'];
                                    this.addUser(userData);
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                disable: isRoot,
                                function: () => {
                                    if (isRoot) {
                                        return;
                                    }
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Delete User',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-user-times'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to permanently delete user ?`,
                                        buttonText: 'Delete',
                                        buttonColorType: ButtonColorType.WARN,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            if (isRoot) {
                                                return;
                                            }

                                            const userData = rowData['data'];
                                            // Generating Delete API Config for the Assessmnt User.
                                            const apiArgs = Helper.generateHitApiConfig(
                                                this.widgetRef.widgetData.widgetInfo.delete
                                            );
                                            apiArgs.input = {};
                                            apiArgs.function = (response) => {
                                                this.widgetRef.notificationsService.showSnackBar(
                                                    `${userData['firstName']} permanently deleted`
                                                );
                                                this.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                                this.widgetRef.refreshWidget();
                                            },
                                            apiArgs.errorFunction = (error) => {
                                                this.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                                Helper.showErrorMessage(
                                                    this.widgetRef
                                                        .notificationsService,
                                                    error,
                                                    `Error deleting ${userData['firstName']}`
                                                );
                                            }
                                            apiArgs.url = apiArgs.url.replace('{id}', userData['id']);
                                            new HitApi(
                                                apiArgs,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-around'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ],
            selection: 'single'
        };
    }
    addUser(userData?) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-user-plus'
        };

        const modalData: IModalData = {
            modalName: 'Create User',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,
            modalHeightVh: userData ? 50 : 70,
            modalWidthVw: 70,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AssessmentCreateUserStepOneComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                userData: userData,
                                edit: userData ? true : false
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        if (!userData) {
            modalData.modalSteps.push({
                stepData: {
                    componentToLoad: AssessmentCreateUserStepTwoComponent,
                    payload: {
                        data: {
                            widgetRef: this.widgetRef,
                            userData: userData,
                            edit: userData ? true : false
                        }
                    }
                },
                stepName: ''
            });
        }
        this.modalService.openModal(modalData);
    }
}
