<div class="allocation-conventions-container">
    <div class="convention-message">
        Conventions set for the following insight
    </div>
    <table *ngIf="conventions">
        <tr *ngFor="let convention of getKeys(conventions)">
            <th>{{ convention }}</th>
            <td>{{ conventions[convention] }}</td>
        </tr>
    </table>
</div>
