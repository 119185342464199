import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    GridOptions,
    RowEvent,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { ILoginResponse } from 'src/app/shared/interfaces/api/portlets/ILoginResponse';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ApiResponseCacheService } from 'src/app/shared/services/cache/api-response-cache/api-response-cache.service';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { CreateCustomerStepOneV2Component } from '../../../modal-templates/create-customer/create-customer-step-one-v2/create-customer-step-one-v2.component';
import { CreateCustomerStepThreeComponent } from '../../../modal-templates/create-customer/create-customer-step-three/create-customer-step-three.component';
import { MasterLoginUsersListComponent } from '../../../modal-templates/master-login/master-login-users-list/master-login-users-list.component';
import { HitApi } from './../../../../classes/HitApi';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { ActionState } from './../../../../enums/ActionState';
import { ActionVisibility } from './../../../../enums/ActionVisibility';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { CustomerInfoModalComponent } from './../../../modal-templates/create-customer/customer-info-modal/customer-info-modal.component';
import { WidgetCatalogTagsModalComponent } from './../../../modal-templates/widget-catalog-tags-modal/widget-catalog-tags-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-customer-listing-v2',
    templateUrl: './customer-listing-v2.component.html',
    styleUrls: ['./customer-listing-v2.component.sass']
})
export class CustomerListingV2Component implements OnInit {
    widgetRef: Widget;
    agGrid: GridOptions = null;
    domainIds = [];
    emailIds = [];
    tableGenInput: ITableGeneratorInput;
    selectedAccounts = [];
    @ViewChild('accounts') accounts: TemplateRef<any>;
    keyNamesForTagGenerator = {};
    region: string;
    constructor(
        widgetData: WidgetInjectedData,
        private sessionService: SessionService,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private apiResponseCacheService: ApiResponseCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }
    masterLoginIndex = null;

    ngOnInit(): void {
        this.setUpBasics();
        this.applyTagOperations();
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
    }
    setUpBasics() {
        this.tableGenInput = {
            afterResponse: (data: any) => {
                if (data) {
                    if (
                        this.tableGenInput.columns[
                            this.tableGenInput.columns.length - 1
                        ].columnKey === 'tagsUi'
                    ) {
                        this.tableGenInput.columns.pop();
                    }

                    let breakLoop: boolean;

                    data.forEach((value) => {
                        if (value['tagsUi']) {
                            breakLoop = true;
                        }
                    });
                    if (breakLoop) {
                        this.tableGenInput.columns.push({
                            columnKey: 'tagsUi',
                            columnName: 'Tags'
                        });
                    }
                }
            },
            showViewIcon: true,
            columns: [
                {
                    columnName: 'Company Name',
                    columnKey: 'companyName',
                    pinned: 'left',
                    minWidth: 120
                },
                {
                    columnName: 'Domain ID',
                    columnKey: 'domainId',
                    minWidth: 120,
                    flex: 1
                },
                {
                    columnName: 'Root User Name',
                    columnKey: 'rootUserName',
                    minWidth: 120,
                    flex: 1
                },
                {
                    columnName: 'Email',
                    columnKey: 'rootUserEmail',
                    minWidth: 120,
                    flex: 1
                },
                {
                    columnName: 'Status',
                    columnKey: '',
                    minWidth: 120,
                    flex: 1,
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.status === 'Active') {
                            return `<span class="green-color"><i class="green-color"></i>Active</span>`;
                        } else {
                            return `<span class="red-color">Suspended</span>`;
                        }
                    },
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return rowData.data.status;
                    }
                },
                {
                    columnName: 'Customer Tags',
                    columnKey: 'tags',
                    cellRenderer: (rowData) => {
                        if (rowData.data.tags && rowData.data.tags.length) {
                            return rowData.data.tags
                                .map(
                                    (tag) =>
                                        `&nbsp;<span style="color:${tag.colorCode}" >${tag.name}</span>`
                                )
                                .join(', ');
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    minWidth: 390,
                    filter: false,
                    pinned: 'right',
                    buttonGen: true,
                    headerClass: 'header-center',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const isDisabled =
                            GlobalConfiguration.CUSTOMER_CATALOG_DISABLED_FOR.includes(
                                rowData.data['domainId']
                            );
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable: isDisabled,
                                hoverText: isDisabled
                                    ? Messages.ACCOUNT_TEMPORARILY_SUSPENDED
                                    : '',
                                blurEffect: isDisabled,
                                function: () => {
                                    this.openCustomerModal(rowData.data);
                                }
                            },
                            {
                                buttonName: 'View',
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.TEXT,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.getPriceTypeDetailsFn(
                                        rowData.data,
                                        buttonRef
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                disable: isDisabled,
                                hoverText: isDisabled
                                    ? Messages.ACCOUNT_TEMPORARILY_SUSPENDED
                                    : '',
                                blurEffect: isDisabled,
                                function: () => {
                                    this.selectedAccounts = [rowData.data];
                                    this.deleteAccount();
                                }
                            },
                            {
                                buttonName:
                                    rowData.data.status === 'Active'
                                        ? 'Suspend'
                                        : 'Resume',
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                showLoader: false,
                                disable: isDisabled,
                                hoverText: isDisabled
                                    ? Messages.ACCOUNT_TEMPORARILY_SUSPENDED
                                    : '',
                                blurEffect: isDisabled,
                                function: () => {
                                    this.selectedAccounts = [rowData.data];

                                    this.openResumeSuspendConfirmationModal(
                                        rowData.data.status === 'Active'
                                            ? false
                                            : true
                                    );
                                    this.widgetRef.changeDetectorRef.detectChanges();
                                }
                            },
                            {
                                buttonName: 'Master Login',
                                buttonColorType: ButtonColorType.SECONDARY,
                                buttonType: ButtonType.TEXT,
                                customClass: `master-login-button master-login-button-${rowData.node.id}`,
                                showLoader: true,
                                disable: isDisabled,
                                hoverText: isDisabled
                                    ? Messages.ACCOUNT_TEMPORARILY_SUSPENDED
                                    : '',
                                blurEffect: isDisabled,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (
                                        rowData['data']['accountStatus'] ===
                                        'UNSUSPEND'
                                    ) {
                                        if (this.masterLoginIndex === null) {
                                            buttonRef.loader = true;
                                            document
                                                .querySelector(
                                                    `.master-login-button-${rowData.node.id}`
                                                )
                                                .classList.add('disabled');
                                            this.masterLoginIndex =
                                                rowData.node.id;
                                            const tenantId =
                                                rowData.data['tenantId'];
                                            this.region =
                                                rowData.data['region'];
                                            if (tenantId) {
                                                const resetTable = () => {
                                                    document
                                                        .querySelector(
                                                            `.master-login-button-${rowData.node.id}`
                                                        )
                                                        .classList.remove(
                                                            'disabled'
                                                        );
                                                    this.masterLoginIndex =
                                                        null;
                                                    document
                                                        .querySelector(
                                                            `.master-login-button-${rowData.node.id}`
                                                        )
                                                        .classList.remove(
                                                            'disabled'
                                                        );
                                                };
                                                const args: IHitApi = {
                                                    url: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}${tenantId}`,
                                                    intactUrl: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}{tenantId}`,
                                                    input: {},
                                                    requestType:
                                                        RequestType.POST,
                                                    uniqueIdentity: Symbol(),
                                                    config: {
                                                        authorization:
                                                            AuthorizationType.BEARER_TOKEN,
                                                            masterRegion: this.region,
                                                            iotRegion: true
                                                    },
                                                    function: (
                                                        users: any[]
                                                    ) => {
                                                        buttonRef.loader =
                                                            false;
                                                        this.widgetRef.changeDetectorRef.detectChanges();

                                                        if (
                                                            users &&
                                                            users.length
                                                        ) {
                                                            resetTable();

                                                            this.widgetRef.modalService.openModal(
                                                                {
                                                                    modalIcon: {
                                                                        type: IconType.FONTAWSOME,
                                                                        class: 'fas fa-users'
                                                                    },
                                                                    modalName:
                                                                        'Master Login',
                                                                    sourceId:
                                                                        this
                                                                            .widgetRef
                                                                            .uniqueIdentity,
                                                                    modalType:
                                                                        ModalType.MIDDLE,
                                                                    modalSteps:
                                                                        [
                                                                            {
                                                                                stepName:
                                                                                    'Master Login',
                                                                                stepData:
                                                                                    {
                                                                                        componentToLoad:
                                                                                            MasterLoginUsersListComponent,
                                                                                        payload:
                                                                                            {
                                                                                                data: {
                                                                                                    users,
                                                                                                    funcRef:
                                                                                                        this.userMasterLogin.bind(
                                                                                                            this
                                                                                                        ),
                                                                                                    widgetRef:
                                                                                                        this
                                                                                                            .widgetRef,
                                                                                                    tenantId:
                                                                                                        tenantId
                                                                                                }
                                                                                            }
                                                                                    }
                                                                            }
                                                                        ],
                                                                    modalWidthVw: 80
                                                                }
                                                            );
                                                        } else {
                                                            Helper.showErrorMessage(
                                                                this.widgetRef
                                                                    .notificationsService,
                                                                null,
                                                                Messages.LOGIN_API_INVALID_RESPONSE
                                                            );
                                                        }
                                                    },
                                                    errorFunction: (error) => {
                                                        buttonRef.loader =
                                                            false;
                                                        this.widgetRef.changeDetectorRef.detectChanges();
                                                        this.masterLoginIndex =
                                                            null;
                                                        document
                                                            .querySelector(
                                                                `.master-login-button-${rowData.node.id}`
                                                            )
                                                            .classList.remove(
                                                                'disabled'
                                                            );
                                                        this.widgetRef.notificationsService.showSnackBar(
                                                            error.error.message,
                                                            true
                                                        );
                                                    }
                                                };
                                                new HitApi(
                                                    args,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            }
                                        }
                                    } else {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Your account has been suspended.',
                                            true
                                        );
                                        buttonRef.loader = false;
                                        return;
                                    }
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            widgetIconData: null,
            buttons: [
                {
                    buttonName: 'Add Customer',
                    function: () => {
                        this.openCustomerModal();
                    },
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY
                }
            ]
        };
    }
    prepareListDataForDefaultViews(data) {
        const defaultViewsListData = [];

        data.forEach((rowData) => {
            defaultViewsListData.push({ id: rowData.id, label: rowData.name });
        });
        return defaultViewsListData;
    }

    openMoreInfo(stepData?) {
        const allRowsData = this.getAllRowsDataFn();
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            modalName: 'More Info',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: CustomerInfoModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: stepData,
                                allRowsData: allRowsData
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    getPriceTypeDetailsFn(rowData, buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = false;
        this.openMoreInfo(rowData);
    }

    getLabelFromResponse(responseData, rowData) {
        const responseMatchedData = [];
        responseData.forEach((allData, index) => {
            if (rowData.includes(allData.id)) {
                responseMatchedData.push(
                    allData.label ? allData.label : allData.name
                );
            } else {
                if (rowData.includes(allData['aclId'])) {
                    responseMatchedData.push(allData['name']);
                }
            }
        });

        return responseMatchedData;
    }
    getAllRowsDataFn(getAllData?) {
        const data = [];
        if (this.agGrid) {
            this.agGrid.api.forEachNode((rowData) => {
                if (!rowData.data.tags) {
                    rowData.data['tags'] = [];
                }
                rowData.data.tags.forEach((tagsData) => {
                    data.push(tagsData);
                });
            });
        }
        return data;
    }
    userMasterLogin(userData, tenantId, resetFunction, viewId) {
        const apiArgs: IHitApi = {
            url: ApiUrls.USER_MASTER_LOGIN,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                masterRegion: this.region,
                iotRegion: false
            },
            function: (response: ILoginResponse) => {
                this.apiResponseCacheService.deleteCollection();
                this.credentialsCacheService.masterLoginDiveIn(response);
                this.sessionService.login(
                    response,
                    this.viewHttpService,
                    true,
                    () => {
                        this.widgetRef.notificationsService.showSnackBar(
                            Messages.LOGIN_API_INVALID_RESPONSE,
                            true
                        );
                        this.credentialsCacheService.masterLoginDiveOut();
                    }
                );
            },

            errorFunction: (error) => {
                resetFunction();
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    Messages.LOGIN_API_INVALID_RESPONSE
                );
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{tenant-id}', tenantId);
        apiArgs.url = apiArgs.url.replace('{user-id}', userData['id']);
        apiArgs.url = apiArgs.url.replace('{view-id}', viewId);

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    applyTagOperations() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.deleteAccount(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'trash_icon_filled',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.openResumeSuspendConfirmationModal(false, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'suspend',
                text: 'Bulk Suspend',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.openResumeSuspendConfirmationModal(true, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'resume',
                text: 'Bulk Resume',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.openBulkAddTagFn();
            },
            icon: {
                type: IconType.SVG,
                class: 'tag_filled',
                text: 'Add Tag',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });

        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }
    openBulkAddTagFn() {
        if (this.agGrid && this.agGrid.api.getSelectedNodes().length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one customer to perform the action'
            );
            return;
        }

        this.selectedAccounts = this.agGrid.api
            .getSelectedNodes()
            .map((rowData) => {
                return rowData.data;
            });

        this.widgetRef.changeDetectorRef.detectChanges();
        const allRowsData = this.getAllRowsDataFn(true);
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            modalName: 'Tags',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: WidgetCatalogTagsModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                allRowsData: allRowsData,
                                selectedRows: this.selectedAccounts,
                                keyNames: this.createKeyNames()
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    createKeyNames() {
        return {
            selectedData: 'selectedRows',
            allRowsData: 'allRowsData',
            names: 'companyName',
            id: 'tenantId',
            message: 'customer',
            headingMessage: 'Customers',
            tagsKeyName: 'tags',
            keysToGetTagData: '',
            payloadIdName: 'tenantId',
            apiInfoKey: 'additionalApisForWidget|addTag'
        };
    }
    onSelectionChanged() {
        this.agGrid.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
    }
    openResumeSuspendConfirmationModal(resume?: boolean, bulkAction?: boolean) {
        if (bulkAction) {
            if (
                this.agGrid &&
                this.agGrid.api.getSelectedNodes().length === 0
            ) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select at least one customer to perform the action'
                );
                return;
            }
        }
        if (bulkAction) {
            if (this.agGrid) {
                this.selectedAccounts = this.agGrid.api
                    .getSelectedNodes()
                    .map((rowData) => {
                        return rowData.data;
                    });

                this.widgetRef.changeDetectorRef.detectChanges();
            }
        }

        const Icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };

        this.modalService.openConfirmationModal({
            modalName: resume ? 'Resume Customer' : 'Suspend Customer',
            modalIcon: Icon,
            bodyTemplate: this.accounts,
            loaderOnExec: true,
            confirmationMessage: `Are you sure you want to ${
                resume ? 'resume' : 'suspend'
            } the following Customer ?`,
            contextIcon: Icon,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            fontSize: 1.12,
            extraClass: 'customer-container',
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo[
                        'additionalApisForWidget'
                    ]['bulkSuspendUnsuspend']
                );

                apiArgs.input = this.createPayload(resume ? false : true);

                apiArgs.function = (response) => {
                    this.notificationService.showSnackBar(
                        `Customer ${
                            resume ? 'resume' : 'suspended'
                        } successfully`
                    );
                    this.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        `Error while ${
                            resume ? 'resuming' : 'suspending'
                        } customer`
                    );
                    this.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },

            modalAutoHeight: true
        });
    }
    deleteAccount(bulkAction?: boolean) {
        if (bulkAction) {
            if (
                this.agGrid &&
                this.agGrid.api.getSelectedNodes().length === 0
            ) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select at least one customer to perform the action'
                );
                return;
            }
        }
        if (bulkAction) {
            if (this.agGrid) {
                this.selectedAccounts = this.agGrid.api
                    .getSelectedNodes()
                    .map((rowData) => {
                        return rowData.data;
                    });

                this.widgetRef.changeDetectorRef.detectChanges();
            }
        }

        const Icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        this.modalService.openConfirmationModal({
            modalName: 'Delete Customer',
            modalIcon: Icon,
            bodyTemplate: this.accounts,

            confirmationMessage:
                'Are you sure you want to  delete the following Customer?',

            contextIcon: Icon,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            extraClass: 'customer-container',
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['batchDelete']
                );
                apiArgs.input = this.createPayload();
                apiArgs.function = (response) => {
                    response['message']
                        ?
                        this.notificationService.showSnackBar(
                            response['message']
                        )
                        : this.notificationService.showSnackBar(
                            'Customer(s) deleted successfully'
                        );
                    this.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error while deleting customers'
                    );
                    this.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
            modalAutoHeight: true,
            reason: true,
            fontSize: 1.12
        });
    }
    createPayload(suspendResume?: boolean) {
        const payLoadData = this.selectedAccounts.map((data) => {
            return data['tenantId'];
        });
        const payLoadObject = {};

        if (suspendResume || suspendResume === false) {
            (payLoadObject['action'] = suspendResume ? 'SUSPEND' : 'UNSUSPEND'),
                (payLoadObject['tenantIds'] = payLoadData);
            return payLoadObject;
        }
        return payLoadData;
    }

    openCustomerModal(stepData?) {
        const allRowsData = this.getAllRowsDataFn();
        if (this.agGrid) {
            this.agGrid.api.forEachNode((rowData) => {
                if (rowData.data['domainId']) {
                    this.domainIds.push(rowData.data['domainId']);
                }
                if (rowData.data['rootUserEmail']) {
                    this.emailIds.push(rowData.data['rootUserEmail']);
                }
            });
        }
        const modalData: IModalData = {
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            modalName: `${stepData ? 'Update' : 'Create'} Customer`,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 73,
            hideSteps: true,

            modalSteps: [
                {
                    stepName: '',
                    resetModal: true,

                    stepData: {
                        componentToLoad: CreateCustomerStepOneV2Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                domainIds: this.domainIds,
                                emailIds: this.emailIds,
                                stepData: stepData ? stepData : null,
                                edit: stepData ? true : false,
                                allRowsData: allRowsData
                            }
                        }
                    }
                },
                {
                    stepName: '',
                    resetModal: true,
                    stepData: {
                        componentToLoad: CreateCustomerStepThreeComponent,
                        payload: {
                            data: {
                                edit: stepData ? true : false,
                                customerData: stepData ? stepData : null,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
}
