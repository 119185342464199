import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-otrs-create-ticket-modal',
    templateUrl: './otrs-create-ticket-modal.component.html',
    styleUrls: ['./otrs-create-ticket-modal.component.sass']
})
export class OtrsCreateTicketModalComponent implements OnInit {
    createTicketFormGenInput: IFormGeneratorInput = null;
    createTicketFormGroup: FormGroup;
    otrsStatusResponse = null;
    widgetRef: Widget;
    stepData;

    constructor(private modalInputData: ModalInjectedData) {
        this.stepData = modalInputData.data;
        this.otrsStatusResponse = modalInputData.data['response'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }
    ngOnInit(): void {
        this.createTicketFormGenInput = {
            formName: 'OTRS Create Ticket',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Create Ticket',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,

                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    const input = {
                        customerUserName:
                            this.createTicketFormGroup.get('customerUserName')
                                .value,
                        queueValue:
                            this.createTicketFormGroup.get('queueValue').value,
                        priority:
                            this.createTicketFormGroup.get('priority').value,

                        body: this.createTicketFormGroup.get('body').value,

                        customFeildValue: {}
                    };
                    if (
                        !this.stepData['isPartner'] &&
                        this.stepData['configuredColumnData']
                    ) {
                        input['title'] = this.stepData['configuredColumnData']
                            ? this.stepData['tableRowData'][
                                  this.stepData['configuredColumnData'][
                                      'titleColumn'
                                  ]
                              ]
                            : '';
                        input['subject'] = this.stepData['configuredColumnData']
                            ? this.stepData['tableRowData'][
                                  this.stepData['configuredColumnData'][
                                      'subjectColumn'
                                  ]
                              ]
                            : '';
                    } else {
                        input['subject'] =
                            this.createTicketFormGroup.get('subject').value;
                        input['title'] =
                            this.createTicketFormGroup.get('title').value;
                    }
                    Object.keys(
                        this.otrsStatusResponse['configuredFeilds'][
                            'customFeilds'
                        ]
                    ).forEach((fieldName) => {
                        input.customFeildValue[fieldName] =
                            this.createTicketFormGroup.get(fieldName).value;
                    });

                    if (this.otrsStatusResponse['userInfo']) {
                        input['customerUserName'] =
                            this.otrsStatusResponse['userInfo'][
                                'customerUserName'
                            ];
                        input['userIntegration'] = true;
                        input['parentIntegration'] = false;
                    } else {
                        input['parentIntegration'] = true;
                        input['userIntegration'] = false;
                    }
                    if (
                        !this.stepData['isPartner'] &&
                        this.stepData['configuredColumnData']
                    ) {
                        (input['subject'] = this.stepData[
                            'configuredColumnData'
                        ]
                            ? this.stepData['tableRowData'][
                                  this.stepData['configuredColumnData'][
                                      'subjectColumn'
                                  ]
                              ]
                            : ''),
                            (input['title'] = this.stepData[
                                'configuredColumnData'
                            ]
                                ? this.stepData['tableRowData'][
                                      this.stepData['configuredColumnData'][
                                          'titleColumn'
                                      ]
                                  ]
                                : '');
                    }
                    const apiArgs: IHitApi = {
                        url: ApiUrls.OTRS_CREATE_TICKET,
                        input: input,
                        requestType: RequestType.POST,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.BEARER_TOKEN
                        },
                        function: (response) => {
                            buttonRef.loader = false;
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.modalInputData.modalId
                            );
                            this.widgetRef.notificationsService.showSnackBar(
                                'Ticket Created Successfully'
                            );
                        },
                        errorFunction: (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error Creating Ticket'
                            );
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            },
            fields: [
                {
                    name: 'customerUserName',
                    placeholder: 'Enter Customer Username',
                    label: 'Customer Username',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer Username is required'
                        }
                    ],
                    value: this.otrsStatusResponse.userIntegration
                        ? this.otrsStatusResponse['userCred'][
                              'customerUserName'
                          ]
                        : null,
                    disabled: this.otrsStatusResponse.userIntegration
                        ? true
                        : false
                },

                {
                    name: 'priority',
                    placeholder: 'Select Priority',
                    label: 'Priority',
                    listData: this.otrsStatusResponse.priorities,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Source is required'
                        }
                    ]
                },
                {
                    name: 'queueValue',
                    placeholder: 'Select Queue',
                    label: 'Queue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData: this.prepareDropdownData(
                        this.otrsStatusResponse['configuredFeilds']['queueId']
                    ),
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ]
                }
            ]
        };

        Object.keys(
            this.otrsStatusResponse['configuredFeilds']['customFeilds']
        ).forEach((field) => {
            this.createTicketFormGenInput.fields.push(
                this.dynamicFormField(field)
            );
        });

        this.createTicketFormGenInput.fields.push({
            name: 'body',
            placeholder: 'Body',
            label: 'Body',
            fieldType: FilterType.TEXTAREA,
            required: true,
            validations: [
                {
                    validator: CustomValidators.required,
                    errorMessage: 'Body is required'
                }
            ]
        });
        if (
            this.stepData['isPartner'] ||
            !this.stepData['configuredColumnData']
        ) {
            this.createTicketFormGenInput.fields.splice(1, 0, {
                name: 'title',
                placeholder: 'Ticket Title',
                label: 'Ticket Title',
                fieldType: FilterType.TEXT,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Ticket Title is required'
                    }
                ],
                value: this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['titleColumn']
                      ]
                    : '',
                disabled: this.stepData['isPartner']
                    ? this.stepData['isPartner']
                    : false
            });
            this.createTicketFormGenInput.fields.splice(5, 0, {
                name: 'subject',
                placeholder: 'Subject',
                label: 'Subject',
                fieldType: FilterType.TEXT,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Subject is required'
                    }
                ],
                value: this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData']['subjectColumn']
                      ]
                    : '',
                disabled: this.stepData['isPartner']
                    ? this.stepData['isPartner']
                    : false
            });
        }
    }

    prepareDropdownData(valueArray) {
        const listData = [];
        valueArray.forEach((value) => {
            listData.push({ id: value, label: value });
        });

        return listData;
    }

    dynamicFormField(fieldName) {
        const formField: IFormField = {
            name: fieldName,
            placeholder: 'Select ' + fieldName,
            label: fieldName,
            listData: this.prepareDropdownData(
                this.otrsStatusResponse['configuredFeilds']['customFeilds'][
                    fieldName
                ]
            ),
            fieldType: FilterType.DROPDOWN_SINGLE,
            required: true,
            validations: [
                {
                    validator: CustomValidators.required,
                    errorMessage: fieldName + ' is required'
                }
            ]
        };

        return formField;
    }
}
