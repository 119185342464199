import { Component, OnInit } from '@angular/core';
import { ColDef, GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { getLogoPathOfCloudLabel } from 'src/app/shared/enums/CloudLabel';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-acl-modal-step-two',
    templateUrl: './acl-modal-step-two.component.html',
    styleUrls: ['./acl-modal-step-two.component.sass']
})
export class AclModalStepTwoComponent implements OnInit {
    widgetRef: Widget;
    isEdit: boolean;
    stepData: any;
    aclData: any;

    hasCurrentStepData: boolean = false;

    agGrid: GridOptions;

    // Unique Cloud, Sub-Product and Service pair list
    uniqueCspsPairList: {
        cloud?: string;
        subProduct?: string;
        service?: string;
    }[] = [];

    cloudFilterInfo: IFilterInfo;
    cloudSelected: any[] = [];
    cloudSet = new Set<string>();
    subProductFilterInfo: IFilterInfo;
    subProductSelected: any[] = [];
    subProductSet = new Set<string>();
    serviceFilterInfo: IFilterInfo;
    serviceSelected: any[] = [];
    serviceSet = new Set<string>();

    permissionFilterInfo: IFilterInfo;
    permissionSelected: any = null;
    versionFilterInfo: IFilterInfo;
    versionSelected: any = null;

    masterAclWidgetList: any[];
    aclWidgetList: any[];

    aclWidgetMap: Map<string, any>;

    isLoading: boolean = true;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    tableGenInput: ITableGeneratorInput;
    tableData: any[];
    defaultColDef: ColDef = {
        width: 100,
        filter: false,
        editable: false,
        sortable: false,
        resizable: false
    };

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: () => {
                this.onBack();
            }
        },
        {
            buttonName: 'Create',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.onCreateOrEditAcl(buttonRef);
            }
        }
    ];

    readonly WIDGET_ID_KEY = 'id';
    readonly WIDGET_NAME_KEY = 'name';
    readonly CLOUD_KEY = 'cloud';
    readonly SUB_PRODUCT_KEY = 'subProduct';
    readonly SERVICE_KEY = 'service';
    readonly PERMISSION_KEY = 'permission';
    readonly DEFAULT_WIDGET_TYPE_KEY = 'defaultWidgetType';
    readonly ACL_WIDGET_TYPE_KEY = 'aclWidgetType';

    isDuplicateViewACL: boolean = false;

    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.isEdit = modalInjectedData.data['isEdit'];
        this.isDuplicateViewACL = modalInjectedData.data['isDuplicateViewACL'];
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(1)
        ) {
            this.stepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(1);
        }
        if (modalInjectedData.data['stepData']) {
            this.aclData = modalInjectedData.data['stepData'];
        }
        if (
            multiStepFormService.stepData.get(modalInjectedData.modalId).has(2)
        ) {
            const currentStepData = multiStepFormService.stepData
                .get(modalInjectedData.modalId)
                .get(2);
            this.masterAclWidgetList = currentStepData['masterAclWidgetList'];
            if (this.masterAclWidgetList) {
                this.hasCurrentStepData = true;
            } else {
                this.hasCurrentStepData = false;
            }
            this.aclWidgetList = currentStepData['aclWidgetList'];
            this.aclWidgetMap = currentStepData['aclWidgetMap'];
        }
    }

    ngOnInit(): void {
        if (this.isEdit) {
            this.buttonGenInputs[1].buttonName = 'Update';
        }
        if (this.hasCurrentStepData) {
            this.initFilterInfos();
            this.setUpTableColumns();
            this.prepareFilters();
            this.tableData = this.masterAclWidgetList;
            this.isLoading = false;
        } else {
            this.fetchMasterAclWidgetList();
            this.initFilterInfos();
            this.setUpTableColumns();
        }
    }

    getSortedMasterAcl(responseList) {
        const sortedlist = [];
        const globalWidgets = [];
        responseList.forEach((widget) => {
            if (
                widget &&
                widget[this.CLOUD_KEY] &&
                widget[this.CLOUD_KEY].includes('GLOBAL')
            ) {
                globalWidgets.push(widget);
            } else {
                sortedlist.push(widget);
            }
        });
        globalWidgets.forEach((widget) => sortedlist.push(widget));
        return sortedlist;
    }

    fetchMasterAclWidgetList() {
        const apiInfo: IApiInfo = {
            host: '',
            apiRouteSuffix: ApiUrls.ACL_DESCRIBE_MASTER,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };
        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.masterAclWidgetList = this.getSortedMasterAcl(response);
            this.prepareFilters();
            if (this.isEdit) {
                this.fetchAclWidgetList();
            } else {
                this.aclWidgetMap = new Map<string, any>();
                this.tableData = this.masterAclWidgetList;
                this.isLoading = false;
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.isLoading = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    fetchAclWidgetList() {
        if (this.aclData && this.aclData.id) {
            const apiInfo: IApiInfo = {
                host: '',
                apiRouteSuffix: ApiUrls.ACL_DESCRIBE.replace(
                    '{aclId}',
                    this.aclData.id
                ),
                authorization: AuthorizationType.BEARER_TOKEN,
                customInput: null,
                requestType: RequestType.GET,
                intactUrl: ApiUrls.ACL_DESCRIBE
            };
            const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);
            apiArgs.input = {};
            apiArgs.function = (response) => {
                this.aclWidgetList = response;
                this.aclWidgetMap = new Map<string, any>();
                this.prepareWidgetMapFromList(
                    this.aclWidgetMap,
                    this.aclWidgetList
                );
                this.tableData = this.masterAclWidgetList;
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
            };
            apiArgs.endFunction = () => {
                this.isLoading = false;
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    prepareWidgetMapFromList(map: Map<string, any>, list: any[]) {
        if (list && list.length) {
            list.forEach((aclWidgetInfo) => {
                map.set(aclWidgetInfo[this.WIDGET_ID_KEY], aclWidgetInfo);
            });
        }
    }

    setUpTableColumns() {
        const colDefsTable: IColumnData[] = [
            {
                columnKey: this.WIDGET_NAME_KEY,
                columnName: 'Widget Name',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                tooltipField: this.WIDGET_NAME_KEY,
                minWidth: 250,
                flex: 5
            },
            {
                columnKey: this.CLOUD_KEY,
                columnName: '',
                cellClass: 'cloud-icon-cell content-center',
                cellRenderer: this.widgetCloudIconRenderer.bind(this),
                minWidth: 40,
                flex: 1
            },
            {
                columnKey: '',
                columnName: 'Lite Version',
                cellClass: 'cell-center',
                headerClass: 'header-center',
                cellRenderer: this.widgetVersionRenderer.bind(this),
                tooltipValueGetter: (params) => {
                    if (
                        params.data[this.DEFAULT_WIDGET_TYPE_KEY] ===
                        Version.FULL
                    ) {
                        return 'Lite version is not supported';
                    }
                },
                minWidth: 60,
                flex: 1
            },
            {
                columnKey: '',
                columnName: 'Read',
                cellClass: 'cell-center',
                headerClass: 'header-center',
                cellRenderer: this.widgetReadRenderer.bind(this),
                minWidth: 60,
                flex: 1
            },
            {
                columnKey: '',
                columnName: 'Read/Write',
                cellClass: 'cell-center',
                headerClass: 'header-center',
                cellRenderer: this.widgetReadWriteRenderer.bind(this),
                minWidth: 60,
                flex: 1
            },
            {
                columnKey: '',
                columnName: 'Deny',
                cellClass: 'cell-center',
                headerClass: 'header-center',
                cellRenderer: this.widgetDenyRenderer.bind(this),
                minWidth: 60,
                flex: 1
            }
        ];
        this.tableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns: colDefsTable,
            isExternalFilterPresent: this.isExternalFilterAvailable.bind(this),
            doesExternalFilterPass: this.externalFilterFunction.bind(this)
        };
    }

    widgetCloudIconRenderer(rowNode) {
        const rowDataItem = rowNode.data;
        if (rowDataItem && rowDataItem[this.CLOUD_KEY]) {
            const cloudIconPath = getLogoPathOfCloudLabel(
                rowDataItem[this.CLOUD_KEY]
            );
            if (cloudIconPath) {
                return `<img style="max-height: 100%; max-width: 100%" src="${cloudIconPath}" alt="${
                    rowDataItem[this.CLOUD_KEY]
                }">`;
            }
        }
    }

    widgetVersionRenderer(rowNode: RowEvent) {
        const rowDataItem = rowNode.data;
        const widgetId = rowDataItem[this.WIDGET_ID_KEY];
        const masterWidgetType = rowDataItem[this.ACL_WIDGET_TYPE_KEY];
        const defaultWidgetType = rowDataItem[this.DEFAULT_WIDGET_TYPE_KEY];
        if (defaultWidgetType) {
            if (defaultWidgetType === Version.FULL) {
                return '<i class="fas fa-toggle-off toggle-switch disabled"></i>';
            }
            if (defaultWidgetType === Version.LITE) {
                return '<i class="fas fa-toggle-on toggle-switch accent disabled"></i>';
            }
            if (defaultWidgetType === Version.LITE_FULL) {
                if (masterWidgetType === Version.LITE) {
                    return '<i class="fas fa-toggle-on toggle-switch accent disabled"></i>';
                } else {
                    const toggleIcon = document.createElement('i');
                    toggleIcon.classList.add('fas', 'toggle-switch', 'accent');
                    let aclWidgetType = Version.FULL;
                    const aclWidgetInfo = this.aclWidgetMap?.get(widgetId);
                    if (aclWidgetInfo) {
                        aclWidgetType = aclWidgetInfo[this.ACL_WIDGET_TYPE_KEY];
                    } else {
                        aclWidgetType = rowDataItem[this.ACL_WIDGET_TYPE_KEY];
                    }
                    if (aclWidgetType && aclWidgetType === Version.LITE) {
                        toggleIcon.classList.add('fa-toggle-on');
                    } else {
                        toggleIcon.classList.add('fa-toggle-off');
                    }
                    const switchVersionInAclWidgetInfo = (version: Version) => {
                        const aclWidgetInfo = this.aclWidgetMap?.get(widgetId);
                        if (aclWidgetInfo) {
                            aclWidgetInfo[this.ACL_WIDGET_TYPE_KEY] = version;
                        }
                    };
                    toggleIcon.addEventListener('click', (e) => {
                        e.stopPropagation();
                        if (rowNode.node.isSelected()) {
                            if (
                                toggleIcon.classList.contains('fa-toggle-off')
                            ) {
                                switchVersionInAclWidgetInfo(Version.LITE);
                                toggleIcon.classList.replace(
                                    'fa-toggle-off',
                                    'fa-toggle-on'
                                );
                            } else {
                                switchVersionInAclWidgetInfo(Version.FULL);
                                toggleIcon.classList.replace(
                                    'fa-toggle-on',
                                    'fa-toggle-off'
                                );
                            }
                        }
                    });
                    return toggleIcon;
                }
            }
        }
    }

    getRadioButton(
        inputName,
        inputId,
        callback,
        callbackParam?,
        rowSelected = false,
        disabled = false,
        checked = false
    ) {
        const radioButtonContainer = document.createElement('div');
        const radioButtonSibling = document.createElement('div');
        const radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.name = inputName;
        radioButton.value = inputId;
        radioButton.disabled = disabled;
        radioButton.checked = checked;
        radioButton.addEventListener('click', (event) => {
            callback(callbackParam, inputId);
        });
        radioButtonContainer.classList.add('radio-button-container');
        if (disabled) {
            radioButtonContainer.classList.add('disabled');
        }
        radioButtonSibling.classList.add('radio-button');
        radioButtonContainer.appendChild(radioButton);
        radioButtonContainer.appendChild(radioButtonSibling);
        radioButtonContainer.addEventListener('click', (event) => {
            if (rowSelected && !disabled) {
                radioButton.click();
            }
        });
        return radioButtonContainer;
    }

    handlePermission(rowDataItem, permission) {
        const widgetId = rowDataItem[this.WIDGET_ID_KEY];
        const masterPermission = rowDataItem[this.PERMISSION_KEY];
        const aclWidgetInfo = this.aclWidgetMap?.get(widgetId);
        if (aclWidgetInfo) {
            if (permission === Permission.DENY) {
                aclWidgetInfo[this.PERMISSION_KEY] = Permission.DENY;
            }
            if (permission === Permission.READ) {
                if (masterPermission === Permission.DENY) {
                    aclWidgetInfo[this.PERMISSION_KEY] = Permission.DENY;
                } else {
                    aclWidgetInfo[this.PERMISSION_KEY] = Permission.READ;
                }
            }
            if (permission === Permission.READ_WRITE) {
                if (
                    masterPermission === Permission.DENY ||
                    masterPermission === Permission.READ
                ) {
                    aclWidgetInfo[this.PERMISSION_KEY] = Permission.DENY;
                } else {
                    aclWidgetInfo[this.PERMISSION_KEY] = Permission.READ_WRITE;
                }
            }
        }
    }

    widgetReadWriteRenderer(rowNode: RowEvent) {
        const rowDataItem = rowNode.data;
        const masterAclPermission = rowDataItem[this.PERMISSION_KEY];
        let selected = false;
        let disabled = false;
        let checked = false;
        if (rowNode.node.isSelected()) {
            selected = true;
            if (
                masterAclPermission === Permission.READ ||
                masterAclPermission === Permission.DENY
            ) {
                disabled = true;
            } else {
                const aclWidget = this.aclWidgetMap?.get(
                    rowDataItem[this.WIDGET_ID_KEY]
                );
                const aclPermission = aclWidget[this.PERMISSION_KEY];
                if (!aclPermission || aclPermission === Permission.READ_WRITE) {
                    checked = true;
                }
            }
        } else {
            if (
                masterAclPermission === Permission.READ ||
                masterAclPermission === Permission.DENY
            ) {
                disabled = true;
            }
        }
        return this.getRadioButton(
            rowDataItem[this.WIDGET_ID_KEY],
            Permission.READ_WRITE,
            this.handlePermission.bind(this),
            rowDataItem,
            selected,
            disabled,
            checked
        );
    }

    widgetReadRenderer(rowNode) {
        const rowDataItem = rowNode.data;
        const masterAclPermission = rowDataItem[this.PERMISSION_KEY];
        let selected = false;
        let disabled = false;
        let checked = false;
        if (rowNode.node.isSelected()) {
            selected = true;
            if (masterAclPermission === Permission.DENY) {
                disabled = true;
            } else {
                const aclWidget = this.aclWidgetMap?.get(
                    rowDataItem[this.WIDGET_ID_KEY]
                );
                const aclPermission = aclWidget[this.PERMISSION_KEY];
                if (!aclPermission || aclPermission === Permission.READ) {
                    checked = true;
                }
            }
        } else {
            if (masterAclPermission === Permission.DENY) {
                disabled = true;
            }
        }
        return this.getRadioButton(
            rowDataItem[this.WIDGET_ID_KEY],
            Permission.READ,
            this.handlePermission.bind(this),
            rowDataItem,
            selected,
            disabled,
            checked
        );
    }

    widgetDenyRenderer(rowNode: RowEvent) {
        const rowDataItem = rowNode.data;
        const masterAclPermission = rowDataItem[this.PERMISSION_KEY];
        let selected = false;
        let checked = false;
        if (rowNode.node.isSelected()) {
            selected = true;
            if (masterAclPermission === Permission.DENY) {
                checked = true;
            } else {
                const aclWidget = this.aclWidgetMap?.get(
                    rowDataItem[this.WIDGET_ID_KEY]
                );
                const aclPermission = aclWidget[this.PERMISSION_KEY];
                if (!aclPermission || aclPermission === Permission.DENY) {
                    checked = true;
                }
            }
        }
        return this.getRadioButton(
            rowDataItem[this.WIDGET_ID_KEY],
            Permission.DENY,
            this.handlePermission.bind(this),
            rowDataItem,
            selected,
            false,
            checked
        );
    }

    setCloudFilterInfo(listData?: string[]) {
        this.cloudFilterInfo = {
            placeholder: 'Cloud',
            label: 'Cloud',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setSubProductFilterInfo(listData?: string[]) {
        this.subProductFilterInfo = {
            placeholder: 'Sub - Product',
            label: 'Sub - Product',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setServiceFilterInfo(listData?: string[]) {
        this.serviceFilterInfo = {
            placeholder: 'Service',
            label: 'Service',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    initFilterInfos() {
        this.setCloudFilterInfo();
        this.setSubProductFilterInfo();
        this.setServiceFilterInfo();
        this.permissionFilterInfo = {
            placeholder: 'Permission',
            label: 'Permission',
            listData: [
                {
                    id: Permission.READ,
                    label: 'Read'
                },
                {
                    id: Permission.READ_WRITE,
                    label: 'Read/Write'
                },
                {
                    id: Permission.DENY,
                    label: 'Deny'
                }
            ]
        };
        this.versionFilterInfo = {
            placeholder: 'Version',
            label: 'Version',
            listData: [
                {
                    id: Version.LITE,
                    label: 'Lite'
                },
                {
                    id: Version.FULL,
                    label: 'Full'
                }
            ]
        };
    }

    prepareFilters() {
        if (this.masterAclWidgetList && this.masterAclWidgetList.length) {
            this.uniqueCspsPairList = [];
            const keySet = new Set<string>();
            this.masterAclWidgetList.forEach((masterAclWidget) => {
                let cloud = null;
                let subProduct = null;
                let service = null;
                if (masterAclWidget[this.CLOUD_KEY]) {
                    cloud = masterAclWidget[this.CLOUD_KEY];
                    this.cloudSet.add(cloud);
                }
                if (masterAclWidget[this.SUB_PRODUCT_KEY]) {
                    subProduct = masterAclWidget[this.SUB_PRODUCT_KEY];
                    this.subProductSet.add(subProduct);
                }
                if (masterAclWidget[this.SERVICE_KEY]) {
                    service = masterAclWidget[this.SERVICE_KEY];
                    this.serviceSet.add(service);
                }
                if (cloud || subProduct || service) {
                    const uniquePairString = [cloud, subProduct, service].join(
                        '|'
                    );
                    if (!keySet.has(uniquePairString)) {
                        keySet.add(uniquePairString);
                        this.uniqueCspsPairList.push({
                            cloud: cloud,
                            subProduct: subProduct,
                            service: service
                        });
                    }
                }
            });
            this.setCloudFilterInfo(Array.from(this.cloudSet.values()));
            this.setSubProductFilterInfo(
                Array.from(this.subProductSet.values())
            );
            this.setServiceFilterInfo(Array.from(this.serviceSet.values()));
        }
    }

    areDistinctLists(ls1: string[], ls2: string[]) {
        if (ls1.length === ls2.length) {
            let result = false;
            for (let i = 0; i < ls1.length; i++) {
                if (!ls2.includes(ls1[i])) {
                    result = true;
                    break;
                }
            }
            return result;
        }
        return true;
    }

    updateListDataForCloud(selectedClouds) {
        let subProductList = null;
        let serviceList = null;
        const localServiceSet = new Set<string>();
        if (selectedClouds && selectedClouds.length) {
            const localSubProductSet = new Set<string>();
            if (this.subProductSelected && this.subProductSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedClouds.includes(cspsPair.cloud)) {
                        if (cspsPair.subProduct) {
                            localSubProductSet.add(cspsPair.subProduct);
                        }
                        if (
                            this.subProductSelected.includes(
                                cspsPair.subProduct
                            ) ||
                            localSubProductSet.has(cspsPair.subProduct)
                        ) {
                            if (cspsPair.service) {
                                localServiceSet.add(cspsPair.service);
                            }
                        }
                    }
                });
            } else {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedClouds.includes(cspsPair.cloud)) {
                        if (cspsPair.subProduct) {
                            localSubProductSet.add(cspsPair.subProduct);
                        }
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            }
            subProductList = Array.from(localSubProductSet.values());
            serviceList = Array.from(localServiceSet.values());
        } else {
            subProductList = Array.from(this.subProductSet.values());
            if (this.subProductSelected && this.subProductSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (this.subProductSelected.includes(cspsPair.subProduct)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
                serviceList = Array.from(localServiceSet.values());
            } else {
                serviceList = Array.from(this.serviceSet.values());
            }
        }
        this.updateSubProductSelectedValues(subProductList);
        this.setSubProductFilterInfo(subProductList);
        this.updateServiceSelectedValues(serviceList);
        this.setServiceFilterInfo(serviceList);
    }

    updateListDataForSubProduct(selectedSubProducts) {
        let serviceList = null;
        const localServiceSet = new Set<string>();
        if (selectedSubProducts && selectedSubProducts.length) {
            if (this.cloudSelected && this.cloudSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (
                        this.cloudSelected.includes(cspsPair.cloud) &&
                        selectedSubProducts.includes(cspsPair.subProduct)
                    ) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            } else {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedSubProducts.includes(cspsPair.subProduct)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            }
            serviceList = Array.from(localServiceSet.values());
        } else {
            if (this.cloudSelected && this.cloudSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (this.cloudSelected.includes(cspsPair.cloud)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
                serviceList = Array.from(localServiceSet.values());
            } else {
                serviceList = Array.from(this.serviceSet.values());
            }
        }
        this.updateServiceSelectedValues(serviceList);
        this.setServiceFilterInfo(serviceList);
    }

    updateSubProductSelectedValues(checkList) {
        const selected = this.subProductSelected.filter((subProductSel) => {
            return checkList.includes(subProductSel);
        });
        if (this.areDistinctLists(selected, this.subProductSelected)) {
            this.subProductSelected = selected;
        }
    }

    updateServiceSelectedValues(checkList) {
        const selected = this.serviceSelected.filter((serviceSel) => {
            return checkList.includes(serviceSel);
        });
        if (this.areDistinctLists(selected, this.serviceSelected)) {
            this.serviceSelected = selected;
        }
    }

    updateFilterInfoListData(selectedList, type) {
        if (this.agGrid) {
            if (type === this.CLOUD_KEY) {
                if (this.areDistinctLists(this.cloudSelected, selectedList)) {
                    this.updateListDataForCloud(selectedList);
                    this.cloudSelected = selectedList;
                    this.agGrid.api.onFilterChanged();
                }
            }
            if (type === this.SUB_PRODUCT_KEY) {
                if (
                    this.areDistinctLists(this.subProductSelected, selectedList)
                ) {
                    this.updateListDataForSubProduct(selectedList);
                    this.subProductSelected = selectedList;
                    this.agGrid.api.onFilterChanged();
                }
            }
            if (type === this.SERVICE_KEY) {
                if (this.areDistinctLists(this.serviceSelected, selectedList)) {
                    this.serviceSelected = selectedList;
                    this.agGrid.api.onFilterChanged();
                }
            }
        }
    }

    isExternalFilterAvailable() {
        if (
            (this.cloudSelected && this.cloudSelected.length) ||
            (this.subProductSelected && this.subProductSelected.length) ||
            (this.serviceSelected && this.serviceSelected.length)
        ) {
            return true;
        }
        return false;
    }

    externalFilterFunction(node: RowNode) {
        const nodeData = node.data;
        let result = true;
        if (this.cloudSelected && this.cloudSelected.length) {
            if (this.cloudSelected.includes(nodeData[this.CLOUD_KEY])) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.subProductSelected && this.subProductSelected.length) {
            if (
                this.subProductSelected.includes(nodeData[this.SUB_PRODUCT_KEY])
            ) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.serviceSelected && this.serviceSelected.length) {
            if (this.serviceSelected.includes(nodeData[this.SERVICE_KEY])) {
                result = result && true;
            } else {
                result = result && false;
            }
        }
        return result;
    }

    bulkUpdateWidgetType(selectedValue) {
        if (selectedValue) {
            if (selectedValue !== this.versionSelected) {
                this.versionSelected = selectedValue;
                if (this.agGrid) {
                    this.agGrid.api
                        .getSelectedNodes()
                        .forEach((rowNode: RowNode) => {
                            const rowNodeData = rowNode.data;
                            if (
                                rowNodeData[this.DEFAULT_WIDGET_TYPE_KEY] ===
                                Version.LITE_FULL
                            ) {
                                const aclWidgetInfo = this.aclWidgetMap?.get(
                                    rowNodeData[this.WIDGET_ID_KEY]
                                );
                                if (aclWidgetInfo) {
                                    if (selectedValue === Version.FULL) {
                                        aclWidgetInfo[
                                            this.ACL_WIDGET_TYPE_KEY
                                        ] = Version.FULL;
                                    }
                                    if (selectedValue === Version.LITE) {
                                        aclWidgetInfo[
                                            this.ACL_WIDGET_TYPE_KEY
                                        ] = Version.LITE;
                                    }
                                }
                            }
                        });
                    this.agGrid.api.redrawRows();
                }
            }
        } else {
            this.versionSelected = null;
        }
    }

    bulkUpdatePermission(selectedValue) {
        if (selectedValue) {
            if (selectedValue !== this.permissionSelected) {
                this.permissionSelected = selectedValue;
                if (this.agGrid) {
                    this.agGrid.api
                        .getSelectedNodes()
                        .forEach((rowNode: RowNode) => {
                            const rowNodeData = rowNode.data;
                            let permission = Permission.DENY;
                            if (
                                rowNodeData[this.PERMISSION_KEY] ===
                                Permission.READ_WRITE
                            ) {
                                permission = selectedValue;
                            }
                            if (
                                rowNodeData[this.PERMISSION_KEY] ===
                                Permission.READ
                            ) {
                                if (selectedValue === Permission.READ_WRITE) {
                                    permission = null;
                                } else {
                                    permission = selectedValue;
                                }
                            }
                            if (
                                rowNodeData[this.PERMISSION_KEY] ===
                                Permission.DENY
                            ) {
                                if (
                                    selectedValue === Permission.READ_WRITE ||
                                    selectedValue === Permission.READ
                                ) {
                                    permission = null;
                                } else {
                                    permission = selectedValue;
                                }
                            }
                            if (permission) {
                                const aclWidgetInfo = this.aclWidgetMap?.get(
                                    rowNodeData[this.WIDGET_ID_KEY]
                                );
                                if (aclWidgetInfo) {
                                    aclWidgetInfo[this.PERMISSION_KEY] =
                                        permission;
                                }
                            }
                        });
                    this.agGrid.api.redrawRows();
                }
            }
        } else {
            this.permissionSelected = null;
        }
    }

    prepareInputData() {
        const widgetPermissions = [];
        this.aclWidgetMap?.forEach((aclWidgetInfo, widgetId) => {
            widgetPermissions.push({
                id: widgetId,
                permission: aclWidgetInfo[this.PERMISSION_KEY],
                aclWidgetType: aclWidgetInfo[this.ACL_WIDGET_TYPE_KEY]
            });
        });
        if (widgetPermissions.length) {
            const finalInputData = {};
            if (!this.isEdit) {
                finalInputData['name'] = this.stepData['name'];
            }
            finalInputData['description'] = this.stepData['description'];
            finalInputData['widgetPermissions'] = widgetPermissions;
            return finalInputData;
        } else {
            return null;
        }
    }

    submitRequest(
        apiInfo: IApiInfo,
        inputData,
        successMessage,
        defaultErrorMessage,
        buttonRef?: IButtonGeneratorInput
    ) {
        buttonRef.loader = true;
        const apiArgs = Helper.generateHitApiConfig(apiInfo);
        apiArgs.input = inputData;
        if (this.isEdit) {
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace('{aclId}', this.aclData['id']);
        }
        apiArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(successMessage);
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInjectedData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                defaultErrorMessage
            );
        };
        apiArgs.endFunction = () => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    onCreateOrEditAcl(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        const inputData = this.prepareInputData();
        if (inputData) {
            let apiInfo = this.isEdit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create;
            if (this.isDuplicateViewACL) {
                apiInfo = this.getUpdateACLAPIInfo();
            }
            this.submitRequest(
                apiInfo,
                inputData,
                this.isEdit
                    ? 'ACL updated successfully'
                    : 'ACL created successfully',
                this.isEdit ? 'ACL updation failed' : 'ACL creation failed',
                buttonRef
            );
        } else {
            this.widgetRef.notificationsService.showSnackBar(
                'No Widget Selected'
            );
        }
    }

    onBack() {
        const currentStepData = {
            masterAclWidgetList: this.masterAclWidgetList,
            aclWidgetList: this.aclWidgetList,
            aclWidgetMap: this.aclWidgetMap
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, currentStepData);
        this.multiStepFormService.previousStep(this.modalInjectedData.modalId);
    }

    onSelectionChanged(event) {
        if (this.agGrid) {
            const selectedMap = this.aclWidgetMap;
            this.aclWidgetMap = new Map<string, any>();
            const selectedNodes = this.agGrid.api.getSelectedNodes();
            const displayedNodes: RowNode[] = (this.agGrid.api as any)[
                'rowModel'
            ]['rowsToDisplay'];
            if (displayedNodes.length < selectedNodes.length) {
                displayedNodes.forEach((rowNode: RowNode) => {
                    const rowNodeData = rowNode.data;
                    if (!selectedMap.has(rowNodeData[this.WIDGET_ID_KEY])) {
                        const clonedMasterAclWidget =
                            Helper.cloneDeep(rowNodeData);
                        clonedMasterAclWidget[this.PERMISSION_KEY] =
                            Permission.DENY;
                        selectedMap.set(
                            rowNodeData[this.WIDGET_ID_KEY],
                            clonedMasterAclWidget
                        );
                    }
                });
                selectedNodes.forEach((rowNode: RowNode) => {
                    const rowNodeData = rowNode.data;
                    if (selectedMap.has(rowNodeData[this.WIDGET_ID_KEY])) {
                        this.aclWidgetMap.set(
                            rowNodeData[this.WIDGET_ID_KEY],
                            selectedMap.get(rowNodeData[this.WIDGET_ID_KEY])
                        );
                    } else {
                        rowNode.setSelected(false, false, true);
                    }
                });
            } else {
                if (
                    selectedNodes.length === 0 &&
                    displayedNodes.length < this.masterAclWidgetList.length
                ) {
                    displayedNodes.forEach((rowNode: RowNode) => {
                        const rowNodeData = rowNode.data;
                        if (selectedMap.has(rowNodeData[this.WIDGET_ID_KEY])) {
                            selectedMap.delete(rowNodeData[this.WIDGET_ID_KEY]);
                        }
                    });
                    this.agGrid.api.forEachNode((rowNode: RowNode, index) => {
                        const rowNodeData = rowNode.data;
                        if (selectedMap.has(rowNodeData[this.WIDGET_ID_KEY])) {
                            this.aclWidgetMap.set(
                                rowNodeData[this.WIDGET_ID_KEY],
                                selectedMap.get(rowNodeData[this.WIDGET_ID_KEY])
                            );
                            if (index === selectedMap.size) {
                                rowNode.setSelected(true);
                            } else {
                                rowNode.setSelected(true, false, true);
                            }
                        }
                    });
                } else {
                    selectedNodes.forEach((rowNode: RowNode) => {
                        const rowNodeData = rowNode.data;
                        if (selectedMap.has(rowNodeData[this.WIDGET_ID_KEY])) {
                            this.aclWidgetMap.set(
                                rowNodeData[this.WIDGET_ID_KEY],
                                selectedMap.get(rowNodeData[this.WIDGET_ID_KEY])
                            );
                        } else {
                            const clonedMasterAclWidget =
                                Helper.cloneDeep(rowNodeData);
                            clonedMasterAclWidget[this.PERMISSION_KEY] =
                                Permission.DENY;
                            this.aclWidgetMap.set(
                                rowNodeData[this.WIDGET_ID_KEY],
                                clonedMasterAclWidget
                            );
                        }
                    });
                }
            }
            if (this.aclWidgetMap && this.aclWidgetMap.size === 0) {
                this.permissionSelected = null;
                this.versionSelected = null;
            }
            this.agGrid.api.redrawRows();
        }
    }

    onGridReady(event) {
        setTimeout(() => {
            if (this.agGrid && this.aclWidgetMap.size) {
                const total = this.aclWidgetMap.size;
                let count = 0;
                this.agGrid.api.forEachNode((rowNode: RowNode, index) => {
                    const rowNodeData = rowNode.data;
                    if (
                        this.aclWidgetMap.has(rowNodeData[this.WIDGET_ID_KEY])
                    ) {
                        count++;
                        if (count === total) {
                            rowNode.setSelected(true);
                        } else {
                            rowNode.setSelected(true, false, true);
                        }
                    }
                });
            }
        }, 500);
    }

    onQuickFilterChanged(id: string) {
        if (this.agGrid) {
            this.agGrid.api.setQuickFilter(
                document.getElementById(id)['value']
            );
        }
    }

    getUpdateACLAPIInfo() {
        const apiInfo = {
            apiRouteSuffix: "/view-and-api-management/aclv2/{aclId}",
            requestType: RequestType.PATCH,
            authorization: AuthorizationType.BEARER_TOKEN,
            host: environment.baseUrl,
            apiSkeleton: "/view-and-api-management/aclv2/{aclId}"
        }
        return apiInfo;
    }
}

enum Version {
    LITE_FULL = 'LITE_FULL',
    LITE = 'LITE',
    FULL = 'FULL'
}

enum Permission {
    READ = 'READ',
    READ_WRITE = 'READ_WRITE',
    DENY = 'DENY'
}
