<ng-container *ngIf="showWidgets">
    <app-only-table-generator
        *ngIf="tableGenInput && aclWidgetApiInfo"
        [tableInput]="tableGenInput"
        [apiInfo]="aclWidgetApiInfo"
    ></app-only-table-generator>
</ng-container>
<ng-container *ngIf="!showWidgets">
    <app-only-table-generator
        *ngIf="tableGenInput && tableData"
        [class.vertical-center]="!tableData || tableData.length === 0"
        [tableInput]="tableGenInput"
        [tableData]="tableData"
    ></app-only-table-generator>
</ng-container>
