<app-modal-info-template [infoData]="infoData"> </app-modal-info-template>
<div class="overlay" *ngIf="!filtersFormGenInput">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>

<div class="filter-container" *ngIf="filtersFormGenInput">
    <div class="form-container">
        <app-form-generator
            *ngIf="filtersFormGenInput"
            [formGeneratorInput]="filtersFormGenInput"
            (formGroupRef)="filtersFormGroup = $event"
        >
        </app-form-generator>
    </div>
    <div class="form-controls">
        <app-button-generator
            [buttonGenInput]="buttonGenInput"
        ></app-button-generator>
    </div>
</div>
