import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-otrs-child-integration',
    templateUrl: './otrs-child-integration.component.html',
    styleUrls: ['./otrs-child-integration.component.sass']
})
export class OtrsChildIntegrationComponent implements OnInit {
    childIntegrationFormGenInput: IFormGeneratorInput = null;
    isUserIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response;
    widgetRef: Widget;
    constructor(private modalInputData: ModalInjectedData) {
        this.response = modalInputData.data['response'];
        this.isOwnerIntegrated =
            modalInputData.data['response']['ownerIntegration'];
        this.isUserIntegrated =
            modalInputData.data['response']['userIntegration'];
        this.isParentIntegrated =
            modalInputData.data['response']['parentIntegration'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.childIntegrationFormGenInput = {
            formName: 'OTRS',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Save',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,

                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        if (this.isOwnerIntegrated && this.isUserIntegrated) {
                            // Update
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.update
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: IntegrationName.OTRS,
                                userIntegration: 'true',
                                parentIntegration: 'false'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'OTRS configuration updated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error updating OTRS configuration'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        } else {
                            // Create
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.create
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: IntegrationName.OTRS,
                                userIntegration: 'true',
                                parentIntegration: 'false'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'OTRS integrated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error integrating OTRS'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                }
            },
            fields: [
                {
                    name: 'customerId',
                    placeholder: 'Enter Customer Id',
                    label: 'Customer Id',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer Id is required'
                        }
                    ],
                    value: this.isUserIntegrated
                        ? this.response['userCred']['customerId']
                        : ''
                },
                {
                    name: 'customerUserName',
                    placeholder: 'Enter Customer Usermame',
                    label: 'User Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'User name is required'
                        }
                    ],
                    value: this.isUserIntegrated
                        ? this.response['userCred']['customerUserName']
                        : ''
                }
            ]
        };
    }
}
