import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { SideMenuType } from 'src/app/shared/enums/SideMenuType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { FilterType } from './../../../../enums/FilterType';
import { FormState } from './../../../../enums/FormState';
import { IGeneralConfigResponse } from './../../../../interfaces/api/portlets/IGeneralConfigResponse';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-general-configuration',
    templateUrl: './general-configuration.component.html',
    styleUrls: ['./general-configuration.component.sass']
})
export class GeneralConfigurationComponent implements OnInit, AfterViewInit {
    generalConfigFormGenInputs: IFormGeneratorInput = null;
    generalConfigFormGroup: FormGroup;
    widgetRef: Widget;

    saveButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        loader: false,
        function: null
    };

    constructor(private widgetData: WidgetInjectedData) {
        this.widgetRef = this.widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.headerIcon.next({
            class: 'fas fa-cogs',
            type: IconType.FONTAWSOME
        });
    }
    bindData(data: IGeneralConfigResponse) {
        this.generalConfigFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'By Default Collapse/Expand Insights',
                    fieldType: FilterType.TOGGLE,
                    name: 'collapseInsightByDefault',
                    placeholder: '',
                    listData: [
                        { id: 'expand', label: 'Expand' },
                        { id: 'collapse', label: 'Collapse' }
                    ],
                    value: data
                        ? data.collapseInsightByDefault === true
                            ? 'collapse'
                            : 'expand'
                        : 'expand',
                    showLabel: true
                },
                {
                    label: 'By Default Collapse/Expand Description',
                    fieldType: FilterType.TOGGLE,
                    name: 'openDescriptionByDefault',
                    placeholder: '',
                    listData: [
                        { id: 'expand', label: 'Expand' },
                        { id: 'collapse', label: 'Collapse' }
                    ],
                    value: data
                        ? data.openDescriptionByDefault === true
                            ? 'collapse'
                            : 'expand'
                        : 'expand',
                    showLabel: true
                },
                {
                    label: 'Default Primary Recipient (To)',
                    fieldType: FilterType.MATCHIPLIST,
                    name: 'emails',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ],
                    value:
                        data && data.primaryEmail
                            ? data.primaryEmail.emails
                            : []
                },
                {
                    label: 'Default Secondary Recipient (CC)',
                    fieldType: FilterType.MATCHIPLIST,
                    name: 'copyEmails',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ],
                    value:
                        data && data.primaryEmail
                            ? data.primaryEmail.copyEmails
                            : []
                },
                {
                    label: 'Default Tertiary Recipient (BCC)',
                    fieldType: FilterType.MATCHIPLIST,
                    name: 'bcopyEmails',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ],
                    value:
                        data && data.primaryEmail
                            ? data.primaryEmail.bcopyEmails
                            : []
                },
                {
                    name: 'sideMenuType',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Side Menu Type',
                    placeholder: 'Select Side Menu Type',
                    value:
                        data && data.sideMenuType
                            ? data.sideMenuType
                            : SideMenuType.DETAILED,
                    required: true,
                    listData: [
                        {
                            id: SideMenuType.DETAILED,
                            label: 'Detailed'
                        },
                        {
                            id: SideMenuType.ICON,
                            label: 'Icon'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Side menu type is required'
                        }
                    ]
                }
            ]
        };
        this.widgetRef.endLoader();
    }
    save(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        if (this.generalConfigFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.generalConfigFormGroup);
            this.generalConfigFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const inputs = this.prepareInputs();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = inputs;
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Your changes will be reflected on next login.'
            );
            this.widgetRef.refreshWidget(true);
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInputs() {
        const inputs = {};
        const insight = this.generalConfigFormGroup.get(
            'collapseInsightByDefault'
        ).value;
        const description = this.generalConfigFormGroup.get(
            'openDescriptionByDefault'
        ).value;
        const sideMenuType =
            this.generalConfigFormGroup.get('sideMenuType').value;

        if (description) {
            if (description === 'expand') {
                inputs['openDescriptionByDefault'] = false;
            }
            if (description === 'collapse') {
                inputs['openDescriptionByDefault'] = true;
            }
        }
        if (insight) {
            if (insight === 'expand') {
                inputs['collapseInsightByDefault'] = false;
            }
            if (insight === 'collapse') {
                inputs['collapseInsightByDefault'] = true;
            }
        }
        const primaryEmails = {};
        (primaryEmails['emails'] = this.generalConfigFormGroup.get('emails')
            .value
            ? this.generalConfigFormGroup.get('emails').value
            : []),
            (primaryEmails['copyEmails'] = this.generalConfigFormGroup.get(
                'copyEmails'
            ).value
                ? this.generalConfigFormGroup.get('copyEmails').value
                : []);
        primaryEmails['bcopyEmails'] = this.generalConfigFormGroup.get(
            'bcopyEmails'
        ).value
            ? this.generalConfigFormGroup.get('bcopyEmails').value
            : [];
        inputs['primaryEmail'] = primaryEmails;
        inputs['sideMenuType'] = sideMenuType;
        return inputs;
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
