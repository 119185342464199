import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { IconName } from 'src/app/shared/classes/Icon';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {
    ModalType = ModalType;
    modalData: IModalData;
    loader: BehaviorSubject<boolean>;
    stepInjectors = new Map<Symbol, Injector>();

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-times'
    };
    resetIcon: IIcon = {
        type: IconType.SVG,
        class: 'reset'
    };

    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    iconName = IconName;

    constructor(
        // public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        public multiStepFormService: MultiStepFormService,
        private injector: Injector,
        private modalService: ModalService
    ) {
        this.modalData = data['modalData'];
        this.loader = data['loader'];
    }

    ngOnInit() {
        this.setUpDefaults();
        this.initInjectors();
    }

    setUpDefaults() {
        if (this.modalData.modalHeightVh <= 95) {
            this.modalData.maxModalHeightPx = 520;
        }
        this.multiStepFormService.currentStep.set(
            this.modalData.modalId,
            new BehaviorSubject(1)
        );
        this.multiStepFormService.currentStep
            .get(this.modalData.modalId)
            .next(1);
        this.multiStepFormService.totalSteps.set(
            this.modalData.modalId,
            this.modalData.modalSteps.length
        );
        let step = 1;
        while (
            step <=
            this.multiStepFormService.totalSteps.get(this.modalData.modalId)
        ) {
            if (
                !this.multiStepFormService.stepData.has(this.modalData.modalId)
            ) {
                this.multiStepFormService.stepData.set(
                    this.modalData.modalId,
                    new Map()
                );
            }

            // this.multiStepFormService.stepData
            //     .get(this.modalData.modalId)
            //     .set(step, new Map());
            step++;
        }
    }

    initInjectors() {
        this.modalData.modalSteps.forEach((modalStep) => {
            const stepData: ModalInjectedData = {
                data:
                    modalStep.stepData.payload &&
                    modalStep.stepData.payload['data']
                        ? modalStep.stepData.payload['data']
                        : null,
                modalId: this.modalData.modalId
            };
            this.stepInjectors.set(
                modalStep.stepIdentifier,
                Injector.create({
                    providers: [
                        {
                            provide: ModalInjectedData,
                            deps: [],
                            useValue: stepData
                        }
                    ],
                    parent: this.injector
                })
            );
        });
    }
    resetModal() {
        this.modalService.resetModal.next();
    }

    closeModal() {
        this.modalService.closeModal(null, this.modalData.modalId);
        if (this.modalData.closeCallback) {
            this.modalData.closeCallback();
        }
    }
}
