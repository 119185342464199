import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { IFilterInfo } from './../../../../../interfaces/filter/IFilterInfo';
import { IFormGeneratorInput } from './../../../../../interfaces/form-generator/IFormGeneratorInput';
import { ListHttpService } from './../../../../../services/http/list-http/list-http.service';
import { NotificationsService } from './../../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-my-price-configuration-modal',
    templateUrl: './my-price-configuration-modal.component.html',
    styleUrls: ['./my-price-configuration-modal.component.sass']
})
export class MyPriceConfigurationModalComponent implements OnInit {
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'Customer Name Account Details are not updated by its Payer Account, in order to update it please contact Payer Account.'
                    ]
                }
            ]
        }
    ];
    buttonInput: IButtonGeneratorInput;
    edit: boolean = false;
    accountDetails = [];
    filterInfo: IFilterInfo;
    chargeType: Map<string, []> = new Map<string, []>();
    awsAccountFormGenInputs: IFormGeneratorInput;
    loader: boolean = false;
    widgetRef: Widget;
    constructor(
        private modalData: ModalInjectedData,
        private notificationService: NotificationsService,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = modalData.data['widgetRef'];
        if (modalData && modalData.data && modalData.data['edit']) {
            this.edit = true;
            this.accountDetails = modalData.data['customerData'];
        }
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.filterInfo = {
            placeholder: 'Select Charge Type',
            label: 'Charge Type',
            lightMode: true,
            apiInfo: this.listHttpService.chargeType,
            getKey: 'listChargeType'
        };
        this.awsAccountFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Select Aws Account',
                    placeholder: 'Select Aws Account',
                    name: 'awsAccount',
                    fieldType: FilterType.DROPDOWN_GROUP_MULTIPLE,
                    apiInfo: this.listHttpService.awsAccount,
                    groupBy: 'group',
                    responseValueKey: 'listGroupAwsAccountIds',
                    value: [],
                    getKey: null
                }
            ]
        };
        this.buttonInput = {
            buttonName: this.edit ? 'Update' : 'Save',
            function: this.save.bind(this),
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY
        };
    }
    save() {
        if (this.loader) {
            return;
        }
        if (this.chargeType.size) {
            for (const value of this.chargeType.values()) {
                if (!value.length) {
                    this.notificationService.showSnackBar(
                        'Select charge type for every account',
                        true
                    );
                    return;
                }
            }
            this.loader = true;
            const apiArgs = this.edit
                ? Helper.generateHitApiConfig(
                      this.widgetRef.widgetData.widgetInfo.update
                  )
                : Helper.generateHitApiConfig(
                      this.widgetRef.widgetData.widgetInfo.create
                  );
            apiArgs.input = this.prepareInput();
            apiArgs.function = () => {
                this.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    this.edit
                        ? 'Configuration updated successfully'
                        : 'Configuration saved successfully.'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            };
            apiArgs.errorFunction = (error) => {
                this.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                this.widgetRef.refreshWidget();

                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else {
            this.notificationService.showSnackBar(
                'Select atleast one account.',
                true
            );
        }
    }
    prepareInput() {
        const inputs = [];
        this.accountDetails.forEach((data) => {
            const payerAccountDetails = {
                payerAccount: data.payerAccount
                    ? data.payerAccount
                    : data.group,
                awsAccount: data.awsAccount ? data.awsAccount : data.id,
                customerName: data.customerName,
                chargeTypeIncluded: this.chargeType.get(
                    data.awsAccount ? data.awsAccount : data.id
                )
            };
            inputs.push(payerAccountDetails);
        });
        return { payerAccountIds: inputs };
    }
    valueChanged(event) {
        this.accountDetails = [];
        if (event && event.awsAccount && event.awsAccount.length) {
            event.awsAccount.forEach((accountDetail) => {
                this.accountDetails.push(accountDetail);
            });
        }
    }
}
