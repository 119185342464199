import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconLibrary } from 'src/app/shared/classes/IconLibrary';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import {
    IconType,
    SupportedIconTypesForView,
    UINameOfIconType
} from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { SvgIconNameConverterPipe } from 'src/app/shared/pipes/svg-icon-name-converter.pipe';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IFormField } from './../../../../interfaces/form-generator/IFormField';

@Component({
    selector: 'app-edit-module-icon',
    templateUrl: './edit-module-icon.component.html',
    styleUrls: ['./edit-module-icon.component.sass']
})
export class EditModuleIconComponent implements OnInit {
    update: boolean = false;
    formGenInput: IFormGeneratorInput = null;
    heading: string = 'Enter Modules Details';
    formGroup: FormGroup = null;
    tree: Tree<IAttributeInfo> = null;
    node: Node<IAttributeInfo> = null;
    isAssessee: boolean = false;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.modalService.closeModal(null, this.modalInputData.modalId);
            }
        },
        {
            buttonName: 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.updateModuleIcon();
            }
        }
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between'
        }
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        if (this.modalInputData.data.node) {
            this.update = true;
        }
        if (this.modalInputData.data.isAssessee) {
            this.isAssessee = true;
        }
        this.tree = this.modalInputData.data.tree;
        this.node = this.modalInputData.data.node;
        if (this.node) {
            this.buttonGenInputs[1].buttonName = 'Update';
        }
        if (this.modalInputData.data.heading) {
            this.heading = this.modalInputData.data.heading;
        }
        const svgNamePipe = new SvgIconNameConverterPipe();
        this.formGenInput = {
            formName: 'Module Update',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    value: this.node ? this.node.data.attributeName : '',
                    label: 'Module Name',
                    name: 'moduleName',
                    placeholder: 'Enter module name',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Module name is required'
                        },
                        {
                            validator: CustomValidators.controlValueNotIn(
                                this.modalInputData.data.existingModules
                            ),
                            errorMessage: 'Module name exists on same level'
                        }
                    ]
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.node ? this.node.data.iconType : '',
                    label: 'Icon Source',
                    name: 'iconSource',
                    placeholder: 'Enter icon source',
                    required: true,
                    listData: SupportedIconTypesForView(this.isAssessee).map(
                        (icon: IconType) => {
                            return { id: icon, label: UINameOfIconType(icon) };
                        }
                    ),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Icon source is required'
                        }
                    ]
                },
                {
                    fieldType: FilterType.TEXT,
                    value: this.node ? this.node.data.icon : '',
                    label: 'Icon Name',
                    name: 'iconName',
                    placeholder: 'Enter icon name',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Icon name is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator: CustomValidators.controlValueIn([
                                        IconType.FONTAWSOME,
                                        IconType.MATICON
                                    ])
                                }
                            ]
                        }
                    ]
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.node ? this.node.data.icon : '',
                    label: 'Image',
                    name: 'image',
                    placeholder: 'Select image',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            IconType.IMAGE
                                        )
                                }
                            ]
                        }
                    ],
                    apiInfo: {
                        host: 'assets/image-list.json',
                        apiRouteSuffix: '',
                        authorization: AuthorizationType.NOT_AUTHORIZED,
                        requestType: RequestType.GET,
                        customInput: null
                    },
                    dropdownItemRenderImageFromId: true,
                    dropdownItemRenderImageType: IconType.IMAGE
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    value: this.node ? this.node.data.icon : '',
                    label: 'Image',
                    name: 'svgImage',
                    placeholder: 'Select image',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Image is required'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            IconType.SVG
                                        )
                                }
                            ]
                        }
                    ],
                    listData: Object.keys(IconLibrary.icon)
                        .filter((iconName) => !iconName.includes('_filled'))
                        .map((icon) => {
                            return {
                                id: icon,
                                label: svgNamePipe.transform(icon)
                            } as IDropdownData;
                        }),
                    dropdownItemRenderImageFromId: true,
                    dropdownItemRenderImageType: IconType.SVG
                },
                {
                    fieldType: FilterType.TEXT,
                    value: this.node ? this.node.data.icon : '',
                    label: 'Enter Number',
                    name: 'number',
                    placeholder: 'Enter number',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required'
                        },
                        {
                            validator: CustomValidators.numberValidator,
                            errorMessage: 'Please enter valid number.'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'iconSource',
                            validations: [
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            IconType.NUMBER
                                        )
                                }
                            ]
                        }
                    ]
                }
            ]
        };
        if (this.modalInputData.data.isAssessment) {
            this.createAssessmentField();
        }
    }

    createAssessmentField() {
        const assessmentFieldLabel = this.modalInputData.data.fieldLabel;
        const assessmentField: IFormField = {
            fieldType: FilterType.TEXT,
            value: this.node ? this.node.data.attributeName : '',
            label: `${assessmentFieldLabel ?? 'Section'} Name`,
            name: 'moduleName',
            placeholder: `Enter ${
                assessmentFieldLabel.toLowerCase() ?? 'section'
            } name`,
            validations: [
                {
                    validator: CustomValidators.required,
                    errorMessage: `${
                        assessmentFieldLabel ?? 'Section'
                    } name is required`
                },
                {
                    validator: CustomValidators.controlValueNotIn(
                        this.modalInputData.data.existingModules
                    ),
                    errorMessage: `${
                        assessmentFieldLabel ?? 'Section'
                    } name exists on same level`
                },
                {
                    validator: CustomValidators.maxLength(100),
                    errorMessage: 'Maximum character allowed is 100'
                }
            ]
        };
        this.formGenInput.fields[0] = assessmentField;
    }

    updateModuleIcon() {
        if (this.formGroup.invalid) {
            Helper.markAllFieldAsTouched(this.formGroup);
            return;
        }

        const formValue = this.formGroup.getRawValue();
        const moduleData: IAttributeInfo = {
            attributeName: formValue['moduleName'],
            iconType: formValue['iconSource'],
            icon:
                formValue['iconSource'] === IconType.FONTAWSOME ||
                formValue['iconSource'] === IconType.MATICON
                    ? formValue['iconName']
                    : formValue['iconSource'] === IconType.NUMBER
                    ? formValue['number']
                    : formValue['iconSource'] === IconType.IMAGE
                    ? formValue['image']
                    : formValue['svgImage']
        };
        if (
            this.node &&
            this.node.data &&
            this.node.data['widgets'] &&
            this.node.data['widgets'].length
        ) {
            moduleData['widgets'] = this.node.data['widgets'];
        }
        if (this.node && this.node.data && this.node.data['framework']) {
            moduleData['framework'] = this.node.data['framework'];
        }
        if (this.update && this.modalInputData.data['updateModuleFunction']) {
            this.node.data = moduleData;
            this.modalInputData.data['updateModuleFunction'](this.node);
        } else if (
            !this.update &&
            this.modalInputData.data['addModuleFunction']
        ) {
            this.modalInputData.data['addModuleFunction'](
                moduleData,
                this.heading
            );
        }
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }
}
