import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnDestroy,
    OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Helper } from '../../classes/Helper';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { FilterType } from '../../enums/FilterType';
import { IconType } from '../../enums/IconType';
import { IFilterData } from '../../interfaces/filter/IFilterData';
import { IFilterInfo } from '../../interfaces/filter/IFilterInfo';
import { IFilterSelection } from '../../interfaces/filter/IFilterSelection';
import { IMiscFilter } from '../../interfaces/filter/IMiscFilter';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { FiltersService } from '../../services/filters/filters.service';
import { FiltersHttpService } from '../../services/http/filters-http/filters-http.service';
import { MulticurrencyFilterSelectorService } from '../../services/multicurrency-filter-selector/multicurrency-filter-selector.service';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-field-generator',
    templateUrl: './field-generator.component.html',
    styleUrls: ['./field-generator.component.sass']
})
export class FieldGeneratorComponent implements OnInit, OnDestroy {
    subSink: SubSink = new SubSink();
    @Input() filterStoreKey: FilterStoreKey;
    @Input() filterData: IFilterData;
    @Input() customClass: string;
    @Input() disabled;
    @Input() disableMessage;
    @Input() enableTemp: boolean;
    @Input() lightMode: boolean;
    @Input() customSelectedValue;
    @Input() hideLogicalOperatorRadioButtons: boolean;
    refreshListing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    selectedValue;
    selectedOperator: string = 'OR';
    filterInfo: IFilterInfo;
    FilterType = FilterType;
    isDropdownChecked = false;

    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };
    readonly CUSTOM_FILTERS = [
        FilterType.CURRENCY,
        FilterType.ACL_VIEW_LAYER,
        FilterType.SINGLE_DROPDOWN_PERCENT
    ];

    FilterStoreKey = FilterStoreKey;

    currencyFilters = [];

    addCurrencyFieldInput: IButtonGeneratorInput = {
		buttonName: '+ Add Currency',
		buttonColorType: ButtonColorType.INFO,
		buttonType: ButtonType.TEXT,
		hoverText: 'Click to add currency',
		function: () => {},
	};

	removeCurrencyFieldInput: IButtonGeneratorInput = {
		buttonName: 'Remove Currency',
		buttonColorType: ButtonColorType.WARN,
		buttonType: ButtonType.TEXT,
		hoverText: 'Click to remove currency',
		function: (index) => {
            this.removeCurrencyField(index);
		},
	};
    isCurrencyArray: boolean = false;
    duplicateFieldIndex = new BehaviorSubject(null);
    showMultiCurrencyField: boolean = false;

    constructor(
        private filtersHttpService: FiltersHttpService,
        private filtersService: FiltersService,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private multiCurrencyFilterService: MulticurrencyFilterSelectorService,
    ) {}

    ngOnInit(): void {
        this.duplicateFieldIndex.next(null);
        if (this.filterStoreKey && this.filterData) {
            this.getFilterInfo();
            this.subSink.add(
                this.filtersService.resetFilters.subscribe((res) => {
                    if (!res) {
                        this.selectedOperator = 'OR';
                        this.isDropdownChecked = false;
                        this.handleBasics();
                        this.refreshFilter();
                    }
                })
            );
            this.subSink.add(
                this.filtersService.updateFilter.subscribe((res) => {
                    if (this.filterInfo && res.has(this.filterStoreKey)) {
                        if (
                            res
                                .get(this.filterStoreKey)
                                .has(this.filterInfo.selector)
                        ) {
                            this.updateFilterValue(
                                res
                                    .get(this.filterStoreKey)
                                    .get(this.filterInfo.selector)
                            );
                        } else if (
                            this.filterData &&
                            res
                                .get(this.filterStoreKey)
                                .has(this.filterData.filterId)
                        ) {
                            this.updateFilterValue(
                                res
                                    .get(this.filterStoreKey)
                                    .get(this.filterData.filterId)['value']
                            );
                        }
                        if (
                            this.filtersService.filtersData
                                .get(this.filterStoreKey)
                                .has(this.filterData.filterId) &&
                            this.filtersService.filtersData
                                .get(this.filterStoreKey)
                                .get(this.filterData.filterId).filterInfo
                                .showCheckbox
                        ) {
                            this.isDropdownChecked =
                                !this.filtersService.filtersData
                                    .get(this.filterStoreKey)
                                    .get(this.filterInfo.checkboxSelector)
                                    ? false
                                    : this.filtersService.filtersData
                                          .get(this.filterStoreKey)
                                          .get(this.filterInfo.checkboxSelector)
                                          .value.includes(
                                              this.filterInfo.checkboxValue
                                          );
                            this.checkboxWithDropdownToggled(
                                this.isDropdownChecked,
                                true
                            );
                        }
                    }
                })
            );
        }
    }

    getFilterInfo() {
        const filterObs = this.filtersHttpService.getFilterInfo(
            this.filterData.filterId
        );
        if (filterObs.value) {
            this.filterInfoParser(filterObs.value);
        } else {
            this.subSink.add(
                filterObs
                    .pipe(skip(1), take(1))
                    .subscribe((response) => this.filterInfoParser(response))
            );
        }
    }

    filterInfoParser(filterInfo: IFilterInfo) {
        this.filterInfo = filterInfo;
        /**
         * Configures settings of the multiCurrencyFilterService based on the filter type.
         * If the filter type is CURRENCY, this function sets up multi-currency field related settings.
        */
        if (this.filterInfo.type === FilterType.CURRENCY) {
            this.showMultiCurrencyField = this.multiCurrencyFilterService.showMultiCurrencyField;
            this.multiCurrencyFilterService.addCurrencyButtonGenInput = this.addCurrencyFieldInput;
            this.multiCurrencyFilterService.duplicateFieldIndex.subscribe(data => {
                this.duplicateFieldIndex.next(data);
            })
        }
        if (this.filterInfo.selector === 'priceType') {
            this.filterInfo.filterId = this.filterData.filterId;
        }
        if (filterInfo) {
            this.handleBasics();
            this.cd.detectChanges();
        }
    }

    getlogicalOperator(event) {
        const logicalOperatorvalue = event;

        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterInfo.logicalOperatorRequestKey, {
                    value: logicalOperatorvalue,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: logicalOperatorvalue,
                    filterInfo: this.filterInfo
                });
        }

        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    handleBasics() {
        const multiCurrencyFilterId = this.multiCurrencyFilterService.multiCurrencyFilterId;
        this.filterInfo.lightMode = this.lightMode;
        if (this.filterInfo.showCheckbox) {
            if (
                this.filtersService.miscFilterList.has(
                    this.filterInfo.checkboxSelector
                )
            ) {
                this.filtersService.miscFilterList
                    .get(this.filterInfo.checkboxSelector)
                    .associatedFilterIds.push(this.filterData.filterId);
            } else {
                const data: IMiscFilter = {
                    selector: this.filterInfo.checkboxSelector,
                    associatedFilterIds: [this.filterData.filterId],
                    selectorType: FilterType.CHECKBOX
                };
                this.filtersService.miscFilterList.set(
                    this.filterInfo.checkboxSelector,
                    data
                );
            }
        }
        if (this.filterInfo.logicalOperatorEnable) {
            if (
                this.filtersService.miscFilterList.has(
                    this.filterInfo.logicalOperatorRequestKey
                )
            ) {
                this.filtersService.miscFilterList
                    .get(this.filterInfo.logicalOperatorRequestKey)
                    .associatedFilterIds.push(this.filterData.filterId);
            } else {
                const data: IMiscFilter = {
                    selector: this.filterInfo.logicalOperatorRequestKey,
                    associatedFilterIds: [this.filterData.filterId],
                    selectorType: FilterType.RADIO
                };
                this.filtersService.miscFilterList.set(
                    this.filterInfo.logicalOperatorRequestKey,
                    data
                );
            }
        }
        if (!this.filtersService.filtersData.has(this.filterStoreKey)) {
            this.filtersService.filtersData.set(
                this.filterStoreKey,
                new Map<string, IFilterSelection>()
            );
        }
        if (this.enableTemp) {
            if (!this.filtersService.filtersDataTemp.has(this.filterStoreKey)) {
                this.filtersService.filtersDataTemp.set(
                    this.filterStoreKey,
                    new Map<string, IFilterSelection>()
                );
            }
        }
        if (
            this.filterInfo.selector &&
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .has(this.filterInfo.selector)
        ) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterInfo.selector),
                    filterInfo: this.filterInfo
                });
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterInfo.selector),
                    filterInfo: this.filterInfo
                });

            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .delete(this.filterInfo.selector);
        }
        // handling default values
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .has(this.filterData.filterId)
        ) {
            if (
                'value' in
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterData.filterId) &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterData.filterId).value !== null &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterData.filterId).value !== undefined &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterData.filterId).value + ''
            ) {
                this.selectedValue = JSON.parse(
                    JSON.stringify(
                        this.filtersService.filtersData
                            .get(this.filterStoreKey)
                            .get(this.filterData.filterId).value
                    )
                );

                /**
                 * Checks and processes selected currency values from the filters data based on specific conditions.
                 * If the multiCurrencyFilterId or 'listCurrencies' filter is present in the filters data and the filter type is CURRENCY,
                 * it extracts and updates the currencyFilters state of the component.
                 * 
                 * Conditions:
                 * - If the multiCurrencyFilterId or 'listCurrencies' filter exists in the filters data.
                 * - If the filter type is CURRENCY.
                 * - If the filter store key is REPORT_FILTERS.
                 * 
                 * If all conditions are met, it processes the selected currency values and updates the component's currencyFilters state.
                */
                if (this.filtersService.filtersData
                    .get(this.filterStoreKey).has(multiCurrencyFilterId) || this.filtersService.filtersData
                        .get(this.filterStoreKey).has('listCurrencies') && this.filterInfo.type === FilterType.CURRENCY) {
                    const currencySelectedValue = JSON.parse(
                        JSON.stringify(
                            this.filtersService.filtersData.get(this.filterStoreKey).has(multiCurrencyFilterId)
                                ? this.filtersService.filtersData?.get(this.filterStoreKey)?.get(multiCurrencyFilterId)?.value
                                : this.filtersService.filtersData?.get(this.filterStoreKey)?.get('listCurrencies')
                        )
                    );

                    if (currencySelectedValue && currencySelectedValue.length > 0
                        && this.filterInfo.type === FilterType.CURRENCY
                        && this.filterStoreKey === FilterStoreKey.REPORT_FILTERS) {
                        this.currencyFilters = [];
                        this.isCurrencyArray = true;
                        this.currencyFilters.push(...currencySelectedValue);
                    }
                }
                if (this.filterInfo.type === FilterType.CUSTOMER_USER) {
                    this.customerUserSelectedValueChange(this.selectedValue);
                }
                if (this.filterInfo.type === FilterType.ACL_VIEW_LAYER) {
                    this.aclViewLayerSelectedValueChange(this.selectedValue);
                }

                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(
                        this.filterData.filterId,
                        JSON.parse(
                            JSON.stringify(
                                this.filtersService.filtersData
                                    .get(this.filterStoreKey)
                                    .get(this.filterData.filterId)
                            )
                        )
                    );
            } else if (
                'value' in
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterData.filterId) &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterData.filterId).value === null &&
                this.filterInfo.type === FilterType.DROPDOWN_SINGLE
            ) {
                this.selectedValue = null;
            }
        } else if (
            this.filterInfo.selectedValue ||
            this.filterInfo.selectedValue === false ||
            this.customSelectedValue
        ) {
            this.selectedValue = this.customSelectedValue
                ? this.customSelectedValue.hasOwnProperty(
                      this.filterInfo.selector
                  )
                    ? this.customSelectedValue[this.filterInfo.selector]
                    : Helper.cloneDeep(this.filterInfo.selectedValue)
                : Helper.cloneDeep(this.filterInfo.selectedValue);
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.customSelectedValue
                        ? this.customSelectedValue.hasOwnProperty(
                              this.filterInfo.selector
                          )
                            ? this.customSelectedValue[this.filterInfo.selector]
                            : Helper.cloneDeep(this.filterInfo.selectedValue)
                        : Helper.cloneDeep(this.filterInfo.selectedValue),
                    filterInfo: this.filterInfo
                });

            if (this.filterInfo.type === FilterType.CUSTOMER_USER) {
                this.customerUserSelectedValueChange(this.selectedValue);
            }
            if (this.filterInfo.type === FilterType.ACL_VIEW_LAYER) {
                this.aclViewLayerSelectedValueChange(this.selectedValue);
            }

            if (this.enableTemp) {
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value: this.customSelectedValue
                            ? this.customSelectedValue.hasOwnProperty(
                                  this.filterInfo.selector
                              )
                                ? this.customSelectedValue[
                                      this.filterInfo.selector
                                  ]
                                : Helper.cloneDeep(
                                      this.filterInfo.selectedValue
                                  )
                            : Helper.cloneDeep(this.filterInfo.selectedValue),
                        filterInfo: this.filterInfo
                    });
            }
            this.filtersService.filtersDefaultValue.set(
                this.filterData.filterId,
                {
                    value: this.customSelectedValue
                        ? this.customSelectedValue.hasOwnProperty(
                              this.filterInfo.selector
                          )
                            ? this.customSelectedValue[this.filterInfo.selector]
                            : Helper.cloneDeep(this.filterInfo.selectedValue)
                        : Helper.cloneDeep(this.filterInfo.selectedValue),
                    filterInfo: this.filterInfo
                }
            );
            if (this.filterInfo.type === FilterType.CUSTOMER_USER) {
                this.customerUserSelectedValueChange(this.selectedValue);
                this.filtersService.filtersDefaultValue.set(
                    this.filterData.filterId,
                    {
                        value: {
                            ...this.selectedValue,
                            users: []
                        },
                        filterInfo: this.filterInfo
                    }
                );
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value: {
                            ...this.selectedValue,
                            users: []
                        },
                        filterInfo: this.filterInfo
                    });
            }
            if (this.filterInfo.type === FilterType.ACL_VIEW_LAYER) {
                this.aclViewLayerSelectedValueChange(this.selectedValue);
                this.filtersService.filtersDefaultValue.set(
                    this.filterData.filterId,
                    {
                        value: {
                            ...this.selectedValue
                        },
                        filterInfo: this.filterInfo
                    }
                );
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value: {
                            ...this.selectedValue
                        },
                        filterInfo: this.filterInfo
                    });
            }
        } else {
            this.selectedValue = Helper.getFilterDefaultValue(
                this.filterInfo.type,
                this.filterInfo.getFullObj
            );
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: Helper.cloneDeep(this.selectedValue),
                    filterInfo: this.filterInfo
                });
            if (this.enableTemp) {
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value: Helper.cloneDeep(this.selectedValue),
                        filterInfo: this.filterInfo
                    });
            }
            this.filtersService.filtersDefaultValue.set(
                this.filterData.filterId,
                {
                    value: Helper.cloneDeep(this.selectedValue),
                    filterInfo: this.filterInfo
                }
            );
        }

        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .has(this.filterInfo.checkboxSelector)
        ) {
            if (
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterInfo.checkboxSelector).value &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterInfo.checkboxSelector)
                    .value.includes(this.filterInfo.checkboxValue)
            ) {
                this.isDropdownChecked = true;
                this.checkboxWithDropdownToggled(this.isDropdownChecked, true);
            } else if (
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .has(this.filterInfo.checkboxSelector) &&
                typeof this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterInfo.checkboxSelector) === 'object' &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterInfo.checkboxSelector) &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(this.filterInfo.checkboxSelector) &&
                Array.isArray(
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterInfo.checkboxSelector)
                ) &&
                Helper.cloneDeep(
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterInfo.checkboxSelector)
                ).includes(this.filterInfo.checkboxValue)
            ) {
                // This was added to fix checkBox not selected in edit of reports
                this.isDropdownChecked = true;
                this.checkboxWithDropdownToggled(this.isDropdownChecked, true);
            }
        }

        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .has(this.filterInfo.logicalOperatorRequestKey)
        ) {
            this.selectedOperator = !this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterInfo.logicalOperatorRequestKey)
                ? this.selectedOperator
                : this.filtersService.filtersData
                      .get(this.filterStoreKey)
                      .get(this.filterInfo.logicalOperatorRequestKey).value ??
                  this.filtersService.filtersData
                      .get(this.filterStoreKey)
                      .get(this.filterInfo.logicalOperatorRequestKey);
        }

        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).filterInfo.type ===
            FilterType.TEXT_WITH_DROPDOWN
        ) {
            this.selectedValue = this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value;
        }
    }

    textWithDropdown(event, text?: string) {
        const frequency =
            event && text
                ? event
                : this.selectedValue && this.selectedValue.frequency
                ? this.selectedValue.frequency
                : this.filterInfo.value.textValue;
        const dateRange =
            event && !text
                ? event
                : this.selectedValue && this.selectedValue.dateRange
                ? this.selectedValue.dateRange
                : this.filterInfo.value.dropdownValue;

        const value = {
            frequency: frequency,
            dateRange: dateRange
        };
        this.selectedValue = value;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: value,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: value,
                    filterInfo: this.filterInfo
                });
        }

        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    /**
     * This function will change selectedValue of customer-user-listing component and if
     * argument is of type string then it will be convert into object
     * @param data This variable will get either string or object as its value
     */
    customerUserSelectedValueChange(data) {
        if (typeof data === 'string') {
            this.selectedValue = {
                customer: this.selectedValue,
                users: []
            };
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.selectedValue,
                    filterInfo: this.filterInfo
                });
        } else {
            this.selectedValue = data;
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.selectedValue,
                    filterInfo: this.filterInfo
                });
            this.cd.detectChanges();
        }
    }

    /**
     * This function will change selectedValue of acl-view-layer component and if
     * argument is of type string then it will be convert into object
     * @param data This variable will get either string or object as its value
     */
    aclViewLayerSelectedValueChange(data) {
        if (typeof data === 'string') {
            this.selectedValue = {
                acl: this.selectedValue,
                view: null,
                layer1: null,
                layer2: null,
                layer3: null,
                layer4: null
            };
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.selectedValue,
                    filterInfo: this.filterInfo
                });
        } else {
            this.selectedValue = data;
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: this.selectedValue,
                    filterInfo: this.filterInfo
                });
            this.cd.detectChanges();
        }
    }

    // Handle Response Of Input Field
    inputResponseHandle(value: string): void {
        // Store response
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    singleDropdownValueChange($event) {
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    multiDropdownValueChange($event) {
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: JSON.parse(JSON.stringify($event)),
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: JSON.parse(JSON.stringify($event)),
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    groupMultiDropdownValueChange($event) {
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    toggleValueChange($event) {
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    numberRangeChanged($event) {
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    numberRangeDoubleChanged($event) {
        this.selectedValue.lowValue = $event.value;
        this.selectedValue.highValue = $event.highValue;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        lowValue: $event.value,
                        highValue: $event.highValue
                    },
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        lowValue: $event.value,
                        highValue: $event.highValue
                    },
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    checkboxWithDropdownToggled($event, avoidFilterChange?: Boolean) {
        let values = [];

        if (
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .get(this.filterInfo.checkboxSelector)
        ) {
            values = this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .get(this.filterInfo.checkboxSelector).value;
        }

        if ($event) {
            if (!values.includes(this.filterInfo.checkboxValue)) {
                values.push(this.filterInfo.checkboxValue);
            }
        } else {
            if (values.includes(this.filterInfo.checkboxValue)) {
                values.splice(values.indexOf(this.filterInfo.checkboxValue), 1);
            }
        }

        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterInfo.checkboxSelector, {
                    value: values,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: values,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !avoidFilterChange &&
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    dateChanged($event) {
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        startDateTime: $event.startDateTime,
                        endDateTime: $event.endDateTime
                    },
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        startDateTime: $event.startDateValue,
                        endDateTime: $event.endDateValue
                    },
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    dateTimeChanged($event) {
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        startDate: $event.startDate,
                        endDate: $event.endDate,
                        startTime: $event.startTime,
                        endTime: $event.endTime
                    },
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        startDate: $event.startDate,
                        endDate: $event.endDate,
                        startTime: $event.startTime,
                        endTime: $event.endTime
                    },
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    customerUsersListingChanged($event) {
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        customer: $event.customer,
                        users: $event.users
                    },
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        customer: $event.customer,
                        users: $event.users
                    },
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    aclViewLayerListingChanged($event) {
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        acl: $event.acl,
                        view: $event.view,
                        layer1: $event.layer1,
                        layer2: $event.layer2,
                        layer3: $event.layer3,
                        layer4: $event.layer4
                    },
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: {
                        acl: $event.acl,
                        view: $event.view,
                        layer1: $event.layer1,
                        layer2: $event.layer2,
                        layer3: $event.layer3,
                        layer4: $event.layer4
                    },
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    refreshFilter() {
        this.refreshListing.next(true);
    }

    numberResponseHandle($event) {
        if (this.filterInfo.rangeOptions) {
            if ($event < this.filterInfo.rangeOptions.floor) {
                $event = this.filterInfo.rangeOptions.floor;
            } else if ($event > this.filterInfo.rangeOptions.ceil) {
                $event = this.filterInfo.rangeOptions.ceil;
            }
        } else {
            if ($event === 0 || $event === '' || $event === undefined) {
                $event = 1;
            }
        }
        this.selectedValue = $event;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: $event,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    radioResponseHandle($event) {
        const radioObject = this.filterInfo.listData.find(
            (obj) => obj.label === $event
        );

        this.selectedValue = radioObject;
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: radioObject,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value: radioObject,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    jsonResponseHandle(value) {
        if (this.enableTemp) {
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        } else {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .set(this.filterData.filterId, {
                    value,
                    filterInfo: this.filterInfo
                });
        }
        if (
            !this.filtersService.filterChanged.includes(
                this.filterData.filterId
            )
        ) {
            this.filtersService.filterChanged.push(this.filterData.filterId);
        }
    }

    updateFilterValue(res) {
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value instanceof Array &&
            res instanceof Array
        ) {
            res.forEach((element) => {
                if (
                    !this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterData.filterId)
                        .value.includes(element)
                ) {
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(this.filterData.filterId)
                        .value.push(element);
                    // this.filtersService.filtersDataTemp
                    //     .get(this.filterStoreKey)
                    //     .get(this.filterData.filterId)
                    //     .value.push(element);
                }
            });
        } else if (
            typeof this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value === 'string' ||
            typeof this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value === 'number' ||
            typeof this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value === 'boolean' ||
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value instanceof Object
        ) {
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value = res;
            this.filtersService.filtersDataTemp
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value = res;
        }
        this.selectedValue = Helper.cloneDeep(
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .get(this.filterData.filterId).value
        );
    }

    /**
     * Adds a new empty currency field to the component's currencyFilters.
     * Checks for duplicate values after adding the new field.
     */
    addCurrencyField() {
        this.currencyFilters.push(this.selectedValue);
        this.multiCurrencyFilterService.checkDuplicateFilter();
    }

    /**
     * Removes a currency filter at the specified index.
     * Checks for duplicate values after removal and updates related data structures.
     *
     * @param index - The index of the currency filter to be removed.
     *                If the selectedValue is an array, it also removes the corresponding item.
     */
    private removeCurrencyFilter(index: number): void {
        const listCurrencyFilterInfo = Helper.cloneDeep(this.filterInfo);
        listCurrencyFilterInfo.selector = 'listCurrencies';
        // Remove the currency filter at the specified index from currencyFilters array
        const multiCurrencyFilterId = this.multiCurrencyFilterService.multiCurrencyFilterId;
        this.currencyFilters.splice((index), 1);

        // Retrieve stored values from multiCurrencyFilterService
        const storedValues = this.multiCurrencyFilterService.getFilterValues(
            multiCurrencyFilterId,
        );

        // Remove the stored value at the specified index
        storedValues.splice(index, 1);

        // Set the updated values back to multiCurrencyFilterService
        this.multiCurrencyFilterService.setFilterValues(
            multiCurrencyFilterId,
            storedValues,
        );

        // Check for duplicate values after removal
        this.multiCurrencyFilterService.checkDuplicateFilter();

        // Update filterDataTemp based on storedValues length
        const filterDataTemp = this.filtersService.filtersDataTemp;
        filterDataTemp.get(this.filterStoreKey).set(multiCurrencyFilterId, {
            value: storedValues,
            filterInfo: listCurrencyFilterInfo,
        });
    }

    /**
     * Public method to remove a currency field at the specified index.
     * Calls the private removeCurrencyFilter method to perform the removal.
     *
     * @param index - The index of the currency field to be removed.
     */
	removeCurrencyField(index) {
		this.removeCurrencyFilter(index);
    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }
}
