/* eslint-disable no-console */

export class Logger {
    private static readonly SESSION_STORAGE_LOGS_KEY = 'showLogs';

    static get showLogs(): boolean {
        return (
            sessionStorage.getItem(Logger.SESSION_STORAGE_LOGS_KEY) &&
            sessionStorage.getItem(Logger.SESSION_STORAGE_LOGS_KEY) === 'true'
        );
    }

    static set showLogs(value: boolean) {
        sessionStorage.setItem(
            Logger.SESSION_STORAGE_LOGS_KEY,
            JSON.stringify(value)
        );
    }

    public static codeLog(message: any) {
        Logger.log(JSON.stringify(message), '#228822');
    }

    public static info(message: any) {
        Logger.log(JSON.stringify(message), '#000000');
    }

    public static warn(message: any) {
        Logger.log(JSON.stringify(message), '#ff0000');
    }

    private static log(message: any, color: string) {
        if (Logger.showLogs) {
            console.log(
                '%c ' + message,
                `color: ${color}; font-weight: 600; font-size: 12px;`
            );
        }
    }
}
