export enum FilterStoreKey {
    WEBSITE_FILTERS = 'WEBSITE_FILTERS',
    REPORT_FILTERS = 'REPORT_FILTERS',
    MANAGED_REPORT_FILTERS = 'MANAGED_REPORT_FILTERS',
    BUDGETING_FILTERS = 'BUDGETING_FILTERS',
    ALERT_FILTERS = 'ALERT_FILTERS',
    FIRST_VIEW_FILTERS = 'FIRST_VIEW_FILTERS',
    SECOND_VIEW_FILTERS = 'SECOND_VIEW_FILTERS',
    AUTOMATION_FILTERS = 'AUTOMATION_FILTERS',
    CONFIGURATION = 'CONFIGURATION'
}
