<div class="tags-container tw-h-full tw-w-full tw-py-2 tw-px-4">
    <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        (selectedTabChange)="changeTab($event.tab.textLabel)"
        mat-align-tabs="center"
    >
        <mat-tab [label]="Tab.EXCEL">
            <div class="tab-content tw-overflow-x-hidden">
                <div class="info-container">
                    <app-modal-info-template
                        [infoData]="infoData"
                    ></app-modal-info-template>
                </div>

                <div
                    class="file-upload-form-section tw-flex tw-items-center tw-flex-col tw-justify-between"
                >
                    <div
                        class="form-row tw-my-3 tw-mx-0 tw-w-full tw-flex tw-items-center tw-flex-wrap tw-justify-between"
                    >
                        <div class="form-col padded-bottom">
                            <h2 class="tw-font-semibold">
                                Download Excel Template
                            </h2>
                        </div>
                        <div class="form-col padded-sides">
                            <div
                                class="form-row padded-bottom tw-w-full tw-flex tw-items-center tw-flex-wrap tw-justify-between"
                            >
                                <div
                                    class="form-col-left-align tw-flex-1 tw-text-left"
                                >
                                    <h3 class="tw-mb-0">Template.xlsx</h3>
                                </div>
                                <div
                                    class="form-col-right-align tw-flex-1 tw-text-right"
                                >
                                    <app-button-generator
                                        [buttonGenInput]="excelTabButtons[1]"
                                    >
                                    </app-button-generator>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-row tw-my-3 tw-mx-0 tw-w-full tw-flex tw-items-center tw-flex-wrap tw-justify-between"
                    >
                        <div class="form-col padded-bottom">
                            <h2 class="tw-font-semibold">Upload Excel File</h2>
                        </div>
                        <app-form-generator
                            class="form-col"
                            *ngIf="excelForm"
                            [formGeneratorInput]="excelForm"
                            (formGroupRef)="excelFormGroup = $event"
                        ></app-form-generator>
                    </div>
                </div>
            </div>
            <div class="bottom-buttons tw-absolute tw-right-3 tw-bottom-0">
                <app-button-generator [buttonGenInput]="excelTabButtons[0]">
                </app-button-generator>
            </div>
        </mat-tab>

        <mat-tab [label]="Tab.ADD_NEW">
            <div
                class="tab-content add-new tw-overflow-x-hidden"
                [style.width.%]="isUpdate ? 100 : 90"
            >
                <ng-container *ngIf="isUpdate">
                    <mat-accordion>
                        <mat-expansion-panel
                            [expanded]="activeInstance === i"
                            *ngFor="
                                let data of resourceTaggingList;
                                index as i;
                                first as isFirst
                            "
                        >
                            <mat-expansion-panel-header
                                collapsedHeight="64px"
                                (click)="prepareKeyValueDataToPatch(i)"
                            >
                                <mat-panel-title>
                                    <div class="name">
                                        <span>
                                            {{ data["identifier"] }}
                                        </span>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <app-key-value-pair-v2
                                *ngIf="activeInstance === i"
                                [data]="data.tagsToApply"
                                [validation]="
                                    widgetRef.widgetData.widgetInfo.cloudIcon
                                "
                                [matChipInputLimit]="1"
                                (change)="keyValuePairOutput($event)"
                                [keysAlreadyExists]="keysArray"
                                [originalKeysData]="
                                    storeAllKeysAlreadyExists.get(i)
                                "
                                [applyCaseInsensitiveCase]="
                                    applyCaseInsensitive
                                "
                                [handleCaseInsensitiveAsWarning]="
                                    widgetRef.widgetData.widgetInfo.cloudIcon &&
                                    widgetRef.widgetData.widgetInfo.cloudIcon.includes(
                                        'Azure'
                                    )
                                        ? !isUpdate
                                        : false
                                "
                                [warningMessage]="
                                    singleRowSelected
                                        ? Messages.CASE_INSENSITIVE_WARN
                                        : Messages.CASE_INSENSITIVE_WARN_BULK
                                "
                            ></app-key-value-pair-v2>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-container *ngIf="!isUpdate">
                    <div class="each-key-value-container">
                        <app-key-value-pair-v2
                            [data]="keyValuePair"
                            [validation]="
                                widgetRef.widgetData.widgetInfo.cloudIcon
                            "
                            [matChipInputLimit]="1"
                            (change)="keyValuePairOutput($event)"
                            [resetKeyValue]="resetKeyValueSubject"
                            [keysAlreadyExists]="keysArray"
                            [applyCaseInsensitiveCase]="applyCaseInsensitive"
                            [handleCaseInsensitiveAsWarning]="
                                widgetRef.widgetData.widgetInfo.cloudIcon &&
                                widgetRef.widgetData.widgetInfo.cloudIcon.includes(
                                    'Azure'
                                )
                                    ? !isUpdate
                                    : false
                            "
                            [warningMessage]="
                                singleRowSelected
                                    ? Messages.CASE_INSENSITIVE_WARN
                                    : Messages.CASE_INSENSITIVE_WARN_BULK
                            "
                        ></app-key-value-pair-v2>
                    </div>
                </ng-container>
            </div>
            <div class="bottom-buttons tw-absolute tw-right-3 tw-bottom-0">
                <app-multi-button-generator
                    [buttonGenInputs]="dashBoardtabButtons"
                >
                </app-multi-button-generator>
            </div>
        </mat-tab>

        <mat-tab [label]="Tab.CONVENTION" *ngIf="!isUpdate">
            <ng-container *ngIf="conventionsFormGroupInput; else loader">
                <div class="tab-content tw-overflow-x-hidden">
                    <div class="each-key-value-container">
                        <app-form-generator
                            *ngIf="conventionsFormGroupInput"
                            [formGeneratorInput]="conventionsFormGroupInput"
                            (valuesChange)="conventionValueChange($event)"
                            (updateControl)="updateControl = $event"
                            (formGroupRef)="conventionFormGroup = $event"
                        >
                        </app-form-generator>
                    </div>
                </div>
                <div class="bottom-buttons tw-absolute tw-right-3 tw-bottom-0">
                    <app-multi-button-generator
                        [buttonGenInputs]="conventionTabButtons"
                    >
                    </app-multi-button-generator>
                </div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #loader>
    <div class="step-loader">
        <mat-spinner [diameter]="25" class="mat-spinner-color"></mat-spinner>
    </div>
</ng-template>
