<div class="container">
    <app-only-table-generator
        [tableInput]="tableGenInput"
        [apiInfo]="
            tableData
                ? null
                : widgetRef.widgetData.widgetInfo['customCompliance'][
                      'listWidget'
                  ]
        "
        [tableData]="tableData"
        [overviewTemplateStart]="overviewTemplateStart"
        (gridRef)="gridRef = $event; onGridReady()"
        (selectionChange)="handleSelectionChange()"
    >
    </app-only-table-generator>

    <ng-template #overviewTemplateStart>
        <app-multi-select-dropdown
            [filterInfo]="filterInfo"
            (change)="handleFilterChange($event)"
        >
        </app-multi-select-dropdown>
    </ng-template>

    <div class="actions">
        <app-multi-button-generator
            *ngIf="multipleSelection"
            [buttonGenInputs]="buttonGenInputs"
        >
        </app-multi-button-generator>
    </div>
</div>
