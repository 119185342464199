import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-whitelabeling-proprietary',
    templateUrl: './whitelabeling-proprietary.component.html',
    styleUrls: ['./whitelabeling-proprietary.component.sass']
})
export class WhitelabelingProprietaryComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    parentWidgetRef: Widget;
    saveAsDraftApiInfo: IApiInfo;

    isDomainRegistered: boolean = false;
    whiteLabelingData: any;
    whiteLabelingDraft: any;

    formGenInput: IFormGeneratorInput;
    formGroupRef: FormGroup;

    @ViewChild('emailStatusRef') emailStatusRef: TemplateRef<any>;

    emailStatus: VerificationStatus;

    VerificationStatus = VerificationStatus;

    lastEmailPublished: string;

    successStatusIcon: IIcon = {
        type: IconType.SVG,
        class: 'success_badge_filled',
        text: 'Email Verified',
        extraClass: 'svg-success-fill suffix-icon'
    };

    failedStatusIcon: IIcon = {
        type: IconType.SVG,
        class: 'warning_filled',
        text: 'Unauthorized',
        extraClass: 'svg-warning-fill suffix-icon'
    };

    pendingStatusIcon: IIcon = {
        type: IconType.SVG,
        class: 'pending_clock_filled',
        text: 'Approval Pending',
        extraClass: 'svg-info-fill suffix-icon'
    };

    updateEmailStatusBtn: IButtonGeneratorInput;
    isEmailStatusUpdating: boolean = false;

    selectedImagesForUpload = null;

    reInitializeWhiteLabelData: Function;

    readonly JSON_ORG_PROP_KEY = 'orgProprietary';
    readonly JSON_DARK_LOGO_KEY = 'darkLogo';
    readonly JSON_LIGHT_LOGO_KEY = 'lightLogo';
    readonly JSON_MINI_LOGO_KEY = 'miniLogo';
    readonly JSON_LOGIN_IMAGE_KEY = 'loginImage';
    readonly JSON_EMAIL_KEY = 'email';
    readonly JSON_EMAIL_STATUS_KEY = 'emailVerificationStatus';

    readonly PROP_LOGO = 'propLogo';
    readonly PROP_FAVICON = 'propFavicon';
    readonly PROP_IMAGE = 'propImage';
    readonly PROP_EMAIL = 'propEmail';

    readonly TEMP_PRESIGNED_KEY = 'presignedUrlKey';
    readonly TEMP_UPLOAD_KEY = 'uploaded';
    readonly TEMP_NOT_UPLOAD_KEY = 'notUploaded';

    saveButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Save As Draft',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        function: (buttonRef) => {
            this.saveAsDraft(buttonRef);
        }
    };

    previewButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Preview',
        buttonType: ButtonType.FLAT,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Already in preview mode'
            : null,
        buttonColorType: ButtonColorType.SECONDARY,
        showLoader: true,
        function: () => {
            if (this.subWidgetData.data.previewWhitelabelModalData) {
                const data = Helper.cloneDeep(this.whiteLabelingDraft);

                data[this.JSON_ORG_PROP_KEY][this.JSON_LIGHT_LOGO_KEY] =
                    this.whiteLabelingData &&
                    this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
                        ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                              this.JSON_LIGHT_LOGO_KEY
                          ]
                        : null;

                data[this.JSON_ORG_PROP_KEY][this.JSON_DARK_LOGO_KEY] =
                    this.whiteLabelingData &&
                    this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
                        ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                              this.JSON_DARK_LOGO_KEY
                          ]
                        : null;

                data[this.JSON_ORG_PROP_KEY][this.JSON_LOGIN_IMAGE_KEY] =
                    this.whiteLabelingData &&
                    this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
                        ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                              this.JSON_LOGIN_IMAGE_KEY
                          ]
                        : null;

                data[this.JSON_ORG_PROP_KEY][this.JSON_MINI_LOGO_KEY] =
                    this.whiteLabelingData &&
                    this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
                        ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                              this.JSON_MINI_LOGO_KEY
                          ]
                        : null;

                if (
                    this.selectedImagesForUpload &&
                    this.selectedImagesForUpload
                ) {
                    this.selectedImagesForUpload.forEach((selectedImage) => {
                        if (
                            selectedImage &&
                            selectedImage[this.TEMP_PRESIGNED_KEY]
                        ) {
                            data[this.JSON_ORG_PROP_KEY][
                                selectedImage[this.TEMP_PRESIGNED_KEY]
                            ] = selectedImage.image;
                        }
                    });
                }
                this.subWidgetData.data.previewWhitelabelModalData(data);
            }
        }
    };

    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public modalService: ModalService,
        public globalDataService: GlobalDataService
    ) {
        this.subWidgetId = subWidgetData.subWidgetId;
        this.resetObservable = subWidgetData.resetObserver;
        this.parentWidgetRef = subWidgetData.parentWidgetRef;
        this.isDomainRegistered = subWidgetData.data.isDomainRegistered;
        this.whiteLabelingData = subWidgetData.data.whiteLabelingData;
        this.whiteLabelingDraft = subWidgetData.data.whiteLabelingDraft;
        this.saveAsDraftApiInfo = subWidgetData.data.saveAsDraftApiInfo;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
    }

    ngOnInit(): void {
        this.initResetObservable();
        this.initFormGenInput();
        if (
            this.whiteLabelingData &&
            this.whiteLabelingData[this.JSON_EMAIL_STATUS_KEY]
        ) {
            this.emailStatus =
                this.whiteLabelingData[this.JSON_EMAIL_STATUS_KEY];
        }
        this.lastEmailPublished =
            this.whiteLabelingData &&
            this.whiteLabelingData[this.JSON_ORG_PROP_KEY] &&
            this.whiteLabelingData[this.JSON_ORG_PROP_KEY][this.JSON_EMAIL_KEY]
                ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                      this.JSON_EMAIL_KEY
                  ]
                : null;
        this.updateEmailStatusBtn = {
            buttonName: 'Refresh',
            buttonColorType: ButtonColorType.INFO,
            buttonType: ButtonType.ICON,
            iconButtonType: 'filled',
            buttonIcon: {
                type: IconType.SVG,
                class: 'refresh',
                extraClass: 'icon-button'
            },
            function: (buttonRef) => {
                this.updateEmailStatus();
            }
        };
    }

    ngAfterViewInit(): void {
        if (this.formGenInput && this.formGenInput.fields) {
            this.formGenInput.fields.forEach((field) => {
                if (field.fieldType === FilterType.EMAIL) {
                    field.suffixTemplate = {
                        templateRef: this.emailStatusRef
                    };
                }
            });
        }
        this.showExistingImages();
    }

    initResetObservable() {
        if (this.resetObservable) {
            this.resetSubscription = this.resetObservable.subscribe(
                (targetId) => {
                    if (targetId === this.subWidgetId) {
                        if (this.formGroupRef) {
                            if (
                                this.whiteLabelingData &&
                                this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
                            ) {
                                this.resetFormToLastState();
                            } else {
                                this.formGroupRef.reset();
                            }
                        }
                    }
                }
            );
        }
    }

    initDraftData() {
        this.whiteLabelingDraft[this.JSON_ORG_PROP_KEY][this.JSON_EMAIL_KEY] =
            this.whiteLabelingData &&
            this.whiteLabelingData[this.JSON_ORG_PROP_KEY] &&
            this.whiteLabelingData[this.JSON_ORG_PROP_KEY][this.JSON_EMAIL_KEY]
                ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                      this.JSON_EMAIL_KEY
                  ]
                : null;
    }

    initFormGenInput() {
        this.initDraftData();

        this.formGenInput = {
            formName: "Configure Organization's Proprietary",
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.IMAGE_UPLOAD,
                    label: 'Upload Logo',
                    placeholder: null,
                    name: this.PROP_LOGO,
                    required: false,
                    imageUpload: {
                        imageDetails: [
                            {
                                modalLabel: 'Dark Theme Logo',
                                inputLabel: 'Dark Theme',
                                maxSizeAllowed: 512000
                            },
                            {
                                modalLabel: 'Light Theme Logo',
                                inputLabel: 'Light Theme',
                                maxSizeAllowed: 512000
                            }
                        ],
                        noImagePreviewMessage: 'No Logo Uploaded',
                        extraMessage: `This logo will appear in the header, login screen, and as an element wherever required. upload your company's trademark logo.`,
                        moreInfoButton: {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonRef) => {
                                this.showMoreInfo(this.PROP_LOGO);
                            }
                        },
                        widgetRef: this.parentWidgetRef,
                        uploadCallback: this.newImageUploaded.bind(this)
                    },
                    fieldStyle: { width: '33%' }
                },
                {
                    fieldType: FilterType.IMAGE_UPLOAD,
                    label: 'Upload Favicon',
                    placeholder: null,
                    name: this.PROP_FAVICON,
                    required: false,
                    imageUpload: {
                        imageDetails: [
                            {
                                modalLabel: 'Favicon',
                                maxSizeAllowed: 512000
                            }
                        ],
                        noImagePreviewMessage: 'No Favicon Uploaded',
                        extraMessage: `This image will be displayed as the browser tab icon. Upload an image as an icon for your whiteLabeling domain.`,
                        moreInfoButton: {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonRef) => {
                                this.showMoreInfo(this.PROP_FAVICON);
                            }
                        },
                        widgetRef: this.parentWidgetRef,
                        uploadCallback: this.newImageUploaded.bind(this)
                    },
                    fieldStyle: { width: '33%' }
                },
                {
                    fieldType: FilterType.IMAGE_UPLOAD,
                    label: 'Upload Log In Image',
                    placeholder: null,
                    name: this.PROP_IMAGE,
                    required: false,
                    imageUpload: {
                        imageDetails: [
                            {
                                modalLabel: 'Login Image',
                                maxSizeAllowed: 2097152
                            }
                        ],
                        noImagePreviewMessage: 'No Image Uploaded',
                        extraMessage: `This image will appear on the Login Page of the Console. Upload your company’s image.`,
                        moreInfoButton: {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonRef) => {
                                this.showMoreInfo(this.PROP_IMAGE);
                            }
                        },
                        widgetRef: this.parentWidgetRef,
                        uploadCallback: this.newImageUploaded.bind(this)
                    },
                    fieldStyle: { width: '33%' }
                },
                {
                    fieldType: FilterType.EMAIL,
                    label: 'Email Authentication',
                    name: this.PROP_EMAIL,
                    placeholder: 'Enter email...',
                    required: false,
                    showLabel: true,
                    showSuffixIconInLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid email'
                        }
                    ],
                    suffixIcon: {
                        hoverText: 'More Info',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'info'
                        },
                        function: () => {
                            this.showMoreInfo(this.PROP_EMAIL);
                        }
                    },
                    value:
                        this.whiteLabelingData &&
                        this.whiteLabelingData[this.JSON_ORG_PROP_KEY] &&
                        this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                            this.JSON_EMAIL_KEY
                        ]
                            ? this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                                  this.JSON_EMAIL_KEY
                              ]
                            : null
                }
            ]
        };
    }

    showMoreInfo(fieldName) {
        const moreInfoMessage = {
            [this.PROP_LOGO]: {
                heading: 'Logo',
                data: [
                    `<b>Edit:</b> If you want to change the submission, you can select ‘Edit Insight’ option on top right.`,
                    `<b>Recommended Dimension:</b> 350px X 100px.`,
                    `<b>Recommended Format:</b> PNG.`,
                    `<b>Maximum Size:</b> 500KB.`
                ]
            },
            [this.PROP_FAVICON]: {
                heading: 'Favicon',
                data: [
                    `<b>Edit:</b> If you want to change the submission, you can select ‘Edit Insight’ option on top right.`,
                    `<b>Recommended Dimension:</b> 11px X 20px.`,
                    `<b>Recommended Format:</b> PNG.`,
                    `<b>Maximum Size:</b> 500KB.`
                ]
            },
            [this.PROP_IMAGE]: {
                heading: 'Image',
                data: [
                    `<b>Edit:</b> If you want to change the submission, select the ‘Edit Insight’ option on the top right.`,
                    `<b>Recommended Dimension:</b> 510px X 550px.`,
                    `<b>Recommended Format:</b> PNG.`,
                    `<b>Maximum Size:</b> 2MB.`
                ]
            },
            [this.PROP_EMAIL]: {
                heading: 'Email Authentication',
                data: [
                    `Enter the email ID that you want to authenticate to communicate with your users to send reports, alerts and much more. This should be a valid email ID.`,
                    `Register the submitted email ID by clicking 'Register' and click on 'Verify' to ensure it is ready to send emails.`,
                    `If you do not register and verify the email ID, then the emails will sent via default email ID.`
                ]
            }
        };
        this.parentWidgetRef.modalService.openInfoModal({
            infoHeading: moreInfoMessage[fieldName].heading,
            content: [
                {
                    type: 'UNORDERED_LIST',
                    data: moreInfoMessage[fieldName].data,
                    listStyleType: 'disc'
                }
            ]
        });
    }

    showExistingImages() {
        if (
            this.formGroupRef &&
            this.whiteLabelingData &&
            this.whiteLabelingData[this.JSON_ORG_PROP_KEY]
        ) {
            const orgProp = this.whiteLabelingData[this.JSON_ORG_PROP_KEY];
            if (orgProp[this.JSON_DARK_LOGO_KEY]) {
                Helper.checkImageSrcExistance(
                    orgProp[this.JSON_DARK_LOGO_KEY],
                    () => {
                        if (
                            this.formGroupRef.get(this.PROP_LOGO).value &&
                            this.formGroupRef.get(this.PROP_LOGO).value.length >
                                0
                        ) {
                            const value = this.formGroupRef.get(
                                this.PROP_LOGO
                            ).value;
                            value[0] = orgProp[this.JSON_DARK_LOGO_KEY];
                            this.formGroupRef
                                .get(this.PROP_LOGO)
                                .setValue([...value]);
                        } else {
                            this.formGroupRef
                                .get(this.PROP_LOGO)
                                .setValue([
                                    orgProp[this.JSON_DARK_LOGO_KEY],
                                    null
                                ]);
                        }
                    }
                );
            }
            if (orgProp[this.JSON_LIGHT_LOGO_KEY]) {
                Helper.checkImageSrcExistance(
                    orgProp[this.JSON_LIGHT_LOGO_KEY],
                    () => {
                        if (
                            this.formGroupRef.get(this.PROP_LOGO).value &&
                            this.formGroupRef.get(this.PROP_LOGO).value.length >
                                1
                        ) {
                            const value = this.formGroupRef.get(
                                this.PROP_LOGO
                            ).value;
                            value[1] = orgProp[this.JSON_LIGHT_LOGO_KEY];
                            this.formGroupRef
                                .get(this.PROP_LOGO)
                                .setValue([...value]);
                        } else {
                            this.formGroupRef
                                .get(this.PROP_LOGO)
                                .setValue([
                                    null,
                                    orgProp[this.JSON_LIGHT_LOGO_KEY]
                                ]);
                        }
                    }
                );
            }
            if (orgProp[this.JSON_MINI_LOGO_KEY]) {
                Helper.checkImageSrcExistance(
                    orgProp[this.JSON_MINI_LOGO_KEY],
                    () => {
                        this.formGroupRef
                            .get(this.PROP_FAVICON)
                            .setValue([orgProp[this.JSON_MINI_LOGO_KEY]]);
                    }
                );
            }
            if (orgProp[this.JSON_LOGIN_IMAGE_KEY]) {
                Helper.checkImageSrcExistance(
                    orgProp[this.JSON_LOGIN_IMAGE_KEY],
                    () => {
                        this.formGroupRef
                            .get(this.PROP_IMAGE)
                            .setValue([orgProp[this.JSON_LOGIN_IMAGE_KEY]]);
                    }
                );
            }
        }
    }

    newImageUploaded(imageDetails, field) {
        if (this.selectedImagesForUpload === null) {
            this.selectedImagesForUpload = [];
        }
        if (field && field.name) {
            const changedImages = imageDetails.filter((imageDetail, index) => {
                if (imageDetail.dirty) {
                    if (field.name === this.PROP_LOGO) {
                        if (index === 0) {
                            // 0 -> for dark logo
                            imageDetail[this.TEMP_PRESIGNED_KEY] =
                                this.JSON_DARK_LOGO_KEY;
                        }
                        if (index === 1) {
                            // 1 -> for light logo
                            imageDetail[this.TEMP_PRESIGNED_KEY] =
                                this.JSON_LIGHT_LOGO_KEY;
                        }
                    }
                    if (field.name === this.PROP_FAVICON) {
                        imageDetail[this.TEMP_PRESIGNED_KEY] =
                            this.JSON_MINI_LOGO_KEY;
                    }
                    if (field.name === this.PROP_IMAGE) {
                        imageDetail[this.TEMP_PRESIGNED_KEY] =
                            this.JSON_LOGIN_IMAGE_KEY;
                    }
                    imageDetail[this.TEMP_UPLOAD_KEY] = false;
                    imageDetail[this.TEMP_NOT_UPLOAD_KEY] = false;
                    return true;
                }
                return false;
            });
            if (changedImages && changedImages.length) {
                this.selectedImagesForUpload.push(...changedImages);
            }
        }
    }

    updateEmailStatus(
        ignoreResetLastEmail?: boolean,
        buttonRef?: IButtonGeneratorInput
    ) {
        if (
            buttonRef &&
            this.formGroupRef &&
            this.formGroupRef.get(this.PROP_EMAIL).value ===
                this.lastEmailPublished
        ) {
            buttonRef.loader = false;
            this.parentWidgetRef.changeDetectorRef.detectChanges();
            return;
        }
        if (
            this.formGroupRef &&
            this.formGroupRef.get(this.PROP_EMAIL) &&
            !ignoreResetLastEmail
        ) {
            this.formGroupRef
                .get(this.PROP_EMAIL)
                .setValue(this.lastEmailPublished);
        }
        const emailStatusApiInfo: IApiInfo =
            this.parentWidgetRef.widgetData.widgetInfo.action;
        if (emailStatusApiInfo && !this.isEmailStatusUpdating) {
            this.isEmailStatusUpdating = true;
            const apiArgs = Helper.generateHitApiConfig(emailStatusApiInfo);
            apiArgs.input = {};
            apiArgs.function = (response) => {
                this.lastEmailPublished = this.formGroupRef.get(
                    this.PROP_EMAIL
                ).value;
                this.whiteLabelingData[this.JSON_EMAIL_STATUS_KEY] = response;
                this.emailStatus = response;
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.parentWidgetRef.notificationsService,
                    error,
                    'Error occured while updating email status'
                );
            };
            apiArgs.endFunction = () => {
                if (buttonRef) {
                    buttonRef.loader = false;
                }
                this.isEmailStatusUpdating = false;
                this.parentWidgetRef.changeDetectorRef.detectChanges();
            };
            new HitApi(
                apiArgs,
                this.parentWidgetRef.httpService,
                this.parentWidgetRef.ngZone
            ).hitApi();
        }
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (this.formGroupRef && this.saveAsDraftApiInfo) {
            const clonedDraft = Helper.cloneDeep(this.whiteLabelingDraft);
            clonedDraft[this.JSON_ORG_PROP_KEY][this.JSON_EMAIL_KEY] =
                this.formGroupRef.get(this.PROP_EMAIL).value;
            buttonRef.loader = true;
            const apiConfig = Helper.generateHitApiConfig(
                this.saveAsDraftApiInfo
            );
            apiConfig.input = clonedDraft;
            apiConfig.function = (response) => {
                if (!this.whiteLabelingData[this.JSON_ORG_PROP_KEY]) {
                    this.whiteLabelingData[this.JSON_ORG_PROP_KEY] = {};
                }
                this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                    this.JSON_EMAIL_KEY
                ] = clonedDraft[this.JSON_ORG_PROP_KEY][this.JSON_EMAIL_KEY];
                this.initDraftData();

                this.uploadToS3(buttonRef);
            };
            apiConfig.errorFunction = (error) => {
                buttonRef.loader = false;
                this.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.parentWidgetRef.notificationsService,
                    error,
                    "Error in saving Organization's Proprietary"
                );
            };
            new HitApi(
                apiConfig,
                this.parentWidgetRef.httpService,
                this.parentWidgetRef.ngZone
            ).hitApi();
        }
    }

    resetFormToLastState() {
        const orgProp = this.whiteLabelingData[this.JSON_ORG_PROP_KEY];
        if (orgProp && this.formGroupRef) {
            if (orgProp[this.JSON_EMAIL_KEY]) {
                this.formGroupRef
                    .get(this.PROP_EMAIL)
                    .setValue(orgProp[this.JSON_EMAIL_KEY]);
            } else {
                this.formGroupRef.get(this.PROP_EMAIL).reset();
            }
            this.formGroupRef.get(this.PROP_LOGO).reset();
            this.formGroupRef.get(this.PROP_FAVICON).reset();
            this.formGroupRef.get(this.PROP_IMAGE).reset();
            this.showExistingImages();
        }
    }

    uploadToS3(buttonRef: IButtonGeneratorInput) {
        const doneUpload = (message?: string, isError?: boolean) => {
            this.updateEmailStatus(true, buttonRef);

            this.parentWidgetRef.changeDetectorRef.detectChanges();
            if (this.reInitializeWhiteLabelData) {
                this.reInitializeWhiteLabelData();
            }
            if (message) {
                this.parentWidgetRef.notificationsService.showSnackBar(
                    message,
                    isError,
                    '',
                    { duration: 3500 }
                );
            }
        };
        if (
            this.selectedImagesForUpload &&
            this.selectedImagesForUpload.length
        ) {
            const presignedUrlApiInfo: IApiInfo =
                this.parentWidgetRef.widgetData.widgetInfo[
                    'additionalApisForWidget'
                ] &&
                this.parentWidgetRef.widgetData.widgetInfo[
                    'additionalApisForWidget'
                ]['generatePresignedUrl']
                    ? this.parentWidgetRef.widgetData.widgetInfo[
                          'additionalApisForWidget'
                      ]['generatePresignedUrl']
                    : null;
            if (presignedUrlApiInfo) {
                const apiArgs =
                    Helper.generateHitApiConfig(presignedUrlApiInfo);
                apiArgs.input = {};
                apiArgs.function = (response) => {
                    this.selectedImagesForUpload.forEach((selectedImage) => {
                        if (
                            selectedImage &&
                            selectedImage[this.TEMP_PRESIGNED_KEY]
                        ) {
                            this.uploadToS3UsingKey(
                                response[
                                    selectedImage[this.TEMP_PRESIGNED_KEY]
                                ],
                                selectedImage,
                                buttonRef
                            );
                        }
                    });
                };
                apiArgs.errorFunction = (error) => {
                    doneUpload();
                    Helper.showErrorMessage(
                        this.parentWidgetRef.notificationsService,
                        error,
                        'Error while uploading images'
                    );
                };
                new HitApi(
                    apiArgs,
                    this.parentWidgetRef.httpService,
                    this.parentWidgetRef.ngZone
                ).hitApi();
            } else {
                doneUpload();
            }
        } else {
            doneUpload(
                'Configuration saved successfully. Click on publish to deploy these changes.'
            );
        }
    }

    uploadToS3UsingKey(presignedUrl, imageUploadObject, buttonRef) {
        const uploadHitApi: IHitApi = {
            url: presignedUrl,
            requestType: RequestType.PUT,
            input: imageUploadObject.file,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true
            },
            function: (response) => {
                imageUploadObject[this.TEMP_UPLOAD_KEY] = true;
                this.whiteLabelingData[this.JSON_ORG_PROP_KEY][
                    imageUploadObject[this.TEMP_PRESIGNED_KEY]
                ] = imageUploadObject.image;
                this.closeLoaderAndRefresh(buttonRef);
            },
            errorFunction: (error) => {
                imageUploadObject[this.TEMP_NOT_UPLOAD_KEY] = true;
                this.closeLoaderAndRefresh(buttonRef);
                Helper.showErrorMessage(
                    this.parentWidgetRef.notificationsService,
                    `Error while uploading ${imageUploadObject.label}`
                );
            }
        };
        new HitApi(
            uploadHitApi,
            this.parentWidgetRef.httpService,
            this.parentWidgetRef.ngZone
        ).hitApi();
    }

    closeLoaderAndRefresh(buttonRef: IButtonGeneratorInput) {
        let [tryCount, uploadCount] = [0, 0];
        if (this.selectedImagesForUpload) {
            this.selectedImagesForUpload.forEach((selectedImage) => {
                if (
                    selectedImage[this.TEMP_UPLOAD_KEY] ||
                    selectedImage[this.TEMP_NOT_UPLOAD_KEY]
                ) {
                    tryCount++;
                }
                if (selectedImage[this.TEMP_UPLOAD_KEY]) {
                    uploadCount++;
                }
            });
            if (this.selectedImagesForUpload.length === tryCount) {
                this.updateEmailStatus(true, buttonRef);
                this.parentWidgetRef.changeDetectorRef.detectChanges();
                if (this.reInitializeWhiteLabelData) {
                    this.reInitializeWhiteLabelData();
                }
                if (this.selectedImagesForUpload.length === uploadCount) {
                    this.parentWidgetRef.notificationsService.showSnackBar(
                        'Configuration saved successfully. Click on publish to deploy these changes.',
                        false,
                        '',
                        { duration: 3500 }
                    );
                }
            }
        }
    }

    onValuesChange(event) {
        if (
            this.lastEmailPublished &&
            event[this.PROP_EMAIL] !== this.lastEmailPublished
        ) {
            if (this.emailStatus !== VerificationStatus.PENDING) {
                this.emailStatus = null;
            }
        } else {
            if (
                this.emailStatus !==
                this.whiteLabelingData[this.JSON_EMAIL_STATUS_KEY]
            ) {
                this.emailStatus =
                    this.whiteLabelingData[this.JSON_EMAIL_STATUS_KEY];
            }
        }
    }

    ngOnDestroy(): void {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}

/**
 * AUTHENTICATED this is the enum u will be getting when email is noreply@centilytics.com
 */
enum VerificationStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    AUTHENTICATED = 'AUTHENTICATED'
}
