import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { FilterCacheService } from './../../../../services/cache/filters-cache/filter-cache.service';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from './../../../../services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-create-tag-ConfigurationForm-step-one.html',
    templateUrl: './create-tag-ConfigurationForm-step-one.html',
    styleUrls: ['./create-tag-ConfigurationForm-step-one.sass']
})
export class CreateTagConfigurationFormStepOne {
    widgetRef: Widget;
    hideServiceFilter: boolean = false;
    applicableResources = null;
    data;
    Ids = [];
    filterIds: [] = [];
    fieldValue: Map<string, any> = new Map();
    stepData = null;
    edit: boolean = false;
    tagConfigFormGroup: FormGroup = null;
    configurationName;
    loader: boolean = false;
    existingConventionList = [];
    buttonInput: IButtonGeneratorInput;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    tagConfigFirstFormGenInput: IFormGeneratorInput = null;
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private filterCacheService: FilterCacheService,
        private configCache: ConfigCacheService,
        private userCache: UserDataCacheService
    ) {
        if (modalInputData.data.hideServiceFilter) {
            this.hideServiceFilter = modalInputData.data['hideServiceFilter'];
            this.applicableResources =
                modalInputData.data['applicableResources'];
        }
        this.filterIds = modalInputData.data['filterIds'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.stepData = modalInputData.data['stepData'];
        this.edit = modalInputData.data['edit'];
        this.existingConventionList =
            this.modalInputData.data['existingConventionsList'];
    }
    ngOnInit() {
        this.loader = true;

        this.setFieldsValue(this.stepData, this.stepData ? true : false);
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            const stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);

            this.stepData = stepData;
            this.setFieldsValue(stepData, true);
        }
        this.buttonInput = {
            buttonName: 'Next',
            function: () => {
                this.goTostepTwo();
            },
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY
        };

        this.generateFormInput();
    }
    setFieldsValue(stepData, stepDataValue?) {
        const keys = Helper.setDynamicCloudFilterKeys(this.widgetRef);

        this.fieldValue.set(
            keys['accountIds'],
            stepDataValue && stepData['accountIds']
                ? stepData['accountIds']
                : stepDataValue && stepData['projectId']
                ? stepData['projectId']
                : []
        );
        this.fieldValue.set(
            keys['regions'],
            stepDataValue && stepData['regions']
                ? stepData['regions']
                : stepDataValue && stepData['gcpRegions']
                ? stepData['gcpRegions']
                : []
        );
        this.fieldValue.set(
            keys['resourceTags'],
            stepDataValue && stepData['resourceTags']
                ? stepData['resourceTags']
                : []
        );

        if (stepDataValue) {
            if (this.stepData['configurationName']) {
                this.configurationName = this.stepData['configurationName'];
            } else if (this.stepData['ruleSetName']) {
                this.configurationName = this.stepData['ruleSetName'];
            }
        }
    }

    generateFormInput() {
        Helper.generateFormInput(
            this.filterIds,
            this.widgetRef.httpService,
            this.filterCacheService,
            {
                showkey: 'label',
                responseValueKey: 'dataList'
            },
            true,
            this.fieldValue,
            null,
            null,
            null,
            this.userCache,
            this.configCache
        ).subscribe((data) => {
            if (data) {
                if (!this.hideServiceFilter) {
                    data.fields.unshift({
                        label: 'Services',
                        placeholder: 'Select Services',
                        name: 'applicableResources',
                        fieldType: FilterType.DROPDOWN_MULTIPLE,
                        required: true,
                        showKey: 'label',
                        responseValueKey: 'listData',
                        apiInfo: this.widgetRef.widgetData.widgetInfo['action'],
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Service is required'
                            }
                        ],
                        value: this.stepData
                            ? this.stepData['applicableResources']
                            : []
                    });
                }
                data.fields.unshift({
                    label: 'Convention Name',
                    placeholder: 'Select Name',
                    name: 'ruleSetName',
                    disabled: this.edit ? true : false,
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Convention Name is required'
                        },
                        {
                            validator: CustomValidators.controlValueNotIn(
                                this.existingConventionList
                            ),
                            errorMessage:
                                'Convention already exists with this name'
                        }
                    ],
                    value: this.configurationName ? this.configurationName : ''
                });
                this.tagConfigFirstFormGenInput = data;
                this.loader = false;
            }
        });
    }
    goTostepTwo() {
        Helper.markAllFieldAsTouched(this.tagConfigFormGroup);
        if (!this.tagConfigFormGroup || this.tagConfigFormGroup.invalid) {
            return;
        }
        const firstStepData = Helper.cloneDeep(this.tagConfigFormGroup.value);

        if (this.applicableResources) {
            firstStepData['applicableResources'] = [this.applicableResources];
        }
        if (this.edit) {
            firstStepData['ruleSetName'] = this.configurationName;
        }
        firstStepData['email'] = this.userCache.emailId;

        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, firstStepData);

        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
}
