<div class="filters-container">
    <div class="tabs" *ngIf="filters">
        <div class="tab-header">
            <div class="tab-header-item">FILTERS</div>
            <div
                matTooltip="Toggle Filter"
                appIcon
                [data]="filterIcon"
                (click)="filterMenu.toggle()"
                class="filter-icon clickable"
            ></div>
        </div>
        <div class="tab-content">
            <div class="tab-content-item tab-content-item-1">
                <ng-container *ngFor="let filter of filters">
                    <ng-container
                        *ngIf="
                            filtersData.get(filter) &&
                            filtersData.get(filter).type !== FilterType.CURRENCY
                        "
                    >
                        <div class="filters">
                            <div class="filter-head">
                                {{ filtersData.get(filter).label }}
                            </div>
                            <div class="filter-content">
                                <!-- Dropdown -->
                                <ng-container
                                    *ngIf="
                                        filtersData
                                            .get(filter)
                                            .type.includes('DROPDOWN')
                                    "
                                >
                                    <app-single-select-dropdown
                                        [filterInfo]="filtersData.get(filter)"
                                        [dummy]="true"
                                    ></app-single-select-dropdown>
                                </ng-container>

                                <!-- Date Range -->
                                <ng-container
                                    *ngIf="
                                        filtersData.get(filter).type ===
                                        FilterType.DATE_RANGE
                                    "
                                >
                                    <app-date-range-filter
                                        class="date-range-template"
                                        [filterInfo]="filtersData.get(filter)"
                                        [dummy]="true"
                                    >
                                    </app-date-range-filter>
                                </ng-container>

                                <!-- Toggle -->
                                <ng-container
                                    *ngIf="
                                        filtersData.get(filter).type ===
                                        FilterType.TOGGLE
                                    "
                                >
                                    <app-toggle
                                        class="toggle-template"
                                        [filterInfo]="filtersData.get(filter)"
                                    >
                                    </app-toggle>
                                </ng-container>

                                <!-- Number -->
                                <ng-container
                                    *ngIf="
                                        filtersData.get(filter).type ===
                                        FilterType.NUMBER
                                    "
                                >
                                    <input
                                        class="number-field"
                                        type="number"
                                        [placeholder]="'Round Off'"
                                    />
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            filtersData.get(filter) &&
                            filtersData.get(filter).type === FilterType.CURRENCY
                        "
                    >
                        <app-currency-filter
                            class="currency-template"
                            [dummy]="true"
                            [filterInfo]="filtersData.get(filter)"
                        >
                        </app-currency-filter>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
