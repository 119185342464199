import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { NagiosIntegrationStepOneComponent } from '../../../modal-templates/integration/nagios-modal/nagios-integration-step-one/nagios-integration-step-one.component';
import { NagiosIntegrationStepTwoComponent } from '../../../modal-templates/integration/nagios-modal/nagios-integration-step-two/nagios-integration-step-two.component';
import { ButtonType } from './../../../../enums/ButtonType';

@Component({
    selector: 'app-nagios-integration',
    templateUrl: './nagios-integration.component.html',
    styleUrls: ['./nagios-integration.component.sass']
})
export class NagiosIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    apiResponse;
    isParentIntegrated = false;
    isSelfIntegrated = false;
    selfData: any = [];
    parentData: any = [];
    integrationName: any = [];
    isConfigured = false;
    tabChanged: Subject<string> = new Subject<string>();
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Nagios Integration',
            imageUrl: 'assets/nagios.png',
            buttons: [],
            imagePadding: 8
        };
    }
    generateButtons(response: IIntegrationApiResponse) {
        if (this.isSelfIntegrated || this.isParentIntegrated) {
            if (this.isSelfIntegrated) {
                this.isConfigured = true;
                // Adding edit button
                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-pen'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit Existing Integrations',
                    function: () => {
                        this.tabChanged.subscribe((tab) => {
                            if (tab === 'OPEN DIRECTORY') {
                                modalArgs.modalSteps[1].resetModal = false;
                            } else {
                                modalArgs.modalSteps[1].resetModal = true;
                            }
                        });
                        const modalArgs: IModalData = {
                            modalName: 'Nagios XI Integration',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.SVG,
                                class: 'nagios_modal_icon'
                            },
                            noStepPadding: true,
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Credentials',
                                    stepData: {
                                        componentToLoad:
                                            NagiosIntegrationStepOneComponent,
                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef,
                                                edit: true,
                                                response: {
                                                    parent: this.parentData,
                                                    self: this.selfData
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    stepName: 'Contact Details',
                                    stepData: {
                                        componentToLoad:
                                            NagiosIntegrationStepTwoComponent,
                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef,
                                                edit: true,
                                                tabChanged: this.tabChanged
                                            }
                                        }
                                    },
                                    stepHeightVh: 76,
                                    resetModal: true
                                }
                            ],
                            modalWidthVw: 70,
                            modalHeightVh: 90
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });

                // Adding delete button
                this.integrationInput.buttons.push({
                    buttonName: 'Delete',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Existing Integrations',
                    function: (modalId: Symbol) => {
                        // Delete integration
                        const args = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo.delete
                        );
                        args.input = { integrationName: this.integrationName };
                        args.function = () => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `Nagios integration removed successfully`
                            );
                            this.widgetRef.refreshWidget();
                            this.selfData = [];
                            this.parentData = [];
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    executeFunctionAfterConfirmation: {
                        modalName: 'Remove Nagios Integration',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'far fa-trash-alt'
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: `Are you sure you want to remove Nagios ?`,
                        buttonText: 'Remove',
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    }
                });
            }
            if (!this.isSelfIntegrated && this.isParentIntegrated) {
                this.isConfigured = true;
                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-pen'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit Existing Integrations',
                    function: () => {
                        const modalArgs: IModalData = {
                            modalName: 'Nagios XI Integration',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.SVG,
                                class: 'nagios_modal_icon'
                            },
                            noStepPadding: true,
                            extraStepContainerClass: 'remove-form-space',
                            modalSteps: [
                                {
                                    stepName: 'Credentials',
                                    stepData: {
                                        componentToLoad:
                                            NagiosIntegrationStepOneComponent,
                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef,
                                                edit: true,
                                                response: {
                                                    parent: this.parentData,
                                                    self: this.selfData
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    stepName: 'Contact Details',
                                    stepData: {
                                        componentToLoad:
                                            NagiosIntegrationStepTwoComponent,
                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef,
                                                edit: true
                                            }
                                        }
                                    },
                                    stepHeightVh: 76,
                                    resetModal: true
                                }
                            ],
                            modalWidthVw: 80,
                            modalHeightVh: 90
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    }
                });
            }
        }
    }
    bindData(response): void {
        this.selfData = [];
        this.parentData = [];
        this.isConfigured = false;
        if (response) {
            this.isParentIntegrated = false;
            this.isSelfIntegrated = false;
        }
        if (
            response.nagiosIntegration &&
            response.nagiosIntegration.parent &&
            response.nagiosIntegration.parent.length
        ) {
            this.isParentIntegrated = true;
            this.isSelfIntegrated = false;
            this.parentData = response.nagiosIntegration.parent;
        }
        if (
            response.nagiosIntegration &&
            response.nagiosIntegration.self &&
            response.nagiosIntegration.self.length
        ) {
            this.isSelfIntegrated = true;
            this.selfData = response.nagiosIntegration.self;
            this.selfData.forEach((data) => {
                this.integrationName.push(data.integrationName);
            });
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    addIntegration(buttonRef) {
        const modalArgs: IModalData = {
            modalIcon: {
                type: IconType.SVG,
                class: 'nagios_modal_icon'
            },
            modalName: 'Nagios Integration',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            extraStepContainerClass: 'remove-form-space',
            noStepPadding: true,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepName: 'Credentials',
                    stepData: {
                        componentToLoad: NagiosIntegrationStepOneComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Contact Details',
                    stepData: {
                        componentToLoad: NagiosIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepHeightVh: 76,
                    resetModal: true
                }
            ],
            modalWidthVw: 80,
            modalHeightVh: 95
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
