<div
    *ngIf="
        widgetRef.apiResponse &&
        widgetRef.apiResponse['dataMap'] &&
        widgetRef.apiResponse['dataMap']['totalPotentialSavings']
    "
    class="tw-my-2 tw-rounded-md general"
>
    <span class="header-web-view tw-font-semibold">
        Potential Savings: &nbsp;</span
    >
    <span class="value tw-font-bold">
        {{ widgetRef.apiResponse["dataMap"]["totalPotentialSavings"] }}
    </span>
</div>
<app-table-generator
    class="table-gen"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [overviewTemplateStart]="checkOverview"
    (gridRef)="gridRef = $event"
    (selectionChanged)="onSelectionChanged()"
    [showSearchBox]="showSearchBox"
></app-table-generator>

<ng-template #checkOverview>
    <app-multi-button-generator
        *ngIf="rowData && rowData.length && severityButtons"
        [buttonGenInputs]="severityButtons"
    ></app-multi-button-generator>
</ng-template>
