import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-delete',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.sass']
})
export class DeleteComponent implements OnInit {
    payload;
    data;
    response;
    loader;
    reason;

    constructor(
        private modalData: ModalInjectedData,
        private notificationsService: NotificationsService,
        private multiStepFormService: MultiStepFormService
    ) {
        this.payload = modalData.data;
    }
    ngOnInit() {
        if (this.payload) {
            this.response = this.payload;
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(1)
        ) {
            this.data = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1);
        }
    }

    action(buttonRef, func) {
        func(buttonRef, this.modalData.modalId);
    }
}
