<div class="carousel-track-container">
    <ng-container>
        <div class="carousel">
            <ul class="carousel-track" *ngIf="carouselSlideInput.length > 0">
                <ng-container
                    *ngFor="
                        let slide of carouselSlideInput;
                        index as slideIndex;
                        trackBy: widgetIdentify
                    "
                >
                    <ng-container>
                        <li
                            [hidden]="
                                !(
                                    slideIndex ===
                                        currentPageofCarousel *
                                            limitToShowDataPerPage ||
                                    slideIndex ===
                                        currentPageofCarousel *
                                            limitToShowDataPerPage +
                                            1 ||
                                    slideIndex ===
                                        currentPageofCarousel *
                                            limitToShowDataPerPage +
                                            2 ||
                                    slideIndex ===
                                        currentPageofCarousel *
                                            limitToShowDataPerPage +
                                            3
                                )
                            "
                        >
                            <ng-container
                                *ngComponentOutlet="
                                    slide.componentToLoad;
                                    injector: injectors[slideIndex]
                                "
                            ></ng-container>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </ng-container>
    <div class="lower-panel">
        <div
            class="clickable back-page"
            (click)="
                currentPageofCarousel - 1 >= 0
                    ? (currentPageofCarousel = currentPageofCarousel - 1)
                    : ''
            "
        >
            <span appIcon [data]="leftPointer"></span>
        </div>

        <div class="carousel-nav">
            <div
                *ngFor="let i of totalCarouselIndicator"
                [ngClass]="
                    i === currentPageofCarousel
                        ? 'carousel-indicator active'
                        : 'carousel-indicator'
                "
                (click)="currentPageofCarousel = i"
            ></div>
        </div>
        <div
            class="clickable next-page"
            (click)="
                currentPageofCarousel + 1 < totalCarouselIndicator.length
                    ? (currentPageofCarousel = currentPageofCarousel + 1)
                    : ''
            "
        >
            <span appIcon [data]="rightPointer"></span>
        </div>
    </div>
</div>
