import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
@Component({
    selector: 'app-otrs-parent-integration-step-two',
    templateUrl: './otrs-parent-integration-step-two.component.html',
    styleUrls: ['./otrs-parent-integration-step-two.component.sass']
})
export class OtrsParentIntegrationStepTwoComponent implements OnInit {
    widgetRef: Widget;
    data = {};
    queueValueData = [];
    selectable = true;
    removable = true;
    valueList = [];
    addOnBlur = true;
    objectKeys = Object.keys;
    objectValues = Object.values;
    tempData = {};
    keyValueData = {};
    tempKey = '';
    showEmptyKeyValueField = false;
    errorMessage = '';
    queueErrorMessage = '';
    finalData = null;
    test1: any;
    test2: any;
    isUserIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response = null;
    deleteFieldButtonInput: IButtonGeneratorInput;
    buttonsInput: IButtonGeneratorInput[];
    dynamicFieldButtonInput: IButtonGeneratorInput;
    dynamicFieldThirdButtonInput: IButtonGeneratorInput;
    @Output('change') change = new EventEmitter();
    queueFormGenInput: IFormGeneratorInput;
    queueFormGenGroup: FormGroup;
    fieldNameFormGenInput: IFormGeneratorInput[] = [];
    fieldFormGroup: Map<Symbol, FormGroup> = new Map();
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    infoData: IInfoModal[] = [
        {
            infoHeading: 'Queue -',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' Add queue values you want to show in ticket creation form'
                    ]
                }
            ]
        }
    ];
    temp = {};
    constructor(
        public multiStepFormService: MultiStepFormService,
        public modalInputData: ModalInjectedData
    ) {
        this.response = modalInputData.data['response'];
        this.isOwnerIntegrated =
            modalInputData.data['response']['ownerIntegration'];
        this.isUserIntegrated =
            modalInputData.data['response']['userIntegration'];
        this.isParentIntegrated =
            modalInputData.data['response']['parentIntegration'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit() {
        if (this.response) {
            this.fieldNameFormGenInput.unshift(this.getKeyValueFormGenInput());
            this.idleKeyValueForms();
        }

        this.queueFormGenInput = {
            formName: 'Queue',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'Queue',
                    placeholder: 'Add Value',
                    label: 'Queue',
                    fieldType: FilterType.MATCHIPLIST,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isParentIntegrated
                        ? this.response['configuredFeilds']['queueId']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue value can not be empty'
                        }
                    ],
                    prefixIcon: {
                        hoverText:
                            'Add queue values you want to show in ticket creation form',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'info'
                        }
                    }
                }
            ]
        };
        // if this.isParentIntegrated = true means edit case.
        if (this.isParentIntegrated) {
            this.finalData = this.response['configuredFeilds']['customFeilds'];
            this.queueValueData = this.response['configuredFeilds']['queueId'];
        }

        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2)
        ) {
            const stepTwoData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2);
            this.finalData = stepTwoData['finalData']
                ? stepTwoData['finalData']
                : null;
            this.queueValueData = stepTwoData['queueValueData'];
        }
        if (this.finalData) {
            if (Object.keys(this.finalData).length > 0) {
                this.showEmptyKeyValueField = false;
                $.extend(true, this.keyValueData, this.finalData);
            }
        }
        this.buttonsInput = [
            {
                buttonName: 'Back',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.goToPreviousStep();
                }
            },
            {
                buttonName: this.isParentIntegrated ? 'Update' : 'Integrate',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.integrate(buttonRef);
                }
            }
        ];
        this.deleteFieldButtonInput = {
            buttonName: 'Delete',
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            function: () => {}
        };
        this.dynamicFieldButtonInput = {
            buttonName: 'Add Dynamic Field',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,

            function: () => {},
            customClass: 'add-key-value',

            hoverText: 'Add dynamic fields which are configured on OTRS console'
        };
    }
    getKeyValueFormGenInput(): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'fieldName',
                    label: 'Field Name',
                    placeholder: 'Add Value',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    value: this.isParentIntegrated
                        ? Object.keys(
                              this.response['configuredFeilds']['customFeilds']
                          )
                        : ''
                },
                {
                    name: 'value',
                    label: 'Value',
                    placeholder: 'Add Value',
                    fieldType: FilterType.MATCHIPLIST,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isParentIntegrated
                        ? Object.values(
                              this.response['configuredFeilds']['customFeilds']
                          )
                        : []
                }
            ]
        };
    }
    lastKeyValueFormUpdate(index: number, formId: Symbol) {
        Helper.markAllFieldAsTouched(this.fieldFormGroup.get(formId));
        if (this.fieldFormGroup.get(formId).invalid) {
            this.fieldFormGroup.get(formId).updateValueAndValidity();
            return;
        }
        if (this.fieldFormGroup.has(formId)) {
            const fg = this.fieldFormGroup.get(formId);
            if (fg.valid) {
                if (
                    this.fieldNameFormGenInput &&
                    this.fieldNameFormGenInput.length
                ) {
                    this.fieldNameFormGenInput.unshift(
                        this.getKeyValueFormGenInput()
                    );
                    this.idleKeyValueForms();
                }
            }
        }
    }
    idleKeyValueForms() {
        this.fieldNameFormGenInput = this.fieldNameFormGenInput.map(
            (fg, index) => {
                if (index !== 0) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    getKey(): string[] {
        const key = [];
        this.fieldNameFormGenInput.forEach((fg, index) => {
            if (index !== this.fieldNameFormGenInput.length - 1) {
                if (this.fieldFormGroup.has(fg.formId)) {
                    key.push(
                        this.fieldFormGroup.get(fg.formId).get('fieldName')
                            .value
                    );
                }
            }
        });

        return key;
    }

    deleteField(index: number, formId: Symbol) {
        this.fieldFormGroup.delete(formId);
        this.fieldNameFormGenInput = Helper.removeIndexfromArray(
            index,
            this.fieldNameFormGenInput
        );
    }

    goToPreviousStep() {
        if (this.queueValueData.length || this.finalData) {
            const stepTwoData = {
                finalData: this.finalData ? this.finalData : null,
                queueValueData: this.queueValueData ? this.queueValueData : []
            };

            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(2, stepTwoData);
        }

        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    integrate(buttonRef: IButtonGeneratorInput) {
        const payload = {};
        for (const val of this.fieldFormGroup.values()) {
            if (val.status === 'DISABLED') {
                payload[val['value']['fieldName']] = val['value']['value'];
            }
        }

        this.queueValueData = this.queueFormGenGroup.get('Queue').value;
        if (buttonRef.loader) {
            return;
        }

        const input = Helper.cloneDeep(
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1).inputData
        );
        input['queue'] = this.queueValueData;
        input['customFields'] = payload;

        buttonRef.loader = true;
        this.widgetRef.notificationsService.showSnackBar(
            Messages.DELAY_MESSAGE
        );
        const apiArgs = Helper.generateHitApiConfig(
            this.isParentIntegrated
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = input;
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.notificationsService.showSnackBar(
                this.isParentIntegrated
                    ? 'OTRS Updated Successfully.'
                    : 'OTRS Integrated Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
