<div class="main-container">
    <div class="first-form-container">
        <span class="details">Customer Information</span>
        <hr />

        <table *ngIf="customerDetails">
            <div class="left-container">
                <thead>
                    <tr>
                        <td *ngFor="let thead of Object.keys(customerDetails)">
                            {{ thead }}
                        </td>
                    </tr>
                </thead>
            </div>
            <div class="right-container">
                <tbody>
                    <tr>
                        <td
                            *ngFor="let tData of Object.keys(customerDetails)"
                            [ngClass]="
                                tData === 'Price Type'
                                    ? 'price-type-container '
                                    : tData === 'Retention Period'
                                    ? 'retention-container'
                                    : ''
                            "
                        >
                            <span
                                *ngIf="
                                    tData != 'Price Type' &&
                                    tData !== 'Customer Tag'
                                "
                                else
                                newSpan
                            >
                                {{
                                    customerDetails[tData]
                                        ? customerDetails[tData]
                                        : "-"
                                }}</span
                            >
                            <div
                                #newSpan
                                *ngIf="tData === 'Price Type'"
                                else
                                customerTag
                                class="price-container"
                            >
                                <ng-container
                                    *ngFor="
                                        let priceTypeData of customerDetails[
                                            tData
                                        ]
                                    "
                                >
                                    <span>
                                        {{ priceTypeData }}
                                    </span>
                                </ng-container>
                            </div>
                            <div #customerTag *ngIf="tData === 'Customer Tag'">
                                <app-tag-generator
                                    [extraClass]="'tagMenuResize'"
                                    [selectedTags]="selectedTagsData"
                                    [availableTags]="availableTagsData"
                                    (tagsDataEmitter)="updateTags($event)"
                                    [showSaveButton]="true"
                                ></app-tag-generator>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </div>
        </table>
    </div>
    <div class="second-form-container">
        <span class="details">Root User Information</span>
        <hr />
        <table *ngIf="userDetails">
            <div class="left-container">
                <thead>
                    <tr>
                        <td *ngFor="let thead of Object.keys(userDetails)">
                            {{ thead }}
                        </td>
                    </tr>
                </thead>
            </div>
            <div class="right-container">
                <tbody>
                    <tr>
                        <td *ngFor="let tData of Object.keys(userDetails)">
                            <span>
                                {{
                                    userDetails[tData]
                                        ? userDetails[tData]
                                        : "-"
                                }}</span
                            >
                        </td>
                    </tr>
                </tbody>
            </div>
        </table>
    </div>
    <div class="third-form-container">
        <span class="details">Attach Policies</span>
        <hr />

        <table>
            <div class="left-container">
                <thead>
                    <tr>
                        <td>
                            {{ "Acl Attached" }}
                        </td>
                    </tr>
                </thead>
            </div>
            <div class="right-container">
                <tbody>
                    <tr>
                        <td class="acl-container">
                            <span> {{ stepData["aclAttached"] }}</span>
                        </td>
                    </tr>
                </tbody>
            </div>
        </table>
    </div>
</div>
