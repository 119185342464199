<div class="container">
    <div class="form-container">
        <div class="form-field">
            <div class="form-field-types">
                <div
                    class="form-field-heading tw-pt-1 tw-pb-4 tw-font-semibold"
                >
                    {{ formFieldHeading }}
                </div>
                <app-table-search
                    class="tw-flex"
                    [data]="customer"
                    (changed)="totalCustomer = $event"
                ></app-table-search>
                <ng-container *ngFor="let data of totalCustomer; index as i">
                    <div class="tw-flex tw-align-center index-item">
                        <mat-checkbox
                            (change)="toggleListing(data, $event.checked)"
                            [checked]="isChecked.get(data.id)"
                            color="accent"
                            >{{ data.customerName }}</mat-checkbox
                        >
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="form-area">
            <div class="form-name">
                <span>Selected Customers and Account Mapping</span>
            </div>
            <div class="form-drop-area">
                <div
                    *ngFor="let customerData of configuredCustomers; index as i"
                    class="form-drop-area-item tw-flex tw-justify-between tw-items-center"
                >
                    <div
                        class="form-drop-area-heading tw-flex-1 tw-font-medium"
                    >
                        {{ customerData.customerName }}
                    </div>
                    <div class="tw-w-3/5">
                        <app-form-generator
                            *ngIf="formgenInput"
                            [formGeneratorInput]="formgenInput"
                            (formGroupRef)="
                                formGroupMap.set(customerData.id, $event)
                            "
                            [intitalFormGroup]="
                                formGroupMap.has(customerData.id)
                                    ? formGroupMap.get(customerData.id)
                                    : null
                            "
                        ></app-form-generator>
                    </div>
                    <div class="action-buttons">
                        <div
                            class="delete-icon"
                            appIcon
                            [data]="deleteIcon"
                            (click)="toggleListing(customerData, false)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-info">
        <div class="info-icon">
            <i class="custom-fa-icon fa fa-info-circle"></i>
        </div>
        <div class="info-message">
            <p>
                Credentials will never be accessed by the customers. The
                customers will only view & create cases corresponding to mapped
                'Salesforce' Account.
            </p>
        </div>
    </div>

    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
