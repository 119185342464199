import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';
import { CustomValidator } from './../classes/CustomValidator';
export class GcpSpecialCharacterValidator extends CustomValidator {
    key = CustomValidatorKey.GCP_SPECIAL_CHARACTER;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const GCP_SPECIAL_CHARACTER = new RegExp(
            /^[^@*^#$.,:'"!;~`+=(){}?/][A-Z-a-z0-9_-]*$/
        );

        if (GCP_SPECIAL_CHARACTER.test(control.value)) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
