<div
    *ngIf="buttonGenInputs"
    class="btn-container {{ justifyContent ? 'full-width' : null }} {{
        extraClass
    }}"
    [style.justify-content]="justifyContent"
    [style.align-items]="alignItems"
>
    <ng-container *ngFor="let button of buttonGenInputs">
        <app-button-generator
            [ngClass]="button.hostClass"
            [buttonGenInput]="button"
            [disabled]="options && options.disable ? options.disable : false"
        >
        </app-button-generator>
    </ng-container>
</div>
