<app-table-generator
    style="margin-top: 8px"
    *ngIf="tableInput"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    [useMaterialIcons]="true"
    [isRowSelectable]="isRowSelectable.bind(this)"
></app-table-generator>

<ng-template #bulkDeleteList>
    <div class="delete-list">
        <li *ngFor="let data of selectedWidgets">
            {{ data.widgetName }}
        </li>
    </div>
</ng-template>

<ng-template #deleteFailedResponseView>
    <div
        class="attatched-widget"
        *ngIf="deleteErrorResponse && deleteErrorResponse.length"
    >
        <ng-container *ngFor="let data of deleteErrorResponse">
            <p>Customer Name</p>
            <li *ngFor="let data1 of data[objectKeys(data)[0]]">
                {{ data1.customer }}
            </li>

            <p>User Name</p>
            <ng-container *ngFor="let data1 of data[objectKeys(data)[0]]">
                <li *ngIf="data1.user">
                    {{ data1.user }}
                </li>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
