<div class="action-menu" *ngIf="!showSubAction">
    <h3>Actions</h3>
    <div class="actions">
        <div class="action" *ngFor="let action of actions; let i = index">
            {{ i + 1 }}. {{ Actions[action] }}
        </div>
    </div>
    <div class="action-handler">
        <input
            autofocus
            type="text"
            #actionField
            (keyup.enter)="handleAction(actions[actionField.value - 1])"
        />
        <button (click)="handleAction(actions[actionField.value - 1])">
            <i class="fas fa-play"></i>
        </button>
    </div>
</div>
<div class="sub-actions" *ngIf="showSubAction">
    <div class="go-back" (click)="goBack()">
        <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
    <h3 *ngIf="subActionHeading">{{ subActionHeading }}</h3>
    <p *ngIf="subActionInfo">{{ subActionInfo }}</p>
    <div class="action-handler" *ngIf="subActionsCallback">
        <input
            autofocus
            type="text"
            #subActionField
            (keyup.enter)="subActionsCallback(subActionField.value)"
        />
        <button (click)="subActionsCallback(subActionField.value)">
            <i class="fas fa-play"></i>
        </button>
    </div>
</div>
