import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFilterSelection } from 'src/app/shared/interfaces/filter/IFilterSelection';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
@Component({
    selector: 'app-add-tag-config-first-step',
    templateUrl: './add-tag-config-first-step.component.html',
    styleUrls: ['./add-tag-config-first-step.component.sass']
})
export class AddTagConfigFirstStepComponent implements OnInit {
    buttonGenInput: IButtonGeneratorInput;
    readonly STORE_KEY = 'selectedServices';
    data;
    loader = false;
    addConfigurationForm: FormGroup;
    filterData: IFilterData = null;
    keyValueSet = {};
    widgetRef: Widget;
    isUpdate = false;
    buttonRef: IButtonGeneratorInput;

    constructor(
        private modalData: ModalInjectedData,
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private userCache: UserDataCacheService,
        private filterCache: FilterCacheService,
        private notificationService: NotificationsService
    ) {
        this.data = modalData.data;
        this.widgetRef = this.data.widgetRef;
        this.addConfigurationForm = this.formBuilder.group({
            ruleSetName: [
                '',
                [
                    CustomValidators.noWhiteSpace.validatorFunction,
                    Validators.required
                ]
            ]
        });
    }

    ngOnInit() {
        const itemData = this.data.itemData;
        if (this.widgetRef.filterService.filtersData.has(this.STORE_KEY)) {
            this.widgetRef.filterService.filtersData.delete(this.STORE_KEY);
        }

        const widgetRef: Widget = this.data.widgetRef;

        const filterId =
            widgetRef.widgetData.widgetInfo.schedulingAutomation
                .widgetFilters[0].filters[0];
        if (this.data.purpose === ModalAction.UPDATE) {
            this.ruleSetName.disable();
            const filterSelection: IFilterSelection = {
                value: itemData.tagsConfig.applicableResources[
                    itemData.tagsConfigType
                ],
                filterInfo: {
                    selector: itemData.tagsConfigType
                } as IFilterInfo
            };
            const filterMap = new Map<string, IFilterSelection>();
            filterMap.set(filterId, filterSelection);
            this.isUpdate = true;
            this.ruleSetName.setValue(itemData.ruleSetName);
            this.keyValueSet = itemData.tagsConfig.keyValueSet || {};
            this.widgetRef.filterService.filtersData.set(
                this.STORE_KEY,
                filterMap
            );
            this.widgetRef.filterService.filtersDataTemp.set(
                this.STORE_KEY,
                filterMap
            );
        }
        this.getServiceDataList(filterId);
        this.generateButton();
    }
    generateButton() {
        this.buttonGenInput = {
            buttonName: this.isUpdate ? 'Update' : 'Save',
            buttonType: ButtonType.FLAT,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (buttonRef) => {
                this.buttonRef = buttonRef;
                this.saveButtonClicked();
            },
            showLoader: true
        };
    }
    getServiceDataList(filterId) {
        this.setServiceDataList(filterId);
    }

    setServiceDataList(filterId) {
        this.filterData = {
            filterId,
            associatedWidgets: []
        };
    }

    get ruleSetName() {
        return this.addConfigurationForm.get('ruleSetName');
    }

    changeInputData(event) {
        if (event.length) {
            this.keyValueSet = event[0];
        }
    }

    saveButtonClicked() {
        this.buttonRef.loader = true;
        if (this.isValid()) {
            const payload = this.preparePayload();
            if (this.isUpdate) {
                this.hitApi(
                    this.widgetRef.widgetData.widgetInfo.update,
                    payload
                );
            } else {
                this.hitApi(
                    this.widgetRef.widgetData.widgetInfo.create,
                    payload
                );
            }
        } else {
            return;
        }
    }

    isValid() {
        const filtersInput = this.widgetRef.filterService.prepareInput(
            this.data.widgetRef.widgetData.widgetInfo.schedulingAutomation
                .widgetFilters[0].filters,
            this.STORE_KEY
        );
        if (
            this.ruleSetName.invalid ||
            !Object.values(filtersInput)[0].length
        ) {
            this.buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                '',
                Messages.FILL_ALL_FIELDS
            );
            return false;
        }

        return true;
    }

    preparePayload() {
        const filters = this.widgetRef.filterService.prepareInput(
            this.data.widgetRef.widgetData.widgetInfo.schedulingAutomation
                .widgetFilters[0].filters,
            this.STORE_KEY
        );
        return {
            ruleSetName: this.ruleSetName.value,
            owners: [],
            tagsConfig: {
                keyValueSet: this.keyValueSet,
                applicableResources: filters
            },
            email: this.userCache.emailId
        };
    }

    hitApi(purpose, payload) {
        const apiArgs = Helper.generateHitApiConfig(purpose);
        apiArgs.input = payload;
        apiArgs.function = () => {
            this.modalService.closeModal(null, this.modalData.modalId);
            this.widgetRef.filterService.refreshWidget.next(
                new Set([this.modalService.modalData.sourceId])
            );
            this.notificationService.showSnackBar(
                this.isUpdate
                    ? 'Tag updated successfully'
                    : 'Tag created successfully'
            );
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.isUpdate
                    ? 'Error while updating tag'
                    : 'Error while creating tag'
            );
            this.buttonRef.loader = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
