<ng-container *ngIf="sideMenuType === SideMenuType.LEGACY">
    <div class="n-level-container">
        <div
            class="level1"
            *ngIf="
                sideNavigationData.get(currentLevel.getValue()) &&
                sideNavigationData.get(currentLevel.getValue())
                    .previousLevelData
            "
        >
            <ng-container
                *ngFor="
                    let item of sideNavigationData.get(currentLevel.getValue())
                        .previousLevelData
                "
            >
                <a
                    *ngIf="item.lastLevel"
                    class="group-icon touch-action-auto itemLevel{{
                        item.level
                    }}"
                    (click)="
                        LegacySideNavigation.lastLevelHandle(
                            item,
                            sideNavigationData,
                            firstLevelData,
                            activeAttributes,
                            selectedAttribues,
                            selectedPathIds,
                            leafNodesData,
                            traversalPurpose,
                            globalDataService
                        )
                    "
                    [matTooltip]="item['name'] ? item['name'] : null"
                    [class.active]="
                        selectedPathIds.includes(item['id']) ||
                        selectedAttribues.includes(item['id']) ||
                        (activeAttributes.has(item['level']) &&
                            activeAttributes.get(item['level']) &&
                            activeAttributes
                                .get(item['level'])
                                .includes(item['id']))
                    "
                >
                    <span class="group-image">
                        <i
                            *ngIf="item.iconType === IconType.FONTAWSOME"
                            class="{{ item.icon }}"
                        ></i>
                        <span
                            *ngIf="item.iconType === IconType.IMAGE"
                            class="image"
                        >
                            <img
                                src="{{ item.icon }}"
                                onerror="if (this.src != 'assets/error.png') this.src = 'assets/error.png';"
                                [alt]="item.name"
                            />
                        </span>
                        <span
                            *ngIf="item.iconType === IconType.SVG"
                            class="svg"
                            appIcon
                            [data]="
                                item.iconType
                                    | attributeToIconDirective: item.icon
                            "
                        >
                        </span>
                        <mat-icon *ngIf="item.iconType === IconType.MATICON">{{
                            item.icon
                        }}</mat-icon>
                    </span>
                    <span class="group-name">
                        {{ item.name }}
                    </span>
                </a>
                <span
                    [matTooltip]="item['name'] ? item['name'] : null"
                    class="group-icon touch-action-auto isExpandable {{
                        item['name']
                    }} itemLevel{{ item.level }}"
                    [class.active]="
                        selectedPathIds.includes(item['id']) ||
                        selectedAttribues.includes(item['id']) ||
                        (activeAttributes.has(item['level']) &&
                            activeAttributes.get(item['level']) &&
                            activeAttributes
                                .get(item['level'])
                                .includes(item['id']))
                    "
                    *ngIf="!item.lastLevel"
                    (click)="
                        LegacySideNavigation.goNextLevel(
                            item,
                            currentLevel,
                            sideNavigationData,
                            activeAttributes,
                            subMenuLoader,
                            sideMenuCache,
                            httpService,
                            ngZone
                        )
                    "
                >
                    <span class="group-image">
                        <i
                            *ngIf="item.iconType === IconType.FONTAWSOME"
                            class="{{ item.icon }}"
                        ></i>
                        <span
                            *ngIf="item.iconType === IconType.IMAGE"
                            class="image"
                        >
                            <img
                                src="{{ item.icon }}"
                                onerror="if (this.src != 'assets/error.png') this.src = 'assets/error.png';"
                                [alt]="item.name"
                            />
                        </span>
                        <span
                            *ngIf="item.iconType === IconType.SVG"
                            class="svg"
                            appIcon
                            [data]="
                                item.iconType
                                    | attributeToIconDirective: item.icon
                            "
                        ></span>
                        <mat-icon *ngIf="item.iconType === IconType.MATICON">{{
                            item.icon
                        }}</mat-icon>
                    </span>
                    <span class="group-name">
                        {{ item.name }}
                    </span>
                </span>
            </ng-container>
        </div>

        <div
            class="level2"
            *ngIf="
                (subMenuLoader | async) ||
                (sideNavigationData.get(currentLevel.getValue()) &&
                    sideNavigationData.get(currentLevel.getValue())
                        .currentLevelData)
            "
        >
            <ng-container
                *ngIf="
                    sideNavigationData.get(currentLevel.getValue()) &&
                    sideNavigationData.get(currentLevel.getValue())
                        .currentLevelData
                "
            >
                <div class="bread-crumbs">
                    <ng-container
                        *ngFor="
                            let item of [].constructor(
                                currentLevel.getValue() + 1
                            );
                            let level = index
                        "
                    >
                        <ng-container
                            *ngIf="
                                currentLevel.getValue() !== 1 &&
                                currentLevel.getValue() === level &&
                                sideNavigationData.has(level) &&
                                sideNavigationData.get(level).currentLevelName
                            "
                        >
                            <span class="level-back">
                                <i
                                    class="fas fa-chevron-left clickable touch-action-auto"
                                    (click)="
                                        LegacySideNavigation.goBackToLevel(
                                            level - 1,
                                            currentLevel,
                                            sideNavigationData
                                        )
                                    "
                                    [matTooltip]="
                                        sideNavigationData &&
                                        sideNavigationData.get(level - 1)
                                            ? sideNavigationData.get(level - 1)
                                                  .currentLevelName
                                            : null
                                    "
                                ></i>
                                <span
                                    class="back-level-name touch-action-auto"
                                    [matTooltip]="
                                        sideNavigationData &&
                                        sideNavigationData.get(level)
                                            ? sideNavigationData.get(level)
                                                  .currentLevelName
                                            : null
                                    "
                                >
                                    {{
                                        sideNavigationData.get(level)
                                            .currentLevelName
                                    }}
                                </span>
                            </span>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                currentLevel.getValue() === 1 &&
                                sideNavigationData.has(level) &&
                                sideNavigationData.get(level).currentLevelName
                            "
                        >
                            <span
                                class="level-back touch-action-auto"
                                [matTooltip]="
                                    sideNavigationData &&
                                    sideNavigationData.get(level)
                                        ? sideNavigationData.get(level)
                                              .currentLevelName
                                        : null
                                "
                            >
                                <span class="back-level-name">
                                    {{
                                        sideNavigationData.get(level)
                                            .currentLevelName
                                    }}
                                </span>
                            </span>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="menu-list">
                    <ng-container
                        *ngFor="
                            let item of sideNavigationData.get(
                                currentLevel.getValue()
                            ).currentLevelData
                        "
                    >
                        <a
                            *ngIf="item.lastLevel"
                            (click)="
                                LegacySideNavigation.lastLevelHandle(
                                    item,
                                    sideNavigationData,
                                    firstLevelData,
                                    activeAttributes,
                                    selectedAttribues,
                                    selectedPathIds,
                                    leafNodesData,
                                    traversalPurpose,
                                    globalDataService
                                )
                            "
                            class="group-icon touch-action-auto itemLevel{{
                                item.level
                            }}"
                            [class.active]="
                                selectedPathIds.includes(item['id']) ||
                                selectedAttribues.includes(item['id']) ||
                                (activeAttributes.has(item['level']) &&
                                    activeAttributes.get(item['level']) &&
                                    activeAttributes
                                        .get(item['level'])
                                        .includes(item['id']))
                            "
                            [matTooltip]="item['name'] ? item['name'] : null"
                        >
                            <span class="item-desc">
                                <i
                                    *ngIf="
                                        item.iconType === IconType.FONTAWSOME
                                    "
                                    class="{{ item.icon }}"
                                ></i>
                                <span
                                    *ngIf="item.iconType === IconType.IMAGE"
                                    class="image"
                                >
                                    <img
                                        src="{{ item.icon }}"
                                        onerror="if (this.src != 'assets/error.png') this.src = 'assets/error.png';"
                                        [alt]="item.name"
                                    />
                                </span>
                                <span
                                    *ngIf="item.iconType === IconType.SVG"
                                    class="svg"
                                    appIcon
                                    [data]="
                                        item.iconType
                                            | attributeToIconDirective
                                                : item.icon
                                    "
                                ></span>
                                <mat-icon
                                    *ngIf="item.iconType === IconType.MATICON"
                                    >{{ item.icon }}</mat-icon
                                >
                                <span class="text">
                                    {{ item.name }}
                                </span>
                            </span>
                        </a>
                        <span
                            [matTooltip]="item['name'] ? item['name'] : null"
                            class="group-icon touch-action-auto isExpandable {{
                                item['name']
                            }} itemLevel{{ item.level }}"
                            [class.active]="
                                selectedPathIds.includes(item['id']) ||
                                selectedAttribues.includes(item['id']) ||
                                (activeAttributes.has(item['level']) &&
                                    activeAttributes.get(item['level']) &&
                                    activeAttributes
                                        .get(item['level'])
                                        .includes(item['id']))
                            "
                            (click)="
                                LegacySideNavigation.goNextLevel(
                                    item,
                                    currentLevel,
                                    sideNavigationData,
                                    activeAttributes,
                                    subMenuLoader,
                                    sideMenuCache,
                                    httpService,
                                    ngZone
                                )
                            "
                            *ngIf="!item.lastLevel"
                        >
                            <span class="item-desc">
                                <i
                                    *ngIf="
                                        item.iconType === IconType.FONTAWSOME
                                    "
                                    class="{{ item.icon }}"
                                ></i>
                                <span
                                    *ngIf="item.iconType === IconType.IMAGE"
                                    class="image"
                                >
                                    <img
                                        src="{{ item.icon }}"
                                        onerror="if (this.src != 'assets/error.png') this.src = 'assets/error.png';"
                                        [alt]="item.name"
                                    />
                                </span>
                                <span
                                    *ngIf="item.iconType === IconType.SVG"
                                    class="svg"
                                    appIcon
                                    [data]="
                                        item.iconType
                                            | attributeToIconDirective
                                                : item.icon
                                    "
                                ></span>
                                <mat-icon
                                    *ngIf="item.iconType === IconType.MATICON"
                                    >{{ item.icon }}</mat-icon
                                >
                                <span class="text">
                                    {{ item.name }}
                                </span>
                                <span class="expandable">
                                    <i class="fas fa-chevron-right"></i>
                                </span>
                            </span>
                        </span>
                    </ng-container>
                </div>
            </ng-container>
            <div class="overlay" *ngIf="subMenuLoader | async">
                <div class="overlay-content">
                    <div
                        *ngIf="subMenuLoader | async"
                        appIcon
                        [data]="spinnerLoader"
                        class="overlay-loader"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="sideMenuType === SideMenuType.ICON">
    <div
        class="icon-sidenav-container"
        [class.sideNavWidgetSelector]="
            traversalPurpose === TraversalPurpose.WIDGET_SELECTOR
        "
        [ngClass]="currentLevel.getValue() | iconSideNavBackground"
    >
        <div
            *ngIf="currentLevel.getValue() === 1"
            [style.display]="
                traversalPurpose !== TraversalPurpose.SIDE_MENU ? 'none' : ''
            "
            class="item"
            (click)="sidenav.toggle()"
        >
            <span class="svg" appIcon [data]="crossIcon"> </span>
        </div>
        <div class="upper">
            <ng-container
                *ngFor="
                    let sideNav of sideNavigationData | keyvalue;
                    last as isLast;
                    let index = index
                "
            >
                <ng-container *ngIf="sideNav.value.currentLevelIconData">
                    <div
                        *ngIf="index === 1"
                        class="item cross-icon"
                        (click)="sidenav.toggle()"
                        [style.display]="
                            traversalPurpose !== TraversalPurpose.SIDE_MENU
                                ? 'none'
                                : ''
                        "
                    >
                        <span class="svg" appIcon [data]="crossIcon"> </span>
                    </div>
                    <div
                        class="item touch-action-auto {{
                            sideNav.value.currentLevelIconData['id']
                        }}"
                        [class.sideNavWithoutCrossIcon]="
                            traversalPurpose ===
                            TraversalPurpose.COMPARE_SIDE_MENU_1
                        "
                        [class.sideNavWidgetSelector]="
                            traversalPurpose ===
                            TraversalPurpose.WIDGET_SELECTOR
                        "
                        (click)="
                            IconSideNavigation.goBackToLevel(
                                sideNav.value.currentLevelIconData.level,
                                currentLevel,
                                sideNavigationData,
                                activeAttributes
                            )
                        "
                        [matTooltip]="
                            sideNav.value.currentLevelIconData['name']
                                ? sideNav.value.currentLevelIconData['name']
                                : null
                        "
                    >
                        <span
                            appIcon
                            [data]="
                                sideNav.value.currentLevelIconData.iconType
                                    | attributeToIconDirective
                                        : sideNav.value.currentLevelIconData
                                              .icon
                                        : null
                                        : true
                            "
                        >
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div
            class="lower"
            *ngIf="
                !(subMenuLoader | async) &&
                currentLevel &&
                currentLevel.getValue()
            "
        >
            <ng-container
                *ngFor="
                    let item of sideNavigationData.get(currentLevel.getValue())
                        .currentLevelData
                "
            >
                <div
                    *ngIf="!item.lastLevel"
                    class="{{
                        item.id
                    }} item current-level-icon touch-action-auto"
                    [class.sideNavWidgetSelector]="
                        traversalPurpose === TraversalPurpose.WIDGET_SELECTOR
                    "
                    (click)="
                        IconSideNavigation.goNextLevel(
                            item,
                            currentLevel,
                            sideNavigationData,
                            activeAttributes,
                            subMenuLoader,
                            sideMenuCache,
                            httpService,
                            ngZone
                        )
                    "
                    [matTooltip]="item['name'] ? item['name'] : null"
                    [class.active]="
                        selectedPathIds.includes(item['id']) ||
                        selectedAttribues.includes(item['id']) ||
                        (activeAttributes.has(item['level']) &&
                            activeAttributes.get(item['level']) &&
                            activeAttributes
                                .get(item['level'])
                                .includes(item['id']))
                    "
                    #iconParentContainer
                >
                    <span
                        appIcon
                        [data]="
                            item.iconType
                                | attributeToIconDirective
                                    : item.icon
                                    : null
                                    : iconParentContainer.classList.contains(
                                          'active'
                                      )
                        "
                    >
                    </span>
                </div>
                <a
                    *ngIf="item.lastLevel"
                    href="{{
                        item | redirectionUrlGenerator: sideNavigationData
                    }}"
                    class="{{
                        item.id
                    }} item  current-level-icon touch-action-auto"
                    [class.sideNavWidgetSelector]="
                        traversalPurpose === TraversalPurpose.WIDGET_SELECTOR
                    "
                    (click)="
                        IconSideNavigation.lastLevelHandle(
                            $event,
                            item,
                            sideNavigationData,
                            firstLevelData,
                            activeAttributes,
                            selectedAttribues,
                            selectedPathIds,
                            leafNodesData,
                            traversalPurpose,
                            globalDataService
                        )
                    "
                    [matTooltip]="item['name'] ? item['name'] : null"
                    [class.active]="
                        selectedPathIds.includes(item['id']) ||
                        selectedAttribues.includes(item['id']) ||
                        (activeAttributes.has(item['level']) &&
                            activeAttributes.get(item['level']) &&
                            activeAttributes
                                .get(item['level'])
                                .includes(item['id']))
                    "
                    #iconParentContainer
                >
                    <span
                        appIcon
                        [data]="
                            item.iconType
                                | attributeToIconDirective
                                    : item.icon
                                    : null
                                    : iconParentContainer.classList.contains(
                                          'active'
                                      )
                        "
                    >
                    </span>
                </a>
            </ng-container>
        </div>
        <div class="sidenav-loader" *ngIf="subMenuLoader | async">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sideMenuType === SideMenuType.DETAILED">
    <div class="console-side-nav-container">
        <div
            class="console-detailed-side-navigation-container"
            [class.centilyticsSideNav]="
                whiteLabelingService.whiteLableData.isCentilytics
            "
        >
            <div class="menu-top">
                <div
                    class="logo-container"
                    [class.underline]="
                        ((sideNavigationData.get(currentLevel.getValue()) &&
                            sideNavigationData.get(currentLevel.getValue())
                                .previousLevelData &&
                            !sideNavigationData.get(currentLevel.getValue())
                                .currentLevelData) ||
                            (subMenuLoader | async)) &&
                        traversalPurpose !==
                            TraversalPurpose.COMPARE_SIDE_MENU_1
                    "
                >
                    <div
                        class="logo"
                        [style.display]="
                            traversalPurpose ===
                            TraversalPurpose.COMPARE_SIDE_MENU_1
                                ? 'none'
                                : ''
                        "
                        appIcon
                        [data]="companyLogo"
                    ></div>
                    <div
                        *ngIf="sidenav"
                        class="menu-toggle clickable"
                        appIcon
                        [data]="
                            sidenav && sidenav.opened
                                ? toggleCloseIcon
                                : toggleOpenIcon
                        "
                        (click)="sidenav.toggle()"
                    ></div>
                </div>
            </div>
            <div
                #menuContent
                class="menu-content"
                [style.height]="
                    (currentLevel | async) !== 1 ? 'calc(100% - 50px)' : '100%'
                "
            >
                <div
                    class="menu-items"
                    *ngIf="
                        !(subMenuLoader | async) &&
                        sideNavigationData.get(currentLevel.getValue()) &&
                        sideNavigationData.get(currentLevel.getValue())
                            .previousLevelData &&
                        !sideNavigationData.get(currentLevel.getValue())
                            .currentLevelData
                    "
                >
                    <ng-container
                        *ngFor="
                            let item of sideNavigationData.get(
                                currentLevel.getValue()
                            ).previousLevelData;
                            first as isFirst;
                            last as isLast
                        "
                    >
                        <a
                            *ngIf="item.lastLevel"
                            class="menu-item touch-action-auto itemLevel{{
                                item.level
                            }}"
                            [class.first]="isFirst"
                            [class.last]="isLast"
                            href="{{
                                item
                                    | redirectionUrlGenerator
                                        : sideNavigationData
                            }}"
                            (click)="
                                DetailedSideNavigation.lastLevelHandle(
                                    $event,
                                    item,
                                    sideNavigationData,
                                    firstLevelData,
                                    activeAttributes,
                                    selectedAttribues,
                                    detailedMenuSelectedPathIds,
                                    detailedMenuSelectedPath,
                                    showLastLevel,
                                    leafNodesData,
                                    traversalPurpose,
                                    globalDataService
                                )
                            "
                            [matTooltip]="item['name'] ? item['name'] : null"
                            [class.active]="
                                detailedMenuSelectedPathIds
                                    .getValue()
                                    .includes(item['id']) ||
                                selectedAttribues.includes(item['id']) ||
                                (activeAttributes.has(item['level']) &&
                                    activeAttributes.get(item['level']) &&
                                    activeAttributes
                                        .get(item['level'])
                                        .includes(item['id']))
                            "
                            #iconParentContainer
                        >
                            <div
                                class="icon"
                                appIcon
                                [data]="
                                    item.iconType
                                        | attributeToIconDirective
                                            : item.icon
                                            : 'svg-white-fill'
                                            : iconParentContainer.classList.contains(
                                                  'active'
                                              )
                                "
                            ></div>
                            <span class="name">
                                {{ item.name }}
                            </span>
                            <span
                                [class.activeCircle]="
                                    detailedMenuSelectedPathIds
                                        .getValue()
                                        .includes(item['id']) ||
                                    selectedAttribues.includes(item['id']) ||
                                    (activeAttributes.has(item['level']) &&
                                        activeAttributes.get(item['level']) &&
                                        activeAttributes
                                            .get(item['level'])
                                            .includes(item['id']))
                                "
                            ></span>
                        </a>
                        <span
                            [matTooltip]="item['name'] ? item['name'] : null"
                            class="menu-item touch-action-auto isExpandable {{
                                item['name']
                            }} itemLevel{{ item.level }}"
                            [class.first]="isFirst"
                            [class.last]="isLast"
                            [class.active]="
                                selectedAttribues.includes(item['id']) ||
                                (!selectedAttribues.length &&
                                    detailedMenuSelectedPathIds
                                        .getValue()
                                        .includes(item['id']))
                            "
                            *ngIf="!item.lastLevel"
                            (click)="
                                DetailedSideNavigation.goNextLevel(
                                    item,
                                    currentLevel,
                                    showLastLevel,
                                    sideNavigationData,
                                    activeAttributes,
                                    subMenuLoader,
                                    lastLevelLoader,
                                    sideMenuCache,
                                    httpService,
                                    ngZone,
                                    changedDetectorRef,
                                    lastLevelData
                                )
                            "
                            #iconParentContainer
                        >
                            <div
                                class="icon"
                                appIcon
                                [data]="
                                    item.iconType
                                        | attributeToIconDirective
                                            : item.icon
                                            : (sideNavigationData.get(
                                                  currentLevel.getValue()
                                              ).currentLevelData
                                                  ? 'svg-white-fill'
                                                  : 'svg-black-fill')
                                            : iconParentContainer.classList.contains(
                                                  'active'
                                              )
                                "
                                [class.background-circle]="
                                    !sideNavigationData.get(
                                        currentLevel.getValue()
                                    ).currentLevelData
                                "
                            ></div>
                            <span class="name">
                                {{ item.name }}
                            </span>
                            <span
                                [class.activeCircle]="
                                    selectedAttribues.includes(item['id']) ||
                                    (!selectedAttribues.length &&
                                        detailedMenuSelectedPathIds
                                            .getValue()
                                            .includes(item['id']))
                                "
                            ></span>
                        </span>
                    </ng-container>
                </div>

                <ng-container
                    *ngIf="
                        !(subMenuLoader | async) &&
                        sideNavigationData.get(currentLevel.getValue()) &&
                        sideNavigationData.get(currentLevel.getValue())
                            .currentLevelData
                    "
                >
                    <ng-container
                        *ngIf="
                            sideNavigationData.get(currentLevel.getValue()) &&
                            sideNavigationData.get(currentLevel.getValue())
                                .currentLevelData
                        "
                    >
                        <div class="action">
                            <ng-container
                                *ngFor="
                                    let item of [].constructor(
                                        currentLevel.getValue() + 1
                                    );
                                    let level = index
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        currentLevel.getValue() !== 1 &&
                                        currentLevel.getValue() === level &&
                                        sideNavigationData.has(level) &&
                                        sideNavigationData.get(level)
                                            .currentLevelName
                                    "
                                >
                                    <div
                                        class="back-icon touch-action-auto"
                                        appIcon
                                        [data]="backArrow"
                                        (click)="
                                            DetailedSideNavigation.goBackToLevel(
                                                level - 1,
                                                currentLevel,
                                                sideNavigationData,
                                                showLastLevel,
                                                activeAttributes
                                            )
                                        "
                                        [matTooltip]="
                                            sideNavigationData &&
                                            sideNavigationData.get(level - 1)
                                                ? sideNavigationData.get(
                                                      level - 1
                                                  ).currentLevelName
                                                : null
                                        "
                                    ></div>
                                    <div
                                        class="back-level-name touch-action-auto"
                                        [matTooltip]="
                                            sideNavigationData &&
                                            sideNavigationData.get(level)
                                                ? sideNavigationData.get(level)
                                                      .currentLevelName
                                                : null
                                        "
                                    >
                                        {{
                                            level ===
                                            GlobalConfiguration.MAX_LAYERS_LIMIT -
                                                1
                                                ? sideNavigationData.get(
                                                      level - 1
                                                  ).currentLevelName
                                                : sideNavigationData.get(level)
                                                      .currentLevelName
                                        }}
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="
                                        currentLevel.getValue() === 1 &&
                                        sideNavigationData.has(level) &&
                                        sideNavigationData.get(level)
                                            .currentLevelName
                                    "
                                >
                                    <span
                                        class="level-back touch-action-auto"
                                        [matTooltip]="
                                            sideNavigationData &&
                                            sideNavigationData.get(level)
                                                ? sideNavigationData.get(level)
                                                      .currentLevelName
                                                : null
                                        "
                                    >
                                        <div
                                            class="back-icon"
                                            appIcon
                                            [data]="backArrow"
                                            (click)="initMenu()"
                                        ></div>
                                        <div class="back-level-name">
                                            {{
                                                sideNavigationData.get(level)
                                                    .currentLevelName
                                            }}
                                        </div>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="menu-items">
                            <ng-container
                                *ngFor="
                                    let item of sideNavigationData.get(
                                        currentLevel.getValue() ===
                                            GlobalConfiguration.MAX_LAYERS_LIMIT -
                                                1
                                            ? currentLevel.getValue() - 1
                                            : currentLevel.getValue()
                                    ).currentLevelData;
                                    first as isFirst;
                                    last as isLast
                                "
                            >
                                <a
                                    *ngIf="item.lastLevel"
                                    href="{{
                                        item
                                            | redirectionUrlGenerator
                                                : sideNavigationData
                                    }}"
                                    (click)="
                                        DetailedSideNavigation.lastLevelHandle(
                                            $event,
                                            item,
                                            sideNavigationData,
                                            firstLevelData,
                                            activeAttributes,
                                            selectedAttribues,
                                            detailedMenuSelectedPathIds,
                                            detailedMenuSelectedPath,
                                            showLastLevel,
                                            leafNodesData,
                                            traversalPurpose,
                                            globalDataService,
                                            shouldPauseNavigation.bind(this),
                                            detailedMenuSelectedPathIds
                                                .getValue()
                                                .includes(item['id']) ||
                                            selectedAttribues.includes(
                                                item['id']
                                            ) ||
                                            (activeAttributes.has(item['level']) &&
                                                activeAttributes.get(
                                                    item['level']
                                                ) &&
                                            activeAttributes
                                                .get(item['level'])
                                                .includes(item['id']))
                                        )
                                    "
                                    class="menu-item touch-action-auto itemLevel{{
                                        item.level
                                    }}"
                                    [class.first]="isFirst"
                                    [class.last]="isLast"
                                    [class.active]="
                                        detailedMenuSelectedPathIds
                                            .getValue()
                                            .includes(item['id']) ||
                                        selectedAttribues.includes(
                                            item['id']
                                        ) ||
                                        (activeAttributes.has(item['level']) &&
                                            activeAttributes.get(
                                                item['level']
                                            ) &&
                                            activeAttributes
                                                .get(item['level'])
                                                .includes(item['id']))
                                    "
                                    [matTooltip]="
                                        item['name'] ? item['name'] : null
                                    "
                                    #iconParentContainer
                                >
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="
                                            item.iconType
                                                | attributeToIconDirective
                                                    : item.icon
                                                    : 'svg-white-fill'
                                                    : iconParentContainer.classList.contains(
                                                          'active'
                                                      )
                                        "
                                    ></div>
                                    <span class="name">
                                        {{ item.name }}
                                    </span>
                                    <span
                                        [class.activeCircle]="
                                            detailedMenuSelectedPathIds
                                                .getValue()
                                                .includes(item['id']) ||
                                            selectedAttribues.includes(
                                                item['id']
                                            ) ||
                                            (activeAttributes.has(
                                                item['level']
                                            ) &&
                                                activeAttributes.get(
                                                    item['level']
                                                ) &&
                                                activeAttributes
                                                    .get(item['level'])
                                                    .includes(item['id']))
                                        "
                                    ></span>
                                </a>
                                <span
                                    [matTooltip]="
                                        item['name'] ? item['name'] : null
                                    "
                                    class="menu-item touch-action-auto isExpandable {{
                                        item['name']
                                    }} itemLevel{{ item.level }}"
                                    [class.active]="
                                        detailedMenuSelectedPathIds
                                            .getValue()
                                            .includes(item['id']) ||
                                        selectedAttribues.includes(
                                            item['id']
                                        ) ||
                                        (activeAttributes.has(item['level']) &&
                                            activeAttributes.get(
                                                item['level']
                                            ) &&
                                            activeAttributes
                                                .get(item['level'])
                                                .includes(item['id']))
                                    "
                                    [class.first]="isFirst"
                                    [class.last]="isLast"
                                    [class.last-level-styling]="
                                        showLastLevel.get(item['id']) &&
                                        activeAttributes.has(item['level']) &&
                                        activeAttributes.get(item['level']) &&
                                        activeAttributes
                                            .get(item['level'])
                                            .includes(item['id'])
                                    "
                                    (click)="
                                        DetailedSideNavigation.goNextLevel(
                                            item,
                                            currentLevel,
                                            showLastLevel,
                                            sideNavigationData,
                                            activeAttributes,
                                            subMenuLoader,
                                            lastLevelLoader,
                                            sideMenuCache,
                                            httpService,
                                            ngZone,
                                            changedDetectorRef,
                                            lastLevelData
                                        )
                                    "
                                    *ngIf="!item.lastLevel"
                                    #iconParentContainer
                                >
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="
                                            item.iconType
                                                | attributeToIconDirective
                                                    : item.icon
                                                    : 'svg-white-fill'
                                                    : iconParentContainer.classList.contains(
                                                          'active'
                                                      )
                                        "
                                    ></div>
                                    <span class="name">
                                        {{ item.name }}
                                    </span>
                                    <span
                                        [class.activeCircle]="
                                            !showLastLevel.get(item['id']) &&
                                            (detailedMenuSelectedPathIds
                                                .getValue()
                                                .includes(item['id']) ||
                                                selectedAttribues.includes(
                                                    item['id']
                                                ) ||
                                                (activeAttributes.has(
                                                    item['level']
                                                ) &&
                                                    activeAttributes.get(
                                                        item['level']
                                                    ) &&
                                                    activeAttributes
                                                        .get(item['level'])
                                                        .includes(item['id'])))
                                        "
                                    ></span>
                                    <span class="expandable">
                                        <i
                                            [ngClass]="
                                                currentLevel.getValue() ===
                                                    GlobalConfiguration.MAX_LAYERS_LIMIT -
                                                        1 &&
                                                activeAttributes.has(
                                                    item['level']
                                                ) &&
                                                activeAttributes.get(
                                                    item['level']
                                                ) &&
                                                activeAttributes
                                                    .get(item['level'])
                                                    .includes(item['id']) &&
                                                item.name ===
                                                    sideNavigationData.get(
                                                        currentLevel.getValue()
                                                    ).currentLevelName
                                                    ? showLastLevel.get(
                                                          item['id']
                                                      )
                                                        ? 'fas fa-chevron-down'
                                                        : 'fas fa-chevron-right'
                                                    : 'fas fa-chevron-right'
                                            "
                                        ></i>
                                    </span>
                                </span>
                                <div
                                    *ngIf="
                                        currentLevel.getValue() ===
                                            GlobalConfiguration.MAX_LAYERS_LIMIT -
                                                1 &&
                                        lastLevelData.get(item['name']) &&
                                        showLastLevel.get(item['id']) &&
                                        !lastLevelLoader.get(item['id']) &&
                                        activeAttributes.has(item['level']) &&
                                        activeAttributes.get(item['level']) &&
                                        activeAttributes
                                            .get(item['level'])
                                            .includes(item['id'])
                                    "
                                    class="menu-items-last-level"
                                >
                                    <ng-container
                                        *ngFor="
                                            let item of lastLevelData.get(
                                                item['name']
                                            );
                                            first as isFirst;
                                            last as isLast
                                        "
                                    >
                                        <a
                                            *ngIf="item.lastLevel"
                                            href="{{
                                                item
                                                    | redirectionUrlGenerator
                                                        : sideNavigationData
                                            }}"
                                            (click)="
                                                DetailedSideNavigation.lastLevelHandle(
                                                    $event,
                                                    item,
                                                    sideNavigationData,
                                                    firstLevelData,
                                                    activeAttributes,
                                                    selectedAttribues,
                                                    detailedMenuSelectedPathIds,
                                                    detailedMenuSelectedPath,
                                                    showLastLevel,
                                                    leafNodesData,
                                                    traversalPurpose,
                                                    globalDataService
                                                )
                                            "
                                            class="menu-item-last-level touch-action-auto itemLevel{{
                                                item.level
                                            }}"
                                            [class.first]="isFirst"
                                            [class.last]="isLast"
                                            [class.active]="
                                                detailedMenuSelectedPathIds
                                                    .getValue()
                                                    .includes(item['id']) ||
                                                selectedAttribues.includes(
                                                    item['id']
                                                ) ||
                                                (activeAttributes.has(
                                                    item['level']
                                                ) &&
                                                    activeAttributes.get(
                                                        item['level']
                                                    ) &&
                                                    activeAttributes
                                                        .get(item['level'])
                                                        .includes(item['id']))
                                            "
                                            [matTooltip]="
                                                item['name']
                                                    ? item['name']
                                                    : null
                                            "
                                            #iconParentContainer
                                        >
                                            <div class="last-level-content">
                                                <span class="icon-last-level">
                                                    <div
                                                        class="icon"
                                                        appIcon
                                                        [data]="
                                                            item.iconType
                                                                | attributeToIconDirective
                                                                    : item.icon
                                                                    : 'svg-white-fill'
                                                                    : iconParentContainer.classList.contains(
                                                                          'active'
                                                                      )
                                                        "
                                                    ></div>
                                                </span>
                                                <span class="name-last-level">
                                                    {{ item.name }}
                                                </span>
                                            </div>
                                            <span
                                                [class.activeCircle]="
                                                    detailedMenuSelectedPathIds
                                                        .getValue()
                                                        .includes(item['id']) ||
                                                    selectedAttribues.includes(
                                                        item['id']
                                                    ) ||
                                                    (activeAttributes.has(
                                                        item['level']
                                                    ) &&
                                                        activeAttributes.get(
                                                            item['level']
                                                        ) &&
                                                        activeAttributes
                                                            .get(item['level'])
                                                            .includes(
                                                                item['id']
                                                            ))
                                                "
                                            ></span>
                                        </a>
                                    </ng-container>
                                </div>
                                <div
                                    class="last-level-loader"
                                    *ngIf="
                                        lastLevelLoader.get(item.id) &&
                                        activeAttributes.has(item['level']) &&
                                        activeAttributes.get(item['level']) &&
                                        activeAttributes
                                            .get(item['level'])
                                            .includes(item['id']) &&
                                        item.level ===
                                            GlobalConfiguration.MAX_LAYERS_LIMIT -
                                                1 &&
                                        !item.lastLevel &&
                                        showLastLevel.get(item['id'])
                                    "
                                >
                                    <div
                                        class="overlay"
                                        *ngIf="lastLevelLoader.get(item.id)"
                                    >
                                        <div class="overlay-content">
                                            <div
                                                *ngIf="
                                                    lastLevelLoader.get(item.id)
                                                "
                                                appIcon
                                                [data]="spinnerLoader"
                                                class="overlay-loader"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="overlay" *ngIf="subMenuLoader | async">
                    <div class="overlay-content">
                        <div
                            *ngIf="subMenuLoader | async"
                            appIcon
                            [data]="spinnerLoader"
                            class="overlay-loader"
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="back-to-main-menu"
                *ngIf="(currentLevel | async) !== 1"
                (click)="initMenu(); subMenuLoader.next(false)"
            >
                Return To Main Menu
            </div>
        </div>
    </div>
</ng-container>
