<div class="assessee-home-container">
    <div class="main">
        <mat-sidenav-container [autosize]="true" class="main-home">
            <mat-sidenav
                class="sidenav"
                #sidenav
                (openedChange)="navigationChanged()"
                [opened]="(globalDataService.isHandset$ | async) === false"
                [mode]="
                    (globalDataService.isHandset$ | async) ? 'over' : 'side'
                "
            >
                <app-side-navigation [sidenav]="sidenav"></app-side-navigation>
            </mat-sidenav>
            <mat-sidenav-content>
                <div class="header">
                    <app-header
                        [filterMenu]="filterMenu"
                        [sidenav]="sidenav"
                    ></app-header>
                </div>
                <mat-sidenav-container [autosize]="true" class="home">
                    <mat-sidenav
                        class="filters-sidenav"
                        #filterMenu
                        [mode]="'over'"
                        (openedChange)="navigationChanged()"
                        position="end"
                        style="height: calc(100% - 50px)"
                        (opened)="filterService.filterMenuOpenState.next(true)"
                        (closed)="filterService.filterMenuOpenState.next(false)"
                        [autoFocus]="false"
                    >
                        <app-filters
                            [filterMenu]="filterMenu"
                            [filterStoreKey]="FilterStoreKey.WEBSITE_FILTERS"
                        ></app-filters>
                    </mat-sidenav>

                    <mat-sidenav-content>
                        <div class="main-content">
                            <div class="dynamic">
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </mat-sidenav-content>
                </mat-sidenav-container>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
