import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Directive({
    selector: '[appValueFormatter]'
})
export class ValueFormatterDirective implements OnInit {
    @Input('value') value;
    constructor(private element: ElementRef) {}
    ngOnInit(): void {
        let htmlString = `<ul>`;
        if (this.value instanceof Array) {
            htmlString += this.transformArray(this.value, true);
        } else if (this.value instanceof Object) {
            htmlString += this.transformObject(this.value, true);
        } else {
            htmlString += this.value;
        }
        htmlString += `</ul>`;
        $(this.element.nativeElement).html(htmlString);
    }
    transformObject(objectValue, withIndentation) {
        let htmlString = ``;
        if (withIndentation) {
            htmlString += `<ul class="listWithPadding">`;
        } else {
            htmlString += `<ul>`;
        }
        if (Object.keys(objectValue).length <= 0) {
            return '';
        }
        Object.keys(objectValue).forEach((key) => {
            htmlString += `<li><strong>` + key + ` :</strong> `;
            if (objectValue[key] instanceof Array) {
                htmlString += this.transformArray(objectValue[key], false);
                +`</li>`;
            } else if (objectValue[key] instanceof Object) {
                htmlString +=
                    this.transformObject(objectValue[key], true) + `</li>`;
            } else {
                htmlString += objectValue[key] + `</li>`;
            }
        });
        htmlString += `</ul>`;
        return htmlString;
    }
    transformArray(arrayValue, withIndentation) {
        let htmlString = ``;
        if (withIndentation) {
            htmlString += `<ul class="listWithPadding">`;
        } else {
            htmlString += `<ul>`;
        }
        if (arrayValue.length <= 0) {
            return '';
        }
        arrayValue.forEach((element) => {
            if (element instanceof Array) {
                htmlString += `<li>` + this.transformArray(element, true);
                +`</li>`;
            } else if (element instanceof Object) {
                htmlString +=
                    `<li>` + this.transformObject(element, false) + `</li>`;
            } else {
                htmlString += `<li>` + element + `</li>`;
            }
        });
        htmlString += `</ul>`;
        return htmlString;
    }
}
