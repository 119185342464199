import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IGaugeApiResponse } from 'src/app/shared/interfaces/api/portlets/IGaugeApiResponse';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { IRadarChartData } from '../../../../interfaces/graph/IGraphData';

@Component({
    selector: 'app-gauge',
    templateUrl: './gauge.component.html',
    styleUrls: ['./gauge.component.sass']
})
export class GaugeComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    ViewType = ViewType;
    warningSeverityColor;
    okSeverityColor;
    criticalSeverityColor;
    graphData: IGraphData;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
        this.graphColorInitialization();
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    graphColorInitialization() {
        this.okSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('okSeverityColor')
        );
        this.warningSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('warningSeverityColor')
        );
        this.criticalSeverityColor = Helper.convertToHexIfHsl(
            Helper.getCssVarValue('criticalSeverityColor')
        );
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);
        const visibleSections = new Set<ViewType>();
        visibleSections.add(ViewType.GRAPH);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data: IGaugeApiResponse) {
        if (!data) {
            return;
        }

        // for view modal
        this.widgetRef.apiResponse = data;
        if (data.dataMap && Object.keys(data.dataMap).length === 0) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        const requiredArrayOfObjects = [];

        Object.entries(data.dataMap).forEach(([module, value]) => {
            const requiredObj = {
                category: module,
                Ok: value['okCount'],
                Warning: value['warningCount'],
                Critical: value['criticalCount']
            };
            requiredArrayOfObjects.push(requiredObj);
        });
        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.loadGaugeMap(requiredArrayOfObjects);
            }, 0);
        });
        this.widgetRef.loadingData.next(false);
    }

    loadGaugeMap(requiredArrayOfObjects: IRadarChartData[]) {
        this.graphData = {
            graphType: GraphType.RADAR,
            chartData: requiredArrayOfObjects,
            seriesList: [
                { seriesName: 'Ok', valueX: 'Ok', color: this.okSeverityColor },
                {
                    seriesName: 'Warning',
                    valueX: 'Warning',
                    color: this.warningSeverityColor
                },
                {
                    seriesName: 'Critical',
                    valueX: 'Critical',
                    color: this.criticalSeverityColor
                }
            ]
        };
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
