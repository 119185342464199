import * as localforage from 'localforage';

export class AsyncIndexedDbCacheMain {
    private ignoredKeys = [];
    private _enableCaching;
    private _prefix: string;

    constructor(prefix: string, enableCaching: boolean = false) {
        this._prefix = prefix;
        this._enableCaching = enableCaching;
    }

    store(key: string, value: any, callback?): void {
        localforage.config({
            driver: localforage.INDEXEDDB,
            name: this._prefix
        });

        if (!this._enableCaching) {
            return null;
        }
        if (!this._prefix) {
            return null;
        }
        // const sizeInKb = Helper.getSizeInKb(value);
        // if (sizeInKb > 100) {
        //     return null;
        // }

        localforage.setItem(key, value).then(() => {
            if (callback) {
                callback();
            }
        });
    }

    fetch(key: string, callback): any {
        localforage.config({
            driver: localforage.INDEXEDDB,
            name: this._prefix
        });
        if (!this._prefix) {
            callback(null);
        }
        localforage.getItem(key).then((value) => {
            callback(value);
        });
    }

    deleteKey(key: string, callback?): void {
        localforage.config({
            driver: localforage.INDEXEDDB,
            name: this._prefix
        });
        if (!this._prefix) {
            return null;
        }
        localforage.removeItem(key).then(() => {
            if (callback) {
                callback();
            }
        });
    }

    deleteCollection(callback?): void {
        localforage.config({
            driver: localforage.INDEXEDDB,
            name: this._prefix
        });
        if (!this._prefix) {
            return null;
        }
        localforage.clear().then(() => {
            if (callback) {
                callback();
            }
        });
    }
}
