import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { CaChildIntegrationComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-child-level-integration/ca-child-integration/ca-child-integration.component';
import { CaIntegrationTableComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-integration-table/ca-integration-table/ca-integration-table.component';
import { CaIntegrationStepOneComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-parent-Integration-step-one/ca-integration-step-one/ca-integration-step-one.component';
import { CaIntegrationStepTwoComponent } from 'src/app/shared/components/modal-templates/integration/ca-sdm-modal/ca-sdm-parent-integration-step-two/ca-integration-step-two/ca-integration-step-two.component';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';

@Component({
    selector: 'app-ca-integration',
    templateUrl: './ca-integration.component.html',
    styleUrls: ['./ca-integration.component.sass']
})
export class CaIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isChildIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    parentIntegrationConfigureFormGenInput: IFormGeneratorInput = null;
    isConfigured: Boolean = false;
    response: IIntegrationApiResponse;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/ca-logo.png',
            buttons: [],
            imagePadding: 8
        };
    }
    generateButtons(response: IIntegrationApiResponse) {
        if (
            this.isOwnerIntegrated ||
            this.isChildIntegrated ||
            this.isParentIntegrated
        ) {
            if (
                !this.isOwnerIntegrated &&
                this.isParentIntegrated &&
                !this.isChildIntegrated
            ) {
                this.isConfigured = true;
                // Adding edit button
                this.integrationInput.buttons.push({
                    buttonName: 'Edit',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-wrench'
                    },

                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Edit CA-SDM',
                    function: () => {
                        this.openParentIntegrationModal(response, true);
                    }
                });

                // Adding delete button
                this.integrationInput.buttons.push({
                    buttonName: 'Delete',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },

                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Remove CA-SDM',
                    function: (modalId: Symbol) => {
                        // Delete integration
                        const args = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo.delete
                        );
                        args.input = {
                            parentIntegration: true,
                            childIntegration: false,
                            integrationType: IntegrationName.CASDM
                        };
                        args.function = (response) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `CA-SDM integration removed successfully`
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                            this.widgetRef.refreshWidget();
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error while removing ca-sdm'
                            );
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    executeFunctionAfterConfirmation: {
                        modalName: 'Remove CA-SDM Integration',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'far fa-trash-alt'
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: `Are you sure you want to remove ca-sdm ?`,
                        buttonText: 'Remove',
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    }
                });
            }

            if (
                this.isOwnerIntegrated &&
                !this.isParentIntegrated &&
                !this.isChildIntegrated
            ) {
                this.isConfigured = true;
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'group_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,

                    hoverText: 'Perform child-level integration',
                    function: () => {
                        this.openChildIntegrationModal(response);
                    }
                });

                //Adding Integrate as Parent button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'person_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Perform parent-level integration',
                    function: () => {
                        this.openParentIntegrationModal(response);
                    }
                });
            }
            if (
                this.isOwnerIntegrated &&
                this.isParentIntegrated &&
                !this.isChildIntegrated
            ) {
                this.isConfigured = true;
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'group_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,

                    hoverText: 'Perform child-level integration',
                    function: () => {
                        this.openChildIntegrationModal(response);
                    }
                });

                //Adding Integrate as Parent button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'person_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    disable: true,
                    buttonClass: 'disabled',
                    hoverText: 'Perform parent-level integration',
                    function: () => {}
                });

                //Adding list icon
                this.integrationInput.buttons.push({
                    buttonName: 'View Existing',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-bars '
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Existing Integrations',
                    function: () => {
                        this.openIntegrationsListingModal(response);
                    }
                });
            }
            if (
                this.isOwnerIntegrated &&
                !this.isParentIntegrated &&
                this.isChildIntegrated
            ) {
                this.isConfigured = true;
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'group_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    disable: true,
                    buttonClass: 'disabled',
                    hoverText: 'Perform child-level integration',
                    function: () => {}
                });

                //Adding Integrate as Parent button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'person_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Perform parent-level integration',
                    function: () => {
                        this.openParentIntegrationModal(response);
                    }
                });

                //Adding list icon
                this.integrationInput.buttons.push({
                    buttonName: 'View Existing',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-bars '
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Existing Integrations',
                    function: () => {
                        this.openIntegrationsListingModal(response);
                    }
                });
            }
            if (
                this.isOwnerIntegrated &&
                this.isParentIntegrated &&
                this.isChildIntegrated
            ) {
                this.isConfigured = true;
                // Adding create button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'group_add'
                    },
                    buttonClass: 'disabled',
                    disable: true,
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Perform child-level integration',
                    function: () => {}
                });

                //Adding Integrate as Parent button
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.MATICON,
                        class: 'person_add'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    buttonClass: 'disabled',
                    disable: true,
                    hoverText: 'Perform parent-level integration',
                    function: () => {}
                });

                //Adding list icon
                this.integrationInput.buttons.push({
                    buttonName: 'Integrate',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-bars '
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    hoverText: 'Existing Integrations',
                    function: () => {
                        this.openIntegrationsListingModal(response);
                    }
                });
            }
        }
    }
    onIntegrate() {
        this.openParentIntegrationModal(this.response);
    }
    openParentIntegrationModal(response, edit?) {
        const modalArgs: IModalData = {
            modalName: 'API User Credentials',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    resetModal: true,
                    stepData: {
                        componentToLoad: CaIntegrationStepOneComponent,
                        payload: {
                            data: {
                                response: response,
                                edit: edit ? true : false,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Configure Fields',
                    stepData: {
                        componentToLoad: CaIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                response: response,
                                edit: edit ? true : false,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepHeightVh: 90
                }
            ],
            modalWidthVw: 60,
            modalHeightPx: 420
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    openChildIntegrationModal(response) {
        const modalArgs: IModalData = {
            modalName: 'API User Credentials',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Credentials',
                    stepData: {
                        componentToLoad: CaChildIntegrationComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],

            modalWidthVw: 60,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    openIntegrationsListingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'Existing Integrations',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-ticket-alt'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Integrations List',
                    stepData: {
                        componentToLoad: CaIntegrationTableComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        this.response = response;
        this.isOwnerIntegrated = response['ownerIntegration'];
        this.isChildIntegrated = response['childIntegration'];
        this.isParentIntegrated = response['parentIntegration'];

        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
