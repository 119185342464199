<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="image-upload-container">
        <div
            class="image-upload clickable"
            (click)="$event.stopPropagation(); openImageUploadToModal(field)"
        >
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="required-marker mat-form-field-required-marker"
                >*</span
            >
            <div class="image-uploaded-preview">
                <ng-container
                    *ngIf="formGroup.get(field.name).value; else noDataPreview"
                >
                    <div class="image-to-preview">
                        <ng-container
                            *ngFor="
                                let image of formGroup.get(field.name).value;
                                last as isLast;
                                index as i
                            "
                        >
                            <div class="image-preview">
                                <div
                                    class="image"
                                    [class.with-label]="
                                        field.imageUpload.imageDetails &&
                                        field.imageUpload.imageDetails[i] &&
                                        field.imageUpload.imageDetails[i]
                                            .inputLabel
                                    "
                                >
                                    <img src="{{ image }}" />
                                </div>
                                <label
                                    *ngIf="
                                        field.imageUpload.imageDetails &&
                                        field.imageUpload.imageDetails[i]
                                    "
                                    >{{
                                        field.imageUpload.imageDetails[i]
                                            .inputLabel
                                    }}</label
                                >
                            </div>
                            <ng-container *ngIf="!isLast">
                                <div class="vertical-divider"></div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #noDataPreview>
                    <div class="no-image-to-preview">
                        {{
                            field.imageUpload.noImagePreviewMessage
                                ? field.imageUpload.noImagePreviewMessage
                                : "No Image Uploaded"
                        }}
                    </div>
                </ng-template>
            </div>
            <label>{{ field.label }}</label>
            <ng-container *ngIf="field.imageUpload.extraMessage">
                <p
                    class="extra-message"
                    [innerHTML]="field.imageUpload.extraMessage"
                ></p>
            </ng-container>
            <ng-container *ngIf="field.imageUpload.moreInfoButton">
                <div class="more-info-container">
                    <app-button-generator
                        [buttonGenInput]="field.imageUpload.moreInfoButton"
                    ></app-button-generator>
                </div>
            </ng-container>
        </div>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!--Field type one end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
