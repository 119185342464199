import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { Helper } from '../../classes/Helper';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { ModalType } from '../../enums/ModalType';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IConfirmationModal } from '../../interfaces/modal/IConfirmationModal';
import { IModalData } from '../../interfaces/modal/IModalData';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ConfirmationModalComponent } from '../modal-templates/confirmation-modal/confirmation-modal.component';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from './../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-tag-generator',
    templateUrl: './tag-generator.component.html',
    styleUrls: ['./tag-generator.component.sass']
})
export class TagGeneratorComponent implements OnInit, OnChanges {
    @Input() availableTags = [];
    @Input() selectedTags = [];
    @Input() extraClass? = '';
    @Input() showSaveButton = false;
    @Input() customContent: boolean = false;
    @Input() limitTags: number;
    @Input() showTagsList: boolean = true;
    @Output() tagsDataEmitter = new EventEmitter();
    @ViewChild('tagMenuTrigger') tagMenuTrigger: MatMenuTrigger;
    menuToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    showCreateTagBar: boolean = false;
    selectedColor = null;
    tagsSavedFlag = false;

    filteredAvailableTags = [];

    tagsData = [];
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross',
        extraClass: 'svg-white-fill'
    };

    topEllipse: IIcon = {
        type: IconType.SVG,
        class: 'editIcon'
    };
    buttonInputs: IButtonGeneratorInput[];

    constructor(
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.updateTagsData();
        this.menuToggle.subscribe((toggle) => {
            if (typeof toggle === 'boolean' && !toggle && !this.tagsSavedFlag) {
                this.tagsData = [...this.selectedTags];
            }
        });
        this.buttonInputs = [
            {
                buttonName: 'Save',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveTags(buttonRef);
                }
            },
            {
                buttonName: 'Cancel',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.closeTagGenerator();
                }
            }
        ];
    }

    ngOnChanges(change) {
        if (change.selectedTags) {
            this.selectedTags = [...change.selectedTags.currentValue];

            this.updateTagsData();
        }
    }

    updateTagsData() {
        if (this.selectedTags) {
            this.tagsData = [...this.selectedTags];
            this.cdr.detectChanges();
        }
    }
    handleTagInput(value) {
        if (value) {
            if (
                this.availableTags.find((tag) => tag.name === value) ||
                this.tagsData.find((tag) => tag.name === value)
            ) {
                this.showCreateTagBar = false;
            } else {
                this.showCreateTagBar = true;
            }
            this.updateSuggestedTags(value);
        } else {
            this.filteredAvailableTags = [];
            this.showCreateTagBar = false;
        }
    }

    addTag(tagInput) {
        const value = tagInput.value;
        if (value && value.trim().length > 2) {
            this.tagsData.push({
                colorCode: this.selectedColor,
                name: value.trim()
            });
            this.tagsSavedFlag = false;
            if (!this.showSaveButton) {
                this.tagsDataEmitter.emit({ tagsData: this.tagsData });
            }

            tagInput.value = '';
            this.filteredAvailableTags = [];
            this.showCreateTagBar = false;
        } else {
            this.notificationsService.showSnackBar(
                'Tag name must be minimum 3 characters long.',
                true
            );
        }
    }

    deleteTag(tagName, tagInput?) {
        if (!this.menuToggle.getValue() && this.showSaveButton) {
            const confirmationModalData: IConfirmationModal = {
                function: (modalId) => {
                    this.executeDeletion(tagName, tagInput);
                    this.modalService.closeModal(null, modalId);
                },

                modalName: 'Delete Tag',
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-trash'
                },
                contextIcon: {
                    extraClass: 'color-accent',
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-triangle'
                },
                confirmationMessage: `Are you sure you want to delete ${tagName} ?`,
                buttonText: 'Delete',
                buttonColorType: ButtonColorType.WARN,
                loaderOnExec: true
            };
            const modalData: IModalData = {
                modalName: confirmationModalData.modalName,
                modalIcon: confirmationModalData.modalIcon,
                sourceId: Symbol(),
                modalType: ModalType.MIDDLE,
                modalHeightVh: 30,
                modalWidthVw: 40,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: ConfirmationModalComponent,
                            payload: {
                                data: {
                                    function: confirmationModalData.function,
                                    params: confirmationModalData
                                }
                            }
                        },
                        stepName: 'Confirmation'
                    }
                ]
            };
            this.modalService.openModal(modalData);
        } else {
            this.executeDeletion(tagName, tagInput);
        }
    }

    executeDeletion(tagName, tagInput) {
        if (tagName) {
            const tagsIndex = this.tagsData.findIndex(
                (tag) => tag.name === tagName
            );

            if (tagsIndex > -1) {
                this.tagsData = Helper.removeIndexfromArray(
                    tagsIndex,
                    this.tagsData
                );
            }

            if (this.filteredAvailableTags.length && tagInput) {
                this.updateSuggestedTags(tagInput.value);
            }
            this.tagsSavedFlag = false;
            this.tagsDataEmitter.emit({
                tagsData: this.tagsData,
                tagToBeDeleted: tagName,
                deleteTags: this.menuToggle.getValue() ? false : true
            });
        }
    }

    addExistingTag(tagName, tagInput) {
        this.tagsData.push(
            this.availableTags.find((tag) => tag.name === tagName)
        );
        this.tagsSavedFlag = false;
        if (!this.showSaveButton) {
            this.tagsDataEmitter.emit({ tagsData: this.tagsData });
        }

        tagInput.value = '';
        this.filteredAvailableTags = [];
        this.showCreateTagBar = false;
    }

    updateSuggestedTags(value) {
        this.filteredAvailableTags = this.availableTags.filter(
            (availableTag) =>
                JSON.stringify(availableTag.name).includes(value.trim()) &&
                !this.tagsData.find((tag) => tag.name === availableTag.name)
        );
    }

    saveTags(buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = true;
        this.tagsSavedFlag = false;

        this.tagsDataEmitter.emit({
            tagsData: this.tagsData,
            callback: (data) => {
                this.saveTagsCallback(data, buttonRef);
            },
            deleteTags: true
        });
    }

    closeTagGenerator() {
        this.tagsData = [...this.selectedTags];
        this.tagMenuTrigger.closeMenu();
    }

    saveTagsCallback(status, buttonRef: IButtonGeneratorInput) {
        buttonRef.loader = false;
        if (status && status === 'SUCCESS') {
            this.tagsData = this.tagsData;
            this.tagMenuTrigger.closeMenu();
            this.tagsSavedFlag = true;
        }
    }
}
