import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { IIcon } from '../../../../interfaces/icon-data/IIcon';
import { BudgetingModalStep1Component } from '../../../modal-templates/budgeting-modal-steps/budgeting-modal-step1/budgeting-modal-step1.component';
import { BudgetingModalStep2Component } from '../../../modal-templates/budgeting-modal-steps/budgeting-modal-step2/budgeting-modal-step2.component';
import { BudgetingModalStep3Component } from '../../../modal-templates/budgeting-modal-steps/budgeting-modal-step3/budgeting-modal-step3.component';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { ICardsGeneratorInput } from './../../../../interfaces/card-generator/ICardsGeneratorInput';

@Component({
    selector: 'app-budget-listing',
    templateUrl: './budget-listing.component.html',
    styleUrls: ['./budget-listing.component.sass']
})
export class BudgetListingComponent implements OnInit {
    widgetRef: Widget;
    cardInput: ICardsGeneratorInput;
    thresholdConfig;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService,
        private bottomSheet: MatBottomSheet
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.initializeThresholdConfiguration();
    }

    initializeThresholdConfiguration() {
        this.thresholdConfig = {
            '0': {
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('positiveBudgetColor')
                )
            },
            '51': {
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('averageBudgetColor')
                )
            },
            '81': {
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('criticalBudgetColor')
                )
            }
        };
    }

    ngOnInit(): void {
        this.cardInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-money-check-alt'
            },
            cardTitle: {
                infoTitle: 'Budget Name',
                infoKey: 'budgetName'
            },
            selection: 'multiple',
            bulkActions: [
                {
                    buttonName: 'Delete',
                    function: (
                        buttonData: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openFullAccessModal();
                            return;
                        }
                        this.modalService.openConfirmationModal({
                            modalName: 'Delete Budgets',
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-trash'
                            },
                            contextIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-exclaimation-triangle'
                            },
                            confirmationMessage:
                                'Are you sure you want to delete selected budgets ?',
                            buttonColorType: ButtonColorType.WARN,
                            buttonText: 'Delete',
                            loaderOnExec: true,
                            function: (modalId: Symbol) => {
                                bulkLoader.next(true);
                                // Bulk Delete
                                const batchDeleteInput = {
                                    ids: selectedCards.map(
                                        (selCard) => selCard.id
                                    )
                                };
                                const apiArgs: IHitApi = {
                                    url: `${
                                        ApiUrls.BUDGET_ENDPOINT
                                    }/batch/monitoringCriteria/${this.monitoringCriteria()}`,
                                    intactUrl: `${ApiUrls.BUDGET_ENDPOINT}/batch/monitoringCriteria/{graph-type}`,
                                    input: batchDeleteInput,
                                    uniqueIdentity: Symbol(),
                                    requestType: RequestType.DELETE,
                                    config: {
                                        authorization:
                                            AuthorizationType.BEARER_TOKEN
                                    },
                                    function: (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Budgets deleted successfully'
                                        );
                                        bulkLoader.next(false);
                                        this.widgetRef.refreshWidget();
                                    },
                                    errorFunction: (error) => {
                                        bulkLoader.next(false);
                                        this.widgetRef.notificationsService.showSnackBar(
                                            error &&
                                                error.error &&
                                                error.error.message
                                                ? error.error.message
                                                : 'Error deleting budgets',
                                            true
                                        );
                                        this.widgetRef.refreshWidget();
                                    }
                                };
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                                new HitApi(
                                    apiArgs,
                                    this.widgetRef.httpService,
                                    this.widgetRef.ngZone
                                ).hitApi();
                            }
                        });
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash',
                        extraClass: 'color-warn'
                    },
                    buttonType: 'PRIMARY',
                    hoverText: 'Delete Selected Reports',
                    showLoader: false,
                    disable: this.widgetRef.lightState
                },
                {
                    buttonName: 'Enable',
                    function: (
                        buttonData: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openFullAccessModal();
                            return;
                        }
                        // Bulk Enable
                        bulkLoader.next(true);
                        const batchEnableInput = {
                            ids: selectedCards.map((selCard) => selCard.id)
                        };
                        const apiArgs: IHitApi = {
                            url: `${
                                ApiUrls.BUDGET_ENDPOINT
                            }/monitoringCriteria/${this.monitoringCriteria()}/batchEnable`,
                            intactUrl: `${ApiUrls.BUDGET_ENDPOINT}/monitoringCriteria/{graph-type}/batchEnable`,
                            input: batchEnableInput,
                            uniqueIdentity: Symbol(),
                            requestType: RequestType.PATCH,
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Budgets enabed successfully'
                                );
                                bulkLoader.next(false);
                                this.widgetRef.refreshWidget();
                            },
                            errorFunction: (error) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    error && error.error && error.error.message
                                        ? error.error.message
                                        : 'Error enabling budgets',
                                    true
                                );
                                this.widgetRef.refreshWidget();
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-success'
                    },
                    buttonType: 'PRIMARY',
                    hoverText: 'Enable Selected Reports',
                    showLoader: false,
                    disable: this.widgetRef.lightState
                },
                {
                    buttonName: 'Disable',
                    function: (
                        buttonData: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openFullAccessModal();
                            return;
                        }
                        // Bulk Disable
                        bulkLoader.next(true);
                        const batchDeleteInput = {
                            ids: selectedCards.map((selCard) => selCard.id)
                        };
                        const apiArgs: IHitApi = {
                            url: `${
                                ApiUrls.BUDGET_ENDPOINT
                            }/monitoringCriteria/${this.monitoringCriteria()}/batchDisable`,
                            intactUrl: `${ApiUrls.BUDGET_ENDPOINT}/monitoringCriteria/{graph-type}/batchDisable`,
                            input: batchDeleteInput,
                            uniqueIdentity: Symbol(),
                            requestType: RequestType.PATCH,
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Budgets disabled successfully'
                                );
                                this.widgetRef.refreshWidget();
                            },
                            errorFunction: (error) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    error && error.error && error.error.message
                                        ? error.error.message
                                        : 'Error disabling budgets',
                                    true
                                );
                                this.widgetRef.refreshWidget();
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-warn'
                    },
                    buttonType: 'PRIMARY',
                    hoverText: 'Enable Selected Reports',
                    showLoader: false,
                    disable: this.widgetRef.lightState
                }
            ],
            infoData: null,
            buttons: [
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.PRIMARY,
                    hoverText: 'New Budget',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: (buttonData: IButtonGeneratorInput) => {
                        if (buttonData.disable) {
                            this.openFullAccessModal();
                            return;
                        }
                        this.create();
                    },
                    disable: this.widgetRef.lightState
                }
            ],
            cardButtons: [
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Budget Info',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-info'
                    },
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        // Card info
                        this.showBudgetInfo(cardData);
                    },
                    showLoader: false
                },
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Edit Budget',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-edit'
                    },
                    function: (buttonData: IButtonGeneratorInput, cardData) => {
                        this.update(cardData);
                    },
                    showLoader: false
                },
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Budget',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        this.delete(cardData);
                    }
                }
            ],
            titleActions: [
                {
                    buttonName: '',
                    showLoader: true,
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        this.toggleScheduler(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off'
                    },
                    ngStyleRenderer: (cardData) => {
                        return { color: cardData.active ? 'green' : 'red' };
                    },
                    hoverTextRenderer: (cardData) => {
                        return cardData.active
                            ? 'Disable Budget'
                            : 'Enable Budget';
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            disableSelectAll: this.widgetRef.lightState,
            afterResponse: (response: any) => {
                if (this.widgetRef.lightState) {
                    if (response && response.length >= 1) {
                        this.cardInput.buttons.forEach(
                            (button) => (button.disable = true)
                        );
                    } else {
                        this.cardInput.buttons.forEach(
                            (button) => (button.disable = false)
                        );
                    }
                }
            }
        };
    }

    openFullAccessModal() {
        Helper.getFullAccess(
            this.widgetRef.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    showBudgetInfo(card) {
        this.bottomSheet.open(BudgetInfoBottomSheetComponent, {
            data: card
        });
    }

    create() {
        const modalInfo = {
            label: 'Create Budget',
            purpose: ModalAction.CREATE,
            portletType: this.widgetRef.widgetData.widgetInfo.portlet.type
        };
        this.setModalDataAndOpen(modalInfo, null);
    }

    update(item) {
        const modalInfo = {
            label: 'Edit Budget',
            purpose: ModalAction.UPDATE
        };
        this.setModalDataAndOpen(modalInfo, item);
    }

    setModalDataAndOpen(modalInfo, item) {
        this.widgetRef.filterService.filtersDataTemp.delete(
            FilterStoreKey.BUDGETING_FILTERS
        );
        this.widgetRef.filterService.filtersData.delete(
            FilterStoreKey.BUDGETING_FILTERS
        );
        const modalData: IModalData = {
            modalName: modalInfo.label,
            modalHeightVh: 98,
            modalWidthVw: 85,
            modalIcon: {
                class: 'fas fa-money-check-alt',
                type: IconType.FONTAWSOME
            } as IIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalPurpose: modalInfo.purpose,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: BudgetingModalStep1Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: item,
                                edit: item ? true : false
                            }
                        }
                    },
                    stepName: 'Widgets'
                },
                {
                    stepData: {
                        componentToLoad: BudgetingModalStep2Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: item,
                                edit: item ? true : false
                            }
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad: BudgetingModalStep3Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: item,
                                edit: item ? true : false
                            }
                        }
                    },
                    stepName: 'Configure Notification'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    delete(item) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Budget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage: 'Are you sure you want to delete budget ?',
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                this.deleteApi.call(this, item, modalId);
            }
        });
    }
    deleteApi(item, modalId: symbol) {
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteArgs.url = deleteArgs.url.replace('{budget-id}', item.id);
        deleteArgs.input = {};
        deleteArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                `Budget deleted successfully`
            );
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            this.widgetRef.notificationsService.showSnackBar(
                error && error.error && error.error.message
                    ? error.error.message
                    : 'Error deleting budget',
                true
            );
            this.modalService.closeModal(null, modalId);
        };
        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    monitoringCriteria = () => {
        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_TIMESERIES
        ) {
            return 'TIMESERIES';
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.BUDGET_PIE
        ) {
            return 'PIE';
        } else {
            return 'REGIONAL';
        }
    };

    toggleScheduler(item): void {
        let status = '';
        if (item.active) {
            status = 'disable';
        } else {
            status = 'enable';
        }

        const apiArgs: IHitApi = {
            url: `${
                ApiUrls.BUDGET_ENDPOINT
            }/monitoringCriteria/${this.monitoringCriteria()}/${status}/${
                item.id
            }`,
            intactUrl: `${ApiUrls.BUDGET_ENDPOINT}/monitoringCriteria/{graph-type}/{status}/{item-id}`,
            requestType: RequestType.PATCH,
            input: {},
            function: (response) => {
                item.active = !item.active;
            },
            errorFunction: (error) => {
                this.widgetRef.notificationsService.showSnackBar(
                    error && error.error && error.error.message
                        ? error.error.message
                        : 'Error ' + item.active
                        ? 'disabling'
                        : 'enabling' + ' budget',
                    true
                );
                this.widgetRef.refreshWidget();
            },
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                ignoreBaseUrl: false
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    gaugeValue(card) {
        const gaugeValue = (
            (card.currentBudget / card.totalBudget) *
            100
        ).toFixed(1);

        if (+gaugeValue > 999) {
            return 999;
        } else {
            return gaugeValue;
        }
    }

    showPrepend(card) {
        const gaugeValue = (
            (card.currentBudget / card.totalBudget) *
            100
        ).toFixed(1);

        if (+gaugeValue > 999) {
            return '>';
        } else {
            return null;
        }
    }
}

@Component({
    selector: 'budget-info-bottom-sheet',
    template: `
        <style>
            .budget-data-container {
                padding: 24px;
                padding-bottom: 48px;
            }
            .heading {
                background-color: var(--primaryColor);
                color: var(--secondaryTextColor);
                font-weight: 600;
                padding: 8px 16px;
                border-radius: 20px;
                justify-content: center;
                display: flex;
                align-items: center;
                margin-bottom: 26px;
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .data {
                display: flex;
                flex-direction: column;
            }
            .item {
                display: flex;
                padding: 6px 12px;
                margin-bottom: 8px;
            }
            .item-heading {
                flex: 3;
                color: var(--defaultTextColor);
                opacity: 67%;
                font-size: var(--fs400);
                display: flex;
                align-items: center;
            }
            .item-value {
                flex: 1;
                justify-content: flex-end;
                display: flex;
                font-size: var(--fs600);
                color: var(--defaultTextColor);
            }
        </style>

        <div class="tw-flex tw-flex-col budget-data-container">
            <div class="heading" [matTooltip]="cardData.budgetName">
                {{ cardData.budgetName }}
            </div>
            <div class="data">
                <div class="item">
                    <div class="item-heading">Total Budget</div>
                    <div class="item-value">
                        {{ cardData.totalBudget }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">
                        Consumed Budget<i
                            [matTooltip]="
                                'Discrepancy can be observed between the cost reflected in Cost Monitoring and Budgets due to custom exchange rate configuration'
                            "
                            style="margin-left: 8px;"
                            class="fas fa-info-circle"
                        ></i>
                    </div>
                    <div class="item-value">
                        {{ cardData.currentBudget }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">Deviation</div>
                    <div class="item-value">
                        {{ getDeviation() }}
                    </div>
                </div>
                <div class="item">
                    <div class="item-heading">Percentage Consumed</div>
                    <div class="item-value">
                        {{
                            (
                                (cardData.currentBudget /
                                    cardData.totalBudget) *
                                100
                            ).toFixed(1)
                        }}
                    </div>
                </div>
            </div>
        </div>
    `
})
export class BudgetInfoBottomSheetComponent {
    cardData;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<BudgetInfoBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) data
    ) {
        this.cardData = data;
    }

    getDeviation() {
        return Math.abs(
            +(this.cardData.totalBudget - this.cardData.currentBudget).toFixed(
                2
            )
        );
    }
}
