import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { Helper } from '../../classes/Helper';
import { HitApi } from '../../classes/HitApi';
import { Widget } from '../../classes/Widget';
import { ButtonType } from '../../enums/ButtonType';
import { FilterType } from '../../enums/FilterType';
import { FormState } from '../../enums/FormState';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
@Component({
    selector: 'app-custom-whitelisting-modal',
    templateUrl: './custom-whitelisting-modal.component.html',
    styleUrls: ['./custom-whitelisting-modal.component.sass']
})
export class CustomWhitelistingModalComponent implements OnInit {
    whitelistFormInput: IFormGeneratorInput = null;
    whitelistFormGroup: FormGroup;
    widgetRef: Widget;
    parentCallBackFunction: Function;
    selectedRowData: any;
    alreadyWhitelisted: any[];
    buttonGenInput: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        customClass: 'whitelisting-save-btn',
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            this.submit(buttonRef);
        }
    };
    constructor(private modalData: ModalInjectedData) {
        this.parentCallBackFunction = this.modalData.data['function'];
        this.widgetRef = this.modalData.data['widgetRef'];
        this.selectedRowData = this.modalData.data['rowData'];
        this.alreadyWhitelisted = this.modalData.data['alreadyWhitelisted'];
    }
    ngOnInit(): void {
        this.getCustomCompliance();
    }
    getCustomCompliance() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['securityCheckResponse'][
                'listCustomCompliance'
            ]
        );
        apiArgs.input = {};
        apiArgs.function = (response) => {
            if (response && response.length) {
                if (this.alreadyWhitelisted && this.alreadyWhitelisted.length) {
                    this.alreadyWhitelisted.map((each) => {
                        response.forEach((element) => {
                            if (each.id === element.id) {
                                element['disabled'] = each['globalWhiteListed'];
                            }
                        });
                    });
                    this.initForms(response);
                } else {
                    this.initForms(response);
                }
            } else {
                this.initForms(response);
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting compliances.'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    initForms(listData: any[]) {
        this.whitelistFormInput = {
            formName: 'customCompliance',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Custom Compliance',
                    placeholder: 'Select Custom Compliance',
                    name: 'customCompliance',
                    required: true,
                    listData: listData,
                    apiInput: { widgetId: this.widgetRef.widgetData.widgetId },
                    showLabel: true,
                    appearance: 'legacy',
                    showKey: 'value',
                    value:
                        this.alreadyWhitelisted &&
                        this.alreadyWhitelisted.length
                            ? this.alreadyWhitelisted.map((each) => each.id)
                            : []
                }
            ]
        };
    }
    submit(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        this.parentCallBackFunction(
            this.whitelistFormGroup.value,
            this.selectedRowData,
            this.modalData.modalId
        );
    }
}
