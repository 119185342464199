<div class="modal-expandable-table-extra-info-container">
    <div
        class="modal-heading"
        [ngStyle]="
            totalTabs >= 6
                ? { 'padding-right': '1rem' }
                : totalTabs <= 3
                ? { 'padding-right': '0.25rem' }
                : null
        "
    >
        <div class="tab-container">
            <ng-container
                *ngFor="
                    let head of getKeys(moreInfoData);
                    let length = count;
                    let index = index
                "
            >
                <ng-container>
                    <div
                        class="each-tab each-tab-parent clickable"
                        (click)="changeTabStyle(head)"
                        [ngClass]="
                            tabDescription === head ? 'active' : 'inactive'
                        "
                        matTooltip="{{ head }}"
                    >
                        <span class="selectedTab">
                            {{ head }}
                        </span>
                    </div>
                    <span *ngIf="index < totalTabs" class="separate"> </span>
                </ng-container>
            </ng-container>
        </div>
        <div class="shadow">
            <div
                class="tw-opacity-100 tw-cursor-pointer close"
                (click)="modalService.closeModal(null, modalData.modalId)"
                appIcon
                [data]="crossIcon"
            ></div>
        </div>
    </div>

    <div class="modal-description">
        <div class="sections" *ngIf="!containsModalData">
            <ng-container
                *ngFor="let heading of getKeys(moreInfoData); let i = index"
            >
                <div
                    class="section"
                    *ngIf="
                        moreInfoData[heading]['data'] &&
                        moreInfoData[heading]['data'].length > 0
                    "
                >
                    <div
                        class="section-heading clickable request-details"
                        id="{{ heading }}"
                        #tableHead
                        (click)="showOrNot(heading)"
                    >
                        <span>{{ heading }}</span>
                        <i
                            class="clpse clickable"
                            [ngClass]="
                                showData[i][heading] === true
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            "
                        ></i>
                    </div>

                    <div
                        class="section-description"
                        *ngIf="showData[i][heading] == true"
                    >
                        <table>
                            <div class="xd-container xd-container-1">
                                <thead>
                                    <tr>
                                        <th
                                            *ngFor="
                                                let thead of moreInfoData[
                                                    heading
                                                ]['columns']
                                            "
                                        >
                                            {{ thead }}
                                        </th>
                                    </tr>
                                </thead>
                            </div>
                            <div class="xd-container xd-container-2">
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let trowObj of moreInfoData[
                                                heading
                                            ]['data'];
                                            let i = index
                                        "
                                    >
                                        <td
                                            *ngFor="
                                                let tdColumn of moreInfoData[
                                                    heading
                                                ]['columns']
                                            "
                                        >
                                            {{
                                                transformValue(
                                                    moreInfoData[heading][
                                                        "data"
                                                    ][i][tdColumn]
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                    </div>
                </div>
                <div class="section" *ngIf="heading === 'Resources'">
                    <div
                        (click)="showOrNot(heading)"
                        class="section-heading clickable"
                        id="{{ heading }}"
                        #tableHead
                    >
                        <span>{{ heading }}</span>
                        <i
                            class="clpse clickable"
                            [ngClass]="
                                showData[i][heading] === true
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            "
                        ></i>
                    </div>

                    <div
                        class="section-description"
                        *ngIf="showData[i][heading] == true"
                    >
                        <app-terraform-preview-resources-modal
                            [previewData]="resourcesData"
                            [widgetRef]="widgetRef"
                        ></app-terraform-preview-resources-modal>
                    </div>
                </div>
                <div class="section" *ngIf="heading === 'Logs'">
                    <div
                        (click)="showOrNot(heading)"
                        class="section-heading clickable"
                        id="{{ heading }}"
                        #tableHead
                    >
                        <span>{{ heading }}</span>
                        <i
                            class="clpse clickable"
                            [ngClass]="
                                showData[i][heading] === true
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            "
                        ></i>
                    </div>

                    <div
                        class="section-description logs-container"
                        *ngIf="showData[i][heading] == true"
                    >
                        <pre>{{ logs }}</pre>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
