<section
    *ngIf="mfaFormGenInputs"
    class="tw-border-t-0 tw-rounded tw-overflow-hidden tw-my-2 tw-mx-0"
>
    <div
        class="
            tw-flex
            tw-py-3
            tw-px-4
            tw-text-default
            tw-border-b
            tw-border-solid
            tw-border-background-color
        "
    >
        <div class="tw-flex tw-items-center">Multi Factor Authentication</div>
        <div class="tw-ml-auto tw-cursor-pointer">
            <div
                *ngIf="
                    mfaFormGenInputs.state === FormState.IDLE;
                    then edit;
                    else idle
                "
            ></div>

            <ng-template #edit>
                <div
                    [hidden]="!mfaState"
                    [class.disabled]="verificationSuccessful"
                    (click)="changeState(FormState.CREATE)"
                >
                    <i class="fas fa-edit"> </i>
                </div>
            </ng-template>
            <ng-template #idle>
                <div [hidden]="!mfaState" (click)="changeState(FormState.IDLE)">
                    <i class="fas fa-times"></i>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="tw-w-full">
        <app-form-generator
            *ngIf="mfaFormGenInputs"
            [formGeneratorInput]="mfaFormGenInputs"
            (formGroupRef)="mfaFormGroup = $event"
            (resetFormFunction)="resetMfaInfo = $event"
        ></app-form-generator>
        <div class="tw-flex-1 tw-flex tw-flex-col">
            <div
                *ngIf="verificationSuccessful"
                class="tw-py-0 tw-px-5 tw-text-neutral"
            >
                <span>OTP verification successful.</span>
            </div>
        </div>

        <div
            [hidden]="mfaFormGenInputs.state === FormState.IDLE"
            class="tw-pt-0 tw-px-5 tw-pb-2"
        >
            <app-button-generator
                [buttonGenInput]="sendOTPButtonGenInput"
                (buttonRef)="otpVerification()"
            ></app-button-generator>
        </div>
    </div>
    <div
        *ngIf="!mfaState || verificationSuccessful"
        class="
            tw-flex
            tw-py-3
            tw-px-4
            tw-text-default
            tw-border-b
            tw-border-solid
            tw-border-background-color
        "
    >
        <div class="tw-flex tw-items-center">Credentials</div>
    </div>
    <div *ngIf="!mfaState || verificationSuccessful" class="tw-w-full">
        <div
            *ngIf="
                credentialFormGenInputs.state === FormState.IDLE;
                then idle1;
                else edit1
            "
        ></div>
        <ng-template #idle1>
            <app-form-generator
                *ngIf="credentialFormGenInputs"
                [formGeneratorInput]="credentialFormGenInputs"
            ></app-form-generator>
        </ng-template>
        <ng-template #edit1>
            <app-form-generator
                *ngIf="passwordFormGenInputs"
                [formGeneratorInput]="passwordFormGenInputs"
                (formGroupRef)="passwordFormGroup = $event"
            ></app-form-generator>

            <div class="tw-flex tw-my-2 tw-mx-3 tw-justify-end">
                <app-button-generator
                    class="tw-ml-auto"
                    [buttonGenInput]="saveButtonGenInput"
                    (buttonRef)="enableMfa($event)"
                ></app-button-generator>
            </div>
        </ng-template>
    </div>
</section>
