<div
    class="tw-flex tw-flex-col tw-relative modal-expandable-table-extra-info-container"
>
    <div
        class="tw-flex tw-text-fs-400 tw-fixed tw-items-start tw-justify-start tw-h-16 modal-heading"
    >
        <div
            class="tw-flex tw-justify-evenly tw-items-center tw-text-fs-600 tw-font-medium tw-h-14 tw-overflow-auto tab-container"
        >
            <ng-container
                *ngFor="
                    let head of getKeys(expandableTableExtraInfoData);
                    let length = count;
                    let index = index
                "
            >
                <ng-container
                    *ngIf="
                        expandableTableExtraInfoData[head]['data'] &&
                        expandableTableExtraInfoData[head]['data'].length > 0
                    "
                >
                    <div
                        class="tw-flex tw-h-12 tw-items-center tw-justify-center tw-flex-1 each-tab each-tab-parent clickable"
                        (click)="changeTabStyle(head, index)"
                        [ngClass]="
                            tabDescription === head ? 'active' : 'inactive'
                        "
                        matTooltip="{{ head }}"
                    >
                        <span
                            class="tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden tw-text-fs-400 selectedTab"
                        >
                            {{ head }}
                        </span>
                    </div>
                    <span
                        *ngIf="index < totalTabs"
                        class="tw-h-8 tw-mt-1 separate"
                    >
                    </span>
                </ng-container>
            </ng-container>
        </div>
        <div class="shadow">
            <div
                class="tw-opacity-100 tw-cursor-pointer close"
                (click)="modalService.closeModal(null, modalData.modalId)"
                appIcon
                [data]="crossIcon"
            ></div>
        </div>
    </div>

    <div class="modal-description">
        <table *ngIf="!containsModalData">
            <tr
                class="tw-flex tw-text-fs-400 tw-my-2 last-child info-item"
                *ngFor="let key of getKeys(expandableTableExtraInfoData)"
            >
                <td class="tw-font-semibold tw-m-2 tw-flex-1 info-item-heading">
                    {{ key | camelCaseToNormalName }}
                </td>
                <td
                    class="tw-break-all tw-flex-f3 info-item-value"
                    appValueFormatter
                    [value]="expandableTableExtraInfoData[key]"
                ></td>
            </tr>
        </table>

        <div class="tw-pt-16 sections" *ngIf="containsModalData">
            <ng-container
                *ngFor="
                    let heading of getKeys(expandableTableExtraInfoData);
                    let i = index
                "
            >
                <div
                    class="section"
                    *ngIf="
                        expandableTableExtraInfoData[heading]['data'] &&
                        expandableTableExtraInfoData[heading]['data'].length > 0
                    "
                    #verticalTabContent
                >
                    <div
                        class="tw-flex tw-justify-between tw-h-10 tw-items-center tw-font-semibold tw-p-5 tw-mr-1 section-heading"
                        id="{{ heading }}"
                        #tableHead
                    >
                        <span>{{ heading }}</span>
                        <i
                            class="tw-flex tw-justify-end tw-items-center clickable"
                            (click)="showOrNot(heading)"
                            [ngClass]="
                                showData[i][heading] === true
                                    ? 'fas fa-angle-up'
                                    : 'fas fa-angle-down'
                            "
                        ></i>
                    </div>

                    <div class="section-description">
                        <table
                            class="tw-flex"
                            *ngIf="showData[i][heading] === true"
                        >
                            <div
                                class="tw-overflow-auto tw-flex-1 xd-container-1"
                            >
                                <thead class="tw-flex tw-flex-col">
                                    <tr class="tw-flex tw-flex-col tw-flex-1">
                                        <th
                                            class="tw-flex-1 tw-p-2 tw-whitespace-pre tw-text-fs-200 tw-text-left tw-font-semibold"
                                            *ngFor="
                                                let thead of expandableTableExtraInfoData[
                                                    heading
                                                ]['columns']
                                            "
                                        >
                                            {{ thead }}
                                        </th>
                                    </tr>
                                </thead>
                            </div>
                            <div class="tw-overflow-auto xd-container-2">
                                <tbody class="tw-flex">
                                    <tr
                                        class="tw-flex tw-flex-col tw-flex-1"
                                        *ngFor="
                                            let trowObj of expandableTableExtraInfoData[
                                                heading
                                            ]['data'];
                                            let i = index
                                        "
                                    >
                                        <td
                                            class="tw-flex-1 tw-p-2 tw-whitespace-pre tw-text-fs-200 tw-text-left mt-1"
                                            *ngFor="
                                                let tdColumn of expandableTableExtraInfoData[
                                                    heading
                                                ]['columns']
                                            "
                                            [ngClass]="
                                                tableKeys && tableKeys.length
                                                    ? tableKeys.includes(
                                                          tdColumn.toLowerCase()
                                                      )
                                                        ? ''
                                                        : 'getFullAccessState'
                                                    : ''
                                            "
                                        >
                                            {{
                                                transformValue(
                                                    expandableTableExtraInfoData[
                                                        heading
                                                    ]["data"][i][tdColumn]
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
