import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AdvancedAutomationModalCongifurationComponent } from '../../../modal-templates/advanced-automation-modal-steps/advanced-automation-modal-congifuration/advanced-automation-modal-congifuration.component';
import { AdvancedAutomationModalFiltersComponent } from '../../../modal-templates/advanced-automation-modal-steps/advanced-automation-modal-filters/advanced-automation-modal-filters.component';
import { AdvancedAutomationModalSchedulerComponent } from '../../../modal-templates/advanced-automation-modal-steps/advanced-automation-modal-scheduler/advanced-automation-modal-scheduler.component';
import { ICardsGeneratorInput } from './../../../../interfaces/card-generator/ICardsGeneratorInput';
import { TaggingAutomationCacheService } from './../../../../services/cache/tagging-automation-cache/tagging-automation-cache.service';

@Component({
    selector: 'app-advanced-automation',
    templateUrl: './advanced-automation.component.html',
    styleUrls: ['./advanced-automation.component.sass']
})
export class AdvancedAutomationComponent {
    widgetRef: Widget;
    cardInput: ICardsGeneratorInput;
    lightState: boolean;
    cardbuttonsToEnable = new Map();
    afterResponseData;
    cachingDetails: string;
    automationPlayWidgets = null;
    indexOfButton: number;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef,
        private automationPlayCache: TaggingAutomationCacheService,
        private globalDataService: GlobalDataService
    ) {
        this.widgetRef = widgetData.widgetRef;
        if (this.widgetRef.lightState) {
            this.lightState = true;
        }
    }

    ngOnInit(): void {
        this.cardInput = {
            noDataMessage: 'No rules available',
            cardTitle: {
                infoTitle: 'Automation Name',
                infoKey: 'automationName'
            },
            infoData: [
                {
                    infoTitle: 'Created By',
                    infoKey: 'createdBy|email'
                },
                {
                    infoTitle: 'Next Scheduled Date',
                    infoKey: 'nextSchedulerDate'
                }
            ],
            buttons: [
                {
                    buttonName: 'New Rule',

                    function: (buttonData: IButtonData) => {
                        if (buttonData.disable) {
                            this.openGetFullAcess();
                            return;
                        }
                        this.create();
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.CIRCLE,
                    hoverText: 'Create New Rule',
                    disable: this.lightState
                }
            ],
            cardButtons: [
                {
                    buttonName: 'Edit Rule',
                    function: (buttonData: IButtonData, cardData) => {
                        if (buttonData.disable) {
                            this.openGetFullAcess();
                            return;
                        }
                        this.update(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-edit'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Edit Rule',
                    showLoader: false,
                    disable: this.lightState
                },
                {
                    buttonName: 'Delete Rule',
                    function: (buttonData: IButtonData, cardData) => {
                        if (buttonData.disable) {
                            this.openGetFullAcess();
                            return;
                        }
                        this.delete(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Rule',
                    showLoader: false,
                    disable: this.lightState
                },
                {
                    buttonName: 'Play',
                    function: (buttonData: IButtonData, cardData) => {
                        if (this.lightState) {
                            this.getCachingDetails(this.afterResponseData);

                            if (!this.automationPlayCache.userDetails) {
                                this.automationPlayWidgets = {};
                                this.automationPlayWidgets[
                                    this.cachingDetails
                                ] = this.widgetRef.widgetData.widgetId;

                                this.automationPlayCache.userDetails =
                                    JSON.stringify(this.automationPlayWidgets);
                            } else if (this.automationPlayCache.userDetails) {
                                this.automationPlayWidgets = JSON.parse(
                                    this.automationPlayCache.userDetails
                                );
                                if (
                                    this.cachingDetails in
                                    this.automationPlayWidgets
                                ) {
                                    this.openGetFullAcess();

                                    return;
                                }
                                this.automationPlayWidgets[
                                    this.cachingDetails
                                ] = this.widgetRef.widgetData.widgetId;
                                this.automationPlayCache.userDetails =
                                    JSON.stringify(this.automationPlayWidgets);
                            }
                        }
                        this.play(cardData, buttonData);
                        buttonData.loader = true;
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-play'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Play',
                    showLoader: true
                }
            ],
            titleActions: [
                {
                    buttonName: 'Enable Disable Rule',
                    showLoader: true,
                    function: (buttonRef: IButtonData, cardData) => {
                        if (buttonRef.disable) {
                            this.openGetFullAcess();
                            return;
                        }
                        // buttonRef.loader = true;

                        this.toggleScheduler(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off'
                    },
                    ngStyleRenderer: (cardData) => {
                        return { color: cardData.active ? 'green' : 'red' };
                    },
                    hoverTextRenderer: (cardData) => {
                        return cardData.active ? 'Disable Rule' : 'Enable Rule';
                    },
                    disable: this.lightState
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            bulkActions: [
                {
                    buttonName: 'Delete',
                    disable: this.lightState,
                    function: (
                        buttonData: IButtonData,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAcess();
                            return;
                        }
                        this.modalService.openConfirmationModal({
                            modalName: 'Delete Rules',
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-trash'
                            },
                            contextIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-exclaimation-triangle'
                            },
                            confirmationMessage:
                                'Are you sure you want to delete selected rules ?',
                            buttonColorType: ButtonColorType.WARN,
                            buttonText: 'Delete',
                            loaderOnExec: true,
                            function: (modalId: Symbol) => {
                                bulkLoader.next(true);
                                // Bulk Delete
                                const batchDeleteInput = {
                                    ids: selectedCards.map(
                                        (selCard) => selCard.id
                                    )
                                };
                                const deleteArgs = Helper.generateHitApiConfig(
                                    this.widgetRef.widgetData.widgetInfo[
                                        'batchDelete'
                                    ]
                                );
                                deleteArgs.input = batchDeleteInput;
                                deleteArgs.function = (response) => {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        'Rules deleted successfully'
                                    );
                                    bulkLoader.next(false);
                                    this.widgetRef.refreshWidget();
                                };
                                deleteArgs.errorFunction = (error) => {
                                    bulkLoader.next(false);
                                    this.widgetRef.notificationsService.showSnackBar(
                                        error &&
                                            error.error &&
                                            error.error.message
                                            ? error.error.message
                                            : 'Error deleting rules',
                                        true
                                    );
                                    this.widgetRef.refreshWidget();
                                };
                                new HitApi(
                                    deleteArgs,
                                    this.widgetRef.httpService,
                                    this.widgetRef.ngZone
                                ).hitApi();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            }
                        });
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash',
                        extraClass: 'color-warn'
                    },
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Delete Selected Reports',
                    showLoader: false
                }
            ],
            selection: 'multiple',
            afterResponse: (response) => {
                this.afterResponseData = response;

                if (this.lightState) {
                    this.indexOfButton = this.searchCardButtonName(
                        'buttons',
                        'New Rule'
                    );
                    if (this.indexOfButton !== -1) {
                        this.cardInput.buttons[this.indexOfButton].disable =
                            this.lightState;
                    }
                    if (!response[0]) {
                        this.enableCardButtons(this.cardInput);
                    } else {
                        this.getCachingDetails(response);
                        if (
                            this.automationPlayCache.userDetails &&
                            this.cachingDetails in
                                JSON.parse(this.automationPlayCache.userDetails)
                        ) {
                            this.indexOfButton = this.searchCardButtonName(
                                'cardButtons',
                                'Play'
                            );

                            if (this.indexOfButton !== -1) {
                                this.cardInput.cardButtons[
                                    this.indexOfButton
                                ].disable = true;
                            }
                            this.cdr.detectChanges();
                        }

                        this.cardbuttonsToEnable.set('cardButtons', [
                            'Delete Rule',
                            'Edit Rule'
                        ]);
                        this.cardbuttonsToEnable.set('titleActions', []);
                        this.cardbuttonsToEnable.set('bulkActions', []);

                        this.enableCardButtons(
                            this.cardInput,
                            this.cardbuttonsToEnable
                        );
                    }
                }
            }
        };
    }
    getCachingDetails(response) {
        if (
            response[0] &&
            response[0]['widgetId'] &&
            response[0]['createdBy']['email'] &&
            response[0]['automationName']
        ) {
            this.cachingDetails =
                response[0]['widgetId'] +
                this.globalDataService.selectedView +
                response[0]['createdBy']['email'] +
                response[0]['automationName'];
        }
    }
    searchCardButtonName(buttonType, buttonName: string) {
        const indexOfButton = this.cardInput[buttonType].findIndex(
            (cardDetails) => {
                return cardDetails['buttonName'] === buttonName;
            }
        );
        return indexOfButton;
    }
    enableCardButtons(cardInput: ICardsGeneratorInput, buttonNames?) {
        if (!buttonNames) {
            this.indexOfButton = this.searchCardButtonName(
                'buttons',
                'New Rule'
            );
            if (this.indexOfButton !== -1) {
                this.cardInput.buttons[this.indexOfButton].disable = false;
            }
        } else {
            for (const [key, value] of buttonNames.entries())
                cardInput[key].forEach((data) => {
                    if (value.length) {
                        if (value.includes(data.buttonName)) {
                            data.disable = false;
                        }
                    } else {
                        data.disable = false;
                    }
                });
        }
        this.cdr.detectChanges();
    }
    openGetFullAcess() {
        Helper.getFullAccess(this.modalService, PortletType.TAGGING_AUTOMATION);
    }

    create() {
        const modalInfo = {
            label: 'Create Automation',
            purpose: ModalAction.CREATE
        };
        this.setModalDataAndOpen(modalInfo);
    }

    update(item) {
        const modalInfo = {
            label: 'Edit Automation',
            purpose: ModalAction.UPDATE,
            cardData: item
        };
        this.setModalDataAndOpen(modalInfo, false);
    }

    setModalDataAndOpen(modalInfo, reset = true) {
        this.widgetRef.filterService.filtersDataTemp.delete(
            FilterStoreKey.AUTOMATION_FILTERS
        );
        this.widgetRef.filterService.filtersData.delete(
            FilterStoreKey.AUTOMATION_FILTERS
        );
        this.widgetRef.hideLogicalOperatorRadioButtons = true;
        const modalData: IModalData = {
            modalName: modalInfo.label,
            modalHeightVh: 94,
            modalWidthVw: 60,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalPurpose: modalInfo.purpose,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            AdvancedAutomationModalFiltersComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                cardData: modalInfo.cardData,
                                purpose: modalInfo.purpose
                            }
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad:
                            AdvancedAutomationModalCongifurationComponent,
                        payload: {
                            data: {
                                widgetInfo:
                                    this.widgetRef.widgetData.widgetInfo,
                                cardData: modalInfo.cardData,
                                purpose: modalInfo.purpose
                            }
                        }
                    },
                    stepName: 'Configuration'
                },
                {
                    stepData: {
                        componentToLoad:
                            AdvancedAutomationModalSchedulerComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                cardData: modalInfo.cardData,
                                purpose: modalInfo.purpose
                            }
                        }
                    },
                    stepName: 'Scheduler'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData, reset);
    }

    delete(item) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Rule',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage: 'Are you sure you want to delete rule ?',
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                this.deleteApi.call(this, item, modalId);
            }
        });
    }

    deleteApi(item, modalId: Symbol) {
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteArgs.intactUrl = deleteArgs.url;
        deleteArgs.url = deleteArgs.url.replace('{automationId}', item.id);
        deleteArgs.input = {};
        deleteArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                'Rule deleted successfully'
            );
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while deleting rule'
            );
            this.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    play(item, buttonData: IButtonData) {
        const widgetRef: Widget = this.widgetRef;

        const apiConfig: IHitApi = Helper.generateHitApiConfig(
            widgetRef.widgetData.widgetInfo.action
        );

        apiConfig.function = (response) => {
            buttonData.loader = false;
            this.cdr.detectChanges();
            this.widgetRef.notificationsService.showSnackBar(
                'Action performed successfully'
            );
        };

        apiConfig.endFunction = () => {
            buttonData.loader = false;
            if (this.lightState) {
                buttonData.disable = true;
            }
            this.cdr.detectChanges();
        };

        apiConfig.errorFunction = (error) => {
            buttonData.loader = false;
            this.cdr.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
        };

        apiConfig.input = {
            automationName: item.automationName,
            automationFilter: item.automationFilter,
            widgetFilter: item.widgetFilter
        };

        new HitApi(apiConfig, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }

    toggleScheduler(item: any): void {
        let status = '';
        if (item.active) {
            status = 'disable';
        } else {
            status = 'enable';
        }
        const widgetRef: Widget = this.widgetRef;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            item.active
                ? this.widgetRef.widgetData.widgetInfo['disable']
                : this.widgetRef.widgetData.widgetInfo['enable']
        );
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{automation-id}', item.id);
        apiArgs.input = {
            active: item.active ? false : true,
            automationFilter: item.automationFilter,
            automationName: item.automationName,
            widgetFilter: item.widgetFilter
        };
        apiArgs.function = (response) => {
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action.'
            );
        };

        new HitApi(apiArgs, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }
}
