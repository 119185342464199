import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { OnboardingAccountType } from 'src/app/shared/enums/OnboardingAccountType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IInfoModal } from './../../../../interfaces/modal/IInfoModal';

enum AuthenticationType {
    ROLE = 'Role Based',
    USER = 'User Based'
}
enum OnboardingType {
    MANUAL = 'Manual Onboarding',
    ONECLICK = 'One-click Onboarding'
}
@Component({
    selector: 'app-aws-onboarding-modal',
    templateUrl: './aws-onboarding-modal.component.html',
    styleUrls: ['./aws-onboarding-modal.component.sass']
})
export class AwsOnboardingModalComponent implements OnInit {
    Tab = Tab;
    selectedTabIndex: number = 0;
    activeTabIndex: number = 0;
    widgetRef: Widget;
    commonOnboardingFormInput: IFormGeneratorInput = null;
    manualOnboardingFormInput: IFormGeneratorInput = null;
    oneClickOnboardingFormInput: IFormGeneratorInput = null;
    userBasedOnboardingFormInput: IFormGeneratorInput = null;
    submitForm: IFormGeneratorInput = null;
    commonOnboardingFormGroup: FormGroup;
    manualOnboardingFormGroup: FormGroup;
    oneClickOnboardingFormGroup: FormGroup;
    userBasedOnboardingFormGroup: FormGroup;
    currentForm: FormGroup;
    eAuthType = AuthenticationType;
    eOnboardType = OnboardingType;
    authenticationType = AuthenticationType.ROLE;
    onboardingType = OnboardingType.ONECLICK;
    createButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Create New Role',
        buttonColorType: ButtonColorType.SUCCESS,
        buttonType: ButtonType.STROKED,
        function: null
    };

    infoData: IInfoModal[] = [
        {
            infoHeading: '<strong>Role Based :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' If you select this, create an assume role and provide us the ARN of that particular role.'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>User Based :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' If you select this, create an IAM user and provide us access key and secret key for that particular user.'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Note :</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' To know how to create an assume role and an IAM user follow the instructions in the pdf below.<a href="assets/onboarding-pdf/aws-poc-onboarding.pdf" target="_blank">Click here</a> to get detailed instructions on how to create an assume role and an IAM user.'
                    ]
                }
            ]
        }
    ];
    buttonInputs: IButtonGeneratorInput[];
    resetSubscription: Subscription;

    constructor(
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalInput: ModalInjectedData,
        private modalService: ModalService,
        private filtersService: FiltersService
    ) {
        if (modalInput.data.formData) {
            if (!modalInput.data.formData.awsCred.roleArn) {
                this.authenticationType = AuthenticationType.USER;
            }
        }
    }

    ngOnInit(): void {
        this.buttonInputs = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: 'assets/onboarding-pdf/aws-poc-onboarding.pdf',
                function: () => {}
            },
            {
                buttonName: 'Onboard',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.onSave(buttonRef);
                }
            }
        ];
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            this.userBasedOnboardingFormGroup.reset();
            this.commonOnboardingFormGroup.reset();
            if (
                this.selectedTabIndex === 1 &&
                this.onboardingType === OnboardingType.ONECLICK
            ) {
                this.oneClickOnboardingFormGroup.reset();
            }
            if (
                this.selectedTabIndex === 1 &&
                this.onboardingType === OnboardingType.MANUAL
            ) {
                this.manualOnboardingFormGroup.reset();
            }
        });

        // General Form Input for second section
        this.commonOnboardingFormInput = {
            formName: 'AWS Onboarding',
            state: this.modalInput.data.formData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Report Path',
                    name: 'reportPath',
                    placeholder: 'Enter Report Path',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.reportPath
                            ? this.modalInput.data.formData.awsCred.reportPath
                            : ''
                        : ''
                },
                {
                    label: 'S3 Bucket Name',
                    name: 'bucketName',
                    placeholder: 'Enter S3 Bucket Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.bucketName
                            ? this.modalInput.data.formData.awsCred.bucketName
                            : ''
                        : ''
                },
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Enter Account Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account Name is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.accountName
                            ? this.modalInput.data.formData.accountName
                            : ''
                        : ''
                },
                {
                    label: 'SPOC/Account Owner',
                    name: 'spoc',
                    placeholder: 'Enter SPOC Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.spoc
                            ? this.modalInput.data.formData.spoc
                            : ''
                        : ''
                }
            ],
            extraClass: 'integration-styling'
        };

        if (
            this.modalInput.data.formData &&
            (this.modalInput.data.formData.type ===
                OnboardingAccountType.CONSOLIDATED ||
                this.modalInput.data.formData.type ===
                    OnboardingAccountType.LINKED ||
                this.modalInput.data.formData.type ===
                    OnboardingAccountType.LINKED_1 ||
                this.modalInput.data.formData.type ===
                    OnboardingAccountType.LINKED_2)
        ) {
            Helper.removeFormField(
                this.commonOnboardingFormInput,
                'reportPath'
            );
            Helper.removeFormField(
                this.commonOnboardingFormInput,
                'bucketName'
            );
        }

        // Manual Onboarding Form Genrator Input
        this.manualOnboardingFormInput = {
            formName: 'Manual Onboarding',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Role ARN',
                    name: 'roleArn',
                    placeholder: 'Enter Role ARN',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Role ARN is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.roleArn
                            ? this.modalInput.data.formData.awsCred.roleArn
                            : ''
                        : ''
                },
                {
                    label: 'External ID',
                    name: 'externalID',
                    placeholder: 'Enter External ID',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.externalId
                            ? this.modalInput.data.formData.awsCred.externalId
                            : ''
                        : ''
                }
            ],
            extraClass: 'integration-styling'
        };
        // One-click form generator input
        this.oneClickOnboardingFormInput = {
            formName: 'One-click Onboarding',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Role ARN',
                    name: 'roleArn',
                    placeholder: 'Enter Role ARN',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Role ARN is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.roleArn
                            ? this.modalInput.data.formData.awsCred.roleArn
                            : ''
                        : ''
                }
            ],
            extraClass: 'integration-styling'
        };
        // User Based from generator input
        this.userBasedOnboardingFormInput = {
            formName: 'User Based Onboarding',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Access Key',
                    name: 'accessKey',
                    placeholder: 'Enter Access Key',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: this.modalInput.data.formData ? false : true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Access Key is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.accessKey
                            ? this.modalInput.data.formData.awsCred.accessKey
                            : ''
                        : ''
                },
                {
                    label: 'Secret Key',
                    name: 'secretKey',
                    placeholder: this.modalInput.data.formData
                        ? this.modalInput.data.formData.awsCred.secretKey
                            ? '******'
                            : 'Enter Secret Key'
                        : 'Enter Secret Key',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: this.modalInput.data.formData ? false : true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Secret Key is required'
                        }
                    ]
                }
            ],
            extraClass: 'integration-styling'
        };
    }
    openManualOnboardingInfoModal() {
        this.modalService.openInfoModal({
            infoHeading: 'To manually onboard your account with us',
            content: [
                {
                    type: 'UNORDERED_LIST',
                    data: [
                        `Create an assume role (download pdf for detailed instructions)`,
                        `Copy the role ARN of the created role`,
                        `Paste it in the below text field`,
                        `You can skip above mentioned steps by selecting One-click on-boarding method.`
                    ]
                }
            ]
        });
    }

    openOneClickOnboardingInfoModal() {
        this.modalService.openInfoModal({
            infoHeading: 'Create your role with one-click.',
            content: [
                {
                    type: 'UNORDERED_LIST',
                    data: [
                        `Copy the Role ARN and paste it in the below text field.`
                    ]
                }
            ]
        });
    }

    createRole() {
        Helper.navigateTo(
            'https://ap-northeast-1.console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/quickcreate?templateURL=https://s3.ap-south-1.amazonaws.com/centilytics.config.ap-south-1/defaultWebsite/assumerolecloudformationtemplate.json&stackName=createAssumeRole',
            'NEW_PAGE'
        );
    }

    updateValidation() {
        if (
            (this.commonOnboardingFormGroup &&
                !this.modalInput.data.formData) ||
            (this.commonOnboardingFormGroup &&
                this.modalInput.data.formData &&
                this.modalInput.data.formData.type !==
                    OnboardingAccountType.CONSOLIDATED &&
                this.modalInput.data.formData.type !==
                    OnboardingAccountType.LINKED &&
                this.modalInput.data.formData.type !==
                    OnboardingAccountType.LINKED_1 &&
                this.modalInput.data.formData.type !==
                    OnboardingAccountType.LINKED_2)
        ) {
            const formGroup = this.commonOnboardingFormGroup;
            const bucketNameIndex =
                this.commonOnboardingFormInput.fields.indexOf(
                    this.commonOnboardingFormInput.fields.find(
                        (field) => field.name === 'bucketName'
                    )
                );
            const reportPathIndex =
                this.commonOnboardingFormInput.fields.indexOf(
                    this.commonOnboardingFormInput.fields.find(
                        (field) => field.name === 'reportPath'
                    )
                );

            if (
                formGroup.get('reportPath').value &&
                !formGroup.get('bucketName').value
            ) {
                this.commonOnboardingFormInput.fields[
                    bucketNameIndex
                ].required = true;
                this.commonOnboardingFormInput.fields[
                    bucketNameIndex
                ].validations = [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Bucket Name is required'
                    }
                ];
            } else if (
                !formGroup.get('reportPath').value &&
                formGroup.get('bucketName').value
            ) {
                this.commonOnboardingFormInput.fields[
                    reportPathIndex
                ].required = true;
                this.commonOnboardingFormInput.fields[
                    reportPathIndex
                ].validations = [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Report Path is required'
                    }
                ];
            } else {
                this.commonOnboardingFormInput.fields[
                    bucketNameIndex
                ].required = false;
                this.commonOnboardingFormInput.fields[
                    bucketNameIndex
                ].validations = null;
                this.commonOnboardingFormInput.fields[
                    reportPathIndex
                ].required = false;
                this.commonOnboardingFormInput.fields[
                    reportPathIndex
                ].validations = null;
            }
        }
    }

    onSave(buttonRef: IButtonGeneratorInput) {
        const formInputJson = {
            accountName:
                this.commonOnboardingFormGroup.get('accountName').value,
            awsAccountInfo: {
                accessKey: '',
                secretKey: '',
                bucketName: this.commonOnboardingFormGroup.get('bucketName')
                    ? this.commonOnboardingFormGroup.get('bucketName').value
                    : '',
                reportPath: this.commonOnboardingFormGroup.get('reportPath')
                    ? this.commonOnboardingFormGroup.get('reportPath').value
                    : '',
                roleArn: '',
                externalId: '',
                roleBased: false
            },
            spoc: this.commonOnboardingFormGroup.get('spoc').value
        };

        // Assigning Values to request input depending on conditions
        if (this.selectedTabIndex === 0) {
            formInputJson.awsAccountInfo.accessKey =
                this.userBasedOnboardingFormGroup.get('accessKey').value;
            formInputJson.awsAccountInfo.secretKey =
                this.userBasedOnboardingFormGroup.get('secretKey').value;
        }
        if (
            this.selectedTabIndex === 1 &&
            this.onboardingType === OnboardingType.MANUAL
        ) {
            formInputJson.awsAccountInfo.roleBased = true;
            formInputJson.awsAccountInfo.roleArn =
                this.manualOnboardingFormGroup.get('roleArn').value;
            formInputJson.awsAccountInfo.externalId =
                this.manualOnboardingFormGroup.get('externalID').value;
        }
        if (
            this.selectedTabIndex === 1 &&
            this.onboardingType === OnboardingType.ONECLICK
        ) {
            formInputJson.awsAccountInfo.roleBased = true;
            formInputJson.awsAccountInfo.roleArn =
                this.oneClickOnboardingFormGroup.get('roleArn').value;
        }

        // Marking Form fields as touched and setting current form
        if (this.selectedTabIndex === 0) {
            Helper.markAllFieldAsTouched(this.userBasedOnboardingFormGroup);
            this.currentForm = this.userBasedOnboardingFormGroup;
        } else {
            if (
                this.selectedTabIndex === 1 &&
                this.onboardingType === OnboardingType.MANUAL
            ) {
                Helper.markAllFieldAsTouched(this.manualOnboardingFormGroup);
                this.currentForm = this.manualOnboardingFormGroup;
            } else if (
                this.selectedTabIndex === 1 &&
                this.onboardingType === OnboardingType.ONECLICK
            ) {
                Helper.markAllFieldAsTouched(this.oneClickOnboardingFormGroup);
                this.currentForm = this.oneClickOnboardingFormGroup;
            }
        }
        Helper.markAllFieldAsTouched(this.commonOnboardingFormGroup);
        if (this.commonOnboardingFormGroup.invalid) {
            this.commonOnboardingFormGroup.updateValueAndValidity();
            return;
        }
        // Checking form validity and Hitting API
        if (this.commonOnboardingFormGroup.valid && this.currentForm.valid) {
            const edit = this.modalInput.data.formData ? true : false;
            if (edit) {
                formInputJson['edit'] = edit;
            }
            buttonRef.loader = true;
            let isBucketNameChanged = false;
            if (
                this.commonOnboardingFormGroup.get('bucketName') &&
                this.commonOnboardingFormGroup.get('bucketName').value
            ) {
                if (this.modalInput.data.formData) {
                    if (
                        this.modalInput.data.formData.awsCred.bucketName !==
                        this.commonOnboardingFormGroup
                            .get('bucketName')
                            .value.trim()
                    ) {
                        isBucketNameChanged = true;
                    } else {
                        isBucketNameChanged = false;
                    }
                } else {
                    isBucketNameChanged = true;
                }
            }
            if (isBucketNameChanged) {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.modalInput.data.widgetInfo.verifyBucket
                );
                apiArgs.input = formInputJson;
                apiArgs.function = (response) => {
                    if (response.status) {
                        const apiArgs: IHitApi = edit
                            ? Helper.generateHitApiConfig(
                                  this.modalInput.data.widgetInfo.update
                              )
                            : Helper.generateHitApiConfig(
                                  this.modalInput.data.widgetInfo.create
                              );
                        if (edit) {
                            apiArgs.url = apiArgs.url.replace(
                                '{id}',
                                this.modalInput.data.formData.accountNumber
                            );
                        }
                        apiArgs.input = formInputJson;
                        apiArgs.function = (response) => {
                            this.notificationService.showSnackBar(
                                edit
                                    ? Messages.ACCOUNT_UPDATE_LIST
                                    : Messages.ACCOUNT_ONBOARDING_SUCCESS
                            );
                            buttonRef.loader = false;
                            this.filtersService.refreshWidget.next(
                                new Set([this.modalService.modalData.sourceId])
                            );
                            this.modalService.closeModal(
                                null,
                                this.modalInput.modalId
                            );
                        };
                        apiArgs.errorFunction = (error) => {
                            this.notificationService.showSnackBar(
                                error.error.message,
                                true
                            );
                            buttonRef.loader = false;
                        };

                        new HitApi(
                            apiArgs,
                            this.httpService,
                            this.ngZone
                        ).hitApi();
                    } else {
                        this.notificationService.showSnackBar(
                            'Invalid bucket name.',
                            true
                        );
                        buttonRef.loader = false;
                    }
                };
                apiArgs.errorFunction = (error) => {
                    this.notificationService.showSnackBar(
                        error.error.message,
                        true
                    );
                    buttonRef.loader = false;
                };

                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            } else {
                const apiArgs: IHitApi = edit
                    ? Helper.generateHitApiConfig(
                          this.modalInput.data.widgetInfo.update
                      )
                    : Helper.generateHitApiConfig(
                          this.modalInput.data.widgetInfo.create
                      );
                if (edit) {
                    apiArgs.url = apiArgs.url.replace(
                        '{id}',
                        this.modalInput.data.formData.accountNumber
                    );
                }
                apiArgs.input = formInputJson;
                apiArgs.function = (response) => {
                    this.notificationService.showSnackBar(
                        edit
                            ? Messages.ACCOUNT_UPDATE_LIST
                            : Messages.ACCOUNT_ONBOARDING_SUCCESS
                    );
                    buttonRef.loader = false;
                    this.filtersService.refreshWidget.next(
                        new Set([this.modalService.modalData.sourceId])
                    );
                    this.modalService.closeModal(null, this.modalInput.modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.notificationService.showSnackBar(
                        error.error.message,
                        true
                    );
                    buttonRef.loader = false;
                };

                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            }
        } else {
            this.notificationService.showSnackBar(
                'Please fill in all the required fields.',
                true
            );
            buttonRef.loader = false;
        }
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}

enum Tab {
    USER_BASED = 'User Based',
    ROLE_BASED = 'Role Based'
}
