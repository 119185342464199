<ng-container *ngIf="whitelistFormInput; else loader">
    <div class="container">
        <app-form-generator
            *ngIf="whitelistFormInput; else"
            [formGeneratorInput]="whitelistFormInput"
            (formGroupRef)="whitelistFormGroup = $event"
        >
        </app-form-generator>
    </div>

    <app-button-generator
        [buttonGenInput]="buttonGenInput"
    ></app-button-generator>
</ng-container>

<ng-template #loader>
    <div class="step-loader">
        <mat-spinner [diameter]="25" class="mat-spinner-color"></mat-spinner>
    </div>
</ng-template>
