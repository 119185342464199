<div class="container">
    <div class="form-container">
        <app-form-generator
            *ngIf="formGenInput"
            [formGeneratorInput]="formGenInput"
            (formGroupRef)="formGroup = $event"
        ></app-form-generator>
    </div>
    <div class="btn-container">
        <app-button-generator
            *ngIf="buttonGenInput"
            [buttonGenInput]="buttonGenInput"
        >
        </app-button-generator>
    </div>
</div>
