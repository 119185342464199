import { Component, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { TextFieldAppearance } from './../../../enums/AppearanceType';

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TextFieldComponent,
            multi: true
        }
    ]
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    FilterType = FilterType;
    FormState = FormState;
    onChange: any = () => {};
    onTouch: any = () => {};
    value: string;
    FieldAppearance = TextFieldAppearance;
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    constructor() {}
    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (
            !existingAppearance.includes(
                this.field.appearance as TextFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(value: string): void {}
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {}
}
