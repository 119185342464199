<div
    class="col-icon"
    [matMenuTriggerFor]="matMenu"
    appIcon
    [data]="iconData"
    matTooltip="Reorder Columns"
></div>
<mat-menu #matMenu="matMenu">
    <div class="menu-height" (click)="$event.stopPropagation()">
        <div class="menu-head">
            <div class="menu-search">
                <app-table-search
                    [data]="columnName"
                    (changed)="filteredColumnName = $event"
                    [applyOutlineStyling]="true"
                ></app-table-search>
            </div>
            <div class="menu-close" (click)="trigger.closeMenu()">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <ng-container *ngIf="disableDragColumns && disableDragColumns.length">
            <ng-container *ngFor="let column of disableDragColumns">
                <div class="disabled-columns column-list">
                    <div
                        *ngIf="disableDragIcon"
                        appIcon
                        [data]="disableDragIcon"
                        class="drag-icon"
                        [style.cursor]="'default'"
                        matTooltip="Cannot be Reordered"
                    ></div>
                    <div
                        class="column-name disable-coloumn-name"
                        [style.cursor]="'default'"
                        matTooltip="Cannot be reordered"
                    >
                        {{ column }}
                    </div>
                    <mat-slide-toggle
                        [checked]="
                            toggleEnabled.get(column) !== undefined
                                ? toggleEnabled.get(column)
                                : true
                        "
                        disabled
                        class="mat-slide-toggle"
                        matTooltip="Cannot be disabled"
                    ></mat-slide-toggle>
                </div>
            </ng-container>
        </ng-container>
        <div cdkDropList (cdkDropListDropped)="drop($event)" class="menu-items">
            <div class="menu-list">
                <div *ngFor="let column of filteredColumnName" cdkDrag>
                    <ng-container *ngIf="!disableDragColumns.includes(column)">
                        <div class="column-list">
                            <div
                                matTooltip="Drag and Reorder"
                                *ngIf="prefixData"
                                class="drag-icon"
                                appIcon
                                [data]="prefixData"
                            ></div>
                            <div
                                class="column-name"
                                matTooltip="Drag and Reorder"
                            >
                                {{ column }}
                            </div>
                            <mat-slide-toggle
                                [disabled]="
                                    (disableToggleSlide &&
                                        toggleEnabled.get(column)) ||
                                    fixedColumns.includes(column)
                                "
                                [checked]="
                                    toggleEnabled.get(column) !== undefined
                                        ? toggleEnabled.get(column)
                                        : true
                                "
                                (change)="columnVisiblity(column, $event)"
                                [matTooltip]="
                                    fixedColumns.includes(column)
                                        ? 'Cannot be disabled'
                                        : toggleEnabled.get(column) !==
                                          undefined
                                        ? toggleEnabled.get(column)
                                            ? 'Hide Column'
                                            : 'Show Column'
                                        : 'Hide Coumn'
                                "
                            ></mat-slide-toggle>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="buttons-container menu-buttons-container">
                <app-multi-button-generator
                    *ngIf="controlButtons"
                    [buttonGenInputs]="controlButtons"
                ></app-multi-button-generator>
            </div>
        </div>
    </div>
</mat-menu>
