<div class="container">
    <div
        [ngClass]="
            filterInfo && filterInfo.showCheckbox && field.isFilter
                ? 'field-with-checkbox'
                : 'field-without-checkbox'
        "
    >
        <!-- field type one start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_1"
            class="ng-select-dropdown-container"
        >
            <div
                [class]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : field.disabledText
                            ? field.disabledText
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : field.disabledText
                        ? field.disabledText
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <legend
                    class="dropdown-legend"
                    *ngIf="
                        field.groupByKey
                            ? formGroup.get(field.groupByKey).get(field.name)
                                  .value &&
                              formGroup.get(field.groupByKey).get(field.name)
                                  .value.length
                            : formGroup.get(field.name).value &&
                              formGroup.get(field.name).value.length
                    "
                >
                    {{
                        field.showLabelInDropdownLegend && field.label
                            ? field.label
                            : field.placeholder
                            ? field.placeholder
                            : field.label
                    }}
                    <span
                        *ngIf="
                            field.required !== undefined &&
                            field.required &&
                            !field.showLabel
                        "
                        class="mat-form-field-required-marker"
                        >&nbsp;*</span
                    >
                    <mat-icon
                        *ngIf="field.prefixIcon"
                        matPrefix
                        [matTooltip]="field.prefixIcon.hoverText"
                        class="clickable"
                        (click)="
                            field.prefixIcon.function
                                ? field.prefixIcon.function(field, formGroup)
                                : null
                        "
                        >{{ field.prefixIcon.iconData.class }}</mat-icon
                    >
                </legend>

                <ng-select
                    #ngSelectRef
                    style="z-index: 10"
                    [appearance]="'outline'"
                    [class.field-required]="
                        field.required !== undefined && !field.showLabel
                            ? field.required
                            : false
                    "
                    [bindValue]="
                        field.getKey || field.getKey === null
                            ? field.getKey
                            : 'id'
                    "
                    [bindLabel]="field.showKey ? field.showKey : 'label'"
                    [items]="
                        !loading.get(field.name) && field.listData
                            ? field.listData
                            : null
                    "
                    [closeOnSelect]="false"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="true"
                    [maxSelectedItems]="
                        field.maxSelectionLimit
                            ? field.maxSelectionLimit
                            : Infinity
                    "
                    (scroll)="scroll($event)"
                    [virtualScroll]="true"
                    (change)="
                        onValueChange($event);
                        field.onChange
                            ? field.onChange($event, formGroup, field.name)
                            : null
                    "
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length > 0
                                        : formGroup.get(field.name).value
                                              .length > 0
                                "
                            >
                                <span class="ng-value-label"
                                    >{{
                                        loading.get(field.name)
                                            ? field.loadingMessage
                                                ? field.loadingMessage
                                                : "Loading..."
                                            : field.groupByKey
                                            ? formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length + " selected"
                                            : formGroup.get(field.name).value
                                                  .length + " selected"
                                    }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            !field.hideSelectAllInDropdown &&
                            !field.maxSelectionLimit &&
                            !loading.get(field.name) &&
                            field.listData &&
                            field.listData.length
                        "
                    >
                        <ng-template ng-header-tmp>
                            <div
                                *ngIf="
                                    field.groupByKey
                                        ? (formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value)
                                        : (formGroup.get(field.name) &&
                                              formGroup.get(field.name).value &&
                                              formGroup.get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup.get(field.name).value)
                                "
                                (click)="onSelectAll(true)"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'ng-select-option-custom'
                                "
                            >
                                <i class="far fa-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length <
                                              field.listData.length &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length !==
                                              0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length < field.listData.length &&
                                          formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length !== 0
                                "
                                (click)="onSelectAll(true)"
                            >
                                <i class="fas fa-minus-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                "
                                (click)="onSelectAll(false)"
                            >
                                <i class="fas fa-check-square clickable"></i
                                ><span class="common-label"> Select All</span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index"
                    >
                        <div
                            appDisableElement
                            [data]="
                                field.groupByKey
                                    ? field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length
                                    : field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup.get(field.name).value.length
                            "
                            *ngIf="!item$.selected"
                            class="ng-select-option-custom"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown far fa-square"></i>
                            <span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="item$.selected"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown fas fa-check-square"></i
                            ><span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type one end -->
        <!-- field type two start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_2"
            class="ng-select-dropdown-container"
        >
            <div
                [class]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : field.disabledText
                            ? field.disabledText
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : field.disabledText
                        ? field.disabledText
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <ng-select
                    #ngSelectRef
                    style="z-index: 10"
                    [appearance]="'outline'"
                    [class.field-required]="
                        field.required !== undefined && !field.showLabel
                            ? field.required
                            : false
                    "
                    [bindValue]="
                        field.getKey || field.getKey === null
                            ? field.getKey
                            : 'id'
                    "
                    [bindLabel]="field.showKey ? field.showKey : 'label'"
                    [items]="
                        !loading.get(field.name) && field.listData
                            ? field.listData
                            : null
                    "
                    [closeOnSelect]="false"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="true"
                    [maxSelectedItems]="
                        field.maxSelectionLimit
                            ? field.maxSelectionLimit
                            : Infinity
                    "
                    (scroll)="scroll($event)"
                    [virtualScroll]="true"
                    (change)="
                        onValueChange($event);
                        field.onChange
                            ? field.onChange($event, formGroup, field.name)
                            : null
                    "
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length > 0
                                        : formGroup.get(field.name).value
                                              .length > 0
                                "
                            >
                                <span class="ng-value-label"
                                    >{{
                                        loading.get(field.name)
                                            ? field.loadingMessage
                                                ? field.loadingMessage
                                                : "Loading..."
                                            : field.groupByKey
                                            ? formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length + " selected"
                                            : formGroup.get(field.name).value
                                                  .length + " selected"
                                    }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            !field.hideSelectAllInDropdown &&
                            !field.maxSelectionLimit &&
                            !loading.get(field.name) &&
                            field.listData &&
                            field.listData.length
                        "
                    >
                        <ng-template ng-header-tmp>
                            <div
                                *ngIf="
                                    field.groupByKey
                                        ? (formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value)
                                        : (formGroup.get(field.name) &&
                                              formGroup.get(field.name).value &&
                                              formGroup.get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup.get(field.name).value)
                                "
                                (click)="onSelectAll(true)"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'ng-select-option-custom'
                                "
                            >
                                <i class="far fa-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length <
                                              field.listData.length &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length !==
                                              0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length < field.listData.length &&
                                          formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length !== 0
                                "
                                (click)="onSelectAll(true)"
                            >
                                <i class="fas fa-minus-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                "
                                (click)="onSelectAll(false)"
                            >
                                <i class="fas fa-check-square clickable"></i
                                ><span class="common-label"> Select All</span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index"
                    >
                        <div
                            appDisableElement
                            [data]="
                                field.groupByKey
                                    ? field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length
                                    : field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup.get(field.name).value.length
                            "
                            *ngIf="!item$.selected"
                            class="ng-select-option-custom"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown far fa-square"></i>
                            <span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="item$.selected"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown fas fa-check-square"></i
                            ><span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type two end -->

        <!-- field type three start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_3"
            class="ng-select-dropdown-container"
        >
            <span class="ng-select-label">
                {{ field.label }}
                <span *ngIf="field.required"> * </span>
            </span>

            <div
                [class]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : field.disabledText
                            ? field.disabledText
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : field.disabledText
                        ? field.disabledText
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <ng-select
                    #ngSelectRef
                    style="z-index: 10"
                    [appearance]="'legacy'"
                    [class.field-required]="
                        field.required !== undefined && !field.showLabel
                            ? field.required
                            : false
                    "
                    [bindValue]="
                        field.getKey || field.getKey === null
                            ? field.getKey
                            : 'id'
                    "
                    [bindLabel]="field.showKey ? field.showKey : 'label'"
                    [items]="
                        !loading.get(field.name) && field.listData
                            ? field.listData
                            : null
                    "
                    [closeOnSelect]="false"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="true"
                    [maxSelectedItems]="
                        field.maxSelectionLimit
                            ? field.maxSelectionLimit
                            : Infinity
                    "
                    [virtualScroll]="true"
                    (scroll)="scroll($event)"
                    (change)="
                        onValueChange($event);
                        field.onChange
                            ? field.onChange($event, formGroup, field.name)
                            : null
                    "
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length > 0
                                        : formGroup.get(field.name).value
                                              .length > 0
                                "
                            >
                                <span class="ng-value-label"
                                    >{{
                                        loading.get(field.name)
                                            ? field.loadingMessage
                                                ? field.loadingMessage
                                                : "Loading..."
                                            : field.groupByKey
                                            ? formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length + " selected"
                                            : formGroup.get(field.name).value
                                                  .length + " selected"
                                    }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            !field.hideSelectAllInDropdown &&
                            !field.maxSelectionLimit &&
                            !loading.get(field.name) &&
                            field.listData &&
                            field.listData.length
                        "
                    >
                        <ng-template ng-header-tmp>
                            <div
                                *ngIf="
                                    field.groupByKey
                                        ? (formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value)
                                        : (formGroup.get(field.name) &&
                                              formGroup.get(field.name).value &&
                                              formGroup.get(field.name).value
                                                  .length === 0) ||
                                          (getEnabledListDataLength(field) &&
                                              !formGroup.get(field.name).value)
                                "
                                (click)="onSelectAll(true)"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'ng-select-option-custom'
                                "
                            >
                                <i class="far fa-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length <
                                              field.listData.length &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length !==
                                              0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length <
                                              getEnabledListDataLength(field) &&
                                          formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length !== 0
                                "
                                (click)="onSelectAll(true)"
                            >
                                <i class="fas fa-minus-square clickable"></i>
                                <span class="common-label"> Select All </span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length ===
                                              getEnabledListDataLength(field) &&
                                          field.listData.length > 0
                                "
                                (click)="onSelectAll(false)"
                            >
                                <i class="fas fa-check-square clickable"></i
                                ><span class="common-label"> Select All</span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index"
                    >
                        <div
                            appDisableElement
                            [data]="
                                field.groupByKey
                                    ? field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length
                                    : field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup.get(field.name).value.length
                            "
                            *ngIf="!item$.selected"
                            class="ng-select-option-custom"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown far fa-square"></i>
                            <span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="item$.selected"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown fas fa-check-square"></i
                            ><span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type three end -->

        <!-- field type four start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_4"
            class="ng-select-dropdown-container"
        >
            <div
                [class]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : field.disabledText
                            ? field.disabledText
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : field.disabledText
                        ? field.disabledText
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <ng-select
                    #ngSelectRef
                    style="z-index: 10"
                    [appearance]="'legacy'"
                    [class.field-required]="
                        field.required !== undefined && !field.showLabel
                            ? field.required
                            : false
                    "
                    [bindValue]="
                        field.getKey || field.getKey === null
                            ? field.getKey
                            : 'id'
                    "
                    [bindLabel]="field.showKey ? field.showKey : 'label'"
                    [items]="
                        !loading.get(field.name) && field.listData
                            ? field.listData
                            : null
                    "
                    [closeOnSelect]="false"
                    [placeholder]="
                        field.placeholder ? field.placeholder : field.label
                    "
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="true"
                    [maxSelectedItems]="
                        field.maxSelectionLimit
                            ? field.maxSelectionLimit
                            : Infinity
                    "
                    [virtualScroll]="true"
                    (scroll)="scroll($event)"
                    (change)="
                        onValueChange($event);
                        field.onChange
                            ? field.onChange($event, formGroup, field.name)
                            : null
                    "
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length > 0
                                        : formGroup.get(field.name).value
                                              .length > 0
                                "
                            >
                                <span class="ng-value-label"
                                    >{{
                                        loading.get(field.name)
                                            ? field.loadingMessage
                                                ? field.loadingMessage
                                                : "Loading..."
                                            : field.groupByKey
                                            ? formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length + " selected"
                                            : formGroup.get(field.name).value
                                                  .length + " selected"
                                    }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            !field.hideSelectAllInDropdown &&
                            !field.maxSelectionLimit &&
                            !loading.get(field.name) &&
                            field.listData &&
                            field.listData.length
                        "
                    >
                        <ng-template ng-header-tmp>
                            <div
                                *ngIf="
                                    field.groupByKey
                                        ? (formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value)
                                        : (formGroup.get(field.name) &&
                                              formGroup.get(field.name).value &&
                                              formGroup.get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup.get(field.name).value)
                                "
                                (click)="onSelectAll(true)"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'ng-select-option-custom'
                                "
                            >
                                <i class="far fa-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length <
                                              field.listData.length &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length !==
                                              0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length < field.listData.length &&
                                          formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length !== 0
                                "
                                (click)="onSelectAll(true)"
                            >
                                <i class="fas fa-minus-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                "
                                (click)="onSelectAll(false)"
                            >
                                <i class="fas fa-check-square clickable"></i
                                ><span class="common-label"> Select All</span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index"
                    >
                        <div
                            appDisableElement
                            [data]="
                                field.groupByKey
                                    ? field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length
                                    : field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup.get(field.name).value.length
                            "
                            *ngIf="!item$.selected"
                            class="ng-select-option-custom"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown far fa-square"></i>
                            <span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="item$.selected"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown fas fa-check-square"></i
                            ><span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type four end -->

        <!-- field type five start -->
        <div *ngIf="field.appearance === AppearanceType.TYPE_5">
            <ng-select
                #ngSelectRef
                class="custom"
                style="z-index: 10"
                [class.field-required]="
                    field.required !== undefined && !field.showLabel
                        ? field.required
                        : false
                "
                [bindValue]="
                    field.getKey || field.getKey === null ? field.getKey : 'id'
                "
                [bindLabel]="field.showKey ? field.showKey : 'label'"
                [items]="
                    !loading.get(field.name) && field.listData
                        ? field.listData
                        : null
                "
                [closeOnSelect]="false"
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [loading]="loading.get(field.name)"
                [appendTo]="'body'"
                [loadingText]="
                    field.loadingMessage ? field.loadingMessage : 'Loading...'
                "
                dropdownPosition="auto"
                [clearable]="true"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [multiple]="true"
                (scroll)="scroll($event)"
                [virtualScroll]="true"
                (change)="
                    onValueChange($event);
                    field.onChange
                        ? field.onChange($event, formGroup, field.name)
                        : null
                "
                [compareWith]="compareWith"
                (open)="showOverlay = true"
                (close)="showOverlay = false"
            >
                <ng-container>
                    <ng-template
                        ng-multi-label-tmp
                        let-items="selectedValues"
                        let-clear="clear"
                    >
                        <div
                            class="ng-value"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length > 0
                                    : formGroup.get(field.name).value.length > 0
                            "
                        >
                            <span class="ng-value-label"
                                >{{
                                    loading.get(field.name)
                                        ? field.loadingMessage
                                            ? field.loadingMessage
                                            : "Loading..."
                                        : field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length +
                                          " selected"
                                        : formGroup.get(field.name).value
                                              .length + " selected"
                                }}
                            </span>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container
                    *ngIf="
                        !field.hideSelectAllInDropdown &&
                        !loading.get(field.name) &&
                        field.listData &&
                        field.listData.length &&
                        showSelectAll
                    "
                >
                    <ng-template ng-header-tmp>
                        <div
                            *ngIf="
                                field.groupByKey
                                    ? (formGroup
                                          .get(field.groupByKey)
                                          .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              0) ||
                                      (field.listData.length &&
                                          !formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value)
                                    : (formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length === 0) ||
                                      (field.listData.length &&
                                          !formGroup.get(field.name).value)
                            "
                            (click)="onSelectAll(true)"
                            [ngClass]="
                                field.disableDropdown
                                    ? 'disabled'
                                    : 'ng-select-option-custom'
                            "
                        >
                            <i class="far fa-square clickable"></i>
                            <span class="common-label"> Select All</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            [ngClass]="
                                field.disableDropdown
                                    ? 'disabled'
                                    : 'selected-item ng-select-option-custom'
                            "
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name) &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name) &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length !== 0
                                    : formGroup.get(field.name) &&
                                      formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup.get(field.name) &&
                                      formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length !==
                                          0
                            "
                            (click)="onSelectAll(true)"
                        >
                            <i class="fas fa-minus-square clickable"></i>
                            <span class="common-label"> Select All</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            [ngClass]="
                                field.disableDropdown
                                    ? 'disabled'
                                    : 'selected-item ng-select-option-custom'
                            "
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name) &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                                    : formGroup.get(field.name) &&
                                      formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                            "
                            (click)="onSelectAll(false)"
                        >
                            <i class="fas fa-check-square clickable"></i
                            ><span class="common-label"> Select All</span>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                >
                    <div
                        appDisableElement
                        [data]="
                            filterInfo && filterInfo.selectionLimit
                                ? filterInfo.selectionLimit ===
                                  formGroup.get(field.name).value.length
                                : field.groupByKey
                                ? field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length
                                : field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup.get(field.name).value.length
                        "
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                        title="{{ showTooltip(item) }}"
                    >
                        <i class="checkbox-dropdown far fa-square"></i>
                        <span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                        title="{{ showTooltip(item) }}"
                    >
                        <i class="checkbox-dropdown fas fa-check-square"></i
                        ><span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                </ng-template>
            </ng-select>
        </div>

        <!-- field type five end -->

        <!-- field type seven start -->
        <div
            *ngIf="field.appearance === AppearanceType.TYPE_7"
            class="ng-select-dropdown-container column"
        >
            <span class="ng-select-label type-7">
                {{ field.label }}
                <span *ngIf="field.required">&nbsp;*</span>
            </span>

            <div
                [class]="
                    'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
                "
                [class.error]="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .touched &&
                          formGroup.get(field.groupByKey).get(field.name).errors
                        : formGroup.get(field.name).touched &&
                          formGroup.get(field.name).errors
                "
                [class.disabled]="
                    field.groupByKey
                        ? formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                        : formGeneratorInput.state === FormState.IDLE ||
                          (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                "
                [matTooltip]="
                    field.groupByKey
                        ? (field.populateFromControl &&
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl)) ||
                          (formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl) &&
                              (!formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value ||
                                  !formGroup
                                      .get(field.groupByKey)
                                      .get(field.populateFromControl).value
                                      .length))
                            ? 'Select ' +
                              (
                                  field.populateFromControl
                                  | fieldDataFromControlName
                                      : formGeneratorInput.fields
                              ).label +
                              ' values first'
                            : field.disabledText
                            ? field.disabledText
                            : null
                        : (field.populateFromControl &&
                              !formGroup.get(field.populateFromControl)) ||
                          (formGroup.get(field.populateFromControl) &&
                              (!formGroup.get(field.populateFromControl)
                                  .value ||
                                  !formGroup.get(field.populateFromControl)
                                      .value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : field.disabledText
                        ? field.disabledText
                        : null
                "
                [matTooltipShowDelay]="1000"
                [matTooltipPosition]="'before'"
            >
                <ng-select
                    #ngSelectRef
                    style="z-index: 10"
                    [appearance]="'legacy'"
                    [class.field-required]="
                        field.required !== undefined && !field.showLabel
                            ? field.required
                            : false
                    "
                    [bindValue]="
                        field.getKey || field.getKey === null
                            ? field.getKey
                            : 'id'
                    "
                    [bindLabel]="field.showKey ? field.showKey : 'label'"
                    [items]="
                        !loading.get(field.name) && field.listData
                            ? field.listData
                            : null
                    "
                    [closeOnSelect]="false"
                    [placeholder]="field.placeholder"
                    [loading]="loading.get(field.name)"
                    [appendTo]="'body'"
                    [loadingText]="
                        field.loadingMessage
                            ? field.loadingMessage
                            : 'Loading...'
                    "
                    dropdownPosition="auto"
                    [clearable]="true"
                    [formControl]="control"
                    [errorStateMatcher]="matcher"
                    [multiple]="true"
                    [maxSelectedItems]="
                        field.maxSelectionLimit
                            ? field.maxSelectionLimit
                            : Infinity
                    "
                    [virtualScroll]="true"
                    (scroll)="scroll($event)"
                    (change)="
                        onValueChange($event);
                        field.onChange
                            ? field.onChange($event, formGroup, field.name)
                            : null
                    "
                    [compareWith]="compareWith"
                    (open)="showOverlay = true"
                    (close)="showOverlay = false"
                >
                    <ng-container>
                        <ng-template
                            ng-multi-label-tmp
                            let-items="selectedValues"
                            let-clear="clear"
                        >
                            <div
                                class="ng-value"
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length > 0
                                        : formGroup.get(field.name).value
                                              .length > 0
                                "
                            >
                                <span class="ng-value-label"
                                    >{{
                                        loading.get(field.name)
                                            ? field.loadingMessage
                                                ? field.loadingMessage
                                                : "Loading..."
                                            : field.groupByKey
                                            ? formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length + " selected"
                                            : formGroup.get(field.name).value
                                                  .length + " selected"
                                    }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            !field.hideSelectAllInDropdown &&
                            !field.maxSelectionLimit &&
                            !loading.get(field.name) &&
                            field.listData &&
                            field.listData.length
                        "
                    >
                        <ng-template ng-header-tmp>
                            <div
                                *ngIf="
                                    field.groupByKey
                                        ? (formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value &&
                                              formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value
                                                  .length === 0) ||
                                          (field.listData.length &&
                                              !formGroup
                                                  .get(field.groupByKey)
                                                  .get(field.name).value)
                                        : (formGroup.get(field.name) &&
                                              formGroup.get(field.name).value &&
                                              formGroup.get(field.name).value
                                                  .length === 0) ||
                                          (getEnabledListDataLength(field) &&
                                              !formGroup.get(field.name).value)
                                "
                                (click)="onSelectAll(true)"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'ng-select-option-custom'
                                "
                            >
                                <i class="far fa-square clickable"></i>
                                <span class="common-label"> Select All</span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length <
                                              field.listData.length &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length !==
                                              0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length <
                                              getEnabledListDataLength(field) &&
                                          formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length !== 0
                                "
                                (click)="onSelectAll(true)"
                            >
                                <i class="fas fa-minus-square clickable"></i>
                                <span class="common-label"> Select All </span>
                            </div>
                            <div
                                class="selected-item ng-select-option-custom"
                                [ngClass]="
                                    field.disableDropdown
                                        ? 'disabled'
                                        : 'selected-item ng-select-option-custom'
                                "
                                *ngIf="
                                    field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name) &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value &&
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length ===
                                              field.listData.length &&
                                          field.listData.length > 0
                                        : formGroup.get(field.name) &&
                                          formGroup.get(field.name).value &&
                                          formGroup.get(field.name).value
                                              .length ===
                                              getEnabledListDataLength(field) &&
                                          field.listData.length > 0
                                "
                                (click)="onSelectAll(false)"
                            >
                                <i class="fas fa-check-square clickable"></i
                                ><span class="common-label"> Select All</span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-item$="item$"
                        let-index="index"
                    >
                        <div
                            appDisableElement
                            [data]="
                                field.groupByKey
                                    ? field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length
                                    : field.maxSelectionLimit &&
                                      field.maxSelectionLimit ===
                                          formGroup.get(field.name).value.length
                            "
                            *ngIf="!item$.selected"
                            class="ng-select-option-custom"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown far fa-square"></i>
                            <span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="item$.selected"
                            title="{{ showTooltip(item) }}"
                        >
                            <i class="checkbox-dropdown fas fa-check-square"></i
                            ><span class="common-label">{{
                                item[field.showKey ? field.showKey : "label"]
                            }}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <!-- Handling errors -->
                <ng-container *ngIf="!field.groupByKey">
                    <ng-template *ngTemplateOutlet="error"></ng-template>
                </ng-container>
                <ng-container *ngIf="field.groupByKey">
                    <ng-template *ngTemplateOutlet="groupError"></ng-template>
                </ng-container>
            </div>
        </div>
        <!-- field type seven end -->

        <ng-template #groupError>
            <ng-container
                *ngIf="
                    formGroup.get(field.groupByKey).get(field.name).touched &&
                    formGroup.get(field.groupByKey).get(field.name).errors
                "
            >
                <mat-error>
                    {{
                        field.validations
                            | formErrorValidation
                                : formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).errors
                    }}
                </mat-error>
            </ng-container>
        </ng-template>
        <ng-template #error>
            <ng-container
                *ngIf="
                    formGroup.get(field.name).touched &&
                    formGroup.get(field.name).errors
                "
            >
                <mat-error>
                    {{
                        field.validations
                            | formErrorValidation
                                : formGroup.get(field.name).errors
                    }}
                </mat-error>
            </ng-container>
        </ng-template>
    </div>
    <!-- Checkbox with dropdown start -->
    <ng-container
        *ngIf="filterInfo?.showCheckbox && showCheckbox && field.isFilter"
    >
        <indreka-checkbox
        [checked]="isDropdownChecked"
        (checkboxEvent)="checkboxWithDropdownToggled($event)"
        ></indreka-checkbox>
    </ng-container>
</div>
<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
