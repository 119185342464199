<div class="filter" *ngIf="filterInfo">
    <div class="dropdown">
        <div class="filter-head">
            <div
                class="filter-name"
                [class.light]="filterInfo.lightMode"
                *ngIf="filterInfo.label"
            >
                {{ filterInfo.label }}
            </div>
            <div class="filter-controls" *ngIf="filterData.associatedWidgets">
                <div
                    appIcon
                    *ngIf="
                        filterData &&
                        filterData.associatedWidgets &&
                        filterData.associatedWidgets.length
                    "
                    [matMenuTriggerFor]="associatedWidgetsMenu"
                    [matTooltip]="'Widgets associated with filter'"
                    [data]="infoIcon"
                    class="action"
                    [class.disabled]="disabled"
                    [matTooltip]="
                        disabled && disableMessage ? disableMessage : null
                    "
                ></div>
                <mat-menu #associatedWidgetsMenu="matMenu">
                    <div class="more-info-menu">
                        <div class="heading">
                            Filter is applicable on following insights
                        </div>
                        <div
                            class="info-item not-clickable"
                            *ngFor="
                                let widget of filterData.associatedWidgets;
                                let i = index
                            "
                        >
                            {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        <div class="filter-content">
            <app-single-select-dropdown
                [isCaching]="true"
                [refreshListing]="null"
                [filterInfo]="filterInfo"
                [selectedValue]="valueSelected"
                [clearable]="false"
                (change)="singleDropdownValueChange($event)"
            ></app-single-select-dropdown>
        </div>
    </div>
    <div class="percent">
        <div class="filter-head">
            <div class="filter-name" [class.light]="filterInfo.lightMode">
                Wastage
            </div>
        </div>
        <div class="filter-content">
            <div class="input-field" [class.disabled]="disablePercentage">
                <input
                    class="number-field"
                    [class.light]="filterInfo.lightMode"
                    type="number"
                    [value]="percentage"
                    [min]="0"
                    [max]="100"
                    placeholder="0%"
                    (change)="
                        !disablePercentage &&
                            this.valueSelected &&
                            percentageChanged($event.target.value)
                    "
                    [class.disabled]="disablePercentage"
                    [disabled]="disablePercentage || !this.valueSelected"
                />
                <div class="arrow up">▲</div>
                <div class="arrow down">▼</div>
            </div>
        </div>
    </div>
</div>
