import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';

@Component({
    selector: 'app-filters-template',
    templateUrl: './filters-template.component.html',
    styleUrls: ['./filters-template.component.sass']
})
export class FiltersTemplateComponent implements OnInit {
    @Input() filters: string[];
    @Input() filtersData: Map<string, IFilterInfo>;
    @Input() filterMenu: MatSidenav;
    filterIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel'
    };
    FilterType = FilterType;

    constructor() {}

    ngOnInit(): void {}
}
