<ng-container
    *ngIf="
        !(widgetRef.loadingData | async) &&
        (widgetRef.visibleSections | async) &&
        (widgetRef.visibleSections | async).has(ViewType.GRAPH)
    "
>
    <div class="tw-h-96">
        <app-graph-generator
            *ngIf="graphChartData"
            [graphData]="graphChartData"
        ></app-graph-generator>
    </div>
</ng-container>
