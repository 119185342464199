<div class="widget-configuration-step-container tw-flex tw-w-full tw-h-full">
    <div class="tabs-container">
        <div class="tabs">
            <ng-container *ngFor="let tab of tabList; let i = index">
                <div
                    class="tab tw-text-base"
                    [class.active]="tab.id === currentTab"
                    (click)="changeTab(tab)"
                >
                    <span class="name"> {{ tab.label }}</span>
                </div>
            </ng-container>
        </div>
    </div>
    <div
        class="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-h-full tw-ml-4"
    >
        <div
            class="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden"
            style="max-height: calc(100% - 45px)"
        >
            <div class="config-container tw-flex flex-1">
                <div
                    class="tw-flex-1 tw-p-4 tw-flex tw-flex-col tw-relative"
                    *ngIf="!isLoading"
                >
                    <span
                        class="tw-text-lg tw-font-bold tw-text-default tw-block tw-pb-2"
                        >{{ currentLabel }}</span
                    >
                    <ng-container *ngIf="currentTab === COLUMN_RENAME_KEY">
                        <div class="config tw-p-1">
                            <ng-container
                                *ngFor="
                                    let column of inputData.columnsToRename
                                        | keyvalue;
                                    let i = index
                                "
                            >
                                <div class="tw-flex tw-p-1">
                                    <div
                                        class="column-rename tw-rounded tw-flex-1 tw-p-2"
                                    >
                                        <input
                                            class="tw-outline-none tw-w-full tw-bg-transparent"
                                            type="text"
                                            name="column"
                                            disabled="true"
                                            [value]="column.value"
                                            placeholder="Column Name"
                                        />
                                    </div>

                                    <span
                                        class="tw-w-11 tw-h-full tw-flex tw-font-bold tw-text-default tw-justify-center tw-items-center"
                                        >AS</span
                                    >

                                    <div
                                        class="column-rename tw-rounded tw-flex-1 tw-p-2"
                                    >
                                        <input
                                            class="tw-outline-none tw-w-full tw-bg-transparent"
                                            type="text"
                                            name="column"
                                            [value]="
                                                columnsToRename.get(column.key)
                                            "
                                            [disabled]="
                                                !columnsEditableMode.get(i)
                                            "
                                            [class.disabled]="
                                                !columnsEditableMode.get(i)
                                            "
                                            (focusout)="
                                                columnNameChanged(
                                                    column.key,
                                                    $event
                                                )
                                            "
                                            placeholder="Column Name"
                                        />
                                    </div>

                                    <span
                                        [style.visibility]="
                                            columnsEditableMode.has(i)
                                                ? 'hidden'
                                                : 'visible'
                                        "
                                        class="tw-w-11 tw-h-full tw-flex tw-justify-center tw-items-center tw-text-accent tw-cursor-pointer"
                                        appIcon
                                        [data]="pencilIcon"
                                        [matTooltip]="'Click to Edit'"
                                        (click)="
                                            columnsEditableMode.set(i, true)
                                        "
                                    >
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentTab === REPORTING_KEY">
                        <mat-selection-list
                            class="config"
                            [(ngModel)]="selectedReportType"
                        >
                            <mat-list-option
                                checkboxPosition="before"
                                *ngFor="let field of reportingOptions"
                                [value]="field.id"
                            >
                                {{ field.label }}
                            </mat-list-option>
                        </mat-selection-list>
                        <div
                            [class.rotatingLoader]="downloadLoader"
                            class="tw-flex tw-items-center tw-cursor-pointer tw-left-4"
                            (click)="downloadReport()"
                        >
                            <span class="download tw-self-center tw-pr-2"
                                >Download Demo report</span
                            >
                            <span
                                class="tw-w-8 tw-h-8 tw-p-2"
                                appIcon
                                [data]="downloadIcon"
                            ></span>
                            <mat-spinner [diameter]="16" *ngIf="downloadLoader">
                            </mat-spinner>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentTab === FILTERS_KEY">
                        <div class="config tw-flex-row">
                            <mat-selection-list [(ngModel)]="selectedFilters">
                                <mat-list-option
                                    checkboxPosition="before"
                                    *ngFor="let field of filters"
                                    [value]="field.id"
                                >
                                    {{ field.label }}
                                </mat-list-option>
                            </mat-selection-list>
                            <div
                                class="tw-w-10 tw-h-10 tw-p-2 tw-absolute tw-top-3 tw-right-3 tw-cursor-pointer"
                                matTooltip="Toggle Filter"
                                appIcon
                                [data]="filterIcon"
                                (click)="filterMenu.toggle()"
                                [class.d-none]="filterMenu.opened"
                            ></div>

                            <mat-sidenav-container
                                [autosize]="true"
                                class="tw-absolute tw-top-3 tw-right-3 tw-bottom-3 tw-flex tw-flex-col"
                            >
                                <mat-sidenav
                                    class="sidenav"
                                    #filterMenu
                                    mode="side"
                                    position="end"
                                    [autoFocus]="false"
                                >
                                    <app-filters-template
                                        [filters]="selectedFilters"
                                        [filtersData]="filtersData"
                                        [filterMenu]="filterMenu"
                                    ></app-filters-template>
                                </mat-sidenav>
                            </mat-sidenav-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentTab === GRAPH_KEY">
                        <div class="config tw-flex-row tw-overflow-hidden">
                            <div
                                class="tw-flex tw-flex-col tw-justify-between tw-flex-1 tw-pt-4 tw-pl-1"
                            >
                                <div class="tw-flex tw-flex-col">
                                    <div
                                        class="dropdown tw-flex-1 tw-mb-5 tw-w-3/4"
                                    >
                                        <app-single-select-dropdown
                                            [filterInfo]="{
                                                placeholder:
                                                    'Select Data Value',
                                                listData: pieXAxisColumns
                                            }"
                                            [selectedValue]="xAxis"
                                            (change)="changeDataValue($event)"
                                        ></app-single-select-dropdown>
                                    </div>

                                    <div class="dropdown tw-flex-1 tw-w-3/4">
                                        <app-single-select-dropdown
                                            [filterInfo]="{
                                                placeholder:
                                                    'Select Data Label',
                                                listData: pieYAxisColumns
                                            }"
                                            [selectedValue]="yAxis"
                                            (change)="changeDataLabel($event)"
                                        ></app-single-select-dropdown>
                                    </div>

                                    <!-- Handling pie case when query shows result as no data available -->
                                    <ng-container
                                        *ngIf="
                                            queryData?.dataMap?.table
                                                ?.length === 0
                                        "
                                    >
                                        <ng-template
                                            *ngTemplateOutlet="infoTemplate"
                                        ></ng-template>
                                    </ng-container>
                                </div>

                                <div
                                    [class.disabled]="!xAxis || !yAxis"
                                    [matTooltip]="'Configure pie'"
                                    class="preview tw-cursor-pointer"
                                    (click)="showPreview()"
                                >
                                    Preview
                                </div>
                            </div>
                            <div
                                class="tw-flex-1 tw-flex tw-justify-center tw-items-center"
                            >
                                <ng-container *ngIf="pieTemplateInjector">
                                    <ng-container
                                        *ngComponentOutlet="
                                            previewData.componentToLoad;
                                            injector: pieTemplateInjector
                                        "
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="filters" *ngIf="currentTab === FILTERS_KEY"></div>
                <div class="overlay" *ngIf="isLoading">
                    <div class="overlay-content">
                        <div
                            appIcon
                            [data]="spinnerLoader"
                            class="overlay-loader"
                        ></div>
                    </div>
                </div>
            </div>
            <!-- Validation Error Handling -->
            <div *ngIf="validationError" class="tw-pt-2 tw-pb-2">
                <ng-template *ngTemplateOutlet="errorTemplate"></ng-template>
            </div>
        </div>
        <div class="btn-gen-container tw-h-12 tw-flex tw-items-end">
            <app-multi-button-generator
                [buttonGenInputs]="buttonGenInputs"
            ></app-multi-button-generator>
        </div>
    </div>
</div>
<ng-template #errorTemplate>
    <div
        class="error-template-content tw-w-full tw-h-9 tw-flex tw-items-center"
    >
        <span class="tw-h-8 tw-w-8 tw-p-2" appIcon [data]="errorIcon"> </span>
        <span class="tw-text-base error-message">{{ validationError }}</span>
    </div>
</ng-template>
<ng-template #infoTemplate>
    <div
        class="info-template-content tw-w-full tw-flex tw-items-center tw-mt-2"
    >
        <span class="tw-h-8 tw-w-8 tw-p-2" appIcon [data]="infoIcon"> </span>
        <span class="tw-text-sm info-message">
            Pie will not be plotted since no data is available
        </span>
    </div>
</ng-template>
