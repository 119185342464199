import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { Clouds } from 'src/app/shared/enums/Clouds';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { Helper } from './../../../../classes/Helper';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { FilterCacheService } from './../../../../services/cache/filters-cache/filter-cache.service';
import { HttpService } from './../../../../services/http/http-main/http.service';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-terraform-request-widget-modal',
    templateUrl: './terraform-request-widget-modal.component.html',
    styleUrls: ['./terraform-request-widget-modal.component.sass']
})
export class TerraformRequestWidgetModalComponent implements OnInit, OnDestroy {
    requestDetailFormGenInputs: IFormGeneratorInput;
    requestDetailFormGroup: FormGroup;
    providerDetailFormGenInputs: IFormGeneratorInput;
    providerDetailFormGroup: FormGroup;
    filterIds: string[];
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    showLoader: boolean = false;
    removeFile: IIcon = {
        type: IconType.SVG,
        class: 'cross_icon_v2'
    };
    providerFilesFormGenInputs: IFormGeneratorInput[] = [];
    providerFilesFormGroup: Map<Symbol, FormGroup> = new Map();
    widgetRef: Widget;
    cloud: Clouds;
    dynamicKeys: any;
    dynamicAzureCloudLabel: AzureCloudKeys;
    providerAccountIdDetail: any[] = [];
    providerRegionDetail: any[] = [];
    accountIds = [];
    regions = [];
    nextButtonGenInput: IButtonGeneratorInput;
    backButtonGenInput: IButtonGeneratorInput;
    currentStepData: any;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    alreadyExistRequestNames: string[];
    infoHeading: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-circle'
    };
    infoMessage: string;
    resetModal: Subscription;
    configureLocation: boolean = false;
    constructor(
        private httpService: HttpService,
        private filtersCacheService: FilterCacheService,
        private modalData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private listHttpService: ListHttpService,
        private userCacheService: UserDataCacheService,
        private configCacheService: ConfigCacheService
    ) {
        this.widgetRef = modalData.data['widgetRef'];
        this.cloud = this.modalData.data['cloud'];
        this.configureLocation = this.modalData.data['configureLocation'];
        this.infoMessage = this.getInfoMessage(this.cloud);
        this.dynamicKeys = Helper.setDynamicCloudFilterKeys(null, this.cloud);
        this.dynamicAzureCloudLabel = this.getAzureCloudLabels(this.cloud);
        this.nextButtonGenInput = {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.goToNextLevel();
            },
            showLoader: false
        };
    }

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(1)
        ) {
            this.currentStepData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1)['currentStepData'];
            this.providerFilesFormGenInputs =
                this.currentStepData['providerFilesFormGenInputs'];
            this.providerDetailFormGenInputs =
                this.currentStepData['providerDetailFormGenInputs'];
            this.providerFilesFormGroup =
                this.currentStepData['providerFilesFormGroup'];
        } else {
            this.filterIds = this.getAccAndRegionIds(this.cloud);
            this.generateProviderForm();
        }
        if (
            this.modalData.data &&
            this.modalData.data['alreadyExistRequestNames']
        ) {
            this.alreadyExistRequestNames =
                this.modalData.data['alreadyExistRequestNames'];
        }
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.requestDetailFormGroup.reset();
            this.providerFilesFormGenInputs = [];
            this.filterIds = this.getAccAndRegionIds(this.cloud);
            this.generateProviderForm();
        });
        this.generateForm();
    }
    generateProviderForm() {
        this.showLoader = true;
        const customeInputs = {
            showKey: 'label',
            responseValueKey: 'dataList',
            appearance: 'legacy',
            showLabel: true
        };
        Helper.generateFormInput(
            this.filterIds,
            this.httpService,
            this.filtersCacheService,
            customeInputs,
            null,
            null,
            null,
            null,
            null,
            this.userCacheService,
            this.configCacheService
        ).subscribe((form) => {
            if (form) {
                this.showLoader = false;
                this.providerDetailFormGenInputs = form;
                if (this.cloud.includes('azure') && this.configureLocation) {
                    this.providerDetailFormGenInputs.fields[0].onChange = (
                        event,
                        formGroup: FormGroup
                    ) => {
                        if (event) {
                            formGroup
                                .get(this.dynamicAzureCloudLabel.name)
                                .reset();
                            this.providerDetailFormGenInputs.fields[1].apiInfo =
                                this.widgetRef.widgetData.widgetInfo[
                                    'additionalApisForWidget'
                                ]['fetchAzureRegion'];
                            this.providerDetailFormGenInputs.fields[1].disabled =
                                false;
                            this.providerDetailFormGenInputs.fields[1].listData =
                                [];
                            this.providerDetailFormGenInputs.fields[1].disabledText =
                                null;
                            this.providerDetailFormGenInputs.fields[1].apiInput =
                                {
                                    accountId: event.id,
                                    cloud: this.cloud
                                };

                            this.updateControlInput = {
                                action: UpdateAction.REFRESH,
                                controls: [this.dynamicAzureCloudLabel.name]
                            };
                            this.updateControl.next(this.updateControlInput);
                        } else {
                            this.providerDetailFormGenInputs.fields[1].disabled =
                                true;
                            this.providerDetailFormGenInputs.fields[1].listData =
                                [];
                            this.providerDetailFormGenInputs.fields[1].disabledText = `Select ${this.dynamicAzureCloudLabel.accountId} Value First`;
                            this.updateControlInput = {
                                action: UpdateAction.UPDATE_VALUE,
                                controls: [this.dynamicKeys.regions],
                                value: []
                            };
                            this.updateControl.next(this.updateControlInput);
                        }
                        this.widgetRef.changeDetectorRef.detectChanges();
                    };
                    this.providerDetailFormGenInputs.fields.push({
                        label: this.dynamicAzureCloudLabel.region,
                        placeholder: this.dynamicAzureCloudLabel.placeholder,
                        name: this.dynamicAzureCloudLabel.name,
                        fieldType: FilterType.DROPDOWN_MULTIPLE,
                        showLabel: true,
                        appearance: 'legacy',
                        required: true,
                        apiInput: {},
                        listData: [],
                        disabled: true,
                        disabledText: `Select ${this.dynamicAzureCloudLabel.accountId} Value First`,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: `${this.dynamicAzureCloudLabel.region} is required.`
                            }
                        ]
                    });
                }
                this.providerFilesFormGenInputs.push({
                    ...this.providerDetailFormGenInputs
                });
                const index = form.fields.findIndex((field) => {
                    return field.label.includes('Regions');
                });
                if (index !== -1) {
                    const region = form.fields[index];
                    form.fields.splice(index, 1);
                    form.fields.push(region);
                }

                form.fields.forEach((field) => {
                    if (field.name.includes('Id')) {
                        field.fieldType = FilterType.DROPDOWN_SINGLE;
                    }
                });
            }
        });
    }
    getAccAndRegionIds(cloud) {
        const providerFilter =
            this.widgetRef.widgetData.widgetInfo['providerFilter'];
        switch (cloud) {
            case Clouds.AWS:
                return providerFilter['aws'];
            case Clouds.AZURE_PLAN:
                return providerFilter['azurePlan'];
            case Clouds.AZURE_CSP:
                return providerFilter['azureCsp'];
            case Clouds.AZURE_EA:
                return providerFilter['azureEa'];
            case Clouds.GCP:
                return providerFilter['gcp'];
        }
    }
    generateForm() {
        this.requestDetailFormGenInputs = {
            formName: 'titleForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Request Name',
                    placeholder: 'Request Name',
                    name: 'requestName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value:
                        this.currentStepData &&
                        this.currentStepData['requestInfo'] &&
                        this.currentStepData['requestInfo']['requestName']
                            ? this.currentStepData['requestInfo']['requestName']
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This Field is required.'
                        }
                    ]
                },
                {
                    label: 'Request Description',
                    placeholder: 'Request Description',
                    name: 'requestDescription',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: false,
                    value:
                        this.currentStepData &&
                        this.currentStepData['requestInfo'] &&
                        this.currentStepData['requestInfo'][
                            'requestDescription'
                        ]
                            ? this.currentStepData['requestInfo'][
                                  'requestDescription'
                              ]
                            : ''
                }
            ]
        };
        if (
            this.alreadyExistRequestNames &&
            this.alreadyExistRequestNames.length
        ) {
            this.requestDetailFormGenInputs.fields[0].validations.push({
                validator: CustomValidators.keyAlreadyExists(
                    this.alreadyExistRequestNames,
                    false
                ),
                errorMessage: 'Enter unique request name'
            });
        }
    }
    addDetails() {
        const formId =
            this.providerFilesFormGenInputs[
                this.providerFilesFormGenInputs.length - 1
            ].formId;
        const formGroup = this.providerFilesFormGroup.get(formId);

        Helper.markAllFieldAsTouched(formGroup);
        if (formGroup.invalid) {
            formGroup.updateValueAndValidity();
            return;
        }
        if (!this.accountIds.length) {
            this.providerDetailFormGenInputs.fields.forEach((field) => {
                if (field.name === this.dynamicKeys.accountIds) {
                    this.accountIds = Helper.cloneDeep(field.listData);
                } else {
                    this.regions = Helper.cloneDeep(field.listData);
                }
            });
        }

        const accountId =
            this.providerFilesFormGroup.get(formId).value[
                this.dynamicKeys.accountIds
            ];
        this.providerAccountIdDetail.push(accountId);
        const form = this.getForm();
        this.providerFilesFormGenInputs.push({
            ...form
        });
        this.providerFilesFormGenInputs = this.providerFilesFormGenInputs.map(
            (form, index) => {
                if (index !== this.providerFilesFormGenInputs.length - 1) {
                    form.state = FormState.IDLE;
                    form.fields.forEach((field) => {
                        if (field.name === this.dynamicKeys.accountIds) {
                            field.listData = this.accountIds;
                        }
                    });
                } else {
                    form.fields.forEach((field) => {
                        if (field.name === this.dynamicKeys.accountIds) {
                            const listData = this.accountIds.filter(
                                (listItem) => {
                                    if (
                                        !this.providerAccountIdDetail.includes(
                                            listItem.id
                                        )
                                    ) {
                                        return listItem;
                                    }
                                }
                            );
                            field.listData = [...listData];
                        }
                    });
                }

                return Helper.dereference(form);
            }
        );

        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getForm(formId?) {
        const form: IFormGeneratorInput = {
            formName: '',
            formId: formId ? formId : Symbol(),
            submitButton: null,
            state: FormState.EDIT,
            fields: [
                { ...this.providerDetailFormGenInputs.fields[0] },
                { ...this.providerDetailFormGenInputs.fields[1] }
            ]
        };
        if (this.cloud.includes('azure') && this.configureLocation) {
            form.fields.length = 1;
            form.fields[0].onChange = (event, formGroup: FormGroup) => {
                if (event) {
                    formGroup.get(this.dynamicAzureCloudLabel.name).reset();
                    form.fields[1].apiInfo =
                        this.widgetRef.widgetData.widgetInfo[
                            'additionalApisForWidget'
                        ]['fetchAzureRegion'];
                    form.fields[1].disabled = false;
                    form.fields[1].disabledText = null;
                    form.fields[1].apiInput = {
                        accountId: event.id,
                        cloud: this.cloud
                    };

                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: [this.dynamicAzureCloudLabel.name]
                    };
                    this.updateControl.next(this.updateControlInput);
                } else {
                    form.fields[1].disabled = true;
                    form.fields[1].disabledText = `Select ${this.dynamicAzureCloudLabel.accountId} Value First`;

                    this.updateControlInput = {
                        action: UpdateAction.UPDATE_VALUE,
                        controls: [this.dynamicKeys.regions],
                        value: []
                    };
                    this.updateControl.next(this.updateControlInput);
                }
                this.widgetRef.changeDetectorRef.detectChanges();
            };
            form.fields.push({
                label: this.dynamicAzureCloudLabel.region,
                placeholder: this.dynamicAzureCloudLabel.placeholder,
                name: this.dynamicAzureCloudLabel.name,
                fieldType: FilterType.DROPDOWN_MULTIPLE,
                showLabel: true,
                appearance: 'legacy',
                listData: [],
                required: true,
                apiInput: {},
                disabled: true,
                disabledText: `Select ${this.dynamicAzureCloudLabel.accountId} Value First`,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: `${this.dynamicAzureCloudLabel.region} is required.`
                    }
                ]
            });
        } else if (this.cloud.includes('azure') && !this.configureLocation) {
            form.fields.length = 1;
        }
        return form;
    }
    goToNextLevel() {
        Helper.markAllFieldAsTouched(this.requestDetailFormGroup);
        if (this.requestDetailFormGroup.invalid) {
            this.requestDetailFormGroup.updateValueAndValidity();
            return;
        }
        let formId;
        if (this.providerFilesFormGenInputs.length === 1) {
            formId =
                this.providerFilesFormGenInputs[
                    this.providerFilesFormGenInputs.length - 1
                ].formId;
        } else {
            formId =
                this.providerFilesFormGenInputs[
                    this.providerFilesFormGenInputs.length - 2
                ].formId;
        }

        const formGroup = this.providerFilesFormGroup.get(formId);
        Helper.markAllFieldAsTouched(formGroup);
        if (formGroup.invalid) {
            formGroup.updateValueAndValidity();
            return;
        }
        if (
            this.providerFilesFormGenInputs &&
            this.providerFilesFormGenInputs.length <= 1
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'Add Provider Details.',
                true
            );
            return;
        }
        const data = this.prepareStepData();
        const currentStepData = {};
        currentStepData['providerFilesFormGenInputs'] =
            this.providerFilesFormGenInputs;
        currentStepData['providerDetailFormGenInputs'] =
            this.providerDetailFormGenInputs;
        currentStepData['providerFilesFormGroup'] = this.providerFilesFormGroup;
        const stepData = {
            currentStepData: { ...currentStepData, ...data },
            data: data
        };

        this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .set(1, stepData);
        this.multiStepFormService.nextStep(this.modalData.modalId);
    }
    prepareStepData() {
        const input = {};
        const requestInfo = {
            requestName: this.requestDetailFormGroup.get('requestName').value,
            requestDescription:
                this.requestDetailFormGroup.get('requestDescription').value
        };
        const providerRequest = [];
        this.providerFilesFormGenInputs.forEach((form, index) => {
            if (index !== this.providerFilesFormGenInputs.length - 1) {
                const value = this.providerFilesFormGroup
                    .get(form.formId)
                    .getRawValue();
                value[this.dynamicKeys.accountIds] = [
                    value[this.dynamicKeys.accountIds]
                ];
                if (this.cloud.includes('azure') && !this.configureLocation) {
                    value[this.dynamicKeys.regions] = [];
                }
                providerRequest.push(value);
            }
        });
        input['requestInfo'] = requestInfo;
        input['providerRequest'] = providerRequest;
        return input;
    }
    deleteProviderDetail(index: number, formId: Symbol) {
        const accountId =
            this.providerFilesFormGroup.get(formId).value[
                this.dynamicKeys.accountIds
            ];
        this.addListData(accountId);

        this.providerAccountIdDetail = this.providerAccountIdDetail.filter(
            (id) => {
                if (!accountId.includes(id)) {
                    return id;
                }
            }
        );
        this.providerFilesFormGroup.delete(formId);
        this.providerFilesFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.providerFilesFormGenInputs
        );
    }
    addListData(accountId) {
        const accountListData = this.accountIds.filter((listItem) => {
            if (accountId.includes(listItem.id)) {
                return listItem;
            }
        });
        const formId =
            this.providerFilesFormGenInputs[
                this.providerFilesFormGenInputs.length - 1
            ].formId;
        this.providerFilesFormGenInputs.splice(
            this.providerFilesFormGenInputs.length - 1,
            1,
            this.getForm(formId)
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getInfoMessage(cloud) {
        const aws =
            'Selecting multiple accounts & regions signifies that script will run multiple times according to selected Account-Region pairs.';
        const azure =
            'Selecting multiple subscriptions & regions signifies that script will run multiple times according to selected Subscription-Region pairs.';
        const gcp =
            'Selecting multiple projects & regions signifies that script will run multiple times according to selected Project Id-Region pairs.';
        switch (cloud) {
            case Clouds.AWS:
                return aws;
            case Clouds.AZURE_PLAN:
            case Clouds.AZURE_CSP:
            case Clouds.AZURE_EA:
                return azure;
            case Clouds.GCP:
                return gcp;
            default:
                return aws;
        }
    }
    ngOnDestroy(): void {
        this.resetModal.unsubscribe();
    }
    getAzureCloudLabels(cloud: Clouds): AzureCloudKeys {
        const azureCsp: AzureCloudKeys = {
            accountId: 'CSP Subscription Ids',
            region: 'CSP Regions',
            placeholder: 'Select CSP Regions',
            name: 'regionsAzureCsp'
        };
        const azurePlan: AzureCloudKeys = {
            accountId: 'Plan IDs',
            region: 'Plan Regions',
            placeholder: 'Select Plan Regions',
            name: 'regionsAzurePlan'
        };
        const azureEa: AzureCloudKeys = {
            accountId: 'EA Subscription Ids',
            region: 'EA Regions',
            placeholder: 'Select EA Regions',
            name: 'regionsAzureEa'
        };
        switch (cloud) {
            case Clouds.AZURE_CSP:
                return azureCsp;
            case Clouds.AZURE_PLAN:
                return azurePlan;
            case Clouds.AZURE_EA:
                return azureEa;
        }
    }
}
export interface AzureCloudKeys {
    accountId: string;
    region: string;
    name: string;
    placeholder: string;
}
