import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { TerraformRequestWidgetFormModalComponent } from '../../../modal-templates/terraform/terraform-request-widget-form-modal/terraform-request-widget-form-modal.component';
import { TerraformRequestWidgetModalComponent } from '../../../modal-templates/terraform/terraform-request-widget-modal/terraform-request-widget-modal.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { TerraformMoreInfoModalComponent } from './../../../modal-templates/terraform/terraform-more-info-modal/terraform-more-info-modal.component';

@Component({
    selector: 'app-terraform-request-widget-listing',
    templateUrl: './terraform-request-widget-listing.component.html',
    styleUrls: ['./terraform-request-widget-listing.component.sass']
})
export class TerraformRequestWidgetListingComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    apiResponse: any;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            buttons: [
                {
                    buttonName: 'Request',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    function: (buttonRef) => {
                        if (buttonRef.loader) {
                            return;
                        }
                        buttonRef.loader = true;
                        const apiArgs: IHitApi = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo[
                                'additionalApisForWidget'
                            ]['myWidgets']
                        );
                        apiArgs.intactUrl = apiArgs.url;
                        apiArgs.url = apiArgs.url.replace(
                            '{widget_id}',
                            this.widgetRef.widgetData.widgetId
                        );
                        apiArgs.input = {};
                        apiArgs.function = (response) => {
                            buttonRef.loader = false;
                            this.createRequestModal(response);
                            this.widgetRef.changeDetectorRef.detectChanges();
                        };
                        apiArgs.errorFunction = (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error while getting data'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        };

                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    },
                    showLoader: true
                }
            ],
            afterResponse: (res: any) => {
                this.apiResponse = res;
            },
            selection: 'single',
            selectionLimit: GlobalConfiguration.CUSTOM_WIDGET_LIMIT,
            columns: [
                {
                    columnKey: 'Request Name',
                    columnName: 'Request Name',
                    flex: 1
                },
                {
                    columnKey: 'Request Status',
                    columnName: 'Request Status',
                    flex: 1,
                    cellRenderer: (rowData: RowEvent) => {
                        const columnContent = {
                            value: rowData.data['Request Status'],
                            type: FilterType.TEXT,
                            style: `color: ${
                                rowData.data['requestStatusColor']?.['hex']
                                    ? rowData.data['requestStatusColor']['hex']
                                    : ''
                            }`
                        };
                        const buttons = [
                            {
                                buttonName: '',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-sync-alt'
                                },
                                hoverText: 'Refresh Status.',
                                showLoader: false,
                                function: () => {
                                    this.widgetRef.refreshWidget();
                                }
                            }
                        ];
                        return Helper.generateColumnButtons(
                            buttons,
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            columnContent
                        );
                    }
                },
                {
                    columnKey: 'Request Date and Time',
                    columnName: 'Requested Date & Time',
                    flex: 1
                },
                {
                    columnKey: '',
                    columnName: 'Action',
                    filter: false,
                    pinned: 'right',
                    maxWidth: 200,
                    minWidth: 200,
                    headerClass: 'grid-cell-data-centred',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'More Info',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: ''
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: true,
                                disable:
                                    rowData.data['Request Status'] ===
                                    RequestStatus.PENDING,
                                function: (buttonRef) => {
                                    buttonRef.loader = true;
                                    const hitApi: IHitApi = {
                                        url: rowData.data.moreInfo['Logs'],
                                        requestType: RequestType.GET,
                                        config: {
                                            ignoreBaseUrl: true,
                                            authorization:
                                                AuthorizationType.NOT_AUTHORIZED,
                                            responseType: 'text'
                                        },
                                        function: (response) => {
                                            buttonRef.loader = false;
                                            this.openMoreInfoModal(
                                                rowData.data.moreInfo,
                                                response
                                            );
                                            this.widgetRef.changeDetectorRef.detectChanges();
                                        },
                                        errorFunction: (error) => {
                                            Helper.showErrorMessage(
                                                this.widgetRef
                                                    .notificationsService,
                                                error
                                            );
                                            buttonRef.loader = false;
                                            this.widgetRef.changeDetectorRef.detectChanges();
                                        },
                                        input: null,
                                        uniqueIdentity: Symbol()
                                    };

                                    new HitApi(
                                        hitApi,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'Destroy',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                showLoader: true,
                                disable:
                                    rowData.data['Request Status'] ===
                                        RequestStatus.PENDING ||
                                    rowData.data['Request Status'] ===
                                        RequestStatus.ERROR ||
                                    rowData.data['Request Status'] ===
                                        RequestStatus.DESTROYED,
                                function: (modalId: Symbol) => {
                                    const apiInfo =
                                        this.widgetRef.widgetData.widgetInfo
                                            .delete;
                                    const apiConfig =
                                        Helper.generateHitApiConfig(apiInfo);
                                    apiConfig.intactUrl = apiConfig.url;
                                    apiConfig.url = apiConfig.url.replace(
                                        '{requestId}',
                                        rowData.data['id']
                                    );
                                    apiConfig.input = {};
                                    apiConfig.function = (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Resource(s) Deleted Successfully.'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                        this.widgetRef.refreshWidget();
                                    };
                                    apiConfig.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiConfig,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                },
                                executeFunctionAfterConfirmation: {
                                    modalName: 'Destroy',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'far fa-trash-alt'
                                    },
                                    contextIcon: {
                                        extraClass: 'color-accent',
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-exclamation-triangle'
                                    },
                                    confirmationMessage:
                                        'Are you sure you want to delete the resources created via this request?',
                                    buttonText: 'Destroy',
                                    buttonColorType: ButtonColorType.WARN,
                                    loaderOnExec: true
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            noDataMessage: 'Request to run your first terraform script.'
        };
    }
    openMoreInfoModal(data, logs) {
        const modalData: IModalData = {
            modalName: 'More Information',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },

            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: TerraformMoreInfoModalComponent,
                        payload: {
                            data: {
                                moreInfoData: data,
                                widgetRef: this.widgetRef,
                                logs: logs
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    createRequestModal(response) {
        const modalData: IModalData = {
            modalName: response['widgetName'],
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },

            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 85,
            modalHeightVh: 95,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: TerraformRequestWidgetModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                cloud: response['extraInfo']['terraformCloud'],
                                alreadyExistRequestNames: this.apiResponse.map(
                                    (each) =>
                                        each['Request Name'].toLocaleLowerCase()
                                ),
                                configureLocation: response['configureLocation']
                            }
                        }
                    },
                    headingText: response['widgetName'],
                    stepName: '',
                    resetModal: true
                },
                {
                    stepData: {
                        componentToLoad:
                            TerraformRequestWidgetFormModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                form: response['form'],
                                isRequestWidget: true,
                                cloud: response['extraInfo']['terraformCloud'],
                                requestDetaiils: response
                            }
                        }
                    },
                    stepName: '',
                    headingText: `${response['widgetName']} Form`,
                    resetModal: true
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
}
export enum RequestStatus {
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    DESTROYED = 'DESTROYED'
}
