import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'descriptionContentGenerator'
})
export class DescriptionContentGeneratorPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(item: string, sections, selectedSection, data): unknown {
        let initialString = item;
        if (item) {
            const regex = /\${([^}]+)}/g;
            const variables = item.match(regex) ? item.match(regex) : [];
            if (variables.length) {
                variables.forEach((variable$) => {
                    const firstBracket = variable$.indexOf('{');
                    const secondBracket = variable$.indexOf('}');
                    const variable = variable$.substring(
                        firstBracket + 1,
                        secondBracket
                    );
                    const placeholders = Object.keys(sections).length
                        ? sections[selectedSection]
                        : data.defaultPlaceholders;
                    if (placeholders[variable]) {
                        if (
                            placeholders[variable].type === 'FONTAWESOME_ICON'
                        ) {
                            if (
                                placeholders[variable].iconClass ===
                                'fas fa-check-circle'
                            ) {
                                const icon = document.createElement('img');
                                icon.src = '../../../assets/green_check.png';
                                icon.style.height = '1rem';
                                icon.style.marginRight = '6px';
                                initialString = initialString
                                    .split(variable$)
                                    .join(icon.outerHTML);
                            } else if (
                                placeholders[variable].iconClass ===
                                'fas fa-exclamation-triangle'
                            ) {
                                const icon = document.createElement('img');
                                icon.src = '../../../assets/yellow_check.png';
                                icon.style.height = '1rem';
                                icon.style.marginRight = '6px';
                                initialString = initialString
                                    .split(variable$)
                                    .join(icon.outerHTML);
                            } else {
                                const icon = document.createElement('i');
                                icon.className =
                                    placeholders[variable].iconClass;
                                icon.style.color = placeholders[variable].color;
                                initialString = initialString
                                    .split(variable$)
                                    .join(icon.outerHTML);
                            }
                        }
                        if (placeholders[variable].type === 'TEXT') {
                            initialString = initialString
                                .split(variable$)
                                .join(placeholders[variable].value);
                        }
                    }
                });
            }
        }
        return this.sanitizer.bypassSecurityTrustHtml(initialString);
    }
}
