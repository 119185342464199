import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Map } from 'immutable';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { DropdownMultipleFieldAppearance } from 'src/app/shared/enums/AppearanceType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { IDropdownData } from './../../../interfaces/dropdown-data/IDropdownData';

@Component({
    selector: 'app-multi-dropdown-field',
    templateUrl: './multi-dropdown-field.component.html',
    styleUrls: ['./multi-dropdown-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiDropdownFieldComponent,
            multi: true
        }
    ]
})
export class MultiDropdownFieldComponent
    implements OnInit, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    @Input() loading: Map<string, boolean>;
    @Input() compareWith: any;
    @Input() filterStoreKey: FilterStoreKey;
    @Output() valueChange = new EventEmitter<any>();
    @Output() selectAllValue = new EventEmitter<any>();
    @Output() onScroll = new EventEmitter<any>();
    @Output() onChecked = new EventEmitter<any>();
    @Output() getFilteredData = new EventEmitter<any>();
    FilterType = FilterType;
    FormState = FormState;
    Infinity = Infinity;
    showOverlay = false;
    AppearanceType = DropdownMultipleFieldAppearance;
    onChange: any = () => {};
    onTouch: any = () => {};
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    isDropdownChecked: boolean = false;
    filterInfo: IFilterInfo;
    showCheckbox: boolean = true;
    showSelectAll: boolean = true;
    @ViewChild('ngSelectRef') ngSelect;
    constructor(
        private filterService: FiltersService,
        private filterCacheService: FilterCacheService,
        private userCacheService: UserDataCacheService,
        private configCache: ConfigCacheService,
        private ngZone: NgZone
    ) {}
    ngOnInit(): void {
        const existingAppearance = Object.values(this.AppearanceType);
        if (
            !existingAppearance.includes(
                this.field.appearance as DropdownMultipleFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }

        this.filterService.resetFilters.subscribe((res) => {
            if (
                !res &&
                (this.filterStoreKey === this.filterService.currentFilterView ||
                    !this.filterService.currentFilterView)
            ) {
                this.showCheckbox = false;
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.isDropdownChecked = false;
                        this.showCheckbox = true;
                    }, 0);
                });
            }
        });
        const filtersData = this.filterCacheService.getFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            this.filterStoreKey
        );
        if (filtersData && filtersData[this.field.id]) {
            this.filterInfo = filtersData[this.field.id].filterInfo;
        }

        if (this.filterInfo) {
            this.isDropdownChecked =
                filtersData[this.field.id] &&
                filtersData[this.field.id].isChecked
                    ? true
                    : filtersData[this.filterInfo.checkboxSelector] &&
                      filtersData[this.filterInfo.checkboxSelector].value &&
                      filtersData[
                          this.filterInfo.checkboxSelector
                      ].value.includes(this.filterInfo.checkboxValue)
                    ? true
                    : false;

            if (
                this.isDropdownChecked ||
                (filtersData[this.filterInfo.checkboxSelector] &&
                    filtersData[this.filterInfo.checkboxSelector].value &&
                    filtersData[this.filterInfo.checkboxSelector].value
                        .length &&
                    filtersData[
                        this.filterInfo.checkboxSelector
                    ].value.includes(this.filterInfo.checkboxValue))
            ) {
                this.onChecked.emit(true);
            }
        }

        this.control = this.findControl();
        this.filterInfo = this.filterService.currentPageFilterInfo.has(
            this.field.id
        )
            ? this.filterService.currentPageFilterInfo.get(this.field.id)
            : null;
        this.filterService.refreshWidget.subscribe((res) => {
            if (res) {
                this.isDropdownChecked =
                    this.filterService.checkboxValue &&
                    this.filterService.checkboxValue.get(this.filterStoreKey) &&
                    this.filterService.checkboxValue
                        .get(this.filterStoreKey)
                        .get(this.field.id)
                        ? this.filterService.checkboxValue
                              .get(this.filterStoreKey)
                              .get(this.field.id)
                        : false;
            }
        });
        if (this.filterInfo && this.filterInfo.selectionLimit) {
            this.showSelectAll = false;
        }
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(obj: any): void {
        this.control = this.findControl();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {}
    onSelectAll(selectAll: boolean) {
        if (
            this.ngSelect &&
            this.ngSelect.itemsList &&
            this.ngSelect.itemsList.filteredItems &&
            this.ngSelect.itemsList.filteredItems.length
        ) {
            this.control.markAsDirty();
            this.getFilteredData.emit(this.ngSelect.itemsList.filteredItems);
        }

        this.selectAllValue.emit(selectAll);
    }
    onValueChange(event) {
        if (
            this.filterInfo &&
            this.filterInfo.selectionLimit &&
            event.length > this.filterInfo.selectionLimit
        ) {
            return;
        }
        const selectedValues: IDropdownData[] = [];
        if (event && event.length) {
            event.forEach((selectedValue: IDropdownData) => {
                if (this.field.getKey) {
                    selectedValues.push(selectedValue[this.field.getKey]);
                } else if (this.field.getKey === null) {
                    selectedValues.push(selectedValue);
                } else {
                    selectedValues.push(selectedValue.id);
                }
            });
        }
        this.onChange(selectedValues);
        this.valueChange.emit(event);
    }
    scroll(event) {
        this.onScroll.emit(event);
    }
    checkboxWithDropdownToggled(event: Event) {
        const customEvent = event as CustomEvent;
        const data = customEvent.detail;
        this.onChecked.emit(data);
    }
    showTooltip(item) {
        if (item['hoveredText']) {
            return item['hoveredText'];
        } else if (item['hoveredJSON']) {
            let text = '';
            Object.keys(item['hoveredJSON']).forEach((key) => {
                text += key + ': ' + item['hoveredJSON'][key] + ' \n ';
            });
            return text;
        } else {
            return item['label'];
        }
    }
    getEnabledListDataLength(field: IFormField) {
        return field.listData.filter((data) => !data.disabled).length;
    }
}
