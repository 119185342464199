import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-terraform-preview-resources-modal',
    templateUrl: './terraform-preview-resources-modal.component.html',
    styleUrls: ['./terraform-preview-resources-modal.component.sass']
})
export class TerraformPreviewResourcesModalComponent implements OnInit {
    @Input() previewData: any;
    @Input() widgetRef: Widget;
    accountIdsFormGenInputs: IFormGeneratorInput;
    accountIdsFormGroup: FormGroup;
    regionFormGenInputs: IFormGeneratorInput;
    regionFormGroup: FormGroup;
    tableInputs: ITableGeneratorInput;
    tableData: any;
    accountIdsListData: IDropdownData[];
    regionListData: IDropdownData[];
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    resources: any;
    tablesData: BehaviorSubject<null> = new BehaviorSubject<any>(null);
    isPreview: boolean = false;
    modalPreviewData: any;
    accIdWithRegion: Object;
    closeButtonGenInput: IButtonGeneratorInput;
    showMessage: boolean = false;
    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.isPreview = this.modalData.data['isPreview'];
        if (!this.isPreview) {
            this.resources = this.previewData['Resources'];
            this.accIdWithRegion =
                this.previewData['Request Details']['Account ID'];
            this.getAccountsIds(this.accIdWithRegion);
            this.generateForm();
        } else {
            this.modalPreviewData = this.modalData.data['modalPreviewData'];
            this.widgetRef = this.modalData.data['widgetRef'];
            this.resources = this.modalPreviewData;
            this.generatePreviewData(this.modalPreviewData);
        }
        this.setUpFullWidthCellRenderer();
        this.generateForm();
        this.generateButtons();
    }
    generateButtons() {
        this.closeButtonGenInput = {
            buttonName: 'Go To Request Form',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.modalService.closeModal(null, this.modalData.modalId);
            },
            showLoader: false
        };
    }
    generatePreviewData(data) {
        this.accIdWithRegion = {};
        data.forEach((resource) => {
            if (this.accIdWithRegion[resource.accountId]) {
                this.accIdWithRegion[resource.accountId] = [
                    resource.region,
                    ...this.accIdWithRegion[resource.accountId]
                ];
            } else {
                this.accIdWithRegion[resource.accountId] = [resource.region];
            }
        });
        this.getAccountsIds(this.accIdWithRegion);
    }
    generateForm() {
        this.accountIdsFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Account Ids',
                    placeholder: 'Select Account Ids',
                    name: 'accountId',
                    listData: this.accountIdsListData,
                    fieldType: FilterType.DROPDOWN_SINGLE
                }
            ]
        };
        this.regionFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Regions',
                    placeholder: 'Select Region',
                    name: 'region',
                    listData: this.regionListData,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    onChange: (data) => {
                        this.regionSelected(data);
                    }
                }
            ]
        };
    }
    getAccountsIds(data) {
        const accountIds = Object.keys(data);
        this.accountIdsListData = Helper.mapToDropdownData(accountIds);
    }
    accountSelected(accountId) {
        const regions = this.accIdWithRegion[accountId['accountId']];
        if (regions && regions.length) {
            this.regionListData = Helper.mapToDropdownData(regions);
        } else {
            this.tableInputs = null;
            this.showMessage = false;
        }
        this.updateControlInput = {
            action: UpdateAction.UPDATE_DROPDOWN,
            controls: ['region'],
            value:
                accountId['accountId'] && this.regionListData
                    ? this.regionListData
                    : []
        };
        this.cd.detectChanges();
        this.updateControl.next(this.updateControlInput);
    }
    regionSelected(event) {
        if (event) {
            this.tableData = [];
            this.tableInputs = null;
            const tableData = this.resources.find((data) => {
                return event['id'] === data.region;
            });
            if (this.isPreview && tableData) {
                this.generateTableDataForPreviewMode(tableData);
            } else {
                this.generateTableData(tableData);
            }
        } else {
            if (this.showMessage) {
                this.showMessage = false;
            }
            this.tableInputs = null;
        }
    }
    generateTableDataForPreviewMode(data) {
        const tableData = [];
        const resources = data['resource'];
        resources.forEach((resource) => {
            let requestedStateAtt = resource.replaceAll('/', '');
            requestedStateAtt = JSON.parse(requestedStateAtt);
            const attributes = Object.keys(requestedStateAtt);
            const index = attributes.findIndex(
                (attribute) => attribute === 'resource_type'
            );
            if (index !== -1) {
                const getType = attributes.splice(index, 1);
                attributes.splice(0, 0, getType[0]);
            }
            attributes.forEach((att) => {
                if (att) {
                    const obj = {};
                    obj['attributes'] = att;
                    if (att === 'resource_type') {
                        obj['isFullWidthCell'] = true;
                        obj['attributes'] = requestedStateAtt[att];
                    } else {
                        obj['requestedResource'] = requestedStateAtt[att];
                    }

                    tableData.push(obj);
                }
            });
        });
        this.setTableData(tableData);
    }
    generateTableData(data) {
        const resources = [];
        if (
            data &&
            data['requestedResource'] &&
            data['requestedResource'].length
        ) {
            this.showMessage = false;
            let noInstance: boolean = false;
            data['requestedResource'].forEach((ele, index) => {
                const fullRowObj = {};
                fullRowObj['attributes'] = ele['type'];
                fullRowObj['isFullWidthCell'] = true;
                resources.push(fullRowObj);
                if (!ele['instances'].length) {
                    noInstance = true;
                    return;
                }
                const attributes = Object.keys(
                    ele['instances'][0]['attributes']
                );
                const requestedStateAtt = ele['instances'][0]['attributes'];
                const currentStateAtt =
                    data &&
                    data['currentResource'] &&
                    data['currentResource'][index]
                        ? data['currentResource'][index]['instances'][0][
                              'attributes'
                          ]
                        : [];
                attributes.forEach((att) => {
                    const obj = {};
                    obj['attributes'] = att;
                    obj['currentResource'] = currentStateAtt[att];
                    obj['requestedResource'] = requestedStateAtt[att];
                    resources.push(obj);
                });
            });
            if (noInstance) {
                this.showMessage = true;
                this.tableData = [];
                this.tableInputs = null;
                return;
            }
            this.setTableData(resources);
        } else if (
            data &&
            data['requestedResource'] &&
            !data['requestedResource'].length
        ) {
            this.showMessage = true;
            this.tableData = [];
            this.tableInputs = null;
        } else {
            this.tableData = [];
            this.tableInputs = null;
        }
    }
    setTableData(tableData) {
        this.tableData = tableData;

        this.tablesData.next(this.tableData);
        this.tableInputs = {
            afterResponse: null,
            widgetIconData: null,
            selection: 'single',
            buttons: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Attributes',
                    columnKey: 'attributes',
                    pinned: 'left'
                },
                {
                    columnName: 'Requested State',
                    columnKey: 'requestedResource',
                    columnValueGetter: this.requstedValueGetter.bind(this)
                }
            ]
        };
        if (!this.isPreview) {
            this.tableInputs.columns.push({
                columnName: 'Current State',
                columnKey: 'currentResource',
                columnValueGetter: this.currentValueGetter.bind(this),
                cellStyle: (param) => {
                    return {
                        justifyContent: 'center'
                    };
                }
            });
        }
        this.cd.detectChanges();
    }
    requstedValueGetter(param) {
        if (
            typeof param.data.requestedResource === 'object' &&
            !(param.data.requestedResource instanceof Array)
        ) {
            return JSON.stringify(param.data.requestedResource);
        } else if (
            typeof param.data.requestedResource === 'object' &&
            param.data.requestedResource instanceof Array
        ) {
            let value = '';
            param.data.requestedResource.forEach((ele) => {
                value += JSON.stringify(ele);
            });
            return value;
        }
        return param.data.requestedResource
            ? param.data.requestedResource
            : '-';
    }
    currentValueGetter(param) {
        if (
            typeof param.data.currentResource === 'object' &&
            !(param.data.currentResource instanceof Array)
        ) {
            return JSON.stringify(param.data.currentResource);
        } else if (
            typeof param.data.currentResource === 'object' &&
            param.data.currentResource instanceof Array
        ) {
            let value = '';
            param.data.currentResource.forEach((ele) => {
                value += JSON.stringify(ele);
            });
            return value;
        }
        return param.data.currentResource ? param.data.currentResource : '-';
    }
    setUpFullWidthCellRenderer() {
        this.widgetRef.fullWidthCellRenderer = (rowNode: RowNode) => {
            const div = document.createElement('div');
            div.innerHTML =
                '<div class="full-width-cell">' +
                rowNode.data['attributes'] +
                '</div>';

            return div.firstChild;
        };
    }
}
