import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { IGraphData } from '../../../../interfaces/graph/IGraphData';
import { GraphType } from './../../../../enums/GraphType';
@Component({
    selector: 'app-single-stacked-bar',
    templateUrl: './single-stacked-bar.component.html',
    styleUrls: ['./single-stacked-bar.component.sass']
})
export class SingleStackedBarComponent implements OnInit, AfterViewInit {
    widgetRef: Widget;
    filteredSearchData: any[];
    tableData: any[];
    tableGenInput: ITableGeneratorInput = null;
    gridRef: GridOptions;
    ViewType = ViewType;
    tableKeys: any[] = [];
    graphData: IGraphData;
    constructor(
        widgetData: WidgetInjectedData,
        private globalDataService: GlobalDataService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.refreshWidgetCallback = () => {
            this.tableGenInput = null;
            this.widgetRef.apiResponse = null;
            this.tableData = [];
        };
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
        this.widgetRef.setRedrawSection(this.redrawSection.bind(this));
    }

    redrawSection(data) {
        this.prepareChartData(data.dataMap.table);
    }

    bindData(data) {
        if (!data) {
            return;
        }

        this.widgetRef.apiResponse = null;
        this.widgetRef.showViewIcon.next(true);
        this.widgetRef.apiResponse = data as any;
        this.filteredSearchData = [];

        if (data.dataMap && !Object.keys(data.dataMap).length) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.widgetActions.next([]);
            this.widgetRef.loadingData.next(false);
            return;
        }

        const widgetActions: IAction[] = [
            this.widgetRef.widgetConfigState.actions.graph,
            this.widgetRef.widgetConfigState.actions.table
        ];
        this.widgetRef.widgetActions.next(widgetActions);

        this.widgetRef.visibleSections.next(
            new Set([ViewType.TABLE, ViewType.GRAPH])
        );
        this.widgetRef.endLoader();
        this.drawTable();
        this.prepareChartData(data.dataMap.table);
    }

    prepareChartData(data) {
        const chartObj = {
            category: this.tableKeys[0]
        };
        data.map((each) => {
            if (each[this.tableKeys[0]] !== 'Total') {
                if (chartObj[each[this.tableKeys[0]]]) {
                    chartObj[each[this.tableKeys[0]]] =
                        Number(
                            Helper.parseCommaSeperatedStringToNumber(
                                chartObj[each[this.tableKeys[0]]]
                            )
                        ) +
                        Number(
                            Helper.parseCommaSeperatedStringToNumber(
                                each[this.tableKeys[1]]
                            )
                        );
                } else {
                    chartObj[each[this.tableKeys[0]]] = Number(
                        Helper.parseCommaSeperatedStringToNumber(
                            each[this.tableKeys[1]]
                        )
                    );
                }
            }
        });

        this.graphData = {
            graphType: GraphType.STACK_BAR,
            labelText: {
                categoryX: 'category',
                xAxesTitle: '',
                yAxesTitle: this.tableKeys[1]
            },
            disableScrollBarX: true,
            disableScrollBarY: true,
            toggleLegend: false,
            height: 215,
            chartData: [chartObj],
            extraClass: 'tw-flex-col',
            showTextOnChartTemplate: true,
            clickableLegendOnHover: true,
            seriesList: Object.keys(chartObj).filter(
                (key) => key !== 'category'
            )
        };
    }

    drawTable() {
        const columnData: IColumnData[] = [];
        this.tableKeys = [];
        this.tableData = [];
        this.tableGenInput = null;

        this.tableKeys = (this.widgetRef.apiResponse as any).dataMap.tableKeys;

        this.tableKeys.map((row) => {
            columnData.push({
                columnKey: row,
                columnName: row
            });
        });

        this.tableData = Helper.cloneDeep(
            (this.widgetRef.apiResponse as any).dataMap.table
        );

        this.tableData.push({
            [this.tableKeys[1]]: (this.widgetRef.apiResponse as any).dataMap
                .totalCost,
            [this.tableKeys[0]]: 'Total'
        });

        this.filteredSearchData = this.tableData;

        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            columns: columnData,
            tableHeight: 216
        };
    }

    onQuickFilterChanged(id: string) {
        this.gridRef.api.setQuickFilter(document.getElementById(id)['value']);
    }
}
