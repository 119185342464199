import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorHandlerService } from '../services/error-handler/error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    errorHandlerService = null;

    constructor(private injector: Injector) {
        setTimeout(() => {
            this.errorHandlerService = injector.get(ErrorHandlerService);
        }, 0);
    }

    handleError(error) {
        if (this.errorHandlerService === null) {
            this.errorHandlerService = this.injector.get(ErrorHandlerService);
            this.errorHandlerService.pushError(error);
        } else {
            this.errorHandlerService.pushError(error);
        }
    }
}
