<mat-button-toggle-group
    [(ngModel)]="selectedValue"
    name="toggle"
    (change)="valueChanged($event)"
    appearance="legacy"
    aria-label="Font Style"
>
    <ng-container *ngFor="let value of filterInfo.listData">
        <mat-button-toggle
            [class.light]="filterInfo.lightMode"
            [value]="value.id + ''"
            >{{ value.label }}</mat-button-toggle
        >
    </ng-container>
</mat-button-toggle-group>
