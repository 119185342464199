import { Pipe, PipeTransform } from '@angular/core';
import { IconLibrary } from '../classes/IconLibrary';
import { IconType } from '../enums/IconType';
import { IIcon } from '../interfaces/icon-data/IIcon';

@Pipe({
    name: 'attributeToIconDirective'
})
export class AttributeToIconDirectivePipe implements PipeTransform {
    transform(
        iconType: IconType,
        iconData: string,
        extraClass?: string,
        active?: boolean
    ): IIcon {
        if (iconType === IconType.SVG) {
            return {
                type: iconType,
                class:
                    iconType === IconType.SVG &&
                    active &&
                    `${iconData}_filled` in IconLibrary.icon
                        ? `${iconData}_filled`
                        : iconData,

                extraClass
            };
        } else if (iconType === IconType.NUMBER) {
            return {
                type: iconType,
                extraData: iconData,
                class: 'number-icon'
            };
        } else {
            return {
                type: iconType,
                class: iconData,
                extraClass
            };
        }
    }
}
