<div
    *ngIf="(currencySymbolValue | async) && (potentialSavingsValue | async)"
    class="tw-mx-auto tw-my-2 tw-w-full general"
    style="min-height: 39px"
>
    <span class="header-web-view"> Potential Savings: &nbsp;</span>
    <span class="value">
        <ng-container *ngIf="currencySymbolValue | async">
            {{ currencySymbolValue | async }}
        </ng-container>

        <ng-container *ngIf="potentialSavingsValue | async">
            {{ potentialSavingsValue | async }}
        </ng-container>
    </span>
</div>
<ng-template #controls>
    <div class="controls" *ngIf="!(widgetRef.loadingData | async)">
        <div class="overview">
            <app-multi-button-generator
                [buttonGenInputs]="controlButtons"
                [options]="controlButtonOption"
            ></app-multi-button-generator>
        </div>
    </div>
</ng-template>

<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    [overviewTemplateStart]="controls"
    (gridRef)="gridRef = $event"
    (selectionChanged)="onSelectionChanged()"
    [showSearchBox]="showSearchBox"
></app-table-generator>
