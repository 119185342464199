<!-- field type one start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_1">
    <div class="ng-select-dropdown-container">
        <div
            [class]="
                'ng-select-custom-material-wrapper ng-select-custom-material-wrapper-outline'
            "
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [class.disabled]="
                field.groupByKey
                    ? formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                    : formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
            "
            [matTooltip]="
                field.groupByKey
                    ? (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                    : (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
            "
            [matTooltipShowDelay]="1000"
            [matTooltipPosition]="'before'"
        >
            <legend
                class="dropdown-legend"
                *ngIf="
                    field.groupByKey
                        ? formGroup.get(field.groupByKey).get(field.name)
                              .value &&
                          formGroup.get(field.groupByKey).get(field.name).value
                              .length
                        : formGroup.get(field.name).value &&
                          formGroup.get(field.name).value.length
                "
            >
                {{
                    field.placeholder
                        ? field.placeholder +
                          (field.required !== undefined
                              ? field.required
                                  ? " *"
                                  : ""
                              : " *")
                        : field.label +
                          (field.required !== undefined
                              ? field.required
                                  ? " *"
                                  : ""
                              : " *")
                }}
                <mat-icon
                    *ngIf="field.prefixIcon"
                    matPrefix
                    [matTooltip]="field.prefixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.prefixIcon.function
                            ? field.prefixIcon.function(field, formGroup)
                            : null
                    "
                    >{{ field.prefixIcon.iconData.class }}</mat-icon
                >
            </legend>

            <ng-select
                style="z-index: 10"
                [appearance]="'outline'"
                [class.field-required]="
                    field.required !== undefined && !field.showLabel
                        ? field.required
                        : false
                "
                [bindValue]="
                    field.getKey || field.getKey === null ? field.getKey : 'id'
                "
                [bindLabel]="field.showKey ? field.showKey : 'label'"
                [items]="
                    !loading.get(field.name) && field.listData
                        ? field.listData
                        : null
                "
                [groupBy]="field.groupBy ? field.groupBy : null"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                [closeOnSelect]="false"
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [loading]="loading.get(field.name)"
                [appendTo]="'body'"
                [loadingText]="
                    field.loadingMessage ? field.loadingMessage : 'Loading...'
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
                dropdownPosition="auto"
                [clearable]="true"
                [multiple]="true"
                [maxSelectedItems]="
                    field.maxSelectionLimit ? field.maxSelectionLimit : Infinity
                "
                [virtualScroll]="true"
                (change)="onValueChange($event)"
                [compareWith]="compareWith"
                (open)="showOverlay = true"
                (close)="showOverlay = false"
            >
                <ng-container>
                    <ng-template
                        ng-multi-label-tmp
                        let-items="selectedValues"
                        let-clear="clear"
                    >
                        <div
                            class="ng-value"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length > 0
                                    : formGroup.get(field.name).value.length > 0
                            "
                        >
                            <span class="ng-value-label"
                                >{{
                                    loading.get(field.name)
                                        ? field.loadingMessage
                                            ? field.loadingMessage
                                            : "Loading..."
                                        : field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length +
                                          " selected"
                                        : formGroup.get(field.name).value
                                              .length + " selected"
                                }}
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container
                    *ngIf="
                        !field.maxSelectionLimit &&
                        !loading.get(field.name) &&
                        field.listData &&
                        field.listData.length
                    "
                >
                    <ng-template ng-header-tmp>
                        <div
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length === 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length ===
                                          0
                            "
                            (click)="onSelectAll(true)"
                            class="ng-select-option-custom"
                        >
                            <i class="far fa-square clickable"></i>
                            <span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length !== 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup.get(field.name).value.length !==
                                          0
                            "
                            (click)="onSelectAll(false)"
                        >
                            <i class="fas fa-minus-square clickable"></i>
                            <span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                            "
                            (click)="onSelectAll(false)"
                        >
                            <i class="fas fa-check-square clickable"></i
                            ><span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                    <div
                        appDisableElement
                        [data]="
                            field.groupByKey
                                ? field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length
                                : field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup.get(field.name).value.length
                        "
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <i class="checkbox-dropdown far fa-square"></i>
                        <span class="common-label">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <i class="checkbox-dropdown fas fa-check-square"></i
                        ><span class="common-label">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                >
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <i class="checkbox-dropdown far fa-square"></i>
                        <span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <i class="checkbox-dropdown fas fa-check-square"></i
                        ><span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                </ng-template>
            </ng-select>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!-- field type one end -->

<!-- field type three start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_3">
    <div class="form-row ng-select-dropdown-container">
        <div *ngIf="field.showLabel" class="form-label">
            {{ field.label }}
            <span *ngIf="field.required" class="mat-form-field-required-marker">
                *
            </span>
        </div>
        <div
            class="ng-select-custom-material-wrapper ng-select-custom-material-wrapper-underline form-input"
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            [class.disabled]="
                field.groupByKey
                    ? formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                    : formGeneratorInput.state === FormState.IDLE ||
                      (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
            "
            [matTooltip]="
                field.groupByKey
                    ? (field.populateFromControl &&
                          !formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl)) ||
                      (formGroup
                          .get(field.groupByKey)
                          .get(field.populateFromControl) &&
                          (!formGroup
                              .get(field.groupByKey)
                              .get(field.populateFromControl).value ||
                              !formGroup
                                  .get(field.groupByKey)
                                  .get(field.populateFromControl).value.length))
                        ? 'Select ' +
                          (
                              field.populateFromControl
                              | fieldDataFromControlName
                                  : formGeneratorInput.fields
                          ).label +
                          ' values first'
                        : null
                    : (field.populateFromControl &&
                          !formGroup.get(field.populateFromControl)) ||
                      (formGroup.get(field.populateFromControl) &&
                          (!formGroup.get(field.populateFromControl).value ||
                              !formGroup.get(field.populateFromControl).value
                                  .length))
                    ? 'Select ' +
                      (
                          field.populateFromControl
                          | fieldDataFromControlName : formGeneratorInput.fields
                      ).label +
                      ' values first'
                    : null
            "
            [matTooltipShowDelay]="1000"
            [matTooltipPosition]="'before'"
        >
            <ng-select
                style="z-index: 10"
                [appearance]="'outline'"
                [class.field-required]="
                    field.required !== undefined && !field.showLabel
                        ? field.required
                        : false
                "
                [bindValue]="
                    field.getKey || field.getKey === null ? field.getKey : 'id'
                "
                [bindLabel]="field.showKey ? field.showKey : 'label'"
                [items]="
                    !loading.get(field.name) && field.listData
                        ? field.listData
                        : null
                "
                [groupBy]="field.groupBy ? field.groupBy : null"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                [closeOnSelect]="false"
                [placeholder]="
                    field.placeholder ? field.placeholder : field.label
                "
                [loading]="loading.get(field.name)"
                [appendTo]="'body'"
                [loadingText]="
                    field.loadingMessage ? field.loadingMessage : 'Loading...'
                "
                [formControl]="control"
                [errorStateMatcher]="matcher"
                dropdownPosition="auto"
                [clearable]="true"
                [multiple]="true"
                [maxSelectedItems]="
                    field.maxSelectionLimit ? field.maxSelectionLimit : Infinity
                "
                [virtualScroll]="true"
                (change)="onValueChange($event)"
                [compareWith]="compareWith"
                (open)="showOverlay = true"
                (close)="showOverlay = false"
            >
                <ng-container>
                    <ng-template
                        ng-multi-label-tmp
                        let-items="selectedValues"
                        let-clear="clear"
                    >
                        <div
                            class="ng-value"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length > 0
                                    : formGroup.get(field.name).value.length > 0
                            "
                        >
                            <span class="ng-value-label"
                                >{{
                                    loading.get(field.name)
                                        ? field.loadingMessage
                                            ? field.loadingMessage
                                            : "Loading..."
                                        : field.groupByKey
                                        ? formGroup
                                              .get(field.groupByKey)
                                              .get(field.name).value.length +
                                          " selected"
                                        : formGroup.get(field.name).value
                                              .length + " selected"
                                }}
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container
                    *ngIf="
                        !field.maxSelectionLimit &&
                        !loading.get(field.name) &&
                        field.listData &&
                        field.listData.length
                    "
                >
                    <ng-template ng-header-tmp>
                        <div
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length === 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length ===
                                          0
                            "
                            (click)="onSelectAll(true)"
                            class="ng-select-option-custom"
                        >
                            <i class="far fa-square clickable"></i>
                            <span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length !== 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length <
                                          field.listData.length &&
                                      formGroup.get(field.name).value.length !==
                                          0
                            "
                            (click)="onSelectAll(false)"
                        >
                            <i class="fas fa-minus-square clickable"></i>
                            <span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                        <div
                            class="selected-item ng-select-option-custom"
                            *ngIf="
                                field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value &&
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                                    : formGroup.get(field.name).value &&
                                      formGroup.get(field.name).value.length ===
                                          field.listData.length &&
                                      field.listData.length > 0
                            "
                            (click)="onSelectAll(false)"
                        >
                            <i class="fas fa-check-square clickable"></i
                            ><span class="common-label">
                                <ng-container *ngIf="field.maxSelectionLimit">
                                    Select top
                                    {{ field.maxSelectionLimit }}
                                    items</ng-container
                                >
                                <ng-container *ngIf="!field.maxSelectionLimit">
                                    Select All</ng-container
                                >
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                    <div
                        appDisableElement
                        [data]="
                            field.groupByKey
                                ? field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length
                                : field.maxSelectionLimit &&
                                  field.maxSelectionLimit ===
                                      formGroup.get(field.name).value.length
                        "
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <i class="checkbox-dropdown far fa-square"></i>
                        <span class="common-label">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <i class="checkbox-dropdown fas fa-check-square"></i
                        ><span class="common-label">{{
                            item[field.groupBy ? field.groupBy : "label"]
                        }}</span>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                >
                    <div
                        *ngIf="!item$.selected"
                        class="ng-select-option-custom"
                    >
                        <i class="checkbox-dropdown far fa-square"></i>
                        <span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="item$.selected"
                    >
                        <i class="checkbox-dropdown fas fa-check-square"></i
                        ><span class="common-label">{{
                            item[field.showKey ? field.showKey : "label"]
                        }}</span>
                    </div>
                </ng-template>
            </ng-select>
            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!-- field type three end -->

<!-- field type five start -->
<ng-container *ngIf="field.appearance === AppearanceType.TYPE_5">
    <div>
        <ng-select
            style="z-index: 10"
            [appearance]="'outline'"
            [class.field-required]="
                field.required !== undefined && !field.showLabel
                    ? field.required
                    : false
            "
            [bindValue]="
                field.getKey || field.getKey === null ? field.getKey : 'id'
            "
            [bindLabel]="field.showKey ? field.showKey : 'label'"
            [items]="
                !loading.get(field.name) && field.listData
                    ? field.listData
                    : null
            "
            [groupBy]="field.groupBy ? field.groupBy : null"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            [closeOnSelect]="false"
            [placeholder]="field.placeholder ? field.placeholder : field.label"
            [loading]="loading.get(field.name)"
            [appendTo]="'body'"
            [loadingText]="
                field.loadingMessage ? field.loadingMessage : 'Loading...'
            "
            [formControl]="control"
            [errorStateMatcher]="matcher"
            dropdownPosition="auto"
            [clearable]="true"
            [multiple]="true"
            [virtualScroll]="true"
            [(ngModel)]="selectedValues"
            (change)="onValueChange($event)"
            [compareWith]="compareWith"
            (open)="showOverlay = true; onOpenDropdown()"
            (close)="showOverlay = false; isOpen = false"
        >
            <ng-container>
                <ng-template
                    ng-multi-label-tmp
                    let-items="selectedValues"
                    let-clear="clear"
                >
                    <div
                        class="ng-value"
                        *ngIf="
                            field.groupByKey
                                ? formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length > 0
                                : formGroup.get(field.name).value.length > 0
                        "
                    >
                        <span class="ng-value-label"
                            >{{
                                loading.get(field.name)
                                    ? field.loadingMessage
                                        ? field.loadingMessage
                                        : "Loading..."
                                    : field.groupByKey
                                    ? formGroup
                                          .get(field.groupByKey)
                                          .get(field.name).value.length +
                                      " selected"
                                    : formGroup.get(field.name).value.length +
                                      " selected"
                            }}
                        </span>
                    </div>
                </ng-template>
            </ng-container>
            <ng-container
                *ngIf="
                    !loading.get(field.name) &&
                    field.listData &&
                    field.listData.length
                "
            >
                <ng-template ng-header-tmp>
                    <div
                        *ngIf="
                            field.groupByKey
                                ? formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length === 0
                                : formGroup.get(field.name).value &&
                                  formGroup.get(field.name).value.length === 0
                        "
                        (click)="onSelectAll(true)"
                        class="ng-select-option-custom"
                    >
                        <i class="far fa-square clickable"></i>
                        <span class="common-label">
                            <ng-container *ngIf="field.maxSelectionLimit">
                                Select top
                                {{ field.maxSelectionLimit }}
                                items</ng-container
                            >
                            <ng-container *ngIf="!field.maxSelectionLimit">
                                Select All</ng-container
                            >
                        </span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="
                            field.groupByKey
                                ? formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length <
                                      field.listData.length &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length !== 0
                                : formGroup.get(field.name).value &&
                                  formGroup.get(field.name).value.length <
                                      field.listData.length &&
                                  formGroup.get(field.name).value.length !== 0
                        "
                        (click)="onSelectAll(false)"
                    >
                        <i class="fas fa-minus-square clickable"></i>
                        <span class="common-label">
                            <ng-container *ngIf="field.maxSelectionLimit">
                                Select top
                                {{ field.maxSelectionLimit }}
                                items</ng-container
                            >
                            <ng-container *ngIf="!field.maxSelectionLimit">
                                Select All</ng-container
                            >
                        </span>
                    </div>
                    <div
                        class="selected-item ng-select-option-custom"
                        *ngIf="
                            field.groupByKey
                                ? formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value &&
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length ===
                                      field.listData.length &&
                                  field.listData.length > 0
                                : formGroup.get(field.name).value &&
                                  formGroup.get(field.name).value.length ===
                                      field.listData.length &&
                                  field.listData.length > 0
                        "
                        (click)="onSelectAll(false)"
                    >
                        <i class="fas fa-check-square clickable"></i
                        ><span class="common-label">
                            <ng-container *ngIf="field.maxSelectionLimit">
                                Select top
                                {{ field.maxSelectionLimit }}
                                items</ng-container
                            >
                            <ng-container *ngIf="!field.maxSelectionLimit">
                                Select All</ng-container
                            >
                        </span>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
                <div
                    appDisableElement
                    [data]="
                        field.groupByKey
                            ? field.maxSelectionLimit &&
                              field.maxSelectionLimit ===
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length
                            : field.maxSelectionLimit &&
                              field.maxSelectionLimit ===
                                  formGroup.get(field.name).value.length
                    "
                    *ngIf="!item$.selected"
                    class="ng-select-option-custom"
                >
                    <i class="checkbox-dropdown far fa-square"></i>
                    <span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <i class="checkbox-dropdown fas fa-check-square"></i
                    ><span class="common-label">{{
                        item[field.groupBy ? field.groupBy : "label"]
                    }}</span>
                </div>
            </ng-template>
            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
            >
                <div
                    appDisableElement
                    [data]="
                        field.groupByKey
                            ? field.maxSelectionLimit &&
                              field.maxSelectionLimit ===
                                  formGroup
                                      .get(field.groupByKey)
                                      .get(field.name).value.length
                            : field.maxSelectionLimit &&
                              field.maxSelectionLimit ===
                                  formGroup.get(field.name).value.length
                    "
                    *ngIf="!item$.selected"
                    class="ng-select-option-custom"
                >
                    <i class="checkbox-dropdown far fa-square"></i>
                    <span class="common-label">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
                <div
                    class="selected-item ng-select-option-custom"
                    *ngIf="item$.selected"
                >
                    <i class="checkbox-dropdown fas fa-check-square"></i
                    ><span class="common-label">{{
                        item[field.showKey ? field.showKey : "label"]
                    }}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</ng-container>
<!-- field type five end -->

<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>

<div class="dropdown-background-layer" *ngIf="showOverlay"></div>
