import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FileType } from 'src/app/shared/enums/FileType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-gcp-onboarding-modal',
    templateUrl: './gcp-onboarding-modal.component.html',
    styleUrls: ['./gcp-onboarding-modal.component.sass']
})
export class GcpOnboardingModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup;
    buttonInputs: IButtonGeneratorInput[];
    uploadFormGenInput: IFormGeneratorInput = null;
    uploadFormGroup: FormGroup;
    emailFormGenInput: IFormGeneratorInput = null;
    emailFormGroup: FormGroup;
    stepData: Object = null;
    resetForm: Function = null;
    jsonValue = {
        value: null
    };
    resetSubscription: Subscription;

    constructor(
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalData: ModalInjectedData,
        private filtersService: FiltersService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        const currentRef = this;
        this.buttonInputs = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: 'assets/onboarding-pdf/gcp-onboarding.pdf',
                function: () => {}
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.onSave(buttonRef);
                }
            }
        ];
        this.uploadFormGenInput = {
            formName: 'On Board GCP',
            state: this.modalData.data.inputData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Upload Key File',
                    name: 'serviceAccountCreds',
                    accept: FileType.JSON,
                    placeholder: 'Select a file to upload...',
                    fieldType: FilterType.FILE,
                    showLabel: true,
                    suffixButton: {
                        text: 'Browse',
                        show: true
                    },
                    appearance: 'legacy',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: ''
                        }
                    ]
                }
            ]
        };
        this.emailFormGenInput = {
            formName: 'On Board GCP',
            state: this.modalData.data.inputData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Service Account Email',
                    name: 'serviceAccountEmail',
                    placeholder: 'Prefetch Data',
                    fieldType: FilterType.EMAIL,
                    appearance: 'legacy',
                    showLabel: true,
                    required: false,
                    disabled: true,
                    disableWithValidation: true,
                    validations: [
                        {
                            validator:
                                CustomValidators.otherJsonControlKeyValueValidator(
                                    this.jsonValue,
                                    'client_email'
                                ),
                            errorMessage:
                                'Uploaded File does not contain Service Account Email'
                        }
                    ]
                }
            ]
        };
        this.formGenInput = {
            formName: 'On Board GCP',
            state: this.modalData.data.inputData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Billing Account ID',
                    name: 'billingAccountID',
                    placeholder: 'Enter Account ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account ID is required'
                        }
                    ],
                    disabled: this.modalData.data.inputData ? true : false,
                    value:
                        this.modalData.data.inputData &&
                            this.modalData.data.inputData.billingAccountId
                            ? this.modalData.data.inputData.billingAccountId
                            : '',
                    fieldStyle: {
                        height: '65px',
                        width: '100%'
                    }
                },
                {
                    label: 'Project ID',
                    name: 'projectId',
                    placeholder: 'Enter Project ID',
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    showLabel: true,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Project ID is required'
                        }
                    ],
                    disabled: this.modalData.data.inputData ? true : false,
                    value:
                        this.modalData.data.inputData &&
                        this.modalData.data.inputData.projectId
                            ? this.modalData.data.inputData.projectId
                            : ''
                }
            ]
        };
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            const formData = {
                billingAccountID: this.stepData
                    ? this.stepData['gcpAccountAttributes']['billingAccountID']
                    : null,
                projectId: this.stepData
                    ? this.stepData['gcpAccountAttributes']['projectId']
                    : null,
                serviceAccountCreds: this.stepData
                    ? this.stepData['gcpAccountAttributes'][
                          'serviceAccountCreds'
                      ]
                    : null
            };
            this.resetForm(formData);
            this.uploadFormGroup.reset();
        });
    }

    updateEmailField(value) {
        if (
            value.serviceAccountCreds &&
            value.serviceAccountCreds.files &&
            value.serviceAccountCreds.files.length
        ) {
            Helper.jsonFileReader(
                value.serviceAccountCreds.files[0],
                this.setJsonValue.bind(this)
            );
        } else {
            if (this.emailFormGroup) {
                this.emailFormGroup.patchValue({
                    serviceAccountEmail: null
                });
            }
        }
    }

    setJsonValue(json) {
        this.jsonValue.value = json;
        if (this.emailFormGroup) {
            this.emailFormGroup.patchValue({
                serviceAccountEmail: json['client_email']
            });
        }
    }

    onSave(buttonRef: IButtonGeneratorInput) {
        if (!this.checkFormValidity()) {
            return;
        }
        buttonRef.loader = true;
        const input = {
            ...this.uploadFormGroup.getRawValue(),
            ...this.formGroup.getRawValue()
        };
        input.serviceAccountCreds = this.jsonValue.value;
        let apiArgs: IHitApi;
        if (this.modalData.data.inputData) {
            apiArgs = Helper.generateHitApiConfig(
                this.modalData.data.widgetInfo.update
            );
        } else {
            apiArgs = Helper.generateHitApiConfig(
                this.modalData.data.widgetInfo.create
            );
        }
        apiArgs.input = { gcpAccountAttributes: input };
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(
                this.modalData.data.inputData
                    ? Messages.ACCOUNT_UPDATE_LIST
                    : Messages.ACCOUNT_ONBOARDING_SUCCESS
            );
            buttonRef.loader = false;
            this.filtersService.refreshWidget.next(
                new Set([this.modalService.modalData.sourceId])
            );
            this.modalService.closeModal(null, this.modalData.modalId);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.notificationService, error);
            buttonRef.loader = false;
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{id}', input.projectId);
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    checkFormValidity() {
        Helper.markAllFieldAsTouched(this.uploadFormGroup);
        Helper.markAllFieldAsTouched(this.emailFormGroup);
        Helper.markAllFieldAsTouched(this.formGroup);
        let valid = true;
        if (this.uploadFormGroup.invalid) {
            this.uploadFormGroup.updateValueAndValidity();
            valid = false;
        }
        if (this.emailFormGroup.invalid) {
            this.emailFormGroup.updateValueAndValidity();
            valid = false;
        }
        if (this.formGroup.invalid) {
            this.formGroup.updateValueAndValidity();
            valid = false;
        }
        return valid;
    }

    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
