import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Query, DataManager } from '@syncfusion/ej2-data';

@Component({
    selector: 'app-single-select-category-dropdown',
    templateUrl: './single-select-category-dropdown.component.html',
    styleUrls: ['./single-select-category-dropdown.component.sass']
})
export class SingleSelectCategoryDropdownComponent implements OnInit {
    @Input('data') data;
    @Input('name') name;
    @Input('groupFields') groupFields;
    @Input('groupWaterMark') groupWaterMark;
    @Input('height') height;
    @Input('value') value;
    @Output('change') change = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    onfiltering($event) {
        let query: Query = new Query();
        // frame the query based on search string with filter type.
        query =
            $event.text !== ''
                ? query.where(
                      this.groupFields['groupBy'],
                      'contains',
                      $event.text,
                      true
                  )
                : query;
        // pass the filter data source, filter query to updateData method.
        $event.updateData(this.data, query);
    }

    dropdownSelectedValueChanged($event) {
        this.change.emit($event.value);
    }
}
