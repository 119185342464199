import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { NotificationsService } from './../../services/notifications/notifications.service';

@Component({
    selector: 'app-table-column-order',
    templateUrl: './table-column-order.component.html',
    styleUrls: ['./table-column-order.component.sass'],
})
export class TableColumnOrderComponent implements OnInit {
    @Input() iconData: IIcon;
    @Input() prefixData: IIcon;
    @Input('onSave') saveOrder: Function;
    @Input('onReset') resetOrder: Function;
    @Input() columnName: string[];
    @Input() toggleEnabled: Map<string, boolean>;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    filteredColumnName;
    @Input() fixedColumns;
    @Input() disableDragColumns;
    disableToggleSlide: boolean = false;
    columnOrderChanged = false;
    disableDragIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'disableDragIcon',
    };
    controlButtons: IButtonGeneratorInput[];

    constructor(
        private cd: ChangeDetectorRef,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.filteredColumnName = this.columnName;
        this.columnVisiblity(this.filteredColumnName);
        this.initializeButtonGenInput();
    }

    drop(event) {
        moveItemInArray(
            this.columnName,
            event.previousIndex,
            event.currentIndex
        );
        this.columnOrderChanged = true;
    }

    initializeButtonGenInput() {
        this.controlButtons = [
            {
                buttonName: 'Reset',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.resetOrder();
                },
            },
            {
                buttonName: 'Save',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.onSave();
                },
            },
        ];
    }

    columnVisiblity(column, event?: MatSlideToggleChange) {
        let i = 1;
        this.disableToggleSlide = false;
        if (event) {
            this.toggleEnabled.set(column, event.checked);
        }
        for (const data of this.toggleEnabled) {
            if (data[1] === false || data[0] === 'Action') {
                i++;
            }
        }
        if (i === this.toggleEnabled.size) {
            this.disableToggleSlide = true;
            this.cd.detectChanges();
        }
        if (event) {
            this.columnOrderChanged = true;
        }
    }

    onSave() {
        this.trigger.closeMenu();
        if (this.columnOrderChanged) {
            if (this.saveOrder) {
                this.columnOrderChanged = false;
                this.saveOrder();
            }
        } else {
            this.notificationsService.showSnackBar('No columns changed');
        }
    }
}
