import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { FilterType } from '../../enums/FilterType';
import { FormState } from '../../enums/FormState';
import { IconType } from '../../enums/IconType';
import { IFormGeneratorInput } from '../../interfaces/form-generator/IFormGeneratorInput';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from './../../interfaces/icon-data/IIcon';
@Component({
    selector: 'app-key-value-pair-v2',
    templateUrl: './key-value-pair-v2.component.html',
    styleUrls: ['./key-value-pair-v2.component.sass']
})
export class KeyValuePairV2Component implements OnInit, OnChanges {
    @Input('data') data;
    @Input() matChipInputLimit: number;

    @Input() resetKeyValue: BehaviorSubject<boolean> = null;
    @Input() validation: string = '';
    @Input() keysAlreadyExists: Array<string> = [];
    @Input() originalKeysData = [];
    @Input() applyCaseInsensitiveCase: boolean;
    @Input() handleCaseInsensitiveAsWarning: boolean;
    @Input() warningMessage: string;
    keyValuePairExists: boolean;
    firstTime: boolean = true;
    allKeysAlreadyExists = [];

    keyValueObject = {};

    newOldKeyValueMap: Map<number, { old: {}; new: {}; isDelete: boolean }> =
        new Map();

    keyValueFormGenInputArray: IFormGeneratorInput[] = [];

    formGroupRef = [];
    payLoadData = [];
    inCompleteSection: boolean;

    keyName = '';
    valueName = '';
    keyValueArray = [];

    @Output('change') change = new EventEmitter();
    pencilIcon: IIcon = {
        type: IconType.SVG,
        class: 'pencil_filled'
    };
    keyValueFormGenInput: IFormGeneratorInput;
    keys = [];

    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash'
    };
    plusIcon: IIcon = {
        type: IconType.SVG,
        class: 'plus_filled'
    };

    buttonInputs: IButtonGeneratorInput[];

    readonly WARNING_MESSAGE_KEY = 'hasWarningMessage';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.allKeysAlreadyExists = this.originalKeysData.map((item) => {
            return item.split('|')[0];
        });

        if (this.data && Object.keys(this.data).length) {
            Object.keys(this.data).forEach((key, index) => {
                this.keyValueObject = {};
                this.keyValueObject[key] = this.data[key];
                this.payLoadData.push(this.keyValueObject);

                this.keyValueArray.push(this.keyValueObject);

                this.createNewKeyValueField(key, this.data[key]);

                this.newOldKeyValueMap.set(index, {
                    old: { key: key, val: this.data[key] },
                    new: {},
                    isDelete: false
                });
            });
        } else {
            this.createNewKeyValueField();
        }

        if (this.resetKeyValue) {
            this.resetKeyValue.subscribe((val) => {
                if (val) {
                    this.keyValueFormGenInputArray = [];
                    this.formGroupRef = [];
                    this.createNewKeyValueField();
                }
            });
        }

        this.buttonInputs = [
            {
                buttonName: '',
                buttonType: ButtonType.ICON,
                buttonColorType: ButtonColorType.PRIMARY,
                buttonIcon: this.plusIcon,
                customClass: 'plus-icon',
                preventHoverEffect: true,
                function: () => {}
            },
            {
                buttonIcon: this.trashIcon,
                buttonName: '',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.ICON,
                preventHoverEffect: true,
                function: () => {},
                customClass: 'trash-icon'
            }
        ];
    }
    trackByFn(index, item) {
        return index;
    }
    createNewKeyValueField(key?, value?, viaSection?: boolean, index?) {
        if (viaSection) {
            this.keyValuePairExists = true;
            this.keyValueArray = [];
        }

        if (viaSection) {
            this.inCompleteSection = false;
            this.checkIncompleteKeyValuePairs(viaSection);

            if (this.inCompleteSection) {
                this.formGroupRef.map((data: FormGroup) => {
                    data.markAllAsTouched();
                });
                return;
            }
        }

        this.formGroupRef.push(null);
        this.firstTime = false;

        this.resetForm();
    }
    resetForm() {
        this.keyValueFormGenInputArray = [];

        this.formGroupRef.forEach((data, fieldIndex1) => {
            this.keyName = '';
            this.valueName = '';
            if (data) {
                this.keyName = data.value.key;
                this.valueName = data.value.Value;
            }

            this.keys = [];
            if (this.firstTime) {
                this.payLoadData.forEach((data, index) => {
                    if (fieldIndex1 !== index) {
                        this.keys.push(Object.keys(data)[0]);
                    }
                });
            } else {
                this.formGroupRef.forEach((data, index1) => {
                    if (index1 !== fieldIndex1 && data) {
                        if (data.value.key !== null) {
                            this.keys.push(data.value.key);
                        }
                    }
                });
            }

            const keyValueFormGenInput: IFormGeneratorInput = {
                formName: 'KeyValue',
                state: FormState.CREATE,
                submitButton: null,

                fields: [
                    {
                        label: 'Key',
                        name: 'key',
                        fieldType: FilterType.TEXT,
                        placeholder: 'Add Key',
                        required: true,
                        validations: Helper.getKeyValidators(
                            this.validation,
                            this.keys,
                            this.keysAlreadyExists,
                            this.allKeysAlreadyExists,
                            fieldIndex1,
                            Object.keys(this.data).length ? true : false,
                            this.handleCaseInsensitiveAsWarning
                                ? false
                                : this.applyCaseInsensitiveCase
                        ),

                        suffixIcon: {
                            hoverText: '',
                            iconData: {
                                type: IconType.MATICON,
                                class: 'mode_edit'
                            }
                        },
                        value:
                            this.keyValueArray.length &&
                            this.keyValueArray[fieldIndex1]
                                ? this.keyValueArray[fieldIndex1][
                                      null as string
                                  ]
                                    ? null
                                    : Object.keys(
                                          this.keyValueArray[fieldIndex1]
                                      )[0] ?? ''
                                : this.keyName,

                        onFocusout: ($event, formGroup: FormGroup) => {
                            this.keyValueArray = [];
                            this.firstTime = false;
                            this.resetForm();
                        }
                    },
                    {
                        suffixIcon: {
                            hoverText: '',
                            iconData: {
                                type: IconType.MATICON,
                                class: 'mode_edit'
                            }
                        },
                        label: 'Value',
                        name: 'Value',
                        fieldType: FilterType.MATCHIPLIST,
                        placeholder: 'Add Value',
                        value: this.keyValueArray.length
                            ? this.keyValueArray[fieldIndex1][
                                  Object.keys(
                                      this.keyValueArray[fieldIndex1]
                                  )[0]
                              ]
                            : this.valueName,
                        validations: Helper.getValueValidators(this.validation),
                        matChipInputLimit: this.matChipInputLimit
                            ? this.matChipInputLimit
                            : null,
                        required: false
                    }
                ]
            };
            if (keyValueFormGenInput) {
                keyValueFormGenInput[this.WARNING_MESSAGE_KEY] = false;
            }
            this.keyValueFormGenInputArray.push(keyValueFormGenInput);
            this.emitObject();
        });
    }

    ngOnChanges(change) {}
    deleteKeyValueField(index) {
        if (this.keyValueFormGenInputArray.length === 1) {
            return;
        }

        this.formGroupRef.splice(index, 1);
        this.keyValueFormGenInputArray.splice(index, 1);
        this.payLoadData.splice(index, 1);
        this.emitObject();

        this.keyValueArray = [];
        this.firstTime = false;
        this.resetForm();

        for (let i = index; i < this.newOldKeyValueMap.size - 1; i++) {
            this.newOldKeyValueMap.set(i, this.newOldKeyValueMap.get(i + 1));
        }
        this.newOldKeyValueMap.delete(this.newOldKeyValueMap.size - 1);
    }

    doesCaseInsensitiveKeyAlreadyExist(checkKey, currIndex) {
        const checkFunction = (
            list,
            ignoreCurrentIndex: boolean = false,
            ignoreSameKeys: boolean = false
        ) => {
            const res = !!list.find((key, index) => {
                if (
                    typeof key === 'string' &&
                    typeof checkKey === 'string' &&
                    key.toLowerCase() === checkKey.toLowerCase()
                ) {
                    if (ignoreCurrentIndex && index === currIndex) {
                        return false;
                    }
                    if (ignoreSameKeys && key === checkKey) {
                        return false;
                    }
                    return true;
                }
                return false;
            });
            return res;
        };
        if (
            checkFunction(this.keys, true, true) ||
            checkFunction(this.allKeysAlreadyExists) ||
            checkFunction(this.keysAlreadyExists)
        ) {
            return true;
        }
        return false;
    }

    changed($event, i) {
        const key = $event['key'];
        const val = $event['Value'];

        if (
            this.handleCaseInsensitiveAsWarning &&
            this.warningMessage &&
            this.keyValueFormGenInputArray &&
            this.keyValueFormGenInputArray[i] &&
            this.formGroupRef &&
            this.formGroupRef[i] &&
            this.formGroupRef[i].valid
        ) {
            if (this.doesCaseInsensitiveKeyAlreadyExist(key, i)) {
                this.keyValueFormGenInputArray[i][this.WARNING_MESSAGE_KEY] =
                    true;
            } else {
                this.keyValueFormGenInputArray[i][this.WARNING_MESSAGE_KEY] =
                    false;
            }
        }

        if (i > this.payLoadData.length - 1) {
            this.keyValueObject[$event['key']] = $event['Value'];
            this.payLoadData[i] = this.keyValueObject;
        } else {
            this.payLoadData.forEach((data, index) => {
                if (index === i) {
                    this.keyValueObject = {};
                    this.keyValueObject[$event['key']] = $event['Value'];

                    this.payLoadData[index] = this.keyValueObject;
                }
            });
        }

        if (this.newOldKeyValueMap.size) {
            this.newOldKeyValueMap.set(i, {
                old: this.newOldKeyValueMap.get(i)
                    ? this.newOldKeyValueMap.get(i).old
                    : { key: key, val: val },
                new: { key: key, val: val },
                isDelete: false
            });
        }
        this.emitObject();
        this.formGroupRef.forEach((data: FormGroup) => {
            if (data && (data.value.key || data.value.Value.length)) {
                data.markAllAsTouched();
                this.cdr.detectChanges();
            }
        });
    }
    checkIncompleteKeyValuePairs(viaSection?: boolean) {
        if (!this.formGroupRef.includes(null)) {
            this.formGroupRef.forEach((data, index) => {
                if (data.status === 'INVALID') {
                    this.inCompleteSection = true;

                    return;
                }
            });
        }

        return;
    }
    emitObject() {
        this.inCompleteSection = false;
        this.checkIncompleteKeyValuePairs();

        this.change.emit([
            this.createPayLoadDataInObject(),
            this.inCompleteSection,
            this.formGroupRef,
            this.newOldKeyValueMap
        ]);
    }
    createPayLoadDataInObject() {
        this.keyValueObject = {};
        this.payLoadData.forEach((data) => {
            const stringData = '' + Object.keys(data)[0];
            this.keyValueObject[stringData] = data[Object.keys(data)[0]];
        });
        if (this.applyCaseInsensitiveCase) {
            const extraArray = Object.keys(this.keyValueObject).map((data) => {
                return data.toLowerCase();
            });
            extraArray.forEach((keyName, index) => {
                const findIndex = extraArray.findIndex((data, index1) => {
                    if (index !== index1) {
                        return data === keyName;
                    }
                });
                if (findIndex >= 0 && findIndex !== index) {
                    delete this.keyValueObject[
                        Object.keys(this.keyValueObject)[index]
                    ];
                }
            });
        }

        return this.keyValueObject;
    }
}
