import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { DoubleRangeAppearance } from 'src/app/shared/enums/AppearanceType';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { IFilterInfo } from './../../../interfaces/filter/IFilterInfo';
import { IFormField } from './../../../interfaces/form-generator/IFormField';

@Component({
    selector: 'app-double-range-field',
    templateUrl: './double-range-field.component.html',
    styleUrls: ['./double-range-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DoubleRangeFieldComponent,
            multi: true
        }
    ]
})
export class DoubleRangeFieldComponent implements OnInit, ControlValueAccessor {
    @Input() field: IFormField;
    @Input() formGroup: FormGroup
    control: FormControl;
    filterInfo: IFilterInfo;
    onChange: any;
    FieldAppearance = DoubleRangeAppearance;
    constructor(public filterService: FiltersService) {}
    writeValue(obj: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (
            !existingAppearance.includes(
                this.field.appearance as DoubleRangeAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.filterInfo = this.filterService.currentPageFilterInfo.get(
            this.field.id
        );
        this.control = this.findControl();
    }

    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }

    onValueChange(event) {
        if (this.field.isFilter) {
            this.control.markAsDirty();
            if (this.filterService.isCompareViewEnabled) {
                this.filterService.comapareViewValueChanged = true;
            } else {
                this.filterService.valueChanged = true;
            }
            this.onChange(this.field.value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
}
