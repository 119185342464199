export enum FilterType {
    DROPDOWN_SINGLE = 'DROPDOWN_SINGLE',
    DROPDOWN_MULTIPLE = 'DROPDOWN_MULTIPLE',
    TEXT = 'TEXT',
    PASSWORD = 'PASSWORD',
    COLOR = 'COLOR',
    URL = 'URL',
    WYSIWYG_EDITOR = 'WYSIWYG_EDITOR',
    TEXTAREA = 'TEXTAREA',
    FILE = 'FILE',
    EMAIL = 'EMAIL',
    NUMBER = 'NUMBER',
    RANGE = 'RANGE',
    DOUBLE_RANGE = 'DOUBLE_RANGE',
    TOGGLE = 'TOGGLE',
    DROPDOWN_GROUP_MULTIPLE = 'DROPDOWN_GROUP_MULTIPLE',
    DROPDOWN_GROUP_SINGLE = 'DROPDOWN_GROUP_SINGLE',
    CURRENCY = 'CURRENCY',
    DATE_RANGE = 'DATE_RANGE',
    WIDGET_DATE_RANGE = 'WIDGET_DATE_RANGE',
    DATE = 'DATE',
    CHECKBOX = 'CHECKBOX',
    MATCHIPLIST = 'MATCHIPLIST',
    RADIO = 'RADIO',
    JSON = 'JSON',
    DROPDOWN_DEPENDENT = 'DROPDOWN_DEPENDENT',
    DATE_TIME_RANGE = 'DATE_TIME_RANGE',
    CUSTOMER_USER = 'CUSTOMER_USER',
    CALLBACK_BUTTON = 'CALLBACK_BUTTON',
    IMAGE_UPLOAD = 'IMAGE_UPLOAD',
    ACL_VIEW_LAYER = 'ACL_VIEW_LAYER',
    DATE_RANGE_TODAY = 'DATE_RANGE_TODAY',
    SINGLE_DROPDOWN_PERCENT = 'SINGLE_DROPDOWN_PERCENT',
    CONTENT = 'CONTENT',
    TIME = 'TIME',
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    DATE_TIME = 'DATE_TIME',
    HIDDEN = 'HIDDEN',
    DROPDOWN_LIST_OBJECT = 'DROPDOWN_LIST_OBJECT',
    BOOLEAN = 'BOOLEAN',
    TABLE = 'TABLE',
    TEXT_WITH_DROPDOWN = 'TEXT_WITH_DROPDOWN',
    MULTI_DROPDOWN_WITH_INPUT = 'MULTI_DROPDOWN_WITH_INPUT',
}
