import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NgxGaugeModule } from 'ngx-gauge';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AggregateComponent } from 'src/app/shared/components/widgets/1.0/aggregate/aggregate.component';
import { AllocationChecksComponent } from 'src/app/shared/components/widgets/1.0/allocation-checks/allocation-checks.component';
import { PieComponent } from 'src/app/shared/components/widgets/2.0/pie/pie.component';
import { RegionalComponent } from 'src/app/shared/components/widgets/2.0/regional/regional.component';
import { TimeseriesComponent } from 'src/app/shared/components/widgets/2.0/timeseries/timeseries.component';
import { IntegrationComponent } from '../../components/integration/integration.component';
import { CreateAlertFirstStepComponent } from '../../components/modal-templates/alert-listing-modal/create-alert-first-step/create-alert-first-step.component';
import { CreateAlertSecondStepComponent } from '../../components/modal-templates/alert-listing-modal/create-alert-second-step/create-alert-second-step.component';
import { CreateAlertThirdStepComponent } from '../../components/modal-templates/alert-listing-modal/create-alert-third-step/create-alert-third-step.component';
import { AutoRecommendationModalComponent } from '../../components/modal-templates/auto-recommendation-modal/auto-recommendation-modal.component';
import { FreshserviceAddFieldComponent } from '../../components/modal-templates/integration/freshservice-modal/freshservice-add-field/freshservice-add-field.component';
import { SsoIntegrationStepOneComponent } from '../../components/modal-templates/integration/sso-modal/sso-integration-step-one/sso-integration-step-one.component';
import { SsoIntegrationStepTwoComponent } from '../../components/modal-templates/integration/sso-modal/sso-integration-step-two/sso-integration-step-two.component';
import { SsoIntegrationsTableComponent } from '../../components/modal-templates/integration/sso-modal/sso-integrations-table/sso-integrations-table.component';
import { QueryBuilderTemplateComponent } from '../../components/modal-templates/query-builder-modal/query-builder-template/query-builder-template.component';
import { RiUtilizationModalComponent } from '../../components/modal-templates/ri-utilization/ri-utilization-modal/ri-utilization-modal.component';
import { ServiceCatalogConfigurationComponent } from '../../components/modal-templates/service-catalog/service-catalog-configuration/service-catalog-configuration.component';
import { ServiceNowVariablesComponent } from '../../components/modal-templates/service-catalog/service-now-variables/service-now-variables.component';
import { ServicenowVariableSetFormComponent } from '../../components/modal-templates/service-catalog/servicenow-variable-set-form/servicenow-variable-set-form.component';
import { AclListingComponent } from '../../components/widgets/1.0/acl-listing/acl-listing.component';
import { AdoptionMetricsComponent } from '../../components/widgets/1.0/adoption-metrics/adoption-metrics.component';
import { AdvancedAutomationComponent } from '../../components/widgets/1.0/advanced-automation/advanced-automation.component';
import { AlertListingComponent } from '../../components/widgets/1.0/alert-listing/alert-listing.component';
import { AllocationChecksV2Component } from '../../components/widgets/1.0/allocation-checks-v2/allocation-checks-v2.component';
import { ArmJsonTemplatesListingComponent } from '../../components/widgets/1.0/arm-json-templates-listing/arm-json-templates-listing.component';
import { AssessmentAssesseeComponent } from '../../components/widgets/1.0/assessment-assessee/assessment-assessee.component';
import { AssessmentListingComponent } from '../../components/widgets/1.0/assessment-listing/assessment-listing.component';
import { AssessmentUserAccessComponent } from '../../components/widgets/1.0/assessment-user-access/assessment-user-access.component';
import { AutoRecommendationComponent } from '../../components/widgets/1.0/auto-recommendation/auto-recommendation.component';
import { AutomationListingComponent } from '../../components/widgets/1.0/automation-listing/automation-listing.component';
import { AutotaskIntegrationComponent } from '../../components/widgets/1.0/autotask-integration/autotask-integration.component';
import { AutotaskTicketListingComponent } from '../../components/widgets/1.0/autotask-ticket-listing/autotask-ticket-listing.component';
import { AwsCreateInstanceProvisioningComponent } from '../../components/widgets/1.0/aws-create-instance-provisioning/aws-create-instance-provisioning.component';
import { AwsIntegrationComponent } from '../../components/widgets/1.0/aws-integration/aws-integration.component';
import { AwsListingComponent } from '../../components/widgets/1.0/aws-listing/aws-listing.component';
import { AzureCreateDiskProvisioningComponent } from '../../components/widgets/1.0/azure-create-disk-provisioning/azure-create-disk-provisioning.component';
import { AzureCreateVmProvisioningComponent } from '../../components/widgets/1.0/azure-create-vm-provisioning/azure-create-vm-provisioning.component';
import { AzureMcaIntegrationComponent } from '../../components/widgets/1.0/azure-mca-integration/azure-mca-integration.component';
import { AzureResourceProvisioningComponent } from '../../components/widgets/1.0/azure-resource-provisioning/azure-resource-provisioning.component';
import { AzureSponsorshipIntegrationComponent } from '../../components/widgets/1.0/azure-sponsorship-integration/azure-sponsorship-integration.component';
import { BargraphComponent } from '../../components/widgets/1.0/bargraph/bargraph.component';
import { BudgetListingComponent } from '../../components/widgets/1.0/budget-listing/budget-listing.component';
import { CaTicketListingComponent } from '../../components/widgets/1.0/ca-sdm-ticket-listing/ca-ticket-listing/ca-ticket-listing.component';
import { CpuUtilizationComponent } from '../../components/widgets/1.0/cpu-utilization/cpu-utilization.component';
import { CreateVolumeProvisioningComponent } from '../../components/widgets/1.0/create-volume-provisioning/create-volume-provisioning.component';
import { CreateWidgetJsonComponent } from '../../components/widgets/1.0/create-widget-json/create-widget-json.component';
import { CspIntegrationComponent } from '../../components/widgets/1.0/csp-integration/csp-integration.component';
import { CspListingComponent } from '../../components/widgets/1.0/csp-listing/csp-listing.component';
import { CustomComplianceListingComponent } from '../../components/widgets/1.0/custom-compliance-listing/custom-compliance-listing.component';
import { CustomCostMonitoringWidgetListingComponent } from '../../components/widgets/1.0/custom-cost-monitoring-widget-listing/custom-cost-monitoring-widget-listing.component';
import { CustomRequestWidgetComponent } from '../../components/widgets/1.0/custom-request-widget/custom-request-widget.component';
import { CustomWidgetListingComponent } from '../../components/widgets/1.0/custom-widget-listing/custom-widget-listing.component';
import { CustomerProfileComponent } from '../../components/widgets/1.0/customer-profile/customer-profile.component';
import { CustomersListingComponent } from '../../components/widgets/1.0/customers-listing/customers-listing.component';
import { DomainConfigurationComponent } from '../../components/widgets/1.0/domain-configuration/domain-configuration.component';
import { EaIntegrationComponent } from '../../components/widgets/1.0/ea-integration/ea-integration.component';
import { EaListingComponent } from '../../components/widgets/1.0/ea-listing/ea-listing.component';
import { ExpandableTableComponent } from '../../components/widgets/1.0/expandable-table/expandable-table.component';
import { FiltersConfigurationComponent } from '../../components/widgets/1.0/filters-configuration/filters-configuration.component';
import { FreshdeskIntegrationComponent } from '../../components/widgets/1.0/freshdesk-integration/freshdesk-integration.component';
import { FreshdeskListingComponent } from '../../components/widgets/1.0/freshdesk-listing/freshdesk-listing.component';
import { FreshserviceIntegrationComponent } from '../../components/widgets/1.0/freshservice-integration/freshservice-integration.component';
import { FreshserviceListingComponent } from '../../components/widgets/1.0/freshservice-listing/freshservice-listing.component';
import { GaugeComponent } from '../../components/widgets/1.0/gauge/gauge.component';
import { GcpIntegrationComponent } from '../../components/widgets/1.0/gcp-integration/gcp-integration.component';
import { GcpListingComponent } from '../../components/widgets/1.0/gcp-listing/gcp-listing.component';
import { GeneralConfigurationComponent } from '../../components/widgets/1.0/general-configuration/general-configuration.component';
import { GmailIntegrationComponent } from '../../components/widgets/1.0/gmail-integration/gmail-integration.component';
import { IssueAssessmentListingComponent } from '../../components/widgets/1.0/issue-assessment-listing/issue-assessment-listing.component';
import { JiradeskIntegrationComponent } from '../../components/widgets/1.0/jiradesk-integration/jiradesk-integration.component';
import { JiradeskTicketListingComponent } from '../../components/widgets/1.0/jiradesk-ticket-listing/jiradesk-ticket-listing.component';
import { LoginMetricsComponent } from '../../components/widgets/1.0/login-metrics/login-metrics.component';
import { ManagedReportListingComponent } from '../../components/widgets/1.0/managed-report-listing/managed-report-listing.component';
import { MyPriceListingComponent } from '../../components/widgets/1.0/my-price-listing/my-price-listing/my-price-listing.component';
import { NagiosIntegrationComponent } from '../../components/widgets/1.0/nagios-integration/nagios-integration.component';
import { O365IntegrationComponent } from '../../components/widgets/1.0/o365-integration/o365-integration.component';
import { OptimizerChecksComponent } from '../../components/widgets/1.0/optimizer-checks/optimizer-checks.component';
import { OtrsIntegrationComponent } from '../../components/widgets/1.0/otrs-integration/otrs-integration.component';
import { OtrsTicketListingComponent } from '../../components/widgets/1.0/otrs-ticket-listing/otrs-ticket-listing.component';
import { OverviewComponent } from '../../components/widgets/1.0/overview/overview.component';
import { PartnerVisibilityComponent } from '../../components/widgets/1.0/partner-visibility/partner-visibility/partner-visibility.component';
import { PlanIntegrationComponent } from '../../components/widgets/1.0/plan-integration/plan-integration.component';
import { ReportListingComponent } from '../../components/widgets/1.0/report-listing/report-listing.component';
import { RiDescriptionComponent } from '../../components/widgets/1.0/ri-description/ri-description.component';
import { RiRecommendationComponent } from '../../components/widgets/1.0/ri-recommendation/ri-recommendation.component';
import { RibbonComponent } from '../../components/widgets/1.0/ribbon/ribbon.component';
import { RightsizingComponent } from '../../components/widgets/1.0/rightsizing/rightsizing.component';
import { MfaComponent } from '../../components/widgets/1.0/root-user-mfa/mfa/mfa.component';
import { SalesforceIntegrationComponent } from '../../components/widgets/1.0/salesforce-integration/salesforce-integration.component';
import { SavingsPlanRecommendationComponent } from '../../components/widgets/1.0/savings-plan-recommendation/savings-plan-recommendation.component';
import { ServiceCatalogComponent } from '../../components/widgets/1.0/service-catalog/service-catalog.component';
import { ServicenowIncidentsListingComponent } from '../../components/widgets/1.0/servicenow-incidents-listing/servicenow-incidents-listing.component';
import { ServicenowIntegrationComponent } from '../../components/widgets/1.0/servicenow-integration/servicenow-integration.component';
import { ServicenowRequestsListingComponent } from '../../components/widgets/1.0/servicenow-requests-listing/servicenow-requests-listing.component';
import { SingleStackedBarComponent } from '../../components/widgets/1.0/single-stacked-bar/single-stacked-bar.component';
import { SlackIntegrationComponent } from '../../components/widgets/1.0/slack-integration/slack-integration.component';
import { SnsIntegrationComponent } from '../../components/widgets/1.0/sns-integration/sns-integration.component';
import { SsoIntegrationComponent } from '../../components/widgets/1.0/sso-integration/sso-integration.component';
import { SubDomainComponent } from '../../components/widgets/1.0/sub-domain/sub-domain.component';
import { TagConfigurationListingComponent } from '../../components/widgets/1.0/tag-configuration-listing/tag-configuration-listing.component';
import { TerraformCreateWidgetComponent } from '../../components/widgets/1.0/terraform-create-widget/terraform-create-widget.component';
import { TerraformRequestWidgetListingComponent } from '../../components/widgets/1.0/terraform-request-widget-listing/terraform-request-widget-listing.component';
import { TopologyGraphComponent } from '../../components/widgets/1.0/topology-graph/topology-graph.component';
import { UserProfileComponent } from '../../components/widgets/1.0/user-profile/user-profile.component';
import { UsersListingComponent } from '../../components/widgets/1.0/users-listing/users-listing.component';
import { UtilisationComponent } from '../../components/widgets/1.0/utilisation/utilisation.component';
import { ViewsComponent } from '../../components/widgets/1.0/views/views.component';
import { WidgetDocumentationListingComponent } from '../../components/widgets/1.0/widget-documentation-listing/widget-documentation-listing.component';
import { ZendeskIntegrationComponent } from '../../components/widgets/1.0/zendesk-integration/zendesk-integration.component';
import { ZendeskListingComponent } from '../../components/widgets/1.0/zendesk-listing/zendesk-listing.component';
import { ZohoIntegrationComponent } from '../../components/widgets/1.0/zoho-integration/zoho-integration.component';
import { ZohoListingComponent } from '../../components/widgets/1.0/zoho-listing/zoho-listing.component';
import { ExpandableTableV2Component } from '../../components/widgets/2.0/expandable-table-v2/expandable-table-v2.component';
import { FreshserviceListingV2Component } from '../../components/widgets/2.0/freshservice-listing-v2/freshservice-listing-v2.component';
import { OptimizerCheckV2Component } from '../../components/widgets/2.0/optimizer-check-v2/optimizer-check-v2.component';
import { RiDescriptionV2Component } from '../../components/widgets/2.0/ri-description-v2/ri-description-v2.component';
import { RightsizingV2Component } from '../../components/widgets/2.0/rightsizing-v2/rightsizing-v2.component';
import { TagConfigurationListingV2Component } from '../../components/widgets/2.0/tag-configuration-listing-v2/tag-configuration-listing-v2.component';
import { WhitelabelingDomainComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling-domain/whitelabeling-domain.component';
import { WhitelabelingEmailComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling-email/whitelabeling-email.component';
import { WhitelabelingProprietaryComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling-proprietary/whitelabeling-proprietary.component';
import { WhitelabelingSocialComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling-social/whitelabeling-social.component';
import { WhitelabelingThemeComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling-theme/whitelabeling-theme.component';
import { WhitelabelingComponent } from '../../components/widgets/2.0/whitelabeling/whitelabeling.component';
import { WhitelebalingPrivacyPolicyComponent } from '../../components/widgets/2.0/whitelabeling/whitelebaling-privacy-policy/whitelebaling-privacy-policy.component';
import { WhitelebelingTermsOfServicesComponent } from '../../components/widgets/2.0/whitelabeling/whitelebeling-terms-of-services/whitelebeling-terms-of-services.component';
import { AllocationChecksV3Component } from '../../components/widgets/3.0/allocation-checks-v3/allocation-checks-v3.component';
import { OptimizerCheckV3Component } from '../../components/widgets/3.0/optimizer-check-v3/optimizer-check-v3.component';
import { RightsizingV3Component } from '../../components/widgets/3.0/rightsizing-v3/rightsizing-v3.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { FormModule } from '../form/form.module';
import { HelperComponentModule } from '../helper-component/helper-component.module';
import { MaterialModule } from '../material/material-module.module';
import { UtilsModule } from '../utils/utils.module';
import { CustomWidgetPreviewComponent } from './../../components/custom-widget-preview/custom-widget-preview.component';
import { FreshserviceCreateTicketComponent } from './../../components/modal-templates/integration/freshservice-modal/freshservice-create-ticket/freshservice-create-ticket.component';
import { FreshserviceIntegrationStepOneComponent } from './../../components/modal-templates/integration/freshservice-modal/freshservice-integration-step-one/freshservice-integration-step-one.component';
import { FreshserviceIntegrationStepThreeComponent } from './../../components/modal-templates/integration/freshservice-modal/freshservice-integration-step-three/freshservice-integration-step-three.component';
import { FreshserviceIntegrationStepTwoComponent } from './../../components/modal-templates/integration/freshservice-modal/freshservice-integration-step-two/freshservice-integration-step-two.component';
import { FreshserviceIntegrationsTableComponent } from './../../components/modal-templates/integration/freshservice-modal/freshservice-integrations-table/freshservice-integrations-table.component';
import { WidgetConfigurationComponent } from './../../components/modal-templates/query-builder-modal/widget-configuration/widget-configuration.component';
import { WidgetDetailsComponent } from './../../components/modal-templates/query-builder-modal/widget-details/widget-details.component';
import { PieTemplateComponent } from './../../components/pie-template/pie-template.component';
import { AzureSponsorshipOnboardingListingComponent } from './../../components/widgets/1.0/azure-sponsorship-onboarding-listing/azure-sponsorship-onboarding-listing.component';
import { CaIntegrationComponent } from './../../components/widgets/1.0/ca-integration/ca-integration/ca-integration.component';
import { CustomerListingV2Component } from './../../components/widgets/1.0/customer-listing-v2/customer-listing-v2.component';
import { CustomerServiceComponent } from './../../components/widgets/1.0/customer-service/customer-service.component';
import { SalesforceListingComponent } from './../../components/widgets/1.0/salesforce-listing/salesforce-listing.component';
import { CustomerServiceListing } from './../../components/widgets/1.0/widget-customer-listing/customer-service-listing.component';
import { FreshserviceIntegrationV2Component } from './../../components/widgets/2.0/freshservice-integration-v2/freshservice-integration-v2.component';
import { GraphModule } from './../graph/graph.module';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { EmailTemplatesComponent } from '../../components/widgets/1.0/email-templates/email-templates.component';

const components = [
    TimeseriesComponent,
    PieComponent,
    RegionalComponent,
    AggregateComponent,
    AllocationChecksComponent,
    ExpandableTableComponent,
    RibbonComponent,
    RightsizingComponent,
    RiDescriptionComponent,
    RiRecommendationComponent,
    OptimizerChecksComponent,
    AutoRecommendationComponent,
    OverviewComponent,
    GaugeComponent,
    BargraphComponent,
    ReportListingComponent,
    ManagedReportListingComponent,
    TagConfigurationListingComponent,
    RiUtilizationModalComponent,
    AutoRecommendationModalComponent,
    ViewsComponent,
    TopologyGraphComponent,
    UserProfileComponent,
    CustomerProfileComponent,
    CustomersListingComponent,
    AutomationListingComponent,
    UsersListingComponent,
    UtilisationComponent,
    IntegrationComponent,
    GmailIntegrationComponent,
    DomainConfigurationComponent,
    BudgetListingComponent,
    AlertListingComponent,
    O365IntegrationComponent,
    SlackIntegrationComponent,
    FreshdeskIntegrationComponent,
    FreshserviceIntegrationComponent,
    ZendeskIntegrationComponent,
    SnsIntegrationComponent,
    ServicenowIntegrationComponent,
    CreateAlertFirstStepComponent,
    CreateAlertSecondStepComponent,
    CreateAlertThirdStepComponent,
    FreshdeskListingComponent,
    FreshserviceListingComponent,
    ZendeskListingComponent,
    ServicenowIncidentsListingComponent,
    ServicenowRequestsListingComponent,
    NagiosIntegrationComponent,
    CspIntegrationComponent,
    EaIntegrationComponent,
    PlanIntegrationComponent,
    AwsIntegrationComponent,
    AwsListingComponent,
    CspListingComponent,
    EaListingComponent,
    SavingsPlanRecommendationComponent,
    AdvancedAutomationComponent,
    AzureResourceProvisioningComponent,
    CreateVolumeProvisioningComponent,
    AwsCreateInstanceProvisioningComponent,
    CustomerServiceListing,
    AzureCreateDiskProvisioningComponent,
    ZohoIntegrationComponent,
    CustomerServiceComponent,
    JiradeskIntegrationComponent,
    JiradeskTicketListingComponent,
    AzureCreateVmProvisioningComponent,
    FiltersConfigurationComponent,
    GeneralConfigurationComponent,
    ZohoListingComponent,
    ServiceCatalogComponent,
    ServiceCatalogConfigurationComponent,
    ServiceNowVariablesComponent,
    ServicenowVariableSetFormComponent,
    AllocationChecksV2Component,
    OptimizerCheckV2Component,
    OptimizerCheckV3Component,
    OtrsIntegrationComponent,
    AutotaskIntegrationComponent,
    AutotaskTicketListingComponent,
    SubDomainComponent,
    OtrsTicketListingComponent,
    SsoIntegrationComponent,
    SsoIntegrationStepOneComponent,
    SsoIntegrationStepTwoComponent,
    SsoIntegrationsTableComponent,
    CaIntegrationComponent,
    CaTicketListingComponent,
    TagConfigurationListingV2Component,
    CustomComplianceListingComponent,
    MfaComponent,
    LoginMetricsComponent,
    AdoptionMetricsComponent,
    GcpListingComponent,
    GcpIntegrationComponent,
    MyPriceListingComponent,
    PartnerVisibilityComponent,
    RightsizingV2Component,
    FreshserviceIntegrationV2Component,
    FreshserviceListingV2Component,
    FreshserviceIntegrationStepOneComponent,
    FreshserviceIntegrationStepTwoComponent,
    FreshserviceIntegrationStepThreeComponent,
    FreshserviceIntegrationsTableComponent,
    FreshserviceCreateTicketComponent,
    FreshserviceAddFieldComponent,
    ExpandableTableV2Component,
    ArmJsonTemplatesListingComponent,
    CreateWidgetJsonComponent,
    AssessmentListingComponent,
    IssueAssessmentListingComponent,
    AclListingComponent,
    CpuUtilizationComponent,
    WhitelabelingComponent,
    WhitelabelingDomainComponent,
    WhitelabelingProprietaryComponent,
    WhitelabelingThemeComponent,
    WhitelabelingSocialComponent,
    WhitelebalingPrivacyPolicyComponent,
    WhitelabelingEmailComponent,
    WhitelebelingTermsOfServicesComponent,
    SingleStackedBarComponent,
    AllocationChecksV3Component,
    AzureSponsorshipOnboardingListingComponent,
    AzureSponsorshipIntegrationComponent,
    RightsizingV3Component,
    CustomCostMonitoringWidgetListingComponent,
    WidgetDetailsComponent,
    QueryBuilderTemplateComponent,
    AzureMcaIntegrationComponent,
    CustomWidgetListingComponent,
    CustomRequestWidgetComponent,
    TerraformCreateWidgetComponent,
    TerraformRequestWidgetListingComponent,
    WidgetConfigurationComponent,
    CustomWidgetPreviewComponent,
    PieTemplateComponent,
    WidgetDocumentationListingComponent,
    CustomerListingV2Component,
    SalesforceIntegrationComponent,
    SalesforceListingComponent,
    RiDescriptionV2Component,
    AssessmentUserAccessComponent,
    AssessmentAssesseeComponent,
    EmailTemplatesComponent
];

const modules = [
    CommonModule,
    FormsModule,
    AgGridModule.withComponents([]),
    HelperComponentModule,
    UtilsModule,
    MaterialModule,
    NgxGaugeModule,
    MonacoEditorModule,
    FormModule,
    CommonPipesModule,
    GraphModule,
    ClipboardModule
];
@NgModule({
    declarations: [...components],
    imports: [...modules],
    exports: [],
    entryComponents: [...components]
})
export class SharedWidgetsModule {}
