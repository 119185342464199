import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';

@Component({
    selector: 'app-customer-service',
    templateUrl: './customer-service.component.html',
    styleUrls: ['./customer-service.component.sass']
})
export class CustomerServiceComponent implements OnInit {
    widgetRef: Widget;
    isAllowed = false;
    buyLoader = false;
    message = null;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetRef.headerIcon.next({
            class: 'fas fa-th-list',
            type: IconType.FONTAWSOME
        });
    }

    bindData(data): void {
        this.message = null;
        this.isAllowed = false;
        if (data && data['canRequest']) {
            this.isAllowed = true;
        }
        if (data && data['message']) {
            this.message = data['message'];
        }
        this.widgetRef.loadingData.next(false);
    }

    requestToBuy() {
        if (this.buyLoader) {
            return;
        }
        if (!this.isAllowed) {
            this.widgetRef.notificationsService.showSnackBar(
                this.message
                    ? this.message
                    : 'You are not allowed to raise request as of now'
            );
            return;
        }
        this.buyLoader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.action
        );
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                'Request raised successfully'
            );
            this.buyLoader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.refreshWidget();
        };

        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error raising request'
            );
            this.buyLoader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
