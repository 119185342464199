import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { McaOnboardingModalComponent } from './../../../modal-templates/account-onboarding-modals/mca-onboarding-modal/mca-onboarding-modal.component';
@Component({
    selector: 'app-azure-mca-integration',
    templateUrl: './azure-mca-integration.component.html',
    styleUrls: ['./azure-mca-integration.component.sass']
})
export class AzureMcaIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Azure Integration',
            imageUrl: 'assets/Azure-Mca.png',
            buttons: [
                {
                    buttonName: 'Add',
                    buttonType: ButtonType.CIRCLE,
                    buttonColorType: ButtonColorType.SUCCESS,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    function: (buttonRef) => {
                        //Open Modal
                        this.onIntegrate(buttonRef);
                    }
                }
            ],
            imagePadding: 4
        };
    }
    onIntegrate(buttonRef: any) {
        const modalArgs: IModalData = {
            modalName: 'On Board MCA Account',
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            sourceId: this.widgetRef.uniqueIdentity,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: McaOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: null,
                                edit: false
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 65,
            modalHeightVh: 95,
            noBottomPadding: true
        };
        this.modalService.openModal(modalArgs);
    }
}
