import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class EmailValidator extends CustomValidator {
    key = CustomValidatorKey.EMAIL;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const EMAIL_REGEX =
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return Validators.pattern(EMAIL_REGEX)(control);
    }
}
