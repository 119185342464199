import { Component, EventEmitter, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-add-resource-tags',
    templateUrl: './add-resource-tags.component.html',
    styleUrls: ['./add-resource-tags.component.sass']
})
export class AddResourceTagsComponent implements OnInit {
    resourceTaggingList = null;
    jsonCreatorData = {
        inputData: {},
        output: new EventEmitter()
    };
    addResourceTagJsonObjectData = {};
    buttonInput: IButtonGeneratorInput;

    constructor(
        private modalInputData: ModalInjectedData,
        private notificationsService: NotificationsService
    ) {
        this.resourceTaggingList =
            modalInputData.data['inputDataForCreatingResourceTag'];
    }

    ngOnInit(): void {
        this.jsonCreatorData.output.subscribe((response) => {
            this.addResourceTagJsonObjectData = response;
        });
        this.buttonInput = {
            buttonName: 'Create/Update Resource Tag(s)',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.onSubmit(buttonRef);
            }
        };
    }

    onSubmit(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        if (Object.keys(this.addResourceTagJsonObjectData).length === 0) {
            this.notificationsService.showSnackBar(
                'Create Atleast One Resource Tag',
                true
            );
            return;
        }
        buttonRef.loader = true;
        this.modalInputData.data['function'](
            this.resourceTaggingList,
            this.addResourceTagJsonObjectData,
            buttonRef,
            this.modalInputData.modalId
        );
    }
}
