import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-sso-integration-step-one',
    templateUrl: './sso-integration-step-one.component.html',
    styleUrls: ['./sso-integration-step-one.component.sass']
})
export class SsoIntegrationStepOneComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    isUserIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response;
    widgetRef: Widget;
    stepData: Object = null;
    edit: boolean;
    formGroup: FormGroup;
    resetSubscription: Subscription;
    buttonGenInput: IButtonGeneratorInput;
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.response = modalInputData.data['response'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.edit = modalInputData.data['edit'];
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1).stepData;
        } else if (this.edit) {
            this.stepData = modalInputData.data['response'];
        }
    }

    ngOnInit(): void {
        this.resetSubscription =
            this.widgetRef.modalService.resetModal.subscribe(() => {
                this.formGroup.reset();
            });
        this.formGenInput = {
            formName: 'SAML',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'configurationName',
                    placeholder: 'Integration Name',
                    label: 'Integration Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Integration Name is required'
                        },
                        {
                            validator: CustomValidators.maxLength(15),
                            errorMessage: 'Maximum 15 characters allowed'
                        },
                        {
                            validator:
                                CustomValidators.alphaNumWithUnderscoreValidator,
                            errorMessage: `Only "_" is allowed as special character`
                        }
                    ],
                    disabled: this.edit ? this.edit : false,
                    value: this.stepData
                        ? this.stepData['configurationName']
                        : ''
                },
                {
                    name: 'domainName',
                    placeholder: 'Domain Name',
                    label: 'Domain Name',
                    fieldType: FilterType.MATCHIPLIST,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain Id is required'
                        }
                    ],
                    value: this.stepData ? this.stepData['domainName'] : ''
                },
                {
                    name: 'emailAttribute',
                    placeholder: 'Email Attribute',
                    label: 'Email Attribute',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Attribute is required'
                        }
                    ],
                    value: this.stepData ? this.stepData['emailAttribute'] : '',
                    disabled: this.edit ? this.edit : false
                },
                {
                    name: 'roleAttribute',
                    placeholder: 'Role Attribute',
                    label: 'Role Attribute',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.stepData ? this.stepData['roleAttribute'] : '',
                    disabled: this.edit ? this.edit : false
                },
                {
                    name: 'ignoredEmailIds',
                    placeholder: 'Restricted Emails',
                    label: 'Restricted Emails',
                    fieldType: FilterType.MATCHIPLIST,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.stepData
                        ? this.stepData['ignoredEmailIds']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.emailList,
                            errorMessage: 'Enter valid email id'
                        }
                    ]
                },
                {
                    label: 'SAML Metadata',
                    placeholder: 'SAML Metadata',
                    name: 'metaDataInputType',
                    fieldType: FilterType.RADIO,
                    extraClass: 'tw-mt-6',
                    listData: [
                        { id: 'manually', label: 'Enter Manually' },
                        { id: 'link', label: 'Enter Link' }
                    ],
                    value: this.stepData
                        ? this.stepData['samlMetadataFile'] ||
                          !this.stepData['samlMetadataFileUrl']
                            ? 'manually'
                            : 'link'
                        : 'manually',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    disabled: this.edit ? this.edit : false,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'SAML Metadata is required'
                        }
                    ]
                },
                {
                    label: 'Enter Manually',
                    placeholder: 'Enter Manually',
                    name: 'samlMetadataFile',
                    value: this.stepData
                        ? this.stepData['samlMetadataFile']
                        : '',
                    fieldType: FilterType.TEXTAREA,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    extraClass: 'tw-items-center',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required'
                        }
                    ],
                    disabled: this.edit ? this.edit : false,
                    hiddenDependency: [
                        {
                            controlName: 'metaDataInputType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'manually'
                                        )
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Enter Link',
                    placeholder: 'Enter Link',
                    name: 'samlMetadataFileUrl',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.stepData
                        ? this.stepData['samlMetadataFileUrl']
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Link is required'
                        }
                    ],
                    disabled: this.edit ? this.edit : false,
                    hiddenDependency: [
                        {
                            controlName: 'metaDataInputType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'link'
                                        )
                                }
                            ]
                        }
                    ]
                }
            ],
            extraClass: 'integration-styling'
        };
        this.buttonGenInput = {
            buttonName: 'Next',
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                Helper.markAllFieldAsTouched(this.formGroup);
                if (this.formGroup.invalid) {
                    this.formGroup.updateValueAndValidity();
                    return;
                }
                if (this.formGroup.valid) {
                    const input = {
                        ...this.formGroup.getRawValue()
                    };
                    const formData = {
                        stepData: input
                    };
                    if (this.edit) {
                        formData.stepData['configurationName'] =
                            this.response['configurationName'];
                    }
                    this.multiStepFormService.stepData
                        .get(this.modalInputData.modalId)
                        .set(1, formData);
                    this.multiStepFormService.nextStep(
                        this.modalInputData.modalId
                    );
                }
            }
        };
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
