import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ApiResponseType } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { AutotaskCreateTicketModalComponent } from '../../../modal-templates/integration/autotask-modal/autotask-create-ticket-modal/autotask-create-ticket-modal.component';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-autotask-ticket-listing',
    templateUrl: './autotask-ticket-listing.component.html',
    styleUrls: ['./autotask-ticket-listing.component.sass']
})
export class AutotaskTicketListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    widgetIcon: IIcon = {
        class: 'assets/integrations/autotask.png',
        type: IconType.IMAGE
    };
    autotaskStatusResponse;
    clientFilterInfo: BehaviorSubject<IFilterInfo> =
        new BehaviorSubject<IFilterInfo>({ placeholder: 'Loading...' });
    statusFilterInfo: BehaviorSubject<IFilterInfo> =
        new BehaviorSubject<IFilterInfo>({ placeholder: 'Loading...' });
    showTemplate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    listingApiInfo;
    defaultStatusValue;
    defaultClientId;
    clientIdList = null;
    tableData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    constructor(
        widgetData: WidgetInjectedData,
        private cdr: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.widgetRef.headerIcon.next(this.widgetIcon);
    }

    ngOnInit(): void {
        const apiArgs: IHitApi = {
            url: ApiUrls.AUTOTASK_CLIENT_IDS,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.clientIdList = response['clientList'];
                this.clientFilterInfo.next({
                    listData: response['clientList'],
                    placeholder: 'Select Client',
                    lightMode: true
                });
            },
            endFunction: () => {
                this.clientFilterInfo.subscribe();
                this.cdr.detectChanges();
                this.showLoader.next(false);
                this.showLoader.subscribe();
                this.setUpBasics();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error fetching client list.'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    setUpBasics() {
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        const apiArgs: IHitApi = {
            url: '/srm-integrations/integration-status/AUTOTASK',
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.autotaskStatusResponse = response;

                if (this.autotaskStatusResponse['childIntegration']) {
                    this.showTemplate.next(false);
                }

                this.statusFilterInfo.next({
                    listData: response['ownerConfiguredFeilds']
                        ? response['ownerConfiguredFeilds']['status']
                        : response['selfConfiguredFeilds']['status'],
                    placeholder: 'Select Status',
                    lightMode: true
                });
            },
            endFunction: () => {
                this.showTemplate.subscribe();
                this.statusFilterInfo.subscribe();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error hitting autotask status api.'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();

        //for the case when widget errors out and is refreshed from actions
        this.widgetRef.isBindDataAssigned = false;

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Ticket',
                    function: (buttonRef: IButtonData) => {
                        const modalArgs: IModalData = {
                            modalName: 'Autotask Create Ticket',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-wrench'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Credentials',
                                    stepData: {
                                        componentToLoad:
                                            AutotaskCreateTicketModalComponent,
                                        payload: {
                                            data: {
                                                autotaskStatusResponse:
                                                    this.autotaskStatusResponse,
                                                widgetRef: this.widgetRef,
                                                clientIdList: this.clientIdList
                                            }
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 60
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    },
                    showLoader: false,
                    buttonType: 'PRIMARY'
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'ticketList'
            },
            noDataMessage: 'No Tickets Available',
            afterResponse: (response) => {
                this.listingApiInfo = response;
                if (this.listingApiInfo['defaultTicketInfo']) {
                    this.defaultStatusValue =
                        this.listingApiInfo['defaultTicketInfo']['statusValue'];
                    this.defaultClientId =
                        this.listingApiInfo['defaultTicketInfo']['clientId'];
                }
            },
            columns: [
                {
                    columnKey: 'CreateDate',
                    columnName: 'Create Date'
                },
                {
                    columnKey: 'Description',
                    columnName: 'Description'
                },
                {
                    columnKey: 'Priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'QueueID',
                    columnName: 'Queue Id'
                },
                {
                    columnKey: 'Status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'TicketNumber',
                    columnName: 'Ticket Number'
                },
                {
                    columnKey: 'Title',
                    columnName: 'Title'
                }
            ]
        };
    }
    statusChange($event) {
        if ($event) {
            this.defaultStatusValue = $event;
        }

        this.hitListingApi();
    }
    clientChange($event) {
        if ($event) {
            this.defaultClientId = $event;
        }

        this.hitListingApi();
    }
    bindData(apiResponse: ApiResponseType) {
        this.tableData.next(apiResponse);
        this.cdr.detectChanges();
    }
    hitListingApi() {
        const customInput = {
            statusValue: this.defaultStatusValue,
            clientId: this.defaultClientId
        };
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this), true, customInput);
    }
}
