<app-vertical-scrolling-tab-group [modalId]="modalData.modalId">
    <ng-template appVerticalScrollingTab [tabLabel]="'Recommendation'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #recommendation
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Recommendation" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!recommendation.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="recommendation.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="table-container">
                    <div
                        class="tw-pt-8 tw-font-bold tw-text-center tw-text-fs-600"
                    >
                        Configuration
                    </div>
                    <div class="tabs">
                        <div class="tab-header">
                            <ng-container
                                *ngFor="
                                    let tab of tabs.length ? tabs : staticTabs
                                "
                            >
                                <div
                                    class="tab-header-item"
                                    *ngIf="objectKeys(card).includes(tab)"
                                    [class.active]="selectedTab === tab"
                                    (click)="selectedTab = tab; refreshTable()"
                                >
                                    {{ tab }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <app-only-table-generator
                        *ngIf="recommendationTableGen"
                        [hideControlBar]="true"
                        [tableData]="recommendationTableData"
                        [tableInput]="recommendationTableGen"
                        (gridRef)="recommendationGrid = $event"
                    ></app-only-table-generator>
                </div>

                <div class="table-container">
                    <div
                        class="tw-p-8 tw-font-bold tw-text-center tw-text-fs-600"
                    >
                        Amortized Cost
                    </div>
                    <app-only-table-generator
                        *ngIf="amortizedTableGen"
                        [hideControlBar]="true"
                        [tableData]="amortizedTableData"
                        [tableInput]="amortizedTableGen"
                        (gridRef)="amortizedGrid = $event"
                    ></app-only-table-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
    <ng-template appVerticalScrollingTab [tabLabel]="'Description'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #description
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Description" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!description.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="description.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="table-container">
                    <app-only-table-generator
                        *ngIf="descriptionTableData"
                        [tableData]="descriptionTableData"
                        [tableInput]="descriptionTableGenInput"
                        [hideControlBar]="true"
                    ></app-only-table-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</app-vertical-scrolling-tab-group>
