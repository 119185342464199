import { NgZone } from '@angular/core';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { HttpService } from '../services/http/http-main/http.service';
import { Logger } from './Logger';
import { Messages } from './Messages';

export class IotHandler {
    iotConfig: IHitApi;
    iotObservable = null;
    index = 0;
    iotInterval;

    constructor(private httpService: HttpService, private ngZone: NgZone) {}

    init() {
        if (this.iotConfig.extraMessage) {
            this.ngZone.runOutsideAngular(() => {
                this.iotConfig.extraMessage.next(
                    Messages.IOT_MESSAGES[this.index]
                );
                this.iotInterval = setInterval(() => {
                    if (this.index === Messages.IOT_MESSAGES.length - 1) {
                        this.index = 0;
                    } else {
                        this.index = this.index + 1;
                    }
                    this.iotConfig.extraMessage.next(
                        Messages.IOT_MESSAGES[this.index]
                    );
                }, 10000);
            });
        }
    }

    end() {
        if (this.iotConfig.endFunction) {
            this.iotConfig.endFunction();
        }
        if (this.iotObservable) {
            this.iotObservable.unsubscribe();
        }
        this.index = 0;
        clearInterval(this.iotInterval);
    }

    listner() {
        this.init();
        this.httpService.resetIotTask.subscribe((identity) => {
            if (
                this.iotConfig.uniqueIdentity === identity &&
                this.iotInterval
            ) {
                clearInterval(this.iotInterval);
            }
        });
        this.iotObservable = this.httpService.insightIOTDataEmitter.subscribe(
            (response) => {
                if (this.iotConfig.uniqueIdentity in response) {
                    Logger.codeLog('IOT Data received ...');
                    if (this.iotConfig.config.isCached) {
                        // Caching response
                        this.httpService.apiResponseCacheService.store(
                            this.iotConfig.url,
                            {
                                input: this.iotConfig.input,
                                output: response[this.iotConfig.uniqueIdentity][
                                    'data'
                                ]
                            }
                        );
                    }
                    if (
                        this.iotConfig.config &&
                        this.iotConfig.config.sendOnlyResponse
                    ) {
                        this.iotConfig.config.responseSub.next(
                            response[this.iotConfig.uniqueIdentity]['data']
                        );
                    } else {
                        if (this.iotConfig.extraMessage) {
                            this.iotConfig.extraMessage.next('');
                        }
                        this.iotConfig.function(
                            response[this.iotConfig.uniqueIdentity]['data']
                        );
                    }

                    if (this.iotConfig.config.iotReponseCallback) {
                        this.iotConfig.config.iotReponseCallback(
                            response[this.iotConfig.uniqueIdentity]['data']
                        );
                    }

                    this.end();
                }
            }
        );
    }
}
