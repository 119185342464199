<div
    class="breadcrumb"
    [hidden]="
        (widgetRef.loadingData | async) ||
        !(
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        )
    "
>
    <div
        class="patch"
        *ngIf="
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        "
    >
        <span
            class="item-name"
            (click)="$event.stopPropagation(); goBackDrillDown(true)"
            >Home
            <span class="arrow-icon" appIcon [data]="arrowRightIcon"></span>
        </span>

        <span
            class="item-name"
            [class.large]="isLast"
            *ngFor="
                let item of widgetRef.widgetLinkingData.breadCrumbs;
                index as i;
                last as isLast;
                count as count
            "
            (click)="
                $event.stopPropagation();
                goBackDrillDown(null, count - (i + 1), isLast)
            "
            >{{ item }}
            <span
                class="arrow-icon"
                *ngIf="!isLast"
                appIcon
                [data]="arrowRightIcon"
            ></span>
        </span>
    </div>
</div>

<div
    class="controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div class="table-search" *ngIf="!widgetRef.lightState">
        <app-table-search
            [searchFunction]="onQuickFilterChanged.bind(this)"
        ></app-table-search>
    </div>
    <!-- <app-single-select-dropdown
        *ngIf="!(widgetRef.loadingData|async) && (widgetRef.visibleSections|async) && (widgetRef.visibleSections|async).has(ViewType.GRAPH)"
        [listData]="widgetRef.apiResponse.dataMap['pie'] && widgetRef.apiResponse.dataMap['pie']['y']"
        (change)="onOptionsSelected($event)">
    </app-single-select-dropdown> -->
</div>
<div
    [ngClass]="
        (widgetRef.compareViewService.isCompareViewEnabled | async)
            ? 'compare-view'
            : 'widget-data-container'
    "
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        !this.widgetRef.extraMessage.value
    "
>
    <div
        class="tw-flex-1 tw-flex-row graph-container"
        [style.display]="
            (widgetRef.loadingData | async) ||
            !(widgetRef.visibleSections | async).has(ViewType.GRAPH)
                ? 'none'
                : 'flex'
        "
    >
        <div class="tw-block tw-flex-f3 tw-relative chartdivMap">
            <div
                *ngIf="widgetRef.lightState"
                class="lightOrFull clickable"
                (click)="getFullAccess()"
            >
                <p>Get Full Access!</p>
                <p class="description">Graph Details in full version</p>
            </div>
            <app-graph-generator [graphData]="graphData"></app-graph-generator>
        </div>
    </div>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
        *ngIf="
            !(widgetRef.loadingData | async) &&
            (widgetRef.visibleSections | async).has(ViewType.TABLE)
        "
    >
        <app-only-table-generator
            [widgetRef]="widgetRef"
            [tableInput]="tableInputData"
            [tableData]="filteredSearchData"
            (gridRef)="agGrid = $event"
            [getRowStyle]="getRowStyle"
            [showSearchBox]="false"
            (rowClicked)="tableRowClicked($event['data'][tableKeys[0]])"
            [hideControlBar]="true"
            [autoFitColumns]="
                !(
                    tableInputData.columns.length > 6 &&
                    tableInputData.columns.length < 8
                )
            "
            [defaultColDef]="defaultColDef"
        ></app-only-table-generator>
    </div>
</div>
