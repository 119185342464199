<div class="container">
    <div class="form-container tw-flex tw-justify-center">
        <div class="tw-w-3/4">
            <app-form-generator
                [formGeneratorInput]="credentialsFormGenInput"
                (formGroupRef)="credentialFormGroupRef = $event"
            ></app-form-generator>
        </div>
    </div>
    <div class="button tw-flex tw-justify-end">
        <app-button-generator
            [buttonGenInput]="nextButtonGenInput"
        ></app-button-generator>
    </div>
</div>
