<div class="tw-h-full tw-flex tw-flex-col budget-third-step">
    <div class="tw-flex data">
        <app-common-integrations-index
            class="mt-4 ml-1.5"
            [initialSelection]="initialSelection"
            [integrations]="integrations"
            (selected)="integrationsChanged($event)"
            (integrationInfoFunc)="integrationInfoFunc = $event"
        ></app-common-integrations-index>
        <mat-tab-group
            (selectedTabChange)="tabChanged($event)"
            [selectedIndex]="initialTabIndex"
        >
            <mat-tab [label]="Tab.SUM">
                <div
                    class="tw-py-3 tw-px-4 tab-content"
                    *ngIf="selectedTab === Tab.SUM"
                >
                    <ng-container
                        *ngTemplateOutlet="totalBudget"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="frequencyTemplate"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="integrationContent"
                    ></ng-container>
                </div>
            </mat-tab>
            <mat-tab
                [label]="Tab.MAX"
                *ngIf="supportedFormat === SupportedFormat.BUDGET_TIMESERIES"
            >
                <div
                    class="tw-py-3 tw-px-4 tab-content"
                    *ngIf="selectedTab === Tab.MAX"
                >
                    <ng-container
                        *ngTemplateOutlet="totalBudget"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="frequencyOnlyCustomTemplate"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="integrationContent"
                    ></ng-container>
                </div>
            </mat-tab>
            <mat-tab
                [label]="Tab.MIN"
                *ngIf="supportedFormat === SupportedFormat.BUDGET_TIMESERIES"
            >
                <div
                    class="tw-py-3 tw-px-4 tab-content"
                    *ngIf="selectedTab === Tab.MIN"
                >
                    <ng-container
                        *ngTemplateOutlet="totalBudget"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="frequencyOnlyCustomTemplate"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="integrationContent"
                    ></ng-container>
                </div>
            </mat-tab>
            <mat-tab
                [label]="Tab.PEAK"
                *ngIf="supportedFormat === SupportedFormat.BUDGET_TIMESERIES"
            >
                <div
                    class="tw-py-3 tw-px-4 tab-content"
                    *ngIf="selectedTab === Tab.PEAK"
                >
                    <ng-container
                        *ngTemplateOutlet="totalBudget"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="frequencyTemplate"
                    ></ng-container>
                    <ng-container
                        *ngTemplateOutlet="integrationContent"
                    ></ng-container>
                </div>
            </mat-tab>
        </mat-tab-group>

        <ng-template #totalBudget>
            <app-form-generator
                class="noPadding"
                [intitalFormGroup]="
                    totalBudgetFormGroup ? totalBudgetFormGroup : null
                "
                [formGeneratorInput]="totalBudgetFormGenInput"
                (formGroupRef)="totalBudgetFormGroup = $event"
            ></app-form-generator>
        </ng-template>

        <ng-template #frequencyTemplate>
            <app-form-generator
                class="noPadding"
                [intitalFormGroup]="
                    frequencyFormGroup ? frequencyFormGroup : null
                "
                [formGeneratorInput]="frequencyFormGenInput"
                (formGroupRef)="frequencyFormGroup = $event"
            ></app-form-generator>
            <app-form-generator
                class="noPadding"
                *ngIf="
                    frequencyFormGroup &&
                    frequencyFormGroup.get('frequency').value === 'custom'
                "
                [intitalFormGroup]="dateFormGroup ? dateFormGroup : null"
                [formGeneratorInput]="dateFormGenInput"
                (formGroupRef)="dateFormGroup = $event"
                (valuesChange)="dateVerification()"
            ></app-form-generator>
        </ng-template>

        <ng-template #frequencyOnlyCustomTemplate>
            <app-form-generator
                class="noPadding"
                [intitalFormGroup]="
                    frequencyOnlyCustomFormGroup
                        ? frequencyOnlyCustomFormGroup
                        : null
                "
                [formGeneratorInput]="frequencyOnlyCustomFormGenInput"
                (formGroupRef)="frequencyOnlyCustomFormGroup = $event"
            ></app-form-generator>
            <app-form-generator
                class="noPadding"
                *ngIf="
                    frequencyOnlyCustomFormGroup &&
                    frequencyOnlyCustomFormGroup.get('frequency').value ===
                        'custom'
                "
                [intitalFormGroup]="dateFormGroup ? dateFormGroup : null"
                [formGeneratorInput]="dateFormGenInput"
                (formGroupRef)="dateFormGroup = $event"
                (valuesChange)="dateVerification()"
            ></app-form-generator>
        </ng-template>

        <ng-template #integrationContent>
            <section
                class="tw-py-1.5 email-fg"
                *ngIf="
                    selectedIntegration &&
                    (selectedIntegration.has(IntegrationName.EMAIL) ||
                        selectedIntegration.has(IntegrationName.O365) ||
                        selectedIntegration.has(IntegrationName.GMAIL))
                "
            >
                <div class="heading">Mail</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let emailFormGenInput of emailFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                class="tw-flex-1"
                                [formGeneratorInput]="emailFormGenInput"
                                [intitalFormGroup]="
                                    emailFormGroups.has(
                                        emailFormGenInput.formId
                                    )
                                        ? emailFormGroups.get(
                                              emailFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    emailFormGroups.set(
                                        emailFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteEmailFormGroup(
                                        i,
                                        emailFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastEmailFormUpdate(
                                        i,
                                        emailFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.SLACK)
                "
            >
                <div class="heading">Slack</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let slackFormGenInput of slackFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="slackFormGenInput"
                                [intitalFormGroup]="
                                    slackFormGroups.has(
                                        slackFormGenInput.formId
                                    )
                                        ? slackFormGroups.get(
                                              slackFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    slackFormGroups.set(
                                        slackFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteSlackFormGroup(
                                        i,
                                        slackFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastSlackFormUpdate(
                                        i,
                                        slackFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.SNS)
                "
            >
                <div class="heading">SNS</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let snsFormGenInput of snsFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="snsFormGenInput"
                                [intitalFormGroup]="
                                    snsFormGroups.has(snsFormGenInput.formId)
                                        ? snsFormGroups.get(
                                              snsFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    snsFormGroups.set(
                                        snsFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteSnsFormGroup(
                                        i,
                                        snsFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastSnsFormUpdate(i, snsFormGenInput.formId)
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.FRESHDESK)
                "
            >
                <div class="heading">Freshdesk</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let freshdeskFormGenInput of freshdeskFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="freshdeskFormGenInput"
                                [intitalFormGroup]="
                                    freshdeskFormGroups.has(
                                        freshdeskFormGenInput.formId
                                    )
                                        ? freshdeskFormGroups.get(
                                              freshdeskFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    freshdeskFormGroups.set(
                                        freshdeskFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteFreshdeskFormGroup(
                                        i,
                                        freshdeskFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastFreshdeskFormUpdate(
                                        i,
                                        freshdeskFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.FRESHSERVICE)
                "
            >
                <div class="heading">Freshservice</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let freshserviceFormGenInput of freshserviceFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="freshserviceFormGenInput"
                                [intitalFormGroup]="
                                    freshserviceFormGroups.has(
                                        freshserviceFormGenInput.formId
                                    )
                                        ? freshserviceFormGroups.get(
                                              freshserviceFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    freshserviceFormGroups.set(
                                        freshserviceFormGenInput.formId,
                                        $event
                                    )
                                "
                                (valuesChange)="
                                    freshserviceConfigValueChanged($event, i)
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteFreshserviceFormGroup(
                                        i,
                                        freshserviceFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastFreshserviceFormUpdate(
                                        i,
                                        freshserviceFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                    <div
                        class="second-form"
                        *ngIf="freshserviceFormData.length"
                    >
                        <ng-container
                            *ngFor="let field of freshserviceFormData"
                        >
                            <ng-container
                                *ngIf="
                                    field.fieldType ===
                                    FilterType.DROPDOWN_DEPENDENT
                                "
                            >
                                <app-dependent-dropdowns
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                ></app-dependent-dropdowns>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    field.fieldType !==
                                        FilterType.DROPDOWN_DEPENDENT &&
                                    field.fieldType !==
                                        FilterType.WYSIWYG_EDITOR &&
                                    !field.partiallyDependentOn
                                "
                            >
                                <app-field-generator-handler
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                ></app-field-generator-handler>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    field.fieldType !==
                                        FilterType.DROPDOWN_DEPENDENT &&
                                    field.fieldType !==
                                        FilterType.WYSIWYG_EDITOR &&
                                    field.partiallyDependentOn
                                "
                            >
                                <app-field-generator-handler
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    [listData]="field.partiallyDependentOn"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                ></app-field-generator-handler>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-flex-1 step-loader"
                        *ngIf="freshserviceLoader"
                    >
                        <mat-spinner
                            [diameter]="20"
                            class="mat-spinner-color"
                        ></mat-spinner>
                    </div>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.ZENDESK)
                "
            >
                <div class="heading">Zendesk</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let zendeskFormGenInput of zendeskFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="zendeskFormGenInput"
                                [intitalFormGroup]="
                                    zendeskFormGroups.has(
                                        zendeskFormGenInput.formId
                                    )
                                        ? zendeskFormGroups.get(
                                              zendeskFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    zendeskFormGroups.set(
                                        zendeskFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteZendeskFormGroup(
                                        i,
                                        zendeskFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastZendeskFormUpdate(
                                        i,
                                        zendeskFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.JIRADESK)
                "
            >
                <div class="heading">Jiradesk</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let jiradeskFormGenInput of jiradeskFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="jiradeskFormGenInput"
                                [intitalFormGroup]="
                                    jiradeskFormGroups.has(
                                        jiradeskFormGenInput.formId
                                    )
                                        ? jiradeskFormGroups.get(
                                              jiradeskFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    jiradeskFormGroups.set(
                                        jiradeskFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteJiradeskFormGroup(
                                        i,
                                        jiradeskFormGenInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastJiradeskFormUpdate(
                                        i,
                                        jiradeskFormGenInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.AUTOTASK)
                "
            >
                <div class="heading">Autotask</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let autotaskFormGenInput of autotaskFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="autotaskFormGenInput"
                                [intitalFormGroup]="
                                    autotaskFormGroups.has(
                                        autotaskFormGenInput.formId
                                    )
                                        ? autotaskFormGroups.get(
                                              autotaskFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    autotaskFormGroups.set(
                                        autotaskFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteAutotaskFormGroup(
                                        i,
                                        autotaskFormGenInput.formId,
                                        IntegrationName.AUTOTASK
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastAutotaskFormUpdate(
                                        i,
                                        autotaskFormGenInput.formId,
                                        IntegrationName.AUTOTASK
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.AUTOTASK_CHILD)
                "
            >
                <div class="heading">Autotask (Child Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let autotaskChildFormGenInput of autotaskChildFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="autotaskChildFormGenInput"
                                [intitalFormGroup]="
                                    autotaskChildFormGroups.has(
                                        autotaskChildFormGenInput.formId
                                    )
                                        ? autotaskChildFormGroups.get(
                                              autotaskChildFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    autotaskChildFormGroups.set(
                                        autotaskChildFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteAutotaskFormGroup(
                                        i,
                                        autotaskChildFormGenInput.formId,
                                        IntegrationName.AUTOTASK_CHILD
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastAutotaskFormUpdate(
                                        i,
                                        autotaskChildFormGenInput.formId,
                                        IntegrationName.AUTOTASK_CHILD
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.AUTOTASK_PARENT)
                "
            >
                <div class="heading">Autotask (Parent Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let autotaskParentFormGenInput of autotaskParentFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="
                                    autotaskParentFormGenInput
                                "
                                [intitalFormGroup]="
                                    autotaskParentFormGroups.has(
                                        autotaskParentFormGenInput.formId
                                    )
                                        ? autotaskParentFormGroups.get(
                                              autotaskParentFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    autotaskParentFormGroups.set(
                                        autotaskParentFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteAutotaskFormGroup(
                                        i,
                                        autotaskParentFormGenInput.formId,
                                        IntegrationName.AUTOTASK_PARENT
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastAutotaskFormUpdate(
                                        i,
                                        autotaskParentFormGenInput.formId,
                                        IntegrationName.AUTOTASK_PARENT
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.OTRS)
                "
            >
                <div class="heading">OTRS</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let otrsFormGenInput of otrsFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="otrsFormGenInput"
                                [intitalFormGroup]="
                                    otrsFormGroups.has(otrsFormGenInput.formId)
                                        ? otrsFormGroups.get(
                                              otrsFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    otrsFormGroups.set(
                                        otrsFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteOtrsFormGroup(
                                        i,
                                        otrsFormGenInput.formId,
                                        IntegrationName.OTRS
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastOtrsFormUpdate(
                                        i,
                                        otrsFormGenInput.formId,
                                        IntegrationName.OTRS
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.OTRS_USER)
                "
            >
                <div class="heading">OTRS (User Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let otrsUserFormGenInput of otrsUserFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="otrsUserFormGenInput"
                                [intitalFormGroup]="
                                    otrsUserFormGroups.has(
                                        otrsUserFormGenInput.formId
                                    )
                                        ? otrsUserFormGroups.get(
                                              otrsUserFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    otrsUserFormGroups.set(
                                        otrsUserFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteOtrsFormGroup(
                                        i,
                                        otrsUserFormGenInput.formId,
                                        IntegrationName.OTRS_USER
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastOtrsFormUpdate(
                                        i,
                                        otrsUserFormGenInput.formId,
                                        IntegrationName.OTRS_USER
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.OTRS_PARENT)
                "
            >
                <div class="heading">OTRS (Parent Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let otrsParentFormGenInput of otrsParentFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="otrsParentFormGenInput"
                                [intitalFormGroup]="
                                    otrsParentFormGroups.has(
                                        otrsParentFormGenInput.formId
                                    )
                                        ? otrsParentFormGroups.get(
                                              otrsParentFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    otrsParentFormGroups.set(
                                        otrsParentFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteOtrsFormGroup(
                                        i,
                                        otrsParentFormGenInput.formId,
                                        IntegrationName.OTRS_PARENT
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastOtrsFormUpdate(
                                        i,
                                        otrsParentFormGenInput.formId,
                                        IntegrationName.OTRS_PARENT
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.CASDM)
                "
            >
                <div class="heading">CASDM</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let casdmFormGenInput of casdmFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="casdmFormGenInput"
                                [intitalFormGroup]="
                                    casdmFormGroups.has(
                                        casdmFormGenInput.formId
                                    )
                                        ? casdmFormGroups.get(
                                              casdmFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    casdmFormGroups.set(
                                        casdmFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteCasdmFormGroup(
                                        i,
                                        casdmFormGenInput.formId,
                                        IntegrationName.CASDM
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastCasdmFormUpdate(
                                        i,
                                        casdmFormGenInput.formId,
                                        IntegrationName.CASDM
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.CASDM_CHILD)
                "
            >
                <div class="heading">CASDM (Child Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let casdmChildFormGenInput of casdmChildFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="casdmChildFormGenInput"
                                [intitalFormGroup]="
                                    casdmChildFormGroups.has(
                                        casdmChildFormGenInput.formId
                                    )
                                        ? casdmChildFormGroups.get(
                                              casdmChildFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    casdmChildFormGroups.set(
                                        casdmChildFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteCasdmFormGroup(
                                        i,
                                        casdmChildFormGenInput.formId,
                                        IntegrationName.CASDM_CHILD
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastCasdmFormUpdate(
                                        i,
                                        casdmChildFormGenInput.formId,
                                        IntegrationName.CASDM_CHILD
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.CASDM_PARENT)
                "
            >
                <div class="heading">CASDM (Parent Tenant Integration)</div>
                <div class="section-content">
                    <ng-container
                        *ngFor="
                            let casdmParentFormGenInput of casdmParentFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="casdmParentFormGenInput"
                                [intitalFormGroup]="
                                    casdmParentFormGroups.has(
                                        casdmParentFormGenInput.formId
                                    )
                                        ? casdmParentFormGroups.get(
                                              casdmParentFormGenInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    casdmParentFormGroups.set(
                                        casdmParentFormGenInput.formId,
                                        $event
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteCasdmFormGroup(
                                        i,
                                        casdmParentFormGenInput.formId,
                                        IntegrationName.CASDM_PARENT
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastCasdmFormUpdate(
                                        i,
                                        casdmParentFormGenInput.formId,
                                        IntegrationName.CASDM_PARENT
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>
                </div>
            </section>

            <!-- SALESFORCE TEMPLATE -->

            <section
                class="integration-fg"
                *ngIf="
                    selectedIntegration &&
                    selectedIntegration.has(IntegrationName.SALESFORCE)
                "
            >
                <div class="heading">Salesforce</div>
                <div class="section-content salesforce-container">
                    <ng-container
                        *ngFor="
                            let salesforceFormInput of salesforceFormGenInputs;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="item">
                            <app-form-generator
                                [formGeneratorInput]="salesforceFormInput"
                                [intitalFormGroup]="
                                    salesforceFormGroups.has(
                                        salesforceFormInput.formId
                                    )
                                        ? salesforceFormGroups.get(
                                              salesforceFormInput.formId
                                          )
                                        : null
                                "
                                (formGroupRef)="
                                    salesforceFormGroups.set(
                                        salesforceFormInput.formId,
                                        $event
                                    )
                                "
                                (valuesChange)="
                                    salesforceConfigurationValueChanged(
                                        $event,
                                        i
                                    )
                                "
                            ></app-form-generator>
                            <mat-icon
                                class="error"
                                *ngIf="!last"
                                (click)="
                                    deleteSalesFormGroup(
                                        i,
                                        salesforceFormInput.formId
                                    )
                                "
                                >delete</mat-icon
                            >
                            <mat-icon
                                class="success"
                                *ngIf="last"
                                (click)="
                                    lastSalesforceFormUpdate(
                                        i,
                                        salesforceFormInput.formId
                                    )
                                "
                                >add</mat-icon
                            >
                        </div>
                    </ng-container>

                    <div
                        class="second-form"
                        *ngIf="
                            salesforceConfigFormGenInput &&
                            salesforceConfigFormGenInput.fields &&
                            salesforceConfigFormGenInput.fields.length
                        "
                    >
                        {{ salesforceConfigFormGenInput.formName }}
                        <app-form-generator
                            *ngIf="salesforceConfigFormGenInput"
                            [formGeneratorInput]="salesforceConfigFormGenInput"
                            (formGroupRef)="
                                salesforceConfigFormGroupRef = $event
                            "
                        >
                        </app-form-generator>
                    </div>
                </div>
            </section>
        </ng-template>
    </div>
    <div class="tw-flex tw-justify-between py-0 px-5 controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>

        <app-button-generator
            *ngIf="saveButton"
            [buttonGenInput]="saveButton"
        ></app-button-generator>
    </div>
</div>
