<div class="container">
    <div class="top-header-buttons">
        <app-multi-button-generator [buttonGenInputs]="topHeaderButtons">
        </app-multi-button-generator>
    </div>

    <div class="main-content">
        <div class="left-container">
            <div class="table-search">
                <app-table-search
                    [data]="documentTypes"
                    (changed)="filteredDocumentTypes = $event"
                ></app-table-search>
            </div>

            <div class="content-list">
                <ng-container *ngFor="let data of filteredDocumentTypes">
                    <div
                        class="each clickable"
                        [class.active]="
                            selectedDocument &&
                            selectedDocument.type === data.type
                        "
                        (click)="documentSelectionChange(data)"
                    >
                        {{ data.type }}
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="right-container">
            <div class="content">
                <ng-container
                    *ngIf="
                        selectedDocument.type !== documentTypesEnums.IMAGES &&
                        selectedDocument.type !== documentTypesEnums.VIDEOS
                    "
                >
                    <app-form-generator
                        *ngIf="contentFormGenInput"
                        class="full-height"
                        [formGeneratorInput]="contentFormGenInput"
                        (valuesChange)="
                            selectedDocument.content =
                                $event && $event.content ? $event.content : ''
                        "
                    >
                    </app-form-generator>
                </ng-container>

                <ng-container
                    *ngIf="
                        selectedDocument.type === documentTypesEnums.IMAGES ||
                        selectedDocument.type === documentTypesEnums.VIDEOS
                    "
                >
                    <div class="upload-container">
                        <span
                            class="info-icon clickable"
                            appIcon
                            [data]="infoIcon"
                            matTooltipPosition="left"
                            [matTooltip]="
                                selectedDocument.type ===
                                documentTypesEnums.IMAGES
                                    ? contentLimitMessage[
                                          documentTypesEnums.IMAGES
                                      ]
                                    : contentLimitMessage[
                                          documentTypesEnums.VIDEOS
                                      ]
                            "
                        >
                        </span>

                        <span
                            class="clickable accent"
                            (click)="addImageOrVideo()"
                            *ngIf="
                                selectedDocument.type ===
                                documentTypesEnums.IMAGES
                            "
                        >
                            Add Image
                        </span>

                        <ng-container
                            *ngIf="
                                selectedDocument.listData &&
                                selectedDocument.listData.length
                            "
                        >
                            <div class="upload-container-child">
                                <ng-container
                                    *ngFor="
                                        let data of selectedDocument.listData;
                                        index as i
                                    "
                                >
                                    <div class="file-upload">
                                        <span
                                            *ngIf="data.selectionStatus"
                                            class="clickable cross-icon"
                                            appIcon
                                            [data]="crossIcon"
                                            (click)="
                                                deleteImageOrVideo(
                                                    selectedDocument,
                                                    i
                                                )
                                            "
                                        >
                                        </span>

                                        <input
                                            type="file"
                                            [id]="data.id"
                                            [accept]="
                                                selectedDocument.contentAccept
                                            "
                                            (change)="
                                                fileUploadChange(
                                                    $event.target.files,
                                                    i
                                                )
                                            "
                                        />
                                        <label [for]="data.id">
                                            <div
                                                class="spinner-icon"
                                                *ngIf="
                                                    !data.uploadStatus &&
                                                    data.selectionStatus
                                                "
                                            >
                                                <mat-spinner
                                                    class="dark-theme loader"
                                                    [diameter]="25"
                                                ></mat-spinner>
                                            </div>
                                            <ng-container
                                                *ngIf="
                                                    data.selectionStatus &&
                                                    data.previewData
                                                "
                                            >
                                                <img
                                                    *ngIf="
                                                        selectedDocument.type ===
                                                        documentTypesEnums.IMAGES
                                                    "
                                                    [src]="data.previewData"
                                                    class="preview-image"
                                                />

                                                <video
                                                    *ngIf="
                                                        selectedDocument.type ===
                                                        documentTypesEnums.VIDEOS
                                                    "
                                                    class="preview-image"
                                                    [src]="data.previewData"
                                                    width="240"
                                                    height="200"
                                                    controls
                                                >
                                                    Your browser does not
                                                    support the video tag.
                                                </video>

                                                <div
                                                    class="file-name info"
                                                    *ngIf="data.data"
                                                >
                                                    <p>{{ data.data.name }}</p>
                                                </div>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="!data.selectionStatus"
                                            >
                                                <span
                                                    appIcon
                                                    [data]="fileUploadIcon"
                                                    class="upload-icon"
                                                >
                                                </span>

                                                <p class="info">
                                                    Upload
                                                    {{
                                                        selectedDocument.type ===
                                                        documentTypesEnums.IMAGES
                                                            ? "Image"
                                                            : "Video"
                                                    }}
                                                </p>
                                            </ng-container>
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="buttons">
            <app-multi-button-generator [buttonGenInputs]="buttonInputs">
            </app-multi-button-generator>
        </div>
    </div>
</div>
