import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Logger } from 'src/app/shared/classes/Logger';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IOptimizerChecksApiResponse } from 'src/app/shared/interfaces/api/portlets/IOptimizerChecksApiResponse';
import { HitApi } from './../../../../classes/HitApi';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { IConfirmationModal } from './../../../../interfaces/modal/IConfirmationModal';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-optimizer-checks',
    templateUrl: './optimizer-checks.component.html',
    styleUrls: ['./optimizer-checks.component.sass']
})
export class OptimizerChecksComponent implements OnInit, AfterViewInit {
    // CHECKS: 1.0
    ViewType = ViewType;
    widgetRef: Widget;
    agGrid: GridOptions;
    rowData;
    agGridIcons = Helper.getAgGridIcons();
    showSearchBox: boolean = true;

    severityCount = {
        red: 0,
        yellow: 0,
        green: 0
    };

    defaultColDef = {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true
    };
    accountRegionCost = null;
    fixActions: IAction[] = [];
    actionInputJson = {};
    tableInput: ITableGeneratorInput;
    severityButtons: IButtonGeneratorInput[];

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        //checking Lite State
        if (this.widgetRef.lightState) {
            this.showSearchBox = false;
            this.setUpRowClassRules();
        }
    }

    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(this.widgetRef);
    }

    bindData(data: IOptimizerChecksApiResponse): void {
        if (!data) {
            return;
        }
        Logger.info(`data: ${data}`);
        this.accountRegionCost = null;
        this.widgetRef.apiResponse = data;
        this.rowData = [];

        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataList && !data.dataList.length)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.attentionRequired(data);
            this.widgetRef.endLoader();
            return;
        }

        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        if (data.dataMap && data.dataMap['accountRegionCost']) {
            this.accountRegionCost = data.dataMap['accountRegionCost'];
        }

        this.rowData = data.dataList;

        const columns: IColumnData[] = [];
        const existingColumns: string[] = [];
        this.rowData.forEach((data) => {
            const keys = Object.keys(data);
            const isKeyPresent =
                new Set([...keys, ...columns]).size === columns.length;
            if (!isKeyPresent) {
                keys.forEach((key) => {
                    if (!existingColumns.includes(key)) {
                        existingColumns.push(key);
                        if (key.toLowerCase() === 'severity') {
                            columns.push({
                                columnKey: key,
                                columnName: key,
                                cellRenderer: (data: ICellRendererParams) => {
                                    if (
                                        data.value.toLowerCase() === 'critical'
                                    ) {
                                        return `<img src='assets/red_check.png' alt='red' class='severity-icon' style='max-width: 16px !important' >`;
                                    } else if (
                                        data.value.toLowerCase() === 'warning'
                                    ) {
                                        return `<img src='assets/yellow_check.png' alt='yellow' class='severity-icon' style='max-width: 16px !important' >`;
                                    } else {
                                        return `<img src='assets/green_check.png' alt='green' class='severity-icon' style='max-width: 16px !important' >`;
                                    }
                                },
                                pinned: 'left',
                                lockPinned: true
                            });
                        } else {
                            columns.push({
                                columnKey: key,
                                columnName: key
                            });
                        }
                    }
                });
            }
        });

        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableHeight: 300
        };

        this.severityCountBinder(data);
        this.widgetRef.attentionRequired(data);
        this.initButtonGenInput();
        this.widgetRef.endLoader();
    }

    severityCountBinder(response: IOptimizerChecksApiResponse) {
        this.severityCount.red =
            'critical' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['critical']
                : 0;
        this.severityCount.yellow =
            'warning' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['warning']
                : 0;
        this.severityCount.green =
            'ok' in response.dataMap['severityCount']
                ? response.dataMap['severityCount']['ok']
                : null;
        const icon: IIcon = {
            class: `assets/${this.maxSeverityCount()}_check.png`,
            type: IconType.IMAGE
        };
        this.widgetRef.headerIcon.next(icon);
    }

    maxSeverityCount() {
        if (this.severityCount.red) {
            return 'red';
        }
        if (this.severityCount.yellow) {
            return 'yellow';
        } else return 'green';
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;

        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${this.severityCount.green}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'ok',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('OK');
                }
            }
        };
        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${this.severityCount.yellow}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Warning');
                }
            }
        };
        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${this.severityCount.red}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('Critical');
                }
            }
        };

        this.severityButtons = [];
        if (this.severityCount.green) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }
        if (this.severityCount.yellow) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (this.severityCount.red) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
    }

    severityFilter(severityType?) {
        let filteredSearch = this.rowData;

        if (severityType) {
            filteredSearch = filteredSearch.filter((item) => {
                if (
                    item['Severity'].toLowerCase() ===
                    severityType.toLowerCase()
                ) {
                    return item;
                }
            });
        }

        this.agGrid.api.setRowData(filteredSearch);
        this.agGrid.api.redrawRows();
    }

    handleFixAction(action) {
        if (!this.agGrid || this.agGrid.api.getSelectedNodes().length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource.',
                true
            );
            return;
        }
        if (this.agGrid.api.getSelectedNodes().length > 5) {
            this.widgetRef.notificationsService.showSnackBar(
                'You Can Select Maximum 5 Resources.',
                true
            );
            return;
        }

        const outputKey = this.widgetRef.widgetData.automation[action][
            'selector'
        ]
            ? this.widgetRef.widgetData.automation[action]['selector']
            : 'manageServices';

        this.actionInputJson[outputKey] = {};
        this.agGrid.api.getSelectedNodes().forEach((item) => {
            let prepareKey = '';
            this.widgetRef.widgetData.automation[action][
                'prepareKeysArray'
            ].forEach((key, index) => {
                if (!index) {
                    prepareKey = item.data[key];
                } else {
                    prepareKey = prepareKey + '|' + item.data[key];
                }
            });

            if (!(prepareKey in this.actionInputJson[outputKey])) {
                this.actionInputJson[outputKey][prepareKey] = [
                    item.data[
                        this.widgetRef.widgetData.automation[action]
                            .columnsToChoose[
                            Object.keys(
                                this.widgetRef.widgetData.automation[action]
                                    .columnsToChoose
                            )[0]
                        ]
                    ]
                ];
            } else {
                this.actionInputJson[outputKey][prepareKey].push(
                    item.data[
                        this.widgetRef.widgetData.automation[action]
                            .columnsToChoose[
                            Object.keys(
                                this.widgetRef.widgetData.automation[action]
                                    .columnsToChoose
                            )[0]
                        ]
                    ]
                );
            }
        });

        const modalArgs: IConfirmationModal = {
            modalName: this.widgetRef.widgetData.widgetInfo.text,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: this.widgetRef.widgetData.automation[action]['icon']
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: this.widgetRef.widgetData.automation[action]['icon']
            },
            confirmationMessage: this.widgetRef.widgetData.automation[action][
                'modalMessage'
            ]
                ? this.widgetRef.widgetData.automation[action]['modalMessage']
                : `Are you sure you want to ${action} ${this.widgetRef.widgetData.widgetInfo.text} `,
            buttonText: this.widgetRef.widgetData.automation[action]['label']
                ? this.widgetRef.widgetData.automation[action]['label']
                : 'Confirm',
            buttonColorType: ButtonColorType.SECONDARY,
            function: () => {
                const args: IHitApi = {
                    url: `${this.widgetRef.widgetData.automation['host']}${this.widgetRef.widgetData.automation[action]['apiPath']}`,
                    intactUrl: `${this.widgetRef.widgetData.automation['host']}{apiPath}`,
                    requestType: RequestType.POST,
                    input: this.actionInputJson,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.AUTHORIZATION_TOKEN,
                        ignoreBaseUrl: true
                    },
                    function: (response) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            `Action performed successfully`
                        );
                        this.widgetRef.refreshWidget();
                    },
                    errorFunction: (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            error.error.message,
                            true
                        );
                    }
                };
                new HitApi(
                    args,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
            loaderOnExec: true
        };
        this.widgetRef.modalService.openConfirmationModal(modalArgs);
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
