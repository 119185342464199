import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IconName } from 'src/app/shared/classes/Icon';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import {
    IDescription,
    IDetailedDescription
} from 'src/app/shared/interfaces/widget/IWidgetDescription';
import { CreateWidgetDocumentationStepOneModalComponent } from '../widget-documentation-modal/create-widget-documentation-step-one-modal/create-widget-documentation-step-one-modal.component';
import { CreateWidgetDocumentationStepTwoModalComponent } from '../widget-documentation-modal/create-widget-documentation-step-two-modal/create-widget-documentation-step-two-modal.component';
import { DocumentTypes } from './../../../interfaces/widget/IWidgetDescription';

@Component({
    selector: 'app-widget-catalog-more-info-modal',
    templateUrl: './widget-catalog-more-info-modal.component.html',
    styleUrls: ['./widget-catalog-more-info-modal.component.sass']
})
export class WidgetCatalogMoreInfoModalComponent implements OnInit {
    widgetRef: Widget;
    widgetMetaData = null;
    infoData = {};
    tagsData = [];
    tagsAssigned = [];
    tagsToBeDeleted = [];
    allWidgetsData = [];
    updatedTagsData = [];
    objectKeys = Object.keys;
    widgetDescription: IDescription = null;
    descriptionLoader = false;
    iconName = IconName;
    detailedDescriptionLoader = false;
    documentTypes = DocumentTypes;
    isUpdateDocumentation: boolean;
    topHeaderButtons: IButtonGeneratorInput[];
    constructor(private modalInjectedData: ModalInjectedData) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.widgetMetaData = modalInjectedData.data['widgetData'];
        this.allWidgetsData = modalInjectedData.data['allWidgetsData'];
        this.allWidgetsData.forEach((widgetData) => {
            this.tagsData.push(...widgetData['moreInfo']['tagColorModel']);
        });
        this.tagsData = Helper.removeDuplicateObjetsFromArray(this.tagsData);
    }

    ngOnInit(): void {
        this.prepareInfoData();
        this.fetchWidgetDocumentation();
        this.generateButtons();
    }
    generateButtons() {
        this.topHeaderButtons = [
            {
                buttonName: 'Add Documentation',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                hide: !(!this.isUpdateDocumentation && !this.descriptionLoader),
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.openAddWidgetDocumentation(false, buttonRef);
                }
            },
            {
                buttonName: 'Update Documentation',
                buttonColorType: ButtonColorType.SUCCESS,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.openAddWidgetDocumentation(true, buttonRef);
                },
                showLoader: true,
                hide: !(this.isUpdateDocumentation && !this.descriptionLoader),
                buttonType: ButtonType.TEXT
            }
        ];
    }

    fetchWidgetDocumentation() {
        this.widgetDescription = null;
        this.descriptionLoader = true;
        const hitApi: IHitApi = {
            url: ApiUrls.WIDGET_DOCUMENTATION_PUBLISHED_DESCRIPTION.replace(
                '{widgetId}',
                this.widgetMetaData['Widget Id']
            ),
            intactUrl: ApiUrls.WIDGET_DOCUMENTATION_PUBLISHED_DESCRIPTION,
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.descriptionLoader = false;
                this.widgetDescription = response;
                this.isUpdateDocumentation = false;
                if (response && response['presignedUrl']) {
                    this.isUpdateDocumentation = true;
                }
                this.generateButtons();
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error getting data.'
                );
                this.descriptionLoader = false;
            },
            input: {}
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    prepareInfoData() {
        const tempInfoData = {};
        tempInfoData['Widget Name'] = this.widgetMetaData['Widget Name'];
        tempInfoData['Widget Creation Date'] =
            this.widgetMetaData['moreInfo']['widgetCreationDate'];
        tempInfoData['Widget Status'] = this.widgetMetaData['Status'];
        tempInfoData['Widget Tags'] = 'Widget Tags';
        tempInfoData['ACL'] = this.widgetMetaData['ACL Name']
            ? this.widgetMetaData['ACL Name']
            : null;
        tempInfoData['Added to ACL Date'] = this.widgetMetaData['moreInfo'][
            'addedToAclDate'
        ]
            ? this.widgetMetaData['moreInfo']['addedToAclDate']
            : null;
        tempInfoData['View Name'] = this.widgetMetaData['View Name']
            ? this.widgetMetaData['View Name']
            : 'N/A';
        tempInfoData['Added to View Date'] = this.widgetMetaData['moreInfo'][
            'addedToViewDate'
        ]
            ? this.widgetMetaData['moreInfo']['addedToViewDate']
            : 'N/A';
        tempInfoData['Layer 1 Name'] = this.widgetMetaData['Layer1']
            ? this.widgetMetaData['Layer1']
            : 'N/A';
        tempInfoData['Layer 2 Name'] = this.widgetMetaData['Layer2']
            ? this.widgetMetaData['Layer2']
            : 'N/A';
        tempInfoData['Layer 3 Name'] = this.widgetMetaData['Layer3']
            ? this.widgetMetaData['Layer3']
            : 'N/A';
        tempInfoData['Layer 4 Name'] = this.widgetMetaData['Layer4']
            ? this.widgetMetaData['Layer4']
            : 'N/A';

        this.infoData = Helper.cloneDeep(tempInfoData);
    }

    save($event) {
        const finalTagsData = [];

        if (!this.tagsAssigned.length && !this.tagsToBeDeleted.length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Apply atleast 1 tag',
                true
            );
        } else {
            let tags = [
                ...this.widgetMetaData['moreInfo']['tagColorModel'],
                ...this.tagsAssigned
            ];
            tags = Helper.removeDuplicateObjetsFromArray(tags);
            if (this.tagsToBeDeleted.length) {
                this.tagsToBeDeleted.forEach((tagToBeDeleted) => {
                    const tagsIndex = tags.findIndex(
                        (tag) => tag.name === tagToBeDeleted
                    );

                    if (tagsIndex > -1) {
                        tags = Helper.removeIndexfromArray(tagsIndex, tags);
                    }
                });
            }
            finalTagsData.push({
                widgetId: this.widgetMetaData['Widget Id'],
                tags
            });

            const apiArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.create
            );
            apiArgs.input = finalTagsData;
            apiArgs.function = (response) => {
                this.widgetMetaData['moreInfo']['tagColorModel'] = tags;
                this.widgetRef.notificationsService.showSnackBar(
                    'Tags updated successfully.'
                );
                this.widgetRef.refreshWidget();
                if ($event['callback']) {
                    $event['callback']('SUCCESS');
                }
            };
            apiArgs.errorFunction = (error) => {
                if ($event['callback']) {
                    $event['callback']('ERROR');
                }
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }

    updateTags($event) {
        this.tagsAssigned = $event['tagsData'];
        if ($event['tagToBeDeleted']) {
            this.tagsToBeDeleted.push($event['tagToBeDeleted']);
        }
        if ($event['deleteTags']) {
            this.save($event);
        }
    }

    openAddWidgetDocumentation(
        isEdit = false,
        buttonRef: IButtonGeneratorInput
    ) {
        const openModalFunction = (
            documentation: IDetailedDescription[] = []
        ) => {
            const modalData: IModalData = {
                modalName: 'Add Widget Documentation',
                modalIcon: null,
                modalType: ModalType.MIDDLE,
                sourceId: this.widgetRef.uniqueIdentity,
                modalAutoWidth: true,
                modalAutoHeight: true,
                hideSteps: true,
                disableClose: true,
                closeCallback: () => {
                    this.fetchWidgetDocumentation();
                },
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad:
                                CreateWidgetDocumentationStepOneModalComponent,
                            payload: {
                                data: {
                                    widgetRef: this.widgetRef,
                                    isEdit,
                                    widgetId: this.widgetMetaData['Widget Id'],
                                    isWidgetCatalog: true
                                }
                            }
                        },
                        stepName: 'Create',
                        headingText: 'Add Widget Documentation'
                    },
                    {
                        stepData: {
                            componentToLoad:
                                CreateWidgetDocumentationStepTwoModalComponent,
                            payload: {
                                data: {
                                    templateData: documentation,
                                    widgetRef: this.widgetRef,
                                    isEdit,
                                    widgetId: this.widgetMetaData['Widget Id'],
                                    hideBackButton: true
                                }
                            }
                        },
                        stepName: 'Add/Update Widget Documentation',
                        headingText: 'Add/Update Widget Documentation',
                        resetModal: true,
                        stepHeightVh: 80
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        };

        buttonRef.loader = true;
        this.widgetRef.hitWidgetMoreInfoApi(
            this.widgetMetaData['Widget Id'],
            true,
            (response) => {
                if (response && response.length) {
                    openModalFunction(response);
                } else {
                    openModalFunction();
                }
                buttonRef.loader = false;
            },
            true,
            true
        );
    }
}
