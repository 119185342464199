<div class="container">
    <div class="form-container">
        <app-form-generator
            *ngIf="formgenInput"
            [formGeneratorInput]="formgenInput"
            (formGroupRef)="serviceTestFormGroup = $event"
        ></app-form-generator>
    </div>
    <div class="button tw-float-right">
        <app-button-generator
            [buttonGenInput]="buttonInput"
        ></app-button-generator>
    </div>
</div>
