import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Column, ColumnApi, IHeaderParams } from 'ag-grid-community';
import { IconType } from '../../enums/IconType';
import { IIcon } from './../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-ag-grid-collapsible-column-header',
    templateUrl: './ag-grid-collapsible-column-header.component.html',
    styleUrls: ['./ag-grid-collapsible-column-header.component.sass']
})
export class AgGridCollapsibleColumnHeaderComponent
    implements IHeaderAngularComp
{
    sortStatus: string = null;

    params: any;
    isExpanded: boolean;
    showCollapseIcon: boolean;
    sortRequested: boolean = false;
    collapsibleColumns = [];
    sortIcon: IIcon = {
        type: IconType.SVG,
        class: 'sort'
    };

    expandIcon: IIcon = {
        type: IconType.SVG,
        class: 'column_expand'
    };

    collapseIcon: IIcon = {
        type: IconType.SVG,
        class: 'column_collapse'
    };

    filterMenu: IIcon = {
        type: IconType.SVG,
        class: 'filter_menu'
    };

    columnIndex: number = -1;

    @ViewChild('menuButton', { read: ElementRef }) public menuButton;

    constructor() {}

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.showCollapseIcon = this.params.collapseIconShow
            ? this.params.collapseIconShow
            : false;
        this.collapsibleColumns = this.params.collapsibleColumns
            ? this.params.collapsibleColumns
            : [];
        this.calculateIndex(params.column, params.columnApi);
    }

    onMenuClicked(event) {
        this.params.showColumnMenu(this.menuButton.nativeElement);
    }

    expandColumn() {
        this.isExpanded = !this.isExpanded;

        this.params.columnApi.setColumnsVisible(
            this.collapsibleColumns,
            this.isExpanded
        );

        if (
            this.params.column.columnApi.columnController.primaryColumns
                .length <= 7
        ) {
            this.params.api.sizeColumnsToFit();
        }
    }

    onSortRequested(event: any) {
        this.sortRequested = true;
        if (this.sortStatus === 'desc') {
            this.sortStatus = null;
            this.sortRequested = false;
        } else if (this.sortStatus === 'asc') {
            this.sortStatus = 'desc';
        } else {
            this.sortStatus = 'asc';
        }
        this.params.setSort(this.sortStatus, event.shiftKey);
    }
    calculateIndex(currentColumn: Column, columnApi: ColumnApi) {
        this.columnIndex = columnApi
            .getAllColumns()
            .findIndex((col) => col.getColId() === currentColumn.getColId());
    }
}
