import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalInjectedData } from '../../../../classes/ModalInjectedData';
import { IUpdateAction } from '../../../../interfaces/update-action/IUpdateAction';
import { ModalService } from '../../../../services/modal/modal-service/modal.service';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-azure-sponsorship-onboarding-modal',
    templateUrl: './azure-sponsorship-onboarding-modal.component.html',
    styleUrls: ['./azure-sponsorship-onboarding-modal.component.sass']
})
export class AzureSponsorshipOnboardingModalComponent implements OnInit {
    formGenFirstInput: IFormGeneratorInput = null;
    formGroupFirstRef: FormGroup;
    formGenSecondInput: IFormGeneratorInput = null;
    formGroupSecondRef: FormGroup;
    resetFirstForm = null;
    resetSecondForm = null;
    resetSingleFormInput = null;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    showLoader: boolean;
    singleFormUpdateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    singleFormUpdateControlInput: IUpdateAction;
    edit: boolean;
    widgetRef: Widget;
    stepData = null;
    loader: boolean;
    isNotTextFieldOpen: boolean = true;
    Tab = Tab;
    countOfTabsChange = 0;

    oldSubscriptionId = null;
    @ViewChild('tabGroup') tabs: MatTabGroup;
    hadleTabsChange;
    testTab: boolean;
    buttonGenInput: IButtonGeneratorInput[];
    buttonOption: IMultiButtonOption = {
        extraClass: 'onboard-buttons',
        layout: {
            justifyContent: 'space-between'
        }
    };
    selectedIndexForDetailsTab: number = 0;

    DetailsTab = DetailsTab;
    singleFormFieldInput: IFormGeneratorInput = null;
    singleFormGroup: FormGroup;
    detailsTabActiveIndex: number = 0;
    detailsEnteredManually: string;
    invalidCredentials: boolean;
    subscriptionId: string;
    isPayAsYouGo: boolean;
    isSponsorship: boolean;
    clientIdValueChange: boolean = false;
    apiFinished: boolean = false;
    submitButtonPressed: IButtonGeneratorInput = null;
    infoIcon: IIcon = {
        class: 'exclamation_icon',
        type: IconType.SVG,
        extraClass: 'svg-warning-fill'
    };
    constructor(
        private cd: ChangeDetectorRef,

        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];

        this.stepData = modalInjectedData.data['stepData'];

        this.edit = modalInjectedData.data['edit'];
        this.isPayAsYouGo = modalInjectedData.data['isPayAsYouGO'];
        this.isSponsorship = modalInjectedData.data['isSponsorship'];
    }

    ngOnInit(): void {
        if (this.edit) {
            this.detailsEnteredManually =
                this.stepData['detailsEnteredManually'];
            if (this.detailsEnteredManually) {
                this.detailsTabActiveIndex = 1;
                this.selectedIndexForDetailsTab = 1;
            }
        }

        this.prepareOnBoardingForm();

        this.modalService.resetModal.subscribe((reset) => {
            let formData;
            formData = {
                appId: this.stepData
                    ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                          'appId'
                      ]
                    : null,
                clientSecretKey: null,
                subscriptionId: this.stepData
                    ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                          'subscriptionId'
                      ]
                    : null,
                accountSpoc: this.stepData ? this.stepData['spoc'] : null,
                accountName: this.stepData
                    ? this.stepData['accountName']
                    : null,
                domain: this.stepData
                    ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                          'domain'
                      ]
                    : null
            };
            if (!this.stepData) {
                formData['subscriptionId'] = null;
            }
            this.resetFirstForm(formData);

            this.hitSubscriptionIdApiOnReset();

            if (this.singleFormGroup) {
                if (!this.detailsEnteredManually && this.edit) {
                    formData = {
                        exportId:
                            this.stepData[
                                'azureSponsorshipPayAsYouGoAttributes'
                            ]['exportId']
                    };
                } else {
                    formData = {
                        exportId: null
                    };
                }
                this.resetSingleFormInput(formData);
            }

            if (this.formGroupSecondRef) {
                formData = {
                    exportName: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'exportName'
                          ]
                        : null,
                    storageAccountName: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'storageAccountName'
                          ]
                        : null,
                    containerName: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'containerName'
                          ]
                        : null,
                    storageDirectory: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'storageDirectory'
                          ]
                        : null
                };
                this.resetSecondForm(formData);
            }
        });
        this.prepareButtons();
    }
    prepareButtons() {
        this.buttonGenInput = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                link: this.isPayAsYouGo
                    ? 'assets/onboarding-pdf/azure-pay-as-you-go.pdf'
                    : 'assets/onboarding-pdf/azure-sponsorship-onboarding.pdf',
                function: null
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                }
            }
        ];
    }

    changeDetailsTab($event) {
        this.detailsTabActiveIndex = $event.index;

        this.resetSecondForm({});

        this.resetSingleFormInput({});
    }

    prepareOnBoardingForm() {
        this.formGenFirstInput = {
            formName: 'On Board Azure Sponsorship Account',
            state: FormState.CREATE,
            submitButton: null,

            fields: [
                {
                    label: 'Application (Client) ID',
                    name: 'appId',
                    placeholder: 'Enter Application ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application ID is required'
                        }
                    ],
                    appearance: 'legacy',
                    onChange: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    },
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'appId'
                          ]
                        : ''
                },

                {
                    label: ' Client Secret Key',
                    name: 'clientSecretKey',
                    placeholder: 'Enter Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Secret key is required'
                        }
                    ],
                    onChange: () => {
                        this.clientIdValueChange = true;
                        if (this.edit) {
                            this.apiFinished = false;
                        }
                    },
                    onFocusout: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    },
                    value: ''
                },
                {
                    label: 'Domain ID',
                    name: 'domain',
                    placeholder: 'Enter Domain',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'domain'
                          ]
                        : '',
                    onChange: ($event, formGroup: FormGroup) => {
                        this.checkFormFields(formGroup);
                    },
                    appearance: 'legacy'
                },
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Enter Account Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,

                    appearance: 'legacy',
                    value: this.stepData ? this.stepData['accountName'] : null
                },
                {
                    label: 'Account SPOC',
                    name: 'accountSpoc',
                    placeholder: 'Enter Account SPOC',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.stepData ? this.stepData['spoc'] : null
                },
                {
                    label: 'Subscription ID',
                    name: 'subscriptionId',
                    placeholder: 'Select Subscription ID',
                    listDataExists: true,
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showLabel: true,
                    showKey: 'label',
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'subscriptionId'
                          ]
                        : null,
                    listData: [],
                    afterResponse: () => {
                        this.apiFinished = true;
                        this.invalidCredentials = false;

                        if (
                            this.formGroupFirstRef.value['appId'] &&
                            this.formGroupFirstRef.value['clientSecretKey'] &&
                            this.formGroupFirstRef.value['domain'] &&
                            !this.formGenFirstInput.fields[5].listData
                        ) {
                            this.invalidCredentials = true;

                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                'Invalid Credentials'
                            );
                        } else {
                            this.getExportNameData();
                        }
                        if (this.submitButtonPressed) {
                            this.save(this.submitButtonPressed);
                        }
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Subscription ID is required'
                        }
                    ],

                    apiInput: this.edit
                        ? this.prepareSubscriptionIdPayload(
                              this.formGroupFirstRef,
                              true
                          )
                        : null,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo[
                            'additionalApisForWidget'
                        ]['getSubscription'],

                    appearance: 'legacy',
                    autoSelectDropDownData: true
                }
            ]
        };

        this.singleFormFieldInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Export Name',
                    name: 'exportId',
                    placeholder: 'Select Export Name',
                    showKey: 'label',
                    appearance: 'legacy',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    showLabel: true,
                    listDataExists: true,
                    required: false,
                    apiInfo:
                        this.widgetRef.widgetData.widgetInfo[
                            'additionalApisForWidget'
                        ]['getExport'],
                    apiInput: this.edit
                        ? this.prepareSubscriptionIdPayload(
                              this.formGroupFirstRef,
                              true,
                              true
                          )
                        : null,
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'exportId'
                          ]
                        : '',
                    autoSelectDropDownData: false,
                    listData:
                        this.stepData &&
                        this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                            'exportId'
                        ]
                            ? [
                                  {
                                      id:
                                          this.stepData &&
                                          this.stepData[
                                              'azureSponsorshipPayAsYouGoAttributes'
                                          ]['exportId'],
                                      label:
                                          this.stepData &&
                                          this.stepData[
                                              'azureSponsorshipPayAsYouGoAttributes'
                                          ]['exportName']
                                  }
                              ]
                            : []
                }
            ]
        };

        this.formGenSecondInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Export Name',
                    name: 'exportName',
                    placeholder: 'Enter Export Name',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: false,
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'exportName'
                          ]
                        : '',
                    onChange: (data, formGroup: FormGroup) => {
                        this.checkIfExportNameExists(
                            formGroup.value['exportName']
                        );
                    }
                },
                {
                    label: 'Storage Account Name',
                    name: 'storageAccountName',
                    placeholder: 'Enter Storage Account Name',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: false,
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'storageAccountName'
                          ]
                        : null
                },
                {
                    label: 'Container Name',
                    name: 'containerName',
                    placeholder: 'Enter Container Name',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    required: false,
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'containerName'
                          ]
                        : null
                },
                {
                    required: false,
                    label: 'Storage Directory',
                    name: 'storageDirectory',
                    placeholder: 'Enter Storage Directory Name',
                    fieldType: FilterType.TEXT,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.stepData
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'storageDirectory'
                          ]
                        : null
                }
            ]
        };

        this.cd.detectChanges();
    }
    checkIfExportNameExists(value) {
        const response = this.singleFormFieldInput.fields[0].listData;

        if (response && response.length && value) {
            const nameExist = response.find((data, index) => {
                return data.id === value || data.label === value;
            });

            return nameExist;
        }
        return null;
    }
    hitExportNameApi($event) {
        if ($event['subscriptionId'] !== this.oldSubscriptionId) {
            this.oldSubscriptionId = $event['subscriptionId'];
            this.getExportNameData();
        }
    }
    hitSubscriptionIdApiOnReset() {
        if (!this.stepData) {
            const formFieldObject = this.formGenFirstInput.fields.find(
                (data) => {
                    return data.name === 'subscriptionId';
                }
            );
            formFieldObject.apiInput = this.prepareSubscriptionIdPayload(
                null,
                this.stepData ? true : false,
                false
            );
            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['subscriptionId']
            };

            if (this.formGroupFirstRef) {
                this.updateControl.next(this.updateControlInput);
                this.cd.detectChanges();
            }
        } else {
            this.formGenFirstInput.fields[5].listData = [
                {
                    id: this.subscriptionId,
                    label: this.stepData[
                        'azureSponsorshipPayAsYouGoAttributes'
                    ]['subscriptionLabel']
                }
            ];
        }
    }

    checkFormFields(formGroup) {
        const formFieldObject = this.formGenFirstInput.fields.find((data) => {
            return data.name === 'subscriptionId';
        });
        if (!this.stepData) {
            formFieldObject.value = null;

            this.formGroupFirstRef.get('subscriptionId').setValue(null);
        }

        if (
            formGroup.value['appId'] &&
            formGroup.value['clientSecretKey'] &&
            formGroup.value['domain']
        ) {
            formFieldObject.apiInput = {
                azureSponsorshipPayAsYouGoAttributes: {
                    domain: formGroup.value['domain'],
                    appId: formGroup.value['appId'],
                    clientSecretKey: formGroup.value['clientSecretKey']
                }
            };

            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['subscriptionId']
            };

            if (this.formGroupFirstRef) {
                this.updateControl.next(this.updateControlInput);
                this.cd.detectChanges();
            }
        }
    }
    getExportNameData() {
        if (this.singleFormGroup) {
            this.singleFormUpdateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['exportId']
            };
            if (!this.stepData) {
                this.singleFormGroup.get('exportId').setValue(null);
                this.singleFormFieldInput.fields[0].value = null;
            }
            const payloadData = this.prepareSubscriptionIdPayload(
                this.formGroupFirstRef
            );
            payloadData['azureSponsorshipPayAsYouGoAttributes'][
                'subscriptionId'
            ] = this.stepData
                ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                      'subscriptionId'
                  ]
                : this.formGroupFirstRef.value['subscriptionId'];

            this.singleFormFieldInput.fields[0].apiInput = payloadData;

            if (this.singleFormGroup) {
                this.singleFormUpdateControl.next(
                    this.singleFormUpdateControlInput
                );
                this.cd.detectChanges();
            }
        }
    }

    prepareSubscriptionIdPayload(
        formGroup?: FormGroup,
        editState?: boolean,
        exportNameDataRequired?: boolean
    ) {
        const data = {
            azureSponsorshipPayAsYouGoAttributes: {
                domain:
                    formGroup && formGroup.value['domain']
                        ? formGroup.value['domain']
                        : editState
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'domain'
                          ]
                        : '',
                appId:
                    formGroup && formGroup.value['appId']
                        ? formGroup.value['appId']
                        : editState
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'appId'
                          ]
                        : '',
                clientSecretKey:
                    formGroup && formGroup.value['clientSecretKey']
                        ? formGroup.value['clientSecretKey']
                        : editState
                        ? this.stepData['azureSponsorshipPayAsYouGoAttributes'][
                              'clientSecretKey'
                          ]
                        : ''
            }
        };
        if (exportNameDataRequired) {
            data['azureSponsorshipPayAsYouGoAttributes']['subscriptionId'] =
                this.subscriptionId;
        }

        return data;
    }
    save(buttonRef: IButtonGeneratorInput) {
        if (!this.apiFinished && this.clientIdValueChange) {
            this.submitButtonPressed = buttonRef;
            return;
        }
        if (this.formGroupFirstRef.invalid) {
            Helper.markAllFieldAsTouched(this.formGroupFirstRef);
            return;
        }
        if (this.invalidCredentials) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                'Invalid credentials'
            );
            return;
        }

        buttonRef.loader = true;

        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.prepareFinalPayload();

        apiArgs.function = () => {
            buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'Account Edited Successfully'
                    : 'Account Onboarded Successfully'
            );
            this.widgetRef.refreshWidget();
        };
        if (this.edit) {
            apiArgs.url = apiArgs.url.replace(
                '{id}',
                this.stepData['accountNumber']
            );
        }
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.edit
                    ? 'Error While Updating Account'
                    : 'Error While Onboarding Account'
            );
            if (
                error.error &&
                (error.error.message.includes(
                    'Billing Details will not be saved'
                ) ||
                    error.error.message.includes('required permission'))
            ) {
                this.modalService.closeModal(
                    null,
                    this.modalInjectedData.modalId
                );
            }
            this.widgetRef.refreshWidget();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    prepareFinalPayload() {
        const finalPayloadData = {
            accountName: this.formGroupFirstRef.value['accountName'],
            spoc: this.formGroupFirstRef.value['accountSpoc'],
            azureSponsorshipPayAsYouGoAttributes: {},
            type: this.isPayAsYouGo ? 'azure_pay_as_you_go' : 'sponsorship',
            detailsEnteredManually: !this.detailsTabActiveIndex ? false : true
        };
        for (const val in this.formGroupFirstRef.value) {
            if (val !== ('accountName' || 'accountSpoc')) {
                finalPayloadData['azureSponsorshipPayAsYouGoAttributes'][val] =
                    this.formGroupFirstRef.value[val];
            }
            if (this.stepData) {
                finalPayloadData['azureSponsorshipPayAsYouGoAttributes'][
                    'subscriptionId'
                ] = this.subscriptionId;
            }
        }
        if (!this.detailsTabActiveIndex) {
            finalPayloadData['azureSponsorshipPayAsYouGoAttributes'][
                'exportId'
            ] = this.singleFormGroup.value
                ? this.singleFormGroup.value['exportId']
                : null;
        } else {
            for (const billingDetails in this.formGroupSecondRef.value) {
                finalPayloadData['azureSponsorshipPayAsYouGoAttributes'][
                    billingDetails
                ] = this.formGroupSecondRef.value[billingDetails];
            }
            const data = this.checkIfExportNameExists(
                this.formGroupSecondRef.value['exportName']
            );
            finalPayloadData['azureSponsorshipPayAsYouGoAttributes'][
                'exportId'
            ] = data ? data.id : this.formGroupSecondRef.value['exportName'];
        }

        return finalPayloadData;
    }

    resetForm() {
        this.resetFirstForm({});

        if (this.formGroupSecondRef) {
            this.resetSecondForm({});
        }
    }
}
enum Tab {
    PAY_AS_YOU_GO = 'Pay As You Go Account',
    SPONSORSHIP = 'Sponsorship Account'
}
export enum DetailsTab {
    ONE_CLICK = 'Enter Detail On One-Click',
    MANUALLY = 'Enter Details Manually'
}
