import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-assessment-create-user-step-two',
    templateUrl: './assessment-create-user-step-two.component.html',
    styleUrls: ['./assessment-create-user-step-two.component.sass']
})
export class AssessmentCreateUserStepTwoComponent implements OnInit {
    emailContentFormGenInput: IFormGeneratorInput = null;
    emailContentFormRef: FormGroup;
    modalLoader: boolean = true;
    template: string;
    widgetRef: Widget;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    issueAssessmentButtonGenInput: IButtonGeneratorInput;
    previousStepData;
    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.multiStepFormService.previousStep(this.modalData.modalId);
            }
        },
        {
            buttonName: 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.createUser(buttonRef);
            }
        }
    ];
    isEdit: boolean = false;
    constructor(
        private configCacheService: ConfigCacheService,
        private modalData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit(): void {
        this.generateForm();
        this.widgetRef = this.modalData.data['widgetRef'];
        this.isEdit = this.modalData.data['edit'];
        this.previousStepData = this.multiStepFormService.stepData
        .get(this.modalData.modalId)
        .get(1);
        this.getAssesseeEmailTemplate();
    }
    generateForm() {
        this.emailContentFormGenInput = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Subject',
                    placeholder: 'Subject',
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    appearance: 'legacy',
                    showLabel: true,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Subject is required'
                        }
                    ]
                },
                {
                    label: 'Email Content',
                    placeholder: '',
                    name: 'emailBody',
                    appearance: 'legacy',
                    showLabel: true,
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    value: this.template
                }
            ]
        };
    }
    getAssesseeEmailTemplate() {
        this.modalLoader = true;
        const assessmentName = this.previousStepData.assessmentIds.map((ids) => {
            return ids.label
        }).join(', ');
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSMENT_USER_EMAIL_TEMPLATE,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.configCacheService.assessmentTemplate = data.id;
                this.template = this.configCacheService.assessmentTemplate;
                const fullName = (
                    this.previousStepData.firstName +
                    ' ' +
                    (this.previousStepData.lastName
                        ? this.previousStepData.lastName
                        : '')
                ).trim();
                const titleCase = new TitleCasePipe().transform(fullName);
                this.template = this.template.replace(/{{user}}/g, titleCase);
                this.template = Helper.replaceAll(
                    this.template,
                    '<Assessment Name>',
                    assessmentName
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '{{email}}',
                    this.previousStepData.email
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '{{password}}',
                    this.previousStepData.password
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '<Domain Id>',
                    this.previousStepData.domainId
                        ? this.previousStepData.domainId
                        : this.userDataCacheService.domainId
                );
                this.modalLoader = false;
                this.generateForm();
            },
            errorFunction: (error) => {
                this.modalLoader = false;
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    createUser(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.emailContentFormRef);
        if (this.emailContentFormRef.invalid) {
            this.emailContentFormRef.updateValueAndValidity();
            return;
        }
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.prepareInput();
        apiArgs.input = {
            ...apiArgs.input
        };
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'User Created Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        buttonRef.loader = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const stepOneData = this.previousStepData;
        const assemmentIdsInput = [];
        stepOneData.assessmentIds.forEach((assemmentIds) => {
            assemmentIdsInput.push(assemmentIds.id);
        });
        const inputs = {
            firstName: stepOneData.firstName,
            lastName: stepOneData.lastName,
            password: stepOneData.password,
            confirmPassword: stepOneData.password,
            defaultViewId: stepOneData.defaultView,
            email: stepOneData.email,
            phoneNumber: stepOneData.phoneNumber,
            assessmentIds: assemmentIdsInput,
            sendingMechanism: {
                emailRequest: {
                    emailTo: [stepOneData.email],
                    subject: this.emailContentFormRef.value.subject,
                    emailBody: this.emailContentFormRef.value.emailBody
                }
            }
        };
        return inputs;
    }
}
