import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { RequestWidgetTemplateModalComponent } from '../../../modal-templates/widget-creation-modal/request-widget-template-modal/request-widget-template-modal.component';
import { IButtonData } from './../../../../interfaces/table-generator/IButtonData';
import { FormBuilderModalComponent } from './../../../modal-templates/widget-creation-modal/form-builder-modal/form-builder-modal.component';
import { RequestWidgetIntegrationComponent } from './../../../modal-templates/widget-creation-modal/request-widget-integration/request-widget-integration.component';

@Component({
    selector: 'app-custom-widget-listing',
    templateUrl: './custom-widget-listing.component.html',
    styleUrls: ['./custom-widget-listing.component.sass']
})
export class CustomWidgetListingComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    agGrid: GridOptions;
    selectedWidgets = [];
    objectKeys = Object.keys;
    selectionDone = false;

    @ViewChild('bulkDeleteList') bulkDeleteTemplateRef: TemplateRef<any>;
    @ViewChild('deleteFailedResponseView')
    deleteFailedResponseViewRef: TemplateRef<any>;

    deleteErrorResponse = [];
    apiResponse: any;
    constructor(
        widgetData: WidgetInjectedData,
        private widgetCreationService: WidgetCreationService
    ) {
        this.widgetRef = widgetData.widgetRef;

        // Setting widget in widget creation service
        this.widgetCreationService.widgetRef = this.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.applyOperationalActions();
        this.widgetRef.refreshWidgetCallback =
            this.refreshWidgetCallback.bind(this);
    }

    refreshWidgetCallback() {
        this.tableInput.columns = this.tableInput.columns.filter(
            (each) => each.columnKey !== 'tags'
        );
    }

    setUpBasics() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableHeight: 300,
            selection: 'multiple',
            selectionLimit: GlobalConfiguration.CUSTOM_WIDGET_LIMIT,
            buttons: [
                {
                    buttonName: 'Create Widget',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    function: () => {
                        this.openCreateWidget();
                    }
                }
            ],
            afterResponse: (res: any) => {
                this.apiResponse = res;
                if (res && res.length && res[0].tags) {
                    this.tableInput.columns = [
                        ...this.tableInput.columns,
                        ...[
                            {
                                columnKey: 'tags',
                                columnName: 'Tags',
                                minWidth: 200
                            }
                        ]
                    ];
                }
            },
            columns: [
                {
                    columnKey: 'widgetName',
                    columnName: 'Widget Name',
                    flex: 1
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    flex: 1,

                    cellRenderer: (rowData: RowEvent) => {
                        return Helper.generateColumnButtons(
                            [
                                {
                                    buttonName: rowData.data['status'],
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: ''
                                    },
                                    buttonColor:
                                        rowData.data['status'] === 'Draft'
                                            ? 'blueInfoColor'
                                            : 'success',
                                    showLoader: true,
                                    function: () => {}
                                }
                            ],
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            null,
                            'text',
                            { justifyContent: 'flex-start' }
                        );
                    }
                },
                {
                    columnKey: 'createdOn',
                    columnName: 'Create Date & Time',
                    flex: 1
                },
                {
                    columnKey: 'lastModifiedOn',
                    columnName: 'Last Modified Date & Time',
                    flex: 1
                },
                {
                    columnKey: 'acl',
                    columnName: 'ACL',
                    flex: 1
                },
                {
                    columnKey: '',
                    columnName: 'Action',
                    filter: false,
                    pinned: 'right',
                    maxWidth: 200,
                    minWidth: 200,
                    headerClass: 'grid-cell-data-centred',
                    cellRenderer: (rowData: RowEvent) => {
                        const buttons: IButtonData[] = [];
                        const editButton = {
                            buttonName: 'Edit',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonColor: 'blueInfoColor',
                            showLoader: true,
                            disable: !rowData.data['modifiable'],
                            disableMessage:
                                'Cannot edit widget as it is attached to customer or user.',
                            function: (buttonRef: IButtonData) => {
                                buttonRef.showLoaderInTableButtons();
                                this.getRequestWidgetData(
                                    rowData.data['id'],
                                    true,
                                    buttonRef
                                );
                            }
                        };

                        const previewButton = {
                            buttonName: 'Preview',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonColor: 'success',
                            showLoader: true,
                            function: (buttonRef: IButtonData) => {
                                buttonRef.showLoaderInTableButtons();
                                this.getRequestWidgetData(
                                    rowData.data['id'],
                                    false,
                                    buttonRef,
                                    true
                                );
                            }
                        };

                        const deleteButton = {
                            buttonName: 'Delete',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: ''
                            },
                            buttonColor: 'warningDarkest',
                            showLoader: true,
                            function: () => {
                                this.openDeleteModal([rowData.data]);
                            }
                        };

                        buttons.push(editButton);

                        buttons.push(previewButton);

                        buttons.push(deleteButton);

                        return Helper.generateColumnButtons(
                            buttons,
                            Helper.getCssVarValue.bind(this.widgetRef),
                            this.widgetRef.modalService,
                            null,
                            'text'
                        );
                    }
                }
            ],
            noDataMessage: Messages.NO_CUSTOM_WIDGET,
            showNotificationBadgeOnSelection:
                this.widgetRef.showNotificationBadge
        };
    }

    getRequestWidgetData(
        widgetId,
        isEdit,
        buttonRef: IButtonData,
        isPreview = false
    ) {
        const apiInfo: IApiInfo = {
            apiRouteSuffix: `${ApiUrls.WIDGET_INFO_API_PATH}${widgetId}`,
            host: '',
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };

        const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);

        apiArgs.input = {};
        apiArgs.function = (response) => {
            buttonRef.hideLoaderInTableButtons();

            response['widgetName'] = response.text ? response.text : '';

            if (response && response['description']) {
                const apiArgs: IHitApi = {
                    uniqueIdentity: Symbol(),
                    url: `${ApiUrls.DESCRIPTION_INFO_API_PATH}${response['description']}`,
                    intactUrl: `${ApiUrls.DESCRIPTION_INFO_API_PATH}{description}`,
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    function: (descriptionResponse) => {
                        response['description'] = descriptionResponse;

                        this.openCreateWidget(
                            response,
                            isEdit,
                            isPreview,
                            widgetId
                        );
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error while getting description'
                        );
                    },
                    requestType: RequestType.GET,
                    input: {}
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            } else {
                this.openCreateWidget(response, isEdit, isPreview, widgetId);
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while getting data'
            );
            buttonRef.hideLoaderInTableButtons();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    applyOperationalActions() {
        this.widgetRef.operationalActions.next(
            new Map().set('Bulk Action', [
                {
                    icon: {
                        type: IconType.SVG,
                        class: 'trash',
                        text: 'Bulk Delete',
                        extraClass: 'inline-fix-box-1'
                    },
                    function: () => {
                        this.openDeleteModal(
                            this.agGrid.api
                                .getSelectedNodes()
                                .map((each) => each.data)
                        );
                    },
                    message: null,
                    state: ActionState.ENABLED,
                    visibility: ActionVisibility.VISIBLE
                }
            ])
        );
    }

    openDeleteModal(data) {
        this.selectedWidgets = data;

        if (this.selectedWidgets.length === 0) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one widget'
            );
            return;
        }

        const confirmationModalData: IConfirmationModal = {
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );

                const deleteIds = data.map((each) => each.id);

                apiArgs.input = deleteIds;

                (apiArgs.function = (response) => {
                    this.widgetRef.modalService.closeModal(null, modalId);

                    if (response && response.error) {
                        this.deleteErrorResponse = response.attachedToWidget;
                        this.openDeleteFailedModal(
                            this.objectKeys(response.attachedToWidget[0])[0]
                        );
                        return;
                    }

                    this.widgetRef.notificationsService.showSnackBar(
                        'Deleted successfully'
                    );

                    this.widgetRef.refreshWidget();
                }),
                    (apiArgs.errorFunction = (error) => {
                        this.widgetRef.modalService.closeModal(null, modalId);
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error Deleting.'
                        );
                    }),
                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
            },
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: null,
            confirmationMessage: `Are you sure you want to delete this widget ?`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            bodyTemplate: this.bulkDeleteTemplateRef,
            fontSize: 1.12,
            hideCancelButton: true
        };

        const modalData: IModalData = {
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 40,
            modalWidthVw: 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openDeleteFailedModal(widgetName) {
        const confirmationModalData: IConfirmationModal = {
            function: null,
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `${widgetName} cannot be Deleted because it is attached to following customers and users.`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true,
            bodyTemplate: this.deleteFailedResponseViewRef,
            fontSize: 1.125,
            hideButtons: true
        };

        const modalData: IModalData = {
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 55,
            modalWidthVw: 55,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Delete'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openCreateWidget(
        templateData?,
        isEdit = false,
        isPreview = false,
        widgetId = null
    ) {
        if (!templateData) {
            templateData = {
                widgetName: '',
                description: ''
            };
            isEdit = true;
        }

        const modalData: IModalData = {
            modalName:
                templateData && templateData.widgetName
                    ? templateData.widgetName
                    : 'Request Widget Template',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 85,
            modalHeightVh: 85,
            hideSteps: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RequestWidgetTemplateModalComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isPreview,
                                widgetId,
                                alreadyExistWidgetNames: this.apiResponse.map(
                                    (each) => each.widgetName
                                ),
                                hideRequestButton: false,
                                hideDescriptionHeader: isPreview
                            }
                        }
                    },
                    stepName: 'Create'
                },
                {
                    stepData: {
                        componentToLoad: FormBuilderModalComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isPreview,
                                widgetId
                            }
                        }
                    },
                    stepName: 'Configure Form'
                },
                {
                    stepData: {
                        componentToLoad: RequestWidgetIntegrationComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit,
                                isPreview,
                                widgetId
                            }
                        }
                    },
                    stepName: 'Request Medium Configuration'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    isRowSelectable(rowNode: RowNode) {
        return rowNode.data['modifiable'];
    }
}
