import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconName } from 'src/app/shared/classes/Icon';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { HitApi } from '../../classes/HitApi';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { RequestType } from '../../enums/RequestType';
import {
    DescriptionPresignedUrlKeys,
    DescriptionUiTypes,
    IDescription,
    IWidgetDescription
} from '../../interfaces/widget/IWidgetDescription';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { SanitizeTypes } from './../../enums/SanitizeTypes';

@Component({
    selector: 'app-description-generator',
    templateUrl: './description-generator.component.html',
    styleUrls: ['./description-generator.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionGeneratorComponent implements OnInit {
    @Input('data') data: IWidgetDescription;
    @Input('collapseState') collapseState;

    @Output('collapseToggle') collapseToggle = new EventEmitter();
    @Output('sectionChanged') sectionChanged = new EventEmitter();
    descState: boolean;
    compliance = {};
    objectKeys = Object.keys;
    SanitizeTypes = SanitizeTypes;
    descriptionClose = false;
    @Input('toggleObservable') toggleObservable;
    @Input() descriptionApperanceType: DescriptionUiTypes;
    selectedSection;
    sections = {};
    descriptionData: IDescription = null;
    iconName = IconName;
    spinnerLoader = false;
    DescriptionApperance = DescriptionUiTypes;
    constructor(
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.data && this.data.oldDescription) {
            this.descriptionData = this.data.oldDescription as IDescription;
        } else if (
            this.data &&
            this.data.presignedUrl &&
            this.data.presignedUrl[DescriptionPresignedUrlKeys.DESCRIPTION]
        ) {
            this.spinnerLoader = true;

            const hitApi: IHitApi = {
                url: this.data.presignedUrl[
                    DescriptionPresignedUrlKeys.DESCRIPTION
                ],
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true,
                    responseType: 'text'
                },
                function: (res) => {
                    this.spinnerLoader = false;

                    this.descriptionData = {
                        baseDescription: [
                            {
                                type: 'HTML',
                                heading: 'Description',
                                showHeading: false,
                                value: Helper.convertMarkdownToHtml(res)
                            }
                        ]
                    };
                    this.cdr.detectChanges();
                },
                input: {}
            };
            new HitApi(hitApi, this.httpService, this.ngZone).hitApi();
        } else {
            this.descriptionData = null;
        }

        const generalConfig = this.configCache.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCache.viewId
        );
        if (generalConfig) {
            this.collapseState = generalConfig?.openDescriptionByDefault
                ? generalConfig.openDescriptionByDefault
                : false;
        }
        if (this.descriptionData && this.descriptionData.sections) {
            this.descriptionData.sections.forEach((section) => {
                this.sections[section.sectionName] = section.placeholders;
            });
            this.selectedSection = Object.keys(this.sections)[0];
            this.sectionChanged.emit(this.selectedSection);
        }
    }
    changeCompliance($event: MatRadioChange) {
        this.selectedSection = $event.value;
        this.sectionChanged.emit(this.selectedSection);
    }
}
