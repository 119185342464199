import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { CartCacheService } from 'src/app/shared/services/cache/cart-cache/cart-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { CreateReportFirstStepComponent } from '../report-listing-modal/create-report-first-step/create-report-first-step.component';
import { CreateReportSecondStepComponent } from '../report-listing-modal/create-report-second-step/create-report-second-step.component';
import { CreateReportThirdStepComponent } from '../report-listing-modal/create-report-third-step/create-report-third-step.component';
import { Helper } from './../../../classes/Helper';
import { IButtonGeneratorInput } from './../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit, OnDestroy {
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        `Select widgets (allowed limit max. ${GlobalConfiguration.REPORT_LIMIT}) to generate reports. Reporting feature is applicable to certain widgets and the list mentioned is generated accordingly.`
                    ]
                }
            ]
        }
    ];

    reportIcon: IIcon = {
        class: 'fas fa-file-invoice',
        type: IconType.FONTAWSOME
    };

    reportRadio = true;
    widgetRef: Widget;
    widgetsList = [];
    widgetsSelected = {};
    cartWidgets = {};
    showGetExistingButton = true;
    selectionLimit = {
        report: 7
    };
    reportListFilterInfo: BehaviorSubject<IFilterInfo> =
        new BehaviorSubject<IFilterInfo>({ placeholder: 'Loading...' });
    showAddButton = false;
    selectedReport = null;
    reportsListResponse = null;
    emptyCartButtonGenInput: IButtonGeneratorInput;
    getExistingButtonGenInput: IButtonGeneratorInput;
    addToExistingButtonGenInput: IButtonGeneratorInput;
    createNewButtonGenInput: IButtonGeneratorInput;
    constructor(
        private globalDataService: GlobalDataService,
        private cartCache: CartCacheService,
        private modalService: ModalService,
        private modalInput: ModalInjectedData,
        private changeDetectorRef: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.globalDataService.toggleCart.next(true);
        this.setUpBasics();
        this.globalDataService.updateSelectedWidgets.subscribe((response) => {
            this.setUpBasics();
        });
    }

    setUpBasics() {
        if (this.cartCache.cartWidgets) {
            this.cartWidgets = JSON.parse(this.cartCache.cartWidgets);
            this.widgetsList = Object.keys(this.cartWidgets);
        }

        if (this.cartCache.cartSelectedWidgets) {
            this.widgetsSelected = JSON.parse(
                this.cartCache.cartSelectedWidgets
            );
        } else {
            Object.keys(this.cartWidgets).forEach((widget) => {
                if (
                    Object.keys(this.widgetsSelected).length <
                    this.selectionLimit['report']
                ) {
                    this.widgetsSelected[widget] = true;
                }
            });
        }
        this.createButtons();
    }
    createButtons() {
        this.emptyCartButtonGenInput = {
            buttonName: 'Empty Cart',
            buttonColorType: ButtonColorType.WARN,
            buttonType: ButtonType.STROKED,
            function: () => {
                this.resetAllWidgets();
            },
            showLoader: false
        };
        this.getExistingButtonGenInput = {
            buttonName: 'Get Existing',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.getExistingReports(buttonRef);
            },
            showLoader: true
        };
        this.addToExistingButtonGenInput = {
            buttonName: 'Add',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.addToExistingReports(buttonRef);
            },
            showLoader: true
        };
        this.createNewButtonGenInput = {
            buttonName: 'Create New',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.generateReport();
            },
            showLoader: false
        };
    }
    checkIfSelectAll() {
        if (
            Object.keys(this.cartWidgets).length > this.selectionLimit['report']
        ) {
            if (
                this.selectionLimit['report'] ===
                Object.keys(this.widgetsSelected).length
            ) {
                return true;
            }
        } else {
            if (
                Object.keys(this.cartWidgets).length ===
                Object.keys(this.widgetsSelected).length
            ) {
                return true;
            }
        }
    }

    selectAllClicked(event) {
        if (event) {
            Object.keys(this.cartWidgets).forEach((widget) => {
                if (
                    Object.keys(this.widgetsSelected).length <
                    this.selectionLimit['report']
                ) {
                    this.widgetsSelected[widget] = true;
                }
            });
        } else {
            this.widgetsSelected = {};
        }
        this.cartCache.cartSelectedWidgets = JSON.stringify(
            this.widgetsSelected
        );
    }
    checkIfChecked(widgetKey) {
        if (this.widgetsSelected[widgetKey]) {
            return true;
        } else {
            return false;
        }
        this.cartCache.cartSelectedWidgets = JSON.stringify(
            this.widgetsSelected
        );
    }

    checkIfDisabled(widgetKey) {
        if (
            Object.keys(this.widgetsSelected).length ===
                this.selectionLimit.report &&
            !Object.keys(this.widgetsSelected).includes(widgetKey)
        ) {
            return true;
        } else {
            return false;
        }
    }

    onCheckBoxChanged(event, widgetKey) {
        if (event.checked) {
            this.widgetsSelected[widgetKey] = event.checked;
        } else {
            if (widgetKey in this.widgetsSelected) {
                delete this.widgetsSelected[widgetKey];
            }
        }
        this.cartCache.cartSelectedWidgets = JSON.stringify(
            this.widgetsSelected
        );
    }

    removeWidget(widgetKey) {
        if (widgetKey in this.cartWidgets) {
            delete this.cartWidgets[widgetKey];
            this.cartCache.cartWidgets = JSON.stringify(this.cartWidgets);
        }
        if (widgetKey in this.widgetsSelected) {
            delete this.widgetsSelected[widgetKey];
            this.cartCache.cartSelectedWidgets = JSON.stringify(
                this.widgetsSelected
            );
        }
        this.globalDataService.updateCartCount.next(true);
        this.globalDataService.updateSelectedWidgets.next(true);
        if (!Object.keys(this.cartWidgets).length) {
            this.modalService.closeModal(null, this.modalInput.modalId);
        }
    }

    resetAllWidgets() {
        this.cartWidgets = {};
        this.widgetsSelected = {};
        this.cartCache.cartWidgets = JSON.stringify(this.cartWidgets);
        this.cartCache.cartSelectedWidgets = JSON.stringify(
            this.widgetsSelected
        );
        this.globalDataService.updateCartCount.next(true);
        this.globalDataService.updateSelectedWidgets.next(true);
        this.cartCache.deleteCollection();
        this.modalService.closeModal(null, this.modalInput.modalId);
    }

    generateReport() {
        const modalData: IModalData = {
            modalName: 'Create Report',
            modalHeightVh: 98,
            modalWidthVw: 85,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalPurpose: ModalAction.CREATE,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateReportFirstStepComponent,
                        payload: {
                            data: {
                                cartWidgets: Object.keys(this.widgetsSelected),
                                edit: false
                            }
                        }
                    },
                    stepName: 'Select Widgets'
                },
                {
                    stepData: {
                        componentToLoad: CreateReportSecondStepComponent,
                        payload: {
                            data: {}
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad: CreateReportThirdStepComponent,
                        payload: {
                            data: {}
                        }
                    },
                    stepName: 'Schedule and Download'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    getExistingReports(buttonRef) {
        if (buttonRef && buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        this.changeDetectorRef.detectChanges();
        const apiArgs: IHitApi = {
            url: ApiUrls.REPORT_ENDPOINT,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            function: (response) => {
                this.reportsListResponse = response;
                const reportListData: IDropdownData[] = [];
                response.forEach((report) => {
                    reportListData.push({
                        id: report['id'],
                        label: report['reportName']
                    });
                });

                this.reportListFilterInfo.next({
                    listData: reportListData,
                    placeholder: 'Choose a report',
                    lightMode: true
                });
                this.showGetExistingButton = false;
                buttonRef.loader = false;
                this.changeDetectorRef.detectChanges();
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error performing action.'
                );
            }
        };

        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    reportSelectionChanged(event) {
        this.selectedReport = event;
        this.showAddButton = true;
    }

    addToExistingReports(buttonRef) {
        if (buttonRef && buttonRef.loader) {
            return;
        }
        if (!Object.keys(this.widgetsSelected).length) {
            this.notificationsService.showSnackBar(
                'No Widgets selected.',
                true
            );
            return;
        }
        buttonRef.loader = true;
        this.changeDetectorRef.detectChanges();

        if (this.reportsListResponse) {
            const reportData = this.reportsListResponse.find(
                (report) => this.selectedReport === report.id
            );
            const widgetIds = [
                ...reportData['widgetIds'],
                ...Object.keys(this.widgetsSelected)
            ];
            if (widgetIds.length > this.selectionLimit.report) {
                this.notificationsService.showSnackBar(
                    'Maximum of 5 widgets can be added to report',
                    true
                );
                buttonRef.loader = false;
                this.changeDetectorRef.detectChanges();
                return;
            }
            reportData['widgetIds'] = Array.from(new Set([...widgetIds]));

            const apiArgs: IHitApi = {
                url: `${ApiUrls.REPORT_ENDPOINT}/${reportData['id']}`,
                intactUrl: `${ApiUrls.REPORT_ENDPOINT}/{id}`,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                input: reportData,
                requestType: RequestType.PATCH,
                uniqueIdentity: Symbol(),
                function: (response) => {
                    buttonRef.loader = false;
                    this.notificationsService.showSnackBar(
                        'Report updated successfully'
                    );
                },
                errorFunction: (error) => {
                    buttonRef.loader = false;
                    Helper.showErrorMessage(
                        this.notificationsService,
                        error,
                        'Error performing action.'
                    );
                }
            };

            new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
        }
    }

    ngOnDestroy() {
        if (Object.keys(this.widgetsSelected).length) {
            this.cartCache.cartSelectedWidgets = JSON.stringify(
                this.widgetsSelected
            );
        }
        this.globalDataService.toggleCart.next(false);
    }
}
