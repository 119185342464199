import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { ServicenowTestingModalComponent } from '../../../modal-templates/integration/servicenow-testing-modal/servicenow-testing-modal.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-servicenow-integration',
    templateUrl: './servicenow-integration.component.html',
    styleUrls: ['./servicenow-integration.component.sass']
})
export class ServicenowIntegrationComponent implements OnInit, AfterViewInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isIntegrated = false;
    isConfigured: Boolean = false;
    formGenInput: IFormGeneratorInput;
    constructor(
        widgetData: WidgetInjectedData,
        private redirectionHandleService: RedirectionHandleService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'ITSM Integration',
            imageUrl: 'assets/integrations/servicenow-plugin.png',
            buttons: [],
            imagePadding: 5
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        this.formGenInput = {
            formName: 'Servicenow',
            state: this.isIntegrated ? FormState.EDIT : FormState.CREATE,
            submitButton: {
                buttonName: 'Integrate',
                showLoader: true,
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        const args = Helper.generateHitApiConfig(
                            this.isIntegrated
                                ? this.widgetRef.widgetData.widgetInfo.update
                                : this.widgetRef.widgetData.widgetInfo.create
                        );
                        args.input = formGroup.value;
                        args.function = (response) => {
                            if (this.isIntegrated) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Servicenow configuration updated successfully'
                                );
                            } else {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Servicenow integrated successfully'
                                );
                            }
                            buttonRef.loader = false;
                            this.widgetRef.refreshWidget();
                            this.widgetRef.modalService.closeModal(
                                null,
                                modalId
                            );
                        };
                        args.errorFunction = (error) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                            buttonRef.loader = false;
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            },
            fields: [
                {
                    name: 'clientId',
                    label: 'Client ID',
                    placeholder: 'Client ID',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isIntegrated ? response['clientId'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client ID is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: `Where to get Client ID`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your Client Id',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Navigate to System OAuth > Application Registry and then click New.`,
                                            `On the interceptor page, click Create an OAuth API endpoint for external clients and then fill in the form.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    name: 'clientSecret',
                    label: 'Client Secret',
                    placeholder: 'Client Secret',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isIntegrated ? response['clientSecret'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client Secret is required'
                        }
                    ],
                    suffixIcon: {
                        hoverText: `Where to get Client Secret`,
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Your Client Secret',
                                content: [
                                    {
                                        type: 'ORDERED_LIST',
                                        data: [
                                            `Navigate to System OAuth > Application Registry and then click New.`,
                                            `On the interceptor page, click Create an OAuth API endpoint for external clients and then fill in the form.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    name: 'userName',
                    label: 'Username',
                    placeholder: 'Username',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isIntegrated ? response['userName'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Username is required'
                        }
                    ]
                },
                {
                    name: 'password',
                    label: 'Password',
                    placeholder: 'Password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isIntegrated ? response['password'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ]
                },
                {
                    name: 'instanceName',
                    label: 'Instance Name',
                    placeholder: 'Instance Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.isIntegrated ? response['instanceName'] : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Instance Name is required'
                        }
                    ]
                }
            ],
            extraClass: 'integration-styling'
        };

        if (this.isIntegrated) {
            // Adding Play Button
            this.integrationInput.buttons.push({
                buttonName: 'Test',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-play'
                },
                hoverText: 'Test Servicenow',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Servicenow',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-play'
                        },
                        extraStepContainerClass: 'remove-form-space',
                        modalSteps: [
                            {
                                stepName: 'Servicenow Create Incident',
                                stepData: {
                                    componentToLoad:
                                        ServicenowTestingModalComponent,
                                    payload: {
                                        data: null
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalHeightVh: 55
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });

            // Adding Edit Button
            this.integrationInput.buttons.push({
                buttonName: 'Edit',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-wrench'
                },
                hoverText: 'Edit Servicenow',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Edit Servicenow',
                        sourceId:
                            this.widgetRef.widgetData.widgetUniqueIdentifier,
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-wrench'
                        },
                        extraStepContainerClass: 'remove-form-space',
                        modalSteps: [
                            {
                                stepName: 'Edit Servicenow',
                                resetModal: true,
                                stepData: {
                                    componentToLoad:
                                        FormGeneratorModalComponent,
                                    payload: {
                                        data: this.formGenInput
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalHeightVh: 55,
                        noBottomPadding: true
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });

            // Adding Delete Button
            this.integrationInput.buttons.push({
                buttonName: 'Delete',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-trash'
                },
                hoverText: 'Remove Servicenow',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.WARN,
                function: (modalId: Symbol) => {
                    // Delete integration
                    const args = Helper.generateHitApiConfig(
                        this.widgetRef.widgetData.widgetInfo.delete
                    );
                    args.input = {};
                    args.function = (response) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            `Servicenow integration removed successfully`
                        );
                        this.widgetRef.refreshWidget();
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    args.errorFunction = (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            error.error.message,
                            true
                        );
                        this.widgetRef.refreshWidget();
                        this.widgetRef.modalService.closeModal(null, modalId);
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                },
                executeFunctionAfterConfirmation: {
                    modalName: 'Remove Servicenow Integration',
                    modalIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'far fa-trash-alt'
                    },
                    contextIcon: {
                        extraClass: 'color-accent',
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-exclamation-triangle'
                    },
                    confirmationMessage: `Are you sure you want to remove servicenow ?`,
                    buttonText: 'Remove',
                    buttonColorType: ButtonColorType.WARN,
                    loaderOnExec: true
                }
            });
        }
    }
    onIntegrate(formGenInput) {
        const modalArgs: IModalData = {
            modalName: 'Integrate Servicenow',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-wrench'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Integrate Servicenow',
                    resetModal: true,
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 55,
            modalAutoHeight: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        this.isIntegrated = response.integration;
        if (this.isIntegrated) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
