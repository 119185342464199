<div
    class="tw-h-full tw-overflow-hidden tw-flex tw-flex-col tw-pt-5 automation-third-step"
>
    <div class="tw-flex data">
        <app-common-integrations-index
            [initialSelection]="initialSelection"
            [integrations]="[
                IntegrationName.EMAIL,
                IntegrationName.O365,
                IntegrationName.GMAIL,
                IntegrationName.SLACK,
                IntegrationName.FRESHDESK,
                IntegrationName.FRESHSERVICE,
                IntegrationName.ZENDESK,
                IntegrationName.SERVICENOW,
                IntegrationName.JIRADESK,
                IntegrationName.SNS
            ]"
            (selected)="integrationsChanged($event)"
        ></app-common-integrations-index>
        <div
            class="tw-flex-1 tw-pb-3 tw-pt-8 tw-px-5 tw-overflow-auto integration-data"
        >
            <div class="notification" *ngIf="notificationFormGenInput">
                <app-form-generator
                    [formGeneratorInput]="notificationFormGenInput"
                    (formGroupRef)="notificationFormGroup = $event"
                ></app-form-generator>
            </div>
            <section
                class="mail"
                *ngIf="
                    emailFormGenInput &&
                    (selectedIntegrations.has(IntegrationName.EMAIL) ||
                        selectedIntegrations.has(IntegrationName.GMAIL) ||
                        selectedIntegrations.has(IntegrationName.O365))
                "
            >
                <div class="heading">Mail</div>
                <app-form-generator
                    [formGeneratorInput]="emailFormGenInput"
                    (formGroupRef)="emailFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="slack"
                *ngIf="
                    slackFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SLACK)
                "
            >
                <div class="heading">Slack</div>
                <app-form-generator
                    [formGeneratorInput]="slackFormGenInput"
                    (formGroupRef)="slackFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="freshdesk"
                *ngIf="
                    freshdeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.FRESHDESK)
                "
            >
                <div class="heading">Freshdesk</div>
                <app-form-generator
                    [formGeneratorInput]="freshdeskFormGenInput"
                    (formGroupRef)="freshdeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="freshservice"
                *ngIf="
                    freshserviceFormGenInput &&
                    selectedIntegrations.has(IntegrationName.FRESHSERVICE)
                "
            >
                <div class="heading">Freshservice</div>
                <app-form-generator
                    [formGeneratorInput]="freshserviceFormGenInput"
                    (formGroupRef)="freshserviceFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="zendesk"
                *ngIf="
                    zendeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.ZENDESK)
                "
            >
                <div class="heading">Zendesk</div>
                <app-form-generator
                    [formGeneratorInput]="zendeskFormGenInput"
                    (formGroupRef)="zendeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="serviceNow"
                *ngIf="
                    serviceNowFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SERVICENOW)
                "
            >
                <div class="heading">Servicenow</div>
                <app-form-generator
                    [formGeneratorInput]="serviceNowFormGenInput"
                    (formGroupRef)="serviceNowFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="jiradesk"
                *ngIf="
                    jiradeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.JIRADESK)
                "
            >
                <div class="heading">Jiradesk</div>
                <app-form-generator
                    [formGeneratorInput]="jiradeskFormGenInput"
                    (formGroupRef)="jiradeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="sns"
                *ngIf="
                    snsFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SNS)
                "
            >
                <div class="heading">SNS</div>
                <app-form-generator
                    [formGeneratorInput]="snsFormGenInput"
                    (formGroupRef)="snsFormGroup = $event"
                ></app-form-generator>
            </section>
        </div>
    </div>
    <div class="tw-flex tw-justify-between tw-px-4 controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="updateCreateButton"
            [buttonGenInput]="updateCreateButton"
        ></app-button-generator>
    </div>
</div>
