import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';

@Component({
    selector: 'app-autotask-parent-integration-step-one',
    templateUrl: './autotask-parent-integration-step-one.component.html',
    styleUrls: ['./autotask-parent-integration-step-one.component.sass']
})
export class AutotaskParentIntegrationStepOneComponent implements OnInit {
    credentialsFormGenInput: IFormGeneratorInput = null;
    isChildIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response;
    widgetRef: Widget;
    credentialsFormGroup: FormGroup;
    resetSubscription: Subscription;
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.response = modalInputData.data['response'];
        this.isOwnerIntegrated =
            modalInputData.data['response']['ownerIntegration'];
        this.isChildIntegrated =
            modalInputData.data['response']['childIntegration'];
        this.isParentIntegrated =
            modalInputData.data['response']['parentIntegration'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        this.credentialsFormGenInput = {
            formName: 'Autotask',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Next',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        if (this.isParentIntegrated) {
                            // Update
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.update
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: IntegrationName.AUTOTASK,
                                childIntegration: 'false',
                                parentIntegration: 'true',
                                getFieldFlag: 'true'
                            };
                            args.function = (response) => {
                                buttonRef.loader = false;
                                const formData = {
                                    formGroup: formGroup,
                                    response: response
                                };
                                this.multiStepFormService.stepData
                                    .get(this.modalInputData.modalId)
                                    .set(1, formData);
                                this.multiStepFormService.nextStep(
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error Validating AutoTask Credentials.'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        } else {
                            // Create
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.create
                            );
                            args.input = {
                                ...formGroup.value,
                                integrationType: IntegrationName.AUTOTASK,
                                childIntegration: 'false',
                                parentIntegration: 'true',
                                getFieldFlag: 'true'
                            };
                            args.function = (response) => {
                                buttonRef.loader = false;
                                const formData = {
                                    formGroup: formGroup,
                                    response: response
                                };
                                this.multiStepFormService.stepData
                                    .get(this.modalInputData.modalId)
                                    .set(1, formData);
                                this.multiStepFormService.nextStep(
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error Validating AutoTask Credentials.'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                }
            },
            fields: [
                {
                    name: 'userName',
                    placeholder: 'Enter Username',
                    label: 'Username',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Username is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['parentCred']['userName']
                        : ''
                },
                {
                    name: 'password',
                    placeholder: 'Enter Password',
                    label: 'Password',
                    showLabel: true,
                    appearance: 'legacy',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['parentCred']['password']
                        : ''
                },
                {
                    name: 'trackingIdentifier',
                    placeholder: 'Enter Tracking Identifier',
                    label: 'Tracking Identifier',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Tracking Identifier is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['parentCred']['trackingId']
                        : ''
                },
                {
                    name: 'deskName',
                    placeholder: 'Enter deskname',
                    label: 'Desk Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Desk Name is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['parentCred']['deskName']
                        : ''
                }
            ],
            extraClass: 'integration-new-styling'
        };
        this.resetSubscription =
            this.widgetRef.modalService.resetModal.subscribe(() => {
                this.credentialsFormGroup.reset();
            });
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
