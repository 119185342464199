import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { VariableFieldType } from 'src/app/shared/enums/VariableFieldType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    WidgetCreationActivityType,
    WidgetCreationService
} from 'src/app/shared/services/widget-creation/widget-creation.service';
import { TerraformRequestWidgetFormModalComponent } from '../terraform-request-widget-form-modal/terraform-request-widget-form-modal.component';
import { TerraformRequestWidgetModalComponent } from '../terraform-request-widget-modal/terraform-request-widget-modal.component';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-terraform-map-variables-modal',
    templateUrl: './terraform-map-variables-modal.component.html',
    styleUrls: ['./terraform-map-variables-modal.component.sass']
})
export class TerraformMapVariablesModalComponent implements OnInit, OnDestroy {
    LoaderType = LoaderType;
    loader: Map<string, boolean> = new Map<string, boolean>();
    variableFields: IFormField[];
    valueTypeFormGenInputs: Map<string, IFormGeneratorInput> = new Map();
    valueTypeFormGroup: Map<string, FormGroup> = new Map();
    variableFormGenInputs: Map<string, IFormGeneratorInput> = new Map();
    variableFormGroup: Map<string, FormGroup> = new Map();
    resetForm: any;
    widgetRef: Widget;
    stepData: any;
    previousStepData: any;
    cloud: string;
    requestFormGenInputs: IFormGeneratorInput;
    stepTwoData: any;
    stepOneData: any;
    widgetId: string;
    canSave: boolean = false;
    deleteUploadedFilesCallBackFn: any;
    deleteUploadedFile: boolean = true;
    actionButtonGenInput: IButtonGeneratorInput[];
    backButtonGenInput: IButtonGeneratorInput;
    infoHeading: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-circle'
    };
    warning: string;
    resetModal: Subscription;
    configureLocation: boolean = false;
    constructor(
        private modalData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private widgetCreationService: WidgetCreationService,
        private modalService: ModalService
    ) {
        this.widgetId = modalData.data['widgetId'];
        this.widgetRef = modalData.data['widgetRef'];
        this.stepOneData = this.multiStepFormService.stepData
            .get(modalData.modalId)
            .get(1);
        this.previousStepData = this.multiStepFormService.stepData
            .get(modalData.modalId)
            .get(3);
        this.stepTwoData = this.multiStepFormService.stepData
            .get(modalData.modalId)
            .get(2);
        this.configureLocation = this.stepTwoData['configureLocation'];
        if (this.stepTwoData && this.stepTwoData.warning) {
            this.warning = `Script includes," ${this.stepTwoData.warning} " which specifies the region where resource will be created. This value will override the "Region(s)" selected in Provider File.`;
        }
        this.cloud = this.stepTwoData['cloud'];
        this.variableFields =
            this.previousStepData && this.previousStepData['formFields']
                ? Helper.cloneDeep(this.previousStepData['formFields'])
                : [];
        if (this.stepTwoData && this.stepTwoData['deleteFilesCallBackFn']) {
            this.deleteUploadedFilesCallBackFn =
                this.stepTwoData['deleteFilesCallBackFn'];
        }
        if (this.multiStepFormService.stepData.get(modalData.modalId).has(4)) {
            this.stepData = this.multiStepFormService.stepData
                .get(modalData.modalId)
                .get(4);
            this.valueTypeFormGenInputs =
                this.stepData['valueTypeFormGenInputs'];
            this.valueTypeFormGroup = this.stepData['valueTypeFormGroup'];
        }
    }

    ngOnInit(): void {
        this.generateButtons();
        this.variableFields.forEach((field: IFormField) => {
            field.fieldType = this.mapVariableType(field.fieldType);
            this.handleResponse(field);
        });
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            if (
                this.loader.get(LoaderType.SAVE) ||
                this.loader.get(LoaderType.SAVE_AS_DRAFT)
            ) {
                return;
            }
            this.stepData = null;
            this.valueTypeFormGenInputs.clear();
            this.valueTypeFormGroup.clear();
            this.variableFields =
                this.previousStepData && this.previousStepData['formFields']
                    ? Helper.cloneDeep(this.previousStepData['formFields'])
                    : [];
            this.variableFields.forEach((field: IFormField) => {
                field.fieldType = this.mapVariableType(field.fieldType);
                this.handleResponse(field);
            });
        });
    }
    generateButtons() {
        this.actionButtonGenInput = [
            {
                buttonName: 'Save as Draft',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.saveAsDraft(buttonRef);
                },
                showLoader: true
            },
            {
                buttonName: 'Test Runbook',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef) => {
                    this.testRunbookModal();
                },
                showLoader: false
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                disable: true,
                function: (buttonRef) => {
                    this.createWidget(buttonRef);
                },
                showLoader: true,
                hoverText: !this.canSave
                    ? 'It is mandatory to test runbook before saving.'
                    : ''
            }
        ];
        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.goToPreviousStep();
            },
            showLoader: false
        };
    }
    mapVariableType(variableType) {
        switch (variableType) {
            case VariableFieldType.SHORT_TEXT:
                return FilterType.TEXT;
            case VariableFieldType.STRING:
                return FilterType.TEXT;
            case VariableFieldType.BOOLEAN:
                return FilterType.DROPDOWN_SINGLE;
            default:
                return variableType;
        }
    }
    handleResponse(response) {
        response.appearance = 'legacy';
        response.hideFloatLabel = true;
        response['placeholder'] = response['description']
            ? response['description']
            : response['placeHolder']
            ? response['placeHolder']
            : '';
        if (response.required) {
            response.validations = [
                {
                    validator: CustomValidators.required,
                    errorMessage: `${response.label} is required.`
                }
            ];
        }
        const form = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [response]
        };
        this.variableFormGenInputs.set(response.name, form);
        if (!this.stepData) {
            this.valueTypeFormGenInputs.set(response.name, {
                formName: '',
                state: FormState.CREATE,
                formId: response.name,
                submitButton: null,
                fields: [
                    {
                        label: 'Authentication Type',
                        placeholder: 'Authentication Type',
                        name: response.name,
                        fieldType: FilterType.RADIO,
                        listData: [
                            { id: 'request-form', label: 'Request Form' },
                            { id: 'set-Default', label: 'Set Default' }
                        ],
                        value:
                            response && response['defaultValue']
                                ? 'set-Default'
                                : response &&
                                  response.mappingType &&
                                  response.mappingType === MappingType.DEFAULT
                                ? 'set-Default'
                                : response.mappingType &&
                                  response.mappingType === MappingType.FORM
                                ? 'request-form'
                                : 'request-form',
                        required: true
                    }
                ]
            });
        }
    }
    valueTypeChanged(value, fieldName) {
        if (value[fieldName] === 'request-form' && this.variableFormGenInputs) {
            this.variableFormGenInputs.get(fieldName).state = FormState.IDLE;
            const value =
                this.variableFormGenInputs.get(fieldName).fields[0].value;
            if (
                this.variableFormGroup.has(fieldName) &&
                this.variableFormGroup.get(fieldName).get(fieldName)
            ) {
                if (value) {
                    this.variableFormGroup
                        .get(fieldName)
                        .get(fieldName)
                        .setValue(value);
                } else {
                    this.variableFormGroup
                        .get(fieldName)
                        .get(fieldName)
                        .setValue(null);
                }
            }
        } else {
            this.variableFormGenInputs.get(fieldName).state = FormState.EDIT;
        }
        this.variableFormGenInputs.set(fieldName, {
            ...this.variableFormGenInputs.get(fieldName)
        });
    }
    testRunbookModal() {
        if (!this.validateForm()) {
            return;
        }
        const form = this.createForm();
        const modalData: IModalData = {
            modalName: 'Form',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 85,
            modalHeightVh: 95,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: TerraformRequestWidgetModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                cloud: this.cloud,
                                configureLocation: this.configureLocation
                            }
                        }
                    },
                    headingText: this.stepOneData['widgetName'],
                    stepName: '',
                    resetModal: true
                },
                {
                    stepData: {
                        componentToLoad:
                            TerraformRequestWidgetFormModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                form: form,
                                stepTwoData: this.stepTwoData,
                                callBackFn:
                                    this.testRunbookCallBackFn.bind(this)
                            }
                        }
                    },
                    headingText: `${this.stepOneData['widgetName']} Form`,
                    stepName: '',
                    resetModal: true
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    validateForm() {
        let validForm: boolean = true;
        this.variableFields.forEach((field: IFormField) => {
            if (
                this.valueTypeFormGroup.get(field.name).get(field.name)
                    .value === ValueType.SET_DEFAULT &&
                !field['defaultValue']
            ) {
                const form = this.variableFormGroup.get(field.name);
                Helper.markAllFieldAsTouched(form);
                if (form.invalid) {
                    form.updateValueAndValidity();
                    validForm = false;
                }
            }
        });
        return validForm;
    }
    testRunbookCallBackFn(status) {
        this.canSave = status;
        this.actionButtonGenInput[2].disable = !status;
        this.actionButtonGenInput[2].hoverText = !this.canSave
            ? this.actionButtonGenInput[2].hoverText
            : '';
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    createForm() {
        const formFields = [];
        this.variableFields.forEach((field: IFormField) => {
            const formField = {
                ...this.variableFormGenInputs.get(field.name).fields[0]
            };
            if (
                this.valueTypeFormGroup.get(field.name).get(field.name)
                    .value === ValueType.SET_DEFAULT
            ) {
                formField['mappingType'] = MappingType.DEFAULT;
                formField.value = this.variableFormGroup
                    .get(field.name)
                    .get(field.name).value;
                formField.disabled = true;
            } else {
                formField['mappingType'] = MappingType.FORM;
            }
            formField['appearance'] = 'outline';
            formField['hideFloatLabel'] = false;
            formFields.push(formField);
        });
        this.requestFormGenInputs = {
            formName: 'Request Form',
            submitButton: null,
            state: FormState.EDIT,
            fields: formFields
        };
        return this.requestFormGenInputs;
    }
    goToPreviousStep() {
        if (
            this.loader.get(LoaderType.SAVE) ||
            this.loader.get(LoaderType.SAVE_AS_DRAFT)
        ) {
            return;
        }
        this.deleteUploadedFile = false;
        const stepData = {};
        stepData['valueTypeFormGenInputs'] = new Map(
            this.valueTypeFormGenInputs
        );
        stepData['valueTypeFormGroup'] = new Map(this.valueTypeFormGroup);
        this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .set(4, stepData);
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }
    createWidget(buttonRef) {
        this.deleteUploadedFile = false;
        if (!this.canSave) {
            return;
        }
        if (
            this.loader.get(LoaderType.SAVE) ||
            this.loader.get(LoaderType.SAVE_AS_DRAFT)
        ) {
            return;
        }
        this.loader.set(LoaderType.SAVE, true);
        buttonRef.loader = true;
        const input = this.prepareInput();
        const apiConfig = Helper.generateHitApiConfig(
            this.widgetId
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiConfig.intactUrl = apiConfig.url;
        apiConfig.url = apiConfig.url.replace('{widget_id}', this.widgetId);
        apiConfig.input = input;
        apiConfig.function = (response) => {
            this.loader.set(LoaderType.SAVE, false);
            buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalData.modalId);
            this.widgetRef.notificationsService.showSnackBar(
                'Widget Created Successfully'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        apiConfig.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.deleteUploadedFile = true;
            this.loader.set(LoaderType.SAVE, false);
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const input = { ...this.stepOneData };
        const formFields = [];
        this.variableFields.forEach((field: IFormField) => {
            const formField = {
                ...this.variableFormGenInputs.get(field.name).fields[0]
            };
            if (
                this.valueTypeFormGroup.get(field.name).get(field.name)
                    .value === ValueType.SET_DEFAULT
            ) {
                formField['mappingType'] = MappingType.DEFAULT;
                formField.value = this.variableFormGroup
                    .get(field.name)
                    .get(field.name).value;
                formField.disabled = true;
            } else {
                formField['mappingType'] = MappingType.FORM;
            }
            delete formField.hideFloatLabel;
            delete formField.appearance;
            delete formField.disabled;
            delete formField.validations;
            formFields.push(formField);
        });
        input['newKeys'] = this.stepTwoData['newKeys'];
        input['extraInfo']['terraformCloud'] = this.stepTwoData.cloud;
        input['extraInfo']['activityType'] =
            WidgetCreationActivityType.WIDGET_PUBLISHED;
        input['mainTfs'] = this.stepTwoData['mainTfs'];
        input['variableFiles'] = this.stepTwoData['variableFiles'];
        input['tfVarsFiles'] = this.stepTwoData['tfVarsFiles']
            ? this.stepTwoData['tfVarsFiles']
            : [];
        input['secretFiles'] = this.stepTwoData['secretFiles']
            ? this.stepTwoData['secretFiles']
            : [];
        input['configureLocation'] = this.configureLocation;
        input['form'] = {
            formName: 'Request Form',
            submitButton: null,
            state: FormState.EDIT,
            fields: formFields
        };
        return input;
    }
    saveAsDraft(buttonRef) {
        this.deleteUploadedFile = false;
        if (
            this.loader.get(LoaderType.SAVE) ||
            this.loader.get(LoaderType.SAVE_AS_DRAFT)
        ) {
            return;
        }
        this.loader.set(LoaderType.SAVE_AS_DRAFT, true);
        buttonRef.loader = true;
        const inputs = this.prepareInput();
        inputs['extraInfo']['activityType'] =
            WidgetCreationActivityType.WIDGET_DRAFT;
        this.widgetCreationService.saveAsDraft(
            inputs,
            (res) => {
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalData.modalId);
            },
            (error) => {
                this.loader.set(LoaderType.SAVE_AS_DRAFT, false);
                this.deleteUploadedFile = true;
                buttonRef.loader = false;
            },
            this.widgetId
        );
    }
    ngOnDestroy() {
        if (this.deleteUploadedFile) {
            this.deleteUploadedFilesCallBackFn(true);
        }
        this.resetModal.unsubscribe();
    }
}
export enum ValueType {
    REQUEST_FORM = 'request-form',
    SET_DEFAULT = 'set-Default'
}
export enum MappingType {
    DEFAULT = 'DEFAULT',
    FORM = 'FORM'
}
export enum LoaderType {
    SAVE = 'SAVE',
    SAVE_AS_DRAFT = 'SAVE_AS_DRAFT'
}
