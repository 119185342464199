import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { StepOneData } from '../salesforce-integrations-step-one/salesforce-integration-step-one.component';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
@Component({
    selector: 'app-salesforce-integration-case-form',
    templateUrl: './salesforce-integration-case-form.component.html',
    styleUrls: ['./salesforce-integration-case-form.component.sass']
})
export class SalesforceIntegrationCaseFormComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroupRef: FormGroup;
    buttonInput: IButtonGeneratorInput;
    widgetRef: Widget;
    stepOneData: StepOneData;
    isCreate: boolean = false;
    configId: string;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService,
        private widgetCreationService: WidgetCreationService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.stepOneData = modalInjectedData.data['stepOneData'];
        this.isCreate = modalInjectedData.data['isCreate'];
        this.configId = modalInjectedData.data['configId'];
    }

    ngOnInit(): void {
        this.caseHandlingForm();
        this.buttonInput = {
            buttonName: this.stepOneData ? 'Test' : 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.createCase(buttonRef);
            }
        };
    }
    caseHandlingForm() {
        this.formGenInput = {
            formName: 'Case Handling',
            state: FormState.CREATE,
            submitButton: null,
            fields: Helper.cloneDeep(this.modalInjectedData.data['formFields'])
        };
        this.formGenInput.fields.forEach((field) => {
            if (field.fieldType === FilterType.CURRENCY) {
                field.fieldType = FilterType.NUMBER;
            }
            field.appearance = 'legacy';
            field.showLabel = true;
            field.placeholder = field.label;
            if (field.required) {
                field.validations = [
                    {
                        validator: CustomValidators.required,
                        errorMessage: field.label + ' is required'
                    }
                ];
            }
        });
    }
    createCase(buttonRef: IButtonGeneratorInput) {
        if (this.formGroupRef) {
            if (this.formGroupRef.invalid) {
                Helper.markAllFieldAsTouched(this.formGroupRef);
                this.formGroupRef.updateValueAndValidity();
                return;
            }
        }
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.isCreate && this.widgetRef.widgetData.widgetInfo['createCase']
                ? this.widgetRef.widgetData.widgetInfo['createCase']
                : this.isCreate &&
                  !this.widgetRef.widgetData.widgetInfo['createCase']
                ? {
                      host: null,
                      apiRouteSuffix:
                          ApiUrls.SALESFORCE_INTEGRATION_CREATE_TICKET,
                      requestType: RequestType.POST,
                      authorization: AuthorizationType.BEARER_TOKEN
                  }
                : this.widgetRef.widgetData.widgetInfo['createCaseTest']
        );
        if (this.isCreate) {
            hitApi.intactUrl = hitApi.url;
            hitApi.url = hitApi.url.replace('{id}', this.configId);
        }
        hitApi.input = this.prepareInput();
        hitApi.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Case created successfully'
            );
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        if (this.isCreate) {
            return this.prepareFormRequestPayload();
        } else {
            return {
                formRequest: this.prepareFormRequestPayload(),
                integrateRequest: { step1: this.stepOneData }
            };
        }
    }
    prepareFormRequestPayload() {
        const formInput = this.formGroupRef.getRawValue();
        const requestPayload = [];

        this.formGenInput.fields.map((field) => {
            requestPayload.push({
                varId: field.varId,
                id: field.varId,
                inputString:
                    this.widgetCreationService.resolveSalesforceInputString(
                        formInput[field.name],
                        'input'
                    ),

                selection:
                    this.widgetCreationService.resolveSalesforceInputString(
                        formInput[field.name],
                        'selection'
                    )
            });
        });
        return requestPayload;
    }
}
