import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { MultiButtonGeneratorComponent } from 'src/app/shared/components/multi-button-generator/multi-button-generator.component';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import {
    AssessmentAuditTypes,
    AssessmentCacheService,
} from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { RequestDetailsModalComponent } from '../../../modal-templates/request-details-modal/request-details-modal.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { UserDataCacheService } from './../../../../services/user-data-cache/user-data-cache.service';
import { RequestFormModalComponent } from './../../../modal-templates/widget-creation-modal/request-form-modal/request-form-modal.component';
import { Messages } from 'src/app/shared/classes/Messages';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IconType } from 'src/app/shared/enums/IconType';
import { CustomRequestWidgetFormValueKeys } from 'src/app/shared/interfaces/assessment/ICustomeRequestWidgetTypes';
import moment from 'moment';

@Component({
    selector: 'app-custom-request-widget',
    templateUrl: './custom-request-widget.component.html',
    styleUrls: ['./custom-request-widget.component.sass'],
})
export class CustomRequestWidgetComponent implements OnInit {
    widgetRef: Widget;
    assessmentData;
    rowData: any[];
    colDefs: any[];
    tableKeys: any[];
    widgetListingData: any;
    tablesData: BehaviorSubject<null> = new BehaviorSubject<any>(null);
    tableInputData: ITableGeneratorInput = {
        listExtraction: {
            type: 'DIRECT',
        },
        columns: [],
        tableHeight: 300,
    };
    frameworkData: any;

    private get isGoogleMspAudit() {
        return (
            this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.GOOGLE_MSP
        );
    }

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private globalDataService: GlobalDataService,
        private assessmentCacheService: AssessmentCacheService,
        private userDataCacheService: UserDataCacheService,
        private ref: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.globalDataService.frameworkData.subscribe((data) => {
            this.frameworkData = data;
        });
    }

    ngOnInit(): void {
        this.assessmentData = this.assessmentCacheService.assessmentData;
        this.widgetRef.widgetData.widgetInfo.list =
            this.widgetRef.widgetData.widgetInfo['get'];
        this.tableInputData.buttons = [
            {
                buttonName: 'Enter Details',
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.request();
                },
                buttonType: ButtonType.FLAT,
            },
        ];
        this.setUpBasics();
        if (
            this.userDataCacheService.isAssesseeView &&
            this.assessmentData.response['assesseOverview'] &&
            this.assessmentData.response['assessorOverview']
        ) {
            this.tableInputData.buttons[0].disable = true;
        }
        this.globalDataService.assessmentStatus.subscribe((res) => {
            if (res !== '') {
                if (
                    this.userDataCacheService.isAssessorView &&
                    this.userDataCacheService.isAssesseeView &&
                    !this.assessmentData.response['assesseOverview'] &&
                    (res === 'Approved' || res === 'Rejected')
                ) {
                    this.tableInputData.buttons[0].disable = true;
                } else if (
                    this.userDataCacheService.isAssesseeView &&
                    !this.userDataCacheService.isAssessorView &&
                    (res === 'Under Evaluation' ||
                        res === 'Approved' ||
                        res === 'Rejected')
                ) {
                    this.tableInputData.buttons[0].disable = true;
                }

                if (this.isGoogleMspAudit) {
                    if (
                        this.userDataCacheService.isAssessorView &&
                        this.userDataCacheService.isAssesseeView &&
                        !this.assessmentData.response['assesseOverview'] &&
                        res === 'Completed'
                    ) {
                        this.tableInputData.buttons[0].disable = true;
                    } else if (
                        this.userDataCacheService.isAssesseeView &&
                        !this.userDataCacheService.isAssessorView &&
                        (res === 'Under Evaluation' || res === 'Completed')
                    ) {
                        this.tableInputData.buttons[0].disable = true;
                    }
                }
            }
        });
    }

    request() {
        const modalData: IModalData = {
            modalName:
                this.widgetRef.widgetData.widgetInfo &&
                this.widgetRef.widgetData.widgetInfo.form &&
                this.widgetRef.widgetData.widgetInfo.form.formName
                    ? this.widgetRef.widgetData.widgetInfo.form.formName
                    : 'Request Form',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 60,
            maxHeightVh: 90,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RequestFormModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                enterDetailsButton:
                                    this.tableInputData.buttons[0],
                            },
                        },
                    },
                    stepName:
                        this.widgetRef.widgetData.widgetInfo &&
                        this.widgetRef.widgetData.widgetInfo.form &&
                        this.widgetRef.widgetData.widgetInfo.form.formName
                            ? this.widgetRef.widgetData.widgetInfo.form.formName
                            : 'Request Form',
                },
            ],
            extraClass: 'modal-height-auto-overflow',
        };

        this.modalService.openModal(modalData);
    }
    setUpBasics() {
        this.widgetRef.showViewIcon.next(true);
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    bindData(data): void {
        if (!data) {
            return;
        }
        // Assigning data to WidgetRef.
        this.widgetRef.apiResponse = data;
        if (data.length === 0) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.loadingData.next(false);
            if (
                !this.userDataCacheService.isAssessorView &&
                !this.frameworkData?.['assesseeSubmitted']
            ) {
                this.tableInputData.buttons[0].disable = false;
                this.tableInputData.buttons[0].hoverText = '';
            }
        }
        if (data.length === 1) {
            this.tableInputData.buttons[0].disable = true;
            this.tableInputData.buttons[0].hoverText =
                'Please delete the existing form to submit new details.';
        }
        this.widgetRef.visibleSections.next(new Set([ViewType.TABLE]));
        this.drawTable();
        this.widgetRef.endLoader();
    }

    drawTable() {
        this.rowData = [];
        this.tableKeys = [];
        this.rowData = this.widgetRef
            .apiResponse as unknown as RequestDetailWidgetResponse[];
        // Providing table data for searching.
        this.widgetListingData = this.rowData;
        this.prepareTableData();
        this.tablesData.next(this.widgetListingData);
        // Will generate the table data.
    }

    prepareTableData() {
        this.tableInputData.columns = [];

        const isDisableEditDeleteButton =
            this.userDataCacheService.isAssesseeView &&
            this.userDataCacheService.isAssessorView
                ? true
                : !this.userDataCacheService.isAssessorView &&
                  this.frameworkData?.['assesseeSubmitted']
                ? true
                : false;

        const isDisableEditDeleteButtonGoogleMsp =
            !this.userDataCacheService.isAssessorView &&
            this.frameworkData?.['assesseeSubmitted']
                ? true
                : this.globalDataService.assessmentStatus.getValue() ===
                  'Completed';

        // Pushing columns to tableInputData
        this.tableInputData.columns = [
            {
                columnName: 'Form ID',
                columnKey: 'Form ID',
                sortable: true,
                pinned: 'left',
                width: 250,
            },
            {
                columnName: 'Status',
                columnKey: 'Status',
                sortable: true,
            },
            {
                columnName: 'User Name',
                columnKey: 'Username',
                sortable: true,
            },
            {
                columnName: 'Email Address',
                columnKey: 'Email Address',
                sortable: true,
            },
            {
                columnName: 'Date & Time',
                columnKey: 'Date and Time',
                sortable: true,
                width: 260,
            },
            {
                columnName: 'Action',
                columnKey: 'Action',
                pinned: 'right',
                buttonGen: true,
                width: 210,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData) => {
                    const buttons: IButtonGeneratorInput[] = [
                        {
                            buttonName: 'View Details',
                            buttonColorType: ButtonColorType.INFO,
                            buttonType: ButtonType.TEXT,
                            function: () => {
                                if (this.isGoogleMspAudit) {
                                    if (
                                        this.widgetRef.widgetData.widgetId ===
                                        '6aafccbb-a3ce-4dde-9602-339f1469c1cb'
                                    ) {
                                        this.requestDetails(rowData);
                                    } else {
                                        this.showDetailsTabular(rowData);
                                    }
                                } else {
                                    this.requestDetails(rowData);
                                }
                            },
                        },
                        {
                            buttonName: 'Edit',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-edit',
                            },
                            showLoader: true,
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonData: IButtonData) => {
                                const isUseAnotherApiForTabularDataSupport =
                                    this.isGoogleMspAudit;

                                buttonData.loader = true;
                                this.tableInputData.buttons[0].disable = true;
                                this.ref.detectChanges();
                                const savedDataRawApiArgs =
                                    Helper.generateHitApiConfig(
                                        isUseAnotherApiForTabularDataSupport
                                            ? this.widgetRef.widgetData
                                                  .widgetInfo?.['get']
                                            : this.widgetRef.widgetData
                                                  .widgetInfo.preview
                                    );
                                savedDataRawApiArgs.intactUrl =
                                    savedDataRawApiArgs.url;
                                if (!isUseAnotherApiForTabularDataSupport) {
                                    savedDataRawApiArgs.url =
                                        savedDataRawApiArgs.url.replace(
                                            '{request-id}',
                                            rowData?.data?.['Form ID']
                                        );
                                    savedDataRawApiArgs.url =
                                        savedDataRawApiArgs.url.replace(
                                            '%7Brequest-id%7D',
                                            rowData?.data?.['Form ID']
                                        );
                                }
                                savedDataRawApiArgs.input = {};
                                savedDataRawApiArgs.function = (response) => {
                                    // response contains raw values for DATE_TIME field
                                    // Hence we are using it for filling the data while editing
                                    this.openEditRequestModal(response);
                                    this.tableInputData.buttons[0].disable =
                                        false;
                                    buttonData.loader = false;
                                    this.ref.detectChanges();
                                };
                                savedDataRawApiArgs.errorFunction = (error) => {
                                    this.tableInputData.buttons[0].disable =
                                        false;
                                    this.widgetRef.notificationsService.showSnackBar(
                                        error && error.error.message
                                            ? error.error.message
                                            : 'Error while editing',
                                        true
                                    );
                                    buttonData.loader = false;
                                    this.ref.detectChanges();
                                    this.widgetRef.refreshWidget();
                                };
                                new HitApi(
                                    savedDataRawApiArgs,
                                    this.widgetRef.httpService,
                                    this.widgetRef.ngZone
                                ).hitApi();
                            },
                            disable: this.isGoogleMspAudit
                                ? isDisableEditDeleteButtonGoogleMsp
                                : isDisableEditDeleteButton,
                            customClass: (
                                this.isGoogleMspAudit
                                    ? isDisableEditDeleteButtonGoogleMsp
                                    : isDisableEditDeleteButton
                            )
                                ? 'decrease-opacity'
                                : 'opacity-1',
                        },
                        {
                            buttonName: 'Delete',
                            buttonIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-trash',
                            },
                            showLoader: false,
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.WARN,
                            function: (modalId: Symbol) => {
                                const deleteArgs = Helper.generateHitApiConfig(
                                    this.widgetRef.widgetData.widgetInfo.delete
                                );
                                deleteArgs.url = deleteArgs.url.replace(
                                    '{request-id}',
                                    rowData?.data?.['Form ID']
                                );
                                deleteArgs.input = {};
                                deleteArgs.function = () => {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        'Information deleted successfully'
                                    );
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        modalId
                                    );
                                    this.tableInputData.buttons[0].disable =
                                        false;
                                    this.widgetRef.refreshWidget();
                                };
                                deleteArgs.errorFunction = (error) => {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        error && error.error.message
                                            ? error.error.message
                                            : 'Error deleting assessment',
                                        true
                                    );
                                    this.widgetRef.modalService.closeModal(
                                        null,
                                        modalId
                                    );
                                };
                                new HitApi(
                                    deleteArgs,
                                    this.widgetRef.httpService,
                                    this.widgetRef.ngZone
                                ).hitApi();
                            },
                            disable: this.isGoogleMspAudit
                                ? isDisableEditDeleteButtonGoogleMsp
                                : isDisableEditDeleteButton,
                            customClass: (
                                this.isGoogleMspAudit
                                    ? isDisableEditDeleteButtonGoogleMsp
                                    : isDisableEditDeleteButton
                            )
                                ? 'decrease-opacity'
                                : 'opacity-1',
                            executeFunctionAfterConfirmation: {
                                modalName: 'Confirmation',
                                modalIcon: null,
                                confirmationMessage: `Are you sure you want to delete the added information?`,
                                buttonText: 'Yes',
                                buttonColorType: ButtonColorType.PRIMARY,
                                loaderOnExec: true,
                                cancelButtonText: 'No',
                            },
                        },
                    ];
                    rowData['buttonGenInputs'] = buttons;
                    return rowData;
                },
            },
        ];
        // Table row selction to single
        this.tableInputData.rowSelection = 'single';
        // Pinning 1st column to left.
        this.tableInputData.columns[0]['pinned'] = 'left';
    }
    requestDetails(rowData?) {
        const getKey = (object, field: IFormField): string => {
            if (
                this.isGoogleMspAudit &&
                this.widgetRef.widgetData.widgetId ===
                    '6aafccbb-a3ce-4dde-9602-339f1469c1cb'
            ) {
                return CustomRequestWidgetFormValueKeys.SELECTION;
            }
            if (
                field.fieldType === FilterType.DROPDOWN_SINGLE ||
                field.fieldType === FilterType.SHORT_TEXT ||
                field.fieldType === FilterType.LONG_TEXT ||
                field.fieldType === FilterType.DATE ||
                (field.fieldType === FilterType.TEXTAREA &&
                    CustomRequestWidgetFormValueKeys.INPUTSTRING in object) ||
                (field.fieldType === FilterType.NUMBER &&
                    CustomRequestWidgetFormValueKeys.INPUTSTRING in object)
            ) {
                return CustomRequestWidgetFormValueKeys.INPUTSTRING;
            }
            if (
                field.fieldType === FilterType.DATE_TIME &&
                CustomRequestWidgetFormValueKeys.DATE in object
            ) {
                return CustomRequestWidgetFormValueKeys.DATE;
            }
            if (
                field.fieldType === FilterType.DROPDOWN_MULTIPLE &&
                CustomRequestWidgetFormValueKeys.SELECTION in object
            ) {
                return CustomRequestWidgetFormValueKeys.SELECTION;
            }
        };

        const tableData = this.widgetRef.widgetData.widgetInfo.form;
        const data = rowData.data['Form Details'];
        tableData.data = data;
        const finalData = [];
        tableData.fields.map((field, index) => {
            finalData.push({
                label: field.label,
                value: tableData.data[index][
                    getKey(tableData.data[index], field)
                ],
            });
        });
        finalData.map((res) => {
            if (
                res.label.toLowerCase().includes('start date') ||
                res.label.toLowerCase().includes('end date') ||
                res.label === 'Date and Time'
            ) {
                res.value = new Date(res.value).toUTCString();
            }
        });
        const dataObj = {};
        finalData.map((data) => {
            dataObj[data['label']] = data['value'];
        });
        const modalData: IModalData = {
            modalName: '',
            noHeader: true,
            modalIcon: null,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: RequestDetailsModalComponent,
                        payload: {
                            data: {
                                stepData: {
                                    'Form Details': dataObj,
                                },
                                noAccordion: true,
                                widgetRef: this.widgetRef,
                            },
                        },
                    },
                },
            ],
        };
        this.modalService.openModal(modalData);
    }

    private showDetailsTabular(rowData: any) {
        const form = this.widgetRef.widgetData.widgetInfo.form;
        const savedTableData = rowData.data['Form Details'];
        const header = form?.fields?.map((field) => field?.label);
        const formattedTableDataToDisplay = savedTableData?.map((row) => {
            return row?.rowFields?.map((fieldSavedDataInfo, index) => {
                const fieldType = form?.fields?.[index]?.fieldType;

                if (fieldType === FilterType.DATE_TIME) {
                    return (
                        moment(
                            Number(
                                fieldSavedDataInfo[
                                    CustomRequestWidgetFormValueKeys.DATE
                                ]
                            )
                        )
                            ?.toDate()
                            ?.toUTCString() || ''
                    );
                } else {
                    return (
                        fieldSavedDataInfo[
                            CustomRequestWidgetFormValueKeys.INPUTSTRING
                        ] || ''
                    );
                }
            });
        });
        const tableData = {
            header,
            rows: formattedTableDataToDisplay,
        };
        const modalData: IModalData = {
            modalName: '',
            noHeader: true,
            modalIcon: null,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: RequestDetailsModalComponent,
                        payload: {
                            data: {
                                stepData: {
                                    'Form Details': tableData,
                                },
                                noAccordion: true,
                                widgetRef: this.widgetRef,
                                isShowTableData: true,
                            },
                        },
                    },
                },
            ],
        };
        this.modalService.openModal(modalData);
    }

    private openEditRequestModal(formPreSavedData) {
        const modalData: IModalData = {
            modalName:
                this.widgetRef.widgetData.widgetInfo &&
                this.widgetRef.widgetData.widgetInfo.form &&
                this.widgetRef.widgetData.widgetInfo.form.formName
                    ? this.widgetRef.widgetData.widgetInfo.form.formName
                    : 'Request Form',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 60,
            maxHeightVh: 90,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RequestFormModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                enterDetailsButton:
                                    this.tableInputData.buttons[0],
                                isEdit: true,
                                formPreSavedData,
                            },
                        },
                    },
                    stepName:
                        this.widgetRef.widgetData.widgetInfo &&
                        this.widgetRef.widgetData.widgetInfo.form &&
                        this.widgetRef.widgetData.widgetInfo.form.formName
                            ? this.widgetRef.widgetData.widgetInfo.form.formName
                            : 'Request Form',
                },
            ],
            extraClass: 'modal-height-auto-overflow',
        };

        this.modalService.openModal(modalData);
    }
}

export interface RequestDetailWidgetResponse {
    statusColor: {};
    'Form ID': string;
    Username: string;
    'Email Address': string;
    'Date and Time': string;
    Status: string;
    'Form Details': [];
}
