import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Helper } from '../../classes/Helper';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.sass']
})
export class ColorPickerComponent implements OnInit, OnDestroy {
    @Input() color: string;
    @Output() colorCode: EventEmitter<string> = new EventEmitter<string>();

    public show = false;
    public defaultColors: string[] = [
        '#000105',
        '#3e6158',
        '#3f7a89',
        '#96c582',
        '#b7d5c4',
        '#bcd6e7',
        '#7c90c1',
        '#9d8594',
        '#dad0d8',
        '#4b4fce',
        '#4e0a77',
        '#a367b5',
        '#ee3e6d',
        '#d63d62',
        '#c6a670',
        '#f46600',
        '#cf0500',
        '#efabbd',
        '#8e0622',
        '#f0b89a',
        '#f0ca68',
        '#62382f',
        '#c97545',
        '#c1800b'
    ];

    constructor() {}

    ngOnInit(): void {
        if (!this.color) {
            this.color = Helper.getRandomFromList(this.defaultColors);
        }
        this.colorCode.emit(this.color);
    }
    /**
     * Change color from default colors
     * @param {string} color
     */
    public changeColor(color: string): void {
        this.color = color;
        this.colorCode.emit(this.color);
        this.show = false;
    }

    /**
     * Change status of visibility to color picker
     */
    public toggleColors(): void {
        this.show = !this.show;
    }

    ngOnDestroy(): void {
        this.show = false;
    }
}
