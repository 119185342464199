<app-modal-info-template [infoData]="infoData"> </app-modal-info-template>

<div class="radio-container">
    <section>
        <div class="section-content">
            <ng-container *ngFor="let filter of labels.filters">
                <app-field-generator
                    *ngIf="filtersData.has(filter)"
                    [enableTemp]="true"
                    [filterStoreKey]="filterStoreKey"
                    [lightMode]="true"
                    [filterData]="filtersData.get(filter)"
                    [customSelectedValue]="selectedValue.get(filter)"
                ></app-field-generator>
            </ng-container>
        </div>
    </section>
</div>
<div class="form-controls button-container">
    <app-multi-button-generator
        [buttonGenInputs]="buttonGenInput"
    ></app-multi-button-generator>
</div>
