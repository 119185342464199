<mat-sidenav-container
    [autosize]="true"
    class="create-view-container tw-flex tw-w-full tw-h-full"
>
    <mat-sidenav
        class="sidenav"
        #sidenav
        [opened]="true"
        [mode]="(breakpointForMenuOverlay | async) ? 'over' : 'side'"
    >
        <div class="side-nav-container tw-h-full">
            <div
                class="detailed-side-nav-container"
                [class.centilyticsSideNav]="
                    whiteLabelingService.whiteLableData.isCentilytics
                "
            >
                <div class="menu-top">
                    <div class="logo-container">
                        <div class="logo" appIcon [data]="companyLogo"></div>
                        <div
                            class="menu-toggle clickable"
                            appIcon
                            [data]="
                                sidenav.opened
                                    ? toggleCloseIcon
                                    : toggleOpenIcon
                            "
                            (click)="sidenav.toggle()"
                        ></div>
                    </div>
                    <div class="actions">
                        <div
                            class="back-icon"
                            (click)="goToParentNode()"
                            *ngIf="
                                menuParentNode &&
                                menuParentNode.parentNodeId !== null
                            "
                            appIcon
                            [data]="backArrow"
                        ></div>
                        <app-button-generator
                            [buttonGenInput]="addModuleButtonGenInput"
                            [disabled]="
                                tree.getLevelOfNode(menuParentNode) >
                                GlobalConfiguration.MAX_LAYERS_LIMIT
                            "
                            [hoverText]="
                                tree.getLevelOfNode(menuParentNode) >
                                GlobalConfiguration.MAX_LAYERS_LIMIT
                                    ? 'Maximum layers created'
                                    : null
                            "
                        ></app-button-generator>
                    </div>
                </div>
                <div class="menu-content">
                    <!-- <div
                            class="menu-heading"
                            *ngIf="
                                menuParentNode &&
                                menuParentNode.parentNodeId !== null &&
                                menuParentNode.data
                            "
                        >
                            {{ menuParentNode.data.attributeName }}
                        </div> -->
                    <div
                        class="menu-items"
                        cdkDropList
                        (cdkDropListDropped)="moduleDropped($event)"
                        *ngIf="menuParentNode && menuParentNode.children"
                    >
                        <div
                            class="menu-item"
                            cdkDrag
                            cdkDragBoundary=".menu-items"
                            cdkDragLockAxis="y"
                            *ngFor="let node of menuParentNode.children"
                        >
                            <div *cdkDragPreview></div>
                            <div
                                class="icon {{
                                    tree.getLevelOfNode(menuParentNode) === 1
                                        ? 'background-circle'
                                        : ''
                                }}"
                                appIcon
                                [data]="
                                    node.data.iconType
                                        | attributeToIconDirective
                                            : node.data.icon
                                            : (tree.getLevelOfNode(
                                                  menuParentNode
                                              ) === 1
                                                  ? 'svg-black-fill menu-icon'
                                                  : 'menu-icon')
                                "
                            ></div>
                            <div class="name">
                                <div
                                    class="edit-icon tw-absolute tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-top-1 tw-left-8 tw-text-accent tw-bg-common"
                                    (click)="moduleDetailsUpdate(node)"
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </div>
                                {{ node.data.attributeName }}
                            </div>
                            <div
                                class="actions"
                                id="{{ node.nodeId }}"
                                #actions
                            >
                                <div
                                    class="icon"
                                    appIcon
                                    [data]="folderIcon"
                                    (click)="diveInNode(node)"
                                ></div>
                                <div class="icon-group">
                                    <div
                                        class="icon"
                                        appIcon
                                        [data]="trashIcon"
                                        (click)="deleteModule(node)"
                                    ></div>
                                    <div
                                        cdkDragHandle
                                        class="icon"
                                        appIcon
                                        [data]="moveIcon"
                                    ></div>
                                </div>
                                <div
                                    class="icon icon-group-trigger"
                                    appIcon
                                    [data]="menuDots"
                                    (click)="actions.classList.add('triggered')"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="back-to-main-menu"
                    *ngIf="
                        menuParentNode &&
                        menuParentNode.parentNodeId &&
                        menuParentNode.parentNodeId !== rootNode.nodeId
                    "
                    (click)="returnToMainMenu()"
                >
                    Return To Main Menu
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="tw-w-full">
        <div class="action-acl-container tw-flex-1 tw-flex tw-flex-col">
            <div
                class="action-header tw-bg-common tw-flex tw-justify-between tw-items-center tw-py-2 tw-px-3"
            >
                <div class="widget-btn-container tw-flex tw-items-center">
                    <div
                        class="menu-toggle clickable tw-h-6 tw-w-6 tw-mr-6"
                        appIcon
                        *ngIf="!sidenav.opened"
                        [data]="
                            sidenav.opened ? toggleCloseIcon : toggleOpenIcon
                        "
                        (click)="sidenav.toggle()"
                    ></div>
                </div>

                <div
                    class="view-name tw-text-fs-600 tw-font-semibold tw-flex tw-items-center tw-justify-center"
                >
                    <div class="icon-container tw-relative">
                        <div
                            class="edit-icon tw-absolute tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-text-fs-400 tw-text-accent tw-bg-common tw-right-0 tw--top-3"
                            (click)="updateViewInfo()"
                        >
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                        <div
                            class="view-icon tw-mr-3 tw-flex tw-justify-center tw-items-center tw-text-fs-800 tw-overflow-hidden"
                            appIcon
                            [data]="viewData.viewIcon"
                        ></div>
                    </div>

                    <div
                        class="tw-whitespace-pre tw-overflow-hidden tw-overflow-ellipsis"
                    >
                        <span> {{ viewData.viewName }}</span>
                    </div>
                </div>

                <div
                    class="tw-flex tw-justify-end tw-items-center tw-font-semibold tw-text-fs-400 hide-on-mobile"
                >
                    <span
                        (click)="returnToConsole()"
                        class="tw-mr-4 tw-whitespace-pre clickable"
                        >Return To Console</span
                    >
                    <span
                        class="tw-flex tw-items-center tw-pl-4 tw-border-l-2 tw-border-solid tw-border-black"
                    >
                        <div
                            (click)="saveView()"
                            class="tw-mr-3 tw-h-7 tw-w-7 clickable"
                            appIcon
                            [data]="saveDisk"
                        ></div>
                        <span class="clickable" (click)="saveView()">Save</span>
                    </span>
                </div>

                <div class="general-actions-menu show-on-mobile">
                    <div
                        class="icon tw-relative tw-h-6 tw-w-6 clickable"
                        appIcon
                        [data]="menuDots"
                        [matMenuTriggerFor]="generalActionsMenu"
                    ></div>
                    <mat-menu #generalActionsMenu>
                        <div class="more-info-menu">
                            <div class="info-item" (click)="saveView()">
                                <div
                                    class="icon clickable"
                                    appIcon
                                    [data]="saveDisk"
                                ></div>
                                Save
                            </div>
                            <div class="info-item" (click)="returnToConsole()">
                                <div
                                    class="icon"
                                    appIcon
                                    [data]="exitArrow"
                                ></div>
                                Return To Console
                            </div>
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="acl-data">
                <div
                    class="top-info tw-flex tw-items-center tw-justify-between tw-mt-6 tw-py-0 tw-px-4"
                    *ngIf="
                        rootNode.nodeId !== menuParentNode.nodeId &&
                        menuParentNode.data.attributeName
                    "
                >
                    <div class="module-info">
                        <span
                            class="tw-overflow-hidden tw-whitespace-pre tw-overflow-ellipsis tw-py-4 tw-px-0"
                            *ngIf="rootNode.nodeId !== menuParentNode.nodeId"
                            [matTooltip]="menuParentNode.data.attributeName"
                        >
                            {{ menuParentNode.data.attributeName }}
                        </span>
                    </div>
                </div>
                <div class="selection-actions">
                    <div class="acl-selection">
                        <app-form-generator
                            class="tw-w-full"
                            *ngIf="aclFormGenInput"
                            [formGeneratorInput]="aclFormGenInput"
                            (formGroupRef)="aclFormGroup = $event"
                            (valuesChange)="aclUpdate()"
                        ></app-form-generator>
                    </div>
                    <app-button-generator
                        class="tw-ml-4"
                        [buttonGenInput]="selectWidgetButtonGenInput"
                        [hoverText]="
                            this.menuParentNode.children.length ||
                            this.menuParentNode.nodeId === this.rootNode.nodeId
                                ? 'Widgets can only be added to non-root module without children'
                                : null
                        "
                        [disabled]="
                            this.menuParentNode.children.length ||
                            this.menuParentNode.nodeId === this.rootNode.nodeId
                        "
                    ></app-button-generator>
                    <app-button-generator
                        class="tw-ml-1"
                        [buttonGenInput]="groupWidgetButtonGenInput"
                        [hoverText]="
                            widgetSelected.length <= 1
                                ? 'Select widgets to enable this feature.'
                                : null
                        "
                        [disabled]="widgetSelected.length <= 1"
                        (buttonRef)="
                            widgetSelected.length > 1 && groupSelectedWidgets()
                        "
                    ></app-button-generator>
                </div>
                <div
                    #widgetListingRef
                    (window:resize)="onResizeListing()"
                    class="widget-listing"
                    cdkDropList
                    (cdkDropListDropped)="widgetDropped($event)"
                >
                    <ng-container
                        *ngIf="
                            !menuParentNode.children.length &&
                            menuParentNode.data.widgets &&
                            menuParentNode.data.widgets.length
                        "
                    >
                        <ng-container
                            *ngFor="
                                let widget of menuParentNode.data.widgets;
                                let i = index
                            "
                        >
                            <ng-container
                                *ngIf="
                                    widget[GROUP_ID_KEY] &&
                                        widget[GROUP_WIDGETS_KEY] &&
                                        widget[GROUP_WIDGETS_KEY].length;
                                    else widgetBlock
                                "
                            >
                                <div
                                    cdkDrag
                                    cdkDragBoundary=".widget-listing"
                                    cdkDragLockAxis="y"
                                    class="widget grouped-widget"
                                >
                                    <div *cdkDragPreview></div>
                                    <div class="select-widget">
                                        <mat-checkbox
                                            (change)="
                                                selectWidgetForGrouping(
                                                    $event,
                                                    widget,
                                                    i,
                                                    true
                                                )
                                            "
                                            [checked]="
                                                widgetSelectedMap.has(
                                                    widget[GROUP_ID_KEY]
                                                )
                                            "
                                        >
                                        </mat-checkbox>
                                    </div>
                                    <div class="action">
                                        <div
                                            cdkDragHandle
                                            class="icon clickable"
                                            appIcon
                                            [data]="moveIcon"
                                        ></div>
                                    </div>
                                    <div class="grouped-widgets-column">
                                        <mat-tab-group
                                            [class.hide-pagination]="
                                                !showPaginations
                                            "
                                            [selectedIndex]="
                                                widgetIndexSelectedInGroup.has(
                                                    widget[GROUP_ID_KEY]
                                                )
                                                    ? widgetIndexSelectedInGroup.get(
                                                          widget[GROUP_ID_KEY]
                                                      )
                                                    : 0
                                            "
                                            (selectedIndexChange)="
                                                onSelectedGroupIndexChanged(
                                                    $event,
                                                    widget
                                                )
                                            "
                                        >
                                            <mat-tab
                                                *ngFor="
                                                    let groupWidget of widget[
                                                        GROUP_WIDGETS_KEY
                                                    ];
                                                    let tabIndex = index
                                                "
                                            >
                                                <ng-template matTabLabel>
                                                    <div
                                                        [id]="
                                                            widget.groupId +
                                                            TAB_JOIN_STRING +
                                                            tabIndex
                                                        "
                                                        cdkDropList
                                                        cdkDragRootElement=".mat-tab-label"
                                                        cdkDropListOrientation="horizontal"
                                                        (cdkDropListDropped)="
                                                            widgetDroppedInGroup(
                                                                $event,
                                                                widget
                                                            )
                                                        "
                                                        [cdkDropListConnectedTo]="
                                                            getAllDropListConnections(
                                                                tabIndex,
                                                                widget
                                                            )
                                                        "
                                                    >
                                                        <div
                                                            cdkDrag
                                                            class="ellipsis-text"
                                                            [matTooltip]="
                                                                groupWidget.name
                                                            "
                                                        >
                                                            {{
                                                                groupWidget.name
                                                            }}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-container
                                                    [ngTemplateOutlet]="
                                                        groupedWidgets
                                                    "
                                                    [ngTemplateOutletContext]="{
                                                        widget: groupWidget
                                                    }"
                                                ></ng-container>
                                            </mat-tab>
                                        </mat-tab-group>
                                        <ng-template
                                            let-widget="widget"
                                            #groupedWidgets
                                        >
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    permissionTemplate
                                                "
                                                [ngTemplateOutletContext]="{
                                                    widget: widget
                                                }"
                                            ></ng-container>
                                        </ng-template>
                                    </div>
                                    <div
                                        class="delete-grouped-widget"
                                        (click)="deleteGroupedWidget(widget, i)"
                                        matTooltip="Delete widget"
                                    >
                                        <div
                                            class="icon clickable"
                                            appIcon
                                            [data]="trashIconWarn"
                                        ></div>
                                    </div>
                                    <div
                                        class="delete-widget"
                                        (click)="
                                            deleteWidgetGroup(
                                                widget[GROUP_ID_KEY]
                                            )
                                        "
                                        matTooltip="Delete widget group"
                                    >
                                        <div
                                            class="icon clickable"
                                            appIcon
                                            [data]="trashIconWarn"
                                        ></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #widgetBlock>
                                <div
                                    cdkDrag
                                    cdkDragBoundary=".widget-listing"
                                    cdkDragLockAxis="y"
                                    class="widget"
                                >
                                    <div *cdkDragPreview></div>
                                    <div class="select-widget">
                                        <mat-checkbox
                                            (change)="
                                                selectWidgetForGrouping(
                                                    $event,
                                                    widget,
                                                    i
                                                )
                                            "
                                            [checked]="
                                                widgetSelectedMap.has(widget.id)
                                            "
                                            [disabled]="
                                                nonGroupablePortlets.has(
                                                    widget[PORTLET_TYPE_KEY]
                                                )
                                            "
                                            [matTooltip]="
                                                nonGroupablePortlets.has(
                                                    widget[PORTLET_TYPE_KEY]
                                                )
                                                    ? 'This widget cannot be grouped.'
                                                    : null
                                            "
                                        >
                                        </mat-checkbox>
                                    </div>
                                    <div class="action">
                                        <div
                                            cdkDragHandle
                                            class="icon clickable"
                                            appIcon
                                            [data]="moveIcon"
                                        ></div>
                                    </div>
                                    <div
                                        [matTooltip]="widget.name"
                                        class="widget-name"
                                    >
                                        {{ widget.name }}
                                    </div>
                                    <ng-container
                                        [ngTemplateOutlet]="permissionTemplate"
                                        [ngTemplateOutletContext]="{
                                            widget: widget
                                        }"
                                    ></ng-container>
                                    <div
                                        class="delete-widget"
                                        (click)="deleteWidget(widget)"
                                        matTooltip="Delete widget"
                                    >
                                        <div
                                            class="icon clickable"
                                            appIcon
                                            [data]="trashIconWarn"
                                        ></div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template
                                let-widget="widget"
                                #permissionTemplate
                            >
                                <div class="widget-permissions hide-on-mobile">
                                    <mat-checkbox
                                        class="no-disabled-style"
                                        (click)="
                                            changeWidgetPermission(
                                                widget,
                                                'READ'
                                            )
                                        "
                                        [checked]="widget.permission === 'READ'"
                                        [disabled]="
                                            widget.permission === 'READ'
                                        "
                                        >Read</mat-checkbox
                                    >
                                    <mat-checkbox
                                        class="no-disabled-style"
                                        (click)="
                                            changeWidgetPermission(
                                                widget,
                                                'READ_WRITE'
                                            )
                                        "
                                        [checked]="
                                            widget.permission === 'READ_WRITE'
                                        "
                                        [disabled]="
                                            widget.permission === 'READ_WRITE'
                                        "
                                        >Read / Write</mat-checkbox
                                    >
                                    <mat-checkbox
                                        class="no-disabled-style"
                                        (click)="
                                            changeWidgetPermission(
                                                widget,
                                                'DENY'
                                            )
                                        "
                                        [checked]="widget.permission === 'DENY'"
                                        [disabled]="
                                            widget.permission === 'DENY'
                                        "
                                        >Deny</mat-checkbox
                                    >
                                </div>
                                <div
                                    class="widget-permission-menu show-on-mobile"
                                >
                                    <div
                                        class="tw-h-6 tw-w-6 clickable"
                                        appIcon
                                        [data]="menuDots"
                                        [matMenuTriggerFor]="permissionMenu"
                                    ></div>
                                    <mat-menu #permissionMenu="matMenu">
                                        <div class="more-info-menu">
                                            <div
                                                class="info-item"
                                                (click)="
                                                    changeWidgetPermission(
                                                        widget,
                                                        'READ'
                                                    )
                                                "
                                            >
                                                <mat-checkbox
                                                    class="no-disabled-style"
                                                    [checked]="
                                                        widget.permission ===
                                                        'READ'
                                                    "
                                                    [disabled]="
                                                        widget.permission ===
                                                        'READ'
                                                    "
                                                    >Read</mat-checkbox
                                                >
                                            </div>
                                            <div
                                                class="info-item"
                                                (click)="
                                                    changeWidgetPermission(
                                                        widget,
                                                        'READ_WRITE'
                                                    )
                                                "
                                            >
                                                <mat-checkbox
                                                    class="no-disabled-style"
                                                    [checked]="
                                                        widget.permission ===
                                                        'READ_WRITE'
                                                    "
                                                    [disabled]="
                                                        widget.permission ===
                                                        'READ_WRITE'
                                                    "
                                                    >Read / Write</mat-checkbox
                                                >
                                            </div>
                                            <div
                                                class="info-item"
                                                (click)="
                                                    changeWidgetPermission(
                                                        widget,
                                                        'DENY'
                                                    )
                                                "
                                            >
                                                <mat-checkbox
                                                    class="no-disabled-style"
                                                    [checked]="
                                                        widget.permission ===
                                                        'DENY'
                                                    "
                                                    [disabled]="
                                                        widget.permission ===
                                                        'DENY'
                                                    "
                                                    >Deny</mat-checkbox
                                                >
                                            </div>
                                        </div>
                                    </mat-menu>
                                </div>
                            </ng-template>
                            <ng-container
                                *ngIf="
                                    (
                                        menuParentNode.data.widgets.length
                                        | widgetsPageSeparation
                                    ).includes(i)
                                "
                            >
                                <span class="page-separation">
                                    <span>
                                        Page
                                        {{
                                            (
                                                menuParentNode.data.widgets
                                                    .length
                                                | widgetsPageSeparation
                                            ).indexOf(i) + 1
                                        }}
                                    </span>
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
