import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ApiResponseCacheService } from '../../cache/api-response-cache/api-response-cache.service';
import { IotService } from '../../iot/iot.service';
import { HttpService } from '../http-main/http.service';

@Injectable({
    providedIn: 'root'
})
export class FiltersHttpService extends HttpService {
    constructor(
        http: HttpClient,
        iotService: IotService,
        apiResponseCacheService: ApiResponseCacheService,
        ngZone: NgZone
    ) {
        super(http, iotService, apiResponseCacheService, ngZone);
    }

    getFilterInfo(filterId: string): BehaviorSubject<IFilterInfo> {
        const resObs = new BehaviorSubject<IFilterInfo>(null);
        const hitApi: IHitApi = {
            function: (response) => resObs.next(response),
            url: `${ApiUrls.FILTER_INFO_API_PATH}${filterId}`,
            intactUrl: `${ApiUrls.FILTER_INFO_API_PATH}{filterId}`,
            input: {},
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                defaultHeaders: false
            },
            uniqueIdentity: Symbol()
        };
        new HitApi(hitApi, this, this.ngZone).hitApi();
        return resObs;
    }
}
