<div class="filters-container">
    <div class="tabs" *ngIf="sortedFiltersIds && sortedFiltersIds.length">
        <div class="tab-header">
            <div
                matTooltip="Toggle Filter"
                appIcon
                [data]="filterFilledIcon"
                (click)="filterMenu.toggle()"
                *ngIf="isCompareView"
                class="filter-icon clickable"
            ></div>
            <div class="tab-header-item">FILTERS</div>
            <div class="top-reset clickable" (click)="resetFilters()">
                Reset
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-content-item tab-content-item-1">
                <app-form-generator
                    *ngIf="
                        formGenInputs &&
                        filtersService.filtersPageData.getValue() &&
                        filtersService.filtersPageData
                            .getValue()
                            .get(filterStoreKey)
                    "
                    [filterData]="
                        filtersService.filtersPageData
                            .getValue()
                            .get(filterStoreKey)
                    "
                    [formGeneratorInput]="formGenInputs"
                    (formGroupRef)="getFormRef($event)"
                    [filterStoreKey]="filterStoreKey"
                    (resetFormFunction)="resetFilterValue = $event"
                    (updateControl)="updateControl = $event"
                ></app-form-generator>
            </div>
        </div>
    </div>
    <div
        class="buttons"
        *ngIf="
            (filtersService.filtersPageData | async) &&
            (filtersService.filtersPageData | async).get(filterStoreKey) &&
            (filtersService.filtersPageData | async).get(filterStoreKey).size
        "
    >
        <button
            mat-stroked-button
            class="btn-primary-stroked"
            (click)="applyFilters()"
        >
            Apply
        </button>
        <button
            mat-stroked-button
            class="btn-primary-stroked"
            (click)="resetAllToDefault()"
        >
            Set Default
        </button>
    </div>

    <div
        class="no-filters"
        *ngIf="sortedFiltersIds && !sortedFiltersIds.length"
    >
        {{ Messages.NO_FILTERS }}
    </div>
    <div class="overlay" *ngIf="!hideLoader">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
</div>
