import { Component, OnInit, ViewChild } from '@angular/core';
import {
    GridOptions,
    RowEvent,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import moment from 'moment';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ApiResponseCacheService } from 'src/app/shared/services/cache/api-response-cache/api-response-cache.service';
import { AssessmentCacheService } from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { AssessmentMoreInfoModalComponent } from '../../../modal-templates/assessee-view/assessment-more-info-modal/assessment-more-info-modal.component';
import { ReportType } from '../../../modal-templates/assessee-view/assessment-overview/assessment-overview.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-assessment-assessee',
    templateUrl: './assessment-assessee.component.html',
    styleUrls: ['./assessment-assessee.component.sass']
})
export class AssessmentAssesseeComponent implements OnInit {
    widgetRef: Widget;
    agGrid: GridOptions = null;
    tableGenInput: ITableGeneratorInput;
    rowData;
    tableDropdownButtons: IButtonGeneratorInput[];
    @ViewChild('downloadMenu') downloadMenu;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private globalDataService: GlobalDataService,
        private apiResponseCacheService: ApiResponseCacheService,
        private widgetCreationService: WidgetCreationService,
        private userDataCacheService: UserDataCacheService,
        private assessmentCacheService: AssessmentCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit() {
        this.setUpBasics();
        this.tableDropdownButtons = [
            {
                buttonName: 'Detailed Report',
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    const rowEntityData = this.rowData.data;
                    if (!rowEntityData.canDownloadDetailedReport) {
                        this.notificationService.showSnackBar(
                            "You don't have permission to download detailed report",
                            true
                        );
                        return;
                    }
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        rowEntityData.assessmentId,
                        rowEntityData.assessmentName,
                        ReportType.DETAILED_REPORT,
                        () => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                        },
                        (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        }
                    );
                },
                disable:
                    this.rowData?.data['disableAllActions'] ||
                    this.rowData?.data['canDownloadDetailedReport']
            },
            {
                buttonName: 'Summary Report',
                buttonColorType: ButtonColorType.DEFAULT,
                buttonType: ButtonType.TEXT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    const rowEntityData = this.rowData.data;
                    if (
                        rowEntityData &&
                        !rowEntityData.canDownloadSummaryReport
                    ) {
                        this.notificationService.showSnackBar(
                            "You don't have permission to download summary report",
                            true
                        );
                        return;
                    }
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        rowEntityData.assessmentId,
                        rowEntityData.assessmentName,
                        ReportType.SUMMARY_REPORT,
                        () => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                        },
                        (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            this.widgetRef.changeDetectorRef.detectChanges();
                        }
                    );
                },
                disable:
                    this.rowData?.data['disableAllActions'] ||
                    this.rowData?.data['canDownloadSummaryReport']
            }
        ];
    }

    setUpBasics() {
        this.tableGenInput = {
            showViewIcon: true,
            columns: [
                {
                    columnName: 'Assessment Name',
                    columnKey: 'assessmentName'
                },
                {
                    columnName: 'Assessment Status',
                    columnKey: 'assessmentStatus'
                },
                {
                    columnName: 'Date Of Assessment',
                    columnKey: 'auditDueDate',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData.data.auditDueDate !== '-') {
                            let date = rowData.data.auditDueDate;
                            const timeValue =
                                rowData.data.auditDueDate.includes(';')
                                    ? rowData.data.auditDueDate
                                          .split('; ')[1]
                                          .split(' ')[0]
                                    : rowData.data.auditDueDate.split(' ')[1];
                            const timeValueUpdate = moment(
                                timeValue,
                                'HH:mm'
                            ).format('hh:mm');
                            date = date.replace(timeValue, timeValueUpdate);
                            return date;
                        } else {
                            return rowData.data.auditDueDate;
                        }
                    }
                },
                {
                    columnName: 'Assigned To',
                    columnKey: 'userName'
                },
                {
                    columnName: 'Associated Users',
                    columnKey: 'assesseUserInfo',
                    columnValueGetter: (params: ValueGetterParams) => {
                        if (
                            params.data['moreInfo'] &&
                            params.data['moreInfo']['assesseUserInfo'] &&
                            params.data['moreInfo']['assesseUserInfo'].length >
                                0
                        ) {
                            return params.data['moreInfo'][
                                'assesseUserInfo'
                            ].map((item) => item.userName);
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Assessment Tags',
                    columnKey: 'tags',
                    cellRenderer: (rowData) => {
                        if (rowData.data.tags && rowData.data.tags.length) {
                            return rowData.data.tags
                                .map(
                                    (tag) =>
                                        `&nbsp;<span style="color:${
                                            tag.color || tag.colorCode
                                        }" >${tag.label || tag.name}</span>`
                                )
                                .join(', ');
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Job Id',
                    columnKey: 'jobId',
                    cellRenderer: (rowData) => {
                        if (rowData?.value) {
                            return rowData?.value;
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    minWidth: 250,
                    filter: false,
                    pinned: 'right',
                    buttonGen: true,
                    headerClass: 'header-center',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'View Details',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.moreInfoModal(
                                        Helper.cloneDeep(rowData.data)
                                    );
                                },
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                showLoader: false
                            },
                            {
                                buttonName: 'Attempt',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                disable:
                                    rowData?.data['disableAllActions'] ||
                                    !rowData.data['canEvaluate'],
                                customClass:
                                    rowData.data['disableAllActions'] ||
                                    !rowData.data['canEvaluate']
                                        ? 'decrease-opacity'
                                        : 'opacity-1',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (rowData.data['canEvaluate']) {
                                        this.evaluateAssessment(
                                            rowData.data['assessmentId'],
                                            buttonRef
                                        );
                                    } else {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Evaluation can not be conducted.',
                                            true
                                        );
                                    }
                                }
                            },
                            {
                                buttonName: 'Download',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                matMenu: this.downloadMenu,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    this.rowData = rowData;
                                },
                                disable: rowData?.data['disableAllActions'],
                                customClass: rowData.data['disableAllActions']
                                    ? 'decrease-opacity'
                                    : 'opacity-1'
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'single',
            widgetIconData: null,
            buttons: []
        };
    }

    moreInfoModal(showInfoData) {
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'View Details',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noStepPadding: true,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: AssessmentMoreInfoModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                showInfoData: showInfoData,
                                assessee: true
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    evaluateAssessment(id, buttonRef: IButtonGeneratorInput) {
        this.globalDataService.fullPageLoader.next(true);
        buttonRef.loader = true;
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSMENT_EVALUATE_API,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.assessmentCacheService.defaultViewId =
                    this.globalDataService.selectedView;
                this.assessmentCacheService.widgetId =
                    this.widgetRef.widgetData.widgetId;
                this.assessmentCacheService.assessmentId = id;
                this.userDataCacheService.isAssesseeView = true;
                this.userDataCacheService.isAssessorView = false;
                this.assessmentCacheService.assessmentAuditType =
                    data['auditType'];
                this.apiResponseCacheService.deleteCollection();
                this.credentialsCacheService.masterLoginDiveIn(data);
                this.sessionService.login(
                    data,
                    this.viewHttpService,
                    true,
                    () => {
                        this.widgetRef.notificationsService.showSnackBar(
                            Messages.LOGIN_API_INVALID_RESPONSE,
                            true
                        );
                        this.credentialsCacheService.masterLoginDiveOut();
                    },
                    () => {
                        this.userDataCacheService.isAssesseeView = true;
                    }
                );
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(this.notificationService, error);
                this.widgetRef.refreshWidget();
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{widget-id}',
            this.widgetRef.widgetData.widgetId
        );
        apiArgs.url = apiArgs.url.replace('{assessment-id}', id);
        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
