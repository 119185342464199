import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-advanced-automation-modal-scheduler',
    templateUrl: './advanced-automation-modal-scheduler.component.html',
    styleUrls: ['./advanced-automation-modal-scheduler.component.sass']
})
export class AdvancedAutomationModalSchedulerComponent implements OnInit {
    data;
    cardData;
    widgetRef: Widget;
    appendKeyDropdownInfo: IFilterInfo = {};
    frequencyDropdownInfo: IFilterInfo = {};
    daysOfWeekDropdownInfo: IFilterInfo = {};
    daysOfMonthDropdownInfo: IFilterInfo = {};
    timezoneDropdownInfo: IFilterInfo = {};
    selectedFrequency = 'hourly';
    selectedAppendKey = '/';
    hours = 0;
    minutes = 0;
    selectedDaysOfWeek: Array<string> = [];
    endDate: string;
    selectedDayOfmonth;
    currentStepData;
    firstStepData = null;
    secondStepData = null;
    showAppendKey = false;
    dataList;
    edit: boolean = false;

    selectedkeysToReplicate;
    frequencyFormGenInput: IFormGeneratorInput = null;
    frequencyFormGroup: FormGroup = null;

    dateFormGenInput: IFormGeneratorInput = null;
    dateFormGroup: FormGroup = null;

    timeFormGenInput: IFormGeneratorInput = null;
    timeFormGroup: FormGroup = null;
    previousStartDate: string;

    hourlyFormGenInput: IFormGeneratorInput = null;
    hourlyFormGroup: FormGroup = null;

    dailyFormGenInput: IFormGeneratorInput = null;
    dailyFormGroup: FormGroup = null;

    weeklyFormGenInput: IFormGeneratorInput = null;
    weeklyFormGroup: FormGroup = null;

    monthlyFormGenInput: IFormGeneratorInput = null;
    monthlyFormGroup: FormGroup = null;

    appendKeyFormGenInput: IFormGeneratorInput = null;
    appendKeyFormGroup: FormGroup = null;

    keyToReplicateFormGenInput: IFormGeneratorInput = null;
    keyToReplicateFormGroup: FormGroup = null;
    timeZoneFormGenInput: IFormGeneratorInput = null;
    timeZoneFormGroup: FormGroup = null;
    timeZoneValue: string;

    formGroup: FormGroup;
    buttonGenInput: IButtonGeneratorInput[];
    buttonRef: IButtonGeneratorInput;
    constructor(
        private multiStepFormService: MultiStepFormService,
        private modalData: ModalInjectedData,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private filtersService: FiltersService,
        private listHttpService: ListHttpService
    ) {}

    ngOnInit(): void {
        this.data = this.modalData.data;
        let cronExpression = null;
        let endDate = null;
        let startDate = null;
        let scheduleTime = null;
        if (this.data.purpose === ModalAction.UPDATE) {
            this.edit = true;
            this.cardData = this.data.cardData;
            cronExpression = this.cardData['cronExpression'];
            endDate = this.cardData['automationFilter']['endDateForSchedular'];
            startDate =
                this.cardData['automationFilter']['startDateForSchedular'];
            scheduleTime = this.cardData['automationFilter']['scheduleTime'];
            if (scheduleTime) {
                scheduleTime = Helper.formatDate(scheduleTime, 'hh:mm a');
            }
            this.selectedAppendKey =
                this.cardData['automationFilter']['appendWith'];
            this.selectedkeysToReplicate =
                this.cardData['automationFilter']['keysToReplicate'];
            this.timeZoneValue =
                this.cardData['automationFilter']['timeZoneId'];
        }
        this.widgetRef = this.data.widgetRef;
        this.firstStepData = this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .get(1);
        this.secondStepData = this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .get(2);
        this.showAppendKey = this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .get(2).showAppendKey;
        this.dataList = this.multiStepFormService.stepData
            .get(this.modalData.modalId)
            .get(3);

        let frequency = '';
        const cronParts = cronExpression ? cronExpression.split(' ') : null;

        // Hourly: 0 0 0/23 * * ? *
        let hour = 0;
        let minutes = 0;

        // Daily: 0 20 12 1/20 * ? *
        let dailyDay = null;

        // Weekly: 0 15 10 ? * 1,2 *
        let weeklyDays = [];

        // Monthly: 0 30 11 1,2,3,4,29,30 1/9 ? *
        let monthlyDays = [];
        let monthlyMonth = null;

        if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[3] === '*' &&
            cronParts[4] === '*' &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            frequency = 'hourly';
            hour = +cronParts[2].split('/')[1];
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[4] === '*' &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            frequency = 'daily';
            dailyDay = +cronParts[3].split('/')[1];
            hour = +cronParts[2];
            minutes = +cronParts[1];
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[3] === '?' &&
            cronParts[4] === '*' &&
            cronParts[6] === '*'
        ) {
            frequency = 'weekly';
            weeklyDays = cronParts[5].split(',');
            hour = +cronParts[2];
            minutes = +cronParts[1];
        } else if (
            cronParts &&
            +cronParts[0] === 0 &&
            cronParts[5] === '?' &&
            cronParts[6] === '*'
        ) {
            frequency = 'monthly';
            monthlyDays = cronParts[3].split(',');
            monthlyMonth = +cronParts[4].split('/')[1];
            hour = +cronParts[2];
            minutes = +cronParts[1];
        }

        this.appendKeyFormGenInput = {
            formName: 'Append With',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'appendKey',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'appendKey',
                    placeholder: 'Choose a delimiter to append',
                    value: this.selectedAppendKey
                        ? this.selectedAppendKey
                        : '/',
                    listData: [
                        {
                            id: '/',
                            label: '/'
                        },
                        {
                            id: '|',
                            label: '|'
                        },
                        {
                            id: ',',
                            label: ','
                        },
                        {
                            id: '&',
                            label: '&'
                        }
                    ],
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Delimiter is required'
                        }
                    ]
                }
            ]
        };

        this.keyToReplicateFormGenInput = {
            formName: 'Key to replicate',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'keyToReplicate',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Key to replicate',
                    placeholder: 'Select key to replicate',
                    value: this.selectedkeysToReplicate
                        ? this.selectedkeysToReplicate
                        : [],
                    listData: this.dataList,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key to replicate is required'
                        }
                    ]
                }
            ]
        };
        this.timeZoneFormGenInput = {
            formName: 'Time zone',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'timeZone',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Time Zone',
                    placeholder: 'Select Timezone',
                    value: this.timeZoneValue ? this.timeZoneValue : null,
                    apiInfo: this.listHttpService.timeZoneApiInfo,
                    responseValueKey: 'dataList',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Time Zone is required'
                        }
                    ]
                }
            ]
        };

        this.frequencyFormGenInput = {
            formName: 'Frequency',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'frequency',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Frequency',
                    placeholder: 'Frequency',
                    value: frequency ? frequency : 'hourly',
                    listData: [
                        {
                            id: 'hourly',
                            label: 'Hourly'
                        },
                        {
                            id: 'daily',
                            label: 'Daily'
                        },
                        {
                            id: 'weekly',
                            label: 'Weekly'
                        },
                        {
                            id: 'monthly',
                            label: 'Monthly'
                        }
                    ],
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Frequency is required'
                        }
                    ]
                }
            ]
        };

        const startDateValue = startDate ? startDate : moment().toDate();
        this.dateFormGenInput = {
            formName: 'End Date',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'startDate',
                    fieldType: FilterType.DATE,
                    label: 'Start Date',
                    placeholder: 'Start Date',
                    value: startDateValue,
                    minDate: moment().toDate(),
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Start date is required'
                        }
                    ]
                },
                {
                    name: 'endDate',
                    fieldType: FilterType.DATE,
                    label: 'End Date',
                    placeholder: 'End Date',
                    minDateFromControl: 'startDate',
                    value: endDate ? endDate : moment().add(1, 'day').toDate(),
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'End date is required'
                        }
                    ]
                }
            ]
        };

        this.previousStartDate = startDateValue;
        if (!scheduleTime && this.edit) {
            scheduleTime = Helper.formatDate(
                `${hour}:${minutes}`,
                'hh:mm a',
                'HH:mm'
            );
        }
        this.setTimeFormGenInput(scheduleTime, startDateValue);

        this.hourlyFormGenInput = {
            formName: 'Frequency Hourly',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Hour(s)',
                    value: hour !== undefined ? hour : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Hourly frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(23),
                            errorMessage: 'Maximum value allowed is 23'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                }
            ]
        };

        this.dailyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Day(s)',
                    value: dailyDay ? dailyDay : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Daily frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(31),
                            errorMessage: 'Maximum value allowed is 31'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                }
            ]
        };

        this.weeklyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'day',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Day',
                    placeholder: 'Select day of week',
                    value: weeklyDays ? weeklyDays : [],
                    required: true,
                    listData: [
                        {
                            id: '1',
                            label: 'Sunday'
                        },
                        {
                            id: '2',
                            label: 'Monday'
                        },
                        {
                            id: '3',
                            label: 'Tuesday'
                        },
                        {
                            id: '4',
                            label: 'Wednesday'
                        },
                        {
                            id: '5',
                            label: 'Thursday'
                        },
                        {
                            id: '6',
                            label: 'Friday'
                        },
                        {
                            id: '7',
                            label: 'Saturday'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Day of week is required'
                        }
                    ]
                }
            ]
        };

        this.monthlyFormGenInput = {
            formName: 'Frequency Daily',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'every',
                    fieldType: FilterType.NUMBER,
                    label: 'Every',
                    placeholder: 'Month(s)',
                    value: monthlyMonth ? monthlyMonth : null,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Monthly frequency is required'
                        },
                        {
                            validator: CustomValidators.maxValue(12),
                            errorMessage: 'Maximum value allowed is 12'
                        },
                        {
                            validator: CustomValidators.minValue(1),
                            errorMessage: 'Minimum value allowed is 1'
                        }
                    ]
                },
                {
                    name: 'dayOfMonth',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Day',
                    placeholder: 'Select day of month',
                    value: monthlyDays ? monthlyDays : [],
                    required: true,
                    listData: Array(31)
                        .fill(1)
                        .map((value, index) => {
                            return {
                                id: `${index + 1}`,
                                label: `${index + 1}`
                            };
                        }),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Day of month is required'
                        }
                    ]
                }
            ]
        };
        this.generateButton();
    }
    generateButton() {
        this.buttonGenInput = [
            {
                buttonName: 'Back',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.buttonRef = buttonRef;
                    this.previouStep();
                }
            },
            {
                buttonName: this.edit ? 'Update' : 'Create',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.buttonRef = buttonRef;
                    this.submitStep();
                },
                showLoader: true
            }
        ];
    }
    generateCronExpression() {
        // <seconds> <minutes> <hours> <days of month> <months> <days of week> <years>
        const sec = '0'; // ignoring seconds by default
        let min = '0'; // * means every minute, 0/4 means every 4 minutes
        let hour = '0';
        const year = '*'; // every year by default
        let dow = '?';
        let month = '*';
        let dom = '*';

        let startDD = '1';
        let startMM = '1';

        const scheduledTime = Helper.formatDate(
            this.timeFormGroup.get('scheduleTime').value,
            'HH:mm',
            'hh:mm a'
        );
        const startDate = Helper.formatDate(
            this.dateFormGroup.get('startDate').value,
            'DD/MM'
        );

        if (typeof scheduledTime === 'string') {
            const splittedTime = scheduledTime.split(':');
            hour = '' + +splittedTime[0];
            min = '' + +splittedTime[1];
        }

        if (startDate && typeof startDate === 'string') {
            const splittedDate = startDate.split('/');
            startDD = '' + +splittedDate[0];
            startMM = '' + +splittedDate[1];
        }

        switch (this.frequencyFormGroup.get('frequency').value) {
            case 'hourly':
                // ever n hour
                if (this.hourlyFormGroup.get('every').value >= 0) {
                    hour = hour + '/' + this.hourlyFormGroup.get('every').value;
                } else {
                    hour = '*';
                }
                break;
            case 'daily':
                if (true) {
                    // every n day
                    const ndom = this.dailyFormGroup.get('every').value;
                    if (ndom >= 1) {
                        dom = startDD + '/' + ndom;
                    }
                }
                break;

            case 'weekly':
                const ndow = this.weeklyFormGroup.get('day').value;
                dow = '*';
                dom = '?';
                if (ndow.length < 7 && ndow.length > 0) {
                    dow = ndow.join(',');
                } else {
                    dow = ndow[0];
                }
                break;

            case 'monthly':
                month = '*';
                const nmonth = this.monthlyFormGroup.get('every').value; // 1 -12
                dom = this.monthlyFormGroup.get('dayOfMonth').value; // 1-31
                dow = '?';

                if (nmonth >= 1) {
                    month = startMM + '/' + nmonth;
                }
                break;

            default:
                break;
        }
        return [sec, min, hour, dom, month, dow, year].join(' ');
    }

    /**
     * This function calculates the rounded time + {threshold} minutes to the floor of minutes
     * Example: If Time: 1:43 PM then output will be
     * Output: 2:00 PM
     * @param roundValue Input for rounded of value
     * @returns return formatted time in 12 hours format
     */
    getRoundedMinutesTime(roundValue: number) {
        const timeMoment = moment();
        const minutesThreshold = 30;
        let currMinutes = timeMoment.minutes();
        if (currMinutes % roundValue !== 0) {
            currMinutes -= currMinutes % roundValue;
            currMinutes += minutesThreshold;
            timeMoment.minutes(currMinutes);
        }
        return timeMoment.format('hh:mm a');
    }

    setTimeFormGenInput(scheduleTime, startDate) {
        let minTime = null;
        const minutesGap = 15;
        const today = Helper.formatDate(null, 'DD/MM/YYYY');
        const dated = Helper.formatDate(startDate, 'DD/MM/YYYY');
        if (today === dated) {
            minTime = this.getRoundedMinutesTime(minutesGap);
        }
        this.timeFormGenInput = {
            formName: 'Time',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'scheduleTime',
                    fieldType: FilterType.TIME,
                    label: 'Schedule Time',
                    placeholder: 'Schedule Time',
                    value: scheduleTime
                        ? scheduleTime
                        : minTime
                        ? minTime
                        : '12:00 AM',
                    minTime: '12:00 AM',
                    timePickerMinutesGap: minutesGap,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Schedule time is required'
                        }
                    ]
                }
            ]
        };

        /**
         * Update form group value for only one condition.
         * form group time < min time
         */
        if (this.timeFormGroup) {
            const currentFormTime =
                this.timeFormGroup.get('scheduleTime').value;
            if (currentFormTime && minTime) {
                const currentTimeInMS = Helper.formatDate(
                    currentFormTime,
                    'unix',
                    'hh:mm a'
                );
                const minTimeInMS = Helper.formatDate(
                    minTime,
                    'unix',
                    'hh:mm a'
                );
                if (currentTimeInMS < minTimeInMS) {
                    this.timeFormGroup.get('scheduleTime').setValue(minTime);
                }
            }
        }
    }

    onDateChange(event) {
        if (event.startDate !== this.previousStartDate) {
            this.setTimeFormGenInput(null, event.startDate);
            this.previousStartDate = event.startDate;
        }
    }

    submitStep() {
        if (this.frequencyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.frequencyFormGroup);
            return;
        }

        if (this.keyToReplicateFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.keyToReplicateFormGroup);
            return;
        }
        const frequency = this.frequencyFormGroup.get('frequency').value;
        if (frequency === 'hourly' && this.hourlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.hourlyFormGroup);
            return;
        } else if (frequency === 'daily' && this.dailyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dailyFormGroup);
            return;
        } else if (frequency === 'weekly' && this.weeklyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.weeklyFormGroup);
            return;
        } else if (frequency === 'monthly' && this.monthlyFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.monthlyFormGroup);
            return;
        }
        if (this.dateFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.dateFormGroup);
            return;
        }
        if (this.timeFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeFormGroup);
            return;
        }
        if (this.timeZoneFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.timeZoneFormGroup);
            return;
        }
        if (this.buttonRef.loader) {
            return;
        }
        this.buttonRef.loader = true;

        let apiConfig: IHitApi;
        if (this.data.purpose === ModalAction.UPDATE) {
            apiConfig = Helper.generateHitApiConfig(
                this.data.widgetRef.widgetData.widgetInfo.update
            );

            apiConfig.intactUrl = apiConfig.url;
            apiConfig.url = apiConfig.url.replace(
                '{automationId}',
                this.cardData['id']
            );
        } else {
            apiConfig = Helper.generateHitApiConfig(
                this.data.widgetRef.widgetData.widgetInfo.create
            );
        }

        apiConfig.function = (response) => {
            if (this.data.purpose === ModalAction.UPDATE) {
                this.notificationService.showSnackBar(
                    'Rule edited successfully'
                );
            } else {
                this.notificationService.showSnackBar(
                    'Rule created successfully'
                );
            }

            this.filtersService.refreshWidget.next(
                new Set([this.modalService.modalData.sourceId])
            );
            this.buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalData.modalId);
        };
        apiConfig.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while creating rule'
            );
            this.modalService.closeModal(null, this.modalData.modalId);
            this.widgetRef.refreshWidget();
            this.buttonRef.loader = false;
        };
        apiConfig.input = this.prepareInput();
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    prepareInput() {
        const filters = [];

        this.firstStepData.firstStepData.widgetFilters.forEach((obj) => {
            filters.push({
                heading: obj.heading,
                filters: {
                    regions: this.firstStepData.widgetFilter[0].filters.regions,
                    accountIds:
                        this.firstStepData.widgetFilter[0].filters.accountIds,
                    resourceTags:
                        this.firstStepData.widgetFilter[0].filters.resourceTags
                }
            });
        });
        const input = {};
        input['automationName'] = this.firstStepData.automationName;
        input['active'] = true;
        input['cronExpression'] = this.generateCronExpression();
        input['widgetFilter'] = filters;
        // input['configurations'] = this.secondStepData.widgetFilters;

        input['automationFilter'] = {};

        input['automationFilter']['appendWith'] = this.showAppendKey
            ? this.appendKeyFormGroup.get('appendKey').value
            : null;
        input['automationFilter']['keysToReplicate'] =
            this.keyToReplicateFormGroup.get('keyToReplicate').value;
        input['automationFilter']['resourceTags'] =
            this.firstStepData.widgetFilter[0].filters.resourceTags;
        input['automationFilter']['configurationValue'] =
            this.secondStepData.configurationAutomation;
        input['automationFilter']['startDateForSchedular'] =
            this.dateFormGroup.get('startDate').value
                ? this.dateFormGroup.get('startDate').value
                : null;
        input['automationFilter']['endDateForSchedular'] =
            this.dateFormGroup.get('endDate').value
                ? this.dateFormGroup.get('endDate').value
                : null;
        input['automationFilter']['scheduleTime'] = Helper.formatDate(
            this.timeFormGroup.get('scheduleTime').value,
            'unix',
            'hh:mm a'
        );
        input['automationFilter']['timeZoneId'] = this.timeZoneFormGroup.get(
            'timeZone'
        )
            ? this.timeZoneFormGroup.get('timeZone').value
            : null;

        return input;
    }

    previouStep() {
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }
}
