import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-image-upload-modal',
    templateUrl: './image-upload-modal.component.html',
    styleUrls: ['./image-upload-modal.component.sass']
})
export class ImageUploadModalComponent implements OnInit {
    uploadCallback: Function;

    uploadIcon: IIcon = {
        type: IconType.SVG,
        class: 'upload_icon',
        extraClass: 'upload-icon'
    };

    uploadButton: IButtonGeneratorInput = {
        buttonName: 'Upload',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        function: null
    };

    imageUploadObjects: {
        label: string;
        file: File;
        image: string | ArrayBuffer;
        dirty: boolean;
        maxSizeAllowed?: number;
    }[];

    constructor(
        private modalInputData: ModalInjectedData,
        private modalService: ModalService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        const imageDetails = this.modalInputData.data.imageDetails;
        this.uploadCallback = this.modalInputData.data.uploadCallback;
        const imageSelected = this.modalInputData.data.imageSelected;
        if (imageDetails && imageDetails.length) {
            this.imageUploadObjects = [];
            imageDetails.forEach((imageLabel, index) => {
                const imageObject = {
                    label: imageLabel.modalLabel,
                    maxSizeAllowed: imageLabel.maxSizeAllowed,
                    file: null,
                    image: null,
                    dirty: false
                };
                if (
                    imageSelected &&
                    imageSelected.length &&
                    imageSelected[index]
                ) {
                    imageObject.image = imageSelected[index];
                }
                this.imageUploadObjects.push(imageObject);
            });
        }
    }

    upload(buttpnRef) {
        this.uploadCallback(this.imageUploadObjects);
        this.modalService.closeModal(null, this.modalInputData.modalId);
    }

    onFileSelected(fileOrMsg, fileUploadObject, isInvalid?: boolean) {
        if (isInvalid) {
            Helper.showErrorMessage(this.notificationsService, fileOrMsg);
            return;
        }
        if (fileOrMsg.size > fileUploadObject.maxSizeAllowed) {
            Helper.showErrorMessage(
                this.notificationsService,
                'Image size limit exceeded'
            );
            return;
        }
        fileUploadObject.file = fileOrMsg;
        fileUploadObject.dirty = true;
        const reader = new FileReader();
        reader.readAsDataURL(fileOrMsg);
        reader.onload = (event) => {
            fileUploadObject.image = event.target.result;
        };
    }
}
