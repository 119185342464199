import { Injectable } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { Helper } from './../../classes/Helper';
import { HitApi } from './../../classes/HitApi';
import { ButtonColorType } from './../../enums/ButtonColorType';
import { ICustomCostMonitoringWidgetCreationInput } from './../../interfaces/api/input-data/ICustomCostMonitoringWidgetCreationInput';
import { IHitApi } from './../../interfaces/hit-api/IHitApi';

@Injectable({
    providedIn: 'root'
})
export class CustomCostMonitoringWidgetService {
    widgetRef: Widget;

    constructor() {}

    saveAsDraft(
        buttonRef: IButtonGeneratorInput,
        widgetRef: Widget,
        draftData: ICustomCostMonitoringWidgetCreationInput,
        callback?,
        errorCallback?,
        widgetId = null,
        loaderCallback = null
    ) {
        if (widgetId && !draftData.draft) {
            draftData.draft = true;
            const modalId = widgetRef.modalService.openConfirmationModal({
                buttonText: 'Confirm',
                buttonColorType: ButtonColorType.PRIMARY,
                confirmationMessage: `Saving as Draft will remove the widget from all attached ACL and views. Are you sure you want to Save it As Draft ?`,
                modalName: 'Save Draft',
                function: () => {
                    this.hitCreateWidgetApi(
                        widgetRef,
                        draftData,
                        callback,
                        errorCallback,
                        widgetId,
                        modalId
                    );
                },
                loaderOnExec: true
            });
        } else {
            buttonRef.loader = true;
            if (loaderCallback) {
                loaderCallback();
            }
            this.hitCreateWidgetApi(
                widgetRef,
                draftData,
                callback,
                errorCallback,
                widgetId
            );
        }
    }

    hitCreateWidgetApi(
        widgetRef,
        widgetData: ICustomCostMonitoringWidgetCreationInput,
        callback?,
        errorCallback?,
        widgetId = null,
        modalId = null,
        buttonRef?
    ) {
        if (buttonRef && buttonRef.loader) {
            return;
        }

        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            widgetId
                ? widgetRef.widgetData.widgetInfo.update
                : widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = widgetId
            ? apiArgs.url.replace('{id}', widgetId)
            : apiArgs.url;

        apiArgs.input = widgetData;
        apiArgs.function = (response) => {
            if (callback) {
                widgetRef.refreshWidget();
                if (modalId) {
                    widgetRef.modalService.closeModal(null, modalId);
                }
                callback(response);
            }
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                widgetRef.notificationsService,
                error,
                'Error while saving'
            );
            if (errorCallback) {
                errorCallback();
            }
        };

        new HitApi(apiArgs, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }

    downloadReport(
        widgetRef: Widget,
        api: string,
        inputData: ICustomCostMonitoringWidgetCreationInput,
        contentType: ContentType,
        reportName: string,
        callback
    ) {
        const apiArgs: IHitApi = {
            url: api,
            input: inputData,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                downloadable: true
            },
            function: (response) => {
                Helper.saveAsBlob(response, contentType, reportName);
                widgetRef.notificationsService.showSnackBar(
                    'Report successfully downloaded'
                );
            },
            errorFunction: (error) => {
                widgetRef.notificationsService.showSnackBar(
                    error && error.error && error.error.message
                        ? error.error.message
                        : 'Error downloading report',
                    true
                );
            },
            endFunction: () => {
                callback();
            }
        };

        new HitApi(apiArgs, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }
}
