import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { CloudLabel } from 'src/app/shared/enums/CloudLabel';

@Component({
    selector: 'app-conventions-modal-v2',
    templateUrl: './conventions-modal-v2.component.html',
    styleUrls: ['./conventions-modal-v2.component.sass']
})
export class ConventionsModalV2Component implements OnInit {
    conventions: any[] = [];
    getKeys = Object.keys;
    widgetRef: Widget;
    cloudLabel = CloudLabel;

    constructor(modalInputData: ModalInjectedData) {
        this.conventions = modalInputData.data['conventions'];
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {}
}
