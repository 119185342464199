import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ButtonColorType } from './../../../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-ca-create-ticket-modal',
    templateUrl: './ca-create-ticket-modal.component.html',
    styleUrls: ['./ca-create-ticket-modal.component.sass']
})
export class CaCreateTicketModalComponent implements OnInit {
    createTicketFormGenInput: IFormGeneratorInput = null;
    modalData;
    widgetRef: Widget;
    stepData;

    constructor(private modalInputData: ModalInjectedData) {
        this.modalData = modalInputData.data['response'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.stepData = modalInputData.data;
    }

    ngOnInit(): void {
        this.createTicketFormGenInput = {
            formName: 'Create Ticket',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Create Ticket',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    const input = { ...formGroup.getRawValue() };
                    if (
                        !this.stepData['isPartner'] &&
                        this.stepData['configuredColumnData']
                    ) {
                        input['description'] = this.stepData[
                            'configuredColumnData'
                        ]
                            ? this.stepData['tableRowData'][
                                  this.stepData['configuredColumnData'][
                                      'descriptionColumn'
                                  ]
                              ]
                            : '';
                    }
                    input['parentIntegration'] =
                        this.modalData && this.modalData.parentIntegration
                            ? this.modalData.parentIntegration
                            : false;
                    input['childIntegration'] =
                        this.modalData && this.modalData.childIntegration
                            ? this.modalData.childIntegration
                            : false;
                    const apiArgs: IHitApi = {
                        url: ApiUrls.CASDM_CREATE_TICKET,
                        input: input,
                        requestType: RequestType.POST,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.BEARER_TOKEN
                        },
                        function: (response) => {
                            buttonRef.loader = false;
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.modalInputData.modalId
                            );
                            this.widgetRef.notificationsService.showSnackBar(
                                'Ticket Created Successfully'
                            );
                            this.widgetRef.refreshWidget(true);
                        },
                        errorFunction: (error) => {
                            buttonRef.loader = false;
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                'Error Creating Ticket'
                            );
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.modalInputData.modalId
                            );
                        }
                    };

                    new HitApi(
                        apiArgs,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                }
            },
            fields: [
                {
                    name: 'customerHandler',
                    placeholder: 'Customer Handle',
                    label: 'Customer Handle',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer handle is required'
                        }
                    ],
                    value:
                        this.modalData &&
                        this.modalData.credentials &&
                        this.modalData.credentials.customerHandler
                            ? this.modalData.credentials.customerHandler
                            : '',
                    disabled:
                        this.modalData &&
                        this.modalData.credentials &&
                        this.modalData.credentials.customerHandler
                            ? true
                            : false
                },
                {
                    name: 'email',
                    placeholder: 'Email',
                    label: 'Email',
                    fieldType: FilterType.EMAIL,
                    value:
                        this.modalData && this.modalData.email
                            ? this.modalData.email
                            : '',
                    disabled: true
                },

                {
                    name: 'priority',
                    placeholder: 'Select Priority',
                    label: 'Priority',
                    listData: this.priority,
                    getKey: 'id',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ]
                },
                {
                    name: 'categoryValue',
                    placeholder: 'Select Category',
                    label: 'Category',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData:
                        this.modalData && this.modalData.category
                            ? this.modalData.category
                            : [],
                    getKey: 'id',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Category is required'
                        }
                    ]
                },
                {
                    name: 'groupValue',
                    placeholder: 'Select Group',
                    label: 'Category Group',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData:
                        this.modalData && this.modalData.group
                            ? this.modalData.group
                            : [],
                    getKey: 'id',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Group is required'
                        }
                    ]
                },
                {
                    name: 'incidentTypeValue',
                    placeholder: 'Select Type',
                    label: 'Type',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    listData:
                        this.modalData && this.modalData.incidentType
                            ? this.handleType(this.modalData.incidentType)
                            : [],
                    getKey: 'id',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Type is required'
                        }
                    ]
                }
            ]
        };
        if (
            this.stepData['isPartner'] ||
            !this.stepData['configuredColumnData']
        ) {
            this.createTicketFormGenInput.fields.splice(2, 0, {
                name: 'description',
                placeholder: 'Description',
                label: 'Description',
                fieldType: FilterType.TEXT,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Description is required'
                    }
                ],
                value: this.stepData['configuredColumnData']
                    ? this.stepData['tableRowData'][
                          this.stepData['configuredColumnData'][
                              'descriptionColumn'
                          ]
                      ]
                    : '',
                disabled: this.stepData['isPartner']
                    ? this.stepData['isPartner']
                    : false
            });
        }
    }
    priority = [
        { id: '1', label: '1' },
        { id: '2', label: '2' },
        { id: '3', label: '3' },
        { id: '4', label: '4' }
    ];
    handleType(data) {
        const listData = [];
        data.forEach((ele) => {
            if (ele === 'incident') {
                listData.push({ id: 'I', label: 'Incident' });
            } else if (ele === 'task') {
                listData.push({ id: 'T', label: 'Task' });
            }
        });
        return listData;
    }
}
