<div
    class="tw-flex tw-flex-col tw-relative tw-overflow-scroll tw-h-full modal-ri-container"
    (scroll)="scrollSubject.emit($event)"
>
    <div class="tw-flex tw-fixed tw-items-start modal-heading">
        <div class="tab-container">
            <div
                class="each-tab-parent clickable"
                [style.width.%]="data['typeDistribution'] ? '24' : '32'"
                [ngClass]="
                    parentActiveTab == 'overview' ? 'active' : 'inactive-tab'
                "
                (click)="changeParentTab('overview', overviewContainer)"
            >
                <span> Savings Overview </span>
            </div>
            <span class="separate"> </span>

            <ng-container *ngIf="data['typeDistribution']">
                <div
                    class="each-tab-parent clickable"
                    [style.width.%]="data['typeDistribution'] ? '24' : '32'"
                    [ngClass]="
                        parentActiveTab == 'recommendations'
                            ? 'active'
                            : 'inactive-tab'
                    "
                    (click)="
                        changeParentTab(
                            'recommendations',
                            recommendationsContainer
                        )
                    "
                >
                    <span> Recommendation based on Instance Flexibility</span>
                </div>
                <span class="separate"> </span>
            </ng-container>

            <div
                class="each-tab-parent clickable"
                [style.width.%]="data['typeDistribution'] ? '24' : '32'"
                [ngClass]="
                    parentActiveTab == 'versus' ? 'active' : 'inactive-tab'
                "
                (click)="changeParentTab('versus', versusContainer)"
            >
                <span> RI vs On Demand</span>
            </div>
            <span class="separate"> </span>

            <div
                class="each-tab-parent clickable"
                [style.width.%]="data['typeDistribution'] ? '24' : '32'"
                [ngClass]="
                    parentActiveTab == 'description' ? 'active' : 'inactive-tab'
                "
                (click)="changeParentTab('description', descriptionContainer)"
            >
                <span> Description</span>
            </div>
        </div>
        <span class="shadow">
            <div
                class="close"
                (click)="modalService.closeModal(null, modalData.modalId)"
                appIcon
                [data]="crossIcon"
            ></div>
        </span>
    </div>

    <div class="main-data-container">
        <mat-accordion displayMode="flat" multi>
            <!-- SAVING OVERVIEW -->
            <div #overviewContainer>
                <mat-expansion-panel
                    hideToggle="true"
                    #overview
                    [expanded]="true"
                    (opened)="changeParentTab('overview')"
                >
                    <mat-expansion-panel-header
                        style="background: white !important"
                    >
                        <mat-panel-title> Savings Overview </mat-panel-title>

                        <mat-panel-description>
                            <span
                                *ngIf="!overview.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            >
                            </span>
                            <span
                                *ngIf="overview.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div
                        class="tw-flex tw-justify-center tw-items-center info-container"
                    >
                        <div
                            class="general general-info"
                            style="flex: initial"
                            *ngIf="
                                data &&
                                data['generalInformation'] &&
                                data['generalInformation'][
                                    'Instance Count to be Reserved'
                                ] &&
                                !isV2
                            "
                        >
                            <span class="header-web-view">
                                Instance Count to be Reserved: &nbsp;</span
                            >
                            <span class="value">{{
                                data["generalInformation"][
                                    "Instance Count to be Reserved"
                                ]
                            }}</span>
                        </div>
                        <div
                            class="general general-info"
                            style="flex: initial"
                            *ngIf="
                                isV2 &&
                                data &&
                                data['generalInformation'] &&
                                data['generalInformation'][
                                    'vCore Count to be Reserved'
                                ]
                            "
                        >
                            <span class="header-web-view">
                                vCore Count to be Reserved: &nbsp;</span
                            >
                            <span class="value">{{
                                data["generalInformation"][
                                    "vCore Count to be Reserved"
                                ]
                            }}</span>
                        </div>

                        <div
                            class="general"
                            style="flex: initial"
                            *ngIf="
                                data.savingTable[
                                    'Total Cost (' + data.currency + ')'
                                ]['Monthly'] &&
                                data.amortizedCost['Monthly Cost'][
                                    'Savings (' + data.currency + ')'
                                ]['Monthly']['1yr'] &&
                                data.amortizedCost['Monthly Cost'][
                                    'Savings (' + data.currency + ')'
                                ]['Monthly']['3yr']
                            "
                        >
                            <span class="header-web-view">
                                Savings To Be Made : &nbsp;

                                <span
                                    *ngIf="
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly'] &&
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly']['1yr']
                                    "
                                    class="tw-font-bold"
                                >
                                    {{ data?.currency }}
                                    {{
                                        data.amortizedCost["Monthly Cost"][
                                            "Savings (" + data.currency + ")"
                                        ]["Monthly"]["1yr"]
                                    }}
                                </span>

                                <span
                                    *ngIf="
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly'] &&
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly']['1yr'] &&
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly']['3yr']
                                    "
                                    class="tw-font-bold"
                                >
                                    -
                                </span>

                                <span
                                    *ngIf="
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly'] &&
                                        data.amortizedCost['Monthly Cost'][
                                            'Savings (' + data.currency + ')'
                                        ]['Monthly']['3yr']
                                    "
                                    class="tw-font-bold"
                                >
                                    {{ data?.currency }}

                                    {{
                                        data.amortizedCost["Monthly Cost"][
                                            "Savings (" + data.currency + ")"
                                        ]["Monthly"]["3yr"]
                                    }}
                                </span>
                            </span>
                        </div>

                        <div
                            class="general"
                            style="flex: initial"
                            *ngIf="
                                isV2 &&
                                data &&
                                data['generalInformation'] &&
                                data['generalInformation']['Savings To Be Made']
                            "
                        >
                            <span class="header-web-view">
                                Savings To Be Made: &nbsp;</span
                            >
                            <span class="value">{{
                                data["generalInformation"]["Savings To Be Made"]
                            }}</span>
                        </div>
                    </div>

                    <div
                        class="tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-m-auto tw-text-fs-600 tw-w-8/12 action-box"
                    >
                        <div class="tw-flex tw-flex-auto items">
                            <div class="tw-w-2/5">Payment Types</div>
                            <div class="tw-flex tw-w-3/5">
                                <div class="tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('Upfront')
                                        "
                                        (change)="
                                            toggleCheckBoxes(
                                                $event.checked,
                                                'Upfront'
                                            )
                                        "
                                        [disabled]="
                                            !applicableFilters.includes(
                                                'Upfront'
                                            )
                                        "
                                        >Upfront</mat-checkbox
                                    >
                                </div>

                                <div class="tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('Monthly')
                                        "
                                        (change)="
                                            toggleCheckBoxes(
                                                $event.checked,
                                                'Monthly'
                                            )
                                        "
                                        [disabled]="
                                            !applicableFilters.includes(
                                                'Monthly'
                                            )
                                        "
                                        >Monthly</mat-checkbox
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-auto items">
                            <div class="tw-w-2/5">Offering Terms</div>
                            <div class="tw-flex tw-w-3/5">
                                <div class="tw-flex tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('1yr')
                                        "
                                        (change)="
                                            toggleCheckBoxes(
                                                $event.checked,
                                                '1yr'
                                            );
                                            toggleOfferings(
                                                $event.checked,
                                                '1yr'
                                            );
                                            prepareChartData()
                                        "
                                        [disabled]="
                                            !applicableFilters.includes('1yr')
                                        "
                                        >1 Year</mat-checkbox
                                    >
                                </div>
                                <div class="tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('3yr')
                                        "
                                        (change)="
                                            toggleCheckBoxes(
                                                $event.checked,
                                                '3yr'
                                            );
                                            toggleOfferings(
                                                $event.checked,
                                                '3yr'
                                            );
                                            prepareChartData()
                                        "
                                        [disabled]="
                                            !applicableFilters.includes('3yr')
                                        "
                                        >3 Years</mat-checkbox
                                    >
                                </div>
                            </div>
                        </div>

                        <!-- V2 SAVINGS TOGGLE ADD -->
                        <div class="tw-flex items" *ngIf="isV2">
                            <div class="tw-w-2/5">Savings</div>

                            <div class="checkbox tw-w-3/5">
                                <mat-radio-group
                                    [value]="'Best Savings'"
                                    (change)="
                                        overviewSavingsRadioButtonVale =
                                            $event.value
                                    "
                                    class="tw-flex tw-w-full tw-justify-between"
                                    aria-label="Select an option tw-flex"
                                >
                                    <mat-radio-button
                                        value="Best Savings"
                                        class="tw-flex-1"
                                        >Best Savings</mat-radio-button
                                    >
                                    <mat-radio-button
                                        value="Full RI Coverage"
                                        class="tw-flex-1"
                                        >Full RI Coverage</mat-radio-button
                                    >
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="tw-mt-6 tabs">
                        <div class="tw-flex tab-header">
                            <div
                                class="tw-flex-1 tw-p-3 tw-text-center tw-font-semibold tw-text-fs-600 tw-cursor-pointer tab-header-item tab-header-item-1"
                                *ngIf="objectKeys(data).includes('savingTable')"
                                [class.active]="selectedTab === 'savingTable'"
                                [class.inactive]="selectedTab !== 'savingTable'"
                                (click)="selectedTab = 'savingTable'"
                            >
                                Savings(%)
                            </div>
                            <div
                                class="tw-flex-1 tw-p-3 tw-text-center tw-font-semibold tw-text-fs-600 tw-cursor-pointer tab-header-item"
                                *ngFor="let tab of tabs"
                                [class.active]="selectedTab === tab"
                                [class.inactive]="selectedTab !== tab"
                                (click)="selectedTab = tab"
                            >
                                {{ tab }}
                            </div>
                        </div>
                        <div class="tab-content">
                            <div
                                class="tw-pb-4 tw-overflow-y-auto tab-content-item tab-content-item-1"
                                *ngIf="selectedTab === 'savingTable'"
                            >
                                <table class="tw-border-collapse tw-m-2 tw-p-1">
                                    <tr class="tw-p-1">
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            rowspan="2"
                                        >
                                            Cost Type
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            [attr.colspan]="
                                                activeCheckboxes.length === 3 &&
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                )
                                                    ? '2'
                                                    : activeCheckboxes.length ===
                                                          3 &&
                                                      (activeCheckboxes.includes(
                                                          'Upfront'
                                                      ) ||
                                                          activeCheckboxes.includes(
                                                              'Monthly'
                                                          ))
                                                    ? '1'
                                                    : '2'
                                            "
                                            *ngIf="
                                                displayCondition('1yr') &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            1 year
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            [attr.colspan]="
                                                activeCheckboxes.length === 3 &&
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                )
                                                    ? '2'
                                                    : activeCheckboxes.length ===
                                                          3 &&
                                                      (activeCheckboxes.includes(
                                                          'Upfront'
                                                      ) ||
                                                          activeCheckboxes.includes(
                                                              'Monthly'
                                                          ))
                                                    ? '1'
                                                    : '2'
                                            "
                                            *ngIf="
                                                displayCondition('3yr') &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            3 years
                                        </th>
                                    </tr>

                                    <tr class="tw-p-1">
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            Upfront
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                ) &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            Monthly
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            Upfront
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                ) &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            Monthly
                                        </th>
                                    </tr>

                                    <tr
                                        class="tw-p-1"
                                        *ngFor="
                                            let rowData of objectKeys(
                                                data['savingTable']
                                            )
                                        "
                                    >
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                        >
                                            {{ rowData }}
                                        </th>

                                        <ng-container
                                            *ngIf="
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Upfront'] &&
                                                        objectKeys(
                                                            data['savingTable'][
                                                                rowData
                                                            ][
                                                                overviewSavingsRadioButtonVale
                                                            ]['Upfront']
                                                        ).includes('1yr')) ||
                                                        (data['savingTable'][
                                                            rowData
                                                        ]['Upfront'] &&
                                                            objectKeys(
                                                                data[
                                                                    'savingTable'
                                                                ][rowData][
                                                                    'Upfront'
                                                                ]
                                                            ).includes(
                                                                '1yr'
                                                            ))) &&
                                                    activeCheckboxes.includes(
                                                        'Upfront'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["savingTable"][
                                                        rowData
                                                    ][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["savingTable"][
                                                              rowData
                                                          ][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Upfront"]["1yr"]
                                                        : data["savingTable"][
                                                              rowData
                                                          ]["Upfront"]["1yr"]
                                                }}
                                            </td>
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Monthly'] &&
                                                        objectKeys(
                                                            data['savingTable'][
                                                                rowData
                                                            ][
                                                                overviewSavingsRadioButtonVale
                                                            ]['Monthly']
                                                        ).includes('1yr')) ||
                                                        (data['savingTable'][
                                                            rowData
                                                        ]['Monthly'] &&
                                                            objectKeys(
                                                                data[
                                                                    'savingTable'
                                                                ][rowData][
                                                                    'Monthly'
                                                                ]
                                                            ).includes(
                                                                '1yr'
                                                            ))) &&
                                                    activeCheckboxes.includes(
                                                        'Monthly'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["savingTable"][
                                                        rowData
                                                    ][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["savingTable"][
                                                              rowData
                                                          ][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Monthly"]["1yr"]
                                                        : data["savingTable"][
                                                              rowData
                                                          ]["Monthly"]["1yr"]
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Upfront'] &&
                                                        objectKeys(
                                                            data['savingTable'][
                                                                rowData
                                                            ][
                                                                overviewSavingsRadioButtonVale
                                                            ]['Upfront']
                                                        ).includes('3yr')) ||
                                                        (data['savingTable'][
                                                            rowData
                                                        ]['Upfront'] &&
                                                            objectKeys(
                                                                data[
                                                                    'savingTable'
                                                                ][rowData][
                                                                    'Upfront'
                                                                ]
                                                            ).includes(
                                                                '3yr'
                                                            ))) &&
                                                    activeCheckboxes.includes(
                                                        'Upfront'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["savingTable"][
                                                        rowData
                                                    ][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["savingTable"][
                                                              rowData
                                                          ][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Upfront"]["3yr"]
                                                        : data["savingTable"][
                                                              rowData
                                                          ]["Upfront"]["3yr"]
                                                }}
                                            </td>
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['savingTable'][
                                                            rowData
                                                        ][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Monthly'] &&
                                                        objectKeys(
                                                            data['savingTable'][
                                                                rowData
                                                            ][
                                                                overviewSavingsRadioButtonVale
                                                            ]['Monthly']
                                                        ).includes('3yr')) ||
                                                        (data['savingTable'][
                                                            rowData
                                                        ]['Monthly'] &&
                                                            objectKeys(
                                                                data[
                                                                    'savingTable'
                                                                ][rowData][
                                                                    'Monthly'
                                                                ]
                                                            ).includes(
                                                                '3yr'
                                                            ))) &&
                                                    activeCheckboxes.includes(
                                                        'Monthly'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["savingTable"][
                                                        rowData
                                                    ][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["savingTable"][
                                                              rowData
                                                          ][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Monthly"]["3yr"]
                                                        : data["savingTable"][
                                                              rowData
                                                          ]["Monthly"]["3yr"]
                                                }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </table>
                            </div>
                            <div
                                class="tw-pb-4 tw-overflow-y-auto tab-content-item"
                                *ngIf="selectedTab !== 'savingTable'"
                            >
                                <table class="tw-border-collapse tw-m-2 tw-p-1">
                                    <tr class="tw-p-1">
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            rowspan="2"
                                        >
                                            Cost Type
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            [attr.colspan]="
                                                activeCheckboxes.length === 3 &&
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                )
                                                    ? '2'
                                                    : activeCheckboxes.length ===
                                                          3 &&
                                                      (activeCheckboxes.includes(
                                                          'Upfront'
                                                      ) ||
                                                          activeCheckboxes.includes(
                                                              'Monthly'
                                                          ))
                                                    ? '1'
                                                    : '2'
                                            "
                                            *ngIf="
                                                displayCondition('1yr') &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            1 year
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            [attr.colspan]="
                                                activeCheckboxes.length === 3 &&
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                )
                                                    ? '2'
                                                    : activeCheckboxes.length ===
                                                          3 &&
                                                      (activeCheckboxes.includes(
                                                          'Upfront'
                                                      ) ||
                                                          activeCheckboxes.includes(
                                                              'Monthly'
                                                          ))
                                                    ? '1'
                                                    : '2'
                                            "
                                            *ngIf="
                                                displayCondition('3yr') &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            3 years
                                        </th>
                                    </tr>

                                    <tr class="tw-p-1">
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            Upfront
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                ) &&
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            Monthly
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Upfront'
                                                ) &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            Upfront
                                        </th>
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                            *ngIf="
                                                activeCheckboxes.includes(
                                                    'Monthly'
                                                ) &&
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            Monthly
                                        </th>
                                    </tr>

                                    <tr
                                        class="tw-p-1"
                                        *ngFor="
                                            let rowData of objectKeys(
                                                data['amortizedCost'][
                                                    selectedTab
                                                ]
                                            )
                                        "
                                    >
                                        <th
                                            class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-heading"
                                        >
                                            {{ rowData }}
                                        </th>

                                        <ng-container
                                            *ngIf="
                                                activeCheckboxes.includes('1yr')
                                            "
                                        >
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Upfront']) ||
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            'Upfront'
                                                        ]) &&
                                                    activeCheckboxes.includes(
                                                        'Upfront'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["amortizedCost"][
                                                        selectedTab
                                                    ][rowData][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Upfront"]["1yr"]
                                                        : data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData]["Upfront"][
                                                              "1yr"
                                                          ]
                                                }}
                                            </td>

                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Monthly']) ||
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            'Monthly'
                                                        ]) &&
                                                    activeCheckboxes.includes(
                                                        'Monthly'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["amortizedCost"][
                                                        selectedTab
                                                    ][rowData][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Monthly"]["1yr"]
                                                        : data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData]["Monthly"][
                                                              "1yr"
                                                          ]
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="
                                                activeCheckboxes.includes('3yr')
                                            "
                                        >
                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Upfront']) ||
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            'Upfront'
                                                        ]) &&
                                                    activeCheckboxes.includes(
                                                        'Upfront'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["amortizedCost"][
                                                        selectedTab
                                                    ][rowData][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Upfront"]["3yr"]
                                                        : data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData]["Upfront"][
                                                              "3yr"
                                                          ]
                                                }}
                                            </td>

                                            <td
                                                class="tw-py-2 tw-px-1 tw-text-fs-200 tw-text-center"
                                                *ngIf="
                                                    ((isV2 &&
                                                        overviewSavingsRadioButtonVale &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ] &&
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            overviewSavingsRadioButtonVale
                                                        ]['Monthly']) ||
                                                        data['amortizedCost'][
                                                            selectedTab
                                                        ][rowData][
                                                            'Monthly'
                                                        ]) &&
                                                    activeCheckboxes.includes(
                                                        'Monthly'
                                                    )
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    overviewSavingsRadioButtonVale &&
                                                    data["amortizedCost"][
                                                        selectedTab
                                                    ][rowData][
                                                        overviewSavingsRadioButtonVale
                                                    ]
                                                        ? data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData][
                                                              overviewSavingsRadioButtonVale
                                                          ]["Monthly"]["3yr"]
                                                        : data["amortizedCost"][
                                                              selectedTab
                                                          ][rowData]["Monthly"][
                                                              "3yr"
                                                          ]
                                                }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!-- INSTANCE FLEXIBILITY ONLY VISIBLE WHEN INSTANCE FLEXIBILITY IS TRUE-->

            <div #recommendationsContainer>
                <mat-expansion-panel
                    hideToggle="true"
                    #recommendations
                    [expanded]="true"
                    *ngIf="data['typeDistribution']"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Recommendation based on Instance Flexibility
                        </mat-panel-title>

                        <mat-panel-description>
                            <span
                                *ngIf="!recommendations.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="recommendations.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="normalization">
                        <div class="tw-flex tw-mx-1 tw-my-3 unit-counts">
                            <div
                                class="tw-flex tw-justify-center tw-flex-f4 tw-text-fs-200 row-item"
                            >
                                <div
                                    class="general general-info"
                                    style="flex: initial"
                                >
                                    <span class="header">
                                        Instance Flexibility Ratio to be
                                        Reserved: &nbsp;</span
                                    >
                                    <span class="value">{{
                                        data["typeDistribution"][
                                            "normalizationCount"
                                        ]
                                    }}</span>
                                </div>
                            </div>
                            <div class="tw-flex-f3 row-item">
                                <div
                                    class="general general-info"
                                    style="flex: initial"
                                >
                                    <span class="header">
                                        Amount of Units left: &nbsp;</span
                                    >
                                    <span class="value">{{ unitsLeft }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="normalization">
                            <table class="tw-border-collapse tw-m-2 tw-p-1">
                                <tr>
                                    <th
                                        class="tw-text-fs-400 tw-font-heading tw-text-center"
                                    >
                                        VM Type
                                    </th>
                                    <th
                                        class="tw-text-fs-400 tw-font-heading tw-text-center"
                                    >
                                        Instance Flexibility Ratio
                                    </th>
                                    <th
                                        class="tw-text-fs-400 tw-font-heading tw-text-center"
                                    >
                                        VM Count
                                    </th>
                                    <th
                                        class="tw-text-fs-400 tw-font-heading tw-text-center"
                                    >
                                        Instance Flexibility Count
                                    </th>
                                </tr>

                                <tr
                                    *ngFor="
                                        let item of data['typeDistribution'][
                                            'instanceType'
                                        ]
                                    "
                                >
                                    <td class="tw-text-fs-600 tw-text-center">
                                        {{ item.split("|")[0] }}
                                    </td>
                                    <td class="tw-text-center">
                                        {{ item.split("|")[1] }}
                                    </td>
                                    <td class="tw-text-center">
                                        <div
                                            class="row-item tw-flex tw-items-center tw-justify-center input-button-container"
                                        >
                                            X

                                            <div
                                                class="tw-flex tw-text-fs-600 tw-rounded-full tw-items-center tw-justify-center tw-select-none tw-cursor-pointer plus-minus btn btn-grey-1"
                                                style="margin-left: 20px"
                                                (click)="
                                                    inputValue[item] =
                                                        +value.value - 1;
                                                    updateTotal(
                                                        item,
                                                        inputValue[item],
                                                        'minus'
                                                    )
                                                "
                                            >
                                                -
                                            </div>
                                            <input
                                                #value
                                                class="tw-text-center tw-rounded-md tw-w-8 tw-h-6 tw-text-fs-600 text-box"
                                                (click)="value.select()"
                                                type="number"
                                                placeholder="0"
                                                [value]="
                                                    inputValue[item]
                                                        ? inputValue[item]
                                                        : '0'
                                                "
                                                (keyup)="
                                                    updateTotal(
                                                        item,
                                                        $event.target.value,
                                                        '',
                                                        value
                                                    )
                                                "
                                            />
                                            <div
                                                class="tw-flex tw-text-fs-600 tw-rounded-full tw-items-center tw-justify-center tw-select-none tw-cursor-pointer plus-minus btn btn-grey-1"
                                                (click)="
                                                    updateTotal(
                                                        item,
                                                        +value.value,
                                                        'plus'
                                                    )
                                                "
                                            >
                                                +
                                            </div>
                                        </div>
                                    </td>

                                    <td class="tw-text-center">
                                        <span>=</span>
                                        {{
                                            totalObj[item] ? totalObj[item] : 0
                                        }}
                                    </td>
                                </tr>
                            </table>

                            <div
                                class="tw-flex tw-my-3 tw-mx-1 tw-float-right total-count"
                            >
                                <div
                                    class="general"
                                    style="
                                        flex: initial;
                                        width: 200px;
                                        margin-right: 20px;
                                        justify-content: space-evenly;
                                    "
                                >
                                    <span class="header"> Total &nbsp;</span>
                                    <span class="value">{{
                                        totalCountOfSelected
                                    }}</span>
                                </div>
                                <div
                                    class="tw-flex-1 tw-font-semibold tw-text-fs-400 tw-justify-center tw-flex row-item"
                                ></div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!-- RO VS ONDEMAND -->
            <div #versusContainer>
                <mat-expansion-panel
                    hideToggle="true"
                    #versus
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title> RI vs On Demand</mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!versus.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            >
                            </span>
                            <span
                                *ngIf="versus.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            >
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div
                        class="tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-m-auto tw-text-fs-600 tw-w-8/12 tw-mt-6 action-box"
                    >
                        <div class="tw-flex tw-flex-auto items">
                            <span class="tw-w-2/5"> Payment Types </span>
                            <div class="tw-w-3/5">
                                <mat-radio-group
                                    aria-label="Select an option"
                                    class="tw-flex tw-w-full tw-justify-between"
                                >
                                    <mat-radio-button
                                        [checked]="
                                            selectedPaymentType === 'Upfront'
                                        "
                                        (change)="
                                            selectedPaymentType = $event.value;
                                            prepareChartData()
                                        "
                                        value="Upfront"
                                        [disabled]="
                                            !applicableFilters.includes(
                                                'Upfront'
                                            )
                                        "
                                        class="tw-flex-1"
                                        >Upfront</mat-radio-button
                                    >

                                    <mat-radio-button
                                        [checked]="
                                            selectedPaymentType === 'Monthly'
                                        "
                                        (change)="
                                            selectedPaymentType = $event.value;
                                            prepareChartData()
                                        "
                                        value="Monthly"
                                        [disabled]="
                                            !applicableFilters.includes(
                                                'Monthly'
                                            )
                                        "
                                        class="tw-flex-1"
                                        >Monthly</mat-radio-button
                                    >
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-auto items">
                            <span class="tw-w-2/5"> Offering Terms </span>
                            <div class="tw-flex tw-w-3/5">
                                <div class="tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('1yr')
                                        "
                                        (change)="
                                            toggleOfferings(
                                                $event.checked,
                                                '1yr'
                                            );
                                            prepareChartData()
                                        "
                                        [disabled]="
                                            !applicableFilters.includes('1yr')
                                        "
                                        >1 Year</mat-checkbox
                                    >
                                </div>
                                <div class="tw-flex-1 checkbox">
                                    <mat-checkbox
                                        [checked]="
                                            activeCheckboxes.includes('3yr')
                                        "
                                        (change)="
                                            toggleOfferings(
                                                $event.checked,
                                                '3yr'
                                            );
                                            prepareChartData()
                                        "
                                        [disabled]="
                                            !applicableFilters.includes('3yr')
                                        "
                                        >3 Years</mat-checkbox
                                    >
                                </div>
                            </div>
                        </div>
                        <!-- V2 SAVINGS TOGGLE ADD -->
                        <div class="tw-flex items" *ngIf="isV2">
                            <span class="tw-w-2/5"> Savings </span>

                            <div class="tw-w-3/5 checkbox">
                                <mat-radio-group
                                    [value]="'Best Savings'"
                                    (change)="
                                        verseusSavingsRadioButtonVale =
                                            $event.value
                                    "
                                    class="tw-flex tw-justify-between tw-w-full"
                                    aria-label="Select an option tw-flex"
                                >
                                    <mat-radio-button
                                        value="Best Savings"
                                        class="tw-flex-1"
                                        >Best Savings</mat-radio-button
                                    >
                                    <mat-radio-button
                                        value="Full RI Coverage"
                                        class="tw-flex-1"
                                        >Full RI Coverage</mat-radio-button
                                    >
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tw-mt-6 tw-overflow-auto versus-table-container"
                    >
                        <table class="tw-border-collapse tw-p-1">
                            <tr class="tw-p-2">
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center tw-absolute heading"
                                >
                                    Cost Type
                                </th>

                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-heading"
                                ></th>

                                <ng-container
                                    *ngIf="selectedPaymentType === 'Monthly'"
                                >
                                    <ng-container
                                        *ngFor="
                                            let item of this.objectKeys(
                                                comparisonCosts
                                            );
                                            index as i
                                        "
                                    >
                                        <th
                                            class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center"
                                            *ngIf="
                                                !activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 11
                                            "
                                        >
                                            {{ "Month " + item }}
                                        </th>

                                        <th
                                            class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center"
                                            *ngIf="
                                                activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 36
                                            "
                                        >
                                            {{ "Month " + item }}
                                        </th>
                                    </ng-container>

                                    <th
                                        class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-total"
                                    ></th>
                                </ng-container>

                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center total"
                                    [style.position]="
                                        selectedPaymentType === 'Monthly'
                                            ? 'absolute'
                                            : 'initial'
                                    "
                                >
                                    Total Cost
                                </th>
                            </tr>

                            <tr *ngIf="activeOfferingsTerms.includes('1yr')">
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center tw-absolute heading"
                                    style="padding: 4px 5px !important"
                                >
                                    1-year Cumulative On Demand Cost ({{
                                        data["currency"]
                                    }})
                                </th>
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-heading"
                                ></th>

                                <ng-container
                                    *ngIf="selectedPaymentType === 'Monthly'"
                                >
                                    <ng-container
                                        *ngFor="
                                            let item of this.objectKeys(
                                                comparisonCosts
                                            );
                                            index as i
                                        "
                                    >
                                        <td
                                            class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                            *ngIf="
                                                !activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 11
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    commutativeDisplayCondition(
                                                        item
                                                    );
                                                    else displayHyphen
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    verseusSavingsRadioButtonVale &&
                                                    comparisonCosts[item][
                                                        "On Demand Cost (" +
                                                            data.currency +
                                                            ")"
                                                    ][
                                                        verseusSavingsRadioButtonVale
                                                    ]
                                                        ? comparisonCosts[item][
                                                              "On Demand Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              verseusSavingsRadioButtonVale
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                        : comparisonCosts[item][
                                                              "On Demand Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                }}
                                            </ng-container>
                                        </td>

                                        <td
                                            class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                            *ngIf="
                                                activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 36
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    commutativeDisplayCondition(
                                                        item
                                                    );
                                                    else displayHyphen
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    verseusSavingsRadioButtonVale &&
                                                    comparisonCosts[item][
                                                        "On Demand Cost (" +
                                                            data.currency +
                                                            ")"
                                                    ][
                                                        verseusSavingsRadioButtonVale
                                                    ]
                                                        ? comparisonCosts[item][
                                                              "On Demand Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              verseusSavingsRadioButtonVale
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                        : comparisonCosts[item][
                                                              "On Demand Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                }}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <th class="replacement-total"></th>
                                </ng-container>

                                <td
                                    class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center total"
                                    [style.position]="
                                        selectedPaymentType === 'Monthly'
                                            ? 'absolute'
                                            : 'initial'
                                    "
                                    *ngIf="
                                        (isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts['12'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ] &&
                                            comparisonCosts['12'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ]['1yr']) ||
                                        (comparisonCosts['12'][
                                            'On Demand Cost (' +
                                                data.currency +
                                                ')'
                                        ][selectedPaymentType] &&
                                            comparisonCosts['12'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][selectedPaymentType]['1yr'])
                                    "
                                >
                                    {{
                                        isV2 &&
                                        verseusSavingsRadioButtonVale &&
                                        comparisonCosts["12"][
                                            "On Demand Cost (" +
                                                data.currency +
                                                ")"
                                        ][verseusSavingsRadioButtonVale]
                                            ? comparisonCosts["12"][
                                                  "On Demand Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][verseusSavingsRadioButtonVale][
                                                  selectedPaymentType
                                              ]["1yr"]
                                            : comparisonCosts["12"][
                                                  "On Demand Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][selectedPaymentType]["1yr"]
                                    }}
                                </td>
                            </tr>

                            <tr *ngIf="activeOfferingsTerms.includes('1yr')">
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center tw-absolute heading"
                                    style="padding: 4px 5px !important"
                                >
                                    1-year Cumulative RI Cost ({{
                                        data["currency"]
                                    }})
                                </th>
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-heading"
                                ></th>
                                <ng-container
                                    *ngIf="selectedPaymentType === 'Monthly'"
                                >
                                    <ng-container
                                        *ngFor="
                                            let item of this.objectKeys(
                                                comparisonCosts
                                            );
                                            index as i
                                        "
                                    >
                                        <td
                                            class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                            *ngIf="
                                                !activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 11
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    commutativeDisplayCondition(
                                                        item
                                                    );
                                                    else displayHyphen
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    verseusSavingsRadioButtonVale &&
                                                    comparisonCosts[item][
                                                        "Total RI Cost (" +
                                                            data.currency +
                                                            ")"
                                                    ][
                                                        verseusSavingsRadioButtonVale
                                                    ]
                                                        ? comparisonCosts[item][
                                                              "Total RI Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              verseusSavingsRadioButtonVale
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                        : comparisonCosts[item][
                                                              "Total RI Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                }}
                                            </ng-container>
                                        </td>

                                        <td
                                            class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                            *ngIf="
                                                activeOfferingsTerms.includes(
                                                    '3yr'
                                                ) && i <= 36
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    commutativeDisplayCondition(
                                                        item
                                                    );
                                                    else displayHyphen
                                                "
                                            >
                                                {{
                                                    isV2 &&
                                                    verseusSavingsRadioButtonVale &&
                                                    comparisonCosts[item][
                                                        "Total RI Cost (" +
                                                            data.currency +
                                                            ")"
                                                    ][
                                                        verseusSavingsRadioButtonVale
                                                    ]
                                                        ? comparisonCosts[item][
                                                              "Total RI Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              verseusSavingsRadioButtonVale
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                        : comparisonCosts[item][
                                                              "Total RI Cost (" +
                                                                  data.currency +
                                                                  ")"
                                                          ][
                                                              selectedPaymentType
                                                          ]["1yr"]
                                                }}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <th
                                        class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-total"
                                    ></th>
                                </ng-container>

                                <td
                                    class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center total"
                                    [style.position]="
                                        selectedPaymentType === 'Monthly'
                                            ? 'absolute'
                                            : 'initial'
                                    "
                                    *ngIf="
                                        (isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts['12'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ] &&
                                            comparisonCosts['12'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale]) ||
                                        (comparisonCosts['12'][
                                            'Total RI Cost (' +
                                                data.currency +
                                                ')'
                                        ][selectedPaymentType] &&
                                            comparisonCosts['12'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][selectedPaymentType]['1yr'])
                                    "
                                >
                                    {{
                                        isV2 &&
                                        verseusSavingsRadioButtonVale &&
                                        comparisonCosts["12"][
                                            "Total RI Cost (" +
                                                data.currency +
                                                ")"
                                        ][verseusSavingsRadioButtonVale]
                                            ? comparisonCosts["12"][
                                                  "Total RI Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][verseusSavingsRadioButtonVale][
                                                  selectedPaymentType
                                              ]["1yr"]
                                            : comparisonCosts["12"][
                                                  "Total RI Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][selectedPaymentType]["1yr"]
                                    }}
                                </td>
                            </tr>

                            <tr *ngIf="activeOfferingsTerms.includes('3yr')">
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center tw-absolute heading"
                                    style="padding: 4px 5px !important"
                                >
                                    3-years Cumulative On Demand Cost ({{
                                        data["currency"]
                                    }})
                                </th>
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-heading"
                                ></th>

                                <ng-container
                                    *ngIf="selectedPaymentType === 'Monthly'"
                                >
                                    <td
                                        class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                        *ngFor="
                                            let item of this.objectKeys(
                                                comparisonCosts
                                            )
                                        "
                                    >
                                        {{
                                            isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts[item][
                                                "On Demand Cost (" +
                                                    data.currency +
                                                    ")"
                                            ][verseusSavingsRadioButtonVale]
                                                ? comparisonCosts[item][
                                                      "On Demand Cost (" +
                                                          data.currency +
                                                          ")"
                                                  ][
                                                      verseusSavingsRadioButtonVale
                                                  ][selectedPaymentType]["3yr"]
                                                : comparisonCosts[item][
                                                      "On Demand Cost (" +
                                                          data.currency +
                                                          ")"
                                                  ][selectedPaymentType]["3yr"]
                                        }}
                                    </td>
                                    <th class="replacement-total"></th>
                                </ng-container>

                                <td
                                    class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center total"
                                    [style.position]="
                                        selectedPaymentType === 'Monthly'
                                            ? 'absolute'
                                            : 'initial'
                                    "
                                    *ngIf="
                                        (isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts['36'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ] &&
                                            comparisonCosts['36'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ]['3yr']) ||
                                        (comparisonCosts['36'][
                                            'On Demand Cost (' +
                                                data.currency +
                                                ')'
                                        ][selectedPaymentType] &&
                                            comparisonCosts['36'][
                                                'On Demand Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][selectedPaymentType]['3yr'])
                                    "
                                >
                                    {{
                                        isV2 &&
                                        verseusSavingsRadioButtonVale &&
                                        comparisonCosts["36"][
                                            "On Demand Cost (" +
                                                data.currency +
                                                ")"
                                        ][verseusSavingsRadioButtonVale]
                                            ? comparisonCosts["36"][
                                                  "On Demand Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][verseusSavingsRadioButtonVale][
                                                  selectedPaymentType
                                              ]["3yr"]
                                            : comparisonCosts["36"][
                                                  "On Demand Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][selectedPaymentType]["3yr"]
                                    }}
                                </td>
                            </tr>

                            <tr *ngIf="activeOfferingsTerms.includes('3yr')">
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center tw-absolute heading"
                                    style="padding: 4px 5px !important"
                                >
                                    3-years Cumulative RI Cost ({{
                                        data["currency"]
                                    }})
                                </th>
                                <th
                                    class="tw-py-3 tw-px-1 tw-text-fs-200 tw-font-heading tw-text-center replacement-heading"
                                ></th>

                                <ng-container
                                    *ngIf="selectedPaymentType === 'Monthly'"
                                >
                                    <td
                                        class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center"
                                        *ngFor="
                                            let item of this.objectKeys(
                                                comparisonCosts
                                            )
                                        "
                                    >
                                        {{
                                            isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts[item][
                                                "Total RI Cost (" +
                                                    data.currency +
                                                    ")"
                                            ][verseusSavingsRadioButtonVale]
                                                ? comparisonCosts[item][
                                                      "Total RI Cost (" +
                                                          data.currency +
                                                          ")"
                                                  ][
                                                      verseusSavingsRadioButtonVale
                                                  ][selectedPaymentType]["3yr"]
                                                : comparisonCosts[item][
                                                      "Total RI Cost (" +
                                                          data.currency +
                                                          ")"
                                                  ][selectedPaymentType]["3yr"]
                                        }}
                                    </td>
                                    <th class="replacement-total"></th>
                                </ng-container>

                                <td
                                    class="tw-py-4 tw-px-1 tw-text-fs-200 tw-text-center total"
                                    [style.position]="
                                        selectedPaymentType === 'Monthly'
                                            ? 'absolute'
                                            : 'initial'
                                    "
                                    *ngIf="
                                        (isV2 &&
                                            verseusSavingsRadioButtonVale &&
                                            comparisonCosts['36'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ] &&
                                            comparisonCosts['36'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][verseusSavingsRadioButtonVale][
                                                selectedPaymentType
                                            ]['3yr']) ||
                                        (comparisonCosts['36'][
                                            'Total RI Cost (' +
                                                data.currency +
                                                ')'
                                        ][selectedPaymentType] &&
                                            comparisonCosts['36'][
                                                'Total RI Cost (' +
                                                    data.currency +
                                                    ')'
                                            ][selectedPaymentType]['3yr'])
                                    "
                                >
                                    {{
                                        isV2 &&
                                        verseusSavingsRadioButtonVale &&
                                        comparisonCosts["36"][
                                            "Total RI Cost (" +
                                                data.currency +
                                                ")"
                                        ][verseusSavingsRadioButtonVale]
                                            ? comparisonCosts["36"][
                                                  "Total RI Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][verseusSavingsRadioButtonVale][
                                                  selectedPaymentType
                                              ]["3yr"]
                                            : comparisonCosts["36"][
                                                  "Total RI Cost (" +
                                                      data.currency +
                                                      ")"
                                              ][selectedPaymentType]["3yr"]
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <ng-container *ngIf="graphData">
                        <app-graph-generator
                            [graphData]="graphData"
                        ></app-graph-generator>
                    </ng-container>

                    <div
                        class="tw-flex tw-justify-evenly tw-items-center tw-m-auto tw-mt-6 bottom-info"
                    >
                        <div
                            class="tw-text-fs-400 tw-font-heading tw-flex tw-justify-center tw-items-center item"
                            style="border-bottom: 2px solid #30d2d6"
                        >
                            No. of Hours ran in a day:&nbsp;
                            <span class="tw-font-bold tw-text-fs-600">
                                {{
                                    isV2 &&
                                    verseusSavingsRadioButtonVale &&
                                    data["graphTable"][
                                        verseusSavingsRadioButtonVale
                                    ]
                                        ? data["graphTable"][
                                              verseusSavingsRadioButtonVale
                                          ]["Number of hours ran in a day"]
                                        : data["graphTable"][
                                              "Number of hours ran in a day"
                                          ]
                                }}</span
                            >
                        </div>
                        <div
                            class="tw-text-fs-400 tw-font-heading tw-flex tw-justify-center tw-items-center item"
                            style="border-bottom: 2px solid #0b7e0b"
                        >
                            On Demand Cost ({{ data["currency"] }}/Hr):&nbsp;
                            <span class="tw-font-bold tw-text-fs-600">
                                {{
                                    isV2 &&
                                    verseusSavingsRadioButtonVale &&
                                    data["graphTable"][
                                        verseusSavingsRadioButtonVale
                                    ]
                                        ? data["graphTable"][
                                              verseusSavingsRadioButtonVale
                                          ]["OnDemand Hourly"]
                                        : data["graphTable"]["OnDemand Hourly"]
                                }}</span
                            >
                        </div>
                        <div
                            class="tw-text-fs-400 tw-font-heading tw-flex tw-justify-center tw-items-center item"
                            style="border-bottom: 2px solid #373737"
                        >
                            On Demand Cost ({{ data["currency"] }}/Month):&nbsp;
                            <span class="tw-font-bold tw-text-fs-600">
                                {{
                                    isV2 &&
                                    verseusSavingsRadioButtonVale &&
                                    data["graphTable"][
                                        verseusSavingsRadioButtonVale
                                    ]
                                        ? data["graphTable"][
                                              verseusSavingsRadioButtonVale
                                          ]["OnDemand Monthly"]
                                        : data["graphTable"]["OnDemand Monthly"]
                                }}
                            </span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!-- DESCRIPTION -->

            <div #descriptionContainer>
                <mat-expansion-panel
                    hideToggle="true"
                    #description
                    [expanded]="true"
                    (opened)="changeParentTab('description')"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title> Description </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!description.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="description.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="tw-flex tw-flex-col modal-description">
                        <table class="tw-border-collapse tw-m-2 tw-p-1">
                            <tr
                                class="tw-p-1"
                                *ngFor="
                                    let k of objectKeys(
                                        data['generalInformation']
                                    )
                                "
                            >
                                <th
                                    class="tw-p-1 tw-text-fs-200 tw-text-center tw-font-headings last-th"
                                    *ngIf="
                                        !isV2 ||
                                        !isObject(data['generalInformation'][k])
                                    "
                                >
                                    {{ k }}
                                </th>
                                <ng-container
                                    *ngIf="
                                        isObject(data['generalInformation'][k])
                                    "
                                >
                                    <td
                                        class="tw-py-2 tw-px-1 tw-text-fs-200"
                                        colspan="2"
                                        *ngIf="!isV2"
                                    >
                                        <ul class="tw-list-none">
                                            <li
                                                *ngFor="
                                                    let i of objectKeys(
                                                        data[
                                                            'generalInformation'
                                                        ][k]
                                                    )
                                                "
                                            >
                                                <strong>{{ i }} =></strong>
                                                {{ data["Current"][k][i] }}
                                            </li>
                                        </ul>
                                    </td>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        !isObject(data['generalInformation'][k])
                                    "
                                >
                                    <td
                                        class="tw-py-2 tw-px-1 tw-text-fs-200"
                                        colspan="2"
                                    >
                                        {{ data["generalInformation"][k] }}
                                    </td>
                                </ng-container>
                            </tr>
                            <ng-container *ngIf="isV2">
                                <tr class="version-2-table-row">
                                    <td></td>
                                    <td>Best Savings</td>
                                    <td>Full RI Coverage</td>
                                </tr>
                                <tr
                                    class="version-2-table-rest-rows"
                                    *ngFor="
                                        let item of objectKeys(
                                            data['generalInformation'][
                                                'Best Savings'
                                            ]
                                        )
                                    "
                                >
                                    <th>{{ item }}</th>
                                    <td>
                                        {{
                                            data["generalInformation"][
                                                "Best Savings"
                                            ][item]
                                        }}
                                    </td>
                                    <td
                                        *ngIf="
                                            data['generalInformation'] &&
                                            data['generalInformation'][
                                                'Full RI Coverage'
                                            ]
                                        "
                                    >
                                        {{
                                            data["generalInformation"][
                                                "Full RI Coverage"
                                            ][item]
                                        }}
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>
</div>

<ng-template #displayHyphen> - </ng-template>
