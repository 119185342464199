import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emptyObject'
})
export class EmptyObjectPipe implements PipeTransform {
    transform(value): boolean {
        return !(Object.values(value).length > 0);
    }
}
