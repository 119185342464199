export class AssessmentConfiguration {
    /**
     * To hide score in auditor recommandation section in MS support audit
     */
    static readonly hideScoreInAttributes = [
        'Strengths',
        'Opportunities for Improvement (OFI)',
        'Action Items (AI)',
    ].map((attr) => attr.toLowerCase());
}
