import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';

/** This validator detects number of addresses between two ip's.
 * @fieldName excepts one fieldName to detect ip of other field.
 * @addressRange for maximum number of ip addresses
 * @initialIp if entered ip is start ip address or end ip address.
 */
export class IPRange extends CustomValidator {
    key = CustomValidatorKey.IP_RANGE;

    constructor(
        private fieldName: string,
        private addressesRange: number,
        private initialIP
    ) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return null;
        }

        const IPv4 =
            /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2])){0,1}$/;
        const err = { [this.key]: true };

        const startIP = this.initialIP
            ? control.value
            : this.formGenRef.formGroup.get(this.fieldName).value;
        const endIP = !this.initialIP
            ? control.value
            : this.formGenRef.formGroup.get(this.fieldName).value;

        if (startIP === '0.0.0.0' || endIP === '0.0.0.0') return err;
        if (control.value === startIP && !IPv4.test(startIP)) return err;
        if (control.value === endIP && !IPv4.test(endIP)) return err;
        if (!startIP || !endIP) return;

        const compareIP = (startIP, endIP) => {
            startIP = startIP.split('.').map(function (str) {
                return parseInt(str);
            });
            endIP = endIP.split('.').map(function (str) {
                return parseInt(str);
            });

            for (let i = 0; i < 4; i++) {
                if (endIP[i] > startIP[i]) {
                    return true;
                } else if (endIP[i] < startIP[i]) {
                    return false;
                }
            }
        };

        if (!compareIP(startIP, endIP)) return err;

        const countIp = (startIP, endIP) => {
            let addressesCount = 0;
            const startIPValues = startIP.split('.');
            const endIPValues = endIP.split('.');

            for (let i = 0; i < 4; i++) {
                if (
                    isNaN(startIPValues[i]) ||
                    isNaN(endIPValues[i]) ||
                    startIPValues[i] < 0 ||
                    startIPValues[i] > 255 ||
                    endIPValues[i] < 0 ||
                    endIPValues[i] > 255
                ) {
                    return err;
                }
                addressesCount +=
                    (startIPValues[i] - endIPValues[i]) * (256 * (3 - i));
            }
            return Math.abs(addressesCount);
        };

        if (countIp(startIP, endIP) > this.addressesRange) return err;
        return;
    }
}
