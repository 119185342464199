<div class="container">
    <ng-container *ngIf="infoData && !loader">
        <p class="heading">Widget Info</p>
        <div class="flex-container">
            <table>
                <tr>
                    <td>Widget Name:</td>
                    <td>{{ infoData.name ? infoData.name : "" }}</td>
                </tr>
                <tr>
                    <td>Documentation Status:</td>
                    <td
                        [ngClass]="
                            infoData.documentStatus === 'Active' ||
                            infoData.documentStatus === 'Published'
                                ? 'success'
                                : 'danger'
                        "
                    >
                        {{
                            infoData.documentStatus === "Active"
                                ? "Published"
                                : infoData.documentStatus
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Widget Owner:</td>
                    <td>
                        {{ infoData.widgetOwner ? infoData.widgetOwner : "" }}
                    </td>
                </tr>
                <tr>
                    <td>Documentation Created By:</td>
                    <td>
                        {{ infoData.createdBy ? infoData.createdBy : "" }}
                    </td>
                </tr>

                <tr>
                    <td>Documentation Created On:</td>
                    <td>
                        {{ infoData.createdOn ? infoData.createdOn : "" }}
                    </td>
                </tr>

                <tr>
                    <td>Last Updated By:</td>
                    <td>
                        {{ infoData.updatedBy ? infoData.updatedBy : "" }}
                    </td>
                </tr>

                <tr>
                    <td>Last Updated On:</td>
                    <td>
                        {{ infoData.updatedOn ? infoData.updatedOn : "" }}
                    </td>
                </tr>
            </table>
        </div>

        <p class="heading clear-fix">Documentation Preview</p>

        <div class="flex-container">
            <table>
                <tbody>
                    <tr>
                        <td>Widget Description</td>
                        <td>
                            <p
                                class="info clickable inline"
                                (click)="openDescription()"
                                [class.disabled]="
                                    loaderGroup.description ||
                                    loaderGroup.detailedDescription
                                "
                            >
                                View &nbsp;
                                <span *ngIf="loaderGroup.description">
                                    <mat-spinner
                                        class="dark-tdeme loader"
                                        [diameter]="16"
                                    ></mat-spinner>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>Widget More Information</td>
                        <td class="">
                            <p
                                class="info clickable inline"
                                [class.disabled]="
                                    loaderGroup.description ||
                                    loaderGroup.detailedDescription
                                "
                                (click)="openMoreInfo()"
                            >
                                View &nbsp;
                                <span *ngIf="loaderGroup.detailedDescription">
                                    <mat-spinner
                                        class="dark-tdeme loader"
                                        [diameter]="16"
                                    ></mat-spinner>
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <div class="spinner" *ngIf="loader">
        <mat-spinner class="dark-tdeme loader" [diameter]="25"></mat-spinner>
    </div>
</div>
