import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';

@Component({
    selector: 'app-autotask-parent-integration-step-two',
    templateUrl: './autotask-parent-integration-step-two.component.html',
    styleUrls: ['./autotask-parent-integration-step-two.component.sass']
})
export class AutotaskParentIntegrationStepTwoComponent implements OnInit {
    configurationFormGenInput: IFormGeneratorInput = null;
    configurationFormGroup: FormGroup;
    isChildIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response;
    credentialsFormGroup;
    apiResponse;
    widgetRef: Widget;
    resetSubscription: Subscription;
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.response = modalInputData.data['response'];
        this.isOwnerIntegrated =
            modalInputData.data['response']['ownerIntegration'];
        this.isChildIntegrated =
            modalInputData.data['response']['childIntegration'];
        this.isParentIntegrated =
            modalInputData.data['response']['parentIntegration'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.credentialsFormGroup = this.multiStepFormService.stepData
            .get(modalInputData.modalId)
            .get(1)['formGroup'];
        this.apiResponse = this.multiStepFormService.stepData
            .get(modalInputData.modalId)
            .get(1)['response'];
    }

    ngOnInit(): void {
        this.configurationFormGenInput = {
            formName: 'Autotask',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Save',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    Helper.markAllFieldAsTouched(this.configurationFormGroup);
                    if (this.configurationFormGroup.invalid) {
                        this.configurationFormGroup.updateValueAndValidity();
                        return;
                    }
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        if (this.isParentIntegrated) {
                            // Update
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.update
                            );
                            args.input = {
                                ...formGroup.value,
                                ...this.credentialsFormGroup.value,
                                integrationType: IntegrationName.AUTOTASK,
                                childIntegration: 'false',
                                parentIntegration: 'true',
                                getFieldFlag: 'false'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Autotask configuration updated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error updating autotask configuration'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        } else {
                            // Create
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.create
                            );
                            args.input = {
                                ...formGroup.value,
                                ...this.credentialsFormGroup.value,
                                integrationType: IntegrationName.AUTOTASK,
                                childIntegration: 'false',
                                parentIntegration: 'true',
                                getFieldFlag: 'false'
                            };
                            args.function = (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Autotask integrated successfully'
                                );
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    this.modalInputData.modalId
                                );
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error integrating autotask'
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                }
            },
            fields: [
                {
                    name: 'queueId',
                    placeholder: 'Select Queues',
                    label: 'Queue',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    listData: this.apiResponse['autotaskInfo']
                        ? this.apiResponse['autotaskInfo']['queueId']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['selfConfiguredFeilds']['queueId']
                        : '',
                    getKey: null
                },
                {
                    name: 'source',
                    placeholder: 'Select Source',
                    label: 'Source',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    listData: this.apiResponse['autotaskInfo']
                        ? this.apiResponse['autotaskInfo']['source']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Source is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['selfConfiguredFeilds']['source']
                        : '',
                    getKey: null
                },
                {
                    name: 'priority',
                    placeholder: 'Select Priority',
                    label: 'Priority',
                    showLabel: true,
                    appearance: 'legacy',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    listData: this.apiResponse['autotaskInfo']
                        ? this.apiResponse['autotaskInfo']['priority']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    value: this.isParentIntegrated
                        ? this.response['selfConfiguredFeilds']['priority']
                        : '',
                    getKey: null
                }
            ],
            extraClass: 'integration-styling',
            backButton: {
                buttonName: 'Back',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: () => {
                    this.multiStepFormService.previousStep(
                        this.modalInputData.modalId
                    );
                }
            }
        };
        this.resetSubscription =
            this.widgetRef.modalService.resetModal.subscribe(() => {
                this.configurationFormGroup.reset();
            });
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
