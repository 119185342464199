import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GraphGeneratorComponent } from '../../components/graph-generator/graph-generator.component';
import { SemiPieComponent } from '../../components/semi-pie/semi-pie.component';
import { MapChartComponent } from './../../components/map-chart/map-chart.component';
import { PieGraphComponent } from './../../components/pie-graph/pie-graph.component';
import { RadarChartComponent } from './../../components/radar-chart/radar-chart.component';
import { StackBarGraphComponent } from './../../components/stack-bar-graph/stack-bar-graph.component';
import { XyChartComponent } from './../../components/xy-chart/xy-chart.component';
import { CommonPipesModule } from './../common-pipes/common-pipes.module';

const components = [
    GraphGeneratorComponent,
    XyChartComponent,
    MapChartComponent,
    PieGraphComponent,
    RadarChartComponent,
    StackBarGraphComponent,
    SemiPieComponent
];
const modules = [CommonModule, CommonPipesModule];
@NgModule({
    declarations: [...components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [...modules],
    exports: [...components]
})
export class GraphModule {}
