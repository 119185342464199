import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { FiltersHttpService } from 'src/app/shared/services/http/filters-http/filters-http.service';
import { HitApi } from '../../../classes/HitApi';
import { IDropdownData } from '../../../interfaces/dropdown-data/IDropdownData';
import { IHitApi } from '../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.sass']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
    @Input() filterInfo: IFilterInfo;
    @Input() selectedValues: string[];
    @Input() loadingMessage: string;
    @Input() isCaching: boolean;

    @Input() refreshListing: BehaviorSubject<boolean>;
    @Input() disabled: boolean;
    @Input() disableMessage: string;
    @Input() apiInputCallback: Function;
    @Input() appendTo: string;
    @Input() dummy: boolean;
    // tslint:disable-next-line: no-output-native
    @Output() change = new EventEmitter();
    @Output() afterResponse = new EventEmitter();
    showSelectAll = true;
    bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
    dataBuffer: IDropdownData[] = [];
    uniqueIdentity: symbol;
    filteredList: IDropdownData[] = [];
    showLoading = false;
    loading = false;
    valueChanges = false;
    selectedItems = [];
    isOpen = false;
    tempFilteredList: IDropdownData[] = [];
    previousSearchData: Map<string, IDropdownData[]> = new Map();
    constructor(
        private filtersHttpService: FiltersHttpService,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.handleBasics();
        this.tempFilteredList = [...this.filteredList];
    }

    handleBasics() {
        if (this.selectedValues) {
            this.selectedItems = this.selectedValues;
        } else {
            this.selectedValues = [];
            this.selectedItems = [];
        }

        if (this.filterInfo.listData) {
            this.filteredList = this.filterInfo.listData;
        }
        this.tempFilteredList = [...this.filteredList];
        if (this.filterInfo.selectionLimit) {
            this.showSelectAll = false;
        }

        if (this.refreshListing) {
            this.refreshListing.subscribe((res) => {
                if (res) {
                    if (this.filterInfo.apiInfo) {
                        this.hitApi();
                    }
                    this.selectedValues = [];
                    this.selectedItems = [];
                }
            });
        }

        if (this.filterInfo.apiInfo) {
            this.hitApi();
        }
        this.change.emit(this.selectedValues);
    }

    ngOnChanges(change) {
        if (change.filterInfo && !change.filterInfo.firstChange) {
            this.filterInfo = change.filterInfo.currentValue;
            this.handleBasics();
        }
        if (change.selectedValues) {
            this.selectedValues = change.selectedValues.currentValue;
            this.selectedItems = change.selectedValues.currentValue;
        }
    }

    selectionChange($event) {
        if (
            this.filterInfo.selectionLimit &&
            $event.length > this.filterInfo.selectionLimit
        ) {
            return;
        }
        if (!this.filterInfo.getFullObj) {
            this.selectedItems = $event.map((item) => item.id);
            this.selectedValues = $event.map((item) => item.id);
        } else {
            this.selectedValues = $event;
            this.selectedItems = $event;
        }
        this.valueChanges = true;
    }

    onScrollToEnd() {
        this.fetchMore();
    }

    onScroll({ end }) {
        if (
            this.loading ||
            (this.filteredList &&
                this.filteredList.length <= this.dataBuffer.length)
        ) {
            return;
        }
        if (
            end + this.numberOfItemsFromEndBeforeFetchingMore >=
            this.dataBuffer.length
        ) {
            this.fetchMore();
        }
    }
    private fetchMore() {
        const len = this.dataBuffer.length;
        const more = this.filteredList.slice(len, this.bufferSize + len);
        this.loading = true;
        // using timeout here to simulate backend API delay
        this.loading = false;
        this.dataBuffer = this.dataBuffer.concat(more);
    }

    onSelectAll(event) {
        this.valueChanges = true;
        if (event === 'selectAll') {
            const selectedItemsId = this.selectedValues.map((item) =>
                this.filterInfo.getFullObj ? item['id'] : item
            );
            const selectedItems: IDropdownData[] = this.filteredList.filter(
                (item) => {
                    if (!selectedItemsId.includes(item.id)) {
                        return item;
                    }
                }
            );
            if (this.filterInfo.getFullObj) {
                const selected = Helper.cloneDeep(selectedItems);
                this.selectedValues = [...this.selectedValues, ...selected];
                this.selectedItems = [...this.selectedItems, ...selected];
            } else {
                const selected = selectedItems.map((item) => item.id);
                this.selectedValues = [...this.selectedValues, ...selected];
                this.selectedItems = [...this.selectedItems, ...selected];
            }
        } else {
            this.selectedValues = [];
            this.selectedItems = [];
        }
    }

    emitChange(type) {
        if (type === 'close') {
            if (this.valueChanges) {
                this.change.emit(this.selectedValues);
                this.valueChanges = false;
            }
        } else if (type === 'clear') {
            this.selectedValues = [];
            this.change.emit(this.selectedValues);
        }
    }

    hitApi() {
        this.filteredList = [];
        this.loading = true;
        this.showLoading = true;
        if (this.filterInfo.apiInfo) {
            const args: IHitApi = Helper.generateHitApiConfig(
                this.filterInfo.apiInfo
            );
            args.config.isCached = this.isCaching;
            args.input = this.apiInputCallback ? this.apiInputCallback() : {};
            args.function = this.bindData.bind(this);
            args.endFunction = () => {
                this.showLoading = false;
                this.loading = false;
            };
            new HitApi(args, this.filtersHttpService, this.ngZone).hitApi();
        }
    }

    bindData(response) {
        this.showLoading = false;
        this.loading = false;
        if (Array.isArray(response)) {
            this.filteredList = response;
        } else {
            this.filteredList = this.filterInfo.getKey
                ? response[this.filterInfo.getKey]
                : this.filterInfo.getKey === null
                ? response
                : response.dataList;
        }
        this.tempFilteredList = [...this.filteredList];

        this.afterResponse.emit(this.filteredList);
        this.cd.detectChanges();
    }

    showTooltip(item) {
        if (item['hoveredText']) {
            return item['hoveredText'];
        } else if (item['hoveredJSON']) {
            let text = '';
            Object.keys(item['hoveredJSON']).forEach((key) => {
                text += key + ': ' + item['hoveredJSON'][key] + ' \n ';
            });
            return text;
        } else {
            return '';
        }
    }
    onSearch(event) {
        if (event.term === '') {
            this.initValue();
        } else if (this.previousSearchData.has(event.term)) {
            this.filteredList = [...this.previousSearchData.get(event.term)];
        } else {
            this.filteredList = [...event.items];
            this.previousSearchData.set(event.term, event.items);
        }
    }
    initValue() {
        this.filteredList = [...this.tempFilteredList];
    }
}
