import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';

@Component({
    selector: 'app-service-now-variables',
    templateUrl: './service-now-variables.component.html',
    styleUrls: ['./service-now-variables.component.sass']
})
export class ServiceNowVariablesComponent implements OnInit {
    defDate = new Date();
    variableList = null;
    variableResp = {};
    variableIdResp = {};
    loader = false;
    varIdVisibilityMap = {};
    mandatoryMap = {};
    removeConditionsVarId = ['8b1cf0bddb0bc850b3da126b3a9619f0'];
    stepData;
    buttonInput: IButtonGeneratorInput;

    constructor(
        private modalInputData: ModalInjectedData,
        private notificationService: NotificationsService
    ) {}

    ngOnInit() {
        if (this.modalInputData && this.modalInputData.data) {
            this.stepData = this.modalInputData.data;
        }

        this.variableList = this.conditionsReMap(this.stepData['variableList']);
        this.defValuesSet();
        this.generateVisibilityMap();
        this.buttonInput = {
            buttonName: 'Create Request',
            function: this.createRequest.bind(this),
            showLoader: true,
            buttonType: ButtonType.RAISED,
            buttonColorType: ButtonColorType.PRIMARY
        };
    }

    conditionsReMap(variableList) {
        variableList = variableList.map((varr) => {
            if (varr['conditions']) {
                const newCons = [];
                varr['conditions'].forEach((con) => {
                    if (con.includes('IN') && con.includes(',')) {
                        const splitted = con.split('IN');
                        const varId = splitted[0];
                        const vals = splitted[1].split('^')[0];
                        vals.split(',').forEach((val) => {
                            const newCon = `${varId}=${val}^EQ`;
                            newCons.push(newCon);
                        });
                    } else {
                        newCons.push(con);
                    }
                });
                varr['conditions'] = newCons;
                if (
                    varr['varId'] &&
                    this.removeConditionsVarId.includes(varr['varId'])
                ) {
                    varr['conditions'] = null;
                }
            }
            return varr;
        });
        return variableList;
    }

    createRequest() {
        this.loader = true;
        Object.keys(this.varIdVisibilityMap).every((varId) => {
            if (this.varIdVisibilityMap[varId] && this.mandatoryMap[varId]) {
                if (!(varId in this.variableIdResp)) {
                    this.loader = false;
                    return false;
                } else if (this.variableIdResp[varId] === null) {
                    this.loader = false;
                    return false;
                } else if (
                    typeof this.variableIdResp[varId] === 'string' &&
                    !this.variableIdResp[varId].trim()
                ) {
                    this.loader = false;
                    return false;
                } else if (
                    Array.isArray(this.variableIdResp[varId]) &&
                    !Array.from(this.variableIdResp[varId]).length
                ) {
                    this.loader = false;
                    return false;
                }
            }
            return true;
        });

        if (!this.loader) {
            this.notificationService.showSnackBar(
                'All fields are necessary',
                true
            );
            return;
        }

        this.stepData['submitFuntion'](
            this.stepData['tableRowData'],
            this.variableResp
        );
    }

    defValuesSet() {
        this.variableList.forEach((varr) => {
            this.variableResp[varr['id']] = null;
            this.mandatoryMap[varr['varId']] = varr['mandatory'];
        });

        this.variableList.forEach((varr) => {
            if (varr['type'] === 'checkbox') {
                this.variableResp[varr['id']] = false;
            }
        });
    }

    generateVisibilityMap() {
        this.variableList.forEach(
            (varr) =>
                (this.varIdVisibilityMap[varr['varId']] = this.isVisible(varr))
        );
    }

    isVisible(varr) {
        if (varr['conditions']) {
            let externalOR = false;
            const conditions = varr['conditions'];
            conditions.forEach((condition) => {
                let internalAND = true;
                condition.split('IO:').forEach((con) => {
                    if (con) {
                        const varId = con.split('=')[0];
                        if (varId in this.variableIdResp) {
                            const val = `${varId}=${this.variableIdResp[varId]}^`;
                            if (con.includes(val)) {
                                internalAND = internalAND && true;
                            } else {
                                internalAND = internalAND && false;
                            }
                        } else {
                            internalAND = internalAND && false;
                        }
                    }
                });

                externalOR = externalOR || internalAND;
            });

            return externalOR;
        }

        return true;
    }
}
