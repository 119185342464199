export enum Clouds {
    AWS = 'aws',
    AZURE_CSP = 'azure-csp',
    SPONSORSHIP = 'azure-sponsorship',
    PAY_AS_YOU_GO = 'azure-pay-as-you-go',
    AZURE_PLAN = 'azure-plan',
    AZURE_EA = 'azure-ea',
    AZURE_MCA = 'azure-mca',
    GCP = 'gcp'
}
