import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IUserProfileApiResponse } from 'src/app/shared/interfaces/api/portlets/IUserProfileApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { HitApi } from './../../../../classes/HitApi';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit, AfterViewInit {
    // USER_PROFILE: 1.0

    widgetRef: Widget;
    toggle: boolean;
    flag: boolean;

    personalInfoFormGenInput: IFormGeneratorInput = null;
    resetPersonalInfo: any;
    credentialsFormGenInput: IFormGeneratorInput = null;
    changeCredentialFormGenInput: IFormGeneratorInput = null;
    changeCredentialFormGroup: FormGroup;
    FormState = FormState;

    credsUpdateButton: IButtonGeneratorInput = {
        buttonName: 'Update',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        function: null
    };

    constructor(
        widgetData: WidgetInjectedData,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        // this.widgetRef.showViewIcon.next(true);

        // Update Header Icon
        this.widgetRef.headerIcon.next({
            class: 'fas fa-user-alt',
            type: IconType.FONTAWSOME
        });
    }

    bindData(data: IUserProfileApiResponse) {
        this.changeCredentialFormGenInput = {
            formName: 'Change Credentials',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Old Password',
                    placeholder: 'Old Password',
                    name: 'oldPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Old password is required'
                        }
                    ]
                },
                {
                    label: 'New Password',
                    placeholder: 'New password',
                    name: 'newPassword',

                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: Helper.getPasswordValidators(
                        'unequal',
                        'oldPassword'
                    )
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Confirm password',
                    name: 'confirmPassword',

                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: Helper.getPasswordValidators(
                        'equal',
                        'newPassword'
                    )
                }
            ]
        };
        this.credentialsFormGenInput = {
            formName: 'Credential Info',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Password',
                    placeholder: '********',
                    name: 'password',
                    value: '**********',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    }
                }
            ]
        };

        this.personalInfoFormGenInput = {
            formName: 'Personal Info',
            state: FormState.IDLE,
            submitButton: {
                buttonName: 'Update',
                showLoader: true,
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    this.updateUserDetails(formGroup.value);
                }
            },
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: data.email ? data.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ],
                    disabled: true
                },
                {
                    label: 'First Name',
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: data.firstName ? data.firstName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First name is required'
                        }
                    ]
                },
                {
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: data.lastName ? data.lastName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last name is required'
                        }
                    ]
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Phone Number',
                    name: 'phoneNumber',
                    value: data.phoneNumber ? data.phoneNumber : '',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'call'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Phone number is required'
                        }
                    ]
                }
            ]
        };

        this.widgetRef.endLoader();
    }
    changePersonalInfoState(state: FormState) {
        if (this.resetPersonalInfo) {
            this.resetPersonalInfo();
        }
        this.personalInfoFormGenInput.state = state;
        this.personalInfoFormGenInput = Helper.dereference(
            this.personalInfoFormGenInput
        );
    }
    changeCredentialsState(state: FormState) {
        this.credentialsFormGenInput.state = state;
        this.credentialsFormGenInput = Helper.dereference(
            this.credentialsFormGenInput
        );
    }
    updateUserDetails(form) {
        const editUserInputJson = form;
        const editApiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        editApiArgs.input = editUserInputJson;
        editUserInputJson[
            'phoneNumber'
        ] = `${editUserInputJson['phoneNumber']}`;
        editApiArgs.function = (response) => {
            this.notificationService.showSnackBar(
                'User details successfully updated'
            );
            this.widgetRef.refreshWidget();
        };
        editApiArgs.errorFunction = (error) => {
            this.notificationService.showSnackBar(
                error && error.error
                    ? error.error.message
                    : 'Error updating user details',
                true
            );

            this.widgetRef.refreshWidget();
        };
        new HitApi(
            editApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    updatePassword(buttonRef?: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.changeCredentialFormGroup);
        this.changeCredentialFormGroup
            .get('confirmPassword')
            .updateValueAndValidity();
        if (this.changeCredentialFormGroup.invalid) {
            this.changeCredentialFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const editUserPassword = this.changeCredentialFormGroup.value;
        const pwdApiArgs: IHitApi = Helper.generateHitApiConfig({
            apiRouteSuffix: ApiUrls.CHANGE_PASSWORD_API_PATH,
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null,
            host: ''
        });
        pwdApiArgs.input = editUserPassword;
        pwdApiArgs.function = (response) => {
            buttonRef.loader = false;
            this.notificationService.showSnackBar(
                'User password successfully updated'
            );
            this.widgetRef.refreshWidget();
        };
        pwdApiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.notificationService.showSnackBar(
                error && error.error
                    ? error.error.message
                    : 'Error updating user password',
                true
            );
            this.widgetRef.refreshWidget();
        };
        new HitApi(
            pwdApiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
