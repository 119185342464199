<div class="form-container">
    <app-form-generator
        *ngIf="zendeskFormGenInputs"
        [formGeneratorInput]="zendeskFormGenInputs"
        (formGroupRef)="zendeskFormGroup = $event"
        (updateControl)="updateControl = $event"
    ></app-form-generator>
    <div class="overlay" *ngIf="modalLoader">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
</div>

<app-button-generator [buttonGenInput]="buttonGenInput"></app-button-generator>
