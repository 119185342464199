<div
    class="controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        widgetRef.visibleSections.value.has(ViewType.CARDS) &&
        cardsData &&
        cardsData.length > 0
    "
>
    <div
        matTooltip="Recommendation Count"
        matTooltipPosition="below"
        class="tw-flex tw-w-8 tw-h-8 tw-ml-6 tw-justify-center tw-items-center tw-rounded-full balls green-circle tool-with-tooltip"
    >
        <span class="tw-text-fs-200 number">{{ recommendationCount }}</span>
    </div>
    <div class="table-search" *ngIf="!widgetRef.lightState">
        <app-table-search
            [data]="cardsData"
            (changed)="filteredCardsData = $event"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        widgetRef.visibleSections.value.has(ViewType.CARDS) &&
        cardsData &&
        cardsData.length > 0
    "
>
    <div
        class="tw-flex tw-w-full tw-overflow-y-auto tw-max-h-96 cards-container"
    >
        <div
            *ngFor="let card of filteredCardsData; let i = index"
            class="tw-w-full tw-overflow-x-auto tw-m-5 tw-rounded-md tw-flex tw-mb-2 tw-items-center tw-justify-between tw-cursor-pointer card-content"
            (click)="
                widgetRef.lightState
                    ? i < widgetRef.liteViewLimit
                        ? showrecommendationModal(card)
                        : null
                    : showrecommendationModal(card)
            "
            [class.getFullAccessState]="
                widgetRef.lightState && i >= widgetRef.liteViewLimit
            "
        >
            <div
                class="tw-flex-1 tw-py-2 tw-px-3 item"
                *ngFor="
                    let key of dataMap['displayEntry']
                        ? dataMap['displayEntry']
                        : objectKeys(card['displayEntry'])
                "
                [class.saving]="key.includes('Savings')"
            >
                <div class="tw-text-fs-400 tw-font-semibold name">
                    {{ key }}
                </div>
                <span class="tw-text-fs-200 tw-font-light tw-break-all value">{{
                    card["displayEntry"][key]
                }}</span>
            </div>
        </div>
    </div>
</div>
