import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-automation-modal-configure-notification',
    templateUrl: './automation-modal-configure-notification.component.html',
    styleUrls: ['./automation-modal-configure-notification.component.sass']
})
export class AutomationModalConfigureNotificationComponent implements OnInit {
    IntegrationName = IntegrationName;
    initialSelection: IntegrationName[] = [];
    selectedIntegrations: Map<IntegrationName, IntegrationData> = new Map();
    availableSupportedFormats: Set<SupportedFormat> = new Set();
    widgetFilters: {
        heading: string;
        filters: string[];
    }[] = null;
    notificationFormGenInput: IFormGeneratorInput = null;
    notificationFormGroup: FormGroup = null;
    emailFormGenInput: IFormGeneratorInput = null;
    emailFormGroup: FormGroup = null;
    slackFormGenInput: IFormGeneratorInput = null;
    slackFormGroup: FormGroup = null;
    freshdeskFormGenInput: IFormGeneratorInput = null;
    freshdeskFormGroup: FormGroup = null;
    freshserviceFormGenInput: IFormGeneratorInput = null;
    freshserviceFormGroup: FormGroup = null;
    zendeskFormGenInput: IFormGeneratorInput = null;
    zendeskFormGroup: FormGroup = null;
    serviceNowFormGenInput: IFormGeneratorInput = null;
    serviceNowFormGroup: FormGroup = null;
    jiradeskFormGenInput: IFormGeneratorInput = null;
    jiradeskFormGroup: FormGroup = null;
    snsFormGenInput: IFormGeneratorInput = null;
    snsFormGroup: FormGroup = null;

    edit = false;
    notificationData = null;
    sendingMechanism = null;
    notificationFilters = null;
    widgetRef: Widget = null;
    defaultEmails;
    defaultCopyEmails;
    defaultBcopyEmails;

    serviceNowIncidentPriority: IDropdownData[] = [
        {
            id: '1 - Critical',
            label: 'Critical'
        },
        {
            id: '2 - High',
            label: 'High'
        },
        {
            id: '3 - Moderate',
            label: 'Moderate'
        },
        {
            id: '4 - Low',
            label: 'Low'
        },
        {
            id: '5 - Planning',
            label: 'Planning'
        }
    ];
    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: () => {
            this.multiStepFormService.previousStep(this.modalInputData.modalId);
        }
    };
    updateCreateButton: IButtonGeneratorInput;

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private listHttpService: ListHttpService,
        private ngZone: NgZone,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService
    ) {}

    ngOnInit() {
        this.widgetRef = this.modalInputData.data.widgetRef;
        const generalConfig = this.configCache.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCache.viewId
        );
        if (generalConfig) {
            this.defaultEmails = generalConfig.primaryEmail?.emails
                ? generalConfig.primaryEmail.emails
                : [];
            this.defaultCopyEmails = generalConfig.primaryEmail?.copyEmails
                ? generalConfig.primaryEmail.copyEmails
                : [];
            this.defaultBcopyEmails = generalConfig.primaryEmail?.bcopyEmails
                ? generalConfig.primaryEmail.bcopyEmails
                : [];
        }
        this.widgetFilters =
            this.widgetRef.widgetData.widgetInfo.schedulingAutomation[
                'widgetFilters'
            ];
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData']
        ) {
            this.notificationData = this.modalInputData.data['stepData'];
            this.notificationFilters =
                this.notificationData['automationFilter'];
            this.sendingMechanism =
                this.notificationFilters['sendingMechanism'];
            this.edit = true;
            if (
                this.sendingMechanism &&
                this.sendingMechanism['sendViaEmail']
            ) {
                this.initialSelection.push(IntegrationName.EMAIL);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['o365IntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.O365);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['gmailIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.GMAIL);
            }
            if (
                (this.sendingMechanism &&
                    this.sendingMechanism['slackUsersIds'] &&
                    this.sendingMechanism['slackUsersIds'].length) ||
                (this.sendingMechanism &&
                    this.sendingMechanism['slackChannelIds'] &&
                    this.sendingMechanism['slackChannelIds'].length)
            ) {
                this.initialSelection.push(IntegrationName.SLACK);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['freshDeskIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.FRESHDESK);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['freshServiceIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.FRESHSERVICE);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['zenDeskIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.ZENDESK);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['serviceNowIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.SERVICENOW);
            }
            if (
                this.sendingMechanism &&
                this.sendingMechanism['jiraDeskIntegrationId']
            ) {
                this.initialSelection.push(IntegrationName.JIRADESK);
            }
            if (this.sendingMechanism && this.sendingMechanism['snsRequest']) {
                this.initialSelection.push(IntegrationName.SNS);
            }
        } else {
            this.initialSelection = [IntegrationName.EMAIL];
        }

        // if (!this.sendingMechanism) {
        //     this.initialSelection = [IntegrationName.EMAIL];
        // }
        // Notification Form Gen Input
        this.notificationFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'notifyType',
                    label: 'Get Notified',
                    placeholder: 'Get Notified',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    value:
                        this.edit &&
                        this.notificationFilters &&
                        this.notificationFilters['notifyType']
                            ? this.notificationFilters['notifyType']
                            : 'HOURLY',
                    listData: [
                        {
                            id: 'HOURLY',
                            label: 'Hourly'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Notification frequency is required'
                        }
                    ],
                    prefixIcon: {
                        hoverText: 'Click here for more info',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Notification Validations',
                                content: [
                                    {
                                        type: 'PARAGRAPH',
                                        data: [
                                            `When you configure notification for an automation rule, the no. of hours that you input in “get notified before” must be less than the scheduled rule’s frequency.`
                                        ]
                                    },
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `<b>Hourly Frequency:</b><br />Consider a rule which repeats in every 4 hours, starting at 12:00 pm. You can configure notification to be received before 3 hours or less than 3 hours as it must be less than the schedule rule’s frequency.`,
                                            `<b>Daily Frequency:</b><br />Consider a rule which repeats in every 1 day, starting at 12:00 pm. You can configure notification to be received before 23 hours or less than 23 hours as it must be less than the schedule rule’s frequency.`,
                                            `<b>Weekly Frequency:</b><br />Case 1: Consider a rule which repeats on every Monday, starting at 12:00 pm. You can configure notification to be received before 6 days (167 hours) or less than 6 days (167 hours) as it must be less than the schedule rule’s frequency.<br />Case 2: Consider a rule which occurs on every Monday, Wednesday and Saturday, starting at 12:00 pm. Since the minimum gap between the occurrence of rule is 2 days, you can configure notification to be received before 1 day (47 hours) or less than 1 day (47 hours) as it must be less than the schedule rule’s frequency.`,
                                            `<b>Monthly Frequency:</b><br />Case 1: Consider a rule which repeats on 2nd of every month, starting at 12:00 pm. You can configure notification to be received before 29 days (719 hours) or less than 29 days (719 hours) as it must be less than the schedule rule’s frequency.<br />Case 2: Consider a rule which occurs on 5th, 10th, 15th and 27th of every month, starting at 12:00 pm. Since the minimum gap between the occurrence of rule is 5 days, you can configure notification to be received before 4 days (119) or less than 4 days (119 hours) as it must be less than the schedule rule’s frequency.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                },
                {
                    name: 'notifyBefore',
                    label: 'Get Notified Before',
                    placeholder: 'Get Notified Before ( Hour(s) )',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    value:
                        this.edit &&
                        this.notificationFilters &&
                        this.notificationFilters['notifyBefore']
                            ? this.notificationFilters['notifyBefore']
                            : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'This field is required'
                        }
                    ]
                }
            ]
        };

        // Email Form Gen Input
        this.emailFormGenInput = {
            formName: 'Email Form',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'emailTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['emailTo'] &&
                        this.sendingMechanism['emailRequest']['emailTo'].length
                            ? this.sendingMechanism['emailRequest']['emailTo']
                            : this.defaultEmails
                            ? this.defaultEmails
                            : [],
                    label: 'To',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Atleast one recipients is required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'copyTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'CC',
                    placeholder: 'example@domain.com',
                    required: false,
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['copyTo'] &&
                        this.sendingMechanism['emailRequest']['copyTo'].length
                            ? this.sendingMechanism['emailRequest']['copyTo']
                            : this.defaultCopyEmails
                            ? this.defaultCopyEmails
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'bcc',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'BCC',
                    placeholder: 'example@domain.com',
                    required: false,
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['bcc'] &&
                        this.sendingMechanism['emailRequest']['bcc'].length
                            ? this.sendingMechanism['emailRequest']['bcc']
                            : this.defaultBcopyEmails
                            ? this.defaultBcopyEmails
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                }
            ]
        };

        // Slack Form Gen Input
        this.slackFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Users',
                    name: 'users',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Users',
                    required: true,
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['slackUsersIds'] &&
                        this.sendingMechanism['slackUsersIds'].length
                            ? this.sendingMechanism['slackUsersIds']
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Select atleast one user'
                        }
                    ],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_USERS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'usersInfo'
                },
                {
                    label: 'Channels',
                    name: 'channels',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Channels',
                    validations: null,
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['slackChannelIds'] &&
                        this.sendingMechanism['slackChannelIds'].length
                            ? this.sendingMechanism['slackChannelIds']
                            : [],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_CHANNELS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'channalsInfo'
                }
            ]
        };

        // ServiceNow Form Gen Input
        this.serviceNowFormGenInput = {
            formName: 'Service Now',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'incidentPriority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Priority',
                    placeholder: 'Incident Priority',
                    required: true,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['serviceNowPriority']
                            ? this.sendingMechanism['serviceNowPriority']
                            : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident priority is required'
                        }
                    ],
                    listData: this.serviceNowIncidentPriority
                }
            ]
        };

        this.updateCreateButton = {
            buttonName: this.edit ? 'Update' : 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            showLoader: true,
            function: (buttonRef) => {
                this.saveNotification(buttonRef);
            }
        };
    }

    integrationsChanged(integrations: Map<IntegrationName, IntegrationData>) {
        this.selectedIntegrations = integrations;
        if (integrations.has(IntegrationName.FRESHDESK)) {
            this.setupFreshdeskForm();
        }
        if (integrations.has(IntegrationName.FRESHSERVICE)) {
            this.setupFreshserviceForm();
        }
        if (integrations.has(IntegrationName.ZENDESK)) {
            this.setupZendeskForm();
        }
        if (integrations.has(IntegrationName.JIRADESK)) {
            this.setupJiradeskForm();
        }
        if (integrations.has(IntegrationName.SNS)) {
            this.setupSnsForm();
        }
    }

    setupFreshdeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.FRESHDESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.FRESHDESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.freshdeskFormGenInput = {
            formName: 'Freshdesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'freshDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Freshdesk support email',
                    placeholder: 'Freshdesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['freshDeskIntegrationId'] &&
                        this.sendingMechanism['freshDeskIntegrationId'].length
                            ? this.sendingMechanism['freshDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupFreshserviceForm() {
        const self = this.selectedIntegrations.get(IntegrationName.FRESHSERVICE)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(
            IntegrationName.FRESHSERVICE
        ).integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.freshserviceFormGenInput = {
            formName: 'Freshservice',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'freshServiceIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Freshservice support email',
                    placeholder: 'Freshservice support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['freshServiceIntegrationId'] &&
                        this.sendingMechanism['freshServiceIntegrationId']
                            .length
                            ? this.sendingMechanism['freshServiceIntegrationId']
                            : []
                }
            ]
        };
    }

    setupZendeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.ZENDESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.ZENDESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.zendeskFormGenInput = {
            formName: 'Zendesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'zenDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Zendesk support email',
                    placeholder: 'Zendesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['zenDeskIntegrationId'] &&
                        this.sendingMechanism['zenDeskIntegrationId'].length
                            ? this.sendingMechanism['zenDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupJiradeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.JIRADESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.JIRADESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.jiradeskFormGenInput = {
            formName: 'Jiradesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'jiraDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Jiradesk support email',
                    placeholder: 'Jiradesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['jiraDeskIntegrationId'] &&
                        this.sendingMechanism['jiraDeskIntegrationId'].length
                            ? this.sendingMechanism['jiraDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupSnsForm() {
        this.snsFormGenInput = {
            formId: Symbol(),
            formName: 'SNS',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Topics',
                    placeholder: 'Topics',
                    name: 'notify',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsListingApiInfo,
                    responseValueKey: 'newAccounts',
                    required: true,
                    value: this.sendingMechanism
                        ? this.sendingMechanism['snsRequest']
                            ? this.sendingMechanism['snsRequest']['notify']
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Topic is required'
                        }
                    ]
                }
            ]
        };
    }

    saveNotification(buttonRef) {
        if (buttonRef.loader) {
            return;
        }

        if (!this.selectedIntegrations.size) {
            this.notificationsService.showSnackBar(
                'No sending mechanism selected',
                true
            );
            return;
        }

        if (this.notificationFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.notificationFormGroup);
            this.notificationsService.showSnackBar(
                Messages.FILL_ALL_FIELDS,
                true
            );
            return;
        }

        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            Helper.markAllFieldAsTouched(this.emailFormGroup);
            if (this.emailFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHDESK)) {
            Helper.markAllFieldAsTouched(this.freshdeskFormGroup);
            if (this.freshdeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHSERVICE)) {
            Helper.markAllFieldAsTouched(this.freshserviceFormGroup);
            if (this.freshserviceFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.ZENDESK)) {
            Helper.markAllFieldAsTouched(this.zendeskFormGroup);
            if (this.zendeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.SERVICENOW)) {
            Helper.markAllFieldAsTouched(this.serviceNowFormGroup);
            if (this.serviceNowFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.JIRADESK)) {
            Helper.markAllFieldAsTouched(this.jiradeskFormGroup);
            if (this.jiradeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.SNS)) {
            Helper.markAllFieldAsTouched(this.snsFormGroup);
            if (this.snsFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        let users = [];
        let channels = [];

        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            users = this.slackFormGroup.value['users'];
            channels = this.slackFormGroup.value['channels'];

            if (
                !users ||
                (users && !users.length) ||
                !channels ||
                (channels && !channels.length)
            ) {
                this.notificationsService.showSnackBar(
                    `Please select atleast one channel and/or user`,
                    true
                );
                return;
            }
        }

        buttonRef.loader = true;
        const sendingMechanism = {
            sendViaEmail: this.selectedIntegrations.has(IntegrationName.EMAIL)
        };
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            sendingMechanism['emailRequest'] = this.emailFormGroup.value;
        }
        if (this.selectedIntegrations.has(IntegrationName.O365)) {
            sendingMechanism['o365IntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.O365
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.GMAIL)) {
            sendingMechanism['gmailIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.GMAIL
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            sendingMechanism['slackUsersIds'] = users;
            sendingMechanism['slackChannelIds'] = channels;
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHDESK)) {
            sendingMechanism['freshDeskIntegrationId'] =
                this.freshdeskFormGroup.get('freshDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHSERVICE)) {
            sendingMechanism['freshServiceIntegrationId'] =
                this.freshserviceFormGroup.get(
                    'freshServiceIntegrationId'
                ).value;
        }

        if (this.selectedIntegrations.has(IntegrationName.ZENDESK)) {
            sendingMechanism['zenDeskIntegrationId'] =
                this.zendeskFormGroup.get('zenDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.SERVICENOW)) {
            sendingMechanism['serviceNowIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.SERVICENOW
                ).integrationData['id'];
            sendingMechanism['serviceNowPriority'] =
                this.serviceNowFormGroup.get('incidentPriority').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.JIRADESK)) {
            sendingMechanism['jiraDeskIntegrationId'] =
                this.jiradeskFormGroup.get('jiraDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.SNS)) {
            sendingMechanism['snsRequest'] = this.snsFormGroup.value;
        }

        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const step2Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        const saveNotificationInput = {
            automationName: step1Data['automationName'],
            cronExpression: step2Data['cronExpression'],
            automationFilter: {
                endDateForSchedular: step2Data['endDate'],
                startDateForSchedular: step2Data['startDate'],
                scheduleTime: step2Data['scheduleTime'],
                timeZoneId: step2Data['timeZoneId'],
                notificationActive: true,
                sendingMechanism,
                notifyType: this.notificationFormGroup
                    .get('notifyType')
                    .value.toUpperCase(),
                notifyBefore:
                    this.notificationFormGroup.get('notifyBefore').value
            },
            widgetFilter: this.widgetFilters.map((widgetFilter) => {
                const filtersData = Helper.dereference(widgetFilter);
                const filters = this.widgetRef.filterService.prepareInput(
                    widgetFilter.filters,
                    FilterStoreKey.AUTOMATION_FILTERS
                );
                filtersData.filters = filters;
                return filtersData;
            })
        };

        if (this.edit) {
            const updateArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.update
            );
            updateArgs.intactUrl = updateArgs.url;
            updateArgs.url = updateArgs.url.replace(
                '{id}',
                this.notificationData['id']
            );
            updateArgs.url = updateArgs.url.replace(
                '{widgetId}',
                this.widgetRef.widgetData.widgetId
            );

            updateArgs.url = updateArgs.url.replace(
                '{automation-id}',
                this.notificationData['id']
            );
            updateArgs.input = saveNotificationInput;
            updateArgs.function = (response) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    'Automation updated successfully'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            updateArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error updating automation'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };

            new HitApi(updateArgs, this.httpService, this.ngZone).hitApi();
        } else {
            const createArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.create
            );
            createArgs.input = saveNotificationInput;

            createArgs.intactUrl = createArgs.url;
            createArgs.url = createArgs.url.replace(
                '{widgetId}',
                this.widgetRef.widgetData.widgetId
            );

            createArgs.function = (response) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    'Automation created successfully'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            createArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error creating automation'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };

            new HitApi(createArgs, this.httpService, this.ngZone).hitApi();
        }
    }
}

interface IntegrationData {
    iconData: IIcon;
    name: string;
    integrationData;
    integrated: boolean;
}
