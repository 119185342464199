import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Helper } from 'src/app/shared/classes/Helper';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import {
    MiscellaneousCacheService,
    SSO_REDIRECT_URL
} from './../../../shared/services/cache/miscellaneous-cache/miscellaneous-cache.service';
declare let gtag: Function;
declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
    FilterStoreKey = FilterStoreKey;
    @Input() isBannerVisible: boolean;
    constructor(
        public globalDataService: GlobalDataService,
        private filterCacheService: FilterCacheService,
        public filterService: FiltersService,
        public router: Router,
        public userDataCache: UserDataCacheService,
        private miscellaneousCacheService: MiscellaneousCacheService
    ) {}

    ngOnInit(): void {
        let userId = null;
        userId = this.userDataCache.emailId
            .replace(/@/g, ' at ')
            .replace(/[^a-z0-9-]+/gi, '|')
            .replace(/^-|-$/g, '');
        userId = userId + '|' + this.userDataCache.domainId;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects !== undefined) {
                    gtag('config', 'G-5ZDW6KW0H0', {
                        user_id: userId
                    });
                    gtag('set', 'user_properties', {
                        user_id_dimension: userId
                    });
                    window.dataLayer.push({
                        event: 'userData',
                        user_id: userId
                    });
                }
            }
        });
        this.checkSSOUrl();
    }

    // Fetching url if present before login in SSO redirection
    checkSSOUrl() {
        this.miscellaneousCacheService.fetch(SSO_REDIRECT_URL, (value) => {
            if (value) {
                const url = location.origin + value;
                this.miscellaneousCacheService.deleteKey(SSO_REDIRECT_URL);
                Helper.navigateTo(url, 'SAME_PAGE');
            }
        });
    }

    navigationChanged() {
        this.globalDataService.isHandset$
            .pipe(take(1))
            .subscribe((isHandset) => {
                if (!isHandset) {
                    this.globalDataService.windowResizeEvent.next();
                }
            });
    }
}
