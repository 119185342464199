<form class="form">
    <div class="form-data">
        <app-form-generator
            *ngIf="accAndRegFormGenInputs"
            [formGeneratorInput]="accAndRegFormGenInputs"
            (formGroupRef)="accAndRegFormGroup = $event"
            (valuesChange)="depField($event)"
        ></app-form-generator>
        <ng-container
            *ngIf="
                accAndRegFormGroup &&
                accAndRegFormGroup.get('accountId') &&
                accAndRegFormGroup.get('accountId').value &&
                accAndRegFormGroup.get('region') &&
                accAndRegFormGroup.get('region').value
            "
        >
            <ng-container *ngTemplateOutlet="first"> </ng-container>
        </ng-container>
        <ng-template #first>
            <app-form-generator
                [formGeneratorInput]="encryptionFormGenInputs"
                (formGroupRef)="encryptionFormGroup = $event"
                (updateControl)="updateControl = $event"
            ></app-form-generator>
        </ng-template>
        <ng-container
            *ngIf="
                encryptionFormGroup &&
                encryptionFormGroup.get('encryption') &&
                encryptionFormGroup.get('encryption').value
            "
        >
            <ng-container *ngTemplateOutlet="second"> </ng-container>
        </ng-container>
        <ng-template #second>
            <app-form-generator
                [formGeneratorInput]="masterKeyFormGenInputs"
                (formGroupRef)="masterKeyFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <app-form-generator
            *ngIf="volumeTypeFormGenInputs"
            [formGeneratorInput]="volumeTypeFormGenInputs"
            (formGroupRef)="volumeTypeFormGroup = $event"
        ></app-form-generator>
        <ng-container
            *ngIf="
                volumeTypeFormGroup &&
                volumeTypeFormGroup.get('volumeType') &&
                volumeTypeFormGroup.get('volumeType').value === 'io1'
            "
        >
            <ng-container *ngTemplateOutlet="third"> </ng-container>
        </ng-container>
        <ng-template #third>
            <app-form-generator
                [formGeneratorInput]="iopsFormGenInputs"
                (formGroupRef)="iopsFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <section class="keyVal-fg">
            <div class="heading">Tags</div>
            <div class="section-content">
                <ng-container
                    *ngFor="
                        let keyValuePair of keyValuePairFormGenInputs;
                        let i = index;
                        let last = last
                    "
                >
                    <div class="item">
                        <app-form-generator
                            [formGeneratorInput]="keyValuePair"
                            [intitalFormGroup]="
                                keyValuePairFormGroup.has(keyValuePair.formId)
                                    ? keyValuePairFormGroup.get(
                                          keyValuePair.formId
                                      )
                                    : null
                            "
                            (formGroupRef)="
                                keyValuePairFormGroup.set(
                                    keyValuePair.formId,
                                    $event
                                )
                            "
                        ></app-form-generator>
                        <mat-icon
                            class="success"
                            *ngIf="last"
                            (click)="
                                lastKeyValueFormUpdate(i, keyValuePair.formId)
                            "
                            >add</mat-icon
                        >
                        <mat-icon
                            class="error"
                            *ngIf="!last"
                            (click)="
                                deleteKeyValuePairFormGroup(
                                    i,
                                    keyValuePair.formId
                                )
                            "
                            >delete</mat-icon
                        >
                    </div>
                </ng-container>
            </div>
        </section>
    </div>
    <div class="form-controls">
        <button
            type="button"
            mat-stroked-button
            class="clickable btn-accent-stroked filled"
            color="accent"
            (click)="reset()"
        >
            Reset
        </button>
        <button
            type="button"
            mat-stroked-button
            class="clickable btn-accent-stroked filled"
            color="accent"
            (click)="createInstance()"
        >
            Submit
            <mat-spinner
                *ngIf="loader"
                class="light-theme"
                [diameter]="16"
            ></mat-spinner>
        </button>
    </div>
</form>
