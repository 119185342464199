import { Injectable } from '@angular/core';
import { AsyncIndexedDbCacheMain } from 'src/app/shared/classes/AsyncIndexedDbCacheMain';

@Injectable({
    providedIn: 'root'
})
export class WidgetCacheService extends AsyncIndexedDbCacheMain {
    constructor() {
        const WIDGET_CACHE_PREFIX = 'WIDGET_CACHE';
        super(WIDGET_CACHE_PREFIX, true);
    }
}
