<ng-template
    #showFilterTemplate
    let-onChangeFn="changeFunction"
    let-availableFilters="available"
    let-checkedFilters="checked"
>
    <div class="show-filter-container" *ngIf="showFilterObjects">
        <div
            class="show-filter-row"
            *ngFor="let showFilterObject of showFilterObjects"
        >
            <div class="show-filter label">
                {{ showFilterObject.name }}
            </div>
            <div
                class="show-filter input"
                *ngFor="let showFilter of showFilterObject.filters"
            >
                <mat-checkbox
                    *ngIf="!availableFilters.has(showFilter.id)"
                    [checked]="false"
                    [disabled]="!availableFilters.has(showFilter.id)"
                    [class.disabled]="!availableFilters.has(showFilter.id)"
                    >{{ showFilter.name }}</mat-checkbox
                >
                <mat-checkbox
                    *ngIf="availableFilters.has(showFilter.id)"
                    [checked]="checkedFilters.has(showFilter.id)"
                    (change)="
                        onChangeFn($event, showFilter.id, showFilterObject.id)
                    "
                    >{{ showFilter.name }}</mat-checkbox
                >
            </div>
        </div>
    </div>
</ng-template>
<div class="container">
    <div
        class="close-button"
        (click)="modalService.closeModal(null, modalData.modalId)"
        appIcon
        [data]="crossIcon"
    ></div>
    <app-vertical-scrolling-tab-group>
        <ng-template appVerticalScrollingTab [tabLabel]="verticalTabs[0]">
            <div tabindex="0">
                <div class="header-container">
                    <div class="general-info-container">
                        <div
                            class="general"
                            *ngIf="
                                dataItem &&
                                dataItem[JSON_SAVINGS_TO_BE_MADE_KEY]
                            "
                        >
                            <span class="header-web-view">
                                Savings To Be Made: &nbsp;{{
                                    dataItem[JSON_SAVINGS_TO_BE_MADE_KEY]
                                }}
                            </span>
                        </div>
                        <div
                            class="general general-red-info"
                            *ngIf="
                                dataItem &&
                                (dataItem[JSON_WASTAGE_PERCENTAGE_KEY] ||
                                    dataItem[JSON_WASTAGE_PERCENTAGE_KEY] === 0)
                            "
                        >
                            <span class="header-web-view">
                                Savings Plan Wastage: &nbsp;{{
                                    dataItem[JSON_WASTAGE_PERCENTAGE_KEY]
                                }}%
                            </span>
                        </div>
                    </div>
                    <ng-container
                        [ngTemplateOutlet]="showFilterTemplate"
                        [ngTemplateOutletContext]="{
                            changeFunction: onOverviewFilterChanged.bind(this),
                            available: availableOverviewFilters,
                            checked: checkedOverviewFilters
                        }"
                    ></ng-container>
                </div>
                <div class="body-container">
                    <mat-tab-group
                        [disablePagination]="true"
                        [dynamicHeight]="true"
                        [selectedIndex]="overviewTabsActiveIndex"
                        (selectedIndexChange)="onOverviewTabChanged($event)"
                        mat-stretch-tabs
                    >
                        <mat-tab *ngFor="let overviewTab of overviewTabsList">
                            <ng-template matTabLabel>
                                <span
                                    [matTooltip]="overviewTab.name"
                                    [matTooltipShowDelay]="1000"
                                    >{{ overviewTab.name }}</span
                                >
                            </ng-template>
                            <app-only-table-generator
                                class="overview-table"
                                [class.no-data]="overviewNoData"
                                [tableInput]="overviewTab.tableGenInput"
                                [tableData]="overviewTab.tableData"
                                [defaultColDef]="generalTableDefaultDef"
                                [hideControlBar]="true"
                                (gridRef)="
                                    onOverviewGridRefReceived(
                                        overviewTab,
                                        $event
                                    )
                                "
                            ></app-only-table-generator>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="verticalTabs[1]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #bifurcatedPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ verticalTabs[1] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!bifurcatedPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="bifurcatedPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="header-container">
                        <ng-container
                            [ngTemplateOutlet]="showFilterTemplate"
                            [ngTemplateOutletContext]="{
                                changeFunction:
                                    onBifurcationFilterChanged.bind(this),
                                available: availableBifurcationFilters,
                                checked: checkedBifurcationFilters
                            }"
                        ></ng-container>
                    </div>
                    <div class="body-container" *ngIf="bifurcationTablesList">
                        <ng-container
                            *ngFor="
                                let bifurcatedTable of bifurcationTablesList
                            "
                        >
                            <div class="table-name">
                                {{ bifurcatedTable.name }}
                            </div>
                            <app-only-table-generator
                                *ngIf="
                                    bifurcatedTable.tableGenInput &&
                                    bifurcatedTable.tableData
                                "
                                [tableInput]="bifurcatedTable.tableGenInput"
                                [tableData]="bifurcatedTable.tableData"
                                [defaultColDef]="generalTableDefaultDef"
                                [hideControlBar]="true"
                                (gridRef)="
                                    onBifurcationGridRefReceived(
                                        bifurcatedTable,
                                        $event
                                    )
                                "
                            ></app-only-table-generator>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="verticalTabs[2]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #descriptionPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ verticalTabs[2] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!descriptionPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="descriptionPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div
                        class="panel-container"
                        *ngIf="descriptionKeyValueList"
                    >
                        <table class="decription-table">
                            <tr
                                *ngFor="
                                    let descriptionRow of descriptionKeyValueList
                                "
                            >
                                <th>{{ descriptionRow.key }}</th>
                                <td>{{ descriptionRow.value }}</td>
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </app-vertical-scrolling-tab-group>
</div>
