import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { TemplateData } from './../../../../classes/TemplateData';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { FilterCacheService } from './../../../../services/cache/filters-cache/filter-cache.service';
import { HttpService } from './../../../../services/http/http-main/http.service';

@Component({
    selector: 'app-create-widget-json',
    templateUrl: './create-widget-json.component.html',
    styleUrls: ['./create-widget-json.component.sass']
})
export class CreateWidgetJsonComponent implements OnInit {
    widgetInput;
    widgetRef: Widget;
    verifiedJson: boolean = true;
    templateJson = TemplateData.widgetDataTemplate;
    pushWidgetLoader: boolean = false;

    validateJsonIcon: IIcon = {
        type: IconType.SVG,
        class: 'validate'
    };

    constructor(
        private notificationService: NotificationsService,
        private filterCacheService: FilterCacheService,
        private httpService: HttpService,
        private widgetData: WidgetInjectedData
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.widgetInput = JSON.stringify(this.templateJson, null, '\t');
    }

    prepareWidgetInput() {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = JSON.parse(this.widgetInput);
        apiArgs.function = (response) => {
            this.notificationService.showSnackBar(
                'The request to create a widget has been successfully initiated'
            );
            this.pushWidgetLoader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.notificationService,
                error,
                'The request to create a widget has failed'
            );
            this.pushWidgetLoader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };

        new HitApi(apiArgs, this.httpService, this.httpService.ngZone).hitApi();
    }

    validateJson() {
        if (!this.widgetInput) {
            return;
        }
        if (this.pushWidgetLoader) {
            return;
        }

        if (Helper.validateJson(this.widgetInput)) {
            this.pushWidgetLoader = true;
            this.verifiedJson = true;
            this.widgetInput = Helper.beautifyJson(this.widgetInput);
            this.prepareWidgetInput();
        } else {
            this.verifiedJson = false;
            this.notificationService.showSnackBar('Incorrect JSON format');
        }
    }
}
