import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from './../../../classes/ModalInjectedData';
import { ITableGeneratorInput } from './../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-table-generator-modal',
    templateUrl: './table-generator-modal.component.html',
    styleUrls: ['./table-generator-modal.component.sass']
})
export class TableGeneratorModalComponent implements OnInit {
    tableGenInputs: ITableGeneratorInput;
    tableData: any;
    constructor(private modalData: ModalInjectedData) {
        this.tableGenInputs = this.modalData.data['tableGenInputs'];
        this.tableData = this.modalData.data['tableData'];
    }

    ngOnInit(): void {}
}
