import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { FormState } from './../../../../enums/FormState';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-otp-verification-modal',
    templateUrl: './otp-verification-modal.component.html',
    styleUrls: ['./otp-verification-modal.component.sass']
})
export class OtpVerificationModalComponent implements OnInit {
    otpFormGenInput: IFormGeneratorInput;
    otpFormGroup: FormGroup;
    functionToExecute;
    enableResendBtn: boolean = false;
    resendCounter: number = 0;
    widgetRef: Widget;
    otpInput: any;
    callbackFn: any;
    timeOut: boolean = false;
    errorMsg = new BehaviorSubject<string>(null);
    invalideOtp: boolean = false;
    rootUser: boolean;
    timer: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    timerData: number;
    showTimer: boolean = false;

    resendOtpButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Resend OTP',
        buttonColorType: ButtonColorType.SECONDARY,
        buttonType: ButtonType.RAISED,
        function: null
    };

    verifyOtpButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Verify',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        loader: false,
        function: null
    };

    constructor(
        public modalData: ModalInjectedData,
        private userDataCache: UserDataCacheService,
        private ngZone: NgZone
    ) {
        this.rootUser = this.userDataCache.rootUser;
        this.functionToExecute = modalData.data['function'];
        this.widgetRef = modalData.data['widgetRef'];
        this.otpInput = modalData.data['input'];
        this.hitOtpApi();
        this.resendTimer();
        //disabled the resend button for 60s
        setTimeout(() => {
            this.enableResendBtn = true;
        }, 60000);
    }

    ngOnInit(): void {
        this.otpFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Verification Code',
                    placeholder: 'Enter Verification Code',
                    name: 'verificationCode',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Verification code required.'
                        }
                    ]
                }
            ]
        };
    }
    sendOtp() {
        if (!this.enableResendBtn) {
            return;
        }
        if (this.resendCounter === 2) {
            this.enableResendBtn = false;
            this.showTimer = false;
            return;
        }
        this.enableResendBtn = false;
        setTimeout(() => {
            this.resendCounter++;
            if (this.resendCounter < 2) {
                this.enableResendBtn = true;
            }
        }, 60000);
        this.hitOtpApi();
        this.resendTimer();
    }
    hitOtpApi() {
        this.errorMsg.next('');
        const apiConf: IHitApi = {
            url: this.rootUser
                ? ApiUrls.SEND_OTP
                : ApiUrls.SEND_OTP_NON_ROOT_USER,
            input: this.otpInput,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            function: () => {},
            errorFunction: (error) => {
                this.timeOut =
                    error &&
                    error.error.message &&
                    error.error.message.includes('Request timed out')
                        ? true
                        : false;
                this.invalideOtp = false;
                this.verifyOtpButtonGenInput.loader = false;
                this.showTimer = false;
                if (this.timeOut) {
                    this.errorMsg.next(
                        'Request timed out! Please try again after 10 minutes.'
                    );
                } else {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error.error.message
                    );
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalData.modalId
                    );
                }
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            }
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    otpVerification(errorMessage?) {
        this.verifyOtpButtonGenInput.loader = false;
        if (errorMessage) {
            this.errorMsg.next(errorMessage);
            this.invalideOtp = true;
        }
    }
    resendTimer() {
        this.timerData = 60;
        this.timer.next(this.timerData);
        this.showTimer = true;

        this.ngZone.runOutsideAngular(() => {
            const timer = setInterval(() => {
                this.ngZone.run(() => {
                    this.timerData--;
                    this.timer.next(this.timerData);
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                this.showTimer = false;
            }, 60000);
        });
    }
}
