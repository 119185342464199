import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class PhoneNumberValidator extends CustomValidator {
    key = CustomValidatorKey.PHONE_NUMBER_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const code = '^(?=.*?[+]).([0-9]{7,15})$';

        return Validators.pattern(code)(control);
    }
}
