<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        widgetRef.visibleSections.value.has(ViewType.GRAPH)
    "
>
    <div
        class="graph-container"
        *ngIf="graphData && objectKeys(graphData).length > 0"
    >
        <div class="resource" *ngIf="noOfDropdowns.length > 0">
            <div>
                Select Resources
                <i
                    class="far fa-question-circle iconsize1"
                    [matTooltip]="'Click here for information'"
                    (click)="aboutSelectResource()"
                ></i>
            </div>
            <app-multi-select-dropdown
                [filterInfo]="
                    noOfDropdowns | createFilterInfo: 'Resources':null:null:true
                "
                (change)="dropdownValueChange($event)"
            >
            </app-multi-select-dropdown>
        </div>
        <div class="primary-radios" *ngIf="primaryData.length > 0">
            <div>
                Choose Primary Resource
                <i
                    class="far fa-question-circle iconsize1"
                    [matTooltip]="'Click here for information'"
                    (click)="aboutChoosePrimary()"
                ></i>
            </div>
            <mat-radio-group
                class="supported-formats-container"
                (change)="primaryValueChanged()"
                [(ngModel)]="primaryValue"
            >
                <mat-radio-button
                    class="supported-format"
                    *ngFor="let value of primaryData"
                    [value]="value"
                >
                    {{ value }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="resource-dropdowns" *ngIf="primaryValue">
            <div class="primary-resource-dropdown">
                <span>{{ primaryValue }}</span>
                <app-grouping-dropdown
                    [filterInfo]="
                        originalDropdowns[primaryValue]
                            | createFilterInfo
                                : primaryValue
                                : null
                                : 'accountId'
                                : true
                    "
                    [selectedValues]="
                        selectedValues.has(primaryValue)
                            ? selectedValues.get(primaryValue)
                            : []
                    "
                    (change)="dropdownSelectedChange($event, primaryValue)"
                >
                </app-grouping-dropdown>
            </div>
            <div class="extra-dropdowns">
                <ng-container *ngFor="let dropdown of primaryData">
                    <div
                        class="extra-dropdown"
                        *ngIf="dropdown !== primaryValue"
                    >
                        <span>{{ dropdown }}</span>
                        <app-grouping-dropdown
                            [filterInfo]="
                                getDropdowndata(dropdown)
                                    | createFilterInfo
                                        : dropdown
                                        : null
                                        : 'accountId'
                                        : true
                            "
                            [selectedValues]="
                                selectedValues.has(dropdown)
                                    ? selectedValues.get(dropdown)
                                    : []
                            "
                            (change)="dropdownSelectedChange($event, dropdown)"
                        >
                        </app-grouping-dropdown>
                    </div>
                </ng-container>
            </div>
        </div>
        <button
            class="btn-accent-stroked"
            *ngIf="selectedValues.size"
            color="accent"
            mat-stroked-button
            (click)="createGraph()"
        >
            Draw
        </button>
        <div *ngIf="plotGraph" class="graph">
            <div id="mynetwork"></div>
            <div class="legends">
                <div
                    class="legend-item"
                    *ngFor="let legend of objectKeys(legends)"
                >
                    <div class="shape">
                        <div
                            class="legend {{ legends[legend].split('|')[1] }}"
                            [style.background]="legends[legend].split('|')[0]"
                        ></div>
                    </div>
                    <div class="text">{{ legend }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
