import { Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { FiltersHttpService } from 'src/app/shared/services/http/filters-http/filters-http.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { SubSink } from 'subsink';
import { DynamicComponentsInjectedData } from './../../../../classes/DynamicComponentsInjectedData';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { ContentType } from './../../../../enums/ContentType';
import { IconType } from './../../../../enums/IconType';
import { ModalType } from './../../../../enums/ModalType';
import { ICustomCostMonitoringWidgetCreationInput } from './../../../../interfaces/api/input-data/ICustomCostMonitoringWidgetCreationInput';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IPreviewData } from './../../../../interfaces/custom-widget/IPreviewData';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { CustomCostMonitoringWidgetService } from './../../../../services/custom-cost-monitoring-widget/custom-cost-monitoring-widget.service';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { CustomWidgetPreviewComponent } from './../../../custom-widget-preview/custom-widget-preview.component';
import { PieTemplateComponent } from './../../../pie-template/pie-template.component';

@Component({
    selector: 'app-widget-configuration',
    templateUrl: './widget-configuration.component.html',
    styleUrls: ['./widget-configuration.component.sass']
})
export class WidgetConfigurationComponent implements OnInit, OnDestroy {
    readonly COLUMN_RENAME_KEY = 'columnsToRename';
    readonly REPORTING_KEY = 'reporting';
    readonly FILTERS_KEY = 'filters';
    readonly GRAPH_KEY = 'graph';
    readonly COLUMN_RENAME_HEADING =
        'Create column name mapping that you want to display on the widget.';
    readonly REPORTING_HEADING =
        'Select report formats you want to configure on the widget.';
    readonly FILTERS_HEADING =
        'Select filters that you want to enable on the widget.';
    readonly GRAPH_HEADING =
        'Select variables on which you want to configure the graph.';

    //Icons
    pencilIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pencil-alt',
        extraClass: 'pencil-icon'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    filterIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel'
    };
    errorIcon: IIcon = {
        type: IconType.SVG,
        class: 'exclamation_filled_circle'
    };
    infoIcon: IIcon = {
        type: IconType.SVG,
        class: 'exclamation_filled_circle',
        extraClass: 'svg-info-v2-fill'
    };
    downloadIcon: IIcon = {
        type: IconType.SVG,
        class: 'download',
        extraClass: 'svg-transparent-fill'
    };

    //Other variables
    subSink: SubSink = new SubSink();
    tabList = [
        {
            id: this.COLUMN_RENAME_KEY,
            label: 'Configure Query Columns',
            heading: this.COLUMN_RENAME_HEADING
        },
        {
            id: this.REPORTING_KEY,
            label: 'Configure Reports',
            heading: this.REPORTING_HEADING
        },
        {
            id: this.FILTERS_KEY,
            label: 'Configure Filters',
            heading: this.FILTERS_HEADING
        },
        {
            id: this.GRAPH_KEY,
            label: 'Configure Pie Graph',
            heading: this.GRAPH_HEADING
        }
    ];
    currentTab = this.COLUMN_RENAME_KEY;
    currentLabel = this.COLUMN_RENAME_HEADING;
    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.back();
            }
        },
        {
            buttonName: 'Save as Draft',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef) => {
                this.saveAsDraft(buttonRef);
            }
        },
        {
            buttonName: 'Save Query',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef) => {
                this.saveConfiguration(buttonRef);
            }
        }
    ];

    reportingOptions = [
        {
            id: SupportedFormat.EXCEL,
            label: SupportedFormat.EXCEL
        },
        {
            id: SupportedFormat.CSV,
            label: SupportedFormat.CSV
        },
        {
            id: SupportedFormat.PDF,
            label: SupportedFormat.PDF
        }
    ];
    selectedReportType = [
        SupportedFormat.EXCEL,
        SupportedFormat.CSV,
        SupportedFormat.PDF
    ];
    filters = [];
    stepData: any;
    inputData: ICustomCostMonitoringWidgetCreationInput;
    actualInputData: ICustomCostMonitoringWidgetCreationInput; // Data received from get Api
    columnsEditableMode = new Map<number, boolean>();
    columnsToRename = new Map<string, string>();
    columnsNewNameMap = new Map<string, string>();
    selectedFilters = [];
    pieXAxisColumns = [];
    pieYAxisColumns = [];
    xAxis;
    yAxis;
    pieData;
    queryData;
    isLoading = false;
    filtersData = new Map<string, IFilterInfo>();
    pieTemplateInjector: Injector;
    previewData: IPreviewData;
    widgetId: string;
    widgetRef: Widget;
    isEdit: boolean;
    isViewMode: boolean;
    validationError = '';
    configurePie: boolean;
    downloadLoader: boolean;
    resetModal: Subscription;
    loader: boolean;

    constructor(
        private customCostMonitoringWidgetService: CustomCostMonitoringWidgetService,
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private filtersService: FiltersService,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private filtersHttpService: FiltersHttpService,
        private ngZone: NgZone,
        private modalService: ModalService,
        private injector: Injector
    ) {
        this.stepData = Helper.cloneDeep(
            multiStepFormService.stepData.get(modalInputData.modalId).get(2)
        );
        this.actualInputData = Helper.cloneDeep(this.stepData.inputData);
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isViewMode = modalInputData.data['isViewMode'];
        this.widgetId = modalInputData.data['widgetId'];
        this.buttonGenInputs[1].disable = this.isViewMode;
        this.buttonGenInputs[2].disable = this.isViewMode;
    }

    ngOnInit(): void {
        this.initStep();
        this.getFilters();
        this.resetModal = this.modalService.resetModal.subscribe((reset) => {
            this.modalService.openConfirmationModal({
                modalName: 'Reset',
                modalIcon: null,
                contextIcon: null,
                confirmationMessage:
                    'Are you sure you want to reset the changes ?',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonText: 'Confirm',
                function: (modalId: Symbol) => {
                    this.initStep();
                    this.validationError = '';
                    this.modalService.closeModal(null, modalId);
                }
            });
        });
    }

    initStep() {
        this.validationError = '';
        this.inputData = Helper.cloneDeep(this.actualInputData);

        // Initialising data
        this.initSelectedFilters();
        let columnToMap = {};
        const colToRename = new Map();
        if (this.isEdit) {
            columnToMap = this.modalInputData.data['widgetData'][
                'columnsToRename'
            ]
                ? this.modalInputData.data['widgetData']['columnsToRename']
                : {};
        }

        let extraCol = '';
        Object.keys(this.inputData['columnsToRename']).forEach((col) => {
            if (
                !this.isEdit ||
                (this.stepData['columnList'] &&
                    this.stepData['columnList'].includes(col))
            ) {
                const key = columnToMap[col]
                    ? columnToMap[col]
                    : this.inputData['columnsToRename'][col];

                colToRename.set(col, key);
            } else {
                extraCol = col;
            }
        });

        if (extraCol) {
            delete this.inputData['columnsToRename'][extraCol];
        }
        this.columnsToRename = Helper.cloneDeep(colToRename);
        this.columnsNewNameMap.clear();
        this.selectedReportType = this.inputData.reporting
            ? Helper.cloneDeep(this.inputData.reporting)
            : [SupportedFormat.EXCEL, SupportedFormat.CSV, SupportedFormat.PDF];

        // Init graph step starts
        this.configurePie = false;
        this.pieTemplateInjector = null;
        this.pieXAxisColumns = Helper.cloneDeep(
            this.stepData['numberTypeColumns']
        );
        this.pieYAxisColumns = Helper.cloneDeep(
            this.stepData['textTypeColumns']
        );
        if (
            !this.inputData.hideGraph &&
            this.inputData.graph &&
            this.inputData.graph.x.length
        ) {
            if (
                this.pieXAxisColumns.find(
                    (obj) => this.inputData.graph.x[0] === obj.id
                )
            ) {
                this.xAxis = this.inputData.graph.x[0];
            }
        } else {
            this.xAxis = null;
        }

        if (
            !this.inputData.hideGraph &&
            this.inputData.graph &&
            this.inputData.graph.y.length
        ) {
            if (
                this.pieYAxisColumns.find(
                    (obj) => this.inputData.graph.y[0] === obj.id
                )
            ) {
                this.yAxis = this.inputData.graph.y[0];
            }
        } else {
            this.yAxis = null;
        }
        // Init graph step ends

        this.queryData = Helper.cloneDeep(this.stepData['queryData']);
    }

    initSelectedFilters() {
        this.selectedFilters =
            this.inputData && this.inputData.filters
                ? Helper.cloneDeep(this.inputData.filters)
                : this.filters && this.filters.length
                ? this.filters.map((filter) => filter.id)
                : [];
    }

    checkChanges() {
        if (
            !Helper.compareObjects(
                this.inputData.reporting,
                this.actualInputData.reporting
            )
        ) {
            return true;
        }

        if (
            !Helper.compareObjects(
                this.inputData.filters,
                this.actualInputData.filters
            )
        ) {
            return true;
        }

        if (
            !Helper.compareObjects(
                this.inputData.graph,
                this.actualInputData.graph
            )
        ) {
            return true;
        }

        const actualColumnsToRenameMap = new Map(
            Object.entries(this.actualInputData.columnsToRename)
        );
        for (const [key, val] of actualColumnsToRenameMap) {
            if (this.columnsToRename.get(key) !== val) {
                return true;
            }
        }
    }

    changeTab(tab) {
        this.validationError = '';
        if (this.isLoading) {
            return;
        }
        this.currentTab = tab.id;
        this.currentLabel = tab.heading;
        this.columnsEditableMode = new Map();

        if (this.currentTab === this.GRAPH_KEY) {
            if (this.xAxis && this.yAxis) {
                this.preparePieDataDrawPie();
            }
        }
    }

    changeDataValue(value) {
        this.validationError = '';
        this.configurePie = true;
        this.xAxis = value;
        if (this.xAxis && this.yAxis) {
            this.preparePieDataDrawPie();
        }
    }

    changeDataLabel(label) {
        this.validationError = '';
        this.yAxis = label;
        this.configurePie = true;
        if (this.xAxis && this.yAxis) {
            this.preparePieDataDrawPie();
        }
    }

    preparePieDataDrawPie() {
        if (
            this.queryData.dataMap.table &&
            this.queryData.dataMap.table.length === 0
        ) {
            return;
        }
        const prepareTempData = {};
        const yAxis = this.inputData.columnsToRename[this.yAxis];
        const xAxis = (this.inputData.columnsToRename[this.xAxis] as string)
            .toLowerCase()
            .includes('cost')
            ? this.inputData.columnsToRename[this.xAxis] +
              `(${this.queryData.dataMap.currencySymbol})`
            : this.inputData.columnsToRename[this.xAxis];
        this.queryData.dataMap.table.forEach((element) => {
            if (element[yAxis] in prepareTempData) {
                const roundOff = 2;
                prepareTempData[element[yAxis]] = Helper.roundOff(
                    prepareTempData[element[yAxis]] + element[xAxis],
                    roundOff
                );
            } else {
                prepareTempData[element[yAxis]] = element[xAxis];
            }
        });

        this.pieData = [];
        Object.keys(prepareTempData).forEach((key) => {
            if (!(prepareTempData[key] < 0)) {
                const tempObject = {};
                tempObject[yAxis] = key;
                tempObject[xAxis] = prepareTempData[key];
                this.pieData.push(tempObject);
            }
        });

        this.initPieInjector();
    }

    getFilters() {
        this.isLoading = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig({
            host: '',
            apiRouteSuffix: ApiUrls.CUSTOM_COST_MONITORING_WIDGET_FILTERS,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        });
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.filters = response;
            this.initSelectedFilters();
            this.prepareFiltersData();
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(this.notificationService, error);
            this.isLoading = false;
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    prepareFiltersData() {
        this.filters.forEach((filter) => {
            if (
                this.filtersService.filtersData
                    .get(FilterStoreKey.WEBSITE_FILTERS)
                    .has(filter.id)
            ) {
                this.updateFiltersData(
                    filter.id,
                    this.filtersService.filtersData
                        .get(FilterStoreKey.WEBSITE_FILTERS)
                        .get(filter.id).filterInfo
                );
            } else {
                this.isLoading = true;
                this.getFilterInfo(filter.id);
            }
        });
    }

    getFilterInfo(filterId) {
        const filterObs = this.filtersHttpService.getFilterInfo(filterId);
        if (filterObs.value) {
            this.updateFiltersData(filterId, filterObs.value);
        } else {
            this.subSink.add(
                filterObs
                    .pipe(skip(1), take(1))
                    .subscribe((response) =>
                        this.updateFiltersData(filterId, response)
                    )
            );
        }
    }

    updateFiltersData(filterId: string, filterInfo: IFilterInfo) {
        this.filtersData.set(filterId, filterInfo);
        if (this.filters.length === this.filtersData.size) {
            this.isLoading = false;
        }
    }

    downloadReport() {
        this.downloadLoader = true;
        this.customCostMonitoringWidgetService.downloadReport(
            this.widgetRef,
            ApiUrls.CUSTOM_MONITORING_REPORT,
            this.prepareData(true),
            ContentType.ZIP,
            this.inputData.widgetName,
            () => {
                this.downloadLoader = false;
            }
        );
    }

    initPieInjector() {
        this.preparePreviewData();

        this.pieTemplateInjector = Injector.create({
            providers: [
                {
                    provide: DynamicComponentsInjectedData,
                    deps: [],
                    useValue: {
                        inputData: this.previewData.componentInputData
                    }
                }
            ],
            parent: this.injector
        });
    }

    showPreview() {
        if (!this.xAxis || !this.yAxis) {
            return;
        }
        this.preparePreviewData(true);
        this.modalService.openModal({
            modalName: 'Preview',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: Symbol(),
            modalWidthVw: 95,
            modalHeightVh: 95,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CustomWidgetPreviewComponent,
                        payload: {
                            data: {
                                previewData: this.previewData
                            }
                        }
                    },
                    stepName: 'Preview'
                }
            ]
        });
    }

    columnNameChanged(key, $event) {
        this.validationError = '';
        const value = $event.target.value;

        if (value === this.columnsToRename.get(key)) {
            return;
        }

        if (!value) {
            $event.target.value = this.columnsToRename.get(key);
            return;
        }

        if (
            this.pieXAxisColumns.find((row) => row.label === value) ||
            this.pieYAxisColumns.find((row) => row.label === value)
        ) {
            $event.target.value = this.columnsToRename.get(key);
            this.widgetRef.notificationsService.showSnackBar(
                'Column Name must be unique'
            );
            return;
        }
        this.columnsToRename.set(key, value);
        this.columnsNewNameMap.set(
            Helper.cloneDeep(this.inputData.columnsToRename[key]),
            value
        );
        let index = this.pieXAxisColumns.findIndex((row) => row.id === key);
        if (index !== -1) {
            this.pieXAxisColumns[index] = {
                id: key,
                label: value
            };
        } else {
            index = this.pieYAxisColumns.findIndex((row) => row.id === key);
            if (index !== -1) {
                this.pieYAxisColumns[index] = {
                    id: key,
                    label: value
                };
            }
        }
    }

    preparePreviewData(showTable = false) {
        let tableInputData: ITableGeneratorInput;
        if (showTable) {
            tableInputData = {
                widgetIconData: null,
                listExtraction: {
                    type: 'DIRECT'
                },
                columns: []
            };

            const currency = this.queryData.dataMap.currencySymbol;
            this.queryData.dataMap.tableKeys.forEach((row) => {
                const key = row.split(`(${currency})`)[0];
                tableInputData.columns.push({
                    columnName: this.columnsNewNameMap.has(key)
                        ? this.columnsNewNameMap.get(key) +
                          (row.includes(currency) ? `(${currency})` : '')
                        : row,
                    columnKey: row
                });
            });
        }

        this.previewData = {
            text: this.inputData.widgetName,
            description: this.inputData.widgetDescription
                ? {
                      oldDescription: {
                          baseDescription: [
                              {
                                  type: 'PARAGRAPH',
                                  value: this.inputData.widgetDescription
                              }
                          ]
                      }
                  }
                : null,
            componentToLoad: PieTemplateComponent,
            componentInputData: {
                pieData:
                    this.pieData && this.pieData.length ? this.pieData : null,
                tableData: showTable ? this.queryData.dataMap.table : null,
                tableInputData: showTable ? tableInputData : null,
                currencySymbol: this.queryData.dataMap.currencySymbol,
                totalCost: this.queryData.dataMap.totalCost,
                totalUsage: this.queryData.dataMap.totalUsage,
                xKey: (this.inputData.columnsToRename[this.xAxis] as string)
                    .toLowerCase()
                    .includes('cost')
                    ? this.inputData.columnsToRename[this.xAxis] +
                      `(${this.queryData.dataMap.currencySymbol})`
                    : this.inputData.columnsToRename[this.xAxis],
                yKey: this.inputData.columnsToRename[this.yAxis]
            }
        };
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (this.loader || buttonRef.loader || this.isViewMode) {
            return;
        }

        this.customCostMonitoringWidgetService.saveAsDraft(
            buttonRef,
            this.widgetRef,
            this.prepareData(this.isEdit ? this.inputData.draft : true),
            () => {
                buttonRef.loader = false;
                this.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    `Widget saved as ‘Draft’ Successfully`
                );
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            () => {
                buttonRef.loader = false;
                this.loader = false;
            },
            this.widgetId,
            () => {
                this.loader = true;
            }
        );
    }

    checkDataValidity() {
        if (this.validationError) {
            return;
        }
        if (!this.selectedReportType.length) {
            this.validationError =
                'At least one report format should be selected';
            return false;
        }
        if (!this.selectedFilters.length) {
            this.validationError = 'At least one filter should be selected';
            return false;
        }
        if (this.configurePie && (!this.xAxis || !this.yAxis)) {
            this.validationError =
                'Select all the fields required to configure pie chart';
            return false;
        }
        return true;
    }

    prepareData(draft = false) {
        const inputData = Helper.cloneDeep(this.inputData);

        let xAxisList = [];
        let yAxisList = [];
        if (!(this.xAxis && this.yAxis)) {
            //hide graph handling
            inputData.hideGraph = true;
            xAxisList = this.pieXAxisColumns.map((row) => row.id);
            yAxisList = this.pieYAxisColumns.map((row) => row.id);
        } else {
            //show graph handling
            inputData.hideGraph = false;
            xAxisList = [this.xAxis];
            this.pieXAxisColumns.forEach((item) => {
                if (!xAxisList.includes(item.id)) {
                    xAxisList.push(item.id);
                }
            });

            yAxisList = [this.yAxis];
            this.pieYAxisColumns.forEach((item) => {
                if (!yAxisList.includes(item.id)) {
                    yAxisList.push(item.id);
                }
            });
        }

        inputData.columnsToRename = Helper.mapToObj(this.columnsToRename);
        inputData.reporting = this.selectedReportType;
        inputData.filters = this.selectedFilters;
        inputData.draft = draft;
        inputData.graph = {
            x: xAxisList,
            y: yAxisList
        };
        return inputData;
    }

    saveConfiguration(buttonRef: IButtonGeneratorInput) {
        if (this.loader || buttonRef.loader || this.isViewMode) {
            return;
        }

        if (!this.checkDataValidity()) {
            return;
        }

        buttonRef.loader = true;
        this.loader = true;
        this.customCostMonitoringWidgetService.hitCreateWidgetApi(
            this.widgetRef,
            this.prepareData(),
            () => {
                buttonRef.loader = false;
                this.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    `Widget saved successfully`
                );
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            () => {
                buttonRef.loader = false;
                this.loader = false;
            },
            this.widgetId
        );
    }
    ngOnDestroy() {
        this.resetModal.unsubscribe();
    }
}
