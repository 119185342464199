<div class="container">
    <div class="form-container">
        <div class="tw-px-6 tw-p-3 tw-m-auto">
            <app-modal-info-template
                [infoData]="infoData"
            ></app-modal-info-template>
        </div>
        <div class="queue-form">
            <app-form-generator
                [formGeneratorInput]="queueFormGenInput"
                (formGroupRef)="queueFormGenGroup = $event"
            ></app-form-generator>
        </div>
        <ng-container
            *ngFor="
                let keyValuePair of fieldNameFormGenInput;
                let i = index;
                let first = first;
                let last = last
            "
        >
            <div class="field-name-form">
                <div class="item">
                    <app-form-generator
                        [formGeneratorInput]="keyValuePair"
                        [intitalFormGroup]="
                            fieldFormGroup.has(keyValuePair.formId)
                                ? fieldFormGroup.get(keyValuePair.formId)
                                : null
                        "
                        (formGroupRef)="
                            fieldFormGroup.set(keyValuePair.formId, $event)
                        "
                    ></app-form-generator>
                </div>
                <div class="button tw-flex tw-justify-end tw-mb-4">
                    <div *ngIf="first">
                        <app-button-generator
                            [buttonGenInput]="dynamicFieldButtonInput"
                            (buttonRef)="
                                lastKeyValueFormUpdate(i, keyValuePair.formId)
                            "
                        >
                        </app-button-generator>
                    </div>
                    <div *ngIf="!first">
                        <app-button-generator
                            [buttonGenInput]="deleteFieldButtonInput"
                            (buttonRef)="deleteField(i, keyValuePair.formId)"
                        ></app-button-generator>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="form-controls">
        <app-multi-button-generator [buttonGenInputs]="buttonsInput">
        </app-multi-button-generator>
    </div>
</div>
