export enum LoginSection {
    DOMAINID = 'domainid',
    EMAIL = 'email',
    SSO_EMAIL = 'SSO_EMAIL',
    PASSWORD = 'password',
    MFA = 'mfa',
    CREDENTIALS = 'credentials',
    SUPPORT = 'support',
    RESET = 'reset',
    CONFIGURE_MFA = 'CONFIGURE_MFA'
}
