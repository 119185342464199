<div class="container">
    <p>
        Markdown is a lightweight markup language that you can use to add
        formatting elements to plaintext text documents. Created by John Gruber
        in 2004, Markdown is now one of the world’s most popular markup
        languages.
    </p>
    <p>
        A Markdown file is a text file created using one of several possible
        dialects of the Markdown language. It uses plain text formatting but
        contains inline text symbols that specify how to format the text (e.g.,
        *bold* for bold text, or other markups for italics, indentation,
        headers, etc.). MARKDOWN files are designed for writing documentation in
        plain text that can be easily converted to HTML.
    </p>

    <br />

    <table>
        <thead>
            <tr>
                <th>Element</th>
                <th>Markdown</th>
                <th>Rendered Output</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#headings"
                        target="_blank"
                        >Heading
                    </a>
                </td>
                <td>
                    <div>
                        <p>#H1</p>
                        <p>##H2</p>
                        <p>###H3</p>
                        <p>####H4</p>
                        <p>#####H5</p>
                        <p>######H6</p>
                    </div>
                </td>
                <td>
                    <div>
                        <H1> Heading level 1 </H1>
                        <H2> Heading level 2 </H2>
                        <H3> Heading level 3 </H3>
                        <H4> Heading level 4 </H4>
                        <H5> Heading level 5 </H5>
                        <H6> Heading level 6 </H6>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#bold"
                        target="_blank"
                        >Bold
                    </a>
                </td>
                <td><p>**bold text**</p></td>
                <td><b> Bold text </b></td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#italic"
                        target="_blank"
                        >Italic
                    </a>
                </td>
                <td><p>*italicized text*</p></td>
                <td><i> Italicized text </i></td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#paragraphs-1"
                        target="_blank"
                        >Paragraph
                    </a>
                </td>
                <td>
                    <p>First Paragraph.</p>
                    <p>Second Paragraph.</p>
                </td>
                <td>
                    <p>First Paragraph.</p>
                    <p>Second Paragraph.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#blockquotes-1"
                        target="_blank"
                        >Blockquote
                    </a>
                </td>
                <td><p>> blockquote</p></td>
                <td><blockquote>blockquote</blockquote></td>
            </tr>

            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#ordered-lists"
                        target="_blank"
                        >Ordered List
                    </a>
                </td>
                <td>
                    <ol class="clear-fix">
                        <li>First item</li>
                        <li>Second item</li>
                        <li>Third item</li>
                    </ol>
                </td>
                <td>
                    <div>
                        <ol class="clear-fix">
                            <li>First item</li>
                            <li>Second item</li>
                            <li>Third item</li>
                        </ol>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#unordered-lists"
                        target="_blank"
                        >Unordered List
                    </a>
                </td>
                <td>
                    <div>
                        <p>- First item</p>
                        <p>- Second item</p>
                        <p>- Third item</p>
                    </div>
                </td>
                <td>
                    <div>
                        <ul class="clear-fix">
                            <li>First item</li>
                            <li>Second item</li>
                            <li>Third item</li>
                        </ul>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#code"
                        target="_blank"
                        >Code
                    </a>
                </td>
                <td>`code`</td>
                <td>
                    <div>
                        <p>code</p>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#horizontal-rules"
                        target="_blank"
                        >Horizontal Rule
                    </a>
                </td>
                <td><p>---</p></td>
                <td>
                    <div>
                        <hr />
                    </div>
                </td>
            </tr>

            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/basic-syntax/#links"
                        target="_blank"
                        >Link
                    </a>
                </td>
                <td>
                    <div>
                        <p>[title](https://www.example.com)</p>
                    </div>
                </td>
                <td>
                    <div>
                        <a href="https://www.example.com" target="_blank"
                            >title
                        </a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#tables"
                        target="_blank"
                        >Table
                    </a>
                </td>
                <td>
                    <div>
                        <p>| Syntax | Description |</p>
                        <p>| ----------- | ----------- |</p>
                        <p>| Header | Title |</p>
                        <p>| Paragraph | Text |</p>
                    </div>
                </td>
                <td>
                    <div>
                        <table>
                            <tr>
                                <th>Syntax</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>Header</td>
                                <td>Title</td>
                            </tr>
                            <tr>
                                <td>Paragraph</td>
                                <td>Text</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#fenced-code-blocks"
                        target="_blank"
                        >Fenced Code Block
                    </a>
                </td>
                <td>
                    <div>
                        <p>Here's a sentence with a footnote. [^1]</p>

                        <p>[^1]: This is the footnote.</p>
                    </div>
                </td>
                <td>
                    <div>
                        <p>
                            Here's a sentence with a footnote.
                            <a appDisableElement href="#footnote-1">[1]</a>
                        </p>

                        <p id="footnote-1" class="flex">
                            [1] This is the footnote.
                            <span
                                class="info"
                                appIcon
                                [data]="returnIcon"
                            ></span>
                        </p>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#footnotes"
                        target="_blank"
                        >Footnote
                    </a>
                </td>
                <td>
                    <div>
                        ```
                        <p>{{ "{" }}</p>
                        <p>"firstName": "John",</p>
                        <p>"lastName": "Smith",</p>
                        <p>"age": 25,</p>
                        <p>}</p>
                        ```
                    </div>
                </td>
                <td>
                    <div>
                        <code>
                            <p>{{ "{" }}</p>
                            <p>"firstName": "John",</p>
                            <p>"lastName": "Smith",</p>
                            <p>"age": 25,</p>
                            <p>}</p>
                        </code>
                    </div>
                </td>
            </tr>

            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#definition-lists"
                        target="_blank"
                        >Definition List
                    </a>
                </td>
                <td>
                    <div>
                        <p>term</p>
                        <p>: definition of the term</p>
                    </div>
                </td>
                <td>
                    <div>
                        <dl>
                            <dt>term</dt>
                            <dd>definition of the term</dd>
                        </dl>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#strikethrough"
                        target="_blank"
                        >Strikethrough
                    </a>
                </td>
                <td>
                    <div>
                        <p>The world is flat.</p>
                    </div>
                </td>
                <td>
                    <div><del>The world is flat.</del></div>
                </td>
            </tr>
            <tr>
                <td>
                    <a
                        href="https://www.markdownguide.org/extended-syntax/#task-lists"
                        target="_blank"
                        >Task List
                    </a>
                </td>
                <td>
                    <div>
                        <p>- [x] Write the press release</p>
                        <p>- [ ] Update the website</p>
                        <p>- [ ] Contact the media</p>
                    </div>
                </td>
                <td>
                    <div>
                        <input type="checkbox" id="random1" name="random1" />
                        <label for="random1"> Wite the press release</label
                        ><br />
                        <input type="checkbox" id="random2" name="random2" />
                        <label for="random2"> Update the website</label><br />
                        <input type="checkbox" id="random3" name="random3" />
                        <label for="random3"> Contact the media</label><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <br />

    <p>To know more about markdown, refer following links.</p>
    <ol class="clear-fix">
        <li>
            <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
            >
                Basic Syntax
            </a>
        </li>
        <li>
            <a
                href="https://www.markdownguide.org/extended-syntax/"
                target="_blank"
                >Extended Syntax
            </a>
        </li>
    </ol>
</div>
