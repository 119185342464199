import { TemplateRef } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Widget } from '../../classes/Widget';
import { FormFieldAppearance } from '../../enums/AppearanceType';
import { FilterType } from '../../enums/FilterType';
import { IconType } from '../../enums/IconType';
import { IApiInfo } from '../api/IApiInfo';
import { IButtonGeneratorInput } from '../button-generator/IButtonGeneratorInput';
import { IDropdownData } from '../dropdown-data/IDropdownData';
import { IIcon } from '../icon-data/IIcon';
import { IHiddenDependency } from './../form-dependency-data/IHiddenDependency';
import { IValidator } from './IValidator';

export interface IFormField {
    varId?: string;
    label: string;
    placeholder: string;
    fieldType: FilterType;
    name: string;
    accept?: string; // For file input type specification
    multipleUpload?: boolean;
    required?: boolean; // Default is true
    infoMessage?: string;
    validations?: IValidator[];
    filters?: string[];
    apiInfo?: IApiInfo;
    apiInput?: any;
    responseValueKey?: string; // It should be pipe separated like key1|key2|key3
    extractValueFromKey?: string;
    value?: any;
    listData?: IDropdownData[];
    loadingMessage?: string;
    getKey?: string; // default value id. It is returned as value. Pass null if you want entire object
    showKey?: string; // default value label. It is shown
    groupBy?: string; //default value null. It is used in grouping dropdown to set group key
    maxSelectionLimit?: number; // Default values that can be selected for multi select dropdown. Defaults to Infinity
    suffixIcon?: {
        iconData: IIcon; // Use only material icon
        hoverText: string;
        function?;
    }; // Not for dropdowns as of now
    prefixIcon?: {
        iconData: IIcon; // Use only material icon
        hoverText: string;
        function?;
    };
    suffixText?: string;
    prefixText?: string;
    type?: string;
    disabled?: boolean; // Permanently disable the field
    populateFromControl?: string; // For dropddowns only. Specify control name from which list data must be extracted;
    minDate?: Date; // For Date
    maxDate?: Date; // For Date
    minDateFromControl?: string;
    maxDateFromControl?: string;
    hiddenDependency?: IHiddenDependency[];
    displayWhen?: {
        controlName: string;
        value: any;
        operation: 'EQUALS';
    };
    dropdownItemRenderImageFromId?: boolean;
    dropdownItemRenderImageType?: IconType;
    disableWithValidation?: true;
    suffixButton?: {
        show: boolean;
        text: string;
        onClick?: Function;
        noFill?: boolean; // to remove filled class in button styling
    };
    appearance?: MatFormFieldAppearance | string | FormFieldAppearance; // default value 'outline'
    hideSelectAllInDropdown?: boolean;
    showLabel?: boolean; // Only for dropdown and multi-dropdown.
    eachGroupMaxLimitSelection?: number;
    onFocusout?;
    onChange?;
    matChipInputLimit?: number;
    disableDropdown?: boolean;
    customField?: boolean;
    showSuffixIconInLabel?: boolean;
    buttonGenInput?: IButtonGeneratorInput; // Only for type CALLBACK_BUTTON
    deleteValueCallback?(buttonRef); // Only for type CALLBACK_BUTTON
    imageUpload?: {
        imageDetails: {
            modalLabel: string;
            inputLabel?: string;
            maxSizeAllowed?: number; // Number of bytes allowed
        }[];
        extraMessage?: string; // Can be HTML string
        noImagePreviewMessage?: string;
        moreInfoButton?: IButtonGeneratorInput;
        widgetRef?: Widget;
        /**
         * imageDetails is a list of object, each consist of:
         * label: string
         * file: File
         * image: string | ArrayBuffer
         * dirty: boolean
         *
         * field will be of type IFormField
         */
        uploadCallback?(imageDetails, field);
    };
    timePickerMinutesGap?: number;
    minTime?: string; // In Format of HH:MM am/pm
    populateFromFormData?: string; // To populate value from other form group data
    showTooltip?: boolean; // To enable or disable tooltip only in content field
    contentDisplayValue?: string; // Display value for Field Type: "CONTENT"
    dropDownType?: FilterType;
    onDropDownCumEditChange?;
    extraClass?: string; // Not handled for every field
    disabelDropDownCumEditToggle?: boolean;
    autoSelectDropDownData?: boolean; //This will only work for single Select dropdown
    customInputCallback?: Function;
    hideWysiwygEditorInsertOperation?: IWysiwygEditorInsertOperation[];
    suffixTemplate?: {
        templateRef: TemplateRef<any>;
        templateData?: any;
    };
    hideFloatLabel?: boolean; // Not handled for every field
    extraData?: any;
    showLabelInDropdownLegend?: boolean; // Not handled for every field
    disabledText?: string;
    table?: {
        tableHeadings?: string[];
        rowfields?: {
            [rowKey: string]: IFormField;
        }[];
    };
    populateFromData?: string;
    afterResponse?(response?);
    listDataExists?: boolean;
    groupByKey?: string;
    objectListData?: object[];
    id?: string; // This key will store the filter id.
    isFilter?: boolean; // This key is used for getting field appearance for filter.
    fieldStyle?: object; // This key is used to give style to field.
    containerClass?: string; // This key is used to give class to field.
    hideFilterInfoIcon?: boolean; // This key Will hide the filter info icon.
    showArrow?: boolean; // This key is used for field type number with arrow.
    hideFilterHead?: boolean; // This key is used to hide the filter head.
    characterLimit?: number;
    /**
     * This key is used to disable entering of characters when the character counts exceeds the character limit
     * This key is used only in WysiwygEditorFieldComponent
     */
    preventCharacterEntering?: boolean;
    preventFieldReset?: boolean; // set this to true to prevent field from resetting on modal reset
    textAreaStyles?: object; // Key used to styles textArea input box
    labelStyles?: object; //Key used to apply styling on label.,
    disableFontChange?: boolean; // if true font change option will be removed from wysiwyg editor
    onFocus?;
    isInputField?: boolean; // it will be used with Multi-dropdown-with-input field filter type. if true, then input field will be visible in dropdown.
    customInputField?: IFormField; // Will help to generate field below dropdown.
    parentField?: string; // to trigger validations for the parent field or can get the parent field linked with the provided custom input field.
    editorPlaceholder?: string; // to set the placeholder inside Editor area.
}

export enum IWysiwygEditorInsertOperation {
    TABLE = 'table',
    PICTURE = 'picture',
    LINK = 'link',
    HR = 'hr'
}
