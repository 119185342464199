import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { OtrsCreateTicketModalComponent } from '../../../modal-templates/integration/otrs-modal/otrs-create-ticket-modal/otrs-create-ticket-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-otrs-ticket-listing',
    templateUrl: './otrs-ticket-listing.component.html',
    styleUrls: ['./otrs-ticket-listing.component.sass']
})
export class OtrsTicketListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    otrsStatusResponse = null;
    widgetIcon: IIcon = {
        class: 'assets/integrations/otrs.png',
        type: IconType.IMAGE
    };
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        const apiArgs: IHitApi = {
            url: ApiUrls.INTEGRATION_STATUS + 'OTRS',
            intactUrl: `${ApiUrls.INTEGRATION_STATUS}{integration-name}`,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                this.otrsStatusResponse = response;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error hitting otrs status api.'
                );
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Ticket',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.RAISED,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        const modalArgs: IModalData = {
                            modalName: 'OTRS Create Ticket',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Credentials',
                                    stepData: {
                                        componentToLoad:
                                            OtrsCreateTicketModalComponent,
                                        payload: {
                                            data: {
                                                response:
                                                    this.otrsStatusResponse,
                                                widgetRef: this.widgetRef
                                            }
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 60,
                            modalHeightVh: 60
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    },

                    showLoader: false
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'ticketlist'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'Ticket Number',
                    columnName: 'Ticket Number'
                },
                {
                    columnKey: 'Ticket ID',
                    columnName: 'Ticket ID'
                },
                {
                    columnKey: 'Customer',
                    columnName: 'Customer'
                },
                {
                    columnKey: 'Customer User',
                    columnName: 'Customer User'
                },
                {
                    columnKey: 'Title',
                    columnName: 'Title'
                },
                {
                    columnKey: 'Type',
                    columnName: 'Type'
                },
                {
                    columnKey: 'Priority',
                    columnName: 'Priority'
                },

                {
                    columnKey: 'Status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'Queue',
                    columnName: 'Queue'
                },
                {
                    columnKey: 'Create Date',
                    columnName: 'Create Date'
                },
                {
                    columnKey: 'Changed Date',
                    columnName: 'Changed Date'
                }
            ]
        };
    }
}
