import { Component, NgZone, OnInit } from '@angular/core';
import {
    RowEvent,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { AwsOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/aws-onboarding-modal/aws-onboarding-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-aws-listing',
    templateUrl: './aws-listing.component.html',
    styleUrls: ['./aws-listing.component.sass']
})
export class AwsListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    constructor(
        widgetData: WidgetInjectedData,
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add Independent Account',
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openOnboardingModal();
                    },
                    showLoader: false,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY
                },
                {
                    buttonName: 'Update Linked Account',
                    function: (buttonRef: IButtonGeneratorInput) => {
                        buttonRef.loader = true;
                        const args: IHitApi = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo[
                                'updateLinkedAccounts'
                            ]
                        );
                        args.input = {};
                        args.function = (response) => {
                            this.widgetRef.refreshWidget();
                            buttonRef.loader = false;
                            this.notificationService.showSnackBar(
                                Messages.ACCOUNT_UPDATE_LIST
                            );
                        };
                        args.errorFunction = (error) => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                error.error.message,
                                true
                            );
                        };
                        new HitApi(
                            args,
                            this.httpService,
                            this.ngZone
                        ).hitApi();
                    },
                    showLoader: true,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Accounts Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'accountNumber',
                    columnName: 'Account'
                },
                {
                    columnKey: 'payer',
                    columnName: 'Payer',
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data.awsCred.payer;
                    },
                    columnValueGetter: (params: ValueGetterParams) => {
                        return params.data.awsCred.payer;
                    }
                },
                {
                    columnKey: 'accountName',
                    columnName: 'Name'
                },
                {
                    columnKey: 'roleArn',
                    columnName: 'Role ARN',
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data.awsCred.roleArn;
                    }
                },
                {
                    columnKey: 'accessKey',
                    columnName: 'Access Key',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return rowData.data.awsCred.accessKey;
                    }
                },
                {
                    columnKey: 'status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'type',
                    columnName: 'Type'
                },
                {
                    columnKey: 'spoc',
                    columnName: 'SPOC'
                },

                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        let accountStatus;
                        if (rowData.data['accountStatus']) {
                            if (rowData.data['accountStatus'] === 'UNSUSPEND') {
                                accountStatus = 'SUSPEND';
                            } else if (
                                rowData.data['accountStatus'] === 'SUSPEND'
                            ) {
                                accountStatus = 'UNSUSPEND';
                            }
                        }

                        const buttons: IButtonGeneratorInput[] = [
                            // EDIT BUTTON
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: false,
                                function: () => {
                                    this.openOnboardingModal(rowData.data);
                                }
                            },
                            // SUSPEND BUTTON
                            {
                                buttonName:
                                    rowData.data['accountStatus'] === 'SUSPEND'
                                        ? 'Undo'
                                        : 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType:
                                    rowData.data['accountStatus'] === 'SUSPEND'
                                        ? ButtonColorType.INFO
                                        : ButtonColorType.WARN,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    const modalData: IConfirmationModal = {
                                        modalName: rowData.data['accountStatus']
                                            ? rowData.data['accountStatus'] ===
                                              'SUSPEND'
                                                ? 'Recover AWS Account'
                                                : 'Suspend AWS Account'
                                            : 'Suspend AWS Account',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: rowData.data['accountStatus']
                                                ? rowData.data[
                                                      'accountStatus'
                                                  ] === 'SUSPEND'
                                                    ? 'fas fa-undo'
                                                    : 'fas fa-trash'
                                                : 'fas fa-trash'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to ${accountStatus.toLowerCase()} ${
                                            rowData.data.accountName
                                        }`,
                                        buttonText: rowData.data[
                                            'accountStatus'
                                        ]
                                            ? rowData.data['accountStatus'] ===
                                              'SUSPEND'
                                                ? 'Recover'
                                                : 'Suspend'
                                            : 'Suspend',
                                        buttonColorType:
                                            ButtonColorType.SECONDARY,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            const apiArgs =
                                                Helper.generateHitApiConfig(
                                                    this.widgetRef.widgetData
                                                        .widgetInfo[
                                                        'suspendUnsuspend'
                                                    ]
                                                );
                                            apiArgs.input = {};
                                            apiArgs.intactUrl = apiArgs.url;
                                            apiArgs.url =
                                                apiArgs.url.replace(
                                                    '{id}',
                                                    rowData.data.accountNumber
                                                ) + `?status=${accountStatus}`;
                                            apiArgs.function = () => {
                                                this.widgetRef.notificationsService.showSnackBar(
                                                    `${rowData.data.accountName.toUpperCase()} ${accountStatus}ED SUCCESSFULLY`
                                                );
                                                this.widgetRef.refreshWidget();
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                            };
                                            apiArgs.errorFunction = (error) => {
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                                Helper.showErrorMessage(
                                                    this.notificationService,
                                                    error,
                                                    'Error perfoming action'
                                                );
                                            };

                                            new HitApi(
                                                apiArgs,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'single'
        };
    }

    openOnboardingModal(formData?: any) {
        const modalArgs: IModalData = {
            modalName: 'Onboard AWS',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            modalSteps: [
                {
                    stepName: 'AWS ONBOARDING',
                    stepData: {
                        componentToLoad: AwsOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetInfo:
                                    this.widgetRef.widgetData.widgetInfo,
                                formData: formData
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 90
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
