import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { WidgetInjectedData } from '../../../../classes/WidgetInjectedData';
import { IFormGeneratorInput } from '../../../../interfaces/form-generator/IFormGeneratorInput';
import { Helper } from './../../../../classes/Helper';
import { UpdateAction } from './../../../../enums/UpdateAction';
import { IUpdateAction } from './../../../../interfaces/update-action/IUpdateAction';

@Component({
    selector: 'app-aws-create-instance-provisioning',
    templateUrl: './aws-create-instance-provisioning.component.html',
    styleUrls: ['./aws-create-instance-provisioning.component.sass']
})
export class AwsCreateInstanceProvisioningComponent implements OnInit {
    widgetRef: Widget;
    accAndRegFormGenInputs: IFormGeneratorInput = null;
    accAndRegFormGroup: FormGroup;
    volumeTypeFormGenInputs: IFormGeneratorInput = null;
    volumeTypeFormGroup: FormGroup;
    imageIdFormGenInputs: IFormGeneratorInput = null;
    imageIdFormGroup: FormGroup;
    sizeFormGenInputs: IFormGeneratorInput = null;
    sizeFormGroup: FormGroup;
    deviceIndexFormGenInputs: IFormGeneratorInput = null;
    deviceIndexFormGroup: FormGroup;
    keyValuePairFormGenInputs: IFormGeneratorInput[] = null;
    keyValuePairFormGroup: Map<Symbol, FormGroup> = new Map();
    loader: boolean = false;
    keyValueSet = {};
    lastKeyValuePair: Symbol;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);

    constructor(
        widgetData: WidgetInjectedData,
        private listHttpService: ListHttpService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
        this.keyValuePairFormGenInputs = [this.getKeyValueFormGenInput()];
    }
    setUpBasics() {
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.hideRefreshIcon.next(true);
        this.accAndRegFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Account Id',
                    placeholder: 'Select Account Id',
                    name: 'accountId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showKey: 'label',
                    apiInfo: {
                        ...this.listHttpService.awsAccountIdApiInfo,
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    },
                    responseValueKey: 'dataList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account id is required'
                        }
                    ]
                },
                {
                    label: 'Region',
                    placeholder: 'Select Region',
                    name: 'region',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    showKey: 'label',
                    responseValueKey: 'dataList',
                    apiInfo: {
                        ...this.listHttpService.awsRegionIdApiInfo,
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Region is required'
                        }
                    ]
                }
            ]
        };
        this.volumeTypeFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Instance Type',
                    placeholder: 'Select Instance Type',
                    name: 'instanceType',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: false,
                    showKey: 'label',
                    responseValueKey: 'dataList',
                    apiInfo: {
                        ...this.listHttpService.awsInstanceTypeApiInfo,
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    }
                },
                {
                    label: 'Volume Type',
                    placeholder: 'Select Volume Type',
                    name: 'volumeType',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: false,
                    showKey: 'label',
                    listData: this.volumeTypeList
                }
            ]
        };

        this.sizeFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Size(GIB)',
                    placeholder: 'Select Size',
                    name: 'size',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    suffixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help_outline'
                        },
                        hoverText: 'Min: 1 GiB , Max: 16384 GiB'
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Size is required'
                        }
                    ]
                },
                {
                    label: 'Device Name',
                    placeholder: 'Select Device Name',
                    name: 'deviceName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Device name is required'
                        }
                    ]
                }
            ]
        };
        this.deviceIndexFormGenInputs = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Device Index',
                    placeholder: 'Enter Device Index',
                    name: 'deviceIndex',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Device index is required'
                        }
                    ]
                }
            ]
        };
    }
    depField(event) {
        const accountId = event?.accountId ? event.accountId : '';
        const region = event?.region ? event.region : '';
        if (accountId && region) {
            this.imageIdFormGenInputs = {
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Image Id',
                        placeholder: 'Select Image Id',
                        name: 'imageId',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        showKey: 'label',
                        responseValueKey: 'dataList',
                        apiInput: {
                            accountIds: [accountId],
                            regions: [region]
                        },
                        apiInfo: {
                            apiRouteSuffix: ApiUrls.AWS_IMAGE_ID,
                            host: this.widgetRef.widgetData.widgetInfo.create
                                .host,
                            requestType: RequestType.POST,
                            authorization:
                                AuthorizationType.AUTHORIZATION_TOKEN_V2,
                            customInput: null
                        },
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Account id is required'
                            }
                        ]
                    },
                    {
                        label: 'Network Interface',
                        placeholder: 'Select Network Interface',
                        name: 'networkInterface',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: false,
                        showKey: 'label',
                        responseValueKey: 'dataList',
                        apiInput: {
                            accountIds: [accountId],
                            regions: [region]
                        },
                        apiInfo: {
                            apiRouteSuffix: ApiUrls.AWS_NETWORK_INTERFACE,
                            host: this.widgetRef.widgetData.widgetInfo.create
                                .host,
                            requestType: RequestType.POST,
                            authorization:
                                AuthorizationType.AUTHORIZATION_TOKEN_V2,
                            customInput: null
                        }
                    }
                ]
            };
            this.updateControlInput = {
                action: UpdateAction.REFRESH,
                controls: ['imageId', 'networkInterface']
            };
            if (this.imageIdFormGroup) {
                this.widgetRef.changeDetectorRef.detectChanges();
                this.updateControl.next(this.updateControlInput);
            }
        }
    }

    createInstance() {
        if (this.loader) {
            return;
        }

        Helper.markAllFieldAsTouched(this.accAndRegFormGroup);
        if (this.accAndRegFormGroup.invalid) {
            this.accAndRegFormGroup.updateValueAndValidity();
            return;
        }
        if (this.imageIdFormGroup) {
            Helper.markAllFieldAsTouched(this.imageIdFormGroup);
            if (this.imageIdFormGroup.invalid) {
                this.imageIdFormGroup.updateValueAndValidity();
                return;
            }
        }
        if (this.deviceIndexFormGroup) {
            Helper.markAllFieldAsTouched(this.deviceIndexFormGroup);
            if (this.deviceIndexFormGroup.invalid) {
                this.deviceIndexFormGroup.updateValueAndValidity();
                return;
            }
        }

        if (this.sizeFormGroup) {
            Helper.markAllFieldAsTouched(this.sizeFormGroup);
            if (this.sizeFormGroup.invalid) {
                this.sizeFormGroup.updateValueAndValidity();
                return;
            }
        }

        this.loader = true;
        const inputs = this.prepareInput();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = inputs;
        apiArgs.function = () => {
            this.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Instance Created Successfully'
            );
            this.reset();
        };
        apiArgs.errorFunction = (error) => {
            this.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Creating Instance'
            );
            this.reset();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const accountId = this.accAndRegFormGroup.get('accountId').value;
        const region = this.accAndRegFormGroup.get('region').value;
        const tags = [];
        let configurationProvision = {};
        const ec2NetworkInterface = this.imageIdFormGroup?.get(
            'networkInterface'
        )?.value
            ? this.imageIdFormGroup.get('networkInterface').value
            : '';

        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;
        this.keyValuePairFormGroup.forEach((value, index) => {
            if (this.lastKeyValuePair !== index) {
                tags.push(value.value);
            }
        });
        configurationProvision = {
            ec2ImageId: this.imageIdFormGroup?.get('imageId')?.value
                ? this.imageIdFormGroup.get('imageId').value
                : '',
            ec2InstanceType: this.volumeTypeFormGroup?.get('instanceType')
                ?.value
                ? this.volumeTypeFormGroup.get('instanceType').value
                : '',
            ec2VolumeType: this.volumeTypeFormGroup?.get('volumeType')?.value
                ? this.volumeTypeFormGroup.get('volumeType').value
                : '',
            ec2NetworkInterface: ec2NetworkInterface,
            tags: tags,
            ec2VolumeSize: this.sizeFormGroup?.get('size')?.value
                ? this.sizeFormGroup.get('size').value
                : ''
        };
        if (ec2NetworkInterface) {
            configurationProvision['deviceName'] = this.sizeFormGroup?.get(
                'deviceName'
            )?.value
                ? this.sizeFormGroup.get('deviceName').value
                : '';
            configurationProvision['deviceIndex'] =
                this.deviceIndexFormGroup?.get('deviceIndex')?.value
                    ? this.deviceIndexFormGroup.get('deviceIndex').value
                    : '';
        }

        return {
            configurationProvision: configurationProvision,
            accountIds: accountId ? [accountId] : [],
            regions: region ? [region] : []
        };
    }
    reset() {
        this.accAndRegFormGroup.reset();
        this.volumeTypeFormGroup.reset();
        if (this.deviceIndexFormGroup) {
            this.deviceIndexFormGroup.reset();
        }
        if (this.keyValuePairFormGenInputs.length !== 1) {
            this.keyValuePairFormGenInputs.splice(
                0,
                this.keyValuePairFormGenInputs.length - 1
            );
        }
        this.lastKeyValuePair =
            this.keyValuePairFormGenInputs[
                this.keyValuePairFormGenInputs.length - 1
            ].formId;

        this.keyValuePairFormGroup.forEach((fg, id) => {
            if (id !== this.lastKeyValuePair) {
                this.keyValuePairFormGroup.delete(id);
            } else {
                fg.reset();
            }
        });
    }
    volumeTypeList = [
        {
            id: 'gp2',
            label: 'General Purpose SSD (gp2)'
        },
        {
            id: 'standard',
            label: 'Magnetic (standard)'
        },
        {
            id: 'io1',
            label: 'Provisioned IOPS SSD (io1)'
        },
        {
            id: 'sc1',
            label: 'Cold HDD (sc1)'
        },
        {
            id: 'st1',
            label: 'Throughput Optimizied HDD (st1)'
        }
    ];

    getKeyValueFormGenInput(): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: 'Key',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'Key',
                    fieldType: FilterType.TEXT,
                    label: 'Key',
                    placeholder: 'Key',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key is required'
                        }
                    ]
                },
                {
                    name: 'Value',
                    fieldType: FilterType.TEXT,
                    label: 'Value',
                    placeholder: 'Value',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'value is required'
                        }
                    ]
                }
            ]
        };
    }
    idleKeyValueForms() {
        this.keyValuePairFormGenInputs = this.keyValuePairFormGenInputs.map(
            (fg, index) => {
                if (index !== this.keyValuePairFormGenInputs.length - 1) {
                    fg.state = FormState.IDLE;
                }
                return Helper.dereference(fg);
            }
        );
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    lastKeyValueFormUpdate(index: number, formId: Symbol) {
        Helper.markAllFieldAsTouched(this.keyValuePairFormGroup.get(formId));
        if (this.keyValuePairFormGroup.get(formId).invalid) {
            this.keyValuePairFormGroup.get(formId).updateValueAndValidity();
            return;
        }
        if (this.keyValuePairFormGroup.has(formId)) {
            const fg = this.keyValuePairFormGroup.get(formId);
            if (fg.valid) {
                if (
                    this.keyValuePairFormGenInputs &&
                    this.keyValuePairFormGenInputs.length
                ) {
                    if (this.getKey().includes(fg.get('Key').value)) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Configuration for this key is already present',
                            true
                        );
                    } else {
                        this.keyValuePairFormGenInputs.push(
                            this.getKeyValueFormGenInput()
                        );
                        this.idleKeyValueForms();
                    }
                }
            }
        }
    }
    getKey(): string[] {
        const key = [];
        this.keyValuePairFormGenInputs.forEach((fg, index) => {
            if (index !== this.keyValuePairFormGenInputs.length - 1) {
                if (this.keyValuePairFormGroup.has(fg.formId)) {
                    key.push(
                        this.keyValuePairFormGroup.get(fg.formId).get('Key')
                            .value
                    );
                }
            }
        });
        return key;
    }
    deleteKeyValuePairFormGroup(index: number, formId: Symbol) {
        this.keyValuePairFormGroup.delete(formId);
        this.keyValuePairFormGenInputs = Helper.removeIndexfromArray(
            index,
            this.keyValuePairFormGenInputs
        );
    }
}
