import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';

@Component({
    selector: 'app-create-tag-ConfigurationForm-step-two.html',
    templateUrl: './create-tag-ConfigurationForm-step-two.html',
    styleUrls: ['./create-tag-ConfigurationForm-step-two.sass']
})
export class CreateTagConfigurationFormStepTwo implements OnInit {
    data;
    addConfigurationForm: FormGroup;
    filterData: IFilterData = null;
    keyValueSet = {};
    widgetRef: Widget;
    isUpdate = false;
    stepData = null;
    edit: boolean;
    selectedKeyValuePair: any;
    incompleteKeyValuePair: boolean;
    updateErrorMessage = new BehaviorSubject('');
    keyValuePairFormRef = [];
    buttonInputs: IButtonGeneratorInput[];
    buttonOptions: IMultiButtonOption = {
        layout: { justifyContent: 'space-between' }
    };

    constructor(
        public modalInjectedData: ModalInjectedData,
        private modalService: ModalService,
        public multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.stepData = modalInjectedData.data['stepData'];
        this.edit = modalInjectedData.data['edit'];
    }

    ngOnInit() {
        if (this.edit) {
            this.isUpdate = true;

            this.keyValueSet = this.stepData['keyValueSet'];
            this.selectedKeyValuePair = this.keyValueSet;
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .has(2)
        ) {
            const keyValueSet = this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .get(2);

            this.keyValueSet = keyValueSet;
        }
        this.buttonInputs = [
            {
                buttonColorType: ButtonColorType.PRIMARY,
                buttonName: 'Back',
                buttonType: ButtonType.RAISED,
                customClass: 'back',
                function: () => {
                    this.backButton();
                }
            },
            {
                buttonColorType: ButtonColorType.PRIMARY,
                buttonName: this.isUpdate ? 'Update' : 'Save',
                buttonType: ButtonType.RAISED,
                customClass: 'save',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveButton(buttonRef);
                }
            }
        ];
    }

    changeInputData(event) {
        if (event.length) {
            this.selectedKeyValuePair = event[0];
            this.incompleteKeyValuePair = event[1];
            this.keyValuePairFormRef = event[2];
        }
    }
    backButton() {
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, this.keyValueSet);
        this.multiStepFormService.previousStep(this.modalInjectedData.modalId);
    }

    saveButton(buttonRef: IButtonGeneratorInput) {
        this.keyValuePairFormRef.map((data: FormGroup) => {
            data.markAllAsTouched();
        });

        if (this.incompleteKeyValuePair) {
            return;
        }

        buttonRef.loader = true;
        const payLoadData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(1);

        payLoadData['keyValueSet'] = this.selectedKeyValuePair;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'] &&
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'setConvention'
                ]
                ? this.widgetRef.widgetData.widgetInfo[
                      'additionalApisForWidget'
                  ]['setConvention']
                : this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = payLoadData;
        apiArgs.function = () => {
            this.widgetRef.filterService.refreshWidget.next(
                new Set([this.modalService.modalData.sourceId])
            );

            buttonRef.loader = false;

            this.modalService.closeModal(null, this.modalInjectedData.modalId);
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'Convention updated successfully'
                    : 'Convention created successfully'
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;

            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.edit
                    ? 'Error while updating convention'
                    : 'Error while creating convention'
            );
            this.widgetRef.refreshWidget();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
