<app-button-generator [buttonGenInput]="buttonInput"> </app-button-generator>
<ag-grid-angular
    #agGrid
    style="width: 100%; min-height: 150px"
    [style.height.px]="90"
    class="ag-theme-balham"
    [rowData]="tableData"
    [columnDefs]="colDefs"
    [animateRows]="true"
    [icons]="agGridIcons"
    [rowHeight]="28"
    [suppressFieldDotNotation]="true"
>
</ag-grid-angular>
