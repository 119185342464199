import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { ButtonType } from './../../../../enums/ButtonType';
import { IOptimizerChecksApiResponse } from './../../../../interfaces/api/portlets/IOptimizerChecksApiResponse';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IBackendFormGeneratorInput } from './../../../../interfaces/form-generator/IBackendFormGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-optimizer-check-v2',
    templateUrl: './optimizer-check-v2.component.html',
    styleUrls: ['./optimizer-check-v2.component.sass']
})
export class OptimizerCheckV2Component implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    RED_SEV = `<span class="critical">Critical</span>`;
    YELLOW_SEV = `<span class="warning">Warning</span>`;
    GREEN_SEV = `<span class="ok">OK</span>`;
    severityTypeCheck: string;
    rowData: any;
    gridRef: GridOptions;
    selectedRowData = [];
    showSearchBox: boolean = true;
    severityButtons: IButtonGeneratorInput[];

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableGenInput = {
            columns: [],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            selection: 'single',
            afterResponse: (response: IOptimizerChecksApiResponse) => {
                this.rowData = response.dataList;
                this.severityTypeCheck = '';
                this.tableGenInput.columns = [];
                const overallSev = response.dataMap['overallSeverity'];
                if (overallSev === '_1') {
                    this.widgetRef.headerIcon.next({
                        class: 'assets/red_check.png',
                        type: IconType.IMAGE
                    });
                } else if (overallSev === '_2') {
                    this.widgetRef.headerIcon.next({
                        class: 'assets/yellow_check.png',
                        type: IconType.IMAGE
                    });
                } else if (response.dataList && response.dataList.length > 0) {
                    this.widgetRef.headerIcon.next({
                        class: 'assets/green_check.png',
                        type: IconType.IMAGE
                    });
                }
                response.dataMap['keysToShow'].forEach(
                    (value: IDropdownData) => {
                        this.tableGenInput.columns.push({
                            columnKey: value.id,
                            columnName: value.label
                        });
                    }
                );
                this.tableGenInput.columns.unshift({
                    columnKey: 'severity',
                    columnName: 'Severity',
                    cellRenderer: (rowData: RowEvent) => {
                        const sev = rowData.data['severity'];
                        if (sev === '_1') {
                            return this.RED_SEV;
                        } else if (sev === '_2') {
                            return this.YELLOW_SEV;
                        } else {
                            return this.GREEN_SEV;
                        }
                    },
                    hideFilterMenu: true,
                    pinned: 'left',
                    lockPinned: true
                });

                // Remediation Setup
                this.remediationSetup();

                this.widgetRef.attentionRequired(response);
                this.initButtonGenInput();
            },
            showViewIcon: true,
            columnResizeCount: 10
        };

        //Lite state Setup
        this.setupLiteState();
    }

    setupLiteState() {
        if (this.widgetRef.lightState) {
            this.showSearchBox = false;
            this.tableGenInput.selectionLimit =
                this.widgetRef.liteViewCheckSelectionLimit;
            this.tableGenInput.selectionLimitBreachMessage = `Maximum of ${this.widgetRef.liteViewCheckSelectionLimit} row can be selected`;
            this.tableGenInput.rowSelection = this.widgetRef.lightState
                ? 'single'
                : 'multiple';
        }
    }

    severityFilter(severityType?) {
        let filteredSearch = this.rowData;

        if (severityType) {
            filteredSearch = filteredSearch.filter((item) => {
                if (
                    item['severity'].toLowerCase() ===
                    severityType.toLowerCase()
                ) {
                    return item;
                }
            });
        }

        this.gridRef.api.setRowData(filteredSearch);
        this.gridRef.api.redrawRows();
    }

    getButtonColor(color) {
        switch (color) {
            case 'red':
                return 'warning';
            default:
                return 'blueInfoColor';
        }
    }

    initButtonGenInput() {
        const buttonData = {};
        let index = 0;
        const criticalButton: IButtonGeneratorInput = {
            buttonName: `Critical : ${
                this.widgetRef.apiResponse['dataMap']['totalSeverity']['_1'] ??
                0
            }`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            hoverText: 'Critical',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Critical',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_1');
                }
            }
        };
        const warningButton: IButtonGeneratorInput = {
            buttonName: `Warning : ${
                this.widgetRef.apiResponse['dataMap']['totalSeverity']['_2'] ??
                0
            }`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.PRIMARY,
            hoverText: 'Warning',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'Warning',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_2');
                }
            }
        };
        const okButton: IButtonGeneratorInput = {
            buttonName: `OK : ${
                this.widgetRef.apiResponse['dataMap']['totalSeverity']['_3'] ??
                0
            }`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.SUCCESS,
            hoverText: 'OK',
            hoverEffect: 'shadow',
            function: (buttoRef: IButtonGeneratorInput) => {
                if (
                    Helper.changeButtonType(
                        buttonData,
                        this.severityButtons,
                        'OK',
                        this.severityFilter.bind(this)
                    )
                ) {
                    this.severityFilter('_3');
                }
            }
        };

        this.severityButtons = [];
        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_1']) {
            this.severityButtons.push(criticalButton);
            buttonData['Critical'] = index++;
        }
        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_2']) {
            this.severityButtons.push(warningButton);
            buttonData['Warning'] = index++;
        }
        if (this.widgetRef.apiResponse['dataMap']['totalSeverity']['_3']) {
            this.severityButtons.push(okButton);
            buttonData['OK'] = index++;
        }
    }

    remediationSetup() {
        if (
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse &&
            (this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                'remediationDocument'
            ] ||
                this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                    'remediation'
                ]) &&
            this.widgetRef.widgetData.widgetInfo.remediationExtraInfo
        ) {
            this.tableGenInput.selection = 'multiple';
            this.tableGenInput.columns.push({
                columnKey: 'action',
                columnName: 'Action',
                pinned: 'right',
                filter: false,
                hideFilterMenu: true,
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData: RowEvent) => {
                    if (
                        this.widgetRef.widgetData.widgetInfo
                            .remediationExtraInfo &&
                        this.widgetRef.widgetData.widgetInfo
                            .remediationExtraInfo.actions
                    ) {
                        if (!rowData['buttonGenInputs']) {
                            rowData['buttonGenInputs'] = [];
                        }
                        this.widgetRef.widgetData.widgetInfo.remediationExtraInfo.actions.forEach(
                            (action) => {
                                const fixButton: IButtonGeneratorInput = {
                                    buttonName: action.buttonName,
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: ''
                                    },
                                    buttonType: ButtonType.TEXT,
                                    buttonColorType:
                                        action.buttonColor === 'red'
                                            ? ButtonColorType.WARN
                                            : ButtonColorType.INFO,
                                    showLoader: true,
                                    function: (buttonRef: IButtonData) => {
                                        this.remediationModalHandling(
                                            [rowData.data],
                                            action,
                                            buttonRef
                                        );
                                    }
                                };

                                if (action.confirmationMessage) {
                                    fixButton.showLoader = false;
                                }
                                rowData['buttonGenInputs'].push(fixButton);
                            }
                        );
                        return rowData;
                    }
                }
            });

            // remediation action setup on header
            if (
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo &&
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo
                    .actions
            ) {
                const actions: IAction[] = [];
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo.actions.forEach(
                    (action) => {
                        actions.push({
                            state: ActionState.ENABLED,
                            visibility: ActionVisibility.VISIBLE,
                            message: action.key,
                            actionId: action.key,
                            function: (actionId) => {
                                if (
                                    !this.gridRef.api.getSelectedRows().length
                                ) {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        'Select atleast 1 row',
                                        true
                                    );
                                    return;
                                }

                                this.remediationModalHandling(
                                    this.selectedRowData,
                                    action,
                                    null,
                                    actionId
                                );
                            },
                            icon: {
                                type: IconType.FONTAWSOME,
                                class: action.icon,
                                text: action.buttonName
                            }
                        });
                    }
                );

                this.widgetRef.operationalActions.value.set(
                    'Remediation',
                    actions
                );

                this.widgetRef.operationalActions.next(
                    this.widgetRef.operationalActions.value
                );
            }
        }
    }

    remediationModalHandling(rowData, action, buttonRef?, actionId?) {
        if (
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse &&
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                'remediationDocument'
            ]
        ) {
            // Fix through Form Parser
            // get Remediation document(JSON)
            if (buttonRef) {
                buttonRef.loader = true;
            } else if (actionId) {
                this.widgetRef.operationLoaders.set(actionId, true);
            }
            const remediateDocument: IApiInfo =
                this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                    'remediationDocument'
                ];
            Helper.getRemediationDoc(
                remediateDocument,
                rowData,
                this.widgetRef.httpService,
                this.widgetRef.ngZone,
                this.widgetRef.notificationsService,
                this.generateRemediationForm.bind(this),
                this.widgetRef.operationLoaders,
                this.widgetRef.changeDetectorRef,
                buttonRef,
                actionId
            );
        } else {
            // Direct Fix
            Helper.executionFunctionAfterConfirmation(
                {
                    executeFunctionAfterConfirmation: {
                        modalName: action.buttonName,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: action.icon
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: action.confirmationMessage,
                        buttonText: action.buttonName,
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    },
                    function: (modalId: Symbol) => {
                        Helper.performRemediationAction(
                            {
                                rowsToBeFixed: rowData
                            },
                            this.widgetRef.widgetData.widgetInfo
                                .securityCheckResponse[action.key],
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone,
                            this.widgetRef.notificationsService,
                            () => {
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                                this.widgetRef.refreshWidget();
                            }
                        );
                    }
                },
                this.widgetRef.modalService
            );
        }
    }

    generateRemediationForm(
        response: IBackendFormGeneratorInput,
        rowData: any[]
    ) {
        const modalId = Symbol();
        const formGenInput: IFormGeneratorInput =
            Helper.formGeneratorBtoFParser(
                response,
                rowData,
                this.widgetRef.notificationsService,
                this.widgetRef.httpService,
                this.widgetRef.changeDetectorRef,
                {
                    rowsToBeFixed: rowData
                },
                () => {
                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                }
            );

        const modalData: IModalData = {
            modalName: formGenInput.formName,
            modalIcon: null,
            sourceId: this.widgetRef.uniqueIdentity,
            modalId: modalId,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    onSelectionChanged() {
        if (this.gridRef.api.getSelectedNodes().length) {
            this.selectedRowData = [];
            const selectedNodes = this.gridRef.api.getSelectedNodes();
            if (selectedNodes.length) {
                selectedNodes.forEach((selectedRow) => {
                    this.selectedRowData.push(selectedRow.data);
                });
            } else {
                this.selectedRowData = [];
            }
        }
    }
}
