import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import {
    DocumentTypes,
    IDetailedDescription,
    IDetailedDescriptionLinks
} from 'src/app/shared/interfaces/widget/IWidgetDescription';
import videoJs from 'video.js';

@Component({
    selector: 'app-widget-documentation-more-info-modal',
    templateUrl: './widget-documentation-more-info-modal.component.html',
    styleUrls: ['./widget-documentation-more-info-modal.component.sass']
})
export class WidgetDocumentationMoreInfoModalComponent
    implements OnInit, AfterViewInit
{
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    leftArrowIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-left'
    };

    rightArrowIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right'
    };

    widgetRef: Widget;
    previewData: IDetailedDescription[];
    documentTypes = DocumentTypes;
    modalId = true;

    @ViewChild('videoJsTemplateRef') videoJsTemplateRef: any;
    videoPlayer: videoJs.Player;
    selectedImageIndex: number = 1;
    displayableImages = [];
    allImagesData: IDetailedDescriptionLinks[] = [];
    currentIndex: number = 1;
    selectedDocument: DocumentTypes;
    selectedIndex: number;
    constructor(public modalInputData: ModalInjectedData) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.selectedDocument = modalInputData.data['selectedDocument'];
    }

    ngOnInit(): void {
        const order = [
            DocumentTypes.DESCRIPTION,
            DocumentTypes.DETAILED_DESCRIPTION,
            DocumentTypes.IMAGES,
            DocumentTypes.VIDEOS,
            DocumentTypes.REFERENCE,
            DocumentTypes.USE_CASES,
            DocumentTypes.FAQ
        ];

        this.previewData = Helper.sortArrayWithOrder(
            this.modalInputData.data['previewData'],
            order,
            'type'
        );

        let index = 0;

        this.previewData.every((each) => {
            if (each.type !== DocumentTypes.DESCRIPTION) {
                if (each.type === this.selectedDocument) {
                    this.selectedIndex = index;
                    return false;
                }
                index = index + 1;
            }

            return true;
        });

        if (!this.selectedIndex) {
            this.selectedIndex = 0;
        }

        this.initImageData();
    }

    initImageData() {
        this.previewData.map((each) => {
            if (each.type === DocumentTypes.IMAGES) {
                const imageName = (name) => {
                    if (name) {
                        const arr = name.split('/');
                        if (arr.length) {
                            return arr[arr.length - 1];
                        } else {
                            return '';
                        }
                    } else {
                        return '';
                    }
                };

                this.allImagesData = each.links;
                each.links.map((each) => {
                    each.name = imageName(each.name);
                    if (this.displayableImages.length < 3) {
                        this.displayableImages.push(each);
                    }
                });
            }
        });
    }

    nextImage() {
        if (this.displayableImages.length < 3) {
            return;
        }

        this.allImagesData.push(this.allImagesData.shift());
        this.displayableImages = this.allImagesData.slice(0, 3);
    }

    prevImage() {
        if (this.displayableImages.length < 3) {
            return;
        }

        this.allImagesData.unshift(this.allImagesData.pop());
        this.displayableImages = this.allImagesData.slice(0, 3);
    }

    ngAfterViewInit() {
        this.initVideoPlayer();
    }

    initVideoPlayer() {
        this.previewData.every((each) => {
            if (each.type === DocumentTypes.VIDEOS) {
                if (this.videoJsTemplateRef) {
                    this.videoPlayer = videoJs(
                        this.videoJsTemplateRef.nativeElement,
                        {
                            autoplay: false,
                            controls: true,
                            sources: [
                                {
                                    src: each.links[0].link,
                                    type: 'video/mp4'
                                }
                            ],
                            poster: 'assets/default_video_thumbnail.png'
                        },
                        () => {}
                    );
                }
                return false;
            }
            return true;
        });
    }
}
