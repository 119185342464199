import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { DropdownGroupMultipleFieldAppearance } from 'src/app/shared/enums/AppearanceType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
    selector: 'app-multi-group-dropdown-field',
    templateUrl: './multi-group-dropdown-field.component.html',
    styleUrls: ['./multi-group-dropdown-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiGroupDropdownFieldComponent,
            multi: true
        }
    ]
})
export class MultiGroupDropdownFieldComponent
    implements OnInit, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    @Input() loading: Map<string, boolean>;
    @Input() compareWith: any;
    @Output() valueChange = new EventEmitter<any>();
    @Output() selectAllValue = new EventEmitter<any>();

    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    FilterType = FilterType;
    FormState = FormState;
    Infinity = Infinity;
    showOverlay = false;
    AppearanceType = DropdownGroupMultipleFieldAppearance;
    onChange: any = () => {};
    onTouch: any = () => {};
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    filterInfo: IFilterInfo;
    isOpen: boolean = false;
    isFreshOpen: boolean = true;
    selectedValues: any[] = [];
    constructor(private filterService: FiltersService) {}
    ngOnInit(): void {
        const existingAppearance = Object.values(this.AppearanceType);
        if (
            !existingAppearance.includes(
                this.field.appearance as DropdownGroupMultipleFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
        this.filterInfo = this.filterService.currentPageFilterInfo.has(
            this.field.id
        )
            ? this.filterService.currentPageFilterInfo.get(this.field.id)
            : null;
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {}
    onSelectAll(selectAll: boolean) {
        this.control.markAsDirty();
        this.selectAllValue.emit(selectAll);
    }
    onValueChange(event) {
        const selectedValues: IDropdownData[] = [];
        if (event && event.length) {
            event.forEach((selectedValue: IDropdownData) => {
                if (this.field.getKey) {
                    selectedValues.push(selectedValue[this.field.getKey]);
                } else if (this.field.getKey === null) {
                    selectedValues.push(selectedValue);
                } else {
                    selectedValues.push(selectedValue.id);
                }
            });
        }
        this.onChange(selectedValues);
        this.control.markAsDirty();
        this.valueChange.emit(selectedValues);
        if (this.filterInfo && this.filterInfo.restrictGroupItemSelect) {
            this.disableGroupItem(this.filterInfo.getFullObj);
        }
    }

    onOpenDropdown() {
        this.isOpen = true;
        if (
            this.filterInfo &&
            this.filterInfo.restrictGroupItemSelect &&
            this.isFreshOpen &&
            this.selectedValues &&
            this.selectedValues.length
        ) {
            setTimeout(
                () => this.disableGroupItem(this.filterInfo.getFullObj),
                500
            );
            this.isFreshOpen = false;
        }
    }

    /**
     * For disabling items based on groups if an item lies in one group and select
     * then items in other groups are disabled
     *
     * @param getFullObj  Whether the selected value list is having objects or strings
     * is true then object else string
     *
     */
    disableGroupItem(getFullObj) {
        const itemToGroupMap = new Map<string, string>();
        this.selectedValues.forEach((selectedValue) => {
            const selectedValueList = getFullObj
                ? selectedValue.id.split('|')
                : selectedValue.split('|');
            if (selectedValueList.length > 1) {
                itemToGroupMap.set(selectedValueList[1], selectedValueList[0]);
            }
        });
        if (
            this.ngSelect &&
            this.ngSelect.viewPortItems &&
            this.ngSelect.viewPortItems.length
        ) {
            this.ngSelect.viewPortItems.forEach((item) => {
                if (!item.children) {
                    if (!item.selected) {
                        if (
                            typeof item.value === 'object' &&
                            item.value['id']
                        ) {
                            const splittedList = item.value['id'].split('|');
                            if (splittedList.length > 1) {
                                if (
                                    itemToGroupMap.has(splittedList[1]) &&
                                    itemToGroupMap.get(splittedList[1]) !==
                                        splittedList[0]
                                ) {
                                    item.disabled = true;
                                } else {
                                    item.disabled = false;
                                }
                            }
                        }
                    }
                }
            });
            this.ngSelect.viewPortItems.forEach((item) => {
                if (item.children) {
                    item.disabled = item.children.every(
                        (each) => each.disabled
                    );
                }
            });
        }
    }
}
