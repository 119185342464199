import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { Messages } from './../../../../classes/Messages';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { FilterType } from './../../../../enums/FilterType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from './../../../../interfaces/dropdown-data/IDropdownData';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-create-alert-third-step',
    templateUrl: './create-alert-third-step.component.html',
    styleUrls: ['./create-alert-third-step.component.sass']
})
export class CreateAlertThirdStepComponent implements OnInit {
    IntegrationName = IntegrationName;
    initialSelection: IntegrationName[] = [];
    selectedIntegrations: Map<IntegrationName, IntegrationData> = new Map();
    availableSupportedFormats: Set<SupportedFormat> = new Set();

    severityFormGenInput: IFormGeneratorInput = null;
    severityFormGroup: FormGroup = null;
    emailFormGenInput: IFormGeneratorInput = null;
    emailFormGroup: FormGroup = null;
    slackFormGenInput: IFormGeneratorInput = null;
    slackFormGroup: FormGroup = null;
    freshdeskFormGenInput: IFormGeneratorInput = null;
    freshdeskFormGroup: FormGroup = null;
    freshserviceFormGenInput: IFormGeneratorInput = null;
    freshserviceFormGroup: FormGroup = null;
    zendeskFormGenInput: IFormGeneratorInput = null;
    zendeskFormGroup: FormGroup = null;
    serviceNowFormGenInput: IFormGeneratorInput = null;
    serviceNowFormGroup: FormGroup = null;
    jiradeskFormGenInput: IFormGeneratorInput = null;
    jiradeskFormGroup: FormGroup = null;
    autotaskFormGenInput: IFormGeneratorInput = null;
    autotaskFormGroup: FormGroup = null;
    autotaskChildFormGenInput: IFormGeneratorInput = null;
    autotaskChildFormGroup: FormGroup = null;
    autotaskParentFormGenInput: IFormGeneratorInput = null;
    autotaskParentFormGroup: FormGroup = null;
    otrsFormGenInput: IFormGeneratorInput = null;
    otrsFormGroup: FormGroup = null;
    otrsUserFormGenInput: IFormGeneratorInput = null;
    otrsUserFormGroup: FormGroup = null;
    otrsParentFormGenInput: IFormGeneratorInput = null;
    otrsParentFormGroup: FormGroup = null;
    casdmFormGenInput: IFormGeneratorInput = null;
    casdmFormGroup: FormGroup = null;
    casdmChildFormGenInput: IFormGeneratorInput = null;
    casdmChildFormGroup: FormGroup = null;
    casdmParentFormGenInput: IFormGeneratorInput = null;
    casdmParentFormGroup: FormGroup = null;
    snsFormGenInput: IFormGeneratorInput = null;
    snsFormGroup: FormGroup = null;

    salesforceFormGenInput: IFormGeneratorInput = null;
    salesforceFormGroup: FormGroup = null;

    salesforceConfigFormGenInput: IFormGeneratorInput = null;
    salesforceConfigFormGroup: FormGroup = null;

    edit = false;
    alertData = null;
    sendingMechanism = null;
    alertFilters = null;
    widgetRef: Widget = null;
    integrationInfoFunc = null;
    defaultEmails;
    defaultCopyEmails;
    defaultBcopyEmails;

    serviceNowIncidentPriority: IDropdownData[] = [
        {
            id: '1 - Critical',
            label: 'Critical'
        },
        {
            id: '2 - High',
            label: 'High'
        },
        {
            id: '3 - Moderate',
            label: 'Moderate'
        },
        {
            id: '4 - Low',
            label: 'Low'
        },
        {
            id: '5 - Planning',
            label: 'Planning'
        }
    ];
    FilterType = FilterType;
    checkValidation: boolean;
    freshserviceFormData = [];
    fieldValueObj = {};
    customFieldValueObj = {};
    otherFieldListObj = {};
    selectedValue;
    freshserviceLoader = false;
    freshServiceConfigurationList = [];

    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: () => {
            this.multiStepFormService.previousStep(this.modalInputData.modalId);
        }
    };
    saveButton: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        showLoader: true,
        function: (buttonRef) => {
            this.saveAlert(buttonRef);
        }
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private listHttpService: ListHttpService,
        private configCache: ConfigCacheService,
        private userDataCache: UserDataCacheService,
        private widgetCreationService: WidgetCreationService
    ) {}

    ngOnInit() {
        this.widgetRef = this.modalInputData.data.widgetRef;
        const generalConfig = this.configCache.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCache.viewId
        );
        if (generalConfig) {
            this.defaultEmails = generalConfig.primaryEmail?.emails
                ? generalConfig.primaryEmail.emails
                : [];
            this.defaultCopyEmails = generalConfig.primaryEmail?.copyEmails
                ? generalConfig.primaryEmail.copyEmails
                : [];
            this.defaultBcopyEmails = generalConfig.primaryEmail?.bcopyEmails
                ? generalConfig.primaryEmail.bcopyEmails
                : [];
        }
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData']
        ) {
            this.alertData = this.modalInputData.data['stepData'];
            this.alertFilters = this.alertData['alertFilters'];
            this.sendingMechanism = this.alertFilters['sendingMechanism'];
            this.edit = true;
            if (this.sendingMechanism['sendViaEmail']) {
                this.initialSelection.push(IntegrationName.EMAIL);
            }
            if (this.sendingMechanism['o365IntegrationId']) {
                this.initialSelection.push(IntegrationName.O365);
            }
            if (this.sendingMechanism['gmailIntegrationId']) {
                this.initialSelection.push(IntegrationName.GMAIL);
            }
            if (
                (this.sendingMechanism['slackUsersIds'] &&
                    this.sendingMechanism['slackUsersIds'].length) ||
                (this.sendingMechanism['slackChannelIds'] &&
                    this.sendingMechanism['slackChannelIds'].length)
            ) {
                this.initialSelection.push(IntegrationName.SLACK);
            }
            if (this.sendingMechanism['freshDeskIntegrationId']) {
                this.initialSelection.push(IntegrationName.FRESHDESK);
            }
            if (this.sendingMechanism['freshServiceIntegrationId']) {
                this.initialSelection.push(IntegrationName.FRESHSERVICE);
            }
            if (this.sendingMechanism['zenDeskIntegrationId']) {
                this.initialSelection.push(IntegrationName.ZENDESK);
            }
            if (this.sendingMechanism['serviceNowIntegrationId']) {
                this.initialSelection.push(IntegrationName.SERVICENOW);
            }
            if (this.sendingMechanism['jiraDeskIntegrationId']) {
                this.initialSelection.push(IntegrationName.JIRADESK);
            }

            if (this.sendingMechanism['snsRequest']) {
                this.initialSelection.push(IntegrationName.SNS);
            }

            if (this.sendingMechanism['autotaskRequest']) {
                this.sendingMechanism['autotaskRequest'].forEach(
                    (autotaskData) => {
                        if (autotaskData['childIntegration']) {
                            this.initialSelection.push(
                                IntegrationName.AUTOTASK_CHILD
                            );
                        }
                        if (
                            autotaskData['parentIntegration'] ||
                            autotaskData['ownerIntegration']
                        ) {
                            this.initialSelection.push(
                                IntegrationName.AUTOTASK
                            );
                        }
                    }
                );
            }

            if (this.sendingMechanism['casdmRequest']) {
                this.sendingMechanism['casdmRequest'].forEach((casdmData) => {
                    if (casdmData['childIntegration']) {
                        this.initialSelection.push(IntegrationName.CASDM_CHILD);
                    }
                    if (
                        casdmData['parentIntegration'] ||
                        casdmData['ownerIntegration']
                    ) {
                        this.initialSelection.push(IntegrationName.CASDM);
                    }
                });
            }

            if (this.sendingMechanism['otrsRequest']) {
                this.sendingMechanism['otrsRequest'].forEach((otrsData) => {
                    if (otrsData['userIntegration']) {
                        this.initialSelection.push(IntegrationName.OTRS_USER);
                    }
                    if (
                        otrsData['parentIntegration'] ||
                        otrsData['ownerIntegration']
                    ) {
                        this.initialSelection.push(IntegrationName.OTRS);
                    }
                });
            }

            if (this.sendingMechanism['freshServiceConfiguration']) {
                this.initialSelection.push(IntegrationName.FRESHSERVICE);
                this.customFieldValueObj =
                    this.sendingMechanism['freshServiceConfiguration'][
                        'custom_fields'
                    ];
                this.fieldValueObj =
                    this.sendingMechanism['freshServiceConfiguration'];
                delete this.fieldValueObj['custom_fields'];
            }

            if (this.sendingMechanism['salesforceConfiguration']) {
                this.initialSelection.push(IntegrationName.SALESFORCE);
            }
        } else {
            this.initialSelection = [IntegrationName.EMAIL];
        }

        const filters = Helper.mapToObj(
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2)['widgetFilters']
        );

        // Severity Form Gen Input
        this.severityFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'severity',
                    label: 'Severity',
                    placeholder: 'Select severity',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    value:
                        this.edit &&
                        this.alertFilters &&
                        this.alertFilters['severity'] &&
                        this.alertFilters['severity'].length
                            ? this.alertFilters['severity']
                            : !this.edit &&
                              filters &&
                              filters['severity'] &&
                              filters['severity'].length
                            ? filters['severity']
                            : [],
                    listData: [
                        {
                            id: 'critical',
                            label: 'Critical'
                        },
                        {
                            id: 'warning',
                            label: 'Warning'
                        },
                        {
                            id: 'ok',
                            label: 'Ok'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Severity is required'
                        }
                    ],
                    prefixIcon: {
                        hoverText: 'Click here for more info',
                        iconData: {
                            type: IconType.MATICON,
                            class: 'help'
                        },
                        function: (field: IFormField, formGroup: FormGroup) => {
                            this.widgetRef.modalService.openInfoModal({
                                infoHeading: 'Severity',
                                content: [
                                    {
                                        type: 'PARAGRAPH',
                                        data: [
                                            'Severity is the classification of checks which are Critical, Warning and OK.'
                                        ]
                                    },
                                    {
                                        type: 'UNORDERED_LIST',
                                        data: [
                                            `<b>Critical:</b> The checks which are shown as red and can cause maximum impact if not rectified.`,
                                            `<b>Warning:</b> The checks which are shown as yellow and can cause average impact if not rectified.`,
                                            `<b>OK:</b> The checks are shown as green and user don't need to take any action against these checks.`
                                        ]
                                    }
                                ]
                            });
                        }
                    }
                }
            ]
        };

        // Email Form Gen Input
        this.emailFormGenInput = {
            formName: 'Email Form',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'emailTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['emailTo'] &&
                        this.sendingMechanism['emailRequest']['emailTo'].length
                            ? this.sendingMechanism['emailRequest']['emailTo']
                            : this.defaultEmails
                            ? this.defaultEmails
                            : [],
                    label: 'To',
                    placeholder: 'example@domain.com',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Atleast one recipients is required'
                        },
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'copyTo',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'CC',
                    placeholder: 'example@domain.com',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['copyTo'] &&
                        this.sendingMechanism['emailRequest']['copyTo'].length
                            ? this.sendingMechanism['emailRequest']['copyTo']
                            : this.defaultCopyEmails
                            ? this.defaultCopyEmails
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                },
                {
                    name: 'bcc',
                    fieldType: FilterType.MATCHIPLIST,
                    type: 'email',
                    label: 'BCC',
                    placeholder: 'example@domain.com',
                    value:
                        this.edit &&
                        this.sendingMechanism &&
                        this.sendingMechanism['emailRequest'] &&
                        this.sendingMechanism['emailRequest']['bcc'] &&
                        this.sendingMechanism['emailRequest']['bcc'].length
                            ? this.sendingMechanism['emailRequest']['bcc']
                            : this.defaultBcopyEmails
                            ? this.defaultBcopyEmails
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.matChipAllowEmail,
                            errorMessage: 'Enter valid email'
                        }
                    ]
                }
            ]
        };

        // Slack Form Gen Input
        this.slackFormGenInput = {
            formName: '',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Users',
                    name: 'users',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Users',
                    required: true,
                    value:
                        this.edit &&
                        this.sendingMechanism['slackUsersIds'] &&
                        this.sendingMechanism['slackUsersIds'].length
                            ? this.sendingMechanism['slackUsersIds']
                            : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Select atleast one user'
                        }
                    ],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_USERS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'usersInfo'
                },
                {
                    label: 'Channels',
                    name: 'channels',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    placeholder: 'Select Channels',
                    validations: null,
                    value:
                        this.edit &&
                        this.alertData['slackChannelIds'] &&
                        this.alertData['slackChannelIds'].length
                            ? this.alertData['slackChannelIds']
                            : [],
                    apiInfo: {
                        requestType: RequestType.GET,
                        customInput: null,
                        host: null,
                        apiRouteSuffix: ApiUrls.SLACK_LIST_CHANNELS,
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    showKey: 'label',
                    getKey: 'id',
                    responseValueKey: 'channalsInfo'
                }
            ]
        };

        // ServiceNow Form Gen Input
        this.serviceNowFormGenInput = {
            formName: 'Service Now',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'incidentPriority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Priority',
                    placeholder: 'Incident Priority',
                    required: true,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['serviceNowPriority']
                            ? this.sendingMechanism['serviceNowPriority']
                            : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident priority is required'
                        }
                    ],
                    listData: this.serviceNowIncidentPriority
                }
            ]
        };
    }

    integrationsChanged(integrations: Map<IntegrationName, IntegrationData>) {
        this.selectedIntegrations = integrations;
        if (integrations.has(IntegrationName.FRESHDESK)) {
            this.setupFreshdeskForm();
        }
        if (integrations.has(IntegrationName.FRESHSERVICE)) {
            this.setupFreshserviceForm(
                integrations.get(IntegrationName.FRESHSERVICE).integrationData
            );
        }
        if (integrations.has(IntegrationName.ZENDESK)) {
            this.setupZendeskForm();
        }

        if (integrations.has(IntegrationName.JIRADESK)) {
            this.setupJiradeskForm();
        }

        if (integrations.has(IntegrationName.SNS)) {
            this.setupSnsForm();
        }

        if (integrations.has(IntegrationName.AUTOTASK)) {
            this.autotaskFormGenInput = this.setupAutotaskForm(
                IntegrationName.AUTOTASK
            );
        }
        if (integrations.has(IntegrationName.AUTOTASK_CHILD)) {
            this.autotaskChildFormGenInput = this.setupAutotaskForm(
                IntegrationName.AUTOTASK_CHILD
            );
        }
        if (integrations.has(IntegrationName.AUTOTASK_PARENT)) {
            this.autotaskParentFormGenInput = this.setupAutotaskForm(
                IntegrationName.AUTOTASK_PARENT
            );
        }

        if (integrations.has(IntegrationName.OTRS)) {
            this.otrsFormGenInput = this.setupOtrsForm(IntegrationName.OTRS);
        }
        if (integrations.has(IntegrationName.OTRS_USER)) {
            this.otrsUserFormGenInput = this.setupOtrsForm(
                IntegrationName.OTRS_USER
            );
        }
        if (integrations.has(IntegrationName.OTRS_PARENT)) {
            this.otrsParentFormGenInput = this.setupOtrsForm(
                IntegrationName.OTRS_PARENT
            );
        }

        if (integrations.has(IntegrationName.CASDM)) {
            this.casdmFormGenInput = this.setupCasdmForm(IntegrationName.CASDM);
        }
        if (integrations.has(IntegrationName.CASDM_CHILD)) {
            this.casdmChildFormGenInput = this.setupCasdmForm(
                IntegrationName.CASDM_CHILD
            );
        }
        if (integrations.has(IntegrationName.CASDM_PARENT)) {
            this.casdmParentFormGenInput = this.setupCasdmForm(
                IntegrationName.CASDM_PARENT
            );
        }

        if (integrations.has(IntegrationName.SALESFORCE)) {
            this.setupSalesforceForm();
        }
    }

    setupSalesforceForm() {
        let salesforceConfigData = null;
        const changeAction = (value) => {
            if (!value && !value.id) {
                return;
            }

            const widgetId = value.id;

            this.salesforceConfigFormGenInput = null;

            const form = this.integrationInfoFunc(
                IntegrationName.SALESFORCE
            ).integrationData.configResponses.find(
                (each) => each.id === widgetId
            );
            if (form && form.step2) {
                this.ngZone.run(() => {
                    setTimeout(() => {
                        this.salesforceConfigFormGenInput =
                            this.widgetCreationService.salesforceBToFParser(
                                form.step2,
                                this.sendingMechanism &&
                                    this.sendingMechanism[
                                        'salesforceConfiguration'
                                    ] &&
                                    this.sendingMechanism[
                                        'salesforceConfiguration'
                                    ][0] &&
                                    this.sendingMechanism[
                                        'salesforceConfiguration'
                                    ][0].request,
                                'HEALTH_ALERT'
                            );
                    }, 0);
                });
            }
        };
        if (this.edit) {
            salesforceConfigData =
                this.sendingMechanism.salesforceConfiguration &&
                this.sendingMechanism.salesforceConfiguration[0]
                    ? this.sendingMechanism.salesforceConfiguration[0]
                    : null;
            changeAction(salesforceConfigData);
        }

        this.salesforceFormGenInput = {
            formName: 'Salesforce form',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    name: 'widgetId',
                    placeholder: 'Select Configuration',
                    label: 'Select Configuration',
                    value:
                        salesforceConfigData && salesforceConfigData.id
                            ? salesforceConfigData.id
                            : '',
                    listData:
                        this.integrationInfoFunc &&
                        this.integrationInfoFunc(IntegrationName.SALESFORCE) &&
                        this.integrationInfoFunc(IntegrationName.SALESFORCE)
                            .integrationData.configResponses &&
                        this.integrationInfoFunc(IntegrationName.SALESFORCE)
                            .integrationData.configResponses.length
                            ? this.integrationInfoFunc(
                                  IntegrationName.SALESFORCE
                              ).integrationData.configResponses.map((each) => {
                                  return {
                                      id: each.id,
                                      label: each.configurationName
                                  };
                              })
                            : [],
                    onChange: (value) => {
                        changeAction(value);
                    }
                }
            ]
        };
    }

    setupFreshdeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.FRESHDESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.FRESHDESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.freshdeskFormGenInput = {
            formName: 'Freshdesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'freshDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Freshdesk support email',
                    placeholder: 'Freshdesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['freshDeskIntegrationId'] &&
                        this.sendingMechanism['freshDeskIntegrationId'].length
                            ? this.sendingMechanism['freshDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupFreshserviceForm(integrationData: IntegrationData) {
        if (!this.freshServiceConfigurationList.length) {
            integrationData['childConfigurationListing'].forEach((element) => {
                this.freshServiceConfigurationList.push({
                    id: element.listingWidgetId,
                    label: element.configurationName
                });
            });
            integrationData['parentConfigurationListing'].forEach((element) => {
                this.freshServiceConfigurationList.push({
                    id: element.listingWidgetId,
                    label: element.configurationName
                });
            });
        }
        this.freshserviceFormGenInput = {
            formName: 'Freshservice',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'widgetId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Configuration',
                    placeholder: 'Select Configuration',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Configuration is required'
                        }
                    ],
                    listData: this.freshServiceConfigurationList,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['freshServiceConfiguration']
                            ? this.sendingMechanism[
                                  'freshServiceConfiguration'
                              ]['widgetId']
                            : ''
                }
            ]
        };
    }

    freshserviceConfigValueChanged(value) {
        if (!value['widgetId']) {
            return;
        }
        this.freshserviceLoader = true;
        this.freshserviceFormData = [];
        let url = ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS.replace(
            '{widgetId}',
            value['widgetId']
        );
        url = url.replace('{budgeting}', 'false');
        url = url.replace('{alerting}', 'true');
        const apiConf: IHitApi = {
            url: url,
            input: {},
            intactUrl: ApiUrls.FRESHSERVICE_CONFIGURED_FIELDS,
            function: (response) => {
                this.freshserviceLoader = false;
                this.freshserviceFormData = response;
            },
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            errorFunction: (error) => {
                this.freshserviceLoader = false;
            }
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    freshserviceFieldValueChanged(response) {
        if (response.value && response.custom) {
            this.customFieldValueObj = {
                ...this.customFieldValueObj,
                ...response.value
            };
        }

        if (response.value && !response.custom) {
            this.fieldValueObj = {
                ...this.fieldValueObj,
                ...response.value
            };
        }

        if (response.otherFieldList) {
            this.otherFieldListObj = {
                ...this.otherFieldListObj,
                ...response.otherFieldList
            };
        }
    }

    setupZendeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.ZENDESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.ZENDESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.zendeskFormGenInput = {
            formName: 'Zendesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'zenDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Zendesk support email',
                    placeholder: 'Zendesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['zenDeskIntegrationId'] &&
                        this.sendingMechanism['zenDeskIntegrationId'].length
                            ? this.sendingMechanism['zenDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupJiradeskForm() {
        const self = this.selectedIntegrations.get(IntegrationName.JIRADESK)
            .integrationData['self'];
        const parent = this.selectedIntegrations.get(IntegrationName.JIRADESK)
            .integrationData['parent'];
        const listData: IDropdownData[] = [];
        if (self) {
            listData.push({
                id: self.id,
                label: self.supportedEmails[0]
            });
        }
        if (parent) {
            listData.push({
                id: parent.id,
                label: parent.supportedEmails[0]
            });
        }
        this.jiradeskFormGenInput = {
            formName: 'JIradesk',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'jiraDeskIntegrationId',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Jiradesk support email',
                    placeholder: 'Jiradesk support email',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Support email is required'
                        }
                    ],
                    listData,
                    value:
                        this.sendingMechanism &&
                        this.sendingMechanism['jiraDeskIntegrationId'] &&
                        this.sendingMechanism['jiraDeskIntegrationId'].length
                            ? this.sendingMechanism['jiraDeskIntegrationId']
                            : []
                }
            ]
        };
    }

    setupAutotaskForm(integrationName: IntegrationName) {
        const childIntegration =
            this.selectedIntegrations.get(integrationName).integrationData[
                'ownerConfiguredFeilds'
            ];
        const parentIntegration =
            this.selectedIntegrations.get(integrationName).integrationData[
                'selfConfiguredFeilds'
            ];
        let priorityListData: IDropdownData[] = [];
        let queueListData: IDropdownData[] = [];
        const sourceListData: IDropdownData[] = [];
        let statusListData: IDropdownData[] = [];
        let ids = {};
        if (
            (integrationName === IntegrationName.AUTOTASK ||
                integrationName === IntegrationName.AUTOTASK_PARENT) &&
            parentIntegration
        ) {
            priorityListData = parentIntegration.priority;
            queueListData = parentIntegration.queueId;
            sourceListData.push(parentIntegration.source);
            statusListData = parentIntegration.status;
            if (
                this.sendingMechanism &&
                this.sendingMechanism['autotaskRequest']
            ) {
                ids = this.sendingMechanism['autotaskRequest'].find(
                    (dataObj) => dataObj['parentIntegration'] === true
                );
            }
        }
        if (
            integrationName === IntegrationName.AUTOTASK_CHILD &&
            childIntegration
        ) {
            priorityListData = childIntegration.priority;
            queueListData = childIntegration.queueId;
            sourceListData.push(childIntegration.source);
            statusListData = childIntegration.status;
            if (
                this.sendingMechanism &&
                this.sendingMechanism['autotaskRequest']
            ) {
                if (
                    this.sendingMechanism['autotaskRequest'].find(
                        (dataObj) => dataObj['childIntegration'] === true
                    )
                ) {
                    ids = this.sendingMechanism['autotaskRequest'].find(
                        (dataObj) => dataObj['childIntegration'] === true
                    );
                }
            }
        }

        return {
            formId: Symbol(),
            formName: 'Autotask',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Priority',
                    name: 'priorityValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids
                        ? ids['priorityValue']
                            ? ids['priorityValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Queue',
                    name: 'queueIdValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Queue',
                    required: true,
                    value: ids
                        ? ids['queueIdValue']
                            ? ids['queueIdValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ],
                    listData: queueListData
                },
                {
                    label: 'Source',
                    name: 'sourceValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Source',
                    required: true,
                    value: ids
                        ? ids['sourceValue']
                            ? ids['sourceValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Source is required'
                        }
                    ],
                    listData: sourceListData
                },
                {
                    label: 'Status',
                    name: 'statusValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Status',
                    required: true,
                    value: ids
                        ? ids['statusValue']
                            ? ids['statusValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Status is required'
                        }
                    ],
                    listData: statusListData
                },
                {
                    label: 'Client Id',
                    name: 'clientId',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Client Id',
                    required: true,
                    value: ids
                        ? ids['clientId']
                            ? {
                                  id: ids['clientId'],
                                  label: ids['clientId']
                              }
                            : integrationName ===
                                  IntegrationName.AUTOTASK_CHILD &&
                              this.integrationInfoFunc(integrationName)
                                  .integrated
                            ? {
                                  id: this.selectedIntegrations.get(
                                      integrationName
                                  ).integrationData['childInfo']['clientId'],
                                  label: this.selectedIntegrations.get(
                                      integrationName
                                  ).integrationData['childInfo']['clientId']
                              }
                            : null
                        : null,
                    apiInfo:
                        integrationName === IntegrationName.AUTOTASK_CHILD
                            ? null
                            : this.listHttpService.autoTaskClientIds,
                    responseValueKey: 'clientList',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Client id is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.AUTOTASK_CHILD
                            ? true
                            : false
                }
            ]
        };
    }

    setupOtrsForm(integrationName: IntegrationName) {
        const userIntegration =
            this.selectedIntegrations.get(integrationName).integrationData[
                'ownerConfiguredFeilds'
            ];
        const parentIntegration =
            this.selectedIntegrations.get(integrationName).integrationData[
                'configuredFeilds'
            ];
        const priorityListData: IDropdownData[] =
            this.selectedIntegrations.get(integrationName).integrationData
                .priorities;
        let queueListData: IDropdownData[] = [];
        let customFieldData: [] = [];
        let ids = {};
        if (
            (integrationName === IntegrationName.OTRS ||
                integrationName === IntegrationName.OTRS_PARENT) &&
            parentIntegration
        ) {
            queueListData = Helper.mapToDropdownData(parentIntegration.queueId);
            customFieldData = parentIntegration['customFeilds'];
            if (this.sendingMechanism && this.sendingMechanism['otrsRequest']) {
                if (
                    this.sendingMechanism['otrsRequest'].find(
                        (dataObj) => dataObj['parentIntegration'] === true
                    )
                ) {
                    ids = this.sendingMechanism['otrsRequest'].find(
                        (dataObj) => dataObj['parentIntegration'] === true
                    );
                }
            }
        }
        if (integrationName === IntegrationName.OTRS_USER && userIntegration) {
            queueListData = Helper.mapToDropdownData(userIntegration.queueId);
            customFieldData = userIntegration['customFeilds'];
            if (this.sendingMechanism && this.sendingMechanism['otrsRequest']) {
                if (
                    this.sendingMechanism['otrsRequest'].find(
                        (dataObj) => dataObj['userIntegration'] === true
                    )
                ) {
                    ids = this.sendingMechanism['otrsRequest'].find(
                        (dataObj) => dataObj['userIntegration'] === true
                    );
                }
            }
        }

        const formGenInput: IFormGeneratorInput = {
            formId: Symbol(),
            formName: 'OTRS',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Priority',
                    name: 'priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids ? (ids['priority'] ? ids['priority'] : []) : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Queue',
                    name: 'queueValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Queue',
                    required: true,
                    value: ids
                        ? ids['queueValue']
                            ? ids['queueValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Queue is required'
                        }
                    ],
                    listData: queueListData
                },
                {
                    label: 'Customer Username',
                    name: 'customerUserName',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Select Client Id',
                    required: true,
                    value: ids
                        ? ids['customerUserName']
                            ? ids['customerUserName']
                            : integrationName === IntegrationName.OTRS_USER
                            ? this.selectedIntegrations.get(integrationName)
                                  .integrationData['userCred'][
                                  'customerUserName'
                              ]
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer username is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.OTRS_USER
                            ? true
                            : false
                }
            ]
        };

        Object.keys(customFieldData).forEach((field) => {
            formGenInput.fields.push(
                this.dynamicFormField(field, customFieldData, ids)
            );
        });

        return formGenInput;
    }

    dynamicFormField(fieldName, data, ids) {
        const formField: IFormField = {
            name: fieldName,
            placeholder: 'Select ' + fieldName,
            label: fieldName,
            value: ids
                ? ids['customFeildValue']
                    ? ids['customFeildValue'][fieldName]
                    : null
                : null,
            listData: Helper.mapToDropdownData(data[fieldName]),
            fieldType: FilterType.DROPDOWN_SINGLE,
            required: true,
            validations: [
                {
                    validator: CustomValidators.required,
                    errorMessage: fieldName + ' is required'
                }
            ]
        };

        return formField;
    }

    setupCasdmForm(integrationName: IntegrationName) {
        const userIntegration =
            this.selectedIntegrations.get(integrationName).integrationData[
                'ownerConfigueredFields'
            ];
        const parentIntegration =
            this.selectedIntegrations.get(integrationName).integrationData;
        let groupListData: IDropdownData[] = [];
        let categoryListData: IDropdownData[] = [];
        const priorityListData: IDropdownData[] = [
            { id: '1', label: '1' },
            { id: '2', label: '2' },
            { id: '3', label: '3' },
            { id: '4', label: '4' }
        ];
        let incidentTypeValueListData: IDropdownData[] = [];
        let ids = {};
        if (
            integrationName === IntegrationName.CASDM ||
            integrationName === IntegrationName.CASDM_PARENT
        ) {
            incidentTypeValueListData = this.handleType(
                parentIntegration['incidentType']
            );
            groupListData = parentIntegration['group'];
            categoryListData = parentIntegration['category'];
            if (
                this.sendingMechanism &&
                this.sendingMechanism['casdmRequest']
            ) {
                if (
                    this.sendingMechanism['casdmRequest'].find(
                        (dataObj) => dataObj['parentIntegration'] === true
                    )
                ) {
                    ids = this.sendingMechanism['casdmRequest'].find(
                        (dataObj) => dataObj['parentIntegration'] === true
                    );
                }
            }
        }
        if (integrationName === IntegrationName.CASDM_CHILD) {
            incidentTypeValueListData = this.handleType(
                userIntegration['incidentType']
            );
            groupListData = userIntegration['group'];
            categoryListData = userIntegration['category'];
            if (
                this.sendingMechanism &&
                this.sendingMechanism['casdmRequest']
            ) {
                if (
                    this.sendingMechanism['casdmRequest'].find(
                        (dataObj) => dataObj['childIntegration'] === true
                    )
                ) {
                    ids = this.sendingMechanism['casdmRequest'].find(
                        (dataObj) => dataObj['childIntegration'] === true
                    );
                }
            }
        }

        return {
            formId: Symbol(),
            formName: 'CA-SDM',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Customer Handler',
                    name: 'customerHandler',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Customer Handler',
                    required: true,
                    value: ids
                        ? ids['customerHandler']
                            ? ids['customerHandler']
                            : integrationName === IntegrationName.CASDM_CHILD
                            ? this.selectedIntegrations.get(integrationName)
                                  .integrationData['childCredentials'][
                                  'customerHandler'
                              ]
                            : integrationName === IntegrationName.CASDM_PARENT
                            ? this.selectedIntegrations.get(integrationName)
                                  .integrationData['credentials'][
                                  'customerHandler'
                              ]
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Customer Handler is required'
                        }
                    ]
                },

                {
                    label: 'Priority',
                    name: 'priority',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Priority',
                    required: true,
                    value: ids ? (ids['priority'] ? ids['priority'] : []) : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Priority is required'
                        }
                    ],
                    listData: priorityListData
                },
                {
                    label: 'Group',
                    name: 'groupValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Group',
                    required: true,
                    value: ids
                        ? ids['groupValue']
                            ? ids['groupValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Group is required'
                        }
                    ],
                    listData: groupListData
                },
                {
                    label: 'Category',
                    name: 'categoryValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Category',
                    required: true,
                    value: ids
                        ? ids['categoryValue']
                            ? ids['categoryValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Category is required'
                        }
                    ],
                    listData: categoryListData
                },
                {
                    label: 'Type',
                    name: 'incidentTypeValue',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    placeholder: 'Select Type',
                    required: true,
                    value: ids
                        ? ids['incidentTypeValue']
                            ? ids['incidentTypeValue']
                            : []
                        : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident Type is required'
                        }
                    ],
                    listData: incidentTypeValueListData
                },
                {
                    label: 'Email',
                    name: 'email',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Enter Email',
                    required: true,
                    value: ids
                        ? ids['email']
                            ? ids['email']
                            : integrationName === IntegrationName.CASDM_CHILD
                            ? this.selectedIntegrations.get(integrationName)
                                  .integrationData['email']
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        }
                    ],
                    disabled:
                        integrationName === IntegrationName.CASDM_CHILD
                            ? true
                            : false
                }
            ]
        };
    }

    handleType(data) {
        const listData = [];
        data.forEach((ele) => {
            if (ele === 'incident') {
                listData.push({ id: 'I', label: 'Incident' });
            } else if (ele === 'task') {
                listData.push({ id: 'T', label: 'Task' });
            }
        });
        return listData;
    }

    setupSnsForm() {
        this.snsFormGenInput = {
            formId: Symbol(),
            formName: 'SNS',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Topics',
                    placeholder: 'Topics',
                    name: 'notify',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.snsListingApiInfo,
                    responseValueKey: 'newAccounts',
                    required: true,
                    value: this.sendingMechanism
                        ? this.sendingMechanism['snsRequest']
                            ? this.sendingMechanism['snsRequest']['notify']
                            : null
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Topic is required'
                        }
                    ]
                }
            ]
        };
    }
    saveAlert(buttonRef) {
        if (buttonRef.loader) {
            return;
        }

        if (!this.selectedIntegrations.size) {
            this.notificationsService.showSnackBar(
                'No sending mechanism selected',
                true
            );
            return;
        }

        if (this.severityFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.severityFormGroup);
            this.notificationsService.showSnackBar(
                Messages.FILL_ALL_FIELDS,
                true
            );
            return;
        }

        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            Helper.markAllFieldAsTouched(this.emailFormGroup);
            if (this.emailFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHDESK)) {
            Helper.markAllFieldAsTouched(this.freshdeskFormGroup);
            if (this.freshdeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHSERVICE)) {
            Helper.markAllFieldAsTouched(this.freshserviceFormGroup);
            if (this.freshserviceFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.ZENDESK)) {
            Helper.markAllFieldAsTouched(this.zendeskFormGroup);
            if (this.zendeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.JIRADESK)) {
            Helper.markAllFieldAsTouched(this.jiradeskFormGroup);
            if (this.jiradeskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.SNS)) {
            Helper.markAllFieldAsTouched(this.snsFormGroup);
            if (this.snsFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.SERVICENOW)) {
            Helper.markAllFieldAsTouched(this.serviceNowFormGroup);
            if (this.serviceNowFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK)) {
            Helper.markAllFieldAsTouched(this.autotaskFormGroup);
            if (this.autotaskFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK_CHILD)) {
            Helper.markAllFieldAsTouched(this.autotaskChildFormGroup);
            if (this.autotaskChildFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK_PARENT)) {
            Helper.markAllFieldAsTouched(this.autotaskParentFormGroup);
            if (this.autotaskParentFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        if (this.selectedIntegrations.has(IntegrationName.SALESFORCE)) {
            Helper.markAllFieldAsTouched(this.salesforceFormGroup);
            Helper.markAllFieldAsTouched(this.salesforceConfigFormGroup);
            if (this.salesforceFormGroup.invalid) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
            if (
                this.salesforceConfigFormGroup &&
                this.salesforceConfigFormGroup.invalid
            ) {
                this.notificationsService.showSnackBar(
                    Messages.FILL_ALL_FIELDS,
                    true
                );
                return;
            }
        }

        let users = [];
        let channels = [];

        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            users = this.slackFormGroup.value['users'];
            channels = this.slackFormGroup.value['channels'];

            if (
                !users ||
                (users && !users.length) ||
                !channels ||
                (channels && !channels.length)
            ) {
                this.notificationsService.showSnackBar(
                    `Please select atleast one channel and/or user`,
                    true
                );
                return;
            }
        }

        const freshserviceInput = {};
        if (this.selectedIntegrations.has(IntegrationName.FRESHSERVICE)) {
            this.checkValidation = true;
            const customFields = {};
            if (
                !Object.keys(this.fieldValueObj).length &&
                !Object.keys(this.customFieldValueObj).length
            ) {
                return;
            }
            const valid = this.freshserviceFormData.every((field) => {
                if (field.required) {
                    if (
                        (!field.customField &&
                            !(field.name in this.fieldValueObj)) ||
                        this.fieldValueObj[field.name] === null ||
                        Array.isArray(
                            this.fieldValueObj[field.name] &&
                                !this.fieldValueObj[field.name].length
                        )
                    ) {
                        return false;
                    } else if (
                        (field.customField &&
                            !(field.name in this.customFieldValueObj)) ||
                        this.customFieldValueObj[field.name] === null ||
                        Array.isArray(
                            this.customFieldValueObj[field.name] &&
                                !this.customFieldValueObj[field.name].length
                        )
                    ) {
                        return false;
                    } else {
                        if (
                            field.customField &&
                            this.customFieldValueObj[field.name] !== null
                        ) {
                            customFields[field.name] =
                                this.customFieldValueObj[field.name];
                        } else {
                            if (this.fieldValueObj[field.name] !== null) {
                                freshserviceInput[field.name] =
                                    this.fieldValueObj[field.name];
                            }
                        }

                        if (field['nestedFields']) {
                            field['nestedFields'].forEach((nestedField) => {
                                if (this.fieldValueObj[nestedField.name]) {
                                    if (
                                        field.customField &&
                                        this.customFieldValueObj[field.name] !==
                                            null
                                    ) {
                                        customFields[nestedField.name] =
                                            this.customFieldValueObj[
                                                nestedField.name
                                            ];
                                    } else {
                                        if (
                                            this.fieldValueObj[field.name] !==
                                            null
                                        ) {
                                            freshserviceInput[
                                                nestedField.name
                                            ] =
                                                this.fieldValueObj[
                                                    nestedField.name
                                                ];
                                        }
                                    }
                                }
                            });
                        }

                        return true;
                    }
                } else {
                    if (
                        field.customField &&
                        this.customFieldValueObj[field.name] !== null
                    ) {
                        customFields[field.name] =
                            this.customFieldValueObj[field.name];
                    } else {
                        if (this.fieldValueObj[field.name] !== null) {
                            freshserviceInput[field.name] =
                                this.fieldValueObj[field.name];
                        }
                    }
                    return true;
                }
            });

            if (!valid) {
                return;
            }

            freshserviceInput['custom_fields'] = customFields;
        }

        buttonRef.loader = true;
        const sendingMechanism = {
            sendViaEmail: this.selectedIntegrations.has(IntegrationName.EMAIL)
        };
        if (
            this.selectedIntegrations.has(IntegrationName.EMAIL) ||
            this.selectedIntegrations.has(IntegrationName.O365) ||
            this.selectedIntegrations.has(IntegrationName.GMAIL)
        ) {
            sendingMechanism['emailRequest'] = this.emailFormGroup.value;
        }
        if (this.selectedIntegrations.has(IntegrationName.O365)) {
            sendingMechanism['o365IntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.O365
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.GMAIL)) {
            sendingMechanism['gmailIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.GMAIL
                ).integrationData['id'];
        }
        if (this.selectedIntegrations.has(IntegrationName.SLACK)) {
            sendingMechanism['slackUsersIds'] = users;
            sendingMechanism['slackChannelIds'] = channels;
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHDESK)) {
            sendingMechanism['freshDeskIntegrationId'] =
                this.freshdeskFormGroup.get('freshDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.FRESHSERVICE)) {
            sendingMechanism['freshServiceConfiguration'] = {
                ...freshserviceInput,
                widgetId: this.freshserviceFormGroup.get('widgetId').value
            };
        }

        if (this.selectedIntegrations.has(IntegrationName.ZENDESK)) {
            sendingMechanism['zenDeskIntegrationId'] =
                this.zendeskFormGroup.get('zenDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.JIRADESK)) {
            sendingMechanism['jiraDeskIntegrationId'] =
                this.jiradeskFormGroup.get('jiraDeskIntegrationId').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.SNS)) {
            sendingMechanism['snsRequest'] = this.snsFormGroup.value;
        }

        if (this.selectedIntegrations.has(IntegrationName.SERVICENOW)) {
            sendingMechanism['serviceNowIntegrationId'] =
                this.selectedIntegrations.get(
                    IntegrationName.SERVICENOW
                ).integrationData['id'];
            sendingMechanism['serviceNowPriority'] =
                this.serviceNowFormGroup.get('incidentPriority').value;
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK)) {
            if (!sendingMechanism['autotaskRequest']) {
                sendingMechanism['autotaskRequest'] = [];
            }
            sendingMechanism['autotaskRequest'].push({
                queueIdValue: this.autotaskFormGroup.value['queueIdValue'],
                sourceValue: this.autotaskFormGroup.value['sourceValue'],
                priorityValue: this.autotaskFormGroup.value['priorityValue'],
                statusValue: this.autotaskFormGroup.value['statusValue'],
                parentIntegration: true,
                clientId: this.autotaskFormGroup.value['clientId']
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK_CHILD)) {
            if (!sendingMechanism['autotaskRequest']) {
                sendingMechanism['autotaskRequest'] = [];
            }
            sendingMechanism['autotaskRequest'].push({
                queueIdValue: this.autotaskChildFormGroup.value['queueIdValue'],
                sourceValue: this.autotaskChildFormGroup.value['sourceValue'],
                priorityValue:
                    this.autotaskChildFormGroup.value['priorityValue'],
                statusValue: this.autotaskChildFormGroup.value['statusValue'],
                childIntegration: true,
                clientId:
                    this.autotaskChildFormGroup.getRawValue()['clientId']['id']
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.AUTOTASK_PARENT)) {
            if (!sendingMechanism['autotaskRequest']) {
                sendingMechanism['autotaskRequest'] = [];
            }
            sendingMechanism['autotaskRequest'].push({
                queueIdValue:
                    this.autotaskParentFormGroup.value['queueIdValue'],
                sourceValue: this.autotaskParentFormGroup.value['sourceValue'],
                priorityValue:
                    this.autotaskParentFormGroup.value['priorityValue'],
                statusValue: this.autotaskParentFormGroup.value['statusValue'],
                parentIntegration: true,
                clientId: this.autotaskParentFormGroup.value['clientId']
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.OTRS)) {
            if (!sendingMechanism['otrsRequest']) {
                sendingMechanism['otrsRequest'] = [];
            }

            const input = {
                customerUserName: this.otrsFormGroup.value['customerUserName'],
                queueValue: this.otrsFormGroup.value['queueValue'],
                priority: this.otrsFormGroup.value['priority'],
                customFeildValue: {},
                parentIntegration: true
            };
            Object.keys(
                this.selectedIntegrations.get(IntegrationName.OTRS)
                    .integrationData['configuredFeilds']['customFeilds']
            ).forEach((fieldName) => {
                input.customFeildValue[fieldName] =
                    this.otrsFormGroup.value[fieldName];
            });

            sendingMechanism['otrsRequest'].push(input);
        }

        if (this.selectedIntegrations.has(IntegrationName.OTRS_USER)) {
            if (!sendingMechanism['otrsRequest']) {
                sendingMechanism['otrsRequest'] = [];
            }

            const input = {
                customerUserName:
                    this.otrsUserFormGroup.getRawValue()['customerUserName'],
                queueValue: this.otrsUserFormGroup.value['queueValue'],
                priority: this.otrsUserFormGroup.value['priority'],
                customFeildValue: {},
                userIntegration: true
            };
            Object.keys(
                this.selectedIntegrations.get(IntegrationName.OTRS_USER)
                    .integrationData['ownerConfiguredFeilds']['customFeilds']
            ).forEach((fieldName) => {
                input.customFeildValue[fieldName] =
                    this.otrsUserFormGroup.value[fieldName];
            });

            sendingMechanism['otrsRequest'].push(input);
        }
        if (this.selectedIntegrations.has(IntegrationName.OTRS_PARENT)) {
            if (!sendingMechanism['otrsRequest']) {
                sendingMechanism['otrsRequest'] = [];
            }

            const input = {
                customerUserName:
                    this.otrsParentFormGroup.value['customerUserName'],
                queueValue: this.otrsParentFormGroup.value['queueValue'],
                priority: this.otrsParentFormGroup.value['priority'],
                customFeildValue: {},
                parentIntegration: true
            };
            Object.keys(
                this.selectedIntegrations.get(IntegrationName.OTRS_PARENT)
                    .integrationData['configuredFeilds']['customFeilds']
            ).forEach((fieldName) => {
                input.customFeildValue[fieldName] =
                    this.otrsParentFormGroup.value[fieldName];
            });

            sendingMechanism['otrsRequest'].push(input);
        }

        if (this.selectedIntegrations.has(IntegrationName.CASDM)) {
            if (!sendingMechanism['casdmRequest']) {
                sendingMechanism['casdmRequest'] = [];
            }

            sendingMechanism['casdmRequest'].push({
                groupValue: this.casdmFormGroup.value['groupValue'],
                categoryValue: this.casdmFormGroup.value['categoryValue'],
                priority: this.casdmFormGroup.value['priority'],
                incidentTypeValue:
                    this.casdmFormGroup.value['incidentTypeValue'],
                customerHandler: this.casdmFormGroup.value['customerHandler'],
                email: this.casdmFormGroup.value['email'],
                parentIntegration: true
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.CASDM_CHILD)) {
            if (!sendingMechanism['casdmRequest']) {
                sendingMechanism['casdmRequest'] = [];
            }

            sendingMechanism['casdmRequest'].push({
                groupValue: this.casdmChildFormGroup.value['groupValue'],
                categoryValue: this.casdmChildFormGroup.value['categoryValue'],
                priority: this.casdmChildFormGroup.value['priority'],
                incidentTypeValue:
                    this.casdmChildFormGroup.value['incidentTypeValue'],
                customerHandler:
                    this.casdmChildFormGroup.value['customerHandler'],
                email: this.casdmChildFormGroup.getRawValue()['email'],
                childIntegration: true
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.CASDM_PARENT)) {
            if (!sendingMechanism['casdmRequest']) {
                sendingMechanism['casdmRequest'] = [];
            }

            sendingMechanism['casdmRequest'].push({
                groupValue: this.casdmParentFormGroup.value['groupValue'],
                categoryValue: this.casdmParentFormGroup.value['categoryValue'],
                priority: this.casdmParentFormGroup.value['priority'],
                incidentTypeValue:
                    this.casdmParentFormGroup.value['incidentTypeValue'],
                customerHandler:
                    this.casdmParentFormGroup.value['customerHandler'],
                email: this.casdmParentFormGroup.value['email'],
                parentIntegration: true
            });
        }

        if (this.selectedIntegrations.has(IntegrationName.SALESFORCE)) {
            const response = this.integrationInfoFunc(
                IntegrationName.SALESFORCE
            ).integrationData.configResponses;
            const form = response.find(
                (each) => each.id === this.salesforceFormGroup.value.widgetId
            ).step2;

            const finalResponse = [];

            form.fields.map((each) => {
                const eachField = {
                    id: each.varId,
                    inputString:
                        this.widgetCreationService.resolveSalesforceInputString(
                            this.salesforceConfigFormGroup.value[each.id],
                            'input'
                        ),

                    selection:
                        this.widgetCreationService.resolveSalesforceInputString(
                            this.salesforceConfigFormGroup.value[each.id],
                            'selection'
                        ),
                    defaultValue: each.defaultValue ? each.defaultValue : '',
                    fieldType: each.fieldType,
                    varId: each.varId
                };
                finalResponse.push(eachField);
            });

            sendingMechanism['salesforceConfiguration'] = [
                {
                    id: this.salesforceFormGroup.value.widgetId,
                    request: finalResponse
                }
            ];
        }

        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const step2Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2);
        const saveAlertInput = {
            alertName: step1Data['alertName'],
            widgetIds: step1Data['widgetsSelected'],
            widgetFilters: Helper.mapToObj(step2Data['widgetFilters']),
            alertFilters: {
                sendingMechanism,
                severity: this.severityFormGroup.get('severity').value
            }
        };

        if (this.edit) {
            const updateArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.update
            );
            updateArgs.intactUrl = updateArgs.url;
            updateArgs.url = updateArgs.url.replace(
                '{alert-id}',
                this.alertData['id']
            );
            updateArgs.input = saveAlertInput;
            updateArgs.function = (response) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    'Alert updated successfully'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            updateArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    error && error.error && error.error.message
                        ? error.error.message
                        : 'Error updating alert',
                    true
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            new HitApi(updateArgs, this.httpService, this.ngZone).hitApi();
        } else {
            const createArgs = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo.create
            );
            createArgs.input = saveAlertInput;
            createArgs.function = (response) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    'Alert created successfully'
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            createArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                this.notificationsService.showSnackBar(
                    error && error.error && error.error.message
                        ? error.error.message
                        : 'Error creating alert',
                    true
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalInputData.modalId
                );
            };
            new HitApi(createArgs, this.httpService, this.ngZone).hitApi();
        }
    }
}

interface IntegrationData {
    iconData: IIcon;
    name: string;
    integrationData;
    integrated: boolean;
}
