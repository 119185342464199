<div class="form-container" *ngIf="!modalLoader">
    <app-form-generator
        *ngIf="assignAssessmentFormGenInputs"
        [formGeneratorInput]="assignAssessmentFormGenInputs"
        (formGroupRef)="assignAssessmentFormGroup = $event"
        (valuesChange)="userSelected($event)"
    ></app-form-generator>
    <app-form-generator
        *ngIf="emailContentFormGenInput"
        [formGeneratorInput]="emailContentFormGenInput"
        (formGroupRef)="emailContentFormGroup = $event"
        (updateControl)="updateControl = $event"
    ></app-form-generator>
</div>

<app-button-generator
    *ngIf="!modalLoader"
    [buttonGenInput]="assignAssessmentButtonGenInput"
></app-button-generator>
<div class="overlay" *ngIf="modalLoader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
