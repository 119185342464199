import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FileType } from 'src/app/shared/enums/FileType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { PseudoConsolePreviewSourceTypes } from 'src/app/shared/enums/PreviewModeSourceType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

const FileTypesToAccept = [
    FileType.PDF,
    FileType.HTML,
    FileType.MS_WORD_DOC,
    FileType.MS_WORD_DOCS
];

@Component({
    selector: 'app-whitelebaling-privacy-policy',
    templateUrl: './whitelebaling-privacy-policy.component.html',
    styleUrls: ['./whitelebaling-privacy-policy.component.sass']
})
export class WhitelebalingPrivacyPolicyComponent implements OnInit {
    excelFormInput: IFormGeneratorInput = null;
    excelForm: FormGroup;
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    reInitializeWhiteLabelData: Function;
    save: IButtonGeneratorInput = {
        buttonName: 'Save As Draft',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Cannot perform this action in preview mode'
            : null,
        function: (buttonRef) => {
            this.saveConfiguration(buttonRef);
        }
    };

    preview: IButtonGeneratorInput = {
        buttonName: 'Preview',
        buttonType: ButtonType.FLAT,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Already in preview mode'
            : null,
        buttonColorType: ButtonColorType.SECONDARY,
        function: () => {
            if (this.subWidgetData.data.previewWhitelabelModalData) {
                const data = Helper.cloneDeep(
                    this.subWidgetData.data.whiteLabelingData
                );
                this.subWidgetData.data.previewWhitelabelModalData(data, {
                    previewSource:
                        PseudoConsolePreviewSourceTypes.PREVIEW_SOURCE_PRIVACY_POLICY,
                    allowAccessToStorageKeys: true
                });
            }
        }
    };
    whiteLabelingData;
    widgetRef;
    tempMail: any;
    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public modalService: ModalService,
        public globalDataService: GlobalDataService,
        public notificationsService: NotificationsService,
        private widgetHttpService: WidgetHttpService
    ) {
        this.resetObservable = subWidgetData.resetObserver;
        this.subWidgetId = subWidgetData.subWidgetId;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
        this.whiteLabelingData = subWidgetData.data.whiteLabelingData;
        this.widgetRef = subWidgetData.parentWidgetRef;
    }

    ngOnInit(): void {
        this.initFormGenInput(false);
    }
    initFormGenInput(useDraft?: boolean) {
        this.excelFormInput = null;
        this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
        let privacyPolicy;
        let fileName: string;
        if (
            !useDraft &&
            this.whiteLabelingData &&
            this.whiteLabelingData.privacyPolicyConfig
        ) {
            privacyPolicy = Helper.cloneDeep(
                this.whiteLabelingData.privacyPolicyConfig
            );
            this.subWidgetData.data.whiteLabelingDraft.privacyPolicyConfig =
                Helper.cloneDeep(privacyPolicy);
        } else {
            privacyPolicy =
                this.subWidgetData.data.whiteLabelingDraft.privacyPolicyConfig;
        }
        if (privacyPolicy?.fileName) {
            fileName = privacyPolicy?.fileName;
        }

        this.excelFormInput = {
            formName: 'Excel Upload',
            submitButton: null,
            fields: [
                {
                    label: 'Upload Policy',
                    fieldType: FilterType.FILE,
                    required: false,
                    accept: FileTypesToAccept.join(','),
                    showLabel: true,
                    suffixIcon: {
                        iconData: {
                            type: IconType.SVG,
                            class: 'upload_icon',
                            extraClass: 'upload-icon'
                        },
                        hoverText: ''
                    },
                    fieldStyle: {
                        width: '80%'
                    },
                    appearance: 'legacy',
                    placeholder: fileName || 'Upload File',
                    name: 'excelFile'
                }
            ],
            state: FormState.CREATE
        };
    }
    onFormValueChange(event) {
        if (event['excelFile']) {
            const fileType = this.excelForm.value?.excelFile?.files?.[0]?.type;
            if (fileType && !this.validateFileType(fileType)) {
                Helper.showErrorMessage(
                    this.notificationsService,
                    true,
                    'The supported file formats are Word, PDF and HTML'
                );
                this.excelForm?.get('excelFile')?.reset();
                return;
            }
            this.tempMail = this.excelForm.value.excelFile;
        }
    }

    private validateFileType(fileType: string) {
        return FileTypesToAccept.find((t) => t === fileType);
    }

    saveConfiguration(buttonRef) {
        if (this.subWidgetData.data.saveAsDraftApiInfo) {
            buttonRef.loader = true;
            const errorCallback = (err: any) => {
                buttonRef.loader = false;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.notificationsService,
                    err,
                    'Error in saving Privacy Policy Configuration'
                );
            };
            const fileObj: File = this.tempMail?.files?.[0];
            if (!fileObj) {
                buttonRef.loader = false;
                if (
                    this.subWidgetData.data.whiteLabelingData
                        .privacyPolicyConfig.fileUrl
                ) {
                    this.sendDraftApi(
                        errorCallback,
                        buttonRef,
                        this.subWidgetData.data.whiteLabelingData
                            .privacyPolicyConfig.privacyPolicyKey,
                        this.subWidgetData.data.whiteLabelingData
                            .privacyPolicyConfig.fileName
                    );
                    return;
                } else {
                    return;
                }
            }
            const fileName: string = fileObj.name;
            let key: string;
            this.getKeyFromBackend(
                `privacy-policy.${
                    fileName.split('.')[fileName.split('.').length - 1]
                }`,
                (result: any) => {
                    key = result?.key;
                    this.dumpFileToS3(
                        result?.preSignedUrl,
                        fileObj,
                        () =>
                            this.sendDraftApi(
                                errorCallback,
                                buttonRef,
                                key,
                                fileName
                            ),
                        errorCallback
                    );
                },
                errorCallback
            );
        } else {
            buttonRef.loader = false;
        }
    }

    private getKeyFromBackend(
        fileName: string,
        onSuccess: Function,
        onError: Function
    ): void {
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_DATA_TO_S3_WHITELABEL.replace('{name}', fileName),
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: onSuccess,
            errorFunction: onError
        };
        apiArgs.intactUrl = apiArgs.url;
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    private dumpFileToS3(
        preSignedUrl: string,
        fileObj: File,
        onSuccess: Function,
        onError: Function
    ) {
        const apiArgs: IHitApi = {
            url: preSignedUrl,
            input: fileObj,
            requestType: RequestType.PUT,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                defaultHeaders: {
                    'Content-Type': fileObj.type
                }
            },
            function: onSuccess,
            errorFunction: onError
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    private sendDraftApi(
        errorCallback: Function,
        buttonRef: any,
        key: string,
        fileName: string
    ) {
        const draftToSend = Helper.cloneDeep(
            this.subWidgetData.data.whiteLabelingDraft
        );

        if (this.whiteLabelingData.termsOfServiceConfig) {
            // keeping other data preset
            draftToSend['termsOfServiceConfig'] = Helper.cloneDeep(
                this.whiteLabelingData.termsOfServiceConfig
            );
        }

        draftToSend['privacyPolicyConfig'] = {
            privacyPolicyKey: key,
            fileName
        };
        const apiConfig = Helper.generateHitApiConfig(
            this.subWidgetData.data.saveAsDraftApiInfo
        );
        apiConfig.input = draftToSend;
        apiConfig.function = () => {
            buttonRef.loader = false;
            this.subWidgetData.data.whiteLabelingDraft.privacyPolicyConfig =
                Helper.cloneDeep(draftToSend['privacyPolicyConfig']);
            this.subWidgetData.parentWidgetRef.notificationsService.showSnackBar(
                'Configuration saved successfully. Click on publish to deploy these changes.',
                false,
                '',
                { duration: 3500 }
            );
            if (this.reInitializeWhiteLabelData) {
                this.reInitializeWhiteLabelData();
            }
            this.initFormGenInput(true);
            this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
        };
        apiConfig.errorFunction = errorCallback;
        new HitApi(
            apiConfig,
            this.subWidgetData.parentWidgetRef.httpService,
            this.subWidgetData.parentWidgetRef.ngZone
        ).hitApi();
    }
}
