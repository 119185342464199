<div class="builder-container">
    <div
        class="field-types"
        cdkDropList
        #fieldList="cdkDropList"
        [cdkDropListData]="formBuilderJson"
        [cdkDropListConnectedTo]="[dropForm]"
    >
        <div
            class="each-field cdk-drag-disabled"
            cdkDrag
            *ngFor="let field of formBuilderJson; index as i"
            (click)="addToFinalForm(i)"
        >
            <span appIcon [data]="dragIcon"> </span>

            <span class="name"> {{ field.formName }}</span>
        </div>
    </div>

    <div class="form-area">
        <div
            [ngClass]="
                editFormName ? 'form-name edit-able' : 'form-name non-edit-able'
            "
        >
            <span *ngIf="!editFormName">
                {{ formName ? formName : "Request Form Name" }}
            </span>

            <app-form-generator
                *ngIf="editFormName"
                [formGeneratorInput]="titleFormInput"
                (formGroupRef)="titleFormGroupRef = $event"
            >
            </app-form-generator>

            <span
                appIcon
                [data]="pencilIcon"
                (click)="editFormName = true; initTitleForm()"
            ></span>
        </div>

        <div
            class="form-drop-area"
            cdkDropList
            #dropForm="cdkDropList"
            [cdkDropListData]="finalForm"
            [cdkDropListConnectedTo]="[fieldList]"
            (cdkDropListDropped)="fieldDropped($event)"
        >
            <div
                class="each-field"
                cdkDrag
                *ngFor="let form of finalForm; index as i"
            >
                <app-form-generator
                    [formGeneratorInput]="form"
                    (updateControl)="form.updateControlSubject = $event"
                    (valuesChange)="staticUpdateFields($event, form, i)"
                >
                </app-form-generator>

                <div class="configure-box" *ngIf="configureFormInput">
                    <app-form-generator
                        [formGeneratorInput]="configureFormInput"
                        (formGroupRef)="configureFormGroupRef = $event"
                        (updateControl)="configUpdateControl = $event"
                    >
                    </app-form-generator>
                    <div class="configure-buttons">
                        <app-button-generator
                            [buttonGenInput]="buttonsInput[0]"
                            (buttonRef)="
                                processConfigurationForm(
                                    activeConfigureFormIndex
                                )
                            "
                        >
                        </app-button-generator>
                        <app-button-generator
                            [buttonGenInput]="buttonsInput[1]"
                            (buttonRef)="
                                cancelConfigureForm(activeConfigureFormIndex)
                            "
                        >
                        </app-button-generator>
                    </div>
                </div>

                <div class="actions">
                    <app-button-generator
                        [buttonGenInput]="editActionButton"
                        (buttonRef)="generateConfigurationForm(form, i)"
                    ></app-button-generator>

                    <app-button-generator
                        [buttonGenInput]="deleteActionButton"
                        (buttonRef)="deleteField(i)"
                    >
                    </app-button-generator>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template
    #dropdownPlus
    let-showPlusIcon="showPlusIcon"
    let-fieldIndex="fieldIndex"
    let-dropdownCount="dropdownCount"
>
    <app-button-generator *ngIf="showPlusIcon" [buttonGenInput]="plusButton">
    </app-button-generator>

    <app-button-generator
        *ngIf="!showPlusIcon"
        [buttonGenInput]="minusButton"
        (buttonRef)="deleteDropdownField(fieldIndex)"
    >
    </app-button-generator>
</ng-template>
