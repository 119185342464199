import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Clouds } from '../../../../enums/Clouds';
import { OnboardingAccountType } from './../../../../enums/OnboardingAccountType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-ea-onboarding-modal',
    templateUrl: './ea-onboarding-modal.component.html',
    styleUrls: ['./ea-onboarding-modal.component.sass']
})
export class EaOnboardingModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    newMethodFormGenInput: IFormGeneratorInput = null;
    buttonInputs: IButtonGeneratorInput[];
    eaFormGroup: FormGroup;
    newEaFormGroup: FormGroup;
    eaNewMethodFormGroup: FormGroup;
    resetSubscription: Subscription;
    selectedTabIndex: number = 0;
    activeTabIndex: number = 0;
    Tab = Tab;
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'The existing onboarding method in Azure EA is being deprecated. To continue with your Azure EA onboarding process, please switch to the New Method.'
                    ]
                }
            ]
        }
    ];
    constructor(
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalInput: ModalInjectedData,
        private filtersService: FiltersService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.buttonInputs = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: 'assets/onboarding-pdf/Azure_EA_Accounts(New_and_Old_Method).pdf',
                function: () => {}
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.onSave(buttonRef);
                }
            }
        ];
        this.formGenInput = {
            formName: 'Azure EA Onboarding',
            state: this.modalInput.data.formData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Enrollment Number',
                    name: 'enrollmentNumber',
                    placeholder: 'Enrollment Number',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Enrollment Number is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .enrollmentNumber
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .enrollmentNumber
                            : ''
                        : ''
                },
                {
                    label: 'App Id',
                    name: 'appId',
                    placeholder: 'Enter App Id',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'App id is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .appId
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .appId
                            : ''
                        : ''
                },
                {
                    label: 'Key',
                    name: 'key',
                    placeholder: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo.key
                            ? '******'
                            : 'Enter Key'
                        : 'Enter Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Key is required'
                        }
                    ]
                },
                {
                    label: 'Domain Name',
                    name: 'domainName',
                    placeholder: 'Enter Domain Name example: onmicrosoft.com',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .domainName
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .domainName
                            : ''
                        : ''
                },
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Account Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.accountName
                            ? this.modalInput.data.formData.accountName
                            : ''
                        : ''
                },
                {
                    label: 'Token',
                    name: 'token',
                    placeholder: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .token
                            ? '******'
                            : 'Token'
                        : 'Token',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Token is required'
                        }
                    ]
                }
            ]
        };
        this.newMethodFormGenInput = {
            formName: 'Azure EA Onboarding',
            state: this.modalInput.data.formData
                ? FormState.EDIT
                : FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Account Name',
                    name: 'accountName',
                    placeholder: 'Account Name',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.accountName
                            ? this.modalInput.data.formData.accountName
                            : ''
                        : ''
                },
                {
                    label: 'Billing Account Id',
                    name: 'enrollmentNumber',
                    placeholder: 'Billing Account Id(Enrollment Number)',
                    fieldType: FilterType.NUMBER,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Enrollment Number is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .enrollmentNumber
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .enrollmentNumber
                            : ''
                        : ''
                },
                {
                    label: 'Application Id',
                    name: 'appId',
                    placeholder: 'Application Id',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Application id is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .appId
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .appId
                            : ''
                        : ''
                },
                {
                    label: 'Object Id',
                    name: 'objectId',
                    placeholder: 'Object Id',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .objectId
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .objectId
                            : ''
                        : ''
                },
                {
                    label: 'Tenant Id',
                    name: 'clientTenantId',
                    placeholder: 'Tenant Id',
                    fieldType: FilterType.TEXT,
                    required: false,
                    showLabel: true,
                    appearance: 'legacy',
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .clientTenantId
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .clientTenantId
                            : ''
                        : ''
                },
                {
                    label: 'Domain Name',
                    name: 'domainName',
                    placeholder: 'Enter Domain Name example: onmicrosoft.com',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain name is required'
                        }
                    ],
                    value: this.modalInput.data.formData
                        ? this.modalInput.data.formData.azureEaAccountsInfo
                              .domainName
                            ? this.modalInput.data.formData.azureEaAccountsInfo
                                  .domainName
                            : ''
                        : ''
                },
                {
                    label: 'Secret Key',
                    name: 'key',
                    placeholder: 'Enter Key',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Secret Key is required'
                        }
                    ],
                    value: ''
                }
            ]
        };
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            this.eaFormGroup.reset();
            this.newEaFormGroup.reset();
        });

        if (this.modalInput.data['formData']) {
            if (
                this.modalInput.data['formData']['type'] ===
                    OnboardingAccountType.CONSOLIDATED ||
                this.modalInput.data['formData']['type'] ===
                    OnboardingAccountType.LINKED ||
                this.modalInput.data['formData']['type'] ===
                    OnboardingAccountType.LINKED_1 ||
                this.modalInput.data['formData']['type'] ===
                    OnboardingAccountType.LINKED_2
            ) {
                Helper.removeFormField(this.formGenInput, 'token');
                Helper.removeFormField(this.formGenInput, 'enrollmentNumber');
            } else if (
                this.modalInput.data['formData']['type'] ===
                OnboardingAccountType.INDEPENDENT
            ) {
                Helper.removeFormField(this.formGenInput, 'key');
                Helper.removeFormField(this.formGenInput, 'appId');
                Helper.removeFormField(this.formGenInput, 'domainName');
            }
        } else {
            Helper.removeFormField(this.formGenInput, 'key');
            Helper.removeFormField(this.formGenInput, 'appId');
            Helper.removeFormField(this.formGenInput, 'domainName');
        }
    }

    onSave(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        if (this.activeTabIndex === 0) {
            Helper.markAllFieldAsTouched(this.eaFormGroup);
            if (this.eaFormGroup.invalid) {
                this.eaFormGroup.updateValueAndValidity();
                return;
            }
        } else {
            Helper.markAllFieldAsTouched(this.newEaFormGroup);
            if (this.newEaFormGroup.invalid) {
                this.newEaFormGroup.updateValueAndValidity();
                return;
            }
        }

        const formInputJson = {
            accountNumber: this.eaFormGroup.get('enrollmentNumber')
                ? this.eaFormGroup.get('enrollmentNumber').value
                : null,
            accountName: this.eaFormGroup.get('accountName').value,
            azureEaAccountsInfo: {
                token: this.eaFormGroup.get('token')
                    ? this.eaFormGroup.get('token').value
                    : null,
                enrollmentNumber: this.eaFormGroup.get('enrollmentNumber')
                    ? this.eaFormGroup.get('enrollmentNumber').value
                    : null,
                key: this.eaFormGroup.get('key')
                    ? this.eaFormGroup.get('key').value
                    : null,
                appId: this.eaFormGroup.get('appId')
                    ? this.eaFormGroup.get('appId').value
                    : null,
                domainName: this.eaFormGroup.get('domainName')
                    ? this.eaFormGroup.get('domainName').value
                    : null
            }
        };
        const newMethodFormInputJson = {
            accountNumber: this.newEaFormGroup.get('enrollmentNumber')
                ? this.newEaFormGroup.get('enrollmentNumber').value
                : null,
            accountName: this.newEaFormGroup.get('accountName').value,
            azureEaAccountsInfo: {
                enrollmentNumber: this.newEaFormGroup.get('enrollmentNumber')
                    ? this.newEaFormGroup.get('enrollmentNumber').value
                    : null,
                key: this.newEaFormGroup.get('key')
                    ? this.newEaFormGroup.get('key').value
                    : null,
                appId: this.newEaFormGroup.get('appId')
                    ? this.newEaFormGroup.get('appId').value
                    : null,
                domainName: this.newEaFormGroup.get('domainName')
                    ? this.newEaFormGroup.get('domainName').value
                    : null,
                objectId: this.newEaFormGroup.get('objectId')
                    ? this.newEaFormGroup.get('objectId').value
                    : null,
                clientTenantId: this.newEaFormGroup.get('clientTenantId')
                    ? this.newEaFormGroup.get('clientTenantId').value
                    : null
            }
        };
        if (this.eaFormGroup.valid || this.newEaFormGroup.valid) {
            buttonRef.loader = true;
            if (this.modalInput.data.formData) {
                const apiArgs: IHitApi = {
                    url: `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_EA}/${this.modalInput.data.formData.accountNumber}`,
                    input:
                        this.activeTabIndex === 0
                            ? formInputJson
                            : newMethodFormInputJson,
                    requestType: RequestType.PUT,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    function: (response) => {
                        this.notificationService.showSnackBar(
                            Messages.ACCOUNT_UPDATE_LIST
                        );
                        buttonRef.loader = false;
                        this.filtersService.refreshWidget.next(
                            new Set([this.modalService.modalData.sourceId])
                        );
                        this.modalService.closeModal(
                            null,
                            this.modalInput.modalId
                        );
                    },
                    errorFunction: (error) => {
                        this.notificationService.showSnackBar(
                            error.error.message,
                            true
                        );
                        buttonRef.loader = false;
                    }
                };
                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            } else {
                if (this.selectedTabIndex === 1 || this.activeTabIndex === 1) {
                    const apiArgs: IHitApi = {
                        url: `${ApiUrls.CLOUD_ACCOUNTS_ENDPOINT}/${Clouds.AZURE_EA}/create-new-azure-ea`,
                        input: newMethodFormInputJson,
                        requestType: RequestType.POST,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.BEARER_TOKEN
                        },
                        function: () => {
                            this.notificationService.showSnackBar(
                                Messages.ACCOUNT_ONBOARDING_SUCCESS
                            );
                            buttonRef.loader = false;
                            this.filtersService.refreshWidget.next(
                                new Set([this.modalService.modalData.sourceId])
                            );
                            this.modalService.closeModal(
                                null,
                                this.modalInput.modalId
                            );
                        },
                        errorFunction: (error) => {
                            this.notificationService.showSnackBar(
                                error.error.message,
                                true
                            );
                            buttonRef.loader = false;
                        }
                    };
                    new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
                } else {
                    const apiArgs: IHitApi = Helper.generateHitApiConfig(
                        this.modalInput.data.create
                    );
                    apiArgs.input = formInputJson;
                    apiArgs.function = (response) => {
                        this.notificationService.showSnackBar(
                            Messages.ACCOUNT_ONBOARDING_SUCCESS
                        );
                        buttonRef.loader = false;
                        this.filtersService.refreshWidget.next(
                            new Set([this.modalService.modalData.sourceId])
                        );
                        this.modalService.closeModal(
                            null,
                            this.modalInput.modalId
                        );
                    };
                    apiArgs.errorFunction = (error) => {
                        this.notificationService.showSnackBar(
                            error.error.message,
                            true
                        );
                        buttonRef.loader = false;
                    };
                    new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
                }
            }
        }
    }
    tabChanged($event) {
        this.selectedTabIndex = $event.index;
        this.activeTabIndex = $event.index;
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}

enum Tab {
    TAB_1 = 'Old Method',
    TAB_2 = 'New Method'
}
