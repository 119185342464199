<div
    class="footer-container"
    [ngClass]="{ 'footer-container-console-preview': isPreviewPrivacyPolicy }"
>
    <div class="lower">
        <span class="socials">
            <a
                appIcon
                [data]="twitterIcon"
                *ngIf="whiteLabelService.whiteLableData.twitterUrl"
                [href]="
                    whiteLabelService.whiteLableData.twitterUrl
                        ? whiteLabelService.whiteLableData.twitterUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.twitterUrl
                            : '//' + whiteLabelService.whiteLableData.twitterUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            ></a>
            <a
                appIcon
                [data]="facebookIcon"
                *ngIf="whiteLabelService.whiteLableData.facebookUrl"
                [href]="
                    whiteLabelService.whiteLableData.facebookUrl
                        ? whiteLabelService.whiteLableData.facebookUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.facebookUrl
                            : '//' +
                              whiteLabelService.whiteLableData.facebookUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            ></a>
            <a
                appIcon
                [data]="linkedInIcon"
                *ngIf="whiteLabelService.whiteLableData.linkedinUrl"
                [href]="
                    whiteLabelService.whiteLableData.linkedinUrl
                        ? whiteLabelService.whiteLableData.linkedinUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.linkedinUrl
                            : '//' +
                              whiteLabelService.whiteLableData.linkedinUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            ></a>
        </span>
        <span class="privacy-policy" #privacypolicyspan>
            <a
                [href]="privacyPolicyUrl"
                target="_blank"
                [class.disabled]="isPreviewPrivacyPolicy"
                >Privacy policy</a
            >
        </span>
        <span class="copyright">
            <ng-container
                *ngIf="
                    whiteLabelService.centilyticsData.companyName ===
                    whiteLabelService.whiteLableData.companyName
                "
                >&copy; 2024 Centilytics. All Rights Reserved</ng-container
            >
            <ng-container
                *ngIf="
                    whiteLabelService.centilyticsData.companyName !==
                    whiteLabelService.whiteLableData.companyName
                "
                >Powered by Centilytics 2024 &copy;</ng-container
            >
        </span>
        <span
            *ngIf="
                credentialsCacheService &&
                credentialsCacheService.masterLoginInfo &&
                credentialsCacheService.masterLoginInfo.length > 1
            "
            id="master-login-container"
            (click)="backToMaster()"
        >
            <span class="name"> Back to master </span>
        </span>
    </div>
</div>
