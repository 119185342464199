<div class="container">
    <form class="form">
        <div class="form-data">
            <app-form-generator
                *ngIf="nagiosFormgenInput"
                [formGeneratorInput]="nagiosFormgenInput"
                (formGroupRef)="nagiosFormGoup = $event"
            ></app-form-generator>
        </div>
        <div class="tw-p-3 tw-flex tw-justify-end add">
            <app-button-generator
                *ngIf="addNew"
                [buttonGenInput]="addNew"
            ></app-button-generator>
        </div>
        <div class="table">
            <ng-container *ngIf="tableInput; else noData">
                <ng-container *ngTemplateOutlet="table"></ng-container>
            </ng-container>
            <ng-template #table>
                <app-only-table-generator
                    [tableInput]="tableInput"
                    [tableData]="tableData"
                    (gridRef)="gridRef = $event"
                    (selectionChange)="getSelectedRow()"
                    [widgetRef]="widgetRef"
                ></app-only-table-generator>
            </ng-template>
            <ng-template #noData>
                <span class="extra-message">No Data Available</span>
            </ng-template>
        </div>
    </form>
</div>
<div class="tw-flex tw-justify-end form-controls">
    <app-button-generator
        *ngIf="deleteButton && selectedRowData && selectedRowData.length > 1"
        [buttonGenInput]="deleteButton"
    >
    </app-button-generator>
    <app-button-generator
        *ngIf="nextButton"
        [buttonGenInput]="nextButton"
    ></app-button-generator>
</div>
