import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetCatalogMoreInfoModalComponent } from '../../../modal-templates/widget-catalog-more-info-modal/widget-catalog-more-info-modal.component';
import { WidgetCatalogTagsModalComponent } from '../../../modal-templates/widget-catalog-tags-modal/widget-catalog-tags-modal.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { WidgetPreviewModalComponent } from '../../../widget-preview-modal/widget-preview-modal.component';
import { ViewType } from './../../../../enums/ViewType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IKanbanGeneratorInput } from './../../../../interfaces/kanbanGenerator/IKanbanGeneratorInput';

@Component({
    selector: 'app-widget-catalog',
    templateUrl: './widget-catalog.component.html',
    styleUrls: ['./widget-catalog.component.sass']
})
export class WidgetCatalogComponent implements OnInit, OnDestroy {
    tableInput: ITableGeneratorInput = null;
    kanbanInput: IKanbanGeneratorInput = null;
    widgetRef: Widget = null;
    agGrid: GridOptions;
    hasData: boolean = true;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    lineLoaders: IIcon = {
        type: IconType.LINELOADER,
        class: 'loading-bar'
    };
    refreshIcon: IIcon = {
        type: IconType.SVG,
        class: 'refresh',
        extraClass: 'svg-white-fill'
    };
    viewFilterInfo: IFilterInfo = {
        label: 'View',
        listData: [
            { id: ViewType.TABLE, label: 'List View' },
            { id: ViewType.KANBAN, label: 'Kanban View' }
        ]
    };

    ViewType = ViewType;
    selectedView: ViewType = ViewType.TABLE;
    widgetCatalogData = null;
    buttonInputs: IButtonGeneratorInput[];

    constructor(
        widgetData: WidgetInjectedData,
        private globalDataService: GlobalDataService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.globalDataService.toggleCatalog.next(true);
        this.setupTableInput();
        this.setKanbanInput();
        this.generateButtons();
    }
    generateButtons() {
        this.buttonInputs = [
            {
                buttonName: 'Add / Update Tags',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: () => {
                    this.openTagsModal();
                },
                customClass: 'tags-btn'
            },
            {
                buttonName: 'Download',
                customClass: 'download-btn',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.SECONDARY,
                function: null,

                hoverText: 'Download Report'
            }
        ];
    }

    setupTableInput() {
        this.tableInput = {
            afterResponse: (data) => {
                if (
                    data &&
                    data['widgetCatalogData'] &&
                    data['widgetCatalogData'].length
                ) {
                    this.hasData = true;
                    this.widgetCatalogData = data['widgetCatalogData'];
                } else {
                    this.hasData = false;
                }
                this.setTableColumns(data);
            },
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            selection: 'multiple',
            pagination: {
                pageSizeOptions: [
                    {
                        id: '50',
                        label: '50'
                    },
                    {
                        id: '100',
                        label: '100'
                    },
                    {
                        id: '200',
                        label: '200'
                    }
                ],
                defaultPageSize: '50'
            },
            buttons: null,
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'widgetCatalogData'
            },
            columns: []
        };
    }

    setTableColumns(data) {
        this.tableInput.columns = [];
        if (data && data['keysToShow']) {
            data['keysToShow'].map((column) => {
                if (column.label === 'Status') {
                    this.tableInput.columns.push({
                        columnKey: column.id,
                        columnName: column.name,
                        cellRenderer: (rowData: RowEvent) => {
                            const statusDiv = ` <div style = "color: ${
                                rowData.data['Status'] === 'Active'
                                    ? Helper.getCssVarValue('positiveColor')
                                    : Helper.getCssVarValue('negativeColor')
                            };"> ${rowData.data['Status']} </div>`;
                            return statusDiv;
                        }
                    });
                } else if (column.label.includes('Layer')) {
                    this.tableInput.columns.push({
                        columnKey: column.id,
                        columnName: column.name,
                        cellRenderer: (rowData: RowEvent) => {
                            if (rowData.data[column.label]) {
                                return rowData.data[column.label];
                            }
                            return '-';
                        }
                    });
                } else {
                    this.tableInput.columns.push({
                        columnKey: column.id,
                        columnName: column.name
                    });
                }
            });
        }

        this.tableInput.columns.push({
            columnKey: 'actions',
            columnName: 'Actions',
            minWidth: 185,
            pinned: 'right',
            filter: false,
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData) => {
                const buttons: IButtonGeneratorInput[] = [
                    {
                        buttonName: 'Preview',
                        buttonColorType: ButtonColorType.SUCCESS,
                        buttonType: ButtonType.TEXT,

                        showLoader: false,
                        function: () => {
                            this.openPreviewModal(rowData.data);
                        }
                    },
                    {
                        buttonName: 'More Info',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,

                        showLoader: false,
                        function: () => {
                            this.openMoreInfoModal(rowData.data);
                        }
                    }
                ];
                rowData['buttonGenInputs'] = buttons;
                return rowData;
            }
        });
    }

    setKanbanInput() {
        this.kanbanInput = {
            boardTitle: {
                infoKey: 'View Name',
                infoTitle: 'View Name'
            },
            cardTitle: {
                infoKey: 'Widget Name',
                infoTitle: 'Widget Name'
            },
            afterResponse: (data) => {
                if (
                    data &&
                    data['widgetCatalogData'] &&
                    data['widgetCatalogData'].length
                ) {
                    this.hasData = true;
                    this.widgetCatalogData = data['widgetCatalogData'];
                } else {
                    this.hasData = false;
                }
            },
            cardBadge: {
                infoKey: 'Status',
                infoTitle: 'Status',
                infoRenderer: (cardData) => {
                    return {
                        background:
                            cardData['Status'] === 'Active'
                                ? Helper.getCssVarValue('positiveColor')
                                : Helper.getCssVarValue('negativeColor')
                    };
                }
            },
            cardActions: [
                {
                    buttonName: '',
                    showLoader: false,
                    function: (buttonRef: IButtonData, cardData) => {
                        this.openTagsModal(cardData);
                    },
                    buttonIcon: {
                        type: IconType.SVG,
                        class: 'tag'
                    },
                    hoverText: 'Add / Update Tags ',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.DEFAULT,
                    customClass: 'button',
                    preventBorder: true,
                    preventHoverEffect: true
                },
                {
                    buttonName: '',
                    showLoader: false,
                    function: (buttonRef: IButtonData, cardData) => {
                        this.openMoreInfoModal(cardData);
                    },
                    buttonIcon: {
                        type: IconType.SVG_ASSETS,
                        class: 'info_icon'
                    },
                    hoverText: 'More Info',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.DEFAULT,
                    customClass: 'button',
                    preventBorder: true,
                    preventHoverEffect: true
                },
                {
                    buttonName: '',
                    showLoader: false,
                    function: (buttonRef: IButtonData, cardData) => {
                        this.openPreviewModal(cardData);
                    },
                    buttonIcon: {
                        type: IconType.SVG_ASSETS,
                        class: 'eye_icon'
                    },
                    hoverText: 'Preview',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.DEFAULT,
                    customClass: 'button',
                    preventBorder: true,
                    preventHoverEffect: true
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'widgetCatalogData'
            },
            noDataMessage: 'No boards available at the moment',
            infoData: null
        };
    }

    openTagsModal(cardData?) {
        let selectedWidgetsData = [];
        if (!cardData) {
            if (this.agGrid && this.agGrid.api.getSelectedRows().length) {
                selectedWidgetsData = this.agGrid.api.getSelectedRows();
            }
        } else {
            selectedWidgetsData = [cardData];
        }

        if (selectedWidgetsData.length) {
            const modalData: IModalData = {
                modalName: 'Tags',
                modalIcon: null,
                sourceId: this.widgetRef.uniqueIdentity,
                modalType: ModalType.MIDDLE,
                modalHeightVh: 95,
                modalWidthVw: 90,
                noStepPadding: true,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: WidgetCatalogTagsModalComponent,
                            payload: {
                                data: {
                                    selectedData: selectedWidgetsData,
                                    allRowsData: this.widgetCatalogData,
                                    widgetRef: this.widgetRef,
                                    keyNames: this.createKeyNames()
                                }
                            }
                        },
                        stepName: 'Tags'
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        } else {
            this.widgetRef.notificationsService.showSnackBar(
                'Select atleast 1 widget',
                true
            );
        }
    }
    createKeyNames() {
        return {
            selectedData: 'selectedData',
            allRowsData: 'allRowsData',
            names: 'Widget Name',
            id: 'Widget Id',
            message: 'widget',
            headingMessage: 'Widgets',
            payloadIdName: 'widgetId',
            tagsKeyName: 'widget',
            keysToGetTagData: 'moreInfo|tagColorModel',
            apiInfoKey: 'create'
        };
    }

    openPreviewModal(widgetData) {
        const modalData: IModalData = {
            modalName: widgetData['Widget Name']
                ? widgetData['Widget Name']
                : 'Preview',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-eye'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalWidthVw: 80,
            modalAutoHeight: true,
            maxHeightVh: 98,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: WidgetPreviewModalComponent,
                        payload: {
                            data: {
                                widgetData
                            }
                        }
                    },
                    stepName: 'Preview'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    openMoreInfoModal(widgetData) {
        const modalData: IModalData = {
            modalName: 'More Info',
            modalIcon: null,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 90,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: WidgetCatalogMoreInfoModalComponent,
                        payload: {
                            data: {
                                widgetData,
                                allWidgetsData: this.widgetCatalogData,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Tags'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    onViewChanged(viewValue: ViewType) {
        this.widgetRef.isBindDataAssigned = false;
        this.selectedView = viewValue;
        const visibleSections = new Set<ViewType>();
        visibleSections.add(viewValue);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    ngOnDestroy() {
        this.globalDataService.toggleCatalog.next(false);
    }
}
