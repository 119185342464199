import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { Logger } from '../../classes/Logger';
import { IIotInput } from '../../interfaces/iot/IIotInput';
import { IotNewService } from './iot-new.service';

declare let AWS: any;

declare let Paho: any;

@Injectable({
    providedIn: 'root',
})
export class IotService {
    @Output() iotDataReceived = new EventEmitter();
    status: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private client;
    private topic;

    httpIotErrorCallback;

    constructor(
        private userDataCacheService: UserDataCacheService,
        public iotServiceNew: IotNewService
    ) {}

    onFailure() {
        Logger.warn('Session expired. Logging out');

        if (this.httpIotErrorCallback) {
            this.httpIotErrorCallback(
                'onFailure',
                this.userDataCacheService.emailId,
                this.userDataCacheService.domainId
            );
        }
        if (this.status) {
            this.status.next(false);
        }
    }

    onSuccess() {
        this.status.next(true);
    }

    onConnectionLost(responseObject) {
        if (this.httpIotErrorCallback) {
            this.httpIotErrorCallback(
                'onConnectionLost',
                this.userDataCacheService.emailId,
                this.userDataCacheService.domainId,
                responseObject
            );
        }

        if (this.status) {
            Logger.warn(responseObject);
            this.status.next(false);
        }
    }

    makeConnection(creds) {
        const iotInput: IIotInput = {
            region: creds['region'],
            endpoint: creds['endpoint'],
            topic: creds['topic'],
            accessKeyId: creds['accessKeyId'],
            secretAccessKey: creds['secretAccessKey'],
            sessionToken: creds['sessionToken'],
            connectionSuccessCallback: this.onSuccess.bind(this),
            connectionFailureCallback: this.onFailure.bind(this),
            connectionLostCallback: this.onConnectionLost.bind(this),
            dataReceivedCallback: (response) => {
                this.iotDataReceived.next(response);
            },
        };

        this.topic = creds['topic'];
        this.client = this.iotServiceNew.makeConnection(iotInput);
    }
}
