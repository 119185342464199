<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row {{ field.extraClass }}">
        <fieldset
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name) &&
                      formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name) &&
                      formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            class="summer-note-container"
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
        >
            <legend>
                {{ field.label }}
                <span
                    *ngIf="field.required === undefined || field.required"
                    class="mat-form-field-required-marker"
                >
                    &nbsp;*
                </span>
                <mat-icon
                    *ngIf="field.prefixIcon"
                    matPrefix
                    [matTooltip]="field.prefixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.prefixIcon.function
                            ? field.prefixIcon.function(field, formGroup)
                            : null
                    "
                >
                    {{ field.prefixIcon.iconData.class }}
                </mat-icon>
            </legend>
            <div
                *ngIf="Editor.get(field.name)"
                [id]="Editor.get(field.name).id"
                [ngxSummernote]="
                    Editor.get(field.name) && Editor.get(field.name).config
                "
                [formControl]="control"
            ></div>

            <!-- Setting character limit -->
            <ng-container *ngIf="field.characterLimit">
                <ng-template *ngTemplateOutlet="characterLimit"></ng-template>
            </ng-container>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row type-two {{ field.extraClass }}">
        <fieldset
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name) &&
                      formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name) &&
                      formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            class="summer-note-container"
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
        >
            <div
                [id]="Editor.get(field.name).id"
                [ngxSummernote]="Editor.get(field.name).config"
                [formControl]="control"
            ></div>

            <!-- Setting character limit -->
            <ng-container *ngIf="field.characterLimit">
                <ng-template *ngTemplateOutlet="characterLimit"></ng-template>
            </ng-container>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>

<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row type-two {{ field.extraClass }}">
        <fieldset
            [class.error]="
                field.groupByKey
                    ? formGroup.get(field.groupByKey).get(field.name) &&
                      formGroup.get(field.groupByKey).get(field.name).touched &&
                      formGroup.get(field.groupByKey).get(field.name).errors
                    : formGroup.get(field.name) &&
                      formGroup.get(field.name).touched &&
                      formGroup.get(field.name).errors
            "
            class="summer-note-container"
            [class.disabled]="formGeneratorInput.state === FormState.IDLE"
            [id]="Editor.get(field.name)?.id"
        >
            <editor
                #editor
                [formControl]="control"
                [init]="{
                    base_url: '/assets/tinymce',
                    suffix: '.min',
                    statusbar: false,
                    table_sizing_mode: 'fixed',
                    convert_urls: false,
                    content_css: '/assets/tinymce/styles/tiny-mce-custom-styling.css',
                    valid_children : '-h1[strong],-h2[strong],-h3[strong],-h4[strong],-h5[strong]',
                    table_column_resizing: 'resizetable',
                    powerpaste_word_import: 'clean',
                    powerpaste_html_import: 'clean',
                    menubar: false,
                    toolbar:
                        'styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist table | forecolor backcolor link outdent indent | undo redo',
                    height: 300,
                    plugins: [
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'preview',
                        'anchor',
                        'table',
                        'powerpaste'
                    ],
                    table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    style_formats: [
                        { title: 'Headings', items: [
                            { title: 'Heading 1', format: 'h1' },
                            { title: 'Heading 2', format: 'h2' },
                            { title: 'Heading 3', format: 'h3' },
                            { title: 'Heading 4', format: 'h4' },
                            { title: 'Heading 5', format: 'h5' },
                            { title: 'Heading 6', format: 'h6' }
                            ]
                        },
                        { title: 'Inline', items: [
                            { title: 'Bold', format: 'bold' },
                            { title: 'Italic', format: 'italic' },
                            { title: 'Underline', format: 'underline' },
                            { title: 'Strikethrough', format: 'strikethrough' },
                            { title: 'Superscript', format: 'superscript' },
                            { title: 'Subscript', format: 'subscript' },
                            { title: 'Code', format: 'code' }
                            ]
                        },
                        { title: 'Blocks', items: [
                            { title: 'Paragraph', format: 'p' }
                            ]
                        }
                    ]
                }"
            >
            </editor>

            <!-- Setting character limit -->
            <ng-container *ngIf="field.characterLimit">
                <ng-template *ngTemplateOutlet="characterLimit"></ng-template>
            </ng-container>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </fieldset>
    </div>
</ng-container>
<!--Field type two end -->

<ng-template #characterLimit>
    <div
        [style.color]="
            totalCharacterCount > field.characterLimit
                ? 'var(--negativeColor)'
                : 'var(--grayTextColor)'
        "
        class="char-limit"
    >
        {{ totalCharacterCount }} / {{ field.characterLimit }}
    </div>
</ng-template>
<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
