import { ChangeDetectorRef, NgZone, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValueSetterParams } from 'ag-grid-community';
import { saveAs } from 'file-saver';
import * as $ from 'jquery';
import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import { FileInput } from 'ngx-material-file-input';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Widget } from 'src/app/shared/classes/Widget';
import {
    IFormField,
    IWysiwygEditorInsertOperation,
} from 'src/app/shared/interfaces/form-generator/IFormField';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ConfirmationModalComponent } from '../components/modal-templates/confirmation-modal/confirmation-modal.component';
import { FormGeneratorModalComponent } from '../components/modal-templates/form-generator-modal/form-generator-modal.component';
import {
    CheckboxField,
    ColorField,
    ContentField,
    DateField,
    DateRangeFieldAppearance,
    DoubleRangeAppearance,
    DropdownGroupMultipleFieldAppearance,
    DropdownGroupSingleFieldAppearance,
    DropdownListObjectFieldAppearance,
    DropdownMultipleFieldAppearance,
    DropdownSingleFieldAppearance,
    EmailField,
    FileField,
    ImageUploadField,
    MatChipField,
    NumberField,
    PasswordField,
    RadioField,
    TextFieldAppearance,
    TimeField,
    ToggleField,
    URLField,
} from '../enums/AppearanceType';
import { AuthorizationType } from '../enums/AuthorizationType';
import { ButtonColorType } from '../enums/ButtonColorType';
import { CloudLabel } from '../enums/CloudLabel';
import { Clouds } from '../enums/Clouds';
import { ContentType } from '../enums/ContentType';
import { FilterType } from '../enums/FilterType';
import { FormState } from '../enums/FormState';
import { IconType } from '../enums/IconType';
import { IntegrationName } from '../enums/IntegrationName';
import { LazyModuleTypes } from '../enums/LazyModuleTypes';
import { ModalType } from '../enums/ModalType';
import { RequestType } from '../enums/RequestType';
import { IApiInfo } from '../interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from '../interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from '../interfaces/dropdown-data/IDropdownData';
import { IBackendFormGeneratorInput } from '../interfaces/form-generator/IBackendFormGeneratorInput';
import { IBackendValidator } from '../interfaces/form-generator/IBackendValidator';
import { IValidator } from '../interfaces/form-generator/IValidator';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { IIcon } from '../interfaces/icon-data/IIcon';
import { IIotInput } from '../interfaces/iot/IIotInput';
import { IModalData } from '../interfaces/modal/IModalData';
import { IButtonData } from '../interfaces/table-generator/IButtonData';
import { IUpdateAction } from '../interfaces/update-action/IUpdateAction';
import { FilterCacheService } from '../services/cache/filters-cache/filter-cache.service';
import { HttpService } from '../services/http/http-main/http.service';
import { ModalService } from '../services/modal/modal-service/modal.service';
import { NotificationsService } from '../services/notifications/notifications.service';
import { GetFullAccessComponent } from './../components/modal-templates/get-full-access/get-full-access.component';
import {
    DateTimeField,
    TextAreaField,
    WysiwygEditorField,
} from './../enums/AppearanceType';
import { ButtonType } from './../enums/ButtonType';
import { FilterStoreKey } from './../enums/FilterStoreKey';
import { IHiddenDependency } from './../interfaces/form-dependency-data/IHiddenDependency';
import { IFormGeneratorInput } from './../interfaces/form-generator/IFormGeneratorInput';
import { ConfigCacheService } from './../services/cache/config-cache/config-cache.service';
import { CustomValidators } from './CustomValidators';
import { HitApi } from './HitApi';
import { IconLibrary } from './IconLibrary';
import { Messages } from './Messages';
const Markdown = require('pagedown');

export class Helper {
    static updateControlInput: IUpdateAction;
    static updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    static dynamicAzureCloudLabel: any;
    static get servicenowIncidentPrioritiesForImpact() {
        return [
            {
                id: 'HIGH',
                label: '1 - High',
            },
            {
                id: 'MEDIUM',
                label: '2 - Medium',
            },
            {
                id: 'LOW',
                label: '3 - Low',
            },
        ];
    }
    static get servicenowIncidentPrioritiesUrgency() {
        return [
            {
                id: 'HIGH',
                label: '1 - High',
            },
            {
                id: 'MEDIUM',
                label: '2 - Medium',
            },
            {
                id: 'LOW',
                label: '3 - Low',
            },
        ];
    }
    static get servicenowIncidentPriorities() {
        return [
            {
                id: '1 - Critical',
                label: '1 - Critical',
            },
            {
                id: '2 - High',
                label: '2 - High',
            },
            {
                id: '3 - Moderate',
                label: '3 - Moderate',
            },
            {
                id: '4 - Low',
                label: '4 - Low',
            },
            {
                id: '5 - Planning',
                label: '5 - Planning',
            },
        ];
    }

    static getFullAccess(modalService, data) {
        const modalData: IModalData = {
            modalName: 'Get Full Access!',
            modalIcon: null,
            sourceId: null,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 60,
            modalAutoHeight: true,
            modalClass: 'backgroundBlur',
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: GetFullAccessComponent,
                        payload: {
                            data,
                        },
                    },
                    stepName: 'Light Full',
                },
            ],
        };
        modalService.openModal(modalData);
    }

    static urlFormatter(url: string): string {
        url = url?.toLocaleLowerCase();
        url = url?.replace(/\s+/g, '_');

        return url;
    }

    static convertUrlToKey(url: string): string {
        return decodeURIComponent(url.split('/').join(''));
    }

    static doScroll(
        id: any,
        blockConfiguration?: any,
        customPosition?: {
            x: number;
            y: number;
        }
    ) {
        if (customPosition) {
            document
                .getElementById(id)
                ?.scrollTo(customPosition.x, customPosition.y);
            return;
        }
        document.getElementById(id)?.scrollIntoView({
            behavior: 'smooth',
            block: blockConfiguration,
            inline: 'nearest',
        });
    }

    static generateUniqueKey(length: number) {
        let result = '';
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    }

    static getRandomNumber(maxValue: number) {
        return Math.floor(Math.random() * maxValue) + 1;
    }
    static getValueValidators(cloudName) {
        const validator = [];
        if (cloudName === CloudLabel.GCP) {
            const valueSpecialCharacterValidator = {
                validator: CustomValidators.gcpValueSpecialCharacter,
                errorMessage:
                    'Value cannot contain special character other than _- or capital letter or spaces',
            };

            validator.push(valueSpecialCharacterValidator);
        }
        return validator;
    }
    static getKeyValidators(
        cloudName,
        keys,
        keysAlreadyExists?,
        allKeysAlreadyExists?,
        keyIndex?,
        update?,
        applyCaseInsensitive?
    ) {
        const validator = [
            {
                validator: CustomValidators.keyAlreadyExists(keys),
                errorMessage: 'Key already exists',
            },
            {
                validator: CustomValidators.required,
                errorMessage: 'Key cannot be empty',
            },
        ] as any;

        if (cloudName === CloudLabel.GCP) {
            const keyCapitalLetterValidator = {
                validator: CustomValidators.gcpCapitalLetterValidator,
                errorMessage: 'Key cannot contain capital letters and spaces',
            };
            const keyNumberValidator = {
                validator: CustomValidators.gcpNumberValidator,
                errorMessage: 'Key cannot start with number',
            };
            const keySpecialCharacterValidator = {
                validator: CustomValidators.gcpKeySpecialCharacterValidator,
                errorMessage:
                    'Key cannot contain special character other than -_ and spaces',
            };
            validator.push(keyCapitalLetterValidator);
            validator.push(keySpecialCharacterValidator);
            validator.push(keyNumberValidator);
        }
        if (applyCaseInsensitive) {
            const caseInsensitiveValidator = {
                validator: CustomValidators.caseInsensitiveKeyAlreadyExists(
                    keysAlreadyExists,
                    allKeysAlreadyExists,
                    keys,
                    keyIndex,
                    update
                ),
                errorMessage: 'Tag Key is Case Insensitive and already exists',
            };
            validator.push(caseInsensitiveValidator);
        }

        return validator;
    }
    static getPasswordValidators(validatorSign?, validatorName?) {
        const validator = [
            {
                validator: CustomValidators.required,
                errorMessage: 'Password is required',
            },
            {
                validator: CustomValidators.minLength(8),
                errorMessage: 'Password must be atleast 8 characters long',
            },
            {
                validator: CustomValidators.password,
                errorMessage:
                    'Invalid password. It must be a combination of uppercase, lowercase, number and special characters',
            },
        ];
        if (validatorSign === 'unequal') {
            const unequalValidator = {
                validator: CustomValidators.unequal(validatorName),
                errorMessage: 'Old and New password must be different',
            };
            validator.push(unequalValidator);
        }
        if (validatorSign === 'equal') {
            const equalValidator = {
                validator: CustomValidators.equal([validatorName]),
                errorMessage: 'Password do not match',
            };
            validator.push(equalValidator);
        }
        if (validatorSign === 'equal-password') {
            const equalPasswordValidator = {
                validator: CustomValidators.equalPassword(validatorName),
                errorMessage: 'Password do not match'
            };
            validator.push(equalPasswordValidator);
        }

        return validator;
    }
    static getRandomFromList(list: string[]) {
        const index = Helper.getRandomNumber(list.length - 1);
        return list[index];
    }

    static generateHitApiConfig(
        apiInfo: IApiInfo,
        bindDataFunction?,
        bindPartialDataFunction?,
        iotConfig?
    ): IHitApi {
        // Below function will replace the micro-service name with the port for testing locally or via IP
        const fixHostAndRoute = (apiInfo: IApiInfo, urlPrefix, replacedUrl) => {
            if (apiInfo.apiRouteSuffix.indexOf(urlPrefix) === 0) {
                apiInfo.host = replacedUrl;
                apiInfo.apiRouteSuffix = apiInfo.apiRouteSuffix.replace(
                    urlPrefix,
                    ''
                );
            }
        };
        if (ApiUrls.IS_VIA_IP) {
            fixHostAndRoute(
                apiInfo,
                ApiUrls.VIEW_AND_API_MGMNT_NAME,
                ApiUrls.VIEW_AND_API_MANAGEMENT
            );
            fixHostAndRoute(
                apiInfo,
                ApiUrls.IDENTITY_ACCESS_MGMNT_NAME,
                ApiUrls.IDENTITY_ACCESS_MANAGEMENT
            );
            fixHostAndRoute(
                apiInfo,
                ApiUrls.SRM_INTEGRATIONS_NAME,
                ApiUrls.SRM_INTEGRATIONS
            );
            fixHostAndRoute(
                apiInfo,
                ApiUrls.SRM_GOVERNANCE_NAME,
                ApiUrls.SRM_GOVERNANCE
            );
            fixHostAndRoute(
                apiInfo,
                ApiUrls.GENERAL_AUTOMATIONS_NAME,
                ApiUrls.GENERAL_AUTOMATIONS
            );
            fixHostAndRoute(
                apiInfo,
                ApiUrls.PARTNER_CENTER_PORTAL_NAME,
                ApiUrls.PARTNER_CENTER_PORTAL
            );
        }
        const apiConfig: IHitApi = {
            url: `${apiInfo.host ? apiInfo.host : ''}${
                apiInfo.apiRouteSuffix ? apiInfo.apiRouteSuffix : ''
            }`,
            intactUrl:
                apiInfo.intactUrl ??
                apiInfo.apiSkeleton ??
                apiInfo.apiRouteSuffix,
            uniqueIdentity: Symbol(),
            requestType: apiInfo.requestType,
            input: null,
            function: bindDataFunction ? bindDataFunction : null,
            partialBindFunction: bindPartialDataFunction
                ? bindPartialDataFunction
                : null,
            config: {
                authorization: apiInfo.authorization,
                ignoreBaseUrl:
                    apiInfo.host && apiInfo.host.length ? true : false,
                skipBypassRegion: apiInfo.skipByPassRegion
                    ? apiInfo.skipByPassRegion
                    : false
            },
            iotStreamData:
                apiInfo.streamRequestType &&
                apiInfo.streamRequestType === 'IOT_STREAM' &&
                apiInfo.streamHost &&
                apiInfo.streamHost.length &&
                apiInfo.streamRouteSuffix &&
                apiInfo.streamRouteSuffix.length
                    ? {
                          streamHost: apiInfo.streamHost,
                          streamRouteSuffix: apiInfo.streamRouteSuffix,
                          iotInput: Helper.generateIotInput(apiInfo, iotConfig),
                      }
                    : null,
            mockApi: apiInfo.mockApi,
        };

        if (apiInfo.customInputCallback) {
            if (apiConfig.input) {
                apiConfig.input = {
                    ...apiConfig.input,
                    ...apiInfo.customInputCallback(),
                };
            } else {
                apiConfig.input = {
                    ...apiInfo.customInputCallback(),
                };
            }
        }

        return apiConfig;
    }

    static generateIotInput(apiInfo: IApiInfo, iotConfig): IIotInput {
        iotConfig['deploymentEndpoint'] = iotConfig['deploymentEndpoint']
            ? iotConfig['deploymentEndpoint']
            : iotConfig['endpoint'];
        const iotInput: IIotInput = {
            region: iotConfig['deploymentEndpoint'].substring(
                iotConfig['deploymentEndpoint'].indexOf('.iot.') + 5,
                iotConfig['deploymentEndpoint'].indexOf('.amazonaws.com')
            ),
            endpoint: iotConfig['deploymentEndpoint'],
            topic: Helper.generateUniqueKey(16),
            accessKeyId: iotConfig['accessKeyId'],
            secretAccessKey: iotConfig['secretAccessKey'],
            sessionToken: iotConfig['sessionToken'],
            connectionSuccessCallback: undefined,
            connectionFailureCallback: undefined,
            connectionLostCallback: undefined,
            dataReceivedCallback: undefined,
        };

        return iotInput;
    }

    /**
     *
     * @param apiInfo apiInfo whose routeSuffix {cloud} part needs to be replace with cloud name
     * @param cloudName cloud name which needs to be replace in apiRouteSuffix
     *
     */

    static addCloudNameInApiUrl(apiInfo: IApiInfo, cloudName) {
        if (
            apiInfo.apiRouteSuffix &&
            apiInfo.apiRouteSuffix.includes('{cloud}')
        ) {
            apiInfo.intactUrl = apiInfo.apiRouteSuffix;
            apiInfo.apiRouteSuffix = apiInfo.apiRouteSuffix.replace(
                '{cloud}',
                cloudName
            );
            return apiInfo;
        }
    }

    static roundOff(num: number, places) {
        const number = +num;
        if (Number(number)) {
            return number.toFixed(places);
        } else {
            return num;
        }
    }

    static getHex(rgbColor: string) {
        if (!rgbColor) {
            return '#000';
        }
        if (rgbColor.includes('#')) {
            rgbColor = Helper.getRgb(rgbColor);
        }
        const rgb = rgbColor.split('hsl(')[1].split(')')[0].split(',');
        return (
            '#' +
            ((1 << 24) + (+rgb[0] << 16) + (+rgb[1] << 8) + +rgb[2])
                .toString(16)
                .slice(1)
        );
    }

    static getRgb(hexColor: string): string {
        if (!hexColor) {
            return 'rgb(0, 0, 0)';
        }
        return (
            'rgb(' +
            hexColor
                .match(/[A-Za-z0-9]{2}/g)
                .map((v) => {
                    return parseInt(v, 16);
                })
                .join(', ') +
            ')'
        );
    }

    /**
     * This function will convert hex color code into hsl form.
     * @param hex it takes hex code as argument and convert it into hsl.
     */
    static getHsl(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        (r /= 255), (g /= 255), (b /= 255);
        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h,
            s,
            l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        s = s * 100;
        s = Math.round(s);
        l = l * 100;
        l = Math.round(l);
        h = Math.round(360 * h);
        const colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';

        return colorInHSL;
    }

    /**
     * This method return hex color code from hsl color code
     * @param varValue It takes hsl color code as an argument
     */
    static convertToHexIfHsl(varValue: string) {
        if (varValue.includes('hsl')) {
            return Helper.getHexFromHsl(varValue);
        }
        return varValue;
    }

    static getHexFromHsl(hsl) {
        hsl = hsl.split('(')[1].split(')')[0].split(',');
        const h = hsl[0].split('%')[0].trim();
        const s = hsl[1].split('%')[0].trim();
        let l = hsl[2].split('%')[0].trim();

        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0'); // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    static compareObjects(value, other) {
        const type = Object.prototype.toString.call(value);

        // If the two objects are not the same type, return false
        if (type !== Object.prototype.toString.call(other)) {
            return false;
        }

        // If items are not an object or array, return false
        if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
            return false;
        }

        // Compare the length of the length of the two items
        const valueLen =
            type === '[object Array]'
                ? value.length
                : Object.keys(value).length;
        const otherLen =
            type === '[object Array]'
                ? other.length
                : Object.keys(other).length;
        if (valueLen !== otherLen) {
            return false;
        }

        // Compare properties
        if (type === '[object Array]') {
            for (let i = 0; i < valueLen; i++) {
                if (Helper.compare(value[i], other[i]) === false) {
                    return false;
                }
            }
        } else {
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (Helper.compare(value[key], other[key]) === false) {
                        return false;
                    }
                }
            }
        }

        // If nothing failed, return true
        return true;
    }

    private static compare(item1, item2) {
        // Get the object type
        const itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!Helper.compareObjects(item1, item2)) {
                return false;
            }
        } else {
            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) {
                return false;
            }

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) {
                    return false;
                }
            } else {
                if (item1 !== item2) {
                    return false;
                }
            }
        }
    }

    /**
     *
     *
     * @static
     * @param {string} originalUrl original url including host and path
     * @param {number} pageNumber page number to reach assuming it exist
     * @returns {string} url with page number added
     * @memberof Helper
     */
    static generateNextPageUrl(
        originalUrl: string,
        pageNumber: number
    ): string {
        const url = `${originalUrl}?page=${Math.max(0, pageNumber)}&maxItems=${
            GlobalConfiguration.WIDGETS_PAGE_LIMIT
        }`;
        return url;
    }

    static getFilterDefaultValue(filterType: FilterType, getFullObj) {
        if (
            filterType === FilterType.DROPDOWN_MULTIPLE ||
            filterType === FilterType.DROPDOWN_GROUP_MULTIPLE ||
            filterType === FilterType.MULTI_DROPDOWN_WITH_INPUT
        ) {
            return [];
        } else if (filterType === FilterType.DROPDOWN_SINGLE && getFullObj) {
            return {};
        } else if (filterType === FilterType.DROPDOWN_SINGLE && !getFullObj) {
            return null;
        } else if (
            filterType === FilterType.DATE_RANGE ||
            filterType === FilterType.WIDGET_DATE_RANGE ||
            filterType === FilterType.DATE_RANGE_TODAY
        ) {
            const { startDateTimeValue, endDateTimeValue } =
                filterType === FilterType.WIDGET_DATE_RANGE ||
                filterType === FilterType.DATE_RANGE_TODAY
                    ? this.getDateTime(7, true)
                    : this.getDateTime(7, false);
            const startDateTime =
                this.convertDateObjectToRequiredFormat(startDateTimeValue) +
                ' 00:00:00';
            const endDateTime =
                this.convertDateObjectToRequiredFormat(endDateTimeValue) +
                ' 23:59:59';
            if (filterType === FilterType.WIDGET_DATE_RANGE) {
                return {
                    startDateTime: '2020-11-05 00:00:00',
                    endDateTime,
                };
            } else {
                return {
                    startDateTime,
                    endDateTime,
                };
            }
        } else if (filterType === FilterType.DATE_TIME_RANGE) {
            const { startDateTimeValue, endDateTimeValue } =
                this.getDateTime(7);
            const startDateTime =
                this.convertDateObjectToRequiredFormat(startDateTimeValue) +
                ' 00:00:00';
            const endDateTime =
                this.convertDateObjectToRequiredFormat(endDateTimeValue) +
                ' 23:59:59';
            return {
                startDate: startDateTime,
                endDate: endDateTime,
                startTime: 1619461800000,
                endTime: 1619548140000,
            };
        } else if (filterType === FilterType.CUSTOMER_USER) {
            return {
                customer: null,
                users: [],
            };
        } else if (filterType === FilterType.ACL_VIEW_LAYER) {
            return {
                acl: [],
                view: null,
                layer1: null,
                layer2: null,
                layer3: null,
                layer4: null,
            };
        }
    }

    static getDateValue(dateSplitted: number[], timeSplitted: number[]) {
        return new Date(
            dateSplitted[0],
            dateSplitted[1] - 1,
            dateSplitted[2],
            timeSplitted[0],
            timeSplitted[1],
            timeSplitted[2]
        );
    }

    static getDateTime(days: number, includeTodayDate = false) {
        let endDateTimeValue = null;
        if (includeTodayDate) {
            endDateTimeValue = new Date(
                new Date().setDate(new Date().getDate())
            );
        } else {
            endDateTimeValue = new Date(
                new Date().setDate(new Date().getDate() - 1)
            );
        }

        const startDateTimeValue = new Date(
            new Date(new Date().getTime()).setDate(new Date().getDate() - days)
        );
        return {
            startDateTimeValue,
            endDateTimeValue,
        };
    }

    static prepareDateWithMonthName(date): string {
        date = '' + new Date(date);
        const splittedDate = date.split(' ');
        return splittedDate[1] + ' ' + splittedDate[2] + ' ' + splittedDate[3];
    }

    static convertDateObjectToRequiredFormat(date): string {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    /**
     * @param key  Pipe(|) separated string indicating data location
     * @param object  Object containing data
     * If some middle key is an array. Take first element directly
     */
    static extractDataFromObject(key: string | string[], dataObject) {
        let result: any;
        if (Array.isArray(key)) {
            let listData = [];
            key.forEach((k) => {
                const resultFromDataExtractionFn = dataExtractfn(k, dataObject);
                if (Array.isArray(resultFromDataExtractionFn)) {
                    listData = [...listData, ...resultFromDataExtractionFn];
                } else {
                    listData = [...listData, resultFromDataExtractionFn];
                }
            });
            result = listData;
        } else {
            result = dataExtractfn(key, dataObject);
        }

        function dataExtractfn(key: string, dataObject) {
            if (dataObject) {
                const splittedKey = key.split('|');
                return splittedKey.reduce((currentObj, currentKey, index) => {
                    if (currentObj) {
                        if (
                            currentObj[currentKey] instanceof Array &&
                            index !== splittedKey.length - 1
                        ) {
                            return currentObj[currentKey][0];
                        }
                        return currentObj[currentKey];
                    } else {
                        return null;
                    }
                }, dataObject);
            } else {
                return null;
            }
        }
        return result;
    }

    static removeStringfromArray(value: string, values: string[]): string[] {
        return values.filter((item) => item !== value);
    }

    static removeIndexfromArray(index: number, values: any[]): any[] {
        return values.filter((item, i) => i !== index);
    }

    /**
     * @static
     * @param {[]} array this is the given array
     * @param {number} fromIndex this is the intial index of the item you want to move in your array
     * @param {number} toIndex this is the destination index of you item
     * @memberof Helper
     */

    static moveItemInArray(array, fromIndex, toIndex) {
        const element = array[fromIndex];
        array.splice(fromIndex, 1);
        array.splice(toIndex, 0, element);
        return array;
    }

    /**
     * @param buttons Use font awesome icons only as of now
     */
    static generateTableButtons(
        buttons: IButtonData[],
        cssVarValueFunction,
        modalServiceRef?: ModalService
    ) {
        const divElement = document.createElement('div');
        divElement.style.display = 'flex';
        buttons.forEach((button) => {
            const btnElement = document.createElement('button');
            btnElement.disabled = button.disable;
            if (button.hoverText) {
                btnElement.title = button.hoverText;
            }
            if (button.buttonClass) {
                btnElement.classList.add(...button.buttonClass.split(' '));
            }
            btnElement.innerHTML = `<i class='${
                button.buttonIcon ? button.buttonIcon.class : ''
            } ${button.buttonIcon ? button.buttonIcon.extraClass : ''}'></i>${
                button.buttonName.trim().length
                    ? `<span style="${
                          button.buttonIcon ? 'margin-left: 8px' : ''
                      }x">${button.buttonName}</span>`
                    : ''
            }
                ${
                    button.showLoader
                        ? `<i class='hide fas fa-spinner spinIcon' style="margin-left: 8px;"></i>`
                        : ''
                }
                `;
            if (button.showLoader) {
                button.showLoaderInTableButtons = () => {
                    const icon = btnElement.querySelector('.spinIcon');
                    icon.classList.remove('hide');
                    icon.classList.add('show');
                    return 'VISIBLE';
                };
                button.hideLoaderInTableButtons = () => {
                    const icon = btnElement.querySelector('.spinIcon');
                    icon.classList.add('hide');
                    icon.classList.remove('show');
                    return 'INVISIBLE';
                };
            }

            btnElement.style.border = `1px solid ${cssVarValueFunction(
                'accent'
            )}`;
            btnElement.style.backgroundColor = 'white';
            btnElement.style.color = cssVarValueFunction('accent');
            btnElement.style.padding = '4px';
            btnElement.style.cursor = button.disable
                ? 'not-allowed'
                : 'pointer';
            btnElement.style.margin = '0 3px';
            btnElement.style.fontSize = '0.75rem';
            btnElement.style.outline = '0';
            btnElement.style.borderRadius = '2px';
            btnElement.style.display = 'flex';
            btnElement.style.alignItems = 'center';
            btnElement.style.justifyContent = 'center';
            btnElement.style.transition = 'all 0.3s ease-in-out';

            btnElement.addEventListener('mouseenter', () => {
                btnElement.style.backgroundColor =
                    cssVarValueFunction('accent');
                btnElement.style.color = 'white';
            });
            btnElement.addEventListener('mouseleave', () => {
                btnElement.style.backgroundColor = 'white';
                btnElement.style.color = cssVarValueFunction('accent');
            });
            btnElement.addEventListener('click', (event) => {
                event.stopPropagation();
                if (
                    button.executeFunctionAfterConfirmation &&
                    modalServiceRef
                ) {
                    this.executionFunctionAfterConfirmation(
                        button,
                        modalServiceRef
                    );
                } else {
                    button.function(button);
                }
            });

            divElement.appendChild(btnElement);
        });
        return divElement;
    }

    static executionFunctionAfterConfirmation(
        data: any,
        modalServiceRef: ModalService
    ) {
        const modalData: IModalData = {
            modalName: data.executeFunctionAfterConfirmation.modalName,
            modalIcon: data.executeFunctionAfterConfirmation.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: data.executeFunctionAfterConfirmation.modalHeightVh
                ? data.executeFunctionAfterConfirmation.modalHeightVh
                : 30,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: data.function,
                                params: data.executeFunctionAfterConfirmation,
                            },
                        },
                    },
                    stepName: 'Confirmation',
                },
            ],
        };
        modalServiceRef.openModal(modalData);
    }

    static generateColumnButtons(
        buttons: IButtonData[],
        cssVarValueFunction,
        modalServiceRef?: ModalService,
        columnContent?: any,
        buttonType?: string,
        columnStyling?: any
    ) {
        const divElement = document.createElement('div');
        if (columnContent) {
            if (columnContent.type === FilterType.PASSWORD) {
                divElement.innerHTML = `<span>*** *** ***</span>`;
            } else if (
                columnContent.type === FilterType.TEXT ||
                columnContent.type === FilterType.EMAIL
            ) {
                if (columnContent.style) {
                    divElement.innerHTML = `<span style="${columnContent.style}">${columnContent['value']}</span>`;
                } else {
                    divElement.innerHTML = `<span>${columnContent['value']}</span>`;
                }
            } else {
                divElement.innerHTML = `<span>${columnContent['value']}</span>`;
            }
        }
        divElement.style.display = 'flex';
        divElement.style.justifyContent =
            columnStyling && columnStyling.justifyContent
                ? columnStyling.justifyContent
                : 'space-evenly';
        divElement.style.width = '100%';
        divElement.style.userSelect = 'none';
        buttons.forEach((button) => {
            const btnElement = document.createElement('button');
            btnElement.disabled = button.disable;
            if (button.hoverText) {
                btnElement.title = button.hoverText;
            }
            btnElement.style.marginLeft = 'auto';

            if (button.buttonClass) {
                btnElement.classList.add(...button.buttonClass.split(' '));
            }

            const innerBtnElement = `${
                button.buttonName.trim().length
                    ? `<span style="margin-left: 8px">${button.buttonName}</span>`
                    : ''
            }
            ${
                button.showLoader
                    ? `<i class='hide fas fa-spinner spinIcon' style="margin-left: 8px;"></i>`
                    : ''
            }`;

            if (buttonType !== 'text') {
                btnElement.innerHTML = `<i class='${button.buttonIcon.class} ${button.buttonIcon.extraClass}'></i>${innerBtnElement}`;
            } else {
                btnElement.innerHTML = innerBtnElement;
                btnElement.style.margin = 'unset';
            }

            if (button.showLoader) {
                button.showLoaderInTableButtons = () => {
                    const icon = btnElement.querySelector('.spinIcon');
                    icon.classList.remove('hide');
                    icon.classList.add('show');
                    return 'VISIBLE';
                };
                button.hideLoaderInTableButtons = () => {
                    const icon = btnElement.querySelector('.spinIcon');
                    icon.classList.add('hide');
                    icon.classList.remove('show');
                    return 'INVISIBLE';
                };
            }
            btnElement.style.backgroundColor = 'none';
            if (button.buttonColor) {
                btnElement.style.color = cssVarValueFunction(
                    button.buttonColor
                );
            }
            btnElement.style.padding = '4px';
            btnElement.style.cursor = button.disable
                ? 'not-allowed'
                : 'pointer';

            btnElement.style.fontSize = '1rem';
            btnElement.style.outline = 'none';
            btnElement.style.borderRadius = 'none';
            btnElement.style.border = 'none';
            btnElement.style.background = 'none';
            btnElement.style.display = 'flex';
            btnElement.style.alignItems = 'center';
            btnElement.style.justifyContent = 'center';
            btnElement.style.transition = 'all 0.3s ease-in-out';
            btnElement.addEventListener('click', (event) => {
                event.stopPropagation();
                if (
                    button.executeFunctionAfterConfirmation &&
                    modalServiceRef
                ) {
                    const modalData: IModalData = {
                        modalName:
                            button.executeFunctionAfterConfirmation.modalName,
                        modalIcon:
                            button.executeFunctionAfterConfirmation.modalIcon,
                        sourceId: Symbol(),
                        modalType: ModalType.MIDDLE,
                        modalHeightVh: 30,
                        modalWidthVw: 50,
                        modalSteps: [
                            {
                                stepData: {
                                    componentToLoad: ConfirmationModalComponent,
                                    payload: {
                                        data: {
                                            function: button.function,
                                            params: button.executeFunctionAfterConfirmation,
                                        },
                                    },
                                },
                                stepName: 'Confirmation',
                            },
                        ],
                    };
                    modalServiceRef.openModal(modalData);
                } else {
                    button.function(button);
                }
            });

            divElement.appendChild(btnElement);
        });
        return divElement;
    }
    static generateToggleButtons(data, callbackFn) {
        const divElement = document.createElement('div');
        divElement.innerHTML = `<i class='${data.class}'></i>`;
        if (data.class.includes('on')) {
            divElement.style.color = 'var(--accent)';
        } else {
            divElement.style.color = '#949494';
        }
        divElement.style.fontSize = '1.7rem';
        divElement.style.cursor = 'pointer';

        divElement.addEventListener('click', (event) => {
            event.stopPropagation();

            if (callbackFn) {
                callbackFn();
            }
        });

        return divElement;
    }
    static saveAsBlob(data: any, type: ContentType, fileName: string) {
        let name;
        if (type === ContentType.EXCEL) {
            name = `${fileName}.xlsx`;
        } else if (type === ContentType.CSV) {
            name = `${fileName}.csv`;
        } else if (type === ContentType.PDF) {
            name = `${fileName}.pdf`;
        } else if (type === ContentType.ZIP) {
            name = `${fileName}.zip`;
        }
        const blob = new Blob([data], { type });
        const file = new File([blob], name, { type });
        saveAs(file);
    }
    /**
     * This method is used to convert base64 string to file.
     * @param data is base64 string which needs to be converted in file.
     * @param fileName is name of the file.
     */
    static convertBase64ToFile(data: any, fileName: string) {
        const arr = data.split(',');
        const fileType = arr[0].match(/:(.*?);/)[1];
        const fileData = atob(arr[1]);
        let n = fileData.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = fileData.charCodeAt(n);
        }
        const file = new File([u8arr], fileName, { type: fileType });
        saveAs(file);
    }

    /**
     * This method is used to convert base64 string to file.
     * @param data is base64 string which needs to be converted in file.
     * @param fileName is name of the file.
     */
    static convertBase64ToFileAndShow(data: any, fileName: string) {
        try {
            const arr = data.split(',');
            const fileType = arr[0].match(/:(.*?);/)[1];
            const fileData = atob(arr[1]);
            let n = fileData.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = fileData.charCodeAt(n);
            }
            const file = new File([u8arr], fileName, { type: fileType });
            return file;
        } catch (err) {
            return null;
        }
    }

    static validateEmail(email) {
        const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (reg.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     *
     * @param url Url to redirect to
     * @param target Redirection target
     */
    static navigateTo(url: string, target: 'NEW_PAGE' | 'SAME_PAGE'): void {
        const win = window.open(
            url,
            target === 'SAME_PAGE' ? '_self' : '_blank'
        );
        if (win) {
            win.focus();
        }
    }

    /**
     *
     * @param formGroup For which all the controls will be marked as touched
     */
    static markAllFieldAsTouched(
        formGroup: FormGroup,
        updateValidity: boolean = false
    ) {
        Object.keys(formGroup.controls).forEach((name) => {
            formGroup.get(name).markAllAsTouched();
            if (updateValidity) {
                formGroup.get(name).updateValueAndValidity();
            }
        });
    }

    static dereference(obj: any): any {
        return { ...obj };
    }

    static beautifyJson(json) {
        return JSON.stringify(JSON.parse(json), null, '\t');
    }

    static validateJson(json) {
        try {
            JSON.parse(json);
            return true;
        } catch {
            return false;
        }
    }

    static convertImageToBase64(
        input: FileInput
    ): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    static convertTextToBase64(input: File): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(input);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    static mapToDropdownData(values: string[]): IDropdownData[] {
        return values.map((val) => {
            return { id: val, label: val };
        });
    }
    static mapToObj(inputMap: Map<string, any>) {
        const obj = {};
        inputMap.forEach((value, key) => {
            obj[key] = value;
        });
        return obj;
    }

    static cloneDeep(obj: any) {
        return cloneDeep(obj);
    }

    static syncStartAndEndDate(
        dateFormGroup: FormGroup,
        startControlName: string,
        endControlName: string
    ) {
        const endDate = new Date(
            dateFormGroup.get(endControlName).value
        ).getTime();
        const startDate = new Date(
            dateFormGroup.get(startControlName).value
        ).getTime();

        if (endDate <= startDate) {
            const newEndDate = moment(startDate).add(1, 'day').toDate();
            dateFormGroup.get('endDate').setValue(newEndDate);
        }
    }

    static showErrorMessage(
        notificationService: NotificationsService,
        error,
        defaultMessage?
    ) {
        const message = Helper.extractErrorMessage(error);
        notificationService.showSnackBar(
            message ? message : defaultMessage,
            true
        );
    }

    static extractErrorMessage(error) {
        if (
            error &&
            typeof error === 'object' &&
            error.error &&
            error.error.message
        ) {
            return error.error.message;
        } else if (
            error &&
            typeof error === 'object' &&
            error.error &&
            typeof error.error === 'string'
        ) {
            return error.error;
        } else if (error && typeof error === 'object' && error.message) {
            return error.message;
        } else if (error && typeof error === 'string') {
            return error;
        }
        return null;
    }

    static getSummernoteConfig(configuration: {
        placeholder: string;
        fontNames?: string[];
        hideInsertOperations?: IWysiwygEditorInsertOperation[];
        disableFontChange?: boolean;
    }) {
        // For configuration
        // https://github.com/lula/ngx-summernote

        const insertOperations = [
            IWysiwygEditorInsertOperation.TABLE,
            IWysiwygEditorInsertOperation.PICTURE,
            IWysiwygEditorInsertOperation.LINK,
            IWysiwygEditorInsertOperation.HR,
        ].filter((each) => {
            if (configuration.hideInsertOperations) {
                if (!configuration.hideInsertOperations.includes(each)) {
                    return each;
                }
            } else {
                return each;
            }
        });

        const getToolBar = () => {
            if (configuration.disableFontChange) {
                return [
                    ['misc', ['undo', 'redo']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough']],
                    ['fontsize', ['fontsize', 'color']],
                    ['para', ['style', 'ul', 'ol', 'height']],
                    ['insert', insertOperations],
                ];
            } else {
                return [
                    ['misc', ['undo', 'redo']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough']],
                    ['fontsize', ['fontname', 'fontsize', 'color']],
                    ['para', ['style', 'ul', 'ol', 'height']],
                    ['insert', insertOperations],
                ];
            }
        };

        return {
            dialogsInBody: true,
            placeholder: configuration.placeholder
                ? configuration.placeholder
                : '',
            tabsize: 2,
            height: '250px',
            toolbar: getToolBar(),
            fontNames: configuration.fontNames
                ? configuration.fontNames
                : [
                      'Mulish',
                      'Helvetica',
                      'Arial',
                      'Arial Black',
                      'Comic Sans MS',
                      'Courier New',
                      'Roboto',
                      'Times',
                  ],
        };
    }

    /**
     *
     * This function formats the date as input into desired output format in string OR in 'unix' (milliseconds)
     * @param date Input date for formatting, If null OR undefined returns formatted date time as per current moment
     * @param outputFormat Desired output format as string, If 'unix' is passed return moment in unixtimestamp, If nothing is passed return in format: 'YYYY-MM-DD HH:mm:ss'
     * @param inputFormat input format as per input date if required
     * @returns string OR number of formatted type
     *
     * Doc: https://momentjs.com/docs/
     */
    static formatDate(
        date: moment.MomentInput,
        outputFormat?: 'unix' | 'utc' | string,
        inputFormat?: string
    ): string | number {
        let momentInstance: moment.Moment;
        if (inputFormat && date) {
            momentInstance = moment(date, inputFormat);
        } else if (date) {
            momentInstance = moment(date);
        } else {
            momentInstance = moment();
        }
        if (outputFormat === 'unix') {
            return momentInstance.valueOf();
        } else if (outputFormat === 'utc') {
            return momentInstance.utc().toISOString();
        } else if (outputFormat) {
            return momentInstance.format(outputFormat);
        } else {
            return momentInstance.format('YYYY-MM-DD HH:mm:ss');
        }
    }

    static generateFormInput(
        filterIds,
        httpService: HttpService,
        filtersCacheService: FilterCacheService,
        customFormInput?,
        removeRequiredValidation?: boolean,
        fieldValueMap?: Map<string, any>,
        filterService?: FiltersService,
        cacheFilterInfo?: boolean,
        filterStoreKey?: FilterStoreKey,
        userCacheService?: UserDataCacheService,
        configCache?: ConfigCacheService
    ) {
        const filtersObs = this.hitParallelFilterApis(
            filterIds,
            httpService,
            cacheFilterInfo
        );

        const filtersFormGenInput: BehaviorSubject<IFormGeneratorInput> =
            new BehaviorSubject<IFormGeneratorInput>(null);
        filtersObs.subscribe((filtersInfo) => {
            const sortedFiltersInfo: Map<string | Symbol, any> = new Map();

            const sortedFilters = this.sortFilterIds(
                filtersCacheService,
                filterIds
            );
            sortedFilters.forEach((id) => {
                if (filtersInfo.get(id)) {
                    sortedFiltersInfo.set(id, filtersInfo.get(id));
                }
            });
            let fields = [];
            for (const [key, value] of sortedFiltersInfo.entries()) {
                const filtersDefaultValue = filtersCacheService.getFiltersInfo(
                    userCacheService.emailId,
                    configCache.viewId,
                    filterStoreKey
                );
                const formFields = {
                    isFilter: customFormInput.isFilter ?? false,
                    id: key ?? '',
                    label: value.label,
                    placeholder: value.placeholder,
                    name: value.selector,
                    fieldType: value.type,
                    groupBy: value.groupBy ? value.groupBy : null,
                    required: removeRequiredValidation ? false : true,
                    showKey: customFormInput?.showKey
                        ? customFormInput.showKey
                        : null,
                    responseValueKey: customFormInput?.responseValueKey
                        ? customFormInput.responseValueKey
                        : null,
                    apiInfo: value.apiInfo,
                    value:
                        fieldValueMap && fieldValueMap.has(value.selector)
                            ? fieldValueMap.get(value.selector)
                            : filtersDefaultValue &&
                              filtersDefaultValue[key as string] &&
                              filtersDefaultValue[key as string].value
                            ? filtersDefaultValue[key as string].value
                            : value.selectedValue ?? null,
                    appearance: customFormInput.appearance
                        ? customFormInput.appearance
                        : value.appearance
                        ? value.appearance
                        : 'outline',
                    showLabel: customFormInput.showLabel
                        ? customFormInput.showLabel
                        : false,
                    listData: value.listData ?? value.dataList ?? null,
                    maxSelectionLimit: value.selectionLimit,
                    validations: removeRequiredValidation
                        ? []
                        : [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: value.label + ' is required',
                              },
                          ],
                };
                fields.push(formFields);

                if (filterService) {
                    filterService.currentPageFilterInfo.set(
                        key as string,
                        value
                    );
                }
            }

            fields = this.cloneDeep(fields);

            filtersFormGenInput.next({
                formId: Symbol(),
                formName: '',
                state: FormState.CREATE,
                submitButton: null,
                fields: fields,
            });
        });
        return filtersFormGenInput;
    }
    static hitParallelFilterApis(
        filterIds,
        httpService: HttpService,
        isCaching?: boolean
    ) {
        const filtersResponse: Subject<Map<Symbol | string, any>> =
            new Subject();
        const parallelApis: IHitApi[] = [];
        filterIds.forEach((filterId) => {
            const apiConfig: IHitApi = {
                function: null,
                url: `${ApiUrls.FILTER_INFO_API_PATH}${filterId}`,
                intactUrl: `${ApiUrls.FILTER_INFO_API_PATH}{filterId}`,
                input: {},
                requestType: RequestType.GET,
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                    defaultHeaders: false,
                    sendOnlyResponse: true,
                    isCached: isCaching ? true : false,
                    extraData: { filterId },
                },
                uniqueIdentity: `${filterId}`,
            };
            parallelApis.push(apiConfig);
        });
        httpService
            .hitParallelApis(parallelApis)
            .subscribe((response: Map<Symbol | string, any>) => {
                filtersResponse.next(response);
            });
        return filtersResponse;
    }
    /**
     * This method will sort the filterIds on basis of Sorted Filters stored in Cache
     */
    static sortFilterIds(filtersCacheService: FilterCacheService, filterIds) {
        let sortedFilters = [];
        if (
            filtersCacheService &&
            filtersCacheService.sortedFilters &&
            filtersCacheService.sortedFilters.length
        ) {
            sortedFilters = filtersCacheService.sortedFilters.filter(
                (filterId) => {
                    if (filterIds.includes(filterId)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
            // Checking if any filterId is not in Sorted Filters list and adding them in the end
            filterIds.forEach((id) => {
                if (!sortedFilters.includes(id)) {
                    sortedFilters.push(id);
                }
            });
        } else {
            sortedFilters = filterIds;
        }
        return sortedFilters;
    }

    static getSizeInBytes(value: string): number {
        return encodeURI(JSON.stringify(value)).split(/%..|./).length - 1;
    }

    static getSizeInKb(value: string): number {
        return Helper.getSizeInBytes(value) / 1000;
    }

    static getSizeInMb(value: string): number {
        return Helper.getSizeInKb(value) / 1000;
    }
    static removeFormField(
        formGenInput: IFormGeneratorInput,
        fieldName: string
    ) {
        const field = formGenInput.fields.find(
            (field) => field.name === fieldName
        );
        if (field) {
            const index = formGenInput.fields.indexOf(field);
            formGenInput.fields.splice(index, 1);
        }
    }

    static replaceAll(string, oldChar, newChar) {
        const regex = new RegExp(oldChar, 'g');
        return string.replace(regex, newChar);
    }

    static getIntegratedItsms(integrations, httpService, ngZone, callback?) {
        const integratedItsms = [];
        integrations.forEach((integration) => {
            let url;
            let intactUrl;
            if (integration === IntegrationName.FRESHSERVICE) {
                intactUrl = ApiUrls.INTEGRATION_STATUS_V2;
                url = ApiUrls.INTEGRATION_STATUS_V2.replace(
                    '{integration-type}',
                    IntegrationName.FRESHSERVICE
                );
            } else if (integration === IntegrationName.SALESFORCE) {
                url = ApiUrls.SALESFORCE_INTEGRATION_STATUS_CHECK;
            } else {
                intactUrl = `${ApiUrls.INTEGRATION_STATUS}{integration}`;
                url = `${ApiUrls.INTEGRATION_STATUS + integration}`;
            }
            const apiArgs: IHitApi = {
                url: url,
                intactUrl: intactUrl,
                requestType: RequestType.GET,
                input: {},
                function: (response) => {
                    if (this.checkIntegrationStatus(response, integration)) {
                        const obj = { key: integration, response: response };
                        integratedItsms.push(obj);
                    }
                },
                errorFunction: (error) => {},
                endFunction: () => {
                    if (callback) {
                        callback(integratedItsms);
                    }
                },
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                },
            };
            new HitApi(apiArgs, httpService, ngZone).hitApi();
        });
    }

    private static checkIntegrationStatus(
        response,
        integration: IntegrationName
    ): boolean {
        if (integration === IntegrationName.FRESHDESK) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.FRESHSERVICE) {
            if (
                response['childIntegrationConfigured'] ||
                response['parentIntegrationConfigured']
            ) {
                return true;
            }
        } else if (integration === IntegrationName.ZENDESK) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.ZOHO) {
            if ('self' in response && response['self']['integration']) {
                return true;
            }
            if ('parent' in response && response['parent']['integration']) {
                return true;
            }
        } else if (integration === IntegrationName.SALESFORCE) {
            if (response['integrated']) {
                return true;
            }
        }
        if (
            integration === IntegrationName.AUTOTASK ||
            integration === IntegrationName.CASDM ||
            integration === IntegrationName.OTRS
        ) {
            if (response['parentIntegration']) {
                return response['parentIntegration'];
            }
            if (response['childIntegration']) {
                return response['childIntegration'];
            }
            if (response['userIntegration']) {
                return response['userIntegration'];
            }
            if (response['ownerIntegration']) {
                return response['ownerIntegration'];
            }
        }
        const integrated =
            'integration' in response ? response['integration'] : false;
        return integrated;
    }

    /**
     * This function will convert backend form gen input to frontend compatible input.
     * @param backendFormGenInput backend form generator input of type IBackendFormGeneratorInput
     * @param rowsData selected rows will come here. Single or multiple selection condition must be handled at component level.
     * @param notificationsService reference of NotificationsService
     * @param httpService reference for HttpService
     * @param changeDetectorRef reference for ChangeDetectorRef
     * @param inputData initial input for form submission
     * @param callback for success api response on form submission
     * @param customInputCallback for adding or editing the value according to formGroup
     * @param sourceFormGroupData source formGroup for form default value population
     * @param finalInputCallback manipulate the final input if there is any update in input just before api hit
     * @returns formGenInput: FormGeneratorInput
     */
    static formGeneratorBtoFParser(
        backendFormGenInput: IBackendFormGeneratorInput,
        rowsData: any[],
        notificationsService: NotificationsService,
        httpService: HttpService,
        changeDetectorRef: ChangeDetectorRef,
        inputData?,
        callback?,
        customInputCallback?,
        sourceFormGroupData?: any,
        finalInputCallback?: Function
    ): IFormGeneratorInput {
        // NOTE: It will eventually grow as cases increase
        const buttonColorTypes = {
            PRIMARY: ButtonColorType.PRIMARY,
            SECONDARY: ButtonColorType.SECONDARY,
            ACCENT: ButtonColorType.PRIMARY,
            WARN: ButtonColorType.WARN,
            SUCCESS: ButtonColorType.SUCCESS,
            INFO: ButtonColorType.INFO,
        };

        let input = {};
        if (inputData) {
            input = inputData;
        }
        const submitFunction = (
            buttonRef: IButtonGeneratorInput,
            formGroup: FormGroup
        ) => {
            Helper.markAllFieldAsTouched(formGroup);
            const apiArgs = Helper.generateHitApiConfig(
                backendFormGenInput.submitButton.apiInfo
            );
            buttonRef.loader = true;
            apiArgs.input = { ...formGroup.value, ...input };
            if (customInputCallback) {
                apiArgs.input = {
                    ...apiArgs.input,
                    ...customInputCallback(formGroup),
                };
            }

            if (finalInputCallback) {
                apiArgs.input = finalInputCallback(apiArgs.input);
            }

            apiArgs.function = (response) => {
                buttonRef.loader = false;
                Helper.handleSuccessResponse(
                    notificationsService,
                    response,
                    backendFormGenInput.submitButton.apiSuccessMessage
                        ? backendFormGenInput.submitButton.apiSuccessMessage
                        : Messages.B_TO_F_FORM_GEN_SUCCESS
                );
                changeDetectorRef.detectChanges();

                if (callback) {
                    callback();
                }
            };
            apiArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    notificationsService,
                    error,
                    'Error performing action'
                );
                changeDetectorRef.detectChanges();
            };
            new HitApi(apiArgs, httpService, httpService.ngZone).hitApi();
        };

        const formGenInput: IFormGeneratorInput = {
            formName: backendFormGenInput.formName,
            state: backendFormGenInput.state,
            formId: Symbol(),
            extraClass: backendFormGenInput.extraClass,
            submitButton: {
                buttonName: backendFormGenInput.submitButton.buttonName,
                buttonType: ButtonType.FLAT,
                buttonColorType: backendFormGenInput.submitButton.buttonType
                    ? buttonColorTypes[
                          backendFormGenInput.submitButton.buttonType
                      ]
                    : ButtonColorType.PRIMARY,
                function: backendFormGenInput.submitButton.apiInfo
                    ? submitFunction
                    : null,
                showLoader: true,
                loader: false,
            },
            fields: [],
            backButton: backendFormGenInput.backButton
                ? {
                      buttonName: backendFormGenInput.backButton.buttonName,
                      function: null,
                      buttonType: ButtonType.FLAT,
                      buttonColorType: backendFormGenInput.backButton.buttonType
                          ? buttonColorTypes[
                                backendFormGenInput.backButton.buttonType
                            ]
                          : ButtonColorType.PRIMARY,
                  }
                : null,
        };
        if (backendFormGenInput.message) {
            formGenInput.formMessage = backendFormGenInput.message;
        }
        if (backendFormGenInput.warningMessage) {
            formGenInput.warningMessage =
                backendFormGenInput.warningMessage['defaultValueKey'];
        }
        const generateDefaultFormField = (backendField): IFormField => {
            const formField: IFormField = {
                name: backendField.name,
                fieldType: backendField.fieldType,
                label: backendField.label,
                placeholder: backendField.placeholder,
                showLabel:
                    backendField.showLabel !== undefined
                        ? backendField.showLabel
                        : false,
                appearance: backendField.appearance
                    ? backendField.appearance
                    : 'outline',
                extraClass: backendField.extraClass,
                required: backendField.required ? backendField.required : true,
                table: backendField['table'] ? backendField['table'] : null,
                showKey: backendField.showKey ? backendField.showKey : 'label',
                getKey: backendField.getKey ? backendField.getKey : 'id',
                customInputCallback: backendField.customInputCallback
                    ? backendField.customInputCallback
                    : null,
                validations: this.generateBtoFValidations(
                    backendField.validations ? backendField.validations : []
                ),
                prefixIcon: (backendField.prefixIcon
                    ? backendField.prefixIcon
                    : null) as any,
                populateFromData: backendField['populateFromData']
                    ? backendField['populateFromData']
                    : null,
            };

            if (backendField.fieldType === FilterType.CONTENT) {
                if (backendField.populateFromFormData && sourceFormGroupData) {
                    formField.populateFromFormData =
                        backendField.populateFromFormData;
                    if (!formField.name) {
                        formField.name = backendField.populateFromFormData;
                    }
                    formField.value =
                        sourceFormGroupData[backendField.populateFromFormData];
                }
                backendField.required = false;

                if (backendField.populateFromData) {
                    if (backendField.fillFromList) {
                        const listData = [];
                        rowsData.forEach((data, index) => {
                            listData.push(
                                Helper.extractDataFromObject(
                                    backendField.populateFromData,
                                    data
                                )
                            );
                        });
                        formField.listData = [...listData];
                    } else {
                        formField.value = Helper.extractDataFromObject(
                            backendField.populateFromData,
                            rowsData[0]
                        );
                    }
                }
            }

            if (
                backendField.fieldType === FilterType.RADIO ||
                backendField.fieldType === FilterType.DROPDOWN_SINGLE ||
                backendField.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                backendField.fieldType === FilterType.DROPDOWN_GROUP_SINGLE ||
                backendField.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
            ) {
                if (backendField.listData) {
                    formField.listData = backendField.listData;
                } else if (backendField.populateFromData) {
                    formField.listData = Helper.extractDataFromObject(
                        backendField.populateFromData,
                        rowsData[0]
                    );
                } else if (backendField.apiInfo) {
                    formField.apiInfo = backendField.apiInfo;
                }

                // Filtering listData based on showDependency validations
                if (formField.listData && formField.listData.length) {
                    formField.listData = formField.listData.filter(
                        (dropdownItem) => {
                            if (dropdownItem) {
                                if (dropdownItem.showDependency) {
                                    return Helper.backendValidatorCheck(
                                        dropdownItem.showDependency,
                                        rowsData
                                    );
                                }
                                return true;
                            }
                        }
                    );
                }
            }
            if (backendField.prefixIcon) {
                backendField.prefixIcon.iconData.type = this.iconTypeMap(
                    backendField.prefixIcon.iconData.type
                );
            }

            // GroupBy Handling
            if (
                backendField.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE ||
                backendField.fieldType === FilterType.DROPDOWN_GROUP_SINGLE
            ) {
                formField.groupBy = 'group';
            }

            // Handling hiddenDependency validations
            if (backendField.hiddenDependency) {
                formField.hiddenDependency = Helper.backendValidatorsConverter(
                    backendField.hiddenDependency
                );
            }
            if (backendField.defaultValue) {
                formField.value = backendField.defaultValue;
            } else {
                // Handling default value
                if (
                    formField.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                    formField.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
                ) {
                    formField.value = [];
                }
            }
            if (backendField.required !== false) {
                // Defaulting field to required
                formField.validations.unshift({
                    errorMessage: 'Field is required',
                    validator: CustomValidators.required,
                });
                formField.required = true;
            } else if (backendField.required === false) {
                formField.required = false;
            }

            if (backendField.responseValueKey) {
                formField.responseValueKey = backendField.responseValueKey;
            }
            return formField;
        };
        if (backendFormGenInput.message) {
            formGenInput.formMessage = backendFormGenInput.message;
        }
        backendFormGenInput.fields.forEach((backendField) => {
            // Fields parsing
            const formField = generateDefaultFormField(backendField);
            if (backendField.fieldType === FilterType.TABLE) {
                if (
                    backendField['table'] &&
                    backendField['table']['rows'] &&
                    backendField['table']['rowsConfig']
                ) {
                    const tableRowData = [];
                    rowsData.forEach(() => {
                        tableRowData.push(
                            Helper.cloneDeep(
                                backendField['table']['rowsConfig']
                            )
                        );
                    });
                    tableRowData.forEach((rowData, index) => {
                        backendField['table']['rows'].forEach((row) => {
                            if (rowData[row]) {
                                rowData[row][
                                    'name'
                                ] = `${rowData[row]['name']}_${index}`;
                                rowData[row] = generateDefaultFormField(
                                    rowData[row]
                                );
                            }

                            if (
                                rowData[row] &&
                                rowData[row]['fieldType'] === FilterType.CONTENT
                            ) {
                                rowData[row]['value'] =
                                    Helper.extractDataFromObject(
                                        rowData[row]['populateFromData'],
                                        rowsData[index]
                                    );
                            }

                            if (
                                rowData[row] &&
                                rowData[row]['fieldType'] ===
                                    FilterType.DROPDOWN_MULTIPLE
                            ) {
                                if (!rowData[row]['listData']) {
                                    rowData[row]['listData'] =
                                        Helper.extractDataFromObject(
                                            rowData[row]['populateFromData'],
                                            rowsData[index]
                                        );
                                }
                            }
                        });
                    });
                    formField.table.tableHeadings = backendField['table'].rows;
                    formField.table.rowfields = tableRowData;
                }
            }

            if (backendField.defaultValueKey) {
                formField.value = Helper.extractDataFromObject(
                    backendField.defaultValueKey,
                    rowsData[0]
                );
            }

            formGenInput.fields.push(formField);
        });
        return formGenInput;
    }

    private static backendValidatorCheck(
        validators: IBackendValidator[],
        rowsData: any[]
    ): boolean {
        // NOTE: Handling control TOTAL_ROWS_SELECTED only
        const validatorCheck = true;

        const totalRowsSelected = rowsData.length;

        validators.forEach((validator) => {
            if (validatorCheck) {
                if (validator.controlName === 'TOTAL_ROWS_SELECTED') {
                    if (!validator.check || validator.check === 'EQUALS') {
                        // Defaulting to EQUALS
                        return validator.value === totalRowsSelected;
                    }
                }
            }
        });

        return validatorCheck;
    }

    private static backendValidatorsConverter(
        validators: IBackendValidator[]
    ): IHiddenDependency[] {
        const modifiedValidators: IHiddenDependency[] = [];
        validators.forEach((validator) => {
            if (
                validator.controlName &&
                validator.value &&
                (!validator.check || validator.check === 'EQUALS')
            ) {
                modifiedValidators.push({
                    controlName: Array.isArray(validator.controlName)
                        ? validator.controlName[0]
                        : validator.controlName,
                    validations: [
                        {
                            validator: CustomValidators.required,
                        },
                        {
                            validator: CustomValidators.controlValueMatch(
                                validator.value
                            ),
                        },
                    ],
                });
            }
        });
        return modifiedValidators;
    }

    public static jsonFileReader(jsonFile, callback) {
        const fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
            const json = JSON.parse(fileLoadedEvent.target.result as string);
            callback(json);
        };
        fileReader.readAsText(jsonFile, 'UTF-8');
    }
    /**
     * @param file We can pass any text file.
     * @param callback To get the file content.
     * @returns This method returns file Content in text format
     */
    public static stringFileReader(file, callback) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const fileData = fileReader.result;
            callback(fileData);
        };
        fileReader.readAsText(file);
    }
    static capitalizeWord(word: String): String {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    }

    static cellStyling(params) {
        if (params && params.value) {
            return {
                backgroundColor: params.value.color.hex,
            };
        }
        return null;
    }

    static getAgGridIcons(): Object {
        return {
            sortAscending: `<div class="svg-default-fill" style = "height: 16px; width: 12px;">${IconLibrary.icon.sort}</div>`,
            sortDescending: `<div class="svg-default-fill" style = "height: 16px; width: 12px;">${IconLibrary.icon.sort}</div>`,
            sortUnSort: `<div class="svg-default-fill" style = "height: 16px; width: 12px;">${IconLibrary.icon.sort}</div>`,
            menu: `<div class="svg-default-fill" style = "height: 16px; width: 14px;">${IconLibrary.icon.filter_menu}</div>`,
        };
    }

    static scrollTo(selector, position, transition) {
        $(selector).animate({ scrollTop: position }, transition);
    }

    static getRemediationDoc(
        apiInfo: IApiInfo,
        rowData: any[],
        httpService: HttpService,
        ngZone: NgZone,
        notificationsService: NotificationsService,
        callback,
        operationLoaders: Map<string, boolean>,
        changeDetectorRef: ChangeDetectorRef,
        buttonRef: IButtonGeneratorInput,
        actionId: string
    ) {
        const args: IHitApi = this.generateHitApiConfig(apiInfo);
        if (args.requestType === RequestType.POST) {
            args.input = { resourceData: rowData };
        } else {
            args.input = {};
        }
        args.function = (response) => {
            if (buttonRef) {
                buttonRef.loader = false;
            } else if (actionId) {
                operationLoaders.set(actionId, false);
            }
            changeDetectorRef.detectChanges();
            callback(response, rowData);
        };
        args.errorFunction = (error) => {
            if (buttonRef) {
                buttonRef.loader = false;
            } else if (actionId) {
                operationLoaders.set(actionId, false);
            }
            Helper.showErrorMessage(notificationsService, error);
        };
        args.endFunction = () => {};
        new HitApi(args, httpService, ngZone).hitApi();
    }

    static performRemediationAction(
        input,
        apiInfo,
        httpService: HttpService,
        ngZone: NgZone,
        notificationService,
        callback?
    ) {
        const args: IHitApi = this.generateHitApiConfig(apiInfo);
        args.input = input;
        args.function = (response) => {
            notificationService.showSnackBar(Messages.B_TO_F_FORM_GEN_SUCCESS);
        };
        args.errorFunction = (error) => {
            this.showErrorMessage(
                notificationService,
                error,
                'Error performing action'
            );
        };
        args.endFunction = () => {
            if (callback) {
                callback();
            }
        };
        new HitApi(args, httpService, ngZone).hitApi();
    }

    static setRowClassRules(widgetRef: Widget, visibleRows?: number) {
        widgetRef.tableRowClassRules = {
            getFullAccessState: (params) => {
                return params.rowIndex >=
                    (visibleRows ? visibleRows : widgetRef.liteViewLimit)
                    ? true
                    : false;
            },
        };
    }
    /**
     * Method converts the IBackendValidator to IValidator.
     */
    static generateBtoFValidations(validators: IBackendValidator[]) {
        const validations: IValidator[] = [];

        if (validators) {
            validators.map((each) => {
                validations.push({
                    errorMessage: each.errorMessage,
                    validator: this.validatorsTypeMap(
                        each.check,
                        each.value ?? each.data,
                        each.controlName
                    ),
                });
            });
        }

        return validations;
    }
    /**
     * Method uses the backend form validation and ag grid params. When user inputs the data into the table cell,
     * same form validation can be used for the ag cell.
     */

    static agGridCellValueValidator(
        params: ValueSetterParams,
        formInput: IBackendFormGeneratorInput,
        notificationsService: NotificationsService
    ): boolean {
        const name = params.colDef.field;
        let formControl: FormControl;
        formInput.fields.map((each) => {
            if (each.name === name) {
                let validation;
                if (each.fieldType === FilterType.NUMBER) {
                    validation =
                        CustomValidators.numberValidator.validatorFunction.bind(
                            this
                        );
                } else {
                    validation = each.validations.map((eachValidation) => {
                        return this.validatorsTypeMap(
                            eachValidation.check
                        ).validatorFunction.bind(this);
                    });
                }

                formControl = new FormControl(params.newValue, validation);
            }
        });

        const group: FormGroup = new FormGroup({ controlName: formControl });

        if (group.invalid) {
            this.showErrorMessage(
                notificationsService,
                null,
                `${params.colDef.headerName} is not valid.`
            );
        }

        return group.valid;
    }

    static iconTypeMap(iconType: string): IconType {
        return IconType[iconType];
    }

    /**
     * @param item takes HTML body as string
     * @returns HTML DOM
     */
    static stringToHTML(
        item: string,
        type:
            | 'application/xhtml+xml'
            | 'application/xml'
            | 'image/svg+xml'
            | 'text/html'
            | 'text/xml' = 'text/html'
    ): Document {
        const parser: DOMParser = new DOMParser();
        const document: Document = parser.parseFromString(item, type);
        return document;
    }

    /**
     *
     * @param widgetRef
     * @param cloud we can get dynamic keys by passing cloud params also. This is optional
     * @returns an object which contains dynamic filter keys for filters-: accountId, resourceTag and region based on Cloud.
     */
    static setDynamicCloudFilterKeys(widgetRef: Widget, cloud?: string) {
        const dynamicKeys = {
            regions: '',
            resourceTags: '',
            accountIds: '',
        };

        if (
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.create &&
                widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                    Clouds.AZURE_CSP
                )) ||
            cloud === Clouds.AZURE_CSP ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.cloudIcon &&
                widgetRef.widgetData.widgetInfo.cloudIcon ===
                    CloudLabel.AZURE_CSP) ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.list &&
                widgetRef.widgetData.widgetInfo.list.apiRouteSuffix.includes(
                    Clouds.AZURE_CSP
                ))
        ) {
            dynamicKeys.regions = 'regionsAzureCsp';
            dynamicKeys.resourceTags = 'resourceTagsAzureCsp';
            dynamicKeys.accountIds = 'accountIdsAzureCsp';
        } else if (
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.create &&
                widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                    Clouds.AZURE_EA
                )) ||
            cloud === Clouds.AZURE_EA ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.cloudIcon &&
                widgetRef.widgetData.widgetInfo.cloudIcon ===
                    CloudLabel.AZURE_EA) ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.list &&
                widgetRef.widgetData.widgetInfo.list.apiRouteSuffix.includes(
                    Clouds.AZURE_EA
                ))
        ) {
            dynamicKeys.regions = 'regionsAzureEa';
            dynamicKeys.resourceTags = 'resourceTagsAzureEa';
            dynamicKeys.accountIds = 'accountIdsAzureEa';
        } else if (
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.create &&
                widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                    Clouds.AZURE_PLAN
                )) ||
            cloud === Clouds.AZURE_PLAN ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.cloudIcon &&
                widgetRef.widgetData.widgetInfo.cloudIcon ===
                    CloudLabel.AZURE_PLAN) ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.list &&
                widgetRef.widgetData.widgetInfo.list.apiRouteSuffix.includes(
                    Clouds.AZURE_PLAN
                ))
        ) {
            dynamicKeys.regions = 'regionsAzurePlan';
            dynamicKeys.resourceTags = 'resourceTagsAzurePlan';
            dynamicKeys.accountIds = 'accountIdsAzurePlan';
        } else if (
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.create &&
                widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                    Clouds.AZURE_MCA
                )) ||
            cloud === Clouds.AZURE_MCA ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.cloudIcon &&
                widgetRef.widgetData.widgetInfo.cloudIcon ===
                    CloudLabel.AZURE_MCA) ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.list &&
                widgetRef.widgetData.widgetInfo.list.apiRouteSuffix.includes(
                    Clouds.AZURE_MCA
                ))
        ) {
            dynamicKeys.regions = 'regionsAzureMca';
            dynamicKeys.resourceTags = 'resourceTagsAzureMca';
            dynamicKeys.accountIds = 'accountIdsAzureMca';
        } else if (
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.create &&
                widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                    Clouds.GCP
                )) ||
            cloud === Clouds.GCP ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.cloudIcon &&
                widgetRef.widgetData.widgetInfo.cloudIcon === CloudLabel.GCP) ||
            (widgetRef &&
                widgetRef.widgetData.widgetInfo.list &&
                widgetRef.widgetData.widgetInfo.list.apiRouteSuffix.includes(
                    Clouds.GCP
                ))
        ) {
            dynamicKeys.regions = 'gcpRegions';
            dynamicKeys.accountIds = 'projectId';
            dynamicKeys.resourceTags = 'resourceTags';
        } else {
            dynamicKeys.regions = 'regions';
            dynamicKeys.resourceTags = 'resourceTags';
            dynamicKeys.accountIds = 'accountIds';
        }

        return dynamicKeys;
    }

    static checkImageSrcExistance(
        src: string,
        exist: Function,
        notFound?: Function
    ) {
        const image = new Image();
        image.onload = () => {
            exist();
        };
        image.onerror = () => {
            if (notFound) {
                notFound();
            }
        };
        image.src = src;
    }

    /**
     * @static
     * // This method will clear the pseudoConsole chache if any that was generated due to preview mode through whitelabeling
     */

    static clearPseudoConsoleCache() {
        Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('CONSOLE_PREVIEW')) {
                localStorage.removeItem(key);
            }
        });
        Object.keys(sessionStorage).forEach((key) => {
            if (key.startsWith('CONSOLE_PREVIEW')) {
                sessionStorage.removeItem(key);
            }
        });
    }

    /**
     * This function add opacity to the existing color
     * @param color color in which opacity is to be added
     * @param opacity opacity to be added 0 <= opacity <= 100
     * @returns return the respective color with opacity.
     */
    static addOpacityToColor(color: string, opacity: number) {
        if (opacity >= 0 && opacity <= 100) {
            opacity /= 100;
            if (color.includes('hsl(')) {
                return `hsla(${color.split('(')[1].split(')')[0]}, ${opacity})`;
            }
            if (color.includes('rgb(')) {
                return `rgba(${color.split('(')[1].split(')')[0]}, ${opacity})`;
            }
            if (color.includes('#')) {
                const opacityInHex =
                    color.length === 4
                        ? Math.floor(opacity * 15).toString(16)
                        : color.length === 7
                        ? Math.floor(opacity * 255).toString(16)
                        : '';
                return `${color}${opacityInHex}`;
            }
        }
        return color;
    }

    /**
     * This function sets the favicon to as per the image source
     * and returns a functions for resetting the function
     * @param src source of image for favicon switching
     * @returns Functions for resetting the favicon
     */
    static switchFaviconTo(src) {
        const favIcon = document.querySelector('#websiteFavIcon');
        if (favIcon) {
            const originalFavicon = favIcon.getAttribute('href');
            favIcon.setAttribute('href', src);
            return () => {
                favIcon.setAttribute('href', originalFavicon);
            };
        }
        return null;
    }

    static removeDuplicateObjetsFromArray(arr: any[]) {
        return [...new Set(arr.map((data) => JSON.stringify(data)))].map(
            (data) => JSON.parse(data)
        );
    }

    /**
     * @param initialModalInput initial minimum modalData required, if want to pass custom modalData you may pass null here
     * @param rawMultiStepFormInput raw input for multi step modal generation
     * @param stepEntryCallback called while entering current step: return formGenInput
     * @param stepExitCallback called just before leaving current step to move forward: return boolean
     * @param stepBackCallback called just before leaving current step to move backward: return boolean
     * @param preserveCurrentStepData callback to preserve the data of the current step: return boolean as per the stepNumber passed as parameter in callback
     * @param customModalData custome modal data to be used, it will take precedence over initialModalInput
     * @returns IModalData object
     */
    static generateMultiStepFormGenModalData(
        initialModalInput: {
            modalName: string;
            sourceId: Symbol;
            modalIcon?: IIcon;
            modalType?: ModalType;
            hideSteps?: boolean;
            modalId?: Symbol;
            modalHeightVh?: number;
        },
        rawMultiStepFormInput: any,
        stepEntryCallback: (
            stepNumber: number,
            backendFormGenInput: any,
            previousStepData: {
                formGenInput: IFormGeneratorInput;
                formGroupRef: FormGroup;
            },
            currentStepData: {
                formGenInput: IFormGeneratorInput;
                formGroupRef: FormGroup;
            }
        ) => IFormGeneratorInput,
        stepExitCallback?: (stepNumber: number, formGroupRef: any) => boolean,
        stepBackCallback?: (stepNumber: number, formGroupRef: any) => boolean,
        preserveCurrentStepData?: (stepNumber?: number) => boolean,
        templateCallback?: (stepNumber?: number) => TemplateRef<any> | any,
        customModalData?: IModalData,
        valuesChange?: Function
    ): IModalData {
        let modalData: IModalData = null;
        if (initialModalInput) {
            modalData = {
                modalName: initialModalInput.modalName,
                sourceId: initialModalInput.sourceId,
                modalIcon: initialModalInput.modalIcon
                    ? initialModalInput.modalIcon
                    : null,
                modalType: initialModalInput.modalType
                    ? initialModalInput.modalType
                    : ModalType.MIDDLE,
                hideSteps:
                    initialModalInput.hideSteps !== undefined
                        ? initialModalInput.hideSteps
                        : true,
                modalSteps: [],
                modalWidthVw: 45,
                modalHeightVh: initialModalInput.modalHeightVh ?? 60,
            };
            if (initialModalInput.modalId) {
                modalData.modalId = initialModalInput.modalId;
            }
        }
        if (customModalData) {
            modalData = customModalData;
        }
        if (modalData && rawMultiStepFormInput) {
            const jsonFormNameKey = 'formName';
            const jsonStepsKey = 'steps';
            const rawformName = rawMultiStepFormInput[jsonFormNameKey];
            const populateModalStep = (
                backendFormGenInput,
                stepName,
                stepNumber,
                stepCount
            ) => {
                modalData.modalSteps.push({
                    stepName: stepName,
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: {
                                multiStepForm: true,
                                backendFormGenInput: backendFormGenInput,
                                stepNumber: stepNumber,
                                isFirst: stepNumber === 1,
                                isLast: stepNumber === stepCount,
                                onStepEntry: stepEntryCallback,
                                onStepExit: stepExitCallback,
                                onStepBack: stepBackCallback,
                                preserveStepData: preserveCurrentStepData,
                                templateCallback: templateCallback,
                                valuesChange: valuesChange,
                            },
                        },
                    },
                });
            };
            if (
                rawMultiStepFormInput[jsonStepsKey] &&
                rawMultiStepFormInput[jsonStepsKey].length
            ) {
                rawMultiStepFormInput[jsonStepsKey].forEach(
                    (stepKey, index, steps) => {
                        if (rawMultiStepFormInput[stepKey]) {
                            if (
                                !rawMultiStepFormInput[stepKey][jsonFormNameKey]
                            ) {
                                rawMultiStepFormInput[stepKey][
                                    jsonFormNameKey
                                ] = rawformName;
                            }
                            populateModalStep(
                                rawMultiStepFormInput[stepKey],
                                stepKey,
                                index + 1,
                                steps.length
                            );
                        }
                    }
                );
            }
        }
        return modalData;
    }

    /**
     * @param targetString String to calculate the row height
     * @param characterLimit limit of characters after which new line is taken
     * @param lineHeight height for each line
     * @param extraSpace extra space to be taken excluding the all line height
     * @param defaultHeight default height to be used if targetString not found
     * @returns rowHeight
     */
    static calcRowHeight(
        targetString: string,
        characterLimit: number,
        lineHeight?: number,
        extraSpace?: number,
        defaultHeight?: number
    ): number {
        if (!lineHeight) {
            lineHeight = 30;
        }
        if (!extraSpace) {
            extraSpace = 10;
        }
        if (!defaultHeight) {
            defaultHeight = 40;
        }
        if (targetString && targetString.length) {
            return (
                lineHeight *
                    (Math.floor(targetString.length / characterLimit) +
                        (targetString.length % characterLimit === 0 ? 0 : 1)) +
                extraSpace
            );
        }
        return defaultHeight;
    }

    /**
     * This method renames/replaces a specific key in an object with new key provided while maintainig the order of keys.
     * @param oldObject object in which key is to be replaced
     * @param oldKey key which is to be replaced
     * @param newKey new key which will replace old key
     * @returns newObject that contains all data of object provided with key replaced and order maintained
     */
    static renameKey(oldObject, oldKey, newKey) {
        let newObject = {};

        Object.keys(oldObject).forEach((key) => {
            if (key === oldKey) {
                const newPair = { [newKey]: oldObject[oldKey] };

                newObject = { ...newObject, ...newPair };
            } else {
                newObject = { ...newObject, [key]: oldObject[key] };
            }
        });

        return newObject;
    }

    /**
     *
     * @param ls1 list of type string or number
     * @param ls2 list of type string or number
     * @returns true if lists are distinct
     * @example [1, 2, 3] will be not distinct from [2, 3, 1]
     * @example [1, 2, 4] will be distinct from [2, 1, 3]
     *
     * @example NOTE: Returns false also for [2, 3, 3] and [2, 2, 3]
     */
    static areDistinctLists(
        ls1: (string | number)[],
        ls2: (string | number)[]
    ): boolean {
        if (ls1.length === ls2.length) {
            let result = false;
            for (let i = 0; i < ls1.length; i++) {
                if (!ls2.includes(ls1[i])) {
                    result = true;
                    break;
                }
            }
            return result;
        }
        return true;
    }

    /**
     * Function Returns width of a column based on the column name
     * Function may change to be more precise in the future
     */
    static getColumnMinWidth(columnName: string) {
        const spaceCount = columnName.split(' ').length - 1;
        const characterCount = columnName.length - spaceCount;
        return spaceCount * 8 + characterCount * 12 + 85;
    }

    /**
     * This function computes if the agGrid's sizeColumnToFit Function should run on resize or not?
     * It prevents the unusual white spacing b/w the columns
     * @param agGrid Referance to Aggrid api from table and only table generator
     * @param tableId Table id provided from table generators
     */
    static handleTableResize(agGrid, tableId) {
        const columnState = agGrid.columnApi.getColumnState();
        const tableDOMRef: any = document
            .getElementById(tableId)
            ?.querySelector('[ref="rootWrapperBody"]');
        const totalWidth = tableDOMRef?.offsetWidth;
        let actualWidth = 0;
        columnState.forEach((each) => {
            if (!each.hide) {
                actualWidth = actualWidth + each.width;
            }
        });
        if (actualWidth <= totalWidth) {
            agGrid.api.sizeColumnsToFit();
        }
        // Header logic
        const lengthMap: Map<Number, Number> = new Map();
        tableDOMRef
            ?.querySelector('[ref="headerRoot"]')
            .querySelectorAll('[ref="eText"]')
            .forEach((e: any) => {
                if (e.offsetWidth < e.scrollWidth) {
                    lengthMap.set(
                        Number(e.getAttribute('aria-colindex')),
                        e.scrollWidth + 120
                    );
                }
            });
        if (lengthMap.size) {
            for (const [key, value] of lengthMap.entries()) {
                agGrid.columnApi.setColumnWidth(
                    columnState[Number(key) - 1].colId,
                    Number(value),
                    true
                );
            }
        }
    }
    /* In this function you pass an array, and will return boolean, if the array has duplicates values.
     * @param array Array that needs to be checked.
     * @returns boolean.
     */
    static arrayHasDuplicates(array) {
        return new Set(array).size !== array.length;
    }

    static convertMarkdownToHtml(markdown) {
        let html: string = new Markdown.Converter().makeHtml(markdown);

        // Modifying anchor tag for security reasons.

        html = html.replace(
            /<a /g,
            '<a rel="noopener noreferrer" target="_blank" '
        );

        return html;
    }

    static convertS3ToDescription(content) {
        return {
            baseDescription: [
                {
                    value: content,
                    type: 'HTML',
                },
            ],
        };
    }

    /**
     * Use this function to sort your array, in the given order, with respect to the objectKeyName, in the array.
     * If objectKeyName is not provided, then, array will be sorted in default way.
     * @param array Array that needs to be sorted.
     * @param order Array of order in which we need to sort.
     * @param objectKeyName Key name that need to be considered, while sorting.
     * @returns Sorted array in the given order.
     */

    static sortArrayWithOrder(
        array: any[],
        order: string[],
        objectKeyName?: string
    ) {
        if (objectKeyName) {
            return array.sort((a, b) => {
                return (
                    order.indexOf(a[objectKeyName]) -
                    order.indexOf(b[objectKeyName])
                );
            });
        } else {
            return array.sort((a, b) => {
                return order.indexOf(a) - order.indexOf(b);
            });
        }
    }

    static handleSuccessResponse(
        notificationsService: NotificationsService,
        response,
        successMessage?: string,
        failureMessage?: string
    ) {
        if (
            response &&
            response.dataMap &&
            response.dataMap.jobIdStatus &&
            response.dataMap.jobIdStatus.length
        ) {
            response.dataMap.jobIdStatus.every((each) => {
                if (each.status === 'FAILURE') {
                    const message = each.message
                        ? each.message
                        : failureMessage;

                    notificationsService.showSnackBar(message, true);

                    return false;
                }

                if (each.status === 'SUCCESS') {
                    const message = each.message
                        ? each.message
                        : successMessage;

                    notificationsService.showSnackBar(message);

                    return false;
                }

                return true;
            });
        } else {
            notificationsService.showSnackBar(successMessage);
        }
    }

    static async loadLazyModules(callback, modulesFor: LazyModuleTypes) {
        if (modulesFor === LazyModuleTypes.CHARTS) {
            callback(
                await import(
                    /* webpackChunkName: "amchart4" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4/core'
                ),
                await import(
                    /* webpackChunkName: "amchart4" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4/charts'
                ),
                await import(
                    /* webpackChunkName: "amchart4_animated" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4/themes/animated'
                )
            );
        } else if (modulesFor === LazyModuleTypes.MAPS) {
            callback(
                await import(
                    /* webpackChunkName: "amchart4" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4/core'
                ),
                await import(
                    /* webpackChunkName: "amchart4" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4/maps'
                ),
                await import(
                    /* webpackChunkName: "amchart4_worldLow" */
                    /* webpackMode: "lazy" */
                    '@amcharts/amcharts4-geodata/worldLow'
                )
            );
        }
    }

    static downloadReport(
        presignedUrl: any,
        reportType: string,
        reportName: string,
        httpService: HttpService,
        ngZone: NgZone
    ) {
        const handleReport = (reportUrl) => {
            if (reportType.toLowerCase().includes('pdf')) {
                new HitApi(
                    {
                        url: reportUrl,
                        input: {},
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED,
                            ignoreBaseUrl: true,
                            downloadable: true,
                            defaultHeaders: {
                                Accept: ContentType.PDF,
                            },
                        },
                        requestType: RequestType.GET,
                        uniqueIdentity: Symbol(),
                        function: (response) => {
                            Helper.saveAsBlob(
                                response,
                                ContentType.PDF,
                                reportName
                            );
                        },
                    },
                    httpService,
                    ngZone
                ).hitApi();
            } else {
                Helper.navigateTo(reportUrl, 'NEW_PAGE');
            }
        };
        if (presignedUrl instanceof Object) {
            presignedUrl.forEach((reportUrl) => {
                handleReport(reportUrl);
            });
        } else {
            handleReport(presignedUrl);
        }
    }

    /**
     * @param {string} val a string value containing comma seperated number like this : 12,24,532
     * @returns {number}
     * @memberof Helper
     */
    static parseCommaSeperatedStringToNumber(val: string): number | string {
        let parsedValue;
        if (typeof val === 'string') {
            if (val.includes(',')) {
                parsedValue = Number(val.split(',').join(''));
            } else {
                parsedValue = Number(val);
            }
        }
        if (parsedValue !== undefined) {
            return parsedValue;
        } else {
            return val;
        }
    }

    /**
     * This function change button UI(buttontype to flat) when clicked
     * @param buttonData is an object of key buttonName and it's value as index(same index as of multibuttons) of buttonName
     * @param multiButtons is list of buttons of IbuttonGeneratorInput
     * @param buttonName is the button you are targetting
     * @param callback is the function invoked when button is already clicked
     * @returns true if button was not clicked already and false if button was clicked already.
     */
    static changeButtonType(
        buttonData: Object,
        multiButtons: IButtonGeneratorInput[],
        buttonName: string,
        callback: Function
    ): boolean {
        const buttonIndex = buttonData[buttonName];
        const button = Helper.cloneDeep(multiButtons[buttonIndex]);

        if (multiButtons[buttonIndex].buttonType === ButtonType.FLAT) {
            button.buttonType = ButtonType.STROKED;
            multiButtons[buttonIndex] = button;
            callback();
            return false;
        }

        button.buttonType = ButtonType.FLAT;
        multiButtons[buttonIndex] = button;

        multiButtons.forEach((button, index) => {
            const btn = Helper.cloneDeep(button);
            if (index !== buttonIndex) {
                btn.buttonType = ButtonType.STROKED;
                multiButtons[index] = btn;
            }
        });

        return true;
    }

    /**
     * This method will return custom validator based on the IBackendValidator check enum property.
     * @param {validatorName} Name of the validator (IBackendValidator check enum property).
     * @param {value} Pass any custom value to a validator.
     * @returns Custom validator.
     */

    private static validatorsTypeMap(validatorName, value?, controlName?) {
        switch (validatorName) {
            case 'CIDR':
                return CustomValidators.CIDR;

            case 'MIN_VALUE':
                return CustomValidators.minValue(value);

            case 'MAX_VALUE':
                return CustomValidators.maxValue(value);

            case 'MULTI_CIDR':
                return CustomValidators.multiCIDRValidator;

            case 'EQUALS':
                if (Array.isArray(controlName)) {
                    return CustomValidators.equal(controlName);
                } else {
                    return CustomValidators.equal([controlName]);
                }

            case 'GREATER_THAN_CONTROLS':
                if (Array.isArray(controlName)) {
                    return CustomValidators.greaterThanControl(controlName);
                } else {
                    return CustomValidators.greaterThanControl([controlName]);
                }

            case 'LESSER_THAN_CONTROLS':
                if (Array.isArray(controlName)) {
                    return CustomValidators.lesserThanControl(controlName);
                } else {
                    return CustomValidators.lesserThanControl([controlName]);
                }
            case 'IP_RANGE':
                return CustomValidators.ipRange(
                    controlName,
                    value.range,
                    value.startIP
                );
            case 'NO_SPACE':
                return CustomValidators.removeSpaceFromString;

            default:
                return null;
        }
    }

    /**
     * This function is used here to provide the appearance to all the form fields in our console
     * @param field we will get the fields data and on the basis of that this function provide appearance accordingly
     */

    static getFieldsAppearance(field: IFormField, isFilter?: boolean) {
        if (
            field.fieldType === FilterType.TEXT ||
            field.fieldType === FilterType.SHORT_TEXT
        ) {
            if (!field.appearance && field.hideFloatLabel) {
                return TextFieldAppearance.TYPE_2;
            } else if (
                field.appearance &&
                field.showLabel &&
                field.appearance === 'legacy'
            ) {
                return TextFieldAppearance.TYPE_3;
            } else if (
                field.appearance &&
                field.appearance === 'legacy' &&
                !field.showLabel
            ) {
                return TextFieldAppearance.TYPE_4;
            } else if (isFilter) {
                return TextFieldAppearance.TYPE_5;
            } else {
                return TextFieldAppearance.TYPE_1;
            }
        } else if (field.fieldType === FilterType.TEXTAREA) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return TextAreaField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return TextAreaField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return TextAreaField.TYPE_3;
            else return TextAreaField.TYPE_4;
        } else if (field.fieldType === FilterType.NUMBER) {
            if (isFilter && field.showArrow) return NumberField.TYPE_6;
            else if (isFilter && !field.showArrow) return NumberField.TYPE_5;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return NumberField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return NumberField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return NumberField.TYPE_3;
            else return NumberField.TYPE_4;
        } else if (field.fieldType === FilterType.DROPDOWN_SINGLE) {
            if (!field.appearance && field.hideFloatLabel) {
                return DropdownSingleFieldAppearance.TYPE_2;
            } else if (field.appearance && field.appearance === 'legacy') {
                return DropdownSingleFieldAppearance.TYPE_3;
            } else if (
                field.appearance &&
                field.appearance === 'legacy' &&
                !field.showLabel
            ) {
                return DropdownSingleFieldAppearance.TYPE_4;
            } else if (isFilter) {
                return DropdownSingleFieldAppearance.TYPE_5;
            } else {
                return DropdownSingleFieldAppearance.TYPE_1;
            }
        } else if (field.fieldType === FilterType.DROPDOWN_MULTIPLE) {
            if (!field.appearance && field.hideFloatLabel) {
                return DropdownMultipleFieldAppearance.TYPE_2;
            } else if (
                field.appearance &&
                field.showLabel &&
                field.appearance === 'legacy'
            ) {
                return DropdownMultipleFieldAppearance.TYPE_3;
            } else if (
                field.appearance &&
                field.appearance === 'legacy' &&
                !field.showLabel
            ) {
                return DropdownMultipleFieldAppearance.TYPE_4;
            } else if (isFilter) {
                return DropdownMultipleFieldAppearance.TYPE_5;
            } else {
                return DropdownMultipleFieldAppearance.TYPE_1;
            }
        } else if (field.fieldType === FilterType.DROPDOWN_GROUP_SINGLE) {
            if (isFilter) {
                return DropdownGroupSingleFieldAppearance.TYPE_5;
            } else if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return DropdownGroupSingleFieldAppearance.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return DropdownGroupSingleFieldAppearance.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return DropdownGroupSingleFieldAppearance.TYPE_3;
            else return DropdownGroupSingleFieldAppearance.TYPE_4;
        } else if (field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE) {
            if (isFilter) {
                return DropdownGroupMultipleFieldAppearance.TYPE_5;
            } else if (field.appearance && field.appearance === 'legacy') {
                return DropdownGroupMultipleFieldAppearance.TYPE_3;
            } else {
                return DropdownGroupMultipleFieldAppearance.TYPE_1;
            }
        } else if (field.fieldType === FilterType.DROPDOWN_LIST_OBJECT) {
            if (
                field.appearance &&
                field.appearance === 'legacy' &&
                !field.showLabel
            ) {
                return DropdownListObjectFieldAppearance.TYPE_4;
            } else {
                return DropdownListObjectFieldAppearance.TYPE_1;
            }
        } else if (field.fieldType === FilterType.CHECKBOX) {
            if (isFilter) return CheckboxField.INDREKA_CHECKBOX;
            else if (field.showLabel) return CheckboxField.TYPE_3;
            else return CheckboxField.TYPE_4;
        } else if (field.fieldType === FilterType.WYSIWYG_EDITOR) {
            const existingAppearance = Object.values(WysiwygEditorField);
            if (
                existingAppearance.includes(
                    field.appearance as WysiwygEditorField
                )
            ) {
                return field.appearance;
            } else {
                return WysiwygEditorField.TYPE_1;
            }
        } else if (field.fieldType === FilterType.CONTENT) {
            return ContentField.TYPE_3;
        } else if (field.fieldType === FilterType.DATE) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return DateField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return DateField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return DateField.TYPE_3;
            else return DateField.TYPE_4;
        } else if (field.fieldType === FilterType.DATE_TIME) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return DateTimeField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return DateTimeField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return DateTimeField.TYPE_3;
            else return DateTimeField.TYPE_4;
        } else if (field.fieldType === FilterType.TIME) {
            if (field.isFilter) {
                return TimeField.TYPE_5;
            } else if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return TimeField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return TimeField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return TimeField.TYPE_3;
            else return TimeField.TYPE_4;
        } else if (field.fieldType === FilterType.COLOR) {
            return ColorField.TYPE_6;
        } else if (field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT) {
            if (isFilter) {
                return DropdownGroupMultipleFieldAppearance.TYPE_5;
            } else if (field.appearance && field.appearance === 'legacy') {
                return DropdownGroupMultipleFieldAppearance.TYPE_3;
            } else {
                return DropdownGroupMultipleFieldAppearance.TYPE_1;
            }
        }
        else if (field.fieldType === FilterType.EMAIL) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return EmailField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return EmailField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return EmailField.TYPE_3;
            else return EmailField.TYPE_4;
        } else if (field.fieldType === FilterType.FILE) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return FileField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return FileField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return FileField.TYPE_3;
            else return FileField.TYPE_4;
        } else if (field.fieldType === FilterType.PASSWORD) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return PasswordField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return PasswordField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return PasswordField.TYPE_3;
            else return PasswordField.TYPE_4;
        } else if (field.fieldType === FilterType.RADIO) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return RadioField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return RadioField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return RadioField.TYPE_3;
            else return RadioField.TYPE_4;
        } else if (field.fieldType === FilterType.TOGGLE) {
            if (isFilter) return ToggleField.TYPE_5;
            else if (field.showLabel) return ToggleField.TYPE_3;
            return ToggleField.TYPE_4;
        } else if (field.fieldType === FilterType.URL) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return URLField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return URLField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return URLField.TYPE_3;
            else return URLField.TYPE_4;
        } else if (field.fieldType === FilterType.MATCHIPLIST) {
            if (
                (!field.appearance || field.appearance === 'outline') &&
                !field.hideFloatLabel
            )
                return MatChipField.TYPE_1;
            else if (
                (!field.appearance || field.appearance === 'outline') &&
                field.hideFloatLabel
            )
                return MatChipField.TYPE_2;
            else if (field.appearance === 'legacy' && field.showLabel)
                return MatChipField.TYPE_3;
            else return MatChipField.TYPE_4;
        } else if (field.fieldType === FilterType.IMAGE_UPLOAD)
            return ImageUploadField.TYPE_1;
        else if (
            field.fieldType === FilterType.DATE_RANGE ||
            field.fieldType === FilterType.DATE_RANGE_TODAY ||
            field.fieldType === FilterType.WIDGET_DATE_RANGE
        ) {
            if (field.isFilter) {
                return DateRangeFieldAppearance.TYPE_5;
            }
        } else if (field.fieldType === FilterType.DOUBLE_RANGE) {
            if (field.isFilter) {
                return DoubleRangeAppearance.TYPE_5;
            }
        }
    }

    static cleanHTML(html: string): string {
        return html;
    }

    /**
     * This function is used here to trim HTML element as it removes empty spaces before and after HTML element
     */
    static trimHTML(element: Document): HTMLElement {
        const parentElement = element.body;

        // Removing initial empty spaces
        const initialNodes: NodeList = parentElement.childNodes;
        for (let i = 0; i < initialNodes.length; i++) {
            const currentNode = initialNodes[i] as HTMLElement;
            if (
                !currentNode.innerText &&
                currentNode.nodeName !== '#text' &&
                currentNode.childNodes.length === 0
            ) {
                parentElement.removeChild(currentNode);
                i--;
            } else {
                break;
            }
        }
        // Removing final empty spaces
        const finalNodes: NodeList = parentElement.childNodes;
        for (let i = finalNodes.length - 1; i >= 0; i--) {
            const currentNode = finalNodes[i] as HTMLElement;
            if (
                !currentNode.innerText &&
                currentNode.nodeName !== '#text' &&
                currentNode.childNodes.length === 0
            ) {
                parentElement.removeChild(currentNode);
            } else {
                break;
            }
        }
        return parentElement;
    }
    /*
     * This function is used to dynamically import graph modules
     *
     */
    static async loadAm4chartsLibraries() {
        const am4core = await import(
            /* webpackChunkName: "amchart4" */
            /* webpackMode: "lazy" */
            '@amcharts/amcharts4/core'
        );
        const am4charts = await import(
            /* webpackChunkName: "amchart4" */
            /* webpackMode: "lazy" */
            '@amcharts/amcharts4/charts'
        );
        const am4themesAnimated = await import(
            /* webpackChunkName: "amchart4" */
            /* webpackMode: "lazy" */
            '@amcharts/amcharts4/themes/animated'
        );

        const am4maps = await import(
            /* webpackChunkName: "amchart4" */
            /* webpackMode: "lazy" */
            '@amcharts/amcharts4/maps'
        );
        return new Promise((resolve) => {
            resolve({
                am4charts: am4charts,
                am4core: am4core,
                am4maps: am4maps,
                am4themesAnimated: am4themesAnimated.default,
            });
        });
    }

    /**
     *
     * @param cssVar is the name of color variable
     * This function return color code of the variable
     *
     */
    static getCssVarValue(cssVar: string) {
        const documentStyles = getComputedStyle(document.documentElement);
        if (cssVar.startsWith('--')) {
            return documentStyles.getPropertyValue(cssVar);
        } else {
            return documentStyles.getPropertyValue(`--${cssVar}`);
        }
    }
    /**
     * Function Takes in formGroup or formComtrol and Returns the Dirty Values
     * @param form Refrance of formGroup | FormControl
     * @returns controls value that are dirty
     */
    static getDirtyValues(form: any) {
        const dirtyValues = {};
        Object.keys(form.controls).forEach((key) => {
            const currentControl = form.controls[key];
            if (currentControl.dirty) {
                if (currentControl['controls'])
                    dirtyValues[key] = this.getDirtyValues(currentControl);
                else dirtyValues[key] = currentControl.value;
            }
        });

        return dirtyValues;
    }
    /**
     * Function Takes in formGroup or formComtrol and Marks them as Prestine
     * @param form Refrance of formGroup | FormControl
     * @returns void
     */
    static markAsPristine(form: any) {
        Object.keys(form.controls).forEach((key) => {
            const currentControl = form.controls[key];
            if (currentControl.dirty) {
                if (currentControl['controls'])
                    this.markAsPristine(currentControl);
                else currentControl.markAsPristine();
            }
        });
    }

    static cleanURL(url: string): string {
        url = url.replace(/[()]/g, '');
        return url;
    }
}
