import { Pipe, PipeTransform } from '@angular/core';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IconType } from '../../enums/IconType';

@Pipe({
    name: 'generateReportIconData'
})
export class GenerateReportIconDataPipe implements PipeTransform {
    transform(value: string): unknown {
        const obj: IIcon = {
            class: 'assets/report-icon/' + value.toLowerCase() + '.svg',
            text: value,
            type: IconType.IMAGE,
            alt: value
        };
        return obj;
    }
}
