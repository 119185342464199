import { Pipe, PipeTransform } from '@angular/core';
import { WhiteLabelingService } from '../services/white-labeling/white-labeling.service';

@Pipe({
    name: 'iconSideNavBackground'
})
export class IconSideNavBackgroundPipe implements PipeTransform {
    constructor(private whiteLabelingService: WhiteLabelingService) {}
    transform(level) {
        if (level % 2 === 0) {
            if (this.whiteLabelingService.whiteLableData.isCentilytics) {
                return 'bg-cent-color-two';
            } else {
                return 'bg-color-two';
            }
        } else {
            if (this.whiteLabelingService.whiteLableData.isCentilytics) {
                return 'bg-cent-color-one';
            } else {
                return 'bg-color-one';
            }
        }
    }
}
