import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicContentComponent } from 'src/app/core/components/dynamic-content/dynamic-content.component';
import { FiltersComponent } from 'src/app/core/components/filters/filters.component';
import { FooterComponent } from 'src/app/core/components/footer/footer.component';
import { HeaderComponent } from 'src/app/core/components/header/header.component';
import { HomeComponent } from 'src/app/core/components/home/home.component';
import { LoginComponent } from 'src/app/core/components/login/login.component';
import { SideNavigationComponent } from 'src/app/core/components/side-navigation/side-navigation.component';
import { SignupComponent } from 'src/app/shared/components/modal-templates/anaplan/signup-component/signup.component';
import { AssesseeHomeComponent } from 'src/app/shared/components/modal-templates/assessee-view/assessee-home/assessee-home.component';
import { AssessmentOverviewComponent } from 'src/app/shared/components/modal-templates/assessee-view/assessment-overview/assessment-overview.component';
import { GenerateFrameworkComponent } from 'src/app/shared/components/modal-templates/assessee-view/generate-framework/generate-framework.component';
import { WidgetGeneratorComponent } from 'src/app/shared/components/widget-generator/widget-generator.component';
import { WidgetPreviewModalComponent } from 'src/app/shared/components/widget-preview-modal/widget-preview-modal.component';
import { WidgetCatalogComponent } from 'src/app/shared/components/widgets/1.0/widget-catalog/widget-catalog.component';
import { CommonPipesModule } from 'src/app/shared/modules/common-pipes/common-pipes.module';
import { FormModule } from 'src/app/shared/modules/form/form.module';
import { HelperComponentModule } from 'src/app/shared/modules/helper-component/helper-component.module';
import { MaterialModule } from 'src/app/shared/modules/material/material-module.module';
import { ModalTemplatesModule } from 'src/app/shared/modules/modal-templates/modal-templates.module';
import { SharedWidgetsModule } from 'src/app/shared/modules/shared-widgets/shared-widgets.module';
import { UtilsModule } from 'src/app/shared/modules/utils/utils.module';
import { CompareViewInsightsComponent } from '../../../shared/components/compare-view-insights/compare-view-insights.component';
import { LinkWidgetComponent } from '../../../shared/components/link-widget/link-widget.component';
import { BootstrapModule } from '../../../shared/modules/bootstrap/bootstrap.module';
import { ModalComponent } from '../../components/modal/modal.component';
import { TestActionsComponent } from '../../components/test-bed/test-actions/test-actions.component';
import { WidgetTestingComponent } from '../../components/test-bed/widget-testing/widget-testing.component';
import { BreadcrumbComponent } from './../../../shared/components/breadcrumb/breadcrumb.component';
import { GlobalErrorComponent } from './../../components/error-handler/global-error/global-error.component';
import { AssessmentInfoBarComponent } from 'src/app/shared/components/modal-templates/assessee-view/assessment-info-bar/assessment-info-bar.component';

const components = [
    LoginComponent,
    HeaderComponent,
    HomeComponent,
    DynamicContentComponent,
    FiltersComponent,
    FooterComponent,
    SideNavigationComponent,
    ModalComponent,
    LinkWidgetComponent,
    CompareViewInsightsComponent,
    TestActionsComponent,
    WidgetTestingComponent,
    WidgetCatalogComponent,
    WidgetPreviewModalComponent,
    GlobalErrorComponent,
    AssesseeHomeComponent,
    AssessmentOverviewComponent,
    GenerateFrameworkComponent,
    SignupComponent,
    BreadcrumbComponent
];
const modules = [
    SharedWidgetsModule,
    CommonModule,
    FormModule,
    UtilsModule,
    MaterialModule,
    BootstrapModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalTemplatesModule,
    CommonPipesModule,
    HelperComponentModule
];

@NgModule({
    declarations: [...components],
    imports: [...modules],
    exports: [...components],
    entryComponents: [
        ModalComponent,
        WidgetGeneratorComponent,
        WidgetCatalogComponent,
        WidgetPreviewModalComponent
    ]
})
export class LayoutsModule {}
