import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IToastNotificationConfig } from './../../../interfaces/notification/IToastNotificationConfig';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.sass'],
    animations: [
        trigger('toastAnimation', [
            state(
                'show',
                style({
                    right: '0',
                    opacity: '1'
                })
            ),
            state(
                'hide',
                style({
                    opacity: '0.2',
                    right: '-550px'
                })
            ),
            state(
                'void',
                style({
                    opacity: '0.2',
                    right: '-550px'
                })
            ),
            transition('* => *', [animate('1s')]),
            transition('show => hide', [animate('0.5s')])
        ])
    ]
})
export class ToastComponent implements OnInit {
    autoCloseToast: Map<
        symbol,
        {
            startTime: number;
            toastConfig: IToastNotificationConfig;
        }
    > = new Map();
    currentToasts: symbol[] = [];
    SanitizeTypes = SanitizeTypes;
    Infinity = Infinity;

    constructor(public notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.notificationsService.toastSub.subscribe((toasts) => {
            this.currentToasts = toasts.map((toast) => toast.toastId);
            this.autoCloseHandle(toasts);
        });
    }

    handleToastClick(toast: IToastNotificationConfig) {
        if (toast.clickAction) {
            toast.clickAction();
            // this.closeToast(toast);
        }
    }
    closeToast(toast: IToastNotificationConfig) {
        if (toast.closeCallback) {
            toast.closeCallback();
        }
        const toasts = this.notificationsService.toastSub.value.filter(
            (toastItem) => toastItem.toastId !== toast.toastId
        );
        this.notificationsService.toastSub.next(toasts);
    }
    identify(index, item: IToastNotificationConfig) {
        return item.toastId;
    }
    autoCloseHandle(toasts: IToastNotificationConfig[]) {
        toasts.forEach((toast) => {
            if (!this.autoCloseToast.has(toast.toastId)) {
                this.autoCloseToast.set(toast.toastId, {
                    startTime: new Date().getTime(),
                    toastConfig: toast
                });
                if (toast.showTime !== Infinity) {
                    setTimeout(() => {
                        this.closeToast(toast);
                    }, toast.showTime);
                }
            }
        });
    }
}
