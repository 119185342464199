import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';
export class GcpValueValidator extends CustomValidator {
    key = CustomValidatorKey.GCP_VALUE_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const GCP_VALUE_REGEX = /^[^A-Z@*^#$.,:'"!;~`+={}()?/><][a-z0-9_-]*$/;
        return Validators.pattern(GCP_VALUE_REGEX)(control);
    }
}
