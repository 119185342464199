import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.sass']
})
export class DatePickerComponent implements OnInit, OnChanges {
    @Output('change') change = new EventEmitter();
    selectedDate;
    date: Date;
    @Input('data') data;
    @Input('serviceNow') serviceNow;
    @Input('maxDate') maxDate;
    @Input('minDate') minDate;

    constructor() {}

    ngOnInit() {
        this.date = this.data.defaultDate;
        const date: Date = this.date;
        const dateString =
            date.getMonth() +
            1 +
            '/' +
            date.getDate() +
            '/' +
            date.getFullYear();
        setTimeout(() => {
            this.dateUpdated(dateString, true);
        }, 500);
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.maxDate) {
            this.maxDate = new Date(changes.maxDate.currentValue);
        }
        if (changes.minDate) {
            this.minDate = new Date(changes.minDate.currentValue);
        }
    }

    dateUpdated($event, directVal?) {
        // mm/dd/yyyy
        const val = directVal ? $event : $event.targetElement.value;

        if (this.serviceNow) {
            const splittedDate = val.split('/');
            const d1 = new Date();
            const date1 = new Date(
                splittedDate[2],
                splittedDate[0],
                splittedDate[1],
                d1.getHours(),
                d1.getMinutes(),
                d1.getSeconds()
            );
            const onlyDate = this.data['includeTime'];
            let newVal =
                date1.getDate() +
                '/' +
                date1.getMonth() +
                '/' +
                date1.getFullYear();
            if (!onlyDate) {
                newVal +=
                    ' ' +
                    date1.getHours() +
                    ':' +
                    date1.getMinutes() +
                    ':' +
                    date1.getSeconds();
            }
            this.change.emit(newVal);
        }
    }
}
