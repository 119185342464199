import { Pipe, PipeTransform } from '@angular/core';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Helper } from '../classes/Helper';
import { FilterType } from '../enums/FilterType';
import { IDateTimeRangeValue } from '../interfaces/filter/IDateTimeRangeValue';
import { IFilterInfo } from '../interfaces/filter/IFilterInfo';
import { FilterStoreKey } from './../enums/FilterStoreKey';
import { UserDataCacheService } from './../services/user-data-cache/user-data-cache.service';

@Pipe({
    name: 'getFilterSelectedValue'
})
export class GetFilterSelectedValuePipe implements PipeTransform {
    transform(
        filterId: string,
        filterService: FiltersService,
        filterStoreKey: FilterStoreKey,
        filterCacheService: FilterCacheService,
        userCacheService: UserDataCacheService,
        configCache: ConfigCacheService
    ): IDateTimeRangeValue {
        const filtersData = filterCacheService.getFiltersInfo(
            userCacheService.emailId,
            configCache.viewId,
            filterStoreKey
        );

        const filterInfo: IFilterInfo =
            filterService.currentPageFilterInfo.get(filterId);
        let selectedValue;
        const filteredDate = filtersData?.[filterId]?.value;
        if (filtersData && filterInfo.type !== FilterType.DATE_TIME_RANGE) {
            selectedValue = filtersData[filterId]?.value;
        } else if (
            filterInfo.type === FilterType.DATE_TIME_RANGE &&
            filteredDate
        ) {
            selectedValue = {
                startDateTime:
                    filteredDate && filteredDate.startDate
                        ? new Date(+filteredDate.startDate)
                        : null,
                endDateTime:
                    filteredDate && filteredDate.endDate
                        ? new Date(+filteredDate.endDate)
                        : null
            };
        } else if (
            filterService.filtersData &&
            filterService.filtersData.has(filterStoreKey) &&
            filterService.filtersData.get(filterStoreKey).has(filterId) &&
            filterService.filtersData.get(filterStoreKey).get(filterId)
        ) {
            if (
                'value' in
                    filterService.filtersData
                        .get(filterStoreKey)
                        .get(filterId) &&
                filterService.filtersData.get(filterStoreKey).get(filterId)
                    .value !== null &&
                filterService.filtersData.get(filterStoreKey).get(filterId)
                    .value !== undefined &&
                filterService.filtersData.get(filterStoreKey).get(filterId)
                    .value + ''
            ) {
                selectedValue = JSON.parse(
                    JSON.stringify(
                        filterService.filtersData
                            .get(filterStoreKey)
                            .get(filterId).value
                    )
                );
            }
        } else {
            selectedValue = Helper.getFilterDefaultValue(
                filterInfo.type,
                filterInfo.getFullObj
            );
            if (filterInfo.type === FilterType.DATE_TIME_RANGE) {
                selectedValue = {
                    startDateTime:
                        selectedValue && selectedValue.startDate
                            ? selectedValue.startDate
                            : null,
                    endDateTime:
                        selectedValue && selectedValue.endDate
                            ? selectedValue.endDate
                            : null
                };
            }
        }
        return selectedValue;
    }
}
