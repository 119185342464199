import { ActionState } from '../enums/ActionState';
import { ActionVisibility } from '../enums/ActionVisibility';
import { GraphType } from '../enums/GraphType';
import { IconType } from '../enums/IconType';
import { ViewType } from '../enums/ViewType';
import { IAction } from '../interfaces/actions/IAction';
import { IIcon } from '../interfaces/icon-data/IIcon';

export class ActionType {
    message = `Switch to premium`;

    cartIcon: IIcon = {
        type: IconType.MATICON,
        class: 'add_shopping_cart'
    };
    deepDive: IIcon = {
        type: IconType.SVG,
        class: 'widget_expand'
    };

    cardIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'far fa-minus-square',
        text: 'Card'
    };
    toggle: IAction = {
        visibility: ActionVisibility.VISIBLE,
        icon: this.cartIcon,
        state: ActionState.ENABLED,
        message: this.message,
        toggleable: true,
        type: ViewType.GRAPH
    };

    card: IAction = {
        type: ViewType.CARDS,
        toggleable: true,
        icon: this.cardIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };

    attentionIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-circle',
        extraClass: 'red-severity-color'
    };

    tagIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-tag'
    };
    refreshIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-sync-alt dynamic-icon',
        text: 'Refresh'
    };

    tableIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-table dynamic-icon',
        text: 'Table'
    };

    graphIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chart-pie dynamic-icon',
        text: 'Graph'
    };

    legendIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-circle dynamic-icon',
        text: 'Legend'
    };

    lineGraphIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chart-line',
        text: 'Line Graph'
    };

    pieGraphIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chart-pie dynamic-icon',
        text: 'Pie Graph'
    };
    regionalGraphIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chart-pie dynamic-icon',
        text: 'Regional Graph'
    };

    stackedBarGraphIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chart-bar',
        text: 'Stack Bar'
    };

    downgradeIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-arrow-circle-down dynamic-icon',
        text: 'Downgrade'
    };

    upgradeIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-arrow-circle-up dynamic-icon',
        text: 'Upgrade'
    };

    moreInfoIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'far fa-question-circle',
        text: 'More Information'
    };

    refresh: IAction = {
        icon: this.refreshIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    fix: IAction = {
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    compareView: IAction = {
        icon: this.deepDive,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    addToCart: IAction = {
        icon: this.cartIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    download: IAction = {
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    attention: IAction = {
        icon: this.attentionIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    tag: IAction = {
        icon: this.tagIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    graph: IAction = {
        type: ViewType.GRAPH,
        toggleable: true,
        icon: this.graphIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    lineGraph: IAction = {
        type: ViewType.GRAPH,
        graphType: GraphType.LINE,
        toggleable: true,
        icon: this.lineGraphIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    pieGraph: IAction = {
        type: ViewType.GRAPH,
        graphType: GraphType.PIE,
        toggleable: true,
        icon: this.pieGraphIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };

    regionalGraph: IAction = {
        type: ViewType.GRAPH,
        graphType: GraphType.MAP,
        toggleable: true,
        icon: this.regionalGraphIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    table: IAction = {
        type: ViewType.TABLE,
        toggleable: true,
        icon: this.tableIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    moreInfo: IAction = {
        icon: this.moreInfoIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    upgrade: IAction = {
        icon: this.upgradeIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    downgrade: IAction = {
        icon: this.downgradeIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
    legend: IAction = {
        type: ViewType.LEGEND,
        toggleable: true,
        icon: this.legendIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };

    stackedBarGraph: IAction = {
        type: ViewType.STACKED_BAR_GRAPH,
        toggleable: true,
        icon: this.stackedBarGraphIcon,
        message: this.message,
        state: ActionState.ENABLED,
        visibility: ActionVisibility.VISIBLE
    };
}
