import { Component, OnInit } from '@angular/core';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { AssessmentCacheService } from './../../../../services/cache/assessment-cache/assessment-cache.service';
import { GlobalDataService } from './../../../../services/global-data/global-data.service';
import { SessionService } from './../../../../services/session/session.service';
import { UserDataCacheService } from './../../../../services/user-data-cache/user-data-cache.service';
import { EmailConfigModalService } from 'src/app/shared/services/assessment/email-config-service/email-config-modal.service';
import { CreateAssessmentComponent } from './../../../modal-templates/create-assessment/create-assessment.component';
import { EmailConfigurationModalComponent } from '../../../modal-templates/email-configuration-modal/email-configuration-modal.component';

@Component({
    selector: 'app-assessment-listing',
    templateUrl: './assessment-listing.component.html',
    styleUrls: ['./assessment-listing.component.sass']
})
export class AssessmentListingComponent implements OnInit {
    tableInput: ITableGeneratorInput;
    widgetRef: Widget;
    tableData: any = [];
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private viewHttpService: ViewHttpService,
        private sessionService: SessionService,
        private userDataCacheService: UserDataCacheService,
        private globalDataService: GlobalDataService,
        private emailConfigurationModalService: EmailConfigModalService,
        private assessmentCacheService: AssessmentCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableInput = {
            buttons: [
                {
                    buttonName: 'Add Assessment',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.STROKED,
                    function: (buttonRef) => {
                        this.addAssessment();
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Assessment Name',
                    columnKey: 'assessmentName'
                },
                {
                    columnName: 'Assessment Id',
                    columnKey: 'assessmentId'
                },
                {
                    columnName: 'Attached',
                    columnKey: 'attached'
                },
                {
                    columnName: 'Creation Date',
                    columnKey: 'creationDate'
                },
                {
                    columnName: 'Last Modified Date',
                    columnKey: 'lastModifiedDate'
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    minWidth: 300,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const modifiable = rowData.data['modifiable'];
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    // Edit Assessment
                                    if (!modifiable) {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Associated Assessments cannot be edited.',
                                            true
                                        );
                                        return;
                                    }
                                    buttonRef.loader = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo
                                            .update
                                    );
                                    apiArgs.intactUrl = apiArgs.url;
                                    apiArgs.url = apiArgs.url.replace(
                                        '{auditId}',
                                        rowData.data['assessmentId']
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        buttonRef.loader = false;
                                        this.editAssessment(response);
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        buttonRef.loader = false;
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                },
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-edit'
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: true
                            },
                            {
                                buttonName: 'View',
                                function: (buttonRef: IButtonData) => {
                                    // View Assessment

                                    this.globalDataService.fullPageLoader.next(
                                        true
                                    );
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo[
                                        'view'
                                        ]
                                    );
                                    apiArgs.url = apiArgs.url.replace(
                                        '{auditId}',
                                        rowData.data['assessmentId']
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (sideMenuData) => {
                                        this.assessmentCacheService.createAssessmentSideMenuData =
                                            sideMenuData.landingPageAttributes;
                                        const viewId =
                                            rowData['data']['assessmentId'];
                                        this.assessmentCacheService.defaultViewId =
                                            this.globalDataService.selectedView;
                                        this.assessmentCacheService.assessmentView =
                                            true;
                                        this.assessmentCacheService.mainViewPath =
                                            window.location.pathname;
                                        this.userDataCacheService.isAssesseeView =
                                            true;
                                        this.sessionService.setSideMenuFirstLevelDataData(
                                            viewId,
                                            sideMenuData,
                                            true,
                                            true
                                        );
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                },
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-edit'
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                showLoader: true
                            },
                            {
                                buttonName: 'Delete',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-trash'
                                },
                                showLoader: false,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                function: (modalId: Symbol) => {
                                    if (!modifiable) {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Associated Assessments cannot be deleted.',
                                            true
                                        );
                                        return;
                                    }
                                    const deleteArgs =
                                        Helper.generateHitApiConfig(
                                            this.widgetRef.widgetData.widgetInfo
                                                .delete
                                        );
                                    deleteArgs.intactUrl = deleteArgs.url;
                                    deleteArgs.url = deleteArgs.url.replace(
                                        '{auditId}',
                                        rowData['data']['assessmentId']
                                    );
                                    deleteArgs.input = {};
                                    deleteArgs.function = () => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Audit deleted successfully'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    deleteArgs.errorFunction = (error) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            error && error.error.message
                                                ? error.error.message
                                                : 'Error deleting assessment',
                                            true
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        deleteArgs,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                },
                                executeFunctionAfterConfirmation: !modifiable
                                    ? null
                                    : {
                                        modalName: 'Delete Assessment',
                                        modalIcon: null,
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to permanently delete Assessment ?`,
                                        buttonText: 'Delete',
                                        buttonColorType: ButtonColorType.WARN,
                                        loaderOnExec: true
                                    }
                            },
                            {
                                buttonName: 'Email Configuration',
                                function: (buttonRef: IButtonData) => {
                                    buttonRef.loader = true
                                    // View Assessment
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo[
                                        'get'
                                        ]
                                    );
                                    apiArgs.url = apiArgs.url.replace(
                                        '{auditId}',
                                        rowData.data['assessmentId']
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        buttonRef.loader = false;
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                        this.emailConfigurationModal(response, rowData.data['assessmentId']);
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        buttonRef.loader = false;
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                },
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-edit'
                                },
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: true,
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            selection: 'single'
        };
    }
    addAssessment() {
        const modalData: IModalData = {
            modalName: 'Create Assessment',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            noBottomPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateAssessmentComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    editAssessment(response) {
        const tree = this.parseJsonToTree(response);
        const modalData: IModalData = {
            modalName: 'Create Assessment',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 100,
            modalWidthVw: 100,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateAssessmentComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                edit: true,
                                tree,
                                rootNode: tree.root,
                                menuParentNode: tree.root,
                                selectedAcl: response.acl,
                                assessmentId: response.id
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    parseJsonToTree(response): Tree<IAttributeInfo> {
        const tree = new Tree<IAttributeInfo>();
        const rootNode = tree.addNode(
            {
                attributeName: response.auditName,
                iconType: response.auditIconType,
                icon: response.auditIcon
            },
            null
        );
        this.addNodesToTree(tree, rootNode, response.attributes);
        return tree;
    }
    addNodesToTree(
        tree: Tree<IAttributeInfo>,
        parentNode: Node<IAttributeInfo>,
        nodesData: any[]
    ) {
        nodesData.forEach((nodeData) => {
            const data: IAttributeInfo = {
                attributeName: nodeData.attributeName,
                icon: nodeData.icon,
                iconType: nodeData.iconType
            };
            const node = tree.addNode(data, parentNode.nodeId);
            if (nodeData.framework) {
                if (
                    nodeData.framework.widgets &&
                    nodeData.framework.widgets.length
                ) {
                    node.data.widgets = nodeData.framework.widgets;
                }
                node.data.framework = nodeData.framework;
            } else if (nodeData.attributes && nodeData.attributes.length) {
                this.addNodesToTree(tree, node, nodeData.attributes);
            }
        });
    }

    emailConfigurationModal(configRuleResponse, auditId: string) {
        this.emailConfigurationModalService.clearAllConfigData();
        const rulesConfig = configRuleResponse ? configRuleResponse['emailConfigurationsResponseModels'] : [];
        const selectedConfigType = configRuleResponse ? configRuleResponse['type'] : 'Default';
        const modalData: IModalData = {
            modalName: 'Email Configuration',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 60,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: EmailConfigurationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                emailConfigRulesData: rulesConfig && rulesConfig.length > 0
                                    ? rulesConfig
                                    : [],
                                isEdit: true,
                                selectedRuleType: selectedConfigType,
                                auditId: auditId,
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ],
            closeCallback: () => {
                this.emailConfigurationModalService.clearAllConfigData();
            }
        };
        this.modalService.openModal(modalData);
    }
}
