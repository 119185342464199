import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { AssessmentS3DataUrl } from 'src/app/shared/enums/AssessmentS3DataUrl';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IWysiwygEditorInsertOperation } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from './../../../../services/notifications/notifications.service';

enum RemarkType {
    PUBLIC = 'Public',
    PRIVATE = 'Private'
}
@Component({
    selector: 'app-upload-evidence-modal',
    templateUrl: './upload-evidence-modal.component.html',
    styleUrls: ['./upload-evidence-modal.component.sass']
})
export class UploadEvidenceModalComponent implements OnInit {
    uploadEvidenceFormGenInputs: IFormGeneratorInput;
    uploadEvidenceFormGroup: FormGroup;
    attachDocButton: IButtonGeneratorInput;
    submitEvidenceButton: IButtonGeneratorInput;
    checkButton: IButtonGeneratorInput;
    eCommentType = RemarkType;
    commentType: string = this.eCommentType.PUBLIC;
    @ViewChild('checkMenu') checkMenu: MatMenu;
    @ViewChild('inp') fileInput: ElementRef;
    file: any;
    fileName: string;
    dropdownIcon: IIcon = {
        type: IconType.SVG,
        class: 'circular_edit'
    };
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross_icon',
        extraClass: 'svg-accent-fill'
    };
    controlPointIds: string[] = [];
    isPublic: boolean;
    frameworkIds: any;
    uploadingDocument: boolean = false;
    uploadingComment: boolean = false;
    disableCross: boolean;
    constructor(
        private notificationService: NotificationsService,
        private cdRef: ChangeDetectorRef,
        private widgetHttpService: WidgetHttpService,
        private modalData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.controlPointIds = this.modalData.data['inputIds'];
        this.frameworkIds = this.modalData.data['frameworkIds'];
    }

    ngOnInit(): void {
        this.uploadEvidenceFormGenInputs = {
            formName: 'Upload Evidence',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    appearance: WysiwygEditorField.TYPE_3,
                    label: '',
                    name: 'evidence',
                    placeholder: '',
                    required: false,
                    extraClass: 'rich-editor',
                    hideWysiwygEditorInsertOperation: [
                        IWysiwygEditorInsertOperation.PICTURE,
                        IWysiwygEditorInsertOperation.TABLE
                    ],
                    characterLimit: 10000,
                    disableFontChange: true,
                    customInputCallback: (
                        totalCharacterCount,
                        leftOverCharacterCount
                    ) => {
                        const button: IButtonGeneratorInput = Helper.cloneDeep(
                            this.submitEvidenceButton
                        );
                        if (leftOverCharacterCount >= 0 &&
                            ((button && !button.disable) ||
                                (button && !button.loader))) {
                            if (button) {
                                button.disable = false;
                                button.loader = false;
                            }
                        } else {
                            button.disable = true;
                        }
                        this.submitEvidenceButton = button;
                    }
                }
            ]
        };
        this.checkButton = {
            buttonName: 'Remark view',
            buttonIcon: this.dropdownIcon,
            function: (buttonRef: IButtonGeneratorInput) => {},
            buttonColorType: ButtonColorType.INFO,
            buttonType: ButtonType.TEXT,
            showLoader: true
        };
        this.generateUploadEvidenceButtons();
    }
    ngAfterViewChecked() {
        this.checkButton.matMenu = this.checkMenu;
    }
    generateUploadEvidenceButtons() {
        this.attachDocButton = {
            buttonName: 'Attach Document',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.STROKED,
            function: (buttonRef) => {
                this.fileReader();
            },
            showLoader: true
        };
        this.submitEvidenceButton = {
            buttonName: 'Submit',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef) => {
                this.getKeyFromBackend(buttonRef);
            }
        };
    }
    fileReader() {
        const elem = document.getElementById('file-reader');
        if (elem && document.createEvent) {
            const evnt = document.createEvent('MouseEvents');
            evnt.initEvent('click', true, false);
            elem.dispatchEvent(evnt);
        }
    }
    removeFile() {
        this.fileName = null;
        this.file = null;
        this.fileInput.nativeElement.value = null;
    }
    getKeyFromBackend(buttonRef: IButtonGeneratorInput, commentId?) {
        buttonRef.loader = true;
        buttonRef.disable = true;
        this.attachDocButton.disable = true;
        this.disableCross = true;
        const inputs = commentId
            ? this.generateInput(commentId)
            : this.generateInput();
        if (inputs.comment !== '') {
            const apiArgs: IHitApi = {
                url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.BULK_COMMENT,
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (res) => {
                    this.dumpDataToS3(res, commentId, buttonRef);
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.file = null;
                    this.fileName = null;
                    buttonRef.loader = false;
                    buttonRef.disable = false;
                    this.uploadingDocument = false;
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        } else {
            this.uploadDocument(buttonRef, inputs);
        }
    }
    dumpDataToS3(response, commentId, buttonRef) {
        const inputs = commentId
            ? this.generateInput(commentId)
            : this.generateInput();
        let comment = Helper.trimHTML(
            Helper.stringToHTML(inputs.comment)
        ).innerHTML?.toString();
        // Moving Closing Brackets out of the Anchor tag.
        const commentHtml = Helper.stringToHTML(inputs.comment);
        // Removing closing brackets from link.
        for (let i = 0; i < commentHtml.getElementsByTagName('a').length; i++) {
            const anchorTagAttribute =
                commentHtml.getElementsByTagName('a')[i]?.href;
            let UpdatedAnchorTag = anchorTagAttribute?.replace(')', '');
            UpdatedAnchorTag = UpdatedAnchorTag.replace('(http', 'http');
            commentHtml
                .getElementsByTagName('a')
                [i].setAttribute('href', UpdatedAnchorTag);
        }
        comment = Helper.trimHTML(commentHtml).innerHTML?.toString();
        comment = comment.replace(/\)<\/a>/g, '</a>)');
        if (
            (comment === null || comment === undefined || !comment) &&
            !inputs.file &&
            !inputs.fileName
        ) {
            this.notificationService.showSnackBar(
                'Please input evidence or attach a document to continue',
                true
            );
            buttonRef.loader = false;
            return;
        }
        //converting text to file
        if (comment !== '') {
            const blob = new Blob([comment], { type: 'text/html' });
            const fileName = commentId + '.html';
            const file = new File([blob], fileName, { type: 'text/html' });
            Helper.convertTextToBase64(file)
                .then((data) => {
                    inputs.comment = data;
                    const { preSignedUrl, key } = response;
                    const apiArgs: IHitApi = {
                        url: preSignedUrl,
                        input: data,
                        requestType: RequestType.PUT,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED
                        },
                        function: (res) => {
                            inputs.comment = key;
                            if (this.file) {
                                this.uploadDocument(buttonRef, inputs);
                            } else {
                                //to make file key and filename null and not undefined in payload
                                inputs.file = null;
                                inputs.fileName = null;
                                this.submit(buttonRef, inputs);
                            }
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error
                            );
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetHttpService,
                        this.widgetHttpService.ngZone
                    ).hitApi();
                })
                .catch((error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                });
        }
    }
    uploadDocument(btnRef, inputs) {
        if (this.file) {
            this.uploadingDocument = true;
            const apiArgs: IHitApi = {
                url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.BULK_COMMENT,
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (res) => {
                    const { preSignedUrl, key } = res;
                    const apiArgs: IHitApi = {
                        url: preSignedUrl,
                        input: {
                            file: this.file,
                            fileName: this.fileName
                        },
                        requestType: RequestType.PUT,
                        uniqueIdentity: Symbol(),
                        config: {
                            authorization: AuthorizationType.NOT_AUTHORIZED,
                            defaultHeaders: {
                                'Content-Type': 'application/json'
                            }
                        },
                        function: (res) => {
                            inputs.file = key;
                            this.submit(btnRef, inputs);
                            this.cdRef.detectChanges();
                        },
                        errorFunction: (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error
                            );
                            btnRef.loader = false;
                            btnRef.disable = false;
                            this.uploadingDocument = false;
                            this.file = null;
                        }
                    };
                    new HitApi(
                        apiArgs,
                        this.widgetHttpService,
                        this.widgetHttpService.ngZone
                    ).hitApi();
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.file = null;
                    this.fileName = null;
                    btnRef.loader = false;
                    btnRef.disable = false;
                    this.cdRef.detectChanges();
                }
            };
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.input = {
                ...apiArgs.input
            };
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        } else {
            this.uploadingDocument = false;
            this.notificationService.showSnackBar(
                'Please input evidence or attach a document to continue',
                true
            );
            btnRef.disable = false;
            btnRef.loader = false;
            this.attachDocButton.disable = false;
            return;
        }
    }
    submit(buttonRef: IButtonGeneratorInput, inputs) {
        if (this.commentType === 'Private') {
            this.isPublic = false;
            inputs['public'] = false;
        } else {
            this.isPublic = true;
            inputs['public'] = true;
        }
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_COMMENT,
            input: inputs,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: () => {
                buttonRef.loader = false;
                buttonRef.disable = false;
                this.submitEvidenceButton.disable = false;
                this.submitEvidenceButton.loader = false;
                this.attachDocButton.disable = false;
                this.uploadingDocument = false;
                this.file = null;
                this.fileName = null;
                this.modalService.closeModal(null, this.modalData.modalId);
                this.cdRef.detectChanges();
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                buttonRef.disable = false;
                Helper.showErrorMessage(this.notificationService, error);
                this.modalService.closeModal(null, this.modalData.modalId);
                this.file = null;
                this.fileName = null;
                this.uploadingDocument = false;
                this.attachDocButton.disable = false;
                this.cdRef.detectChanges();
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.input = {
            ...apiArgs.input,
            frameworkIds: this.frameworkIds
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    fileSelected(event) {
        this.fileName = event.target.files[0]['name'];
        Helper.convertImageToBase64(event.target)
            .then((data) => {
                this.file = data;
            })
            .catch((error) => {
                Helper.showErrorMessage(this.notificationService, error);
            });
    }
    generateInput(commentId?) {
        const input = {
            comment: this.uploadEvidenceFormGroup.get('evidence').value ?? '',
            file: this.file,
            fileName: this.fileName,
            public: this.isPublic
        };
        if (commentId) {
            input['parentCommentId'] = commentId;
        }
        return input;
    }
}
