<ng-container *ngIf="!(widgetRef.loadingData | async); else loadingBlock">
    <div class="container tw-relative" *ngIf="isLoaded">
        <ng-container *ngIf="actionButtons && actionButtons.length">
            <div class="actions tw-flex tw-justify-end tw-absolute tw-w-full">
                <app-multi-button-generator
                    [buttonGenInputs]="actionButtons"
                ></app-multi-button-generator>
            </div>
        </ng-container>
        <ng-container *ngIf="whitelabelData.whitelabel">
            <div class="cloud-front-dns">
                <span>
                    Cloudfront DNS:
                    <mat-icon
                        class="tw-text-fs-400 info clickable"
                        (click)="openCloudFrontDnsInfo()"
                        >info</mat-icon
                    >
                </span>

                <span class="dns">{{
                    whitelabelData.domainConfig.cloudFrontDns
                }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="subWidgetList && subWidgetList.length">
            <div class="sub-widgets">
                <ng-container *ngFor="let subWidget of subWidgetList">
                    <div
                        class="sub-widget-container tw-overflow-hidden tw-mb-5"
                        [class.collapsed]="
                            subWidgetCollapseMap.get(subWidget.id)
                        "
                    >
                        <div
                            class="sub-widget-header tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-6 clickable"
                            (click)="
                                $event.stopPropagation();
                                onToggleCollapse(subWidget.id)
                            "
                        >
                            <div
                                class="tw-text-fs-400 tw-font-semibold tw-leading-normal"
                            >
                                {{ subWidget.name }}
                            </div>
                            <div
                                class="sub-widget-reset"
                                *ngIf="!subWidget?.isHideResetButton"
                            >
                                <app-button-generator
                                    [buttonGenInput]="resetButton"
                                    (buttonRef)="
                                        resetSubWidgetForm(subWidget.id)
                                    "
                                ></app-button-generator>
                            </div>
                        </div>
                        <div
                            class="sub-widget-body tw-overflow-auto tw-relative"
                        >
                            <ng-container
                                *ngIf="
                                    subWidget.id !== 'domain' &&
                                    !this.isDomainSaved
                                "
                            >
                                <div
                                    class="sub-overlay tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-opacity-0 tw-z-10 hover:tw-opacity-100"
                                >
                                    <div
                                        class="messge-box tw-w-2/5 tw-text-center tw-font-semibold tw-leading-tight tw-py-8 tw-px-11"
                                    >
                                        <p>
                                            Please Configure Domain to Access
                                            Further and Customise other Data
                                        </p>
                                    </div>
                                </div>
                            </ng-container>
                            <div
                                class="sub-widget-body-content tw-py-3 tw-px-4"
                                [class.blur]="
                                    subWidget.id !== 'domain' &&
                                    !this.isDomainSaved
                                "
                            >
                                <ng-container
                                    *ngComponentOutlet="
                                        subWidget.component;
                                        injector: subWidget.subWidgetInjector
                                    "
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div
        class="error-message"
        *ngIf="
            (widgetRef.errorMessage | async) &&
            (widgetRef.errorMessage | async).length
        "
    >
        <i class="fas fa-exclamation-circle"></i>
        <span>{{ widgetRef.errorMessage.value }}</span>
    </div>
</ng-container>
<ng-template #loadingBlock>
    <div class="overlay">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
</ng-template>

<ng-template #deleteModal>
    <div class="tw-absolute tw-bottom-14">
        <span class="note">Note: This action may take 30 minutes.</span>
    </div>
</ng-template>

<ng-template #publishModal>
    <div class="publish-modal">
        <span
            >Following parameters are not configured. Click confirm to
            proceed.</span
        >
        <br />
        <li>
            <span class="sub-heading"
                >Configure Organization's Proprietary.</span
            >
        </li>
        <ng-container *ngFor="let data of proprietary | keyvalue">
            <li *ngIf="!data.value" class="proprietary">{{ data.key }}</li>
        </ng-container>
    </div>
</ng-template>
