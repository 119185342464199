<div class="link-insight-container">
    <div class="head-container">
        <div class="switch-container">
            <span [ngClass]="'toggle-text'">
                <p style="cursor: auto; margin-right: 10px; margin-top: 3px">
                    Split View
                </p>
                <mat-slide-toggle (click)="toggleCompare()"></mat-slide-toggle>
            </span>
        </div>
        <div
            class="modal-buttons"
            [class.tw-justify-end]="
                showSecondInsight || (filterMenuRef && filterMenuRef.opened)
            "
        >
            <div
                *ngIf="
                    !showSecondInsight && filterMenuRef && !filterMenuRef.opened
                "
                (click)="filterMenuRef.open()"
                class="filter-icon-container"
                matTooltip="Toggle Filter"
                appIcon
                [data]="filterIcon"
            ></div>
            <div
                (click)="closeModal()"
                class="close-modal-container"
                matTooltip="Close Modal"
                appIcon
                [data]="crossIcon"
            ></div>
        </div>
    </div>
    <div class="insight-container">
        <!-- First Insight Start -->
        <div
            *ngIf="firstViewData"
            class="insight-one-container"
            [style.maxWidth.%]="showSecondInsight ? 50 : 100"
        >
            <app-compare-view-insights
                [modalData]="firstViewData"
                [dataStoreKey]="FilterStoreKey.FIRST_VIEW_FILTERS"
                [filterStoreKey]="FilterStoreKey.FIRST_VIEW_FILTERS"
                (filterMenu)="filterMenuRef = $event"
            ></app-compare-view-insights>
        </div>
        <!-- First Insight End -->

        <!-- Second Insight Start-->
        <div
            *ngIf="showSecondInsight"
            class="insight-two-container"
            #compareInsight
        >
            <app-compare-view-insights
                [modalData]="secondViewData"
                [dataStoreKey]="FilterStoreKey.SECOND_VIEW_FILTERS"
                [filterStoreKey]="FilterStoreKey.SECOND_VIEW_FILTERS"
            ></app-compare-view-insights>
        </div>
        <!-- Second Insight End-->
    </div>
</div>
