<div class="editor">
    <app-form-generator
        [formGeneratorInput]="uploadEvidenceFormGenInputs"
        (formGroupRef)="uploadEvidenceFormGroup = $event"
    ></app-form-generator>
    <div class="controls">
        <div class="tw-relative tw-left-2 tw-flex">
            <input
                #inp
                type="file"
                name=""
                id="file-reader"
                (change)="fileSelected($event)"
            />
            <span class="file-name" title="{{ fileName }}">
                {{ fileName }}
            </span>
            <div
                *ngIf="fileName && !uploadingDocument && !disableCross"
                class="cross-icon"
                [matTooltip]="'Remove File'"
                (click)="removeFile()"
                appIcon
                [data]="crossIcon"
            ></div>
            <span class="uploading-document" *ngIf="uploadingDocument">
                {{" Uploading your document"}} <span class="loading"></span
            ></span>
        </div>
        <div class="tw-flex">
            <app-button-generator
                *ngIf="attachDocButton"
                [buttonGenInput]="attachDocButton"
            ></app-button-generator>

            <app-button-generator
                *ngIf="submitEvidenceButton"
                [buttonGenInput]="submitEvidenceButton"
            ></app-button-generator>
        </div>
    </div>
</div>
<div class="dropdown">
    <app-button-generator [buttonGenInput]="checkButton"></app-button-generator>
    <div class="menuItem">
        <mat-menu #checkMenu="matMenu">
            <mat-radio-group
                class="tw-flex"
                name="commentType"
                (change)="commentType = $event.value"
            >
                <ul class="list">
                    <li class="list-item">
                        <div class="item-private">
                            <div class="input-text">
                                <mat-radio-button
                                    [checked]="
                                        commentType == eCommentType.PRIVATE
                                    "
                                    value="Private"
                                ></mat-radio-button>
                                <span class="private-text">Private</span>
                            </div>
                            <span class="private-sub-text"
                                >Only you can view the content</span
                            >
                        </div>
                        <div class="item-public">
                            <div class="input-text">
                                <mat-radio-button
                                    [checked]="
                                        commentType == eCommentType.PUBLIC
                                    "
                                    value="Public"
                                ></mat-radio-button>
                                <span class="public-text">Public</span>
                            </div>
                            <span class="public-sub-text"
                                >Partner can view the content in both console
                                and detailed report.</span
                            >
                        </div>
                    </li>
                </ul>
            </mat-radio-group>
        </mat-menu>
    </div>
</div>
