<div class="container">
    <div
        class="selected-tags"
        [class.tw-border-none]="customContent"
        *ngIf="showSaveButton"
    >
        <div class="associatedTagContainer">
            <ng-container *ngIf="tagsData && showTagsList">
                <ng-container *ngFor="let tag of tagsData">
                    <div class="tags" [ngStyle]="{ background: tag.colorCode }">
                        <div class="tag-name" [matTooltip]="tag.name">
                            {{ tag.name }}
                        </div>
                        <div
                            appIcon
                            [data]="crossIcon"
                            class="delete-tag"
                            (click)="deleteTag(tag.name)"
                        ></div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="customContent; else defaultMenuIcon">
            <div
                [matMenuTriggerFor]="tagMenu"
                (menuOpened)="menuToggle.next(true)"
                (menuClosed)="menuToggle.next(false)"
                (click)="$event.stopPropagation()"
                #tagMenuTrigger="matMenuTrigger"
            >
                <ng-content></ng-content>
            </div>
        </ng-container>
        <ng-template #defaultMenuIcon>
            <div
                class="edit-tags"
                [matMenuTriggerFor]="tagMenu"
                (menuOpened)="menuToggle.next(true)"
                (menuClosed)="menuToggle.next(false)"
                (click)="$event.stopPropagation()"
                #tagMenuTrigger="matMenuTrigger"
                appIcon
                [data]="topEllipse"
            ></div>
        </ng-template>
    </div>

    <mat-menu #tagMenu="matMenu" [class]="extraClass ? extraClass : ''">
        <ng-container *ngTemplateOutlet="tagGenerator"></ng-container>
    </mat-menu>

    <div class="tag-generator" *ngIf="!showSaveButton">
        <ng-container *ngTemplateOutlet="tagGenerator"> </ng-container>
    </div>

    <ng-template #tagGenerator>
        <div class="tag-generator-wrapper" (click)="$event.stopPropagation()">
            <div class="heading">
                <span> Tags </span>
            </div>
            <div class="tags-container">
                <div class="tags-status-bar">
                    <div class="associatedTagContainer">
                        <ng-container *ngIf="tagsData && tagsData.length">
                            <ng-container *ngFor="let tag of tagsData">
                                <div
                                    class="tags"
                                    [ngStyle]="{ background: tag.colorCode }"
                                >
                                    <div
                                        class="tag-name"
                                        [matTooltip]="tag.name"
                                    >
                                        {{ tag.name }}
                                    </div>
                                    <div
                                        appIcon
                                        [data]="crossIcon"
                                        class="delete-tag"
                                        (click)="deleteTag(tag.name, tagInput)"
                                    ></div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <input
                        #tagInput
                        type="text"
                        class="tag-search"
                        [placeholder]="'Enter the tag name'"
                        [disabled]="
                            limitTags && tagsData.length === limitTags
                                ? true
                                : false
                        "
                        (keyup)="handleTagInput($event.target.value)"
                        (keyup.enter)="showCreateTagBar ? addTag(tagInput) : ''"
                    />
                </div>
                <div class="create-tag-container" *ngIf="showCreateTagBar">
                    <div class="color-picker">
                        <app-color-picker
                            (colorCode)="selectedColor = $event"
                        ></app-color-picker>
                    </div>
                    <div class="new-tag-name">
                        <span class="default-suffix"> Create new Tag</span>
                        "<b class="tag-name">{{ tagInput.value }} </b>"
                    </div>
                    <div class="add-tag-container">
                        <span
                            appIcon
                            [data]="crossIcon"
                            class="add-tag"
                            (click)="addTag(tagInput)"
                        ></span>
                    </div>
                </div>
                <div class="tag-suggestions">
                    <ng-container
                        *ngIf="
                            filteredAvailableTags &&
                            filteredAvailableTags.length
                        "
                    >
                        <ng-container *ngFor="let tag of filteredAvailableTags">
                            <div
                                class="tags"
                                (click)="addExistingTag(tag.name, tagInput)"
                                [ngStyle]="{ background: tag.colorCode }"
                            >
                                <span class="tag-name">{{ tag.name }}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="actions" *ngIf="showSaveButton">
                <app-multi-button-generator
                    [buttonGenInputs]="buttonInputs"
                ></app-multi-button-generator>
            </div>
        </div>
    </ng-template>
</div>
