<div class="container">
    <div class="form-container">
        <app-form-generator
            *ngIf="formGenInput"
            [formGeneratorInput]="formGenInput"
            (formGroupRef)="formGroupRef = $event"
        ></app-form-generator>
        <div class="modal-info" *ngIf="!isCreate">
            <div class="info-icon">
                <i class="custom-fa-icon fa fa-info-circle"></i>
            </div>
            <div class="info-message">
                <p>
                    This case is being created to test if case is created on
                    salesforce successfully or not.
                </p>
            </div>
        </div>
    </div>
    <div class="buttons">
        <app-button-generator
            *ngIf="buttonInput"
            [buttonGenInput]="buttonInput"
        ></app-button-generator>
    </div>
</div>
