import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { Helper } from '../../../../classes/Helper';
import { WidgetInjectedData } from '../../../../classes/WidgetInjectedData';
import { IIcon } from '../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from '../../../../interfaces/table-generator/ITableGeneratorInput';
import { AzureSponsorshipOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/azure-sponsorship-onboarding-modal/azure-sponsorship-onboarding-modal.component';
import { AzureSponsorshipOnboardingImpactModalComponent } from '../../../modal-templates/azure-sponsorship-onboarding-impact-modal/azure-sponsorship-onboarding-impact-modal.component';
import { AddAzureSponsorshipAccountComponent } from '../../../modal-templates/azure-sponsorship-onboarding-modal-steps/add-azure-sponsorship-account/add-azure-sponsorship-account.component';
import { CommonMoreInfoModalComponent } from '../../../modal-templates/common-more-info-modal/common-more-info-modal.component';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-azure-sponsorship-onboarding-listing',
    templateUrl: './azure-sponsorship-onboarding-listing.component.html',
    styleUrls: ['./azure-sponsorship-onboarding-listing.component.sass']
})
export class AzureSponsorshipOnboardingListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    selectedData;
    @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
    @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
    @ViewChild('moreInfoWarningMessage')
    moreInfoWarningMessage: TemplateRef<any>;
    agGrid: GridOptions = null;
    accountsToBeConverted = [];
    warningIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-exclamation-triangle',
        extraClass: 'yellow-severity-color'
    };
    isSponsorship: boolean = false;
    isPayAsYouGO: boolean = false;
    impactModalLoader: boolean = false;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.SPONSORSHIP_LISTING
        ) {
            this.isSponsorship = true;
        } else if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
            PortletType.PAY_AS_YOU_GO_LISTING
        ) {
            this.isPayAsYouGO = true;
        }

        this.applyTagOperation();
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
        this.setupBasics();
    }
    applyTagOperation() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.deleteAccount(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'trash_icon_filled',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.uploadAccount();
            },
            icon: {
                type: IconType.SVG,
                class: 'upload',
                text: 'Bulk Upload',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.suspendResumeAccount(true, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'suspend',
                text: 'Bulk Suspend',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.suspendResumeAccount(false, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'resume',
                text: 'Bulk Resume',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }
    setupBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add Account',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: this.openAzureOnBoardingModal.bind(this)
                }
            ],
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            refreshWidgetCallback: () => {
                this.widgetRef.showNotificationBadge.next(false);
            },
            columns: [
                {
                    columnName: 'Subscription ID',
                    columnKey: 'accountNumber',
                    pinned: 'left',
                    minWidth: 200
                },
                {
                    columnName: 'Account Name',
                    columnKey: 'accountName'
                },
                {
                    columnName: 'Account SPOC',
                    columnKey: 'spoc'
                },
                {
                    columnName: 'Status',
                    columnKey: 'status',
                    cellRenderer: (rowData) => {
                        if (rowData.data['conversionNeeded']) {
                            return `<div class='yellow-severity-color tw-flex tw-justify-center tw-items-center'>
                                        <span style='margin-right:10px'>
                                            <i class="fas fa-circle"></i>
                                        </span> ${rowData.data.status}
                                    </div>`;
                        } else if (rowData.data.status === 'INACTIVE') {
                            return `<div class='red-severity-color tw-flex tw-justify-center tw-items-center'>
                                        <span style='margin-right:10px'>
                                             <i class="fas fa-circle"></i>
                                        </span> ${rowData.data.status}
                                    </div>`;
                        } else {
                            return `<div class='green-severity-color tw-flex tw-justify-center tw-items-center'>
                                        <span style='margin-right:10px'>
                                            <i class="fas fa-circle"></i>
                                        </span> ${rowData.data.status}
                                    </div>`;
                        }
                    }
                },
                {
                    columnName: 'Account Type',
                    columnKey: 'type',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        if (rowData.data['type'] !== 'SPONSORSHIP') {
                            return 'Pay As You Go';
                        }
                        return 'Sponsorship';
                    }
                },

                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    cellClass: 'action-container',
                    minWidth: this.isPayAsYouGO ? 230 : 350,
                    filter: false,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                showLoader: true,
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable:
                                    this.isSponsorship &&
                                    rowData.data['status'] === 'CONVERTED',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    buttonRef.loader = true;
                                    this.getAccountDetails(
                                        rowData.data['accountNumber'],
                                        buttonRef,
                                        rowData.data['type']
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                showLoader: false,
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.selectedData = [rowData.data];
                                    this.deleteAccount();
                                }
                            },
                            {
                                buttonName:
                                    rowData.data.status === 'ACTIVE'
                                        ? 'Suspend'
                                        : 'Resume',
                                showLoader: false,
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                disable:
                                    this.isSponsorship &&
                                    rowData.data['status'] === 'CONVERTED',
                                function: () => {
                                    this.selectedData = [rowData.data];
                                    this.suspendResumeAccount(
                                        rowData.data.status === 'ACTIVE'
                                            ? true
                                            : false
                                    );
                                }
                            },
                            {
                                buttonName: 'More Info',
                                showLoader: false,
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable:
                                    this.isSponsorship &&
                                    rowData.data['status'] === 'CONVERTED',
                                function: () => {
                                    const showMoreInfoMessage =
                                        rowData.data['conversionNeeded'];
                                    this.openMoreInfo(
                                        rowData.data,
                                        showMoreInfoMessage
                                    );
                                }
                            }
                        ];
                        const convertButton: IButtonGeneratorInput = {
                            buttonName: 'Convert',
                            showLoader: false,
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.PRIMARY,
                            customClass: !rowData.data['conversionNeeded']
                                ? 'convert-btn-disable'
                                : '',
                            disable: !rowData.data['conversionNeeded'],
                            function: () => {
                                this.openConversionModal(rowData.data);
                            }
                        };
                        if (this.isSponsorship) {
                            buttons.splice(3, 0, convertButton);
                        }
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = {
                            layout: {
                                justifyContent: 'space-between'
                            }
                        };
                        return rowData;
                    }
                }
            ]
        };
    }

    getAccountDetails(
        accountId,
        buttonRef?: IButtonGeneratorInput,
        accountType?: string
    ) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'getAccountDetails'
            ]
        );
        apiArgs.url = apiArgs.url.replace('{id}', accountId);
        apiArgs.input = {};
        apiArgs.function = (response) => {
            response['type'] = accountType;
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.openAzureOnBoardingModal(response, true);
        };

        apiArgs.endFunction = () => {
            buttonRef.loader = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    openAzureOnBoardingModal(stepData, editData?: boolean) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: this.isPayAsYouGO
                ? 'On Board Azure Pay As You Go Account'
                : 'On Board Azure Sponsorship Account',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,

            sourceId: this.widgetRef.uniqueIdentity,

            modalWidthVw: 75,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad:
                            AzureSponsorshipOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: editData ? stepData : null,
                                edit: editData ? true : false,
                                isPayAsYouGO: this.isPayAsYouGO,
                                isSponsorship: this.isSponsorship
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    uploadAccount() {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: this.isPayAsYouGO
                ? 'Bulk Upload Azure Pay As You Go Account'
                : 'Bulk Upload Azure Sponsorship Account',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,

            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 63,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: AddAzureSponsorshipAccountComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    deleteAccount(bulkDeleteSelected?) {
        if (bulkDeleteSelected && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }
        if (bulkDeleteSelected) {
            this.selectedData = this.agGrid.api
                .getSelectedNodes()
                .map((rowData) => {
                    return rowData.data;
                });
        }

        const deleteIcon: IIcon = {
            class: '',
            type: IconType.FONTAWSOME,
            extraClass: 'text'
        };
        this.widgetRef.modalService.openConfirmationModal({
            modalName: 'Delete Azure  Account',
            modalIcon: deleteIcon,
            confirmationMessage:
                'Are you sure you want to delete the following Account?',
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            contextIcon: deleteIcon,
            modalAutoHeight: true,
            fontSize: 1.12,
            bodyTemplate: this.actionTemplate,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['delete']
                );
                apiArgs.input = this.createPayload();
                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Account deleted successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        error && error.error && error.error.message
                            ? error.error.message
                            : 'Error deleting account',
                        true
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };

                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        });
    }
    suspendResumeAccount(suspend, bulkActionSelected?) {
        if (bulkActionSelected && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }
        if (bulkActionSelected) {
            this.selectedData = this.agGrid.api
                .getSelectedNodes()
                .map((rowData) => {
                    return rowData.data;
                });
        }
        const icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };

        this.widgetRef.modalService.openConfirmationModal({
            confirmationMessage: `Are you sure you want to ${
                suspend ? 'suspend' : 'resume'
            } the folllowing Account?`,

            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            modalIcon: icon,
            modalAutoHeight: true,
            fontSize: 1.12,
            loaderOnExec: true,
            bodyTemplate: this.actionTemplate,
            modalName: suspend
                ? 'Suspend Azure  Account'
                : 'Resume Azure  Account',
            contextIcon: icon,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['suspendUnsuspend']
                );
                apiArgs.input = this.createPayload();
                apiArgs.url =
                    apiArgs.url + `?status=${suspend ? 'INACTIVE' : 'ACTIVE'}`;

                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        suspend
                            ? 'Account suspended successfully'
                            : 'Account resume successfully'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        error && error.error && error.error.message
                            ? error.error.message
                            : `'Error' ${
                                  suspend ? 'suspending' : 'resuming'
                              } accounts`,
                        true
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };

                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        });
    }
    onSelectionChanged() {
        this.agGrid.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
    }
    createPayload() {
        const payLoadData = this.selectedData.map((data) => {
            return data['accountNumber'];
        });

        return payLoadData;
    }
    openMoreInfo(rowData, showMoreInfoMessage?) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: '',
            noHeader: true,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalIcon: moreInfoIcon,
            modalSteps: [
                {
                    stepName: 'step 1',
                    stepData: {
                        componentToLoad: CommonMoreInfoModalComponent,
                        payload: {
                            data: {
                                stepData: rowData['moreInfo'],
                                widgetRef: this.widgetRef,
                                ignoreCamelCaseToNormalPipe: false,
                                warningMessageTemplate: showMoreInfoMessage
                                    ? this.moreInfoWarningMessage
                                    : null
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    openConversionModal(data) {
        this.accountsToBeConverted = [{ ...data }];
        const modalData: IConfirmationModal = {
            modalName: 'Convert Account',
            modalIcon: null,
            contextIcon: null,
            confirmationMessage: null,
            buttonText: 'Convert',
            buttonColorType: ButtonColorType.PRIMARY,
            hideCancelButton: true,
            bodyTemplate: this.confirmationModal,
            loaderOnExec: true,
            modalHeightVh: 45,

            function: (modalId, formGroup: FormGroup, buttonRef) => {
                this.callConvertApi(
                    this.accountsToBeConverted,
                    modalId,
                    null,
                    buttonRef
                );
            },
            closeCallBack: () => {
                this.accountsToBeConverted = [];
            }
        };
        this.widgetRef.modalService.openConfirmationModal(modalData);
    }

    callConvertApi(
        data,
        modalId?,
        stopLoader?: any,
        buttonRef?: IButtonGeneratorInput
    ) {
        if (buttonRef) {
            buttonRef.loader = true;
        }
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['convert']
        );
        let convertRequest = [];
        data.forEach((element) => {
            convertRequest = [
                ...convertRequest,
                {
                    subscriptionId: element['accountNumber'],
                    type: element['type']
                }
            ];
        });
        apiArgs.input = { convertRequest };
        apiArgs.function = (response) => {
            if (buttonRef) {
                buttonRef.loader = false;
            } else {
                stopLoader();
            }
            if (modalId) {
                this.widgetRef.modalService.closeModal(null, modalId);
            }
            this.widgetRef.notificationsService.showSnackBar(
                response && response['message'],
                false
            );
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            if (buttonRef) {
                buttonRef.loader = false;
            }
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing the action'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    openImpactModal() {
        this.impactModalLoader = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.additionalApisForWidget[
                'convertAccountInfo'
            ]
        );
        apiArgs.requestType = RequestType.POST;
        apiArgs.input = { id: this.accountsToBeConverted[0]['accountNumber'] };
        apiArgs.function = (res) => {
            this.impactModalLoader = false;
            const modalData: IModalData = {
                modalIcon: null,
                modalName: '',
                modalType: ModalType.SIDE,
                sourceId: this.widgetRef.uniqueIdentity,
                noHeader: true,
                noStepPadding: true,
                modalSteps: [
                    {
                        stepName: 'step 1',
                        stepData: {
                            payload: {
                                data: {
                                    impactModalData: res
                                }
                            },
                            componentToLoad:
                                AzureSponsorshipOnboardingImpactModalComponent
                        }
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        };
        apiArgs.errorFunction = (err) => {
            this.impactModalLoader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                err,
                'Error Occured'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
