import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { SubSink } from 'subsink';
import { ApiUrls } from '../../classes/ApiUrls';
import { FilterStoreKey } from './../../../shared/enums/FilterStoreKey';
import { IFormGeneratorInput } from './../../../shared/interfaces/form-generator/IFormGeneratorInput';
import { CompareViewService } from './../../../shared/services/compare-view/compare-view.service';
import { UserDataCacheService } from './../../../shared/services/user-data-cache/user-data-cache.service';
@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.sass']
})
export class FiltersComponent implements OnInit, OnDestroy {
    subSink: SubSink = new SubSink();
    readonly isChecked = 'isChecked';
    Messages = Messages;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    filterFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel_filled',
        extraClass: 'svg-accent-fill'
    };
    sortedFiltersIds: string[] = null;
    restrictedFilters = [];
    disableMessage = '';

    @Input() filterStoreKey: FilterStoreKey;
    @Input() filterMenu: MatSidenav;
    @Input() isCompareView?: Boolean;
    formGenInputs: IFormGeneratorInput;
    formGroup: FormGroup;
    field: any;
    resetFilterValue = null;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    layersList = [
        LayerNames.layer1,
        LayerNames.layer2,
        LayerNames.layer3,
        LayerNames.layer4
    ];
    aclGroupingKey: string;
    hideLoader: boolean = false;
    currencyValue: number;
    changedFiltersValue: Set<string> = new Set();
    // This map varible is used for setting linking data when user deep dive in widget
    linkingData: Map<string, any> = new Map();
    constructor(
        public filtersService: FiltersService,
        public filterCacheService: FilterCacheService,
        private notificationService: NotificationsService,
        public globalDataService: GlobalDataService,
        private httpService: HttpService,
        private userCacheService: UserDataCacheService,
        private configCache: ConfigCacheService,
        private compareViewService: CompareViewService
    ) {}
    ngOnInit() {
        this.filtersService.isCompareViewEnabled = this.isCompareView
            ? true
            : false;
        this.filtersService.filterChanged = [];
        if (this.filterStoreKey === FilterStoreKey.FIRST_VIEW_FILTERS)
            this.filtersService.comapareViewValueChanged = false;
        else this.filtersService.valueChanged = false;
        this.subSink.add(
            this.filtersService.widgetLinkingData.subscribe((data) => {
                if (!data) {
                    this.linkingData.clear();
                }
                if (data && data.get(this.filterStoreKey)) {
                    Object.keys(data.get(this.filterStoreKey)).map((key) => {
                        this.linkingData.set(
                            key,
                            data.get(this.filterStoreKey)[key]
                        );
                    });
                }
            })
        );
        this.subSink.add(
            this.filtersService.drawFilter.subscribe(
                (currentPageFilterStoreKey) => {
                    if (currentPageFilterStoreKey !== this.filterStoreKey) {
                        return;
                    }
                    const filtersPageData =
                        this.filtersService.filtersPageData.getValue();
                    this.formGenInputs = null;
                    this.formGroup = null;
                    if (filtersPageData === null) {
                        this.sortedFiltersIds = null;
                        this.hideLoader = false;
                        return;
                    }
                    if (
                        filtersPageData &&
                        filtersPageData.has(this.filterStoreKey)
                    ) {
                        if (!filtersPageData.get(this.filterStoreKey).size) {
                            this.sortedFiltersIds = [];
                            this.hideLoader = true;
                            return;
                        }
                        const filtersData = filtersPageData.get(
                            this.filterStoreKey
                        );
                        const allFilters = Array.from(filtersData.keys());
                        this.sortedFiltersIds = Helper.sortFilterIds(
                            this.filterCacheService,
                            allFilters
                        );
                        this.hideLoader = false;
                        this.subSink.add(
                            Helper.generateFormInput(
                                this.sortedFiltersIds,
                                this.httpService,
                                this.filterCacheService,
                                {
                                    isFilter: true,
                                    filterStoreKey: this.filterStoreKey
                                },
                                true,
                                this.linkingData,
                                this.filtersService,
                                true,
                                currentPageFilterStoreKey,
                                this.userCacheService,
                                this.configCache
                            ).subscribe((form) => {
                                if (form) {
                                    this.addCustomFields(form.fields);
                                    this.formGenInputs = form;
                                    this.hideLoader = true;
                                }
                            })
                        );
                    } else {
                        this.sortedFiltersIds = [];
                        this.hideLoader = true;
                    }
                }
            )
        );

        this.subSink.add(
            this.filtersService.restrictedFilters.subscribe(
                (restrictedFiltersData) => {
                    if (
                        restrictedFiltersData &&
                        restrictedFiltersData.has(this.filterStoreKey)
                    ) {
                        this.restrictedFilters = restrictedFiltersData
                            .get(this.filterStoreKey)
                            .get('restrictedFilters');
                        this.disableMessage = restrictedFiltersData
                            .get(this.filterStoreKey)
                            .get('disableMessage');
                    }
                }
            )
        );
        this.subSink.add(
            this.filtersService.setFilterInputs.subscribe((value) => {
                if (value) {
                    if (
                        !this.filtersService.firstViewInitailized &&
                        this.filtersService.isCompareViewEnabled
                    ) {
                        this.filtersService.checkboxValue.set(
                            FilterStoreKey.FIRST_VIEW_FILTERS,
                            Helper.cloneDeep(
                                this.filtersService.checkboxValue.get(
                                    FilterStoreKey.FIRST_VIEW_FILTERS
                                )
                            )
                        );
                    } else {
                        this.filtersService.checkboxValue.set(
                            this.filterStoreKey,
                            Helper.cloneDeep(
                                this.filtersService.checkboxValue.get(
                                    FilterStoreKey.WEBSITE_FILTERS
                                )
                            )
                        );
                    }
                    this.prepareFilterInputs();
                }
            })
        );
    }
    addCustomFields(fields: IFormField[]) {
        fields.forEach((field) => {
            if (field.fieldType === FilterType.ACL_VIEW_LAYER) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.ACL_VIEW_LAYER;
                });
                const field = fields[fieldIndex];
                const newForms = this.generateAclForm(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.SINGLE_DROPDOWN_PERCENT) {
                const fieldIndex = fields.findIndex((field) => {
                    return (
                        field.fieldType === FilterType.SINGLE_DROPDOWN_PERCENT
                    );
                });
                const field = fields[fieldIndex];
                const newForms = this.getSavingPercentageFields(
                    field.id,
                    field
                );
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.CURRENCY) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.CURRENCY;
                });
                const field = fields[fieldIndex];
                const newForms = this.getCurrencyFields(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.CUSTOMER_USER) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.CUSTOMER_USER;
                });
                const field = fields[fieldIndex];

                const newForms = this.generateCustomerUserForm(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.DATE_TIME_RANGE) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.DATE_TIME_RANGE;
                });
                const field = fields[fieldIndex];

                const newForms = this.generateDateTimeForm(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.CONTENT) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.CONTENT;
                });
                const field = fields[fieldIndex];

                const newForms = this.getContentField(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.TEXT_WITH_DROPDOWN) {
                const fieldIndex = fields.findIndex((field) => {
                    return field.fieldType === FilterType.TEXT_WITH_DROPDOWN;
                });
                const field = fields[fieldIndex];

                const newForms = this.getTextWithDropdownField(field.id, field);
                fields.splice(fieldIndex, 1);
                newForms.forEach((field, index) => {
                    fields.splice(fieldIndex + index, 0, field);
                });
                this.addCustomFields(fields);
            } else if (field.fieldType === FilterType.NUMBER) {
                field.onChange = (value) => {
                    let updatedValue: number;
                    const filterInfo =
                        this.filtersService.currentPageFilterInfo.get(field.id);
                    if (value) {
                        if (filterInfo.rangeOptions) {
                            if (value < filterInfo.rangeOptions.floor) {
                                updatedValue = Number(
                                    filterInfo.rangeOptions.floor
                                );
                            } else if (value > filterInfo.rangeOptions.ceil) {
                                updatedValue = Number(
                                    filterInfo.rangeOptions.ceil
                                );
                            }
                        }
                    } else {
                        updatedValue = filterInfo.rangeOptions
                            ? Number(filterInfo.rangeOptions.floor)
                            : 1;
                    }
                    this.formGroup
                        .get(field.name)
                        ? this.formGroup?.get(field.name)
                            ?.setValue(updatedValue ?? value)
                        : this.formGroup?.get(field.groupByKey)?.get(field.name)
                            ?.setValue(updatedValue ?? value);
                };
            }
        });
    }
    getTextWithDropdownField(
        filterId: string,
        field: IFormField
    ): IFormField[] {
        const filterInfo = this.filtersService.currentPageFilterInfo.get(
            field.id
        );
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: field.label,
                placeholder: '',
                name: 'frequency',
                fieldType: FilterType.NUMBER,
                groupByKey: field.name,
                hideFilterInfoIcon: true,
                fieldStyle: {
                    width: '50%',
                    'margin-right': '20px',
                    display: 'flex'
                },
                value: +filterInfo.value['textValue'],
                extraClass: 'text-with-dropdown',
                containerClass: 'text-with-dropdown-field',
                extractValueFromKey: 'frequency',
                onFocusout: (event, formGroup: FormGroup) => {
                    if (!event) {
                        formGroup.get(field.name).get('frequency').setValue(1);
                    }
                    if (event <= 0) {
                        formGroup.get(field.name).get('frequency').setValue(1);
                    }
                }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Wastage',
                placeholder: '',
                name: 'dateRange',
                fieldType: FilterType.DROPDOWN_SINGLE,
                listData: field.listData,
                groupByKey: field.name,
                required: false,
                fieldStyle: { width: '40%' },
                hideFilterHead: true,
                value: filterInfo.value['dropdownValue'],
                extractValueFromKey: 'dateRange'
            }
        ];
    }
    getContentField(filterId: string, field: IFormField): IFormField[] {
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: field.label,
                placeholder: field.placeholder,
                name: field.name,
                fieldType: FilterType.HIDDEN,
                required: false
            }
        ];
    }
    getSavingPercentageFields(
        filterId: string,
        field: IFormField
    ): IFormField[] {
        const filterInfo = this.filtersService.currentPageFilterInfo.get(
            field.id
        );

        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: field.label,
                placeholder: field.placeholder,
                name: field.name,
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                listData: field.listData,
                value: field.value['savingPlanStrategy'],
                extractValueFromKey: 'savingPlanStrategy',
                onChange: (event, formGroup: FormGroup) => {
                    const index = this.formGenInputs.fields.findIndex(
                        (field) => field.name === 'percent'
                    );

                    if (
                        !event ||
                        (event &&
                            event.id &&
                            filterInfo.disablePercentFor.includes(event.id))
                    ) {
                        if (index !== -1) {
                            if (!event) {
                                this.formGroup
                                    .get(
                                        this.formGenInputs.fields[index]
                                            .groupByKey
                                    )
                                    .get('percent')
                                    .setValue(null);
                            }
                            this.formGenInputs.fields[index].disabled = true;
                        }
                    } else {
                        this.formGenInputs.fields[index].disabled = false;
                        this.formGenInputs.fields[index].value =
                            field.value['percent'];
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['percent']
                    };
                    this.updateControl.next(this.updateControlInput);
                },
                fieldStyle: { width: '60%', 'margin-right': '20px' }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Wastage',
                placeholder: '0%',
                name: 'percent',
                fieldType: FilterType.NUMBER,
                showArrow: true,
                listData: field.listData,
                groupByKey: field.name,
                required: false,
                fieldStyle: { width: '30%' },
                value: field.value['percent'],
                extractValueFromKey: 'percent',
                hideFilterInfoIcon: true,
                extraClass: 'input-field',
                disabled: filterInfo.disablePercentFor.includes(
                    filterInfo.selectedValue['savingPlanStrategy']
                )
            }
        ];
    }

    getCurrencyFields(filterId: string, field: IFormField): IFormField[] {
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Currency',
                placeholder: field.placeholder,
                name: field.name,
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                apiInfo: field.apiInfo,
                required: false,
                getKey: null,
                value: field.value['id'],
                extractValueFromKey: 'id',
                onChange: (event, formGroup: FormGroup) => {
                    this.currencyValue = event['exchangeRate'];
                    const index = this.formGenInputs.fields.findIndex(
                        (field) => field.name === 'exchangeRate'
                    );
                    this.formGroup
                        .get(this.formGenInputs.fields[index].groupByKey)
                        .get('exchangeRate')
                        .setValue(event['exchangeRate']);
                },
                fieldStyle: { width: '48%', 'margin-right': '5px' }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Exchange Rate ',
                placeholder: '',
                name: 'exchangeRate',
                fieldType: FilterType.NUMBER,
                groupByKey: field.name,
                required: false,
                fieldStyle: { width: '35%' },
                value: field.value['exchangeRate'],
                extractValueFromKey: 'exchangeRate',
                hideFilterInfoIcon: true,
                extraClass: 'exchange-rate',
                disabled: true
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: '',
                placeholder: '',
                name: 'defaultCurrencyValue',
                fieldType: FilterType.CHECKBOX,
                groupByKey: field.name,
                required: false,
                fieldStyle: { width:'5%'},
                hideFilterInfoIcon: true,
                extraClass: 'checkbox-field',
                extractValueFromKey: 'defaultCurrencyValue',
                onChange: (event, formGroup: FormGroup) => {
                    if (this.filtersService.isCompareViewEnabled) {
                        this.filtersService.comapareViewValueChanged = true;
                    } else {
                        this.filtersService.valueChanged = true;
                    }
                    const index = this.formGenInputs.fields.findIndex(
                        (field) => {
                            return field.name === 'exchangeRate';
                        }
                    );
                    const fieldValue = formGroup.getRawValue();
                    if (event.target.checked) {
                        if (index !== -1) {
                            this.formGenInputs.fields[index].disabled = false;
                        }
                    } else {
                        if (index !== -1) {
                            this.formGenInputs.fields[index].disabled = true;
                        }
                    }
                    this.updateControlInput = {
                        action: UpdateAction.UPDATE_VALUE,
                        controls: ['exchangeRate'],
                        value: this.currencyValue
                            ? this.currencyValue
                            : fieldValue[field.name]['exchangeRate']
                            ? fieldValue[field.name]['exchangeRate']
                            : 1
                    };
                    this.updateControl.next(this.updateControlInput);
                }
            }
        ];
    }

    generateAclForm(filterId: string, field: IFormField): IFormField[] {
        this.aclGroupingKey = field.name;
        const viewApiInfo: IApiInfo = {
            host: '',
            apiRouteSuffix: ApiUrls.VIEW_FILTER,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.POST,
            customInput: null
        };

        const layerApiInfo: IApiInfo = {
            host: '',
            apiRouteSuffix: ApiUrls.LAYER_FILTER,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.POST,
            customInput: null
        };
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Acl',
                placeholder: field.placeholder,
                name: 'acl',
                fieldType: FilterType.DROPDOWN_MULTIPLE,
                groupByKey: field.name,
                required: false,
                apiInfo: field.apiInfo,
                onChange: (event, formGroup: FormGroup) => {
                    if (event && !event.length) {
                        const formControls = [
                            'view',
                            'layer1',
                            'layer2',
                            'layer3',
                            'layer4'
                        ];
                        this.resetAlcLayers(formControls);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: [
                            'view',
                            'layer1',
                            'layer2',
                            'layer3',
                            'layer4'
                        ]
                    };
                    this.updateControl.next(this.updateControlInput);
                },
                fieldStyle: { width: '100%' }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Views',
                placeholder: 'Select Views',
                name: 'view',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: viewApiInfo,
                fieldStyle: { width: '100%' },
                onChange: (event, formGroup: FormGroup) => {
                    if (!event) {
                        const formControls = [
                            'layer1',
                            'layer2',
                            'layer3',
                            'layer4'
                        ];
                        this.resetAlcLayers(formControls);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['layer1', 'layer2', 'layer3', 'layer4']
                    };
                    this.updateControl.next(this.updateControlInput);
                },
                customInputCallback: this.viewInputCallback.bind(this)
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Layer1',
                placeholder: 'Select Layer1',
                name: 'layer1',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: layerApiInfo,
                fieldStyle: { width: '100%' },
                customInputCallback: this.layerInputCallback.bind(this, 0),
                onChange: (event, formGroup: FormGroup) => {
                    if (!event) {
                        const formControls = ['layer2', 'layer3', 'layer4'];
                        this.resetAlcLayers(formControls);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['layer2', 'layer3', 'layer4']
                    };
                    this.updateControl.next(this.updateControlInput);
                }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Layer2',
                placeholder: 'Select Layer2',
                name: 'layer2',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: layerApiInfo,
                fieldStyle: { width: '100%' },
                customInputCallback: this.layerInputCallback.bind(this, 1),
                onChange: (event, formGroup: FormGroup) => {
                    if (!event) {
                        const formControls = ['layer3', 'layer4'];
                        this.resetAlcLayers(formControls);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['layer3', 'layer4']
                    };
                    this.updateControl.next(this.updateControlInput);
                }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Layer3',
                placeholder: 'Select Layer3',
                name: 'layer3',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: layerApiInfo,
                fieldStyle: { width: '100%' },
                customInputCallback: this.layerInputCallback.bind(this, 2),
                onChange: (event, formGroup: FormGroup) => {
                    if (!event) {
                        const formControls = ['layer4'];
                        this.resetAlcLayers(formControls);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['layer4']
                    };
                    this.updateControl.next(this.updateControlInput);
                }
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Layer4',
                placeholder: 'Select Layer4',
                name: 'layer4',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: layerApiInfo,
                fieldStyle: { width: '100%' },
                customInputCallback: this.layerInputCallback.bind(this, 3)
            }
        ];
    }
    resetAlcLayers(controls: string[]) {
        controls.forEach((controls) => {
            this.formGroup
                .get(this.aclGroupingKey)
                .get(controls)
                .setValue(null);
        });
    }
    viewInputCallback() {
        if (!this.formGroup) {
            return {};
        }
        return {
            acl: this.formGroup.get(this.aclGroupingKey).get('acl').value,
            view: null,
            layer: null
        };
    }
    layerInputCallback(layerIndex?) {
        if (!this.formGroup) {
            return {};
        }
        const selectedValue = this.formGroup.get(this.aclGroupingKey).value;
        const input = {};
        input['acl'] = selectedValue['acl'];
        input['view'] = layerIndex
            ? selectedValue[this.layersList[layerIndex - 1]]
                ? selectedValue['view']
                : null
            : selectedValue['view'];
        input['layer'] = selectedValue['layer1']
            ? selectedValue[this.layersList[layerIndex - 1]]
            : null;

        return input;
    }
    applyFilters() {
        this.filtersService.filterMenuOpenState.next(false);
        this.filterMenu.close();
        if (this.isCompareView) {
            if (
                !this.filtersService.comapareViewValueChanged &&
                this.compareViewService.isCompareViewEnabled &&
                !this.compareViewService.isCompareViewEnabled.getValue()
            ) {
                this.notificationService.showSnackBar('No Filters Changed');
            } else {
                const changedFiltersIds = this.getChangedFiltersIds();
                if (
                    this.filtersService.checkboxValue &&
                    this.filtersService.checkboxValue.has(
                        this.filterStoreKey
                    ) &&
                    this.filtersService.checkboxValue.get(this.filterStoreKey)
                ) {
                    for (const key of this.filtersService.checkboxValue
                        .get(this.filterStoreKey)
                        .keys()) {
                        changedFiltersIds.push(key);
                    }
                }
                const ids = [];
                this.filtersService.filtersPageData
                    .getValue()
                    .get(this.filterStoreKey)
                    .forEach((data) => {
                        ids.push(data.filterId);
                    });
                const widgetSymbol = new Set<Symbol>();
                ids.forEach((id) => {
                    if (
                        this.filtersService.filtersPageData
                            .getValue()
                            .get(this.filterStoreKey)
                            .has(id)
                    ) {
                        this.filtersService.filtersPageData
                            .getValue()
                            .get(this.filterStoreKey)
                            .get(id)
                            .associatedWidgets.forEach((element) => {
                                if (changedFiltersIds.includes(id)) {
                                    widgetSymbol.add(
                                        element.widgetUniqueIdentifier
                                    );
                                }
                            });
                    }
                });
                this.prepareFilterInputs();
                this.filtersService.refreshWidget.next(widgetSymbol);
                this.filtersService.comapareViewValueChanged = false;
            }
        } else {
            if (
                !this.filtersService.valueChanged &&
                this.compareViewService.isCompareViewEnabled &&
                !this.compareViewService.isCompareViewEnabled.getValue()
            ) {
                this.notificationService.showSnackBar('No Filters Changed');
            } else {
                const changedFiltersIds = this.getChangedFiltersIds();
                if (
                    this.filtersService.checkboxValue &&
                    this.filtersService.checkboxValue.has(
                        this.filterStoreKey
                    ) &&
                    this.filtersService.checkboxValue.get(this.filterStoreKey)
                ) {
                    for (const key of this.filtersService.checkboxValue
                        .get(this.filterStoreKey)
                        .keys()) {
                        changedFiltersIds.push(key);
                    }
                }
                const ids = [];
                this.filtersService.filtersPageData
                    .getValue()
                    .get(this.filterStoreKey)
                    .forEach((data) => {
                        ids.push(data.filterId);
                    });
                const widgetSymbol = new Set<Symbol>();
                ids.forEach((id) => {
                    if (
                        this.filtersService.filtersPageData
                            .getValue()
                            .get(this.filterStoreKey)
                            .has(id)
                    ) {
                        this.filtersService.filtersPageData
                            .getValue()
                            .get(this.filterStoreKey)
                            .get(id)
                            .associatedWidgets.forEach((element) => {
                                if (changedFiltersIds.includes(id)) {
                                    widgetSymbol.add(
                                        element.widgetUniqueIdentifier
                                    );
                                }
                            });
                    }
                });
                this.prepareFilterInputs();
                this.filtersService.refreshWidget.next(widgetSymbol);
                this.filtersService.valueChanged = false;
            }
            this.changedFiltersValue = new Set();
            Helper.markAsPristine(this.formGroup);
        }
    }
    resetFilters() {
        this.filtersService.isFilerReset = true;
        this.changedFiltersValue = new Set();
        Helper.markAsPristine(this.formGroup);
        this.filtersService.filterMenuOpenState.next(false);
        this.filterMenu.close();
        if (this.filterStoreKey !== FilterStoreKey.WEBSITE_FILTERS)
            this.filtersService.currentFilterView = this.filterStoreKey;
        this.filtersService.resetFilters.next(null);
        this.setDefaultValue(true);
        if (this.filterStoreKey === FilterStoreKey.WEBSITE_FILTERS) {
            this.filtersService.filtersFormData.clear();
            const filterData = {
                [this.filterStoreKey]: {},
                [FilterStoreKey.FIRST_VIEW_FILTERS]: {},
                [FilterStoreKey.SECOND_VIEW_FILTERS]: {}
            };
            this.filterCacheService.setFiltersInfo(
                this.userCacheService.emailId,
                this.configCache.viewId,
                filterData
            );
        } else {
            this.filtersService.filtersFormData.delete(this.filterStoreKey);
        }
        const widgetSymbol = new Set<Symbol>();
        this.formGenInputs = null;
        this.filtersService.drawFilter.next(this.filterStoreKey);
        for (const [key, value] of this.filtersService.filtersPageData
            .getValue()
            .get(this.filterStoreKey)
            .entries()) {
            value.associatedWidgets.forEach((element) => {
                widgetSymbol.add(element.widgetUniqueIdentifier);
            });
        }
        this.filtersService.refreshWidget.next(widgetSymbol);
        this.filtersService.valueChanged = false;
    }
    resetAllToDefault() {
        this.filtersService.isFilerReset = false;
        this.changedFiltersValue = new Set();
        Helper.markAsPristine(this.formGroup);
        this.filtersService.filterMenuOpenState.next(false);
        this.filterMenu.close();
        if (this.filtersService.filtersData.get(FilterStoreKey.CONFIGURATION)) {
            this.filtersService.filtersFormData.clear();
            this.setDefaultValue();
            this.filtersService.drawFilter.next(this.filterStoreKey);
            const widgetSymbol = new Set<Symbol>();
            for (const [key, value] of this.filtersService.filtersPageData
                .getValue()
                .get(this.filterStoreKey)
                .entries()) {
                value.associatedWidgets.forEach((element) => {
                    widgetSymbol.add(element.widgetUniqueIdentifier);
                });
            }
            this.filtersService.resetFilters.next(null);
            this.filtersService.refreshWidget.next(widgetSymbol);
        } else {
            this.notificationService.showSnackBar(
                'No default filters set in Configuration'
            );
        }
        this.filtersService.valueChanged = false;
    }
    prepareFilterInputs(isReset?: boolean) {
        if (!this.formGroup) return;
        const filtersInput = new Map();
        const fieldValue = this.formGroup.getRawValue();
        this.formGenInputs.fields.forEach((field: IFormField) => {
            const filterInfo: IFilterInfo =
                this.filtersService.currentPageFilterInfo.get(field.id);
            if (field.fieldType !== FilterType.CONTENT) {
                if (field.groupByKey) {
                    filtersInput.set(field.id, {
                        selector: field.groupByKey,
                        value:
                            filterInfo.type === FilterType.DATE_TIME_RANGE
                                ? this.getDateTimeValue(
                                      fieldValue[field.groupByKey],
                                      field.groupByKey,
                                      field.id
                                  )
                                : field.groupByKey === 'currency'
                                ? this.getCurrencyValue(
                                      fieldValue[field.groupByKey],
                                      field.groupByKey,
                                      field.id
                                  )
                                : fieldValue[field.groupByKey]
                    });
                } else {
                    filtersInput.set(field.id, {
                        selector: field.name,
                        value: fieldValue[field.name]
                    });
                }
            }
        });
        this.filtersService.filtersFormData.set(
            this.filterStoreKey,
            filtersInput
        );
        this.setFilterPageValue(isReset);
    }
    getCurrencyValue(value, key: string, filterId: string) {
        if (typeof value[key] === 'string') {
            const filterInfo: IFilterInfo =
                this.filtersService.currentPageFilterInfo.get(filterId);
            const field = this.formGenInputs.fields.find(
                (field) => field.name === key
            );
            const input =
                field.listData?.find((data) => data.id === value[key]) ??
                filterInfo.selectedValue;

            input['exchangeRate'] = value['exchangeRate'];
            input['defaultCurrencyValue'] = false;
            return input;
        } else {
            const input = {
                ...value[key]
            };
            input['exchangeRate'] = value['exchangeRate'];
            input['defaultCurrencyValue'] =
                value['defaultCurrencyValue'] ?? false;
            return input;
        }
    }
    getDateTimeValue(value, groupByKey: string, filterId: string) {
        const selectedValue = this.getSelectedValue(filterId);
        const startDate = this.getSelectedTime(
            value.startTime,
            selectedValue.startTime
        );
        const endDate = this.getSelectedTime(
            value.endTime,
            selectedValue.endDate
        );
        const selectedDateTimeValue = {
            startDate: `${new Date(value[groupByKey].startDateTime).getTime()}`,
            endDate: `${new Date(value[groupByKey].endDateTime).getTime()}`,
            startTime: `${startDate}`,
            endTime: `${endDate}`
        };

        return selectedDateTimeValue;
    }
    getSelectedValue(filterId: string) {
        const filterInfo: IFilterInfo =
            this.filtersService.currentPageFilterInfo.get(filterId);
        let selectedValue;
        if (
            this.filtersService.filtersData
                .get(this.filterStoreKey)
                .has(filterId)
        ) {
            if (
                'value' in
                    this.filtersService.filtersData
                        .get(this.filterStoreKey)
                        .get(filterId) &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(filterId).value !== null &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(filterId).value !== undefined &&
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .get(filterId).value + ''
            ) {
                selectedValue = JSON.parse(
                    JSON.stringify(
                        this.filtersService.filtersData
                            .get(this.filterStoreKey)
                            .get(filterId).value
                    )
                );
            }
        } else {
            selectedValue = Helper.getFilterDefaultValue(
                filterInfo.type,
                filterInfo.getFullObj
            );
        }
        return selectedValue;
    }
    getSelectedTime(event: string, selectedTime) {
        const time = this.extractHoursMinsFromUITime(event);
        return this.changeTimeInDate(
            selectedTime,
            time.hour,
            time.minute,
            time.meridiem
        );
    }
    extractHoursMinsFromUITime(time: string) {
        const splittedTime = time.split(':');
        const hour = +splittedTime[0];
        const minuteSplit = splittedTime[1].split(' ');
        const minute = +minuteSplit[0];
        const meridiem = minuteSplit[1];
        return {
            hour,
            minute,
            meridiem
        };
    }
    changeTimeInDate(
        date: number,
        hour: number,
        minute: number,
        meridiem: string
    ): number {
        if (meridiem.toLowerCase() === 'am' && hour >= 12) {
            hour = hour - 12;
        } else if (meridiem.toLowerCase() === 'pm' && hour < 12) {
            hour = hour + 12;
        }

        const newDate = moment(new Date(date));
        newDate.hour(hour);
        newDate.minute(minute);

        return newDate.valueOf();
    }
    setFilterPageValue(isReset?: boolean) {
        let filtersData = {};
        const existedFilterData = this.filterCacheService.getFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            this.filterStoreKey
        );

        // Setting checkbox values coreesponding to filter fields
        if (existedFilterData) {
            Object.keys(existedFilterData).map((filterId) => {
                if (isNaN(Number(filterId))) {
                    delete existedFilterData[filterId];
                    return;
                }
                const isCheckedValue =
                    existedFilterData[filterId][this.isChecked];
                if (
                    this.filtersService.checkboxValue &&
                    this.filtersService.checkboxValue.get(
                        this.filterStoreKey
                    ) &&
                    !this.filtersService.checkboxValue
                        .get(this.filterStoreKey)
                        .has(filterId) &&
                    isCheckedValue !== undefined
                ) {
                    this.filtersService.checkboxValue
                        .get(this.filterStoreKey)
                        .set(filterId, isCheckedValue);
                }
            });
        }

        this.sortedFiltersIds.forEach((filterId) => {
            if (
                this.filtersService.currentPageFilterInfo.get(filterId) &&
                this.filtersService.currentPageFilterInfo.get(filterId)
                    .selector === 'frequency'
            ) {
                return;
            }
            filtersData[filterId] = {
                value: this.filtersService.filtersFormData
                    .get(this.filterStoreKey)
                    .get(filterId).value,

                filterInfo:
                    this.filtersService.currentPageFilterInfo.get(filterId)
            };
            if (
                filtersData[filterId] &&
                filtersData[filterId]['filterInfo'] &&
                filtersData[filterId]['filterInfo']['showCheckbox']
            ) {
                filtersData[filterId]['isChecked'] =
                    this.filtersService.checkboxValue
                        .get(this.filterStoreKey)
                        ?.get(filterId) ?? false;
            }
            if (
                filtersData[filterId] &&
                filtersData[filterId].filterInfo &&
                filtersData[filterId].filterInfo['logicalOperatorValue'] &&
                isReset
            ) {
                filtersData[filterId].filterInfo['logicalOperatorValue'] = null;
            }
        });

        if (existedFilterData) {
            filtersData = { ...existedFilterData, ...filtersData };
        }
        const filterData = { [this.filterStoreKey]: filtersData };
        this.filterCacheService.setFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            filterData
        );
    }
    setDefaultValue(isReset?: boolean) {
        let filtersData = this.filterCacheService.getFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            FilterStoreKey.CONFIGURATION
        );
        if (isReset) {
            filtersData = {};
        }
        const filterData = { [this.filterStoreKey]: filtersData };
        this.filterCacheService.setFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            filterData
        );
    }
    generateDateTimeForm(filterId: string, field: IFormField): IFormField[] {
        let selectedValue = this.getSelectedValue(filterId);
        const dateTimeValue = this.filterCacheService.getFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            this.filterStoreKey
        );
        if (dateTimeValue && dateTimeValue[filterId]) {
            selectedValue = dateTimeValue[filterId].value;
        }
        const startTime = moment(parseInt(selectedValue.startTime)).format(
            'LT'
        );
        const endTime = moment(parseInt(selectedValue.endTime)).format('LT');
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: field.label,
                placeholder: field.placeholder,
                name: field.name,
                fieldType: FilterType.DATE_RANGE,
                groupByKey: field.name,
                required: false
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Start Time',
                placeholder: '',
                name: 'startTime',
                fieldType: FilterType.TIME,
                groupByKey: field.name,
                listData: field.listData,
                required: false,
                value: startTime
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'End Time',
                placeholder: '',
                name: 'endTime',
                fieldType: FilterType.TIME,
                groupByKey: field.name,
                listData: field.listData,
                required: false,
                value: endTime
            }
        ];
    }
    generateCustomerUserForm(
        filterId: string,
        field: IFormField
    ): IFormField[] {
        return [
            {
                isFilter: true,
                id: filterId ?? '',
                label: field.label,
                placeholder: field.placeholder,
                name: 'customer',
                fieldType: FilterType.DROPDOWN_SINGLE,
                groupByKey: field.name,
                required: false,
                apiInfo: field.apiInfo,
                onChange: (event, formGroup: FormGroup) => {
                    if (
                        formGroup.get(field.name) &&
                        formGroup.get(field.name).get('users')
                    ) {
                        formGroup.get(field.name).get('users').setValue([]);
                    }
                    this.updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['users']
                    };
                    this.updateControl.next(this.updateControlInput);
                },
                extractValueFromKey: field.name
            },
            {
                isFilter: true,
                id: filterId ?? '',
                label: 'Users',
                placeholder: 'Select Users',
                name: 'users',
                fieldType: FilterType.DROPDOWN_MULTIPLE,
                groupByKey: field.name,
                listData: field.listData,
                listDataExists: true,
                required: false,
                customInputCallback: () => {
                    return this.prepareCustomUserFormInput(field);
                },
                apiInfo: {
                    host: '',
                    apiRouteSuffix:
                        '/identity-access-management/users/filter/user-name',
                    authorization: AuthorizationType.BEARER_TOKEN,
                    requestType: RequestType.POST,
                    customInput: null
                },
                extractValueFromKey: 'users'
            }
        ];
    }
    prepareCustomUserFormInput(field: IFormField) {
        if (
            this.formGroup &&
            this.formGroup.get(field.name) &&
            this.formGroup.get(field.name).get('customer')
        ) {
            return { id: this.formGroup.get(field.name).get('customer').value };
        }
    }

    /**
     * This method populates the Form Group property of the class and fetches the values that have been changed by the user on the UI
     * @param event FormGroup Ref emitted by form-generator
     */

    getFormRef(event) {
        this.formGroup = event;
        this.hideLoader = true;
        const dirtyValues = Helper.getDirtyValues(this.formGroup);
        const dirtyValuesKeys = Object.keys(dirtyValues);
        dirtyValuesKeys.map((key) => {
            this.changedFiltersValue.add(key);
        });
    }

    /**
     * Functoin is responsible for getting filters ids corrosponding to the filters changed by the user
     */
    getChangedFiltersIds() {
        const _changedFiltersIds = [];
        this.formGenInputs.fields.forEach((field) => {
            if (
                Array.from(this.changedFiltersValue.values()).includes(
                    field.name
                ) || Array.from(this.changedFiltersValue.values()).includes(
                    field.groupByKey
                )
            ) {
                _changedFiltersIds.push(field.id);
            }
        });
        return _changedFiltersIds;
    }
    ngOnDestroy() {
        if (this.isCompareView || this.filtersService.isCompareViewEnabled) {
            this.filtersService.isCompareViewEnabled = false;
            const filterData = { [FilterStoreKey.FIRST_VIEW_FILTERS]: {} };
            this.filterCacheService.setFiltersInfo(
                this.userCacheService.emailId,
                this.configCache.viewId,
                filterData
            );
        }
        this.subSink.unsubscribe();
    }
}
enum LayerNames {
    layer1 = 'layer1',
    layer2 = 'layer2',
    layer3 = 'layer3',
    layer4 = 'layer4'
}

interface IAclViewLayerData {
    acl: string[];
    view: string;
    [LayerNames.layer1]: string;
    [LayerNames.layer2]: string;
    [LayerNames.layer3]: string;
    [LayerNames.layer4]: string;
}
