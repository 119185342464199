<div class="overlay" *ngIf="!stepOneFormGenInput">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>
<div class="container" *ngIf="stepOneFormGenInput">
    <div class="flex-container">
        <div class="form-container">
            <app-form-generator
                *ngIf="stepOneFormGenInput"
                [formGeneratorInput]="stepOneFormGenInput"
                (formGroupRef)="stepOneFormGroup = $event"
            ></app-form-generator>
        </div>
    </div>
    <div class="controls">
        <app-button-generator
            [buttonGenInput]="nextButtonGenInput"
        ></app-button-generator>
    </div>
</div>
