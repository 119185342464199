import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';

@Component({
    selector: 'app-number-field-cell-editor',
    templateUrl: './number-field-cell-editor.component.html',
    styleUrls: ['./number-field-cell-editor.component.sass'],
})
export class NumberFieldCellEditorComponent
    implements ICellEditorAngularComp, AfterViewInit
{
    params: any;
    gridRef: GridApi;
    value: number;
    highlightAllOnFocus = true;
    cancelBeforeStart = false;

    isSelected: boolean = false;

    @ViewChild('numberInput', { read: ViewContainerRef })
    input: ViewContainerRef;

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.gridRef = params.api;
        this.setInitialState(this.params);
        this.isSelected =
            !!params.charPress ||
            params.keyPress in [KeyCodes.KEY_F2, KeyCodes.KEY_ENTER];
        this.cancelBeforeStart = !!(
            params.charPress && '1234567890'.indexOf(params.charPress) < 0
        );
    }

    setInitialState(params: ICellEditorParams) {
        this.value = params.value;
        if (params.keyPress === KeyCodes.KEY_F2) {
            this.highlightAllOnFocus = false;
        }
    }

    getValue(): any {
        if (this.params.onValueChange) {
            this.params.onValueChange(
                this.value,
                this.params.rowIndex,
                this.params.colDef.field
            );
        }
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    onKeyDown(event: any): void {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedNumeric(event)
        ) {
            if (event.preventDefault) event.preventDefault();
        }
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            if (
                this.params &&
                this.params.isFocused &&
                this.params.isFocused(this.params.index, this.isSelected)
            ) {
                if (this.highlightAllOnFocus) {
                    this.input.element.nativeElement.select();

                    this.highlightAllOnFocus = false;
                }
                this.input.element.nativeElement.focus();
            }
        });
    }

    private isKeyPressedNumeric(event: any): boolean {
        return !!/\d/.test(event.key);
    }

    private deleteOrBackspace(event: any) {
        return (
            event.keyCode === KeyCodes.KEY_DELETE ||
            event.keyCode === KeyCodes.KEY_BACKSPACE
        );
    }

    private isLeftOrRight(event: any) {
        return (
            event.keyCode === KeyCodes.KEY_ARROW_LEFT ||
            event.keyCode === KeyCodes.KEY_ARROW_RIGHT
        );
    }

    private finishedEditingPressed(event: any) {
        return (
            event.keyCode === KeyCodes.KEY_ENTER ||
            event.keyCode === KeyCodes.KEY_TAB
        );
    }
}

enum KeyCodes {
    KEY_BACKSPACE = 8,
    KEY_DELETE = 46,
    KEY_F2 = 113,
    KEY_ENTER = 13,
    KEY_TAB = 9,
    KEY_ARROW_LEFT = 37,
    KEY_ARROW_RIGHT = 39,
}
