import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { Helper } from './../../classes/Helper';

@Component({
    selector: 'app-field-generator-handler',
    templateUrl: './field-generator-handler.component.html',
    styleUrls: ['./field-generator-handler.component.sass']
})
export class FieldGeneratorHandlerComponent implements OnInit, OnChanges {
    @Input() fieldData: IFormField;
    @Input() listData;
    @Input() validate: boolean;
    @Output() change = new EventEmitter();
    @Input() selectedValue;
    @Input() selectedCustomFieldValue;
    formGenInput: IFormGeneratorInput = null;
    formGroup: FormGroup;
    constructor() {}

    ngOnChanges(change) {
        if (
            this.listData &&
            change.listData &&
            change.listData.currentValue &&
            change.listData.currentValue[this.fieldData.name]
        ) {
            this.fieldData.listData =
                change.listData.currentValue[this.fieldData.name];
            this.formGenInput = { ...this.formGenInput };
        }

        if (change.validate && change.validate.currentValue) {
            Helper.markAllFieldAsTouched(this.formGroup);
        }
    }

    ngOnInit(): void {
        if (this.fieldData.getKey === '') {
            this.fieldData.getKey = null;
        }

        // validation handling
        if (this.fieldData.required) {
            this.fieldData.validations = [
                {
                    validator: CustomValidators.required,
                    errorMessage: this.fieldData.label + ' is required'
                }
            ];
        } else {
            this.fieldData.required = false;
        }

        // disable only if response from backend have value key in it
        if (this.fieldData.value && this.fieldData.value.length) {
            this.fieldData.disabled = true;
        }

        // Handling default value
        if (
            this.selectedValue &&
            this.selectedValue[this.fieldData.name] &&
            !this.fieldData.customField
        ) {
            this.fieldData.value = this.selectedValue[this.fieldData.name];
        }

        if (
            this.selectedCustomFieldValue &&
            this.selectedCustomFieldValue[this.fieldData.name] &&
            this.fieldData.customField
        ) {
            this.fieldData.value =
                this.selectedCustomFieldValue[this.fieldData.name];
        }

        // handling placeholder
        if (!this.fieldData.placeholder) {
            this.fieldData.placeholder = this.fieldData.label;
        }

        this.formGenInput = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [this.fieldData]
        };
    }

    fieldSelectionChange(value) {
        let obj = {};
        if (this.fieldData.getKey === null) {
            obj = {
                value: value[this.fieldData.name],
                otherFieldList: value[this.fieldData.name]
            };
        } else {
            obj = {
                custom: this.fieldData.customField,
                value: value
            };
        }
        this.change.emit(obj);
    }
}
