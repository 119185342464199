import { Pipe, PipeTransform } from '@angular/core';
import { FileInput } from 'ngx-material-file-input';

@Pipe({
    name: 'imageUrlFromFile'
})
export class ImageUrlFromFilePipe implements PipeTransform {
    readonly LOADER_IMAGE_URL = `assets/loader.png`;

    transform(value: FileInput, imageElement: HTMLImageElement): string {
        if (typeof value === 'string') {
            // Found image
            return value;
        }
        const reader = new FileReader();
        reader.readAsDataURL(value.files[0]);
        reader.onload = (event) => {
            imageElement.src = `${event.target.result}`;
        };
        return this.LOADER_IMAGE_URL;
    }
}
