import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { MyErrorStateMatcher } from './../../../classes/ErrorStateMatcher';
import { FilterType } from './../../../enums/FilterType';
import { FormState } from './../../../enums/FormState';
import { IFormField } from './../../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-wysiwyg-editor-field',
    templateUrl: './wysiwyg-editor-field.component.html',
    styleUrls: ['./wysiwyg-editor-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WysiwygEditorFieldComponent,
            multi: true
        }
    ]
})
export class WysiwygEditorFieldComponent
    implements OnInit, ControlValueAccessor, AfterViewInit
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    FilterType = FilterType;
    FormState = FormState;
    onChange: any = () => {};
    onTouch: any = () => {};
    FieldAppearance = WysiwygEditorField;
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    leftOverCharacterCount: number;
    totalCharacterCount: number;
    @ViewChild('editor') editor: any;
    previousAlignment: string;
    previousCommand: string;
    sameCommand: boolean;
    previousContent: any = '';
    tinyEditor: any;
    totalListparents: any;
    pasteLocation: any;
    Editor: Map<
        string,
        {
            id: string;
            config: any;
        }
    > = new Map<
        string,
        {
            id: string;
            config: any;
        }
    >();

    constructor(private notifcationService: NotificationsService) {}

    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (
            !existingAppearance.includes(
                this.field.appearance as WysiwygEditorField
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
        if (this.field.characterLimit) {
            const text = Helper.stringToHTML(this.field.value).body.innerText;
            this.setUpCharacterCount(text !== 'undefined' ? text : '');
            this.control.valueChanges.subscribe((data) => {
                this.setUpCharacterCount(
                    Helper.stringToHTML(data).body.innerText
                );
            });
        }
        this.Editor.set(this.field.name, {
            id: Helper.generateUniqueKey(16),
            config: Helper.getSummernoteConfig({
                placeholder: this.field.placeholder,
                hideInsertOperations:
                    this.field.hideWysiwygEditorInsertOperation,
                disableFontChange: this.field.disableFontChange
            })
        });
    }

    setUpCharacterCount(text: string) {
        this.totalCharacterCount = text.length;
        this.leftOverCharacterCount =
            this.field.characterLimit - this.totalCharacterCount;
        this.field.customInputCallback
            ? this.field.customInputCallback(
                  this.totalCharacterCount,
                  this.leftOverCharacterCount
              )
            : null;
    }

    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }

    writeValue(value: string): void {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    ngAfterViewInit() {
        if (this.editor && this.editor.editor) {
            const tinymceEditor = this.editor.editor;
            tinymceEditor.on('focus', (event) => {
                if (this.field && this.field.onFocus)
                    this.field?.onFocus(event, this.formGroup, this.field.name);
            })
            
            // Setting Placeholder property.
            tinymceEditor.targetElm.placeholder = this.field.editorPlaceholder ? this.field.editorPlaceholder : '';

            this.addResizeEventOnTable(tinymceEditor);
            tinymceEditor.on('contextmenu', ()=> {
                    setTimeout(() => {
                        // Removing Table Properties from Table Menu Dropdown and Right-Click (Context) Menu
                        const divElement = document.querySelector('div[title="Table properties"]') as HTMLDivElement;
                        divElement?.style.setProperty('display', 'none');

                        // Removing Cell Properties From the Table Menu
                        const cellMenu = document.querySelector('div[title="Cell"]');
                        cellMenu?.addEventListener('mouseover', () => {
                            const divElement = document.querySelector('div[title="Cell properties"]') as HTMLDivElement;
                            divElement?.style.setProperty('display', 'none');
                        })

                        // Removing Row Properties From the Table Menu
                        const rowMenu = document.querySelector('div[title="Row"]');
                        rowMenu?.addEventListener('mouseover', () => {
                            const divElement = document.querySelector('div[title="Row properties"]') as HTMLDivElement;
                            divElement?.style.setProperty('display', 'none');
                        })
                    }, 0);
            });

            tinymceEditor.on('ExecCommand', (e) => {
                // Check for text alignment commands
                if (e.command === 'mceToggleFormat') {
                    const listItems =
                        tinymceEditor.dom.doc.querySelectorAll('li');
                    listItems.forEach((item) => {
                        let fontSize = 'h6';
                        item.childNodes.forEach((key) => {
                            if (
                                key.localName === 'h2' ||
                                key.localName === 'h1'
                            ) {
                                fontSize = 'h1';
                            } else if (key.localName === 'h3') {
                                fontSize = 'h3';
                            } else if (key.localName === 'h4') {
                                fontSize = 'h4';
                            } else if (key.localName === 'p') {
                                fontSize = 'p';
                            }
                        });
                        item.classList.remove('h1', 'p', 'h3', 'h4', 'h6');
                        item.classList.add(fontSize);
                    });
                }

                // To Prevent Table Being Inserted into Table.
                if (
                    e.command === 'mceInsertContent' ||
                    e.command === 'mceInsertTable'
                ) {
                    // GEtting all images from the content.
                    const imgTags = tinymceEditor?.dom?.doc?.querySelectorAll('img');
                    let validImageFormat = this.validateImageFormat(imgTags);
                    if (!validImageFormat) {
                        this.notifcationService.showSnackBar(
                            'Invalid image format. The formats supported are PNG, JPEG, WEBP and JPG.',
                            true,
                            '',
                            { duration: 3000 }
                        );
                        validImageFormat = true;
                    }
                    this.insertClassToTable(tinymceEditor);
                    this.preventTableInsideTable(tinymceEditor);
                    if (e.value?.content?.includes('li'))
                        this.restrictNoOfIndents(tinymceEditor, e.command, '');
                }

                if (e.command === 'indent')
                    this.restrictNoOfIndents(tinymceEditor, e.command, '');
            });

            tinymceEditor.on('KeyDown', (e) => {
                if (e.keyCode === 9)
                    this.restrictNoOfIndents(tinymceEditor, '', e);
            });

            // Function to handle pre-processing of data before pasting into the editor.
            tinymceEditor.on('PastePreProcess', (e) => {
                this.previousContent = tinymceEditor.getContent();
                this.pasteLocation = tinymceEditor.selection.getNode();
                // To remove 'list-style-type' from inline styling
                e.content = this.removeInlineListStyle(e.content)
            });
        }
        setTimeout(() => {
            if (this.field.appearance === this.FieldAppearance.TYPE_3) {
                const editor = document.getElementById(
                    this.Editor?.get(this.field.name)?.id
                );
                const tableProp = editor?.getElementsByClassName(
                    'tox-toolbar-overlord'
                )[0];

                tableProp?.addEventListener('click', (event) => {
                    setTimeout(() => {
                        const divElement = document.querySelector(
                            'div[title="Table properties"]'
                        ) as HTMLDivElement;
                        divElement?.style.setProperty('display', 'none');
                        // Removing Cell Properties From the Table Menu
                        const cellMenu = document.getElementsByClassName('tox-menu')[0]?.querySelector('div[title="Cell"]');
                        cellMenu?.addEventListener('mouseover', () => {
                            const divElement = document.querySelector('div[title="Cell properties"]') as HTMLDivElement;
                            divElement?.style.setProperty('display', 'none');
                        });
                        // Removing Row Properties From the Table Menu
                        const rowMenu = document.getElementsByClassName('tox-menu')[0]?.querySelector('div[title="Row"]');
                        rowMenu?.addEventListener('mouseover', () => {
                            const divElement = document.querySelector('div[title="Row properties"]') as HTMLDivElement;
                            divElement?.style.setProperty('display', 'none');
                        });
                    }, 10);
                });
            }
        }, 2000);
    }

    restrictNoOfIndents(editor: any, command?: string, e?: any): void {
        if (command === 'mceInsertContent') {
            this.removeIndentationOnPaste(editor, this.previousContent);
        } else this.restrictIndentationWhileCreation(editor, e, command);
    }

    // Function to insert default class to all tables.
    insertClassToTable(tinymceEditor: any) {
        const tableList = tinymceEditor.dom.doc.querySelectorAll('table');
        tableList?.forEach((table: HTMLTableElement) => {
            table.classList.add('content-table');
            table.style.width = '680px';
        });
    }

    // Function to prevent inserting table inside tabel.
    preventTableInsideTable(tinymceEditor: any) {
        let selectedNode = tinymceEditor.selection.getNode();
        while (selectedNode) {
            const tables = selectedNode.querySelectorAll('table');
            tables.forEach((table) => {
                const nestedTables = table.querySelectorAll('table');
                if (nestedTables && nestedTables.length > 0) {
                    nestedTables.forEach((nestedTable) => {
                        nestedTable.remove();
                    });
                }
            });
            selectedNode = selectedNode.parentNode;
        }
    }

    // Function to limit No. of Indentation by 4 In editor.
    restrictIndentationWhileCreation(editor, e, command) {
        let selectedElement = editor.selection.getNode(); // Current Node/Element
        let liParentCount = 0;

        // Remove all the empty cite elements.
        editor.dom.doc.querySelectorAll('cite').forEach((cite) => {
            if (!cite.textContent) cite.remove();
        });
        editor.dom.doc.querySelectorAll('sup').forEach((sup) => {
            if (!sup.textContent) sup.remove();
        });
        editor.dom.doc.querySelectorAll('sub').forEach((sub) => {
            if (!sub.textContent) sub.remove();
        });
        while (selectedElement) {
            if (selectedElement.localName !== 'li'
                && selectedElement.localName !== 'ul' &&
                selectedElement.localName !== 'ol') {
                selectedElement = selectedElement?.parentNode;
                continue;
            }
            const parentNodeName = selectedElement?.parentNode?.nodeName;
            if (parentNodeName === 'LI') {
                liParentCount++;
                if (
                    (liParentCount >= 3 && e?.keyCode === 9) ||
                    (liParentCount >= 4 && command === 'indent')
                ) {
                    editor.execCommand('outdent');
                    Helper.showErrorMessage(
                        this.notifcationService,
                        'Indentation limit exceeded. The limit allowed is 4.'
                    );
                    break;
                }
            }
            // Break The loop, as we don't need to check beyond body.
            if (parentNodeName === 'BODY') break;
            // Updating selected element with the parentNode of current element.
            selectedElement = selectedElement.parentNode;
        }
    }

    // Function to remove Element from the pasted Content if indentation more than 4.
    removeIndentationOnPaste(editor, previousContent) {
        // Common Function to Handle OL/UL Elements.
        this.processNestedLists(editor, 4, previousContent);

    }

    processNestedLists(editor, maxNestedLevel, previousContent) {
        let selectedElement = editor.selection.getNode();
        let validated: boolean = true;

        const indentUlList = this.validHTMLContentAndList(selectedElement, maxNestedLevel);
        const validHtml = indentUlList.length <= maxNestedLevel ? true : false;
        if (!validHtml) {
            validated = false;
        }

        if (!validated) {
            this.totalListparents = [];
            Helper.showErrorMessage(
                this.notifcationService,
                'Indentation limit exceeded. The limit allowed is 4.'
            );
            editor.setContent(previousContent);
        }
    }

    validHTMLContentAndList(selectedElement, maxNestedLevel: number): any {
        let valid = true;
        let indentUlList = [];

        // If pasteLocation equals selectedElement,
        // then asgining selectedElement it's previous Element.
        if (this.pasteLocation === selectedElement) {
            // If selected element is not 'li' then iterating till we get 'LI'
            if (selectedElement && selectedElement.nodeName !== 'LI') {
                while (selectedElement) {
                    if (selectedElement.parentNode.nodeName === 'LI') {
                        selectedElement = selectedElement.parentNode;
                        break;
                    }
                    selectedElement = selectedElement.parentNode;
                }
            }
            // Assigning 'LI' element to selected Element.
            selectedElement = selectedElement.previousElementSibling;
            // Now assigining selected element the last node or last child of pasted list items.
            while (selectedElement) {
                if (selectedElement.lastChild === null || selectedElement.lastChild.nodeName === '#text')
                    break;
                selectedElement = selectedElement.lastChild;
                if (selectedElement && selectedElement.localName === 'br')
                    break;
            }
        }
        // Finally checking the Level of Indentation.
        while (selectedElement && selectedElement?.nodeName !== 'BODY' && valid) {
            if (selectedElement.localName === 'ul' || selectedElement.localName === 'ol') {
                if (indentUlList.length > maxNestedLevel) {
                    valid = false;
                }
                indentUlList.push(selectedElement);
            }
            selectedElement = selectedElement?.parentNode;
        }
        return indentUlList;
    }

    // Function to validate the Image Formats.
    validateImageFormat(imgTags: any): boolean {
        let validImageFormat = true;
        if (imgTags && imgTags.length > 0) {
            imgTags.forEach((imgTag) => {
                const imageUrl = imgTag.src.toLowerCase();
                if (imageUrl.endsWith('.jpg') || imageUrl.endsWith('.jpeg') ||
                    imageUrl.endsWith('.png') || imageUrl.endsWith('.webp') ||
                    imageUrl.startsWith('blob')) {
                    // Do Nothing
                } else {
                    imgTag.remove();
                    validImageFormat = false;
                }
                imgTag.removeAttribute('srcset');
            });
        }
        return validImageFormat;
    }

    removeInlineListStyle(content): string {
        const regex = /list-style-type\s*:\s*[^;]+;/g;
        const updatedContent = content.replace(regex, '');
        return updatedContent;
    }

 /**
  * Adds a resize event listener on the given TinyMCE editor to handle table resizing.
  * When the table is resized, it adjusts the width of the table and displays an error message
  * if the width exceeds 700px.
  * @param {any} tinymceEditor - The TinyMCE editor instance.
  * @returns None
  */
	addResizeEventOnTable(tinymceEditor: any) {
        tinymceEditor.on('ObjectResized', (event) => {
            setTimeout(() => { 
                    const dataAttribute = event?.target?.attributes['data-mce-style'];
                if (dataAttribute) {
                    const currentStyle = event.target.getAttribute('data-mce-style');
                    if (currentStyle) {
                        const modifiedStyle = currentStyle.replace(/width:\s*[^;]+;/, `width: ${event.width}px;`);
                        event.target.setAttribute('data-mce-style', modifiedStyle);
                        event.target.setAttribute('style', modifiedStyle);
                    }
                }
            }, 0);
            event.target.style.width = event.width + 'px';
            if (parseInt(event.width) > 680) {
                const sourceOfResize = event.origin;
                if (sourceOfResize !== 'bar-row') {
                    this.notifcationService.showSnackBar(
                        'The table width is more than 680 pixels. Hence, the table will break in the PDF report.',
                        true,
                        '',
                        { duration: 3000 }
                    );
                }
            }
		});
    }
}
