import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class DomainValidator extends CustomValidator {
    key = CustomValidatorKey.DOMAIN_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const URL_REGEXP = /[A-Za-z\d._-]*\.[a-z]*/g;
        return Validators.pattern(URL_REGEXP)(control);
    }
}
