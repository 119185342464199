import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MultiStepFormService {
    currentStep: Map<Symbol, BehaviorSubject<number>> = new Map();
    totalSteps: Map<Symbol, number> = new Map();
    stepData: Map<Symbol, Map<number, any>> = new Map();

    constructor() {}

    nextStep(modalId: Symbol) {
        if (
            this.currentStep.get(modalId).value < this.totalSteps.get(modalId)
        ) {
            this.currentStep
                .get(modalId)
                .next(this.currentStep.get(modalId).value + 1);
        }
    }

    previousStep(modalId: Symbol) {
        if (this.currentStep.get(modalId).value >= 2) {
            this.currentStep
                .get(modalId)
                .next(this.currentStep.get(modalId).value - 1);
        }
    }
}
