import { Component, OnInit } from '@angular/core';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IconType } from './../../../../enums/IconType';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-rightsizing-modal',
    templateUrl: './rightsizing-modal.component.html',
    styleUrls: ['./rightsizing-modal.component.sass']
})
export class RightsizingModalComponent implements OnInit {
    data;
    objectKeys = Object.keys;
    keys = [];
    selectedTab = '';
    visibleTabs = [];
    tabs = [];
    recommendationTableTabData: Map<string, object[]> = new Map<
        string,
        object[]
    >();
    amortizedTableTabData: Map<string, object[]> = new Map<string, object[]>();
    text = 'Type';
    card = [];
    recommendationTableGen: ITableGeneratorInput;
    recommendationTableData: object[];
    recommendationGrid: GridOptions;
    amortizedTableGen: ITableGeneratorInput;
    amortizedTableData: object[];
    amortizedGrid: GridOptions;
    descriptionTableData: object[];
    descriptionTableGenInput: ITableGeneratorInput;
    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    staticTabs = [
        'CPU Based',
        'Memory Based',
        'IOPS Based',
        'Network Based',
        'Ideal Based'
    ];

    constructor(
        public modalData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.data = modalData.data;
    }
    ngOnInit() {
        this.card = this.data.card;
        this.tabs = this.data['dataMap']['tabs']
            ? this.data['dataMap']['tabs']
            : [];

        if ('key' in this.data['dataMap']) {
            this.text = this.data['dataMap']['key'];
        }

        this.keys = Object.keys(this.card['Current']);

        const tabs = this.tabs.length ? this.tabs : this.staticTabs;
        tabs.forEach((tab) => {
            if (Object.keys(this.card).includes(tab)) {
                this.visibleTabs.push(tab);
            }
        });

        this.selectedTab = this.visibleTabs[0];
        this.initRecommendationTable();
        this.initAmortizedTable();
        this.recommendationTableGen.gridOptions.alignedGrids.push(
            this.amortizedTableGen.gridOptions
        );
        this.amortizedTableGen.gridOptions.alignedGrids.push(
            this.recommendationTableGen.gridOptions
        );
        this.initDescriptionTableGen();
    }

    isObject(val) {
        return typeof val === 'object';
    }

    handleCloseModal() {
        this.modalService.closeModal(null, this.modalData.modalId);
    }

    refreshTable() {
        this.initRecommendationTable(false);
        this.initAmortizedTable(false);
    }

    initRecommendationTable(setTabData = true) {
        const data = Helper.cloneDeep(this.data);
        const columns: IColumnData[] = [
            {
                columnKey: this.text,
                columnName: this.text,
                pinned: 'left',
                minWidth: 300,
                cellClass: 'pinned-key'
            }
        ];

        data.card[this.selectedTab]['recommendationOrder'].forEach((key) => {
            columns.push({
                columnKey: key,
                columnName: key,
                minWidth: 275,
                cellClass: 'value-column'
            });
        });

        if (setTabData) {
            this.tabs.forEach((tabName) => {
                const configuration = data.card[tabName]?.['Configuration'];
                const tableData = [];
                if (configuration) {
                    Object.keys(configuration).forEach((key) => {
                        const tempTableData = configuration[key];
                        tempTableData[this.text] = key;
                        tableData.push(tempTableData);
                    });
                    this.recommendationTableTabData.set(tabName, tableData);
                }
            });
        }
        this.recommendationTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true,
            gridOptions: {
                alignedGrids: []
            }
        };
        this.recommendationTableData = this.recommendationTableTabData.get(
            this.selectedTab
        );

        if (!setTabData) {
            this.recommendationGrid.api.setRowData(
                this.recommendationTableTabData.get(this.selectedTab)
            );
            this.recommendationGrid.api.redrawRows();
        }
    }

    initAmortizedTable(setTabData = true) {
        const data = Helper.cloneDeep(this.data);
        const columns: IColumnData[] = [
            {
                columnKey: this.text,
                columnName: this.text,
                pinned: 'left',
                minWidth: 300,
                cellClass: 'pinned-key'
            }
        ];

        data.card[this.selectedTab]['recommendationOrder'].forEach((key) => {
            columns.push({
                columnKey: key,
                columnName: key,
                minWidth: 275,
                cellClass: 'value-column'
            });
        });

        if (setTabData) {
            this.tabs.forEach((tabName) => {
                const amortizedPrice = data.card[tabName]?.['Amortized Price'];
                const tableData = [];
                if (amortizedPrice) {
                    Object.keys(amortizedPrice).forEach((key) => {
                        const tempTableData = amortizedPrice[key];
                        tempTableData[this.text] = key;
                        tableData.push(tempTableData);
                    });
                    this.amortizedTableTabData.set(tabName, tableData);
                }
            });
        }

        this.amortizedTableGen = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns,
            tableAutoHeight: true,
            headerHeight: 0,
            gridOptions: {
                alignedGrids: []
            }
        };
        this.amortizedTableData = this.amortizedTableTabData.get(
            this.selectedTab
        );

        if (!setTabData) {
            this.amortizedGrid.api.setRowData(
                this.amortizedTableTabData.get(this.selectedTab)
            );
            this.amortizedGrid.api.redrawRows();
        }
    }

    initDescriptionTableGen() {
        const data = Helper.cloneDeep(this.data.card['Current']);
        const dataKeys = Object.keys(data);
        const columns: IColumnData[] = [
            {
                columnKey: 'key',
                columnName: 'key',
                pinned: 'left',
                cellClass: 'pinned-key'
            },
            {
                columnKey: 'value',
                columnName: 'value',
                cellClass: 'value-column',
                cellRenderer: (rowData: ICellRendererParams) => {
                    if (
                        typeof rowData.value === 'object' &&
                        rowData.value !== null
                    ) {
                        const data = rowData.value;
                        const div = document.createElement('div');
                        div.style.overflow = 'auto';
                        if (Object.keys(data).length > 5) {
                            div.style.height = `${
                                Object.keys(data).length * 27
                            }px`;
                        }
                        Object.keys(data).forEach((key) => {
                            const childDiv = document.createElement('div');
                            childDiv.classList.add('tw-flex');
                            childDiv.innerHTML = `<span class="tw-font-bold">${key}</span> => ${data[key]}`;
                            div.appendChild(childDiv);
                        });
                        return div;
                    } else {
                        const div = document.createElement('div');
                        const data = rowData.value;
                        div.style.overflow = 'auto';
                        dataKeys.forEach((key) => {
                            if (key === 'Resource Id') {
                                const childDiv = document.createElement('div');
                                childDiv.classList.add('tw-flex');
                                childDiv.innerHTML = `<span>${data}</span>`;
                                div.appendChild(childDiv);
                            }
                        });
                        return div;
                    }
                }
            }
        ];
        const tableData: object[] = [];
        Object.keys(data).forEach((colKey) => {
            tableData.push({ key: colKey, value: data[colKey] });
        });
        this.descriptionTableData = tableData;
        let rowHeightDesc: number;
        this.descriptionTableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            columns,
            headerHeight: 0,
            getRowHeight: (params) => {
                if (typeof params.data.value === 'object') {
                    Object.keys(data).forEach(keysValue => {
                        if (typeof data[keysValue] === 'object') {
                            rowHeightDesc = Object.keys(data[keysValue]).length * 32
                        }
                    })
                    return rowHeightDesc;
                }
                return;
            }
        };
    }
}
