export enum FileType {
    EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    PDF = 'application/pdf',
    HTML = 'text/html',
    MS_WORD_DOC = 'application/msword',
    MS_WORD_DOCS = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    CSV = 'text/csv',
    ZIP = 'application/zip',
    JSON = 'application/JSON'
}
