<div
    class="controls"
    *ngIf="
        !loader &&
        !(extraMessage | async) &&
        !(errorMessage | async) &&
        !hideControlBar
    "
>
    <div class="overview" *ngIf="overviewTemplateStart">
        <ng-container *ngTemplateOutlet="overviewTemplateStart"></ng-container>
    </div>
    <div class="buttons-container" *ngIf="tableInput.buttons">
        <app-multi-button-generator [buttonGenInputs]="tableInput.buttons">
        </app-multi-button-generator>
    </div>

    <div
        class="overview"
        *ngIf="overviewTemplateEnd"
        style="margin-left: auto; margin-right: 24px"
    >
        <ng-container *ngTemplateOutlet="overviewTemplateEnd"></ng-container>
    </div>
    <div
        *ngIf="rowData && rowData.length && !loader && showSearchBox"
        class="table-search"
        [style.marginLeft]="'auto'"
    >
        <app-table-search
            [searchFunction]="onQuickFilterChanged.bind(this)"
            [searchPlaceholder]="tableInput.searchPlaceholder"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    [class.tw-h-fit]="tableInput.tableAutoHeight || tableAutoHeight"
    *ngIf="!(extraMessage | async) && !(errorMessage | async)"
>
    <div class="overlay" *ngIf="loader">
        <div class="overlay-content">
            <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
        </div>
    </div>
    <div
        class="table-container"
        *ngIf="rowData && rowData.length && !loader"
        [id]="tableId"
        appResizeObserver
        (resize)="handleResize()"
    >
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham material-icons {{
                tableInput.extraClass ? tableInput.extraClass : ''
            }}"
            [id]="tableId"
            [suppressRowTransform]="tableInput.suppressRowTransform"
            [class.auto-height]="tableInput.tableAutoHeight || tableAutoHeight"
            [gridOptions]="tableInput.gridOptions"
            [headerHeight]="tableInput.headerHeight"
            [groupHeaderHeight]="tableInput.groupHeaderHeight"
            [rowData]="rowData"
            [columnDefs]="colDefs"
            [animateRows]="true"
            [rowHeight]="tableInput.rowHeight ? tableInput.rowHeight : 37"
            [getRowHeight]="
                tableInput.getRowHeight ? tableInput.getRowHeight : null
            "
            [rowSelection]="
                tableInput.rowSelection ? tableInput.rowSelection : 'multiple'
            "
            [rowMultiSelectWithClick]="true"
            [suppressFieldDotNotation]="true"
            [stopEditingWhenGridLosesFocus]="stopEditingWhenGridLosesFocus"
            [defaultColDef]="!tableInput.gridOptions ? defaultColDef : null"
            [stopEditingWhenGridLosesFocus]="true"
            [isExternalFilterPresent]="
                tableInput.isExternalFilterPresent
                    ? tableInput.isExternalFilterPresent
                    : null
            "
            [doesExternalFilterPass]="
                tableInput.doesExternalFilterPass
                    ? tableInput.doesExternalFilterPass
                    : null
            "
            (gridReady)="onGridReady($event)"
            [tooltipShowDelay]="500"
            [icons]="agGridIcons"
            [domLayout]="tableInput.tableAutoHeight ? 'autoHeight' : null"
            (selectionChanged)="selectionChanged($event)"
            [rowClassRules]="
                widgetRef && widgetRef.tableRowClassRules
                    ? widgetRef.tableRowClassRules
                    : tableRowClassRules
            "
            [frameworkComponents]="frameworkComponents"
            [suppressClickEdit]="true"
            (cellValueChanged)="cellValueChanged.emit($event)"
            [style.height.px]="
                tableInput.tableAutoHeight
                    ? null
                    : tableInput.tableHeight
                    ? tableInput.tableHeight
                    : 200
            "
            [isRowSelectable]="
                tableInput.isRowSelectable ? tableInput.isRowSelectable : null
            "
            [getRowStyle]="getRowStyle"
            (rowClicked)="handleRowClick($event)"
            (bodyScroll)="scroll.emit($event)"
            [editType]="tableInput.editType"
        >
        </ag-grid-angular>
    </div>
</div>

<div class="overview" *ngIf="overviewTemplateBottom">
    <ng-container *ngTemplateOutlet="overviewTemplateBottom"></ng-container>
</div>

<div class="extra-message" *ngIf="extraMessage | async">
    {{ extraMessage | async }}
</div>
<div
    class="error-message"
    *ngIf="!tableInput.hideError && errorMessage | async"
>
    <i class="fas fa-exclamation-circle"></i>
    {{ errorMessage | async }}
</div>
