import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-create-email-template',
    templateUrl: './create-email-template.component.html',
    styleUrls: ['./create-email-template.component.sass']
})
export class CreateEmailTemplateComponent implements OnInit {
    macroButtonGenInput: Map<String, IButtonGeneratorInput> = new Map();
    buttonData: Map<string, IButtonGeneratorInput>;
    stepData: any;
    plusIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'plus_icon',
        extraClass: 'action-icon'
    };
    saveEmailTemplateButtonGenInput: IButtonGeneratorInput;
    activeField: string = '';
    isEdit: boolean = false;
    isView: boolean = false;
    widgetRef: Widget;
    emailTemplateFormGenInput: IFormGeneratorInput;
    emailTemplateFormGroup: FormGroup;
    isMacrosDisabled: boolean = false;
    macrosList: IDropdownData[] = [];
    searchText: string = '';
    filteredMacros: IDropdownData[] = [];
    constructor(
        private modalData: ModalInjectedData,
        private notificationService: NotificationsService,
        private cdRef: ChangeDetectorRef,
        public httpService: HttpService,
        public ngZone: NgZone,
        private modalService: ModalService
    ) {
        this.widgetRef = this.modalData.data.widgetRef
    }

    ngOnInit() {
        // Fetching Macros List if api failed then setting default macros list.
        this.getMacrosList();

        this.isEdit = this.modalData.data['isEdit'];
        if (this.modalData.data && (this.modalData.data['isEdit'] || this.modalData.data['isView'] || this.modalData.data['isDuplicate'])) {
            this.stepData = this.modalData.data['stepData'];
        }
        if (this.modalData.data && this.modalData.data['isView']) {
            this.isView = this.modalData.data['isView'];
            this.isMacrosDisabled = true;
        }

        if (this.modalData.data['isDuplicate']) {
            this.stepData['templateName'] = this.stepData['templateName'] + ' - copy'
        }

        this.saveEmailTemplateButtonGenInput = {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            disable: this.isView,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.generateEmailTemplateRequest(buttonRef);
            },
            customClass: 'save-email-template'
        };
        this.setEmailBody(this.stepData?.['emailBody'] ?? '', (emailBody: string) => {
            this.emailTemplateFormGenInput = {
                formName: 'Email Template',
                state: this.isView ? FormState.IDLE : FormState.CREATE,
                submitButton: null,
                extraClass: 'email-template-form-fields',
                fields: [
                    {
                        name: 'templateName',
                        fieldType: FilterType.TEXT,
                        label: 'Template Name',
                        placeholder: 'Input Template Name',
                        required: true,
                        showLabel: true,
                        fieldStyle: {
                            'width': '85%'
                        },
                        value: this.stepData && this.stepData['templateName'] ? this.stepData['templateName'] : '',
                        onFocus: () => {
                            this.isMacrosDisabled = true;
                            this.cdRef.detectChanges();
                        },
                        disabled: this.isEdit
                    },
                    {
                        name: 'templateDescription',
                        fieldType: FilterType.TEXTAREA,
                        label: 'Template Description',
                        placeholder: 'Input Template Description',
                        extraClass: 'template-description',
                        required: false,
                        value: this.stepData && this.stepData['templateDescription'] ? this.stepData['templateDescription'] : '',
                        fieldStyle: {
                            'width': '85%'
                        },
                        onFocus: () => {
                            this.isMacrosDisabled = true;
                            this.cdRef.detectChanges();
                        },
                    },
                    {
                        name: 'emailSubject',
                        fieldType: FilterType.TEXT,
                        label: 'Email Subject',
                        placeholder: 'Input Email Subject',
                        required: true,
                        fieldStyle: {
                            'width': '85%'
                        },
                        onFocus: () => {
                            this.activeField = 'emailSubject';
                            this.isMacrosDisabled = false;
                            this.cdRef.detectChanges();
                        },
                        value: this.stepData && this.stepData['emailSubject'] ? this.stepData['emailSubject'] : '',
                    },
                    {
                        name: 'emailBody',
                        label: 'Email Body',
                        placeholder: 'Enter the Email Body',
                        editorPlaceholder: 'Input Email Body',
                        appearance: this.isView ? '' : WysiwygEditorField.TYPE_3,
                        showLabel: true,
                        disableFontChange: true,
                        fieldType: this.isView ? FilterType.CONTENT : FilterType.WYSIWYG_EDITOR,
                        required: true,
                        value: this.stepData && this.stepData['emailBody'] ? emailBody : '',
                        onFocus: () => {
                            this.activeField = 'emailBody';
                            this.isMacrosDisabled = false;
                            this.cdRef.detectChanges();
                        },
                        showTooltip: false,
                        extraClass: this.isView ? 'content-field-email-body' : 'email-editor-field note-editor',
                    }
                ]
            }
        });
    }

    // Function that will trigger on Save Button and responsible for generating payload for API
    generateEmailTemplateRequest(buttonRef: IButtonGeneratorInput) {
        Helper.markAllFieldAsTouched(this.emailTemplateFormGroup);
        if (this.emailTemplateFormGroup.invalid) {
            this.emailTemplateFormGroup.updateValueAndValidity();
            return;
        }
        if (this.emailTemplateFormGroup.invalid) {
            this.emailTemplateFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        buttonRef.disable = true;
        const { templateName, emailBody } = this.emailTemplateFormGroup.getRawValue();
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_DATA_TO_S3 + 'EMAIL_BODY',
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.dumpDataToS3(
                    res,
                    emailBody,
                    templateName,
                    buttonRef
                );
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    'Unable to save the email template.'
                );
                buttonRef.loader = false;
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        new HitApi(
            apiArgs, this.httpService, this.ngZone
        ).hitApi();

    }

    // Function that will dump the Email Body into S3
    dumpDataToS3(response, emailBody: string, templateName: string, buttonRef: IButtonGeneratorInput) {
        const inputs = {
            ...this.emailTemplateFormGroup.getRawValue(),
            type: 'Custom',
        };
        const blob = new Blob([emailBody], { type: 'text/html' });
        const fileName = templateName + '.html';
        const file = new File([blob], fileName, { type: 'text/html' });
        Helper.convertTextToBase64(file)
            .then((data) => {
                const { preSignedUrl, key } = response;
                const apiArgs: IHitApi = {
                    url: preSignedUrl,
                    input: data,
                    requestType: RequestType.PUT,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED
                    },
                    function: (res) => {
                        inputs['emailBody'] = key;
                        this.saveEmailTemplate(inputs, buttonRef);
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.notificationService,
                            error
                        );
                        buttonRef.loader = false;
                        buttonRef.disable = false;
                    }
                };
                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            })
            .catch((error) => {
                Helper.showErrorMessage(this.notificationService, error);
            });
    }

    // Function will get triggered from generate Request Payload for API.
    // And finally hit the API to save EMail Temaplate.
    saveEmailTemplate(inputs: any, buttonRef: IButtonGeneratorInput) {
        let apiArgs = Helper.generateHitApiConfig(this.widgetRef.widgetData.widgetInfo.create);
        if (this.isEdit) {
            apiArgs = Helper.generateHitApiConfig(this.widgetRef.widgetData.widgetInfo.update);
        }
        apiArgs.input = inputs;
        apiArgs.function = (response) => {
            if (response) {
                buttonRef.loader = false;
                buttonRef.disable = false;
                this.cdRef.detectChanges();
                this.notificationService.showSnackBar(
                    this.isEdit ? 'Email template edited Successfully.' : 'Email template created successfully.',
                    false,
                    '',
                    {
                        duration: 5000,
                        panelClass: '',
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom'
                    }
                );
                this.modalService.closeModal(null, this.modalData.modalId);
                this.widgetRef.refreshWidget();
            }
        }
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            buttonRef.disable = false;
            Helper.showErrorMessage(this.notificationService, error);
            this.cdRef.detectChanges();
        }
        if (this.isEdit) {
            apiArgs.url = apiArgs.url.replace('{email_template_id}', this.stepData['id'])
        }
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    // Function that will fetch the email body template from the S3 API and Convert it back to HTML.
    setEmailBody(emailBodyUrl: string, cb?) {
        let emailBody = '';
        if (emailBodyUrl) {
            const apiArgs: IHitApi = {
                url: emailBodyUrl,
                input: {},
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true,
                    defaultHeaders: {
                        'Content-Type': 'text/plain'
                    },
                    responseType: 'text'
                },
                function: (res) => {
                    emailBody = res
                        ? decodeURIComponent(
                            window.escape(atob(res.split(',')[1]))
                        )
                        : '';
                    cb(emailBody);
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(
                        this.notificationService,
                        error
                    );
                    cb('');
                }
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        } else {
            cb('');
        }
    }

    // Function call on each macros selected from the list.
    macrosSelected(macroValue: any) {
        let contentValue = this.emailTemplateFormGroup.get(this.activeField).value ?? ''
        if (this.activeField === 'emailBody') {
            if (contentValue && contentValue.endsWith('</p>')) {
                contentValue = contentValue.slice(0, contentValue.length - 4);
                contentValue += `<span>${macroValue}</span></p>`
            } else {
                contentValue += `<span>${macroValue}</span>`
            }
        } else {
            contentValue += `${macroValue}`;
        }
        this.emailTemplateFormGroup.get(this.activeField).setValue(contentValue)
    }

    // Function to fetch the Macros list from the API, if it fails then setting default one's
    getMacrosList() {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_EMAIL_TEMPLATE_MACROS,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (res) => {
                this.macrosList = this.filteredMacros = res;
            },
            errorFunction: (error) => {
                this.macrosList = this.filteredMacros = this.getDefaultMacrosList();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    // Function that will return the default macros if API to fetch Macros Fails.
    getDefaultMacrosList(): IDropdownData[] {
        const macrosList = [
            {
                "id": "{CUSTOMER_DOMAIN_ID}",
                "label": "Customer Domain ID"
            },
            {
                "id": "{CUSTOMER_NAME}",
                "label": "Customer Name/Company Name"
            },
            {
                "id": "{ROOT_MAIL_ID}",
                "label": "Root Mail ID"
            },
            {
                "id": "{ROOT_USER_FIRST_NAME}",
                "label": "First Name of Root User"
            },
            {
                "id": "{ROOT_USER_LAST_NAME}",
                "label": "Last Name of Root User"
            },
            {
                "id": "{RECEIVING_USER_FIRST_NAME}",
                "label": "First Name of Receiving User"
            },
            {
                "id": "{RECEIVING_USER_LAST_NAME}",
                "label": "Last Name of Receiving User"
            },
            {
                "id": "{ASSESSMENT_NAME}",
                "label": "Assessment Name"
            },
            {
                "id": "{ASSESSOR_NAMES}",
                "label": "Assessor Names"
            },
            {
                "id": "{PROGRAM_MANAGER_NAMES}",
                "label": "Program Manager Names"
            },
            {
                "id": "{ASSESSMENT_STATUSES}",
                "label": "Assessment Statuses"
            },
            {
                "id": "{ASSESSOR_EMAILS}",
                "label": "Assessor Emails"
            },
            {
                "id": "{PROGRAM_MANAGER_EMAILS}",
                "label": "Program Manager Emails"
            },
            {
                "id": "{RECEIVING_USER_EMAIL}",
                "label": "Receiving User Email"
            },
            {
                "id": "{EMAIL_ADDRESS_OF_ALL_THE_ASSESSOR}",
                "label": "Email Address of All the Assessor"
            },
            {
                "id": "{EMAIL_ADDRESS_OF_ALL_THE_PROGRAM_MANAGER}",
                "label": "Email Address of All the Program Manager"
            },
            {
                "id": "{NAME_OF_ALL_THE_ASSESSOR}",
                "label": "Name of All the Assessor"
            },
            {
                "id": "{NAME_OF_ALL_THE_PROGRAM_MANAGER}",
                "label": "Name of All the Program Manager"
            },
            {
                "id": "{ASSESSMENT_ISSUE_DATE}",
                "label": "Assessment Issue Date"
            },
            {
                "id": "{ASSESSMENT_DATE}",
                "label": "Assessment Date"
            },
            {
                "id": "{ASSESSMENT_TIME}",
                "label": "Assessment Time"
            },
            {
                "id": "{TIME_ZONE}",
                "label": "Time Zone"
            },
            {
                "id": "{ISSUED_BY}",
                "label": "Issued By"
            },
            {
                "id": "{ASSESSMENT_TAGS}",
                "label": "Assessment Tags"
            },
            {
                "id": "{RECEIVING_USER_CONTACT_NUMBER}",
                "label": "Receiving User Contact Number"
            },
            {
                "id": "{CONTROL_POINT_STATUS}",
                "label": "Control Point Status"
            },
            {
                "id": "{ASSESSOR_CONTACT_NUMBERS}",
                "label": "Assessor Contact Numbers"
            },
            {
                "id": "{PROGRAM_MANAGER_CONTACT_NUMBERS}",
                "label": "Program Manager Contact Numbers"
            },
            {
                "id": "{ROOT_USER_CONTACT_NUMBER}",
                "label": "Root User Contact Number"
            },
            {
                "id": "{CONTROL_POINT_NAMES}",
                "label": "Control Point Names"
            }
        ]
        return macrosList;
    }

    /**
     * Updates the filtered macros based on the provided search text.
     * @param {string} id - The ID of the quick filter.
     * @param {string} searchText - The search text to filter the macros.
     * @returns None
     */
    onQuickFilterChanged(id: string, searchText: string) {
        this.searchText = searchText;
        if (searchText) {
            this.filteredMacros =
                this.macrosList.filter((item) =>
                    item.label.toLowerCase().includes(searchText.toLowerCase().trim()),
                );
        } else {
            this.filteredMacros = this.macrosList;
        }
    }
}
