<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div
        class="time-picker-form-field float-label outline {{
            field.extraClass
        }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
    >
        <div class="time-picker-container">
            <legend class="label">
                {{ field.label ? field.label : "Time" }}

                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </legend>

            <ngx-mat-timepicker-field
                #timePickerField
                [class.light-mode]="true"
                [controlOnly]="true"
                [min]="field.minTime ? field.minTime : null"
                [defaultTime]="field.value ? field.value : null"
                (timeChanged)="onTimePickerValueChanged(field, timePickerField)"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></ngx-mat-timepicker-field>
        </div>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div
        class="time-picker-form-field outline {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
    >
        <div class="time-picker-container">
            <ngx-mat-timepicker-field
                #timePickerField
                [class.light-mode]="true"
                [controlOnly]="true"
                [min]="field.minTime ? field.minTime : null"
                [defaultTime]="field.value ? field.value : null"
                (timeChanged)="onTimePickerValueChanged(field, timePickerField)"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></ngx-mat-timepicker-field>
        </div>

        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div
        class="time-picker-form-field legacy {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
    >
        <div class="time-picker-container">
            <span class="label">
                {{ field.label ? field.label : "Time" }}
                <span
                    *ngIf="field.required !== undefined ? field.required : true"
                    class="color-warn"
                >
                    &nbsp;*
                </span>
            </span>

            <ngx-mat-timepicker-field
                #timePickerField
                class="light-mode form-field"
                [controlOnly]="true"
                [min]="field.minTime ? field.minTime : null"
                [defaultTime]="field.value ? field.value : null"
                (timeChanged)="onTimePickerValueChanged(field, timePickerField)"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></ngx-mat-timepicker-field>
        </div>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div
        class="time-picker-form-field hide-label legacy {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
    >
        <div class="time-picker-container">
            <ngx-mat-timepicker-field
                #timePickerField
                class="light-mode form-field"
                [controlOnly]="true"
                [min]="field.minTime ? field.minTime : null"
                [defaultTime]="field.value ? field.value : null"
                (timeChanged)="onTimePickerValueChanged(field, timePickerField)"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></ngx-mat-timepicker-field>
        </div>
        <!-- Handling errors -->
        <ng-container *ngIf="!field.groupByKey">
            <ng-template *ngTemplateOutlet="error"></ng-template>
        </ng-container>
        <ng-container *ngIf="field.groupByKey">
            <ng-template *ngTemplateOutlet="groupError"></ng-template>
        </ng-container>
    </div>
</ng-container>
<!--Field type four end -->
<!-- Field Type 5 start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_5">
    <div
        class="time-picker-form-field {{ field.extraClass }}"
        [class.error]="
            field.groupByKey
                ? formGroup.get(field.groupByKey).get(field.name) &&
                  formGroup.get(field.groupByKey).get(field.name).touched &&
                  formGroup.get(field.groupByKey).get(field.name).errors
                : formGroup.get(field.name) &&
                  formGroup.get(field.name).touched &&
                  formGroup.get(field.name).errors
        "
    >
        <div class="time-picker-container">
            <ngx-mat-timepicker-field
                #timePickerField
                [class.light-mode]="true"
                [controlOnly]="true"
                [min]="field.minTime ? field.minTime : null"
                [defaultTime]="field.value ? field.value : null"
                (timeChanged)="onTimePickerValueChanged(field, timePickerField)"
                [formControl]="control"
                [errorStateMatcher]="matcher"
            ></ngx-mat-timepicker-field>
        </div>
    </div>
</ng-container>
<!-- Field Type 5 End -->
<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
