import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    CellClassParams,
    GridOptions,
    ICellRendererParams,
    RowEvent,
    RowNode,
    ValueGetterParams
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IBackendFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IBackendFormGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ActionState } from '../../../../enums/ActionState';
import { ActionVisibility } from '../../../../enums/ActionVisibility';
import { IButtonGeneratorInput } from '../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IBackendFormField } from '../../../../interfaces/form-generator/IBackendFormField';
import { RightsizingMoreInfoModalV3Component } from '../../../modal-templates/rightsizing-v3/rightsizing-more-info-modal-v3/rightsizing-more-info-modal-v3.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { IMultiButtonOption } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { RegexExtractPipe } from './../../../../pipes/regex-extract.pipe';

enum Actions {
    Upgrade = 'Upgrade',
    Downgrade = 'Downgrade'
}
enum CostType {
    ADDITIONAL_COST = 'Additional Cost',
    COST_SAVINGS = 'Cost Savings'
}
@Component({
    selector: 'app-rightsizing-v3',
    templateUrl: './rightsizing-v3.component.html',
    styleUrls: ['./rightsizing-v3.component.sass']
})
export class RightsizingV3Component implements OnInit, AfterViewInit {
    ViewType = ViewType;
    widgetRef: Widget;
    cardsData: any[] = [];
    filteredCardsData: any[];
    gridRef: GridOptions = null;
    State = State;
    selectedResources: string[] = [];
    selectedResourceName: string[] = [];
    rightsizeOptions = {};
    selectedRightsizing = {};
    tabs;
    response: object;
    accounts;
    tableGenInput: ITableGeneratorInput;
    tableData = null;
    cardCount = {
        upgrade: 0,
        downgrade: 0
    };
    actionType;
    actionInputJson = {
        manageServices: {}
    };
    actions = Actions;
    costType = CostType;
    displayEntry: string[];
    buttons: IButtonGeneratorInput[];
    options: IMultiButtonOption = {
        layout: {
            justifyContent: 'flex-start'
        }
    };
    currency: string;
    resourceType: string;
    totalPotentialSavings: number;
    @ViewChild('rightsizing') rightsizing: ElementRef;
    @ViewChild('rightsizingModal') rightsizingModal: TemplateRef<any>;
    @ViewChild('confirmationMessage') confirmationMessage: TemplateRef<any>;
    readonly currentSize: string = 'Current Size';
    readonly costAfterRightsizing: string = 'Cost after Rightsizing';
    readonly confirmationStep: string = 'confirmationStep';
    readonly defaultValueKey: string = 'defaultValueKey';

    constructor(
        widgetData: WidgetInjectedData,
        private filtersService: FiltersService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);
        this.widgetRef.visibleSections.subscribe(this.redrawSection.bind(this));

        // Adding Fix Actions
        const actions: IAction[] = [
            {
                state: ActionState.ENABLED,
                visibility: ActionVisibility.VISIBLE,
                message: null,
                icon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exchange-alt',
                    text: 'Rightsize'
                },
                function: () => {
                    if (
                        this.widgetRef.visibleSections.value.has(ViewType.TABLE)
                    ) {
                        this.selectedResources.length = 0;
                        this.selectedResourceName.length = 0;
                        this.gridRef.api
                            .getSelectedNodes()
                            .forEach((rowNode: RowNode) => {
                                this.selectedResources.push(
                                    rowNode.data['Resource ID']
                                );
                                this.selectedResourceName.push(
                                    `${rowNode.data['Resource Name']}(${rowNode.data['Instance Type']})`
                                );
                            });
                    }
                    if (!this.selectedResources.length) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Select atleast one card',
                            true
                        );
                        return;
                    }
                    this.getFormData(this.selectedResources, null, true);
                }
            }
        ];
        this.widgetRef.fixActions.next(actions);

        if (
            this.widgetRef.widgetConfigDetails &&
            this.widgetRef.widgetConfigDetails.visibleSections
        ) {
            this.widgetRef.visibleSections.next(
                this.widgetRef.widgetConfigDetails.visibleSections
            );
        }
    }

    redrawSection() {
        this.selectedResources = [];
        this.selectedResourceName = [];
        if (this.widgetRef.visibleSections.value.has(ViewType.CARDS)) {
            this.filteredCardsData = Helper.cloneDeep(this.cardsData);
        }
    }

    initButtonGenInputs() {
        this.buttons = [
            {
                buttonName: `Upgrade Recommendations : ${this.cardCount.upgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: 'Upgrade Recommendations',
                hoverEffect: 'shadow',
                function: () => {
                    if (this.changeButtonType('Upgrade')) {
                        this.search('Upgrade');
                    }
                }
            },
            {
                buttonName: `Downgrade Recommendations : ${this.cardCount.downgrade}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Downgrade Recommendations',
                hoverEffect: 'shadow',
                function: () => {
                    if (this.changeButtonType('Downgrade')) {
                        this.search('Downgrade');
                    }
                }
            }
        ];
    }

    changeButtonType(buttonName: string): boolean {
        const buttonIndex = {
            Upgrade: 0,
            Downgrade: 1
        };
        const index = buttonIndex[buttonName];
        const button = Helper.cloneDeep(this.buttons[index]);

        if (this.buttons[index].buttonType === ButtonType.FLAT) {
            button.buttonType = ButtonType.STROKED;
            this.buttons[index] = button;
            this.search(buttonName, true);
            return false;
        }

        button.buttonType = ButtonType.FLAT;
        this.buttons[index] = button;

        this.buttons.forEach((button, i) => {
            const btn = Helper.cloneDeep(button);
            if (i !== index) {
                btn.buttonType = ButtonType.STROKED;
                this.buttons[i] = btn;
            }
        });

        return true;
    }

    initTableGenInput(rawData) {
        const data = Helper.cloneDeep(rawData);
        const columns: IColumnData[] = [];
        const existingColumns: string[] = [];

        data.dataMap.keysToShow.forEach((data) => {
            if (
                data.label === CostType.ADDITIONAL_COST ||
                data.label === CostType.COST_SAVINGS
            ) {
                if (!existingColumns.includes('Cost')) {
                    columns.push({
                        columnKey: 'cost',
                        columnName: 'Cost',
                        cellClass: (cellClassParams: CellClassParams) => {
                            if (
                                cellClassParams.data[
                                    CostType.ADDITIONAL_COST
                                ] !== '-'
                            ) {
                                return 'negativeColor';
                            } else {
                                return 'positiveColor';
                            }
                        },
                        cellRenderer: (row: ICellRendererParams) => {
                            return row.data['cost'];
                        },
                        columnValueGetter: (params: ValueGetterParams) => {
                            if (params.data.cost.includes('('))
                                return parseFloat(
                                    params.data.cost.split('(')[1].split(')')[0]
                                );
                            return parseFloat(params.data.cost.split('%')[0]);
                        }
                    });
                    existingColumns.push('Cost');
                }
            } else if (!existingColumns.includes(data.label)) {
                columns.push({
                    columnKey: data.id,
                    columnName: data.label
                });
                existingColumns.push(data.label);
            }
        });
        this.tableData = data.dataList;

        // Replacing Additional Cost & Cost savings key with cost
        this.tableData.forEach((data) => {
            if (
                Object.keys(data).includes(CostType.ADDITIONAL_COST) &&
                data[CostType.ADDITIONAL_COST] !== '-'
            ) {
                data['cost'] = data[CostType.ADDITIONAL_COST];
            } else if (
                Object.keys(data).includes(CostType.COST_SAVINGS) &&
                data[CostType.COST_SAVINGS] !== '-'
            ) {
                data['cost'] = data[CostType.COST_SAVINGS];
            }
        });
        columns.push({
            columnName: 'Action',
            columnKey: 'Action',
            pinned: 'right',
            minWidth: 200,
            buttonGen: true,
            headerClass: 'action-column',
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData: RowEvent) => {
                rowData['buttonGenInputs'] = [
                    {
                        buttonName: 'More Info',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,
                        function: () => {
                            this.moreInfoModal(rowData.data);
                        }
                    },
                    {
                        buttonName: 'Rightsize',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,
                        showLoader: true,
                        function: (buttonRef: IButtonGeneratorInput) => {
                            if (buttonRef.loader) {
                                return;
                            }
                            buttonRef.loader = true;
                            this.getFormData(rowData.data, buttonRef);
                        }
                    }
                ];
                rowData['options'] = {
                    layout: { justifyContent: 'space-evenly' }
                };
                return rowData;
            }
        });

        this.tableGenInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            columns,
            columnResizeCount: 10
        };
    }

    bindData(data) {
        if (data === null) {
            return;
        }

        const widgetAction: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.widgetActions.next(widgetAction);

        this.widgetRef.apiResponse = data;
        this.cardCount.upgrade = 0;
        this.cardCount.downgrade = 0;

        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];

        if (this.accounts && this.accounts.length === 0) {
            this.widgetRef.extraMessage.next(
                data.message ? data.message : Messages.NO_DATA_AVAILABLE
            );
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        this.response = data;
        this.displayEntry = data.dataMap.keysToShow;

        if (this.widgetRef.visibleSections.value.size === 0) {
            this.widgetRef.visibleSections.next(new Set([ViewType.CARDS]));
        }

        this.accounts.forEach((item) => {
            if (item.Action === Actions.Upgrade) {
                this.cardCount.upgrade += 1;
            } else if (item.Action === Actions.Downgrade) {
                this.cardCount.downgrade += 1;
            }
            item.selected = false;
        });
        this.totalPotentialSavings = data.dataMap.totalPotentialSavings;
        this.currency = data.dataMap.currency ?? '';
        this.cardsData = this.accounts;
        this.filteredCardsData = Helper.cloneDeep(this.accounts);
        this.initTableGenInput(data);
        this.initButtonGenInputs();
        this.widgetRef.endLoader();
    }

    setColors() {
        const styleList = [
            `--positiveColorBackground:${Helper.addOpacityToColor(
                Helper.getCssVarValue('positiveColor'),
                10
            )}`
        ];
        this.rightsizing.nativeElement.style = styleList.join('; ');
    }

    search(text: string, reset = false) {
        let filteredTableData = this.tableData;
        this.filteredCardsData = Helper.cloneDeep(this.cardsData);
        if (!reset) {
            text = text.toLowerCase();
            if (text.trim().length === 0) {
                this.filteredCardsData = Helper.cloneDeep(this.cardsData);
                return;
            }
            this.filteredCardsData = this.filteredCardsData.filter((item) =>
                JSON.stringify(item).toLowerCase().includes(text.trim())
            );
            filteredTableData = filteredTableData.filter((item) =>
                JSON.stringify(item).toLowerCase().includes(text.trim())
            );
        }

        if (this.gridRef) {
            this.gridRef.api.setRowData(filteredTableData);
            this.gridRef.api.redrawRows();
        }
    }

    getFormData(
        card,
        buttonRef?: IButtonGeneratorInput,
        bulkRightsize?: boolean
    ) {
        const input = {};
        if (card.loader) {
            return;
        }
        if (!bulkRightsize) {
            input['region'] = card['Region'];
            input['os'] = card['Operating System'];
            input['subscriptionId'] = card['Subscription ID'];
            input['dropdownData'] = card['dropdownData'];
            input['filters'] = this.filtersService.prepareInput(
                this.widgetRef.widgetData.widgetInfo.filters,
                FilterStoreKey.WEBSITE_FILTERS
            );
            input['rowValue'] = [card];
        }

        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['formGenerator'][
                'thresholdFormGenerator'
            ]
        );
        apiArgs.input = input;
        apiArgs.function = (response) => {
            this.rightsizeModal(card, response, bulkRightsize);
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                Messages.DEFAULT_ERROR
            );
        };
        apiArgs.endFunction = () => {
            if (buttonRef) {
                buttonRef.loader = false;
            }
            delete card.loader;
            this.widgetRef.changeDetectorRef.detectChanges();
        };

        new HitApi(
            apiArgs,
            this.widgetRef.widgetHttpService,
            this.widgetRef.widgetHttpService.ngZone
        ).hitApi();
    }

    rightsizeModal(cardItemData, rawStepFormData, bulkRightsize?: boolean) {
        let vmSizeListData;
        let vmTypeListData;
        let radioButtonIndex;
        let vmSizeFieldIndex;
        let lastSelectedVMSeries;
        let vmTypeFieldIndex;
        let lastSelectedVMSize;
        const regexExp = /([0-9]*([\.]*[0-9]*))/g;

        rawStepFormData.rightSizeStep.fields.forEach((data, index) => {
            if (data.name === 'vmSize') {
                vmSizeListData = Helper.cloneDeep(data.listData);
                vmSizeFieldIndex = index;
            } else if (data.name === 'vmType') {
                vmTypeListData = Helper.cloneDeep(data.listData);
                vmTypeFieldIndex = index;
            }
            if (data.name === 'rightsize') {
                radioButtonIndex = index;
            }
        });
        const modalId = Symbol();
        const modalData = Helper.generateMultiStepFormGenModalData(
            {
                modalName: 'Rightsize Virtual Machine(s)',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalId: modalId,
                modalHeightVh: 76
            },
            rawStepFormData,
            (
                stepNumber,
                backendFormGenInput: IBackendFormGeneratorInput,
                prevStepData,
                currStepData
            ) => {
                if (stepNumber === 2 && !bulkRightsize) {
                    let currentCost;
                    let costAfterRightsizing;
                    const prevStepValue = prevStepData.formGroupRef.value;
                    const customize = prevStepValue.rightsize === 'customize';
                    let savingsFieldPresent = false;
                    const recommendedInstance = customize
                        ? prevStepValue.vmType
                        : prevStepValue.recommendations;

                    this.resourceType = recommendedInstance;
                    backendFormGenInput.fields.forEach(
                        (data: IBackendFormField) => {
                            // Fteching current cost
                            if (data.label === 'Current Cost') {
                                let tempCost;
                                const monthlyPrice =
                                    cardItemData['Amortized Price'][
                                        `Monthly Price (${this.currency})`
                                    ];
                                Object.keys(monthlyPrice).forEach((keys) => {
                                    if (keys.includes('Current')) {
                                        tempCost = monthlyPrice[keys];
                                    }
                                });

                                currentCost = new RegexExtractPipe().transform(
                                    tempCost,
                                    regexExp
                                );
                                currentCost = parseFloat(
                                    currentCost.filter((each) => !!each)[0]
                                );

                                data.defaultValue = `${this.currency} ${currentCost}`;

                                delete data.defaultValueKey;
                            }

                            // Fteching recommended size
                            if (
                                data.label === 'Recommended VM Type' ||
                                data.label === 'Selected VM Type'
                            ) {
                                data.label = customize
                                    ? 'Selected VM Type'
                                    : 'Recommended VM Type';
                                data.defaultValue = recommendedInstance;
                            }

                            if (
                                data.label === 'Cost after Rightsizing' &&
                                !customize
                            ) {
                                costAfterRightsizing =
                                    new RegexExtractPipe().transform(
                                        cardItemData['Amortized Price'][
                                            `Monthly Price (${this.currency})`
                                        ][recommendedInstance],
                                        regexExp
                                    );
                                costAfterRightsizing = parseFloat(
                                    costAfterRightsizing.filter(
                                        (each) => !!each
                                    )[0]
                                );
                                data.defaultValue = `${this.currency} ${costAfterRightsizing}`;
                            } else if (
                                data.label === 'Cost after Rightsizing' &&
                                customize
                            ) {
                                vmTypeListData.forEach((customListData) => {
                                    if (
                                        customListData.id ===
                                        prevStepValue.vmType
                                    ) {
                                        costAfterRightsizing =
                                            customListData.value;

                                        data.defaultValue = `${this.currency} ${costAfterRightsizing}`;
                                    }
                                });
                            }

                            if (data.name === 'savingsField') {
                                savingsFieldPresent = true;
                            }
                        }
                    );

                    const savings = Helper.roundOff(
                        currentCost - costAfterRightsizing,
                        3
                    );
                    const savingsField: IBackendFormField = {
                        label: savings < 0 ? 'Additional Cost' : 'Savings',
                        fieldType: FilterType.CONTENT,
                        showLabel: true,
                        placeholder: '',
                        name: 'savingsField',
                        defaultValue: `${this.currency} ${Math.abs(+savings)}`,
                        extraClass: savings < 0 ? 'additionalCost' : 'savings'
                    };

                    if (!savingsFieldPresent) {
                        backendFormGenInput.fields.push(savingsField);
                    } else {
                        backendFormGenInput.fields.splice(
                            backendFormGenInput.fields.length - 1,
                            1
                        );
                        backendFormGenInput.fields.push(savingsField);
                    }
                } else if (stepNumber === 2 && bulkRightsize) {
                    this.resourceType =
                        prevStepData.formGroupRef.value['vmType'];
                }

                const formGenInput: IFormGeneratorInput =
                    Helper.formGeneratorBtoFParser(
                        backendFormGenInput,
                        [cardItemData],
                        this.widgetRef.notificationsService,
                        this.widgetRef.httpService,
                        this.widgetRef.changeDetectorRef,
                        null,
                        null,
                        null,
                        prevStepData && prevStepData.formGroupRef
                            ? prevStepData.formGroupRef.getRawValue()
                            : null,
                        () => {
                            if (stepNumber === 2 && !bulkRightsize) {
                                const input = prevStepData.formGroupRef.value;
                                input['listResourceUri'] = [
                                    cardItemData['Resource ID']
                                ];
                                return input;
                            } else if (stepNumber === 2 && bulkRightsize) {
                                const input = prevStepData.formGroupRef.value;
                                input['listResourceUri'] = [
                                    ...this.selectedResources
                                ];
                                return input;
                            }
                        }
                    );

                if (stepNumber === 1) {
                    formGenInput.extraClass = 'rightsizingStep';
                    formGenInput.fields[vmSizeFieldIndex].disabled = true;
                    formGenInput.fields[vmTypeFieldIndex].disabled = true;
                    if (bulkRightsize) {
                        formGenInput.fields[radioButtonIndex].value =
                            'customize';
                        formGenInput.fields[radioButtonIndex].listData.forEach(
                            (data) => {
                                data.id === 'centilyticsRecommendation'
                                    ? (data.disabled = true)
                                    : null;
                            }
                        );
                    } else {
                        formGenInput.fields[radioButtonIndex].value =
                            'centilyticsRecommendation';
                    }
                } else if (stepNumber === 2 && bulkRightsize) {
                    formGenInput.fields = [];
                    formGenInput.extraClass = 'bulkrightsize-confirmation';
                    formGenInput.formMessage = null;
                } else if (stepNumber === 2 && !bulkRightsize) {
                    formGenInput.extraClass = 'confirmation-step';
                }

                return formGenInput;
            },
            null,
            null,
            (stepNumber) => stepNumber === 1,
            (stepNumber) => {
                if (stepNumber === 2 && bulkRightsize) {
                    return this.confirmationMessage;
                }
            },
            null,
            (
                stepNumber,
                event,
                formGenInput: IFormGeneratorInput,
                formGroup: FormGroup
            ) => {
                if (stepNumber === 1) {
                    if (event['vmSeries']) {
                        if (event['vmSeries'] !== lastSelectedVMSeries) {
                            lastSelectedVMSeries = event['vmSeries'];
                            formGroup.controls.vmSize.reset();
                        }
                        const finalListData = vmSizeListData.filter(
                            (data) => data.group === event['vmSeries']
                        );
                        formGenInput.fields[vmSizeFieldIndex].value = null;
                        formGenInput.fields[vmSizeFieldIndex].listData =
                            finalListData;
                        formGenInput.fields[vmSizeFieldIndex].disabled = false;
                    }
                    if (event['vmSize']) {
                        if (event['vmSize'] !== lastSelectedVMSize) {
                            lastSelectedVMSize = event['vmSize'];
                            formGroup.controls.vmType.reset();
                        }
                        const finalListData = vmTypeListData.filter(
                            (data) => data.group === event['vmSize']
                        );
                        formGenInput.fields[vmTypeFieldIndex].value = null;
                        formGenInput.fields[vmTypeFieldIndex].listData =
                            finalListData;
                        formGenInput.fields[vmTypeFieldIndex].disabled = false;
                    }
                }
            }
        );
        if (bulkRightsize) {
            modalData.modalSteps[1].stepHeightVh = 60;
        }
        this.widgetRef.modalService.openModal(modalData);
    }

    moreInfoModal(data) {
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'RightsizingMoreInfoModalV3',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noStepPadding: true,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RightsizingMoreInfoModalV3Component,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                itemData: data,
                                response: this.response
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    cardSelected(card, checked) {
        const resourceNameType = `${card['Resource Name']}(${card['Instance Type']})`;
        if (checked) {
            this.selectedResources.push(card['Resource ID']);
            this.selectedResourceName.push(resourceNameType);
        } else {
            this.selectedResources.splice(
                this.selectedResources.findIndex(
                    (cardData) => cardData === card['Resource ID']
                ),
                1
            );
            this.selectedResourceName.splice(
                this.selectedResourceName.findIndex(
                    (cardData) => cardData === resourceNameType
                ),
                1
            );
        }
    }

    ngAfterViewInit(): void {
        this.setColors();
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
