import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class SideMenuCacheService extends CacheMain {
    static readonly FIRST_LEVEL_DATA_KEY = 'FIRST_LEVEL_DATA_KEY';
    static readonly PATH_SELECTION_DATA = 'PATH_SELECTION_DATA';
    static readonly CURRENT_NAVIGATION_PATH = 'CURRENT_NAVIGATION_PATH ';

    constructor() {
        const SIDE_MENU_CACHE_PREFIX = 'SIDEBAR_ATTRIBUTES';
        super(SIDE_MENU_CACHE_PREFIX, true);
    }

    getFirstLevelData(viewId) {
        return this.fetch(SideMenuCacheService.FIRST_LEVEL_DATA_KEY)[viewId];
    }

    get firstLevelData() {
        return this.fetch(SideMenuCacheService.FIRST_LEVEL_DATA_KEY);
    }

    set firstLevelData(firstLevelData) {
        this.store(SideMenuCacheService.FIRST_LEVEL_DATA_KEY, firstLevelData);
    }

    getPathSelectedData(viewId: string, path: string) {
        const pathData = this.fetch(SideMenuCacheService.PATH_SELECTION_DATA);
        if (!pathData) {
            return null;
        }
        if (!(viewId in pathData)) {
            return null;
        }
        if (!(path in pathData[viewId])) {
            return null;
        }
        return pathData[viewId][path];
    }

    setPathSelectedData(viewId: string, path: string, data: any) {
        let pathData = this.fetch(SideMenuCacheService.PATH_SELECTION_DATA);
        if (!pathData) {
            pathData = {};
        }
        if (!(viewId in pathData)) {
            pathData[viewId] = {};
        }
        pathData[viewId][path] = data;
        this.store(SideMenuCacheService.PATH_SELECTION_DATA, pathData);
    }
    getNavigationdpoints(viewId: string) {
        const pathData = this.fetch(
            SideMenuCacheService.CURRENT_NAVIGATION_PATH
        );
        if (!pathData) {
            return null;
        }
        if (!(viewId in pathData)) {
            return null;
        }
        return pathData[viewId];
    }
    setNavigationdpoints(viewId: string, data: string[]) {
        const pathData = {};
        pathData[viewId] = data;
        this.store(SideMenuCacheService.CURRENT_NAVIGATION_PATH, pathData);
    }
}
