<div
    class="controls"
    *ngIf="!(widgetRef.loadingData | async) && tableData && tableData.length"
>
    <div class="table-search">
        <div class="search-input-container">
            <app-table-search
                [searchFunction]="onQuickFilterChanged.bind(this)"
            ></app-table-search>
        </div>
    </div>
</div>

<div
    class="tw-flex tw-justify-center tw-items-center"
    [ngClass]="
        (widgetRef.compareViewService.isCompareViewEnabled | async)
            ? 'compare-view'
            : 'main-container'
    "
>
    <ng-container *ngIf="widgetRef.visibleSections.value.has(ViewType.TABLE)">
        <div class="tw-w-full tw-flex-f5 table">
            <app-only-table-generator
                *ngIf="tableGenInput"
                [tableInput]="tableGenInput"
                [tableData]="tableData"
                [showSearchBox]="false"
                [hideControlBar]="true"
                (gridRef)="gridRef = $event"
            ></app-only-table-generator>
        </div>
    </ng-container>

    <ng-container *ngIf="widgetRef.visibleSections.value.has(ViewType.GRAPH)">
        <div class="tw-w-full tw-px-6 tw-flex-f5 chart">
            <app-graph-generator
                *ngIf="graphData"
                [graphData]="graphData"
            ></app-graph-generator>
        </div>
    </ng-container>
</div>
