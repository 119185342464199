import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { IButtonData } from '../../interfaces/table-generator/IButtonData';

@Pipe({
    name: 'cardButtonsDereferencer'
})
export class CardButtonsDereferencerPipe implements PipeTransform {
    transform(value: IButtonData[]): IButtonData[] {
        return value.map(Helper.dereference);
    }
}
