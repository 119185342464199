<div class="delete">
    <div class="text">
        <div class="warning-icon">
            <img src="../../../../../assets/yellow_check.png" alt="WARNING" />
        </div>
        <span>{{ response["deleteMessage"] }}</span>
    </div>
    <label class="reason" *ngIf="response['reasonInput']">
        <span class="reasonSpan"
            ><strong>Reason</strong
            ><span *ngIf="response['reasonCompulsory']" class="compulsory"
                >*</span
            ></span
        >
        <input
            type="text"
            placeholder="Reason"
            [style.borderColor]=""
            name="reason"
            [(ngModel)]="reason"
        />
    </label>
    <div class="warning" *ngIf="response['warning']">
        <div class="warning-text">
            {{ response["warning"] }}
        </div>
    </div>
    <div class="disclaimer-compulsory" *ngIf="response['extraMessage']">
        <i class="fas fa-star-of-life"></i> This action can't be undone
    </div>
    <div class="controls">
        <app-button-generator
            *ngIf="response['buttons']"
            [buttonGenInput]="response['buttons'][0]"
            (buttonRef)="action($event, response['buttons'][0]['function'])"
        ></app-button-generator>
        <app-button-generator
            *ngIf="response['buttons']"
            [buttonGenInput]="response['buttons'][1]"
            (buttonRef)="action($event, response['buttons'][1]['function'])"
        ></app-button-generator>
    </div>
</div>
