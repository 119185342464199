import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class NumberValidator extends CustomValidator {
    key = CustomValidatorKey.NUMBER_VALIDATOR;

    constructor() {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        const code = '([0-9]+[.,]*)+';
        return Validators.pattern(code)(control);
    }
}
