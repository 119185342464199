<div #recommendationsContainer class="tw-relative tw-h-full tw-w-full">
    <app-vertical-scrolling-tab-group [modalId]="modalData.modalId">
        <ng-template appVerticalScrollingTab [tabLabel]="'Description'">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #description
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ "Description" }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!description.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="description.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="table-container">
                        <app-only-table-generator
                            *ngIf="tableData"
                            [tableData]="tableData"
                            [tableInput]="tableGenInput"
                            [hideControlBar]="true"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-container *ngIf="checkNormalization(selectedItem)">
            <ng-template
                appVerticalScrollingTab
                [tabLabel]="'Recommendations based on normalisation factor'"
            >
                <mat-accordion displayMode="flat" multi>
                    <mat-expansion-panel
                        class="tw-py-2"
                        #normalisation
                        hideToggle="true"
                        [expanded]="true"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Recommendations based on normalisation factor
                            </mat-panel-title>
                            <mat-panel-description>
                                <span
                                    *ngIf="!normalisation.expanded"
                                    appIcon
                                    [data]="arrowDownIcon"
                                ></span>
                                <span
                                    *ngIf="normalisation.expanded"
                                    appIcon
                                    [data]="arrowUpIcon"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="normalizationContainer">
                            <div class="tw-flex tw-my-2 tw-mx-0">
                                <div
                                    class="tw-flex-1 tw-flex tw-text-fs-400 tw-justify-center tw-mx-16 tw-rounded-md tw-p-3 normalization-amount"
                                >
                                    Normalized Units to reserve:
                                    <span class="tw-font-semibold tw-ml-1">
                                        {{
                                            selectedItem["typeDistribution"][
                                                "normalizationCount"
                                            ]
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="tw-flex-1 tw-flex tw-text-fs-400 tw-justify-center tw-mx-16 tw-rounded-md tw-p-3 normalization-amount"
                                >
                                    Amount of units left:
                                    <span class="tw-font-semibold tw-ml-1">{{
                                        unitsLeft
                                    }}</span>
                                </div>
                            </div>
                            <div class="tw-p-2">
                                <div class="tw-flex columnName">
                                    <p
                                        class="tw-flex tw-flex-1 tw-text-fs-400 tw-justify-center tw-font-bold tw-font-heading tw-items-center tw-p-3 tw-m-0"
                                    >
                                        Instance Type
                                    </p>
                                    <p
                                        class="tw-flex tw-flex-1 tw-text-fs-400 tw-justify-center tw-font-bold tw-font-heading tw-items-center tw-p-3 tw-m-0"
                                    >
                                        Normalization Factor
                                    </p>
                                    <p
                                        class="tw-flex tw-flex-1 tw-text-fs-400 tw-justify-center tw-font-bold tw-font-heading tw-items-center tw-p-3 tw-m-0"
                                    >
                                        Instance Count
                                    </p>
                                    <p
                                        class="tw-flex tw-flex-1 tw-text-fs-400 tw-justify-center tw-font-bold tw-font-heading tw-items-center tw-p-3 tw-m-0"
                                    >
                                        Normalised Count
                                    </p>
                                </div>

                                <div
                                    class="tw-flex tw-mx-0 row"
                                    *ngFor="
                                        let item of selectedItem[
                                            'typeDistribution'
                                        ]['instanceType']
                                    "
                                >
                                    <div
                                        class="tw-flex tw-text-fs-400 tw-justify-center tw-items-center tw-flex-1 tw-p-3 row-item"
                                    >
                                        {{ item.split("|")[0] }}
                                    </div>
                                    <div
                                        class="tw-flex tw-text-fs-400 tw-justify-center tw-items-center tw-flex-1 tw-p-3 row-item"
                                    >
                                        {{ item.split("|")[1] }}
                                    </div>

                                    <div
                                        class="tw-flex tw-text-fs-400 tw-justify-center tw-items-center tw-flex-1 tw-p-3 row-item"
                                    >
                                        <div
                                            class="tw-relative tw-flex tw-items-center tw-mx-3 tw-text-fs-800 multiply"
                                        >
                                            x
                                        </div>
                                        <div
                                            class="tw-p-0 tw-h-5 tw-w-5 tw-flex tw-text-fs-400 tw-rounded-full tw-items-center tw-justify-center tw-my-0 tw-mx-1 tw-cursor-pointer tw-select-none plus-minus btn btn-grey-1"
                                            (click)="
                                                inputValue[item] =
                                                    +value.value - 1;
                                                updateTotal(
                                                    item,
                                                    inputValue[item],
                                                    'minus'
                                                )
                                            "
                                        >
                                            -
                                        </div>
                                        <input
                                            #value
                                            class="tw-w-10 tw-rounded-md tw-text-center tw-mx-2 text-box"
                                            (click)="value.select()"
                                            type="number"
                                            placeholder="0"
                                            [value]="
                                                inputValue[item]
                                                    ? inputValue[item]
                                                    : '0'
                                            "
                                            (keyup)="
                                                updateTotal(
                                                    item,
                                                    $event.target.value,
                                                    '',
                                                    value
                                                )
                                            "
                                        />
                                        <div
                                            class="tw-p-0 tw-h-5 tw-w-5 tw-flex tw-text-fs-400 tw-rounded-full tw-items-center tw-justify-center tw-my-0 tw-mx-1 tw-cursor-pointer tw-select-none plus-minus btn btn-grey-1"
                                            (click)="
                                                updateTotal(
                                                    item,
                                                    +value.value,
                                                    'plus'
                                                )
                                            "
                                        >
                                            +
                                        </div>
                                    </div>

                                    <div
                                        class="tw-flex tw-text-fs-400 tw-justify-center tw-items-center tw-flex-1 tw-p-3 row-item"
                                    >
                                        = &nbsp;
                                        {{
                                            totalObj[item] ? totalObj[item] : 0
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="tw-flex tw-justify-end tw-text-fs-800"
                                >
                                    <div
                                        class="total-count tw-m-6 tw-py-2 tw-text-positive"
                                    >
                                        <span class="tw-mx-6">Total</span>
                                        {{ totalCountOfSelected }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-template>
        </ng-container>

        <ng-template appVerticalScrollingTab [tabLabel]="'Recommendation'">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #recommendation
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ "Recommendation" }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!recommendation.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="recommendation.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="tabs">
                        <div class="tab-header">
                            <div
                                class="tab-header-item"
                                *ngIf="
                                    objectKeys(selectedItem).includes(
                                        'savingsTable'
                                    )
                                "
                                [class.active]="selectedTab === 'savingsTable'"
                                (click)="changeTab('savingsTable')"
                            >
                                Savings(%)
                            </div>
                            <div
                                class="tab-header-item"
                                *ngFor="let tab of tabs"
                                [class.active]="selectedTab === tab"
                                (click)="changeTab(tab)"
                            >
                                {{ tab }}
                            </div>
                        </div>
                        <div
                            *ngIf="recommendationTableGenInput"
                            class="recommendation-table"
                        >
                            <app-only-table-generator
                                [defaultColDef]="defaultColDef"
                                [tableInput]="recommendationTableGenInput"
                                [tableData]="recommendationTableData"
                                [hideControlBar]="true"
                                (gridRef)="gridRef = $event"
                            ></app-only-table-generator>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </app-vertical-scrolling-tab-group>
</div>
