import { Component, Injector, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/classes/Messages';
import { DynamicComponentsInjectedData } from './../../classes/DynamicComponentsInjectedData';
import { ModalInjectedData } from './../../classes/ModalInjectedData';
import { IPreviewData } from './../../interfaces/custom-widget/IPreviewData';

@Component({
    selector: 'app-custom-widget-preview',
    templateUrl: './custom-widget-preview.component.html',
    styleUrls: ['./custom-widget-preview.component.sass']
})
export class CustomWidgetPreviewComponent implements OnInit {
    previewData: IPreviewData;
    componentInjector: Injector;
    extraMessage: string;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private injector: Injector
    ) {
        if (modalInjectedData.data && modalInjectedData.data.previewData) {
            this.previewData = modalInjectedData.data.previewData;

            if (
                this.previewData &&
                this.previewData.componentInputData.tableData &&
                this.previewData.componentInputData.tableData.length === 0
            ) {
                this.extraMessage = Messages.NO_DATA_AVAILABLE;
            }
        }
    }

    ngOnInit(): void {
        this.componentInjector = Injector.create({
            providers: [
                {
                    provide: DynamicComponentsInjectedData,
                    deps: [],
                    useValue: {
                        inputData: this.previewData.componentInputData
                    }
                }
            ],
            parent: this.injector
        });
    }
}
