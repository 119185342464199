import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class NoSpecialCharactersValidator extends CustomValidator {
    key = CustomValidatorKey.NO_SPECIAL_CHARACTERS;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const SPECIAL_CHARACTER_REGEXP = /^[a-zA-Z0-9]*$/;
        return Validators.pattern(SPECIAL_CHARACTER_REGEXP)(control);
    }
}
