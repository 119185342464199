import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { FiltersService } from './../../../../../../services/filters/filters.service';
import { ModalService } from './../../../../../../services/modal/modal-service/modal.service';

@Component({
    selector: 'app-ca-integration-step-two',
    templateUrl: './ca-integration-step-two.component.html',
    styleUrls: ['./ca-integration-step-two.component.sass']
})
export class CaIntegrationStepTwoComponent implements OnInit {
    groupFormGenInputs: IFormGeneratorInput[] = [];
    groupFormGroup: Map<Symbol, FormGroup> = new Map();
    categoryFormGenInputs: IFormGeneratorInput[] = [];
    categoryFormGroup: Map<Symbol, FormGroup> = new Map();
    incidentTypeFormGenInputs: IFormGeneratorInput = null;
    incidentTypeFormGroup: FormGroup;
    keyValueSet = {};
    lastGroupPair: Symbol;
    lastCategoryPair: Symbol;
    widgetRef: Widget;
    stepData;
    edit: boolean = false;
    buttonInputs: IButtonGeneratorInput[];
    buttonOption: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between'
        }
    };
    constructor(
        private modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private filterService: FiltersService
    ) {
        this.widgetRef = this.modalInputData.data.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        if (this.modalInputData.data.edit) {
            this.edit = true;
            this.stepData = this.modalInputData.data.response;
            if (
                this.stepData &&
                this.stepData.group &&
                this.stepData.group.length
            ) {
                this.stepData.group.forEach((ele) => {
                    this.groupFormGenInputs.push(
                        this.getKeyValueFormGenInput(ele.id, ele.label)
                    );
                });
                this.groupFormGenInputs.push(this.getKeyValueFormGenInput());
                this.idleKeyValueForms(this.groupFormGenInputs);
            }
            if (
                this.stepData &&
                this.stepData.category &&
                this.stepData.category.length
            ) {
                this.stepData.category.forEach((ele) => {
                    this.categoryFormGenInputs.push(
                        this.getKeyValueFormGenInput(ele.id, ele.label)
                    );
                });
                this.categoryFormGenInputs.push(this.getKeyValueFormGenInput());
                this.idleKeyValueForms(this.categoryFormGenInputs);
            }
        } else {
            this.groupFormGenInputs = [this.getKeyValueFormGenInput()];
            this.categoryFormGenInputs = [this.getKeyValueFormGenInput()];
        }
        this.incidentTypeFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'incidentType',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Type',
                    placeholder: 'Select Type',
                    listData: this.incidentType,
                    showKey: 'label',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value:
                        this.stepData && this.stepData.incidentType
                            ? this.stepData.incidentType
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Type is required'
                        }
                    ]
                }
            ]
        };
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                function: () => {
                    this.back();
                }
            },
            {
                buttonName: this.edit ? 'Update' : 'Submit',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.submit(buttonRef);
                }
            }
        ];
    }
    getKeyValueFormGenInput(id?, label?): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'label',
                    fieldType: FilterType.TEXT,
                    label: 'Name',
                    placeholder: 'Name',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: label ? label : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Name is required'
                        }
                    ]
                },
                {
                    name: 'id',
                    fieldType: FilterType.TEXT,
                    label: 'Handle',
                    placeholder: 'Handle',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: id ? id : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Handle is required'
                        }
                    ]
                }
            ]
        };
    }
    idleKeyValueForms(formGenInputs) {
        formGenInputs = formGenInputs.map((fg, index) => {
            if (index !== formGenInputs.length - 1) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    lastKeyValueFormUpdate(
        formGenInputs: IFormGeneratorInput[],
        formId: Symbol,
        formGroup: Map<Symbol, FormGroup>
    ) {
        Helper.markAllFieldAsTouched(formGroup.get(formId));
        if (formGroup.get(formId).invalid) {
            formGroup.get(formId).updateValueAndValidity();
            return;
        }
        if (formGroup.get(formId)) {
            const fg = formGroup.get(formId);
            if (fg.valid) {
                if (formGenInputs && formGenInputs.length) {
                    if (
                        this.getKey(formGenInputs, formGroup).includes(
                            fg.get('label').value
                        )
                    ) {
                        this.widgetRef.notificationsService.showSnackBar(
                            'Configuration for this key is already present',
                            true
                        );
                    } else {
                        formGenInputs.push(this.getKeyValueFormGenInput());
                        this.idleKeyValueForms(formGenInputs);
                    }
                }
            }
        }
    }
    getKey(formGenInputs, formGroup): string[] {
        const key = [];
        formGenInputs.forEach((fg, index) => {
            if (index !== formGenInputs.length - 1) {
                if (formGroup.has(fg.formId)) {
                    key.push(formGroup.get(fg.formId).get('label').value);
                }
            }
        });
        return key;
    }
    deletegroupFormGroup(index: number, formId: Symbol, name) {
        if (name === 'category') {
            this.categoryFormGroup.delete(formId);
            this.categoryFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.categoryFormGenInputs
            );
        }
        if (name === 'group') {
            this.groupFormGroup.delete(formId);
            this.groupFormGenInputs = Helper.removeIndexfromArray(
                index,
                this.groupFormGenInputs
            );
        }
    }
    submit(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        if (this.groupFormGenInputs.length === 1) {
            this.groupFormGroup.forEach((fg) => {
                Helper.markAllFieldAsTouched(fg);
                if (fg.invalid) {
                    fg.updateValueAndValidity();
                    return;
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Group is required',
                        true
                    );
                    return;
                }
            });
            return;
        }
        if (this.categoryFormGenInputs.length === 1) {
            this.categoryFormGroup.forEach((fg) => {
                Helper.markAllFieldAsTouched(fg);
                if (fg.invalid) {
                    fg.updateValueAndValidity();
                    return;
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Category is required',
                        true
                    );
                    return;
                }
            });
            return;
        }
        Helper.markAllFieldAsTouched(this.incidentTypeFormGroup);
        if (this.incidentTypeFormGroup.invalid) {
            this.incidentTypeFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const inputs = this.prepareInputs();
        const apiConfig = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiConfig.input = inputs;
        apiConfig.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'CA-SDM Updated Successfully'
                    : 'CA-SDM Integrated Successfully'
            );
            this.filterService.refreshWidget.next(
                new Set([this.modalService.modalData.sourceId])
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        apiConfig.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.edit
                    ? 'Error while updating ca-sdm'
                    : 'Error while integrating ca-sdm'
            );
            buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInputs() {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        const obj = {
            ...step1Data,
            parentIntegration: true,

            childIntegration: false
        };

        const groupTags = [];
        const categoryTags = [];
        this.lastGroupPair =
            this.groupFormGenInputs[this.groupFormGenInputs.length - 1].formId;
        this.groupFormGroup.forEach((value, index) => {
            if (this.lastGroupPair !== index) {
                groupTags.push(value.value);
            }
        });
        this.lastCategoryPair =
            this.categoryFormGenInputs[
                this.categoryFormGenInputs.length - 1
            ].formId;
        this.categoryFormGroup.forEach((value, index) => {
            if (this.lastCategoryPair !== index) {
                categoryTags.push(value.value);
            }
        });
        obj['group'] = groupTags ? groupTags : [];
        obj['category'] = categoryTags ? categoryTags : [];
        obj['incidentType'] =
            this.incidentTypeFormGroup &&
            this.incidentTypeFormGroup.get('incidentType') &&
            this.incidentTypeFormGroup.value
                ? this.incidentTypeFormGroup.get('incidentType').value
                : '';
        obj['integrationType'] = IntegrationName.CASDM;

        return obj;
    }
    back() {
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, this.incidentTypeFormGroup.value);
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
    incidentType = [
        { id: 'incident', label: 'Incident' },
        { id: 'task', label: 'Task' }
    ];
}
