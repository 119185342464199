import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { FormState } from 'src/app/shared/enums/FormState';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { Helper } from 'src/app/shared/classes/Helper';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { HitApi } from 'src/app/shared/classes/HitApi';

@Component({
    selector: 'app-servicenow-form-generator-modal',
    templateUrl: './servicenow-incidents-form-generator-modal.component.html',
    styleUrls: ['./servicenow-incidents-form-generator-modal.component.sass'],
})
export class ServicenowIncidentsFormGeneratorModalComponent implements OnInit {
    public formGenInput: IFormGeneratorInput = null;
    public formGroupRef: FormGroup = null;
    public incidentPriorityFormGroupRef: FormGroup = null;
    private widgetRef: Widget;
    public priorityIncidentsFormInput: IFormGeneratorInput;
    private resetSubcription: Subscription;
    public isActive: boolean = false;
    public showIncidentsTable: boolean = false;
    public submitGenInput: IButtonGeneratorInput = {
        buttonName: 'Create',
        showLoader: true,
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        function: (buttonRef: IButtonGeneratorInput) => {
            Helper.markAllFieldAsTouched(this.formGroupRef);
            if (this.formGroupRef.valid) {
                buttonRef.loader = true;
                this.ref.detectChanges();
                const value = this.formGroupRef.value;
                const apiArgs = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.action
                );
                apiArgs.input = value;
                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Incident created successfully'
                    );
                    buttonRef.loader = false;
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(
                        null,
                        this.modalInputData.modalId
                    );
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error creating incident'
                    );
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        },
    };
    public readonly incidents = [
        { impact: '1 - High', urgency: '1 - High', priority: '1 - Critical' },
        { impact: '1 - High', urgency: '2 - Medium', priority: '2 - High' },
        { impact: '1 - High', urgency: '3 - Low', priority: '3 - Moderate' },
        { impact: '2 - Medium', urgency: '1 - High', priority: '2 - High' },
        {
            impact: '2 - Medium',
            urgency: '2 - Medium',
            priority: '3 - Moderate',
        },
        { impact: '2 - Medium', urgency: '3 - Low', priority: '4 - Low' },
        { impact: '3 - Low', urgency: '1 - High', priority: '3 - Moderate' },
        { impact: '3 - Low', urgency: '2 - Medium', priority: '4 - Low' },
        { impact: '3 - Low', urgency: '3 - Low', priority: '5 - Planning' },
    ];

    constructor(
        public modalInputData: ModalInjectedData,
        private modalService: ModalService,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const modalData = this.modalInputData.data;
        this.widgetRef = modalData['widgetRef'];
        this.formGenInput = {
            state: FormState.CREATE,
            formName: 'Create Ticket',
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.TEXT,
                    label: 'Short Description',
                    name: 'shortDescription',
                    placeholder: 'Short Description',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Short description is required',
                        },
                    ],
                },
                {
                    fieldType: FilterType.TEXTAREA,
                    label: 'Detailed Description',
                    name: 'description',
                    placeholder: 'Detailed Description',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Detailed description is required',
                        },
                    ],
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Impact',
                    name: 'impact',
                    placeholder: 'Impact',
                    listData: Helper.servicenowIncidentPrioritiesForImpact,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident impact is required',
                        },
                    ],
                    fieldStyle: { width: '49%', 'margin-right': '2%' },
                },
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Urgency',
                    name: 'urgency',
                    placeholder: 'Set Urgency',
                    listData: Helper.servicenowIncidentPrioritiesUrgency,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident urgency is required',
                        },
                    ],
                    fieldStyle: { width: '49%' },
                },
            ],
        };
        this.resetSubcription = this.modalService.resetModal.subscribe(() => {
            const resetControls = this.formGenInput.fields.map((field) => {
                if (field.preventFieldReset) {
                    return field.name;
                }
            });
            if (resetControls.length) {
                Object.keys(this.formGroupRef.controls).map((control) => {
                    if (!resetControls.includes(control)) {
                        this.formGroupRef.controls[control].reset();
                    }
                });
            } else {
                this.formGroupRef.reset();
            }
        });
        this.priorityIncidentsFormInput = {
            formName: 'Incident Priority',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Incident Priority',
                    name: 'priority',
                    placeholder: 'Incident Priority',
                    listData: Helper.servicenowIncidentPriorities,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Incident priority is required',
                        },
                    ],
                    disabled: true,
                },
            ],
        };
    }

    valuesChange(values) {
        if (values.impact !== null && values.urgency !== null) {
            // Create a map to hold the impact-urgency combinations and their corresponding priority
            const priorityMap = {
                'HIGH:HIGH': '1 - Critical',
                'HIGH:MEDIUM': '2 - High',
                'HIGH:LOW': '3 - Moderate',
                'MEDIUM:HIGH': '2 - High',
                'MEDIUM:MEDIUM': '3 - Moderate',
                'MEDIUM:LOW': '4 - Low',
                'LOW:HIGH': '3 - Moderate',
                'LOW:MEDIUM': '4 - Low',
                'LOW:LOW': '5 - Planning',
            };
            const updatedPriority = this.setPriorityValue(
                values,
                this.incidentPriorityFormGroupRef,
                priorityMap
            );
        } else {
            // Handle the else condition if required
        }
    }

    ngOnDestroy() {
        this.resetSubcription.unsubscribe();
        this.incidentPriorityFormGroupRef.reset();
    }

    toggleRules(status: boolean) {
        this.isActive = status;
        this.showIncidentsTable = this.isActive ? true : false;
    }

    setPriorityValue(values, formGroupRef, priorityMap) {
        const priorityControl = formGroupRef.get('priority');
        const key = `${values.impact}:${values.urgency}`;
        const newPriority = priorityMap[key];
        // Only update the priority if the new value is different from the current value
        if (newPriority && priorityControl.value !== newPriority) {
            priorityControl.patchValue(newPriority, { emitEvent: false });
        }
        return newPriority;
    }
}
