import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-acl-view-layer-filter',
    templateUrl: './acl-view-layer-filter.component.html',
    styleUrls: ['./acl-view-layer-filter.component.sass']
})
export class AclViewLayerFilterComponent implements OnInit {
    @Input() selectedValue: IAclViewLayerData;
    @Input() lightMode: boolean;
    @Input() filterInfo: IFilterInfo;
    @Input() filterData: IFilterData;
    @Input() disabled;
    @Input() isCaching: boolean;
    @Input() refreshListing: BehaviorSubject<boolean>;
    @Output() valueChange = new EventEmitter();

    subSink: SubSink = new SubSink();
    layerNames = LayerNames;
    showAcl = false;
    refreshViewListing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshLayer1Listing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshLayer2Listing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshLayer3Listing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshLayer4Listing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refreshListArray = [];

    layersList = [
        LayerNames.layer1,
        LayerNames.layer2,
        LayerNames.layer3,
        LayerNames.layer4
    ];
    viewApiInfo: IApiInfo = {
        host: '',
        apiRouteSuffix: ApiUrls.VIEW_FILTER,
        authorization: AuthorizationType.BEARER_TOKEN,
        requestType: RequestType.POST,
        customInput: null
    };

    layerApiInfo: IApiInfo = {
        host: '',
        apiRouteSuffix: ApiUrls.LAYER_FILTER,
        authorization: AuthorizationType.BEARER_TOKEN,
        requestType: RequestType.POST,
        customInput: null
    };

    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };

    constructor() {}

    ngOnChanges(change) {
        if (change.selectedValue) {
            this.selectedValue = change.selectedValue.currentValue;
        }
    }

    ngOnInit(): void {
        if (this.refreshListing) {
            this.subSink.add(
                this.refreshListing.subscribe((val) => {
                    if (val) {
                        this.refreshViewListing.next(true);
                    }
                })
            );
        }

        this.refreshListArray = [
            this.refreshLayer1Listing,
            this.refreshLayer2Listing,
            this.refreshLayer3Listing,
            this.refreshLayer4Listing
        ];
    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

    handleResponse($event) {
        if ($event && $event.length) {
            this.showAcl = true;
        } else {
            this.showAcl = false;
        }
    }

    aclValueChanged($event) {
        if (this.selectedValue.acl !== $event) {
            this.selectedValue.acl = $event;
            this.selectedValue.view = null;
            this.layersList.forEach((layer) => {
                this.selectedValue[layer] = null;
            });
            this.refreshViewListing.next(true);
            this.refreshListArray.forEach((layer) => layer.next(true));
            this.emitChange();
        }
    }

    viewChanged($event) {
        if (
            ($event !== undefined || $event !== null) &&
            this.selectedValue.view !== $event
        ) {
            this.selectedValue.view = $event;
            this.layersList.forEach((layer) => {
                this.selectedValue[layer] = null;
            });
            this.refreshListArray.forEach((layer) => layer.next(true));
            this.emitChange();
        }
    }

    layerChanged($event, layerIndex) {
        if (
            ($event !== undefined || $event !== null) &&
            this.selectedValue[this.layersList[layerIndex]] !== $event
        ) {
            this.selectedValue[this.layersList[layerIndex]] = $event;
            let i = 1;
            this.refreshListArray.slice(layerIndex + 1).forEach((layer) => {
                this.selectedValue[this.layersList[layerIndex + i]] = null;
                i++;
                layer.next(true);
            });
            this.emitChange();
        }
    }

    emitChange() {
        this.valueChange.emit(this.selectedValue);
    }

    viewInputCallback() {
        return {
            acl: this.selectedValue.acl,
            view: null,
            layer: null
        };
    }

    layerInputCallback(layerIndex) {
        const input = {};
        input['acl'] = this.selectedValue.acl;
        input['view'] = layerIndex
            ? this.selectedValue[this.layersList[layerIndex - 1]]
                ? this.selectedValue.view
                : null
            : this.selectedValue.view;
        input['layer'] = this.selectedValue.layer1
            ? this.selectedValue[this.layersList[layerIndex - 1]]
            : null;

        return input;
    }
}

enum LayerNames {
    layer1 = 'layer1',
    layer2 = 'layer2',
    layer3 = 'layer3',
    layer4 = 'layer4'
}

interface IAclViewLayerData {
    acl: string[];
    view: string;
    [LayerNames.layer1]: string;
    [LayerNames.layer2]: string;
    [LayerNames.layer3]: string;
    [LayerNames.layer4]: string;
}
