import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-savings-plan-recommendation-modal-v2',
    templateUrl: './savings-plan-recommendation-modal-v2.component.html',
    styleUrls: ['./savings-plan-recommendation-modal-v2.component.sass']
})
export class SavingsPlanRecommendationModalV2Component implements OnInit {
    verticalTabs: string[] = [
        'Savings Overview',
        'Savings Plan Bifurcation',
        'Description'
    ];

    readonly TABLE_ALL_1_YR_KEY = 'allUpFront_1Yr';
    readonly TABLE_ALL_3_YR_KEY = 'allUpFront_3Yr';
    readonly TABLE_PARTIAL_1_YR_KEY = 'partialUpFront_1Yr';
    readonly TABLE_PARTIAL_3_YR_KEY = 'partialUpFront_3Yr';
    readonly TABLE_NO_1_YR_KEY = 'noUpFront_1Yr';
    readonly TABLE_NO_3_YR_KEY = 'noUpFront_3Yr';

    readonly JSON_SAVINGS_TO_BE_MADE_KEY = 'Savings To Be Made';
    readonly JSON_WASTAGE_PERCENTAGE_KEY = 'Wastage (%)';
    readonly JSON_SAVINGS_TABLE_KEY = 'savingsTable';
    readonly JSON_AMORTIZED_COST_KEY = 'amortizedCost';
    readonly JSON_DESCRIPTION_KEY = 'description';

    readonly COST_TYPE_KEY = 'costType';
    readonly USAGE_TYPE_KEY = 'Usage Type';

    dataItem: any;
    dataMap: any;

    mappedUpFrontName = {
        all: 'All Upfront',
        no: 'No Upfront',
        partial: 'Partial Upfront'
    };
    mappedKeys = {
        '1 Year || All Upfront': {
            key: this.TABLE_ALL_1_YR_KEY,
            year: 1,
            upFront: 'all'
        },
        '3 Years || All Upfront': {
            key: this.TABLE_ALL_3_YR_KEY,
            year: 3,
            upFront: 'all'
        },
        '1 Year || No Upfront': {
            key: this.TABLE_NO_1_YR_KEY,
            year: 1,
            upFront: 'no'
        },
        '3 Years || No Upfront': {
            key: this.TABLE_NO_3_YR_KEY,
            year: 3,
            upFront: 'no'
        },
        '1 Year || Partial Upfront': {
            key: this.TABLE_PARTIAL_1_YR_KEY,
            year: 1,
            upFront: 'partial'
        },
        '3 Years || Partial Upfront': {
            key: this.TABLE_PARTIAL_3_YR_KEY,
            year: 3,
            upFront: 'partial'
        }
    };
    showFilterObjects = [
        {
            id: 'payment',
            name: 'Payment Types',
            filters: [
                {
                    id: 'all',
                    name: this.mappedUpFrontName.all
                },
                {
                    id: 'partial',
                    name: this.mappedUpFrontName.partial
                },
                {
                    id: 'no',
                    name: this.mappedUpFrontName.no
                }
            ]
        },
        {
            id: 'offering',
            name: 'Offering Terms',
            filters: [
                {
                    id: '1Yr',
                    name: '1 Year'
                },
                {
                    id: '3Yr',
                    name: '3 Years'
                }
            ]
        }
    ];
    availableOverviewFilters: Set<string>;
    checkedOverviewFilters: Set<string>;
    availableBifurcationFilters: Set<string>;
    checkedBifurcationFilters: Set<string>;

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    overviewTabsActiveIndex = 0;
    overviewTabsList: ITableData[];

    overviewNoData: boolean = false;

    bifurcationTablesList: ITableData[];

    generalTableDefaultDef = {
        sortable: true,
        filter: false,
        resizable: true,
        minWidth: 150,
        width: 200,
        suppressMovable: true,
        headerClass: 'grid-cell-data-centred'
    };

    descriptionKeyValueList: { key: string; value: string }[];

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {
        this.dataItem = Helper.cloneDeep(modalData.data['itemData']);
        this.dataMap = modalData.data['dataMap'];
    }

    ngOnInit(): void {
        this.availableOverviewFilters = new Set<string>();
        this.checkedOverviewFilters = new Set<string>();
        this.availableBifurcationFilters = new Set<string>();
        this.checkedBifurcationFilters = new Set<string>();
        this.populateOverviewTabList();
        this.populateBifurcationTableList();
        this.prepareDescriptionPanel();
    }

    populateOverviewTabList() {
        this.overviewTabsList = [];
        const pushIntoOverviewTabs = (tabName, dataObject) => {
            const tableConfig = this.generateOverviewTableConfig(dataObject);
            this.overviewTabsList.push({
                name: tabName,
                tableGenInput: tableConfig.tableInput,
                tableData: tableConfig.tableData,
                gridRef: null,
                availableFilters: tableConfig.availableFilters
            });
        };
        if (this.dataItem[this.JSON_SAVINGS_TABLE_KEY]) {
            pushIntoOverviewTabs(
                'Savings (%)',
                this.dataItem[this.JSON_SAVINGS_TABLE_KEY]
            );
        }
        if (this.dataItem[this.JSON_AMORTIZED_COST_KEY]) {
            Object.keys(this.dataItem[this.JSON_AMORTIZED_COST_KEY]).forEach(
                (amortizedCostTabName) => {
                    pushIntoOverviewTabs(
                        amortizedCostTabName,
                        this.dataItem[this.JSON_AMORTIZED_COST_KEY][
                            amortizedCostTabName
                        ]
                    );
                }
            );
        }
        this.availableOverviewFilters =
            this.overviewTabsList[
                this.overviewTabsActiveIndex
            ].availableFilters;
        if (
            this.availableOverviewFilters &&
            this.availableOverviewFilters.size
        ) {
            if (
                this.availableOverviewFilters.has('1Yr') &&
                this.availableOverviewFilters.has('no')
            ) {
                this.checkedOverviewFilters = new Set(['1Yr', 'no']);
            } else {
                this.checkedOverviewFilters = new Set(
                    this.availableOverviewFilters.values()
                );
            }
        }
    }

    generateOverviewTableConfig(rawInput): {
        tableInput: ITableGeneratorInput;
        tableData: any;
        availableFilters: Set<string>;
    } {
        const tableInput: ITableGeneratorInput = {
            widgetIconData: null,
            columns: [],
            listExtraction: {
                type: 'DIRECT'
            },
            tableAutoHeight: true,
            getRowHeight: (params) =>
                Helper.calcRowHeight(params.data[this.COST_TYPE_KEY], 27)
        };
        const tableData = [];
        const rawInputList = Object.keys(rawInput);
        const availableFilters = new Set<string>();
        if (rawInputList.length) {
            const columnKeySet = new Set<string>();
            rawInputList.forEach((key) => {
                const tableDataObject = {
                    [this.COST_TYPE_KEY]: key
                };
                if (rawInput[key]) {
                    const rawInputKeyList = Object.keys(rawInput[key]);
                    if (rawInputKeyList && rawInputKeyList.length) {
                        rawInputKeyList.forEach((rawInputKey) => {
                            if (
                                this.mappedKeys[rawInputKey] &&
                                this.mappedKeys[rawInputKey].key
                            ) {
                                tableDataObject[
                                    this.mappedKeys[rawInputKey].key
                                ] = rawInput[key][rawInputKey];
                            }
                            columnKeySet.add(rawInputKey);
                        });
                    }
                }
                tableData.push(tableDataObject);
            });
            const columnMap = new Map<number, IColumnData[]>();
            columnKeySet.forEach((columnKey) => {
                const mappedColumnKey = this.mappedKeys[columnKey];
                if (
                    mappedColumnKey &&
                    mappedColumnKey.key &&
                    mappedColumnKey.year
                ) {
                    if (!columnMap.has(mappedColumnKey.year)) {
                        columnMap.set(mappedColumnKey.year, []);
                    }
                    availableFilters.add(mappedColumnKey.upFront);
                    columnMap.get(mappedColumnKey.year).push({
                        colId: mappedColumnKey.key,
                        columnKey: mappedColumnKey.key,
                        columnName:
                            this.mappedUpFrontName[mappedColumnKey.upFront],
                        maxWidth: 240
                    });
                }
            });
            columnMap.forEach((children, year) => {
                availableFilters.add(`${year}Yr`);
                const sortedChildren = this.getSortedOverviewColumns(children);
                tableInput.columns.push({
                    columnKey: '',
                    columnName: year === 1 ? `${year} Year` : `${year} Years`,
                    children: sortedChildren,
                    headerClass: 'grid-cell-data-centred'
                });
            });
            tableInput.columns.unshift({
                columnKey: this.COST_TYPE_KEY,
                columnName: 'Cost Type',
                pinned: 'left',
                minWidth: 200,
                maxWidth: 200,
                resizable: false,
                headerClass: 'no-border-top grid-cell-data-centred'
            });
        }
        return { tableInput, tableData, availableFilters };
    }

    onOverviewGridRefReceived(overviewTab, event) {
        overviewTab.gridRef = event;
        this.handleTableColumnToggle(
            overviewTab.gridRef,
            this.checkedOverviewFilters
        );
    }

    populateBifurcationTableList() {
        if (this.dataItem) {
            const tableNames = ['EC2 Instances', 'Lambda', 'Fargate'];
            this.bifurcationTablesList = [];
            tableNames.forEach((name) => {
                if (this.dataItem[name]) {
                    const tableConfig = this.generateBifurcationTableConfig(
                        this.dataItem[name]
                    );
                    this.bifurcationTablesList.push({
                        name,
                        tableData: tableConfig.tableData,
                        tableGenInput: tableConfig.tableInput,
                        gridRef: null
                    });
                }
            });
            if (
                this.availableBifurcationFilters &&
                this.availableBifurcationFilters.size
            ) {
                if (
                    this.availableBifurcationFilters.has('1Yr') &&
                    this.availableBifurcationFilters.has('no')
                ) {
                    this.checkedBifurcationFilters = new Set(['1Yr', 'no']);
                } else {
                    this.checkedBifurcationFilters = new Set(
                        this.availableBifurcationFilters.values()
                    );
                }
            }
        }
    }

    generateBifurcationTableConfig(rawInput): {
        tableInput: ITableGeneratorInput;
        tableData: any;
    } {
        const tableInput: ITableGeneratorInput = {
            widgetIconData: null,
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [],
            tableAutoHeight: true,
            getRowHeight: (params) =>
                Helper.calcRowHeight(params.data[this.USAGE_TYPE_KEY], 20)
        };
        let tableData = [];
        if (rawInput && rawInput.length) {
            const savingColumns = [];
            const columns: IColumnData[] = [];
            const insertIfNotThere = (name, key, template?: boolean) => {
                let found;
                if (template) {
                    found = savingColumns.find(
                        (column) => column.columnKey === key
                    );
                } else {
                    found = columns.find((column) => column.columnKey === key);
                }
                if (!found) {
                    const colObject: IColumnData = {
                        colId: key,
                        columnKey: key,
                        columnName: name,
                        headerTooltip: name
                    };
                    if (template) {
                        colObject.headerClass = 'grid-cell-data-centred';
                        colObject.minWidth = 230;
                        savingColumns.push(colObject);
                    } else {
                        colObject.cellClass = 'line-height-1';
                        columns.push(colObject);
                    }
                }
            };
            const prepareObjectTypeData = (key, data, parent) => {
                const dataKeys = Object.keys(data);
                if (dataKeys && dataKeys.length) {
                    dataKeys.forEach((dataKey) => {
                        const mapKeyIndex = dataKey.indexOf(' (');
                        let mapKey = dataKey;
                        if (mapKeyIndex >= 0) {
                            mapKey = dataKey.substring(0, mapKeyIndex);
                        }
                        const mappedKey = this.mappedKeys[mapKey];
                        if (
                            mappedKey &&
                            mappedKey.key &&
                            mappedKey.upFront &&
                            mappedKey.year
                        ) {
                            parent[mappedKey.key] = data[dataKey];
                            this.availableBifurcationFilters.add(
                                mappedKey.upFront
                            );
                            this.availableBifurcationFilters.add(
                                `${mappedKey.year}Yr`
                            );
                            insertIfNotThere(
                                `${key} ${dataKey}`,
                                mappedKey.key,
                                true
                            );
                        }
                    });
                }
            };
            rawInput.forEach((rawInputData) => {
                const rawInputDataKeys = Object.keys(rawInputData);
                if (rawInputDataKeys && rawInputDataKeys.length) {
                    rawInputDataKeys.forEach((rawInputDataKey) => {
                        if (typeof rawInputData[rawInputDataKey] === 'object') {
                            prepareObjectTypeData(
                                rawInputDataKey,
                                rawInputData[rawInputDataKey],
                                rawInputData
                            );
                        } else {
                            insertIfNotThere(rawInputDataKey, rawInputDataKey);
                        }
                    });
                }
            });
            tableData = rawInput;
            tableInput.columns = [
                ...columns,
                ...this.getSortedBifurcatedColumns(savingColumns)
            ];
        }
        return {
            tableInput,
            tableData
        };
    }

    onBifurcationGridRefReceived(bifurcatedTable, event) {
        bifurcatedTable.gridRef = event;
        this.handleTableColumnToggle(
            bifurcatedTable.gridRef,
            this.checkedBifurcationFilters
        );
    }

    prepareDescriptionPanel() {
        if (this.dataItem && this.dataItem[this.JSON_DESCRIPTION_KEY]) {
            const descriptionKeyList = Object.keys(
                this.dataItem[this.JSON_DESCRIPTION_KEY]
            );
            if (descriptionKeyList && descriptionKeyList.length) {
                this.descriptionKeyValueList = descriptionKeyList.map(
                    (key) => ({
                        key: key,
                        value: this.dataItem[this.JSON_DESCRIPTION_KEY][key]
                    })
                );
            }
        }
    }

    onOverviewTabChanged(index) {
        this.overviewTabsActiveIndex = index;
        const activeTab = this.overviewTabsList[this.overviewTabsActiveIndex];
        const newAvailableFilters = activeTab.availableFilters;
        const newCheckedFilters = new Set<string>();
        this.checkedOverviewFilters.forEach((filterId) => {
            if (newAvailableFilters.has(filterId)) {
                newCheckedFilters.add(filterId);
            }
        });
        this.checkedOverviewFilters = newCheckedFilters;
        this.availableOverviewFilters = newAvailableFilters;
        this.handleTableColumnToggle(
            activeTab.gridRef,
            this.checkedOverviewFilters
        );
    }

    handleTableColumnToggle(tableGridRef: GridOptions, filterSet: Set<string>) {
        if (tableGridRef) {
            const columns = this.getHiddenVisibleColumnSet(filterSet);
            if (columns.visibleColumns && columns.visibleColumns.length) {
                tableGridRef.columnApi.setColumnsVisible(
                    columns.visibleColumns,
                    true
                );
            }
            if (columns.hiddenColumns && columns.hiddenColumns.length) {
                tableGridRef.columnApi.setColumnsVisible(
                    columns.hiddenColumns,
                    false
                );
                if (columns.hiddenColumns.length === 6) {
                    this.overviewNoData = true;
                } else {
                    this.overviewNoData = false;
                }
            } else {
                this.overviewNoData = false;
            }
        }
    }

    getHiddenVisibleColumnSet(filterSet: Set<string>): {
        visibleColumns: string[];
        hiddenColumns: string[];
    } {
        const hiddenColumnSet = new Set([
            this.TABLE_ALL_1_YR_KEY,
            this.TABLE_ALL_3_YR_KEY,
            this.TABLE_PARTIAL_1_YR_KEY,
            this.TABLE_PARTIAL_3_YR_KEY,
            this.TABLE_NO_1_YR_KEY,
            this.TABLE_NO_3_YR_KEY
        ]);
        const columnSet = new Set<string>();
        if (filterSet && filterSet.size) {
            if (filterSet.has('1Yr')) {
                if (filterSet.has('all')) {
                    columnSet.add(this.TABLE_ALL_1_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_ALL_1_YR_KEY);
                }
                if (filterSet.has('partial')) {
                    columnSet.add(this.TABLE_PARTIAL_1_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_PARTIAL_1_YR_KEY);
                }
                if (filterSet.has('no')) {
                    columnSet.add(this.TABLE_NO_1_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_NO_1_YR_KEY);
                }
            }
            if (filterSet.has('3Yr')) {
                if (filterSet.has('all')) {
                    columnSet.add(this.TABLE_ALL_3_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_ALL_3_YR_KEY);
                }
                if (filterSet.has('partial')) {
                    columnSet.add(this.TABLE_PARTIAL_3_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_PARTIAL_3_YR_KEY);
                }
                if (filterSet.has('no')) {
                    columnSet.add(this.TABLE_NO_3_YR_KEY);
                    hiddenColumnSet.delete(this.TABLE_NO_3_YR_KEY);
                }
            }
        }
        return {
            visibleColumns: Array.from(columnSet.values()),
            hiddenColumns: Array.from(hiddenColumnSet.values())
        };
    }

    onOverviewFilterChanged(event, filterId, parentId) {
        if (event.checked) {
            this.checkedOverviewFilters.add(filterId);
        } else {
            this.checkedOverviewFilters.delete(filterId);
        }
        this.handleTableColumnToggle(
            this.overviewTabsList[this.overviewTabsActiveIndex].gridRef,
            this.checkedOverviewFilters
        );
    }

    onBifurcationFilterChanged(event, filterId, parentId) {
        if (event.checked) {
            this.checkedBifurcationFilters.add(filterId);
        } else {
            this.checkedBifurcationFilters.delete(filterId);
        }
        this.bifurcationTablesList.forEach((table) => {
            this.handleTableColumnToggle(
                table.gridRef,
                this.checkedBifurcationFilters
            );
        });
    }

    getSortedOverviewColumns(columns: IColumnData[]) {
        if (columns && columns.length > 1) {
            const checkColumnByName = (name) => {
                return columns.find((column) => column.columnName === name);
            };
            const sortedColumns = [];
            const sortOrder = ['no', 'partial', 'all'];
            sortOrder.forEach((order) => {
                const column = checkColumnByName(this.mappedUpFrontName[order]);
                if (column) {
                    sortedColumns.push(column);
                }
            });
            return sortedColumns;
        } else {
            return columns;
        }
    }

    getSortedBifurcatedColumns(columns: IColumnData[]): IColumnData[] {
        if (columns && columns.length > 1) {
            const checkColumnById = (id) => {
                return columns.find((column) => column.colId === id);
            };
            const sortedColumns = [];
            const sortOrder = [
                this.TABLE_NO_1_YR_KEY,
                this.TABLE_NO_3_YR_KEY,
                this.TABLE_PARTIAL_1_YR_KEY,
                this.TABLE_PARTIAL_3_YR_KEY,
                this.TABLE_ALL_1_YR_KEY,
                this.TABLE_ALL_3_YR_KEY
            ];
            sortOrder.forEach((sortId) => {
                const column = checkColumnById(sortId);
                if (column) {
                    sortedColumns.push(column);
                }
            });
            return sortedColumns;
        } else {
            return columns;
        }
    }
}

interface ITableData {
    name: string;
    tableGenInput: ITableGeneratorInput;
    tableData: any;
    gridRef: GridOptions;
    availableFilters?: Set<string>;
}
