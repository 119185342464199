<div class="filter">
	<div class="currency">
		<div class="filter-head">
			<div class="filter-name {{ filterInfo.lightMode ? 'light' : false }}">
				Currency
			</div>
			<div
				class="filter-controls"
				*ngIf="!dummy && filterData.associatedWidgets"
			>
				<div
					appIcon
					*ngIf="filterData?.associatedWidgets.length"
					[matMenuTriggerFor]="associatedWidgetsMenu"
					[matTooltip]="'Widgets associated with filter'"
					[data]="infoIcon"
					class="action"
					[class.disabled]="disabled"
				></div>
				<mat-menu #associatedWidgetsMenu="matMenu">
					<div class="more-info-menu">
						<div class="heading">
							Filter is applicable on following insights
						</div>
						<div
							class="info-item not-clickable"
							*ngFor="
								let widget of filterData.associatedWidgets;
								let i = index
							"
						>
							{{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
						</div>
					</div>
				</mat-menu>
			</div>
		</div>
		<div class="filter-content">
			<app-single-select-dropdown
				[isCaching]="true"
				[refreshListing]="refreshListing"
				[filterInfo]="filterInfo"
				[selectedValue]="selectedValue"
				[clearable]="false"
				[dummy]="dummy"
				(change)="singleDropdownValueChange($event)"
			></app-single-select-dropdown>
		</div>
	</div>
	<div class="exchange-rate">
		<div class="filter-head">
			<div class="filter-name {{ filterInfo.lightMode ? 'light' : false }}">
				Exchange Rate
			</div>
		</div>
		<div class="filter-content">
			<div
				class="input-field"
				[matTooltip]="!customExchangeRate ? 'Current Exchange Rate' : ''"
			>
				<input
					class="number-field {{ filterInfo.lightMode ? 'light' : false }}"
					type="number"
					[value]="exchangeRate"
					[disabled]="!customExchangeRate || dummy"
					[class.disabled]="!customExchangeRate && !dummy"
					step="any"
					(change)="exchangeRateChanged($event.target.value)"
				/>
			</div>

			<div class="checkbox">
				<mat-checkbox
					color="accent"
					(change)="defaultExchangeRateChanged($event)"
					[(ngModel)]="customExchangeRate"
					[matTooltip]="
						!customExchangeRate
							? 'Enable to add custom exchange rate'
							: ''
					"
					[disabled]="dummy"
				></mat-checkbox>
			</div>
		</div>
	</div>
	<div>
		<app-button-generator
			class="remove-currency-button"
			[buttonGenInput]="removeButtonGenInput"
			(buttonRef)="removeButtonGenInput.function(index)"
		></app-button-generator>
	</div>
</div>
