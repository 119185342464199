<form class="form">
    <div class="form-data">
        <app-form-generator
            [formGeneratorInput]="frequencyFormGenInput"
            (formGroupRef)="frequencyFormGroup = $event"
        ></app-form-generator>

        <ng-container
            *ngIf="
                frequencyFormGroup &&
                frequencyFormGroup.get('frequency') &&
                frequencyFormGroup.get('frequency').value
            "
        >
            <ng-container
                *ngTemplateOutlet="
                    frequencyFormGroup.get('frequency').value === 'hourly'
                        ? hourlyFrequency
                        : frequencyFormGroup.get('frequency').value ===
                          'monthly'
                        ? monthlyFrequency
                        : frequencyFormGroup.get('frequency').value === 'weekly'
                        ? weeklyFrequency
                        : dailyFrequency
                "
            ></ng-container>
        </ng-container>

        <app-form-generator
            *ngIf="dateFormGenInput"
            [formGeneratorInput]="dateFormGenInput"
            (formGroupRef)="dateFormGroup = $event"
            (valuesChange)="onDateChange($event)"
        ></app-form-generator>

        <app-form-generator
            *ngIf="timeFormGenInput"
            [formGeneratorInput]="timeFormGenInput"
            (formGroupRef)="timeFormGroup = $event"
        ></app-form-generator>

        <ng-template #hourlyFrequency>
            <app-form-generator
                [formGeneratorInput]="hourlyFormGenInput"
                (formGroupRef)="hourlyFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <ng-template #weeklyFrequency>
            <app-form-generator
                [formGeneratorInput]="weeklyFormGenInput"
                (formGroupRef)="weeklyFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <ng-template #monthlyFrequency>
            <app-form-generator
                [formGeneratorInput]="monthlyFormGenInput"
                (formGroupRef)="monthlyFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <ng-template #dailyFrequency>
            <app-form-generator
                [formGeneratorInput]="dailyFormGenInput"
                (formGroupRef)="dailyFormGroup = $event"
            ></app-form-generator>
        </ng-template>
        <app-form-generator
            *ngIf="timeZoneFormGenInput"
            [formGeneratorInput]="timeZoneFormGenInput"
            (formGroupRef)="timeZoneFormGroup = $event"
        ></app-form-generator>
    </div>
    <div class="tw-flex tw-justify-between tw-mr-2 form-controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>
        <div class="tw-flex tw-flex-row">
            <app-button-generator
                *ngIf="createUpdateButton"
                [buttonGenInput]="createUpdateButton"
            ></app-button-generator>
            <app-button-generator
                *ngIf="nextStepButton"
                [buttonGenInput]="nextStepButton"
            ></app-button-generator>
        </div>
    </div>
</form>
