import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ApiResponseType } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IntegrationCacheService } from 'src/app/shared/services/cache/integration-cache/integration-cache.service';

@Component({
    selector: 'app-zendesk-listing',
    templateUrl: './zendesk-listing.component.html',
    styleUrls: ['./zendesk-listing.component.sass']
})
export class ZendeskListingComponent implements OnInit, OnDestroy {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    widgetIcon: IIcon = {
        class: 'assets/integrations/freshdesk-mini-logo.svg',
        type: IconType.IMAGE
    };
    tableData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    agGrid: GridOptions = null;
    appliedFilter = null;
    statusMap;
    currentPage = 1;
    totalPages: number;
    showBackButton = false;
    showNextButton = true;
    constructor(
        widgetData: WidgetInjectedData,
        private integrationCacheService: IntegrationCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.initializeStatusColors();
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    initializeStatusColors() {
        this.statusMap = {
            open: {
                name: 'Open',
                color: Helper.getCssVarValue('negativeTicketColor'),
                count: 0
            },
            new: {
                name: 'New',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('accentTicketColor')
                ),
                count: 0
            },
            pending: {
                name: 'Pending',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('accentLightTicketColor')
                ),
                count: 0
            },
            solved: {
                name: 'Solved',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('postiveTicketColor')
                ),
                count: 0
            },
            hold: {
                name: 'On Hold',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('blueTicketColor')
                ),
                count: 0
            },
            closed: {
                name: 'Closed',
                color: Helper.convertToHexIfHsl(
                    Helper.getCssVarValue('neutralTicketColor')
                ),
                count: 0
            }
        };
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Case',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openTestingModal(this.widgetRef.apiResponse);
                    },
                    showLoader: false
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'tickets'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'status',
                    columnName: '',
                    pinned: 'left',
                    cellRenderer: (rowData: RowEvent) => {
                        const status = rowData.data['status'];
                        const circleDiv = `
                            <div
                                style = "
                                    height: 14px;
                                    width: 14px;
                                    background: ${
                                        this.statusMap[status]
                                            ? this.statusMap[status].color
                                            : 'black'
                                    };
                                    border-radius: 100%;
                                "
                            >
                            </div>
                        `;
                        return circleDiv;
                    }
                },
                {
                    columnKey: 'requesterEmail',
                    columnName: 'Requester'
                },
                {
                    columnKey: 'subject',
                    columnName: 'Subject'
                },
                {
                    columnKey: 'created_at',
                    columnName: 'Creation Time'
                },
                {
                    columnKey: 'created_at',
                    columnName: 'Creation Time'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    cellRenderer: (rowData: RowEvent) => {
                        return this.statusMap[rowData.data['status']].name;
                    }
                }
            ],
            afterResponse: (response) => {
                this.overviewRender(response);
            }
        };
        const zendeskScript = this.integrationCacheService.zendeskScript;
        if (zendeskScript) {
            this.loadScript(zendeskScript);
        }
    }

    overviewRender(response) {
        //Resetting status count
        Object.keys(this.statusMap).forEach((status) => {
            this.statusMap[status].count = 0;
        });
        response['tickets'].forEach((row) => {
            const status = row['status'];
            this.statusMap[status].count++;
        });
        if (response && response.totalPages) {
            this.totalPages = response.totalPages;
        }
    }

    filterStatus(statusKey) {
        if (this.appliedFilter !== statusKey) {
            this.appliedFilter = statusKey;
            const filteredRows = this.widgetRef.apiResponse['tickets'].filter(
                (rowData) => rowData['status'] === this.appliedFilter
            );
            this.agGrid.api.setRowData(filteredRows);
            this.agGrid.api.refreshCells();
        } else {
            this.appliedFilter = null;
            this.agGrid.api.setRowData(this.widgetRef.apiResponse['tickets']);
            this.agGrid.api.refreshCells();
        }
    }

    openTestingModal(response) {
        const scriptToRender = response.widgetCode;
        const sub = scriptToRender.substr(
            (scriptToRender.indexOf(`src="`) >= 0
                ? scriptToRender.indexOf(`src="`)
                : scriptToRender.indexOf(`src='`)) + 5
        );
        const loadedScript = this.integrationCacheService.zendeskScript;
        const url = sub.substr(
            0,
            sub.indexOf(`">`) >= 0 ? sub.indexOf(`">`) : sub.indexOf(`'>`)
        );
        if (!loadedScript) {
            this.integrationCacheService.zendeskScript = url;
            this.loadScript(url);
            return;
        }
        if (loadedScript === url) {
            $('#launcher').show();
        } else {
            this.integrationCacheService.zendeskScript = url;
            window.location.reload();
        }
    }
    loadScript(url) {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.id = 'ze-snippet';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
    bindData(apiResponse: ApiResponseType) {
        this.tableData.next(apiResponse);
        this.overviewRender(apiResponse);
        if (apiResponse['tickets']) {
            if (!apiResponse['tickets'].length) {
                this.showBackButton = true;
                this.showNextButton = false;
            } else if (this.totalPages === this.currentPage) {
                this.showNextButton = false;
                this.showBackButton = true;
            } else if (
                apiResponse['tickets'].length &&
                this.currentPage === 1
            ) {
                this.showBackButton = false;
                this.showNextButton = true;
            } else {
                this.showBackButton = true;
                this.showNextButton = true;
            }
        }
    }
    changePage(pageNumber, pageFlow) {
        const customInput = {
            pageNumber: pageNumber
        };
        if (pageFlow === 'back') {
            this.currentPage--;
        }
        if (pageFlow === 'next') {
            this.currentPage++;
        }
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(
            this.bindData.bind(this),
            true,
            customInput,
            this.setPageNumber.bind(this)
        );
    }
    setPageNumber() {
        return { pageNumber: this.currentPage };
    }
    ngOnDestroy() {
        if ($('#launcher')) {
            $('#launcher').hide();
        }
    }
}
