import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MyErrorStateMatcher } from './../../../classes/ErrorStateMatcher';
import { NumberField } from './../../../enums/AppearanceType';
import { FilterType } from './../../../enums/FilterType';
import { FormState } from './../../../enums/FormState';
import { IFormField } from './../../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-number-field',
    templateUrl: './number-field.component.html',
    styleUrls: ['./number-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: NumberFieldComponent,
            multi: true
        }
    ]
})
export class NumberFieldComponent
    implements OnInit, OnChanges, ControlValueAccessor
{
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    FilterType = FilterType;
    FormState = FormState;
    onChange: any = () => {};
    onTouch: any = () => {};
    FieldAppearance = NumberField;
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    percentage: number = 0;
    value: number;
    Infinity = Infinity;

    constructor(private filterService: FiltersService) {}

    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (
            !existingAppearance.includes(this.field.appearance as NumberField)
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
    }

    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }

    writeValue(value: number): void {
        if (this.field.showArrow) {
            this.percentage = value;
        } else {
            this.value = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.control = this.findControl();
    }
    valueChanged(value) {
        if (this.field.showArrow) {
            if (value > 100) {
                value = 100;
            }
            if (value < 0) {
                value = 0;
            }
            this.percentage = value;
            this.onChange(this.percentage);
        }
        if (this.field.isFilter) {
            if (this.filterService.isCompareViewEnabled) {
                this.filterService.comapareViewValueChanged = true;
            } else {
                this.filterService.valueChanged = true;
            }
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.setDisabledState(false);
    }
}
