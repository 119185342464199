import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { FiltersHttpService } from 'src/app/shared/services/http/filters-http/filters-http.service';
import { HitApi } from '../../../classes/HitApi';
import { IDropdownData } from '../../../interfaces/dropdown-data/IDropdownData';
import { IHitApi } from '../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-grouping-dropdown',
    templateUrl: './grouping-dropdown.component.html',
    styleUrls: ['./grouping-dropdown.component.sass']
})
export class GroupingDropdownComponent implements OnInit, OnChanges {
    @Input() filterInfo: IFilterInfo;
    @Input() selectedValues;
    @Input() loadingMessage: string;
    @Input() isCaching: boolean;
    @Input() disabled: boolean;
    @Input() dropdownLabel: string;

    // tslint:disable-next-line: no-output-native
    @Output() change = new EventEmitter();

    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    showSelectAll = true;
    bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
    dataBuffer: IDropdownData[] = [];
    uniqueIdentity: symbol;
    filteredList: IDropdownData[] = [];
    showLoading = false;
    loading = false;
    valueChanges = false;
    isOpen = false;
    isFreshOpen = true;

    errorMessage = {
        message: ''
    };
    selectedItems = [];
    @Input() refreshListing: BehaviorSubject<boolean>;

    constructor(
        private filtersHttpService: FiltersHttpService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.handleBasics();
    }

    ngOnChanges(change) {
        if (change.filterInfo) {
            this.filterInfo = change.filterInfo.currentValue;
            this.handleBasics();
        }
        if ('selectedValues' in change) {
            if (change.selectedValues && change.selectedValues.currentValue) {
                this.selectedValues = JSON.parse(
                    JSON.stringify(change.selectedValues.currentValue)
                );
                this.selectedItems = JSON.parse(
                    JSON.stringify(change.selectedValues.currentValue)
                );
            } else {
                this.selectedValues = [];
                this.selectedItems = [];
            }
        }
    }

    handleDisable() {
        this.ngSelect.viewPortItems.forEach((item) => {
            if (
                this.filterInfo.selectionLimit &&
                this.selectedItems.length >= this.filterInfo.selectionLimit &&
                !item.selected
            ) {
                item.disabled = true;
            } else {
                item.disabled = false;
            }
        });
    }

    selectionChange($event) {
        if (
            this.filterInfo.selectionLimit &&
            $event.length > this.filterInfo.selectionLimit
        ) {
            let counter = 0;
            const selectedValue = [];
            $event.forEach((group) => {
                counter++;
                if (counter <= this.filterInfo.selectionLimit) {
                    selectedValue.push(group);
                }
            });
            if (!this.filterInfo.getFullObj) {
                this.selectedItems = selectedValue.map((item) => item.id);
                this.selectedValues = selectedValue.map((item) => item.id);
            } else {
                this.selectedValues = selectedValue;
                this.selectedItems = selectedValue;
            }
            this.handleDisable();
            return;
        }
        if (!this.filterInfo.getFullObj) {
            this.selectedItems = $event.map((item) => item.id);
            this.selectedValues = $event.map((item) => item.id);
        } else {
            this.selectedValues = $event;
            this.selectedItems = $event;
        }
        this.valueChanges = true;
        if (this.filterInfo.restrictGroupItemSelect) {
            this.disableGroupItem(this.filterInfo.getFullObj);
        } else {
            this.handleDisable();
        }
    }

    onScrollToEnd() {
        if (this.filterInfo.restrictGroupItemSelect) {
            return;
        }
        this.fetchMore();
        this.handleDisable();
    }

    onScroll({ end }) {
        if (this.filterInfo.restrictGroupItemSelect) {
            return;
        }
        if (
            this.loading ||
            this.filteredList.length <= this.dataBuffer.length
        ) {
            return;
        }
        if (
            end + this.numberOfItemsFromEndBeforeFetchingMore >=
            this.dataBuffer.length
        ) {
            this.fetchMore();
        }
        this.handleDisable();
    }
    private fetchMore() {
        const len = this.dataBuffer.length;
        const more = this.filteredList.slice(len, this.bufferSize + len);
        this.loading = true;
        // using timeout here to simulate backend API delay
        this.loading = false;
        this.dataBuffer = this.dataBuffer.concat(more);
    }

    onSelectAll(event) {
        this.valueChanges = true;
        if (event === 'selectAll') {
            if (this.filterInfo.selectionLimit) {
                if (this.filterInfo.getFullObj) {
                    const selected = [];
                    let i = this.selectedValues.length;
                    this.filteredList.every((item) => {
                        let flag = false;
                        if (i.length < this.filterInfo.selectionLimit) {
                            this.selectedValues.every((element) => {
                                if (element.id === item.id) {
                                    flag = true;
                                    return false;
                                }
                                return true;
                            });
                        }
                        if (!flag) {
                            selected.push(item);
                            i++;
                        }
                        if (i === this.filterInfo.selectionLimit) {
                            return false;
                        }
                        return true;
                    });
                    this.selectedValues.push(...selected);
                    this.selectedItems = this.selectedValues;
                } else {
                    const selected = [];
                    let i = this.selectedValues.length;
                    this.filteredList.every((item) => {
                        if (
                            !this.selectedValues.includes(item.id) &&
                            i < this.filterInfo.selectionLimit
                        ) {
                            selected.push(item.id);
                            i++;
                        }
                        if (i === this.filterInfo.selectionLimit) {
                            return false;
                        }
                        return true;
                    });
                    this.selectedValues.push(...selected);
                    this.selectedItems = this.selectedValues;
                }
            } else {
                if (this.filterInfo.getFullObj) {
                    this.selectedValues = JSON.parse(
                        JSON.stringify(this.filteredList)
                    );
                    this.selectedItems = JSON.parse(
                        JSON.stringify(this.filteredList)
                    );
                } else {
                    const selected = this.filteredList.map((item) => item.id);
                    this.selectedValues = selected;
                    this.selectedItems = selected;
                }
            }
        } else {
            this.selectedValues = [];
            this.selectedItems = [];
        }
        //this.handleDisable();
    }

    emitChange(type) {
        if (type === 'close') {
            if (this.valueChanges) {
                this.change.emit(this.selectedValues);
                this.valueChanges = false;
            }
        } else if (type === 'clear') {
            this.selectedValues = [];
            this.change.emit(this.selectedValues);
        }
    }

    hitApi() {
        this.filteredList = [];
        this.loading = true;
        this.showLoading = true;
        if (this.filterInfo.apiInfo) {
            const args: IHitApi = Helper.generateHitApiConfig(
                this.filterInfo.apiInfo
            );
            args.input = {};
            args.config.isCached = this.isCaching;
            args.function = this.bindData.bind(this);
            args.endFunction = () => {
                this.showLoading = false;
                this.loading = false;
            };
            new HitApi(args, this.filtersHttpService, this.ngZone).hitApi();
        }
    }

    bindData(response) {
        this.showLoading = false;
        this.loading = false;
        this.filteredList = this.filterInfo.getKey
            ? response[this.filterInfo.getKey]
            : response.dataList;
    }

    handleBasics() {
        if (this.filterInfo.restrictGroupItemSelect) {
            this.showSelectAll = false;
        }
        if (!this.filterInfo.placeholder) {
            this.filterInfo.placeholder = 'Select ' + this.filterInfo.label;
        }
        if (this.selectedValues) {
            this.selectedItems = JSON.parse(
                JSON.stringify(this.selectedValues)
            );
        } else {
            this.selectedValues = [];
        }
        if (this.filterInfo.listData) {
            this.filteredList = this.filterInfo.listData;
        }
        if (this.filterInfo.apiInfo) {
            this.hitApi();
        }

        if (this.refreshListing) {
            this.refreshListing.subscribe((res) => {
                if (res) {
                    this.hitApi();
                    this.selectedValues = [];
                    this.selectedItems = [];
                }
            });
        }
    }

    onOpenDropdown(event) {
        this.isOpen = true;
        if (
            this.filterInfo.restrictGroupItemSelect &&
            this.isFreshOpen &&
            this.selectedValues &&
            this.selectedValues.length
        ) {
            setTimeout(
                () => this.disableGroupItem(this.filterInfo.getFullObj),
                500
            );
            this.isFreshOpen = false;
        }
    }

    /**
     * For disabling items based on groups if an item lies in one group and select
     * then items in other groups are disabled
     *
     * @param getFullObj  Whether the selected value list is having objects or strings
     * is true then object else string
     *
     */
    disableGroupItem(getFullObj) {
        const itemToGroupMap = new Map<string, string>();
        this.selectedValues.forEach((selectedValue) => {
            const selectedValueList = getFullObj
                ? selectedValue.id.split('|')
                : selectedValue.split('|');
            if (selectedValueList.length > 1) {
                itemToGroupMap.set(selectedValueList[1], selectedValueList[0]);
            }
        });
        if (
            this.ngSelect &&
            this.ngSelect.viewPortItems &&
            this.ngSelect.viewPortItems.length
        ) {
            this.ngSelect.viewPortItems.forEach((item) => {
                if (!item.children) {
                    if (!item.selected) {
                        if (
                            typeof item.value === 'object' &&
                            item.value['id']
                        ) {
                            const splittedList = item.value['id'].split('|');
                            if (splittedList.length > 1) {
                                if (
                                    itemToGroupMap.has(splittedList[1]) &&
                                    itemToGroupMap.get(splittedList[1]) !==
                                        splittedList[0]
                                ) {
                                    item.disabled = true;
                                } else {
                                    item.disabled = false;
                                }
                            }
                        }
                    }
                }
            });
        }
    }
}
