<div
    *ngIf="buttonGenInput"
    [matTooltip]="
        buttonGenInput.hoverText ? buttonGenInput.hoverText : hoverText
    "
    [matTooltipPosition]="
        buttonGenInput.tooltipPosition
            ? buttonGenInput.tooltipPosition
            : 'below'
    "
    class="buttons-container"
>
    <button
        *ngIf="
            buttonGenInput.matMenu &&
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.TEXT
        "
        type="button"
        [matMenuTriggerFor]="buttonGenInput.matMenu"
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span
            [ngClass]="{ 'button-name': true, blur: buttonGenInput.blurEffect }"
        >
            {{ buttonGenInput.buttonName }}
        </span>

        <mat-spinner
            class="light-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.matMenu &&
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.TEXT
        "
        type="button"
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span
            [ngClass]="{ 'button-name': true, blur: buttonGenInput.blurEffect }"
        >
            {{ buttonGenInput.buttonName }}
        </span>

        <mat-spinner
            class="light-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.FLAT
        "
        type="button"
        mat-flat-button
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span class="button-name">
            {{ buttonGenInput.buttonName }}
        </span>
        <ng-container *ngIf="buttonGenInput.buttonIcon">
            <span class="button-icon">
                <div
                    *ngIf="buttonGenInput.buttonIcon"
                    appIcon
                    [data]="buttonGenInput.buttonIcon"
                    class="overlay-loader"
                ></div>
            </span>
        </ng-container>

        <mat-spinner
            class="light-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            buttonGenInput.matMenu &&
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.ICON
        "
        type="button"
        [matMenuTriggerFor]="buttonGenInput.matMenu"
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.no-hover]="buttonGenInput.preventHoverEffect ? true : false"
        [class.no-stroke]="buttonGenInput.iconButtonType === 'filled'"
        [class.no-border]="buttonGenInput.preventBorder ? true : false"
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span class="button-icon">
            <div
                *ngIf="buttonGenInput.buttonIcon"
                appIcon
                [data]="buttonGenInput.buttonIcon"
                class="overlay-loader"
            ></div>
        </span>

        <mat-spinner
            class="dark-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.matMenu &&
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.ICON
        "
        type="button"
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.no-hover]="buttonGenInput.preventHoverEffect ? true : false"
        [class.no-stroke]="buttonGenInput.iconButtonType === 'filled'"
        [class.no-border]="buttonGenInput.preventBorder ? true : false"
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span class="button-icon">
            <div
                *ngIf="buttonGenInput.buttonIcon"
                appIcon
                [data]="buttonGenInput.buttonIcon"
                class="overlay-loader"
            ></div>
        </span>

        <mat-spinner
            class="dark-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.CIRCLE
        "
        type="button"
        [ngClass]="buttonClass"
        [class.disabled]="buttonGenInput.disable"
        [class.primary]="
            buttonGenInput.buttonColorType === ButtonColorType.PRIMARY
        "
        [class.secondary]="
            buttonGenInput.buttonColorType === ButtonColorType.SECONDARY
        "
        [class.success]="
            buttonGenInput.buttonColorType === ButtonColorType.SUCCESS
        "
        [class.info]="buttonGenInput.buttonColorType === ButtonColorType.INFO"
        [class.warn]="buttonGenInput.buttonColorType === ButtonColorType.WARN"
        [class.default]="
            buttonGenInput.buttonColorType === ButtonColorType.DEFAULT
        "
        [class.loader-active]="
            buttonGenInput.showLoader && buttonGenInput.loader
        "
        [disabled]="buttonGenInput.disable"
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span class="button-icon">
            <div
                *ngIf="buttonGenInput.buttonIcon"
                appIcon
                [data]="buttonGenInput.buttonIcon"
                class="tw-flex overlay-loader"
            ></div>
        </span>

        <mat-spinner
            [diameter]="28"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.RAISED
        "
        type="button"
        mat-raised-button
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <span class="button-name">
            {{ buttonGenInput.buttonName }}
        </span>
        <ng-container *ngIf="buttonGenInput.buttonIcon">
            <span class="button-icon">
                <div
                    *ngIf="buttonGenInput.buttonIcon"
                    appIcon
                    [data]="buttonGenInput.buttonIcon"
                    class="overlay-loader"
                ></div>
            </span>
        </ng-container>

        <mat-spinner
            class="light-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.STROKED
        "
        type="button"
        mat-stroked-button
        [ngClass]="buttonClass"
        [disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.disabled]="
            buttonGenInput.disable !== undefined
                ? buttonGenInput.disable
                : disabled
        "
        [class.no-hover]="buttonGenInput.preventHoverEffect ? true : false"
        [class.shadow-hover]="buttonGenInput.hoverEffect === 'shadow'"
        [disableRipple]="buttonGenInput.disableRipple"
        (click)="
            clickableEffect
                ? [$event.stopPropagation(), handleClickEvent(buttonGenInput)]
                : null
        "
    >
        <ng-container *ngIf="buttonGenInput.buttonIconPrefix">
            <span class="button-icon-prefix">
                <div
                    appIcon
                    [data]="buttonGenInput.buttonIconPrefix"
                    class="overlay-loader"
                ></div>
            </span>
        </ng-container>
        <span class="button-name">
            {{ buttonGenInput.buttonName }}
        </span>
        <ng-container *ngIf="buttonGenInput.buttonIcon">
            <span class="button-icon">
                <div
                    *ngIf="buttonGenInput.buttonIcon"
                    appIcon
                    [data]="buttonGenInput.buttonIcon"
                    class="overlay-loader"
                ></div>
            </span>
        </ng-container>

        <mat-spinner
            class="light-theme"
            [color]="'white'"
            [diameter]="16"
            *ngIf="buttonGenInput.showLoader && buttonGenInput.loader"
        >
        </mat-spinner>
    </button>
    <button
        *ngIf="
            !buttonGenInput.hide &&
            buttonGenInput.buttonType === ButtonType.LINK
        "
        type="button"
        mat-stroked-button
        [ngClass]="buttonClass"
        [disabled]="buttonGenInput.hoverText"
        (click)="$event.stopPropagation(); handleClickEvent(buttonGenInput)"
    >
        <a
            [attr.href]="buttonGenInput.link"
            [attr.target]="
                buttonGenInput.linkTargetValue
                    ? buttonGenInput.linkTargetValue
                    : HtmlTargetAttributeValue.BLANK
            "
            [attr.download]="buttonGenInput.fileName"
        >
            <span class="button-name">
                {{ buttonGenInput.buttonName }}
            </span></a
        >
    </button>
    <ng-container
        *ngIf="
            buttonGenInput.fileType &&
            buttonGenInput.fileType.includes(FileType.EXCEL) &&
            buttonGenInput.buttonType === ButtonType.FILE
        "
    >
        <button
            type="button"
            mat-stroked-button
            [ngClass]="buttonClass"
            [disabled]="buttonGenInput.disable"
            [matTooltip]="buttonGenInput.hoverText"
            (click)="clickableEffect ? excelField.click() : null"
        >
            <span class="button-name">
                {{ buttonGenInput.buttonName }}
            </span>
            <span class="button-icon">
                <div
                    *ngIf="buttonGenInput.buttonIcon"
                    appIcon
                    [data]="buttonGenInput.buttonIcon"
                    class="overlay-loader"
                ></div>
            </span>
        </button>
        <input
            type="file"
            [accept]="buttonGenInput.fileType.join(',')"
            name="excelUpload"
            id="excelUploadField"
            #excelField
            (change)="buttonGenInput.function($event)"
            hidden
        />
    </ng-container>
</div>
