import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';

@Component({
    selector: 'app-acl-associated-listing',
    templateUrl: './acl-associated-listing.component.html',
    styleUrls: ['./acl-associated-listing.component.sass']
})
export class AclAssociatedListingComponent implements OnInit {
    widgetRef: Widget;
    aclData: any;
    showWidgets: boolean = false;
    tableData: any;

    tableGenInput: ITableGeneratorInput;
    aclWidgetApiInfo: IApiInfo;

    readonly CLOUD_KEY = 'cloud';
    readonly SUB_PRODUCT_KEY = 'subProduct';
    readonly SERVICE_KEY = 'service';

    constructor(private modalInjectedData: ModalInjectedData) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.aclData = modalInjectedData.data['aclData'];
        this.showWidgets = modalInjectedData.data['showWidgets'];
    }

    ngOnInit(): void {
        if (this.showWidgets) {
            this.tableGenInput = {
                listExtraction: {
                    type: 'DIRECT'
                },
                columns: [
                    {
                        columnKey: 'name',
                        columnName: 'Widget Name',
                        minWidth: 240,
                        filter: false
                    },
                    {
                        columnKey: this.CLOUD_KEY,
                        columnName: 'Cloud',
                        filter: false,
                        cellRenderer: (row: RowEvent) =>
                            row.data && row.data[this.CLOUD_KEY]
                                ? row.data[this.CLOUD_KEY]
                                : '–'
                    },
                    {
                        columnKey: this.SUB_PRODUCT_KEY,
                        columnName: 'Sub-Product',
                        filter: false,
                        cellRenderer: (row: RowEvent) =>
                            row.data && row.data[this.SUB_PRODUCT_KEY]
                                ? row.data[this.SUB_PRODUCT_KEY]
                                : '–'
                    },
                    {
                        columnKey: this.SERVICE_KEY,
                        columnName: 'Service',
                        filter: false,
                        cellRenderer: (row: RowEvent) =>
                            row.data && row.data[this.SERVICE_KEY]
                                ? row.data[this.SERVICE_KEY]
                                : '–'
                    },
                    {
                        columnKey: 'permission',
                        columnName: 'Permission',
                        filter: false
                    }
                ]
            };
            this.aclWidgetApiInfo = {
                host: '',
                apiRouteSuffix: ApiUrls.ACL_DESCRIBE.replace(
                    '{aclId}',
                    this.aclData.id
                ),
                authorization: AuthorizationType.BEARER_TOKEN,
                requestType: RequestType.GET,
                intactUrl: ApiUrls.ACL_DESCRIBE
            };
        } else {
            this.tableGenInput = {
                noDataMessage: 'No Attachment Found',
                listExtraction: {
                    type: 'DIRECT'
                },
                columns: [
                    {
                        columnKey: 'domainId',
                        columnName: 'Customer Domain Name',
                        filter: false
                    }
                ]
            };
            if (this.aclData['associations']) {
                this.tableData = this.aclData['associations'];
            } else {
                this.tableData = [];
            }
        }
    }
}
