<div class="tw-w-full tw-h-full tw-flex tw-flex-col">
    <div
        class="
            tw-flex-1 tw-flex tw-justify-center tw-items-center tw-py-5 tw-px-0
        "
        style="height: calc(100% - 40px) !important"
    >
        <ng-container
            *ngFor="let imageUploadObject of imageUploadObjects; last as isLast"
        >
            <div
                class="
                    tw-py-0
                    tw-px-6
                    tw-text-center
                    tw-h-full
                    tw-flex
                    tw-flex-col
                    tw-flex-1
                "
            >
                <div
                    class="
                        image-drop-zone
                        tw-w-full
                        tw-flex-1
                        tw-p-5
                        tw-overflow-hidden
                        tw-relative
                        tw-z-0
                        tw-flex
                        tw-flex-col
                        tw-justify-center
                        tw-content-center
                        clickable
                    "
                >
                    <div
                        class="drop-zone tw-absolute tw-inset-0"
                        appDragDropFileSelect
                        [mimeTypes]="['image/png', 'image/jpeg']"
                        (onChange)="onFileSelected($event, imageUploadObject)"
                        (onInvalidSelection)="
                            onFileSelected($event, imageUploadObject, true)
                        "
                    ></div>
                    <ng-container
                        *ngIf="imageUploadObject.image; else noPreviewImage"
                    >
                        <div class="image-preview">
                            <img
                                *ngIf="imageUploadObject.image"
                                [src]="imageUploadObject.image"
                            />
                        </div>
                    </ng-container>
                    <ng-template #noPreviewImage>
                        <div
                            class="icon-container"
                            appIcon
                            [data]="uploadIcon"
                        ></div>
                    </ng-template>
                    <p>Drag Files to Upload</p>
                </div>
                <label class="image-drop-zone-label">{{
                    imageUploadObject.label
                }}</label>
            </div>
            <ng-container *ngIf="!isLast">
                <div class="vertical-divider"></div>
            </ng-container>
        </ng-container>
    </div>
    <div class="tw-w-full tw-flex tw-justify-end tw-pb-3">
        <app-button-generator
            [buttonGenInput]="uploadButton"
            (buttonRef)="upload($event)"
        ></app-button-generator>
    </div>
</div>
