<div class="container">
    <div
        class="top-header-buttons"
        *ngIf="
            widgetMetaData &&
            widgetMetaData['Widget Type'] &&
            widgetMetaData['Widget Type'] === 'CUSTOM'
        "
    >
        <app-multi-button-generator [buttonGenInputs]="topHeaderButtons">
        </app-multi-button-generator>
    </div>

    <div class="widget-info">
        <div class="description" *ngIf="descriptionLoader || widgetDescription">
            <div
                *ngIf="descriptionLoader"
                appIcon
                [iconName]="iconName.SPINNER"
            ></div>
            <ng-container
                *ngIf="
                    widgetDescription && objectKeys(widgetDescription).length
                "
            >
                <div class="heading">Widget Description:</div>

                <div class="description-content" *ngIf="widgetDescription">
                    <app-description-generator
                        [data]="widgetDescription"
                    ></app-description-generator>
                </div>
            </ng-container>
        </div>

        <div
            *ngIf="objectKeys(infoData).length"
            class="widget-info widget-info-container"
        >
            <div class="heading">Widget Info:</div>
            <div class="info-container">
                <ng-container *ngFor="let info of objectKeys(infoData)">
                    <div class="info-section" *ngIf="infoData[info]">
                        <div class="title">{{ info }}</div>
                        <input
                            *ngIf="info !== 'Widget Tags'"
                            class="input-field"
                            [value]="infoData[info]"
                            [name]="info"
                            disabled
                        />
                        <app-tag-generator
                            *ngIf="info === 'Widget Tags'"
                            [extraClass]="'tagMenuResize'"
                            [selectedTags]="
                                widgetMetaData['moreInfo']['tagColorModel']
                            "
                            [availableTags]="
                                tagsData && tagsData.length ? tagsData : []
                            "
                            [showSaveButton]="true"
                            (tagsDataEmitter)="updateTags($event)"
                        >
                        </app-tag-generator>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="loader" #loader></div>
