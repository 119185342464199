import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-create-widget-documentation-step-one-modal',
    templateUrl: './create-widget-documentation-step-one-modal.component.html',
    styleUrls: ['./create-widget-documentation-step-one-modal.component.sass']
})
export class CreateWidgetDocumentationStepOneModalComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    gridRef: GridOptions = null;
    showMultiSelectWidgetMessage: boolean = false;
    buttonInput: IButtonGeneratorInput;

    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info_outlined'
    };

    widgetStatusFilterInfo: IFilterInfo = {
        listData: [
            {
                id: 'Draft',
                label: 'Draft'
            },
            {
                id: 'Active',
                label: 'Active'
            }
        ],
        placeholder: 'Select Service',
        lightMode: true
    };
    selectedWidgetStatus: string[] = [];
    isEdit: boolean;
    widgetId: string;
    isWidgetCatalog: boolean;
    isDataReceived: boolean = false;
    constructor(
        public modalInputData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService
    ) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isWidgetCatalog = modalInputData.data['isWidgetCatalog'];
        this.widgetId = modalInputData.data['widgetId'];
    }

    ngOnInit(): void {
        if (this.isEdit || this.isWidgetCatalog) {
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(1, [this.widgetId]);

            this.multiStepFormService.nextStep(this.modalInputData.modalId);
            return;
        }
        this.buttonInput = {
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.nextStep();
            },
            buttonName: 'Next'
        };

        this.setupTable();
    }

    setupTable() {
        this.tableGenInput = {
            buttons: null,
            listExtraction: {
                type: 'DIRECT'
            },
            isExternalFilterPresent: this.isExternalFilterAvailable.bind(this),
            doesExternalFilterPass: this.externalFilterFunction.bind(this),
            selection: 'multiple',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-list'
            },
            afterResponse: () => {
                this.isDataReceived = true;
            },
            noDataMessage: 'No Widgets Available',
            columns: [
                {
                    columnKey: 'name',
                    columnName: 'Select Widget'
                },
                {
                    columnKey: 'widgetStatus',
                    columnName: 'Status',
                    minWidth: 220,
                    maxWidth: 220,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: rowData.data['widgetStatus'],
                                buttonType: ButtonType.TEXT,
                                buttonColorType:
                                    rowData.data['widgetStatus'] === 'Draft'
                                        ? ButtonColorType.WARN
                                        : ButtonColorType.SUCCESS,
                                showLoader: true,
                                function: (buttonRef: IButtonData) => {}
                            }
                        ];
                        return rowData;
                    }
                }
            ]
        };
    }

    isExternalFilterAvailable() {
        if (this.selectedWidgetStatus.length) {
            return true;
        }
        return false;
    }

    handleBackButton() {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.gridRef.api.forEachNode((node) => {
                if (
                    this.multiStepFormService.stepData
                        .get(this.modalInputData.modalId)
                        .get(1)
                        .includes(node.data['id'])
                ) {
                    node.selectThisNode(true);
                }
            });

            if (
                this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(1).length > 1
            ) {
                this.showMultiSelectWidgetMessage = true;
            }
        }
    }

    externalFilterFunction(rowData: RowEvent) {
        const nodeData = rowData.data;
        let result = true;
        if (this.selectedWidgetStatus && this.selectedWidgetStatus.length) {
            if (this.selectedWidgetStatus.includes(nodeData['widgetStatus'])) {
                result = true;
            } else {
                result = false;
            }
        }
        return result;
    }

    handleWidgetStatusFilterChange(event) {
        this.selectedWidgetStatus = event;

        if (this.selectedWidgetStatus) {
            if (this.gridRef) {
                this.gridRef.api.onFilterChanged();
            }
        }
    }

    handleSelectionChange() {
        this.gridRef.api.getSelectedNodes().length > 1
            ? (this.showMultiSelectWidgetMessage = true)
            : (this.showMultiSelectWidgetMessage = false);
    }

    nextStep() {
        if (this.gridRef.api.getSelectedNodes().length) {
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(
                    1,
                    this.gridRef.api
                        .getSelectedNodes()
                        .map((each) => each.data['id'])
                );

            this.multiStepFormService.nextStep(this.modalInputData.modalId);
        } else {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one widget to proceed.'
            );
        }
    }
}
