<ng-template #overviewTemplateStart>
    <div class="overview">
        <div class="tw-flex tw-relative consumption-heading">
            <app-multi-button-generator
                *ngIf="severityButtons"
                [buttonGenInputs]="severityButtons"
            ></app-multi-button-generator>
        </div>
    </div>
</ng-template>

<ng-template #potentialSavingTemplate>
    <div
        *ngIf="
            apiResponse &&
            apiResponse['dataMap'] &&
            apiResponse['dataMap']['totalPotentialSavings']
        "
        class="general"
        style="margin: auto; min-height: 39px"
    >
        <span class="header-web-view"> Potential Savings: &nbsp;</span>
        <span class="value">{{
            apiResponse["dataMap"]["totalPotentialSavings"]
        }}</span>
    </div>
</ng-template>

<ng-template #searchTemplate>
    <div class="table-search" *ngIf="dataList.length">
        <app-table-search
            [data]="dataList"
            (changed)="filteredCardsData = $event"
        ></app-table-search>
    </div>
</ng-template>

<app-table-generator
    class="table-container"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged()"
    [overviewTemplateStart]="overviewTemplateStart"
    [overviewTemplateBottom]="
        widgetRef.visibleSections.value.has(ViewType.TABLE)
            ? potentialSavingTemplate
            : null
    "
    [overviewTemplateEnd]="
        widgetRef.visibleSections.value.has(ViewType.TABLE)
            ? null
            : searchTemplate
    "
    [showSearchBox]="widgetRef.visibleSections.value.has(ViewType.TABLE)"
></app-table-generator>

<ng-container
    *ngIf="
        !(widgetRef.loadingData | async) &&
        !widgetRef.visibleSections.value.has(ViewType.TABLE) &&
        dataList &&
        dataList.length
    "
>
    <div class="widget-data-container">
        <div class="cards-container">
            <div
                *ngFor="let card of filteredCardsData; let i = index"
                class="card"
                (click)="$event.stopPropagation(); onMoreInfoClicked(card)"
            >
                <div
                    class="head"
                    [class.green-gradient]="
                        card[ACTION_KEY] === GradeOptions.Downgrade
                    "
                    [class.yellow-gradient]="
                        card[ACTION_KEY] === GradeOptions.Upgrade
                    "
                >
                    <div
                        [class.disabled]="card['disabled']"
                        (click)="$event.stopPropagation()"
                        [matTooltip]="
                            card['disabled']
                                ? null
                                : 'Select the instance to downsize or upsize'
                        "
                    >
                        <mat-checkbox
                            [disabled]="card['disabled']"
                            (change)="onCardsSelectionChange($event, card)"
                        >
                        </mat-checkbox>
                    </div>
                    <div class="card-circle">
                        <span
                            *ngIf="card[ACTION_KEY] === GradeOptions.Downgrade"
                            class="status"
                            [matTooltip]="'Downgrade'"
                            appIcon
                            [data]="downgradeIcon"
                        ></span>
                        <span
                            *ngIf="card[ACTION_KEY] === GradeOptions.Upgrade"
                            class="status"
                            [matTooltip]="'Upgrade'"
                            appIcon
                            [data]="upgradeIcon"
                        ></span>
                    </div>
                </div>
                <div class="content">
                    <ng-container *ngFor="let showKey of keysToShow">
                        <ng-container *ngIf="showKey.id !== ACTION_KEY">
                            <div
                                class="display-label-value"
                                [class.red-saving]="
                                    showKey.id.includes('Saving') &&
                                    card[ACTION_KEY] === GradeOptions.Upgrade
                                "
                                [class.green-saving]="
                                    showKey.id.includes('Saving') &&
                                    card[ACTION_KEY] === GradeOptions.Downgrade
                                "
                            >
                                <p>
                                    {{ showKey.label }}:
                                    <span>{{ card[showKey.id] }}</span>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="card-action">
                    <div
                        class="rightsize-button"
                        [class.green-gradient-to-top]="
                            card[ACTION_KEY] === GradeOptions.Downgrade
                        "
                        [class.yellow-gradient-to-top]="
                            card[ACTION_KEY] === GradeOptions.Upgrade
                        "
                        (click)="
                            $event.stopPropagation();
                            openRightsizeModal([card], null, null, i + 1)
                        "
                    >
                        Rightsize
                        <mat-spinner
                            style="margin-left: 8px"
                            class="dark-theme"
                            [diameter]="16"
                            *ngIf="
                                cardButtonLoader.has(i + 1) &&
                                cardButtonLoader.get(i + 1) === true
                            "
                        >
                        </mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="potential-savings-container">
        <ng-container
            [ngTemplateOutlet]="potentialSavingTemplate"
        ></ng-container>
    </div>
</ng-container>
