<!-- TYPE_5  -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_5">
    <div class="custom-date">
        <div
            class="selected-date"
            [matMenuTriggerFor]="customDateDropdown"
            *ngIf="field.appearance === FieldAppearance.TYPE_5"
        >
            <i class="fas fa-calendar-alt"></i
            ><span>{{
                !dummy ? dateValueString : "Select " + filterInfo.label
            }}</span>

            <mat-menu #customDateDropdown="matMenu">
                <div class="custom-date-dropdown" *ngIf="!dummy">
                    <ng-container *ngIf="dateFilterList">
                        <ng-container *ngFor="let item of dateFilterList">
                            <div
                                (click)="dateChanged(item)"
                                class="dropdown-item"
                            >
                                {{ item["text"] }}
                            </div>
                        </ng-container>
                    </ng-container>
                    <div
                        class="dropdown-item"
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="calender"
                    >
                        Custom Date
                        <mat-menu #calender="matMenu">
                            <div
                                (click)="$event.stopPropagation()"
                                class="calendar-container"
                            >
                                <app-date-picker-range
                                    (dateChanged)="
                                        customDateUpdated($event);
                                        trigger.closeMenu()
                                    "
                                    [minDate]="minDate"
                                    [maxDate]="maxDate"
                                    [selectedDates]="selectedDates"
                                ></app-date-picker-range>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
</ng-container>
