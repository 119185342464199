import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'regexExtract'
})
export class RegexExtractPipe implements PipeTransform {
    transform(value: string, regex: any, index?: number) {
        if (value && index) {
            return value.match(regex)[index];
        } else if (value) {
            return value.match(regex);
        }
    }
}
