<div class="forms">
    <app-form-generator
        *ngIf="formGenInput"
        [formGeneratorInput]="formGenInput"
        (formGroupRef)="formGroup = $event"
    ></app-form-generator>
    <ng-container
        *ngIf="
            formGroup &&
            formGroup.get('frequency') &&
            formGroup.get('frequency').value === 'CUSTOM'
        "
    >
        <app-form-generator
            *ngIf="formGenInput"
            [formGeneratorInput]="customFrequencyformGenInput"
            (formGroupRef)="customFrequencyformGroup = $event"
        ></app-form-generator>
    </ng-container>
</div>
<div class="btn">
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        (click)="goNext()"
    >
        Next
    </button>
</div>
