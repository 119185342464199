<ng-container *ngIf="!loader && !error && viewData">
    <app-n-level-traversal-menu-view
        *ngIf="viewData"
        [traversalPurpose]="TraversalPurpose.WIDGET_SELECTOR"
        [firstLevelData]="viewData.attributes"
        (leafNodesData)="handleLeafNode($event)"
    >
    </app-n-level-traversal-menu-view>

    <div class="widgets" *ngIf="leafWidgets">
        <div class="title">
            Select Widgets
            <span class="maximum-limit">
                (Maximum {{ limit }} widgets can be selected at a time)
            </span>
        </div>
        <ng-container *ngFor="let widget of leafWidgets; trackBy: trackByID">
            <div class="item">
                <mat-checkbox
                    [disabled]="checkIfDisabled(widget)"
                    [class.disabled]="checkIfDisabled(widget)"
                    [matTooltip]="
                        widget.widgetType === State.LITE
                            ? 'Widget cannot be selected because it is in lite version'
                            : null
                    "
                    [checked]="selectedWidgets.has(widget.id)"
                    (change)="toggleWidgetSelection($event.checked, widget)"
                >
                    {{ widget.name }}
                </mat-checkbox>
            </div>
        </ng-container>
    </div>

    <div
        class="selected-widgets"
        *ngIf="selectedWidgetIds && selectedWidgetIds.length"
    >
        <div class="title">
            Selected Widgets
            <span class="widget-count">
                ({{ selectedWidgetIds.length }} out of {{ limit }} selected)
            </span>
        </div>
        <div
            class="item remove-item"
            *ngFor="let widgetId of selectedWidgetIds"
            (click)="
                toggleWidgetSelection(false, {
                    id: widgetId,
                    name: selectedWidgets.get(widgetId),
                    supportedFormats: []
                })
            "
        >
            <i class="remove fas fa-trash"></i>
            {{ selectedWidgets.get(widgetId) }}
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loader || error">
    <div
        class="loader-container"
        style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        "
        *ngIf="loader"
    >
        <mat-spinner [color]="'primary'" [diameter]="24"></mat-spinner>
    </div>
    <div
        class="error-container"
        style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        "
        *ngIf="error"
    >
        <i
            class="fas fa-exclamation-triangle color-warn"
            style="margin-right: 12px"
        ></i>
        {{ errorMessage ? errorMessage : "Error generating view." }}
        <ng-container *ngIf="!errorMessage">
            <span
                style="
                    text-decoration: underline;
                    color: var(--primary);
                    font-weight: 600;
                    cursor: pointer;
                "
                (click)="generatView()"
                >Try refreshing</span
            >
            &nbsp; and contact administrator if issue persists.
        </ng-container>
    </div>
</ng-container>
