<app-form-generator
    *ngIf="formGenInput"
    [formGeneratorInput]="formGenInput"
    (formGroupRef)="formGroup = $event"
></app-form-generator>
<div class="btn">
    <button
        style="margin-right: auto"
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        (click)="back()"
    >
        Back
    </button>
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        (click)="saveAsDraft()"
        *ngIf="!edit"
        [class.disabled]="draftLoader || publishLoader"
    >
        Save as draft
        <mat-spinner
            *ngIf="draftLoader"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        *ngIf="!edit"
        (click)="publish()"
        [class.disabled]="draftLoader || publishLoader"
    >
        Publish
        <mat-spinner
            *ngIf="publishLoader"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
    <button
        type="button"
        mat-stroked-button
        class="clickable btn-primary-stroked filled"
        color="primary"
        *ngIf="edit"
        (click)="update()"
    >
        Update
        <mat-spinner
            *ngIf="updateLoader"
            class="light-theme"
            [diameter]="16"
        ></mat-spinner>
    </button>
</div>
