import { Component, Injector, Input, OnInit } from '@angular/core';
import { CarouselInjectedData } from '../../classes/CarouselInjectedData';
import { IconType } from '../../enums/IconType';
import { ISlideInfo } from '../../interfaces/carousel-generator/ISlideInfo';
import { IIcon } from '../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-carousel-generator',
    templateUrl: './carousel-generator.component.html',
    styleUrls: ['./carousel-generator.component.sass']
})
export class CarouselGeneratorComponent implements OnInit {
    @Input() carouselSlideInput: ISlideInfo[] = [];

    private injector: Injector;

    currentPageofCarousel = 0;
    totalCarouselIndicator = [];

    @Input() limitToShowDataPerPage = 0;

    injectors = [];
    leftPointer: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-left'
    };
    rightPointer: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-chevron-right'
    };

    constructor(private inj: Injector) {
        this.injector = this.inj;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }
    setUpBasics() {
        this.injectors = this.carouselSlideInput.map((slideData) => {
            const values: CarouselInjectedData = {
                data: slideData.componentInput
            };
            return Injector.create({
                providers: [
                    {
                        provide: CarouselInjectedData,
                        deps: [],
                        useValue: values
                    }
                ],
                parent: this.injector
            });
        });

        for (
            let i = 0;
            i <
            Math.ceil(
                this.carouselSlideInput.length / this.limitToShowDataPerPage
            );
            i++
        ) {
            this.totalCarouselIndicator.push(i);
        }
    }

    widgetIdentify(index, widgetId) {
        return widgetId;
    }
}
