import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-otrs-parent-integration-step-one',
    templateUrl: './otrs-parent-integration-step-one.component.html',
    styleUrls: ['./otrs-parent-integration-step-one.component.sass']
})
export class OtrsParentIntegrationStepOneComponent implements OnInit {
    credentialsFormGenInput: IFormGeneratorInput = null;
    isUserIntegrated = false;
    isParentIntegrated = false;
    isOwnerIntegrated = false;
    response;
    widgetRef: Widget;
    stepData: Object = null;
    nextButtonGenInput: IButtonGeneratorInput;
    credentialFormGroupRef: FormGroup;
    resetSubscription: Subscription;
    constructor(
        private modalInputData: ModalInjectedData,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService
    ) {
        this.response = modalInputData.data['response'];
        this.isOwnerIntegrated =
            modalInputData.data['response']['ownerIntegration'];
        this.isUserIntegrated =
            modalInputData.data['response']['userIntegration'];
        this.isParentIntegrated =
            modalInputData.data['response']['parentIntegration'];
        this.widgetRef = modalInputData.data['widgetRef'];
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1).inputData;
        }
    }

    ngOnInit(): void {
        this.resetSubscription = this.modalService.resetModal.subscribe(() => {
            this.credentialFormGroupRef.reset();
        });
        this.credentialsFormGenInput = {
            formName: 'OTRS',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    name: 'otrsUrl',
                    placeholder: 'Enter OTRS Domain Name',
                    label: 'OTRS Domain Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'OTRS domain name is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['otrsUrl']
                        : this.isParentIntegrated
                        ? this.response['parentCred']['otrsUrl']
                        : ''
                },
                {
                    name: 'webserviceName',
                    placeholder: 'Enter Web Service Name',
                    label: 'Web Service Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Web service name is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['webserviceName']
                        : this.isParentIntegrated
                        ? this.response['parentCred']['webserviceName']
                        : ''
                },
                {
                    name: 'userName',
                    placeholder: 'Enter User Name',
                    label: 'User Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Username is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['userName']
                        : this.isParentIntegrated
                        ? this.response['parentCred']['userName']
                        : ''
                },
                {
                    name: 'password',
                    placeholder: 'Enter Password',
                    label: 'Password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['password']
                        : this.isParentIntegrated
                        ? this.response['parentCred']['password']
                        : ''
                },
                {
                    name: 'deskName',
                    placeholder: 'Enter Deskname',
                    label: 'Desk Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Desk Name is required'
                        }
                    ],
                    value: this.stepData
                        ? this.stepData['deskName']
                        : this.isParentIntegrated
                        ? this.response['parentCred']['deskName']
                        : ''
                }
            ]
        };
        this.nextButtonGenInput = {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            showLoader: true,
            function: () => {
                Helper.markAllFieldAsTouched(this.credentialFormGroupRef);
                if (this.credentialFormGroupRef.invalid) {
                    this.credentialFormGroupRef.updateValueAndValidity();
                    return;
                }
                if (this.credentialFormGroupRef.valid) {
                    const input = {
                        ...this.credentialFormGroupRef.value,
                        integrationType: IntegrationName.OTRS,
                        userIntegration: 'false',
                        parentIntegration: 'true'
                    };
                    const formData = {
                        inputData: input
                    };
                    this.multiStepFormService.stepData
                        .get(this.modalInputData.modalId)
                        .set(1, formData);
                    this.multiStepFormService.nextStep(
                        this.modalInputData.modalId
                    );
                }
            }
        };
    }
    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
