<div class="container">
    <div class="form-container">
        <div class="tw-w-3/4">
            <app-form-generator
                [formGeneratorInput]="stepOneFormGenInputs"
                (formGroupRef)="stepOneFormGroup = $event"
            ></app-form-generator>
        </div>
    </div>
    <div class="button tw-float-right">
        <app-button-generator [buttonGenInput]="buttonInput">
        </app-button-generator>
    </div>
</div>
