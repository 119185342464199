import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from './../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';
export class GcpValueSpecialCharacterValidator extends CustomValidator {
    key = CustomValidatorKey.GCP_SPECIAL_CHARACTER;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const GCP_VALUE_SPECIAL_CHARACTER_REGEX = new RegExp(
            /^[^@*^#$.,:'"!;~`+=(){}?/A-Z][a-z0-9_-]*$/
        );

        if (!control.value.length) {
            return null;
        } else {
            let isValid = true;
            control.value.forEach((element) => {
                if (!GCP_VALUE_SPECIAL_CHARACTER_REGEX.test(element)) {
                    isValid = false;
                }
            });

            if (isValid) {
                return null;
            }
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
