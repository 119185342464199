import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';
import { IGeneralConfigResponse } from 'src/app/shared/interfaces/api/portlets/IGeneralConfigResponse';

@Injectable({
    providedIn: 'root'
})
export class ConfigCacheService extends CacheMain {
    static readonly VIEW_ID_SELECTED = 'VIEW_ID_SELECTED';
    static readonly PAGE_NUMBER = 'PAGE_NUMBER';
    static readonly FRESH_LOGIN = 'FRESH_LOGIN';
    static readonly GENERAL_CONFIG = 'GENERAL_CONFIG';
    static readonly ASSESSEE_TEMPLATE = 'ASSESSEE_TEMPLATE';
    static readonly ASSESSOR_TEMPLATE = 'ASSESSOR_TEMPLATE';

    constructor() {
        const CONFIG_CACHE_PREFIX = 'CONFIG';
        super(CONFIG_CACHE_PREFIX, true);
    }

    get viewId(): string {
        return this.fetch(ConfigCacheService.VIEW_ID_SELECTED);
    }

    set viewId(viewId: string) {
        this.store(ConfigCacheService.VIEW_ID_SELECTED, viewId);
    }

    get pageNumber(): number {
        return +this.fetch(ConfigCacheService.PAGE_NUMBER);
    }

    set pageNumber(pageNumber: number) {
        this.store(ConfigCacheService.PAGE_NUMBER, pageNumber);
    }

    get freshLogin(): boolean {
        return this.fetch(ConfigCacheService.FRESH_LOGIN);
    }

    set freshLogin(freshLogin: boolean) {
        this.store(ConfigCacheService.FRESH_LOGIN, freshLogin);
    }
    set assessmentTemplate(template: string) {
        this.store(ConfigCacheService.ASSESSEE_TEMPLATE, template);
    }

    get assessmentTemplate(): string {
        return this.fetch(ConfigCacheService.ASSESSEE_TEMPLATE);
    }
    set assessorEmailTemplate(template: string) {
        this.store(ConfigCacheService.ASSESSOR_TEMPLATE, template);
    }

    get assessorEmailTemplate(): string {
        return this.fetch(ConfigCacheService.ASSESSOR_TEMPLATE);
    }
    getGeneralConfig(emailId: string, viewId: string): IGeneralConfigResponse {
        const defaultConfig = this.fetch(ConfigCacheService.GENERAL_CONFIG);
        if (!defaultConfig || !defaultConfig[`${emailId}${viewId}`]) {
            return null;
        }
        return defaultConfig[`${emailId}${viewId}`];
    }
    setGeneralConfig(emailId: string, viewId: string, config: any) {
        let generalConfig = this.fetch(ConfigCacheService.GENERAL_CONFIG);
        if (!generalConfig) {
            generalConfig = {};
        }
        generalConfig[`${emailId}${viewId}`] = config;
        this.store(ConfigCacheService.GENERAL_CONFIG, generalConfig);
    }
}
