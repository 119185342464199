import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Helper } from 'src/app/shared/classes/Helper';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SideMenuType } from 'src/app/shared/enums/SideMenuType';
import { TraversalPurpose } from 'src/app/shared/enums/TraversalPurpose';
import { NLevelRoutePipe } from 'src/app/shared/pipes/n-level-route-pipe/n-level-route-pipe.pipe';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { ApiUrls } from '../../classes/ApiUrls';
import { SideMenuCacheService } from './../../../shared/services/cache/side-menu-cache/side-menu-cache.service';
import { UserDataCacheService } from './../../../shared/services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.sass']
})
export class SideNavigationComponent implements OnInit {
    selectedPath: string[] = null;
    TraversalPurpose = TraversalPurpose;
    SideMenuType = SideMenuType;
    noRedirection = true;
    @Input() sidenav: MatSidenav;

    constructor(
        public globalDataService: GlobalDataService,
        private sideMenuCacheService: SideMenuCacheService,
        private router: Router,
        private redirectionHandleService: RedirectionHandleService,
        private userDataCacheService: UserDataCacheService
    ) {}

    ngOnInit() {
        if (
            this.redirectionHandleService.urlToRedirect &&
            this.redirectionHandleService.widgetIdToRedirect
        ) {
            this.noRedirection = false;
        }
        if (
            (this.globalDataService.freshLogin ||
                !window.location.pathname.replace('/', '').length) &&
            this.noRedirection
        ) {
            this.handleLandingPage();
        }
        if (this.globalDataService.returnToConsole) {
            const routeData = this.sideMenuCacheService.getPathSelectedData(
                this.globalDataService.selectedView,
                location.pathname
            );
            if (routeData) {
                this.handleRouteData(routeData);
            } else {
                this.handleLandingPage();
            }
        } else {
            const routeData = this.sideMenuCacheService.getPathSelectedData(
                this.globalDataService.selectedView,
                location.pathname
            );
            if (routeData && this.noRedirection) {
                this.handleRouteData(routeData, true);
            }
        }
        this.globalDataService.routingData.subscribe((routingData) => {
            if (routingData) {
                this.handleRouteData(routingData);
                this.handleDynamicRouting(routingData);
            }
        });

        this.globalDataService.handleLeafNode.subscribe((resp) => {
            if (resp) {
                if (this.globalDataService.currentLeafNodeData) {
                    this.handleLeafNode(
                        this.globalDataService.currentLeafNodeData
                    );
                } else {
                    this.handleLandingPage();
                }
            }
        });
        this.globalDataService.handleSelectedPath.subscribe((resp) => {
            if (resp) {
                this.selectedPath = resp;
            }
        });
        this.globalDataService.invalidUrl.subscribe((val) => {
            this.handleLandingPage();
        });
    }

    loadWidgets(apiUrl, pageName, inputUrl?) {
        if (
            this.userDataCacheService.isAssesseeView &&
            apiUrl.includes('assessment')
        ) {
            setTimeout(() => {
                this.globalDataService.loadWidgetsForAssessee.next({
                    url: apiUrl,
                    input: {},
                    requestType: RequestType.GET,
                    uniqueIdentity: Symbol(),
                    function: null,
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,
                        extraData: {
                            pageName
                        }
                    }
                });
            }, 0);
        } else {
            setTimeout(() => {
                this.globalDataService.loadWidgetsFromApiArgs.next({
                    url: apiUrl,
                    input: inputUrl ? inputUrl : {},
                    requestType: inputUrl ? RequestType.POST : RequestType.GET,
                    uniqueIdentity: Symbol(),
                    function: null,
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN,
                        extraData: {
                            pageName
                        }
                    }
                });
            }, 0);
        }
    }

    handleLandingPage() {
        const routeData =
            this.globalDataService.sideMenuStepOne.landingPageAttributes;
        // Caching the landing page attributes
        if (routeData && !this.userDataCacheService.isAssesseeView) {
            let selectedPath = Helper.urlFormatter(
                routeData.map((ele) => ele.name).join('/')
            );
            if (selectedPath.charAt(0) !== '/') {
                selectedPath = `/${selectedPath}`;
            }
            // Removing Brackets from the URL Path
            const cleanUrl = Helper.cleanURL(selectedPath);
            this.sideMenuCacheService.setPathSelectedData(
                this.globalDataService.selectedView,
                cleanUrl,
                routeData
            );
        }
        if (
            this.globalDataService.enteredUrlBeforeLogin &&
            this.globalDataService.enteredUrlBeforeLogin.length &&
            !this.userDataCacheService.isAssesseeView
        ) {
            this.globalDataService.joinEnteredUrlBeforeLogin =
                this.globalDataService.enteredUrlBeforeLogin.join('/');
            const input = {
                viewId: this.globalDataService.selectedView,
                url: `/${this.globalDataService.joinEnteredUrlBeforeLogin}`
            };
            this.loadWidgets(ApiUrls.LOGIN_REDIRECT_URL, null, input);
            this.router.navigateByUrl(
                this.globalDataService.joinEnteredUrlBeforeLogin
            );
            this.globalDataService.joinEnteredUrlBeforeLogin = null;
            this.globalDataService.enteredUrlBeforeLogin = null;
        } else {
            this.handleRouteData(routeData);
        }
        if (!this.userDataCacheService.isAssesseeView) {
            this.globalDataService.handleSelectedPath.next(null);
        }
    }

    handleRouteData(routeData, noReq?: boolean) {
        this.selectedPath = routeData.map((ele) => ele.id);
        const selectedPath = Helper.urlFormatter(
            routeData.map((ele) => ele.name).join('/')
        );
        const lastLevel = routeData[routeData.length - 1];

        if (
            lastLevel['lastLevel'] &&
            lastLevel['name'] &&
            lastLevel['name'] === 'Assessment Overview'
        ) {
            let selectedPathUpdated = '';
            if (selectedPath.charAt(0) !== '/') {
                selectedPathUpdated = `/${selectedPath}`;
            }
            // Removing Brackets from the URL Path
            const cleanUrl = Helper.cleanURL(selectedPathUpdated);
            this.sideMenuCacheService.setPathSelectedData(
                this.globalDataService.selectedView,
                cleanUrl,
                routeData
            );
        }

        if (lastLevel.nextUrl && !noReq) {
            this.reqForWidgets(lastLevel.nextUrl, lastLevel.name);
        } else if (
            this.userDataCacheService.isAssessorView ||
            this.userDataCacheService.isAssesseeView
        ) {
            if (this.globalDataService.fullPageLoader.getValue()) {
                this.globalDataService.fullPageLoader.next(false);
            }
        }
        this.router.navigateByUrl(Helper.cleanURL(selectedPath));
        this.globalDataService.breadcrumbData.next(
            routeData.map((ele) => ele.name)
        );
    }

    handleLeafNode(leafNodes: Map<number, any>) {
        this.globalDataService.currentLeafNodeData = leafNodes;
        let url = Helper.urlFormatter(
            new NLevelRoutePipe().transform(leafNodes)
        );
        if (location.pathname === url) {
            return;
        }
        const leafNode = leafNodes.get(url.split('/').length - 1);

        url = Helper.cleanURL(url);
        this.router.navigateByUrl(url);

        const routeData = [];
        // Generating route data
        let index = 1;
        while (leafNodes.has(index)) {
            routeData.push(leafNodes.get(index++));
        }

        this.sideMenuCacheService.setPathSelectedData(
            this.globalDataService.selectedView,
            url,
            routeData
        );
        this.sideMenuCacheService.setNavigationdpoints(
            this.globalDataService.selectedView,
            routeData
        );
        this.reqForWidgets(leafNode['nextUrl'], leafNode['name']);
        this.globalDataService.breadcrumbData.next(
            routeData.map((ele) => ele.name)
        );
    }

    reqForWidgets(url: string, pageName: string) {
        let apiUrl = url;
        if (apiUrl.charAt(0) !== '/') {
            apiUrl = `/${apiUrl}`;
        }
        setTimeout(() => {
            this.loadWidgets(apiUrl, pageName);
        }, 0);
    }
    handleDynamicRouting(routingData) {
        this.selectedPath = routingData.map((ele) => ele.id);
        this.globalDataService.handleSelectedPath.next(this.selectedPath);
        let selectedPath = Helper.urlFormatter(
            routingData.map((ele) => ele.name).join('/')
        );
        if (selectedPath.charAt(0) !== '/') {
            selectedPath = `/${selectedPath}`;
        }
        // Removing Brackets from the URL Path
        const cleanUrl = Helper.cleanURL(selectedPath);
        this.sideMenuCacheService.setPathSelectedData(
            this.globalDataService.selectedView,
            cleanUrl,
            routingData
        );
        this.sideMenuCacheService.setNavigationdpoints(
            this.globalDataService.selectedView,
            routingData
        );
        this.globalDataService.breadcrumbData.next(
            routingData.map((ele) => ele.name)
        );
    }
}
