import { Component, OnInit } from '@angular/core';
import { RowSpanParams } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { GraphType } from 'src/app/shared/enums/GraphType';
import { IGraphData } from 'src/app/shared/interfaces/graph/IGraphData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ISemiPieData } from '../../../interfaces/graph/IGraphData';
import { Helper } from './../../../classes/Helper';
import { ModalInjectedData } from './../../../classes/ModalInjectedData';
import { IconType } from './../../../enums/IconType';
import { IIcon } from './../../../interfaces/icon-data/IIcon';
import { IColumnData } from './../../../interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from './../../../interfaces/table-generator/ITableGeneratorInput';

enum DataList {
    Resource_ID = 'Resource ID',
    Subscription_ID = 'Subscription ID',
    Account_ID = 'Account ID',
    Consumption_Breakup = 'Consumption Breakup',
    Graph_Data = 'Graph Data',
    On_Demand_Cost = 'On-Demand Cost ($)'
}
@Component({
    selector: 'app-ri-utilization-modal-v2',
    templateUrl: './ri-utilization-modal-v2.component.html',
    styleUrls: ['./ri-utilization-modal-v2.component.sass']
})
export class RiUtilizationModalV2Component implements OnInit {
    chartData: any;
    graphData: IGraphData;
    responseData: any;
    widgetRef: Widget;
    dataList = DataList;
    generalInfo: any;
    generalInfoTableInput: ITableGeneratorInput;
    generalInfoTableData: any[] = [];
    consumptionInfo: any;
    consumptionInfoTableInput: ITableGeneratorInput;
    consumptionInfoTableData: any[] = [];
    selectedBreakupBy: string;
    breakUpByKey: string = null;
    accountIdInfo: any;
    accountIdTableInput: ITableGeneratorInput;
    accountIdTableData: any[] = [];
    resourceIdInfo: any;
    resourceIdTableInput: ITableGeneratorInput;
    resourceIdTableData: any[] = [];

    tabs: string[] = [
        'Consumption Information',
        'Consumption Breakup',
        'General Information'
    ];

    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };

    arrowUpIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-up'
    };

    arrowDownIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-down'
    };

    consumptionInfoColDef = {
        sortable: false,
        filter: false,
        resizable: true,
        minWidth: 150,
        width: 200,
        suppressMovable: true,
        headerClass: 'grid-cell-data-centred'
    };

    consumptionBreakupColDef = {
        sortable: false,
        filter: false,
        resizable: true,
        minWidth: 250,
        width: 250,
        suppressMovable: true,
        headerClass: 'grid-cell-data-centred'
    };

    constructor(
        public modalData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.widgetRef = this.modalData.data.widgetRef;
        this.responseData = this.modalData.data.itemData;
        this.generalInfo = this.responseData['General Information'];
        this.consumptionInfo = this.responseData['Consumption Information'];
        this.breakUpByKey = this.responseData[DataList.Consumption_Breakup][
            DataList.Account_ID
        ]
            ? DataList.Account_ID
            : DataList.Subscription_ID;
        this.selectedBreakupBy = this.breakUpByKey;
        this.accountIdInfo =
            this.responseData[DataList.Consumption_Breakup][this.breakUpByKey];
        this.resourceIdInfo =
            this.responseData[DataList.Consumption_Breakup][
                DataList.Resource_ID
            ];
        this.generateConsumtionInfoTable();
        this.generateAccountIdTable();
        this.generateResourceIdTable();
        this.generalInfoTable();
        this.prepareGraphInput(
            this.responseData[DataList.Consumption_Breakup][DataList.Graph_Data]
        );
    }

    generateConsumtionInfoTable() {
        const columns: IColumnData[] = [];
        const columnKeys: string[] = [];
        const message =
            'The cost that would be\nincurred on-demand if\nRI was not purchased';

        Object.keys(this.consumptionInfo).map((key) => {
            const data = Helper.cloneDeep(this.consumptionInfo[key]);
            data['Fields'] = key;
            this.consumptionInfoTableData.push(data);
        });

        Object.keys(this.consumptionInfo).forEach((key) => {
            Object.entries(this.consumptionInfo[key]).forEach((value) => {
                const data: IColumnData = {
                    columnKey: value[0],
                    columnName: value[0],
                    minWidth: 180
                };

                if (value[0] === DataList.On_Demand_Cost) {
                    data.headerComponentParams = {
                        template:
                            `<div class="ag-cell-label-container" role="presentation">` +
                            `   <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">` +
                            `       <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>` +
                            `       <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>` +
                            `       <i title="${message}" style="color:var(--accentColor);padding-left:8px;cursor:pointer" class="fas fa-info-circle"></i>` +
                            `   </div>` +
                            `</div>`
                    };
                }

                if (!columnKeys.includes(value[0])) {
                    columns.push(data);
                    columnKeys.push(value[0]);
                }
            });
        });

        columns.unshift({
            columnKey: 'Fields',
            columnName: 'Fields',
            pinned: 'left',
            minWidth: 200
        });

        this.consumptionInfoTableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            rowHeight: 60,
            tableAutoHeight: true,
            headerHeight: 60,
            columns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };
    }

    generateAccountIdTable() {
        const columns: IColumnData[] = [];
        const columnKeys: string[] = [];

        // Setting table data
        Object.keys(this.accountIdInfo).map((key) => {
            this.accountIdTableData.push(this.accountIdInfo[key]);
        });

        Object.keys(this.accountIdInfo).forEach((key) => {
            Object.entries(this.accountIdInfo[key]).forEach((value) => {
                const data: IColumnData = {
                    columnKey: value[0],
                    columnName: value[0]
                };

                if (!columnKeys.includes(value[0])) {
                    columns.push(data);
                    columnKeys.push(value[0]);
                }
            });
        });

        this.accountIdTableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            rowHeight: 90,
            headerHeight: 60,
            tableAutoHeight: true,
            columns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };
    }

    generateResourceIdTable() {
        const columns: IColumnData[] = [];
        const columnKeys: string[] = [];

        // Setting table data
        this.resourceIdInfo.forEach((resourceIdData) => {
            let previousAccountId = null;
            Object.keys(resourceIdData).forEach((resourceIdKey) => {
                if (Array.isArray(resourceIdData[resourceIdKey])) {
                    resourceIdData[resourceIdKey].forEach(
                        (resourceData, index) => {
                            let accountId = null;
                            if (index === 0) {
                                accountId = previousAccountId;
                            }
                            this.resourceIdTableData.push({
                                [this.breakUpByKey]: accountId,
                                ...resourceData
                            });
                        }
                    );
                } else {
                    previousAccountId = resourceIdData[resourceIdKey];
                }
            });
        });

        this.resourceIdInfo.forEach((resourceIdData) => {
            Object.keys(resourceIdData).forEach((resourceIdKey) => {
                if (Array.isArray(resourceIdData[resourceIdKey])) {
                    resourceIdData[resourceIdKey].forEach((resourceData) => {
                        Object.keys(resourceData).forEach((key) => {
                            const data: IColumnData = {
                                columnKey: key,
                                columnName: key
                            };
                            if (!columnKeys.includes(key)) {
                                columns.push(data);
                                columnKeys.push(key);
                            }
                        });
                    });
                } else {
                    const data: IColumnData = {
                        columnKey: resourceIdKey,
                        columnName: resourceIdKey,
                        cellClass: 'account-id-cell',
                        rowSpan: (params: RowSpanParams) => {
                            const index = params.node.rowIndex;
                            let spanCount = 1;
                            if (params.data[this.breakUpByKey] === null) {
                                return 0;
                            }
                            for (
                                let i = index + 1;
                                i < this.resourceIdTableData.length;
                                i++
                            ) {
                                if (
                                    this.resourceIdTableData[i][
                                        this.breakUpByKey
                                    ] === null
                                ) {
                                    spanCount++;
                                } else {
                                    break;
                                }
                            }
                            return spanCount;
                        }
                    };
                    if (!columnKeys.includes(resourceIdKey)) {
                        columns.push(data);
                        columnKeys.push(resourceIdKey);
                    }
                }
            });
        });
        this.resourceIdTableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 60,
            rowHeight: 90,
            tableAutoHeight: true,
            suppressRowTransform: true,
            columns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };
    }

    prepareGraphInput(chartData: { percentage: string; text: string }[]) {
        const graphChartData: ISemiPieData[] = chartData.map((data, index) => {
            const tempData: ISemiPieData = {
                category: data.text,
                value: data.percentage
            };
            return tempData;
        });
        this.graphData = {
            graphType: GraphType.SEMI_PIE,
            chartData: graphChartData,
            liteState: this.widgetRef.lightState
        };
    }

    generalInfoTable() {
        const columns: IColumnData[] = [
            {
                columnName: 'data',
                columnKey: 'data',
                pinned: 'left'
            },
            {
                columnName: 'val',
                columnKey: 'val',
                cellClass: (params) => {
                    if (params.data.data === 'Reservation IDs') {
                        if (this.generalInfo['Reservation IDs'].length > 1) {
                            return 'general-info-table';
                        }
                    } else if (params.data.data === 'Subscription ID') {
                        return 'general-info-table';
                    }
                }
            }
        ];

        Object.keys(this.generalInfo).forEach((key) => {
            let arrayValue;
            if (this.checkInstanceOfArray(this.generalInfo[key])) {
                this.generalInfo[key].forEach((item, index) => {
                    if (index === 0) {
                        arrayValue = item;
                    } else {
                        arrayValue += `, ${item}`;
                    }
                });
            }

            this.generalInfoTableData.push({
                data: key,
                val: arrayValue ?? this.generalInfo[key]
            });
        });

        this.generalInfoTableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            headerHeight: 0,
            tableAutoHeight: true,
            columns,
            agGridCellOptions: {
                cellFontSize: 'var(--fs400)'
            }
        };
    }

    checkInstanceOfArray(item) {
        return Array.isArray(item);
    }
}
