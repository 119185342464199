<app-cards-generator
    [cardInput]="cardInput"
    [widgetRef]="widgetRef"
    [cardInfoTemplate]="cardInfoTemplate"
    cardInfoTemplateClasses="tw-flex tw-justify-center tw-items-center tw-relative"
></app-cards-generator>

<ng-template #cardInfoTemplate let-card="card">
    <ngx-gauge
        class="tw-absolute tw-top-1"
        [type]="'arch'"
        [value]="gaugeValue(card)"
        [size]="120"
        [append]="'%'"
        [thresholds]="thresholdConfig"
        [thick]="10"
        [prepend]="showPrepend(card)"
    >
    </ngx-gauge>
</ng-template>
