import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class MaxValueValidator extends CustomValidator {
    key = CustomValidatorKey.MAX_VALUE;

    constructor(private maxValue: number) {
        super();
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.max(this.maxValue)(control);
    }
}
