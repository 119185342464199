import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { HitApi } from '../../classes/HitApi';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { IconType } from '../../enums/IconType';
import { ModalType } from '../../enums/ModalType';
import { RequestType } from '../../enums/RequestType';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { IConfirmationModal } from '../../interfaces/modal/IConfirmationModal';
import { IModalData } from '../../interfaces/modal/IModalData';
import { ConfirmationModalComponent } from '../modal-templates/confirmation-modal/confirmation-modal.component';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-request-mediums',
    templateUrl: './request-mediums.component.html',
    styleUrls: ['./request-mediums.component.sass']
})
export class RequestMediumsComponent implements OnInit {
    activeMediumIndex: number = 0;
    @Input() selectedMedium: RequestMediums = null;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    IGNORED_STATUS = [RequestMediums.EMAIL];
    buttonsInput: IButtonGeneratorInput[] = [];
    storeDisableValues = new Map<number, boolean>();
    requestMediums = [
        {
            name: RequestMediums.EMAIL,
            disabled: false,
            integrationName: IntegrationName.EMAIL,
            loader: false,
            integrationId: null
        },
        {
            name: RequestMediums.SERVICE_NOW,
            disabled: true,
            integrationName: IntegrationName.SERVICENOW,
            loader: false,
            integrationId: null
        }
    ];

    @ViewChild('mediumSwitchConfirmation')
    mediumSwitchRef: TemplateRef<any>;

    constructor(
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.generateButtons(true);
        this.checkIntegrationStatus();
        this.checkSelectedMedium();
    }
    generateButtons(firstTime: boolean) {
        this.requestMediums.forEach((data, index) => {
            if (firstTime) {
                this.storeDisableValues.set(index, data.disabled);
            }
            const buttonInput: IButtonGeneratorInput = {
                buttonName: data.name,
                buttonColorType: ButtonColorType.INFO,
                showLoader: true,
                function: null,
                buttonType: ButtonType.STROKED,
                preventHoverEffect: true,
                customClass:
                    this.activeMediumIndex === index
                        ? 'half-color-button active'
                        : 'half-color-button',
                disable: this.storeDisableValues.get(index)
            };
            this.buttonsInput.push(buttonInput);
        });
    }

    checkSelectedMedium() {
        for (let i = 0; i < this.requestMediums.length; i++) {
            if (this.requestMediums[i].name === this.selectedMedium) {
                this.selectMedium(i, true);
                break;
            }
        }
    }

    selectMedium(index, initialSetup = false) {
        if (initialSetup) {
            this.activeMediumIndex = index;
            this.selected.emit(this.requestMediums[index]);
            return;
        }

        if (this.activeMediumIndex === index) {
            return;
        }

        const confirmationModalData: IConfirmationModal = {
            function: (modalId) => {
                this.activeMediumIndex = index;
                this.selected.emit(this.requestMediums[index]);
                this.buttonsInput = [];
                this.generateButtons(false);
                this.modalService.closeModal(null, modalId);
            },
            modalName: 'Reset Modal',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: null,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            fontSize: 1.125,
            bodyTemplate: this.mediumSwitchRef,
            cancelButtonAttribute: 'stroked',
            cancelButtonType: 'ACCENT',
            buttonPosition: 'center'
        };

        const modalData: IModalData = {
            modalName: 'Delete Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            modalType: ModalType.MINI_MODAL,
            sourceId: Symbol(),
            modalWidthVw: 30,
            noHeader: true,
            modalAutoHeight: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Delete'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    checkIntegrationStatus() {
        this.requestMediums.forEach((integration, index) => {
            if (!this.IGNORED_STATUS.includes(integration.name)) {
                const url = `${
                    ApiUrls.INTEGRATION_STATUS + integration.integrationName
                }`;

                this.buttonsInput[index].loader = true;

                const apiArgs: IHitApi = {
                    url: url,
                    requestType: RequestType.GET,
                    input: {},
                    function: (response) => {
                        if (response && response.integration) {
                            this.buttonsInput[index].disable =
                                !response.integration;

                            integration.integrationId = response.id;
                        }
                    },
                    errorFunction: (error) => {
                        this.buttonsInput[index].disable = false;
                        this.storeDisableValues.set(index, false);
                    },
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.BEARER_TOKEN
                    },
                    endFunction: () => {
                        this.buttonsInput[index].loader = false;
                        this.storeDisableValues.set(index, false);
                    }
                };
                new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
            }
        });
    }
}

export enum RequestMediums {
    SERVICE_NOW = 'Service Now',
    EMAIL = 'Email'
}
