<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
></app-table-generator>

<mat-menu #downloadMenu="matMenu">
    <ul class="list">
        <li *ngFor="let button of tableDropdownButtons" class="list-item">
            <app-button-generator
                [buttonGenInput]="button"
            ></app-button-generator>
        </li>
    </ul>
</mat-menu>
