export enum AuditEmailConfigActionType {
    ADD = 'ADD',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum AuditEmailConfigurationRuleType {
    DEFAULT = 'Default',
    CUSTOM = 'Custom',
}

export interface AuditEmailConfigRuleItemType {
    ruleName: string;
    triggerPoints: string[];
    recipients: string[];
    carbonCopy: string[];
    blindCarbonCopy: string[];
    emailTemplateId: string;
    ruleId?: string;
    auditId?: string;
    type?: AuditEmailConfigurationRuleType;
    action?: AuditEmailConfigActionType; // only for FE use
}
