import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { NgxMatTimepickerFieldComponent } from 'ngx-mat-timepicker';
import { BehaviorSubject } from 'rxjs';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Helper } from '../../classes/Helper';
import { HitApi } from '../../classes/HitApi';
import { WysiwygEditorField } from '../../enums/AppearanceType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ButtonType } from '../../enums/ButtonType';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { FilterType } from '../../enums/FilterType';
import { IconType } from '../../enums/IconType';
import { ModalType } from '../../enums/ModalType';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from '../../interfaces/dropdown-data/IDropdownData';
import { IFilterData } from '../../interfaces/filter/IFilterData';
import { IFormField } from '../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from '../../interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IModalData } from '../../interfaces/modal/IModalData';
import { FieldDataFromControlNamePipe } from '../../pipes/field-data-from-control-name/field-data-from-control-name.pipe';
import { GetFilterSelectedValuePipe } from '../../pipes/get-filter-selected-value.pipe';
import { ConfigCacheService } from '../../services/cache/config-cache/config-cache.service';
import { HttpService } from '../../services/http/http-main/http.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { ImageUploadModalComponent } from '../modal-templates/image-upload-modal/image-upload-modal.component';
import { FormState } from './../../enums/FormState';
import { UpdateAction } from './../../enums/UpdateAction';
import { IUpdateAction } from './../../interfaces/update-action/IUpdateAction';
import { NotificationsService } from './../../services/notifications/notifications.service';
import { DateTimeFieldComponent } from '../form-fields/date-time-field/date-time-field.component';

@Component({
    selector: 'app-form-generator',
    templateUrl: './form-generator.component.html',
    styleUrls: ['./form-generator.component.sass'],
})
export class FormGeneratorComponent
    implements OnInit, OnChanges, AfterViewInit {
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() intitalFormGroup: FormGroup;
    @Input() modalId: Symbol;
    @Input() resetHeight;
    @Input() filterData: Map<string, IFilterData>;
    @Input() filterStoreKey: FilterStoreKey;
    @Output() updateControl = new EventEmitter<
        BehaviorSubject<IUpdateAction>
    >();
    @Output() valuesChange = new EventEmitter<any>();
    @Output() formGroupRef = new EventEmitter<FormGroup>();
    @Output() resetFormFunction = new EventEmitter();
    @Output() formSubmitted = new EventEmitter();
    updateControls: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    toggleField: boolean;

    private updateOnDropdownValuesChange = false;
    formGroup: FormGroup;
    FilterType = FilterType;
    submitAttempted = false;

    loading: Map<string, boolean> = new Map();
    dataBuffer: Map<string, IDropdownData[]> = new Map();
    hiddenField: Map<string, boolean> = new Map();
    readonly BUFFER_SIZE = 50;
    readonly LOADING_ITEMS_OFFSET = 10;
    Infinity = Infinity;
    FormState = FormState;
    ButtonType = ButtonType;
    ButtonColorType = ButtonColorType;
    previousValues = null;
    showOverlay = false;
    initialFormValue: FormGroup;

    Editor: Map<
        string,
        {
            id: string;
            config: any;
        }
    > = new Map();
    chips = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    deleteButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Delete',
        buttonType: ButtonType.ICON,
        buttonColorType: ButtonColorType.WARN,
        function: null,
        buttonIcon: {
            type: IconType.SVG,
            class: 'trash',
            extraClass: 'icon-button',
        },
    };
    dateTimePickerValue = {};
    Object = Object;

    @ViewChild('timePicker')
    timePicker: NgxMatTimepickerFieldComponent;

    @ViewChildren(DateTimeFieldComponent)
    private dateTimeFieldComponents: QueryList<DateTimeFieldComponent>;

    @ViewChildren('timePickerField')
    timePickerFields: QueryList<NgxMatTimepickerFieldComponent>;

    infoIcon: IIcon = {
        type: IconType.MATICON,
        class: 'info',
    };
    selectedOperator: string = 'OR';
    showToggleButton: boolean = true;
    filteredData: IDropdownData[] = [];
    constructor(
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private notificationsService: NotificationsService,
        private ngZone: NgZone,
        private ref: ChangeDetectorRef,
        public filterService: FiltersService,
        public filterCacheService: FilterCacheService,
        public userCacheService: UserDataCacheService,
        public configCache: ConfigCacheService
    ) { }

    ngOnInit(): void {
        if (this.intitalFormGroup) {
            this.formGroup = this.intitalFormGroup;
            this.initFormFieldsForExistingFormGroup();
        } else {
            this.setUpFormGroup();
        }
        this.handleEvents();
        this.updateFormControls();

        this.updateControls.subscribe((data) => {
            if (data) {
                this.updateFormControl(data);
            }
        });
        this.filterService.resetFilters.subscribe((res) => {
            if (!res) {
                this.showToggleButton = false;
                this.selectedOperator = 'OR';
                if (this.filterService.currentFilterView)
                    this.filterService.checkboxValue.set(
                        this.filterService.currentFilterView,
                        new Map()
                    );
                else this.filterService.checkboxValue.clear();
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.showToggleButton = true;
                    }, 0);
                });
            }
        });
    }

    checkboxWithDropdownToggled(
        isChecked: boolean,
        filterId: string,
        field: IFormField
    ) {
        if (
            this.filterService.checkboxValue.has(this.filterStoreKey) &&
            this.filterService.checkboxValue.get(this.filterStoreKey)
        ) {
            this.filterService.checkboxValue
                .get(this.filterStoreKey)
                .set(filterId, isChecked);
        } else {
            this.filterService.checkboxValue.set(
                this.filterStoreKey,
                new Map().set(filterId, isChecked)
            );
        }
        if (field.isFilter) {
            if (this.filterStoreKey === FilterStoreKey.FIRST_VIEW_FILTERS) {
                this.filterService.comapareViewValueChanged = true;
            } else {
                this.filterService.valueChanged = true;
            }
        }
    }
    setlogicalOperator(event, filterId) {
        this.filterService.currentPageFilterInfo.get(filterId)[
            'logicalOperatorValue'
        ] = event;
        if (this.filterStoreKey === FilterStoreKey.FIRST_VIEW_FILTERS) {
            this.filterService.comapareViewValueChanged = true;
        } else {
            this.filterService.valueChanged = true;
        }
    }

    ngAfterViewInit(): void {
        this.initTimePickers();
        if (this.formGeneratorInput) {
            this.formGeneratorInput.fields.forEach((field) => {
                if (field.fieldType === FilterType.DATE_TIME) {
                    let timeValue = null;
                    this.dateTimePickerValue[field.name] = {
                        dateValue: null,
                        timeValue: null
                    };
                    if (
                        field.value &&
                        field.value.timeValue &&
                        field.value.dateValue
                    ) {
                        timeValue = this.patchTimeValue(field.value.timeValue, field.name);

                        field.value = {
                            dateValue: field.value.dateValue,
                            timeValue: timeValue,
                        };
                        this.dateTimePickerValue[field.name] = field.value;

                        if (this.formGroup && this.formGroup.get(field.name)) {
                            this.formGroup
                                .get(field.name)
                                .patchValue(field.value);
                        }
                    }
                }
            });
        }
        this.filterService.comapareViewValueChanged = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.intitalFormGroup && changes.intitalFormGroup.currentValue) {
            this.formGroup = changes.intitalFormGroup.currentValue;
        }
        if (
            changes &&
            changes.formGeneratorInput &&
            changes.formGeneratorInput.currentValue
        ) {
            this.formGeneratorInput = changes.formGeneratorInput.currentValue;
            if (!changes.firstChange) {
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.initTimePickers();
                    }, 500);
                });
            }
            const formState: FormState =
                changes.formGeneratorInput.currentValue.state;
            if (this.formGroup) {
                if (formState === FormState.EDIT) {
                    this.previousValues = Helper.dereference(
                        this.formGroup.value
                    );
                }
                const formGenInput: IFormGeneratorInput =
                    changes.formGeneratorInput.currentValue;
                Object.keys(this.formGroup.controls).forEach((name) => {
                    const field = formGenInput.fields.find(
                        (value) => value.name === name
                    );
                    if (formState === FormState.IDLE) {
                        try {
                            this.formGroup.get(name).disable();
                        } catch (e) {
                            // Do Nothing
                        }
                    } else {
                        if (field) {
                            try {
                                this.formGroup.get(name).enable();
                            } catch (e) {
                                // Do Nothing
                            }
                        }
                    }
                });
            }
        }
    }

    initFormFieldsForExistingFormGroup() {
        if (this.formGeneratorInput) {
            this.formGeneratorInput.fields.forEach((field) => {
                if (
                    field.fieldType === FilterType.DROPDOWN_SINGLE ||
                    field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                    field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE ||
                    field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT
                ) {
                    this.dataBuffer.set(field.name, []);
                    this.loading.set(field.name, false);
                    if (field.apiInfo && !field.listData) {
                        // Hit api to populate list data
                        this.populateListingData(field);
                    }
                }
            });
        }
    }

    updateFormControls() {
        this.valueUpdateForDependentDropdowns();
        this.updateHiddenFields();
        this.formGeneratorInput.fields.forEach((field) => {
            if (
                this.formGroup.get(field.name) ||
                this.formGroup.get(field.groupByKey)?.get(field.name)
            ) {
                if (
                    (this.formGeneratorInput.state === FormState.IDLE ||
                        field.disabled) &&
                    !field.disableWithValidation
                ) {
                    if (field.groupByKey) {
                        this.formGroup
                            .get(field.groupByKey)
                            .get(field.name)
                            .disable();
                    } else {
                        this.formGroup.get(field.name).disable();
                    }
                } else if (
                    !field.groupByKey &&
                    field.populateFromControl &&
                    this.formGroup.get(field.populateFromControl) &&
                    (!this.formGroup.get(field.populateFromControl).value ||
                        !this.formGroup.get(field.populateFromControl).value
                            .length)
                ) {
                    this.formGroup.get(field.name).disable();
                } else if (
                    field.groupByKey &&
                    field.populateFromControl &&
                    this.formGroup.get(field.populateFromControl) &&
                    (!this.formGroup.get(field.populateFromControl).value ||
                        !this.formGroup.get(field.populateFromControl).value
                            .length)
                ) {
                    this.formGroup
                        .get(field.groupByKey)
                        .get(field.name)
                        .disable();
                } else if (
                    !field.hiddenDependency ||
                    !field.hiddenDependency.length
                ) {
                    if (field.groupByKey) {
                        this.formGroup
                            .get(field.groupByKey)
                            .get(field.name)
                            .enable();
                    } else {
                        this.formGroup.get(field.name).enable();
                    }
                }
            }
        });
    }

    setUpFormGroup(setDefaultValue: boolean = true) {
        const formGroupData = {};
        const groupObj = {};
        const setUpFormGroupFn = (field) => {
            if (field.fieldType === FilterType.TOGGLE) {
                field.value = field.value + '';
            }
            if (field.isFilter && setDefaultValue) {
                const filtersData = this.filterCacheService.getFiltersInfo(
                    this.userCacheService.emailId,
                    this.configCache.viewId,
                    this.filterStoreKey
                );
                const filterInfo = this.filterService.currentPageFilterInfo.get(
                    field.id
                );

                if (
                    filtersData &&
                    filtersData[field.id] &&
                    filterInfo.type !== FilterType.DATE_TIME_RANGE
                ) {
                    if (
                        field.fieldType === FilterType.TOGGLE &&
                        filtersData[field.id].value !== undefined
                    ) {
                        filtersData[field.id].value =
                            filtersData[field.id].value + '';
                    }
                    if (filterInfo && filterInfo.logicalOperatorEnable) {
                        this.selectedOperator =
                            filtersData[field.id]?.filterInfo[
                            'logicalOperatorValue'
                            ] ?? 'OR';
                    }
                    if (
                        field.extractValueFromKey &&
                        filtersData[field.id] &&
                        filtersData[field.id].value
                    ) {
                        const valueObj = filtersData[field.id]?.value;
                        field.value = Helper.extractDataFromObject(
                            field.extractValueFromKey,
                            valueObj
                        );
                    } else if (
                        filtersData[field.id] &&
                        this.filterStoreKey !== FilterStoreKey.WEBSITE_FILTERS
                    ) {
                        field.value = field.value
                            ? field.value
                            : filtersData[field.id]?.value;
                    } else if (
                        filtersData[field.id] &&
                        this.filterStoreKey === FilterStoreKey.WEBSITE_FILTERS
                    ) {
                        field.value =
                            filtersData &&
                                filtersData[field.id] &&
                                filtersData[field.id].value
                                ? filtersData[field.id].value
                                : field.value
                                    ? field.value
                                    : null;
                    }
                }
            }
            if (field.isFilter && setDefaultValue) {
                if (
                    field.fieldType === FilterType.DATE_RANGE ||
                    field.fieldType === FilterType.DATE_RANGE_TODAY ||
                    field.fieldType === FilterType.WIDGET_DATE_RANGE
                ) {
                    const dateFilterValue =
                        new GetFilterSelectedValuePipe().transform(
                            field.id,
                            this.filterService,
                            this.filterStoreKey,
                            this.filterCacheService,
                            this.userCacheService,
                            this.configCache
                        );
                    field.value = dateFilterValue;
                }
            }
            let defaultValue = field.value
                ? field.value
                : typeof field.value === 'number'
                    ? field.value
                    : undefined;
            if (
                (field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                    field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE ||
                    field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                    field.fieldType === FilterType.MATCHIPLIST) &&
                defaultValue === undefined
            ) {
                defaultValue = [];
            }
            if (field.groupByKey) {
                if (field.groupByKey in groupObj) {
                    groupObj[field.groupByKey][field.name] = [
                        field.value ? field.value : defaultValue,
                        [
                            // Validators will come here
                        ],
                    ];
                } else {
                    groupObj[field.groupByKey] = {
                        [field.name]: [
                            field.value ? field.value : defaultValue,
                            [
                                // Validators will come here
                            ],
                        ],
                    };
                }
                if (field.validations) {
                    field.validations.forEach((validation) => {
                        validation.validator.formGenRef = this;
                        groupObj[field.groupByKey][field.name][1].push(
                            validation.validator.validatorFunction.bind(
                                validation.validator
                            )
                        );
                    });
                }
                const groupArr = Object.keys(groupObj);
                if (groupArr.length) {
                    groupArr.forEach((group) => {
                        const formGroupData1 = this.formBuilder.group(
                            groupObj[group]
                        );
                        formGroupData[group] = formGroupData1;
                    });
                }
            } else {
                formGroupData[field.name] = [
                    field.value ? field.value : defaultValue,
                    [
                        // Validators will come here
                    ],
                ];
            }

            if (field.populateFromControl) {
                this.updateOnDropdownValuesChange = true;
            }

            if (
                field.fieldType === FilterType.DROPDOWN_SINGLE ||
                field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE ||
                field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                field.fieldType === FilterType.DROPDOWN_LIST_OBJECT
            ) {
                this.dataBuffer.set(field.name, []);
                this.loading.set(field.name, false);
                if (field.apiInfo && !field.listData) {
                    // Hit api to populate list data
                    this.populateListingData(field);
                }
            }
            if (field.fieldType === FilterType.WYSIWYG_EDITOR) {
                this.Editor.set(field.name, {
                    id: Helper.generateUniqueKey(16),
                    config: Helper.getSummernoteConfig({
                        placeholder: field.placeholder,
                    }),
                });
            }
            if (field.validations && !field.groupByKey) {
                field.validations.forEach((validation) => {
                    validation.validator.formGenRef = this;
                    formGroupData[field.name][1].push(
                        validation.validator.validatorFunction.bind(
                            validation.validator
                        )
                    );
                });
            }
        };
        if (this.formGeneratorInput) {
            this.formGeneratorInput.fields.forEach((field) => {
                setUpFormGroupFn(field);
                if (field['table'] && field['table']['rowfields']) {
                    field['table']['rowfields'].forEach((rowField) => {
                        if (rowField) {
                            field['table']['tableHeadings'].forEach(
                                (heading) => {
                                    if (rowField[heading]) {
                                        setUpFormGroupFn(rowField[heading]);
                                    }
                                }
                            );
                        }
                    });
                }
            });

            this.formGroup = this.formBuilder.group(formGroupData);
        }
    }
    handleEvents() {
        this.formGroupRef.emit(this.formGroup);
        if (this.formGroup) {
            this.formGroup.valueChanges.subscribe((formValue) => {
                this.valuesChange.emit(formValue);
                this.formGroupRef.emit(this.formGroup);

                // Update Hidden Fields on Reset
                this.formGeneratorInput.fields.forEach((field) => {
                    if (field.hiddenDependency) {
                        field.hiddenDependency.forEach((control) => {
                            if (!formValue[control.controlName]) {
                                this.hiddenField.set(field.name, true);
                            }
                        });
                    }
                });
            });
        }
        this.resetFormFunction.emit(this.resetForm.bind(this));
        this.updateControl.emit(this.updateControls);
    }
    updateFormControl(data: IUpdateAction) {
        if (data.action === UpdateAction.REFRESH) {
            this.formGeneratorInput.fields.forEach((field) => {
                data.controls.forEach((controlName) => {
                    if (field.name === controlName) {
                        if (
                            field.fieldType === FilterType.DROPDOWN_SINGLE ||
                            field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                            field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                            field.fieldType ===
                            FilterType.DROPDOWN_GROUP_MULTIPLE
                        ) {
                            if (field.apiInfo && field.listDataExists) {
                                this.populateListingData(field);
                            } else if (
                                field.apiInfo &&
                                (!field.listData || !field.listData.length)
                            ) {
                                // Hit api to populate list data
                                this.populateListingData(field);
                            }
                        }
                        this.updateFormControls();
                    }
                });
            });
        }
        if (data.action === UpdateAction.UPDATE_VALUE) {
            this.formGeneratorInput.fields.forEach((field) => {
                data.controls.forEach((controlName) => {
                    if (field.name !== controlName) {
                        if (field.groupByKey) {
                            field.value =
                                this.formGroup &&
                                    this.formGroup
                                        .get(field.groupByKey)
                                        .get(field.name) &&
                                    this.formGroup
                                        .get(field.groupByKey)
                                        .get(field.name).value
                                    ? this.formGroup
                                        .get(field.groupByKey)
                                        .get(field.name).value
                                    : undefined;
                        } else {
                            field.value =
                                this.formGroup &&
                                    this.formGroup.get(field.name) &&
                                    this.formGroup.get(field.name).value
                                    ? this.formGroup.get(field.name).value
                                    : undefined;
                        }
                    }
                    if (field.name === controlName) {
                        if (
                            field.fieldType === FilterType.DROPDOWN_SINGLE ||
                            field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                            field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                            field.fieldType ===
                            FilterType.DROPDOWN_GROUP_MULTIPLE
                        ) {
                            field.value = data.value;
                            this.fixDropdownSelection(field);
                        } else {
                            field.value = data.value;
                            this.setUpFormGroup(false);
                            this.formGroupRef.emit(this.formGroup);
                        }
                        this.updateFormControls();
                    }
                });
            });
        }
        if (data.action === UpdateAction.UPDATE_FORM_FIELD) {
            this.formGeneratorInput.fields = [
                ...this.formGeneratorInput.fields,
                ...data.fields,
            ];
            this.setUpFormGroup();
            this.updateHiddenFields();
            this.updateFormControls();
            this.formGroupRef.emit(this.formGroup);
        }
        if (data.action === UpdateAction.UPDATE_HIDDEN_FIELD) {
            this.updateFormControls();
            this.formGroupRef.emit(this.formGroup);
        }
        if (data.action === UpdateAction.UPDATE_DROPDOWN) {
            this.formGeneratorInput.fields.forEach((field) => {
                data.controls.forEach((controlName) => {
                    if (field.name === controlName) {
                        if (
                            field.fieldType === FilterType.DROPDOWN_SINGLE ||
                            field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                            field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                            field.fieldType ===
                            FilterType.DROPDOWN_GROUP_MULTIPLE
                        ) {
                            field.listData = data.value;
                            field.value = '';
                            this.updateFormControls();
                            this.setUpFormGroup();
                        }
                    }
                });
            });
        }
    }
    resetForm(formData?) {
        if (this.previousValues && !formData) {
            this.formGroup.reset(this.previousValues);
        } else if (this.initialFormValue && !formData) {
            this.formGroup.reset(this.initialFormValue.value);
        } else if (formData) {
            this.formGroup.reset(formData);
        }
    }

    submitForm(form) {
        this.formSubmitted.emit(true);
        Helper.markAllFieldAsTouched(
            this.formGroup,
            this.formGeneratorInput.updateValidityOnSubmit
        );
        if (this.formGroup.invalid) {
            this.formGroup.updateValueAndValidity();
            return;
        }
        if (
            this.formGeneratorInput.submitButton &&
            this.formGeneratorInput.submitButton.showLoader
        ) {
            if (this.formGeneratorInput.submitButton.loader) {
                return;
            }
            this.formGeneratorInput.submitButton.loader = true;
        }
        this.handleTableFormInput();
        if (
            this.formGeneratorInput.submitButton &&
            this.formGeneratorInput.submitButton.function
        ) {
            this.formGeneratorInput.submitButton.function(
                this.formGeneratorInput.submitButton,
                this.formGroup,
                this.modalId ? this.modalId : null
            );
        }
    }

    onScroll({ end }, field: IFormField) {
        if (
            (this.dataBuffer.get(field.name) &&
                this.dataBuffer.get(field.name).length &&
                this.loading.has(field.name) &&
                this.loading.get(field.name)) ||
            (field.listData &&
                this.dataBuffer.get(field.name) &&
                field.listData.length <= this.dataBuffer.get(field.name).length)
        ) {
            return;
        }
        if (
            end + this.LOADING_ITEMS_OFFSET >=
            this.dataBuffer.get(field.name).length
        ) {
            this.fetchMore(field);
        }
    }

    private fetchMore(field: IFormField) {
        if (field.listData) {
            const len = this.dataBuffer.get(field.name).length;
            const more = field.listData.slice(len, this.BUFFER_SIZE + len);
            this.loading.set(field.name, false);
            this.dataBuffer.set(
                field.name,
                this.dataBuffer.get(field.name).concat(more)
            );
        }
    }

    populateListingData(field: IFormField) {
        this.loading.set(field.name, true);
        const apiArgs = Helper.generateHitApiConfig(field.apiInfo);
        apiArgs.input = field.apiInput ? field.apiInput : {};

        if (field.customInputCallback) {
            apiArgs.input = {
                ...apiArgs.input,
                ...field.customInputCallback(),
            };
        }

        apiArgs.function = (response) => {
            if (response) {
                if (field.responseValueKey) {
                    field.listData = Helper.extractDataFromObject(
                        field.responseValueKey,
                        response
                    );
                } else if ('dataList' in response) {
                    field.listData = Helper.extractDataFromObject(
                        'dataList',
                        response
                    );
                    if (!field.value) {
                        field.value =
                            response.dataMap && response.dataMap.defaultValue
                                ? response.dataMap.defaultValue
                                : null;
                    }
                } else {
                    field.listData = response;
                }

                this.fixDropdownSelection(field);
                this.valueUpdateForDependentDropdowns();
                this.loading.set(field.name, false);
                this.loading = new Map(this.loading);
                this.ref.detectChanges();
            } else {
                this.loading.set(field.name, false);
                this.loading = new Map(this.loading);
                this.ref.detectChanges();
                field.listData = null;
                // field.placeholder = Messages.NO_DATA_AVAILABLE;
            }
            if (field.afterResponse) {
                field.afterResponse(response);
            }
        };
        apiArgs.errorFunction = (error) => {
            this.loading.set(field.name, false);
            this.loading = new Map(this.loading);
            this.ref.detectChanges();
            field.listData = null;
            if (field.afterResponse) {
                field.afterResponse();
            }
            // field.placeholder = error.error.message;
        };

        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    fixDropdownSelection(field: IFormField) {
        if (
            field.value !== undefined &&
            !field.value &&
            field.listData &&
            field.listData.length === 1 &&
            field.fieldType === FilterType.DROPDOWN_SINGLE &&
            field.autoSelectDropDownData
        ) {
            field.value = field.listData[0].id;
        }
        if (field.value) {
            if (
                (typeof field.value === 'string' ||
                    typeof field.value === 'number') &&
                field.listData &&
                field.listData.length
            ) {
                field.listData.forEach((value) => {
                    if (value.id === field.value) {
                        if (
                            !field.groupByKey &&
                            this.formGroup.get(field.name)
                        ) {
                            if (field.getKey) {
                                this.formGroup
                                    .get(field.name)
                                    .setValue(value[field.getKey]);
                            } else if (field.getKey === null) {
                                this.formGroup.get(field.name).setValue(value);
                            } else {
                                this.formGroup
                                    .get(field.name)
                                    .setValue(value.id);
                            }
                        } else if (
                            field.groupByKey &&
                            this.formGroup.get(field.groupByKey) &&
                            this.formGroup.get(field.groupByKey).get(field.name)
                        ) {
                            if (field.getKey) {
                                this.formGroup
                                    .get(field.groupByKey)
                                    .get(field.name)
                                    .setValue(value[field.getKey]);
                            } else if (field.getKey === null) {
                                this.formGroup
                                    .get(field.groupByKey)
                                    .get(field.name)
                                    .setValue(value);
                            } else {
                                this.formGroup
                                    .get(field.groupByKey)
                                    .get(field.name)
                                    .setValue(value.id);
                            }
                        }
                    }
                });
            } else if (
                typeof field.value === 'object' &&
                field.listData &&
                field.listData.length &&
                field.fieldType !== FilterType.DATE_TIME
            ) {
                const values = [];
                field.value.forEach((value) => {
                    const selectedValue = field.listData.find((val) => {
                        if (val && value) {
                            if (typeof value === 'string') {
                                return val.id === value;
                            }
                            return val.id === value.id;
                        }
                    });

                    if (selectedValue) {
                        if (this.formGroup.get(field.name)) {
                            if (field.getKey) {
                                values.push(selectedValue[field.getKey]);
                            } else if (field.getKey === null) {
                                values.push(selectedValue);
                            } else {
                                values.push(selectedValue.id);
                            }
                        }
                    }
                });
                if (field.groupByKey) {
                    this.formGroup
                        .get(field.groupByKey)
                        .get(field.name)
                        .setValue(values);
                } else {
                    this.formGroup.get(field.name).setValue(values);
                }
            } else {
                if (field.groupByKey) {
                    this.formGroup
                        .get(field.groupByKey)
                        .get(field.name)
                        .setValue([]);
                } else {
                    this.formGroup.get(field.name).setValue([]);
                }
            }
        }
    }

    onSelectAll(selectAll: boolean, field: IFormField) {
        if (field.disableDropdown) {
            return;
        }
        if (selectAll) {
            let existingValue;
            if (field && field.groupByKey) {
                existingValue = this.formGroup
                    .get(field.groupByKey)
                    .get(field.name).value;
            } else {
                existingValue = this.formGroup.get(field.name).value;
            }
            const selectedItems = field.listData;
            let selectedItemsId = this.filteredData.map((item) => {
                return item['value']['id'];
            });
            selectedItemsId = [...selectedItemsId, ...existingValue];

            let finalMappedList: IDropdownData[] = selectedItems.filter(
                (item) => {
                    if (selectedItemsId.includes(item.id)) {
                        return item;
                    }
                }
            );
            if (finalMappedList && !finalMappedList.length) {
                finalMappedList = field.listData;
            }
            if (field.maxSelectionLimit) {
                finalMappedList = finalMappedList.slice(
                    0,
                    field.maxSelectionLimit
                );
            }
            if (field && field.groupByKey) {
                this.formGroup
                    .get(field.groupByKey)
                    .get(field.name)
                    .setValue(
                        finalMappedList.map((listItem) => {
                            if (field.getKey) {
                                return listItem[field.getKey];
                            } else if (field.getKey === null) {
                                return listItem;
                            } else {
                                return listItem.id;
                            }
                        })
                    );
            } else {
                this.formGroup.get(field.name).setValue(
                    finalMappedList
                        .map((listItem) => {
                            if (!listItem.disabled) {
                                if (field.getKey) {
                                    return listItem[field.getKey];
                                } else if (field.getKey === null) {
                                    return listItem;
                                } else {
                                    return listItem.id;
                                }
                            }
                        })
                        .filter((id) => id)
                );
            }

            this.updateFormControls();
        } else {
            const disabledOptions = [];
            field.listData.map((listItem) => {
                if (listItem.disabled) {
                    if (field.getKey) {
                        disabledOptions.push(listItem[field.getKey]);
                    } else if (field.getKey === null) {
                        disabledOptions.push(listItem);
                    } else {
                        disabledOptions.push(listItem.id);
                    }
                }
            });
            if (field && field.groupByKey) {
                // this.formGroup
                //     .get(field.groupByKey)
                //     .get(field.name)
                //     .setValue(disabledOptions);
                this.formGroup
                    .get(field.groupByKey)
                    .get(field.name)
                    .setValue([]);
            } else {
                // this.formGroup.get(field.name).setValue(disabledOptions);
                this.formGroup.get(field.name).setValue([]);
            }
        }
        if (field.isFilter) {
            if (this.filterService.isCompareViewEnabled) {
                this.filterService.comapareViewValueChanged = true;
            } else {
                this.filterService.valueChanged = true;
            }
        }
    }
    getFiltredData(selectedData: IDropdownData[]) {
        if (selectedData) {
            this.filteredData = selectedData;
        }
    }

    addChip(field: IFormField, event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value.trim();
        const values: string[] = this.formGroup.controls[field.name].value;
        if (value.length && !values.includes(value)) {
            values.push(value);
            input.value = '';
        }
        if (
            field.matChipInputLimit &&
            values.length > field.matChipInputLimit
        ) {
            this.formGroup.controls[field.name].setValue(
                values.splice(values.length - 1, 1)
            );
            input.value = '';
        }

        this.formGroup.get(field.name).updateValueAndValidity();
    }

    onRemoveChip(field: IFormField, index: number) {
        if (index > -1) {
            this.formGroup.controls[field.name].value.splice(index, 1);
        }
        this.formGroup.get(field.name).updateValueAndValidity();
    }

    dropdownValuesChanged(value, field: IFormField) {
        let valueLength;
        if (value) {
            valueLength = value.length;
        }
        const filterInfo = this.filterService.currentPageFilterInfo.has(
            field.id
        )
            ? this.filterService.currentPageFilterInfo.get(field.id)
            : null;
        this.updateHiddenFields();
        if (this.updateOnDropdownValuesChange) {
            this.updateFormControls();
        }

        if (field) {
            if (
                field.fieldType &&
                field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
            ) {
                if (
                    field.eachGroupMaxLimitSelection ||
                    (filterInfo && filterInfo.selectionLimit)
                ) {
                    if (value && value.length > 0) {
                        const obj = {};
                        value.map((each) => {
                            if (each[field['groupBy']] in obj) {
                                obj[each[field['groupBy']]] =
                                    obj[each[field['groupBy']]] + 1;
                            } else {
                                obj[each[field['groupBy']]] = 1;
                            }
                        });

                        Object.keys(obj).map((each) => {
                            for (let i = value.length; i--;) {
                                if (
                                    obj[each] >
                                    field.eachGroupMaxLimitSelection ||
                                    obj[each] > filterInfo.selectionLimit
                                ) {
                                    const limit =
                                        field.eachGroupMaxLimitSelection
                                            ? field.eachGroupMaxLimitSelection
                                            : filterInfo.selectionLimit
                                                ? filterInfo.selectionLimit
                                                : 0;
                                    value = value.slice(0, limit);
                                }
                            }
                        });

                        this.formGroup.get(field.name).setValue(value);
                        if (valueLength !== value.length) {
                            Helper.showErrorMessage(
                                this.notificationsService,
                                null,
                                filterInfo.selectionLimit
                                    ? `Only ${filterInfo.selectionLimit} ${filterInfo.selectionLimit === 1
                                        ? 'item'
                                        : 'items'
                                    } per group is allowed to select.`
                                    : `Only ${field.eachGroupMaxLimitSelection
                                    } ${field.eachGroupMaxLimitSelection === 1
                                        ? 'item'
                                        : 'items'
                                    } per group is allowed to select.`
                            );
                        }
                    }
                }
            }
        }
        if (field.isFilter) {
            if (this.filterService.isCompareViewEnabled) {
                this.filterService.comapareViewValueChanged = true;
            } else {
                this.filterService.valueChanged = true;
            }
        }
    }

    valueUpdateForDependentDropdowns() {
        this.formGeneratorInput.fields.forEach((field) => {
            if (
                field.populateFromControl &&
                this.formGroup &&
                this.formGroup.get(field.populateFromControl) &&
                this.formGroup.get(field.populateFromControl).value &&
                this.formGroup.get(field.populateFromControl).value.length
            ) {
                const selectedValuesOfParent: string[] = this.formGroup.get(
                    field.populateFromControl
                ).value;
                const selectedValues = this.formGroup.get(field.name).value;
                const depField = new FieldDataFromControlNamePipe().transform(
                    field.populateFromControl,
                    this.formGeneratorInput.fields
                );

                if (depField && depField.listData) {
                    let dropdownValue = [];
                    selectedValuesOfParent.forEach((ele) => {
                        if (typeof ele === 'object') {
                            const val = depField.listData.filter(
                                (value) => value['id'] === ele!['id']
                            );
                            dropdownValue.push(val[0]);
                        } else if (typeof ele === 'string') {
                            const dropdownValues = depField.listData.filter(
                                (value) =>
                                    selectedValuesOfParent.includes(value.id)
                            );
                            dropdownValue = dropdownValues;
                        }
                    });
                    field.listData = dropdownValue;
                    if (
                        field.value &&
                        field.value.length &&
                        !this.formGroup.get(field.name).value
                    ) {
                        const val = field.listData.find(
                            (data) => data.id === field.value
                        );
                        this.formGroup
                            .get(field.name)
                            .setValue(val ? val.id : null);
                    }
                }

                if (!field.listData || !field.listData.length) {
                    if (
                        field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
                    ) {
                        this.formGroup.get(field.name).setValue([]);
                    } else {
                        this.formGroup.get(field.name).setValue(null);
                    }
                }
                if (
                    field.listData &&
                    field.listData.length &&
                    selectedValues &&
                    selectedValues.length
                ) {
                    if (typeof selectedValues === 'string') {
                        const val = field.listData.find(
                            (data) => data.id === selectedValues
                        );

                        this.formGroup
                            .get(field.name)
                            .setValue(val ? val.id : null);
                    } else if (typeof selectedValues === 'object') {
                        this.formGroup
                            .get(field.name)
                            .setValue(
                                selectedValues.filter((value) =>
                                    field.listData.find(
                                        (data) => data.id === value
                                    )
                                )
                            );
                    }
                }
            } else if (
                field.populateFromControl &&
                this.formGroup &&
                (field.fieldType === FilterType.TEXT ||
                    field.fieldType === FilterType.EMAIL)
            ) {
                const fieldValue = this.formGroup.get(field.name);
                const depField = this.formGroup.get(
                    field.populateFromControl
                ).value;
                const selectedValuesOfParent: string[] = this.formGroup.get(
                    field.populateFromControl
                ).value;
                if (
                    selectedValuesOfParent &&
                    selectedValuesOfParent[field.showKey]
                ) {
                    this.formGroup
                        .get(field.name)
                        .setValue(selectedValuesOfParent[field.showKey]);
                } else {
                    this.formGroup.get(field.name).setValue(null);
                }
            } else if (
                field.populateFromControl &&
                this.formGroup &&
                this.formGroup.get(field.populateFromControl)
            ) {
                if (field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE) {
                    this.formGroup.get(field.name).setValue([]);
                } else {
                    this.formGroup.get(field.name).setValue(null);
                }
            }
        });
    }

    updateHiddenFields() {
        this.formGeneratorInput.fields.forEach((field) => {
            if (field.hiddenDependency && field.hiddenDependency.length) {
                let validFieldCount = 0;

                field.hiddenDependency.forEach((control) => {
                    let validationCounter = 0;
                    control.validations.forEach((validation) => {
                        validation.validator.formGenRef = this;

                        if (
                            !validation.validator.validatorFunction(
                                this.formGroup.controls[control.controlName]
                            )
                        ) {
                            validationCounter++;
                        }
                    });

                    if (validationCounter === control.validations.length) {
                        validFieldCount++;
                    }
                });

                if (validFieldCount === field.hiddenDependency.length) {
                    this.hiddenField.set(field.name, false);
                    if (!field.disabled) {
                        this.formGroup.controls[field.name].enable();
                    }
                } else {
                    this.hiddenField.set(field.name, true);
                    this.formGroup.controls[field.name].disable();

                    if (
                        field.fieldType === FilterType.DROPDOWN_SINGLE ||
                        field.fieldType === FilterType.DROPDOWN_MULTIPLE ||
                        field.fieldType === FilterType.MULTI_DROPDOWN_WITH_INPUT ||
                        field.fieldType === FilterType.DROPDOWN_GROUP_MULTIPLE
                    ) {
                        this.formGroup.controls[field.name].reset();
                        this.fixDropdownSelection(field);
                    }
                    // this.formGroup.controls[field.name].clearValidators();
                }
            } else {
                this.hiddenField.set(field.name, false);
            }
        });
    }
    compareWith(o1: any, o2: any) {
        const key = this['bindValue'] ? this['bindValue'] : 'id';
        if (typeof o2 === 'string') {
            return (
                (o1[key] &&
                    o1[key].length &&
                    o2 &&
                    o2.length &&
                    o1[key] === o2) ||
                o1 === o2
            );
        }
        if (o1 && o1[key] && o2 && o2[key]) {
            return o1[key] === o2[key];
        }
        if (typeof o2 === 'object' && o1 && o2) {
            let counter = 0;
            const value = Object.keys(o2);
            value.forEach((keys) => {
                if (o1[keys] === o2[keys]) {
                    counter++;
                }
            });

            return counter === value.length;
        }

        if (o1 && o2 && !o2[key]) {
            return o1[key] ? o1[key] === o2 : o1 === o2;
        }

        return true;
    }

    handleTableFormInput() {
        const finalTableData = [];
        this.formGeneratorInput.fields.map((field) => {
            if (field.fieldType === FilterType.TABLE) {
                field.table.rowfields.map((rowField, i) => {
                    field.table.tableHeadings.map((heading) => {
                        if (heading && rowField[heading]) {
                            finalTableData[i] = {
                                [heading]:
                                    this.formGroup.controls[
                                        rowField[heading].name
                                    ].value,
                                ...finalTableData[i],
                            };
                        }
                    });
                });
                this.formGroup.value['tableValue'] = finalTableData;
            }
        });
    }
    initTimePickers() {
        if (
            this.timePickerFields &&
            this.timePickerFields.length &&
            this.formGeneratorInput &&
            this.formGeneratorInput.fields &&
            this.formGeneratorInput.fields.length
        ) {
            let pickerIndex = -1;
            const timePickerList = this.timePickerFields.toArray();
            this.formGeneratorInput.fields.forEach((field) => {
                if (field.fieldType === FilterType.TIME) {
                    pickerIndex += 1;
                    const timePicker = timePickerList[pickerIndex];
                    if (!timePicker) {
                        return;
                    }
                    if (field.timePickerMinutesGap > 1) {
                        if (field.minTime) {
                            setTimeout(() => {
                                this.filterOutMinutes(timePicker, field);
                            }, 500);
                        } else {
                            this.filterOutMinutes(timePicker, field);
                        }
                    }
                }
            });
        }
    }

    /**
     * This function disables the timePicker Minutes as per field's timePickerMinutesGap
     * @param timePickerComp NgxMatTimePickerField component instance to manipulate it's minutes list
     * @param field field on whose timePickerMinutesGap is to be used
     */
    private filterOutMinutes(timePickerComp, field) {
        timePickerComp.minutesList.forEach((minutes) => {
            if (minutes.time % field.timePickerMinutesGap !== 0) {
                minutes.disabled = true;
            }
        });
    }

    onTimePickerValueChanged(
        field: IFormField,
        timePicker: NgxMatTimepickerFieldComponent
    ) {
        if (field.minTime && field.timePickerMinutesGap > 1) {
            this.filterOutMinutes(timePicker, field);
            if (field.isFilter) {
                if (this.filterService.isCompareViewEnabled) {
                    this.filterService.comapareViewValueChanged = true;
                } else {
                    this.filterService.valueChanged = true;
                }
            }
        }
    }

    timeChanged(event, controlName) {
        this.dateTimePickerValue[controlName].timeValue = event;
        this.formGroup.controls[controlName].setValue(
            this.dateTimePickerValue[controlName]
        );
    }

    dateChanged(event: MatDatepickerInputEvent<any>, controlName) {
        this.dateTimePickerValue[controlName].dateValue = event.value;
        this.formGroup.controls[controlName].setValue(
            this.dateTimePickerValue[controlName]
        );
    }

    toggleFieldFn($event, type?) {
        this.toggleField = !this.toggleField;
    }

    private patchTimeValue(timeValue, fieldName?) {
        const result = this.extractHoursMinsFromTime(timeValue);
        const formattedValue = `${result.hours}:${result.mins} ${result.meridiem}`;
        if (this.timePicker) { // My guess is that it will be undefined and could be removed in future
            this.timePicker.writeValue(formattedValue);
        } else if (fieldName) {
            const timePicker = this.dateTimeFieldComponents.find(
                (item) => item.field.name === fieldName
            )?.timePicker;
            if (timePicker) {
                timePicker.writeValue(formattedValue);
            }
        }
        return formattedValue;
    }

    openImageUploadToModal(field: IFormField) {
        const imageUploadInfo = field.imageUpload;
        if (imageUploadInfo) {
            const uploadCount = imageUploadInfo.imageDetails
                ? imageUploadInfo.imageDetails.length
                : 1;
            if (uploadCount <= 2) {
                const imageUploadedCallback = (imageDetails) => {
                    if (imageDetails) {
                        const values = imageDetails.map(
                            (imageDetail) => imageDetail.image
                        );
                        this.formGroup.get(field.name).setValue(values);
                        if (imageUploadInfo.uploadCallback) {
                            imageUploadInfo.uploadCallback(imageDetails, field);
                        }
                    }
                };
                const modalData: IModalData = {
                    modalName: field.label ? field.label : 'Image Upload',
                    modalIcon: null,
                    modalType: ModalType.MIDDLE,
                    sourceId:
                        imageUploadInfo.widgetRef.widgetData
                            .widgetUniqueIdentifier,
                    modalHeightVh: 70,
                    modalWidthVw: 50,
                    modalSteps: [
                        {
                            stepName: 'Upload',
                            stepData: {
                                componentToLoad: ImageUploadModalComponent,
                                payload: {
                                    data: {
                                        imageDetails:
                                            imageUploadInfo.imageDetails,
                                        imageSelected:
                                            this.formGroup.get(field.name) &&
                                                this.formGroup.get(field.name).value
                                                ? this.formGroup.get(field.name)
                                                    .value
                                                : null,
                                        uploadCallback: imageUploadedCallback,
                                    },
                                },
                            },
                        },
                    ],
                };
                imageUploadInfo.widgetRef.modalService.openModal(modalData);
            }
        }
    }

    extractHoursMinsFromTime(time) {
        let date = null;
        if (isNaN(time)) {
            date = moment(new Date(time)).format('hh mm A');
        } else {
            date = moment(new Date(+time)).format('hh mm A');
        }

        if (date) {
            const splittedDate = date.split(' ');
            const hours = +splittedDate[0];
            const mins = +splittedDate[1];
            const meridiem = splittedDate[2];
            return {
                hours,
                mins,
                meridiem,
            };
        }

        return {
            hours: 0,
            mins: 0,
            meridiem: 'am',
        };
    }
}
