import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { SupportedFormat } from 'src/app/shared/enums/SupportedFormat';
import { IFilterSelection } from 'src/app/shared/interfaces/filter/IFilterSelection';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-create-alert-second-step',
    templateUrl: './create-alert-second-step.component.html',
    styleUrls: ['./create-alert-second-step.component.sass']
})
export class CreateAlertSecondStepComponent implements OnInit {
    widgetsSelected: string[] = [];
    FilterStoreKey = FilterStoreKey;
    supportedFormats: Set<SupportedFormat> = null;
    disableNextButton = true;
    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.previousStep.bind(this)
    };
    nextButton: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: this.nextStep.bind(this)
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        public filtersService: FiltersService
    ) {}

    ngOnInit(): void {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        this.widgetsSelected = step1Data['widgetsSelected'];
        if (
            this.modalInputData &&
            this.modalInputData.data &&
            this.modalInputData.data['stepData'] &&
            !this.filtersService.filtersData.get(FilterStoreKey.ALERT_FILTERS)
        ) {
            const widgetFilters =
                this.modalInputData.data['stepData']['widgetFilters'];
            const filtersMap = new Map(Object.entries(widgetFilters));
            this.filtersService.filtersData.set(
                FilterStoreKey.ALERT_FILTERS,
                filtersMap as Map<string, IFilterSelection>
            );
        }
        // if (currentStepData) {
        // this.filtersService.filtersDataTemp.set(
        //     FilterStoreKey.ALERT_FILTERS,
        //     currentStepData['widgetFilters']
        // );
        // }
    }

    updateData() {
        const filters = this.filtersService.prepareInput(
            Array.from(
                this.filtersService.filtersPageData
                    .getValue()
                    .get(FilterStoreKey.ALERT_FILTERS)
                    .keys()
            ),
            FilterStoreKey.ALERT_FILTERS
        );
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, {
                widgetFilters: new Map(Object.entries(filters)),
                supportedFormats: this.supportedFormats
            });
    }

    nextStep() {
        if (this.disableNextButton) {
            return;
        }
        this.filtersService.convertTempToMain(
            FilterStoreKey.ALERT_FILTERS,
            false
        );
        this.updateData();
        this.multiStepFormService.nextStep(this.modalInputData.modalId);
    }
    previousStep() {
        this.filtersService.convertTempToMain(
            FilterStoreKey.ALERT_FILTERS,
            false
        );
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
}
