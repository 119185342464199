<div class="table-container">
    <div class="spinner">
        <mat-spinner *ngIf="excelLoader" [diameter]="45" class="loader spinner">
        </mat-spinner>
    </div>
    <app-only-table-generator
        *ngIf="excelLoader === false && !resetClicked"
        [tableData]="tableData"
        [tableInput]="tableInput"
        (gridRef)="onGridReady($event)"
        [useMaterialIcons]="true"
        [showSearchBox]="false"
        [hideControlBar]="true"
    >
    </app-only-table-generator>
</div>
<div class="container">
    <div class="file-upload-form-section">
        <div class="form-col padded-bottom">
            <span>Download Excel Template :</span>
        </div>
        <div class="form-col padded-sides">
            <div class="form-row padded-bottom">
                <div class="form-col-left-align">
                    <h4>Template.xlsx</h4>
                </div>
                <div class="form-col-right-align">
                    <app-button-generator [buttonGenInput]="buttonInputs[0]">
                    </app-button-generator>
                </div>
            </div>
        </div>
    </div>
    <div class="form-row separated-up-down">
        <div class="form-col padded-bottom">
            <span class="upload-container">Upload Excel File :</span>
        </div>
        <app-form-generator
            *ngIf="excelFormInput"
            class="excel-form"
            [formGeneratorInput]="excelFormInput"
            (formGroupRef)="excelForm = $event"
            (valuesChange)="excelFile($event)"
        >
        </app-form-generator>
    </div>
</div>
<div class="tab-content-actions">
    <app-button-generator [buttonGenInput]="buttonInputs[1]">
    </app-button-generator>
</div>
