import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IFilterData } from 'src/app/shared/interfaces/filter/IFilterData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
    selector: 'app-single-dropdown-percent-filter',
    templateUrl: './single-dropdown-percent-filter.component.html',
    styleUrls: ['./single-dropdown-percent-filter.component.sass'],
})
export class SingleDropdownPercentFilterComponent implements OnInit, OnDestroy {
    @Input() filterInfo: IFilterInfo;
    @Input() filterData: IFilterData;
    @Input() disabled: boolean;
    @Input() disableMessage: string;
    @Input() filterStoreKey: string;
    @Input() enableTemp: boolean;
    @Input() selectedValue: any;
    @Input() infoIcon: IIcon;

    percentage = null;
    valueSelected = null;

    disablePercentage = false;

    resetFilter: Subscription = null;

    percentKey;
    dropDownValueKey;

    constructor(private filtersService: FiltersService) {}

    ngOnInit(): void {
        this.percentKey = 'percent';
        this.dropDownValueKey = this.filterInfo.selector
            ? this.filterInfo.selector
            : '';
        this.initSelectedValue(this.selectedValue);
        this.resetFilter = this.filtersService.resetFilters.subscribe((res) => {
            if (!res) {
                if (this.filterInfo.selectedValue) {
                    this.initSelectedValue(this.filterInfo.selectedValue);
                }
            }
        });
    }

    initSelectedValue(selectedValue) {
        if (selectedValue) {
            if (typeof selectedValue === 'string') {
                const selectedItem = this.filterInfo.listData.find(
                    (listItem) => {
                        if (listItem.id === selectedValue) {
                            return listItem;
                        }
                    }
                );
                if (selectedItem) {
                    this.valueSelected = selectedItem;
                }
                this.percentage = null;
            } else {
                if (selectedValue[this.dropDownValueKey]) {
                    const selectedItem = this.filterInfo.listData.find(
                        (listItem) => {
                            if (
                                listItem.id ===
                                selectedValue[this.dropDownValueKey]
                            ) {
                                return listItem;
                            }
                        }
                    );
                    if (selectedItem) {
                        this.valueSelected = selectedItem;
                        if (selectedValue[this.percentKey]) {
                            this.percentage = selectedValue[this.percentKey];
                        } else {
                            this.percentage = null;
                        }
                    }
                }
            }
        }
        this.togglePercentageField();
    }

    singleDropdownValueChange(event) {
        this.valueSelected = event;
        this.togglePercentageField();
        this.percentage =
            this.valueSelected && this.valueSelected.defaultPercentage
                ? this.valueSelected.defaultPercentage
                : null;
        this.filterChanged();
    }

    togglePercentageField() {
        if (
            this.valueSelected &&
            this.valueSelected.id &&
            this.filterInfo.disablePercentFor.includes(this.valueSelected.id)
        ) {
            this.disablePercentage = true;
        } else {
            this.disablePercentage = false;
        }
    }

    percentageChanged(value) {
        if (value > 100) {
            value = 100;
        }
        if (value < 0) {
            value = 0;
        }
        this.percentage = +value;
        this.filterChanged();
    }

    filterChanged() {
        if (this.valueSelected && this.valueSelected.id) {
            const value = {
                [this.dropDownValueKey]: this.valueSelected.id,
                [this.percentKey]: this.disablePercentage
                    ? this.valueSelected.defaultPercentage
                        ? this.valueSelected.defaultPercentage
                        : 0
                    : this.percentage
                    ? this.percentage
                    : 0,
            };
            if (this.enableTemp) {
                this.filtersService.filtersDataTemp
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value,
                        filterInfo: this.filterInfo,
                    });
            } else {
                this.filtersService.filtersData
                    .get(this.filterStoreKey)
                    .set(this.filterData.filterId, {
                        value,
                        filterInfo: this.filterInfo,
                    });
            }
            if (
                !this.filtersService.filterChanged.includes(
                    this.filterData.filterId
                )
            ) {
                this.filtersService.filterChanged.push(
                    this.filterData.filterId
                );
            }
        }
    }

    ngOnDestroy(): void {
        this.resetFilter.unsubscribe();
    }
}
