<div class="top-navigation-container">
    <div class="toggle" *ngIf="!sidenav.opened">
        <div
            matTooltip="Toggle Sidebar"
            appIcon
            [data]="sidenav.opened ? crossIcon : toggleIcon"
            class="item clickable header-responsiveness"
            (click)="sidenav.toggle()"
        ></div>
    </div>
    <div
        class="logo"
        [style.display]="
            sidenav.opened && sideMenuType === SideMenuType.DETAILED
                ? 'none'
                : 'inline-flex'
        "
    >
        <div appIcon [data]="logo" class="image"></div>
        <div appIcon [data]="logo" class="mobile-image"></div>
    </div>

    <div class="options">
        <div class="two">
            <div
                *ngIf="
                    assessmentCacheService.assessmentView ||
                    userDataCacheService.isAssessorView ||
                    userDataCacheService.isAssesseeView
                "
                [ngStyle]="
                    (globalDataService.isFrameworkPage | async)
                        ? ''
                        : { 'padding-right': '20px' }
                "
                (click)="goToMainView(shouldPauseNavigation.bind(this))"
                class="returnToConsole"
            >
                <span>Return To Console</span>
            </div>
            <div
                *ngIf="
                    (userDataCacheService.isAssessorView ||
                        assessmentCacheService.assessmentView) &&
                        globalDataService.isFrameworkPage | async
                "
                class="line2"
            ></div>
            <div
                matTooltip="Knowledge Base"
                appIcon
                [data]="knowledgeBaseIcon"
                class="item clickable header-responsiveness"
                *ngIf="whiteLabelService.whiteLableData.knowledgeBaseUrl"
                (click)="
                    Helper.navigateTo(
                        whiteLabelService.whiteLableData.knowledgeBaseUrl,
                        'NEW_PAGE'
                    )
                "
            ></div>
            <!-- <div
                matTooltip="Role List"
                appIcon
                [data]="roleIcon"
                [matMenuTriggerFor]="roleListMenu"
                class="view-list item line magicFont tool clickable"
                *ngIf="globalDataService.roles"
            >
                <mat-menu #roleListMenu="matMenu">
                    <div class="more-info-menu">
                        <div class="heading">Role List</div>
                        <ng-container
                            *ngFor="let roleListItem of globalDataService.roles"
                        >
                            <div
                                (click)="changeRole(roleListItem)"
                                class="info-item"
                                [class.active]="
                                    selectedRole &&
                                    roleListItem.roleName === selectedRole
                                "
                            >
                                {{ roleListItem.roleName }}
                            </div>
                        </ng-container>
                    </div>
                </mat-menu>

            </div> -->
            <div
                class="secondTopLogo"
                *ngIf="whiteLabelService.isSubdomain"
                appIcon
                [data]="poweredCentilyticsLogo"
            ></div>

            <div *ngIf="whiteLabelService.isSubdomain" class="line1"></div>
            <span
                matTooltip="View List"
                appIcon
                [data]="menuStateList ? viewFilledIcon : viewIcon"
                [matMenuTriggerFor]="viewListMenu"
                (menuOpened)="menuStateList = true"
                (menuClosed)="menuStateList = false"
                class="item header-responsiveness clickable"
                *ngIf="views && !userDataCacheService.isAssesseeView"
            >
                <mat-menu
                    #viewListMenu="matMenu"
                    [xPosition]="'before'"
                    class="view-menu"
                >
                    <div
                        class="tw-flex tw-flex-col tw-h-full tw-p-0 more-info-menu"
                        (click)="$event.stopPropagation()"
                    >
                        <div class="heading">List of Views</div>
                        <div class="search-container">
                            <app-table-search
                                [data]="views"
                                (changed)="filteredViews = $event"
                            ></app-table-search>
                        </div>

                        <div class="tw-flex-1 tw-overflow-auto">
                            <ng-container
                                *ngFor="let viewListItem of filteredViews"
                            >
                                <div
                                    (click)="changeView(viewListItem)"
                                    class="info-item"
                                    [class.active]="
                                        globalDataService.selectedView &&
                                        viewListItem.id ===
                                            globalDataService.selectedView
                                    "
                                    appIcon
                                    [data]="
                                        viewListItem['viewIconType']
                                            | iconDataGenerator
                                                : viewListItem['viewIcon']
                                                : viewListItem['name']
                                                : 'inline-fix-box-1 icon-data'
                                    "
                                    [matTooltip]="viewListItem['name']"
                                ></div>
                            </ng-container>
                        </div>
                    </div>
                </mat-menu>
            </span>

            <div
                [matTooltip]="collapseContent ? 'Expand All' : 'Collapse All'"
                appIcon
                [data]="expandIcon"
                (click)="contentVisibilityToggle()"
                class="item clickable header-responsiveness"
                *ngIf="
                    !userDataCacheService.isAssesseeView
                        ? true
                        : (globalDataService.isFrameworkPage | async)
                "
            ></div>
            <ng-container *ngIf="widgetCatalog">
                <div
                    [matTooltip]="widgetCatalog.widgetCatalogResponse.name"
                    appIcon
                    [data]="
                        !widgetCatalogToggle
                            ? widgetCatalogFilledIcon
                            : widgetCatalogIcon
                    "
                    (click)="toggleWidgetCatalog()"
                    *ngIf="!userDataCacheService.isAssesseeView"
                    class="item clickable header-responsiveness"
                ></div>
            </ng-container>

            <div
                class="badge"
                [matBadge]="cartCount ? cartCount : ''"
                [matBadgeColor]="'accent'"
                [matBadgeSize]="'medium'"
                *ngIf="!userDataCacheService.isAssesseeView"
            >
                <div
                    appIcon
                    [data]="cartIcon"
                    (click)="openShoppingCart()"
                    [style.bottom]="cartCount ? '10px' : '0'"
                    class="shopping-cart item line magicFont tool clickable header-responsiveness"
                ></div>
            </div>

            <div
                appIcon
                [data]="menuStateUser ? userFilledIcon : userIcon"
                [matMenuTriggerFor]="userInfoMenu"
                (menuOpened)="menuStateUser = true"
                (menuClosed)="menuStateUser = false"
                class="item header-responsiveness clickable"
                *ngIf="
                    !userDataCacheService.isAssessorView &&
                    !assessmentCacheService.assessmentView
                "
            >
                <mat-menu #userInfoMenu="matMenu">
                    <div class="user-info-container">
                        <div class="name">{{ userDataCacheService.firstName }}</div>
                        <div
                            class="action-buttons"
                            [matTooltip]="
                                GlobalConfiguration.PREVIEW_MODE
                                    ? 'Preview mode does not support logout option'
                                    : null
                            "
                        >
                            <button
                                [disabled]="GlobalConfiguration.PREVIEW_MODE"
                                [style.cursor]="
                                    GlobalConfiguration.PREVIEW_MODE
                                        ? 'not-allowed'
                                        : 'pointer'
                                "
                                mat-button
                                class="clickable btn-primary-stroked"
                                color="primary"
                                (click)="sessionService.logout(true)"
                            >
                                <span
                                    ><i class="fas fa-power-off"></i>
                                    <span> Logout</span></span
                                >
                            </button>
                        </div>
                    </div>
                </mat-menu>
            </div>
            <div
                [matTooltip]="
                    (filterService.filterMenuOpenState | async)
                        ? 'Close Filters'
                        : 'Open Filters'
                "
                appIcon
                [data]="
                    (filterService.filterMenuOpenState | async)
                        ? filterFilledIcon
                        : filterIcon
                "
                (click)="filterMenu.toggle()"
                class="item clickable header-responsiveness"
                *ngIf="
                    !userDataCacheService.isAssesseeView
                        ? true
                        : (globalDataService.isFrameworkPage | async)
                "
            ></div>
        </div>
    </div>
</div>
