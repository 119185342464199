import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as $ from 'jquery';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IntegrationCacheService } from 'src/app/shared/services/cache/integration-cache/integration-cache.service';

@Component({
    selector: 'app-jiradesk-ticket-listing',
    templateUrl: './jiradesk-ticket-listing.component.html',
    styleUrls: ['./jiradesk-ticket-listing.component.sass']
})
export class JiradeskTicketListingComponent implements OnInit, OnDestroy {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    widgetIcon: IIcon = {
        class: 'assets/integrations/jiradesk-mini-logo.png',
        type: IconType.IMAGE
    };
    agGrid: GridOptions = null;

    constructor(
        widgetData: WidgetInjectedData,
        private integrationCacheService: IntegrationCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Case',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    showLoader: false,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        const scriptToRender =
                            this.widgetRef.apiResponse['widgetCode'];
                        const loadedScript =
                            this.integrationCacheService.jiradeskScript;
                        if (loadedScript && loadedScript === scriptToRender) {
                            $('#jsd-widget').show();
                            return;
                        } else if (loadedScript) {
                            window.location.reload();
                            return;
                        }
                        this.loadScript(scriptToRender);
                        this.integrationCacheService.jiradeskScript =
                            scriptToRender;
                    }
                }
            ],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'tickets'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            columns: [
                {
                    columnKey: 'requester',
                    columnName: 'Requester'
                },
                {
                    columnKey: 'subject',
                    columnName: 'Subject'
                },
                {
                    columnKey: 'projectName',
                    columnName: 'Project Name'
                },
                {
                    columnKey: 'priority',
                    columnName: 'Priority'
                },
                {
                    columnKey: 'status',
                    columnName: 'Status'
                },
                {
                    columnKey: 'creationTime',
                    columnName: 'Creation Time'
                }
            ]
        };
    }
    loadScript(widgetCode) {
        const node = document.createElement('script');
        node.type = 'text/javascript';
        node.async = true;
        node.id = 'ji-snippet-injector';
        const attributes = Object.keys(
            $(
                new DOMParser().parseFromString(widgetCode, 'text/html')
                    .firstChild
            )
                .children('head')
                .children('script')[0]['attributes']
        );
        for (let i = 0, length = attributes.length; i < length; ++i) {
            node.setAttribute(
                $(
                    new DOMParser().parseFromString(widgetCode, 'text/html')
                        .firstChild
                )
                    .children('head')
                    .children('script')[0]['attributes'][attributes[i]]['name'],
                $(
                    new DOMParser().parseFromString(widgetCode, 'text/html')
                        .firstChild
                )
                    .children('head')
                    .children('script')[0]['attributes'][attributes[i]]['value']
            );
        }
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => {
            const DOMContentLoaded_event = document.createEvent('Event');
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        };
    }

    ngOnDestroy() {
        const loadedScript = this.integrationCacheService.jiradeskScript;
        if ($('#jsd-widget')) {
            $('#jsd-widget').hide();
        }
    }
}
