<div class="container">
    <div class="tag-dashboard">
        <div class="section widget-list" *ngIf="selectedWidgetsData">
            <div class="heading">Selected {{ keyNames["headingMessage"] }}</div>
            <div class="list">
                <ng-container *ngFor="let widget of selectedWidgetsData">
                    <mat-checkbox
                        [checked]="checkIfChecked(widget)"
                        (change)="onCheckBoxChanged($event, widget)"
                        [value]="widget[keyNames['id']]"
                        >{{ widget[keyNames["names"]] }}</mat-checkbox
                    >
                </ng-container>
            </div>
        </div>

        <div class="upsert-tag">
            <div class="section">
                <div class="heading">Enter Tags</div>
                <app-tag-generator
                    [availableTags]="
                        tagsData && tagsData.length ? tagsData : []
                    "
                    [selectedTags]="
                        commonTags && commonTags.length ? commonTags : []
                    "
                    (tagsDataEmitter)="updateTags($event)"
                ></app-tag-generator>
            </div>
        </div>
    </div>
    <div class="actions">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
