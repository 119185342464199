import { Injectable } from '@angular/core';
import { SessionCacheMain } from 'src/app/shared/classes/SessionCacheMain';

@Injectable({
    providedIn: 'root'
})
export class ApiResponseCacheService extends SessionCacheMain {
    constructor() {
        const API_RESPONSE_CACHE_PREFIX = 'API_RESPONSE_CACHE';
        super(API_RESPONSE_CACHE_PREFIX, true);
    }
}
