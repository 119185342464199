<app-table-generator
    *ngIf="tableGenInput"
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="onGridReady($event)"
    (selectionChanged)="handleSelectionChange()"
></app-table-generator>

<ng-template #deleteTemplate>
    <li class="compliance-list" *ngFor="let data of selectedCompliance">
        {{ data.complianceName }}
    </li>
</ng-template>
