import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { RiUtilizationModalComponent } from '../../../modal-templates/ri-utilization/ri-utilization-modal/ri-utilization-modal.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

enum UtilsationColors {
    GREEN = 'green',
    RED = 'red',
    YELLOW = 'yellow',
    SILVER = 'silver'
}
@Component({
    selector: 'app-ri-description',
    templateUrl: './ri-description.component.html',
    styleUrls: ['./ri-description.component.sass']
})
export class RiDescriptionComponent implements OnInit, AfterViewInit {
    Messages = Messages;
    showSearchBox: boolean = true;
    ViewType = ViewType;
    widgetRef: Widget;
    severityCount = {
        red: 0,
        yellow: 0,
        green: 0,
        silver: 0
    };
    dataMap;
    cardsData: any[] = [];
    filteredCardsData: any[];
    State = State;
    objectkeys = Object.keys;
    accounts;
    keys;
    tableInput: ITableGeneratorInput;
    widgetAction: IAction[];
    tableData: any[];
    gridRef: GridOptions = null;
    severityButtons: IButtonGeneratorInput[];

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);
        if (
            this.widgetRef.widgetConfigDetails &&
            this.widgetRef.widgetConfigDetails.visibleSections
        ) {
            this.widgetRef.visibleSections.next(
                this.widgetRef.widgetConfigDetails.visibleSections
            );
        }
        // Setting Lite state
        if (this.widgetRef.lightState) {
            this.showSearchBox = false;
        }
    }

    bindData(data) {
        if (data === null) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.dataMap = data.dataMap;
        // for view modal
        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];
        if (this.accounts && this.accounts.length === 0 && !data.message) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        if (this.accounts && !this.accounts.length) {
            this.widgetRef.widgetActions.next([]);
        }
        if ('message' in data && data.message) {
            this.widgetRef.extraMessage.next(data.message);
            this.widgetRef.endLoader();
            return;
        }

        this.tableData = this.accounts.map((each) => each['Display Entry']);

        this.generateTableView(data);
        const widgetAction: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.widgetActions.next(widgetAction);

        this.cardsData = this.accounts;
        this.filteredCardsData = this.accounts;
        this.severityCount.red = 0;
        this.severityCount.yellow = 0;
        this.severityCount.green = 0;
        this.severityCount.silver = 0;
        data.dataList.forEach((item) => {
            if (
                this.colorGradient(
                    item['Display Entry']['Consumption (In Percentage)']
                ) === 'red'
            ) {
                this.severityCount.red += 1;
            } else if (
                this.colorGradient(
                    item['Display Entry']['Consumption (In Percentage)']
                ) === 'green'
            ) {
                this.severityCount.green += 1;
            } else if (
                this.colorGradient(
                    item['Display Entry']['Consumption (In Percentage)']
                ) === 'yellow'
            ) {
                this.severityCount.yellow += 1;
            } else {
                this.severityCount.silver += 1;
            }
        });
        this.initButtonGenInputs();
        this.widgetRef.endLoader();
    }

    initButtonGenInputs() {
        const buttonData = {
            Complete: 0,
            Average: 1,
            Low: 2,
            Insufficient: 3
        };
        this.severityButtons = [
            {
                buttonName: `Complete : ${this.severityCount.green}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Complete',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Complete',
                            this.filterRiUtilizationCards.bind(this)
                        )
                    ) {
                        this.filterRiUtilizationCards(UtilsationColors.GREEN);
                    }
                }
            },
            {
                buttonName: `Average : ${this.severityCount.yellow}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: 'Average',
                hoverEffect: 'shadow',
                function: () => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Average',
                            this.filterRiUtilizationCards.bind(this)
                        )
                    ) {
                        this.filterRiUtilizationCards(UtilsationColors.YELLOW);
                    }
                }
            },
            {
                buttonName: `Low : ${this.severityCount.red}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.WARN,
                hoverText: 'Low',
                hoverEffect: 'shadow',
                function: () => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Low',
                            this.filterRiUtilizationCards.bind(this)
                        )
                    ) {
                        this.filterRiUtilizationCards(UtilsationColors.RED);
                    }
                }
            },
            {
                buttonName: `Insufficient : ${this.severityCount.silver}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.DEFAULT,
                hoverText: 'Insufficient',
                hoverEffect: 'shadow',
                function: () => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.severityButtons,
                            'Insufficient',
                            this.filterRiUtilizationCards.bind(this)
                        )
                    ) {
                        this.filterRiUtilizationCards(UtilsationColors.SILVER);
                    }
                }
            }
        ];
    }

    filterRiUtilizationCards(color) {
        this.filteredCardsData = this.accounts;
        if (color) {
            this.filteredCardsData = this.filteredCardsData.filter(
                (item) =>
                    this.colorGradient(
                        item['Display Entry']['Consumption (In Percentage)']
                    ) === color
            );
        }

        this.tableData = this.filteredCardsData.map(
            (each) => each['Display Entry']
        );

        this.cardsData = this.filteredCardsData;
        this.gridRef.api.setRowData(this.tableData);
        this.gridRef.api.redrawRows();
    }

    showRiModal(card) {
        const toggleOnIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-toggle-on',
            extraClass: 'switch'
        };
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Utilization',
            modalIcon: toggleOnIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RiUtilizationModalComponent,
                        payload: {
                            data: { card, dataMap: this.dataMap }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    colorGradient(x) {
        if (!(x === 'unknown')) {
            x = +x;
        }

        if (x >= 0 && x <= 60) {
            return 'red';
        } else if (x > 60 && x <= 90) {
            return 'yellow';
        } else if (x > 90) {
            return 'green';
        } else {
            return 'silver';
        }
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    generateTableView(response) {
        const columns = [];

        const newObject = response['dataList'][0]['Display Entry'];

        Object.keys(newObject).forEach((columnName) => {
            columns.push({
                columnKey: columnName,
                columnName: columnName
            });
        });
        columns.unshift({
            columnKey: 'consumptionlevel',
            columnName: 'Consumption Level',
            filter: false,
            cellRenderer: (rowData: RowEvent) => {
                const viewText = document.createElement('SPAN');

                const colorGradient = this.colorGradient(
                    rowData.data['Consumption (In Percentage)']
                );

                if (colorGradient === 'red') {
                    viewText.innerHTML = 'LOW';
                    viewText.className = 'low';
                } else if (colorGradient === 'green') {
                    viewText.innerHTML = 'COMPLETE';
                    viewText.className = 'complete';
                } else if (colorGradient === 'yellow') {
                    viewText.innerHTML = 'AVERAGE';
                    viewText.className = 'average';
                } else {
                    viewText.innerHTML = 'INSUFFICIENT';
                    viewText.className = 'insufficient';
                }

                const div = document.createElement('DIV');
                div.appendChild(viewText);
                return div;
            }
        });

        columns.push({
            columnKey: 'action',
            columnName: 'Action',
            pinned: 'right',
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData: RowEvent) => {
                const buttons: IButtonGeneratorInput[] = [
                    {
                        buttonName: 'More Info',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,
                        function: () => {
                            this.showRiModal(
                                this.cardsData[rowData.node.rowIndex]
                            );
                        }
                    }
                ];

                rowData['buttonGenInputs'] = buttons;
                return rowData;
            }
        });
        this.tableInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            tableHeight: 300,
            columns
        };
    }
}
