export enum WhitelabelingColorPalette {
    PRIMARY = '--primaryColor',
    ACCENT = '--accentColor',
    ACCENTWITHOPACITY5 = '--accentWithOpacity5',
    ACCENTWITHOPACITY12 = '--accentWithOpacity12',
    DEFAULT_TEXT_COLOR = '--defaultTextColor',
    PRIMARY_BUTTON_COLOR = '--primaryButtonColor',
    SECONDARY_BUTTON_COLOR = '--secondaryButtonColor',
    BACKGROUND_COLOR = '--backgroundColor',
    WIDGET_COLOR = '--widgetColor',
    WIDGET_TEXT_COLOR = '--widgetTextColor',
    AGGREGATE_DEFAULT_COLOR = '--aggregatesDefaultColor',
    AGGREGATE_DEFAULT_TEXT_COLOR = '--aggregatesDefaultTextColor',
    MENU_PRIMARY_COLOR = '--menuPrimaryColor',
    MENU_ACCENT_COLOR = '--menuAccentColor',
    MENU_TEXT_COLOR = '--menuTextColor',
    MENU_HOVER_COLOR = '--menuHoverColor',
    HEADER_COLOR = '--headerColor',
    FOOTER_COLOR = '--footerColor',
    FOOTER_TEXT_COLOR = '--footerTextColor',
    NEGATIVE_COLOR = '--negativeColor',
    POSITEVE_COLOR = '--positiveColor',
    NEUTRAL_COLOR = '--neutralColor',
    //all this below variables will be deleted after domain config.
    WARNING = '--warning',
    PRIMARY_TEXT_COLOR = '--primaryTextColor',
    WIDGET_BACKGROUND = '--insightBackground',
    WIDGET_TEXT = '--insightText',
    NAV_FOOTER_COLOR = '--navFooterColor',
    NAV_FOOTER_TEXT = '--navFooterText',
    MAIN_BACKGROUND = '--mainBackground'
}
