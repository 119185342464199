import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RowEvent, ValueFormatterParams } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AccountStatus } from 'src/app/shared/enums/AccountStatus';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CredentialsCacheService } from 'src/app/shared/services/cache/credentials-cache/credentials-cache.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { CreateCustomerStepOneComponent } from '../../../modal-templates/create-customer/create-customer-step-one/create-customer-step-one.component';
import { CreateCustomerStepThreeComponent } from '../../../modal-templates/create-customer/create-customer-step-three/create-customer-step-three.component';
import { CreateCustomerStepTwoComponent } from '../../../modal-templates/create-customer/create-customer-step-two/create-customer-step-two.component';
import { MasterLoginUsersListComponent } from '../../../modal-templates/master-login/master-login-users-list/master-login-users-list.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { FilterStoreKey } from './../../../../enums/FilterStoreKey';
import { ILoginResponse } from './../../../../interfaces/api/portlets/ILoginResponse';
import { ApiResponseCacheService } from './../../../../services/cache/api-response-cache/api-response-cache.service';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-customers-listing',
    templateUrl: './customers-listing.component.html',
    styleUrls: ['./customers-listing.component.sass']
})
export class CustomersListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    masterLoginIndex = null;

    constructor(
        private widgetData: WidgetInjectedData,
        private sessionService: SessionService,
        private credentialsCacheService: CredentialsCacheService,
        private viewHttpService: ViewHttpService,
        private apiResponseCacheService: ApiResponseCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.tableInput = {
            afterResponse: null,
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            selection: 'single',
            buttons: [
                {
                    buttonName: 'Add Customer',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: this.addCustomer.bind(this),
                    loader: false
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Company Name',
                    columnKey: 'companyName',
                    pinned: 'left'
                },
                {
                    columnName: 'Domain Id',
                    columnKey: 'domainId'
                },
                {
                    columnName: 'Data Retention Months',
                    columnKey: 'dataRetentionMonths'
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        const accountStatus: AccountStatus =
                            rowData['data']['accountStatus'];
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: () => {
                                    // Edit customer
                                    const customerData = rowData['data'];
                                    const moreInfoIcon: IIcon = {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-user-plus'
                                    };
                                    const modalData: IModalData = {
                                        modalName: 'Update Customer',
                                        modalIcon: moreInfoIcon,
                                        sourceId: this.widgetRef.uniqueIdentity,
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh: 90,
                                        modalWidthVw: 70,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        CreateCustomerStepOneComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            customerData
                                                        }
                                                    }
                                                },
                                                stepName: 'CUSTOMER INFORMATION'
                                            },
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        CreateCustomerStepThreeComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            customerData
                                                        }
                                                    }
                                                },
                                                stepName: 'PERMISSIONS'
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            },
                            {
                                buttonName:
                                    accountStatus === AccountStatus.SUSPEND
                                        ? 'undo'
                                        : 'Delete',
                                buttonType: ButtonType.TEXT,
                                buttonColorType:
                                    accountStatus === AccountStatus.SUSPEND
                                        ? ButtonColorType.INFO
                                        : ButtonColorType.WARN,
                                function: () => {
                                    // Use row data to suspend or unsuspend

                                    const data = rowData['data'];
                                    const confirmationModalData: IConfirmationModal =
                                        {
                                            function: (
                                                modalId: Symbol,
                                                formGroup: FormGroup
                                            ) => {
                                                Helper.markAllFieldAsTouched(
                                                    formGroup
                                                );
                                                if (formGroup.invalid) {
                                                    formGroup.updateValueAndValidity();
                                                    return;
                                                } else {
                                                    const apiConf: IHitApi = {
                                                        url: `${
                                                            accountStatus ===
                                                            AccountStatus.SUSPEND
                                                                ? ApiUrls.UNSUSPEND_ACCOUNT
                                                                : ApiUrls.SUSPEND_ACCOUNT
                                                        }${data['tenantId']}`,
                                                        intactUrl: `${
                                                            accountStatus ===
                                                            AccountStatus.SUSPEND
                                                                ? ApiUrls.UNSUSPEND_ACCOUNT
                                                                : ApiUrls.SUSPEND_ACCOUNT
                                                        }{tenantId}`,
                                                        input: {
                                                            reason: formGroup
                                                                .value.reason
                                                        },
                                                        config: {
                                                            authorization:
                                                                AuthorizationType.BEARER_TOKEN
                                                        },
                                                        requestType:
                                                            RequestType.PATCH,
                                                        uniqueIdentity:
                                                            Symbol(),
                                                        function: (
                                                            response
                                                        ) => {
                                                            this.widgetRef.notificationsService.showSnackBar(
                                                                accountStatus ===
                                                                    AccountStatus.SUSPEND
                                                                    ? 'Account unsuspended successfully'
                                                                    : 'Account suspended successfully'
                                                            );
                                                            this.widgetRef.refreshWidget();
                                                            this.widgetRef.modalService.closeModal(
                                                                null,
                                                                modalId
                                                            );
                                                        },
                                                        errorFunction: (
                                                            error
                                                        ) => {
                                                            this.widgetRef.modalService.closeModal(
                                                                null,
                                                                modalId
                                                            );
                                                            this.widgetRef.notificationsService.showSnackBar(
                                                                accountStatus ===
                                                                    AccountStatus.SUSPEND
                                                                    ? 'Error while unsuspending account'
                                                                    : 'Error while suspending account',
                                                                true
                                                            );
                                                        }
                                                    };

                                                    new HitApi(
                                                        apiConf,
                                                        this.widgetRef.httpService,
                                                        this.widgetRef.ngZone
                                                    ).hitApi();
                                                }
                                            },
                                            reason:
                                                accountStatus ===
                                                AccountStatus.SUSPEND
                                                    ? false
                                                    : true,
                                            modalName:
                                                accountStatus ===
                                                AccountStatus.SUSPEND
                                                    ? 'Unsuspend Account'
                                                    : 'Suspend Account',
                                            modalIcon: {
                                                type: IconType.FONTAWSOME,
                                                class:
                                                    accountStatus ===
                                                    AccountStatus.SUSPEND
                                                        ? 'fas fa-undo'
                                                        : 'fas fa-trash'
                                            },
                                            contextIcon: {
                                                extraClass: 'color-accent',
                                                type: IconType.FONTAWSOME,
                                                class: 'fas fa-exclamation-triangle'
                                            },
                                            confirmationMessage: `Are you sure you want to ${
                                                accountStatus ===
                                                AccountStatus.SUSPEND
                                                    ? 'unsuspend'
                                                    : 'suspend'
                                            } account ?`,
                                            buttonText:
                                                accountStatus ===
                                                AccountStatus.SUSPEND
                                                    ? 'Unsuspend'
                                                    : 'Suspend',
                                            buttonColorType:
                                                ButtonColorType.SECONDARY,
                                            loaderOnExec: true
                                        };
                                    const modalData: IModalData = {
                                        modalName:
                                            confirmationModalData.modalName,
                                        modalIcon:
                                            confirmationModalData.modalIcon,
                                        sourceId: Symbol(),
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh:
                                            accountStatus ===
                                            AccountStatus.SUSPEND
                                                ? 30
                                                : 42,
                                        modalWidthVw: 50,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        ConfirmationModalComponent,
                                                    payload: {
                                                        data: {
                                                            function:
                                                                confirmationModalData.function,
                                                            params: confirmationModalData
                                                        }
                                                    }
                                                },
                                                stepName: 'Confirmation'
                                            }
                                        ]
                                    };
                                    this.widgetRef.modalService.openModal(
                                        modalData
                                    );
                                }
                            }
                        ];

                        const buttonOption: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['options'] = buttonOption;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                },
                {
                    columnName: 'Master Login',
                    columnKey: 'masterLogin',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Login',
                                customClass: `master-login-button master-login-button-${rowData.rowIndex} table-button`,
                                buttonIconPrefix: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-user'
                                },
                                showLoader: true,
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.STROKED,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (
                                        rowData['data']['accountStatus'] ===
                                        'UNSUSPEND'
                                    ) {
                                        if (this.masterLoginIndex === null) {
                                            document
                                                .querySelector(
                                                    `.master-login-button-${rowData.rowIndex}`
                                                )
                                                .classList.add('disabled');

                                            this.masterLoginIndex =
                                                rowData.rowIndex;
                                            const tenantId =
                                                rowData.data['tenantId'];
                                            if (tenantId) {
                                                buttonRef.loader = true;
                                                const resetTable = () => {
                                                    buttonRef.loader = false;
                                                    this.widgetRef.changeDetectorRef.detectChanges();
                                                    document
                                                        .querySelector(
                                                            `.master-login-button-${rowData.rowIndex}`
                                                        )
                                                        .classList.remove(
                                                            'disabled'
                                                        );
                                                    this.masterLoginIndex =
                                                        null;
                                                    document
                                                        .querySelector(
                                                            `.master-login-button-${rowData.rowIndex}`
                                                        )
                                                        .classList.remove(
                                                            'disabled'
                                                        );
                                                };
                                                const args: IHitApi = {
                                                    url: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}${tenantId}`,
                                                    intactUrl: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}{tenantId}`,
                                                    input: {},
                                                    requestType:
                                                        RequestType.POST,
                                                    uniqueIdentity: Symbol(),
                                                    config: {
                                                        authorization:
                                                            AuthorizationType.BEARER_TOKEN
                                                    },
                                                    function: (
                                                        users: any[]
                                                    ) => {
                                                        // if (
                                                        //     users &&
                                                        //     users.length === 1
                                                        // ) {
                                                        //     this.userMasterLogin(
                                                        //         users[0],
                                                        //         tenantId,
                                                        //         resetTable
                                                        //     );
                                                        // } else
                                                        if (
                                                            users &&
                                                            users.length
                                                        ) {
                                                            resetTable();

                                                            this.widgetRef.modalService.openModal(
                                                                {
                                                                    modalIcon: {
                                                                        type: IconType.FONTAWSOME,
                                                                        class: 'fas fa-users'
                                                                    },
                                                                    modalName:
                                                                        'Master Login',
                                                                    sourceId:
                                                                        this
                                                                            .widgetRef
                                                                            .uniqueIdentity,
                                                                    modalType:
                                                                        ModalType.MIDDLE,
                                                                    modalSteps:
                                                                        [
                                                                            {
                                                                                stepName:
                                                                                    'Master Login',
                                                                                stepData:
                                                                                    {
                                                                                        componentToLoad:
                                                                                            MasterLoginUsersListComponent,
                                                                                        payload:
                                                                                            {
                                                                                                data: {
                                                                                                    users,
                                                                                                    funcRef:
                                                                                                        this.userMasterLogin.bind(
                                                                                                            this
                                                                                                        ),
                                                                                                    widgetRef:
                                                                                                        this
                                                                                                            .widgetRef,
                                                                                                    tenantId:
                                                                                                        tenantId
                                                                                                }
                                                                                            }
                                                                                    }
                                                                            }
                                                                        ],
                                                                    modalHeightVh: 53,
                                                                    modalWidthVw: 50
                                                                }
                                                            );
                                                        } else {
                                                            Helper.showErrorMessage(
                                                                this.widgetRef
                                                                    .notificationsService,
                                                                null,
                                                                Messages.LOGIN_API_INVALID_RESPONSE
                                                            );
                                                        }
                                                    },
                                                    errorFunction: (error) => {
                                                        buttonRef.loader =
                                                            false;
                                                        this.widgetRef.changeDetectorRef.detectChanges();
                                                        this.masterLoginIndex =
                                                            null;
                                                        document
                                                            .querySelector(
                                                                `.master-login-button-${rowData.rowIndex}`
                                                            )
                                                            .classList.remove(
                                                                'disabled'
                                                            );
                                                        this.widgetRef.notificationsService.showSnackBar(
                                                            error.error.message,
                                                            true
                                                        );
                                                    }
                                                };
                                                new HitApi(
                                                    args,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            }
                                        }
                                    } else {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Your account has been suspended.',
                                            true
                                        );
                                        return;
                                    }
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                },
                {
                    columnName: 'Permanent Delete',
                    columnKey: 'permanentDelete',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const data: AccountStatus = rowData.data;
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Permanent Delete',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-user-times'
                                },
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.ICON,
                                showLoader: false,
                                customClass: 'table-icon-button',
                                function: () => {
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Permanently Delete',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-user-times'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to permanently delete account ?`,
                                        buttonText: 'Delete',
                                        buttonColorType: ButtonColorType.WARN,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            const apiConf: IHitApi = {
                                                url: `${ApiUrls.TENANT_ENDPOINT}${data['tenantId']}`,
                                                intactUrl: `${ApiUrls.TENANT_ENDPOINT}{tenantId}`,
                                                input: {},
                                                config: {
                                                    authorization:
                                                        AuthorizationType.BEARER_TOKEN
                                                },
                                                requestType: RequestType.DELETE,
                                                uniqueIdentity: Symbol(),
                                                function: (response) => {
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        `${data['companyName']} permanently deleted`
                                                    );
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                },
                                                errorFunction: (error) => {
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        `Error deleting ${data['companyName']}`
                                                    );
                                                }
                                            };
                                            new HitApi(
                                                apiConf,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ]
        };
    }

    ngOnInit(): void {}

    addCustomer(buttonRef: IButtonData) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-user-plus'
        };

        const modalData: IModalData = {
            modalName: 'Create Customer',
            modalIcon: moreInfoIcon,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            modalWidthVw: 75,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateCustomerStepOneComponent,
                        payload: null
                    },
                    stepName: 'CUSTOMER INFORMATION'
                },
                {
                    stepData: {
                        componentToLoad: CreateCustomerStepTwoComponent,
                        payload: null
                    },
                    stepName: 'ROOT USER INFORMATION'
                },
                {
                    stepData: {
                        componentToLoad: CreateCustomerStepThreeComponent,
                        payload: null
                    },
                    stepName: 'PERMISSIONS'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    resetFilters() {
        const widgetSymbol = new Set<Symbol>();
        this.widgetRef.filterService.filtersDataTemp.set(
            FilterStoreKey.WEBSITE_FILTERS,
            new Map(this.widgetRef.filterService.filtersDefaultValue)
        );
        this.widgetRef.filterService.convertTempToMain(
            FilterStoreKey.WEBSITE_FILTERS,
            true
        );
        for (const [key, value] of this.widgetRef.filterService.filtersPageData
            .getValue()
            .get(FilterStoreKey.WEBSITE_FILTERS)
            .entries()) {
            value.associatedWidgets.forEach((element) => {
                widgetSymbol.add(element.widgetUniqueIdentifier);
            });
        }
        this.widgetRef.filterService.resetFilters.next(null);
        this.widgetRef.filterService.refreshWidget.next(widgetSymbol);
    }

    userMasterLogin(userData, tenantId, resetFunction) {
        const apiArgs: IHitApi = {
            url: ApiUrls.USER_MASTER_LOGIN,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response: ILoginResponse) => {
                this.apiResponseCacheService.deleteCollection();
                this.credentialsCacheService.masterLoginDiveIn(response);
                this.sessionService.login(
                    response,
                    this.viewHttpService,
                    true,
                    () => {
                        this.widgetRef.notificationsService.showSnackBar(
                            Messages.LOGIN_API_INVALID_RESPONSE,
                            true
                        );
                        this.credentialsCacheService.masterLoginDiveOut();
                    }
                );
            },

            errorFunction: (error) => {
                resetFunction();
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    Messages.LOGIN_API_INVALID_RESPONSE
                );
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{tenant-id}', tenantId);
        apiArgs.url = apiArgs.url.replace('{user-id}', userData['id']);

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
