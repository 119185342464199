import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    WidgetCreationActivityType,
    WidgetCreationService
} from 'src/app/shared/services/widget-creation/widget-creation.service';
import { Helper } from './../../../../classes/Helper';
import { HitApi } from './../../../../classes/HitApi';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { WidgetCreationData } from './../../../../services/widget-creation/widget-creation.service';
import { RequestMediums } from './../../../request-mediums/request-mediums.component';
import { ServiceNowValueMapModalComponent } from './../service-now-value-map-modal/service-now-value-map-modal.component';

@Component({
    selector: 'app-request-widget-integration',
    templateUrl: './request-widget-integration.component.html',
    styleUrls: ['./request-widget-integration.component.sass']
})
export class RequestWidgetIntegrationComponent implements OnInit {
    templateData: any;
    widgetRef: Widget;
    isEdit: boolean = true;
    isPreview: any;
    widgetId: any;
    loaderGroup = {
        saveAsDraft: false,
        create: false,
        serviceNowFields: false
    };

    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    serviceNowFields: any;
    selectedRequestMedium: RequestMediums = null;
    requestMediums = RequestMediums;
    emailFormsTypes = EmailFormsTypes;
    serviceNowFormTypes = ServiceNowFormTypes;

    emailBodyTemplateValues: any = [];
    emailFormsMap: Map<
        EmailFormsTypes,
        { formGenInput: IFormGeneratorInput; formGroupRef: FormGroup }
    > = new Map();

    serviceNowFormsMap: Map<
        ServiceNowFormTypes,
        {
            formGenInput: IFormGeneratorInput;
            formGroupRef: FormGroup;
            onChange?();
        }
    > = new Map();

    formBuilderFields: IDropdownData[];
    serviceNowItemSelected: boolean;
    selectedMediumData: any;
    catalogId: string;
    buttonInputs: IButtonGeneratorInput[];
    buttonOptions: IMultiButtonOption;

    constructor(
        private modalInputData: ModalInjectedData,
        private widgetCreationService: WidgetCreationService,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService,
        private cdRef: ChangeDetectorRef
    ) {
        this.templateData = modalInputData.data['templateData'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.isEdit = modalInputData.data['isEdit'];
        this.isPreview = modalInputData.data['isPreview'];
        this.widgetId = modalInputData.data['widgetId'];

        this.convertFormToListValues();

        this.checkSelectedMedium();
        this.buttonOptions = {
            layout: { justifyContent: 'flex-end' }
        };
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    this.back();
                },
                hostClass: 'mr-auto'
            },
            {
                buttonName: 'Save as Draft',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveAsDraft(buttonRef);
                }
            },
            {
                buttonName: 'Create',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.create(buttonRef);
                }
            }
        ];
    }

    checkSelectedMedium() {
        if (this.templateData) {
            if (this.templateData.extraInfo) {
                if (this.templateData.extraInfo.configuredByEmail) {
                    this.selectedRequestMedium = this.requestMediums.EMAIL;
                    this.selectedMediumData = { name: 'Email' };
                    const emailParsedData = {
                        email: this.templateData.extraInfo.email,
                        subjectMapping: null,
                        subjectMappingValue: null,
                        bodyMappingValue: []
                    };

                    if (
                        this.templateData.form &&
                        this.templateData.form.fields
                    ) {
                        this.templateData.form.fields.map((each) => {
                            if (each.emailBody) {
                                this.formBuilderFields.map((eachField) => {
                                    if (eachField.label === each.label) {
                                        emailParsedData.bodyMappingValue.push(
                                            eachField
                                        );
                                    }
                                });
                            }

                            if (each.subject) {
                                emailParsedData.subjectMapping =
                                    EmailFieldMapping.REQUEST_FORM;

                                const getFieldId = () => {
                                    const field = this.formBuilderFields.find(
                                        (eachField) => {
                                            if (
                                                eachField.label === each.label
                                            ) {
                                                return true;
                                            }
                                        }
                                    );

                                    if (field) {
                                        return field.id;
                                    } else {
                                        return null;
                                    }
                                };

                                emailParsedData.subjectMappingValue =
                                    getFieldId();
                            }
                        });

                        if (this.templateData.form.subject) {
                            emailParsedData.subjectMapping =
                                EmailFieldMapping.SET_DEFAULT;
                            emailParsedData.subjectMappingValue =
                                this.templateData.form.subject;
                        }
                    }

                    this.setupEmailMedium(emailParsedData);
                } else if (this.templateData.extraInfo.configuredByServiceNow) {
                    this.selectedRequestMedium =
                        this.requestMediums.SERVICE_NOW;
                    this.selectedMediumData = { name: 'Service Now' };

                    this.setupServiceNowMedium();
                } else {
                    this.selectedRequestMedium = this.requestMediums.EMAIL;
                    this.selectedMediumData = { name: 'Email' };

                    this.setupEmailMedium();
                }
            } else {
                this.selectedRequestMedium = this.requestMediums.EMAIL;
                this.selectedMediumData = { name: 'Email' };

                this.setupEmailMedium();
            }
        } else {
            this.selectedRequestMedium = this.requestMediums.EMAIL;
            this.selectedMediumData = { name: 'Email' };

            this.setupEmailMedium();
        }
    }

    convertFormToListValues() {
        const form = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(2).form;

        if (form && form.fields && form.fields.length) {
            this.formBuilderFields = form.fields.map((each) => {
                return {
                    id: each.name,
                    label: each.label
                };
            });
        }
    }

    requestMediumOutput(event) {
        if (
            this.selectedMediumData &&
            this.selectedMediumData.name === event.name
        ) {
            return;
        }
        this.selectedMediumData = event;

        switch (event.name) {
            case 'Email':
                this.setupEmailMedium();
                this.serviceNowFormsMap.clear();
                break;

            case 'Service Now':
                this.setupServiceNowMedium();

                break;

            default:
                break;
        }
    }

    setupServiceNowMedium() {
        const catalogApiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.SERVICENOW_CATALOG,
            host: null,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET
        };

        this.serviceNowFormsMap.set(ServiceNowFormTypes.CATALOG, {
            formGenInput: {
                formName: 'catalogMapping',
                submitButton: null,
                state: FormState.CREATE,

                fields: [
                    {
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        name: 'catalogId',
                        placeholder: 'Select Catalog',
                        label: 'Catalog',
                        required: true,
                        appearance: 'legacy',
                        showLabel: true,
                        apiInfo: catalogApiInfo,
                        value:
                            this.templateData &&
                            this.templateData.extraInfo &&
                            this.templateData.extraInfo.catalogId
                                ? this.templateData.extraInfo.catalogId
                                : '',
                        validations: [
                            {
                                errorMessage: 'This field is required.',
                                validator: CustomValidators.required
                            }
                        ]
                    }
                ]
            },
            formGroupRef: null
        });

        this.selectedRequestMedium = RequestMediums.SERVICE_NOW;
    }

    categoryValueChange(event) {
        const categoryId = event.categoryId;
        if (!categoryId) {
            return;
        }

        if (this.serviceNowFormsMap.has(ServiceNowFormTypes.ITEMS)) {
            this.serviceNowFormsMap.delete(ServiceNowFormTypes.ITEMS);
        }
        const finalApiEndpoint = ApiUrls.SERVICENOW_ITEMS.replace(
            '{id}',
            categoryId
        );
        const itemApiInfo: IApiInfo = {
            apiRouteSuffix: finalApiEndpoint.replace(
                '{catalogId}',
                this.catalogId
            ),
            host: null,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET,
            intactUrl: ApiUrls.SERVICENOW_ITEMS
        };

        setTimeout(() => {
            this.serviceNowFormsMap.set(ServiceNowFormTypes.ITEMS, {
                formGenInput: {
                    formName: 'itemsMapping',
                    submitButton: null,
                    state: FormState.CREATE,

                    fields: [
                        {
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            name: 'itemId',
                            placeholder: 'Select Item',
                            label: 'Item',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            apiInfo: itemApiInfo,
                            validations: [
                                {
                                    errorMessage: 'This field is required.',
                                    validator: CustomValidators.required
                                }
                            ],
                            value:
                                this.templateData &&
                                this.templateData.extraInfo &&
                                this.templateData.extraInfo.itemId
                                    ? this.templateData.extraInfo.itemId
                                    : ''
                        }
                    ]
                },
                formGroupRef: null
            });
        }, 0);
    }

    serviceNowItemsValueChange(event) {
        const itemId = event.itemId;

        if (!itemId) {
            this.serviceNowItemSelected = false;
            return;
        }

        if (
            this.serviceNowFormsMap.get(ServiceNowFormTypes.CATEGORY)
                .formGenInput.fields[0].listData === undefined ||
            this.serviceNowFormsMap.get(ServiceNowFormTypes.ITEMS).formGenInput
                .fields[0].listData === undefined
        ) {
            return;
        }

        this.serviceNowFields = [];

        this.serviceNowItemSelected = true;
        this.loaderGroup.serviceNowFields = true;

        const itemApiInfo: IHitApi = {
            url: ApiUrls.SERVICENOW_FIELDS.replace('{item-id}', itemId),
            input: {},
            intactUrl: ApiUrls.SERVICENOW_FIELDS,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            requestType: RequestType.GET,
            function: (response) => {
                if (response && response['result']) {
                    this.parseServiceNowFields(response['result']);
                }

                this.loaderGroup.serviceNowFields = false;
            },
            uniqueIdentity: Symbol(),
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error while getting fields.'
                );
                this.loaderGroup.serviceNowFields = false;
                this.serviceNowFields = [];
            }
        };

        new HitApi(
            itemApiInfo,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    parseServiceNowFields(fields) {
        const serviceNowOtherFields = [
            ServiceNowFieldTypes.TYPE_1,
            ServiceNowFieldTypes.TYPE_27,
            ServiceNowFieldTypes.TYPE_28,
            ServiceNowFieldTypes.TYPE_0,
            ServiceNowFieldTypes.TYPE_3,
            ServiceNowFieldTypes.TYPE_5,
            ServiceNowFieldTypes.TYPE_8,
            ServiceNowFieldTypes.TYPE_10
        ];

        fields.forEach((each) => {
            each.showDefaultForm = true;
            each.showValueMapping = false;

            let selectedValue = '';
            let selectedMapping = null;
            const fieldListData = this.generateServiceNowRequestForm(each);
            if (
                this.templateData &&
                this.templateData.form &&
                this.templateData.form.fields &&
                this.templateData.form.fields.length
            ) {
                this.templateData.form.fields.every((eachForm) => {
                    if (eachForm.mappingType === 'DEFAULT') {
                        if (eachForm.label === each.id) {
                            selectedValue = eachForm.text ? eachForm.text : '';
                            selectedMapping = EmailFieldMapping.SET_DEFAULT;
                            return true;
                        }
                        return true;
                    } else if (
                        eachForm.mappingType === 'FORM' &&
                        eachForm.sysIds &&
                        eachForm.sysIds.length &&
                        eachForm.sysIds.includes(each.id)
                    ) {
                        if (eachForm.mappingType) {
                            if (eachForm.mappingType === 'FORM') {
                                const tmpValue = fieldListData.find(
                                    (each) => each.label === eachForm.label
                                );
                                selectedValue =
                                    tmpValue && tmpValue.id ? tmpValue.id : '';
                                selectedMapping =
                                    EmailFieldMapping.REQUEST_FORM;
                                each.showDefaultForm = false;
                                return false;
                            }

                            return true;
                        }
                        return true;
                    } else {
                        return true;
                    }
                });
            }

            if (selectedMapping === null) {
                selectedMapping = EmailFieldMapping.SET_DEFAULT;
            }

            const mappingForm: IFormGeneratorInput = {
                formName: 'fieldMapping',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.RADIO,
                        name: 'subjectFieldMapping',
                        placeholder: '',
                        label: '',
                        required: true,
                        appearance: 'legacy',
                        value: selectedMapping,
                        listData: [
                            {
                                id: EmailFieldMapping.REQUEST_FORM,
                                label: EmailFieldMapping.REQUEST_FORM,
                                disabled:
                                    this.formBuilderFields &&
                                    this.formBuilderFields.length
                                        ? false
                                        : true
                            },
                            {
                                id: EmailFieldMapping.SET_DEFAULT,
                                label: EmailFieldMapping.SET_DEFAULT
                            }
                        ]
                    }
                ]
            };

            const mappingValueDefaultForm: IFormGeneratorInput = {
                formName: 'fieldMapping',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.TEXT,
                        name: 'emailSubjectValue',
                        placeholder: 'Default value',
                        label: 'Default Value',
                        required: each.mandatory ? each.mandatory : false,
                        value:
                            selectedMapping === EmailFieldMapping.SET_DEFAULT
                                ? selectedValue
                                : '',
                        appearance: 'legacy'
                    }
                ]
            };

            each.mappingForm = mappingForm;
            each.mappingValueDefaultForm = mappingValueDefaultForm;

            if (serviceNowOtherFields.includes(each.type)) {
                if (each.type === ServiceNowFieldTypes.TYPE_1) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: [
                            {
                                fieldType: FilterType.DROPDOWN_SINGLE,
                                name: each.id,
                                placeholder: 'Select Value',
                                label: 'Value',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy',
                                value: selectedValue,
                                listData: [
                                    {
                                        id: 'yes',
                                        label: 'Yes'
                                    },
                                    {
                                        id: 'no',
                                        label: 'No'
                                    }
                                ],
                                validations: each.mandatory
                                    ? [
                                          {
                                              errorMessage:
                                                  'This field is required.',
                                              validator:
                                                  CustomValidators.required
                                          }
                                      ]
                                    : []
                            }
                        ]
                    };
                    each.mappingForm.fields[0].listData[0].disabled = true;
                }

                if (each.type === ServiceNowFieldTypes.TYPE_0) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: [
                            {
                                fieldType: FilterType.DROPDOWN_MULTIPLE,
                                name: each.id,
                                placeholder: 'Select Value',
                                label: 'Value',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy',
                                value: selectedValue,
                                listData: [
                                    {
                                        id: 'true',
                                        label: 'True'
                                    },
                                    {
                                        id: 'false',
                                        label: 'False'
                                    }
                                ],
                                validations: each.mandatory
                                    ? [
                                          {
                                              errorMessage:
                                                  'This field is required.',
                                              validator:
                                                  CustomValidators.required
                                          }
                                      ]
                                    : []
                            }
                        ]
                    };
                    each.mappingForm.fields[0].listData[0].disabled = true;
                }

                if (each.type === ServiceNowFieldTypes.TYPE_27) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        value: selectedValue,
                        fields: [
                            {
                                fieldType: FilterType.URL,
                                name: each.id,
                                placeholder: 'Enter URL',
                                label: 'URL',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy'
                            }
                        ]
                    };
                    each.mappingForm.fields[0].listData[0].disabled = true;
                }

                if (each.type === ServiceNowFieldTypes.TYPE_28) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        value: selectedValue,
                        fields: [
                            {
                                fieldType: FilterType.NUMBER,
                                name: each.id,
                                placeholder: 'Enter Number',
                                label: 'Number',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy'
                            }
                        ]
                    };
                    each.mappingForm.fields[0].listData[0].disabled = true;
                }

                if (
                    each.type === ServiceNowFieldTypes.TYPE_8 ||
                    each.type === ServiceNowFieldTypes.TYPE_3
                ) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: [
                            {
                                fieldType: FilterType.DROPDOWN_SINGLE,
                                name: each.id,
                                placeholder: 'Select Value',
                                label: 'Value',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                value: selectedValue,
                                appearance: 'legacy',
                                apiInfo: {
                                    apiRouteSuffix:
                                        ApiUrls.SERVICENOW_FIELDS_REFERENCE.replace(
                                            '{reference-name}',
                                            each.reference
                                        ),
                                    host: null,
                                    authorization:
                                        AuthorizationType.BEARER_TOKEN,
                                    requestType: RequestType.GET,
                                    intactUrl:
                                        ApiUrls.SERVICENOW_FIELDS_REFERENCE
                                },
                                validations: each.mandatory
                                    ? [
                                          {
                                              errorMessage:
                                                  'This field is required.',
                                              validator:
                                                  CustomValidators.required
                                          }
                                      ]
                                    : []
                            }
                        ]
                    };
                }

                if (each.type === ServiceNowFieldTypes.TYPE_5) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: [
                            {
                                fieldType: FilterType.DROPDOWN_SINGLE,
                                name: each.id,
                                placeholder: 'Select Value',
                                label: 'Value',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy',
                                value: selectedValue,
                                listData: each.choices
                                    ? each.choices.map((choice) => {
                                          return {
                                              id: choice.value,

                                              label: choice.label
                                          };
                                      })
                                    : [],
                                validations: each.mandatory
                                    ? [
                                          {
                                              errorMessage:
                                                  'This field is required.',
                                              validator:
                                                  CustomValidators.required
                                          }
                                      ]
                                    : []
                            }
                        ]
                    };
                }

                if (each.type === ServiceNowFieldTypes.TYPE_10) {
                    each.mappingValueDefaultForm = {
                        formName: each.id,
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: [
                            {
                                fieldType: FilterType.DATE_TIME,
                                name: each.id,
                                value: {
                                    dateValue: selectedValue
                                        ? new Date(Number(selectedValue))
                                        : null,
                                    timeValue: selectedValue
                                        ? new Date(Number(selectedValue))
                                        : null
                                },
                                placeholder: 'Enter date & time',
                                label: 'Date',
                                required: each.mandatory
                                    ? each.mandatory
                                    : false,
                                appearance: 'legacy',
                                validations: each.mandatory
                                    ? [
                                          {
                                              errorMessage:
                                                  'This field is required.',
                                              validator:
                                                  CustomValidators.required
                                          }
                                      ]
                                    : []
                            }
                        ]
                    };
                }
            }
            each.mappingValueRequestForm = {
                formName: each.id,
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        name: 'fieldName',
                        placeholder: 'Select Value',
                        label: 'Value',
                        required: each.mandatory ? each.mandatory : false,
                        appearance: 'legacy',
                        listData: [],
                        value: selectedValue,
                        validations: each.mandatory
                            ? [
                                  {
                                      errorMessage: 'This field is required.',
                                      validator: CustomValidators.required
                                  }
                              ]
                            : []
                    }
                ]
            };
            each.mappingValueRequestForm.fields[0].listData = fieldListData;

            each.mappingValueRequestFormGroupRef = null;
            each.mappingValueDefaultFormGroupRef = null;
            each.mappingFormGroupRef = null;
        });

        this.serviceNowFields = fields;
    }

    generateServiceNowRequestForm(each): IDropdownData[] {
        const dropdownValues: IDropdownData[] = [];
        const fields =
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form.fields
                ? this.multiStepFormService.stepData
                      .get(this.modalInputData.modalId)
                      .get(2).form.fields
                : [];

        if (fields && fields.length) {
            if (!each.formBuilderToServiceNow) {
                each.formBuilderToServiceNow = {};
            }
            if (
                each.type === ServiceNowFieldTypes.TYPE_6 ||
                each.type === ServiceNowFieldTypes.TYPE_2 ||
                each.type === ServiceNowFieldTypes.TYPE_16
            ) {
                fields.map((eachField) => {
                    if (
                        eachField.fieldType === FilterType.SHORT_TEXT ||
                        eachField.fieldType === FilterType.TEXTAREA ||
                        eachField.fieldType === FilterType.NUMBER
                    ) {
                        dropdownValues.push({
                            id: eachField.name,
                            label: eachField.label
                        });

                        each.formBuilderFieldLabel = eachField.label;
                    }
                });
            }

            if (each.type === ServiceNowFieldTypes.TYPE_10) {
                fields.map((eachField) => {
                    if (eachField.fieldType === FilterType.DATE_TIME) {
                        dropdownValues.push({
                            id: eachField.name,
                            label: eachField.label
                        });
                        each.formBuilderFieldLabel = eachField.label;
                    }
                });
            }

            if (
                each.type === ServiceNowFieldTypes.TYPE_3 ||
                each.type === ServiceNowFieldTypes.TYPE_5 ||
                each.type === ServiceNowFieldTypes.TYPE_8 ||
                each.type === ServiceNowFieldTypes.TYPE_31
            ) {
                fields.map((eachField) => {
                    if (eachField.fieldType === FilterType.DROPDOWN_SINGLE) {
                        dropdownValues.push({
                            id: eachField.name,
                            label: eachField.label
                        });
                        each.formBuilderDropdownValues = eachField.listData;
                        each.formBuilderToServiceNow[eachField.name] =
                            eachField.listData;
                        each.formBuilderFieldLabel = eachField.label;
                    }
                });
            }

            if (
                each.type === ServiceNowFieldTypes.TYPE_3 ||
                each.type === ServiceNowFieldTypes.TYPE_5
            ) {
                fields.map((eachField) => {
                    if (eachField.fieldType === FilterType.DROPDOWN_MULTIPLE) {
                        dropdownValues.push({
                            id: eachField.name,
                            label: eachField.label
                        });

                        each.formBuilderDropdownValues = eachField.listData;
                        each.formBuilderToServiceNow[eachField.name] =
                            eachField.listData;
                        each.formBuilderFieldLabel = eachField.label;
                    }
                });
            }
        }

        return dropdownValues;
    }

    serviceNowMappingValueChange(value, index) {
        if (value && value.subjectFieldMapping) {
            if (value.subjectFieldMapping === EmailFieldMapping.REQUEST_FORM) {
                this.serviceNowFields[index].showDefaultForm = false;
            }

            if (value.subjectFieldMapping === EmailFieldMapping.SET_DEFAULT) {
                this.serviceNowFields[index].showDefaultForm = true;
                this.serviceNowFields[index].showValueMapping = false;
            }
        }
    }

    setupEmailMedium(valueData?) {
        this.emailFormsMap.set(EmailFormsTypes.EMAIL_ADDRESS, {
            formGenInput: {
                formName: 'emailInputForm',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.MATCHIPLIST,
                        name: 'email',
                        placeholder: 'Email address',
                        label: 'Email',
                        required: true,
                        value: valueData?.email?.split(',') || [],
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage:
                                    'Atleast one recipients is required'
                            },
                            {
                                validator: CustomValidators.matChipAllowEmail,
                                errorMessage: 'Input a valid email address'
                            }
                        ]
                    }
                ]
            },
            formGroupRef: null
        });

        this.emailFormsMap.set(EmailFormsTypes.SUBJECT_FIELD_MAPPING, {
            formGenInput: {
                formName: 'fieldMapping',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.RADIO,
                        name: 'subjectFieldMapping',
                        placeholder: '',
                        label: '',
                        required: true,
                        appearance: 'legacy',
                        value: valueData
                            ? valueData.subjectMapping
                            : EmailFieldMapping.SET_DEFAULT,
                        listData: [
                            {
                                id: EmailFieldMapping.REQUEST_FORM,
                                label: EmailFieldMapping.REQUEST_FORM,
                                disabled:
                                    this.formBuilderFields &&
                                    this.formBuilderFields.length
                                        ? false
                                        : true
                            },
                            {
                                id: EmailFieldMapping.SET_DEFAULT,
                                label: EmailFieldMapping.SET_DEFAULT
                            }
                        ]
                    }
                ]
            },
            formGroupRef: null
        });

        if (this.formBuilderFields && this.formBuilderFields.length) {
            const data = {};
            data['subjectFieldMapping'] = EmailFieldMapping.REQUEST_FORM;
            this.fieldMappingEmailChange(
                data,
                valueData ? valueData.subjectMappingValue : ''
            );
        } else {
            const data = {};
            data['subjectFieldMapping'] = EmailFieldMapping.SET_DEFAULT;
            this.fieldMappingEmailChange(
                data,
                valueData ? valueData.subjectMappingValue : ''
            );
        }

        this.emailFormsMap.set(EmailFormsTypes.BODY_FIELD_MAPPING, {
            formGenInput: {
                formName: 'bodyMapping',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.RADIO,
                        name: 'bodyFieldMapping',
                        placeholder: '',
                        label: '',
                        required: true,
                        appearance: 'legacy',

                        listData: [
                            {
                                id: EmailFieldMapping.REQUEST_FORM,
                                label: EmailFieldMapping.REQUEST_FORM
                            }
                        ],
                        value: EmailFieldMapping.REQUEST_FORM
                    }
                ]
            },
            formGroupRef: null
        });

        this.emailFormsMap.set(EmailFormsTypes.BODY_VALUE, {
            formGenInput: {
                formName: 'fieldMapping',
                submitButton: null,
                state: FormState.CREATE,
                fields: [
                    {
                        fieldType: FilterType.DROPDOWN_MULTIPLE,
                        name: 'emailBodyMappingValue',
                        placeholder: 'Default value',
                        label: 'Default Value',
                        required: false,
                        appearance: 'legacy',
                        value:
                            valueData && valueData.bodyMappingValue
                                ? valueData.bodyMappingValue
                                : [],
                        listData: this.formBuilderFields,
                        getKey: null
                    }
                ]
            },
            formGroupRef: null
        });

        this.selectedRequestMedium = RequestMediums.EMAIL;
    }

    bodyFieldValueChanges(event) {
        if (
            event &&
            event.emailBodyMappingValue &&
            event.emailBodyMappingValue.length
        ) {
            this.emailBodyTemplateValues = event.emailBodyMappingValue.map(
                (each) => {
                    if (typeof each === 'string') {
                        return each;
                    } else {
                        return each.label;
                    }
                }
            );
        } else {
            this.emailBodyTemplateValues = [];
        }
    }

    fieldMappingEmailChange(event, defaultValue?) {
        let field: IFormField[] = [];
        if (event && event['subjectFieldMapping']) {
            if (
                event['subjectFieldMapping'] === EmailFieldMapping.SET_DEFAULT
            ) {
                field = [
                    {
                        fieldType: FilterType.TEXT,
                        name: 'emailSubjectValue',
                        placeholder: 'Default value',
                        label: 'Default Value',
                        required: true,
                        value: defaultValue ? defaultValue : '',
                        appearance: 'legacy'
                    }
                ];
            } else if (
                event['subjectFieldMapping'] === EmailFieldMapping.REQUEST_FORM
            ) {
                field = [
                    {
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        name: 'emailSubjectValue',
                        placeholder: 'Default value',
                        label: 'Default Value',
                        required: true,
                        appearance: 'legacy',
                        value: defaultValue ? defaultValue : '',
                        listData: this.formBuilderFields
                    }
                ];
            }
            if (this.emailFormsMap.has(EmailFormsTypes.SUBJECT_VALUE)) {
                this.emailFormsMap.delete(EmailFormsTypes.SUBJECT_VALUE);
            }
            setTimeout(() => {
                this.emailFormsMap.set(EmailFormsTypes.SUBJECT_VALUE, {
                    formGenInput: {
                        formName: 'fieldMapping',
                        submitButton: null,
                        state: FormState.CREATE,
                        fields: field
                    },
                    formGroupRef: null
                });
            }, 0);
        }
    }

    ngOnInit(): void {}

    create(buttonRef: IButtonGeneratorInput) {
        if (this.loaderGroup.create) {
            return;
        }

        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        buttonRef.loader = true;

        const dataForApi = this.prepareData();

        dataForApi.extraInfo.activityType =
            WidgetCreationActivityType.WIDGET_PUBLISHED;

        this.widgetCreationService.saveAsDraft(
            dataForApi,
            () => {
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            () => {
                buttonRef.loader = false;
            },
            this.widgetId
        );
    }

    saveAsDraft(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        if (!this.checkDataValidityAndSetData()) {
            return;
        }

        buttonRef.loader = true;

        const dataForApi = this.prepareData();

        this.widgetCreationService.saveAsDraft(
            dataForApi,
            () => {
                buttonRef.loader = true;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            () => {
                buttonRef.loader = true;
            },
            this.widgetId
        );
    }

    checkDataValidityAndSetData(): boolean {
        let isValid = true;

        if (this.selectedRequestMedium === RequestMediums.EMAIL) {
            this.emailFormsMap.forEach((value, key) => {
                value.formGroupRef.markAllAsTouched();
                if (value.formGroupRef.invalid) {
                    isValid = false;
                }
            });
        }

        if (this.selectedRequestMedium === RequestMediums.SERVICE_NOW) {
            if (
                this.serviceNowFormsMap.get(this.serviceNowFormTypes.CATEGORY)
                    .formGroupRef.invalid
            ) {
                this.serviceNowFormsMap
                    .get(this.serviceNowFormTypes.CATEGORY)
                    .formGroupRef.markAllAsTouched();
                isValid = false;

                if (
                    this.serviceNowFormsMap.has(
                        this.serviceNowFormTypes.ITEMS
                    ) &&
                    this.serviceNowFormsMap.get(this.serviceNowFormTypes.ITEMS)
                        .formGroupRef.invalid
                ) {
                    this.serviceNowFormsMap
                        .get(this.serviceNowFormTypes.ITEMS)
                        .formGroupRef.markAllAsTouched();
                    isValid = false;
                }
            }
            if (this.serviceNowFields) {
                this.serviceNowFields.forEach((each) => {
                    if (each.mandatory) {
                        if (each.showDefaultForm) {
                            if (each.mappingValueDefaultFormGroupRef.invalid) {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    null,
                                    '* marked fields are mandatory to map.'
                                );

                                each.mappingValueDefaultFormGroupRef.markAllAsTouched();
                                this.cdRef.detectChanges();
                                isValid = false;
                            }

                            if (each.type === ServiceNowFieldTypes.TYPE_10) {
                                let isDateValid = true;
                                const textValue =
                                    each.mappingValueDefaultFormGroupRef;
                                const date =
                                    textValue && textValue.dateValue
                                        ? textValue.dateValue
                                        : null;
                                const time =
                                    textValue && textValue.timeValue
                                        ? textValue.timeValue
                                        : null;

                                if (date || time) {
                                    if (!date) {
                                        isDateValid = false;
                                    }

                                    if (!time) {
                                        isDateValid = false;
                                    }
                                }

                                if (!isDateValid) {
                                    Helper.showErrorMessage(
                                        this.widgetRef.notificationsService,
                                        null,
                                        'Please input correct date and time'
                                    );
                                    isValid = false;
                                }
                            }
                        } else {
                            if (each.mappingValueRequestFormGroupRef.invalid) {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    null,
                                    '* marked fields are mandatory to map.'
                                );
                                each.mappingValueRequestFormGroupRef.markAllAsTouched();
                                isValid = false;
                            } else {
                                if (
                                    each.type === ServiceNowFieldTypes.TYPE_3 ||
                                    each.type === ServiceNowFieldTypes.TYPE_5 ||
                                    each.type === ServiceNowFieldTypes.TYPE_8
                                ) {
                                    if (!each.dropdownValueMapped) {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            null,
                                            'Value mapping for * marked fields are mandatory.'
                                        );
                                        isValid = false;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        return isValid;
    }

    prepareData() {
        if (this.selectedRequestMedium === RequestMediums.EMAIL) {
            const dataForApi: WidgetCreationData = {
                ...this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(1),
                ...this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(2)
            };

            dataForApi.extraInfo.email = this.emailFormsMap
                .get(EmailFormsTypes.EMAIL_ADDRESS)
                .formGroupRef.value.email?.join(',');

            if (
                dataForApi.form &&
                dataForApi.form.fields &&
                dataForApi.form.fields.length
            ) {
                dataForApi.form.fields.forEach((each) => {
                    each['emailBody'] = false;
                    if (
                        each.name ===
                        this.emailFormsMap.get(EmailFormsTypes.SUBJECT_VALUE)
                            .formGroupRef.value.emailSubjectValue
                    ) {
                        each['subject'] = true;
                    } else {
                        each['subject'] = false;
                    }

                    if (
                        this.emailFormsMap.get(EmailFormsTypes.BODY_VALUE)
                            .formGroupRef.value.emailBodyMappingValue &&
                        this.emailFormsMap.get(EmailFormsTypes.BODY_VALUE)
                            .formGroupRef.value.emailBodyMappingValue.length
                    ) {
                        this.emailFormsMap
                            .get(EmailFormsTypes.BODY_VALUE)
                            .formGroupRef.value.emailBodyMappingValue.map(
                                (eachDropdown) => {
                                    if (eachDropdown.label === each.label) {
                                        each['emailBody'] = true;
                                    }
                                }
                            );
                    } else {
                        each['emailBody'] = false;
                    }
                });
            }

            if (!dataForApi.form) {
                dataForApi.form = {
                    formName: 'Email'
                };
            }

            dataForApi.form['body'] = null;
            dataForApi.form['subject'] =
                this.emailFormsMap.get(EmailFormsTypes.SUBJECT_FIELD_MAPPING)
                    .formGroupRef.value.subjectFieldMapping ===
                EmailFieldMapping.SET_DEFAULT
                    ? this.emailFormsMap.get(EmailFormsTypes.SUBJECT_VALUE)
                          .formGroupRef.value.emailSubjectValue
                    : null;

            dataForApi.extraInfo.configuredByEmail = true;
            dataForApi.extraInfo.configuredByServiceNow = false;
            dataForApi.extraInfo.serviceNowIntegrationId = null;

            return dataForApi;
        }

        if (this.selectedRequestMedium === RequestMediums.SERVICE_NOW) {
            const dataForApi: WidgetCreationData = {
                ...this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(1),
                ...this.multiStepFormService.stepData
                    .get(this.modalInputData.modalId)
                    .get(2)
            };

            if (!dataForApi.form) {
                dataForApi.form = {
                    formName: 'Service now',
                    fields: []
                };
            }

            dataForApi.extraInfo.configuredByEmail = false;
            dataForApi.extraInfo.serviceNowIntegrationId =
                this.selectedMediumData.integrationId;

            dataForApi.extraInfo.categoryId = this.serviceNowFormsMap.get(
                ServiceNowFormTypes.CATEGORY
            ).formGroupRef.value['categoryId'];
            dataForApi.extraInfo.itemId = this.serviceNowFormsMap.get(
                ServiceNowFormTypes.ITEMS
            ).formGroupRef.value['itemId'];
            dataForApi.extraInfo.configuredByServiceNow = true;
            dataForApi.extraInfo.catalogId = this.serviceNowFormsMap.get(
                ServiceNowFormTypes.CATALOG
            ).formGroupRef.value['catalogId'];
            if (this.serviceNowFields) {
                this.serviceNowFields.forEach((each) => {
                    if (each.showDefaultForm) {
                        let textValue;

                        if (
                            each.mappingValueDefaultFormGroupRef.value &&
                            each.mappingValueDefaultFormGroupRef.value[each.id]
                        ) {
                            textValue =
                                each.mappingValueDefaultFormGroupRef.value[
                                    each.id
                                ];
                        } else if (
                            each.mappingValueDefaultFormGroupRef.value &&
                            each.mappingValueDefaultFormGroupRef.value[
                                'emailSubjectValue'
                            ]
                        ) {
                            textValue =
                                each.mappingValueDefaultFormGroupRef.value[
                                    'emailSubjectValue'
                                ];
                        }

                        if (each.type === ServiceNowFieldTypes.TYPE_10) {
                            const date =
                                textValue && textValue.dateValue
                                    ? textValue.dateValue
                                    : null;
                            const time =
                                textValue && textValue.timeValue
                                    ? textValue.timeValue
                                    : null;

                            textValue = moment(
                                moment(date).format('YYYY-MM-DD') + ' ' + time
                            ).valueOf();
                        }

                        const formData = {
                            sysIds: [each.id],
                            label: each.id,
                            required: each.mandatory,
                            fieldType: FilterType.HIDDEN,
                            text: !Array.isArray(textValue) ? textValue : '',
                            mappingType: 'DEFAULT'
                        };
                        if (each.type === ServiceNowFieldTypes.TYPE_0) {
                            formData['listOfData'] = textValue;
                        }
                        dataForApi.form.fields.push(formData);
                    } else {
                        dataForApi.form.fields.forEach((eachForm) => {
                            if (
                                eachForm.name ===
                                each.mappingValueRequestFormGroupRef.value[
                                    'fieldName'
                                ]
                            ) {
                                eachForm.mappingType = 'FORM';

                                if (eachForm.sysIds) {
                                    eachForm.sysIds.push(each.id);
                                } else {
                                    eachForm.sysIds = [each.id];
                                }
                            }
                        });
                    }
                });

                dataForApi.form['body'] = null;
                dataForApi.form['subject'] = null;
            }
            return dataForApi;
        }
    }

    back() {
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }

    serviceNowRequestFormValueChange(event, serviceNowField) {
        if (
            event['fieldName'] &&
            (serviceNowField.type === ServiceNowFieldTypes.TYPE_3 ||
                serviceNowField.type === ServiceNowFieldTypes.TYPE_5 ||
                serviceNowField.type === ServiceNowFieldTypes.TYPE_8)
        ) {
            serviceNowField.showValueMapping = true;
        } else {
            serviceNowField.showValueMapping = false;
        }
    }

    openValueMappingModal(serviceNowField, selectedDropdownField) {
        const selectedField = {};

        selectedField['name'] = selectedDropdownField.fieldName;
        selectedField['values'] =
            serviceNowField.formBuilderToServiceNow[
                selectedDropdownField.fieldName
            ];

        const fields =
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2).form.fields
                ? this.multiStepFormService.stepData
                      .get(this.modalInputData.modalId)
                      .get(2).form.fields
                : [];

        selectedField['label'] = fields.filter(
            (each) => each.name === selectedDropdownField.fieldName
        )[0].label;

        const categoryApiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.SERVICENOW_FIELDS_REFERENCE.replace(
                '{reference-name}',
                serviceNowField.reference
            ),
            host: null,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET,
            intactUrl: ApiUrls.SERVICENOW_FIELDS_REFERENCE
        };

        const templateData = {
            ...this.templateData,
            serviceNowValueApi: categoryApiInfo,
            listData:
                serviceNowField.choices && serviceNowField.choices.length
                    ? serviceNowField.choices.map((each) => {
                          return {
                              id: each.value,
                              label: each.label
                          };
                      })
                    : null
        };

        const modalData: IModalData = {
            modalName: 'Mapping (Form Field with Service Now Field)',
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 50,
            modalAutoHeight: true,
            hideSteps: true,
            disableClose: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ServiceNowValueMapModalComponent,
                        payload: {
                            data: {
                                templateData,
                                widgetRef: this.widgetRef,
                                isEdit: this.isEdit,
                                isPreview: this.isPreview,
                                widgetId: this.widgetId,
                                serviceNowField: serviceNowField,
                                selectedField: selectedField,
                                callback: (valueMapping, modalId) => {
                                    serviceNowField.dropdownValueMapped = true;

                                    const fields =
                                        this.multiStepFormService.stepData
                                            .get(this.modalInputData.modalId)
                                            .get(2).form &&
                                        this.multiStepFormService.stepData
                                            .get(this.modalInputData.modalId)
                                            .get(2).form.fields
                                            ? this.multiStepFormService.stepData
                                                  .get(
                                                      this.modalInputData
                                                          .modalId
                                                  )
                                                  .get(2).form.fields
                                            : [];

                                    fields.every((element) => {
                                        if (
                                            element.name ===
                                            selectedDropdownField.fieldName
                                        ) {
                                            if (
                                                element.sysIds &&
                                                element.sysIds.length
                                            ) {
                                                if (
                                                    !element.sysIds.includes(
                                                        serviceNowField.id
                                                    )
                                                ) {
                                                    element.sysIds.push(
                                                        serviceNowField.id
                                                    );

                                                    if (
                                                        element.listData &&
                                                        element.listData.length
                                                    ) {
                                                        element.listData = [
                                                            ...element.listData,
                                                            ...valueMapping
                                                        ];
                                                    }
                                                }
                                            } else {
                                                element.sysIds = [];
                                                element.sysIds.push(
                                                    serviceNowField.id
                                                );
                                                element.listData = valueMapping;
                                            }

                                            return false;
                                        }

                                        return true;
                                    });
                                    this.multiStepFormService.stepData
                                        .get(this.modalInputData.modalId)
                                        .get(2).form.fields = fields;
                                    this.modalService.closeModal(null, modalId);
                                }
                            }
                        }
                    },
                    stepName: 'Mapping (Form Field with Service Now Field)'
                }
            ]
        };

        this.modalService.openModal(modalData);
    }

    catalogValueChange(event) {
        const catalogId = event.catalogId;
        this.catalogId = catalogId;

        if (!catalogId) {
            return;
        }

        if (this.serviceNowFormsMap.has(ServiceNowFormTypes.CATEGORY)) {
            this.serviceNowFormsMap.delete(ServiceNowFormTypes.CATEGORY);
        }

        const categoryApiInfo: IApiInfo = {
            apiRouteSuffix: ApiUrls.SERVICENOW_CATEGORY.replace(
                '{id}',
                catalogId
            ),
            host: null,
            authorization: AuthorizationType.BEARER_TOKEN,
            requestType: RequestType.GET,
            intactUrl: ApiUrls.SERVICENOW_CATEGORY
        };

        setTimeout(() => {
            this.serviceNowFormsMap.set(ServiceNowFormTypes.CATEGORY, {
                formGenInput: {
                    formName: 'categoryMapping',
                    submitButton: null,
                    state: FormState.CREATE,

                    fields: [
                        {
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            name: 'categoryId',
                            placeholder: 'Select Item',
                            label: 'Category',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            apiInfo: categoryApiInfo,
                            validations: [
                                {
                                    errorMessage: 'This field is required.',
                                    validator: CustomValidators.required
                                }
                            ],
                            value:
                                this.templateData &&
                                this.templateData.extraInfo &&
                                this.templateData.extraInfo.categoryId
                                    ? this.templateData.extraInfo.categoryId
                                    : ''
                        }
                    ]
                },
                formGroupRef: null
            });
        }, 0);
    }
}

export enum EmailFieldMapping {
    REQUEST_FORM = 'Request Form',
    SET_DEFAULT = 'Set Default'
}

export enum EmailFormsTypes {
    EMAIL_ADDRESS = 'Email Address',
    SUBJECT_FIELD_MAPPING = 'Subject Field Mapping',
    BODY_FIELD_MAPPING = 'Body Field Mapping',
    SUBJECT_VALUE = 'Subject Value',
    BODY_VALUE = 'Body Value'
}

export enum ServiceNowFormTypes {
    CATALOG = 'Catalog',
    CATEGORY = 'Category',
    ITEMS = 'Items'
}

export enum ServiceNowFieldTypes {
    TYPE_6 = 6,
    TYPE_2 = 2,
    TYPE_16 = 16,
    TYPE_10 = 10,
    TYPE_3 = 3,
    TYPE_5 = 5,
    TYPE_31 = 31,
    TYPE_1 = 1,
    TYPE_27 = 27,
    TYPE_28 = 28,
    TYPE_8 = 8,
    TYPE_0 = 0
}
