import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class UserDataCacheService extends CacheMain {
    static readonly EMAIL_ID = 'EMAIL_ID';
    static readonly FIRST_NAME = 'FIRST_NAME';
    static readonly LAST_NAME = 'LAST_NAME';
    static readonly COMPANY_NAME = 'COMPANY_NAME';
    static readonly IS_ASSESSEE_VIEW = 'IS_ASSESSEE_VIEW';
    static readonly IS_ASSESSOR_VIEW = 'IS_ASSESSOR_VIEW';
    static readonly MFA_STATUS = 'MFA_STATUS';
    static readonly ROOT_USER = 'ROOT_USER';
    static readonly DOMAIN_ID = 'DOMAIN_ID';
    static readonly ALLOW_DOMAIN_BASED_USERS = 'ALLOW_DOMAIN_BASED_USERS';
    static readonly PRIVACY_POLICY_FILE = 'PRIVACY_POLICY_FILE';
    static readonly TERMS_OF_SERVICES_FILE = 'TERMS_OF_SERVICES_FILE';
    static readonly SHOW_LICENSE_AGREEMENT = 'SHOW_LICENSE_AGREEMENT';

    constructor() {
        const USER_DATA_CACHE_PREFIX = 'USER_DATA_CACHE';
        super(USER_DATA_CACHE_PREFIX, true);
    }

    get emailId(): string {
        return this.fetch(UserDataCacheService.EMAIL_ID);
    }

    set emailId(emailId: string) {
        this.store(UserDataCacheService.EMAIL_ID, emailId);
    }

    get firstName(): string {
        return this.fetch(UserDataCacheService.FIRST_NAME);
    }

    set firstName(firstName: string) {
        this.store(UserDataCacheService.FIRST_NAME, firstName);
    }
    get companyName(): string {
        return this.fetch(UserDataCacheService.COMPANY_NAME);
    }
    set companyName(companyName: string) {
        this.store(UserDataCacheService.COMPANY_NAME, companyName);
    }

    get lastName(): string {
        return this.fetch(UserDataCacheService.LAST_NAME);
    }

    set lastName(lastName: string) {
        this.store(UserDataCacheService.LAST_NAME, lastName);
    }
    get isAssesseeView(): boolean {
        return this.fetch(UserDataCacheService.IS_ASSESSEE_VIEW);
    }

    set isAssesseeView(isAssesseeView: boolean) {
        this.store(UserDataCacheService.IS_ASSESSEE_VIEW, isAssesseeView);
    }
    get isAssessorView(): boolean {
        return this.fetch(UserDataCacheService.IS_ASSESSOR_VIEW);
    }

    set isAssessorView(isAssessorView: boolean) {
        this.store(UserDataCacheService.IS_ASSESSOR_VIEW, isAssessorView);
    }
    get mfaStatus(): boolean {
        return this.fetch(UserDataCacheService.MFA_STATUS);
    }

    set mfaStatus(mfaStatus: boolean) {
        this.store(UserDataCacheService.MFA_STATUS, mfaStatus);
    }
    get rootUser(): boolean {
        return this.fetch(UserDataCacheService.ROOT_USER);
    }

    set rootUser(rootUser: boolean) {
        this.store(UserDataCacheService.ROOT_USER, rootUser);
    }

    get domainId(): string {
        return this.fetch(UserDataCacheService.DOMAIN_ID);
    }

    set domainId(domainId: string) {
        this.store(UserDataCacheService.DOMAIN_ID, domainId);
    }
    get allowDomainBasedUsers(): boolean {
        return this.fetch(UserDataCacheService.ALLOW_DOMAIN_BASED_USERS);
    }
    set allowDomainBasedUsers(domainBasedUserValue: boolean) {
        this.store(
            UserDataCacheService.ALLOW_DOMAIN_BASED_USERS,
            domainBasedUserValue
        );
    }

    get privacyPolicyFile(): string {
        return this.fetch(UserDataCacheService.PRIVACY_POLICY_FILE);
    }
    set privacyPolicyFile(privacyPolicyFile: string) {
        this.store(UserDataCacheService.PRIVACY_POLICY_FILE, privacyPolicyFile);
    }

    get termsOfServicesFile(): string {
        return this.fetch(UserDataCacheService.TERMS_OF_SERVICES_FILE);
    }
    set termsOfServicesFile(termsOfServicesFile: string) {
        this.store(
            UserDataCacheService.TERMS_OF_SERVICES_FILE,
            termsOfServicesFile
        );
    }

    get showLicenseAgreement(): boolean {
        return this.fetch(UserDataCacheService.SHOW_LICENSE_AGREEMENT);
    }
    set showLicenseAgreement(showLicenseAgreement: boolean) {
        this.store(
            UserDataCacheService.SHOW_LICENSE_AGREEMENT,
            showLicenseAgreement
        );
    }
}
