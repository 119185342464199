<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row toggle {{ field.extraClass }}">
        <div class="toggle-header">
            <span>
                {{ field.label }}
            </span>
        </div>

        <div class="toggle-body">
            <mat-button-toggle-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                appearance="legacy"
                aria-label="Font Style"
            >
                <ng-container *ngFor="let value of field.listData">
                    <mat-button-toggle [value]="value.id + ''">
                        {{ value.label }}
                    </mat-button-toggle>
                </ng-container>
            </mat-button-toggle-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row toggle {{ field.extraClass }}">
        <div class="toggle-body">
            <mat-button-toggle-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                appearance="legacy"
                aria-label="Font Style"
            >
                <ng-container *ngFor="let value of field.listData">
                    <mat-button-toggle [value]="value.id + ''">
                        {{ value.label }}
                    </mat-button-toggle>
                </ng-container>
            </mat-button-toggle-group>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>
<!--Field type four end -->

<!--Field type five start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_5">
    <div class="form-row toggle-filter {{ field.extraClass }}">
        <div class="toggle-body">
            <mat-button-toggle-group
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (change)="valueChanged()"
                appearance="legacy"
                aria-label="Font Style"
            >
                <ng-container *ngFor="let value of field.listData">
                    <mat-button-toggle [value]="value.id + ''">
                        {{ value.label }}
                    </mat-button-toggle>
                </ng-container>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-container>
<!--Field type five end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation: formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
