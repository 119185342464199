import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as $ from 'jquery';
import { SubSink } from 'subsink';
import { Helper } from '../../classes/Helper';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { FilterType } from '../../enums/FilterType';
import { IconType } from '../../enums/IconType';
import { TraversalPurpose } from '../../enums/TraversalPurpose';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IModalData } from '../../interfaces/modal/IModalData';
import { ILinkingData } from '../../interfaces/widget/ILinkingData';
import { IWidgetConfig } from '../../interfaces/widget/IWidgetConfig';
import { IWidgetData } from '../../interfaces/widget/IWidgetData';
import { IWidgetViewInfo } from '../../interfaces/widget/IWidgetViewInfo';
import { NLevelRoutePipe } from '../../pipes/n-level-route-pipe/n-level-route-pipe.pipe';
import { ConfigCacheService } from '../../services/cache/config-cache/config-cache.service';
import { FilterCacheService } from '../../services/cache/filters-cache/filter-cache.service';
import { CompareViewService } from '../../services/compare-view/compare-view.service';
import { FiltersService } from '../../services/filters/filters.service';
import { GlobalDataService } from '../../services/global-data/global-data.service';
import { WidgetHttpService } from '../../services/http/widget-http/widget-http.service';
import { UserDataCacheService } from '../../services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from '../../services/white-labeling/white-labeling.service';
import { SideMenuType } from './../../enums/SideMenuType';

@Component({
    selector: 'app-compare-view-insights',
    templateUrl: './compare-view-insights.component.html',
    styleUrls: ['./compare-view-insights.component.sass']
})
export class CompareViewInsightsComponent implements OnInit, AfterViewInit {
    moduleList = [];
    widgetIDs = [];
    pageList = [];
    lastLevelName = '';
    module;
    showThirdLevel = false;
    page;
    insight;
    insightList = [];
    insightObservables = [];
    errorMessage;
    showDateOnTop = false;
    refreshViewInsights = new EventEmitter();
    filtersCollapsed = true;
    showSecondInsight;
    retentionMonths;
    minDate;
    maxDate;
    fromDate;
    toDate;
    startDateValue = new Date(
        new Date(new Date().getTime()).setDate(new Date().getDate() - 7)
    );
    endDateValue = new Date(new Date().setDate(new Date().getDate() - 1));
    startDateTime;
    endDateTime;
    showInsightModal;
    compareViewEnabled: boolean;
    applyDateAndRefresh = new EventEmitter();
    manifestData;
    sideMenuType: SideMenuType = SideMenuType.DETAILED;
    SideMenuType = SideMenuType;
    TraversalPurpose = TraversalPurpose;
    widgetSubs = new SubSink();
    widgetData: Map<string, IWidgetData> = new Map<string, IWidgetData>();
    widgetConfig: IWidgetConfig;
    @Input('modalData') modalData: IModalData;
    @Input('dataStoreKey') dataStoreKey;
    @Input() filterStoreKey: FilterStoreKey;
    @Output('filterMenu') filterMenuRef = new EventEmitter();
    widgetId: string;
    linkingData: ILinkingData;
    filterIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel'
    };

    filterFilledIcon: IIcon = {
        type: IconType.SVG,
        class: 'funnel_filled',
        extraClass: 'svg-accent-fill'
    };

    widgetIcon: IIcon = {
        type: IconType.SVG,
        class: 'menu_icon'
    };
    widgetIds = [];
    FilterType = FilterType;
    updateWidgetViewData = {};
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    @ViewChild('sidenav') sideNav: MatSidenav;
    @ViewChild('filterMenu') filterMenu: MatSidenav;

    constructor(
        private widgetHttpService: WidgetHttpService,
        private filtersService: FiltersService,
        public compareViewService: CompareViewService,
        public globalDataService: GlobalDataService,
        public configCacheService: ConfigCacheService,
        public whiteLabelingService: WhiteLabelingService,
        private userDataCache: UserDataCacheService,
        private filterCacheService: FilterCacheService
    ) {}

    ngOnInit() {
        this.filtersService.firstViewInitailized = true;
        this.setSideMenuType();
        this.widgetConfig = this.modalData.widgetConfig;
        this.manifestData = this.compareViewService.viewInsightsManifest;
        this.compareViewService.isCompareViewEnabled.subscribe((isEnabled) => {
            this.compareViewEnabled = isEnabled;
            if (!this.compareViewEnabled) {
                if (this.sideNav) {
                    this.sideNav.close();
                }
            }
        });

        setTimeout(() => {
            this.setViewInitialData();
        }, 10);
        if ($(window).width() < 840) {
            $('.link-insight').css('min-height', '400px');
        }

        this.compareViewService.nextWidgetView.subscribe(
            (updateWidgetViewData) => {
                this.filtersService.setFilterInputs.next(true);
                this.updateWidgetViewData = updateWidgetViewData;
                if (updateWidgetViewData['viewId'] === this.dataStoreKey) {
                    this.widgetData = new Map<string, IWidgetData>();
                    this.widgetId = updateWidgetViewData['widgetId'];
                    setTimeout(() => {
                        this.widgetSubs.unsubscribe();
                        if (updateWidgetViewData['widgetConfig']) {
                            this.widgetConfig = {
                                visibleSections:
                                    updateWidgetViewData['widgetConfig'][
                                        'visibleSections'
                                    ]
                            };
                        }

                        this.widgetHttpService.loadWidgetsData(
                            this.widgetHttpService,
                            this.widgetSubs,
                            this.widgetId,
                            this.widgetData,
                            this.prepareLinkingData.bind(this)
                        );
                    }, 10);
                }
            }
        );

        this.compareViewService.previousWidgetView.subscribe((response) => {
            if (response[this.dataStoreKey]) {
                this.widgetData.clear();
                setTimeout(() => {
                    this.filtersService.widgetLinkingData.next(null);
                    const widgetViewInfo: IWidgetViewInfo =
                        this.compareViewService.linkingArray.get(
                            this.dataStoreKey
                        )[
                            this.compareViewService.currentLevel[
                                this.dataStoreKey
                            ] - 1
                        ];

                    this.updateRestrictedFilters(
                        widgetViewInfo.restrictedFilters
                    );
                    this.widgetId = widgetViewInfo['widgetData']['widgetId'];

                    this.linkingData = Helper.cloneDeep(
                        widgetViewInfo.linkingData
                    );
                    this.widgetConfig = {
                        visibleSections:
                            widgetViewInfo.widgetConfig.visibleSections
                    };
                    this.widgetData.set(
                        widgetViewInfo['widgetData']['widgetId'],
                        widgetViewInfo['widgetData']
                    );
                    this.filtersService.filtersFormData.clear();
                    const filterData = {
                        [this.filterStoreKey]: widgetViewInfo.filters
                    };
                    this.filterCacheService.setFiltersInfo(
                        this.userDataCache.emailId,
                        this.configCacheService.viewId,
                        filterData
                    );
                    this.filtersService.widgetLinkingData.next(
                        new Map().set(
                            this.filterStoreKey,
                            Helper.cloneDeep(widgetViewInfo.linkingData.input)
                        )
                    );
                    this.filtersService.generateFiltersData(
                        this.filterStoreKey,
                        this.widgetData
                    );
                    this.updateFilter(widgetViewInfo.linkingData['input']);
                }, 10);
            }
        });
    }

    ngAfterViewInit(): void {
        this.filterMenuRef.emit(this.filterMenu);
        this.filtersService.firstViewInitailized = false;
    }

    setSideMenuType() {
        const generalConfig = this.configCacheService.getGeneralConfig(
            this.userDataCache.emailId,
            this.configCacheService.viewId
        );
        if (generalConfig) {
            if (generalConfig.sideMenuType) {
                this.sideMenuType = generalConfig.sideMenuType;
            }
        }
    }

    updateFilter(drilldownInput) {
        if (Object.keys(drilldownInput).length) {
            const updateFilterObj = new Map();
            Object.keys(drilldownInput).forEach((element) => {
                updateFilterObj.set(element, drilldownInput[element]);
            });
            this.filtersService.updateFilter.next(
                new Map().set(this.dataStoreKey, updateFilterObj)
            );
        }
    }

    updateRestrictedFilters(restrictedFilters) {
        if (restrictedFilters) {
            if (restrictedFilters.length) {
                const restrictedFiltersData = new Map();
                restrictedFiltersData.set(
                    'restrictedFilters',
                    restrictedFilters
                );
                restrictedFiltersData.set(
                    'disableMessage',
                    'As data is displayed for unique instance id filter is not applicable'
                );
                this.filtersService.restrictedFilters.next(
                    new Map().set(this.dataStoreKey, restrictedFiltersData)
                );
            } else {
                const restrictedFiltersData = new Map();
                restrictedFiltersData.set('restrictedFilters', []);
                restrictedFiltersData.set('disableMessage', '');
                this.filtersService.restrictedFilters.next(
                    new Map().set(this.dataStoreKey, restrictedFiltersData)
                );
            }
        }
    }

    populateWidgetData() {
        this.widgetData = new Map<string, IWidgetData>();
        setTimeout(() => {
            this.widgetSubs.unsubscribe();
            this.widgetHttpService.loadWidgetsData(
                this.widgetHttpService,
                this.widgetSubs,
                this.widgetId,
                this.widgetData,
                this.prepareLinkingData.bind(this)
            );
        }, 100);
    }

    prepareLinkingData() {
        this.filtersService.generateFiltersData(
            this.filterStoreKey,
            this.widgetData
        );
        const widgetData = this.widgetData.get(this.widgetId);

        //restricted filters
        let restrictedFilters: string[] = [];

        this.linkingData = {
            breadCrumbs: [],
            breadCrumbsData: [],
            viewId: this.dataStoreKey,
            input: {}
        };
        if (this.compareViewService.currentLevel[this.dataStoreKey] > 1) {
            restrictedFilters = this.updateWidgetViewData['restrictedFilters'];
            this.updateRestrictedFilters(restrictedFilters);

            this.linkingData = {
                breadCrumbs: this.updateWidgetViewData['breadCrumbs'],
                breadCrumbsData: this.updateWidgetViewData['breadCrumbsData'],
                viewId: this.updateWidgetViewData['viewId'],
                input: this.updateWidgetViewData['input']
            };
            this.linkingData = Helper.cloneDeep(this.linkingData);

            if (Object.keys(this.updateWidgetViewData['input']).length) {
                this.updateFilter(this.updateWidgetViewData['input']);
            }
        }

        const tempObj = this.compareViewService.linkingArray
            .get(this.dataStoreKey)
            .find((row) => {
                if (this.widgetId === row['widgetData']['widgetId']) {
                    return true;
                } else {
                    return false;
                }
            });

        if (!tempObj) {
            const filterInput = this.filterCacheService.getFiltersInfo(
                this.userDataCache.emailId,
                this.configCacheService.viewId,
                this.filterStoreKey
            );
            this.compareViewService.linkingArray.get(this.dataStoreKey).push({
                widgetData: widgetData,
                filters: Helper.cloneDeep(filterInput),
                restrictedFilters: restrictedFilters,
                linkingData: Helper.cloneDeep(this.linkingData),
                widgetConfig: {
                    visibleSections: this.widgetConfig.visibleSections
                },
                level: this.compareViewService.currentLevel[this.dataStoreKey]
            });
        }
    }

    refreshInsightGen(apiData) {
        if (
            apiData['inputData']['portletData']['portletType'] !==
                'AUTOMATION' &&
            apiData['inputData']['portletData']['portletType'] !==
                'AGGREGATE' &&
            apiData['inputData']['portletData']['portletType'] !== 'RIBBON' &&
            apiData['inputData']['portletData']['portletType'] !== 'CUSTOM'
        ) {
            const modifiedObject = {};
            //modifiedObject['component'] = Miscellaneous.getComponentType(apiData['inputData']['portletData']['portletType']);
            modifiedObject['inputData'] = apiData['inputData'];
            this.refreshViewInsights.emit(modifiedObject);
        } else {
            this.refreshViewInsights.emit(apiData['inputData']);
        }
    }

    setViewInitialData() {
        this.compareViewService.linkingArray.set(this.dataStoreKey, []);
        this.compareViewService.currentLevel[this.dataStoreKey] = 1;
        this.widgetId = this.modalData.widgetData.widgetId;
        this.insight = this.widgetId;
        if (this.modalData.widgetData.apiResponse) {
            this.widgetData = new Map<string, IWidgetData>();
            const widgetData = this.modalData.widgetData;
            widgetData.widgetUniqueIdentifier = Symbol();
            this.widgetData.set(this.widgetId, widgetData);
            // this.module = this.modalData.widgetData.apiResponse.module;
            // this.page = this.modalData.widgetData.apiResponse.page;
            this.prepareLinkingData();
        } else {
            const urlList =
                this.modalData.widgetData.widgetInfo.list.apiRouteSuffix.split(
                    '/'
                );
            this.module = urlList[2];
            this.page = urlList[4];
            this.populateWidgetData();
        }
    }

    changeWidget(sidenav: MatSidenav) {
        sidenav.toggle();
    }

    filtersCollapse($event) {
        $event.stopPropagation();
    }
    handleLeafNode(leafNodes: Map<number, any>) {
        this.showThirdLevel = true;
        this.widgetIDs = [];
        const url = Helper.urlFormatter(
            new NLevelRoutePipe().transform(leafNodes)
        );
        const leafNode = leafNodes.get(url.split('/').length - 1);
        this.lastLevelName = leafNode.name;
        this.widgetHttpService
            .getAllWidgetsFromLeafNode(leafNode.nextUrl)
            .subscribe((data) => {
                this.widgetIDs = data;
            });
    }

    widgetSelection(item, sidenav: MatSidenav) {
        sidenav.close();
        this.widgetId = item.id;
        this.populateWidgetData();
    }
}
