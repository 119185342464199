<div
    class="widget-data-container"
    [id]="widgetRef.widgetTableId"
    *ngIf="!(widgetRef.loadingData | async)"
>
    <ng-container *ngIf="edit">
        <app-modal-info-template
            [infoData]="infoData | async"
        ></app-modal-info-template>
    </ng-container>

    <section *ngIf="domainConfigForm">
        <div class="section-heading" (click)="toggleSection($event)">
            <div class="title">Domain Configuration</div>
            <div class="actions">
                <i
                    class="fas fa-times"
                    [matTooltip]="'Cancel'"
                    *ngIf="domainConfigForm.state === FormState.EDIT"
                    (click)="
                        stopEditingFormGroup(
                            domainConfigForm,
                            domainConfigFormGroup,
                            FORMS_AVAILABLE.DOMAIN_CONFIG_FORM
                        );
                        domainConfigFormResetFunc()
                    "
                ></i>
                <i
                    class="fas fa-edit"
                    *ngIf="domainConfigForm.state === FormState.IDLE"
                    [matTooltip]="'Edit'"
                    (click)="
                        editFormGroup(
                            domainConfigForm,
                            domainConfigFormGroup,
                            FORMS_AVAILABLE.DOMAIN_CONFIG_FORM
                        )
                    "
                ></i>
            </div>
        </div>
        <div class="section-content">
            <app-form-generator
                [formGeneratorInput]="domainConfigForm"
                (formGroupRef)="domainConfigFormGroup = $event"
                (resetFormFunction)="domainConfigFormResetFunc = $event"
            ></app-form-generator>
        </div>
    </section>

    <section *ngIf="orgProprietaryForm">
        <div class="section-heading" (click)="toggleSection($event)">
            <div class="title">Configure Organization’s Proprietary</div>
            <div class="actions">
                <i
                    class="fas fa-times"
                    [matTooltip]="'Cancel'"
                    *ngIf="orgProprietaryForm.state === FormState.EDIT"
                    (click)="
                        stopEditingFormGroup(
                            orgProprietaryForm,
                            orgProprietaryFormGroup,
                            FORMS_AVAILABLE.ORG_PROPRIETARY_FORM
                        );
                        orgProprietaryFormResetFunc()
                    "
                ></i>
                <i
                    class="fas fa-edit"
                    *ngIf="orgProprietaryForm.state === FormState.IDLE"
                    [matTooltip]="'Edit'"
                    (click)="
                        editFormGroup(
                            orgProprietaryForm,
                            orgProprietaryFormGroup,
                            FORMS_AVAILABLE.ORG_PROPRIETARY_FORM
                        )
                    "
                ></i>
            </div>
        </div>
        <div class="section-content">
            <app-form-generator
                [formGeneratorInput]="orgProprietaryForm"
                (formGroupRef)="orgProprietaryFormGroup = $event"
                (resetFormFunction)="orgProprietaryFormResetFunc = $event"
            ></app-form-generator>
        </div>
    </section>

    <fieldset
        *ngIf="
            socialChannelForm ||
            colorThemeForm ||
            (emailContentForms && emailContentForms.length)
        "
    >
        <legend>Advance Configuration</legend>
        <section *ngIf="socialChannelForm">
            <div class="section-heading" (click)="toggleSection($event)">
                <div class="title">Configure Social Channels</div>
                <div class="actions">
                    <i
                        class="fas fa-times"
                        [matTooltip]="'Cancel'"
                        *ngIf="socialChannelForm.state === FormState.EDIT"
                        (click)="
                            stopEditingFormGroup(
                                socialChannelForm,
                                socialChannelFormGroup,
                                FORMS_AVAILABLE.SOCIAL_CHANNEL_FORM
                            );
                            socialChannelFormResetFunc()
                        "
                    ></i>
                    <i
                        class="fas fa-edit"
                        *ngIf="socialChannelForm.state === FormState.IDLE"
                        [matTooltip]="'Edit'"
                        (click)="
                            editFormGroup(
                                socialChannelForm,
                                socialChannelFormGroup,
                                FORMS_AVAILABLE.SOCIAL_CHANNEL_FORM
                            )
                        "
                    ></i>
                </div>
            </div>
            <div class="section-content">
                <app-form-generator
                    [formGeneratorInput]="socialChannelForm"
                    (formGroupRef)="socialChannelFormGroup = $event"
                    (resetFormFunction)="socialChannelFormResetFunc = $event"
                ></app-form-generator>
            </div>
        </section>
        <section *ngIf="colorThemeForm">
            <div class="section-heading" (click)="toggleSection($event)">
                <div class="title">Configure Theme</div>
                <div class="actions">
                    <i
                        class="fas fa-times"
                        [matTooltip]="'Cancel'"
                        *ngIf="colorThemeForm.state === FormState.EDIT"
                        (click)="
                            stopEditingFormGroup(
                                colorThemeForm,
                                colorThemeFormGroup,
                                FORMS_AVAILABLE.COLOR_THEME_FORM
                            );
                            colorThemeFormResetFunc()
                        "
                    ></i>
                    <i
                        class="fas fa-edit"
                        [matTooltip]="'Edit'"
                        *ngIf="colorThemeForm.state === FormState.IDLE"
                        (click)="
                            editFormGroup(
                                colorThemeForm,
                                colorThemeFormGroup,
                                FORMS_AVAILABLE.COLOR_THEME_FORM
                            )
                        "
                    ></i>
                </div>
            </div>
            <div class="section-content">
                <app-form-generator
                    [formGeneratorInput]="colorThemeForm"
                    (formGroupRef)="colorThemeFormGroup = $event"
                    (resetFormFunction)="colorThemeFormResetFunc = $event"
                ></app-form-generator>
            </div>
        </section>
        <section *ngIf="emailContentForms && emailContentForms.length">
            <div class="section-heading" (click)="toggleSection($event)">
                <div class="title">Configure Email Contents</div>
            </div>
            <div class="section-content">
                <section
                    *ngFor="
                        let emailContentForm of emailContentForms;
                        let i = index
                    "
                    (click)="toggleSection($event, true)"
                >
                    <div class="section-heading">
                        <div class="title">
                            {{ emailContentForm.sectionHeading }}
                            <mat-icon
                                [matTooltip]="MORE_INFO_HOVER_TEXT"
                                (click)="emailContentForm.infoFunc()"
                                >help</mat-icon
                            >
                        </div>
                        <div class="actions">
                            <i
                                class="fas fa-times"
                                [matTooltip]="'Cancel'"
                                *ngIf="
                                    emailContentForm.formGeneratorInput
                                        .state === FormState.EDIT
                                "
                                (click)="
                                    emailContentForm.resetFunc();
                                    stopEditingEmailContent(i)
                                "
                            ></i>
                            <i
                                class="fas fa-edit"
                                *ngIf="
                                    emailContentForm.formGeneratorInput
                                        .state === FormState.IDLE
                                "
                                [matTooltip]="'Edit'"
                                (click)="editEmailContent(i)"
                            ></i>
                        </div>
                    </div>
                    <div
                        class="section-content"
                        [style.display]="i > 0 ? 'none' : 'block'"
                    >
                        <app-form-generator
                            [formGeneratorInput]="
                                emailContentForm.formGeneratorInput
                            "
                            (formGroupRef)="emailContentForm.formGroup = $event"
                            (resetFormFunction)="
                                emailContentForm.resetFunc = $event
                            "
                        ></app-form-generator>
                    </div>
                </section>
            </div>
        </section>

        <div
            class="actions"
            style="display: flex; justify-content: flex-end; margin: 18px 16px"
        >
            <button
                type="button"
                mat-stroked-button
                class="clickable btn-warning-stroked filled"
                color="warn"
                *ngIf="configExist"
                style="margin-right: 8px"
                (click)="deleteWhitelabelingIntegration()"
            >
                Delete
                <mat-spinner
                    class="light-theme"
                    [diameter]="16"
                    *ngIf="deleteButtonLoader"
                ></mat-spinner>
            </button>

            <button
                type="button"
                mat-stroked-button
                class="clickable btn-primary-stroked filled"
                color="primary"
                (click)="saveWhitelabelingData()"
            >
                Save
                <mat-spinner
                    class="light-theme"
                    [diameter]="16"
                    *ngIf="saveButtonLoader"
                ></mat-spinner>
            </button>
        </div>
    </fieldset>
</div>
