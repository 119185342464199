import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridOptions, RowNode } from 'ag-grid-community';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AclAssociatedListingComponent } from '../../../modal-templates/acl-modals/acl-associated-listing/acl-associated-listing.component';
import { AclModalStepOneComponent } from '../../../modal-templates/acl-modals/acl-modal-step-one/acl-modal-step-one.component';
import { AclModalStepTwoComponent } from '../../../modal-templates/acl-modals/acl-modal-step-two/acl-modal-step-two.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { IAction } from './../../../../interfaces/actions/IAction';
import { IModalData } from './../../../../interfaces/modal/IModalData';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';

@Component({
    selector: 'app-acl-listing',
    templateUrl: './acl-listing.component.html',
    styleUrls: ['./acl-listing.component.sass']
})
export class AclListingComponent implements OnInit {
    widgetRef: Widget;
    agGrid: GridOptions = null;

    tableGenInput: ITableGeneratorInput;
    apiResponse: any;
    selectAll = false;

    deleteAclList: any[] = [];
    @ViewChild('confirmAclDeleteTemplate')
    confirmAclDeleteTemplate: TemplateRef<any>;

    readonly MASTER_ACL = 'Master';
    readonly ACL_TYPE_KEY = 'acltype';

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.setUpBasics();
    }

    ngOnInit(): void {
        this.widgetRef.refreshWidgetCallback = this.onWidgetRefresh.bind(this);
    }

    onWidgetRefresh() {
        this.selectAll = false;
        this.widgetRef.showNotificationBadge.next(false);
    }

    isRowSelectable(rowNode: RowNode) {
        return rowNode.data
            ? rowNode.data[this.ACL_TYPE_KEY] !== this.MASTER_ACL
            : false;
    }

    setUpRowClassRules() {
        this.widgetRef.tableRowClassRules = {
            agRowMod: (params) => {
                if (
                    this.agGrid &&
                    this.agGrid.api.getSelectedNodes().length >=
                        GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
                ) {
                    return params.node.selected === false;
                }
                return false;
            }
        };
    }

    onSelectionChanged() {
        if (!this.agGrid) {
            return;
        }
        if (
            this.agGrid.api.getSelectedNodes().length >
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.agGrid.api.forEachNode((node) => {
                    if (
                        node.rowIndex >=
                        GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
                    ) {
                        node.selectThisNode(false);
                    }
                });
            } else {
                this.agGrid.api.deselectAll();
            }
        } else if (
            this.agGrid.api.getSelectedNodes().length ===
            GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                `Maximum ${GlobalConfiguration.RESOURCE_SELECTION_LIMIT_V2} selection allowed`
            );
            this.selectAll = true;
        } else {
            this.selectAll = false;
        }
        this.agGrid.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
        this.agGrid.api.redrawRows();
    }

    onGridReady() {
        if (this.agGrid && this.apiResponse && this.apiResponse.length) {
            let index = -1;
            for (let i = 0; i < this.apiResponse.length; i++) {
                if (
                    this.apiResponse[i][this.ACL_TYPE_KEY] === this.MASTER_ACL
                ) {
                    index = i;
                    break;
                }
            }
            if (index > 0) {
                const masterAcl = this.apiResponse[index];
                this.apiResponse.splice(index, 1);
                this.apiResponse.unshift(masterAcl);
                this.agGrid.api.setRowData(this.apiResponse);
                this.agGrid.api.refreshCells();
            }
        }
    }

    setUpBasics(): void {
        this.setUpRowClassRules();
        this.tableGenInput = {
            columns: [],
            listExtraction: {
                type: 'DIRECT'
            },
            showViewIcon: false,
            selection: 'multiple',
            buttons: [
                {
                    buttonName: 'Create ACL',
                    function: this.openCreateAclModal.bind(this),
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT
                }
            ],
            afterResponse: (response: any) => {
                this.apiResponse = response;
                this.setTableColumns();
                if (response && response.length) {
                    const bulkActions = new Map<string, IAction[]>();
                    bulkActions.set('Bulk Actions', [
                        {
                            message: 'Not Allowed',
                            state: ActionState.ENABLED,
                            visibility: ActionVisibility.VISIBLE,
                            actionId: 'acl_bulk_delete',
                            function: this.bulkDelete.bind(this),
                            icon: {
                                type: IconType.SVG,
                                class: 'trash_icon_filled',
                                text: 'Bulk Delete',
                                extraClass: 'inline-fix-box-1 svg-black-fill'
                            }
                        }
                    ]);
                    this.widgetRef.operationalActions.next(bulkActions);
                }
            }
        };
    }

    setTableColumns() {
        const keysToShow = [
            {
                id: 'name',
                label: 'ACL Name'
            },
            {
                id: 'acltype',
                label: 'ACL Type'
            },
            {
                id: 'description',
                label: 'Description'
            },
            {
                id: 'createdOn',
                label: 'Creation Date'
            }
        ];
        const columns: IColumnData[] = [];
        keysToShow.forEach((keyToShow) => {
            columns.push({
                columnKey: keyToShow.id,
                columnName: keyToShow.label,
                flex: 1,
                minWidth: 150
            });
        });
        columns.push({
            columnKey: '',
            columnName: 'Associated Widgets',
            flex: 1,
            minWidth: 150,
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: this.associatedWidgetsRenderer.bind(this)
        });
        columns.push({
            columnKey: '',
            columnName: 'Associated Accounts',
            flex: 1,
            minWidth: 150,
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: this.associatedAccountsRenderer.bind(this)
        });
        columns.push({
            columnKey: '',
            columnName: 'Action',
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: this.actionRenderer.bind(this),
            filter: false,
            pinned: 'right',
            headerClass: 'grid-cell-data-centred'
        });
        this.tableGenInput.columns = columns;
    }

    associatedWidgetsRenderer(item) {
        const itemData = item.data;
        const button: IButtonGeneratorInput[] = [
            {
                buttonName: 'View',
                buttonColorType: ButtonColorType.SUCCESS,
                buttonType: ButtonType.TEXT,
                function: () => {
                    this.openAclAssociatedWidgetsModal(itemData);
                }
            }
        ];
        item['buttonGenInputs'] = button;
        return item;
    }

    associatedAccountsRenderer(item) {
        const itemData = item.data;
        const button: IButtonGeneratorInput[] = [
            {
                buttonName: 'View',
                buttonColorType: ButtonColorType.SUCCESS,
                buttonType: ButtonType.TEXT,
                function: () => {
                    this.openAclAssociatedCustomersModal(itemData);
                }
            }
        ];
        item['buttonGenInputs'] = button;
        return item;
    }

    actionRenderer(item) {
        const itemData = item.data;
        const isMaster = itemData[this.ACL_TYPE_KEY] === this.MASTER_ACL;
        const buttons: IButtonGeneratorInput[] = [
            {
                buttonName: 'Edit',
                buttonColorType: ButtonColorType.INFO,
                buttonType: ButtonType.TEXT,
                disable: isMaster,
                function: () => {
                    if (isMaster) {
                        return;
                    }
                    this.openEditAclModal(itemData);
                }
            },
            {
                buttonName: 'Delete',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.TEXT,
                disable: isMaster,
                showLoader: !isMaster,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (isMaster) {
                        return;
                    }
                    this.confirmDelete([itemData], buttonRef);
                }
            }
        ];
        const buttonOptions: IMultiButtonOption = {
            layout: {
                justifyContent: 'space-around'
            }
        };
        item['buttonGenInputs'] = buttons;
        item['options'] = buttonOptions;
        return item;
    }

    confirmDelete(acl?: any[], buttonRef?: IButtonGeneratorInput, actionId?) {
        if (acl.length === 0) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select an ACL to delete'
            );
            return;
        }
        this.deleteAclList = acl;
        const modalId = this.modalService.openConfirmationModal({
            modalName: 'Delete Confirmation',
            modalHeightVh: 50,
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle',
                extraClass: 'yellow-severity-color'
            },
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-check-double'
            },
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.WARN,
            confirmationMessage:
                'Are you sure you want to delete the following ACL(s)?',
            bodyTemplate: this.confirmAclDeleteTemplate,
            loaderOnExec: true,
            function: () => {
                const aclIds = acl.map((each) => each['id']);
                if (buttonRef) {
                    buttonRef.loader = true;
                }
                if (actionId) {
                    this.widgetRef.operationLoaders.set(actionId, true);
                }
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = { deleteAclIds: aclIds };
                apiArgs.function = (response) => {
                    this.widgetRef.refreshWidget(true);
                    this.widgetRef.notificationsService.showSnackBar(
                        'ACL(s) deleted successfully'
                    );
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        'Error occurred while deleting ACL(s)'
                    );
                };
                apiArgs.endFunction = () => {
                    this.modalService.closeModal(null, modalId);
                    if (buttonRef) {
                        buttonRef.loader = false;
                    }
                    if (actionId) {
                        this.widgetRef.operationLoaders.set(actionId, false);
                    }
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        });
    }

    bulkDelete(actionId?) {
        let isMaster = false;
        const acl: any[] = [];
        if (this.agGrid && this.agGrid.api) {
            this.agGrid.api.getSelectedNodes().forEach((rowNode: RowNode) => {
                if (rowNode.data[this.ACL_TYPE_KEY] === this.MASTER_ACL) {
                    isMaster = true;
                }
                acl.push(rowNode.data);
            });
        }
        if (isMaster) {
            this.widgetRef.notificationsService.showSnackBar(
                'Master ACL cannot be deleted',
                true
            );
        } else {
            this.confirmDelete(acl, null, actionId);
        }
    }

    openCreateAclModal() {
        this.handleAclModal({
            name: 'Create ACL',
            purpose: ModalAction.CREATE
        });
    }

    openEditAclModal(aclData) {
        this.handleAclModal(
            {
                name: 'Edit ACL',
                purpose: ModalAction.UPDATE
            },
            aclData
        );
    }

    handleAclModal(
        modalInfo: { name: string; purpose: ModalAction },
        stepData?: any
    ) {
        const modalData: IModalData = {
            modalName: modalInfo.name,
            modalHeightVh: 97,
            modalWidthVw: 95,
            modalIcon: null,
            modalType: ModalType.MIDDLE,
            modalPurpose: modalInfo.purpose,
            noStepPadding: true,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalSteps: [
                {
                    stepName: 'Name & Description',
                    stepData: {
                        componentToLoad: AclModalStepOneComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: stepData,
                                aclNameList:
                                    this.apiResponse && this.apiResponse.length
                                        ? this.apiResponse.map(
                                              (each) => each['name']
                                          )
                                        : null,
                                isEdit: modalInfo.purpose === ModalAction.UPDATE
                            }
                        }
                    }
                },
                {
                    stepName: 'Widget Selection',
                    stepData: {
                        componentToLoad: AclModalStepTwoComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: stepData,
                                isEdit: modalInfo.purpose === ModalAction.UPDATE
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    openAclAssociatedWidgetsModal(aclData) {
        const modalData: IModalData = {
            modalIcon: null,
            modalName: 'Associated Widget',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalHeightVh: 97,
            modalWidthVw: 95,
            modalSteps: [
                {
                    stepName: 'Associated Widgets',
                    stepData: {
                        componentToLoad: AclAssociatedListingComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                aclData: aclData,
                                showWidgets: true
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    openAclAssociatedCustomersModal(aclData) {
        const modalData: IModalData = {
            modalIcon: null,
            modalName: 'Associated Customers',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalHeightVh: 90,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepName: 'Associated Customers',
                    stepData: {
                        componentToLoad: AclAssociatedListingComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                aclData: aclData,
                                showWidgets: false
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
}
