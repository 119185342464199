import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import * as vis from 'vis';

@Component({
    selector: 'app-topology-graph',
    templateUrl: './topology-graph.component.html',
    styleUrls: ['./topology-graph.component.sass']
})
export class TopologyGraphComponent implements OnInit, AfterViewInit {
    ViewType = ViewType;
    widgetRef: Widget;

    nodes = [];
    edges = [];
    options;
    objectKeys = Object.keys;
    network;
    iotObservable = null;
    data1;
    checkFields;
    checkWaterMark;
    legends = {};
    graphData = {};
    selectedValues: Map<string, any> = new Map();
    graphPoints = {};
    dropdowns;
    originalDropdowns;
    primaryData = [];
    primaryValue = '';
    plotGraph = false;
    noOfDropdowns = [];
    selectResourceFilterInfo: IFilterInfo;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(false);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.GRAPH]);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data) {
        if (data === null) {
            return;
        }

        this.widgetRef.apiResponse = data;

        this.graphData = data.dataMap.graphMap;
        if (
            (!this.graphData ||
                (this.graphData && Object.keys(this.graphData).length === 0)) &&
            'message' in data &&
            !data.message
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            return;
        }
        if ('message' in data && data.message) {
            this.widgetRef.extraMessage.next(data.message);
            this.widgetRef.endLoader();
            return;
        }

        this.originalDropdowns = data.dataMap.dropdown;
        this.dropdowns = data.dataMap.dropdown;
        this.legends = data.dataMap.legends;
        this.objectKeys(this.dropdowns).forEach((item) => {
            const obj = {};
            obj['id'] = item;
            obj['label'] = item;
            this.noOfDropdowns.push(obj);
        });
        this.widgetRef.endLoader();
    }

    drawGraph(data) {
        this.nodes = [];
        this.edges = [];
        Object.keys(data).forEach((item) => {
            this.nodes.push({
                id: item,
                label: data[item].hoverText,
                shape: data[item].shape,
                title: data[item].hoverText,
                font: { color: 'white' },
                color: data[item].color
            });
        });

        Object.keys(data).forEach((item) => {
            data[item].connectedResource.forEach((element) => {
                this.edges.push({
                    from: item,
                    to: element,
                    length: 30
                });
            });
        });

        // provide the data in the vis format
        this.data1 = {
            nodes: this.nodes,
            edges: this.edges
        };
        this.options = {
            interaction: {
                navigationButtons: true,
                dragNodes: false,
                dragView: true,
                hover: true,
                hoverConnectedEdges: true,
                selectable: true,
                selectConnectedEdges: true,
                tooltipDelay: 30,
                zoomView: true
            },
            autoResize: true,
            edges: {
                smooth: {
                    type: 'continuous',
                    roundness: 0
                },
                color: {
                    color: 'black',
                    highlight: 'black',
                    hover: 'black',
                    inherit: 'from',
                    opacity: 1.0
                }
            },
            physics: {
                stabilization: true,
                enabled: true,
                barnesHut: {
                    avoidOverlap: 1,
                    gravitationalConstant: -15000,
                    centralGravity: 0.1,
                    springConstant: 0
                }
            }
        };
        setTimeout(() => {
            const container = document.getElementById('mynetwork');
            this.network = new vis.Network(container, this.data1, this.options);
            this.network.stabilize(2000);
            this.network.once('afterDrawing', () => {
                this.network.fit({
                    animation: {
                        duration: 3000,
                        easingFunction: 'easeInOutQuad'
                    }
                });
            });
        }, 100);
    }
    dropdownValueChange($event) {
        this.plotGraph = false;
        this.primaryData = [];
        this.graphPoints = {};
        this.primaryValue = '';
        this.selectedValues = new Map();
        $event.forEach((element) => {
            if (!this.primaryData.includes(element)) {
                this.primaryData.push(element);
            }
        });
    }
    getDropdowndata(item) {
        return this.dropdowns[item];
    }

    dropdownSelectedChange($event, selector) {
        this.plotGraph = false;
        this.graphPoints = {};
        if ($event && $event.length) {
            this.selectedValues.set(selector, $event);
            if (selector === this.primaryValue) {
                const values = new Map();
                for (const [key, value] of this.selectedValues.entries()) {
                    if (key === selector) {
                        values.set(key, this.selectedValues.get(selector));
                    }
                }
                this.selectedValues = new Map(values);
                const x = this.selectedValues.get(this.primaryValue);
                this.dropdowns = JSON.parse(
                    JSON.stringify(this.originalDropdowns)
                );
                Object.keys(this.dropdowns).forEach((key) => {
                    if (key !== this.primaryValue) {
                        this.dropdowns[key] = this.dropdowns[key].filter(
                            (value) => {
                                return (
                                    (this.graphData[value.id] &&
                                        this.graphData[
                                            value.id
                                        ].connectedResource.some((v) =>
                                            x.includes(v)
                                        )) ||
                                    x.some(
                                        (v) =>
                                            this.graphData[v] &&
                                            this.graphData[
                                                v
                                            ].connectedResource.includes(
                                                value.id
                                            )
                                    )
                                );
                            }
                        );
                    }
                });
            }
        } else {
            if (selector === this.primaryValue) {
                this.dropdowns = JSON.parse(
                    JSON.stringify(this.originalDropdowns)
                );
                this.selectedValues = new Map();
            }
            this.selectedValues.delete(selector);
        }
        if (this.selectedValues && this.selectedValues.size) {
            for (const [key, value] of this.selectedValues.entries()) {
                this.selectedValues.get(key).forEach((item) => {
                    this.graphPoints[item] = this.graphData[item];
                });
            }
        }
    }

    primaryValueChanged() {
        this.plotGraph = false;
        // this.emptyValues.emit('true');
        this.dropdowns = JSON.parse(JSON.stringify(this.originalDropdowns));
        this.selectedValues = new Map();
        this.graphPoints = {};
    }

    createGraph() {
        this.plotGraph = true;
        this.drawGraph(this.graphPoints);
    }

    aboutChoosePrimary() {
        // this.dialog.open(ShowDataComponent, {
        //   width: '600px',
        //   data: {
        //     heading: 'Choose Primary Resource', content: `
        //     Based upon the selected primary resource, all other selected resources will display connections to that only.<br>
        //     <strong>For Example:</strong> If instance is selected as a primary resource and you have selected the instances, volumes
        //     load balancers in the previous step, then listing of dropdowns for load balancer and volumes will be based upon
        //     primary resource.
        //   `}
        // });
    }

    aboutSelectResource() {
        // this.dialog.open(ShowDataComponent, {
        //   width: '600px',
        //   data: {
        //     heading: 'Select Resources', content: `
        //     Select resources with respect to which you want to see the interconnections.<br>
        //     <strong>For Example:</strong> To see how instances, load balancers and volumes are connected select only those from the dropdown menu.
        //   `}
        // });
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
