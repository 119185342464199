<div class="inte-container tw-flex tw-flex-col tw-bg-white tw-rounded-xl">
    <div class="logo-container tw-px-3">
        <img
            [style.padding.px]="
                integrationInput.imagePadding ||
                integrationInput.imagePadding === 0
                    ? integrationInput.imagePadding
                    : 8
            "
            src="{{ integrationInput.imageUrl }}"
            alt="{{ integrationInput.integrationName }}"
        />
    </div>

    <!-- Integration Description section -->
    <div class="mid-container">
        <div class="tw-px-3">
            <div class="integration-info">
                <div
                    class="integration-name"
                    [matTooltip]="integrationInput.integrationName"
                >
                    {{ integrationInput.integrationName }}
                </div>
                <div class="integration-about">
                    {{ integrationInput.integrationCategory }}
                </div>
            </div>
            <div class="integration-description">
                <app-description-generator
                    [descriptionApperanceType]="DescriptionUiTypes.TYPE_2"
                    [data]="description"
                ></app-description-generator>
            </div>
            <div
                class="more-info"
                *ngIf="
                    widgetRef.widgetMoreInfo && widgetRef.widgetMoreInfo.length
                "
                (click)="
                    $event.stopPropagation();
                    widgetRef.openMoreInfoModal(widgetRef.widgetMoreInfo)
                "
            >
                More Info
            </div>
        </div>
    </div>
    <hr />
    <!-- Integration Button -->
    <div>
        <div *ngIf="!isConfigured" class="button tw-flex tw-justify-center">
            <div>
                <app-button-generator [buttonGenInput]="buttonInputs">
                </app-button-generator>
            </div>
        </div>
        <div *ngIf="isConfigured">
            <div class="button tw-flex tw-justify-around tw-items-center">
                <div
                    class="setting-button-icon"
                    [mat-menu-trigger-for]="settingMenu"
                >
                    <span appIcon [data]="settingIcon"></span>
                </div>
                <mat-menu #settingMenu="matMenu">
                    <div *ngFor="let e of integrationInput.buttons">
                        <div
                            mat-menu-item
                            (click)="executeButtonFunction(e)"
                            class="tw-flex tw-items-center"
                        >
                            <div
                                appIcon
                                [data]="e.buttonIcon"
                                class="tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center i-icon-font-size"
                                [class.tw-self-center]="
                                    e.buttonIcon.type === IconType.SVG
                                "
                            ></div>
                            <span>{{ e.buttonName }}</span>
                        </div>
                    </div>
                </mat-menu>
                <div class="configured-button">Configured</div>
            </div>
        </div>
    </div>
</div>
