<ejs-dropdownlist
    #grouping
    id="single-select-category-dropdown"
    [dataSource]="data"
    [placeholder]="groupWaterMark"
    [fields]="groupFields"
    [popupHeight]="height"
    [value]="value"
    (filtering)="onfiltering($event)"
    (change)="dropdownSelectedValueChanged($event)"
>
</ejs-dropdownlist>
