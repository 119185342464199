<div class="each-button" *ngFor="let field of buttonsInput; index as i">
    <app-button-generator
        [buttonGenInput]="field"
        (buttonRef)="selectMedium(i)"
    >
    </app-button-generator>
</div>

<ng-template #mediumSwitchConfirmation>
    <div class="medium-switch">
        <span
            >Configuring this
            <span class="text-danger"> method will delete </span>
            <span class="text-danger">
                the existing configuration & field mapping.
            </span>
        </span>

        <br />
        <br />

        <span class="confirm"> Click confirm to proceed.</span>
    </div>
</ng-template>
