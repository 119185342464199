<div class="tw-flex tw-w-full tw-h-full" style="min-height: 250px">
    <div *ngIf="tableData && tableInputData" class="tw-flex-f3">
        <app-only-table-generator
            [tableData]="tableData"
            [tableInput]="tableInputData"
            [showSearchBox]="false"
            [hideControlBar]="true"
        ></app-only-table-generator>
    </div>
    <div *ngIf="pieData" class="graph-container">
        <div class="pieChart">
            <app-graph-generator [graphData]="graphData"></app-graph-generator>
        </div>
        <div class="general">
            <span class="header-web-view">
                {{
                    xKey.toLowerCase().includes("cost")
                        ? "Total Cost :"
                        : "Total Usage :"
                }}
            </span>
            &nbsp;
            <span class="value">
                {{ xKey.toLowerCase().includes("cost") ? currencySymbol : "" }}
                {{
                    xKey.toLowerCase().includes("cost") ? totalCost : totalUsage
                }}
            </span>
        </div>
    </div>
</div>
