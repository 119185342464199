import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as moment from 'moment';
import { NgxMatTimepickerFieldComponent } from 'ngx-mat-timepicker';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IDateTimeRangeValue } from '../../interfaces/filter/IDateTimeRangeValue';

@Component({
    selector: 'app-date-time-range',
    templateUrl: './date-time-range.component.html',
    styleUrls: ['./date-time-range.component.sass']
})
export class DateTimeRangeComponent
    implements OnInit, OnChanges, AfterViewInit
{
    @ViewChild('startTimePicker')
    startTimePicker: NgxMatTimepickerFieldComponent;
    @ViewChild('endTimePicker') endTimePicker: NgxMatTimepickerFieldComponent;

    @Input() selectedValue: IDateTimeRangeValue;
    @Input() minDateValue;
    @Input() maxDateValue;
    @Input() lightMode: boolean;
    @Input() filterInfo: IFilterInfo;

    @Output() dateTimeChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.updateStartTimeFromSelectedValue();
        this.updateEndTimeFromSelectedValue();
    }

    updateStartTimeFromSelectedValue() {
        const startHoursMins = this.extractHoursMinsFromTime(
            this.selectedValue.startTime
        );
        this.startTimePicker.writeValue(
            `${startHoursMins.hours}:${startHoursMins.mins} ${startHoursMins.meridiem}`
        );
    }
    updateEndTimeFromSelectedValue() {
        const endHoursMins = this.extractHoursMinsFromTime(
            this.selectedValue.endTime
        );
        this.endTimePicker.writeValue(
            `${endHoursMins.hours}:${endHoursMins.mins} ${endHoursMins.meridiem}`
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedValue && !changes.selectedValue.firstChange) {
            this.updateStartTimeFromSelectedValue();
            this.updateEndTimeFromSelectedValue();
        }
    }

    dateChanged($event) {
        this.selectedValue.startDate = $event.startDateTime;
        this.selectedValue.endDate = $event.endDateTime;
        this.emitDateTime();
    }

    startTimeChanged($event: string) {
        const time = this.extractHoursMinsFromUITime($event);
        this.selectedValue.startTime = this.changeTimeInDate(
            +this.selectedValue.startTime,
            time.hour,
            time.minute,
            time.meridiem
        );

        if (+this.selectedValue.startTime >= +this.selectedValue.endTime) {
            this.selectedValue.endTime = moment(
                new Date(+this.selectedValue.startTime)
            )
                .add(1, 'minute')
                .valueOf();
            this.updateEndTimeFromSelectedValue();
        }

        this.emitDateTime();
    }

    endTimeChanged($event: string) {
        const time = this.extractHoursMinsFromUITime($event);

        this.selectedValue.endTime = this.changeTimeInDate(
            +this.selectedValue.endTime,
            time.hour,
            time.minute,
            time.meridiem
        );

        if (+this.selectedValue.endTime <= +this.selectedValue.startTime) {
            this.selectedValue.startTime = moment(
                new Date(+this.selectedValue.endTime)
            )
                .subtract(1, 'minute')
                .valueOf();
            this.updateStartTimeFromSelectedValue();
        }

        this.emitDateTime();
    }

    changeTimeInDate(
        date: number,
        hour: number,
        minute: number,
        meridiem: string
    ): number {
        if (meridiem.toLowerCase() === 'am' && hour >= 12) {
            hour = hour - 12;
        } else if (meridiem.toLowerCase() === 'pm' && hour < 12) {
            hour = hour + 12;
        }

        const newDate = moment(new Date(date));
        newDate.hour(hour);
        newDate.minute(minute);

        return newDate.valueOf();
    }

    extractHoursMinsFromUITime(time: string) {
        const splittedTime = time.split(':');
        const hour = +splittedTime[0];
        const minuteSplit = splittedTime[1].split(' ');
        const minute = +minuteSplit[0];
        const meridiem = minuteSplit[1];
        return {
            hour,
            minute,
            meridiem
        };
    }

    extractHoursMinsFromTime(time) {
        const date = moment(new Date(+time)).format('hh mm A');
        const splittedDate = date.split(' ');
        const hours = +splittedDate[0];
        const mins = +splittedDate[1];
        const meridiem = splittedDate[2];
        return {
            hours,
            mins,
            meridiem
        };
    }

    emitDateTime() {
        this.dateTimeChange.emit(this.selectedValue);
    }
}
