import { Injectable, NgZone } from '@angular/core';
import { Logger } from '../../classes/Logger';
import { GlobalDataService } from '../global-data/global-data.service';
import { HttpService } from '../http/http-main/http.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    errors = [];
    miscErrors = [];

    constructor(
        private globalDataService: GlobalDataService,
        private httpService: HttpService,
        private ngZone: NgZone,
    ) {}

    pushError(error) {
        console.error(error);
        this.sendError(error);

        try {
            if (!this.globalDataService.isProd) {
                Logger.warn(error);
                return;
            }
            if (JSON.stringify(error)) {
                this.errors.push(error);
            }
        } catch (e) {
            this.miscErrors.push(error);
        }
    }

    /**
     * Function is resonsible for converting errors to string and hit logging API.
     * @param error Error, any javascript error.
     */

    sendError(error) {
        if (error) {
            try {
                error = JSON.stringify(
                    error,
                    Object.getOwnPropertyNames(error)
                );
            } catch (error) {}
        }
        this.httpService.hitLogApi(this.ngZone, false, error, true, null);
    }

    logAllErrors() {
        this.errors.forEach((error) => {
            Logger.warn(error);
        });
    }

    logMiscErrors() {
        this.miscErrors.forEach((error) => {
            Logger.warn(error);
        });
    }
}
