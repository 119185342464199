<ng-container *ngIf="notificationsService.bannerMessages.size">
    <ng-container
        *ngFor="
            let notification of notificationsService.bannerMessages | keyvalue
        "
    >
        <div class="banner-container">
            <div class="banner" id="banner">
                <span class="banner-icon" appIcon [data]="warningIcon"></span>
                <span class="banner-text">
                    {{ notification.value.message }}
                    <span
                        *ngIf="notification.value.buttonName"
                        (click)="notification.value.function()"
                        class="notification-button"
                        >{{ notification.value.buttonName }}</span
                    >
                </span>
                <span
                    class="banner-close"
                    appIcon
                    [data]="crossIcon"
                    (click)="notificationsService.closeBanner(notification.key)"
                ></span>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="whitelabelingService.configLoaded | async; else noConfig">
    <ng-container
        *ngIf="!(sessionService.sessionActive | async) && !showSignUp"
    >
        <app-login></app-login>
    </ng-container>
    <ng-container *ngIf="showSignUp">
        <app-signup-component></app-signup-component>
    </ng-container>
    <ng-container
        *ngIf="
            !showSignUp &&
                !userDataCacheService.isAssesseeView &&
                sessionService.sessionActive | async
        "
    >
        <app-home
            [isBannerVisible]="notificationsService.bannerMessages.size"
        ></app-home>
    </ng-container>
    <ng-container
        *ngIf="
            userDataCacheService.isAssesseeView && sessionService.sessionActive
                | async
        "
    >
        <app-assessee-home></app-assessee-home>
    </ng-container>
    <div
        class="overlay"
        style="z-index: 99999"
        *ngIf="globalDataService.fullPageLoader | async"
    >
        <div class="overlay-content">
            <div
                *ngIf="globalDataService.fullPageLoader | async"
                appIcon
                [data]="spinnerLoader"
                class="overlay-loader"
            ></div>
        </div>
    </div>

    <app-test-actions *ngIf="showTestActions"></app-test-actions>
    <app-widget-testing
        *ngIf="widgetTesting && testingWidgetId"
        [widgetId]="testingWidgetId"
    ></app-widget-testing>
</ng-container>
<ng-template #noConfig>
    <div
        class="config-loader"
        *ngIf="!(whitelabelingService.themeError | async)"
    >
        <mat-spinner [diameter]="44"></mat-spinner>
    </div>
</ng-template>
<app-global-error
    *ngIf="whitelabelingService.themeError | async"
></app-global-error>
<app-toast></app-toast>

<div
    id="preview-blur-background-privacy-policy"
    *ngIf="
        isPreviewPrivacyPolicy && !(globalDataService.fullPageLoader | async)
    "
>
    <div class="preview-blur-background-h1"></div>
    <div class="preview-blur-background-Q3"></div>
    <div class="preview-blur-background-Q4"></div>
    <div class="preview-console-privacy-policy-tooltip">
        The updated file will be shown here
        <svg
            class="right-arrow-image"
            xmlns="http://www.w3.org/2000/svg"
            width="46.763"
            height="37.347"
            viewBox="0 0 46.763 37.347"
        >
            <g
                id="Group_21945"
                data-name="Group 21945"
                transform="translate(0.524 1.93)"
            >
                <path
                    id="Path_7593"
                    data-name="Path 7593"
                    d="M2615.356,721.847s32.439,8.809,39.646,32.835"
                    transform="translate(-2615.356 -721.847)"
                    fill="none"
                    stroke="#ffb42a"
                    stroke-width="4"
                />
                <path
                    id="Path_7594"
                    data-name="Path 7594"
                    d="M2632.333,730.568l-5.006,12.182-13.685-6.842"
                    transform="translate(-2587.943 -710.084)"
                    fill="none"
                    stroke="#ffb42a"
                    stroke-width="4"
                />
            </g>
        </svg>
        <svg
            class="left-arrow-image"
            xmlns="http://www.w3.org/2000/svg"
            width="51.114"
            height="37.36"
            viewBox="0 0 51.114 37.36"
        >
            <g
                id="Group_21944"
                data-name="Group 21944"
                transform="translate(1.85 1.943)"
            >
                <path
                    id="Path_7593"
                    data-name="Path 7593"
                    d="M2659.4,721.847s-36.039,8.809-44.047,32.835"
                    transform="translate(-2610.614 -721.847)"
                    fill="none"
                    stroke="#ffb42a"
                    stroke-width="4"
                />
                <path
                    id="Path_7594"
                    data-name="Path 7594"
                    d="M2613.642,730.568l5.006,12.182,13.685-6.842"
                    transform="translate(-2613.641 -710.084)"
                    fill="none"
                    stroke="#ffb42a"
                    stroke-width="4"
                />
            </g>
        </svg>
    </div>
</div>
