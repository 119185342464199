import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { HitApi } from '../../../../classes/HitApi';
import { WidgetInjectedData } from '../../../../classes/WidgetInjectedData';
import { IButtonData } from '../../../../interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from '../../../../interfaces/table-generator/ITableGeneratorInput';
import { CreateWidgetStepOneComponent } from '../../../modal-templates/create-widget/create-widget-step-one/create-widget-step-one.component';
import { CreateWidgetStepTwoComponent } from '../../../modal-templates/create-widget/create-widget-step-two/create-widget-step-two.component';

@Component({
    selector: 'app-widget-customer-listing',
    templateUrl: './customer-service-listing.component.html',
    styleUrls: ['./customer-service-listing.component.sass']
})
export class CustomerServiceListing implements OnInit {
    // CUSTOMER_SERVICE_LISTING: 1.0

    widgetRef: Widget;
    tableInput: ITableGeneratorInput;
    publishWidgetIndex = null;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    ngOnInit(): void {
        this.tableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-th-list'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            buttons: [
                {
                    buttonName: 'Create',
                    buttonType: 'ACCENT',
                    showLoader: false,
                    function: (buttonData: IButtonData) => {
                        this.openCreateWidgetModal(null);
                    }
                }
            ],
            noDataMessage: 'No widgets created',
            selection: 'single',
            columns: [
                {
                    columnName: 'Widget Name',
                    columnKey: 'text'
                },
                {
                    columnName: 'Email',
                    columnKey: 'email',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData['data'] && rowData['data']['extraInfo']) {
                            return rowData['data']['extraInfo']['email'];
                        }
                        return '-';
                    }
                },
                {
                    columnName: 'Status',
                    columnKey: 'activityType',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData['data'] && rowData['data']['extraInfo']) {
                            const type =
                                rowData.data['extraInfo']['activityType'];
                            return type === 'WIDGET_DRAFT'
                                ? 'Draft'
                                : 'Published';
                        }
                        return '-';
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    cellRenderer: (rowData: RowEvent) => {
                        if (rowData['data'] && rowData['data']['extraInfo']) {
                            const buttons: IButtonData[] = [];
                            const type =
                                rowData.data['extraInfo']['activityType'];
                            if (type === 'WIDGET_DRAFT') {
                                // Publish Widget
                                buttons.push({
                                    buttonName: '',
                                    buttonClass: `publish-widget-button publish-widget-button-${rowData.rowIndex}`,
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-paper-plane'
                                    },
                                    hoverText: 'Publish Widget',
                                    showLoader: true,
                                    function: (buttonRef: IButtonData) => {
                                        if (this.publishWidgetIndex === null) {
                                            document
                                                .querySelector(
                                                    `.publish-widget-button`
                                                )
                                                .classList.add('disabled');
                                            this.publishWidgetIndex =
                                                rowData.rowIndex;
                                            const widgetId =
                                                rowData.data['widgetId'];
                                            if (widgetId) {
                                                buttonRef.showLoaderInTableButtons();
                                                const publishArgs =
                                                    Helper.generateHitApiConfig(
                                                        this.widgetRef
                                                            .widgetData
                                                            .widgetInfo.action
                                                    );
                                                publishArgs.intactUrl =
                                                    publishArgs.url;
                                                publishArgs.url =
                                                    publishArgs.url.replace(
                                                        '{widgetId}',
                                                        widgetId
                                                    );
                                                publishArgs.input = {};
                                                (publishArgs.function = (
                                                    response
                                                ) => {
                                                    buttonRef.hideLoaderInTableButtons();
                                                    this.publishWidgetIndex =
                                                        null;
                                                    document
                                                        .querySelector(
                                                            `.publish-widget-button`
                                                        )
                                                        .classList.remove(
                                                            'disabled'
                                                        );
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        'Widget published successfully'
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                }),
                                                    (publishArgs.errorFunction =
                                                        (error) => {
                                                            buttonRef.hideLoaderInTableButtons();
                                                            this.publishWidgetIndex =
                                                                null;
                                                            document
                                                                .querySelector(
                                                                    `.publish-widget-button`
                                                                )
                                                                .classList.remove(
                                                                    'disabled'
                                                                );
                                                            Helper.showErrorMessage(
                                                                this.widgetRef
                                                                    .notificationsService,
                                                                error,
                                                                'Error publishing widget'
                                                            );
                                                        });
                                                new HitApi(
                                                    publishArgs,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            }
                                        }
                                    }
                                });
                            }
                            // Edit Widget
                            buttons.push({
                                buttonName: '',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-edit'
                                },
                                showLoader: false,
                                function: () => {
                                    this.openCreateWidgetModal(rowData['data']);
                                }
                            });

                            // Delete Widget
                            buttons.push({
                                buttonName: '',
                                buttonIcon: {
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-trash'
                                },
                                showLoader: false,
                                function: (modalId: Symbol) => {
                                    const widgetData = rowData['data'];
                                    const deleteArgs =
                                        Helper.generateHitApiConfig(
                                            this.widgetRef.widgetData.widgetInfo
                                                .delete
                                        );
                                    deleteArgs.intactUrl = deleteArgs.url;
                                    deleteArgs.url = deleteArgs.url.replace(
                                        '{widgetId}',
                                        widgetData['widgetId']
                                    );
                                    deleteArgs.input = {};
                                    deleteArgs.function = (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Widget deleted successfully'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                        this.widgetRef.refreshWidget();
                                    };
                                    deleteArgs.errorFunction = (error) => {
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error,
                                            'Error deleting widget'
                                        );
                                        this.widgetRef.modalService.closeModal(
                                            null,
                                            modalId
                                        );
                                    };
                                    new HitApi(
                                        deleteArgs,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                },
                                executeFunctionAfterConfirmation: {
                                    modalName: 'Delete Widget',
                                    modalIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-trash'
                                    },
                                    contextIcon: {
                                        extraClass: 'color-accent',
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-exclamation-triangle'
                                    },
                                    confirmationMessage: `Are you sure you want to delete the widget ?`,
                                    buttonText: 'Delete',
                                    buttonColorType: ButtonColorType.WARN,
                                    loaderOnExec: true
                                }
                            });

                            return Helper.generateTableButtons(
                                buttons,
                                Helper.getCssVarValue.bind(this.widgetRef),
                                this.widgetRef.modalService
                            );
                        }
                    }
                }
            ]
        };
    }

    openCreateWidgetModal(widgetData) {
        this.widgetRef.modalService.openModal({
            modalName: widgetData ? 'Update Widget' : 'Create Widget',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-folder-plus'
            },
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalHeightVh: 95,
            modalWidthVw: 75,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateWidgetStepOneComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                widgetData,
                                edit: widgetData ? true : false
                            }
                        }
                    },
                    stepName: 'Widget Information'
                },
                {
                    stepData: {
                        componentToLoad: CreateWidgetStepTwoComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                widgetData,
                                edit: widgetData ? true : false
                            }
                        }
                    },
                    stepName: 'More Information'
                }
            ]
        });
    }
}
