<div class="info-container">
    <div class="icon" appIcon [data]="infoIcon"></div>
    <div class="content">
        <ng-container *ngFor="let info of infoData">
            <ng-container *ngFor="let con of info.content">
                <ng-container *ngIf="con.type === 'PARAGRAPH'">
                    <p
                        *ngFor="let value of con.data"
                        [innerHTML]="
                            info.infoHeading + value
                                | safeRender : SanitizeTypes.HTML
                        "
                    ></p>
                </ng-container>
                <ng-container *ngIf="con.type === 'ORDERED_LIST'">
                    <ol>
                        <li
                            *ngFor="let value of con.data"
                            [innerHTML]="
                                value | safeRender : SanitizeTypes.HTML
                            "
                        ></li>
                    </ol>
                </ng-container>
                <ng-container *ngIf="con.type === 'UNORDERED_LIST'">
                    <ul>
                        <li
                            *ngFor="let value of con.data"
                            [innerHTML]="
                                value | safeRender : SanitizeTypes.HTML
                            "
                        ></li>
                    </ul>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
