<div class="container">
    <div class="tw-p-3 tw-w-2/3 tw-m-auto">
        <app-modal-info-template
            [infoData]="infoData"
        ></app-modal-info-template>
    </div>
    <div class="form-container tw-flex tw-justify-center">
        <div class="tw-w-3/4">
            <app-form-generator
                *ngIf="formGenInput"
                [formGeneratorInput]="formGenInput"
                (formGroupRef)="formGroup = $event"
            ></app-form-generator>
        </div>
    </div>
    <div class="button tw-float-right">
        <app-button-generator [buttonGenInput]="buttonInput">
        </app-button-generator>
    </div>
</div>
