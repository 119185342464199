import {
    CdkDragDrop,
    copyArrayItem,
    moveItemInArray
} from '@angular/cdk/drag-drop';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { HitApi } from '../../classes/HitApi';
import { Messages } from '../../classes/Messages';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { FilterType } from '../../enums/FilterType';
import { FormState } from '../../enums/FormState';
import { IconType } from '../../enums/IconType';
import { RequestType } from '../../enums/RequestType';
import { IBackendFormField } from '../../interfaces/form-generator/IBackendFormField';
import { IIcon } from '../../interfaces/icon-data/IIcon';
import { IUpdateAction } from '../../interfaces/update-action/IUpdateAction';
import { ButtonType } from './../../enums/ButtonType';
import { UpdateAction } from './../../enums/UpdateAction';
import { IButtonGeneratorInput } from './../../interfaces/button-generator/IButtonGeneratorInput';
import { IBackendFormGeneratorInput } from './../../interfaces/form-generator/IBackendFormGeneratorInput';
import { IFormGeneratorInput } from './../../interfaces/form-generator/IFormGeneratorInput';
import { NotificationsService } from './../../services/notifications/notifications.service';
import { FieldConfig, formBuilder, formConfiguration } from './form-json';

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.sass']
})
export class FormBuilderComponent implements OnInit, OnChanges {
    @Input() backendFormInput: IBackendFormGeneratorInput = null;
    @Input() widgetData: any;
    @Input() widgetId?: any;
    editFormName: boolean = false;
    configureFormGroupRef: FormGroup = null;
    FIRST_LABEL;
    SECOND_LABEL;
    formBuilderJson: IFormGeneratorInput[];
    @ViewChild('dropdownPlus') dropdownPlusIconTemplateRef: TemplateRef<any>;
    configUpdateControl: any;
    @Output() formBuilderOutput: EventEmitter<IBackendFormGeneratorInput> =
        new EventEmitter();
    @Output() errorMessageOutput: EventEmitter<string> = new EventEmitter();

    configurationForms = formConfiguration;
    buttonsInput: IButtonGeneratorInput[];
    editActionButton: IButtonGeneratorInput;
    deleteActionButton: IButtonGeneratorInput;
    plusButton: IButtonGeneratorInput;
    minusButton: IButtonGeneratorInput;
    finalForm = [];

    pencilIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-pencil-alt',
        extraClass: 'pencil-icon'
    };

    editIcon: IIcon = {
        type: IconType.SVG,
        class: 'circular_edit'
    };

    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'circular_trash'
    };

    dragIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-grip-vertical'
    };

    plusIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-plus'
    };

    minusIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-minus'
    };
    titleFormInput: IFormGeneratorInput = null;
    titleFormGroupRef: FormGroup = null;
    configureFormInput: IFormGeneratorInput = null;
    activeConfigureFormName: string;
    extraValueFieldNames = [];
    errorMessage: string;
    activeConfigureFormIndex: any;
    checkIfNewFieldAdded: boolean;
    formName: string;

    staticValueMap;

    constructor(
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.formBuilderJson = Helper.cloneDeep(formBuilder);
    }

    ngOnInit(): void {
        new HitApi(
            {
                url: `https://configurations.centilytics.com/1/assessment-jsons/${this.widgetId}.json`,
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                input: {},
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true
                },
                function: (res) => {
                    this.staticValueMap = res.list;
                    this.FIRST_LABEL = res.firstLabel;
                    this.SECOND_LABEL = res.secondLabel;
                    this.initialized();
                },
                errorFunction: (err) => {
                    this.initialized();
                }
            },
            this.httpService,
            this.ngZone
        ).hitApi();
    }
    initialized() {
        this.initForms();
        this.emitFinalFormInput();
        this.generateButtons();
    }
    generateButtons() {
        this.buttonsInput = [
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                function: null,
                buttonType: ButtonType.FLAT
            },
            {
                buttonName: 'Cancel',
                buttonColorType: ButtonColorType.PRIMARY,
                function: null,
                buttonType: ButtonType.STROKED
            }
        ];
        this.editActionButton = {
            buttonName: '',
            customClass: 'edit-icon',
            function: null,
            buttonType: ButtonType.ICON,
            buttonColorType: ButtonColorType.INFO,
            buttonIcon: this.editIcon,
            preventHoverEffect: true
        };
        this.deleteActionButton = {
            buttonName: '',
            customClass: 'trash-icon',
            function: null,
            buttonType: ButtonType.ICON,
            buttonColorType: ButtonColorType.INFO,
            buttonIcon: this.trashIcon,
            preventHoverEffect: true
        };
        this.plusButton = {
            buttonName: '',
            customClass: 'plus-button',
            function: () => {
                this.addDropdownValueField();
            },
            buttonType: ButtonType.ICON,
            buttonIcon: this.plusIcon,
            preventHoverEffect: true,
            buttonColorType: ButtonColorType.PRIMARY
        };
        this.minusButton = {
            buttonName: '',
            customClass: 'plus-button',
            function: null,
            buttonType: ButtonType.ICON,
            buttonColorType: ButtonColorType.PRIMARY,
            preventHoverEffect: true,
            buttonIcon: this.minusIcon
        };
    }

    ngOnChanges() {
        this.parseIBackendForm();
    }

    fieldDropped(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            if (this.configureFormInput) {
                return;
            }

            copyArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );

            const index = event.previousIndex;

            const form = Helper.cloneDeep(this.formBuilderJson[index]);

            form.fields[0].name = `${
                form.fields[0].name
            }${Helper.generateUniqueKey(3)}`;

            this.checkIfNewFieldAdded = true;
            this.generateConfigurationForm(form, this.finalForm.length - 1);
        }
        this.emitFinalFormInput();
    }

    addToFinalForm(i) {
        if (this.configureFormInput) {
            return;
        }

        const form = Helper.cloneDeep(this.formBuilderJson[i]);

        form.fields[0].name = `${form.fields[0].name}${Helper.generateUniqueKey(
            3
        )}`;

        this.checkIfNewFieldAdded = true;

        this.finalForm.push(form);

        this.generateConfigurationForm(form, this.finalForm.length - 1);

        this.emitFinalFormInput();
    }

    cancelConfigureForm(index) {
        if (this.checkIfNewFieldAdded) {
            this.finalForm.splice(index, 1);
        }
        this.configureFormInput = null;
        this.emitFinalFormInput();
    }

    initForms() {
        this.initTitleForm();
        this.parseIBackendForm();
    }

    initTitleForm() {
        this.formName = this.formName
            ? this.formName
            : this.widgetData && this.widgetData.widgetName
            ? this.widgetData.widgetName
            : '';

        this.titleFormInput = {
            formName: 'titleForm',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Request Form Name',
                    placeholder: 'Request Form Name',
                    name: 'formName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.formName ? this.formName : 'Request Form Name',
                    onFocusout: () => {
                        this.editFormName = false;
                        this.formName =
                            this.titleFormGroupRef &&
                            this.titleFormGroupRef.value &&
                            this.titleFormGroupRef.value.formName
                                ? this.titleFormGroupRef.value.formName
                                : 'Request Form Name';
                        this.emitFinalFormInput();
                    }
                }
            ]
        };
    }

    parseIBackendForm() {
        this.finalForm = [];
        if (this.backendFormInput) {
            if (!this.formName) {
                this.formName =
                    this.backendFormInput && this.backendFormInput.formName
                        ? this.backendFormInput.formName
                        : 'Request Form Name';
            }

            const fields = this.backendFormInput.fields;
            fields.forEach((each) => {
                if (each.fieldType === FilterType.SHORT_TEXT) {
                    this.updateFinalForm(each, 0);
                }
                if (each.fieldType === FilterType.TEXTAREA) {
                    this.updateFinalForm(each, 1);
                }
                if (each.fieldType === FilterType.NUMBER) {
                    this.updateFinalForm(each, 2);
                }
                if (each.fieldType === FilterType.DATE_TIME) {
                    this.updateFinalForm(each, 3);
                }
                if (each.fieldType === FilterType.DROPDOWN_SINGLE) {
                    this.updateFinalForm(each, 4);
                }
                if (each.fieldType === FilterType.DROPDOWN_MULTIPLE) {
                    this.updateFinalForm(each, 5);
                }
            });
        }
    }

    updateFinalForm(formField: IBackendFormField, index) {
        const form = Helper.cloneDeep(this.formBuilderJson[index]);
        form.fields[0].name = `${form.fields[0].name}${Helper.generateUniqueKey(
            3
        )}`;
        form.fields[0].label = formField.label;
        form.fields[0].placeholder = formField.placeholder
            ? formField.placeholder
            : formField.placeHolder;
        form.fields[0].required = formField.required;

        if (
            formField.fieldType === FilterType.DROPDOWN_MULTIPLE ||
            formField.fieldType === FilterType.DROPDOWN_SINGLE
        ) {
            form.fields[0].listData = formField.listData.map((value) => {
                value.id = value.id.trim().replace(/\s\s+/g, ' ');
                value.label = value.label.trim().replace(/\s\s+/g, ' ');
                return value;
            });
            form.fields[0].value = null;
        }

        this.finalForm.push(form);
    }

    staticUpdateFields(value, form, index) {
        if (this.staticValueMap && this.FIRST_LABEL && this.SECOND_LABEL) {
            if (
                value &&
                form.fields[0].label === this.FIRST_LABEL &&
                this.finalForm[1].fields[0].label === this.SECOND_LABEL
            ) {
                // Change in first dropdown
                this.finalForm[1].updateControlSubject &&
                    this.finalForm[1].updateControlSubject.next({
                        action: UpdateAction.UPDATE_VALUE,
                        controls: [this.finalForm[1].fields[0].name],
                        value: []
                    });
                const selectedValue = String(Object.values(value)[0]);
                const dependentDropdownValue =
                    this.staticValueMap[selectedValue];
                this.finalForm[1].fields[0].listData = dependentDropdownValue;
            } else if (!value && form.fields[0].label === this.FIRST_LABEL) {
                this.finalForm[1].fields[0].listData = [];
            }
        }
    }

    deleteField(index) {
        this.finalForm.splice(index, 1);
        this.emitFinalFormInput();
    }

    processConfigurationForm(index) {
        if (this.configureFormGroupRef.invalid) {
            this.configureFormGroupRef.markAllAsTouched();
            return;
        }
        const configureValue = this.configureFormGroupRef.value;

        const valuesKeys = Object.keys(configureValue).filter((each) => {
            if (each.includes(FieldConfig.DROPDOWN_VALUE)) {
                return each;
            }
        });

        if (
            Helper.arrayHasDuplicates(
                valuesKeys.map((each) => this.configureFormGroupRef.value[each])
            )
        ) {
            Helper.showErrorMessage(
                this.notificationService,
                null,
                'Dropdown cannot have duplicate values.'
            );

            return;
        }

        let listData = [];
        if (valuesKeys && valuesKeys.length) {
            listData = valuesKeys.map((each) => {
                return {
                    id: this.configureFormGroupRef.value[each],
                    label: this.configureFormGroupRef.value[each]
                };
            });
        }

        const fields: IFormField[] = [...this.finalForm[index].fields];
        fields[0].listData = listData;
        fields[0].label = configureValue[FieldConfig.LABEL_VALUE];
        fields[0].placeholder = configureValue[FieldConfig.PLACE_HOLDER_VALUE];
        fields[0].value = configureValue[FieldConfig.PLACE_HOLDER_VALUE];
        fields[0].required = configureValue[FieldConfig.REQUIRED_VALUE];

        this.finalForm[index].fields = [];

        this.finalForm[index].fields = [...fields];

        const refreshControl: IUpdateAction = {
            action: UpdateAction.REFRESH,
            controls: [fields[0].name]
        };

        const refreshValue: IUpdateAction = {
            action: UpdateAction.UPDATE_VALUE,
            controls: [fields[0].name],
            value: configureValue.placeHolderValue
        };

        this.checkIfNewFieldAdded = false;

        this.finalForm[index].updateControlSubject.next(refreshControl);
        this.finalForm[index].updateControlSubject.next(refreshValue);
        this.configureFormInput = null;
        this.formBuilderJson = Helper.cloneDeep(formBuilder);

        this.emitFinalFormInput();
    }

    generateConfigurationForm(form: IFormGeneratorInput, index, viaHtml?) {
        const finalFormField = form.fields[0];
        const configureForm = Helper.cloneDeep(
            this.configurationForms[form.formName]
        );
        if (viaHtml) {
            this.checkIfNewFieldAdded = false;
        }
        this.activeConfigureFormName = form.formName;
        this.activeConfigureFormIndex = index;
        let dropdownStartIndex: number;
        const dropdownFields = [];

        const labels = [];

        this.finalForm.map((eachFinalForm) => {
            if (eachFinalForm.fields[0].name !== form.fields[0].name) {
                labels.push(eachFinalForm.fields[0].label);
            }
        });

        configureForm.fields = configureForm.fields.map(
            (each: IFormField, index) => {
                if (each.name === FieldConfig.REQUIRED_VALUE) {
                    each.value = finalFormField.required;
                }
                if (each.name === FieldConfig.LABEL_VALUE) {
                    each.value = finalFormField.label;
                    each.validations = [
                        {
                            errorMessage: 'Field label already exist',
                            validator:
                                CustomValidators.controlValueNotIn(labels)
                        }
                    ];
                }
                if (each.name === FieldConfig.PLACE_HOLDER_VALUE) {
                    each.value = finalFormField.placeholder;
                }
                if (each.name === FieldConfig.DROPDOWN_VALUE) {
                    if (
                        finalFormField.listData &&
                        finalFormField.listData.length
                    ) {
                        dropdownStartIndex = index;
                        finalFormField.listData.map(
                            (eachDropdownField, dropdownIndex) => {
                                const dropdownData = {
                                    ...each,
                                    suffixTemplate: {
                                        templateRef:
                                            this.dropdownPlusIconTemplateRef,
                                        templateData: {
                                            showPlusIcon:
                                                dropdownIndex ===
                                                finalFormField.listData.length -
                                                    1
                                                    ? true
                                                    : false,
                                            fieldIndex: index,
                                            dropdownCount: index
                                        }
                                    },

                                    value: eachDropdownField.label,
                                    name: `${
                                        FieldConfig.DROPDOWN_VALUE
                                    }${Helper.generateUniqueKey(3)}`
                                };
                                dropdownFields.push(dropdownData);
                            }
                        );
                    } else {
                        each.suffixTemplate = {
                            templateRef: this.dropdownPlusIconTemplateRef,
                            templateData: {
                                showPlusIcon: true,
                                fieldIndex: index,
                                dropdownCount: index
                            }
                        };
                    }
                }
                return each;
            }
        );

        if (dropdownFields && dropdownFields.length) {
            configureForm.fields.splice(dropdownStartIndex, 1);

            configureForm.fields.splice(
                configureForm.fields.length - 2,
                0,
                ...dropdownFields
            );
        }

        this.configureFormInput = configureForm;
    }

    addDropdownValueField() {
        if (this.configureFormGroupRef.invalid) {
            this.configureFormGroupRef.markAllAsTouched();
            return;
        }

        const configureForm = this.configureFormInput;
        configureForm.fields.forEach((element: IFormField, index: number) => {
            element.value = this.configureFormGroupRef.value[element.name];
            if (element.suffixTemplate && element.suffixTemplate.templateData) {
                element.suffixTemplate.templateData.showPlusIcon = false;
                element.suffixTemplate.templateData.fieldIndex = index;
            }
        });

        this.configureFormInput = null;
        let fieldAdded = false;
        setTimeout(() => {
            for (let i = 0; i < configureForm.fields.length; i++) {
                if (
                    configureForm.fields[i].name.includes(
                        FieldConfig.DROPDOWN_VALUE
                    )
                ) {
                    if (fieldAdded === false) {
                        const extraValueFieldName = `${
                            configureForm.fields[i].name
                        }${Helper.generateUniqueKey(3)}`;

                        const field: IFormField = {
                            ...configureForm.fields[i],
                            name: extraValueFieldName,
                            value: '',
                            suffixTemplate: {
                                ...configureForm.fields[i].suffixTemplate,
                                templateData: {
                                    showPlusIcon: true,
                                    fieldIndex: i + 1,
                                    dropdownCount:
                                        configureForm.fields[i].suffixTemplate
                                            .templateData.fieldIndex + 1
                                }
                            }
                        };

                        configureForm.fields.splice(
                            configureForm.fields.length - 2,
                            0,
                            field
                        );
                        fieldAdded = true;
                    }
                }
            }
            this.configureFormInput = configureForm;
        }, 0);
    }

    deleteDropdownField(formIndex) {
        this.configureFormInput.fields.splice(formIndex, 1);

        const configureForm = this.configureFormInput;
        configureForm.fields.forEach((element: IFormField, index: number) => {
            element.value = this.configureFormGroupRef.value[element.name];
            if (element.suffixTemplate && element.suffixTemplate.templateData) {
                element.suffixTemplate.templateData.fieldIndex = index;
            }
        });

        this.configureFormInput = null;

        setTimeout(() => {
            this.configureFormInput = configureForm;
        }, 0);
    }

    emitFinalFormInput() {
        this.formBuilderOutput.emit(this.formBuilderToBackendFormParser());
        this.errorMessageOutput.emit(this.errorMessage);
    }

    // Checking all the validation in this function

    formBuilderToBackendFormParser() {
        this.errorMessage = null;

        let form: IBackendFormGeneratorInput = {
            formName: this.formName ? this.formName : 'Request Form Name',
            submitButton: null,
            state: FormState.CREATE,
            fields: []
        };

        const fields: IBackendFormField[] = [];
        this.finalForm.map((each: IFormGeneratorInput) => {
            const field: IBackendFormField = {
                label: each.fields[0].label,
                placeholder: each.fields[0].placeholder,
                name: each.fields[0].name,
                fieldType: each.fields[0].extraData
                    ? each.fields[0].extraData
                    : each.fields[0].fieldType,
                placeHolder: each.fields[0].placeholder,
                required: each.fields[0].required
            };

            if (
                each.fields[0].fieldType === FilterType.DROPDOWN_SINGLE ||
                each.fields[0].fieldType === FilterType.DROPDOWN_MULTIPLE
            ) {
                field.listData =
                    each.fields[0].listData && each.fields[0].listData.length
                        ? each.fields[0].listData
                        : [];

                if (field.listData && field.listData.length === 0) {
                    this.updateErrorMessage(
                        Messages.FORM_BUILDER_ERRORS.EMPTY_DROPDOWN_LIST
                    );
                }
            }

            fields.push(field);
        });

        form.fields = fields;

        if (fields && fields.length === 0) {
            form = null;
        }

        return form;
    }

    updateErrorMessage(errorMessage: string) {
        if (!this.errorMessage) {
            this.errorMessage = errorMessage;
        }
    }
}
