import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cardDataRenderer'
})
export class CardDataRendererPipe implements PipeTransform {
    transform(rendererFunction, cardData): any {
        if (rendererFunction) {
            return rendererFunction(cardData);
        }
        return '-';
    }
}
