<ejs-multiselect
    id="multiselect-checkbox"
    #checkbox
    [popupWidth]="width ? width : '320px'"
    [dataSource]="data"
    [placeholder]="checkWaterMark"
    [enableSelectionOrder]="false"
    [fields]="checkFields"
    cssClass="e-custom"
    [showClearButton]="true"
    [showDropDownIcon]="true"
    [mode]="mode"
    [allowFiltering]="true"
    (filtering)="onfiltering($event)"
    [showSelectAll]="showSelectAll"
    [enableGroupCheckBox]="enableGroupCheckBox"
    [maximumSelectionLength]="limit"
    (change)="dropdownSelectedValueChamged($event)"
    [enabled]="enabled"
    [filterBarPlaceholder]="filterBarPlaceholder"
    [popupHeight]="height"
    [value]="value"
>
</ejs-multiselect>

<!-- <ejs-multiselect id="multiselect-checkbox" #checkbox [popupWidth]="width ? width : '320px'" [dataSource]="data"
  [placeholder]="checkWaterMark" [enableSelectionOrder]="false" [fields]="checkFields" cssClass='e-custom'
  [showClearButton]="true" [showDropDownIcon]="showDropdownIcon === 'false' ? false : true" [mode]="mode" [allowFiltering]='true'
  (filtering)="onfiltering($event)" [showSelectAll]="showSelectAll" [enableGroupCheckBox]="enableGroupCheckBox"
  [maximumSelectionLength]="limit" (change)=dropdownSelectedValueChamged($event)
  [enabled] = "enabled === 'false' ? false : true"
  [filterBarPlaceholder]="filterBarPlaceholder" [popupHeight]="height" [value]="value">
</ejs-multiselect> -->
