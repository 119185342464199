<div class="table-container">
    <mat-tab-group
        *ngIf="!bulkUpdateSelected"
        mat-stretch-tabs
        (selectedTabChange)="changeTab($event)"
        mat-align-tabs="center"
        [(selectedIndex)]="selectedIndexForTab"
        [disableRipple]="true"
    >
        <mat-tab [label]="'Old Method'"></mat-tab>
        <mat-tab [label]="'New Method'"> </mat-tab>
    </mat-tab-group>
    <div class="spinner">
        <mat-spinner *ngIf="excelLoader" [diameter]="45" class="loader spinner">
        </mat-spinner>
    </div>
    <app-only-table-generator
        *ngIf="excelLoader === false && !resetClicked"
        [tableData]="tableData"
        [tableInput]="tableInput"
        (gridRef)="onGridReady($event)"
        [useMaterialIcons]="true"
        [showSearchBox]="false"
    >
    </app-only-table-generator>
</div>
<div class="container">
    <div
        class="file-upload-form-section tw-flex tw-justify-between tw-items-center tw-mt-14"
    >
        <div class="form-col padded-bottom tw-max-h-8 tw-mt-5">
            <span>Download Excel Template :</span>
        </div>
        <div class="form-col padded-sides tw-max-h-8 tw-flex tw-items-center">
            <div
                class="form-row padded-bottom tw-flex tw-items-center tw-justify-between"
            >
                <div
                    class="form-col-left-align tw-flex tw-relative tw-justify-around"
                >
                    <h4>Template.xlsx</h4>
                </div>
                <div class="form-col-right-align">
                    <app-button-generator [buttonGenInput]="callButtons(0)">
                    </app-button-generator>
                </div>
            </div>
        </div>
    </div>
    <div
        class="form-row separated-up-down tw-flex tw-items-center tw-justify-between"
        [style.margin-top]="
            bulkUpdateSelected && Tab.SUBSCRIPTION_ACCOUNT !== accountType
                ? '0px'
                : '15px'
        "
    >
        <div class="form-col-padded-bottom">
            <span class="upload-container tw-relative tw-bottom-3">
                Upload Excel File :
            </span>
        </div>
        <app-form-generator
            *ngIf="excelFormInput"
            class="excel-form tw-h-full"
            [formGeneratorInput]="excelFormInput"
            (formGroupRef)="excelForm = $event"
            (valuesChange)="excelFile($event)"
        >
        </app-form-generator>
    </div>
</div>
<div
    class="tab-content-actions tw-absolute tw-flex tw-justify-end tw-bottom-2 tw-right-3"
>
    <app-button-generator [buttonGenInput]="callButtons(1)">
    </app-button-generator>
</div>
