import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RowEvent, ValueGetterParams } from 'ag-grid-community';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { PortletVersion } from 'src/app/shared/enums/PortletVersion';
import { State } from 'src/app/shared/enums/State';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { AzureRiRecommendationModalV2Component } from '../../../modal-templates/azure-ri-recommendation-modal-v2/azure-ri-recommendation-modal-v2.component';
import { AzureRiRecommendationModalComponent } from '../../../modal-templates/azure-ri-recommendation-modal/azure-ri-recommendation-modal/azure-ri-recommendation-modal.component';
import { RiPurchaseFormComponent } from '../../../modal-templates/ri-purchase-form/ri-purchase-form.component';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { RiRecommendationModalComponent } from './../../../modal-templates/ri-recommendation-modal/ri-utilization-modal/ri-recommendation-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';
@Component({
    selector: 'app-ri-recommendation',
    templateUrl: './ri-recommendation.component.html',
    styleUrls: ['./ri-recommendation.component.sass']
})
export class RiRecommendationComponent implements OnInit, AfterViewInit {
    ViewType = ViewType;
    widgetRef: Widget;
    severityCount = {
        red: 0,
        yellow: 0,
        green: 0,
        silver: 0
    };
    dataMap;
    objectKeys = Object.keys;
    cardsData: any[] = [];
    filteredCardsData: any[];
    State = State;
    accounts;
    keys;
    isWidgetBasic = false;
    enableFix = true;
    tableGenInput: ITableGeneratorInput = null;
    tableData = [];
    instanceTypes = InstanceType;
    recommendationCount: IButtonGeneratorInput;
    isV2: boolean = false;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);

        const widgetActions: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.refreshWidgetCallback =
            this.refreshWidgetCallback.bind(this);
        this.widgetRef.widgetActions.next(widgetActions);
    }

    setUpTableView() {
        let displayEntry = this.dataMap['displayEntry'];

        if (!displayEntry) {
            if (this.tableData && this.tableData.length) {
                displayEntry = this.objectKeys(this.tableData[0]);
            }
        }

        if (!displayEntry) {
            return;
        }

        const columnsDef: IColumnData[] = displayEntry.map((value) => {
            if (value === InstanceType.VM_TYPE) {
                return {
                    columnKey: value,
                    columnName: value,
                    minWidth: 100,
                    cellRenderer: (rowData: RowEvent) => {
                        if (
                            this.cardsData[rowData.rowIndex] &&
                            this.cardsData[rowData.rowIndex]
                                .generalInformation &&
                            'Instance Count to be Reserved' in
                                this.cardsData[rowData.rowIndex]
                                    .generalInformation
                        ) {
                            return `${
                                this.cardsData[rowData.rowIndex].displayEntry[
                                    InstanceType.VM_TYPE
                                ]
                            } (${
                                this.cardsData[rowData.rowIndex]
                                    .generalInformation[
                                    'Instance Count to be Reserved'
                                ]
                            })`;
                        }
                    }
                };
            } else if (value === InstanceType.VM_FAMILY) {
                return {
                    columnKey: value,
                    columnName: value,
                    minWidth: 100,
                    cellRenderer: (rowData: RowEvent) => {
                        if (
                            this.cardsData[rowData.rowIndex] &&
                            this.cardsData[rowData.rowIndex]
                                .generalInformation &&
                            'Instance Flexibility Count To Reserve' in
                                this.cardsData[rowData.rowIndex]
                                    .generalInformation
                        ) {
                            return `${
                                this.cardsData[rowData.rowIndex].displayEntry[
                                    InstanceType.VM_FAMILY
                                ]
                            } (${
                                this.cardsData[rowData.rowIndex]
                                    .generalInformation[
                                    'Instance Flexibility Count To Reserve'
                                ]
                            })`;
                        }
                    }
                };
            } else {
                return {
                    columnKey: value,
                    columnName: value,
                    minWidth: 100,
                    cellClass:
                        value === 'Best Savings' ? 'general-success' : '',
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data[value];
                    },
                    columnValueGetter: (params: ValueGetterParams) => {
                        if (value !== 'Best Savings') return params.data[value];
                        return parseFloat(params.data[value].match(/(\d+)/)[0]);
                    }
                };
            }
        });

        const actionColumns: IColumnData[] = [
            {
                columnName: 'Action',
                columnKey: 'action',
                pinned: 'right',
                lockPinned: true,
                lockPosition: true,
                hideFilterMenu: true,
                minWidth: this.isV2 ? 100 : 180,
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData: RowEvent) => {
                    if (!rowData['buttonGenInputs']) {
                        rowData['buttonGenInputs'] = [];
                    }
                    const fixButton: IButtonGeneratorInput[] = [
                        {
                            buttonName: 'More Info',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.INFO,
                            function: (buttonRef) => {
                                this.showRiModal(
                                    this.cardsData[rowData.node.rowIndex]
                                );
                            }
                        }
                    ];
                    const purchaseRIButton: IButtonGeneratorInput = {
                        buttonName: 'Purchase RI',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.SUCCESS,
                        buttonIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'none'
                        },
                        function: (buttonRef) => {
                            this.performAction(rowData);
                        }
                    };
                    const options: IMultiButtonOption = {
                        layout: {
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    };
                    if (!this.isV2) {
                        fixButton.push(purchaseRIButton);
                    }
                    rowData['buttonGenInputs'] = fixButton;
                    rowData['options'] = options;
                    return rowData;
                }
            }
        ];

        this.tableGenInput = {
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [...columnsDef, ...actionColumns],
            tableHeight: 350
        };
    }

    refreshWidgetCallback() {
        this.filteredCardsData = [];
    }

    bindData(data) {
        if (data === null) {
            return;
        }
        this.tableData = [];
        this.tableGenInput = null;
        this.widgetRef.apiResponse = data;

        this.dataMap = data.dataMap;
        if (
            this.dataMap &&
            this.dataMap.version &&
            this.dataMap.version === 2.0
        ) {
            this.isV2 = true;
        }

        if (
            this.widgetRef.widgetData.widgetInfo.portlet.type ===
                PortletType.AZURE_RIRECOMMENDATION &&
            this.widgetRef.widgetData.widgetInfo.portlet.version ===
                PortletVersion._2
        ) {
            this.isV2 = true;
        }

        this.enableFix = this.dataMap['enableFix'];
        // for view modal
        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];
        if (this.accounts && this.accounts.length === 0 && !data.message) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.endLoader();
            return;
        }
        if ('message' in data && data.message) {
            this.widgetRef.extraMessage.next(data.message);
            this.widgetRef.endLoader();
            return;
        }
        this.cardsData = this.accounts;
        if (this.accounts.length) {
            this.filteredCardsData = this.accounts;
        }

        this.widgetRef.endLoader();
        this.tableData = this.accounts.map((each) => each['displayEntry']);
        this.tableData.forEach((key, i) => {
            key['riPurchaseOfferingMap'] =
                this.accounts[i]['riPurchaseOfferingMap'];
        });
        this.setUpTableView();
        this.recommendationCount = {
            buttonName: `Recommendation Count :${this.filteredCardsData.length}`,
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.INFO,
            preventHoverEffect: true,
            disableRipple: true,
            hoverText: `Recommendation Count :${this.filteredCardsData.length}`,
            function: () => {}
        };
    }

    // @Deprecated Method
    // This function is deprecated as we removed purchase ri button from table gen
    performAction(rowData) {
        const creditCardIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-credit-card'
        };
        const modalData: IModalData = {
            modalName: 'Purchase RI',
            modalIcon: creditCardIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 80,
            modalWidthVw: 41,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RiPurchaseFormComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                rowData,
                                automationJson:
                                    this.widgetRef.widgetData['automation'],
                                calculationType:
                                    this.widgetRef.widgetData['automation'][
                                        'calculationType'
                                    ],
                                cards: this.cardsData
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    showRiModal(card) {
        const toggleOnIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-toggle-on',
            extraClass: 'switch'
        };
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'Recommendation',
            modalIcon: toggleOnIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            modalWidthVw: 76,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            this.widgetRef.widgetData.widgetInfo.portlet
                                .type === PortletType.AZURE_RIRECOMMENDATION &&
                            this.widgetRef.widgetData.widgetInfo.portlet
                                .version === '1.0'
                                ? AzureRiRecommendationModalComponent
                                : this.widgetRef.widgetData.widgetInfo.portlet
                                      .type ===
                                      PortletType.AZURE_RIRECOMMENDATION &&
                                  this.widgetRef.widgetData.widgetInfo.portlet
                                      .version === '2.0'
                                ? AzureRiRecommendationModalV2Component
                                : RiRecommendationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                item: card,
                                dataMap: this.dataMap
                            }
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}

export enum InstanceType {
    VM_TYPE = 'VM Type',
    VM_FAMILY = 'VM Family'
}
