import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { IExpandableTableApiResponse } from 'src/app/shared/interfaces/api/portlets/IExpandableTableApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { CamelCaseToNormalNamePipe } from 'src/app/shared/pipes/camel-case-to-normal-name.pipe';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ExpandableTableMoreInfoModalComponent } from '../../../modal-templates/expandable-table/expandable-table-more-info-modal/expandable-table-more-info-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-expandable-table',
    templateUrl: './expandable-table.component.html',
    styleUrls: ['./expandable-table.component.sass']
})
export class ExpandableTableComponent implements OnInit, AfterViewInit {
    // EXPANDABLETABLE: 1.0

    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    ViewType = ViewType;
    widgetRef: Widget;
    gridApi;
    gridColumnApi;
    agGridIcons = Helper.getAgGridIcons();

    rowData;
    filteredSearchData;
    columnsDefination;
    defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true
    };

    frameworkComponent = {
        buttonGen: MultiButtonGeneratorComponent
    };
    tableInputData: ITableGeneratorInput = {
        tableAutoHeight: true,
        widgetIconData: null,
        listExtraction: {
            type: 'DIRECT'
        },
        columns: [],
        tableHeight: 300
    };
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    bindData(data: IExpandableTableApiResponse): void {
        if (!data) {
            return;
        }
        this.widgetRef.apiResponse = data;
        this.rowData = [];
        this.filteredSearchData = [];

        this.widgetRef.attentionRequired(data);
        if (
            (data.dataMap && !Object.keys(data.dataMap).length) ||
            (data.dataList && !data.dataList.length)
        ) {
            this.widgetRef.extraMessage.next(Messages.NO_DATA_AVAILABLE);
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.rowData = data.dataList;
        this.filteredSearchData = data.dataList;
        this.prepareColumnList(
            data,
            data.dataMap['keysToShow'] ?? Object.keys(data.dataList[0])
        );
        this.prepareTableData();
        this.widgetRef.endLoader();
    }

    moreInfoClicked(rowData) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-info'
        };

        const modalData: IModalData = {
            modalName: 'More Information',
            modalIcon: moreInfoIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ExpandableTableMoreInfoModalComponent,
                        payload: {
                            data: {
                                modalData: rowData['data']['modalData']
                                    ? rowData['data']['modalData']
                                    : rowData['data'],
                                containsModalData: rowData['data']['modalData']
                                    ? true
                                    : false
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    prepareColumnList(data, keys) {
        this.columnsDefination = [];
        const nameFormatterPipe = new CamelCaseToNormalNamePipe();
        keys.forEach((key) => {
            let keyObject = {};
            if (
                data.dataMap['ignoreCamelCase'] &&
                key in data.dataMap['ignoreCamelCase']
            ) {
                keyObject = {
                    headerName: data.dataMap['ignoreCamelCase'][key],
                    field: key
                };
            } else {
                if (key.includes('(')) {
                    keyObject = {
                        headerName:
                            nameFormatterPipe.transform(key.split('(')[0]) +
                            '(' +
                            key.split('(')[1],
                        field: key
                    };
                } else {
                    keyObject = {
                        headerName: nameFormatterPipe.transform(key),
                        field: key
                    };
                }
            }
            this.columnsDefination.push(keyObject);
        });
        this.columnsDefination[0]['pinned'] = 'left';
    }

    prepareTableData() {
        this.tableInputData.columns = [];

        this.columnsDefination.forEach((column) => {
            const columnObj: IColumnData = {
                columnName: column.headerName,
                columnKey: column.field,
                pinned: column.pinned
            };
            this.tableInputData.columns.push(columnObj);
        });

        // Adding more info
        this.tableInputData.columns.push({
            columnName: 'Action',
            columnKey: 'moreInfo',
            pinned: 'right',
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData: RowEvent) => {
                if (!rowData['buttonGenInputs']) {
                    rowData['buttonGenInputs'] = [];
                }
                const fixButtons: IButtonGeneratorInput[] = [
                    {
                        buttonName: 'More Info',
                        buttonType: ButtonType.TEXT,
                        buttonColorType: ButtonColorType.INFO,
                        function: () => {
                            this.moreInfoClicked(rowData);
                        }
                    }
                ];
                rowData['buttonGenInputs'] = fixButtons;
                return rowData;
            }
        });
        this.tableInputData.columns[this.tableInputData.columns.length - 1][
            'pinned'
        ] = 'right';
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    ngAfterViewInit() {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
