import { Component, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { MyErrorStateMatcher } from './../../../classes/ErrorStateMatcher';
import { FileField } from './../../../enums/AppearanceType';
import { ButtonColorType } from './../../../enums/ButtonColorType';
import { ButtonType } from './../../../enums/ButtonType';
import { FilterType } from './../../../enums/FilterType';
import { FormState } from './../../../enums/FormState';
import { IFormField } from './../../../interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from './../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-file-field',
    templateUrl: './file-field.component.html',
    styleUrls: ['./file-field.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileFieldComponent,
            multi: true
        }
    ]
})
export class FileFieldComponent implements OnInit, ControlValueAccessor {
    @Input() field: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    FilterType = FilterType;
    FormState = FormState;
    onChange: any = () => {};
    onTouch: any = () => {};
    FieldAppearance = FileField;
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    ButtonType = ButtonType;
    ButtonColorType = ButtonColorType;

    constructor() {}

    ngOnInit(): void {
        const existingAppearance = Object.values(this.FieldAppearance);
        if (!existingAppearance.includes(this.field.appearance as FileField)) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }
        this.control = this.findControl();
    }

    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }

    writeValue(value: string): void {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}
}
