<ng-container
	*ngIf="filterInfo && filterData && 
    filterInfo.type === FilterType.CURRENCY"
>
    <app-currency-filter
        [filterStoreKey]="filterStoreKey"
        [selectedValue]="selectedValue"
        [disabled]="disabled"
        [disableMessage]="disableMessage"
        [enableTemp]="enableTemp"
        [filterData]="filterData"
        [filterInfo]="filterInfo"
    >
    </app-currency-filter>
</ng-container>
<ng-container
	*ngIf="
		filterInfo &&
		filterData &&
		filterInfo.type === FilterType.CURRENCY &&
		filterStoreKey === FilterStoreKey.REPORT_FILTERS &&
        showMultiCurrencyField
	"
>
	<ng-container *ngFor="let item of currencyFilters; let i = index">
		<div class="multi-currency-div">
			<app-multi-currency-filter
				[index]="i"
				[filterStoreKey]="filterStoreKey"
				[selectedValue]="item"
				[disabled]="disabled"
				[disableMessage]="disableMessage"
				[enableTemp]="enableTemp"
				[filterData]="filterData"
				[filterInfo]="filterInfo"
                [removeButtonGenInput]="removeCurrencyFieldInput"
                [fieldError] = "duplicateFieldIndex"
			>
			</app-multi-currency-filter>
		</div>
	</ng-container>
    <ng-container *ngIf="duplicateFieldIndex | async">
        <div class="field-error">
            {{ 'Invalid Selection: Duplicate Currencies Detected' }}
        </div>
    </ng-container>
    <app-button-generator
        class="add-currency-button"
        [buttonGenInput]="addCurrencyFieldInput"
        (buttonRef)="addCurrencyField()"
    ></app-button-generator>
</ng-container>
<ng-container
    *ngIf="
        filterInfo &&
        filterData &&
        filterInfo.type === FilterType.ACL_VIEW_LAYER
    "
>
    <app-acl-view-layer-filter
        *ngIf="filterInfo.type === FilterType.ACL_VIEW_LAYER"
        [selectedValue]="selectedValue"
        [lightMode]="lightMode ? lightMode : false"
        [isCaching]="
            filterInfo && filterInfo.cachable !== undefined
                ? filterInfo.cachable
                : true
        "
        [refreshListing]="refreshListing"
        [filterInfo]="filterInfo"
        [filterData]="filterData"
        (valueChange)="aclViewLayerListingChanged($event)"
    >
    </app-acl-view-layer-filter>
</ng-container>

<ng-container
    *ngIf="
        filterInfo &&
        filterData &&
        filterInfo.type === FilterType.SINGLE_DROPDOWN_PERCENT
    "
>
    <app-single-dropdown-percent-filter
        [filterStoreKey]="filterStoreKey"
        [selectedValue]="selectedValue"
        [disabled]="disabled"
        [disableMessage]="disableMessage"
        [enableTemp]="enableTemp"
        [filterData]="filterData"
        [filterInfo]="filterInfo"
        [infoIcon]="infoIcon"
    ></app-single-dropdown-percent-filter>
</ng-container>
<div
    class="filter"
    *ngIf="
        filterInfo && filterData && !CUSTOM_FILTERS.includes(filterInfo.type)
    "
    [ngClass]="filterInfo.parentSelector ? 'child-filter' : null"
>
    <div class="filter-head">
        <div class="filter-name">
            {{ filterInfo.label }}
            <span *ngIf="filterInfo.compulsory" class="compulsory">*</span>
        </div>
        <ng-container *ngIf="!filterInfo.parentSelector">
            <div class="filter-controls">
                <div
                    *ngIf="filterData?.associatedWidgets.length"
                    appIcon
                    [matMenuTriggerFor]="associatedWidgetsMenu"
                    [matTooltip]="'Widgets associated with filter'"
                    [data]="infoIcon"
                    class="action"
                    [class.disabled]="disabled"
                ></div>
                <mat-menu #associatedWidgetsMenu="matMenu">
                    <div class="more-info-menu">
                        <div class="heading">
                            Filter is applicable on following insights
                        </div>
                        <!-- <ng-container
                            *ngIf="filterData && filterData.associatedWidgets"
                        ></ng-container> -->
                        <div
                            class="info-item not-clickable"
                            *ngFor="
                                let widget of filterData.associatedWidgets;
                                let i = index
                            "
                        >
                            {{ i + 1 }}.&nbsp;{{ widget.widgetTitle }}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                filterInfo.logicalOperatorEnable &&
                !hideLogicalOperatorRadioButtons
            "
        >
            <div class="dropdown-radio">
                <mat-radio-group
                    (change)="getlogicalOperator($event.value)"
                    [(ngModel)]="selectedOperator"
                    class="logicalOperators"
                >
                    <mat-radio-button value="OR">OR</mat-radio-button>
                    <mat-radio-button value="AND">AND</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>
    </div>
    <div class="filter-content">
        <!-- Single-Select Dropdown -->
        <ng-container *ngIf="filterInfo.type === FilterType.DROPDOWN_SINGLE">
            <!-- Loader... -->
            <app-single-select-dropdown
                [isCaching]="
                    filterInfo && filterInfo.cachable !== undefined
                        ? filterInfo.cachable
                        : true
                "
                [refreshListing]="refreshListing"
                [filterInfo]="filterInfo"
                [selectedValue]="selectedValue"
                [clearable]="false"
                (change)="singleDropdownValueChange($event)"
            ></app-single-select-dropdown>
        </ng-container>

        <!-- Multi-Select Dropdown -->
        <ng-container *ngIf="filterInfo.type === FilterType.DROPDOWN_MULTIPLE">
            <app-multi-select-dropdown
                [isCaching]="
                    filterInfo && filterInfo.cachable !== undefined
                        ? filterInfo.cachable
                        : true
                "
                [ngClass]="filterInfo.showCheckbox ? 'dropdown-checkbox' : ''"
                [refreshListing]="refreshListing"
                [filterInfo]="filterInfo"
                [selectedValues]="selectedValue"
                [disabled]="disabled"
                (change)="multiDropdownValueChange($event)"
            ></app-multi-select-dropdown>
        </ng-container>
        <!-- Multi-Select Dropdown-With-Input -->
        <ng-container *ngIf="filterInfo.type === FilterType.MULTI_DROPDOWN_WITH_INPUT">
            <app-multi-dropdown-with-input
                [isCaching]="
                    filterInfo && filterInfo.cachable !== undefined
                        ? filterInfo.cachable
                        : true
                "
                [ngClass]="filterInfo.showCheckbox ? 'dropdown-checkbox' : ''"
                [refreshListing]="refreshListing"
                [filterInfo]="filterInfo"
                [selectedValues]="selectedValue"
                [disabled]="disabled"
                (change)="multiDropdownValueChange($event)"
            ></app-multi-dropdown-with-input>
        </ng-container>

        <!-- Grouping Multi-Select Dropdown -->
        <ng-container
            *ngIf="filterInfo.type === FilterType.DROPDOWN_GROUP_MULTIPLE"
        >
            <app-grouping-dropdown
                [isCaching]="
                    filterInfo && filterInfo.cachable !== undefined
                        ? filterInfo.cachable
                        : true
                "
                [refreshListing]="refreshListing"
                [filterInfo]="filterInfo"
                [selectedValues]="selectedValue"
                [disabled]="disabled"
                (change)="groupMultiDropdownValueChange($event)"
            ></app-grouping-dropdown>
        </ng-container>

        <!-- Toggle -->
        <ng-container *ngIf="filterInfo.type === FilterType.TOGGLE">
            <app-toggle
                [filterInfo]="filterInfo"
                [selectedValue]="selectedValue"
                (change)="toggleValueChange($event)"
            >
            </app-toggle>
        </ng-container>

        <!-- Range -->
        <ng-container *ngIf="filterInfo.type === FilterType.RANGE">
            <div class="numberRangeSingle {{ lightMode ? 'light' : null }}">
                <ngx-slider
                    (userChangeEnd)="numberRangeChanged($event)"
                    [(value)]="selectedValue"
                    [options]="filterInfo.rangeOptions"
                ></ngx-slider>
            </div>
        </ng-container>

        <!-- Double Range -->
        <ng-container *ngIf="filterInfo.type === FilterType.DOUBLE_RANGE">
            <div class="numberRangeSingle {{ lightMode ? 'light' : null }}">
                <ngx-slider
                    (userChangeEnd)="numberRangeDoubleChanged($event)"
                    [(value)]="selectedValue.lowValue"
                    [(highValue)]="selectedValue.highValue"
                    [options]="filterInfo.rangeOptions"
                ></ngx-slider>
            </div>
        </ng-container>

        <!-- Input -->
        <ng-container *ngIf="filterInfo.type === FilterType.TEXT">
            <input
                class="text-field {{ lightMode ? 'light' : null }}"
                type="text"
                [name]="filterInfo.label"
                [value]="selectedValue ? selectedValue : ''"
                (change)="inputResponseHandle($event.target.value)"
                [placeholder]="
                    filterInfo.placeholder
                        ? filterInfo.placeholder
                        : 'Select ' + filterInfo.label
                "
            />
        </ng-container>

        <!-- Number -->
        <ng-container *ngIf="filterInfo.type === FilterType.NUMBER">
            <input
                class="number-field {{ lightMode ? 'light' : null }}"
                type="number"
                [value]="selectedValue ? selectedValue : ''"
                [min]="
                    filterInfo.rangeOptions ? filterInfo.rangeOptions.floor : 0
                "
                [max]="
                    filterInfo.rangeOptions ? filterInfo.rangeOptions.ceil : ''
                "
                [name]="filterInfo.label"
                [placeholder]="
                    filterInfo.placeholder
                        ? filterInfo.placeholder
                        : 'Select ' + filterInfo.label
                "
                (blur)="selectedValue = $event.target.value"
                (change)="numberResponseHandle($event.target.value)"
            />
        </ng-container>

        <!-- Date Range -->
        <ng-container
            *ngIf="
                filterInfo.type === FilterType.DATE_RANGE ||
                filterInfo.type === FilterType.WIDGET_DATE_RANGE ||
                filterInfo.type === FilterType.DATE_RANGE_TODAY
            "
        >
            <app-date-range-filter
                [selectedValue]="selectedValue"
                (dateOnChange)="dateChanged($event)"
                [minDateValue]="
                    filterInfo &&
                    filterInfo.dateRange &&
                    filterInfo.dateRange.minDate
                        ? filterInfo.dateRange.minDate
                        : {}
                "
                [maxDateValue]="
                    filterInfo &&
                    filterInfo.dateRange &&
                    filterInfo.dateRange.maxDate
                        ? filterInfo.dateRange.maxDate
                        : {}
                "
                [lightMode]="lightMode ? lightMode : false"
                [filterInfo]="filterInfo"
            >
            </app-date-range-filter>
        </ng-container>

        <!-- Checkbox with dropdown start -->
        <ng-container *ngIf="filterInfo.showCheckbox">
            <mat-checkbox
                color="accent"
                [disabled]="disabled"
                [(ngModel)]="isDropdownChecked"
                (change)="checkboxWithDropdownToggled($event.checked)"
            ></mat-checkbox>
        </ng-container>

        <!-- Radio Button-->
        <ng-container *ngIf="filterInfo.type === FilterType.RADIO">
            <label for="{{ filterInfo.label }}" class="label">
                <mat-radio-group
                    class="type-container"
                    (change)="radioResponseHandle($event.value)"
                    name="{{ filterInfo.label }}"
                >
                    <ng-container *ngFor="let label of filterInfo.listData">
                        <mat-radio-button
                            [checked]="selectedValue.id === label.id"
                            class="type"
                            value="{{ label.label }}"
                            name="{{ filterInfo.label }}"
                        >
                            <span class="common-label">{{ label.label }}</span>
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </label>
        </ng-container>

        <!-- JSON-->
        <ng-container *ngIf="filterInfo.type === FilterType.JSON">
            <textarea
                type="text"
                [placeholder]="filterInfo.placeholder"
                [value]="selectedValue ? selectedValue : ''"
                [disabled]="filterInfo['disable']"
                [name]="filterInfo.label"
                (change)="jsonResponseHandle($event.target.value)"
            ></textarea>
        </ng-container>

        <!-- Date Time Range -->
        <app-date-time-range
            *ngIf="filterInfo.type === FilterType.DATE_TIME_RANGE"
            [filterInfo]="filterInfo"
            [selectedValue]="selectedValue"
            [lightMode]="lightMode ? lightMode : false"
            [minDateValue]="
                filterInfo &&
                filterInfo.dateRange &&
                filterInfo.dateRange.minDate
                    ? filterInfo.dateRange.minDate
                    : {}
            "
            [maxDateValue]="
                filterInfo &&
                filterInfo.dateRange &&
                filterInfo.dateRange.maxDate
                    ? filterInfo.dateRange.maxDate
                    : {}
            "
            (dateTimeChange)="dateTimeChanged($event)"
        ></app-date-time-range>

        <!-- Customer User -->
        <app-customer-user-listing-filter
            *ngIf="filterInfo.type === FilterType.CUSTOMER_USER"
            [selectedValue]="selectedValue"
            [lightMode]="lightMode ? lightMode : false"
            [isCaching]="
                filterInfo && filterInfo.cachable !== undefined
                    ? filterInfo.cachable
                    : true
            "
            [refreshListing]="refreshListing"
            [filterInfo]="filterInfo"
            (valueChange)="customerUsersListingChanged($event)"
        ></app-customer-user-listing-filter>

        <!-- Text With Dropdown -->
        <ng-container *ngIf="filterInfo.type === FilterType.TEXT_WITH_DROPDOWN">
            <div class="tw-flex tw-justify-around tw-items-center">
                <input
                    type="number"
                    [value]="
                        selectedValue && selectedValue.frequency
                            ? selectedValue.frequency
                            : filterInfo.value.textValue
                    "
                    class="input-number"
                    (change)="textWithDropdown($event.target.value, 'text')"
                />
                <div class="tw-w-3/6">
                    <app-single-select-dropdown
                        [isCaching]="
                            filterInfo && filterInfo.cachable !== undefined
                                ? filterInfo.cachable
                                : true
                        "
                        [refreshListing]="refreshListing"
                        [filterInfo]="filterInfo"
                        [selectedValue]="
                            selectedValue && selectedValue.dateRange
                                ? selectedValue.dateRange
                                : filterInfo.value.dropdownValue
                        "
                        [clearable]="false"
                        (change)="textWithDropdown($event)"
                    ></app-single-select-dropdown>
                </div>
            </div>
        </ng-container>

        <div
            class="filters-overlay"
            [matTooltip]="disableMessage"
            *ngIf="disabled && disableMessage"
        ></div>
    </div>
</div>
