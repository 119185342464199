import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Logger } from 'src/app/shared/classes/Logger';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { HttpService } from '../../../../services/http/http-main/http.service';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { GlobalDataService } from './../../../../services/global-data/global-data.service';

@Component({
    selector: 'app-signup-component',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {
    @ViewChild(MatStepper)
    public matStepper: MatStepper;

    constructor(
        public whiteLabelService: WhiteLabelingService,
        public httpService: HttpService,
        public ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private notificationService: NotificationsService,
        private globalDataService: GlobalDataService,
        private listHttpService: ListHttpService
    ) {}
    logo: IIcon = {
        type: IconType.IMAGE,
        class: this.whiteLabelService.whiteLableData.darkLogo,
        alt: this.whiteLabelService.whiteLableData.companyName
    };
    loginImage: IIcon = {
        type: IconType.IMAGE,
        class: 'assets/anaplanImage.svg',
        alt: 'Anaplan'
    };
    loginImageGoogle: IIcon = {
        type: IconType.IMAGE,
        class: 'assets/googleImage.svg',
        alt: 'Google'
    };
    loginImageHpe: IIcon = {
        type: IconType.IMAGE,
        class: 'assets/hpeImage.svg',
        alt: 'Hpe'
    };
    loginImageJuniper: IIcon = {
        type: IconType.IMAGE,
        class: 'assets/juniperImg.svg',
        alt: 'Juniper'
    };
    bottomEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'bottom_ellipse',
        extraClass: 'svg-accent-fill'
    };

    topEllipse: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'top_ellipse',
        extraClass: 'svg-accent-fill'
    };
    doneIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-check',
        alt: 'done'
    };
    signupFormGenInput: IFormGeneratorInput = null;
    billingFormGenInput: IFormGeneratorInput = null;
    signupFormGroup: FormGroup = null;
    billingFormGroup: FormGroup = null;
    assesseeCreds: any;
    backButtonGenInput: IButtonGeneratorInput;
    signupFrom: string;
    juniperSignupFormGroup: FormGroup = null;
    juniperSignupFormGenInput: IFormGeneratorInput = null;

    ngOnInit(): void {
        Logger.codeLog('signupcomponent');
        this.globalDataService.signUp.subscribe((res) => {
            this.signupFrom = res;
        });
        this.logInView();
    }

    logInView() {
        Logger.codeLog('loginViewinSignup');
        this.signupFormGenInput = {
            formName: 'Sign Up',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Domain ID',
                    placeholder: 'Domain ID',
                    name: 'domainId',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.domainId
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain ID is required'
                        },
                        {
                            validator: CustomValidators.noSpecialCharacters,
                            errorMessage:
                                'No space and special character is allowed in Domain ID'
                        }
                    ],
                    infoMessage:
                        'Please enter your company name as domain ID with no space or special characters'
                },
                {
                    label: 'Company Name',
                    placeholder: 'Enter Company Name',
                    name: 'companyName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.companyName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company Name is required'
                        }
                    ],
                    infoMessage:
                        'Company Name in which the certification or badge will be issued.'
                },
                {
                    label: 'First Name',
                    placeholder: 'Enter First Name',
                    name: 'firstName',
                    fieldType: FilterType.TEXT,
                    fieldStyle: {
                        width: '49%',
                        'margin-right': '2%'
                    },
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.firstName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First Name is required'
                        }
                    ],
                    infoMessage:
                        "Input user details of the company's representative."
                },
                {
                    label: 'Last Name',
                    placeholder: 'Enter Last Name',
                    name: 'lastName',
                    fieldType: FilterType.TEXT,
                    fieldStyle: { width: '49%' },
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.lastName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last Name is required'
                        }
                    ]
                },
                {
                    label: 'Email ID',
                    placeholder: 'Enter Email ID',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.emailId
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email ID is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Contact Number',
                    placeholder: 'Enter Contact Number',
                    name: 'contactNumber',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.contactNumber
                        : null
                },
                {
                    label: 'Password',
                    placeholder: 'Enter Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.password
                        : '',
                    validations: Helper.getPasswordValidators(
                        'equal-password',
                        'confirmPassword'
                    )
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Enter Confirm Password',
                    name: 'confirmPassword',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    value: this.signupFormGroup
                        ? this.signupFormGroup.value.confirmPassword
                        : '',
                    validations: Helper.getPasswordValidators(
                        'equal-password',
                        'password'
                    )
                }
            ]
        };
        
        this.juniperSignupFormGenInput = {
            formName: 'Sign Up',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    label: 'Company Name',
                    placeholder: 'Enter Company Name',
                    name: 'companyName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.companyName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company Name is required'
                        }
                    ],
                    infoMessage:
                        'Company Name in which the certification or badge will be issued.'
                },
                {
                    label: 'First Name',
                    placeholder: 'Enter First Name',
                    name: 'firstName',
                    fieldType: FilterType.TEXT,
                    fieldStyle: {
                        width: '49%',
                        'margin-right': '2%'
                    },
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.firstName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First Name is required'
                        }
                    ],
                    infoMessage:
                        "Input user details of the company's representative."
                },
                {
                    label: 'Last Name',
                    placeholder: 'Enter Last Name',
                    name: 'lastName',
                    fieldType: FilterType.TEXT,
                    fieldStyle: { width: '49%' },
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.lastName
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Last Name is required'
                        }
                    ]
                },
                {
                    label: 'Email ID',
                    placeholder: 'Enter Email ID',
                    name: 'emailId',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.emailId
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email ID is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Contact Number',
                    placeholder: 'Enter Contact Number',
                    name: 'contactNumber',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.contactNumber
                        : null
                },
                {
                    label: 'Select Category',
                    placeholder: 'Select Category',
                    name: 'category',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.category
                        : '',
                    listData: [
                        {
                            id: 'Elite Plus Partner',
                            label: 'Elite Plus Partner'
                        },
                        {
                            id: 'Managed Network Provider',
                            label: 'Managed Network Provider'
                        }
                    ],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Category is required'
                        }
                    ],
                    containerClass: 'preferredLanguage'
                },
                {
                    label: 'VAR ID',
                    placeholder: '1234-5678',
                    name: 'varId',
                    fieldType: FilterType.TEXT,
                    required: true,
                    value: this.juniperSignupFormGroup
                        ? this.juniperSignupFormGroup.value.true
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'VAR ID is required'
                        },
                        {
                            validator: CustomValidators.noWhiteSpace,
                            errorMessage: 'No whitespace is allowed'
                        },
                        {
                            validator: CustomValidators.maxLength(9),
                            errorMessage: 'Maximum value of 8 characters is allowed'
                        }
                    ],
                }
            ]
        };
        
        if (this.signupFrom === 'hpe') {
            this.signupFormGenInput.fields.splice(6, 0, {
                label: 'HPE Party ID',
                placeholder: 'Enter HPE Party ID',
                name: 'hpePartyId',
                fieldType: FilterType.TEXT,
                required: true,
                value: this.signupFormGroup
                    ? this.signupFormGroup.value?.hpePartyId
                    : '',
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'HPE Party ID is required'
                    }
                ],
                suffixIcon: {
                    hoverText: 'See the HPE Maturity benchmark program guide for instructions to locate your HPE Party ID',
                    iconData: {
                        type: IconType.MATICON,
                        class: 'info'
                    }
                }
            });
            this.signupFormGenInput.fields[1].label = 'Company Name to appear on certificate';
            this.signupFormGenInput.submitButton = null;
            this.billingFormGenInput = {
                formName: 'Billing information',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'SPOC name & Email ID same as above',
                        placeholder: 'check',
                        name: 'check',
                        fieldType: FilterType.CHECKBOX,
                        onChange: this.generateNames.bind(this),
                        required: false,
                        fieldStyle: {
                            margin: '0px !important'
                        },
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.check
                            : null
                    },
                    {
                        label: 'Maturity Benchmark Center of Expertise *',
                        placeholder: 'Maturity Benchmark Center of Expertise',
                        name: 'selfAssessments',
                        fieldType: FilterType.DROPDOWN_MULTIPLE,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.selfAssessments
                            : '',
                        apiInfo: this.listHttpService.companyListApi,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Please select at least one.'
                            }
                        ]
                    },
                    {
                        label: 'Preferred Language',
                        placeholder: 'Preferred Language',
                        name: 'preferredLanguage',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        apiInfo: this.listHttpService.preferredLanguagesApi,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.preferredLanguage
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Preferred Language is required'
                            }
                        ],
                        containerClass: 'preferredLanguage'
                    },
                    {
                        label: 'First Name',
                        placeholder: 'Enter First Name',
                        name: 'billingFirstName',
                        fieldType: FilterType.TEXT,
                        fieldStyle: {
                            width: '49%',
                            'margin-right': '2%'
                        },
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingfirstName
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'First Name is required'
                            }
                        ]
                    },
                    {
                        label: 'Last Name',
                        placeholder: 'Enter Last Name',
                        name: 'billingLastName',
                        fieldType: FilterType.TEXT,
                        fieldStyle: { width: '49%' },
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingLastName
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Last Name is required'
                            }
                        ]
                    },
                    {
                        label: 'Email ID',
                        placeholder: 'Enter Email ID',
                        name: 'billingEmailId',
                        fieldType: FilterType.EMAIL,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingEmailId
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Email ID is required'
                            },
                            {
                                validator: CustomValidators.email,
                                errorMessage: 'Email is invalid'
                            }
                        ]
                    },
                    {
                        label: 'Address',
                        placeholder: 'Address',
                        name: 'billingAddress',
                        fieldType: FilterType.TEXTAREA,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingAddress
                            : null
                    }
                ]
            };
        } else if (this.signupFrom === 'juniper') {
            this.juniperSignupFormGenInput.submitButton = null;
            this.billingFormGenInput = {
                formName: 'Billing information',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Partner Location',
                        placeholder: 'Partner Location',
                        name: 'partnerLocation',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.partnerLocation
                            : '',
                        apiInfo: this.listHttpService.listCountries,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Partner location is required'
                            }
                        ]
                    },
                    {
                        name: 'timeZone',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        label: 'Time Zone',
                        placeholder: 'Time zone',
                        value:
                            this.billingFormGroup &&
                                this.billingFormGroup.value
                                ? this.billingFormGroup.value.timezone
                                : '',
                        apiInfo: this.listHttpService.newTimeZoneApi,
                        responseValueKey: 'dataList',
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Time zone is required'
                            }
                        ]
                    },
                    {
                        label: 'Preferred Language',
                        placeholder: 'Preferred Language',
                        name: 'preferredLanguage',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        apiInfo: this.listHttpService.preferredLanguagesApi,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.preferredLanguage
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Preferred Language is required'
                            }
                        ],
                        containerClass: 'preferredLanguage'
                    },
                    {
                        label: 'Billing Contact Name',
                        placeholder: 'Billing Contact Name',
                        name: 'billingContact',
                        fieldType: FilterType.TEXT,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingContact
                            : null
                    },
                    {
                        label: 'Billing Email',
                        placeholder: 'Billing Email',
                        name: 'billingEmailId',
                        fieldType: FilterType.EMAIL,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.biilingEmail
                            : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Email ID is required'
                            },
                            {
                                validator: CustomValidators.email,
                                errorMessage: 'Email is invalid'
                            }
                        ]
                    },
                    {
                        label: 'Billing Address',
                        placeholder: 'Billing Address',
                        name: 'billingAddress',
                        fieldType: FilterType.TEXT,
                        required: true,
                        value: this.billingFormGroup
                            ? this.billingFormGroup.value.billingAddress
                            : null
                    }
                ]
            };
        } else {
            this.billingFormGenInput = null;
        }

        this.backButtonGenInput = {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.SECONDARY,
            customClass: 'back-button',
            buttonType: ButtonType.STROKED,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.matStepper?.previous();
            },
            showLoader: true
        };
    }

    public get submitButtonGenInput(): IButtonGeneratorInput {
        return {
            buttonName:
                this.signupFrom === 'hpe'
                        || this.signupFrom === 'juniper'
                    ? this.matStepper?.selectedIndex === 0
                        ? 'Next'
                        : 'Register'
                    : 'Register',
            buttonColorType: ButtonColorType.PRIMARY,
            customClass: 'register-button',
            buttonType: ButtonType.RAISED,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.authenticateAndSignup(buttonRef);
            },
            showLoader: true
        };
    }

    authenticateAndSignup(btnRef?) {
        if (this.signupFrom === 'juniper') {
            this.signupFormGroup = this.juniperSignupFormGroup;
        }
        if (this.signupFormGroup && this.signupFormGroup.invalid) {
            this.signupFormGroup.markAllAsTouched();
            return;
        }
        if (this.billingFormGroup && this.matStepper?.selectedIndex === 0) {
            this.matStepper.next();
            this.matStepper.steps.first.completed = true;
            return;
        }
        if (this.billingFormGroup && this.billingFormGroup.invalid) {
            this.billingFormGroup.markAllAsTouched();
            return;
        }
        if (!btnRef) {
            return;
        }
        btnRef.loader = true;
        const {
            domainId,
            companyName,
            firstName,
            lastName,
            emailId,
            password,
            confirmPassword,
            contactNumber,
            hpePartyId,
            category,
            varId
        } = this.signupFormGroup.value;
        const input = {
            companyName: companyName,
            dataSourceId: 'us-east-1',
            domainId: domainId,
            dataRetentionMonths: 6,
            priceType: ['listPrice', 'myPrice'],
            allowDomainBasedUsers: false,
            user: {
                firstName: firstName,
                lastName: lastName,
                email: emailId,
                phoneNumber: contactNumber,
                password: password,
                confirmPassword: confirmPassword
            },
            ...(hpePartyId ? { hpePartyId } : {})
        };
        if (this.signupFormGroup && this.signupFormGroup.valid) {
            const apiArgs: IHitApi = {
                url:
                    this.signupFrom === 'anaplan'
                    ? ApiUrls.ANAPLAN_SIGN_UP
                    : this.signupFrom === 'google'
                    ? ApiUrls.GOOGLE_SIGN_UP
                    : this.signupFrom === 'hpe'
                    ? ApiUrls.HPE
                    : ApiUrls.JUNIPER_SIGN_UP,
                input: input,
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                function: (response) => {
                    if (response) {
                        btnRef.loader = false;
                        this.cd.detectChanges();
                        this.signupFormGroup && this.signupFormGroup.reset();
                        this.billingFormGroup && this.billingFormGroup.reset();
                        if ((this.signupFrom === 'hpe' || this.signupFrom === 'juniper') && this.matStepper) {
                            this.matStepper?.previous();
                        }
                        this.notificationService.showSnackBar(
                            'Thankyou for registering with us. Please check your mailbox for credentials.',
                            false,
                            '',
                            {
                                duration: 5000,
                                panelClass: '',
                                horizontalPosition: 'center',
                                verticalPosition: 'bottom'
                            }
                        );
                    }
                },
                errorFunction: (error) => {
                    btnRef.loader = false;
                    Helper.showErrorMessage(this.notificationService, error);
                    this.cd.detectChanges();
                },
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    skipBypassRegion: true
                }
            };
            if (this.signupFrom === 'hpe' || this.signupFrom === 'juniper') {
                const {
                    selfAssessments,
                    billingFirstName,
                    billingLastName,
                    billingEmailId,
                    billingAddress,
                    preferredLanguage,
                    partnerLocation,
                    timeZone,
                    billingContact,
                } = this.billingFormGroup.value;
                apiArgs.input = {
                    domainId: input.domainId,
                    companyName: input.companyName,
                    firstName: firstName,
                    lastName: lastName,
                    email: emailId,
                    contactNumber: contactNumber,
                    password: password,
                    confirmPassword: confirmPassword,
                    selfAssessments: selfAssessments,
                    billingContactFirstName: billingFirstName,
                    billingContactLastName: billingLastName,
                    billingEmailId: billingEmailId,
                    billingAddress: billingAddress,
                    partnerLocation: partnerLocation,
                    timeZone: timeZone,
                    category: category,
                    varId: varId,
                    billingContact: billingContact,
                    ...(input?.hpePartyId
                        ? { hpePartyId: input?.hpePartyId }
                        : {}),
                    preferredLanguage
                };
            }
            new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
        }
    }
    generateNames(res) {
        if (this.signupFormGroup.invalid) {
            this.signupFormGroup.markAllAsTouched();
            this.billingFormGroup.get('check').setValue(false);
        }
        if (res?.checked === false) {
            this.billingFormGroup?.get('billingFirstName')?.reset();
            this.billingFormGroup?.get('billingLastName')?.reset();
            this.billingFormGroup?.get('billingEmailId')?.reset();
        } else {
            this.billingFormGroup
                .get('billingFirstName')
                .setValue(this.signupFormGroup.value.firstName);
            this.billingFormGroup
                .get('billingLastName')
                .setValue(this.signupFormGroup.value.lastName);
            this.billingFormGroup
                .get('billingEmailId')
                .setValue(this.signupFormGroup.value.emailId);
        }
    }
}
