<!--Field type one start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_1">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #urlFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <mat-label>{{ field.label }}</mat-label>
            <input
                matInput
                type="url"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="field.prefixIcon ? urlFieldRef.updateOutlineGap() : ''"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type one end -->

<!--Field type two start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_2">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #urlFieldRef
            [appearance]="'outline'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <input
                matInput
                type="url"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="field.prefixIcon ? urlFieldRef.updateOutlineGap() : ''"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type two end -->

<!--Field type three start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_3">
    <div class="form-row {{ field.extraClass }}">
        <div class="form-label">
            {{ field.label }}
            <span
                *ngIf="field.required !== undefined ? field.required : true"
                class="mat-form-field-required-marker"
            >
                &nbsp;*
            </span>
            <ng-container
                *ngIf="field.showSuffixIconInLabel && field.suffixIcon"
            >
                &nbsp;
                <mat-icon
                    *ngIf="field.showSuffixIconInLabel && field.suffixIcon"
                    matSuffix
                    [matTooltip]="field.suffixIcon.hoverText"
                    class="clickable"
                    (click)="
                        field.suffixIcon.function
                            ? field.suffixIcon.function(field, formGroup)
                            : null
                    "
                >
                    {{ field.suffixIcon.iconData.class }}
                </mat-icon>
            </ng-container>
        </div>
        <mat-form-field
            class="form-input"
            #urlFieldRef
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
            [floatLabel]="'never'"
        >
            <input
                matInput
                type="url"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                (focus)="field.prefixIcon ? urlFieldRef.updateOutlineGap() : ''"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type three end -->

<!--Field type four start -->
<ng-container *ngIf="field.appearance === FieldAppearance.TYPE_4">
    <div class="form-row {{ field.extraClass }}">
        <mat-form-field
            #urlFieldRef
            [appearance]="'legacy'"
            [color]="'accent'"
            [class.disabled]="
                formGeneratorInput.state === FormState.IDLE || field.disabled
            "
        >
            <input
                matInput
                type="url"
                [placeholder]="field.placeholder"
                [name]="field.name"
                [formControl]="control"
                [errorStateMatcher]="matcher"
                [required]="
                    field.required !== undefined ? field.required : true
                "
                (focus)="field.prefixIcon ? urlFieldRef.updateOutlineGap() : ''"
            />
            <mat-icon
                *ngIf="field.prefixIcon"
                matPrefix
                [matTooltip]="field.prefixIcon.hoverText"
                class="clickable"
                (click)="
                    field.prefixIcon.function
                        ? field.prefixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.prefixIcon.iconData.class }}
            </mat-icon>
            <mat-icon
                *ngIf="field.suffixIcon"
                matSuffix
                [matTooltip]="field.suffixIcon.hoverText"
                class="clickable"
                (click)="
                    field.suffixIcon.function
                        ? field.suffixIcon.function(field, formGroup)
                        : null
                "
            >
                {{ field.suffixIcon.iconData.class }}
            </mat-icon>

            <!-- Handling errors -->
            <ng-container *ngIf="!field.groupByKey">
                <ng-template *ngTemplateOutlet="error"></ng-template>
            </ng-container>
            <ng-container *ngIf="field.groupByKey">
                <ng-template *ngTemplateOutlet="groupError"></ng-template>
            </ng-container>
        </mat-form-field>
    </div>
</ng-container>
<!--Field type four end -->

<!-- Error Templates -->
<ng-template #groupError>
    <ng-container
        *ngIf="
            formGroup.get(field.groupByKey).get(field.name).touched &&
            formGroup.get(field.groupByKey).get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation
                        : formGroup.get(field.groupByKey).get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
<ng-template #error>
    <ng-container
        *ngIf="
            formGroup.get(field.name).touched &&
            formGroup.get(field.name).errors
        "
    >
        <mat-error>
            {{
                field.validations
                    | formErrorValidation : formGroup.get(field.name).errors
            }}
        </mat-error>
    </ng-container>
</ng-template>
