<div class="controls" *ngIf="!(widgetRef.loadingData | async)">
    <div class="overview" *ngIf="cardInput.buttons || cardInput.bulkActions">
        <ng-container *ngFor="let button of cardInput.buttons">
            <div
                class="circle-button-with-loader {{
                    button && button.buttonColorType
                        ? 'card-btn-' + button.buttonColorType
                        : ''
                }}"
                [class.loader-active]="button.showLoader && button.loader"
                (click)="$event.stopPropagation(); button.function(button)"
                [class.disabled]="button.disable"
                [matTooltip]="button.hoverText ? button.hoverText : null"
            >
                <ng-container
                    [ngTemplateOutlet]="matSpinnerLoader"
                    [ngTemplateOutletContext]="{
                        buttonPassed: button
                    }"
                ></ng-container>
                <button
                    appIcon
                    [data]="button.buttonIcon"
                    [class.disabled]="button.disable"
                ></button>
            </div>
        </ng-container>

        <ng-container
            *ngIf="
                cardInput.bulkActions &&
                cardInput.bulkActions.length &&
                cardInput.selection === 'multiple'
            "
        >
            <div
                class="circle-button-with-loader card-btn-primary"
                [class.loader-active]="bulkLoader | async"
                matTooltip="Actions"
                [matMenuTriggerFor]="bulkActionsMenu"
                [class.disabled]="
                    totalSelectedCards <= 0 || (bulkLoader | async)
                "
                [matBadge]="totalSelectedCards"
                (click)="
                    totalSelectedCards <= 0 || bulkLoader.value
                        ? bulkActionsMenu.close.next('click')
                        : false
                "
            >
                <ng-container
                    *ngIf="bulkLoader | async"
                    [ngTemplateOutlet]="matSpinnerLoader"
                ></ng-container>
                <button
                    [class.disabled]="
                        totalSelectedCards <= 0 || (bulkLoader | async)
                    "
                >
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <mat-menu
                [disabled]="totalSelectedCards <= 0"
                #bulkActionsMenu="matMenu"
            >
                <div class="more-info-menu" style="min-width: fit-content">
                    <div
                        class="info-item"
                        *ngFor="let bulkAction of cardInput.bulkActions"
                        (click)="
                            bulkAction.function(
                                bulkAction,
                                getSelectedCards(),
                                bulkLoader
                            )
                        "
                        [class.disabled]="bulkAction.disable"
                    >
                        <div
                            class="icon"
                            style="margin-right: 8px"
                            *ngIf="bulkAction.buttonIcon"
                            appIcon
                            [data]="bulkAction.buttonIcon"
                            [class.disabled]="bulkAction.disable"
                        ></div>
                        <div
                            class="action-name"
                            *ngIf="bulkAction.buttonName"
                            [class.disabled]="bulkAction.disable"
                        >
                            {{ bulkAction.buttonName }}
                        </div>
                    </div>
                </div>
            </mat-menu>
        </ng-container>
        <ng-container
            *ngIf="
                cardInput.bulkActions &&
                cardInput.bulkActions.length &&
                cardInput.selection === 'multiple'
            "
        >
            <div
                [class.checked]="selectAll"
                class="circle-button-with-loader card-btn-primary"
                [class.disabled]="cardInput.disableSelectAll"
                [matTooltip]="selectAll ? 'Deselect All' : 'Select All'"
                (click)="
                    cardInput.disableSelectAll
                        ? widgetRef.lightState
                            ? Helper.getFullAccess(
                                  widgetRef.modalService,
                                  widgetRef.widgetData.widgetInfo.portlet.type
                              )
                            : null
                        : toggleSelection()
                "
            >
                <button [class.disabled]="cardInput.disableSelectAll">
                    <i class="fas fa-check-double"></i>
                </button>
            </div>
        </ng-container>
    </div>
    <div
        class="table-search"
        [style.marginLeft]="cardInput.buttons ? 'unset' : 'auto'"
        *ngIf="!this.widgetRef.lightState && cardsData && cardsData.length"
    >
        <app-table-search
            [data]="cardsData"
            (changed)="filteredCardsData = $event"
            [disableInputField]="this.widgetRef.lightState"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    *ngIf="!(widgetRef.loadingData | async) && cardsData && cardsData.length"
>
    <ng-container *ngIf="cardsData && cardsData.length">
        <ng-container
            *ngFor="let card of filteredCardsData; let cardIndex = index"
        >
            <div
                class="card"
                (click)="
                    cardInput.selection === 'multiple'
                        ? handleCardClick(card)
                        : false
                "
                [class.selected]="card.CGselected"
                [style.cursor]="
                    cardInput.selection === 'multiple' ? 'pointer' : 'default'
                "
            >
                <div class="card-head">
                    <div
                        class="card-title"
                        *ngIf="cardInput.cardTitle"
                        [matTooltip]="
                            cardInput.cardTitle.infoKey
                                | objectDataExtractor: card
                        "
                    >
                        {{
                            cardInput.cardTitle.infoKey
                                | objectDataExtractor: card
                        }}
                    </div>
                    <div
                        class="card-title-actions"
                        *ngIf="cardInput.titleActions"
                    >
                        <ng-container
                            *ngFor="
                                let titleAction of cardInput.titleActions
                                    | cardButtonsDereferencer
                            "
                        >
                            <div class="circle-button-with-loader">
                                <ng-container
                                    [ngTemplateOutlet]="matSpinnerLoader"
                                    [ngTemplateOutletContext]="{
                                        buttonPassed: titleAction
                                    }"
                                ></ng-container>

                                <button
                                    appIcon
                                    [data]="titleAction.buttonIcon"
                                    [ngStyle]="
                                        titleAction.ngStyleRenderer
                                            ? titleAction.ngStyleRenderer(card)
                                            : null
                                    "
                                    [class.disabled]="titleAction.disable"
                                    (click)="
                                        $event.stopPropagation();
                                        titleAction.function(titleAction, card)
                                    "
                                    [matTooltip]="
                                        titleAction.hoverText
                                            ? titleAction.hoverText
                                            : titleAction.hoverTextRenderer
                                            ? titleAction.hoverTextRenderer(
                                                  card
                                              )
                                            : null
                                    "
                                ></button>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div
                    class="card-info"
                    [ngStyle]="
                        cardInfoTemplate && cardInfoTemplateStyles
                            ? cardInfoTemplateStyles
                            : null
                    "
                    [ngClass]="
                        cardInfoTemplateClasses ? cardInfoTemplateClasses : null
                    "
                >
                    <ng-container *ngIf="cardInfoTemplate">
                        <ng-container
                            *ngTemplateOutlet="
                                cardInfoTemplate;
                                context: { card: card }
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            !cardInfoTemplate &&
                            cardInput.infoData &&
                            cardInput.infoData.length
                        "
                    >
                        <ng-container *ngFor="let info of cardInput.infoData">
                            <div class="info-item">
                                <div class="info-heading">
                                    {{ info.infoTitle }}
                                </div>
                                <div
                                    class="info-value"
                                    [matTooltip]="
                                        info.infoRenderer
                                            ? (info.infoRenderer
                                              | cardDataRenderer: card)
                                            : (info.infoKey
                                              | objectDataExtractor: card)
                                    "
                                >
                                    <ng-container *ngIf="info.infoRenderer">
                                        {{
                                            info.infoRenderer
                                                | cardDataRenderer: card
                                        }}
                                    </ng-container>
                                    <ng-container *ngIf="!info.infoRenderer">
                                        {{
                                            info.infoKey
                                                | objectDataExtractor: card
                                        }}
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="card-actions" *ngIf="cardInput.cardButtons">
                    <ng-container
                        *ngFor="
                            let cardButton of cardInput.cardButtons
                                | cardButtonsDereferencer
                        "
                    >
                        <div class="circle-button-with-loader">
                            <ng-container
                                [ngTemplateOutlet]="matSpinnerLoader"
                                [ngTemplateOutletContext]="{
                                    buttonPassed: cardButton
                                }"
                            ></ng-container>
                            <button
                                appIcon
                                [data]="cardButton.buttonIcon"
                                [ngStyle]="
                                    cardButton.ngStyleRenderer
                                        ? cardButton.ngStyleRenderer(card)
                                        : null
                                "
                                [style.color]="
                                    cardButton.buttonColorType ===
                                    ButtonColorType.PRIMARY
                                        ? 'var(--primaryButtonColor)'
                                        : cardButton.buttonColorType ===
                                          ButtonColorType.SECONDARY
                                        ? 'var(--secondaryButtonColor)'
                                        : cardButton.buttonColorType ===
                                          ButtonColorType.WARN
                                        ? 'var(--negativeColor)'
                                        : 'var(--secondaryButtonColor)'
                                "
                                (click)="
                                    $event.stopPropagation();
                                    cardButton.function(cardButton, card)
                                "
                                [class.disabled]="cardButton.disable"
                                [matTooltip]="
                                    cardButton.hoverText
                                        ? cardButton.hoverText
                                        : cardButton.hoverTextRenderer
                                        ? cardButton.hoverTextRenderer(card)
                                        : null
                                "
                            ></button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-template #matSpinnerLoader let-button="buttonPassed">
    <ng-container *ngIf="button">
        <mat-spinner
            [class.primary]="button.buttonColorType === ButtonColorType.PRIMARY"
            [class.secondary]="
                button.buttonColorType === ButtonColorType.SECONDARY
            "
            [class.success]="button.buttonColorType === ButtonColorType.SUCCESS"
            [class.info]="button.buttonColorType === ButtonColorType.INFO"
            [class.warn]="button.buttonColorType === ButtonColorType.WARN"
            [diameter]="28"
            *ngIf="button.showLoader && button.loader"
        >
        </mat-spinner>
    </ng-container>
    <ng-container *ngIf="!button">
        <mat-spinner [ngClass]="'primary'" [diameter]="28"></mat-spinner>
    </ng-container>
</ng-template>
