import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SwPush, SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { environment } from 'src/environments/environment';
import { HitApi } from '../../classes/HitApi';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { IconType } from '../../enums/IconType';
import { RequestType } from '../../enums/RequestType';
import { IAssessmentStatus } from '../../interfaces/assessmentStatus/IAssessmentStatus';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { Logger } from './../../classes/Logger';
import { NotificationType } from './../../enums/NotificationType';
import { IToastNotificationConfig } from './../../interfaces/notification/IToastNotificationConfig';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    // Toast variables
    toastSub: BehaviorSubject<IToastNotificationConfig[]> = new BehaviorSubject(
        []
    );
    bannerMessages: Map<
        string | Symbol,
        { buttonName?: string; function: Function; message: string }
    > = new Map();
    updateControlPointStatus: BehaviorSubject<IAssessmentStatus> =
        new BehaviorSubject(null);

    constructor(
        private _snackBar: MatSnackBar,
        private swUpdate: SwUpdate,
        private swPush: SwPush,
        private httpService: HttpService
    ) {}

    initPushNotificationConnection() {
        if (this.swUpdate.isEnabled) {
            this.swPush
                .requestSubscription({
                    serverPublicKey: GlobalConfiguration.VAPID_KEYS.publicKey
                })
                .then((sub) => {
                    this.postSubscription(sub).subscribe();
                });
        }
    }

    private postSubscription(subscription: PushSubscription): Observable<any> {
        return this.httpService
            .hitApi(
                ApiUrls.PUSH_NOTIFICATIONS_ENDPOINT,
                RequestType.POST,
                subscription,
                {
                    authorization: AuthorizationType.BEARER_TOKEN
                }
            )
            .pipe(
                catchError((error) => {
                    return throwError(error.error.message);
                })
            );
    }

    showSnackBar(
        message: string,
        isError?: boolean,
        actionName?: string,
        config?: MatSnackBarConfig
    ) {
        this._snackBar.open(
            `${message}`,
            `${actionName ? actionName : ''}`,
            config
                ? config
                : {
                      duration: 2000,
                      panelClass: isError ? 'warning' : '',
                      horizontalPosition: 'center',
                      verticalPosition: 'bottom'
                  }
        );
    }

    showToast(
        type: NotificationType,
        heading: string,
        message: string,
        config?: IToastNotificationConfig
    ): symbol {
        if (!config) {
            config = {
                type: type,
                title: heading,
                message: message,
                toastIcon: null
            };
        }
        if (config.type === NotificationType.ERROR) {
            if (!config.toastIcon) {
                config.toastIcon = {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-circle'
                };
            }
            config.background = '#ffe7e0';
            config.textColor = '#464946';
            config.borderColor = '#ff8964';
            config.iconColor = '#ff8964';
        } else if (config.type === NotificationType.INFORMATION) {
            if (config.toastIcon) {
                config.toastIcon = {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-info-circle'
                };
            }
            config.background = '#e0eefb';
            config.textColor = '#464946';
            config.borderColor = '#62aced';
            config.iconColor = '#62aced';
        } else if (config.type === NotificationType.WARNING) {
            if (config.toastIcon) {
                config.toastIcon = {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-triangle'
                };
            }
            config.background = '#fff1db';
            config.textColor = '#464946';
            config.borderColor = '#ffb74d';
            config.iconColor = '#ffb74d';
        } else if (config.type === NotificationType.SUCCESS) {
            if (config.toastIcon) {
                config.toastIcon = {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-check-circle'
                };
            }
            config.background = '#e6f4e7';
            config.textColor = '#464946';
            config.borderColor = '#82c785';
            config.iconColor = '#82c785';
        }
        config.toastId = Symbol();
        if (!config.showTime) {
            config.showTime = 5000;
        }

        const toasts = this.toastSub.value;
        toasts.push(config);
        this.toastSub.next(toasts);
        return config.toastId;
    }

    hideToast(toastId: symbol) {
        if (this.toastSub.value && this.toastSub.value.length) {
            const toasts = this.toastSub.value.filter(
                (toast) => toast.toastId !== toastId
            );
            this.toastSub.next(toasts);
        }
    }

    /**
     * Fetches notification data from multiple regions in parallel and invokes a callback with the response and timer.
     * @param {Function} callback - The callback function to handle the notification data.
     */
    getNotificationData(callback) {
        let region = 1;
        let apiErrorCount = 1;
        const parallelApis: IHitApi[] = [];
        while (region <= 4) {
            const apiConfig: IHitApi = {
                url: ApiUrls.CUSTOM_NOTIFICATION_API,
                input: {},
                requestType: RequestType.GET,
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true,
                },
                uniqueIdentity: Symbol(),
                function: (response) => {
                    Logger.codeLog('Hitting Notification Api');
                    Logger.codeLog(`Enviornment, ${environment}`);
                    if (response?.environment?.includes(environment.baseUrl)) {
                        const timer: number = this.getTimer(response?.time);
                        // If there is a timer, schedule the next API call after the timer expires
                        if (timer > 0) {
                            this.httpService.ngZone.run(() => {
                                setTimeout(() => {
                                    this.getNotificationData(callback);
                                }, timer);
                            });
                            callback(response, timer);
                        }
                    }
                },
                errorFunction: (response) => {
                    // Check if there is a response and if it's the last region with an error
                    if (response && !response.environment && apiErrorCount === 4) {
                        return;
                    }
                    apiErrorCount++;
                },
            };
            // Update the API URL for the current region
            apiConfig.url = apiConfig.url.replace('{region}', '' + region);
            parallelApis.push(apiConfig);
            region++;
        }
        // Make parallel API requests using the HTTP service
        this.httpService.hitParallelApis(parallelApis);
    }

    getTimer(date): number {
        let timer: number = 0;
        const msgDate = moment.utc(date);
        const currentDate = moment.utc().format();
        const secDiff = msgDate.diff(currentDate, 'seconds');
        if (secDiff > 0) {
            timer = secDiff * 1000;
        }
        return timer;
    }
    showBanner(message: string, buttonName?: string, fn?: Function): Symbol {
        const _id = Symbol();
        this.bannerMessages.set(_id, {
            buttonName: buttonName,
            function: fn,
            message: message
        });
        return _id;
    }
    closeBanner(id: Symbol) {
        this.bannerMessages.delete(id);
    }
}
