import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';
/**
 * Used for the fields which are taking the input from the key of any other control of type JSON.
 * Validates whether other control(type JSON) contains a specific key or not. If it doesn't contains that key then it will show errors for the fields which are dependent on that control(type JSON).
 */
export class OtherJsonControlKeyValueValidator extends CustomValidator {
    key = CustomValidatorKey.OTHER_JSON_CONTROL_KEY_VALUE_VALIDATOR;

    /**
     *
     * @param jsonValue JSON which is the value of other control(type JSON).
     * @param value key which should be present in the JSON, otherwise the validator will throw error.
     */
    constructor(private jsonValue, private value: string) {
        super();
    }
    /**
     *
     * @param control not used in the function because current control's value is coming from other control(type JSON).
     * @returns If value key of jsonValue is null/undefined or contains this.value(i.e. key) then return nothing
     *          else it will return object representing an error for the existing control (not for the other control(type JSON)).
     */
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (!this.jsonValue.value || this.jsonValue.value[this.value]) {
            return;
        }
        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
