import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class ControlValueNotInValidator extends CustomValidator {
    key = CustomValidatorKey.CONTROL_VALUE_NOT_IN;

    constructor(private values: string[], private caseSensitive: boolean) {
        super();
    }
    validatorFunction(control: AbstractControl): ValidationErrors {
        if (this.caseSensitive) {
            if (!this.values.includes(control.value)) {
                return null;
            }
        } else if (
            control.value &&
            control.value.length &&
            !this.values
                .map((val) => val.toLocaleLowerCase())
                .includes(control.value.toLowerCase())
        ) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
