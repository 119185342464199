import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { ILoginResponse } from 'src/app/shared/interfaces/api/portlets/ILoginResponse';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { SessionService } from 'src/app/shared/services/session/session.service';

@Component({
    selector: 'app-landing-page-selection',
    templateUrl: './landing-page-selection.component.html',
    styleUrls: ['./landing-page-selection.component.sass']
})
export class LandingPageSelectionComponent implements OnInit {
    widgetRef: Widget = null;
    landingPageFormGenInput: IFormGeneratorInput = null;
    landingPageFormGroup: FormGroup = null;
    landingNodeExists = false;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Cancel',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.modalService.closeModal(null, this.modalInputData.modalId);
            }
        },
        {
            buttonName: 'Save View',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            loader: false,
            function: (buttonRef) => {
                this.saveView(buttonRef);
            }
        }
    ];

    prepareGroupedInput: Function;

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between'
        }
    };

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        private viewHttpService: ViewHttpService,
        private globalDataService: GlobalDataService,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        if (this.modalInputData.data) {
            this.widgetRef = this.modalInputData.data['widgetRef'];
            this.prepareGroupedInput =
                this.modalInputData.data['prepareGroupedInputFn'];
            this.landingPageFormGenInput = {
                state: FormState.CREATE,
                formName: 'Landing Page',
                submitButton: null,
                fields: [
                    {
                        name: 'landingPage',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        label: 'Landing Page',
                        placeholder: 'Select Landing Page',
                        listData: this.getLeafNodes(
                            this.modalInputData.data['tree'],
                            this.modalInputData.data['rootNode']
                        ),
                        required: true,
                        value:
                            this.modalInputData.data.landingPage &&
                            this.landingNodeExists
                                ? this.modalInputData.data.landingPage.nodeId
                                : '',
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Landing page is required'
                            }
                        ]
                    }
                ]
            };
        }
    }

    getLeafNodes(
        tree: Tree<IAttributeInfo>,
        rootNode: Node<IAttributeInfo>
    ): IDropdownData[] {
        const leafNodes: IDropdownData[] = [];
        tree.traverseTree(
            (
                node: Node<IAttributeInfo>,
                parentNode: Node<IAttributeInfo>,
                cancelTraversalFunction
            ) => {
                if (
                    !node.children.length &&
                    node.data.widgets &&
                    node.data.widgets.length
                ) {
                    if (
                        node &&
                        this.modalInputData &&
                        this.modalInputData.data &&
                        this.modalInputData.data.landingPage &&
                        this.modalInputData.data.landingPage.nodeId ===
                            node.nodeId
                    ) {
                        this.landingNodeExists = true;
                    }
                    leafNodes.push({
                        id: node.nodeId,
                        label: this.getParentHierarchyOfNode(node)
                            .reverse()
                            .join(' > ')
                    });
                }
            }
        );
        return leafNodes;
    }

    getParentHierarchyOfNode(node: Node<IAttributeInfo>): string[] {
        const rootNode: Node<IAttributeInfo> =
            this.modalInputData.data['rootNode'];
        const hierarchy = [];
        while (node.nodeId !== rootNode.nodeId) {
            hierarchy.push(node.data.attributeName);
            node = (
                this.modalInputData.data['tree'] as Tree<IAttributeInfo>
            ).findNode(node.parentNodeId);
        }
        return hierarchy;
    }

    saveView(buttonRef: IButtonGeneratorInput) {
        if (this.landingPageFormGroup.invalid) {
            Helper.markAllFieldAsTouched(this.landingPageFormGroup);
            return;
        }
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const rootNode: Node<IAttributeInfo> =
            this.modalInputData.data['rootNode'];
        const reqInput = {
            viewName: this.modalInputData.data['view']['viewName'],
            viewIcon: this.modalInputData.data['view']['viewIcon']['class'],
            viewIconType: this.modalInputData.data['view']['viewIcon']['type'],
            acl: this.modalInputData.data['acl'],
            attributes: rootNode.children.map((child) =>
                this.moduleToAttribute(child)
            )
        };
        const args = Helper.generateHitApiConfig(
            this.modalInputData.data.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );

        if (this.modalInputData.data.edit) {
            args.intactUrl = args.url;
            args.url = args.url.replace(
                '{viewId}',
                this.modalInputData.data.viewId
            );
        }
        args.input = reqInput;
        args.function = (response) => {
            if (this.modalInputData.data.edit) {
                if (
                    this.globalDataService.selectedView ===
                    this.modalInputData.data.viewId
                ) {
                    this.sessionService.hitSideMenuApi(
                        {
                            defaultViewId: this.modalInputData.data.viewId
                        } as ILoginResponse,
                        this.viewHttpService,
                        true,
                        null,
                        () => {
                            this.widgetRef.notificationsService.showSnackBar(
                                `${args.input.viewName} updated successfully. Any widget permission changes will be reflected on next login.`
                            );
                        }
                    );
                    return;
                }
                this.widgetRef.notificationsService.showSnackBar(
                    `${args.input.viewName} updated successfully. Any widget permission changes will be reflected on next login.`
                );
            } else {
                this.widgetRef.notificationsService.showSnackBar(
                    `${args.input.viewName} created successfully`
                );
            }
            this.widgetRef.refreshWidget();
            this.modalService.closeModal(
                null,
                this.modalInputData.data['parentModalId']
            );
            this.modalService.closeModal(null, this.modalInputData.modalId);
        };
        args.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                `Error ${
                    this.modalInputData.data.edit ? 'updating' : 'creating'
                } view`
            );
            buttonRef.loader = false;
        };
        new HitApi(
            args,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    moduleToAttribute(node: Node<IAttributeInfo>) {
        const attrInfo = {
            attributeName: node.data.attributeName,
            iconType: node.data.iconType,
            icon: node.data.icon,
            ishomePage:
                this.landingPageFormGroup.get('landingPage').value ===
                node.nodeId
                    ? true
                    : false
        };
        if (
            !node.children.length &&
            node.data.widgets &&
            node.data.widgets.length
        ) {
            if (this.prepareGroupedInput) {
                attrInfo['widgets'] = this.prepareGroupedInput(
                    node.data.widgets
                );
            } else {
                attrInfo['widgets'] = node.data.widgets;
            }
            attrInfo['leafNode'] = true;
        } else {
            attrInfo['leafNode'] = false;
            attrInfo['attributes'] = node.children.map((child) =>
                this.moduleToAttribute(child)
            );
        }
        return attrInfo;
    }
}
