<div class="container">
    <ng-container *ngIf="valueMappingFormGenInput; else loader">
        <br />

        <div class="heading">
            <div>
                {{ selectedField.label }}
            </div>

            <div>
                {{ serviceNowField.label }}
            </div>
        </div>

        <br />
        <hr />

        <app-form-generator
            *ngIf="valueMappingFormGenInput"
            [formGeneratorInput]="valueMappingFormGenInput"
            (formGroupRef)="valueMappingFormGroupRef = $event"
        >
        </app-form-generator>

        <br />

        <span class="text-danger font-size">
            *Marked fields values are not mapped. Please map the values to
            proceed ahead
        </span>
        <br />

        <div class="button">
            <app-button-generator [buttonGenInput]="buttonInput">
            </app-button-generator>
        </div>

        <br />
    </ng-container>
</div>

<ng-template #loader>
    <div class="overlay-content">
        <mat-spinner class="dark-theme" [diameter]="24"></mat-spinner>
    </div>
</ng-template>
