<app-table-generator
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    [tableData]="tableData"
    (gridRef)="gridRef = $event"
    [overviewTemplateStart]="overviewTemplateStart"
></app-table-generator>
<ng-template #overviewTemplateStart>
    <div
        class="general-details tw-flex tw-h-14 tw-my-1 tw-mx-0"
        *ngIf="generalData"
    >
        <div
            class="total-amount tw-cursor-pointer tw-h-8 tw-mt-4 tw-rounded-md tw-my-0 tw-mx-2 tw-justify-center tw-flex tw-items-center tw-text-fs-400 tw-py-4 tw-px-2"
        >
            <span class="text tw-font-normal tw-text-fs-200">Total Cost:</span>
            &nbsp; &nbsp;
            <span
                class="amount tw-font-semibold tw-w-16 tw-whitespace-nowrap tw-overflow-hidden tw-overflow-ellipsis"
                [matTooltip]="generalData.TotalCost"
                >{{
                    generalData.currencySymbol + " " + generalData.TotalCost
                }}</span
            >
        </div>
        <div class="payer-acc tw-w-56">
            <app-form-generator
                [formGeneratorInput]="payerAccFormGenInputs"
                (formGroupRef)="payerAccFormGroup = $event"
                (valuesChange)="accountIdSelected($event)"
                (updateControl)="updateControl = $event"
            >
            </app-form-generator>
        </div>
        <div class="hide-icon tw-flex tw-items-center tw-py-0 tw-px-2">
            <mat-icon
                matSuffix
                [matTooltip]="
                    hideCustomer
                        ? 'Click here to show customer.'
                        : 'Click here to hide customer.'
                "
                class="clickable"
                (click)="hideCustomer = !hideCustomer"
            >
                {{ hideCustomer ? "visibility" : "visibility_off" }}
            </mat-icon>
        </div>
        <div class="btn tw-flex tw-items-center tw-py-0 tw-px-2">
            <app-button-generator [buttonGenInput]="buttonInput">
            </app-button-generator>
        </div>
    </div>
</ng-template>
