<ng-container *ngIf="graphData.graphType === GraphType.PIE">
    <app-pie-graph [graphData]="graphData"></app-pie-graph>
</ng-container>
<ng-container *ngIf="graphData.graphType === GraphType.SEMI_PIE">
    <app-semi-pie [graphData]="graphData"></app-semi-pie>
</ng-container>
<ng-container *ngIf="graphData.graphType === GraphType.LINE">
    <app-xy-chart [graphData]="graphData"></app-xy-chart>
</ng-container>
<ng-container *ngIf="graphData.graphType === GraphType.STACK_BAR">
    <app-stack-bar-graph [graphData]="graphData"></app-stack-bar-graph>
</ng-container>
<ng-container *ngIf="graphData.graphType === GraphType.MAP">
    <app-map-chart [graphData]="graphData"></app-map-chart>
</ng-container>
<ng-container *ngIf="graphData.graphType === GraphType.RADAR">
    <app-radar-chart [graphData]="graphData"></app-radar-chart>
</ng-container>
