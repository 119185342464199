import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IInfoModal } from './../../../interfaces/modal/IInfoModal';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.sass']
})
export class InfoModalComponent implements OnInit {
    infoData: IInfoModal;
    SanitizeTypes = SanitizeTypes;
    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.infoData = this.modalInputData.data;
    }
}
