<app-vertical-scrolling-tab-group [modalId]="modalData.modalId">
    <ng-template appVerticalScrollingTab [tabLabel]="'Consumption Information'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #consumptionInfo
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Consumption Information" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!consumptionInfo.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="consumptionInfo.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="table-container">
                    <app-only-table-generator
                        *ngIf="consumptionInfoTableData"
                        [tableData]="consumptionInfoTableData"
                        [tableInput]="consumptionInfoTableGen"
                        [defaultColDef]="defaultColDef"
                        [hideControlBar]="true"
                    ></app-only-table-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>

    <ng-container *ngIf="data.card['Consumption Information Break Up']">
        <ng-template
            appVerticalScrollingTab
            [tabLabel]="'Consumption Break Up'"
        >
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    class="tw-py-2"
                    #consumptionInfo
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ "Consumption Break Up" }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionInfo.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionInfo.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="table-container">
                        <app-only-table-generator
                            *ngIf="consumptionBreakUpTableData"
                            [defaultColDef]="defaultColDef"
                            [tableData]="consumptionBreakUpTableData"
                            [tableInput]="consumptionBreakUpTableGen"
                            [hideControlBar]="true"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </ng-container>

    <ng-template appVerticalScrollingTab [tabLabel]="'General Information'">
        <mat-accordion displayMode="flat" multi>
            <mat-expansion-panel
                class="tw-py-2"
                #generalInfo
                hideToggle="true"
                [expanded]="true"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "General Information" }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span
                            *ngIf="!generalInfo.expanded"
                            appIcon
                            [data]="arrowDownIcon"
                        ></span>
                        <span
                            *ngIf="generalInfo.expanded"
                            appIcon
                            [data]="arrowUpIcon"
                        ></span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="table-container">
                    <app-only-table-generator
                        *ngIf="generalInformationTableData"
                        [tableData]="generalInformationTableData"
                        [tableInput]="generalInformationTableGen"
                        [defaultColDef]="defaultColDef"
                        [hideControlBar]="true"
                    ></app-only-table-generator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</app-vertical-scrolling-tab-group>
