import { Component, OnInit } from '@angular/core';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';

@Component({
    selector: 'app-description-preview',
    templateUrl: './description-preview.component.html',
    styleUrls: ['./description-preview.component.sass']
})
export class DescriptionPreviewComponent implements OnInit {
    constructor(public modalInputData: ModalInjectedData) {}
    ngOnInit(): void {}
}
