import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { ButtonType } from './../../../../enums/ButtonType';
import { IconType } from './../../../../enums/IconType';
import { ModalType } from './../../../../enums/ModalType';
import { ViewType } from './../../../../enums/ViewType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from './../../../../interfaces/table-generator/ITableGeneratorInput';
import { FreshserviceCreateTicketComponent } from './../../../modal-templates/integration/freshservice-modal/freshservice-create-ticket/freshservice-create-ticket.component';

@Component({
    selector: 'app-freshservice-listing-v2',
    templateUrl: './freshservice-listing-v2.component.html',
    styleUrls: ['./freshservice-listing-v2.component.sass']
})
export class FreshserviceListingV2Component implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    freshServiceStatusResponse = null;
    widgetIcon: IIcon = {
        class: 'assets/integrations/freshservice-plugin.png',
        type: IconType.IMAGE
    };
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);

        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Create Ticket',
                    buttonType: ButtonType.RAISED,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        const modalArgs = {
                            modalName: 'Fresh Service Create Ticket',
                            sourceId:
                                this.widgetRef.widgetData
                                    .widgetUniqueIdentifier,
                            modalType: ModalType.MIDDLE,
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-ticket-alt'
                            },
                            modalSteps: [
                                {
                                    stepName: 'Fresh Service Create Incident',
                                    stepData: {
                                        componentToLoad:
                                            FreshserviceCreateTicketComponent,
                                        payload: {
                                            data: {
                                                widgetRef: this.widgetRef
                                            }
                                        }
                                    }
                                }
                            ],
                            modalWidthVw: 50,
                            modalHeightVh: 95
                        };
                        this.widgetRef.modalService.openModal(modalArgs);
                    },

                    showLoader: false
                }
            ],
            columns: [],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'userTickets'
            },
            noDataMessage: 'No Tickets Available',
            widgetIconData: this.widgetIcon,
            afterResponse: (response) => {
                this.tableGenInput.columns = [];
                if (response['fieldsToShow']) {
                    response['fieldsToShow'].forEach((value) => {
                        this.tableGenInput.columns.push({
                            columnKey: value,
                            columnName: value
                        });
                    });
                }
            }
        };
    }
}
