import { Component, OnInit } from '@angular/core';
import { RowEvent } from 'ag-grid-community';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { WidgetPreviewModalComponent } from '../../widget-preview-modal/widget-preview-modal.component';
import { ModalInjectedData } from './../../../classes/ModalInjectedData';
import { MultiButtonGeneratorComponent } from './../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-azure-sponsorship-onboarding-impact-modal',
    templateUrl: './azure-sponsorship-onboarding-impact-modal.component.html',
    styleUrls: ['./azure-sponsorship-onboarding-impact-modal.component.sass']
})
export class AzureSponsorshipOnboardingImpactModalComponent implements OnInit {
    tabs: string[] = [];
    data: IImpactModalData | object; // Object is used as type because the data is being modified further in the code
    tableInput: ITableGeneratorInput;
    crossIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fa fa-times'
    };
    constructor(
        public modalInjectedData: ModalInjectedData,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.data = this.modalInjectedData.data['impactModalData'];
        this.tabs = Object.keys(this.data);
        for (let i = 0; i < this.tabs.length; i++) {
            this.data[this.tabs[i]].map((data) => {
                Object.keys(data).forEach((key) => {
                    data['name'] = key;
                });
            });
        }
        this.prepareTableInput();
    }
    prepareTableInput() {
        this.tableInput = {
            widgetIconData: null,
            listExtraction: { type: 'DIRECT' },
            columns: [
                {
                    columnKey: 'name',
                    columnName: 'name',
                    cellClass: 'left-column',
                    cellStyle: () => {
                        return {
                            justifyContent: 'center'
                        };
                    }
                },
                {
                    columnKey: '',
                    columnName: '',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'View',
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.TEXT,
                                function: this.openWidgetPreviewModal.bind(
                                    this,
                                    rowData.data
                                )
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'center'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                }
            ],
            headerHeight: 0
        };
    }

    openWidgetPreviewModal(rowData) {
        const _viewID =
            rowData[rowData['name']]['viewId'] &&
            rowData[rowData['name']]['viewId'].length
                ? rowData[rowData['name']]['viewId'][0]
                : null;
        if (!_viewID) {
            return;
        }
        const widgetData = {
            'Widget Id': rowData[rowData['name']]['widgetId'],
            'View Id': _viewID,
            ...rowData[rowData['name']]['layerData'][_viewID]
        };
        const modalData: IModalData = {
            modalName: rowData['name'] ? rowData['name'] : 'Preview',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-eye'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalWidthVw: 80,
            modalAutoHeight: true,
            maxHeightVh: 98,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: WidgetPreviewModalComponent,
                        payload: {
                            data: {
                                widgetData
                            }
                        }
                    },
                    stepName: 'Preview'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
}

interface IImpactModalData {
    [tab: string]: {
        [ruleName: string]: IRuleData;
    }[];
}
interface IRuleData {
    layerData: {
        [key: string]: {
            Layer1?: string;
            Layer2?: string;
            Layer3?: string;
            Layer4?: string;
        };
    };
    viewId: string[];
    widgetId: string;
}
