<h2 class="tw-text-fs-800 tw-font-semibold tw-text-center tw-font-heading">
    Description
</h2>
<div class="data">
    {{ modalData.data?.description }}
</div>
<div class="tw-text-fs-200 container">
    <div class="row">
        <div class="label">IMPACT:</div>
        <div class="value">{{impactUrgencyValueDetails[modalData.data?.impact]}}</div>
    </div>
    <div class="row">
        <div class="label">URGENCY:</div>
        <div class="value">{{impactUrgencyValueDetails[modalData.data?.urgency]}}</div>
    </div>
</div>
