import { Component, Input, OnInit } from '@angular/core';
import { ModalInjectedData } from '../../classes/ModalInjectedData';

@Component({
    selector: 'app-attention-required-accordion',
    templateUrl: './attention-required-accordion.component.html',
    styleUrls: ['./attention-required-accordion.component.sass']
})
export class AttentionRequiredAccordionComponent implements OnInit {
    @Input('List') List: any[];
    constructor(modalInputData: ModalInjectedData) {
        this.List = modalInputData.data;
    }

    ngOnInit(): void {}
}
