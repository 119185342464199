import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class RequiredValidator extends CustomValidator {
    readonly key = CustomValidatorKey.REQUIRED;
    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.required(control);
    }
}
