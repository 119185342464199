<div class="fullAccess">
    <p class="description">{{ fullAccessPoints.get(portlet).description }}</p>
    <div class="points">
        <ng-container
            *ngFor="let point of fullAccessPoints.get(portlet).features"
        >
            <div class="desc">{{ point }}</div>
        </ng-container>
    </div>
    <span class="contact-us">
        Contact Us
        <span *ngIf="contactUs">
            at
            <a class="underline" href="mailto:{{ contactUs }}" target="_top">
                {{ contactUs }}
            </a>
        </span>
    </span>
</div>
