import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidator } from '../classes/CustomValidator';
import { CustomValidatorKey } from '../enums/CustomValidatorKey';

export class MinLengthValidator extends CustomValidator {
    key = CustomValidatorKey.MIN_LENGTH;
    minLength = Infinity;
    constructor(minLength: number) {
        super();
        this.minLength = minLength;
    }

    validatorFunction(control: AbstractControl): ValidationErrors {
        return Validators.minLength(this.minLength)(control);
    }
}
