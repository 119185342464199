import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { WhitelabelingColorPalette } from 'src/app/shared/enums/WhitelabelingColorPalette';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { Helper } from './../../../../../classes/Helper';
import { HitApi } from './../../../../../classes/HitApi';
import { ButtonColorType } from './../../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IModalData } from './../../../../../interfaces/modal/IModalData';
import { GlobalDataService } from './../../../../../services/global-data/global-data.service';
import { ModalService } from './../../../../../services/modal/modal-service/modal.service';

interface IInput {
    heading: string;
    formInput: IFormGeneratorInput;
}
@Component({
    selector: 'app-whitelabeling-theme',
    templateUrl: './whitelabeling-theme.component.html',
    styleUrls: ['./whitelabeling-theme.component.sass']
})
export class WhitelabelingThemeComponent implements OnInit, OnDestroy {
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    widgetRef: Widget;
    formGenInput: IInput[] = [];
    temporaryThemesData;
    modalData: IModalData;
    reInitializeWhiteLabelData: Function;

    save: IButtonGeneratorInput = {
        buttonName: 'Save As Draft',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Cannot perform this action in preview mode'
            : null,
        function: (buttonRef) => {
            this.saveConfiguration(buttonRef);
        }
    };

    preview: IButtonGeneratorInput = {
        buttonName: 'Preview',
        buttonType: ButtonType.FLAT,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Already in preview mode'
            : null,
        buttonColorType: ButtonColorType.SECONDARY,
        function: () => {
            if (this.subWidgetData.data.previewWhitelabelModalData) {
                const data = Helper.cloneDeep(
                    this.subWidgetData.data.whiteLabelingDraft
                );

                const whiteLabelData =
                    this.subWidgetData.data.whiteLabelingData;
                const orgProp = data.orgProprietary;

                if (!orgProp['miniLogo']) {
                    orgProp['miniLogo'] =
                        whiteLabelData.orgProprietary &&
                        whiteLabelData.orgProprietary['miniLogo']
                            ? whiteLabelData.orgProprietary['miniLogo']
                            : null;
                }
                if (!orgProp['lightLogo']) {
                    orgProp['lightLogo'] =
                        whiteLabelData.orgProprietary &&
                        whiteLabelData.orgProprietary['lightLogo']
                            ? whiteLabelData.orgProprietary['lightLogo']
                            : null;
                }
                if (!orgProp['darkLogo']) {
                    orgProp['darkLogo'] =
                        whiteLabelData.orgProprietary &&
                        whiteLabelData.orgProprietary['darkLogo']
                            ? whiteLabelData.orgProprietary['darkLogo']
                            : null;
                }
                if (!orgProp['loginImage']) {
                    orgProp['loginImage'] =
                        whiteLabelData.orgProprietary &&
                        whiteLabelData.orgProprietary['loginImage']
                            ? whiteLabelData.orgProprietary['loginImage']
                            : null;
                }
                data['themeConfig'] = this.temporaryThemesData;
                this.subWidgetData.data.previewWhitelabelModalData(data);
            }
        }
    };

    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public modalService: ModalService,
        public globalDataService: GlobalDataService
    ) {
        this.resetObservable = subWidgetData.resetObserver;
        this.subWidgetId = subWidgetData.subWidgetId;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
    }

    ngOnInit(): void {
        this.setThemesData();
        this.initResetObservable();
        this.initFormGenInput();
    }

    setThemesData() {
        if (
            this.subWidgetData.data.whiteLabelingData &&
            this.subWidgetData.data.whiteLabelingData.themeConfig
        ) {
            const keys = Object.keys(
                this.subWidgetData.data.whiteLabelingData.themeConfig
            );

            keys.forEach((colorKey) => {
                this.subWidgetData.data.whiteLabelingDraft.themeConfig[
                    colorKey
                ] =
                    this.subWidgetData.data.whiteLabelingData.themeConfig[
                        colorKey
                    ];
            });
        }

        this.temporaryThemesData = Helper.cloneDeep(
            this.subWidgetData.data.whiteLabelingDraft.themeConfig
        );
    }

    initResetObservable() {
        if (this.resetObservable) {
            this.resetSubscription = this.resetObservable.subscribe(
                (targetId) => {
                    if (targetId === this.subWidgetId) {
                        this.initFormGenInput();
                    }
                }
            );
        }
    }

    initFormGenInput() {
        const defaultTheme =
            this.subWidgetData.data.whiteLabelingDraft.themeConfig;
        this.formGenInput = [];

        // Setting Console Themes
        this.formGenInput.push({
            heading: 'Console Themes',
            formInput: {
                formName: 'Console Theme',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Primary Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.PRIMARY,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[WhitelabelingColorPalette.PRIMARY],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText:
                            'The primary color is the source of all other colors. Select the primary color you want to use throughout the console for widget icons, selection etc.'
                    },
                    {
                        label: 'Accent Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.ACCENT,
                        placeholder: 'null',
                        required: true,
                        value: defaultTheme[WhitelabelingColorPalette.ACCENT],
                        fieldStyle: { width: 'unset' },
                        appearance: 'none',
                        hideFloatLabel: true,
                        suffixText:
                            'Accent color is supplementary color that complement the primary color. Select the accent color which will be used throughout the console for hovers, loading bar etc.'
                    },
                    {
                        label: 'Default Text Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.DEFAULT_TEXT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        hideFloatLabel: true,
                        value: defaultTheme[
                            WhitelabelingColorPalette.DEFAULT_TEXT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText:
                            'Select the color you want to use as the default text color throughout the console.'
                    },
                    {
                        label: 'Primary Button Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.PRIMARY_BUTTON_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText:
                            'Select the default color you want to use for primary buttons throughout the console such as Save and confirm etc.'
                    },
                    {
                        label: 'Secondary Button Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.SECONDARY_BUTTON_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText:
                            'Select the default color you want to use for secondary buttons throughout the console such as Preview and Skip etc.'
                    },
                    {
                        label: 'Background Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.BACKGROUND_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.BACKGROUND_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText:
                            'Select the primary color you want to use for the background and as highlight of the console.'
                    }
                ]
            }
        });

        // Setting Widget and Aggregates Themes
        this.formGenInput.push({
            heading: 'Widget and Aggregates Themes',
            formInput: {
                formName: 'Widget and Aggregates Theme',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Widget Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.WIDGET_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.WIDGET_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText: `Select the color you want to use for the widget headers.`
                    },
                    {
                        label: 'Widget Text Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.WIDGET_TEXT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.WIDGET_TEXT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText: `Select the color you want to use for the text of widget headers.`
                    },
                    {
                        label: 'Aggregates Default Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.AGGREGATE_DEFAULT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText: `Select the color you want to use as the default color for the aggregates.`
                    },
                    {
                        label: 'Aggregates Default Text Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.AGGREGATE_DEFAULT_TEXT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette
                                .AGGREGATE_DEFAULT_TEXT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        hideFloatLabel: true,
                        suffixText: `Select the color you want to use for the text of aggregates.`
                    }
                ]
            }
        });

        // Setting Side Menu Themes
        this.formGenInput.push({
            heading: 'Side Menu Theme',
            formInput: {
                formName: 'Side Menu Theme',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Menu Primary Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.MENU_PRIMARY_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.MENU_PRIMARY_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for menu background as its primary color.`
                    },
                    {
                        label: 'Menu Accent Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.MENU_ACCENT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.MENU_ACCENT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for highlighting text and selected icons`
                    },
                    {
                        label: 'Menu Text Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.MENU_TEXT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.MENU_TEXT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for menu text.`
                    },
                    {
                        label: 'Menu Hover Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.MENU_HOVER_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.MENU_HOVER_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use as hover color`
                    }
                ]
            }
        });

        // Setting Header Footer Themes
        this.formGenInput.push({
            heading: 'Header Footer Theme',
            formInput: {
                formName: 'Header Footer Theme',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Header Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.HEADER_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.HEADER_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use as header color.`
                    },
                    {
                        label: 'Footer Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.FOOTER_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.FOOTER_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use as footer color.`
                    },
                    {
                        label: 'Footer Text Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.FOOTER_TEXT_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.FOOTER_TEXT_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use as footer text color.`
                    }
                ]
            }
        });

        // Setting Action Themes
        this.formGenInput.push({
            heading: 'Action Color',
            formInput: {
                formName: 'Action Theme',
                state: FormState.CREATE,
                submitButton: null,
                fields: [
                    {
                        label: 'Negative Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.NEGATIVE_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.NEGATIVE_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for function with negative feedback (i.e Delete, Stopped, Critical).`
                    },
                    {
                        label: 'Positive Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.POSITEVE_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.POSITEVE_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for function with positive feedback (i.e Success, Rightsized, Running)`
                    },
                    {
                        label: 'Neutral Color',
                        fieldType: FilterType.COLOR,
                        name: WhitelabelingColorPalette.NEUTRAL_COLOR,
                        placeholder: 'null',
                        required: true,
                        appearance: 'none',
                        value: defaultTheme[
                            WhitelabelingColorPalette.NEUTRAL_COLOR
                        ],
                        fieldStyle: { width: 'unset' },
                        suffixText: `Select the color you want to use for function with neutral feedback
                        (i.e Edit, View)`
                    }
                ]
            }
        });
    }

    setValues(event) {
        const colors = event.value;
        const keys = Object.keys(colors);

        keys.forEach((colorKey) => {
            this.temporaryThemesData[colorKey] = colors[colorKey];
        });
    }

    saveConfiguration(buttonRef) {
        if (this.subWidgetData.data.saveAsDraftApiInfo) {
            buttonRef.loader = true;
            const themeDraft = Helper.cloneDeep(
                this.subWidgetData.data.whiteLabelingDraft
            );
            themeDraft['themeConfig'] = Helper.cloneDeep(
                this.temporaryThemesData
            );

            const apiConfig = Helper.generateHitApiConfig(
                this.subWidgetData.data.saveAsDraftApiInfo
            );
            apiConfig.input = themeDraft;

            apiConfig.function = (response) => {
                buttonRef.loader = false;
                this.subWidgetData.data.whiteLabelingData.themeConfig =
                    Helper.cloneDeep(this.temporaryThemesData);
                this.subWidgetData.parentWidgetRef.notificationsService.showSnackBar(
                    'Configuration saved successfully. Click on publish to deploy these changes.',
                    false,
                    '',
                    { duration: 3500 }
                );
                if (this.reInitializeWhiteLabelData) {
                    this.reInitializeWhiteLabelData();
                }
                this.setThemesData();
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
            };
            apiConfig.errorFunction = (error) => {
                buttonRef.loader = false;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.subWidgetData.parentWidgetRef.notificationsService,
                    error,
                    'Error in saving theme configuration'
                );
            };

            new HitApi(
                apiConfig,
                this.subWidgetData.parentWidgetRef.httpService,
                this.subWidgetData.parentWidgetRef.ngZone
            ).hitApi();
        }
    }

    ngOnDestroy(): void {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}
