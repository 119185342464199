import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class TaggingAutomationCacheService extends CacheMain {
    static readonly USER_DETAILS = 'USER_DETAILS';

    constructor() {
        const AUTOMATION_CACHE_PREFIX = 'AUTOMATION_CACHE';
        super(AUTOMATION_CACHE_PREFIX, true);
    }

    get userDetails() {
        return this.fetch(TaggingAutomationCacheService.USER_DETAILS);
    }
    set userDetails(details) {
        this.store(TaggingAutomationCacheService.USER_DETAILS, details);
    }
}
