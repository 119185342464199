import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { CloudLabel } from 'src/app/shared/enums/CloudLabel';
import { ContentType } from 'src/app/shared/enums/ContentType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { Helper } from './../../../../classes/Helper';
import { FormState } from './../../../../enums/FormState';
import { RequestType } from './../../../../enums/RequestType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-add-resource-tags-v2',
    templateUrl: './add-resource-tags-v2.component.html',
    styleUrls: ['./add-resource-tags-v2.component.sass']
})
export class AddResourceTagsV2Component implements OnInit {
    widgetRef: Widget;
    resourceTaggingList = null;
    addResourceTagJsonObjectData = {};
    lastKeyValuePair: Symbol;
    keyValueSet = {};
    extraTags = [];
    tagsToApply = [];
    Tab = Tab;
    selectedTab: any = Tab.EXCEL;
    keyValuePairFormGroupRef = [];
    mapForOldKeysValues: Map<number, { old: {}; new: {}; isDelete: boolean }> =
        new Map();
    activeInstance = 0;
    inCompleteSection: boolean = false;
    selectedConventionNames = [];

    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    isUpdate: Boolean = false;
    keyValuePair = {};
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    resetKeyValueSubject = new BehaviorSubject(false);
    updateErrorMessage = new BehaviorSubject('');
    conventionTabButtons: IButtonGeneratorInput[];
    dashBoardtabButtons: IButtonGeneratorInput[];
    excelDownloadButton: IButtonGeneratorInput;
    excelTabButtons: IButtonGeneratorInput[];
    singleRowSelected: boolean = false;
    storeAllKeysAlreadyExists: Map<number, {}> = new Map();
    applyCaseInsensitive: boolean;

    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' This insight will allow you to tag the Instances by uploading an excel file.'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Step 1:</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [' Download the excel sheet format.']
                }
            ]
        },
        {
            infoHeading: '<strong>Step 2:</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' Add the tags that you wish to attach to the resources in the preset format.'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Step 3:</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        ' Now, upload the updated excel file that will apply the tags to the specified resources'
                    ]
                }
            ]
        },
        {
            infoHeading: '<strong>Note:</strong>',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [' The file must be uploaded in the preset format.']
                }
            ]
        }
    ];
    excelForm: IFormGeneratorInput = null;
    excelFormGroup: FormGroup;
    conventionFormGroup: FormGroup;

    selectedKeyValuePair: any;
    conventionsFormGroupInput: IFormGeneratorInput = null;
    keysArray = [];

    Messages = Messages;

    constructor(
        private modalInputData: ModalInjectedData,
        private http: HttpClient
    ) {
        this.widgetRef = modalInputData.data['widgetRef'];

        this.isUpdate = modalInputData.data['isUpdate'];
        this.resourceTaggingList =
            modalInputData.data['inputDataForCreatingResourceTag'];
        this.singleRowSelected = modalInputData.data['singleRowSelected'];
        this.initializeUpdateData();
        if (this.isUpdate) {
            if (
                this.resourceTaggingList[0] &&
                this.resourceTaggingList[0]['extraTags']
            ) {
                this.getAllTheKeys(this.resourceTaggingList[0]['extraTags']);
            }
        } else {
            const unionList = this.resourceTaggingList.reduce((prev, curr) => {
                if (curr['extraTags'] && curr['extraTags'].length) {
                    return [...prev, ...curr['extraTags']];
                }

                return prev;
            }, []);

            this.getAllTheKeys(unionList);
        }
        this.resourceTaggingList.forEach((rowData, index) => {
            this.storeAllKeysAlreadyExists.set(index, rowData['extraTags']);
        });
    }
    getAllTheKeys(keysData) {
        this.keysArray = keysData.map((item) => {
            return item.split('|')[0];
        });
    }

    initializeUpdateData() {
        if (this.isUpdate) {
            this.resourceTaggingList.map((element, index) => {
                const keyValuePair = {};

                element['extraTags'].map((each) => {
                    if (each.split('|').length > 1) {
                        keyValuePair[each.split('|')[0]] =
                            each.split('|')[1] && each.split('|')[1].length
                                ? each.split('|')[1]
                                : null;
                        keyValuePair[each.split('|')[0]] = keyValuePair[
                            each.split('|')[0]
                        ]
                            ? keyValuePair[each.split('|')[0]].split('|')
                            : [];
                    }
                });

                element['appliedTags'].map((each) => {
                    if (each.split('|').length > 1) {
                        keyValuePair[each.split('|')[0]] =
                            each.split('|')[1] && each.split('|')[1].length
                                ? each.split('|')[1]
                                : null;
                        keyValuePair[each.split('|')[0]] = keyValuePair[
                            each.split('|')[0]
                        ]
                            ? keyValuePair[each.split('|')[0]].split('|')
                            : [];
                    }
                });

                element['missingTags'].map((each) => {
                    if (each.split('|').length > 1) {
                        if (each.split('|')[1] !== 'Value Not Found') {
                            keyValuePair[each.split('|')[0]] =
                                each.split('|')[1] && each.split('|')[1].length
                                    ? each.split('|')[1]
                                    : null;
                            keyValuePair[each.split('|')[0]] = keyValuePair[
                                each.split('|')[0]
                            ]
                                ? keyValuePair[each.split('|')[0]].split('|')
                                : [];
                        }
                    }
                });

                this.resourceTaggingList[index]['tagsToApply'] = keyValuePair;
            });
        }
        this.conventionTabButtons = [
            {
                buttonName: 'Reset',
                function: () => {
                    this.conventionFormResetFunction();
                },
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED
            },
            {
                buttonName: 'Save',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.addViaConvention(buttonRef);
                },
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED
            }
        ];
        this.dashBoardtabButtons = [
            {
                buttonName: this.isUpdate ? 'Save & Replicate' : 'Reset',
                showLoader: true,
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this.isUpdate) {
                        this.saveAndReplicate(buttonRef);
                    } else {
                        this.resetKeyValueFn();
                    }
                }
            },
            {
                buttonName: 'Save',
                showLoader: true,
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (this.isUpdate) {
                        this.updateKeyValuePair(buttonRef);
                    } else {
                        this.addKeyValuePair(buttonRef);
                    }
                }
            }
        ];

        this.excelTabButtons = [
            {
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                buttonName: 'Save',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.uploadExcel(buttonRef);
                }
            },
            {
                buttonName: 'Download',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                customClass: 'download-container',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.downloadExcelTemplate(buttonRef);
                }
            }
        ];
    }

    conventionFormResetFunction() {
        this.conventionFormGroup.reset();
    }

    ngOnInit(): void {
        this.initializeForms();
        if (
            [
                CloudLabel.AZURE_CSP,
                CloudLabel.AZURE_EA,
                CloudLabel.AZURE_MCA,
                CloudLabel.AZURE_PLAN
            ].includes(this.widgetRef.widgetData.widgetInfo.cloudIcon)
        ) {
            this.applyCaseInsensitive = true;
        }
    }

    initializeForms() {
        this.excelForm = {
            formName: 'Excel Upload',
            submitButton: null,

            state: FormState.CREATE,
            fields: [
                {
                    fieldType: FilterType.FILE,

                    label: 'Excel File Upload',
                    name: 'excelFile',
                    placeholder: 'Select a file to upload...',
                    accept: '.xls, .xlsx',
                    required: true,
                    suffixButton: {
                        text: 'Browse',
                        show: true
                    },
                    appearance: 'legacy'
                }
            ]
        };

        if (!this.isUpdate) {
            this.setUpConventions();
        }
    }

    changeTab(tab) {
        if (tab === Tab.ADD_NEW && this.selectedTab !== Tab.ADD_NEW) {
            if (this.isUpdate) {
                this.activeInstance = 0;
                if (
                    this.resourceTaggingList &&
                    this.resourceTaggingList[this.activeInstance] &&
                    this.resourceTaggingList[this.activeInstance]['tagsToApply']
                ) {
                    this.selectedKeyValuePair =
                        this.resourceTaggingList[this.activeInstance][
                            'tagsToApply'
                        ];
                }

                this.prepareKeyValueDataToPatch(0);
            } else {
                this.keyValuePair = {};
            }
        }

        this.selectedTab = tab;
    }

    prepareKeyValueDataToPatch(index) {
        if (
            this.resourceTaggingList &&
            this.resourceTaggingList[index] &&
            this.resourceTaggingList[index]['extraTags']
        ) {
            this.getAllTheKeys(this.resourceTaggingList[index]['extraTags']);
            this.keyValuePair = this.resourceTaggingList[index]['tagsToApply'];
            this.selectedKeyValuePair = this.keyValuePair;
        }
        this.updateErrorMessage.next('');
        this.activeInstance = index;
    }

    saveAndReplicate(buttonRef: IButtonGeneratorInput) {
        this.keyValuePairFormGroupRef.map((data: FormGroup) => {
            data.markAllAsTouched();
        });
        if (this.inCompleteSection) {
            return;
        }

        this.resourceTaggingList.forEach((data, index) => {
            if (index !== this.activeInstance) {
                this.tagsToApply = Object.keys(data['tagsToApply']);
                this.tagsToApply.forEach((tagName, tagIndex) => {
                    if (tagName) {
                        for (const [
                            key,
                            tagValue
                        ] of this.mapForOldKeysValues.entries()) {
                            if (tagValue.old['key'] === tagName) {
                                const key = tagValue.new['key'];

                                const values = tagValue.new['val'];

                                this.resourceTaggingList[index]['tagsToApply'][
                                    key
                                ] = values;
                                if (key !== tagName) {
                                    delete this.resourceTaggingList[index][
                                        'tagsToApply'
                                    ][tagName];
                                }
                            }
                        }
                    }
                });
            }
        });

        buttonRef.loader = true;
        const dataForApi = {};

        dataForApi['resourceTaggingList'] = this.prepareDataForApi(false, true);

        dataForApi['resourceTaggingList'].forEach((element) => {
            const keys = Object.keys(element['tagsToApply']);
            const keyValuePair = element['tagsToApply'];
            keys.map((each) => {
                keyValuePair[each] = keyValuePair[each].length
                    ? keyValuePair[each].toString()
                    : '';
            });

            element['tagsToApply'] = keyValuePair;
        });

        this.modalInputData.data.function(
            dataForApi['resourceTaggingList'],
            buttonRef,
            this.modalInputData.modalId,
            this.isUpdate
        );
    }

    updateKeyValuePair(buttonRef: IButtonGeneratorInput) {
        this.keyValuePairFormGroupRef.map((data: FormGroup) => {
            data.markAllAsTouched();
        });
        if (this.inCompleteSection) {
            return;
        }
        if (this.selectedKeyValuePair) {
            Object.keys(this.selectedKeyValuePair)?.forEach((data, index) => {
                if (
                    Object.keys(this.selectedKeyValuePair)[index] === 'null' &&
                    this.selectedKeyValuePair[
                        Object.keys(this.selectedKeyValuePair)[index]
                    ].includes('null')
                ) {
                    delete this.selectedKeyValuePair['null'];
                }
            });
        }

        buttonRef.loader = true;

        const dataForApi = {};
        dataForApi['resourceTaggingList'] = [];

        dataForApi['resourceTaggingList'] = this.prepareDataForApi();
        dataForApi['resourceTaggingList'].forEach((element) => {
            const keys = Object.keys(element['tagsToApply']);
            const keyValuePair = element['tagsToApply'];
            keys.map((each) => {
                keyValuePair[each] = keyValuePair[each].length
                    ? keyValuePair[each].toString()
                    : '';
            });

            element['tagsToApply'] = keyValuePair;
        });

        this.modalInputData.data.function(
            dataForApi['resourceTaggingList'],
            buttonRef,
            this.modalInputData.modalId,
            this.isUpdate
        );
    }

    setUpConventions() {
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'getConvention'
            ]
        );
        hitApi.function = (response) => {
            this.initConventionForm(response);
        };
        hitApi.config.defaultHeaders = {
            'content-type': 'application/json'
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error getting convention list.'
            );
            this.initConventionForm([]);
        };
        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    resetKeyValueFn() {
        this.resetKeyValueSubject.next(true);
    }

    initConventionForm(response) {
        let conventionsList;

        if (response && response.length) {
            conventionsList = response.map((each) => {
                return {
                    id: each,
                    label: each
                };
            });
        } else {
            conventionsList = [];
        }

        this.conventionsFormGroupInput = {
            formName: 'Convention Form',
            submitButton: null,
            state: FormState.CREATE,

            fields: [
                {
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Convention Name',
                    appearance: 'legacy',
                    name: 'configurationName',
                    required: true,
                    placeholder: 'Select Name',
                    listData: conventionsList,
                    showLabel: true,
                    validations: [
                        {
                            errorMessage: 'This field is required',
                            validator: CustomValidators.required
                        }
                    ]
                },
                {
                    fieldType: FilterType.DROPDOWN_GROUP_MULTIPLE,
                    label: 'Resource Tag',
                    appearance: 'legacy',
                    name: 'resourceTag',
                    required: true,
                    placeholder: 'Select Key',
                    groupBy: 'group',
                    showLabel: true,
                    getKey: null,
                    showKey: 'label',
                    eachGroupMaxLimitSelection: 1,
                    validations: [
                        {
                            errorMessage: 'This field is required',
                            validator: CustomValidators.required
                        }
                    ]
                }
            ]
        };
    }

    conventionValueChange(selectedValue) {
        const configurationName = selectedValue['configurationName'];

        if (!configurationName) {
            return;
        }

        if (
            this.selectedConventionNames &&
            configurationName &&
            configurationName.length === this.selectedConventionNames.length &&
            configurationName.every(
                (value, index) => value === this.selectedConventionNames[index]
            )
        ) {
            return;
        }

        this.selectedConventionNames = configurationName;

        this.conventionsFormGroupInput.fields[1].apiInfo =
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'generateDropdown'
            ];
        this.conventionsFormGroupInput.fields[1].apiInput = configurationName;
        this.conventionsFormGroupInput.fields[1].listData = [];

        const updateControlInput = {
            action: UpdateAction.REFRESH,
            controls: ['resourceTag']
        };
        this.widgetRef.changeDetectorRef.detectChanges();
        this.updateControl.next(updateControlInput);
    }

    keyValuePairOutput(event) {
        if (event.length) {
            this.selectedKeyValuePair = event[0]
                ? event[0]
                : this.selectedKeyValuePair;
            this.inCompleteSection = event[1];
            this.keyValuePairFormGroupRef = event[2];
            this.mapForOldKeysValues = event[3];

            if (this.isUpdate) {
                this.resourceTaggingList[this.activeInstance]['tagsToApply'] =
                    this.selectedKeyValuePair;
            }
        }
    }

    openMatTabs(index) {
        this.activeInstance = index;
    }

    addKeyValuePair(buttonRef: IButtonGeneratorInput) {
        this.keyValuePairFormGroupRef.map((data: FormGroup) => {
            data.markAllAsTouched();
        });
        if (this.inCompleteSection) {
            return;
        }

        Object.keys(this.selectedKeyValuePair).forEach((data, index) => {
            if (
                Object.keys(this.selectedKeyValuePair)[index] === 'null' &&
                this.selectedKeyValuePair[
                    Object.keys(this.selectedKeyValuePair)[index]
                ].includes('null')
            ) {
                delete this.selectedKeyValuePair['null'];
            }
        });
        if (this.inCompleteSection) {
            return;
        }

        buttonRef.loader = true;
        const data = this.prepareDataForApi();
        const keys = Object.keys(this.selectedKeyValuePair);
        const keyValuePair = this.selectedKeyValuePair;
        keys.map((each) => {
            keyValuePair[each] = keyValuePair[each].length
                ? keyValuePair[each].toString()
                : '';
        });

        data.forEach((each) => {
            each['tagsToApply'] = keyValuePair;
        });

        const dataForApi = {
            resourceTaggingList: data
        };

        this.modalInputData.data.function(
            dataForApi.resourceTaggingList,
            buttonRef,
            this.modalInputData.modalId
        );
    }

    downloadExcelTemplate(buttonRef: IButtonGeneratorInput) {
        const addTagExcelApiInfo =
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                `${
                    this.isUpdate ? 'createUpdateTagExcel' : 'createAddTagExcel'
                }`
            ];

        const inputData = this.prepareDataForApi(true);
        if (!inputData) {
            return;
        }
        buttonRef.loader = true;

        const hitApi: IHitApi = Helper.generateHitApiConfig(addTagExcelApiInfo);
        hitApi.input = inputData;
        hitApi.config.downloadable = true;
        hitApi.config.defaultHeaders = {
            Accept: ContentType.EXCEL
        };

        hitApi.function = (response) => {
            if (hitApi.config.downloadable) {
                Helper.saveAsBlob(
                    response,
                    hitApi.config.defaultHeaders.Accept,
                    'Template'
                );
            }
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error downloading excel template.'
            );
        };
        hitApi.endFunction = () => {
            buttonRef.loader = false;
        };
        const http = new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        );
        http.hitApi();
    }

    addViaConvention(buttonRef: IButtonGeneratorInput) {
        const selectedResourceTag = this.conventionFormGroup.value.resourceTag;
        if (
            !selectedResourceTag ||
            (selectedResourceTag && selectedResourceTag.length === 0)
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select Atleast One Resource Tag',
                true
            );
            return;
        }

        buttonRef.loader = true;

        const data = this.prepareDataForApi();
        const keyValuePair = {};
        selectedResourceTag.map((each) => {
            keyValuePair[each['id'].split('|')[0]] = [];
            each['id'].split('|').map((value, index) => {
                if (index) {
                    keyValuePair[each['id'].split('|')[0]].push(value);
                }
            });
            keyValuePair[each['id'].split('|')[0]] =
                keyValuePair[each['id'].split('|')[0]].join('|');
        });

        data.forEach((each) => {
            each['tagsToApply'] = keyValuePair;
        });

        const dataForApi = {
            resourceTaggingList: data
        };

        this.modalInputData.data.function(
            dataForApi.resourceTaggingList,
            buttonRef,
            this.modalInputData.modalId
        );
    }

    uploadExcel(buttonRef: IButtonGeneratorInput) {
        if (this.excelFormGroup.invalid) {
            this.excelFormGroup.markAllAsTouched();
            return;
        }
        buttonRef.loader = true;

        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'generatePresignedUrl'
            ]
        );

        hitApi.input = {};

        hitApi.function = (response) => {
            const preSignedUrl = response['preSignedURL'];

            if (preSignedUrl) {
                const file = this.excelFormGroup.value.excelFile['_files'][0];

                const hitApi: IHitApi = {
                    url: preSignedUrl,
                    requestType: RequestType.PUT,
                    config: {
                        ignoreBaseUrl: true,
                        authorization: null,
                        defaultHeaders: {
                            'Content-Type': ContentType.EXCEL
                        }
                    },

                    function: (response) => {
                        this.modalInputData.data.excelFunction(
                            preSignedUrl,
                            this.isUpdate,
                            buttonRef,
                            this.modalInputData.modalId
                        );
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.widgetRef.notificationsService,
                            error,
                            'Error uploading excel template.'
                        );
                        buttonRef.loader = false;
                    },
                    input: file,

                    uniqueIdentity: Symbol()
                };

                new HitApi(
                    hitApi,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        };
        hitApi.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error uploading excel template.'
            );
            buttonRef.loader = false;
        };
        const http = new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        );
        http.hitApi();
    }

    prepareDataForApi(isExcel = false, isReplicateAll = false) {
        const data = [];

        if (
            this.widgetRef &&
            this.widgetRef.apiResponse &&
            this.widgetRef.apiResponse['dataMap'] &&
            this.widgetRef.apiResponse['dataMap']['tableKeys'] &&
            this.widgetRef.apiResponse['dataMap']['tableKeys'].length
        ) {
            if (isExcel) {
                this.resourceTaggingList.forEach((element) => {
                    const keyName = {};
                    this.widgetRef.apiResponse['dataMap']['keysToShow'].forEach(
                        (colObj) => {
                            keyName[colObj['id']] = element[colObj['id']];
                            keyName['extraTags'] = element['extraTags'];
                            keyName['appliedTags'] = element['appliedTags'];
                            keyName['missingTags'] = element['missingTags'];
                            keyName['zone'] = element['zone'];
                        }
                    );
                    data.push(keyName);
                });
            } else {
                this.resourceTaggingList.forEach((element, index) => {
                    const keyName = {};
                    this.widgetRef.apiResponse['dataMap']['tableKeys'].forEach(
                        (each) => {
                            if (this.isUpdate) {
                                keyName[each] = element[each];
                                if (
                                    Object.keys(
                                        element['tagsToApply']
                                    ).includes('null') &&
                                    element['tagsToApply']['null'].includes(
                                        'null'
                                    )
                                ) {
                                    delete element['tagsToApply']['null'];
                                }
                                keyName['tagsToApply'] = element['tagsToApply'];
                            } else {
                                keyName[each] = element[each];
                            }
                        }
                    );
                    if (Object.keys(keyName).length) {
                        data.push(keyName);
                    }
                });
            }
        } else {
            if (
                this.widgetRef.widgetData.widgetInfo.cloudIcon ===
                CloudLabel.AWS
            ) {
                if (isExcel) {
                    this.resourceTaggingList.forEach((element) => {
                        const keyName = {};
                        this.widgetRef.apiResponse['dataMap'][
                            'keysToShow'
                        ].forEach((colObj) => {
                            keyName[colObj['id']] = element[colObj['id']];
                            keyName['extraTags'] = element['extraTags'];
                            keyName['appliedTags'] = element['appliedTags'];
                            keyName['missingTags'] = element['missingTags'];
                        });
                        data.push(keyName);
                    });
                } else {
                    this.resourceTaggingList.map((element, index) => {
                        if (this.isUpdate) {
                            if (
                                element.tagsToApply &&
                                Object.keys(element.tagsToApply).includes(
                                    'null'
                                ) &&
                                element.tagsToApply['null'].includes('null')
                            ) {
                                delete element.tagsToApply['null'];
                            }

                            data.push({
                                resourceId: element.identifier,
                                regionName: element.region,
                                accountNumber: element.accountId,
                                tagsToApply: element.tagsToApply
                            });
                        } else {
                            data.push({
                                resourceId: element.identifier,
                                regionName: element.region,
                                accountNumber: element.accountId
                            });
                        }
                    });
                }
            }
        }
        return data;
    }
}

enum Tab {
    ADD_NEW = 'Dashboard',
    CONVENTION = 'Convention',
    EXCEL = 'Excel File Uploader'
}

enum Messages {
    CASE_INSENSITIVE_WARN = 'Tag Key already exists on resource. Saving will Update the existing tag as well.',
    CASE_INSENSITIVE_WARN_BULK = 'Tag Key already exists on resource(s). Saving will Update the existing tag(s) as well.'
}
