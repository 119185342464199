import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IntegrationName } from 'src/app/shared/enums/IntegrationName';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ButtonColorType } from './../../../../../../enums/ButtonColorType';
import { IButtonGeneratorInput } from './../../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-ca-child-integration',
    templateUrl: './ca-child-integration.component.html',
    styleUrls: ['./ca-child-integration.component.sass']
})
export class CaChildIntegrationComponent implements OnInit {
    childIntegrationFormGenInput: IFormGeneratorInput = null;
    response;
    widgetRef: Widget;
    edit: boolean = false;
    constructor(private modalInputData: ModalInjectedData) {
        this.widgetRef = modalInputData.data['widgetRef'];
    }

    ngOnInit(): void {
        if (this.modalInputData.data.edit) {
            this.edit = this.modalInputData.data.edit;
            this.response =
                this.modalInputData.data.response['childCredentials'];
        }
        this.setUpBasics();
    }
    setUpBasics() {
        this.childIntegrationFormGenInput = {
            formName: IntegrationName.CASDM,
            state: FormState.CREATE,
            submitButton: {
                buttonName: this.edit ? 'Update' : 'Save',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,

                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup,
                    modalId: Symbol
                ) => {
                    if (formGroup.valid) {
                        buttonRef.loader = true;
                        const args = Helper.generateHitApiConfig(
                            this.edit
                                ? this.widgetRef.widgetData.widgetInfo.update
                                : this.widgetRef.widgetData.widgetInfo.create
                        );
                        args.input = {
                            ...formGroup.value,
                            integrationType: IntegrationName.CASDM,
                            childIntegration: 'true',
                            parentIntegration: 'false'
                        };
                        args.function = (response) => {
                            this.widgetRef.notificationsService.showSnackBar(
                                this.edit
                                    ? 'CA-SDM updated successfully'
                                    : 'CA-SDM integrated successfully'
                            );
                            buttonRef.loader = false;
                            this.widgetRef.refreshWidget();
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.modalInputData.modalId
                            );
                        };
                        args.errorFunction = (error) => {
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                error,
                                this.edit
                                    ? 'Error while updating ca-sdm'
                                    : 'Error while integrating ca-sdm'
                            );
                            buttonRef.loader = false;
                        };
                        new HitApi(
                            args,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            },
            fields: [
                {
                    name: 'userName',
                    placeholder: 'User Name',
                    label: 'User Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'User name is required'
                        }
                    ],
                    value: this.edit ? this.response.userName : ''
                },
                {
                    name: 'password',
                    placeholder: 'Password',
                    label: 'Password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: Helper.getPasswordValidators(),
                    value: this.edit ? this.response.password : ''
                }
            ]
        };
    }
}
