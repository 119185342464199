import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges
} from '@angular/core';
@Directive({
    selector: '[appDisableElement]'
})
export class DisableElementDirective implements OnInit, OnChanges {
    @Input() data;
    constructor(private element: ElementRef) {}

    ngOnInit() {
        this.addClassIfTrue();
        this.element.nativeElement.parentElement.addEventListener(
            'mouseenter',
            () => {
                this.addClassIfTrue();
            }
        );
        this.element.nativeElement.parentElement.addEventListener(
            'mouseleave',
            () => {
                this.addClassIfTrue();
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            !changes.data.firstChange &&
            changes.data.currentValue !== changes.data.previousValue
        ) {
            this.addClassIfTrue();
        }
    }

    addClassIfTrue() {
        if (this.data) {
            if (this.element.nativeElement.parentElement) {
                this.element.nativeElement.parentElement.classList.add(
                    'disabled'
                );
            }
        } else {
            if (this.element.nativeElement.parentElement) {
                this.element.nativeElement.parentElement.classList.remove(
                    'disabled'
                );
            }
        }
    }
}
