import { Component, Input, OnInit } from '@angular/core';
import { IconType } from 'src/app/shared/enums/IconType';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IInfoModal } from './../../../interfaces/modal/IInfoModal';

@Component({
    selector: 'app-modal-info-template',
    templateUrl: './modal-info-template.component.html',
    styleUrls: ['./modal-info-template.component.sass']
})
export class ModalInfoTemplateComponent implements OnInit {
    @Input() infoData: IInfoModal[];
    SanitizeTypes = SanitizeTypes;
    infoIcon: IIcon = {
        type: IconType.SVG,
        class: 'modal_info_icon',
        extraClass: 'i-icon-font-size'
    };

    constructor() {}

    ngOnInit(): void {}
}
