<div class="alert-third-step">
    <div class="data">
        <app-common-integrations-index
            [initialSelection]="initialSelection"
            [integrations]="[
                IntegrationName.EMAIL,
                IntegrationName.O365,
                IntegrationName.GMAIL,
                IntegrationName.SLACK,
                IntegrationName.SNS,
                IntegrationName.FRESHDESK,
                IntegrationName.FRESHSERVICE,
                IntegrationName.SALESFORCE,
                IntegrationName.ZENDESK,
                IntegrationName.SERVICENOW,
                IntegrationName.JIRADESK,
                IntegrationName.AUTOTASK,
                IntegrationName.OTRS,
                IntegrationName.CASDM
            ]"
            (selected)="integrationsChanged($event)"
            (integrationInfoFunc)="integrationInfoFunc = $event"
        ></app-common-integrations-index>
        <div class="integration-data">
            <div class="severity" *ngIf="severityFormGenInput">
                <app-form-generator
                    [formGeneratorInput]="severityFormGenInput"
                    (formGroupRef)="severityFormGroup = $event"
                ></app-form-generator>
            </div>
            <section
                class="mail"
                *ngIf="
                    emailFormGenInput &&
                    (selectedIntegrations.has(IntegrationName.EMAIL) ||
                        selectedIntegrations.has(IntegrationName.GMAIL) ||
                        selectedIntegrations.has(IntegrationName.O365))
                "
            >
                <div class="heading">Mail</div>
                <app-form-generator
                    [formGeneratorInput]="emailFormGenInput"
                    (formGroupRef)="emailFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="slack"
                *ngIf="
                    slackFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SLACK)
                "
            >
                <div class="heading">Slack</div>
                <app-form-generator
                    [formGeneratorInput]="slackFormGenInput"
                    (formGroupRef)="slackFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="sns"
                *ngIf="
                    snsFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SNS)
                "
            >
                <div class="heading">SNS</div>
                <app-form-generator
                    [formGeneratorInput]="snsFormGenInput"
                    (formGroupRef)="snsFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="freshdesk"
                *ngIf="
                    freshdeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.FRESHDESK)
                "
            >
                <div class="heading">Freshdesk</div>
                <app-form-generator
                    [formGeneratorInput]="freshdeskFormGenInput"
                    (formGroupRef)="freshdeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="freshservice"
                *ngIf="
                    freshserviceFormGenInput &&
                    selectedIntegrations.has(IntegrationName.FRESHSERVICE)
                "
            >
                <div class="heading">Freshservice</div>
                <div class="form">
                    <div class="first-form">
                        <app-form-generator
                            *ngIf="freshserviceFormGenInput"
                            [formGeneratorInput]="freshserviceFormGenInput"
                            (formGroupRef)="freshserviceFormGroup = $event"
                            (valuesChange)="
                                freshserviceConfigValueChanged($event)
                            "
                        ></app-form-generator>
                    </div>

                    <div class="tw-flex-1" *ngIf="freshserviceFormData.length">
                        <ng-container
                            *ngFor="let field of freshserviceFormData"
                        >
                            <ng-container
                                *ngIf="
                                    field.fieldType ===
                                    FilterType.DROPDOWN_DEPENDENT
                                "
                            >
                                <app-dependent-dropdowns
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                    [selectedValue]="fieldValueObj"
                                    [selectedCustomFieldValue]="
                                        customFieldValueObj
                                    "
                                ></app-dependent-dropdowns>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    field.fieldType !==
                                        FilterType.DROPDOWN_DEPENDENT &&
                                    field.fieldType !==
                                        FilterType.WYSIWYG_EDITOR &&
                                    !field.partiallyDependentOn
                                "
                            >
                                <app-field-generator-handler
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                    [selectedValue]="fieldValueObj"
                                    [selectedCustomFieldValue]="
                                        customFieldValueObj
                                    "
                                ></app-field-generator-handler>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    field.fieldType !==
                                        FilterType.DROPDOWN_DEPENDENT &&
                                    field.fieldType !==
                                        FilterType.WYSIWYG_EDITOR &&
                                    field.partiallyDependentOn
                                "
                            >
                                <app-field-generator-handler
                                    [fieldData]="field"
                                    [validate]="checkValidation"
                                    [listData]="otherFieldListObj"
                                    (change)="
                                        freshserviceFieldValueChanged($event)
                                    "
                                    [selectedValue]="fieldValueObj"
                                    [selectedCustomFieldValue]="
                                        customFieldValueObj
                                    "
                                ></app-field-generator-handler>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-flex-1"
                        *ngIf="freshserviceLoader"
                    >
                        <mat-spinner
                            [diameter]="20"
                            class="mat-spinner-color"
                        ></mat-spinner>
                    </div>
                </div>
            </section>

            <section
                class="zendesk"
                *ngIf="
                    zendeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.ZENDESK)
                "
            >
                <div class="heading">Zendesk</div>
                <app-form-generator
                    [formGeneratorInput]="zendeskFormGenInput"
                    (formGroupRef)="zendeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="serviceNow"
                *ngIf="
                    serviceNowFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SERVICENOW)
                "
            >
                <div class="heading">Servicenow</div>
                <app-form-generator
                    [formGeneratorInput]="serviceNowFormGenInput"
                    (formGroupRef)="serviceNowFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="jiradesk"
                *ngIf="
                    jiradeskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.JIRADESK)
                "
            >
                <div class="heading">Jiradesk</div>
                <app-form-generator
                    [formGeneratorInput]="jiradeskFormGenInput"
                    (formGroupRef)="jiradeskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="autotask"
                *ngIf="
                    autotaskFormGenInput &&
                    selectedIntegrations.has(IntegrationName.AUTOTASK)
                "
            >
                <div class="heading">Autotask</div>
                <app-form-generator
                    [formGeneratorInput]="autotaskFormGenInput"
                    (formGroupRef)="autotaskFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="autotask"
                *ngIf="
                    autotaskChildFormGenInput &&
                    selectedIntegrations.has(IntegrationName.AUTOTASK_CHILD)
                "
            >
                <div class="heading">Autotask (Child Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="autotaskChildFormGenInput"
                    (formGroupRef)="autotaskChildFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="autotask"
                *ngIf="
                    autotaskParentFormGenInput &&
                    selectedIntegrations.has(IntegrationName.AUTOTASK_PARENT)
                "
            >
                <div class="heading">Autotask (Parent Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="autotaskParentFormGenInput"
                    (formGroupRef)="autotaskParentFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="otrs"
                *ngIf="
                    otrsFormGenInput &&
                    selectedIntegrations.has(IntegrationName.OTRS)
                "
            >
                <div class="heading">OTRS</div>
                <app-form-generator
                    [formGeneratorInput]="otrsFormGenInput"
                    (formGroupRef)="otrsFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="otrs"
                *ngIf="
                    otrsUserFormGenInput &&
                    selectedIntegrations.has(IntegrationName.OTRS_USER)
                "
            >
                <div class="heading">OTRS (User Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="otrsUserFormGenInput"
                    (formGroupRef)="otrsUserFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="otrs"
                *ngIf="
                    otrsParentFormGenInput &&
                    selectedIntegrations.has(IntegrationName.OTRS_PARENT)
                "
            >
                <div class="heading">OTRS (Parent Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="otrsParentFormGenInput"
                    (formGroupRef)="otrsParentFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="casdm"
                *ngIf="
                    casdmFormGenInput &&
                    selectedIntegrations.has(IntegrationName.CASDM)
                "
            >
                <div class="heading">CASDM</div>
                <app-form-generator
                    [formGeneratorInput]="casdmFormGenInput"
                    (formGroupRef)="casdmFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="casdm"
                *ngIf="
                    casdmChildFormGenInput &&
                    selectedIntegrations.has(IntegrationName.CASDM_CHILD)
                "
            >
                <div class="heading">CASDM (Child Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="casdmChildFormGenInput"
                    (formGroupRef)="casdmChildFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="casdm"
                *ngIf="
                    casdmParentFormGenInput &&
                    selectedIntegrations.has(IntegrationName.CASDM_PARENT)
                "
            >
                <div class="heading">CASDM (Parent Tenant Integration)</div>
                <app-form-generator
                    [formGeneratorInput]="casdmParentFormGenInput"
                    (formGroupRef)="casdmParentFormGroup = $event"
                ></app-form-generator>
            </section>

            <section
                class="salesforce"
                *ngIf="
                    salesforceFormGenInput &&
                    selectedIntegrations.has(IntegrationName.SALESFORCE)
                "
            >
                <div class="heading">Salesforce</div>
                <app-form-generator
                    [formGeneratorInput]="salesforceFormGenInput"
                    (formGroupRef)="salesforceFormGroup = $event"
                ></app-form-generator>

                <app-form-generator
                    *ngIf="salesforceConfigFormGenInput"
                    [formGeneratorInput]="salesforceConfigFormGenInput"
                    (formGroupRef)="salesforceConfigFormGroup = $event"
                ></app-form-generator>
            </section>
        </div>
    </div>
    <div class="tw-flex tw-justify-between controls">
        <app-button-generator
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>
        <app-button-generator
            *ngIf="saveButton"
            [buttonGenInput]="saveButton"
        ></app-button-generator>
    </div>
</div>
