import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { MultiButtonGeneratorComponent } from 'src/app/shared/components/multi-button-generator/multi-button-generator.component';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal.component';
import { FormGeneratorModalComponent } from '../../../form-generator-modal/form-generator-modal.component';
import { TableGeneratorModalComponent } from '../../../table-generator-modal/table-generator-modal.component';
import { NagiosGroupEditModalComponent } from '../nagios-group-edit-modal/nagios-group-edit-modal.component';
import { IMultiButtonOption } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';
enum Tab {
    ADD_CONTACT = 'ADD CONTACT',
    ADD_GROUP = 'ADD GROUP',
    OPEN_DIRECTORY = 'OPEN DIRECTORY'
}
@Component({
    selector: 'app-nagios-integration-step-two',
    templateUrl: './nagios-integration-step-two.component.html',
    styleUrls: ['./nagios-integration-step-two.component.sass']
})
export class NagiosIntegrationStepTwoComponent implements OnInit, OnDestroy {
    Tab = Tab;
    widgetRef: Widget;
    selectedTab: any = Tab.ADD_CONTACT;
    addContactFormGenInputs: IFormGeneratorInput = null;
    addContactFormGroup: FormGroup;
    addGroupFormGenInputs: IFormGeneratorInput = null;
    addGroupFormGroup: FormGroup;
    integrationFormGenInputs: IFormGeneratorInput = null;
    integrationFormGroup: FormGroup;
    contactGroupFormGenInputs: IFormGeneratorInput = null;
    contactGroupFormGroup: FormGroup;
    contactsFormGenInputs: IFormGeneratorInput = null;
    contactsFormGroup: FormGroup;
    phoneBookTableGenInputs: ITableGeneratorInput;
    groupTableGenInputs: ITableGeneratorInput;
    groupTable: boolean = false;
    apiInfo: IApiInfo;
    statusChange: any;
    gridRef: GridOptions;
    filterBy: any = [];
    viewBy: any;
    hideTable: boolean = false;
    selectedRowData: any = [];
    filterByData;
    integrationName: any = [];
    integrationNameOnly: any = [];
    contacts: any = [];
    contactGroup = new Map<string, any>();
    groups: any = [];
    groupContact = new Map<string, any>();
    loader: boolean = false;
    syncLoader: boolean = false;
    closeLoader: boolean = false;
    stepData: any;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    addContactIcon: IIcon = {
        type: IconType.SVG,
        class: 'add_contact'
    };
    addGroupIcon: IIcon = {
        type: IconType.SVG,
        class: 'add_group'
    };
    directoryIcon: IIcon = {
        type: IconType.SVG,
        class: 'open_directory'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    hideFilter: boolean = false;
    refreshTable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    contactButtons: IButtonGeneratorInput[];
    groupButtons: IButtonGeneratorInput[];
    directoryButton: IButtonGeneratorInput[];
    tabChanged: Subject<string>;
    directoryButtonOptopns: IMultiButtonOption = {
        layout: {
            justifyContent: 'flex-end'
        }
    };
    backButton: IButtonGeneratorInput = {
        buttonName: 'Back',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.STROKED,
        function: this.back.bind(this)
    };
    groupEditModalClosed: Subject<any> = new Subject();
    @ViewChild('confirmation') confirmationModalTemplate;
    @ViewChild('resetConfirmation') resetConfirmationModalTemplate;
    resetModal: Subscription;
    isChanged: boolean = false;
    constructor(
        public modalData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        public modalService: ModalService,
        private cd: ChangeDetectorRef
    ) {
        this.widgetRef = modalData.data.widgetRef;
        this.tabChanged = modalData.data.tabChanged;
    }
    ngOnDestroy(): void {
        this.resetModal.unsubscribe();
    }
    ngOnInit(): void {
        this.resetModal = this.widgetRef.modalService.resetModal.subscribe(
            () => {
                if (this.selectedTab === Tab.OPEN_DIRECTORY) {
                    return;
                }
                this.openConfirmationModal('reset', this.selectedTab, () => {
                    switch (this.selectedTab) {
                        case Tab.ADD_CONTACT:
                            this.contactsFormGroup.reset();
                            this.integrationFormGroup.reset();
                            this.addContactFormGroup.reset();
                            break;
                        case Tab.ADD_GROUP:
                            this.addGroupFormGroup.reset();
                            this.integrationFormGroup.reset();
                            this.contactGroupFormGroup.reset();
                            break;
                    }
                });
            }
        );
        this.refreshTable.subscribe((data) => {
            if (data === 'group') {
                this.hideTable = true;
                this.hideFilter = true;
                setTimeout(() => {
                    this.hideTable = false;
                }, 0);
                this.groupTableGenInputs = null;
                this.statusChanged(data);
            }
        });
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1)['selfData'];
            this.stepData.forEach((data) => {
                this.integrationNameOnly.push(data.integrationName);
            });
        }
        this.generateContactFields();
        this.phoneBookTableGen();
        this.integrationFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Nagios Integration Name',
                    name: 'integrationName',
                    placeholder: 'Select Integration Name',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    listData: this.getIntegrationName(),
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Integration name is required'
                        }
                    ],
                    value: []
                }
            ]
        };
        this.prepareButtonGenInput();
    }

    prepareButtonGenInput() {
        this.contactButtons = [
            {
                buttonName: ' Add Contact',
                buttonColorType: ButtonColorType.SUCCESS,
                buttonType: ButtonType.STROKED,
                customClass: 'add',
                hoverText: 'Add New Contact',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.addContacts('contact');
                    if (this.contacts.length) {
                        this.contactButtons[1].disable = false;
                    }
                }
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                disable: true,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveControl(null, buttonRef);
                }
            }
        ];
        this.groupButtons = [
            {
                buttonName: ' Add Group',
                buttonColorType: ButtonColorType.SUCCESS,
                buttonType: ButtonType.STROKED,
                customClass: 'add',
                hoverText: 'Add New Group',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.addContacts('group');
                    if (this.groups.length) {
                        this.groupButtons[1].disable = false;
                    }
                }
            },
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                disable: !this.groups.length,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.saveGroup(null, buttonRef);
                }
            }
        ];
        this.directoryButton = [
            {
                buttonName: 'Sync',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                hoverText:
                    this.viewBy === 'group'
                        ? 'Sync Contact Group Directory With Contact Directory'
                        : 'Sync Contact Directory With Contact Group Directory',
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.sync(buttonRef);
                }
            },
            {
                buttonName: 'Delete All',
                buttonColorType: ButtonColorType.WARN,
                buttonType: ButtonType.STROKED,
                disable: true,
                function: this.deleteAllSelectedRow.bind(this)
            },
            {
                buttonName: 'Update',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.editContacts(buttonRef);
                }
            }
        ];
    }

    setUpBasics() {
        this.phoneBookTableGen();
        this.generateContactFields();
        this.generateGroupFields();
    }
    generateContactFields() {
        this.addContactFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Name',
                    name: 'name',
                    placeholder: 'Enter Name',
                    showLabel: true,
                    appearance: 'legacy',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Name is required'
                        }
                    ]
                },
                {
                    label: 'Email',
                    name: 'email',
                    placeholder: 'Enter Email',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'Service Notification',
                    name: 'severity',
                    placeholder: 'Select Service Notification',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    listData: [
                        { id: 'w', label: 'Warning' },
                        { id: 'c', label: 'Critical' }
                    ],
                    showLabel: true,
                    appearance: 'legacy',
                    getKey: 'id',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Service notification is required'
                        }
                    ],
                    value: []
                }
            ]
        };
    }
    generateGroupFields() {
        this.addGroupFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Group Name',
                    name: 'groupName',
                    placeholder: 'Enter Group Name',
                    fieldType: FilterType.TEXT,
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Group name is required'
                        }
                    ]
                }
            ]
        };
    }
    depField(event, fieldToShow) {
        if (event.integrationName && fieldToShow === 'group') {
            this.contactsFormGenInputs = {
                formName: '',
                state: FormState.EDIT,
                submitButton: null,
                fields: [
                    {
                        label: 'Select Group',
                        name: 'group',
                        placeholder: 'Select Group',
                        fieldType: FilterType.DROPDOWN_GROUP_MULTIPLE,
                        apiInfo:
                            this.widgetRef.widgetData.widgetInfo[
                                'nagiosIntegration'
                            ]['listContactGroups'],
                        groupBy: 'group',
                        apiInput: {
                            integrationsList: event.integrationName
                        },
                        showLabel: true,
                        appearance: 'legacy',
                        required: false,
                        getKey: null,
                        value: []
                    }
                ]
            };
            setTimeout(() => {
                this.updateControlInput = {
                    action: UpdateAction.REFRESH,
                    controls: ['group']
                };

                if (this.contactsFormGroup) {
                    this.updateControl.next(this.updateControlInput);
                }
            }, 0);
        }
        if (event.integrationName && fieldToShow === 'contact') {
            this.contactGroupFormGenInputs = {
                formName: '',
                state: FormState.EDIT,
                submitButton: null,
                fields: [
                    {
                        label: 'Select Contact',
                        name: 'selectContact',
                        placeholder: 'Select Contact',
                        fieldType: FilterType.DROPDOWN_GROUP_MULTIPLE,
                        groupBy: 'group',
                        getKey: null,
                        showLabel: true,
                        appearance: 'legacy',
                        apiInput: { integrationsList: event.integrationName },
                        apiInfo:
                            this.widgetRef.widgetData.widgetInfo[
                                'nagiosIntegration'
                            ]['listContacts'],
                        value: [],
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Contact is required'
                            }
                        ]
                    }
                ]
            };
            setTimeout(() => {
                this.updateControlInput = {
                    action: UpdateAction.REFRESH,
                    controls: ['selectContact']
                };

                if (this.contactGroupFormGroup) {
                    this.updateControl.next(this.updateControlInput);
                }
            }, 0);
        }
    }
    getIntegrationName() {
        const integrationName = [];
        if (this.stepData) {
            this.stepData.forEach((data) => {
                integrationName.push({
                    id: data.integrationName,
                    label: data.integrationName
                });
            });
            return integrationName;
        }
        return;
    }
    phoneBookTableGen(viewBy?) {
        this.statusChange = {
            listData: [
                { id: 'contact', label: 'Contact' },
                { id: 'group', label: 'Group' }
            ],
            lightMode: true
        };
        this.tablesData(viewBy);
        if (viewBy === 'group') {
            this.groupTable = true;
            this.groupTableGenInputs = {
                afterResponse: this.tableResponse.bind(this),
                widgetIconData: null,
                tableHeight: 250,
                selection: 'multiple',
                listExtraction: {
                    type: 'NESTED',
                    nestedKey: 'groupView'
                },
                columns: [
                    {
                        columnName: 'Group name',
                        columnKey: 'groupName'
                    },
                    {
                        columnName: 'Members',
                        columnKey: 'membersCount'
                    },
                    {
                        columnName: 'Integration Name',
                        columnKey: 'integrationName'
                    },
                    {
                        columnName: 'Associated Server List',
                        columnKey: 'associatedServerList',
                        buttonGen: true,
                        componentFramework: MultiButtonGeneratorComponent,
                        valueFormatter: (rowData: RowEvent) => {
                            const buttons: IButtonGeneratorInput[] = [
                                {
                                    buttonName: 'View',
                                    buttonType: ButtonType.TEXT,
                                    buttonColorType: ButtonColorType.SUCCESS,
                                    disable: !(
                                        rowData.data['serverStatus'] &&
                                        rowData.data['serverStatus'].length
                                    ),
                                    function: () => {
                                        this.showAssociation(rowData);
                                    }
                                }
                            ];
                            rowData['buttonGenInputs'] = buttons;
                            const buttonOptions: IMultiButtonOption = {
                                layout: { justifyContent: 'flex-start' }
                            };
                            rowData['options'] = buttonOptions;
                            return rowData;
                        }
                    },
                    {
                        columnName: 'Action',
                        columnKey: 'action',
                        buttonGen: true,
                        componentFramework: MultiButtonGeneratorComponent,
                        valueFormatter: (rowData: RowEvent) => {
                            const serverStatus = rowData.data['serverStatus'];
                            const buttons: IButtonGeneratorInput[] = [
                                {
                                    buttonName: 'Edit',
                                    buttonType: ButtonType.TEXT,
                                    buttonColorType: ButtonColorType.INFO,
                                    showLoader: false,
                                    function: () => {
                                        let modalId = null;
                                        const customerData = rowData['data'];
                                        const groupModalIcon: IIcon = {
                                            type: IconType.SVG,
                                            class: 'contact_modal_icon'
                                        };
                                        const modalData: IModalData = {
                                            modalName: 'Contact List',
                                            modalIcon: groupModalIcon,
                                            sourceId:
                                                this.widgetRef.uniqueIdentity,
                                            modalType: ModalType.MIDDLE,
                                            modalHeightVh: 90,
                                            modalWidthVw: 65,
                                            modalSteps: [
                                                {
                                                    stepData: {
                                                        componentToLoad:
                                                            NagiosGroupEditModalComponent,
                                                        payload: {
                                                            data: {
                                                                modalData:
                                                                    customerData,
                                                                widgetRef:
                                                                    this
                                                                        .widgetRef,
                                                                refreshListing:
                                                                    this
                                                                        .refreshTable,
                                                                closeCallbackSubject:
                                                                    this
                                                                        .groupEditModalClosed,
                                                                callbackFn:
                                                                    () => {
                                                                        this.isChanged =
                                                                            true;
                                                                    }
                                                            }
                                                        }
                                                    },
                                                    stepName: '',
                                                    resetModal: true
                                                }
                                            ],
                                            closeCallback: () => {
                                                this.groupEditModalClosed.next(
                                                    modalId
                                                );
                                            },
                                            closeModal: () => {
                                                if (!this.isChanged) {
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    return;
                                                }
                                                this.closeConfirmationModal(
                                                    (
                                                        buttonRef,
                                                        confirmationModalId
                                                    ) => {
                                                        this.isChanged = false;
                                                        const obj = {
                                                            modalId,
                                                            buttonRef,
                                                            confirmationModalId
                                                        };
                                                        this.groupEditModalClosed.next(
                                                            obj
                                                        );
                                                    },
                                                    modalId
                                                );
                                            }
                                        };
                                        modalId =
                                            this.widgetRef.modalService.openModal(
                                                modalData
                                            );
                                    }
                                },
                                {
                                    buttonName: 'Delete',
                                    buttonColorType: ButtonColorType.WARN,
                                    buttonType: ButtonType.TEXT,
                                    disable:
                                        serverStatus && serverStatus.length,
                                    showLoader: false,
                                    function:
                                        serverStatus && serverStatus.length
                                            ? null
                                            : () => {
                                                  const confirmationModalData: IConfirmationModal =
                                                      {
                                                          function: (
                                                              modalId
                                                          ) => {
                                                              this.hideTable =
                                                                  true;
                                                              this.hideFilter =
                                                                  true;
                                                              const apiConf: IHitApi =
                                                                  Helper.generateHitApiConfig(
                                                                      this
                                                                          .widgetRef
                                                                          .widgetData
                                                                          .widgetInfo[
                                                                          'nagiosIntegration'
                                                                      ][
                                                                          'deleteContactGroupFromNagios'
                                                                      ]
                                                                  );
                                                              apiConf.input =
                                                                  this.prepareDeleteInput(
                                                                      rowData.data
                                                                  );
                                                              apiConf.function =
                                                                  () => {
                                                                      this.widgetRef.notificationsService.showSnackBar(
                                                                          'Contact-Group Deleted Successfully.'
                                                                      );
                                                                      this.hideTable =
                                                                          false;
                                                                      this.widgetRef.modalService.closeModal(
                                                                          null,
                                                                          modalId
                                                                      );
                                                                      this.widgetRef.refreshWidget();
                                                                  };
                                                              apiConf.errorFunction =
                                                                  (error) => {
                                                                      Helper.showErrorMessage(
                                                                          this
                                                                              .widgetRef
                                                                              .notificationsService,
                                                                          error,
                                                                          'Error while deleting contact-group.'
                                                                      );
                                                                      this.widgetRef.modalService.closeModal(
                                                                          null,
                                                                          modalId
                                                                      );
                                                                  };

                                                              new HitApi(
                                                                  apiConf,
                                                                  this.widgetRef.httpService,
                                                                  this.widgetRef.ngZone
                                                              ).hitApi();
                                                          },

                                                          modalName:
                                                              'Delete Contact-Group',
                                                          modalIcon: {
                                                              type: IconType.FONTAWSOME,
                                                              class: 'fas fa-trash'
                                                          },
                                                          contextIcon: {
                                                              extraClass:
                                                                  'color-accent',
                                                              type: IconType.FONTAWSOME,
                                                              class: 'fas fa-exclamation-triangle'
                                                          },
                                                          confirmationMessage: `Are you sure you want to delete the Contact-Group ?`,
                                                          buttonText: 'Delete',
                                                          buttonColorType:
                                                              ButtonColorType.WARN,
                                                          loaderOnExec: true
                                                      };
                                                  const modalData: IModalData =
                                                      {
                                                          modalName:
                                                              confirmationModalData.modalName,
                                                          modalIcon:
                                                              confirmationModalData.modalIcon,
                                                          sourceId: Symbol(),
                                                          modalType:
                                                              ModalType.MIDDLE,
                                                          modalHeightVh: 40,
                                                          modalWidthVw: 50,
                                                          modalSteps: [
                                                              {
                                                                  stepData: {
                                                                      componentToLoad:
                                                                          ConfirmationModalComponent,
                                                                      payload: {
                                                                          data: {
                                                                              function:
                                                                                  confirmationModalData.function,
                                                                              params: confirmationModalData
                                                                          }
                                                                      }
                                                                  },
                                                                  stepName:
                                                                      'Confirmation'
                                                              }
                                                          ]
                                                      };
                                                  this.widgetRef.modalService.openModal(
                                                      modalData
                                                  );
                                              }
                                }
                            ];
                            const buttonOptions: IMultiButtonOption = {
                                layout: { justifyContent: 'space-evenly' }
                            };
                            rowData['options'] = buttonOptions;
                            rowData['buttonGenInputs'] = buttons;
                            return rowData;
                        }
                    }
                ],
                removeHeaderCheckBox: false,
                apiInput: { integrationsList: this.integrationNameOnly }
            };
            this.groupTableGenInputs = Helper.dereference(
                this.groupTableGenInputs
            );
        } else {
            this.groupTable = false;
            this.phoneBookTableGenInputs = {
                afterResponse: this.tableResponse.bind(this),
                selection: 'multiple',
                listExtraction: {
                    type: 'NESTED',
                    nestedKey: 'contactsView'
                },
                tableHeight: 250,
                removeHeaderCheckBox: false,
                isRowSelectable: (rowNode: RowNode) => {
                    return !(
                        rowNode.data['serverStatus'] &&
                        rowNode.data['serverStatus'].length
                    );
                },
                columns: [
                    {
                        columnName: 'Contact Name',
                        columnKey: 'contactName'
                    },
                    {
                        columnName: 'Email',
                        columnKey: 'email',
                        editable: true
                    },
                    {
                        columnName: 'Contact Groups',
                        columnKey: 'contactGroups'
                    },
                    {
                        columnName: 'Severity',
                        columnKey: 'severity',
                        editable: true
                    },
                    {
                        columnName: 'Integration Name',
                        columnKey: 'integrationName'
                    },
                    {
                        columnName: 'Action',
                        columnKey: 'action',
                        width: 300,
                        buttonGen: true,
                        componentFramework: MultiButtonGeneratorComponent,
                        valueFormatter: (rowData: RowEvent) => {
                            const serverStatus = rowData.data['serverStatus'];
                            const buttons: IButtonGeneratorInput[] = [
                                {
                                    buttonName: 'View',
                                    buttonColorType: ButtonColorType.SUCCESS,
                                    buttonType: ButtonType.TEXT,
                                    disable: !(
                                        rowData.data['serverStatus'] &&
                                        rowData.data['serverStatus'].length
                                    ),
                                    function: () => {
                                        this.showAssociation(rowData);
                                    }
                                },
                                {
                                    buttonName: 'Edit',
                                    buttonColorType: ButtonColorType.INFO,
                                    buttonType: ButtonType.TEXT,
                                    function: () => {
                                        const formFields: IFormField[] = [];
                                        const columnIds = Object.keys(
                                            rowData.data
                                        );
                                        columnIds.forEach((id) => {
                                            const columnInstance =
                                                rowData.columnApi.getColumn(id);
                                            if (columnInstance) {
                                                const colDef =
                                                    columnInstance.getColDef();

                                                const labelLowerCased =
                                                    colDef.headerName.toLowerCase();

                                                formFields.push({
                                                    name: colDef.field,
                                                    label: colDef.headerName,
                                                    placeholder:
                                                        colDef.headerName,
                                                    fieldType:
                                                        labelLowerCased.includes(
                                                            'severity'
                                                        )
                                                            ? FilterType.DROPDOWN_MULTIPLE
                                                            : FilterType.TEXT,
                                                    disabled: !colDef.editable,
                                                    showLabel: true,
                                                    appearance: 'legacy',
                                                    value: rowData.data[id],
                                                    required: true,
                                                    listData:
                                                        labelLowerCased.includes(
                                                            'severity'
                                                        )
                                                            ? [
                                                                  {
                                                                      id: 'Critical',
                                                                      label: 'Critical'
                                                                  },
                                                                  {
                                                                      id: 'Warning',
                                                                      label: 'Warning'
                                                                  }
                                                              ]
                                                            : null,
                                                    validations:
                                                        labelLowerCased.includes(
                                                            'email'
                                                        )
                                                            ? [
                                                                  {
                                                                      validator:
                                                                          CustomValidators.email,
                                                                      errorMessage:
                                                                          'Invalid email'
                                                                  }
                                                              ]
                                                            : [
                                                                  {
                                                                      validator:
                                                                          CustomValidators.required,
                                                                      errorMessage: `${colDef.headerName} is required`
                                                                  }
                                                              ]
                                                });
                                            }
                                        });

                                        const editFormGenInput: IFormGeneratorInput =
                                            {
                                                formName:
                                                    'Nagios XI Integration',
                                                state: FormState.CREATE,
                                                submitButton: {
                                                    buttonName: 'Update',
                                                    buttonColorType:
                                                        ButtonColorType.PRIMARY,
                                                    buttonType:
                                                        ButtonType.STROKED,
                                                    showLoader: true,
                                                    function: (
                                                        buttonRef: IButtonGeneratorInput,
                                                        formGroup: FormGroup
                                                    ) => {
                                                        const formGroupValueKeys =
                                                            Object.keys(
                                                                formGroup.value
                                                            );
                                                        const rowDataKeys =
                                                            Object.keys(
                                                                rowData.data
                                                            );

                                                        rowDataKeys.forEach(
                                                            (key) => {
                                                                if (
                                                                    formGroupValueKeys.includes(
                                                                        key
                                                                    )
                                                                ) {
                                                                    if (
                                                                        Array.isArray(
                                                                            formGroup
                                                                                .value[
                                                                                key
                                                                            ]
                                                                        )
                                                                    ) {
                                                                        rowData.node.setDataValue(
                                                                            key,
                                                                            formGroup.value[
                                                                                key
                                                                            ].toString()
                                                                        );
                                                                    } else {
                                                                        rowData.node.setDataValue(
                                                                            key,
                                                                            formGroup
                                                                                .value[
                                                                                key
                                                                            ]
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        );
                                                        this.editContacts(
                                                            buttonRef
                                                        );
                                                        this.widgetRef.modalService.closeModal(
                                                            null,
                                                            modalData.modalId
                                                        );
                                                    }
                                                },
                                                fields: formFields,
                                                extraClass: 'add-scroll'
                                            };
                                        const modalData: IModalData = {
                                            modalName: 'Nagios Integration',
                                            modalIcon: null,
                                            modalType: ModalType.MIDDLE,
                                            sourceId: null,
                                            modalId: Symbol(),
                                            extraStepContainerClass:
                                                'remove-form-space',
                                            modalWidthVw: 50,
                                            modalHeightVh: 65,
                                            modalSteps: [
                                                {
                                                    stepName:
                                                        'Nagios Integration',
                                                    stepData: {
                                                        componentToLoad:
                                                            FormGeneratorModalComponent,
                                                        payload: {
                                                            data: editFormGenInput
                                                        }
                                                    }
                                                }
                                            ]
                                        };
                                        this.widgetRef.modalService.openModal(
                                            modalData
                                        );
                                    }
                                },
                                {
                                    buttonName: 'Delete',
                                    buttonType: ButtonType.TEXT,
                                    buttonColorType: ButtonColorType.WARN,
                                    disable:
                                        serverStatus && serverStatus.length,
                                    showLoader: false,
                                    function: (
                                        buttonRef: IButtonGeneratorInput
                                    ) => {
                                        const confirmationModalData: IConfirmationModal =
                                            {
                                                function: (modalId) => {
                                                    this.hideTable = true;
                                                    this.hideFilter = true;
                                                    const apiConf: IHitApi =
                                                        Helper.generateHitApiConfig(
                                                            this.widgetRef
                                                                .widgetData
                                                                .widgetInfo[
                                                                'nagiosIntegration'
                                                            ][
                                                                'deleteContactFromNagios'
                                                            ]
                                                        );
                                                    const contactData = [];
                                                    contactData.push(
                                                        rowData.data
                                                    );
                                                    apiConf.input =
                                                        this.prepareDeleteInput(
                                                            rowData.data
                                                        );
                                                    apiConf.function = () => {
                                                        this.widgetRef.notificationsService.showSnackBar(
                                                            'Contact Deleted Successfully.'
                                                        );
                                                        this.hideTable = false;

                                                        this.widgetRef.refreshWidget();
                                                        this.widgetRef.modalService.closeModal(
                                                            null,
                                                            modalId
                                                        );
                                                    };
                                                    apiConf.errorFunction = (
                                                        error
                                                    ) => {
                                                        Helper.showErrorMessage(
                                                            this.widgetRef
                                                                .notificationsService,
                                                            error,
                                                            'Error while deleting contact.'
                                                        );
                                                        this.widgetRef.modalService.closeModal(
                                                            null,
                                                            modalId
                                                        );
                                                    };

                                                    new HitApi(
                                                        apiConf,
                                                        this.widgetRef.httpService,
                                                        this.widgetRef.ngZone
                                                    ).hitApi();
                                                },

                                                modalName: 'Delete Contact',
                                                modalIcon: {
                                                    type: IconType.FONTAWSOME,
                                                    class: 'fas fa-trash'
                                                },
                                                contextIcon: {
                                                    extraClass: 'color-accent',
                                                    type: IconType.FONTAWSOME,
                                                    class: 'fas fa-exclamation-triangle'
                                                },
                                                confirmationMessage: `Are you sure you want to delete the Contact?`,
                                                buttonText: 'Delete',
                                                buttonColorType:
                                                    ButtonColorType.WARN,
                                                loaderOnExec: true
                                            };
                                        const modalData: IModalData = {
                                            modalName:
                                                confirmationModalData.modalName,
                                            modalIcon:
                                                confirmationModalData.modalIcon,
                                            sourceId: Symbol(),
                                            modalType: ModalType.MIDDLE,
                                            modalHeightVh: 40,
                                            modalWidthVw: 50,
                                            modalSteps: [
                                                {
                                                    stepData: {
                                                        componentToLoad:
                                                            ConfirmationModalComponent,
                                                        payload: {
                                                            data: {
                                                                function:
                                                                    confirmationModalData.function,
                                                                params: confirmationModalData
                                                            }
                                                        }
                                                    },
                                                    stepName: 'Confirmation'
                                                }
                                            ]
                                        };
                                        this.widgetRef.modalService.openModal(
                                            modalData
                                        );
                                    }
                                }
                            ];
                            rowData['buttonGenInputs'] = buttons;
                            const buttonOptions: IMultiButtonOption = {
                                layout: { justifyContent: 'flex-start' }
                            };
                            rowData['options'] = buttonOptions;
                            return rowData;
                        }
                    }
                ],
                apiInput: { integrationsList: this.integrationNameOnly }
            };
        }
    }
    tableResponse(data) {
        const tableData =
            data && data['contactsView']
                ? data['contactsView']
                : data['groupView'];

        this.filterBy = [];
        const integrationName = [];
        if (tableData && tableData.length) {
            tableData.forEach((element) => {
                const obj = {};

                if (
                    !integrationName.includes(element.integrationName) &&
                    element.integrationName
                ) {
                    obj['id'] = element.integrationName;
                    obj['label'] = element.integrationName;
                    this.filterBy.push(obj);
                    integrationName.push(element.integrationName);
                }
            });
        }

        this.filterByData = {
            listData: this.filterBy,
            lightMode: true,
            placeholder: 'Integration Name'
        };
        this.filterByData = { ...this.filterByData };
        this.hideFilter = false;
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    editContacts(buttonRef: IButtonGeneratorInput) {
        if (this.loader || this.syncLoader || !this.gridRef) {
            return;
        }
        this.gridRef.api.stopEditing();
        this.loader = true;
        buttonRef.loader = true;
        const apiConf: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'editContactToNagios'
            ]
        );
        apiConf.input = this.prepareEditContactInput();
        apiConf.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Contact Updated Successfully'
            );
            this.loader = false;
            buttonRef.loader = false;
        };
        apiConf.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while editing contact.'
            );
            this.loader = false;
            buttonRef.loader = false;
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    sync(buttonRef?: IButtonGeneratorInput) {
        if (buttonRef.loader || this.loader || !this.gridRef) {
            return;
        }
        this.gridRef.api.stopEditing();
        buttonRef.loader = true;
        this.syncLoader = true;
        const apiConf: IHitApi =
            this.viewBy === 'group'
                ? Helper.generateHitApiConfig(
                      this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                          'syncContactGroupToNagios'
                      ]
                  )
                : Helper.generateHitApiConfig(
                      this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                          'syncContactToNagios'
                      ]
                  );
        apiConf.input =
            this.viewBy === 'group'
                ? { groupView: this.gridRef.rowData }
                : { contactsView: this.gridRef.rowData };
        apiConf.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                this.viewBy === 'group'
                    ? 'Contact-Groups Synchronized Successfully.'
                    : 'Contacts Synchronized Successfully'
            );
            const tableData =
                this.viewBy === 'group'
                    ? response['groupView']
                    : response['contactsView'];

            this.gridRef.api.setRowData(tableData);
            this.syncLoader = false;
            buttonRef.loader = false;
        };
        apiConf.errorFunction = (error) => {
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.syncLoader = false;
            buttonRef.loader = false;
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    tablesData(viewBy?) {
        this.apiInfo =
            viewBy === 'group'
                ? this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                      'listContactGroupForAlerting'
                  ]
                : this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                      'listContactFromNagios'
                  ];
    }
    getSelectedRow() {
        this.selectedRowData = [];
        const selectedNodes = this.gridRef.api.getSelectedNodes();
        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                this.selectedRowData.push(selectedRow.data);
            });
        }
        if (this.selectedRowData.length > 1) {
            this.directoryButton[1].disable = false;
        }
    }
    deleteAllSelectedRow() {
        if (this.loader || this.syncLoader || !this.gridRef) {
            return;
        }
        if (!this.selectedRowData.length) {
            this.widgetRef.notificationsService.showSnackBar(
                `Please select atleast one ${
                    this.viewBy === 'group' ? 'group' : 'contact'
                } to delete`,

                true
            );
            return;
        }
        const confirmationModalData: IConfirmationModal = {
            function: (modalId) => {
                const apiConf: IHitApi =
                    this.viewBy === 'group'
                        ? Helper.generateHitApiConfig(
                              this.widgetRef.widgetData.widgetInfo[
                                  'nagiosIntegration'
                              ]['deleteContactGroupFromNagios']
                          )
                        : Helper.generateHitApiConfig(
                              this.widgetRef.widgetData.widgetInfo[
                                  'nagiosIntegration'
                              ]['deleteContactFromNagios']
                          );
                this.hideTable = true;
                this.hideFilter = true;
                apiConf.input = this.prepareDeleteAllInput();
                apiConf.function = () => {
                    this.hideTable = false;
                    this.widgetRef.notificationsService.showSnackBar(
                        this.viewBy === 'group'
                            ? 'Contact-Groups Deleted Successfully.'
                            : 'Contacts Deleted Successfully.'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.selectedRowData = [];
                };
                apiConf.errorFunction = (error) => {
                    this.hideTable = false;
                    this.hideFilter = false;
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        this.viewBy === 'group'
                            ? 'Error while deleting contacts-groups'
                            : 'Error while deleting contacts'
                    );
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiConf,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },

            modalName:
                this.viewBy === 'group'
                    ? 'Delete Contact-Groups'
                    : 'Delete Contacts',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage:
                this.viewBy === 'group'
                    ? `Are you sure you want to delete the contact-groups?`
                    : `Are you sure you want to delete the contacts?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 40,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    prepareDeleteAllInput() {
        const integration = [];
        if (this.viewBy === 'group') {
            this.selectedRowData.forEach((group) => {
                const input = {
                    contactGroup: group['groupName'],
                    integrationName: group['integrationName']
                };
                integration.push(input);
            });
            return { contactGroupList: integration };
        } else {
            this.selectedRowData.forEach((contact) => {
                const input = {
                    contacts: contact['contactName'],
                    integrationName: contact['integrationName']
                };
                integration.push(input);
            });

            return { contactsList: integration };
        }
    }
    prepareDeleteInput(data) {
        const integration = [];
        if (this.viewBy === 'group') {
            const rowData = {
                contactGroup: data['groupName'],
                integrationName: data['integrationName']
            };
            integration.push(rowData);
            return { contactGroupList: integration };
        } else {
            const rowData = {
                contacts: data['contactName'],
                integrationName: data['integrationName']
            };
            integration.push(rowData);
            return { contactsList: integration };
        }
    }
    statusChanged(event) {
        this.directoryButton[1].disable = true;
        this.viewBy = event;
        this.phoneBookTableGen(event);
        this.cd.detectChanges();
        this.selectedRowData = [];
        this.filterByData = null;
    }
    changeTab(tab, selectedView?) {
        if (this.selectedTab !== tab) {
            this.openConfirmationModal('tabChange', this.selectedTab, () => {
                this.selectedTab = tab ? tab : Tab.ADD_CONTACT;
                this.loader = false;
                if (this.selectedTab === Tab.ADD_CONTACT) {
                    this.generateContactFields();
                } else if (this.selectedTab === Tab.ADD_GROUP) {
                    this.generateGroupFields();
                } else if (this.selectedTab === Tab.OPEN_DIRECTORY) {
                    this.phoneBookTableGen(selectedView);
                    this.viewBy = 'contact';
                }
                this.contacts = [];
                this.groups = [];
                this.contactGroup.clear();
                this.groupContact.clear();
                this.integrationFormGroup.reset();
            });
        }
        this.tabChanged.next(this.selectedTab);
    }
    onQuickFilterChanged(filterdata) {
        if (!filterdata.length) {
            if (this.gridRef) {
                this.gridRef.api.setRowData(this.gridRef.rowData);
                return;
            }
            return;
        }
        const filteredData = [];
        this.gridRef.rowData.forEach((data) => {
            if (filterdata.includes(data.integrationName)) {
                filteredData.push(data);
            }
        });

        this.gridRef.api.setRowData(filteredData);
    }
    addContacts(contactType) {
        if (contactType === 'group') {
            Helper.markAllFieldAsTouched(this.addGroupFormGroup);
            Helper.markAllFieldAsTouched(this.integrationFormGroup);
            Helper.markAllFieldAsTouched(this.contactGroupFormGroup);
            if (
                this.addGroupFormGroup.invalid ||
                this.integrationFormGroup.invalid ||
                this.contactGroupFormGroup.invalid
            ) {
                this.addGroupFormGroup.updateValueAndValidity();
                this.integrationFormGroup.updateValueAndValidity();
                this.contactGroupFormGroup.updateValueAndValidity();
                return;
            } else {
                if (this.groups && this.groups.length) {
                    this.checkIfNameExists(
                        this.addGroupFormGroup.value['groupName'],
                        contactType
                    );
                } else {
                    const group = {
                        ...this.integrationFormGroup.value,
                        ...this.addGroupFormGroup.value
                    };
                    this.groups.push(group);
                    if (
                        this.contactGroupFormGroup &&
                        this.contactGroupFormGroup.value
                    ) {
                        const groups = {};
                        this.contactGroupFormGroup
                            .get('selectContact')
                            .value.forEach((groupObj) => {
                                const keys = Object.keys(groups);
                                if (keys.includes(groupObj.group)) {
                                    groups[groupObj.group] = [
                                        ...groups[groupObj.group],
                                        groupObj.id
                                    ];
                                } else {
                                    groups[groupObj.group] = [groupObj.id];
                                }
                            });
                        this.groupContact.set(
                            this.addGroupFormGroup.get('groupName').value,
                            groups
                        );
                    }
                    this.contactGroupFormGroup.reset();
                    this.addGroupFormGroup.reset();
                    this.integrationFormGroup.reset();
                }
            }
        }
        if (contactType === 'contact') {
            Helper.markAllFieldAsTouched(this.addContactFormGroup);
            Helper.markAllFieldAsTouched(this.integrationFormGroup);
            if (
                this.addContactFormGroup.invalid ||
                this.integrationFormGroup.invalid
            ) {
                this.addContactFormGroup.updateValueAndValidity();
                this.integrationFormGroup.updateValueAndValidity();
                return;
            } else {
                if (this.contacts && this.contacts.length) {
                    this.checkIfNameExists(
                        this.addContactFormGroup.value['name'],
                        contactType
                    );
                } else {
                    const contact = {
                        ...this.integrationFormGroup.value,
                        ...this.addContactFormGroup.value
                    };

                    this.contacts.push(contact);
                    if (
                        this.contactsFormGroup &&
                        this.contactsFormGroup.value
                    ) {
                        const groups = {};
                        this.contactsFormGroup
                            .get('group')
                            .value.forEach((groupObj) => {
                                const keys = Object.keys(groups);
                                if (keys.includes(groupObj.group)) {
                                    groups[groupObj.group] = [
                                        ...groups[groupObj.group],
                                        groupObj.id
                                    ];
                                } else {
                                    groups[groupObj.group] = [groupObj.id];
                                }
                            });
                        this.contactGroup.set(
                            this.addContactFormGroup.get('name').value,
                            groups
                        );
                    }
                    this.integrationFormGroup.reset();
                    this.addContactFormGroup.reset();
                    this.contactsFormGroup.reset();
                }
            }
        }
    }
    clearList(type) {
        if (type === 'contact') {
            this.contacts = [];
            this.contactButtons[1].disable = true;
        } else {
            this.groups = [];
        }
    }
    checkIfNameExists(name: string, contactType: string) {
        if (contactType === 'contact') {
            const contactNameIndex = this.contacts.findIndex(
                (integration) => integration.name === name
            );
            if (contactNameIndex > -1) {
                this.widgetRef.notificationsService.showSnackBar(
                    'The name already exist. Please enters a new name.',
                    true
                );
                return;
            } else {
                const contact = {
                    ...this.integrationFormGroup.value,
                    ...this.addContactFormGroup.value
                };
                this.contacts.push(contact);
                if (this.contactsFormGroup && this.contactsFormGroup.value) {
                    const groups = {};
                    this.contactsFormGroup
                        .get('group')
                        .value.forEach((groupObj) => {
                            const keys = Object.keys(groups);
                            if (keys.includes(groupObj.group)) {
                                groups[groupObj.group] = [
                                    ...groups[groupObj.group],
                                    groupObj.id
                                ];
                            } else {
                                groups[groupObj.group] = [groupObj.id];
                            }
                        });
                    this.contactGroup.set(
                        this.addContactFormGroup.get('name').value,
                        groups
                    );
                }

                this.addContactFormGroup.reset();
                this.contactsFormGroup.reset();
                this.integrationFormGroup.reset();
            }
        }
        if (contactType === 'group') {
            const groupNameIndex = this.groups.findIndex(
                (integration) => integration.groupName === name
            );
            if (groupNameIndex > -1) {
                this.widgetRef.notificationsService.showSnackBar(
                    'The name already exist. Please enters a new name.',
                    true
                );
                return;
            } else {
                const group = {
                    ...this.integrationFormGroup.value,
                    ...this.addGroupFormGroup.value
                };
                this.groups.push(group);
                if (
                    this.contactGroupFormGroup &&
                    this.contactGroupFormGroup.value
                ) {
                    const groups = {};
                    this.contactGroupFormGroup
                        .get('selectContact')
                        .value.forEach((groupObj) => {
                            const keys = Object.keys(groups);
                            if (keys.includes(groupObj.group)) {
                                groups[groupObj.group] = [
                                    ...groups[groupObj.group],
                                    groupObj.id
                                ];
                            } else {
                                groups[groupObj.group] = [groupObj.id];
                            }
                        });
                    this.groupContact.set(
                        this.addGroupFormGroup.get('groupName').value,
                        groups
                    );
                }
                this.contactGroupFormGroup.reset();
                this.addGroupFormGroup.reset();
                this.integrationFormGroup.reset();
            }
        }
    }
    saveControl(action?, buttonRef?: IButtonGeneratorInput) {
        if (!this.contacts.length) {
            this.widgetRef.notificationsService.showSnackBar(
                `Please add atleast one contact.`,
                true
            );
            return;
        }
        if (this.loader || this.closeLoader) {
            return;
        }
        if (action === 'close') {
            this.closeLoader = true;
        } else {
            this.loader = true;
            buttonRef.loader = true;
        }
        const apiConf: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'addContactToNagios'
            ]
        );
        apiConf.input = this.prepareInputs();

        apiConf.function = () => {
            if (action === 'close') {
                this.closeLoader = false;
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            } else {
                this.loader = false;
                buttonRef.loader = false;
            }
            this.widgetRef.notificationsService.showSnackBar(
                'New Contact Added Successfully'
            );
            this.contacts = [];
            this.contactGroup.clear();
        };
        apiConf.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while integrating.'
            );
            if (action === 'close') {
                this.closeLoader = false;
            } else {
                this.loader = false;
                buttonRef.loader = false;
            }
        };
        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    saveGroup(action?, buttonRef?: IButtonGeneratorInput) {
        if (!this.groups.length) {
            this.widgetRef.notificationsService.showSnackBar(
                `Please add atleast one contact-group.`,
                true
            );
            return;
        }
        if (this.loader || this.closeLoader) {
            return;
        }
        if (action === 'close') {
            this.closeLoader = true;
        } else {
            this.loader = true;
            buttonRef.loader = true;
        }
        const apiConf: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['nagiosIntegration'][
                'addNewContactGroup'
            ]
        );
        apiConf.input = this.prepareGroupInputs();

        apiConf.function = () => {
            if (action === 'close') {
                this.closeLoader = false;
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            } else {
                this.loader = false;
                buttonRef.loader = false;
            }
            this.widgetRef.notificationsService.showSnackBar(
                'New Group Added Successfully'
            );
            this.groups = [];
            this.groupContact.clear();
        };
        apiConf.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while integrating.'
            );
            if (action === 'close') {
                this.closeLoader = false;
            } else {
                this.loader = false;
                buttonRef.loader = false;
            }
        };

        new HitApi(
            apiConf,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInputs() {
        const inputs = [];
        this.contacts.forEach((contact) => {
            inputs.push({
                name: contact.name,
                email: contact.email,
                severity: contact.severity,
                contactGroup: this.getContactGroup(contact),
                integrationName: contact.integrationName
            });
        });

        return { contactsList: inputs };
    }
    getContactGroup(contact) {
        const contactGroups = {};

        contact.integrationName.forEach((integrationName) => {
            this.contactGroup.forEach((group) => {
                if (group[integrationName]) {
                    contactGroups[integrationName] = group[integrationName];
                } else {
                    contactGroups[integrationName] = [];
                }
            });
        });

        return contactGroups;
    }
    prepareGroupInputs() {
        const inputs = [];
        this.groups.forEach((group) => {
            inputs.push({
                groupName: group.groupName,
                contacts: this.getContact(group),
                integrationName: group.integrationName
            });
        });
        return { contactsGroupList: inputs };
    }
    getContact(group) {
        const contact = {};

        group.integrationName.forEach((integrationName) => {
            this.groupContact.forEach((group) => {
                if (group[integrationName]) {
                    contact[integrationName] = group[integrationName];
                } else {
                    contact[integrationName] = [];
                }
            });
        });

        return contact;
    }
    prepareEditContactInput() {
        const contactList = [];
        let severity = [];
        this.gridRef.rowData.forEach((contactRowData) => {
            const contact = {};
            const keys = Object.keys(contact);

            if (keys.includes(contactRowData.integrationName)) {
                contact[contactRowData.integrationName] = [
                    ...contact[contactRowData.contactGroups],
                    contactRowData.contactGroups
                ];
            } else {
                contact[contactRowData.integrationName] =
                    contactRowData.contactGroups;
            }
            contactRowData['severity'].forEach((sev) => {
                if (sev === 'Warning') {
                    severity.push('w');
                } else if (sev === 'Critical') {
                    severity.push('c');
                }
            });

            const inputs = {
                name: contactRowData['contactName'],
                email: contactRowData['email'],
                severity: severity,
                integrationName: [contactRowData['integrationName']],
                contactGroup: contact
            };
            contactList.push(inputs);
            severity = [];
        });

        return { contactsList: contactList };
    }
    back() {
        this.multiStepFormService.previousStep(this.modalData.modalId);
    }

    showAssociation(rowData: RowEvent) {
        const serverStatus = rowData.data['serverStatus'];
        if (serverStatus && serverStatus.length) {
            const tableGenInputs = {
                afterResponse: null,
                widgetIconData: null,
                selection: 'single',
                listExtraction: {
                    type: 'DIRECT'
                },
                columns: [
                    {
                        columnName: 'Server Name',
                        columnKey: 'serverName'
                    },

                    {
                        columnName: 'Connection Point',
                        columnKey: 'connectionPoint'
                    }
                ]
            };
            const serverListIcon: IIcon = {
                type: IconType.SVG,
                class: 'contact_modal_icon'
            };
            const modalData: IModalData = {
                modalName: 'Associated Server List',
                modalIcon: serverListIcon,
                sourceId: Symbol(),
                modalType: ModalType.MIDDLE,
                modalHeightVh: 50,
                modalWidthVw: 50,
                modalSteps: [
                    {
                        stepData: {
                            componentToLoad: TableGeneratorModalComponent,
                            payload: {
                                data: {
                                    tableData: serverStatus,
                                    tableGenInputs: tableGenInputs
                                }
                            }
                        },
                        stepName: 'Confirmation'
                    }
                ]
            };
            this.widgetRef.modalService.openModal(modalData);
        }
    }

    openConfirmationModal(reason, selectedTab, cb) {
        if (reason === 'tabChange') {
            if (selectedTab === Tab.ADD_CONTACT && !this.contacts.length) {
                cb();
                return;
            }
            if (selectedTab === Tab.ADD_GROUP && !this.groups.length) {
                cb();
                return;
            }
            if (selectedTab === Tab.OPEN_DIRECTORY) {
                cb();
                return;
            }
        }

        if (reason === 'reset') {
            if (
                selectedTab === Tab.ADD_CONTACT &&
                !this.contactsFormGroup.value &&
                !this.integrationFormGroup.value &&
                this.addContactFormGroup.value
            ) {
                cb();
                return;
            }
            if (
                selectedTab === Tab.ADD_GROUP &&
                !this.addGroupFormGroup.value &&
                !this.integrationFormGroup.value &&
                !this.contactGroupFormGroup.value
            ) {
                cb();
                return;
            }
        }

        let modalId = null;
        const modalButtons: IButtonGeneratorInput[] = [
            {
                buttonName: reason === 'tabChange' ? `Don't Save` : 'Cancel',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    if (reason === 'tabChange') {
                        cb();
                    }
                    if (modalId) {
                        this.widgetRef.modalService.closeModal(null, modalId);
                    }
                }
            },
            {
                buttonName: reason === 'tabChange' ? 'Save' : 'Proceed',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    if (reason === 'tabChange') {
                        if (selectedTab === Tab.ADD_CONTACT) {
                            this.saveControl(null, buttonRef);
                        }
                        if (selectedTab === Tab.ADD_GROUP) {
                            this.saveGroup(null, buttonRef);
                        }
                    }
                    cb();
                    this.widgetRef.modalService.closeModal(null, modalId);
                }
            }
        ];
        const buttonOptions: IMultiButtonOption = {
            layout: {
                justifyContent: 'center'
            }
        };
        const modalData: IConfirmationModal = {
            modalName: '',
            confirmationMessage: null,
            buttonText: reason === 'tabChange' ? 'Save' : 'Proceed',
            noHeaderLine: true,
            bodyTemplate:
                reason === 'tabChange'
                    ? this.confirmationModalTemplate
                    : this.resetConfirmationModalTemplate,
            modalWidthVw: 30,
            modalHeightVh: 40,
            buttonPosition: 'center',
            customButtonGeneratorInput: {
                buttonGenInput: modalButtons,
                options: buttonOptions
            },
            extraClass: 'confirmation-container',
            buttonColorType: ButtonColorType.PRIMARY
        };
        modalId = this.widgetRef.modalService.openConfirmationModal(modalData);
    }
    closeConfirmationModal(cb, parentModalId) {
        let modalId = null;
        const modalButtons: IButtonGeneratorInput[] = [
            {
                buttonName: `Don't Save`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: () => {
                    if (modalId) {
                        this.widgetRef.modalService.closeModal(null, modalId);
                    }
                    if (parentModalId) {
                        this.widgetRef.modalService.closeModal(
                            null,
                            parentModalId
                        );
                    }
                    this.isChanged = false;
                }
            },
            {
                buttonName: 'Save',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef: IButtonGeneratorInput) => {
                    cb(buttonRef, modalId);
                },
                showLoader: true
            }
        ];
        const buttonOptions: IMultiButtonOption = {
            layout: {
                justifyContent: 'center'
            }
        };
        const modalData: IConfirmationModal = {
            modalName: '',
            confirmationMessage: null,
            buttonText: 'Save',
            noHeaderLine: true,
            bodyTemplate: this.confirmationModalTemplate,
            modalWidthVw: 30,
            modalHeightVh: 30,
            buttonPosition: 'center',
            extraClass: 'confirmation-container',
            customButtonGeneratorInput: {
                buttonGenInput: modalButtons,
                options: buttonOptions
            },
            buttonColorType: ButtonColorType.PRIMARY
        };
        modalId = this.widgetRef.modalService.openConfirmationModal(modalData);
    }
}
