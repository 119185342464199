<div
    class="widget"
    #widget
    [id]="
        widgetData && widgetData.widgetId
            ? widgetData.widgetId
            : widgetContainerId
    "
    *ngIf="
        widgetData &&
        !CUSTOM_STYLED_PORTLETS.includes(widgetData.widgetInfo.portlet.type)
    "
>
    <div class="widget-head" (click)="toggleCollapseWidget($event, widget)">
        <div class="widget-title" [class.with-tabs]="widgetTabsRef">
            <div *ngIf="headerIcon | async">
                <div
                    appIcon
                    [data]="headerIcon | async"
                    class="old-widget-icon"
                    [class.severity-icon]="widgetData.widgetInfo.cloudIconPath"
                ></div>
            </div>

            <div
                class="curve-container"
                *ngIf="widgetData.widgetInfo.cloudIconPath"
            >
                <div appIcon [data]="headingCurveIcon"></div>

                <div>
                    <img
                        class="widget-icon"
                        [src]="widgetData.widgetInfo.cloudIconPath"
                    />
                </div>
            </div>

            <ng-container *ngIf="widgetTabsRef; else normalWidgetName">
                <div
                    class="widget-tabs-container"
                    [style.marginLeft.px]="
                        widgetData.widgetInfo.cloudIconPath ? 60 : ''
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="widgetTabsRef"
                    ></ng-container>
                </div>
            </ng-container>
            <ng-template #normalWidgetName>
                <div
                    class="widget-name"
                    [style.marginLeft.px]="
                        widgetData.widgetInfo.cloudIconPath ? 40 : ''
                    "
                    *ngIf="widgetData && widgetData.widgetInfo.text"
                    [matTooltip]="
                        widgetData.widgetInfo.text
                            ? widgetData.widgetInfo.text
                            : null
                    "
                >
                    {{ widgetData.widgetInfo.text }}
                </div>
            </ng-template>
        </div>
        <div class="widget-actions widget-action-svg">
            <div
                class="attention-required-icon action"
                *ngIf="
                    attentionRequiredList &&
                    attentionRequiredList.length &&
                    widgetConfigState.actions.attention.visibility
                "
                (click)="showAttentionRequired($event)"
                [class.disabled]="!widgetConfigState.actions.attention.state"
            >
                <div
                    appIcon
                    [data]="attentionRequiredIcon"
                    [matTooltip]="'Attention'"
                    class="attention"
                ></div>
            </div>
            <div
                appIcon
                [data]="widgetConfigState.actions.compareView.icon"
                class="action {{
                    (showViewIcon | async) &&
                    !widgetConfigState.actions.compareView.state
                        ? 'disabled grey-color '
                        : ''
                }}"
                *ngIf="
                    (showViewIcon | async) &&
                    widgetConfigState.actions.compareView.visibility &&
                    !this.widgetLinkingData &&
                    !userDataCache.isAssesseeView
                "
                (click)="$event.stopPropagation(); openView()"
                [matTooltip]="'Deep dive and compare'"
            ></div>

            <div
                *ngIf="
                    widgetData.widgetInfo.reports &&
                    !(widgetData.widgetInfo.reports | emptyObject) &&
                    !userDataCache.isAssesseeView
                "
                appIcon
                [data]="widgetConfigState.actions.addToCart.icon"
                class="action"
                [class.highlightText]="widgetInCart | async"
                [matTooltip]="
                    (widgetInCart | async) ? 'Remove from cart' : 'Add to cart'
                "
                (click)="
                    $event.stopPropagation();
                    lightState ? getFullAccess() : addWidgetToCart()
                "
            ></div>

            <div
                [matMenuTriggerFor]="moreInfoMenu"
                (menuOpened)="menuToggle.next(true)"
                (menuClosed)="menuToggle.next(false)"
                (click)="$event.stopPropagation()"
                *ngIf="!(hideRefreshIcon | async)"
            >
                <div class="action action-menu">
                    <i
                        class="fas fa-ellipsis-v"
                        [matBadge]="(showNotificationBadge | async) ? '0' : ''"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                    ></i>
                </div>
                <mat-menu #moreInfoMenu="matMenu">
                    <div class="more-info-menu">
                        <!-- General Section -->
                        <div class="heading">General</div>
                        <div
                            appIcon
                            [data]="widgetConfigState.actions.refresh.icon"
                            class="info-item"
                            (click)="
                                $event.stopPropagation();
                                refreshWidget();
                                showNotificationBadge.next(false)
                            "
                            [class.disabled]="
                                !widgetConfigState.actions.refresh.state
                            "
                            [class.spinIcon]="
                                (loadingData | async) || (actionLoading | async)
                            "
                        ></div>

                        <div
                            class="info-item"
                            *ngIf="widgetMoreInfo && widgetMoreInfo.length"
                            (click)="
                                $event.stopPropagation();
                                openMoreInfoModal(widgetMoreInfo)
                            "
                        >
                            <div
                                appIcon
                                [data]="widgetConfigState.actions.moreInfo.icon"
                                class="image-question"
                            ></div>

                            <div
                                class="rotatingLoader"
                                *ngIf="detailDescriptionLineLoader"
                            >
                                <div
                                    appIcon
                                    [data]="lineLoaders"
                                    class="loader margin-fix"
                                ></div>
                            </div>
                        </div>

                        <div
                            class="info-item"
                            *ngIf="
                                (widgetData &&
                                    widgetData.description &&
                                    widgetData.description['moreInfo']) ||
                                widgetData.moreInfo
                            "
                            (click)="moreInfo()"
                        >
                            <div
                                appIcon
                                [data]="widgetConfigState.actions.moreInfo.icon"
                                class="image-question"
                            ></div>
                        </div>

                        <!-- Settings Section -->
                        <ng-container
                            *ngIf="
                                (widgetActions | async) &&
                                (widgetActions | async).length > 0
                            "
                        >
                            <div class="heading">Settings</div>
                            <ng-container
                                *ngFor="
                                    let widgetAction of widgetActions | async
                                "
                            >
                                <div
                                    class="info-item"
                                    (click)="
                                        $event.stopPropagation();
                                        widgetAction.state ===
                                        actionState.DISABLED
                                            ? null
                                            : performAction(
                                                  widgetAction,
                                                  widgetAction.actionId
                                              )
                                    "
                                    [class.disabled]="
                                        widgetAction.state ===
                                        actionState.DISABLED
                                    "
                                    [matTooltip]="
                                        widgetAction.tooltip &&
                                        widgetAction.state ===
                                            actionState.DISABLED &&
                                        widgetAction.tooltip[
                                            actionState.DISABLED
                                        ]
                                            ? widgetAction.tooltip[
                                                  actionState.DISABLED
                                              ]
                                            : widgetAction.tooltip &&
                                              widgetAction.state ===
                                                  actionState.ENABLED &&
                                              widgetAction.tooltip[
                                                  actionState.ENABLED
                                              ]
                                            ? widgetAction.tooltip[
                                                  actionState.ENABLED
                                              ]
                                            : null
                                    "
                                    [class.rotatingLoader]="
                                        operationLoaders.has(
                                            widgetAction.actionId
                                        ) &&
                                        operationLoaders.get(
                                            widgetAction.actionId
                                        )
                                    "
                                >
                                    <span
                                        class="toggle switch"
                                        *ngIf="
                                            widgetAction.toggleable &&
                                            visibleSections.value.has(
                                                widgetAction.type
                                            )
                                        "
                                        appIcon
                                        [data]="toggleOnIcon"
                                    ></span>
                                    <span
                                        class="toggle switch"
                                        *ngIf="
                                            widgetAction.toggleable &&
                                            !visibleSections.value.has(
                                                widgetAction.type
                                            )
                                        "
                                        appIcon
                                        [data]="toggleOffIcon"
                                    ></span>
                                    <span>{{ widgetAction.icon.text }}</span>
                                    <div
                                        *ngIf="
                                            operationLoaders.has(
                                                widgetAction.actionId
                                            ) &&
                                            operationLoaders.get(
                                                widgetAction.actionId
                                            )
                                        "
                                        appIcon
                                        [data]="lineLoaders"
                                        class="loader"
                                    ></div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <!-- Downloads Section -->
                        <div
                            class="heading"
                            *ngIf="
                                widgetData.widgetInfo.reports &&
                                !(widgetData.widgetInfo.reports | emptyObject)
                            "
                        >
                            Download
                        </div>
                        <ng-container
                            *ngFor="
                                let report of widgetData.widgetInfo.reports
                                    | keyvalue
                            "
                        >
                            <div
                                class="info-item"
                                [class.rotatingLoader]="
                                    reportLoaders.has(report.key) &&
                                    reportLoaders.get(report.key)
                                "
                                (click)="
                                    $event.stopPropagation();
                                    lightState
                                        ? getFullAccess()
                                        : downloadReport(
                                              report.key,
                                              report.value
                                          )
                                "
                            >
                                <span
                                    class="action-item"
                                    appIcon
                                    [data]="report.key | generateReportIconData"
                                ></span>
                                <div
                                    appIcon
                                    [data]="lineLoaders"
                                    class="loader"
                                ></div>
                            </div>
                        </ng-container>

                        <!-- Fix Section -->
                        <ng-container
                            *ngIf="
                                (fixActions | async) &&
                                (fixActions | async).length
                            "
                        >
                            <div class="heading">Fix</div>
                            <ng-container
                                *ngFor="let action of fixActions | async"
                            >
                                <div
                                    class="info-item"
                                    (click)="action.function()"
                                >
                                    <span
                                        class="action-item"
                                        appIcon
                                        [data]="action.icon"
                                    ></span>
                                </div>
                            </ng-container>
                        </ng-container>

                        <!-- Operations Section -->
                        <ng-container *ngIf="operationalActions | async">
                            <ng-container
                                *ngFor="
                                    let action of operationalActions
                                        | async
                                        | keyvalue
                                "
                            >
                                <div class="heading">{{ action.key }}</div>
                                <ng-container
                                    *ngFor="let operations of action.value"
                                >
                                    <div
                                        class="info-item"
                                        (click)="
                                            $event.stopPropagation();
                                            operations.state ===
                                            actionState.DISABLED
                                                ? null
                                                : operations.function(
                                                      operations.actionId
                                                  );
                                            showNotificationBadge.next(false)
                                        "
                                        [class.disabled]="
                                            operations.state ===
                                            actionState.DISABLED
                                        "
                                        [matTooltip]="
                                            operations.tooltip &&
                                            operations.state ===
                                                actionState.DISABLED &&
                                            operations.tooltip[
                                                actionState.DISABLED
                                            ]
                                                ? operations.tooltip[
                                                      actionState.DISABLED
                                                  ]
                                                : operations.tooltip &&
                                                  operations.state ===
                                                      actionState.ENABLED &&
                                                  operations.tooltip[
                                                      actionState.ENABLED
                                                  ]
                                                ? operations.tooltip[
                                                      actionState.ENABLED
                                                  ]
                                                : null
                                        "
                                        [class.rotatingLoader]="
                                            operationLoaders.has(
                                                operations.actionId
                                            ) &&
                                            operationLoaders.get(
                                                operations.actionId
                                            )
                                        "
                                    >
                                        <span
                                            class="action-item"
                                            appIcon
                                            [data]="operations.icon"
                                        ></span>
                                        <div
                                            appIcon
                                            [data]="lineLoaders"
                                            class="loader"
                                        ></div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div
        class="widget-content"
        [style.display]="widgetContentVisible.value ? 'flex' : 'none'"
    >
        <div class="description-container" *ngIf="widgetDescription">
            <app-description-generator
                [data]="widgetDescription"
            ></app-description-generator>
        </div>
        <div class="widget-data">
            <div
                [ngClass]="
                    !(extraMessage.value === Messages.NO_DATA_AVAILABLE)
                        ? 'overlay'
                        : 'widget-container'
                "
                *ngIf="
                    !errorMessage.value &&
                    ((loadingData | async) || (showAccordion | async))
                "
            >
                <div class="overlay-content">
                    <div
                        *ngIf="loadingData | async"
                        appIcon
                        [data]="spinnerLoader"
                        class="overlay-loader"
                    ></div>
                </div>
            </div>
            <div
                [style.minHeight]="(loadingData | async) ? '160px' : 'unset'"
                class="widget-container"
                *ngIf="widgetInjector && !errorMessage.value"
            >
                <ng-container
                    *ngComponentOutlet="
                        widgetData.componentToLoad;
                        injector: widgetInjector
                    "
                ></ng-container>
            </div>
            <div
                class="extra-message"
                [style.top]="(loadingData | async) ? '-40px' : 'unset'"
                *ngIf="(extraMessage | async) && (extraMessage | async).length"
            >
                {{ extraMessage.value }}
            </div>
            <div
                class="error-message"
                *ngIf="(errorMessage | async) && (errorMessage | async).length"
            >
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ errorMessage.value }}</span>
            </div>
        </div>
        <div
            [matTooltip]="'Click to refresh'"
            class="widget-footer"
            (click)="openRefreshCacheModal()"
            *ngIf="
                widgetData.widgetInfo.showLastModifiedTime && lastModifiedTime
            "
        >
            Last updated {{ lastModifiedTime }}
        </div>
    </div>

    <div
        *ngIf="lightState"
        (click)="getFullAccess()"
        appIcon
        [data]="lockIcon"
        class="lock"
    ></div>
</div>

<ng-container
    *ngIf="
        widgetData &&
        CUSTOM_STYLED_PORTLETS.includes(widgetData.widgetInfo.portlet.type)
    "
>
    <ng-container
        *ngIf="
            widgetData.widgetInfo.portlet.type ===
                PortletType.SLACK_INTEGRATION;
            else customComponent
        "
    >
        <ng-container *ngIf="!(globalDataService.viaMicrosoftTeams | async)">
            <ng-container [ngTemplateOutlet]="customComponent"></ng-container>
        </ng-container>
    </ng-container>
    <ng-template #customComponent>
        <ng-container
            *ngComponentOutlet="
                widgetData.componentToLoad;
                injector: widgetInjector
            "
        ></ng-container>
    </ng-template>
</ng-container>

<ng-template #confirmationModalTemplate>
    <div class="confirmation-modal">
        This action will update the cached data for widgets and aggregates.
        Click on proceed to continue
    </div>
</ng-template>
