<app-table-generator
    *ngIf="tableInput"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
></app-table-generator>

<ng-template #bulkDeleteList>
    <div class="delete-list">
        <li *ngFor="let data of selectedWidgets">
            {{ data["Widget Name"] }}
        </li>
    </div>
</ng-template>

<ng-template #actionsRestrictionView>
    <div class="attatched-widget" *ngIf="customerUsersData">
        <ng-container
            *ngIf="
                customerUsersData['customer'] &&
                customerUsersData['customer'].length
            "
        >
            <p>Customer Name</p>
            <li *ngFor="let customer of customerUsersData['customer']">
                {{ customer }}
            </li>
        </ng-container>
        <ng-container
            *ngIf="
                customerUsersData['user'] && customerUsersData['user'].length
            "
        >
            <p>User Name</p>
            <li *ngFor="let user of customerUsersData['user']">
                {{ user }}
            </li>
        </ng-container>
    </div>
</ng-template>
