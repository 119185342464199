import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { MyErrorStateMatcher } from 'src/app/shared/classes/ErrorStateMatcher';
import { Helper } from 'src/app/shared/classes/Helper';
import { DropdownMultipleFieldAppearance } from 'src/app/shared/enums/AppearanceType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmailConfigModalService } from 'src/app/shared/services/assessment/email-config-service/email-config-modal.service';

@Component({
    selector: 'app-multi-dropdown-with-input',
    templateUrl: './multi-dropdown-with-input.component.html',
    styleUrls: ['./multi-dropdown-with-input.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiDropdownWithInputComponent,
            multi: true
        }
    ]
})
export class MultiDropdownWithInputComponent implements OnInit, ControlValueAccessor {
    @Input() field: IFormField;
    emailInputfield: IFormField;
    @Input() formGeneratorInput: IFormGeneratorInput;
    @Input() formGroup: FormGroup;
    @Input() loading: Map<string, boolean>;
    @Input() compareWith: any;
    @Input() filterStoreKey: FilterStoreKey;
    @Output() valueChange = new EventEmitter<any>();
    @Output() selectAllValue = new EventEmitter<any>();
    @Output() onScroll = new EventEmitter<any>();
    @Output() onChecked = new EventEmitter<any>();
    @Output() getFilteredData = new EventEmitter<any>();
    FilterType = FilterType;
    FormState = FormState;
    Infinity = Infinity;
    showOverlay = false;
    AppearanceType = DropdownMultipleFieldAppearance;
    onChange: any = () => { };
    onTouch: any = () => { };
    control: FormControl;
    matcher = new MyErrorStateMatcher();
    isDropdownChecked: boolean = false;
    filterInfo: IFilterInfo;
    showCheckbox: boolean = true;
    showSelectAll: boolean = true;
    @ViewChild('ngSelectRef') ngSelect;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    inputFormGroup: FormGroup;
    constructor(
        private filterService: FiltersService,
        private filterCacheService: FilterCacheService,
        private userCacheService: UserDataCacheService,
        private configCache: ConfigCacheService,
        private ngZone: NgZone,
        private emailConfigService: EmailConfigModalService,
        private cdRef: ChangeDetectorRef
    ) { }
    ngOnInit(): void {
        const existingAppearance = Object.values(this.AppearanceType);
        if (
            !existingAppearance.includes(
                this.field.appearance as DropdownMultipleFieldAppearance
            )
        ) {
            this.field.appearance = Helper.getFieldsAppearance(
                this.field,
                this.field.isFilter ? true : false
            );
        }

        this.filterService.resetFilters.subscribe((res) => {
            if (
                !res &&
                (this.filterStoreKey === this.filterService.currentFilterView ||
                    !this.filterService.currentFilterView)
            ) {
                this.showCheckbox = false;
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.isDropdownChecked = false;
                        this.showCheckbox = true;
                    }, 0);
                });
            }
        });
        const filtersData = this.filterCacheService.getFiltersInfo(
            this.userCacheService.emailId,
            this.configCache.viewId,
            this.filterStoreKey
        );
        if (filtersData && filtersData[this.field.id]) {
            this.filterInfo = filtersData[this.field.id].filterInfo;
        }

        if (this.field.isInputField) {
            const customFieldName = this.field.customInputField.name;
            const validators = this.field.customInputField.validations;
            const validatorFunctions = [];
            if (validators) {
                validators.forEach((validation) => {
                    validation.validator.formGenRef = this
                    validatorFunctions.push(
                        validation.validator.validatorFunction.bind(
                            validation.validator
                        )
                    );
                });
            }
            this.formGroup.addControl(customFieldName, new FormControl([], [...validatorFunctions]));
        };

        if (this.filterInfo) {
            this.isDropdownChecked =
                filtersData[this.field.id] &&
                    filtersData[this.field.id].isChecked
                    ? true
                    : filtersData[this.filterInfo.checkboxSelector] &&
                        filtersData[this.filterInfo.checkboxSelector].value &&
                        filtersData[
                            this.filterInfo.checkboxSelector
                        ].value.includes(this.filterInfo.checkboxValue)
                        ? true
                        : false;

            if (
                this.isDropdownChecked ||
                (filtersData[this.filterInfo.checkboxSelector] &&
                    filtersData[this.filterInfo.checkboxSelector].value &&
                    filtersData[this.filterInfo.checkboxSelector].value
                        .length &&
                    filtersData[
                        this.filterInfo.checkboxSelector
                    ].value.includes(this.filterInfo.checkboxValue))
            ) {
                this.onChecked.emit(true);
            }
        }

        this.control = this.findControl();
        this.filterInfo = this.filterService.currentPageFilterInfo.has(
            this.field.id
        )
            ? this.filterService.currentPageFilterInfo.get(this.field.id)
            : null;
        this.filterService.refreshWidget.subscribe((res) => {
            if (res) {
                this.isDropdownChecked =
                    this.filterService.checkboxValue &&
                        this.filterService.checkboxValue.get(this.filterStoreKey) &&
                        this.filterService.checkboxValue
                            .get(this.filterStoreKey)
                            .get(this.field.id)
                        ? this.filterService.checkboxValue
                            .get(this.filterStoreKey)
                            .get(this.field.id)
                        : false;
            }
        });
        if (this.filterInfo && this.filterInfo.selectionLimit) {
            this.showSelectAll = false;
        }
    }
    findControl(): FormControl {
        if (this.field.groupByKey) {
            const formControl = this.formGroup
                .get(this.field.groupByKey)
                .get(this.field.name);
            return formControl as FormControl;
        } else {
            return this.formGroup.get(this.field.name) as FormControl;
        }
    }
    writeValue(obj: any): void {
        this.control = this.findControl();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void { }
    onSelectAll(selectAll: boolean) {
        if (
            this.ngSelect &&
            this.ngSelect.itemsList &&
            this.ngSelect.itemsList.filteredItems &&
            this.ngSelect.itemsList.filteredItems.length
        ) {
            this.control.markAsDirty();
            this.getFilteredData.emit(this.ngSelect.itemsList.filteredItems);
        }

        this.selectAllValue.emit(selectAll);
    }
    onValueChange(event?) {
        if (
            this.filterInfo &&
            this.filterInfo.selectionLimit &&
            event.length > this.filterInfo.selectionLimit
        ) {
            return;
        }
        let selectedValues: IDropdownData[] = [];
        if (event && event.length) {
            event.forEach((selectedValue: IDropdownData) => {
                if (this.field.getKey) {
                    selectedValues.push(selectedValue[this.field.getKey]);
                } else if (this.field.getKey === null) {
                    selectedValues.push(selectedValue);
                } else {
                    selectedValues.push(selectedValue.id);
                }
            });
        }
        this.onChange(selectedValues);
        this.valueChange.emit(event);
    }
    scroll(event) {
        this.onScroll.emit(event);
    }
    checkboxWithDropdownToggled(event: Event) {
        const customEvent = event as CustomEvent;
        const data = customEvent.detail;
        this.onChecked.emit(data);
    }
    showTooltip(item) {
        if (item['hoveredText']) {
            return item['hoveredText'];
        } else if (item['hoveredJSON']) {
            let text = '';
            Object.keys(item['hoveredJSON']).forEach((key) => {
                text += key + ': ' + item['hoveredJSON'][key] + ' \n ';
            });
            return text;
        } else {
            return item['label'];
        }
    }
    getEnabledListDataLength(field: IFormField) {
        return field.listData.filter((data) => !data.disabled).length;
    }

    addChip(field: IFormField, event: MatChipInputEvent) {
        if (!this.formGroup.controls[field.name].value) {
            this.formGroup.controls[field.name].setValue([]);
        }
        const input = event.input;
        const value = event.value.trim();
        const values: string[] = this.formGroup.controls[field.name].value;
        if (value.length && !values.includes(value)) {
            values.push(value);
            // If the parent field is required, then setting the data into service
            // to trigger the validations.
            if (this.field.required)
                this.emailConfigService.setCustomUserRoles(field.parentField, values);
            input.value = '';
        }
        if (
            field.matChipInputLimit &&
            values.length > field.matChipInputLimit
        ) {
            this.formGroup.controls[field.name].setValue(
                values.splice(values.length - 1, 1)
            );
            input.value = '';
        }
        // Triggering the Field Validations by markisng the field as Touched.
        this.formGroup.get(field.name).markAsTouched();
        this.formGroup.get(field.name).updateValueAndValidity();
    }

    onRemoveChip(field: IFormField, index: number) {
        if (index > -1) {
            this.formGroup.controls[field.name].value.splice(index, 1);
            if (field.required) {
                const values = this.emailConfigService.getCustomUserRoles(field.parentField).splice(index, 1);
                this.emailConfigService.setCustomUserRoles(field.name, values);
            }
        }
        this.formGroup.get(field.name).updateValueAndValidity();
        // Triggering the Coun update after removing of Email value from
        // the input field.
        const emailsEntered = this.formGroup.get(field.name).value;
        const filteredData = this.ngSelect.selectedItems.reduce((selectedValues, data) => {
            // Check if the condition is met for the current item
            if (data.index !== null || emailsEntered.includes(data.value.id)) {
                selectedValues.push(data.value.id); // Include the item in the filtered array
            }
            return selectedValues;
        }, []);
        this.onChange(filteredData);
        this.cdRef.detectChanges();
    }

    // This will be called everytime when the dropdown opens, as we need to update the
    // form field if there is a value in the input field.
    checkValidation() {
        this.emailConfigService.getAllCustomUserRoles().forEach((value, key) => {
            if (value && value.length > 0)
                this.setFormStatusValid(key);
        })
    }

    // Setting form state valid after validations.
    setFormStatusValid(fieldName: string): void {
        this.formGroup.get(fieldName).setErrors(null);
        this.formGroup.get(fieldName).markAsUntouched();
    }
}
