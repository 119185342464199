<div class="container">
    <div class="sub-container">
        <div class="info" *ngIf="!isRequestWidget">
            <span
                >Note: Test action will <span class="dark">NOT</span> perform
                any action in your cloud environment.</span
            >
        </div>
        <app-form-generator
            *ngIf="formGenInputs"
            [formGeneratorInput]="formGenInputs"
            (formGroupRef)="setFormGroup($event)"
        ></app-form-generator>
        <div class="no-var-text" *ngIf="!formGenInputs">
            <span>No Input Required</span>
        </div>
    </div>
    <div class="error-message" *ngIf="errorMessage">
        <div appIcon [data]="infoIcon" class="action"></div>
        <span>{{ errorMessage }}</span>
    </div>
</div>
<div class="controls">
    <div class="back-button">
        <app-button-generator
            [buttonGenInput]="backButtonGenInput"
        ></app-button-generator>
    </div>
    <div class="action-button">
        <app-button-generator
            [buttonGenInput]="previewButtonGenInput"
        ></app-button-generator>
        <app-button-generator
            *ngIf="showRequestButton"
            [buttonGenInput]="requestButtonGenInput"
        ></app-button-generator>
    </div>
</div>
