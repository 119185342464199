import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidator } from './../classes/CustomValidator';
import { CustomValidatorKey } from './../enums/CustomValidatorKey';
export class GcpCapitalLetterValidator extends CustomValidator {
    key = CustomValidatorKey.GCP_CAPITAL_LETTERS_VALIDATOR;
    validatorFunction(control: AbstractControl): ValidationErrors {
        const GCP_CAPITAL_LETTER = new RegExp(
            /^[^A-Z][a-z-0-9@!+_#$%^&?><={}()]*$/
        );

        if (GCP_CAPITAL_LETTER.test(control.value)) {
            return null;
        }

        const obj = {};
        obj[this.key] = true;
        return obj;
    }
}
