import { Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { IColumnData } from 'src/app/shared/interfaces/table-generator/IColumnData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ButtonType } from '../../../../enums/ButtonType';
import { IOptimizerChecksApiResponse } from '../../../../interfaces/api/portlets/IOptimizerChecksApiResponse';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from '../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IBackendFormGeneratorInput } from '../../../../interfaces/form-generator/IBackendFormGeneratorInput';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-optimizer-check-v3',
    templateUrl: './optimizer-check-v3.component.html',
    styleUrls: ['./optimizer-check-v3.component.sass']
})
export class OptimizerCheckV3Component implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    RED_SEV = `<span class="red-severity-color" style="font-weight: bold; font-size: var(--fs200);">CRITICAL</span>`;
    YELLOW_SEV = `<span class="yellow-severity-color" style="font-weight: bold; font-size: var(--fs200);">WARNING</span>`;
    GREEN_SEV = `<span class="green-severity-color" style="font-weight: bold; font-size: var(--fs200);">OK</span>`;
    filteredSearchData: any;
    severityTypeCheck: string;
    rowData: any;
    gridRef: GridOptions;
    selectedRowData = [];
    showSearchBox: boolean = true;
    showPotentialSavings: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    potentialSavingsValue: BehaviorSubject<number> =
        new BehaviorSubject<number>(null);
    currencySymbolValue: BehaviorSubject<string> = new BehaviorSubject<string>(
        null
    );

    severityCount: any = {};
    apiResponse: any = null;
    controlButtons: IButtonGeneratorInput[];
    controlButtonOption: IMultiButtonOption = {
        disable: true
    };

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableGenInput = {
            columns: [],
            listExtraction: {
                type: 'NESTED',
                nestedKey: 'dataList'
            },
            selection: 'single',
            afterPartialResponse: (response: IOptimizerChecksApiResponse) => {
                this.showPotentialSavings.next(false);
                if (
                    response &&
                    response['dataList'] &&
                    response['dataList'].length
                ) {
                    this.setUpTable(response);
                    this.remediationSetup();
                    this.setUpControlButtons();
                }
                if (
                    response &&
                    response['dataMap'] &&
                    response['dataMap']['currencySymbol'] &&
                    !this.currencySymbolValue.getValue()
                ) {
                    this.currencySymbolValue.next(
                        response['dataMap']['currencySymbol']
                    );
                }
            },
            onStreamClosed: (rowData) => {
                if (rowData) {
                    this.enableControlButtons();

                    // Total potential savings cost being calculated
                    // from all list data once socket is closed and data has arrived.
                    this.calculateTotalPotentialSavings(rowData);
                    this.resizeColumns();
                }
            },
            afterResponse: (response: IOptimizerChecksApiResponse) => {
                this.showPotentialSavings.next(false);
                this.setUpTable(response);
                this.remediationSetup();
                this.setUpControlButtons();
                this.enableControlButtons();
                if (
                    this.widgetRef.apiResponse &&
                    this.widgetRef.apiResponse['dataMap'] &&
                    this.widgetRef.apiResponse['dataMap'][
                        'totalPotentialSavings'
                    ]
                ) {
                    this.potentialSavingsValue.next(
                        this.widgetRef.apiResponse['dataMap'][
                            'totalPotentialSavings'
                        ]
                    );
                    this.showPotentialSavings.next(true);
                }
                this.resizeColumns();
            },
            showViewIcon: true,
            extraComponents: {
                buttonGen: MultiButtonGeneratorComponent
            },
            refreshWidgetCallback: this.widgetRefreshCallback.bind(this),
            refreshColumnAfterResponse: true,
            handleAttentionRequired: true
        };

        //Lite state Setup
        this.setupLiteState();
    }

    getWidgetIcon() {
        if (this.severityCount['_1']) {
            return 'red';
        }
        if (this.severityCount['_2']) {
            return 'yellow';
        } else if (this.severityCount['_3']) {
            return 'green';
        }
    }

    setUpTable(response) {
        if (!this.apiResponse) {
            this.apiResponse = response;
            this.addTableColumns(response);
        } else {
            if (this.tableGenInput.columns.length === 0) {
                this.addTableColumns(response);
            }

            this.apiResponse['dataList'].push(...response.dataList);
        }

        this.widgetRef.apiResponse = this.apiResponse;

        this.calculateSeverityCount(response);
    }

    addTableColumns(response) {
        const columns: IColumnData[] = [];
        response['dataMap']['keysToShow'].forEach((colObj) => {
            columns.push({
                columnName: colObj['label'],
                columnKey: colObj['id']
            });
        });

        columns.unshift({
            columnKey: 'severity',
            columnName: 'Severity',
            pinned: 'left',
            lockPinned: true,
            cellRenderer: (rowData: RowEvent) => {
                const sev = rowData.data['severity'];
                if (sev === '_1') {
                    return this.RED_SEV;
                } else if (sev === '_2') {
                    return this.YELLOW_SEV;
                } else {
                    return this.GREEN_SEV;
                }
            }
        });

        this.tableGenInput.columns = [...columns];
    }

    setUpControlButtons() {
        const buttonData = {};
        this.controlButtons = [];
        let index = 0;

        if (Object.keys(this.severityCount).length) {
            const criticalButton: IButtonGeneratorInput = {
                buttonName: `Critical : ${this.severityCount['_1'] ?? 0}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.WARN,
                hoverText: 'Critical',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Critical',
                            this.severityFilter.bind(this, '_1')
                        )
                    ) {
                        this.severityFilter('_1');
                    }
                }
            };
            const warningButton: IButtonGeneratorInput = {
                buttonName: `Warning : ${this.severityCount['_2'] ?? 0}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: 'Warning',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Warning',
                            this.severityFilter.bind(this, '_2')
                        )
                    ) {
                        this.severityFilter('_2');
                    }
                }
            };
            const okButton: IButtonGeneratorInput = {
                buttonName: `Ok : ${this.severityCount['_3'] ?? 0}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Ok',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (
                        Helper.changeButtonType(
                            buttonData,
                            this.controlButtons,
                            'Ok',
                            this.severityFilter.bind(this, '_3')
                        )
                    ) {
                        this.severityFilter('_3');
                    }
                }
            };

            if (this.severityCount['_3']) {
                this.controlButtons.push(okButton);
                buttonData['Ok'] = index++;
            }
            if (this.severityCount['_2']) {
                this.controlButtons.push(warningButton);
                buttonData['Warning'] = index++;
            }
            if (this.severityCount['_1']) {
                this.controlButtons.push(criticalButton);
                buttonData['Critical'] = index++;
            }
        }
    }

    enableControlButtons() {
        this.controlButtonOption = {
            disable: false
        };
    }

    widgetRefreshCallback() {
        this.severityTypeCheck = '';
        this.apiResponse = null;
        this.controlButtons = [];
        this.severityCount = {};
        this.tableGenInput.columns = [];
        this.potentialSavingsValue.next(null);
        this.showPotentialSavings.next(null);
        this.currencySymbolValue.next(null);
        this.widgetRef.showNotificationBadge.next(false);
    }

    /**
     * This function make an object of all severities with their count.
     */

    calculateSeverityCount(response) {
        if (
            response &&
            response['dataMap'] &&
            response['dataMap']['totalSeverity'] &&
            Object.keys(response['dataMap']['totalSeverity']).length
        ) {
            const severityKeys = Object.keys(
                response['dataMap']['totalSeverity']
            );

            severityKeys.map((severity) => {
                if (severity in this.severityCount) {
                    this.severityCount[severity] =
                        this.severityCount[severity] +
                        Number(response['dataMap']['totalSeverity'][severity]);
                } else {
                    this.severityCount[severity] = Number(
                        response['dataMap']['totalSeverity'][severity]
                    );
                }
            });
        }

        const maxSeverity = this.getWidgetIcon();

        if (maxSeverity) {
            this.widgetRef.headerIcon.next({
                class: `assets/${maxSeverity}_check.png`,
                type: IconType.IMAGE
            });
        }
    }

    setupLiteState() {
        if (this.widgetRef.lightState) {
            this.showSearchBox = false;
            this.tableGenInput.selectionLimit =
                this.widgetRef.liteViewCheckSelectionLimit;
            this.tableGenInput.selectionLimitBreachMessage = `Maximum of ${this.widgetRef.liteViewCheckSelectionLimit} row can be selected`;
            this.tableGenInput.rowSelection = this.widgetRef.lightState
                ? 'single'
                : 'multiple';
        }
    }

    severityFilter(severityType) {
        if (this.severityTypeCheck === severityType) {
            this.severityTypeCheck = '';
            this.gridRef.api.setRowData(this.apiResponse['dataList']);
            this.gridRef.api.refreshCells();
            return;
        }
        this.filteredSearchData = this.apiResponse['dataList'].filter(
            (item) => {
                if (item['severity'] === severityType) {
                    return item;
                }
            }
        );
        this.gridRef.api.setRowData(this.filteredSearchData);
        this.gridRef.api.refreshCells();
        this.severityTypeCheck = severityType;
    }

    calculateTotalPotentialSavings(data: any[]) {
        let value = null;
        if (data && data.length) {
            value = data.reduce((acc, saving) => {
                if (saving['potentialMonthlySavings'] !== undefined) {
                    acc = acc + Number(saving['potentialMonthlySavings']);
                }
                return acc;
            }, 0);

            value = Number(value.toFixed(2));
            if (value !== undefined) {
                this.potentialSavingsValue.next(value);
                this.showPotentialSavings.next(true);
            }
        }
    }

    remediationSetup() {
        if (
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse &&
            (this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                'remediationDocument'
            ] ||
                this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                    'remediation'
                ]) &&
            this.widgetRef.widgetData.widgetInfo.remediationExtraInfo &&
            this.tableGenInput.columns.filter(
                (column: IColumnData) => column.columnKey === 'action'
            ).length < 1
        ) {
            this.tableGenInput.selection = 'multiple';
            this.tableGenInput.columns.push({
                columnKey: 'action',
                columnName: 'Action',
                pinned: 'right',
                filter: false,
                hideFilterMenu: true,
                buttonGen: true,
                componentFramework: MultiButtonGeneratorComponent,
                valueFormatter: (rowData: RowEvent) => {
                    if (
                        this.widgetRef.widgetData.widgetInfo
                            .remediationExtraInfo &&
                        this.widgetRef.widgetData.widgetInfo
                            .remediationExtraInfo.actions
                    ) {
                        if (!rowData['buttonGenInputs']) {
                            rowData['buttonGenInputs'] = [];
                        }
                        this.widgetRef.widgetData.widgetInfo.remediationExtraInfo.actions.forEach(
                            (action) => {
                                const fixButton: IButtonGeneratorInput = {
                                    buttonName: action.buttonName,
                                    buttonIcon: {
                                        type: IconType.FONTAWSOME,
                                        class: ''
                                    },
                                    buttonType: ButtonType.TEXT,
                                    buttonColorType:
                                        action.buttonColor === 'red'
                                            ? ButtonColorType.WARN
                                            : ButtonColorType.INFO,
                                    showLoader: true,
                                    function: (buttonRef: IButtonData) => {
                                        this.remediationModalHandling(
                                            [rowData.data],
                                            action,
                                            buttonRef
                                        );
                                    }
                                };

                                if (action.confirmationMessage) {
                                    fixButton.showLoader = false;
                                }

                                const buttonOptions: IMultiButtonOption = {
                                    layout: { justifyContent: 'space-evenly' }
                                };
                                rowData['options'] = buttonOptions;
                                rowData['buttonGenInputs'].push(fixButton);
                            }
                        );
                        return rowData;
                    }
                }
            });

            // remediation action setup on header
            if (
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo &&
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo
                    .actions
            ) {
                const actions: IAction[] = [];
                this.widgetRef.widgetData.widgetInfo.remediationExtraInfo.actions.forEach(
                    (action) => {
                        actions.push({
                            state: ActionState.ENABLED,
                            visibility: ActionVisibility.VISIBLE,
                            message: action.key,
                            actionId: action.key,
                            function: (actionId) => {
                                if (
                                    !this.gridRef.api.getSelectedRows().length
                                ) {
                                    this.widgetRef.notificationsService.showSnackBar(
                                        'Select atleast 1 row',
                                        true
                                    );
                                    return;
                                }

                                this.remediationModalHandling(
                                    this.selectedRowData,
                                    action,
                                    null,
                                    actionId
                                );
                            },
                            icon: {
                                type: IconType.FONTAWSOME,
                                class: action.icon,
                                text: action.buttonName
                            }
                        });
                    }
                );

                this.widgetRef.operationalActions.value.set(
                    'Remediation',
                    actions
                );

                this.widgetRef.operationalActions.next(
                    this.widgetRef.operationalActions.value
                );
            }
        }
    }

    remediationModalHandling(rowData, action, buttonRef?, actionId?) {
        if (
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse &&
            this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                'remediationDocument'
            ]
        ) {
            // Fix through Form Parser
            // get Remediation document(JSON)
            if (buttonRef) {
                buttonRef.loader = true;
            } else if (actionId) {
                this.widgetRef.operationLoaders.set(actionId, true);
            }
            const remediateDocument: IApiInfo =
                this.widgetRef.widgetData.widgetInfo.securityCheckResponse[
                    'remediationDocument'
                ];
            Helper.getRemediationDoc(
                remediateDocument,
                rowData,
                this.widgetRef.httpService,
                this.widgetRef.ngZone,
                this.widgetRef.notificationsService,
                this.generateRemediationForm.bind(this),
                this.widgetRef.operationLoaders,
                this.widgetRef.changeDetectorRef,
                buttonRef,
                actionId
            );
        } else {
            // Direct Fix
            Helper.executionFunctionAfterConfirmation(
                {
                    executeFunctionAfterConfirmation: {
                        modalName: action.buttonName,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: action.icon
                        },
                        contextIcon: {
                            extraClass: 'color-accent',
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        confirmationMessage: action.confirmationMessage,
                        buttonText: action.buttonName,
                        buttonColorType: ButtonColorType.WARN,
                        loaderOnExec: true
                    },
                    function: (modalId: Symbol) => {
                        Helper.performRemediationAction(
                            {
                                rowsToBeFixed: rowData
                            },
                            this.widgetRef.widgetData.widgetInfo
                                .securityCheckResponse[action.key],
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone,
                            this.widgetRef.notificationsService,
                            () => {
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                                this.widgetRef.refreshWidget();
                            }
                        );
                    }
                },
                this.widgetRef.modalService
            );
        }
    }

    generateRemediationForm(
        response: IBackendFormGeneratorInput,
        rowData: any[]
    ) {
        const modalId = Symbol();
        const formGenInput: IFormGeneratorInput =
            Helper.formGeneratorBtoFParser(
                response,
                rowData,
                this.widgetRef.notificationsService,
                this.widgetRef.httpService,
                this.widgetRef.changeDetectorRef,
                {
                    rowsToBeFixed: rowData
                },
                () => {
                    this.widgetRef.modalService.closeModal(null, modalId);
                    this.widgetRef.refreshWidget();
                }
            );

        const modalData: IModalData = {
            modalName: formGenInput.formName,
            modalIcon: null,
            sourceId: this.widgetRef.uniqueIdentity,
            modalId: modalId,
            modalType: ModalType.MIDDLE,
            modalWidthVw: 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    },
                    stepName: ''
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }

    onSelectionChanged() {
        this.selectedRowData = [];
        const selectedNodes = this.gridRef.api.getSelectedNodes();
        if (selectedNodes.length) {
            selectedNodes.forEach((selectedRow) => {
                this.selectedRowData.push(selectedRow.data);
            });
        } else {
            this.selectedRowData = [];
        }

        if (this.selectedRowData && this.selectedRowData.length) {
            this.widgetRef.showNotificationBadge.next(true);
        } else {
            this.widgetRef.showNotificationBadge.next(false);
        }
    }

    resizeColumns() {
        this.gridRef.api.sizeColumnsToFit();
        this.gridRef.api.redrawRows();
        this.widgetRef.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.gridRef.api.refreshHeader();
            }, 100);
        });
    }
}
