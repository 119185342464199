<div class="key-value-container">
    <app-key-value-pair-v2
        [data]="keyValueSet"
        (change)="changeInputData($event)"
        [validation]="
    widgetRef.widgetData.widgetInfo.cloudIcon"
    ></app-key-value-pair-v2>
</div>
<div class="tw-py-2">
    <app-multi-button-generator
        [buttonGenInputs]="buttonInputs"
        [options]="buttonOptions"
    >
    </app-multi-button-generator>
</div>
