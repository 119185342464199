<div
    class="widget-data-container"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        (widgetRef.visibleSections | async) &&
        (widgetRef.visibleSections | async).has(ViewType.GRAPH)
    "
>
    <div *ngIf="graphData" class="graph-container">
        <app-graph-generator [graphData]="graphData"></app-graph-generator>
    </div>
</div>
