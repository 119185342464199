<div class="error-container">
    <div class="icon">
        <div appIcon [data]="errorIcon"></div>
    </div>
    <div class="error-msg-container">
        <span>Page Not Found</span>
        <div class="error-msg">
            <label>Try :</label>
            <ul>
                <li>Checking the Internet connection.</li>
                <li>Checking the Firewall Rules.</li>
            </ul>
        </div>
    </div>
</div>
