import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GridOptions, RowEvent } from 'ag-grid-community';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { RiUtilizationModalV2Component } from '../../../modal-templates/ri-utilization-modal-v2/ri-utilization-modal-v2.component';
import { Helper } from './../../../../classes/Helper';
import { Messages } from './../../../../classes/Messages';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { IconType } from './../../../../enums/IconType';
import { State } from './../../../../enums/State';
import { ViewType } from './../../../../enums/ViewType';
import { IAction } from './../../../../interfaces/actions/IAction';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from './../../../../interfaces/icon-data/IIcon';
import { IColumnData } from './../../../../interfaces/table-generator/IColumnData';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

enum UtilsationColors {
    GREEN = 'GREEN',
    RED = 'RED',
    YELLOW = 'YELLOW'
}

enum DataList {
    Consumption = 'Consumption (In Percentage)'
}
@Component({
    selector: 'app-ri-description-v2',
    templateUrl: './ri-description-v2.component.html',
    styleUrls: ['./ri-description-v2.component.sass']
})
export class RiDescriptionV2Component implements OnInit, AfterViewInit {
    Messages = Messages;
    ViewType = ViewType;
    widgetRef: Widget;
    utilisationCount;
    viewOrder: string[];
    utilsationColors = UtilsationColors;
    cardsData: any[] = [];
    filteredCardsData: any[];
    State = State;
    accounts;
    tableInput: ITableGeneratorInput;
    widgetAction: IAction[];
    tableData: any[];
    gridRef: GridOptions = null;
    severityButtons: IButtonGeneratorInput[];
    dataLists = DataList;
    exclamationIcon: IIcon = {
        type: IconType.SVG,
        class: 'exclamation_icon',
        extraClass: 'svg-white-fill'
    };
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross_icon',
        extraClass: 'svg-white-fill'
    };
    checkIcon: IIcon = {
        type: IconType.SVG,
        class: 'check_icon',
        extraClass: 'svg-white-fill'
    };

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(true);

        if (
            this.widgetRef.widgetConfigDetails &&
            this.widgetRef.widgetConfigDetails.visibleSections
        ) {
            this.widgetRef.visibleSections.next(
                this.widgetRef.widgetConfigDetails.visibleSections
            );
        }
    }

    bindData(data) {
        if (data === null) {
            return;
        }

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = new Set([ViewType.CARDS]);
        this.widgetRef.visibleSections.next(visibleSections);

        this.utilisationCount = data.dataMap.count;
        this.viewOrder = data.dataMap['Display Entry'];

        const widgetAction: IAction[] = [
            this.widgetRef.widgetConfigState.actions.card
        ];
        this.widgetRef.widgetActions.next(widgetAction);
        this.accounts =
            data.dataList && data.dataList.length ? data.dataList : [];

        if (this.accounts && this.accounts.length === 0) {
            this.widgetRef.extraMessage.next(
                data.message ? data.message : Messages.NO_DATA_AVAILABLE
            );
            this.widgetRef.visibleSections.next(new Set());
            this.widgetRef.endLoader();
            return;
        }

        this.cardsData = data.dataList;
        this.filteredCardsData = this.accounts;

        this.tableData = this.filteredCardsData.map(
            (each) => each['Display Entry']
        );
        this.generateTableView(data);

        this.initButtonGenInputs();
        this.widgetRef.endLoader();
    }

    initButtonGenInputs() {
        this.severityButtons = [
            {
                buttonName: `Complete : ${this.utilisationCount.complete}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Complete',
                hoverEffect: 'shadow',
                function: (buttoRef: IButtonGeneratorInput) => {
                    if (this.changeButtonType('Complete')) {
                        this.filterRiUtilizationCards(UtilsationColors.GREEN);
                    }
                }
            },
            {
                buttonName: `Average : ${this.utilisationCount.average}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                hoverText: 'Average',
                hoverEffect: 'shadow',
                function: () => {
                    if (this.changeButtonType('Average')) {
                        this.filterRiUtilizationCards(UtilsationColors.YELLOW);
                    }
                }
            },
            {
                buttonName: `Low : ${this.utilisationCount.low}`,
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.WARN,
                hoverText: 'Low',
                hoverEffect: 'shadow',
                function: () => {
                    if (this.changeButtonType('Low')) {
                        this.filterRiUtilizationCards(UtilsationColors.RED);
                    }
                }
            }
        ];
    }

    changeButtonType(buttonName: string): boolean {
        const severityButtonIndex = {
            Complete: 0,
            Average: 1,
            Low: 2
        };
        const buttonIndex = severityButtonIndex[buttonName];
        const button = Helper.cloneDeep(this.severityButtons[buttonIndex]);

        if (this.severityButtons[buttonIndex].buttonType === ButtonType.FLAT) {
            button.buttonType = ButtonType.STROKED;
            this.severityButtons[buttonIndex] = button;
            this.filterRiUtilizationCards(null, true);
            return false;
        }

        button.buttonType = ButtonType.FLAT;
        this.severityButtons[buttonIndex] = button;

        this.severityButtons.forEach((button, index) => {
            const btn = Helper.cloneDeep(button);
            if (index !== buttonIndex) {
                btn.buttonType = ButtonType.STROKED;
                this.severityButtons[index] = btn;
            }
        });

        return true;
    }

    filterRiUtilizationCards(color, reset: boolean = false) {
        this.filteredCardsData = this.accounts;
        if (!reset) {
            this.filteredCardsData = this.filteredCardsData.filter(
                (item) =>
                    this.colorGradient(
                        item['Display Entry'][DataList.Consumption]
                    ) === color
            );
        }
        this.tableData = this.filteredCardsData.map(
            (each) => each['Display Entry']
        );
        this.cardsData = this.filteredCardsData;
        if (this.gridRef) {
            this.gridRef.api.setRowData(this.tableData);
            this.gridRef.api.redrawRows();
        }
    }

    colorGradient(consumptionValue) {
        const value = Number(consumptionValue.match(/[0-9]*\.?[0-9]+/));
        if (value >= 0 && value <= 60) {
            return UtilsationColors.RED;
        } else if (value > 60 && value <= 90) {
            return UtilsationColors.YELLOW;
        } else if (value > 90) {
            return UtilsationColors.GREEN;
        }
    }

    ngAfterViewInit(): void {
        this.widgetRef.isBindDataAssigned = false;
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    getIcon(card, dataLists) {
        if (
            this.colorGradient(card['Display Entry'][dataLists.Consumption]) ===
            this.utilsationColors.YELLOW
        ) {
            return {
                iconData: this.exclamationIcon,
                class: 'exclamation-icon'
            };
        } else if (
            this.colorGradient(card['Display Entry'][dataLists.Consumption]) ===
            this.utilsationColors.RED
        ) {
            return { iconData: this.crossIcon, class: 'cross-icon' };
        } else {
            return { iconData: this.checkIcon, class: 'check-icon' };
        }
    }

    generateTableView(response) {
        const columns: IColumnData[] = [];
        const displayList = response['dataMap']['Display Entry'];

        displayList.forEach((columnName) => {
            columns.push({
                columnKey: columnName,
                columnName: columnName
            });
        });

        columns.unshift({
            columnKey: 'consumptionlevel',
            columnName: 'Consumption Level',

            cellRenderer: (rowData: RowEvent) => {
                const viewText = document.createElement('SPAN');

                const colorGradient = this.colorGradient(
                    rowData.data[DataList.Consumption]
                );

                if (colorGradient === UtilsationColors.RED) {
                    viewText.innerText = 'LOW';
                    viewText.className = 'low';
                } else if (colorGradient === UtilsationColors.GREEN) {
                    viewText.innerText = 'COMPLETE';
                    viewText.className = 'complete';
                } else if (colorGradient === UtilsationColors.YELLOW) {
                    viewText.innerText = 'AVERAGE';
                    viewText.className = 'average';
                }

                const div = document.createElement('DIV');
                div.appendChild(viewText);
                return div;
            }
        });

        columns.push({
            columnKey: 'action',
            columnName: 'Action',
            pinned: 'right',
            buttonGen: true,
            componentFramework: MultiButtonGeneratorComponent,
            valueFormatter: (rowData: RowEvent) => {
                const viewButton: IButtonGeneratorInput = {
                    buttonName: 'More Info',
                    buttonType: ButtonType.TEXT,
                    buttonColorType: ButtonColorType.INFO,
                    function: () => {
                        this.showRiModal(
                            this.filteredCardsData[rowData.node.id]
                        );
                    }
                };

                rowData['buttonGenInputs'] = [viewButton];
                return rowData;
            }
        });

        this.tableInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chart-line'
            },
            listExtraction: {
                type: 'DIRECT'
            },
            tableHeight: 300,
            columns
        };
    }

    showRiModal(data) {
        const modalData: IModalData = {
            noHeader: true,
            modalName: 'UtilizationV2',
            modalIcon: null,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.SIDE,
            noStepPadding: true,
            modalWidthVw: 70,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: RiUtilizationModalV2Component,
                        payload: {
                            data: { widgetRef: this.widgetRef, itemData: data }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
}
