<div class="container">
    <div class="flex-container">
        <div class="overlay" *ngIf="isLoading">
            <div class="overlay-content">
                <div
                    appIcon
                    [data]="spinnerLoader"
                    class="overlay-loader"
                ></div>
            </div>
        </div>
        <ng-container *ngIf="!isLoading && tableData">
            <div class="search-section">
                <h2 style="margin-bottom: 0">Widget List</h2>
                <div>
                    <app-table-search
                        [searchFunction]="onQuickFilterChanged.bind(this)"
                    ></app-table-search>
                </div>
            </div>
            <div class="filter-section">
                <div class="filter-row">
                    <div class="filter-label">
                        <h3>Filter & Search By:</h3>
                    </div>
                    <div class="filter-dropdown">
                        <app-multi-select-dropdown
                            *ngIf="cloudFilterInfo"
                            [filterInfo]="cloudFilterInfo"
                            [selectedValues]="cloudSelected"
                            (change)="
                                updateFilterInfoListData($event, CLOUD_KEY)
                            "
                        ></app-multi-select-dropdown>
                    </div>
                    <div class="filter-dropdown">
                        <app-multi-select-dropdown
                            *ngIf="subProductFilterInfo"
                            [filterInfo]="subProductFilterInfo"
                            [selectedValues]="subProductSelected"
                            (change)="
                                updateFilterInfoListData(
                                    $event,
                                    SUB_PRODUCT_KEY
                                )
                            "
                        ></app-multi-select-dropdown>
                    </div>
                    <div class="filter-dropdown">
                        <app-multi-select-dropdown
                            *ngIf="serviceFilterInfo"
                            [filterInfo]="serviceFilterInfo"
                            [selectedValues]="serviceSelected"
                            (change)="
                                updateFilterInfoListData($event, SERVICE_KEY)
                            "
                        ></app-multi-select-dropdown>
                    </div>
                </div>
                <div class="filter-row bulk-row">
                    <div class="filter-label">
                        <h3>Bulk Update:</h3>
                    </div>
                    <div class="filter-dropdown">
                        <app-single-select-dropdown
                            *ngIf="permissionFilterInfo"
                            [filterInfo]="permissionFilterInfo"
                            [clearable]="true"
                            [selectedValue]="permissionSelected"
                            (change)="bulkUpdatePermission($event)"
                        ></app-single-select-dropdown>
                    </div>
                    <div class="filter-dropdown">
                        <app-single-select-dropdown
                            *ngIf="versionFilterInfo"
                            [filterInfo]="versionFilterInfo"
                            [clearable]="true"
                            [selectedValue]="versionSelected"
                            (change)="bulkUpdateWidgetType($event)"
                        ></app-single-select-dropdown>
                    </div>
                </div>
            </div>
            <div class="table-section">
                <ng-container *ngIf="tableGenInput && tableData">
                    <app-only-table-generator
                        [tableInput]="tableGenInput"
                        [tableData]="tableData"
                        [defaultColDef]="defaultColDef"
                        [hideControlBar]="true"
                        [autoFitColumns]="false"
                        (gridRef)="agGrid = $event; onGridReady($event)"
                        (selectionChange)="onSelectionChanged($event)"
                    ></app-only-table-generator>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="controls">
        <app-multi-button-generator
            [buttonGenInputs]="buttonGenInputs"
        ></app-multi-button-generator>
    </div>
</div>
