import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { SanitizeTypes } from './../../../../enums/SanitizeTypes';
@Component({
    selector: 'app-more-info-modal',
    templateUrl: './more-info-modal.component.html',
    styleUrls: ['./more-info-modal.component.sass']
})
export class MoreInfoModalComponent implements OnInit {
    objectKeys = Object.keys;
    objectValues = Object.values;
    data;
    isHtml = false;
    imageUrl = '';
    filters;
    filtersDesc;
    filtersDescInsightLevel = {};
    allCloudsfiltersDesc = {};
    SanitizeTypes = SanitizeTypes;

    constructor(private modalInputData: ModalInjectedData) {}

    ngOnInit(): void {
        this.isHtml = this.modalInputData.data.isHtml;
        this.data = this.modalInputData.data.moreInfo;
        if (!this.isHtml) {
            this.imageUrl = this.data['tableDescription'][0]['url'];
            this.filtersDescInsightLevel = this.data['Filters Appilcable'];
            if (localStorage.getItem('filter-desc')) {
                this.allCloudsfiltersDesc = JSON.parse(
                    localStorage.getItem('filter-desc')
                );
                this.filtersDesc =
                    this.allCloudsfiltersDesc[
                        this.modalInputData['apiDocs']['cloud']
                    ];
                this.filters = this.modalInputData['portletData']['filters'];

                this.filters.forEach((key) => {
                    if (key in this.filtersDesc) {
                        this.filtersDescInsightLevel[key] =
                            this.filtersDesc[key];
                    }
                });
            }
        }
    }
    ngAfterViewInit(): void {
        Helper.scrollTo('.more-info-conteiner', 0, 'slow');
    }
}
