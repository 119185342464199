import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { Clouds } from 'src/app/shared/enums/Clouds';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { FilterCacheService } from 'src/app/shared/services/cache/filters-cache/filter-cache.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetInjectedData } from './../../../../classes/WidgetInjectedData';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-azure-resource-provisioning',
    templateUrl: './azure-resource-provisioning.component.html',
    styleUrls: ['./azure-resource-provisioning.component.sass']
})
export class AzureResourceProvisioningComponent implements OnInit {
    widgetRef: Widget;
    widgetFilters = [];
    resourceFormGenInputs: BehaviorSubject<IFormGeneratorInput> =
        new BehaviorSubject<IFormGeneratorInput>(null);

    resourceFormGroup: FormGroup;
    templateFormGenInputs: IFormGeneratorInput = null;
    templateFormGroup: FormGroup;
    templateBodyFormGenInputs: IFormGeneratorInput = null;
    templateBodyFormGroup: FormGroup;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    cloud: string;

    resetButton: IButtonGeneratorInput = {
        buttonName: 'Reset',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: this.reset.bind(this)
    };

    submitButton: IButtonGeneratorInput = {
        buttonName: 'Submit',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        showLoader: true,
        function: (buttonRef) => {
            this.create(buttonRef);
        }
    };
    constructor(
        widgetData: WidgetInjectedData,
        private filterCacheService: FilterCacheService,
        private listHttpService: ListHttpService,
        private userCache: UserDataCacheService,
        private configCache: ConfigCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_CSP
            )
        ) {
            this.cloud = Clouds.AZURE_CSP;
        }
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_EA
            )
        ) {
            this.cloud = Clouds.AZURE_EA;
        }
        if (
            this.widgetRef.widgetData.widgetInfo.create &&
            this.widgetRef.widgetData.widgetInfo.create.apiRouteSuffix.includes(
                Clouds.AZURE_PLAN
            )
        ) {
            this.cloud = Clouds.AZURE_PLAN;
        }
    }

    ngOnInit() {
        this.widgetRef.hideRefreshIcon.next(true);
        this.widgetRef.showViewIcon.next(false);
        this.widgetRef.widgetData.widgetInfo.schedulingAutomation.widgetFilters.forEach(
            (element) => {
                this.widgetFilters = element.filters;
            }
        );

        this.setUpBasics();
    }
    setUpBasics() {
        const customFormInput = {
            showKey: 'label',
            responseValueKey: 'dataList'
        };
        Helper.generateFormInput(
            this.widgetFilters,
            this.widgetRef.httpService,
            this.filterCacheService,
            customFormInput,
            null,
            null,
            null,
            null,
            null,
            this.userCache,
            this.configCache
        ).subscribe((res) => {
            if (res) {
                const response = Helper.cloneDeep(res);
                response.fields.forEach((ele) => {
                    if (ele.name.includes('Ids')) {
                        const index = response.fields.findIndex((ele) =>
                            ele.name.includes('Ids')
                        );

                        if (index !== 0) {
                            response.fields.splice(index, 1);
                            response.fields.splice(0, 0, ele);
                        }
                    }
                });
                const fieldIndex1 = response.fields.findIndex((element) =>
                    element.name.includes('Resource')
                );
                const controlName = response.fields.find((element) =>
                    element.name.includes('Id')
                );
                response.fields[fieldIndex1].hiddenDependency = [
                    {
                        controlName: controlName.name,
                        validations: [{ validator: CustomValidators.required }]
                    }
                ];
                response.fields.unshift({
                    label: 'Deployment Name',
                    placeholder: 'Deployment Name',
                    name: 'stackName',
                    fieldType: FilterType.TEXT,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Account id is required'
                        }
                    ]
                });
                response.fields.push({
                    label: 'JSON File',
                    placeholder: '',
                    name: 'jsonFile',
                    fieldType: FilterType.RADIO,
                    listData: [
                        { id: 'create', label: 'Create' },
                        {
                            id: 'json',
                            label: 'Select from uploaded templates'
                        }
                    ],
                    showKey: 'label',
                    value: 'create'
                });
                this.resourceFormGenInputs.next(response);
                this.resourceFormGenInputs.subscribe();

                this.generateFields();
            }
        });
    }
    generateFields() {
        this.templateFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Template JSON',
                    placeholder: 'Enter template JSON',
                    name: 'templateJson',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    apiInfo: {
                        ...this.listHttpService.listArmJson(this.cloud),
                        host: this.widgetRef.widgetData.widgetInfo.create.host
                    },
                    responseValueKey: 'dataList',
                    showKey: 'fileName',
                    getKey: null
                }
            ]
        };
        this.templateBodyFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Template',
                    placeholder: 'Select template',
                    name: 'template',
                    fieldType: FilterType.TEXTAREA,
                    value: '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Template is required'
                        },
                        {
                            validator: CustomValidators.json,
                            errorMessage: 'Invalid JSON'
                        }
                    ]
                }
            ]
        };
    }
    depField(event) {
        if (event && event.templateJson && event.templateJson.jsonTemplate) {
            this.updateControlInput = {
                action: UpdateAction.UPDATE_VALUE,
                controls: ['template'],
                value: JSON.stringify(event.templateJson.jsonTemplate, null, 3)
            };
            this.updateControl.next(this.updateControlInput);
        }
        this.templateBodyFormGroup.updateValueAndValidity();
    }
    create(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.resourceFormGroup);
        if (this.resourceFormGroup.invalid) {
            this.resourceFormGroup.updateValueAndValidity();
            return;
        }
        Helper.markAllFieldAsTouched(this.templateBodyFormGroup);
        if (this.templateBodyFormGroup.invalid) {
            this.templateBodyFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const inputs = this.prepareInputs();
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );

        apiArgs.input = inputs;
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Request to create template has been initiated successfully.'
            );
            this.reset();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error While Creating Instance'
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    reset() {
        this.templateBodyFormGroup.updateValueAndValidity();
        this.templateBodyFormGroup.reset();
        this.resourceFormGroup.reset();
        this.resourceFormGroup.get('jsonFile').setValue('create');
        if (this.templateFormGroup) {
            this.templateFormGroup.reset();
        }
    }
    prepareInputs() {
        const configurationProvision = this.resourceFormGroup?.value
            ? this.resourceFormGroup.value
            : '';
        const accountIdKey = Object.keys(configurationProvision).find((ele) =>
            ele.includes('Id')
        );
        const accountIdValue = configurationProvision[accountIdKey];
        delete configurationProvision[accountIdKey];
        delete configurationProvision.jsonFile;
        const templateBody = this.templateBodyFormGroup?.value?.template
            ? this.templateBodyFormGroup.value.template
            : '';
        configurationProvision['templateBody'] = templateBody;
        return {
            configurationProvision: configurationProvision,
            [accountIdKey]: [accountIdValue]
        };
    }
}
