<!-- Email Template Form -->
<div class="email-config-modal">
	<div class="radio-button-config">
		<span class="configuration-label">Configuration for Email Template</span>
		<mat-radio-group
			aria-label="Configuration for Email Template"
			class="tw-flex"
			name="emailTemplateType"
			(click)="handleRuleSelectionChange($event)"
		>
			<mat-radio-button
				class="tw-mr-12"
				[checked]="selectedRuleType == ConfigurationType.DEFAULT"
				value="Default"
			>
				<span class="common-label">Default</span>
			</mat-radio-button>
			<mat-radio-button
				class="tw-mr-12"
				[checked]="selectedRuleType == ConfigurationType.CUSTOM"
				value="Custom"
			>
				<span class="common-label">Custom</span>
			</mat-radio-button>
		</mat-radio-group>
		<hr />
	</div>
	<div class="email-configuration">
		<div class="email-config-rules-list">
			<span class="rules-heading">Rules</span>
			<ng-container
				*ngIf="rulesList && rulesList.length > 0; else displayMesssage"
			>
				<div
					class="add-email-config-rule-button"
					(click)="handleClickPlusRuleButton()"
				>
					<span class="add-rule" appIcon [data]="plusIcon"></span>
				</div>
				<div
					class="rules-list"
					*ngFor="let rule of rulesList; let i = index"
				>
					<div class="rule-name"
                        (click)="editEmailRuleConfig(i)"
                        [matTooltip]="rule['ruleName']">
						{{ rule['ruleName'] }}
					</div>
					<span
						class="cross-svg"
						appIcon
						[data]="removeIcon"
						(click)="removeEmailConfigRule(i)"
						[class.disabled]="isInEditState && i === currentRuleConfigIndex"
					></span>
				</div>
			</ng-container>
		</div>
		<div class="email-config-rules-div">
			<ng-container>
				<div class="email-config-rules-form">
					<app-form-generator
						*ngIf="emailRulesConfigFormGenInput"
						[formGeneratorInput]="emailRulesConfigFormGenInput"
						(formGroupRef)="emailRulesConfigFormGroup = $event"
						class="emailConfigForm"
					></app-form-generator>
					<div class="email-rules-config-form-buttons">
						<app-button-generator
							*ngIf="!addEmailConfigRuleButtonGenInput.disable"
							[buttonGenInput]="addEmailConfigRuleButtonGenInput"
						></app-button-generator>
						<app-button-generator
							*ngIf="!updateEmailConfigRuleButtonGenInput.disable"
							[buttonGenInput]="updateEmailConfigRuleButtonGenInput"
						></app-button-generator>
					</div>
				</div>
			</ng-container>
			<div class="save-email-config-button-div">
				<app-button-generator
					[buttonGenInput]="saveEmailConfigRuleButtonGenInput"
				></app-button-generator>
			</div>
		</div>
	</div>
</div>

<ng-template #displayMesssage>
	<div class="display-message">
		<span class="info-message">Rules will be listed once added</span>
	</div>
</ng-template>
