import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { CustomValidators } from './../../../../classes/CustomValidators';
import { FilterType } from './../../../../enums/FilterType';
import { FormState } from './../../../../enums/FormState';
import { IconType } from './../../../../enums/IconType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';

@Component({
    selector: 'app-create-customer-step-two',
    templateUrl: './create-customer-step-two.component.html',
    styleUrls: ['./create-customer-step-two.component.sass']
})
export class CreateCustomerStepTwoComponent implements OnInit {
    rootUserInfoFormGenInput: IFormGeneratorInput = null;
    rootUserInfoFormGroup: FormGroup;

    stepData: any;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.back();
            }
        },
        {
            buttonName: 'Next',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.nextStep();
            }
        }
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between',
        },
    };

    constructor(
        public multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private httpService: HttpService,
        private ngZone: NgZone,
        private modalService: ModalService,
        private filtersService: FiltersService,
        private modalInputData: ModalInjectedData
    ) {}

    ngOnInit(): void {
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2);
        }
        this.settingUpForms();
    }
    settingUpForms() {
        this.rootUserInfoFormGenInput = {
            formName: 'Customer Info',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Email Id',
                    placeholder: 'Email Id',
                    name: 'email',
                    value: this.stepData ? this.stepData.email : '',
                    fieldType: FilterType.EMAIL,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'email'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Email is invalid'
                        }
                    ]
                },
                {
                    label: 'First Name',
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: this.stepData ? this.stepData.firstName : '',
                    fieldType: FilterType.TEXT,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    },
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'First name is required'
                        }
                    ]
                },
                {
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: this.stepData ? this.stepData.lastName : '',
                    fieldType: FilterType.TEXT,
                    required: false,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'perm_identity'
                        },
                        hoverText: null
                    }
                },
                {
                    label: 'Phone Number',
                    placeholder: 'Phone Number',
                    name: 'phoneNumber',
                    value: this.stepData ? this.stepData.phoneNumber : '',
                    fieldType: FilterType.NUMBER,
                    required: false,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'call'
                        },
                        hoverText: null
                    }
                },
                {
                    label: 'Password',
                    placeholder: 'Password',
                    name: 'password',
                    value: this.stepData ? this.stepData.password : '',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    suffixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'edit'
                        },
                        hoverText: 'Generate Password',
                        function: this.generatePassword.bind(this)
                    },
                    validations: Helper.getPasswordValidators()
                },
                {
                    label: 'Confirm Password',
                    placeholder: 'Confirm password',
                    name: 'confirmPassword',
                    value: this.stepData ? this.stepData.confirmPassword : '',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    prefixIcon: {
                        iconData: {
                            type: IconType.MATICON,
                            class: 'lock'
                        },
                        hoverText: null
                    },
                    validations: Helper.getPasswordValidators(
                        'equal',
                        'password'
                    )
                }
            ]
        };
    }
    generatePassword() {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        this.rootUserInfoFormGroup.get('password').setValue(password);
        this.rootUserInfoFormGroup.get('confirmPassword').setValue(password);
    }
    back() {
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, this.rootUserInfoFormGroup.value);
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
    nextStep() {
        Helper.markAllFieldAsTouched(this.rootUserInfoFormGroup);
        this.rootUserInfoFormGroup
            .get('confirmPassword')
            .updateValueAndValidity();
        if (this.rootUserInfoFormGroup.invalid) {
            this.rootUserInfoFormGroup.updateValueAndValidity();
            return;
        } else {
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .set(2, this.rootUserInfoFormGroup.value);
        }
    }
}
