import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IButtonGeneratorInput } from '../../interfaces/button-generator/IButtonGeneratorInput';

@Injectable({
	providedIn: 'root',
})
export class MulticurrencyFilterSelectorService {
	multiCurrencyFilters: Map<string, any[]> = new Map();
	addCurrencyButtonGenInput: IButtonGeneratorInput;
	duplicateFieldIndex = new BehaviorSubject<number | null>(null);
    duplicateCurrency: any = [];
    multiCurrencyFilterId: string = '1d1f1eab-e0c3-410c-bea8-242d9def2b92'; // Cretaed a Temp Filter Id for the MultiCurrency Field, to prepare the filter input.
    supportedFormats: string[] = [];
    showMultiCurrencyField: boolean;
	constructor() {}

	/**
	 * Sets the filter values for the specified filter identifier.
	 *
	 * @param filterId - The identifier of the filter for which values are to be set.
	 * @param values - An array of values to set for the specified filter.
	 */
	public setFilterValues(filterId: string, values: any[]): void {
		this.multiCurrencyFilters.set(filterId, values);
	}

	/**
	 * Retrieves the filter values for the specified filter identifier.
	 *
	 * @param filterId - The identifier of the filter for which values are to be retrieved.
	 * @returns An array of values for the specified filter, or an empty array if no values are set.
	 */
	public getFilterValues(filterId: string): any[] {
		return this.multiCurrencyFilters.get(filterId) || [];
	}

    /**
     * Checks for duplicate values in the currency filters and updates the state accordingly.
     * @returns A boolean indicating whether there are duplicate values in the currency filters.
     */
    public checkDuplicateFilter(): boolean {
        const filterId = this.multiCurrencyFilterId;
		const currencyFiltersValue = this.getFilterValues(filterId);
		this.duplicateCurrency.splice(1, this.duplicateCurrency.length);
		let isDuplicate = false;
		this.addCurrencyButtonGenInput.disable = false;
		if (Array.isArray(currencyFiltersValue)) {
			for (let i = 0; i < currencyFiltersValue.length; i++) {
                const currencyValue = currencyFiltersValue[i];
				if (this.duplicateCurrency.includes(currencyValue?.label)) {
					this.addCurrencyButtonGenInput.disable = true;
                    this.duplicateFieldIndex.next(i + 1);
					return (isDuplicate = true);
				} else {
					this.duplicateFieldIndex.next(null);
				}
				this.duplicateCurrency.push(currencyValue?.label);
			}
		}
		return isDuplicate;
	}
}
