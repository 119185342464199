<div class="tw-relative tw-h-full tw-w-full container">
    <div
        class="tw-cursor-pointer close-button"
        (click)="modalService.closeModal(null, modalData.modalId)"
        appIcon
        [data]="crossIcon"
    ></div>

    <app-vertical-scrolling-tab-group>
        <ng-template appVerticalScrollingTab [tabLabel]="tabs[0]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #consumptionInfoPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ tabs[0] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionInfoPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="body-container">
                        <app-only-table-generator
                            [tableInput]="consumptionInfoTableInput"
                            [tableData]="consumptionInfoTableData"
                            [hideControlBar]="true"
                            [defaultColDef]="consumptionInfoColDef"
                        ></app-only-table-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="tabs[1]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #consumptionBreakupPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ tabs[1] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!consumptionBreakupPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="consumptionBreakupPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div
                        class="tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-mx-auto tw-mt-6 tw-mb-5 tw-p-5 tw-text-fs-400 action-box"
                    >
                        <div class="tw-flex tw-flex-auto tw-py-2 tw-px-0">
                            <span class="tw-flex-auto tw-font-bold">
                                Breakup by:
                            </span>
                            <mat-radio-group
                                class="tw-contents"
                                [(ngModel)]="selectedBreakupBy"
                            >
                                <div class="tw-flex-auto tw-my-0 tw-mx-3">
                                    <mat-radio-button
                                        (change)="
                                            selectedBreakupBy = $event.value
                                        "
                                        [value]="breakUpByKey"
                                    >
                                        {{ breakUpByKey }}
                                    </mat-radio-button>
                                </div>

                                <div class="tw-flex-auto tw-my-0 tw-mx-3">
                                    <mat-radio-button
                                        (change)="
                                            selectedBreakupBy = $event.value
                                        "
                                        [value]="dataList.Resource_ID"
                                    >
                                        Resource Name
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="body-container">
                        <div class="table-name">
                            Breakup by
                            {{
                                selectedBreakupBy === breakUpByKey
                                    ? breakUpByKey
                                    : "Resource ID"
                            }}
                        </div>
                        <ng-container
                            *ngIf="
                                selectedBreakupBy === breakUpByKey &&
                                accountIdTableInput &&
                                accountIdTableData
                            "
                        >
                            <app-only-table-generator
                                [tableInput]="accountIdTableInput"
                                [tableData]="accountIdTableData"
                                [hideControlBar]="true"
                                [defaultColDef]="consumptionBreakupColDef"
                            ></app-only-table-generator>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                selectedBreakupBy === dataList.Resource_ID &&
                                resourceIdTableInput &&
                                resourceIdTableData
                            "
                        >
                            <app-only-table-generator
                                [tableInput]="resourceIdTableInput"
                                [tableData]="resourceIdTableData"
                                [hideControlBar]="true"
                                [defaultColDef]="consumptionBreakupColDef"
                            ></app-only-table-generator>
                        </ng-container>
                    </div>
                    <div class="tw-px-6 tw-h-96">
                        <app-graph-generator
                            *ngIf="graphData"
                            [graphData]="graphData"
                        ></app-graph-generator>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

        <ng-template appVerticalScrollingTab [tabLabel]="tabs[2]">
            <mat-accordion displayMode="flat" multi>
                <mat-expansion-panel
                    #generalInfoPanel
                    hideToggle="true"
                    [expanded]="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ tabs[2] }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <span
                                *ngIf="!generalInfoPanel.expanded"
                                appIcon
                                [data]="arrowDownIcon"
                            ></span>
                            <span
                                *ngIf="generalInfoPanel.expanded"
                                appIcon
                                [data]="arrowUpIcon"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-container
                        *ngIf="generalInfoTableInput && generalInfoTableData"
                    >
                        <span class="body-container">
                            <app-only-table-generator
                                [tableInput]="generalInfoTableInput"
                                [tableData]="generalInfoTableData"
                                [hideControlBar]="true"
                            ></app-only-table-generator>
                        </span>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </app-vertical-scrolling-tab-group>
</div>
