<div
    class="tw-h-32 tw-relative tw-rounded-md ribbon-container"
    [ngClass]="
        widgetRef.widgetData.widgetInfo.color
            ? widgetRef.widgetData.widgetInfo.color
            : 'blue'
    "
>
    <div class="tw-h-4/5 tw-w-3/4 tw-relative ribbon-box">
        <div
            class="
                tw-ml-2
                tw-h-11
                tw-top-1
                tw-relative
                tw-text-fs-200
                tw-font-normal
                ribbon-name
            "
        >
            {{ widgetRef.widgetData.widgetInfo.text }}
        </div>
    </div>
    <div
        class="
            tw-relative
            tw-top-4
            tw-h-2/6
            tw-w-10/12
            tw-flex
            tw-items-center
            tw-ml-4
            action-container
        "
    >
        <div
            class="
                tw-w-5/12
                tw-relative
                tw-top-1
                tw-flex
                tw-items-center
                tw-text-fs-1000
                ribbon-count
            "
        >
            {{ count === null ? "" : count }}
        </div>
        <div
            class="
                tw-top-2
                tw-cursor-pointer
                tw-flex-1
                tw-text-fs-200
                tw-absolute
                tw-flex
                tw-justify-center
                tw-w-6
                tw-h-6
                tw-overflow-hidden
                tw--right-3
                tw-bottom-2
                tw-rounded-full
                refresh-container
            "
        >
            <div
                class="tw-w-7/12 icon"
                appIcon
                [data]="refreshIcon"
                [hideText]="true"
                (click)="$event.stopPropagation(); widgetRef.refreshWidget()"
                [class.disabled]="
                    !widgetRef.widgetConfigState.actions.refresh.state
                "
                [class.loader]="widgetRef.loadingData | async"
            ></div>
        </div>
    </div>
    <div
        class="tw-absolute tw-h-2/5 top-0 image"
        appIcon
        [data]="curveIcon"
    ></div>
    <div class="tw-absolute tw-top-1 tw-right-1">
        <span>
            <img
                class="tw-w-6 tw-h-6"
                [src]="widgetRef.widgetData.widgetInfo.cloudIconPath"
            />
        </span>
    </div>
</div>
