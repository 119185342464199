import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { SsoIntegrationStepOneComponent } from '../../../modal-templates/integration/sso-modal/sso-integration-step-one/sso-integration-step-one.component';
import { SsoIntegrationStepTwoComponent } from '../../../modal-templates/integration/sso-modal/sso-integration-step-two/sso-integration-step-two.component';
import { SsoIntegrationsTableComponent } from '../../../modal-templates/integration/sso-modal/sso-integrations-table/sso-integrations-table.component';
import { ButtonColorType } from './../../../../enums/ButtonColorType';
import { ButtonType } from './../../../../enums/ButtonType';

@Component({
    selector: 'app-sso-integration',
    templateUrl: './sso-integration.component.html',
    styleUrls: ['./sso-integration.component.sass']
})
export class SsoIntegrationComponent implements OnInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isIntegrated: any;
    isConfigured: Boolean = false;
    response: IIntegrationApiResponse;

    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'SAML Integration',
            imageUrl: 'assets/integrations/sso-plugin.png',
            buttons: [],
            imagePadding: 8,
            backgroundColor: '#1e72c6'
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        // Adding Integrate Button
        this.integrationInput.buttons.push({
            buttonName: 'Integrate',
            buttonType: ButtonType.CIRCLE,
            buttonColorType: ButtonColorType.SUCCESS,
            buttonIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-plus'
            },
            hoverText: 'Integrate SSO',
            customClass: 'sso-container',

            function: () => {
                const modalArgs: IModalData = {
                    modalName: 'Integrate SSO',
                    sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                    modalType: ModalType.MIDDLE,
                    modalIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-wrench'
                    },
                    extraStepContainerClass: 'remove-form-space',
                    modalSteps: [
                        {
                            stepName: 'Integrate SSO',
                            resetModal: true,
                            stepData: {
                                componentToLoad: SsoIntegrationStepOneComponent,
                                payload: {
                                    data: {
                                        response: response,
                                        widgetRef: this.widgetRef
                                    }
                                }
                            }
                        },
                        {
                            stepName: 'Map Roles to Views',
                            stepData: {
                                componentToLoad: SsoIntegrationStepTwoComponent,
                                payload: {
                                    data: {
                                        response: response,
                                        widgetRef: this.widgetRef
                                    }
                                }
                            },
                            resetModal: true
                        }
                    ],
                    modalWidthVw: 60,
                    modalHeightVh: 95,
                    noBottomPadding: true
                };
                this.widgetRef.modalService.openModal(modalArgs);
            }
        });
        //Adding list icon
        this.integrationInput.buttons.push({
            buttonName: 'View Existing',
            buttonType: ButtonType.CIRCLE,
            buttonColorType: ButtonColorType.SUCCESS,
            buttonIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-bars '
            },
            hoverText: 'Existing Integrations',
            customClass: 'sso-container',
            function: () => {
                this.openIntegrationsListingModal(response);
            }
        });
    }
    onIntegrate(response) {
        const modalArgs: IModalData = {
            modalName: 'Integrate SSO',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-wrench'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Integrate SSO',
                    resetModal: true,
                    stepData: {
                        componentToLoad: SsoIntegrationStepOneComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                },
                {
                    stepName: 'Map Roles to Views',
                    stepData: {
                        componentToLoad: SsoIntegrationStepTwoComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    },
                    resetModal: true
                }
            ],
            modalWidthVw: 60,
            modalHeightVh: 95,
            noBottomPadding: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        this.response = response;
        this.isIntegrated = response;
        if (this.isIntegrated && this.isIntegrated.length) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }

    openIntegrationsListingModal(response) {
        const modalArgs: IModalData = {
            modalName: 'Existing Integrations',
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-circle'
            },
            modalSteps: [
                {
                    stepName: 'Integrations List',
                    stepData: {
                        componentToLoad: SsoIntegrationsTableComponent,
                        payload: {
                            data: {
                                response: response,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 50,
            modalHeightVh: 60
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}
