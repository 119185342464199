import { Component, OnInit } from '@angular/core';
import { ColDef, GridOptions, RowEvent, RowNode } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { getLogoPathOfCloudLabel } from 'src/app/shared/enums/CloudLabel';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IApiInfo } from 'src/app/shared/interfaces/api/IApiInfo';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

@Component({
    selector: 'app-add-widget',
    templateUrl: './add-widget.component.html',
    styleUrls: ['./add-widget.component.sass']
})
export class AddWidgetComponent implements OnInit {
    widgetRef: Widget;
    tableGenInput: ITableGeneratorInput = null;
    aclApiInfo: IApiInfo = null;
    gridRef: GridOptions = null;
    node: Node<IAttributeInfo> = null;
    acl: string = null;
    buttonGenInput: IButtonGeneratorInput[];
    buttonOption: IMultiButtonOption;

    // Unique Cloud, Sub-Product and Service pair list
    uniqueCspsPairList: {
        cloud?: string;
        subProduct?: string;
        service?: string;
    }[] = [];

    cloudFilterInfo: IFilterInfo;
    cloudSelected: any[] = [];
    cloudSet = new Set<string>();
    subProductFilterInfo: IFilterInfo;
    subProductSelected: any[] = [];
    subProductSet = new Set<string>();
    serviceFilterInfo: IFilterInfo;
    serviceSelected: any[] = [];
    serviceSet = new Set<string>();

    isLoading: boolean = true;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    tableWidgetList: any[];
    defaultColDef: ColDef = {
        width: 100,
        filter: false,
        editable: false,
        sortable: false,
        resizable: false
    };

    readonly WIDGET_ID_KEY = 'id';
    readonly WIDGET_NAME_KEY = 'name';
    readonly CLOUD_KEY = 'cloud';
    readonly SUB_PRODUCT_KEY = 'subProduct';
    readonly SERVICE_KEY = 'service';

    constructor(
        public modalInputData: ModalInjectedData,
        public modalService: ModalService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.acl = this.modalInputData.data['acl'];
        this.node = this.modalInputData.data['node'];
        this.widgetRef = this.modalInputData.data['widgetRef'];

        this.fetchWidgetList();
    }

    fetchWidgetList() {
        this.aclApiInfo = {
            host: '',
            apiRouteSuffix: ApiUrls.ACL_DESCRIBE.replace('{aclId}', this.acl),
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null,
            requestType: RequestType.GET,
            intactUrl: ApiUrls.ACL_DESCRIBE
        };
        const apiArgs = Helper.generateHitApiConfig(this.aclApiInfo);
        apiArgs.input = {};
        apiArgs.function = (response) => {
            this.tableWidgetList = response;
            this.prepareFilters();
            this.generateTableAndButtons();
        };
        apiArgs.errorFunction = (error) => {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while fetching widget list'
            );
        };
        apiArgs.endFunction = () => {
            this.isLoading = false;
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    prepareFilters() {
        if (this.tableWidgetList && this.tableWidgetList.length) {
            this.uniqueCspsPairList = [];
            const keySet = new Set<string>();
            this.tableWidgetList.forEach((masterAclWidget) => {
                let cloud = null;
                let subProduct = null;
                let service = null;
                if (masterAclWidget[this.CLOUD_KEY]) {
                    cloud = masterAclWidget[this.CLOUD_KEY];
                    this.cloudSet.add(cloud);
                }
                if (masterAclWidget[this.SUB_PRODUCT_KEY]) {
                    subProduct = masterAclWidget[this.SUB_PRODUCT_KEY];
                    this.subProductSet.add(subProduct);
                }
                if (masterAclWidget[this.SERVICE_KEY]) {
                    service = masterAclWidget[this.SERVICE_KEY];
                    this.serviceSet.add(service);
                }
                if (cloud || subProduct || service) {
                    const uniquePairString = [cloud, subProduct, service].join(
                        '|'
                    );
                    if (!keySet.has(uniquePairString)) {
                        keySet.add(uniquePairString);
                        this.uniqueCspsPairList.push({
                            cloud: cloud,
                            subProduct: subProduct,
                            service: service
                        });
                    }
                }
            });
            this.setCloudFilterInfo(Array.from(this.cloudSet.values()));
            this.setSubProductFilterInfo(
                Array.from(this.subProductSet.values())
            );
            this.setServiceFilterInfo(Array.from(this.serviceSet.values()));
        }
    }

    setCloudFilterInfo(listData?: string[]) {
        this.cloudFilterInfo = {
            placeholder: 'Cloud',
            label: 'Cloud',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setSubProductFilterInfo(listData?: string[]) {
        this.subProductFilterInfo = {
            placeholder: 'Sub - Product',
            label: 'Sub - Product',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    setServiceFilterInfo(listData?: string[]) {
        this.serviceFilterInfo = {
            placeholder: 'Service',
            label: 'Service',
            listData: listData ? Helper.mapToDropdownData(listData) : []
        };
    }

    updateFilterInfoListData(selectedList, type) {
        if (this.gridRef) {
            if (type === this.CLOUD_KEY) {
                if (this.areDistinctLists(this.cloudSelected, selectedList)) {
                    this.updateListDataForCloud(selectedList);
                    this.cloudSelected = selectedList;
                    this.gridRef.api.onFilterChanged();
                }
            }
            if (type === this.SUB_PRODUCT_KEY) {
                if (
                    this.areDistinctLists(this.subProductSelected, selectedList)
                ) {
                    this.updateListDataForSubProduct(selectedList);
                    this.subProductSelected = selectedList;
                    this.gridRef.api.onFilterChanged();
                }
            }
            if (type === this.SERVICE_KEY) {
                if (this.areDistinctLists(this.serviceSelected, selectedList)) {
                    this.serviceSelected = selectedList;
                    this.gridRef.api.onFilterChanged();
                }
            }
        }
    }

    areDistinctLists(ls1: string[], ls2: string[]) {
        if (ls1.length === ls2.length) {
            let result = false;
            for (let i = 0; i < ls1.length; i++) {
                if (!ls2.includes(ls1[i])) {
                    result = true;
                    break;
                }
            }
            return result;
        }
        return true;
    }

    updateListDataForCloud(selectedClouds) {
        let subProductList = null;
        let serviceList = null;
        const localServiceSet = new Set<string>();
        if (selectedClouds && selectedClouds.length) {
            const localSubProductSet = new Set<string>();
            if (this.subProductSelected && this.subProductSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedClouds.includes(cspsPair.cloud)) {
                        if (cspsPair.subProduct) {
                            localSubProductSet.add(cspsPair.subProduct);
                        }
                        if (
                            this.subProductSelected.includes(
                                cspsPair.subProduct
                            ) ||
                            localSubProductSet.has(cspsPair.subProduct)
                        ) {
                            if (cspsPair.service) {
                                localServiceSet.add(cspsPair.service);
                            }
                        }
                    }
                });
            } else {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedClouds.includes(cspsPair.cloud)) {
                        if (cspsPair.subProduct) {
                            localSubProductSet.add(cspsPair.subProduct);
                        }
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            }
            subProductList = Array.from(localSubProductSet.values());
            serviceList = Array.from(localServiceSet.values());
        } else {
            subProductList = Array.from(this.subProductSet.values());
            if (this.subProductSelected && this.subProductSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (this.subProductSelected.includes(cspsPair.subProduct)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
                serviceList = Array.from(localServiceSet.values());
            } else {
                serviceList = Array.from(this.serviceSet.values());
            }
        }
        this.updateSubProductSelectedValues(subProductList);
        this.setSubProductFilterInfo(subProductList);
        this.updateServiceSelectedValues(serviceList);
        this.setServiceFilterInfo(serviceList);
    }

    updateListDataForSubProduct(selectedSubProducts) {
        let serviceList = null;
        const localServiceSet = new Set<string>();
        if (selectedSubProducts && selectedSubProducts.length) {
            if (this.cloudSelected && this.cloudSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (
                        this.cloudSelected.includes(cspsPair.cloud) &&
                        selectedSubProducts.includes(cspsPair.subProduct)
                    ) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            } else {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (selectedSubProducts.includes(cspsPair.subProduct)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
            }
            serviceList = Array.from(localServiceSet.values());
        } else {
            if (this.cloudSelected && this.cloudSelected.length) {
                this.uniqueCspsPairList.forEach((cspsPair) => {
                    if (this.cloudSelected.includes(cspsPair.cloud)) {
                        if (cspsPair.service) {
                            localServiceSet.add(cspsPair.service);
                        }
                    }
                });
                serviceList = Array.from(localServiceSet.values());
            } else {
                serviceList = Array.from(this.serviceSet.values());
            }
        }
        this.updateServiceSelectedValues(serviceList);
        this.setServiceFilterInfo(serviceList);
    }

    updateSubProductSelectedValues(checkList) {
        const selected = this.subProductSelected.filter((subProductSel) => {
            return checkList.includes(subProductSel);
        });
        if (this.areDistinctLists(selected, this.subProductSelected)) {
            this.subProductSelected = selected;
        }
    }

    updateServiceSelectedValues(checkList) {
        const selected = this.serviceSelected.filter((serviceSel) => {
            return checkList.includes(serviceSel);
        });
        if (this.areDistinctLists(selected, this.serviceSelected)) {
            this.serviceSelected = selected;
        }
    }

    isExternalFilterAvailable() {
        if (
            (this.cloudSelected && this.cloudSelected.length) ||
            (this.subProductSelected && this.subProductSelected.length) ||
            (this.serviceSelected && this.serviceSelected.length)
        ) {
            return true;
        }
        return false;
    }

    externalFilterFunction(node: RowNode) {
        const nodeData = node.data;
        let result = true;
        if (this.cloudSelected && this.cloudSelected.length) {
            if (this.cloudSelected.includes(nodeData[this.CLOUD_KEY])) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.subProductSelected && this.subProductSelected.length) {
            if (
                this.subProductSelected.includes(nodeData[this.SUB_PRODUCT_KEY])
            ) {
                result = result && true;
            } else {
                result = result && false;
                return result;
            }
        }
        if (this.serviceSelected && this.serviceSelected.length) {
            if (this.serviceSelected.includes(nodeData[this.SERVICE_KEY])) {
                result = result && true;
            } else {
                result = result && false;
            }
        }
        return result;
    }

    generateTableAndButtons() {
        if (this.node && this.acl) {
            // Only showing full group
            this.tableGenInput = {
                listExtraction: {
                    type: 'DIRECT'
                },
                searchPlaceholder: 'Search by widget name',
                isExternalFilterPresent:
                    this.isExternalFilterAvailable.bind(this),
                doesExternalFilterPass: this.externalFilterFunction.bind(this),
                columns: [
                    {
                        columnKey: this.WIDGET_NAME_KEY,
                        columnName: 'Widget Name',
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        tooltipField: this.WIDGET_NAME_KEY,
                        minWidth: 250,
                        flex: 5
                    },
                    {
                        columnKey: this.CLOUD_KEY,
                        columnName: '',
                        minWidth: 40,
                        flex: 1,
                        cellClass: 'cloud-icon-cell content-center',
                        cellRenderer: (rowData: RowEvent) => {
                            const rowDataItem = rowData.data;
                            if (rowDataItem && rowDataItem[this.CLOUD_KEY]) {
                                const cloudIconPath = getLogoPathOfCloudLabel(
                                    rowDataItem[this.CLOUD_KEY]
                                );
                                if (cloudIconPath) {
                                    return `<img style="max-height: 100%; max-width: 100%" src="${cloudIconPath}" alt="${
                                        rowDataItem[this.CLOUD_KEY]
                                    }">`;
                                }
                            }
                        }
                    },
                    {
                        columnName: 'Sub Product',
                        columnKey: this.SUB_PRODUCT_KEY,
                        minWidth: 120,
                        flex: 2
                    },
                    {
                        columnName: 'Service',
                        columnKey: this.SERVICE_KEY,
                        minWidth: 120,
                        flex: 2
                    }
                ],
                noDataMessage: 'No widgets available in ACL',
                selectionLimit: GlobalConfiguration.WIDGETS_PER_SERVICE_LIMIT,
                selectionLimitBreachMessage: `You can add maximum of ${GlobalConfiguration.WIDGETS_PER_SERVICE_LIMIT} widgets per module`
            };
        }
        this.generateButton();
    }

    generateButton() {
        this.buttonGenInput = [
            {
                buttonName: 'Cancel',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.modalService.closeModal(
                        null,
                        this.modalInputData.modalId
                    );
                }
            },
            {
                buttonName: 'Insert',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                function: (buttonRef) => {
                    this.insertWidgetsToModule();
                },
                showLoader: false
            }
        ];
        this.buttonOption = {
            disable:
                this.gridRef &&
                this.gridRef.api &&
                !this.gridRef.api.getSelectedRows().length,
            layout: {
                justifyContent: 'space-between'
            }
        };
    }
    insertWidgetsToModule() {
        if (
            this.gridRef &&
            this.gridRef.api &&
            !this.gridRef.api.getSelectedRows().length
        ) {
            this.notificationsService.showSnackBar(
                'No widgets selected to add',
                true
            );
            return;
        }

        this.node.data.widgets = this.gridRef.api.getSelectedRows();

        this.modalService.closeModal(null, this.modalInputData.modalId);
    }

    onGridReady(event) {
        if (
            this.node &&
            this.node.data &&
            this.node.data.widgets &&
            this.node.data.widgets.length
        ) {
            const widgetIdSet = new Set<string>();
            this.node.data.widgets.forEach((widget) => {
                widgetIdSet.add(widget[this.WIDGET_ID_KEY]);
            });
            setTimeout(() => {
                if (this.gridRef && widgetIdSet.size) {
                    const total = widgetIdSet.size;
                    let count = 0;
                    this.gridRef.api.forEachNode((rowNode: RowNode, index) => {
                        const rowNodeData = rowNode.data;
                        if (widgetIdSet.has(rowNodeData[this.WIDGET_ID_KEY])) {
                            count++;
                            if (count === total) {
                                rowNode.setSelected(true);
                            } else {
                                rowNode.setSelected(true, false, true);
                            }
                        }
                    });
                }
            }, 500);
        }
    }

    onQuickFilterChanged(id: string) {
        if (this.gridRef) {
            this.gridRef.api.setQuickFilter(
                document.getElementById(id)['value']
            );
        }
    }
}
